import { CrmEntity, HateoasLink } from '@/models/base';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';

export enum CrmTypeList {
    ALL = 'all',
    CHILD_TYPE_1 = 'child/custom-1',
    CHILD_TYPE_2 = 'child/custom-2',
    CHILD_TYPE_3 = 'child/custom-3',
    CHILD_TYPE_4 = 'child/custom-4',
    CLASSROOM_GROUPS = 'classrooms',
    CONTACT_METHOD = 'family/contact-method',
    DRIP_EMAIL_EVENTS = 'drip-campaigns/email-events',
    DRIP_TEXT_EVENTS = 'drip-campaigns/text-events',
    ENROLLED_REASON = 'reasons/enrolled',
    EMAIL_FROM = 'communications/email/from',
    EMAIL_FROM_NAMES = 'communications/email/from-names',
    FAMILY_INQUIRY = 'families/inquiry',
    FAMILY_ORIGIN = 'families/origins',
    FAMILY_SOURCE = 'families/source',
    FORM_FIELDS_TYPES = 'fields/types',
    FIELDS = 'fields',
    GENDER = 'child/gender',
    LEAD_TYPE_1 = 'family/custom-1',
    LEAD_TYPE_2 = 'family/custom-2',
    LEAD_TYPE_3 = 'family/custom-3',
    LEAD_TYPE_4 = 'family/custom-4',
    LOCATION_STATUS = 'centers/status',
    LOCATION_GROUPS = 'location-groups',
    MARKTING_CAMPAIGNS = 'marketing-campaigns',
    MONTHS = 'months',
    PHONE_TYPE = 'phone',
    PENDING_PROCESS_ATS = 'pending-process-at',
    PENDING_REPLY_TOS = 'communications/email/pending-reply-to',
    PENDING_STAFF_TOS = 'communications/email/pending-to',
    PRIOR_CHILD_CARE = 'families/current-situations',
    REASON_FOR_CHANGE = 'reasons/changes',
    REASON_LOST_TO_COMPETITOR = 'reasons/lost-competitor',
    REASONS_LOST_OPP = 'reasons/lost-opportunity',
    REJECTED_REASONS = 'reasons/rejected',
    REASON_TEMPORARY_LEAVE = 'reasons/temporary-leave',
    REASON_WAIT_LIST = 'reasons/wait-list',
    REASON_WITHDRAWN = 'reasons/withdrawn',
    RELATIONSHIP = 'families/relationships',
    REPLY_TOS = 'communications/email/reply-to',
    STAFF_GROUPS = 'staff-groups',
    STAFF_NOTIFY = 'staff-notifications',
    STAFF_POSITIONS = 'staff-positions',
    STAFF_TITLE = 'staff-title',
    TASKS = 'tasks',
    TASK_RESULTS = 'tasks/results',
    TEMPLATE_GROUPS_EMAIL = 'communications/templates/groups/email',
    TEMPLATE_GROUPS_TEXT = 'communications/templates/groups/text',
    WAIT_LIST_PRIORITY = 'wait-list-priorities',
    WAIT_LIST_TYPE = 'wait-list-types',
    WORKFLOW_TASK_NOTIFY = 'task-reminders',
    CALL_CENTERS_ENROLLMENT_REP_FLOWS = 'call-centers/enrollment-rep-flows',
    COMMUNICATION_TYPES = 'communications'
}

export enum CrmType {
    FORM_FIELDS = 'fields',
    FORM_FIELDS_TYPES = 'fields/types'
}

export enum CrmTypeField {
    SELECT_LIST = 3001,
    STATE_SELECT_LIST = 7,
    PRIMARY_GUARDIAN_STATUS= 11,
    CHILD_STATUS = 21,
    STATUS = 3003,
    DATE = 3005,
    MARKETING_CAMPAIGN = 3022
}

export interface CrmTypeOption extends CrmEntity {
    order: number;
    value: string;
    is_default: boolean;
    is_editable: boolean;
    select_list?: HateoasLink | null;
    identifier?: string | null;
    is_log?: boolean | null;
    is_system_event?: boolean | null;
    is_task_result?: boolean | null;
    is_active?: boolean | null;
    is_hidden?: boolean | null;
}

export interface CrmTypeOptionCreateDto extends AbstractCreateDto {
    is_default: boolean;
    value: string;
    order: number;
}

export class CrmTypeOptionDto implements CrmTypeOptionCreateDto {
    id = 0;
    is_default = false;
    order = 0;
    value = '';
}

export interface CrmTypeOptionUpdateDto extends AbstractUpdateDto, CrmTypeOptionCreateDto {
    id: number;
    is_editable?: boolean;
}

export interface CustomTypeLabel extends CrmEntity {
    identifier: string;
    value: string;
}

export interface CustomTypeLabelDto {
    identifier: string;
    value: string;
}

export enum CustomTypeIdentifiers {
    LEAD_TYPE_1 = 'LEAD_TYPE',
    LEAD_TYPE_2 = 'LEAD_TYPE_TWO',
    LEAD_TYPE_3 = 'LEAD_TYPE_THREE',
    LEAD_TYPE_4 = 'LEAD_TYPE_FOUR',
    CHILD_TYPE_1 = 'CHILD_TYPE',
    CHILD_TYPE_2 = 'CHILD_TYPE_TWO',
    CHILD_TYPE_3 = 'CHILD_TYPE_THREE',
    CHILD_TYPE_4 = 'CHILD_TYPE_FOUR'
}

export interface TemplateGroupItem {
    groupItem: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto;
    templatesInGroup: Array<number>;
}

export interface CrmTypeOptionsChangesInterface {
    updatedOptions: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto>;
    deletedOptions: Array<number>;
    crmTypeList: CrmTypeList;
}

export enum RejectedReasonIdentifiers {
    DUPLICATE = 'reasonRejectedDuplicate',
    INVALID = 'reasonRejectedInvalid',
    UNKNOWN = 'reasonRejectedUnknown'
}

export enum InquiryTypeIdentifiers {
    UNKNOWN = 'inquirySourceUnknown'
}

export enum FamilySourceIdentifiers {
    UNKNOWN = 'leadSourceUnknown'
}

export enum PhoneTypeIdentifiers {
    CELL = 'phoneTypeMobile'
}
