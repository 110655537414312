













































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getWhiteLabelColors } from '@/utils/color-utils';

@Component
export default class LineLeaderBaseButton extends Mixins(LocaleMixin) {
    @Prop({ required: false, type: Boolean }) enroll!: boolean;
    @Prop({ required: false, type: Boolean }) manage!: boolean;
    @Prop({ required: false, type: Boolean }) whitelabel!: boolean;
    @Prop({ required: false, type: Boolean }) large!: boolean;
    @Prop({ required: false, type: String }) href!: string;
    @Prop({ required: false, type: Boolean }) ripple!: boolean;
    @Prop({ required: false, type: Boolean }) showIcon!: boolean;

    get classes() {
        getWhiteLabelColors();
        if (this.whitelabel) {
            return 'btn-whitelabel';
        } else {
            return this.enroll ? 'btn-enroll' : 'btn-manage';
        }
    }
}
