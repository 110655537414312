import { render, staticRenderFns } from "./InboxTable.vue?vue&type=template&id=a99b79b6&scoped=true&"
import script from "./InboxTable.vue?vue&type=script&lang=ts&"
export * from "./InboxTable.vue?vue&type=script&lang=ts&"
import style0 from "./InboxTable.vue?vue&type=style&index=0&id=a99b79b6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a99b79b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCardText,VCardTitle,VCheckbox,VCol,VIcon,VRow})
