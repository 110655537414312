import {
    CcfInterviewObject,
    CcfVariableLinkObject,
    TemplateVariable
} from '@/communications/templates/models/template-variable';
import { CcfIntegration } from '@/integrations/models/integration';
import { CcfInterviewsHash } from '@/integrations/store/integration-store';

export function groupTemplateVariables(variables: Array<TemplateVariable>, linksOnly = false): Map<string, Array<TemplateVariable>> {
    const map = new Map();
    variables.forEach((item) => {
        if (linksOnly && !item.is_link) {
            return;
        }
        const collection = map.get(item.group.values.value);
        if (!collection) {
            map.set(item.group.values.value, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function getTagLabel(variable: TemplateVariable): string {
    return variable.tag.replace('Lead', 'Guardian').replace('SelfScheduledTour', 'ParentScheduledTour');
}

export function getTagValue(variable: TemplateVariable): string {
    return '{{ ' + variable.tag + ' }}';
}

export function getGroupLabel(group: string): string {
    return group.replace('Lead', 'Guardian');
}

export function getLinkObjects(ccfVars: Array<TemplateVariable>, ccfIntegrations: Array<CcfIntegration>, ccfInterviews: CcfInterviewsHash): Array<CcfVariableLinkObject> {
    const linkObjects = [];
    for (const templateVar of ccfVars) {
        const interviewLinkObjects: Array<CcfInterviewObject> = [];
        for (const ccfIntegration of ccfIntegrations) {
            let interviewId = templateVar.ccf_interview_mappings?.find((mapping) => mapping.ccf_client_id === ccfIntegration.ccf_client_id)?.interview_id ?? 0;
            // clear out bad link entries when we can
            if (interviewId && !ccfInterviews[ccfIntegration.dns_name].map(ccfInterview => (ccfInterview.id)).includes(interviewId)) {
                interviewId = 0;
            }
            interviewLinkObjects.push({
                ccf_client_id: ccfIntegration.ccf_client_id,
                domain: ccfIntegration.dns_name,
                interview_id: interviewId
            });
        }
        linkObjects.push({
            linkId: templateVar.id,
            linkTag: templateVar.tag,
            interviewLinkObjects
        });
    }
    return linkObjects;
}
