















































































































































































































import { TextTemplateMapper } from '@/communications/mappers/text-template-mapper';
import { Reminder, TextReminderDto } from '@/communications/reminders/models/reminder-models';
import { TextRemindersRepository } from '@/communications/reminders/repositories/text-reminders-repository';
import { TextRemindersStore } from '@/communications/reminders/store/text-reminders-store';
import {
    HIERARCHY_TYPE_BRAND_ID,
    HIERARCHY_TYPE_LOCATION_GROUP_ID,
    HIERARCHY_TYPE_LOCATION_ID,
    HIERARCHY_TYPES_LIST
} from '@/crm-types/crm-types-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CommunicationTypes as CommunicationTypesConstants } from '@/communications/communication-constants';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Org } from '@/models/organization/org';
import { Brand } from '@/organizations/brands/models/brand-models';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { OrgsUtil } from '@/organizations/orgs-util';
import { OrgsStore } from '@/store/orgs-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AppStateStore } from '@/store/app-state-store';
import { groupTemplateVariables } from '@/communications/templates/template-variables-util';
import { TemplateVariablesRepository } from '@/communications/templates/repositories/template-variables-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import { MessageTemplate, TextTemplateUpdateDto } from '@/communications/templates/models/message-template';
import tinymce, { Editor as TinyEditor, RawEditorSettings } from 'tinymce';
import StringUtils from '@/utils/string-utils';
import TinyEditorComponent from '@tinymce/tinymce-vue';
import TinymceUtils from '@/utils/tinymce-utils';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';

// Theme
import 'tinymce/icons/default';
import 'tinymce/themes/silver/theme';

// Plugins
import 'tinymce/plugins/paste';
import '@/assets/plugins/tinymce-variable-tags/src/plugin';
import { TemplateVariable } from '@/communications/templates/models/template-variable';
import { TextTemplateUtils } from '@/communications/templates/text-template-utils';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import CopyTemplate from '@/communications/templates/components/CopyTemplate.vue';
import cloneDeep from 'lodash/cloneDeep';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import { Route } from 'vue-router';
import BaseClose from '@/components/base/BaseClose.vue';

const appState = getModule(AppStateStore);
const brandsState = getModule(BrandsStore);
const crmTypesState = getModule(CrmTypesStore);
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const orgsState = getModule(OrgsStore);
const orgsUtil = new OrgsUtil();
const templateMapper = new TextTemplateMapper();
const textRemindersRepository = new TextRemindersRepository();
const textRemindersState = getModule(TextRemindersStore);
const textTemplatesRepository = new TextTemplatesRepository();
const textTemplateState = getModule(TextTemplatesStore);
const utils = new TextTemplateUtils();
const staffUtils = new StaffUtils();
const authState = getModule(AuthStore, store);

// Get variables, group them by their grouping.
let variableTagsGrouped: any;
const variableTagsRepository = new TemplateVariablesRepository();

// If we want to have the variables show up in a readable format in the pane,
// we need to fill this out with a key (template var) and a value (readable value).
// This will end up looking like:
// variable_mapper: {
//     LeadFirstName: 'Lead First Name',
//     LeadLastName: 'Lead Last Name'
// }
// The object would be variableMapping.
const variableMapping: Record<any, any> = { };

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: {
        BaseClose,
        tinyEditorComponent: TinyEditorComponent,
        CopyTemplate,
        CrmTypeSelectList
    }
})
export default class TextTemplateEditor extends Mixins(LocaleMixin, BasicValidationMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
        if (canViewTemplatesRemindersAttachments) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    @Prop({ required: false }) id: number | undefined;

    private automationMessageTemplatesPermissionGrant = false;
    private automationRemindersPermissionGrant = false;
    private brandHierarchyTypeId = HIERARCHY_TYPE_BRAND_ID;
    private availCountClass = 'avail_count_good'
    private availLength = 1200;
    private canSave = true;
    private canUserAccessOrg = false;
    private isTemplate = true;
    private loadingKey = TextTemplateEditor.name;
    private locationGroupHierarchyTypeId = HIERARCHY_TYPE_LOCATION_GROUP_ID;
    private locationGroups: Array<CrmTypeOption> = [];
    private maxLength = 1200;
    private messageTemplate: MessageTemplate | Reminder | null = null;
    private messageTemplateDto: TextTemplateUpdateDto | TextReminderDto | null = null;
    private orgHierarchyTypeId = HIERARCHY_TYPE_LOCATION_ID;
    private previewBody = '';
    private previewLoadingKey = 'editTextTemplatePreviewLoading';
    private previewOpen = false;
    private textTemplate = 'Sample Text Template';
    private templateGroups: Array<CrmTypeOption> = [];
    private validForm = false;
    private copyTemplateModalOpen = false;
    private copiedMessageTemplate: MessageTemplate | null = null;
    private templateGroupsTextList = CrmTypeList.TEMPLATE_GROUPS_TEXT;
    private communicationTypeOptions: Array<CrmTypeOption> = [];
    private communicationType: number | null = CommunicationTypesConstants.MARKETING;

    // You can try to break out the init if you want, but JS make me cry sometimes.
    // 1. this.* is actually a "one-way" binding on build and with deep anonymous functions,
    //    this is not this.
    private tinyInit: RawEditorSettings = {
        menubar: false,
        plugins: ['paste', 'variableTags', 'emoticons'],
        paste_as_text: true,
        toolbar: 'undo redo variableTagsButton previewTemplateButton emoticons',
        statusbar: false,
        forced_root_block: false,
        browser_spellcheck: true,
        contextmenu: false,
        setup: (editor: TinyEditor) => {
            editor.on('paste', (e) => {
                if (e.clipboardData) {
                    const data = e.clipboardData.getData('Text');
                    const availLength = TinymceUtils.calcAvailLength(editor, this.maxLength, true);

                    if (data.length > availLength) {
                        e.preventDefault();
                        e.stopPropagation();
                        alert('Paste Content Exceeds Max Character Limit.');
                        return false;
                    }
                }

                return true;
            });

            editor.on('keyDown', (e) => {
                const avail = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (avail < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });

            editor.on('keyUp', (e) => {
                const avail = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (avail < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });

            editor.on('change', (e) => {
                const availLength = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (availLength < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });

            /** Create a dropdown menu button for all the tags. **/
            editor.ui.registry.addMenuButton('variableTagsButton', {
                text: 'Variable Tags',
                tooltip: 'Insert A Variable Tag',
                fetch: function(callback) {
                    const items: Array<Record<any, any>> = [];
                    variableTagsGrouped.forEach((value: Array<TemplateVariable>, key: string) => {
                        // Get the sub menu item stuff first.
                        const subItems: Array<Record<any, any>> = [];
                        value.forEach((subItem) => {
                            subItems.push(
                                {
                                    type: 'menuitem',
                                    text: subItem.label ? subItem.label : subItem.tag,
                                    onAction: () => {
                                        editor.insertContent('{{ ' + subItem.tag + ' }}');
                                    }
                                }
                            );

                            if (subItem.label) {
                                variableMapping[subItem.tag] = subItem.label;
                            }
                        });

                        // Create main item and push it to the stack.
                        items.push({
                            type: 'nestedmenuitem',
                            text: key,
                            getSubmenuItems: () => {
                                return subItems;
                            }
                        });
                    });

                    callback(items);
                }
            });

            editor.ui.registry.addButton(
                'previewTemplateButton',
                {
                    icon: 'preview',
                    tooltip: 'Preview Template',
                    onAction: () => {
                        this.openClosePreview();
                    }
                }
            );
        },
        variable_mapper: variableMapping
    };

    /**
     * Whether brands are enabled.
     */
    get areBrandsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.BRANDS);
    }

    /**
     * Whether location groups are enabled.
     */
    get areLocationGroupsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    /**
     * Whether template groups are enabled.
     */
    get areTemplateGroupsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    /**
     * The brands that a template can be tied to.
     */
    get brands(): Array<Brand> {
        return brandsState.stored;
    }

    get breadcrumbs() {
        const crumbs = [
            {
                text: 'Automation',
                to: { name: 'automation' }
            }
        ];
        if (this.isTemplate) {
            crumbs.push({
                text: 'Message Templates',
                to: { name: 'message-templates' }
            });
        } else {
            crumbs.push({
                text: 'Reminders',
                to: { name: 'reminders' }
            });
        }
        return crumbs;
    }

    get isSuperUser() {
        return authState.isSuperuser;
    }

    get canDeleteTemplates(): boolean {
        const corpOnly = featuresStore.isFeatureEnabled(FeatureConstants.TEMPLATE_DELETE_CORP_ONLY);
        return corpOnly ? this.isCorpUser : true;
    }

    /**
     * Whether the fields directly under the name and subject line should appear.
     */
    get canSeeHierarchyFields(): boolean {
        if (!this.isTemplate) {
            return false;
        }

        if (this.isSuperUser) {
            return true;
        }

        if (this.isTemplate && this.isCrmPlus && this.automationMessageTemplatesPermissionGrant) {
            return true;
        }

        return !this.isTemplate && this.isCrmPlus && this.automationRemindersPermissionGrant;
    }

    get center() {
        return appState.storedCurrentCenter;
    }

    get currentOrg() {
        return appState.storedCurrentOrg;
    }

    get dateEdited(): string {
        if (!this.messageTemplate) {
            return '';
        }
        return this.messageTemplate.last_edited_datetime
            ? this.formatDate(this.messageTemplate.last_edited_datetime, this.timezone)
            : 'Never';
    }

    get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    get hasCommunicationTypes(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    /**
     * The items to populate in the hierarchy type list.
     */
    private get hierarchyItems() {
        const items = [];
        for (const item of HIERARCHY_TYPES_LIST) {
            switch (item.id) {
            case HIERARCHY_TYPE_BRAND_ID:
                if (this.areBrandsEnabled) {
                    items.push(item);
                }
                break;
            case HIERARCHY_TYPE_LOCATION_ID:
                items.push(item);
                break;
            case HIERARCHY_TYPE_LOCATION_GROUP_ID:
                if (this.areLocationGroupsEnabled) {
                    items.push(item);
                }
                break;
            }
        }
        return items;
    }

    get isCorpUser() {
        return authState.isCorporateUser;
    }

    get isPreviewOnly(): boolean {
        if (!this.isCrmPlus) {
            return !this.isCorpUser;
        }
        if (this.isTemplate && this.id !== undefined) {
            // The user must have the permission and be able to access the template's org
            return !(this.automationMessageTemplatesPermissionGrant && this.canUserAccessOrg);
        }

        return !this.isTemplate && this.id !== undefined && !this.automationRemindersPermissionGrant;
    }

    get lastEditedMessage(): string {
        return `Last modified ${this.dateEdited} by ${this.staffName}`;
    }

    /**
     * The orgs that a template can be tied to.
     */
    get orgs(): Array<Org> {
        return orgsState.stored;
    }

    get previewSubject(): string {
        return 'Text ' + (this.isTemplate ? 'Template' : 'Reminder') + ' Preview';
    }

    get staffName(): string {
        if (!this.messageTemplate) {
            return '';
        }
        return this.messageTemplate.last_edited_by_staff
            ? `${this.messageTemplate.last_edited_by_staff.values.first_name} ${this.messageTemplate.last_edited_by_staff.values.last_name}`
            : 'Unknown';
    }

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    @Watch('textTemplate', { immediate: true })
    private getAvailLength() {
        this.availLength = TinymceUtils.calcAvailLength(tinymce.activeEditor, this.maxLength, true);
    }

    @Watch('availLength', { immediate: true })
    private availLengthColorize() {
        if (this.availLength > this.maxLength * 0.1) {
            this.availCountClass = 'avail_count_good';
        } else if (this.availLength > this.maxLength * 0.01) {
            this.availCountClass = 'avail_count_warn';
        } else {
            this.availCountClass = 'avail_count_error';
        }
    }

    @Watch('previewOpen')
    public async previewTemplate(value: boolean) {
        loadingState.loadingIncrement(this.previewLoadingKey);

        if (value) {
            // Get the HTML content of the template, and convert to text for the API.
            const body = StringUtils.cleanContentForTextApi(
                StringUtils.convertHtmlVariableTagsToText(tinymce.activeEditor.getContent())
            );

            // Make sure we don't have weirdness in the display.
            try {
                const preview = await textTemplatesRepository.generatePreview(
                    body,
                    this.center ? this.center.id : null,
                    this.currentOrg ? this.currentOrg.id : null
                );
                this.previewBody = StringUtils.removeSpecialUTF8(preview.body);
            } catch (e) {
                loadingState.loadingDecrement(this.previewLoadingKey);
                this.closePreview();
            }
        }
        loadingState.loadingDecrement(this.previewLoadingKey);
    }

    // Life Cycle Hooks
    async mounted() {
        await this.loadData();
    }

    async loadData() {
        loadingState.loadingIncrement(this.loadingKey);

        await featuresStore.init();
        this.automationMessageTemplatesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationMessageTemplates);
        this.automationRemindersPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationReminders);
        // Determine from the route if we're modifying a template or a reminder
        this.isTemplate = this.$route.name === 'template-editor-text';
        // Load more data from the API if this is a template and not a reminder

        if (this.canSeeHierarchyFields) {
            const promises = [];
            const orgsResponse = orgsState.init();
            promises.push(orgsResponse);
            if (this.areTemplateGroupsEnabled) {
                const templateGroupsResponse = crmTypesState.initList(CrmTypeList.TEMPLATE_GROUPS_TEXT);
                promises.push(templateGroupsResponse);
            }
            if (this.areLocationGroupsEnabled) {
                const locationGroupsResponse = crmTypesState.initList(CrmTypeList.LOCATION_GROUPS);
                promises.push(locationGroupsResponse);
            }
            if (this.areBrandsEnabled) {
                const brandsResponse = brandsState.init();
                promises.push(brandsResponse);
            }
            if (this.hasCommunicationTypes) {
                promises.push(crmTypesState.initList(CrmTypeList.COMMUNICATION_TYPES));
            }
            await Promise.all(promises);

            this.locationGroups = crmTypesState.listOptions(CrmTypeList.LOCATION_GROUPS);
            this.templateGroups = crmTypesState.listOptions(CrmTypeList.TEMPLATE_GROUPS_TEXT);
        }

        // Load in the template.
        if (this.id !== undefined) {
            if (this.isTemplate) {
                this.messageTemplate = await textTemplatesRepository.getOne(this.id as number);
                this.copiedMessageTemplate = cloneDeep(this.messageTemplate);
                this.messageTemplateDto = templateMapper.toUpdateDto(this.messageTemplate as MessageTemplate);
                this.canUserAccessOrg = await orgsUtil.canUserAccessOrg(this.messageTemplateDto.org);
                if (this.hasCommunicationTypes && this.communicationTypeOptions.length === 0) {
                    this.communicationTypeOptions = crmTypesState.listOptions(CrmTypeList.COMMUNICATION_TYPES).filter(option => option.id !== CommunicationTypesConstants.URGENT);
                    const { communication_type: { id: communicationTypeId } } = this.messageTemplate;
                    this.communicationType = communicationTypeId;
                }
            } else {
                this.canUserAccessOrg = true;
                this.messageTemplate = await textRemindersRepository.getOne(this.id as number);
                this.messageTemplateDto = {
                    content: this.messageTemplate.content,
                    name: this.messageTemplate.name
                } as TextReminderDto;
            }
            this.textTemplate = this.messageTemplate.content ?? '';
        }

        if (!this.isPreviewOnly) {
            tinymce.activeEditor.mode.set('design');
        } else {
            tinymce.activeEditor.mode.set('readonly');
        }

        // Get variables, group them by their grouping.
        const variableTags = await variableTagsRepository.get();
        variableTagsGrouped = groupTemplateVariables(variableTags.entities);

        this.availLength = TinymceUtils.calcAvailLength(tinymce.activeEditor, this.maxLength, true);

        loadingState.loadingDecrement(this.loadingKey);
    }

    // Preview 'modal' open / close stuff.
    public openClosePreview(): void {
        if (this.previewOpen) {
            this.closePreview();
        } else {
            this.previewOpen = true;
        }
    }

    public closePreview(): void {
        this.previewOpen = false;
        this.previewBody = '';
    }

    /**
     * Whether the db is in crm plus mode.
     */
    get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        if (this.isPreviewOnly) {
            await this.$router.push({ name: this.isTemplate ? 'message-templates' : 'reminders' });
            return;
        }

        if (tinymce.activeEditor.getContent().length < 1 || !this.messageTemplate) {
            loadingState.loadingDecrement(this.loadingKey);
            await this.$swal({
                title: 'Empty Template',
                text: 'Please add content to this template before saving',
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: false
            });
            return;
        }

        if (this.isTemplate) {
            const messageTemplate = this.messageTemplateDto as TextTemplateUpdateDto;
            messageTemplate.communication_type = this.communicationType ?? CommunicationTypesConstants.MARKETING;
            this.messageTemplateDto = messageTemplate;
        }

        this.canSave = false;
        const utilsResult = await utils.save(
            tinymce.activeEditor.getContent(),
            this.messageTemplate,
            this.messageTemplateDto as TextTemplateUpdateDto | TextReminderDto
        );
        if (utilsResult !== null) {
            this.messageTemplate = utilsResult;
            if (!this.isTemplate) {
                // Refresh the reminders in the store
                await textRemindersState.retrieveAll();
            } else {
                await textTemplateState.clear();
            }
            await this.$router.push({ name: this.isTemplate ? 'message-templates' : 'reminders' });
        }
        this.canSave = true;

        loadingState.loadingDecrement(this.loadingKey);
    }

    public async deleteTemplate() {
        this.$swal({
            title: 'Are you sure?',
            text: 'Deleted templates cannot be recovered!',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);

                try {
                    await textTemplatesRepository.delete(this.id as number);
                    textTemplateState.removeEntityWithId(Number(this.id));
                    loadingState.loadingDecrement(this.loadingKey);
                    await this.$router.push({ name: 'message-templates' });
                } catch (e) {
                    // Do nothing, since an error should be show, but don't block everything!
                    loadingState.loadingDecrement(this.loadingKey);
                }
            }
        });
    }

    private async reloadEditor() {
        await this.loadData();
    }
}
