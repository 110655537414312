



























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { VEmojiPicker } from 'v-emoji-picker';
import { EventTypes } from '@/constants/event-type-constants';
import { Emoji } from 'v-emoji-picker/lib/models/Emoji';

@Component({ components: { VEmojiPicker } })
export default class EmojiSelect extends Mixins(LocaleMixin) {
    @Prop() isOpen!: boolean;
    @Prop() value!: string;

    get emoji() {
        return this.value;
    }

    set emoji(emoji: string) {
        this.$emit(EventTypes.INPUT, emoji);
    }

    cancel() {
        this.emoji = '';
        this.$emit(EventTypes.CLOSE);
    }

    emojiSelected(emoji: Emoji) {
        this.emoji = emoji.data;
        this.$emit(EventTypes.CLOSE);
    }
}
