















import type { GroupCommHistory } from '@/communications/messages/models/message';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';

@Component({
    components: {}
})
export default class GroupHistoryPreview extends Mixins(LocaleMixin) {
    /**
     * The group history to show stats for.
     */
    @Prop({ required: true }) readonly history!: GroupCommHistory;

    private previewBody = '';
    private previewSubject = '';

    private created() {
        this.previewBody = this.history.content;
        this.previewSubject = this.history.subject ?? 'Group Text Preview';
    }
}
