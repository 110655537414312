import { User } from '@/staff/models/user';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { whiteLabelColors } from '@/utils/color-utils';

const interfaceSettingsStore = getModule(InterfaceSettingsStore);
const featureStore = getModule(FeaturesStore);
export const colors: string[] = ['#AC7BA6', '#EFBF9E', '#00C2CB', '#03989E', '#330867', '#004369', '#545454'];
export const lineLeaderColors: string[] = ['#F89B05', '#43C759', '#5AC8FA', '#197AFF', '#5856D6', '#F53B30', '#AC52DB'];

export interface AvatarValues {
    initials: string;
    bgColor: string;
}

export function getAvatarInitialsFromName(firstName: string, lastName: string): string {
    return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
}

export function getAvatarInitialsFromUser(userInfo?: User): string {
    if (userInfo) {
        return getAvatarInitialsFromName(userInfo.first_name, userInfo.last_name);
    }
    return '';
}

/***
 * Select avatar background from the set of constant colors by by using the modulus of guardian id with length of the color array
 * @param userId
 */
export function getAvatarBackgroundFromUser(userId?: number): string {
    if (userId) {
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL) && interfaceSettingsStore.hasWhiteLabel) {
            return whiteLabelColors[userId % whiteLabelColors.length];
        }
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
            return lineLeaderColors[userId % lineLeaderColors.length];
        }
        return colors[userId % colors.length];
    }
    return '';
}

/**
 * Given a string, return a unique number
 * @param input
 */
function getHashNumber(input: string): number {
    let hash = 0; const len = input.length;
    for (let i = 0; i < len; i++) {
        hash = ((hash << 5) - hash) + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
    }
    return hash;
}

export function getAvatarBackgroundFromString(input: string): string {
    if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL) && interfaceSettingsStore.hasWhiteLabel) {
        return whiteLabelColors[getHashNumber(input) % whiteLabelColors.length];
    }
    if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
        return lineLeaderColors[getHashNumber(input) % lineLeaderColors.length];
    }
    return colors[getHashNumber(input) % colors.length];
}
