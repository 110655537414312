


































































import { OutgoingEmail } from '@/communications/messages/models/email';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { getSecondaryGuardianFullName } from '@/families/families-utils';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import type { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';
import type { Change } from '../models/update';
import { ChangeType } from '@/families/models/update';
import ViewEventsHistory from '@/families/components/ViewEventsHistory.vue';
import ViewTasksHistory from '@/families/components/ViewTasksHistory.vue';
import ViewChangesHistory from '@/families/components/ViewChangesHistory.vue';
import { EventsRepository } from '@/families/events/repositories/events-repository';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { Task, TaskGroups } from '@/tasks/models/task-models';
import { Event } from '@/families/events/models/event-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeField, CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { MarketingCampaignsStore } from '@/marketing/stores/marketing-campaigns-store';
import BaseClose from '@/components/base/BaseClose.vue';

const emailsRepo = new EmailsRepository();
const eventsRepo = new EventsRepository();
const taskRepo = new TasksRepository();
const loadingState = getModule(LoadingStore);
const typesState = getModule(CrmTypesStore);
const marketingCampaignsState = getModule(MarketingCampaignsStore);
const statusesState = getModule(StatusesStore);

@Component({
    components: {
        BaseClose,
        ViewEventsHistory,
        ViewTasksHistory,
        ViewChangesHistory
    }
})
export default class ViewDetailsHistoryModal extends Mixins(FamilyHubMixin, LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly change!: Change;
    @Prop() family!: Family;
    @Prop() readonly type!: string;
    @Prop() readonly typeId!: number;
    @Prop() readonly user!: string;
    @Prop({ type: String, required: true }) readonly guardianName!: string;

    private emailItem: OutgoingEmail | null = null;
    private eventItem: Event | null = null;
    private taskItem: Task | null = null;
    private loadingKey = 'history';
    private typeOptions: Array<CrmTypeOption> = [];
    private statuses: Array<Status> = [];

    private get guardianNames(): string {
        if (this.emailItem?.cc_secondary_guardian) {
            const secondaryGuardianName = getSecondaryGuardianFullName(this.family, false);
            if (secondaryGuardianName) {
                return `${this.guardianName} and ${secondaryGuardianName}`;
            }
        }
        return this.guardianName;
    }

    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    @Watch('modelValue')
    private async generateType() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);

            if (this.type === ChangeType.TASK) {
                // Get task
                this.taskItem = await taskRepo.getOne(this.typeId);
            } else if (this.type === ChangeType.EVENT) {
                // Get event
                this.eventItem = await eventsRepo.getOne(this.typeId);
                if (this.eventItem?.email) {
                    this.emailItem = await emailsRepo.getOutgoing(this.eventItem.email.id);
                }
            } else {
                // Load all type options
                const typesPromise = typesState.initList(CrmTypeList.ALL);
                await typesPromise;
                this.typeOptions = typesState.listOptions(CrmTypeList.ALL);

                // Load all status options
                const statuesPromise = statusesState.init();
                await statuesPromise;
                this.statuses = statusesState.statuses;

                // Make sure there is no bad data, so filter out where field is null
                this.change.field_changes = this.change.field_changes.filter((field_change) => {
                    return field_change.field !== null;
                });
                for (const field_change of this.change.field_changes) {
                    const fromValue = parseInt(field_change.from);
                    const toValue = parseInt(field_change.to);

                    // Convert to type option from id to text
                    if (field_change.field.values.type === CrmTypeField.SELECT_LIST ||
                        field_change.field.values.type === CrmTypeField.STATE_SELECT_LIST) {
                        if (!isNaN(fromValue)) {
                            field_change.from = this.typeOptions.find(e => e.id === fromValue)?.value as string;
                        }
                        if (!isNaN(toValue)) {
                            field_change.to = this.typeOptions.find(e => e.id === toValue)?.value as string;
                        }
                    }

                    if (field_change.field.values.type === CrmTypeField.MARKETING_CAMPAIGN) {
                        const campPromise = marketingCampaignsState.init();
                        await campPromise;
                        if (!isNaN(fromValue)) {
                            field_change.from = marketingCampaignsState.stored.find(e => e.id === fromValue)?.name as string;
                        }
                        if (!isNaN(toValue)) {
                            field_change.to = marketingCampaignsState.stored.find(e => e.id === toValue)?.name as string;
                        }
                    }

                    // Convert to status option from id to text
                    // Status is a select list, but its options are included in status store instead of type store so I have to check it separately
                    if (field_change.field.values.type === CrmTypeField.STATUS ||
                        field_change.field.values.type === CrmTypeField.CHILD_STATUS ||
                        field_change.field.values.type === CrmTypeField.PRIMARY_GUARDIAN_STATUS) {
                        if (!isNaN(fromValue)) {
                            field_change.from = this.statuses.find(e => e.id === fromValue)?.name as string;
                        }
                        if (!isNaN(toValue)) {
                            field_change.to = this.statuses.find(e => e.id === toValue)?.name as string;
                        }
                    }

                    // Convert from milliseconds to date
                    // It needs to * 1000 since the API returns milliseconds missing three 0s
                    if (field_change.field.values.type === CrmTypeField.DATE) {
                        if (!isNaN(fromValue)) {
                            field_change.from = fromValue !== 0 ? this.formatDate(new Date(fromValue * 1000)) : '';
                        }
                        if (!isNaN(toValue)) {
                            field_change.to = toValue !== 0 ? this.formatDate(new Date(toValue * 1000)) : '';
                        }
                    }
                }
            }
            loadingState.loadingDecrement(this.loadingKey);
        } else {
            this.emailItem = null;
        }
    }

    private handleClose() {
        this.modelValue = false;
    }

    private handleEdit() {
        this.modelValue = false;
        this.$emit(EventTypes.TASK_EDIT, this.taskItem);
    }

    private handleEditEvent() {
        this.modelValue = false;
        this.$emit(EventTypes.EVENT_EDIT, this.eventItem);
    }

    private get typeTitle(): string {
        if (this.type === ChangeType.TASK && this.taskItem) {
            if (this.taskItem.group.id === TaskGroups.MEETINGS) {
                return 'Meeting';
            }
            if (this.taskItem.group.id === TaskGroups.TOURS) {
                return 'Tour';
            }
            return 'Task';
        } else if (this.type === ChangeType.EVENT) {
            return 'Event';
        } else {
            return 'Changes';
        }
    }
}
