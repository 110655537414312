export class SearchUtils {
    /**
     * Do all the tokens match one of the strings in haystack?
     * @param tokens
     * @param haystack
     */
    static searchStrings(tokens: Array<string>, haystack: Array<string>): boolean {
        for (const searched of haystack) {
            let match = true;
            for (const token of tokens) {
                if (!searched.toLowerCase().includes(token.toLowerCase())) {
                    match = false;
                    break;
                }
            }
            if (match) {
                return true;
            }
        }
        return false;
    }
}
