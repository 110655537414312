import { CustomFieldsRepository } from '@/crm-types/custom-fields/repositories/custom-fields-repository';
import { getModule } from 'vuex-module-decorators';
import { CustomFieldSettingsStore } from '@/crm-types/custom-fields/stores/custom-field-settings-store';
import { CustomFieldsTypes } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldValuesRepository } from '@/crm-types/custom-fields/repositories/custom-field-values-repository';

/**
 * A service to handle saving changes to custom fields and their values.
 */
export default class CustomFieldsService {
    private customFieldsRepository = new CustomFieldsRepository();
    private customValuesRepository = new CustomFieldValuesRepository();
    private customFieldsStore = getModule(CustomFieldSettingsStore);

    public cancelChanges() {
        this.customFieldsStore.clearState();
    }

    /**
     * This function saves all the queued changes.
     * It does so by building an array of promises to wait for so we can at least do them in parallel.
     */
    public async save() {
        const promises: Array<Promise<any>> = [];

        // New fields with new values have to be added serially because we need the new field's id
        for (const field of this.customFieldsStore.addedFields) {
            if (!field.label.length) {
                continue;
            }
            // Do we have values to add for this new field?
            const valueAdds = this.customFieldsStore.addedValues.get(field);

            if (valueAdds === undefined && field.type !== CustomFieldsTypes.TEXT) {
                // Do not add custom fields that lack values if they require them
                continue;
            }
            const newField = await this.customFieldsRepository.addField(field);

            if (valueAdds !== undefined) {
                for (const valueAdd of valueAdds) {
                    if (valueAdd.value.length > 0) {
                        promises.push(this.customValuesRepository.addValueToField(newField.id, valueAdd));
                    }
                }
            }
        }
        // await each separate section to avoid deadlocks in the API
        await Promise.all(promises);

        for (const field of this.customFieldsStore.updatedFields) {
            if (!this.customFieldsStore.deletedFields.has(field.id) && field.label.length > 0) {
                promises.push(this.customFieldsRepository.updateField(field));
            }
        }
        await Promise.all(promises);

        for (const fieldId of this.customFieldsStore.deletedFields) {
            promises.push(this.customFieldsRepository.deleteField(fieldId));
        }
        await Promise.all(promises);

        for (const [field, values] of this.customFieldsStore.addedValues) {
            if (!field.id) {
                // new values for new groups are added when the field is added
                continue;
            }
            for (const value of values) {
                if (!this.customFieldsStore.deletedFields.has(field.id) && value.value.length > 0) {
                    promises.push(this.customValuesRepository.addValueToField(field.id, value));
                }
            }
        }
        await Promise.all(promises);

        for (const [field, values] of this.customFieldsStore.updatedValues) {
            if (!field.id) {
                continue;
            }
            for (const value of values) {
                if (!this.customFieldsStore.deletedFields.has(field.id) && value.value.length > 0) {
                    promises.push(this.customValuesRepository.updateValueForField(field.id, value));
                }
            }
        }
        await Promise.all(promises);

        for (const [field, values] of this.customFieldsStore.deletedValues) {
            if (!field.id) {
                continue;
            }
            for (const value of values) {
                promises.push(this.customValuesRepository.deleteValueFromField(field.id, value));
            }
        }
        await Promise.all(promises);

        // Clear our state now
        this.customFieldsStore.clearState();
    }
}
