import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Feature } from '@/features/features-models';
import { FeaturesRepository } from '@/features/features-repository';
import { FeatureConstants } from '@/features/feature-constants';

export interface FeaturesState extends AbstractEntityState<Feature> {
    entities: Array<Feature>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FEATURES
})
export class FeaturesStore extends AbstractApiStore<Feature> implements FeaturesState {
    readonly repository = new FeaturesRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Feature> {
        return this.entities;
    }

    public get isActuallyCrm() {
        return this.isFeatureEnabled(FeatureConstants.CRM_MODE) && !this.isFeatureEnabled(FeatureConstants.LEGACY_DUAL_MODE);
    }

    public get isFeatureEnabled() {
        return (featureId: FeatureConstants): boolean => {
            if (this.entities !== null) {
                for (const feature of this.entities) {
                    if (feature.identifier === featureId) {
                        return feature.is_active;
                    }
                }
            }

            return false;
        };
    }

    public get feature() {
        return (featureId: FeatureConstants): Feature | null => {
            if (this.entities !== null) {
                const result = this.entities.find(({ identifier }) => identifier === featureId);
                return result ?? null;
            }

            return null;
        };
    }

    /**
     * encapsulated drip campaigns check so it's easy to update
     */
    public get hasDripCampaigns() {
        return this.isFeatureEnabled(FeatureConstants.DRIP_CAMPAIGNS) && this.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) && !this.isFeatureEnabled(FeatureConstants.LEGACY_DUAL_MODE);
    }

    public get isLineLeaderEnroll() {
        return this.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    public get isLineLeaderManage() {
        return this.isFeatureEnabled(FeatureConstants.LINE_LEADER_MANAGE);
    }

    @Mutation
    public clear() {
        this.entities = [];
        this.mappedEntities.clear();
    }
}
