































































































import FamilySourceChart from '@/components/main/FamilySourceChart.vue';
import CompletedTasksChart from '@/dashboards/components/CompletedTasksChart.vue';
import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import TasksPastDueChart from '@/components/main/TasksPastDueChart.vue';
import DateRangeSelection from '@/components/main/DateRangeSelection.vue';
import { dashboardDateRanges } from '@/models/date-filter';
import OpportunitiesChart from '@/families/components/OpportunitiesChart.vue';
import InquiryPieChart from '@/dashboards/components/InquiryPieChart.vue';
import RecentlyModifiedFamilies from '@/dashboards/components/RecentlyModifiedFamilies.vue';
import GoToLegacy from '@/dashboards/views/GoToLegacy.vue';
import { StaffUtils } from '@/staff/staff-utils';
import { FeaturesStore } from '@/features/features-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import DashboardSettingsModal from '@/dashboards/components/DashboardSettingsModal.vue';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import { TabTitles } from '@/router/route-constants';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import Highcharts from 'highcharts';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import CorpGreeting from '@/dashboards/components/CorpGreeting.vue';
import ConversionSuccessGraphs from '@/dashboards/components/ConversionSuccessGraphs.vue';

const appState = getModule(AppStateStore);
const staffUtils = new StaffUtils();
const featuresStore = getModule(FeaturesStore);
const authState = getModule(AuthStore, store);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        ConversionSuccessGraphs,
        CorpGreeting,
        BasePageTitle,
        TasksPastDueChart,
        DateRangeSelection,
        CompletedTasksChart,
        OpportunitiesChart,
        FamilySourceChart,
        InquiryPieChart,
        RecentlyModifiedFamilies,
        GoToLegacy,
        DashboardSettingsModal
    }
})
export default class CorporateStaffDashboardMP extends Mixins(LocaleMixin, PageTitleMixin, CrmBreakpointsMixin) {
    private dateRanges = dashboardDateRanges;
    private canSeeSettings = false;
    private settingsComponent = DashboardSettingsModal;

    get org() {
        return appState.storedCurrentOrg;
    }

    get orgIds() {
        return this.org ? [this.org.id] : [];
    }

    get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isSuperUser() {
        return authState.isSuperuser;
    }

    async created() {
        this.canSeeSettings = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
    }

    async mounted() {
        // Update the tab title, since 'Add Tab' can't set it correctly.
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? TabTitles.DASHBOARD;
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = TabTitles.CORPORATE_DASHBOARD;
        layoutTabsStore.updateTab(outerTab);
        this.setPageTitle(`${this.$t('insight').toString()} ${this.$t('dashboard').toString()}`);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore-next-line
        Highcharts.wrap(Highcharts.Renderer.prototype, 'label', function(proceed: any, str: any, x: any, y: any, shape: any, anchorX: any, anchorY: any, useHTML: boolean) {
            if (/class="fa/.test(str)) {
                useHTML = true;
            }

            // Run original proceed method
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore-next-line
            return proceed.call(this, str, x, y, shape, anchorX, anchorY, useHTML);
        });
    }
}
