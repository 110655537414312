


























































































































































































































































































































































































































































































import { CommunicationTypes, CommunicationTypes as CommunicationTypesConstants } from '@/communications/communication-constants';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { EventTypes } from '@/constants/event-type-constants';
import {
    getFamilyCell,
    getFamilyEmail,
    getSecondaryGuardian,
    getSecondaryGuardianFullName,
    isDoNotEmail,
    isDoNotText
} from '@/families/families-utils';
import { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AbstractFile } from '@/models/files';
import { EmailCreateBase, EmailCreateDto, GroupEmailDto } from '@/communications/messages/models/email';
import { CreateTextBase, CreateTextDto, GroupTextDto } from '@/communications/messages/models/text';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    formatClockTime,
    formatIsoDateTime,
    getTimeZoneOptions,
    isDateInPast,
    newDateForDatePicker
} from '@/date-time/date-time-utils';
import EmailAttachments from '@/communications/messages/components/EmailAttachments.vue';
import EmailContentEditor from '@/communications/messages/components/EmailContentEditor.vue';
import TextContentEditor from '@/communications/messages/components/TextContentEditor.vue';
import TemplateSelect from '@/communications/templates/components/TemplateSelect.vue';
import FamilySearch from '@/families/components/FamilySearch.vue';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import store from '@/store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { VForm } from '@/types/types';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { AppStateStore } from '@/store/app-state-store';
import TemplateService from '@/communications/templates/template-service';
import BaseClose from '@/components/base/BaseClose.vue';
import { MarketingSubscriptionTypes } from '@/marketing/models/marketing-model';
import { MarketingCampaignsStore } from '@/marketing/stores/marketing-campaigns-store';
import { ApplyMarketingCampaignDto, MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsRepository } from '@/marketing/marketing-campaigns-repository';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { TimezoneOption } from '@/core/timezones/timezone';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const centersStore = getModule(CentersStore);
const crmTypesStore = getModule(CrmTypesStore);
const emailsRepository = new EmailsRepository();
const featureStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const orgsStore = getModule(OrgsStore);
const textsRepository = new TextsRepository();
const marketingCampaignsStore = getModule(MarketingCampaignsStore);
const marketingCampaignsRepository = new MarketingCampaignsRepository();
const timezoneState = getModule(TimezonesStore);

@Component({
    components: {
        BaseClose,
        EmailAttachments,
        EmailContentEditor,
        FamilySearch,
        TextContentEditor,
        TemplateSelect,
        CenterAscendingStaffList
    }
})
export default class SendMessageModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() family: Family | undefined;
    @Prop() familyIncludedIds: Array<number> | undefined;
    @Prop() familyExcludedIds: Array<number> | undefined;
    @Prop() familyFilterId: number | undefined;
    @Prop() extraOrgId: number | undefined;
    @Prop({ required: false }) selectedType!: CommunicationTypes | undefined;
    @Prop({ default: true }) showActivator!: boolean;
    @Prop({ default: false }) activate!: boolean;
    @Prop() replyToId!: number | undefined;
    @Prop() defaultSubject!: string | undefined;
    @Prop() defaultBody!: string | undefined;
    @Ref('form') readonly form!: VForm;

    private showDialog = false;
    private updatedEvent = EventTypes.UPDATED;
    private ccAlternateEmail = false;
    private ccTo: number | null = null;
    private bccTo: number | null = null;
    private copySecondaryGuardians = true;
    private replyToOptions: Array<CrmTypeOption> = [];
    private fromEmailOptions: Array<CrmTypeOption> = [];
    private fromNameOptions: Array<CrmTypeOption> = [];
    private communicationTypeOptions: Array<CrmTypeOption> = [];
    private replyTo = 2400;
    private fromEmail = 0;
    private fromName = 0;
    private notifyDirector = false;
    // Used for controlling the button group
    private textType = CommunicationTypes.TEXT;
    private emailType = CommunicationTypes.EMAIL;
    private messageContent = '';
    private selectedTemplate: MessageTemplate | null = null;
    private attachments: Array<AbstractFile> = [];
    private emailSubject: string | undefined = '';
    private loadingKey = SendMessageModal.name;
    private messageSent = false; // controls showing snackbar
    private messageSentMessage = ''; // Message shown in the snackbar
    private messageType = this.textType;
    private key = 0;
    private searchedFamily: Family | null = null;
    private searchKey = 0;
    private familyOrgId: number | null = null;
    private communicationType: number | null = CommunicationTypesConstants.GENERAL;
    private communicationTypeDisabled = false;
    private campaigns: Array<MarketingCampaign> = [];
    private selectedCampaign = 0;
    private startDate = '';
    private startTime = '';
    private chosenTimezone = 'UTC';
    private timezoneOptions: Array<TimezoneOption> = [];

    private validForm = false;

    get centerId() {
        return this.familyToMessage?.center?.id ?? null;
    }

    /**
     * The label for the cc secondary guardian field when there is a single family to message.
     */
    get copySecondaryGuardianLabel(): string {
        if (this.familyToMessage) {
            return 'Copy ' + getSecondaryGuardianFullName(this.familyToMessage);
        }
        return '';
    }

    get isMini() {
        return appState.isMini;
    }

    get orgId() {
        return this.extraOrgId ?? appState.storedCurrentOrg?.id ?? null;
    }

    get staffCenterId() {
        if (this.familyToMessage?.primary_guardian.center_id) {
            return this.familyToMessage.primary_guardian.center_id;
        }

        return null;
    }

    get hasMarketingCampaigns(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    get hasAlternateEmail() {
        if (this.familyToMessage) {
            return this.familyToMessage?.primary_guardian.alternate_email;
        }
        return true;
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get hasCommunicationTypes(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    /**
     * Return if the family has a secondary guardian that can be messaged.
     */
    get familyHasSecondaryGuardian(): boolean {
        if (!this.familyToMessage) {
            return false;
        }
        const secondaryGuardian = getSecondaryGuardian(this.familyToMessage);
        return !!secondaryGuardian && secondaryGuardian?.email?.length > 0 && !secondaryGuardian.do_not_email;
    }

    async created() {
        const replyPromise = crmTypesStore.initList(CrmTypeList.REPLY_TOS);
        const emailFromPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM);
        const emailFromNamesPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM_NAMES);
        const communicationTypes = crmTypesStore.initList(CrmTypeList.COMMUNICATION_TYPES);
        const marketingCampaignsPromise = marketingCampaignsStore.initForOrgId(this.orgId ?? 1);
        const timezonesPromise = timezoneState.init();
        const promises = [
            replyPromise,
            emailFromPromise,
            emailFromNamesPromise,
            communicationTypes,
            marketingCampaignsPromise,
            timezonesPromise
        ];
        await Promise.all(promises);

        this.replyToOptions = crmTypesStore.listOptions(CrmTypeList.REPLY_TOS);
        this.fromEmailOptions = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM);
        this.fromNameOptions = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM_NAMES);
        this.communicationTypeOptions = crmTypesStore.listOptions(CrmTypeList.COMMUNICATION_TYPES).filter(option => option.id !== CommunicationTypes.URGENT);
        this.campaigns = marketingCampaignsStore.storedOrgCampaigns(this.orgId ?? 1).filter(c => c.is_active);
        this.timezoneOptions = getTimeZoneOptions(timezoneState.stored, new Date(), this.$i18n.locale);
        this.setDeliveryDateTime();
    }

    mounted() {
        this.setType();
    }

    private get contactInfo() {
        if (!this.familyToMessage) {
            return;
        }

        const name = `${this.familyToMessage.primary_guardian.first_name} ${this.familyToMessage.primary_guardian.last_name}`;

        let contact;
        if (this.messageType === this.textType) {
            contact = this.formatPhone(getFamilyCell(this.familyToMessage)?.number ?? '');
        } else {
            contact = `<${getFamilyEmail(this.familyToMessage)}>`;
        }

        return `${name} ${contact}`;
    }

    private get familyToMessage(): Family | null {
        return this.family ?? this.searchedFamily;
    }

    private get isEmail() {
        return this.messageType === this.emailType;
    }

    private get isEmailDisabled() {
        if (!this.familyToMessage) {
            return false;
        }

        return isDoNotEmail(this.familyToMessage);
    }

    private get isSendDisabled() {
        return (!this.familyToMessage && !this.familyFilterId) ||
            this.messageContent.length === 0 ||
            (this.isEmail && !this.emailSubject) ||
            this.startDate === '' ||
            this.startTime === '';
    }

    private get isTextDisabled() {
        if (!this.familyToMessage) {
            return false;
        }

        return isDoNotText(this.familyToMessage);
    }

    private get timezone() {
        return authState.userTimeZone;
    }

    get communicationTypeOptionsEnabled() {
        return this.communicationTypeOptions.filter(option => {
            const subscription = this.messageType === this.emailType ? this.family?.subscriptions.email : this.family?.subscriptions.text;
            const optionEnabled = subscription ? subscription[option.value.toLowerCase() as keyof MarketingSubscriptionTypes] : true;
            return optionEnabled ? option : null;
        });
    }

    @Watch('activate', { immediate: true })
    updateModalStatus() {
        this.$nextTick(() => {
            this.showDialog = this.activate;
            this.setType();
            this.setDeliveryDateTime();
            if (this.activate) {
                this.initialSetup();
            }
        });
    }

    /**
     * When the family is chosen, set the org id for them
     */
    @Watch('familyToMessage', { immediate: true })
    private async familySelected() {
        if (!this.familyToMessage) {
            return;
        }

        if (this.familyToMessage.center) {
            const center = await centersStore.getById(this.familyToMessage.center.id);
            if (center) {
                const org = await orgsStore.getOrgById(center.organization_id);
                this.familyOrgId = org?.id ?? null;
            }
        }
    }

    @Watch('messageType')
    clearContents() {
        this.messageContent = '';
        this.selectedTemplate = null;
        this.emailSubject = '';
        ++this.searchKey;
    }

    @Watch('communicationTypeOptionsEnabled')
    setCommunicationType() {
        if (this.communicationTypeOptionsEnabled.length) {
            this.communicationType = this.communicationTypeOptionsEnabled[0].id;
        }
    }

    private initialSetup() {
        this.messageContent = '';
        this.emailSubject = '';
        this.selectedTemplate = null;
        this.attachments = [];
        this.searchedFamily = null;
        this.ccTo = null;
        this.bccTo = null;
        this.replyTo = 2400;
        this.fromName = 0;
        this.fromEmail = 0;
        this.ccAlternateEmail = false;
        this.notifyDirector = false;
        this.communicationTypeDisabled = false;
        this.selectedCampaign = 0;

        if (this.communicationTypeOptionsEnabled.length) {
            this.communicationType = this.communicationTypeOptionsEnabled[0].id;
        }
        if (this.defaultSubject) {
            this.emailSubject = this.defaultSubject;
        }

        if (this.defaultBody) {
            this.messageContent = this.defaultBody;
        }

        if (!this.familyToMessage) {
            this.familyOrgId = appState.storedCurrentOrg?.id ?? null;
        }
    }

    private setType() {
        if (this.selectedType) {
            this.messageType = this.selectedType;
        } else {
            this.messageType = this.isTextDisabled ? this.emailType : this.textType;
        }
    }

    private close() {
        this.showDialog = false;
        this.messageContent = '';
        this.emailSubject = '';
        this.selectedTemplate = null;
        this.attachments = [];
        this.searchedFamily = null;
        this.ccTo = null;
        this.bccTo = null;
        this.replyTo = 2400;
        this.fromName = 0;
        this.fromEmail = 0;
        this.ccAlternateEmail = false;
        this.notifyDirector = false;
        this.familyOrgId = null;
        ++this.key;
        this.$emit(EventTypes.CLOSE);
    }

    private async sendMessage() {
        if (!this.familyToMessage && !this.familyFilterId) {
            return;
        }

        if (this.familyFilterId && !this.extraOrgId) {
            return;
        }

        const sendDateTime = formatIsoDateTime(this.startDate + ' ' + this.startTime, this.chosenTimezone);
        const isInThePast = isDateInPast(sendDateTime);

        if (isInThePast) {
            this.showDialog = false;
            const result = await this.$swal({
                text: 'Warning--the delivery time entered is not set for the future. Do you want to send the message immediately?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            });

            if (result.isConfirmed) {
                await this.doSend(formatIsoDateTime(new Date(), this.timezone));
            } else {
                this.$nextTick(() => {
                    this.showDialog = true;
                });
            }
            return;
        }
        await this.doSend(sendDateTime);
    }

    private async doSend(sendDateTime: string) {
        loadingState.loadingIncrement(this.loadingKey);
        // If we get an error from the API, make sure we don't hang the screen.
        try {
            if (this.messageType === this.emailType) {
                const dtoBase: EmailCreateBase = {
                    html: this.messageContent,
                    subject: this.emailSubject ?? '',
                    reply_to_user: authState.userInfoObject!.id,
                    send_by_user: authState.userInfoObject!.id,
                    template: this.selectedTemplate ? this.selectedTemplate.id : null,
                    attachments: this.attachments.map(a => a.id), // Just the ids, please
                    send_date_time: sendDateTime,
                    communication_type: this.communicationType
                };

                if (this.familyToMessage) {
                    const dto: EmailCreateDto = {
                        ...dtoBase,
                        send_to_lead: this.familyToMessage.id
                    };

                    if (this.replyToId) {
                        dto.reply_to_inbound_message = this.replyToId;
                    }

                    if (this.ccTo) {
                        dto.cc_user = this.ccTo;
                    }

                    if (this.bccTo) {
                        dto.bcc_user = this.bccTo;
                    }
                    if (this.ccAlternateEmail) {
                        dto.cc_alternate_email = this.ccAlternateEmail;
                    }
                    if (this.copySecondaryGuardians) {
                        dto.cc_secondary_guardian = this.copySecondaryGuardians;
                    }
                    const newOutgoing = await emailsRepository.sendEmail(dto);

                    if (this.replyToId) {
                        this.$emit(EventTypes.REPLIED, newOutgoing, this.replyToId);
                    }
                } else {
                    const groupDto: GroupEmailDto = {
                        subject: this.emailSubject ?? '',
                        content: this.messageContent,
                        org_id: this.extraOrgId!,
                        reply_to_user: this.replyTo ? this.replyTo : 2400,
                        send_from_name: this.fromName ? this.fromName : 0,
                        send_from_user: this.fromEmail ? this.fromEmail : 0,
                        included_families: this.familyIncludedIds!,
                        excluded_families: this.familyExcludedIds!,
                        template: this.selectedTemplate ? this.selectedTemplate.id : null,
                        attachments: this.attachments.map(a => a.id), // Just the ids, please
                        send_date_time: sendDateTime,
                        notify_director: this.notifyDirector,
                        communication_type: this.communicationType
                    };

                    if (this.ccAlternateEmail) {
                        groupDto.cc_alternate_email = this.ccAlternateEmail;
                    }
                    if (this.copySecondaryGuardians) {
                        groupDto.cc_secondary_guardian = this.copySecondaryGuardians;
                    }

                    await emailsRepository.sendGroupEmail(this.familyFilterId!, groupDto);
                }

                this.messageSentMessage = 'Email Sent.';
            } else {
                const dtoBase: CreateTextBase = {
                    send_by_user: authState.userInfoObject!.id,
                    data: this.messageContent,
                    template: this.selectedTemplate ? this.selectedTemplate.id : null,
                    send_date_time: sendDateTime,
                    communication_type: this.communicationType
                };

                if (this.familyToMessage) {
                    const dto: CreateTextDto = {
                        ...dtoBase,
                        send_to_lead: this.familyToMessage.id
                    };

                    await textsRepository.sendText(dto);
                } else {
                    const groupDto: GroupTextDto = {
                        content: this.messageContent,
                        org_id: this.extraOrgId!,
                        included_families: this.familyIncludedIds!,
                        excluded_families: this.familyExcludedIds!,
                        template: this.selectedTemplate ? this.selectedTemplate.id : null,
                        send_date_time: sendDateTime,
                        notify_director: this.notifyDirector,
                        communication_type: this.communicationType
                    };

                    await textsRepository.sendGroupText(this.familyFilterId!, groupDto);
                }

                this.messageSentMessage = 'Text Sent.';
            }

            await this.applyMarketingCampaign();

            loadingState.loadingDecrement(this.loadingKey);
            this.messageSent = true;
            this.communicationTypeDisabled = false;
            this.$emit(EventTypes.MESSAGE_SENT);
            this.close();
        } catch (e) {
            loadingState.loadingDecrement(this.loadingKey);
            await this.$swal({
                text: 'An error occurred while trying to send the message.',
                icon: 'error'
            });
        }
    }

    private async templateSelected(template: MessageTemplate | null) {
        this.selectedTemplate = template;
        this.messageContent = '';
        this.emailSubject = '';
        this.attachments = [];
        this.communicationTypeDisabled = false;
        if (this.communicationTypeOptionsEnabled.length) {
            this.communicationType = this.communicationTypeOptionsEnabled[0].id;
        }
        if (template) {
            loadingState.loadingIncrement(this.loadingKey);
            const templateService = new TemplateService();

            // Get content -- list of templates does not include content
            const templateDetails = this.isEmail
                ? await templateService.getEmailTemplateDetails(template, this.familyToMessage)
                : await templateService.getTextTemplateDetails(template, this.familyToMessage);
            this.messageContent = templateDetails.messageContent;
            this.emailSubject = templateDetails.emailSubject;

            // Get attachment details from the template, if any
            // Needed for the file chips to have info about them
            if (this.isEmail && templateDetails.attachments) {
                this.attachments = templateDetails.attachments;
            }
            loadingState.loadingDecrement(this.loadingKey);
            const { communicationType: { id: communicationTypeId } } = templateDetails;
            this.communicationType = communicationTypeId;
            this.communicationTypeDisabled = true;
        }
    }

    private async applyMarketingCampaign() {
        if (!this.orgId || !this.selectedCampaign) {
            return;
        }

        const dto: ApplyMarketingCampaignDto = {
            family_ids: this.familyToMessage ? [this.familyToMessage.id] : this.familyIncludedIds ?? [],
            excluded_family_ids_from_group: this.familyExcludedIds ?? []
        };

        if (this.familyIncludedIds && this.familyIncludedIds.length === 0 && !this.familyToMessage) {
            delete dto.family_ids;
            dto.org_id = this.orgId;
            if (this.familyFilterId) {
                dto.group_id = this.familyFilterId;
            }
        }
        await marketingCampaignsRepository.applyToFamilies(this.selectedCampaign, dto);
    }

    private setDeliveryDateTime() {
        const foundTzOption = this.timezoneOptions.find(t => t.value === this.timezone);
        this.chosenTimezone = foundTzOption ? foundTzOption.value : this.timezoneOptions.length > 0 ? this.timezoneOptions[0].value : 'UTC';

        this.startDate = newDateForDatePicker();
        const now = new Date();
        now.setMinutes(now.getMinutes() + 15);
        this.startTime = formatClockTime(now);
    }

}
