import { EmailTemplateBlock, EmailTemplateBlockDto } from '@/communications/templates/models/email-template-block';
import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import * as chamaileon from '@/types/chamaileon-types';
import BlockUpdateEventPayload = chamaileon.BlockUpdateEventPayload;

/**
 * Mapper for creating DTOs for email template blocks.
 */
export class EmailTemplateBlockMapper implements AbstractMapper<EmailTemplateBlock> {
    toCreateDto(entity: EmailTemplateBlock): EmailTemplateBlockDto {
        return {
            content: JSON.stringify(entity.content) ?? '',
            label: entity.label ?? ''
        };
    }

    /**
     * Create a block object for Chamaileon from a block entity.
     *
     * @param entity
     */
    toBlockLibraryBlock(entity: EmailTemplateBlock): chamaileon.BlockObject {
        return {
            _id: entity.id.toString(),
            title: entity.label,
            body: entity.content
        };
    }

    toUpdateDto(entity: EmailTemplateBlock): EmailTemplateBlockDto {
        return this.toCreateDto(entity) as EmailTemplateBlockDto;
    }

    /**
     * Create the DTO for sending to the API from the block update payload.
     *
     * @param payload
     */
    toDtoFromBlockUpdatePayload(payload: BlockUpdateEventPayload): EmailTemplateBlockDto {
        return {
            content: JSON.stringify(payload.block.body),
            label: payload.block.title
        };
    }
}
