import { AbstractRepository, ApiPagination } from '@/repositories/abstract-repository';
import {
    FamiliesFilter,
    FamiliesFilterDtoInterface, FilterSortParameter
} from '@/filters/models/families-filter';

export class FamiliesFilterRepository extends AbstractRepository<FamiliesFilter> {
    readonly endpoint = 'groups';

    public async getFiltersForOrg(org: number, pagination: ApiPagination | null = null, sort: FilterSortParameter): Promise<{ count: number; filters: Array<FamiliesFilter> }> {
        const params = { org_id: org, no_anonymous: true };
        Object.assign(params, sort);
        const response = await this.get(pagination, params);
        return { count: response.count, filters: response.entities };
    }

    public async getFilterByName(org: number | null, name: string): Promise<FamiliesFilter | null> {
        const response = await this.client.get(this.endpoint, {
            params: {
                org_id: org,
                by_name: name
            }
        });
        if (response.data.length) {
            return response.data[0];
        }
        return null;
    }

    public async createFilter(dto: FamiliesFilterDtoInterface): Promise<FamiliesFilter> {
        const response = await this.client.post(this.endpoint, dto);
        return response.data;
    }

    public async updateFilter(filterId: number, dto: FamiliesFilterDtoInterface): Promise<FamiliesFilter> {
        const response = await this.client.put(this.endpoint + '/' + filterId, dto);
        return response.data;
    }

    public async deleteFilter(filterId: number): Promise<void> {
        await this.client.delete(this.endpoint + '/' + filterId);
    }
}
