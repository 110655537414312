import { CrmEntity, FileHateoasLink, HateoasLink, ValuedLink } from '@/models/base';
import { BasePhone } from '@/families/models/phone';
import { Address } from '@/families/models/address';
import { SortParameter } from '@/models/sort-parameter';
import { Brand } from '@/organizations/brands/models/brand-models';
import { SocialMediaLink } from '@/core/models/social-media-link';
import { ApiParameters } from '@/repositories/abstract-repository';

export interface LocationGroupLink extends HateoasLink {
    values: { value: string };
}

export interface LocationGroupAndCentersEntity {
    group: HateoasLink;
    centers: Array<HateoasLink>;
}

export interface Advisory {
    title: string;
    description: string;
}

export interface Detail {
    title: string;
    description: string;
    value: string;
}

export interface CenterLink extends HateoasLink {
    values: { name: string; code: string; short_name: string };
}

export interface CenterHours {
    mon_open: string | null;
    mon_close: string | null;
    tue_open: string | null;
    tue_close: string | null;
    wed_open: string | null;
    wed_close: string | null;
    thu_open: string | null;
    thu_close: string | null;
    fri_open: string | null;
    fri_close: string | null;
    sat_open: string | null;
    sat_close: string | null;
    sun_open: string | null;
    sun_close: string | null;
}

export interface CommunicationHours {
    mon: CommunicationHoursDay;
    tue: CommunicationHoursDay;
    wed: CommunicationHoursDay;
    thu: CommunicationHoursDay;
    fri: CommunicationHoursDay;
    sat: CommunicationHoursDay;
    sun: CommunicationHoursDay;
}

export interface CommunicationHoursDay {
    start: string | null;
    end: string | null;
}

export type CenterDayType = 'mon' | 'tue' | 'wed' | 'thu' | 'fri';
export const centerHoursWeekdays: Array<CenterDayType> = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri'
];

export interface Center extends CrmEntity {
    name: string;
    code: string;
    is_active: boolean;
    organization_id: number;
    avg_weekly_tuition: number | null;
    timezone: string;
    external_system_id: string;
    secondary_external_system_id?: string | null;
    email: string;
    url: string;
    capacity: number;
    contact: {
        phone: BasePhone;
        crm_phone: BasePhone;
        call_center_phone: BasePhone;
        address: Address;
    };
    status: ValuedLink | null;
    parent_organization_id?: number;
    brand: Brand | null;
    staff: CenterStaff | null;
    open_saturday: boolean;
    open_sunday: boolean;
    groups: Array<LocationGroupLink>;
    logo: FileHateoasLink | null;
    social_media: SocialMediaLink | null;
    hours: CenterHours;
    communication_hours: CommunicationHours;
    advisories: Array<Advisory>;
    details: Array<Detail>;
    variable_tags?: Array<string>;
}

export interface CenterHateoas extends HateoasLink {
    values: {
        name: string;
        code: string;
    };
}
export interface StaffHateoas extends HateoasLink {
    values: {
        username: string;
        first_name: string;
        last_name: string;
    };
}

export interface CenterStaff {
    director: StaffHateoas | null;
    assistant_director: StaffHateoas | null;
    administrator: StaffHateoas | null;
    family_rep: StaffHateoas | null;
    enrollment_rep: StaffHateoas | null;
}

export interface CenterTableData extends Center {
    active_status?: string;
    locality?: string;
    parent_organization_name?: string;
    phone?: string;
    postcode?: string;
    region?: string;
}

export interface CentersEnabled {
    center: Center;
    enabled: boolean;
}

export interface LocationGroup {
    group: ValuedLink;
    centers: Array<CenterLink>;
}

export interface CenterAgeRange {
    min: number;
    max: number;
}

export interface CenterFilters extends ApiParameters {
    has_availability_min?: number;
    has_availability_max?: number;
    offering_ids?: Array<number>;
    brand_ids?: Array<number>;
    only_accessible_centers?: boolean;
    is_referrable?: boolean;
    simple?: boolean;
    ids_only?: boolean;
    search?: string;
    inactive_only?: boolean;
}

export enum CenterSortKeys {
    LOCALITY = 'locality',
    NAME = 'name',
    PARENT_ORG = 'parent_organization_name',
    ADDRESS = 'address1',
    POSTCODE = 'postcode',
    REGION = 'region',
    STATUS = 'active_status',
    TIMEZONE = 'timezone',
    TUITION = 'avg_weekly_tuition'
}

export interface CenterSortParameter extends SortParameter {
    sort_keys: Array<CenterSortKeys>;
}

export interface CenterUpdateItem {
    id: number;
    section: string;
    date_time: string;
    staff?: StaffHateoas;
    center: CenterHateoas;
}

export interface CenterUpdates {
    info: CenterUpdateItem | null;
    rates: CenterUpdateItem | null;
    availability: CenterUpdateItem | null;
    details: CenterUpdateItem | null;
    offerings: CenterUpdateItem | null;
    ratings: CenterUpdateItem | null;
}
