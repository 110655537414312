import { ReportMappingObject } from '@/reports/models/reports';
import { REPORT_FORMATS, ReportFormatNames } from '@/reports/report-format-constants';
import {
    DataSource,
    Format,
    Hierarchy, Measure,
    Options,
    Pivot,
    Report,
    Slice,
    SliceHierarchy,
    SliceMeasure
} from 'flexmonster';
import { StandardReportType } from '@/reports/models/saved-report';
import { StandardReportIdentifier, ReportIdentifier } from '@/reports/report-constants';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Handles functionality to reconfigure a Flexmonster report object to allow for custom editing
 * outside of the built-in interfaces that it provides.
 */
export class ReportObjectUtils {
    /**
     * Apply formats to columns in the given report.
     *
     * @param reportIdentifier
     * @param report
     */
    public applyReportFormats(reportIdentifier: ReportIdentifier, report: Report) {
        const sliceMeasures: Array<SliceMeasure> = [];
        if (reportIdentifier in REPORT_FORMATS) {
            for (const columnFormat of REPORT_FORMATS[reportIdentifier]!) {
                sliceMeasures.push({
                    uniqueName: columnFormat.uniqueName,
                    aggregation: 'sum',
                    active: false,
                    format: columnFormat.formatName
                });
            }
        }

        if (report) {
            const slice = report.slice ?? { rows: [], columns: [] };
            const measures = slice.measures ?? [];
            measures.push(...sliceMeasures);
            slice.measures = measures;
            report.slice = slice;
        }
    }

    /**
     * Build array of slice hierarchies that if existed in the hashmap, added the results array
     *
     * @param rows
     * @param map
     * @param originalRows
     */
    public buildSliceWithMap(rows: Array<SliceHierarchy>, map: Map<string, SliceHierarchy>, originalRows: Array<SliceHierarchy>): Array<SliceHierarchy> {
        const results: Array<SliceHierarchy> = [];
        // use the original rows as a guide to sort the rows we got from the select fields modal
        const rowsCopy = cloneDeep(rows);
        rowsCopy.sort((a, b) => {
            let aIdx = originalRows.findIndex(row => row.uniqueName === a.uniqueName);
            if (aIdx < 0) {
                aIdx = 9999;
            }
            let bIdx = originalRows.findIndex(row => row.uniqueName === b.uniqueName);
            if (bIdx < 0) {
                bIdx = 9999;
            }
            if (aIdx === bIdx) {
                return 0;
            }
            return aIdx < bIdx ? -1 : 1;
        });
        for (const row of rowsCopy) {
            const hashMapItem = map.get(row.uniqueName);
            if (hashMapItem) {
                results.push(hashMapItem);
            } else {
                results.push(row);
            }
        }
        return results;
    }

    /**
     * Build a hash map to save any rows of Slice object that has other props than 'uniqueName' (e.g: filter, sort, etc.). This
     * is stored those rows and apply those props after selecting the fields
     *
     * @param slice
     */
    public buildMapFromSlice(slice: Slice): Map<string, SliceHierarchy> {
        const map: Map<string, SliceHierarchy> = new Map();
        if (slice && slice.rows) {
            for (const row of slice.rows) {
                if (Object.keys(row).length > 1 && !map.get(row.uniqueName)) {
                    map.set(row.uniqueName, row);
                }
            }
        }
        return map;
    }

    /**
     * Build and return an array of string from a basic slice object from reports. Currently used to handle multiple
     * selected checkboxes with v-model as an array of string
     *
     * @param slice
     */
    public buildArrayOfStringFromSlice(slice: Slice): Array<string> {
        const stringArr: Array<string> = [];
        if (slice) {
            if (slice.rows && slice.rows.length) {
                slice.rows.forEach((row) => {
                    stringArr.push(row.uniqueName);
                });
            }
        }
        return stringArr;
    }

    /**
     * Build and return a basic slice object to set for a report from an array of string. Currently used to handle multiple
     * selected checkboxes with v-model as an array of string
     *
     * @param stringArr
     */
    public buildSliceFromArrayOfString(stringArr: Array <string>): Slice {
        const slice = { flatOrder: [], rows: [] } as Slice;
        for (const item of stringArr) {
            slice.flatOrder!.push(item);
            slice.rows!.push({ uniqueName: item });
        }
        return slice;
    }

    /**
     * Build and return a basic slice object to set for a report.
     *
     * @param mappingObject
     */
    public buildSliceFromMapping(mappingObject: ReportMappingObject): Slice {
        const slice = { rows: [] } as Slice;
        for (const key of Object.keys(mappingObject)) {
            slice.rows!.push({ uniqueName: key });
        }
        return slice;
    }

    /**
     * Get the columns from the report (located in the slice object).
     *
     * @param reportPivot
     */
    public getColumns(reportPivot: Pivot): Array<Hierarchy> {
        return reportPivot.getColumns();
    }

    /**
     * Get the data source object from the report.
     *
     * @param reportPivot
     */
    public getDataSource(reportPivot: Pivot): DataSource | undefined {
        const report = this.getReport(reportPivot);
        return report?.dataSource;
    }

    /**
     * Get the array of unique name of row which have filters
     s
     * @param reportPivot
     */
    public getFilterNames(rows: Array<SliceHierarchy>): Array<string> {
        const results: Array<string> = [];
        for (const row of rows) {
            if (row.filter) {
                results.push(row.uniqueName);
            }
        }
        return results;
    }

    /**
     * Get the array of format objects from the report.
     *
     * @param reportPivot
     */
    public getFormats(reportPivot: Pivot): Array<Format> | undefined {
        const report = this.getReport(reportPivot);
        return report?.formats;
    }

    /**
     * Get the measures on the report (located in the slice object).
     *
     * @param reportPivot
     */
    public getMeasures(reportPivot: Pivot): Array<Measure> {
        return reportPivot.getMeasures();
    }

    /**
     * Get the options object from the report.
     *
     * @param reportPivot
     */
    public getOptions(reportPivot: Pivot): Options {
        return reportPivot.getOptions();
    }

    /**
     * Get the slice object from the report.
     *
     * @param reportPivot
     */
    public getSlice(reportPivot: Pivot): Slice | undefined {
        const report = this.getReport(reportPivot);
        return report?.slice;
    }

    /**
     * Get selected fields based on report view (graph, chart, etc.) and standard report types
     *
     * @param selectedType
     * @param standardReportType
     */
    public getSelectedFields(selectedType: string | StandardReportIdentifier | null, standardReportType: StandardReportType): Array<string> {
        let results: Array<string> = [];
        const slice = this.retrieveSliceForStandardReport(selectedType, standardReportType);
        if (slice) {
            results = this.buildArrayOfStringFromSlice(slice);
        }
        return results;
    }

    /**
     * Get the report object from the Flexmonster pivot object.
     *
     * @param reportPivot
     */
    public getReport(reportPivot: Pivot): Report | null {
        const report = reportPivot.getReport();
        return typeof report === 'string' ? null : report;
    }

    /**
     * Get the filters on the report (located in the slice object).
     *
     * @param reportPivot
     */
    public getReportFilters(reportPivot: Pivot): Array<Hierarchy> {
        return reportPivot.getReportFilters();
    }

    /**
     * Get slice based on report view (graph, chart, etc.) and standard report types
     *
     * @param selectedType
     * @param standardReportType
     */
    public retrieveSliceForStandardReport(selectedType: string | StandardReportIdentifier | null, standardReportType: StandardReportType | null): Slice | null {
        let selectedReport = null;
        if (selectedType && standardReportType) {
            selectedReport = standardReportType.reports.find(report => report.standard_report_identifier === selectedType);
        }
        if (selectedReport) {
            const reportData = JSON.parse(selectedReport.report_data);
            return reportData.slice;
        }
        return null;
    }

    /**
     * Set the data source object for the report.
     *
     * @param reportPivot
     * @param dataSource
     */
    public setDataSource(reportPivot: Pivot, dataSource: DataSource): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.dataSource = dataSource;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set a format for the report.
     *
     * @param reportPivot
     * @param format
     * @param measureName
     */
    public setFormat(reportPivot: Pivot, format: Format, measureName: string): void {
        reportPivot.setFormat(format, measureName);
    }

    /**
     * Set the array of format objects for the report.
     *
     * @param reportPivot
     * @param formats
     */
    public setFormats(reportPivot: Pivot, formats: Array<Format>): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.formats = formats;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set the options object for the report.
     *
     * @param reportPivot
     * @param options
     */
    public setOptions(reportPivot: Pivot, options: Options): void {
        reportPivot.setOptions(options);
    }

    /**
     * Set the slice object for the report.
     *
     * @param reportPivot
     * @param slice
     */
    public setSlice(reportPivot: Pivot, slice: Slice): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.slice = slice;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set the columns property for the report slice.
     *
     * @param reportPivot
     * @param columns
     */
    public setColumns(reportPivot: Pivot, columns: Array<SliceHierarchy>): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.slice!.columns = columns;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set the correct localized currency symbols for the currency format.
     *
     * @param report
     * @param currencySymbol
     * @param decimalSeparator
     * @param thousandsSeparator
     */
    public setCurrencyFormatting(
        report: Report,
        currencySymbol: string,
        decimalSeparator: string,
        thousandsSeparator: string
    ) {
        if (!report.formats) {
            return;
        }
        for (let i = 0; i < report.formats.length; i++) {
            if (report.formats[i].name && report.formats[i].name === ReportFormatNames.CURRENCY) {
                report.formats[i].currencySymbol = currencySymbol;
                report.formats[i].decimalSeparator = decimalSeparator;
                report.formats[i].thousandsSeparator = thousandsSeparator;
            }
        }
    }

    /**
     * Set the measures property for the report slice.
     *
     * @param reportPivot
     * @param measures
     */
    public setMeasures(reportPivot: Pivot, measures: Array<SliceMeasure>): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.slice!.measures = measures;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set the report filters property for the report slice.
     *
     * @param reportPivot
     * @param reportFilters
     */
    public setReportFilters(reportPivot: Pivot, reportFilters: Array<SliceHierarchy>): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.slice!.reportFilters = reportFilters;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Set the report rows property for the report slice.
     *
     * @param reportPivot
     * @param rows
     */
    public setSliceRows(reportPivot: Pivot, rows: Array<SliceHierarchy>): Report | null {
        const report = this.getReport(reportPivot);
        if (report) {
            report.slice!.rows = rows;
            reportPivot.setReport(report);
        }
        return report;
    }

    /**
     * Sort the selected fields the user wishes to see according to the mapping defaults.
     *
     * @param selectedFields
     * @param mappingObject
     */
    public sortSelectedFieldsFromMapping(selectedFields: Array<string>, mappingObject: ReportMappingObject): Array<string> {
        const selectedFieldsSet = new Set(selectedFields);
        return Object.keys(mappingObject).filter(key => selectedFieldsSet.has(key));
    }
}
