
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VBtn from 'vuetify/lib/components/VBtn/VBtn';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VBtn] });
export default base.extend({
    name: 'base-button-primary',
    props: {
        elevation: {
            type: Number,
            default: 0
        },
        outlined: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        'min-width': {
            type: [Number, String],
            default: 64
        },
        'min-height': {
            type: [Number, String],
            default: 36
        }
    },
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VBtn.options.computed.classes.call(this),
                'base-button': true,
                'base-button-action': true,
                'base-button-primary': true
            };
        }
    }
});
