import { CrmEntity, HateoasLink } from '@/models/base';
import { CrmTypeOption } from '@/crm-types/models/crm-type';

export interface CustomDashboard extends CrmEntity {
    type: number;
    name: string;
    sort_order: number;
    is_default: boolean;
    is_enabled: boolean;
    elements: Array<CustomDashboardElement>;
}

export interface CustomDashboardDto {
    name: string;
    sort_order: number;
    is_default: boolean;
    is_enabled: boolean;
    elements: Array<CustomDashboardElementDto>;
}

export interface DashElementType extends CrmTypeOption {
    identifier: string;
}

export interface CustomDashboardElement {
    identifier: DashElementIdentifier;
    saved_report: HateoasLink | null;
    standard_report_identifier: string | null;
    sort_order: number;
}

export interface CustomDashboardElementDto {
    identifier: DashElementIdentifier;
    saved_report: number | null;
    standard_report_identifier: string | null;
    sort_order: number;
}

export interface DashboardUiObject {
    id: number;
    type: number;
    name: string;
    is_default: boolean;
    is_enabled: boolean;
    elements: Array<DashboardElementUiObject>;
    actionTabs: Array<DashElementIdentifier>;
}

export interface DashboardElementUiObject {
    identifier: string;
}

export interface DashboardElementOption {
    id: string;
    name: string;
    identifier: DashElementIdentifier;
    saved_report: number | null;
    standard_report_identifier: string | null;
}

export const customDashboardType = 4;
export const actionDashboardType = 1;
export const insightDashboardType = 2;
export const enrollmentDashboardType = 3;

export enum DashElementIdentifier {
    CHILD_COUNTS = 'childCounts',
    TODO_TAB = 'toDo',
    CALENDAR = 'calendar',
    CHILD_STARTS = 'childStarts',
    AVAILABILITY = 'availability',
    RECENTLY_VIEWED = 'recentlyViewed',
    GREETING = 'greeting',
    CHILDREN_BY_STATUS = 'childrenByStatus',
    CONVERSION_SUCCESS = 'conversionSuccess',
    PAST_DUE = 'pastDue',
    COMPLETED = 'completed',
    INQUIRY_TYPES = 'inquiryTypes',
    FAMILY_SOURCE = 'familySource',
    ENROLLMENT_TODO = 'enrollmentToDo',
    ENROLLMENT_INBOX = 'enrollmentInbox',
    ENROLLMENT_LOCATION = 'enrollmentLocation',
    ENROLLMENT_MAP = 'enrollmentMap',
    STANDARD_REPORT = 'standardReport',
    CUSTOM_REPORT = 'customReport',
    ACTION_TABS_PLACEHOLDER = 'actionTabs',
}

export const elementIdentifierStandardReports = DashElementIdentifier.STANDARD_REPORT;
export const elementIdentifierCustomReports = DashElementIdentifier.CUSTOM_REPORT;

export const DashConstants = {
    actionDashTabs: [
        DashElementIdentifier.TODO_TAB,
        DashElementIdentifier.CALENDAR,
        DashElementIdentifier.AVAILABILITY,
        DashElementIdentifier.RECENTLY_VIEWED,
        DashElementIdentifier.CHILD_STARTS
    ],
    halfWidthElements: [
        DashElementIdentifier.CHILDREN_BY_STATUS,
        DashElementIdentifier.CONVERSION_SUCCESS,
        DashElementIdentifier.PAST_DUE,
        DashElementIdentifier.COMPLETED,
        DashElementIdentifier.INQUIRY_TYPES,
        DashElementIdentifier.FAMILY_SOURCE,
        DashElementIdentifier.CUSTOM_REPORT,
        DashElementIdentifier.STANDARD_REPORT
    ],
    dateRangeElements: [
        DashElementIdentifier.STANDARD_REPORT,
        DashElementIdentifier.CUSTOM_REPORT,
        DashElementIdentifier.GREETING,
        DashElementIdentifier.INQUIRY_TYPES,
        DashElementIdentifier.FAMILY_SOURCE,
        DashElementIdentifier.CHILDREN_BY_STATUS,
        DashElementIdentifier.CONVERSION_SUCCESS,
        DashElementIdentifier.PAST_DUE,
        DashElementIdentifier.COMPLETED
    ],
    mineOnlyElements: [
        DashElementIdentifier.TODO_TAB,
        DashElementIdentifier.CALENDAR
    ]
};
