import {
    LandingPageOptionExclusion, LandingPageOptionExclusionDto,
    LandingPageOptionExclusionPossibilities
} from '@/automation/landing-pages/models/landing-pages-models';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { getModule } from 'vuex-module-decorators';

export class LandingPageOptionExclusionsMapper {
    /**
     * Get the option ids to exclude in the list given the selected ids.
     *
     * @param selectedOptionIds
     * @param list
     */
    async getExcludedOptionIdsFromList(
        selectedOptionIds: Array<number>,
        list: CrmTypeList
    ): Promise<Array<number>> {
        const crmTypesStore = getModule(CrmTypesStore);
        switch (list) {
            case CrmTypeList.FAMILY_SOURCE: {
                await crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);

                return crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE)
                    .map(option => option.id)
                    .filter((optionId) => {
                        return !selectedOptionIds.includes(optionId);
                    });
            }
            default:
                break;
        }
        return [];
    }

    /**
     * Get the exclusions dto to send in the API PUT request.
     *
     * @param familySources
     * @param others
     */
    getExclusionsDtoFromExcludedOptionIds(familySources: Array<number>, others: Array<number>): LandingPageOptionExclusionDto {
        return {
            ids: Array.from([...familySources, ...others])
        };
    }

    /**
     * Get the options to include in the list given the exclusions.
     *
     * @param exclusionsMap
     * @param list
     */
    async getIncludedOptionsForList(
        exclusionsMap: Map<LandingPageOptionExclusionPossibilities, Array<LandingPageOptionExclusion>>,
        list: CrmTypeList
    ): Promise<Array<number>> {
        const crmTypesStore = getModule(CrmTypesStore);
        switch (list) {
            case CrmTypeList.FAMILY_SOURCE: {
                if (!exclusionsMap.has(CrmTypeList.FAMILY_SOURCE)) {
                    break;
                }
                const excludedOptionIds = exclusionsMap.get(CrmTypeList.FAMILY_SOURCE)!.map(option => option.exclusion.id);
                await crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);

                return crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE)
                    .map(option => option.id)
                    .filter((optionId) => {
                        return !excludedOptionIds.includes(optionId);
                    });
            }
            default:
                break;
        }
        return [];
    }

    /**
     * Map the exclusions into a map with the list type as the key and the exclusions array as the value.
     *
     * @param exclusions
     */
    mapExclusions(exclusions: Array<LandingPageOptionExclusion>): Map<LandingPageOptionExclusionPossibilities, Array<LandingPageOptionExclusion>> {
        const exclusionsMap: Map<LandingPageOptionExclusionPossibilities, Array<LandingPageOptionExclusion>> = new Map();
        const sources: Array<LandingPageOptionExclusion> = [];
        const others: Array<LandingPageOptionExclusion> = [];
        let href = '';
        for (const exclusion of exclusions) {
            href = exclusion.exclusion.links[0].href;
            if (href.includes('types/' + CrmTypeList.FAMILY_SOURCE)) {
                sources.push(exclusion);
            } else {
                others.push(exclusion);
            }
        }
        exclusionsMap.set(CrmTypeList.FAMILY_SOURCE, sources);
        exclusionsMap.set('other', others);

        return exclusionsMap;
    }
}
