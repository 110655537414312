import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { EnrollmentCenterSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-settings-repository';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { StaffRepository } from '@/staff/repositories/staff-repository';

/**
 * Factory for creating and retrieving repositories.
 */
export class RepositoryFactory {
    private childrenRepo: ChildrenRepository | null = null;
    private ecSettingsRepo: EnrollmentCenterSettingsRepository | null = null;
    private integrationRepo: IntegrationRepository | null = null;
    private landingPagesRepo: LandingPagesRepository | null = null;
    private staffRepo: StaffRepository | null = null;

    /**
     * Get the children repository.
     */
    public getChildrenRepository() {
        if (!this.childrenRepo) {
            this.childrenRepo = new ChildrenRepository();
        }
        return this.childrenRepo;
    }

    /**
     * Get the ec settings repository.
     */
    public getEnrollmentCenterSettingsRepository() {
        if (!this.ecSettingsRepo) {
            this.ecSettingsRepo = new EnrollmentCenterSettingsRepository();
        }
        return this.ecSettingsRepo;
    }

    /**
     * Get the integration repository.
     */
    public getIntegrationRepository() {
        if (!this.integrationRepo) {
            this.integrationRepo = new IntegrationRepository();
        }
        return this.integrationRepo;
    }

    /**
     * Get the landing pages repository.
     */
    public getLandingPagesRepository(): LandingPagesRepository {
        if (!this.landingPagesRepo) {
            this.landingPagesRepo = new LandingPagesRepository();
        }
        return this.landingPagesRepo;
    }

    /**
     * Get the staff repository.
     */
    public getStaffRepository() {
        if (!this.staffRepo) {
            this.staffRepo = new StaffRepository();
        }
        return this.staffRepo;
    }
}
