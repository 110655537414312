import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { Recording } from '@/communications/recordings/models/recording-models';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';

export interface RecordingsState extends AbstractEntityState<Recording> {
    entities: Array<Recording>;
    orgInboxCount: number;
    teamInboxCount: number;
    currentOrgId: number | undefined;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.RECORDINGS
})
export class RecordingsStore extends AbstractApiStore<Recording> implements RecordingsState {
    protected readonly repository = new RecordingsRepository();

    entities: Array<Recording> = [];
    orgInboxCount = 0;
    teamInboxCount = 0;
    currentOrgId: number | undefined = undefined;

    @Mutation
    private storeRecordingsInboxCount(update: { count: number; orgId: number }) {
        this.orgInboxCount = update.count;
        this.currentOrgId = update.orgId;
    }

    @Mutation
    private storeRecordingsEnrollmentInboxCount(update: { count: number; orgId: number }) {
        this.teamInboxCount = update.count;
        this.currentOrgId = update.orgId;
    }

    @Mutation
    public incrementInboxCount() {
        this.orgInboxCount++;
    }

    @Mutation
    public incrementEnrollmentInboxCount() {
        this.teamInboxCount++;
    }

    @Mutation
    public decrementInboxCount() {
        this.orgInboxCount--;
    }

    @Mutation
    public decrementEnrollmentInboxCount() {
        this.teamInboxCount--;
    }

    @Action({ commit: 'storeRecordingsInboxCount' })
    public async retrieveRecordingsInboxCount(orgId: number) {
        const count = await this.repository.getRecordingsCount({ org_id: orgId });
        return {
            count,
            orgId
        };
    }

    @Action({ commit: 'storeRecordingsEnrollmentInboxCount' })
    public async retrieveRecordingsEnrollmentInboxCount(orgId: number) {
        const count = await this.repository.getRecordingsCount({
            org_id: orgId,
            enrollment_team: true
        });
        return {
            count,
            orgId
        };
    }

    @Action
    public async initRecordingsInboxCount(orgId: number) {
        if (this.currentOrgId !== orgId) {
            await this.retrieveRecordingsInboxCount(orgId);
        }
    }

    @Action
    public async initRecordingsEnrollmentInboxCount(orgId: number) {
        if (this.currentOrgId !== orgId) {
            await this.retrieveRecordingsEnrollmentInboxCount(orgId);
        }
    }

    public get inboxCount(): number {
        return this.orgInboxCount;
    }

    public get enrollmentTeamInboxCount(): number {
        return this.teamInboxCount;
    }

}
