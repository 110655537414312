







































































































































import { FamilyHistoryUtils } from '@/families/family-history-utils';
import { isEqual } from 'lodash';
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Change } from '@/families/models/update';
import { AuditTrailTableData, ChangeType } from '@/families/models/update';
import { DataTableHeader } from 'vuetify';
import ViewDetailsHistoryModal from '@/families/components/ViewDetailsHistoryModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { HistoryTypes } from '@/constants/history-type-constants';
import { Task } from '@/tasks/models/task-models';
import { Event } from '@/families/events/models/event-models';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import LogEventModal from '@/tasks/components/LogEventModal.vue';
import type { Family } from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);
const familyHistoryUtils = new FamilyHistoryUtils();

@Component({
    components: {
        ViewDetailsHistoryModal,
        ManageTaskModal,
        AddTaskModal,
        LogEventModal
    }
})
export default class AuditTrailTable extends Mixins(LocaleMixin) {
    /**
     * Whether to include a filter on the table for showing the complete audit history.
     */
    @Prop({ default: false }) readonly includeFullAuditFilter!: boolean;
    @Prop({ default: false }) readonly isFlat!: boolean;
    @Prop() family!: Family;
    @PropSync('isFullAudit', {
        type: Boolean,
        default: true
    }) isFullAuditSynced!: boolean;

    @Prop({
        type: String,
        required: true
    }) readonly guardianName!: string;

    @Prop({ required: true }) readonly updates!: Array<Change>;
    @Prop({ default: HistoryTypes.AUDIT_TRAIL }) readonly tableType!: string;

    private addAnotherTaskEvent = EventTypes.TASK_ADD_ANOTHER;
    private editTaskEvent = EventTypes.TASK_EDIT;
    private editEventEvent = EventTypes.EVENT_EDIT;
    private closedEvent = EventTypes.CLOSE;
    private updatedEvent = EventTypes.UPDATED;
    private editTaskItem: Task | null = null;
    private event: Event | null = null;
    private isAddTask = false;
    private isMeeting = false;
    private selectedItem: Change | null = null;
    private showManageTaskModal = false;
    private showLogEventModal = false;
    private modalValue = false;
    private typeId = 0;
    private type = '';
    private key = 0;
    private loadingKey = 0;
    private search = '';
    private user = '';
    private auditTableHeaders: Array<DataTableHeader> = [
        {
            text: '',
            value: 'icon',
            width: '40px'
        },
        {
            text: 'Made By',
            value: 'user',
            width: '25%'
        },
        {
            text: 'Event Date',
            value: 'date',
            width: '165px'
        },
        {
            text: 'Type',
            value: 'type',
            width: '90px'
        },
        {
            text: 'Action',
            value: 'action',
            width: '90px'
        },
        {
            text: 'Details',
            value: 'details',
            width: '40%'
        }
    ];

    private commentsTableHeaders: Array<DataTableHeader> = [
        {
            text: '',
            value: 'icon',
            width: '40px'
        },
        {
            text: 'Made By',
            value: 'user',
            width: '12%'
        },
        {
            text: 'Date',
            value: 'date',
            width: '165px'
        },
        {
            text: 'Type',
            value: 'type',
            width: '90px'
        },
        {
            text: 'Details',
            value: 'details',
            width: '20%'
        },
        {
            text: 'Comments',
            value: 'event_comment',
            width: '50%'
        }
    ];

    private get isAuditTrail() {
        if (this.tableType === HistoryTypes.AUDIT_TRAIL) {
            return true;
        } else {
            return false;
        }
    }

    private get historyTableHeaders() {
        if (this.tableType === HistoryTypes.AUDIT_TRAIL) {
            return this.auditTableHeaders;
        } else {
            return this.commentsTableHeaders;
        }
    }

    private get auditTrailTableData() {
        return this.updates.map((change: Change) => {
            return {
                icon: (change.field_changes.length > 0 || familyHistoryUtils.getChangeId(change) !== null) &&
                change.type !== ChangeType.STATUS
                    ? 'mdi-eye'
                    : '',
                id: this.key++,
                user: familyHistoryUtils.getChangeUser(change),
                date: change.type === ChangeType.TASK && change.action === 'Completed'
                    ? change.action_date_time
                    : change.date_time,
                type: change.type,
                action: change.action,
                details: !this.isFullAuditSynced
                    ? change.details
                    : familyHistoryUtils.getIntuitiveDetails(change, this.timezone, this.$i18n.locale, this.updates),
                event_comment: change.event_comment,
                change: change
            } as AuditTrailTableData;
        });
    }

    private get isCompletedEdit() {
        if (this.editTaskItem) {
            return this.editTaskItem.is_completed;
        }
        return false;
    }

    get timezone() {
        return authState.userTimeZone;
    }

    /**
     * Get the class to apply to the table row.
     *
     * @param data
     */
    private getRowClass(data: AuditTrailTableData): string {
        return data.icon.length > 0 ? 'clickable' : '';
    }

    /**
     * Show the modal that holds more detailed information of what was changed.
     *
     * @param itemRow
     */
    private viewHistoryChangesModal(itemRow: AuditTrailTableData) {
        this.selectedItem = this.updates.find(change => isEqual(change, itemRow.change)) as Change;
        if (!this.selectedItem) {
            return;
        }
        this.typeId = familyHistoryUtils.getChangeId(this.selectedItem) ?? 0;
        this.type = this.selectedItem.type;
        this.user = itemRow.user;
        this.modalValue = (this.selectedItem.field_changes.length > 0 || this.typeId !== 0) && this.type !== ChangeType.STATUS;
    }

    /**
     * open the edit task modal to edit completed tasks/tours
     *
     * @param task
     */
    private viewEditTaskModal(task: Task) {
        this.loadingKey++;
        this.editTaskItem = task;
        this.isMeeting = task.type.values.value === 'Meeting';
        this.showManageTaskModal = true;
    }

    /**
     * open the edit event modal to edit events/logs
     *
     * @param task
     */
    private viewEditEventModal(event: Event) {
        this.loadingKey++;
        this.event = event;
        this.showLogEventModal = true;
    }

    private handleClose() {
        this.editTaskItem = null;
        this.event = null;
    }

    private handleUpdate() {
        this.$emit(EventTypes.UPDATED);
    }

    private addAnotherTask() {
        this.loadingKey++;
        this.editTaskItem = null;
        this.isAddTask = true;
    }

    private activityAdded() {
        this.$emit(EventTypes.UPDATED);
        this.$emit('updateFamily');
    }
}
