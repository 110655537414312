import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import store from '@/store';
import { TaskTypesRepository } from '@/tasks/repositories/task-types-repository';
import { CrmTypeOption } from '@/crm-types/models/crm-type';

export interface TaskTypeState extends AbstractEntityState<CrmTypeOption> {
    entities: Array<CrmTypeOption>;
}

/**
 * @deprecated
 * Use Crm Types Store with Task Types
 */
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.TASK_TYPES
})
export class TaskTypesStore extends AbstractApiStore<CrmTypeOption> implements TaskTypeState {
    readonly repository = new TaskTypesRepository();

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.retrieve();
        }
    }

    @Mutation
    public storeList(options: Array<CrmTypeOption>) {
        this.entities = [...options];
    }

    // Unfortunately getters don't work with vuex-module-decorator inheritance for some reason.
    public get stored(): Array<CrmTypeOption> {
        return this.entities;
    }
}
