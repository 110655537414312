var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-h4 font-weight-bold mx-1"},[_c('v-col',[_vm._v(" Potential Callers ")])],1),_c('base-card',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-text',[(!_vm.searchComplete)?_c('span',[_vm._v(" Searching... ")]):_c('base-data-table',{attrs:{"items":_vm.matches,"headers":_vm.resultHeaders,"disable-sort":""},scopedSlots:_vm._u([{key:"item.guardian",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"guardian data-cell",attrs:{"title":item.last_name + ', ' + item.first_name}},[_vm._v(" "+_vm._s(item.last_name)+", "+_vm._s(item.first_name)+" ")])]}},{key:"item.childrenNames",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"children data-cell",attrs:{"title":_vm.getChildrenNames(item)}},[_vm._v(" "+_vm._s(_vm.getChildrenNames(item))+" ")])]}},{key:"item.centerName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"location data-cell",attrs:{"title":item.center ? item.center.values.name : ''}},[_vm._v(" "+_vm._s(item.center ? item.center.values.name : '')+" ")])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell phone"},[_vm._v(" "+_vm._s(_vm.formatPhone(item.phone_number))+" ")])]}},{key:"item.goToButton",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell action"},[_c('base-button-primary',{on:{"click":function($event){return _vm.goToFamily(item)}}},[_vm._v(" Go To Family ")])],1)]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('base-button-secondary',{on:{"click":_vm.goToEcc}},[_vm._v(" Go To Enrollment Center ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }