













import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsChangeStatusDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { WorkflowsTypesStore } from '@/automation/workflows/store/workflows-types-store';
import { LoadingStore } from '@/store/loading-store';
import { childStatusId, WorkflowEntityTypes } from '@/automation/workflows/constants/workflow-constants';
import { WorkflowType } from '@/automation/workflows/models/workflow-type-models';

const workflowsTypesStore = getModule(WorkflowsTypesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class WorkflowActionChangeStatus extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsChangeStatusDto;

    private loadingKey = 'manageWorkflow';
    private statuses: Array<WorkflowType> = [];

    get settings(): WorkflowActionSettingsChangeStatusDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsChangeStatusDto) {
        this.$emit('input', settings);
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await workflowsTypesStore.retrieveFieldValues({ entityId: WorkflowEntityTypes.CHILD, fieldId: childStatusId });
        this.statuses = workflowsTypesStore.fieldValues(WorkflowEntityTypes.CHILD, childStatusId);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
