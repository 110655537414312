import { DripCampaign } from '@/automation/drip-campaigns/models/drip-campaign';
import { WorkflowActiveStatus } from '@/automation/workflows/constants/workflow-constants';
import { Workflow } from '@/automation/workflows/models/workflow-models';
import { WorkflowLocationsStore } from '@/automation/workflows/store/workflow-locations-store';
import { WorkflowsStore } from '@/automation/workflows/store/workflows-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { ValuedLink } from '@/models/base';
import { Org } from '@/models/organization/org';
import {
    Center,
    CentersEnabled
} from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { OrgsStore } from '@/store/orgs-store';
import cloneDeep from 'lodash/cloneDeep';
import { getModule } from 'vuex-module-decorators';

export interface WorkflowLocationAndGroupMapping {
    centersAvailable: Array<CentersEnabled>;
    groupsAvailable: Array<{
        locationGroup: ValuedLink;
        enabled: boolean;
    }>;
    originalCenterData: Array<CentersEnabled>;
    groupsMap: Map<number, Array<CentersEnabled>>;
}

export class WorkflowLocationsUtils {
    private authStore = getModule(AuthStore, store);
    private centersStore = getModule(CentersStore);
    private featuresStore = getModule(FeaturesStore);
    private orgsStore = getModule(OrgsStore);
    private workflowLocationsStore = getModule(WorkflowLocationsStore);
    private workflowsStore = getModule(WorkflowsStore);

    /**
     * Build location and group data for workflow or drip campaign.
     *
     * @param {DripCampaign | Workflow | null}entity
     */
    public async buildLocationAndGroupData(
        entity: DripCampaign | Workflow | null
    ): Promise<WorkflowLocationAndGroupMapping> {
        let originalCenterData = [];
        let centersAvailable = [];
        const groupsAvailable = [];
        const groupsMap = new Map();
        const centersPromise = this.centersStore.initAccessibleCentersWithInactive(true);
        const locationGroupsPromise = this.centersStore.initLocationGroups(true);
        await centersPromise;
        await locationGroupsPromise;

        // Fetch location groups
        const locationGroups = this.centersStore.locationGroups;
        for (const locationGroup of locationGroups) {
            groupsAvailable.push({ locationGroup: locationGroup.group, enabled: true });
            groupsMap.set(locationGroup.group.id, [] as Array<CentersEnabled>);
        }

        // If the store has an empty array means that we haven't made any changes.
        // Need to fetch centers and check if workflow or drip campaign is enabled for each center.
        if (this.workflowLocationsStore.storedLocationMappings.length === 0) {
            const centers = this.centersStore.storedAccessibleCentersWithInactive;
            for (const center of centers) {
                const centerData: CentersEnabled = { center: center, enabled: true };
                if (entity === null) {
                    // Default to enabled
                    centersAvailable.push(centerData);
                } else {
                    const enabled = entity.enabled_center_ids.includes(center.id);
                    centerData.enabled = enabled;
                    centersAvailable.push(centerData);
                    originalCenterData.push({ center: center, enabled: enabled });
                }

                // For each center check which groups it is associated with
                for (const group of locationGroups) {
                    if (group.centers.map((centerLink) => {
                        return centerLink.id;
                    }).includes(center.id)) {
                        if (groupsMap.has(group.group.id)) {
                            const updatedCenterDataArray: Array<CentersEnabled> = groupsMap.get(group.group.id)!;
                            updatedCenterDataArray.push(centerData);
                            groupsMap.set(group.group.id, updatedCenterDataArray);
                        }
                    }
                }
            }
        } else {
            // Center data fetched from store
            centersAvailable = this.workflowLocationsStore.storedLocationMappings;
            originalCenterData = cloneDeep(centersAvailable);

            // For each center check which groups it is associated with
            for (const centerData of centersAvailable) {
                for (const group of locationGroups) {
                    if (group.centers.map((centerLink) => {
                        return centerLink.id;
                    }).includes(centerData.center.id)) {
                        if (groupsMap.has(group.group.id)) {
                            const updatedCenterDataArray: Array<CentersEnabled> = groupsMap.get(group.group.id)!;
                            updatedCenterDataArray.push(centerData);
                            groupsMap.set(group.group.id, updatedCenterDataArray);
                        }
                    }
                }
            }
        }

        // For each location group, look at whether any of the centers that use it are enabled or not.
        // Lets us know whether to show the entire group as enabled or not.
        // Show the group as enabled if any of its centers is enabled.
        for (let i = 0; i < groupsAvailable.length; ++i) {
            let groupEnabled = false;

            if (!groupsMap.has(groupsAvailable[i].locationGroup.id)) {
                groupsAvailable.splice(i, 1);
                --i;
                continue;
            }

            // If no centers are using a location group, don't include it in the location groups list
            if (groupsMap.get(groupsAvailable[i].locationGroup.id)!.length === 0) {
                groupsMap.delete(groupsAvailable[i].locationGroup.id);
                groupsAvailable.splice(i, 1);
                --i;
                continue;
            }

            // Get locations using a group array.
            const centerDataArray: Array<CentersEnabled> = groupsMap.get(groupsAvailable[i].locationGroup.id)!;

            for (let j = 0; j < centerDataArray.length; ++j) {
                if (centerDataArray[j].enabled) {
                    groupEnabled = true;
                    break;
                }
            }
            groupsAvailable[i].enabled = groupEnabled;
        }

        return {
            centersAvailable: centersAvailable,
            groupsAvailable: groupsAvailable,
            originalCenterData: originalCenterData,
            groupsMap: groupsMap
        };
    }

    /**
     * Returns the workflows that should be visible for the selected location.
     *
     * @param {Org}org
     */
    public getVisibleWorkflows(org: Org): Array<Workflow> {
        const isCrmPlus = this.featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
        const isCorpUser = this.authStore.isCorporateUser;

        if (
            org.id === 1 ||
            !isCrmPlus ||
            (isCrmPlus && !this.featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE)) ||
            (isCrmPlus && this.featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE) && isCorpUser)
        ) {
            return this.workflowsStore.stored;
        } else {
            const centersForOrg = this.orgsStore.centersByOrgId(org.id);
            return this.workflowsStore.stored.filter(
                workflow => {
                    for (const center of centersForOrg) {
                        if (workflow.enabled_center_ids.includes(center.id)) {
                            return true;
                        }
                    }

                    return false;
                }
            );
        }
    }

    /**
     * Get the active status for a workflow at a given org.
     * Can be fully active, partially active, or inactive.
     *
     * @param {Workflow}workflow
     * @param {Org}org
     */
    public getWorkflowActiveStatus(workflow: Workflow, org: Org): WorkflowActiveStatus {
        // Check that every center under/in the org is enabled for the workflow
        if (!workflow.is_enabled) {
            return WorkflowActiveStatus.INACTIVE;
        }

        let foundCenterCount = 0;
        const centersForOrg = this.orgsStore.centersByOrgId(org.id);
        for (const center of centersForOrg) {
            if (workflow.enabled_center_ids.includes(center.id)) {
                // This center is enabled for the workflow: increment counter
                ++foundCenterCount;
            }
        }
        if (foundCenterCount === centersForOrg.length) {
            return WorkflowActiveStatus.FULLY_ACTIVE;
        } else if (foundCenterCount === 0) {
            return WorkflowActiveStatus.INACTIVE;
        }

        return WorkflowActiveStatus.PARTIALLY_ACTIVE;
    }

    /**
     * Whether a workflow is enabled at all available centers.
     *
     * @param {Workflow}workflow
     * @param {Org | Center}currentLocation   The center or org the user is viewing the workflow at
     * @param {Array<Center>}accessibleCenters The centers the current user has access to
     */
    public hasMixedCoverage(
        workflow: Workflow,
        currentLocation: Org | Center,
        accessibleCenters: Array<Center>
    ) {
        return Object.prototype.hasOwnProperty.call(currentLocation, 'center') &&
            accessibleCenters
                .filter(center => workflow.enabled_center_ids.includes(center.id))
                .length !== accessibleCenters.length &&
            accessibleCenters
                .filter(center => workflow.enabled_center_ids.includes(center.id))
                .length > 0 &&
            workflow.is_enabled;
    }
}
