import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    CustomField,
    CustomFieldCreateDto,
    CustomFieldUpdateDto
} from '@/crm-types/custom-fields/custom-fields-types';

/**
 * Repository for managing custom fields
 */
export class CustomFieldsRepository extends AbstractRepository<CustomField> {
    protected endpoint = 'types/fields/custom';

    public async addField(field: CustomFieldCreateDto): Promise<CustomField> {
        return (await this.client.post(`${this.endpoint}`, field)).data;
    }

    public async updateField(field: CustomFieldUpdateDto): Promise<CustomField> {
        return (await this.client.patch(`${this.endpoint}/${field.id}`, field)).data;
    }

    public async deleteField(fieldId: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/${fieldId}`);
    }

    public async getFields(): Promise<Array<CustomField>> {
        return (await this.getAll()).entities as Array<CustomField>;
    }
}
