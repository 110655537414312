










































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import cloneDeep from 'lodash/cloneDeep';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import { DataTableHeader } from 'vuetify';
import { TaskFiltersParameters, TasksRepository } from '@/tasks/repositories/tasks-repository';
import type { StaffLink } from '@/staff/models/user';
import type { ManageTaskTableData, Task, TasksSortParameter } from '@/tasks/models/task-models';
import { TasksSortKeys } from '@/tasks/models/task-models';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import store from '@/store';
import type { Org } from '@/models/organization/org';

import { getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import { diffDays, formatDateWithTimezone, getTodayEnd, getTodayStart } from '@/date-time/date-time-utils';
import { Family } from '@/families/models/family';
import { FeaturesStore } from '@/features/features-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { Timezone } from '@/core/timezones/timezone';
import { ApiPagination } from '@/repositories/abstract-repository';
import { DataTableOptions } from '@/models/datatables';
import { getPagination } from '@/core/datatables-utils';
import { SortConstants } from '@/constants/sort-constants';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const tasksRepo = new TasksRepository();
const featuresState = getModule(FeaturesStore);
const enrollmentCenterSettingsState = getModule(EnrollmentCenterSettingsStore);
const centerStore = getModule(CentersStore);
const timezonesStore = getModule(TimezonesStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: {
        ManageTaskModal,
        AddTaskModal
    }
})
export default class ManageTasks extends Mixins(LocaleMixin) {
    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;

    // Whether or not to include tasks after today.
    @Prop({
        type: Boolean,
        default: true
    }) readonly includeFutureTasks!: boolean;

    // Whether or not to include tasks other than the current user's tasks.
    @Prop({
        type: Boolean,
        default: false
    }) readonly myTasksOnly!: boolean;

    // Whether or not to show the search bar. Used to determine if view is generated from TourCalendar.
    @Prop({
        type: Boolean,
        default: true
    }) readonly showTaskSearch!: boolean;

    // Passed in search string if any
    @Prop({
        type: String,
        default: null
    }) readonly tourSearch!: string | null;

    @Prop({
        type: String,
        default: null
    }) readonly location!: string | null;

    private addAnotherTaskEvent = EventTypes.TASK_ADD_ANOTHER;
    private closeEvent = EventTypes.CLOSE;
    private isTaskSelected = false;
    private nonTours: Array<Task> = [];
    private tours: Array<Task> = [];
    private items: Array<ManageTaskTableData> = [];
    private memoizedItems: Array<ManageTaskTableData> = [];
    private loadingKey = 'nonToursData';
    private selectedTask: Task | null = null;
    private showLocation = true;
    private todayStart = '';
    private todayEnd = '';
    private timezone = 'UTC';
    private userId: number | null = null;
    private addTaskModal = false;
    private family: Family | null = null;
    private shownItems: Array<ManageTaskTableData> | null = null;
    private showEt = false;
    private count = 0;
    private searchString: string | null = '';
    private searchParameter = '';

    private currentItemCount = 1;
    private options: DataTableOptions = {
        itemsPerPage: 25,
        page: 1,
        sortBy: [TasksSortKeys.Date]
    };

    private isLoading = true;
    private defaultSorting: TasksSortParameter = {
        sort_keys: [TasksSortKeys.Date],
        sort_dir: [SortConstants.ASCENDING]
    };

    get headers(): DataTableHeader[] {
        // Using the sort keys as column headers to make it easier to pass the sort params to the API
        const ret = [
            {
                text: 'Guardian',
                value: TasksSortKeys.Guardian,
                class: this.showLocation ? 'guardian-header-col-sml' : 'guardian-header-col'
            },
            {
                text: 'Type',
                value: TasksSortKeys.Type,
                class: 'type-header-col'
            },
            {
                text: 'Assignee',
                value: TasksSortKeys.Assignee,
                class: this.showLocation ? 'assignee-header-col-sml' : 'assignee-header-col'
            }
        ];
        if (this.showLocation) {
            ret.push({
                text: 'Location',
                value: TasksSortKeys.Location,
                class: 'location-header-col'
            });
        }
        if (this.isEnrollmentTeamMode) {
            ret.push({
                text: 'Time Zone',
                value: TasksSortKeys.Timezone,
                class: 'timezone-header-col'
            });
        }
        ret.push({
            text: 'Due',
            value: TasksSortKeys.Date,
            class: this.showLocation ? 'due-header-col-sml' : 'due-header-col'
        });
        return ret;
    }

    get center() {
        return appState.storedCurrentCenter;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get timezones(): Array<Timezone> {
        return timezonesStore.stored;
    }

    get isShowGroups() {
        return this.options.sortBy && this.options.sortBy.length && this.options.sortBy[0] === 'due_date';
    }

    /**
     * Filter the currently shown items in the table to the ones that are considered past due.
     */
    get shownPastDueItems() {
        const groupedTasks = this.groupBy(this.shownItems as Array<ManageTaskTableData>);
        let ret: Array<Task | ManageTaskTableData> | null = null;

        if (groupedTasks['Past Due'] && groupedTasks['Past Due'].length > 0) {
            ret = groupedTasks['Past Due'];
        }

        return ret;
    }

    /**
     * Should be show the mass task cancellation button or not in the parent Vue?
     */
    @Watch('shownItems')
    private updateShowMassCancelButton() {
        const items = this.shownPastDueItems;
        if (items && items.length > 0) {
            this.$emit('showMassCancelButton', items);
            return;
        }

        this.$emit('showMassCancelButton', null);
    }

    // Watch for changes to the Datatables options -- pagination, sorting, etc.
    @Watch('options')
    async onOptionsChange() {
        if (this.showTaskSearch) {
            await this.fetchNonTours(true);
        } else {
            this.applySearch();
            await this.fetchOnlyTours();
        }
        this.memoizedItems = cloneDeep(this.items);
    }

    @Watch('org', { deep: true })
    private async updateOrg(org: Org) {
        if (org && org.id > 0 && this.showTaskSearch) {
            this.$set(this.options, 'page', 1);
            await this.fetchNonTours(true);
        }
    }

    @Watch('searchParameter')
    async onSearchChange() {
        if (this.searchParameter.length > 2) {
            if (!this.showTaskSearch) {
                await this.fetchOnlyTours();
            } else {
                await this.fetchNonTours();
            }
        }
    }

    @Watch('tourSearch')
    updateTourSearch() {
        this.applySearch();
    }

    @Watch('location')
    async updateSearchLocation() {
        await this.fetchOnlyTours();
    }

    @Watch('myTasksOnly')
    async updateOnlyMe() {
        await this.fetchOnlyTours();
    }

    /**
     * initialization hook
     */
    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        if (authState.userInfoObject) {
            this.timezone = authState.userInfoObject.timezone;
            this.todayStart = getTodayStart(this.timezone);
            this.todayEnd = getTodayEnd(this.timezone);
            this.showLocation = !authState.userInfoObject.center_id;
            this.userId = authState.id as number;
        }
        await featuresState.init();
        if (featuresState.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
            await enrollmentCenterSettingsState.init();
            this.showEt = true;
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Grab all our tasks, filtered by location.
     */
    async fetchNonTours(force = false) {
        this.isLoading = true;

        const pagination: ApiPagination = getPagination(this.options);
        const sorting: TasksSortParameter = this.getSortingFromOptions(this.options);
        if ((this.org && this.org.id > 0) || this.isEnrollmentTeamMode) {
            this.isTaskSelected = false;
            this.selectedTask = null;
            const end = !this.includeFutureTasks ? this.todayEnd : null;
            const extraParams: TaskFiltersParameters = !this.isEnrollmentTeamMode ? { org_id: '' + this.org?.id } : { org_id: undefined };
            if (this.myTasksOnly) {
                extraParams.assigned_to = this.userId as number;
            }
            if (this.isEnrollmentTeamMode) {
                extraParams.only_enrollment_team = true as boolean;
            }
            if (this.searchParameter) {
                extraParams.search = this.searchParameter;
            }

            const nonToursResults = await tasksRepo.getNonTours(end, pagination, extraParams, sorting, force);

            this.nonTours = nonToursResults.entities;
            this.currentItemCount = Number(nonToursResults.count);

            this.$emit('tasksCount', nonToursResults.count);
            this.items = this.nonTours.map((task: Task) => {
                const centerTimezone = centerStore.storedAccessibleCenters.find(c => c.id === task.center.id)?.timezone ?? 'UTC';
                return {
                    id: task.id,
                    guardian_name: task.family.values.name,
                    type: task.type.values.value,
                    assignee_name: task.assigned_to_staff ? `${task.assigned_to_staff.values.last_name} ${task.assigned_to_staff.values.first_name}` : '',
                    assigneeDisplay: task.assigned_to_staff,
                    et: task.assigned_to_enrollment_team,
                    location: task.center.values.name,
                    timezone: this.timezoneToName(centerTimezone),
                    due_date: task.due_date_time
                } as ManageTaskTableData;
            });

        } else {
            this.$emit('tasksCount', 0);
        }

        this.isLoading = false;
    }

    async fetchOnlyTours(force = false) {
        this.isLoading = true;
        const pagination: ApiPagination = getPagination(this.options);
        const sorting: TasksSortParameter = this.getSortingFromOptions(this.options);
        if ((this.org && this.org.id > 0) || this.isEnrollmentTeamMode) {
            const end = !this.includeFutureTasks ? this.todayEnd : null;
            const extraParams: TaskFiltersParameters = !this.isEnrollmentTeamMode ? { org_id: '' + this.org?.id } : { org_id: undefined };
            if (this.location) {
                extraParams.org_id = this.location;
            }
            if (this.myTasksOnly) {
                extraParams.assigned_to = this.userId as number;
            }
            if (this.isEnrollmentTeamMode) {
                extraParams.only_enrollment_team = true as boolean;
            }
            if (this.searchParameter) {
                extraParams.search = this.searchParameter;
            }

            const toursResults = await tasksRepo.getTours(end, pagination, extraParams, sorting, force);
            this.tours = toursResults.entities.filter(entity => entity.group.values.name === 'Tour' || entity.group.values.name === 'Meeting');
            this.currentItemCount = Number(toursResults.count);
            this.$emit('toursCount', toursResults.count);

            this.items = this.tours.map((task: Task) => {
                const centerTimezone = centerStore.storedAccessibleCenters.find(c => c.id === task.center.id)?.timezone ?? 'UTC';
                return {
                    id: task.id,
                    guardian_name: task.family.values.name,
                    type: task.type.values.value,
                    assignee_name: task.assigned_to_staff ? `${task.assigned_to_staff.values.last_name} ${task.assigned_to_staff.values.first_name}` : '',
                    assigneeDisplay: task.assigned_to_staff,
                    et: task.assigned_to_enrollment_team,
                    location: task.center.values.name,
                    timezone: this.timezoneToName(centerTimezone),
                    due_date: task.due_date_time
                } as ManageTaskTableData;
            });
        } else {
            this.$emit('toursCount', 0);
        }
        this.isLoading = false;
    }

    /**
     * Open add task modal.
     * @param value
     */
    openAddTaskModal(value: Family) {
        this.family = value;
        this.addTaskModal = true;
    }

    /**
     * The callback for handling manage task modal.
     * @param taskRow
     */
    manageTask(taskRow: ManageTaskTableData) {
        if (this.showTaskSearch) {
            this.selectedTask = this.nonTours.find(task => task.id === taskRow.id) as Task;
            this.isTaskSelected = true;
        } else {
            // if table is opened via Calendar view, pass the selected task back to the calendar to open task module
            this.selectedTask = this.tours.find(task => task.id === taskRow.id) as Task;
            this.$emit('selected-task', this.selectedTask);
        }
    }

    resetTask() {
        this.selectedTask = null;
        this.isTaskSelected = false;
    }

    /**
     * Format due date per specs.
     * @param dueDateTime
     */
    formatDueDate(dueDateTime: string): string {
        if (dueDateTime < this.todayStart) {
            return Math.ceil(diffDays(this.todayStart, dueDateTime)) + ' days ago';
        }

        if (dueDateTime < this.todayEnd) {
            return this.formatTime(formatDateWithTimezone(dueDateTime, this.timezone));
        }

        return this.formatDate(formatDateWithTimezone(dueDateTime, this.timezone));
    }

    /**
     * Format "assigned to" column per specs.
     * Must be used with v-html to avoid escaping strong.
     * @param assignedTo
     * @param et
     */
    formatAssignee(assignedTo: StaffLink | null, et: number, html = true) {
        if (!html) {
            if (et === 1 && !assignedTo && enrollmentCenterSettingsState.settings) {
                return enrollmentCenterSettingsState.settings.name;
            }
        }
        if (et === 1 && !assignedTo && enrollmentCenterSettingsState.settings) {
            return '<strong>' + enrollmentCenterSettingsState.settings.name + '</strong>';
        }

        if (!assignedTo) {
            return '';
        }

        return '<strong>' + assignedTo.values.first_name + ' ' + assignedTo.values.last_name + '</strong>';
    }

    formatAvatarInitials(assignedTo: StaffLink) {
        return assignedTo.values.first_name.substr(0, 1).toUpperCase() + assignedTo.values.last_name.substr(0, 1).toUpperCase();
    }

    getAvatarBackgroundColor(assignedTo: StaffLink) {
        return getAvatarBackgroundFromUser(assignedTo.id);
    }

    applySearch() {
        if (this.searchString && this.searchString.length > 2) {
            this.searchParameter = this.searchString;
        } else if (this.tourSearch && this.tourSearch.length > 2) {
            this.searchParameter = this.tourSearch;
        } else {
            this.searchParameter = '';
        }
    }

    clearSearch() {
        // reset items to the items copied at initialization.(options watcher)
        if (this.memoizedItems) {
            this.items = this.memoizedItems;
        }
        this.searchString = '';
        this.applySearch();
    }

    /**
     * A helper function for due date grouping.
     * @param tasks
     */
    groupBy(tasks: Array<any>): Record<string, Array<Task>> {
        return tasks.reduce((taskGroups, task) => {
                const group = task.due_date < this.todayStart ? 'Past Due' : (task.due_date < this.todayEnd ? 'Due Today' : 'Upcoming');
                if (!taskGroups[group]) {
                    taskGroups[group] = [];
                    taskGroups[group] = [];
                }

                taskGroups[group].push(task);

                return taskGroups;
            },
            {} as Record<string, Task[]>
        );
    }

    /**
     * Set a property to contain the currently shown items in the task table.
     * @param items
     */
    currentItems(items: Array<ManageTaskTableData> | null) {
        this.shownItems = items;
    }

    private timezoneToName(tzCode: string): string {
        for (const tz of this.timezones) {
            if (tz.code === tzCode) {
                return tz.name;
            }
        }
        return '';
    }

    private getSortingFromOptions(options: DataTableOptions): TasksSortParameter {
        let sorting = this.defaultSorting;
        if (options.sortBy && options.sortBy.length) {
            // Right now we don't support multiSort; only single column sort
            // It works in the API, but I don't want to implement it here yet
            sorting = {
                sort_keys: [options.sortBy[0] as TasksSortKeys],
                sort_dir: [
                    options.sortDesc && options.sortDesc[0]
                        ? SortConstants.DESCENDING
                        : SortConstants.ASCENDING
                ]
            };
        }
        return sorting;
    }
}
