





















































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import NewFamilyToScheduledGaugeGraph from '@/dashboards/components/NewFamilyToScheduledGaugeGraph.vue';
import NewFamilyToTourComplGaugeGraph from '@/dashboards/components/NewFamilyToTourComplGaugeGraph.vue';
import ScheduledToursToComplGaugeGraph from '@/dashboards/components/ScheduledToursToComplGaugeGraph.vue';
import RegisteredAfterTourCompGaugeGraph from '@/dashboards/components/RegisteredAfterTourCompGaugeGraph.vue';

@Component({
    components: {
        RegisteredAfterTourCompGaugeGraph,
        ScheduledToursToComplGaugeGraph,
        NewFamilyToTourComplGaugeGraph,
        NewFamilyToScheduledGaugeGraph
    }
})
export default class ConversionSuccessGraphs extends Mixins(LocaleMixin) {
}
