









import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import ManageUsers from '@/staff/components/ManageStaff.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';

Component.registerHooks([
    'beforeRouteLeave',
    'beforeRouteEnter'
]);
const appState = getModule(AppStateStore);
const staffUtils = new StaffUtils();

@Component({
    components: { ManageUsers }
})
export default class Staff extends Mixins(LocaleMixin) {
    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        if (appState.manageStaffModalOpen) {
            appState.updateManageStaffModalStatus(false);
        }
        next();
    }

    async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
        const canViewEditUserSettings = await staffUtils.getUserPermission(PermissionName.SettingsUsers);
        if (canViewEditUserSettings) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private loadingKey = 'users';
}
