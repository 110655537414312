
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VSnackbar from 'vuetify/lib/components/VSnackbar/VSnackbar';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VSnackbar] });

export default base.extend({
    name: 'base-snackbar',
    props: {
        app: {
            type: Boolean,
            default: true
        },
        bottom: {
            type: Boolean,
            default: true
        },
        contentClass: {
            type: String,
            default: 'base-snackbar__content'
        },
        elevation: {
            type: Number,
            default: 24
        },
        light: {
            type: Boolean,
            default: false
        },
        timeout: {
            type: Number,
            default: 12000
        }
    },
    computed: {
        classes(): object {
            return {
                ...VSnackbar.options.computed.classes.call(this),
                'base-snackbar': true
            };
        }
    }
});
