import {
    FormProcessingFlow,
    LandingPage,
    LandingPageCreateDtoInterface, LandingPageSettings, LandingPageSettingsUpdateDtoInterface,
    LandingPageType,
    LandingPageUpdateDtoInterface, PendingReplyTo, TemplateType
} from '@/automation/landing-pages/models/landing-pages-models';
import { AbstractMapper } from '@/core/mappers/abstract-mapper';

export class LandingPageMapper extends AbstractMapper<LandingPage> {
    toCreateDto(entity: LandingPage): LandingPageCreateDtoInterface {
        return {
            name: entity.name ?? '',
            type: entity.type ? entity.type.id : LandingPageType.WEB
        };
    }

    toUpdateDto(entity: LandingPage): LandingPageUpdateDtoInterface {
        return {
            inquiry_type: entity.inquiry_type ? entity.inquiry_type.id : undefined,
            name: entity.name,
            wait_list_reason: entity.wait_list_reason ? entity.wait_list_reason.id : undefined,
            wait_list_type: entity.wait_list_type ? entity.wait_list_type.id : undefined,
            marketing_campaign: entity.marketing_campaign ? entity.marketing_campaign.id : undefined
        };
    }
}

export class LandingPageSettingsMapper {
    toUpdateDto(entity: LandingPageSettings): LandingPageSettingsUpdateDtoInterface {
        let communication = null;
        if (entity.communication) {
            communication = {
                family_email_delay_amount: entity.communication.family_email_delay_amount,
                family_email_delay_units: entity.communication.family_email_delay_units !== null ? entity.communication.family_email_delay_units.id : 3,
                family_email_from: entity.communication.family_email_from !== null ? entity.communication.family_email_from.id : PendingReplyTo.LOCATION_DIRECTOR,
                family_email_reply_to: entity.communication.family_email_reply_to !== null ? entity.communication.family_email_reply_to.id : PendingReplyTo.LOCATION_DIRECTOR,
                family_email_template: entity.communication.family_email_template !== null ? entity.communication.family_email_template.id : null,
                family_email_template_group: entity.communication.family_email_template_group !== null ? entity.communication.family_email_template_group.id : null,
                family_email_template_method: entity.communication.family_email_template_method !== null ? entity.communication.family_email_template_method.id : TemplateType.SINGLE,
                is_send_thank_you_email: entity.communication.is_send_thank_you_email,
                staff_notification_email_from: entity.communication.staff_notification_email_from !== null ? entity.communication.staff_notification_email_from.id : null,
                staff_notification_email_to: entity.communication.staff_notification_email_to !== null ? entity.communication.staff_notification_email_to.id : null
            };
            if (!communication.family_email_delay_units) {
                communication.family_email_delay_units = 3;
            }
        }
        return {
            communication: communication,
            confirmation: entity.confirmation,
            header: entity.header,
            process_at: entity.process_at ? entity.process_at.id : FormProcessingFlow.PROCESS_PENDING_ALL,
            style: entity.style
        };
    }
}
