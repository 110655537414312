import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { CcfIntegration, CcfInterview, Integration, IntegrationPartner } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import store from '@/store';

export interface CcfInterviewsHash {
    [key: string]: Array<CcfInterview>;
}

interface IntegrationsState {
    entities: Array<Integration>;
    mappedEntities: Map<number, Integration>;
    centerMappedEntities: Map<number, Integration>;
    ccfIntegrations: Array<CcfIntegration>;
    ccfInterviewsByDomain: CcfInterviewsHash;
    partners: Array<IntegrationPartner>;
    activeIntegrations: Array<Integration>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.INTEGRATIONS
})
export class IntegrationStore extends AbstractApiStore<Integration> implements IntegrationsState {
    readonly repository = new IntegrationRepository();

    entities: Array<Integration> = [];
    mappedEntities: Map<number, Integration> = new Map();
    centerMappedEntities: Map<number, Integration> = new Map();
    ccfIntegrations: Array<CcfIntegration> = [];
    ccfInterviewsByDomain: CcfInterviewsHash = {};
    partners: Array<IntegrationPartner> = [];
    activeIntegrations: Array<Integration> = [];

    @Mutation
    public storeActiveIntegrations(integrations: Array<Integration>) {
        this.activeIntegrations.length = 0;
        integrations.forEach((integration) => {
            this.activeIntegrations.push(integration);
        });
    }

    @Mutation
    public storeCcf(ccfIntegrations: Array<CcfIntegration>) {
        this.ccfIntegrations.length = 0;
        ccfIntegrations.forEach((ccfIntegration) => {
            this.ccfIntegrations.push(ccfIntegration);
        });
    }

    @Mutation
    public storeCcfInterviews(params: { domain: string; interviews: Array<CcfInterview> }) {
        this.ccfInterviewsByDomain[params.domain] = params.interviews;
    }

    @Mutation
    public storePartners(partners: Array<IntegrationPartner>) {
        this.partners.length = 0;
        partners.forEach((partner) => {
            this.partners.push(partner);
        });
    }

    @Action
    public async init(rebuild = false) {
        if (
            this.entities.length === 0 ||
            this.partners.length === 0 ||
            this.activeIntegrations.length === 0 ||
            rebuild
        ) {
            // Clear out the arrays and memory.
            // this.entities.splice(0, this.entities.length);
            // this.partners.splice(0, this.partners.length);
            // this.activeIntegrations.splice(0, this.activeIntegrations.length);

            await this.initPromise(
                {
                    hash: 'integrationInfo',
                    closure: async () => {
                        if (this.partners.length === 0 || rebuild) {
                            await this.retrieveAllPartners();
                        }

                        if (this.entities.length === 0 || rebuild) {
                            await this.retrieveAllIntegrations();
                        }

                        if (this.activeIntegrations.length === 0 || rebuild) {
                            // Yes, this is a little different from allIntegrations.
                            await this.retrieveAllActiveIntegrations();
                        }
                    },
                    rebuild: rebuild
                }
            );
        }
    }

    @Action
    public async initCcf(rebuild = false) {
        if (this.ccfIntegrations.length === 0 || rebuild) {
            await this.initPromise(
                {
                    hash: 'ccf-integrations',
                    closure: async () => {
                        await this.retrieveAllCcfIntegrations();
                    },
                    rebuild: rebuild
                }
            );
        }
    }

    @Action
    public async initCcfInterviews(domain: string, rebuild = false) {
        if (!this.ccfInterviewsByDomain[domain] || rebuild) {
            await this.initPromise(
                {
                    hash: 'ccf-interviews-' + domain,
                    closure: async () => {
                        await this.retrieveCcfInterviews(domain);
                    },
                    rebuild: rebuild
                }
            );
        }
    }

    @Action
    public async initPartners(rebuild = false) {
        if (this.partners.length === 0 || rebuild) {
            await this.initPromise(
                {
                    hash: 'integration_partners',
                    closure: async () => {
                        await this.retrieveAllPartners();
                    },
                    rebuild: rebuild
                }
            );
        }
    }

    @Action({ commit: 'storeActiveIntegrations' })
    public async retrieveAllActiveIntegrations(): Promise<Array<Integration>> {
        return await this.repository.getAllActiveIntegrations();
    }

    @Action
    public async retrieveAllIntegrations() {
        await this.retrieveAll();
        this.storeCenterMappedIntegrations();
    }

    @Action({ commit: 'storeCcf' })
    public async retrieveAllCcfIntegrations() {
        return await this.repository.getCCF();
    }

    @Action({ commit: 'storeCcfInterviews' })
    public async retrieveCcfInterviews(domain: string) {
        const interviews = await this.repository.getCcfInterviews(domain);
        return { domain, interviews };
    }

    @Action({ commit: 'storePartners' })
    public async retrieveAllPartners(): Promise<Array<IntegrationPartner>> {
        return await this.repository.getAllIntegrationPartners();
    }

    // Get integrations by center id.
    @Action({ rawError: true })
    public async getByCenterId(id: number, forceRefresh = false): Promise<Integration | null> {
        if (!forceRefresh && this.centerMappedEntities.has(id as number)) {
            return this.centerMappedEntities.get(id as number) as Integration;
        }

        return null;
    }

    // Store the integrations mapped by center id.
    @Mutation
    private storeCenterMappedIntegrations(): void {
        // Reset the map.
        this.centerMappedEntities.clear();

        this.entities.forEach((integration: Integration) => {
            if (integration.center && integration.center.id) {
                this.centerMappedEntities.set(integration.center.id, integration);
            }
        });
    }

    // Unfortunately, getters don't work with vuex-module-decorator inheritance for some reason.
    public get stored(): Array<Integration> {
        return this.entities;
    }
}
