
















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { MessageTableData } from '@/communications/templates/models/message-template';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { TemplatesRepository } from '@/communications/templates/repositories/templates-repository';
import { FeaturesStore } from '@/features/features-store';
import { OrgsStore } from '@/store/orgs-store';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { LocationOption, OrgsUtil } from '@/organizations/orgs-util';
import TemplateService from '@/communications/templates/template-service';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const templatesRepo = new TemplatesRepository();
const featuresState = getModule(FeaturesStore);
const orgsState = getModule(OrgsStore);
const brandState = getModule(BrandsStore);
const typesState = getModule(CrmTypesStore);
const orgsUtil = new OrgsUtil();
const templateService = new TemplateService();
@Component({
    components: { BaseClose }
})
export default class MassCopyTemplatesModal extends Mixins(LocaleMixin, VmodelShowMixin) {
    @Prop() readonly templates!: Array<MessageTableData>;
    private loadingKey = MassCopyTemplatesModal.name;
    private locationOption: LocationOption | null = null;

    get countText() {
        return this.templates.length === 1 ? 'this template' : 'these ' + this.templates.length + ' templates';
    }

    get hasBrands() {
        return featuresState.isFeatureEnabled(FeatureConstants.BRANDS);
    }

    get hasLocationGroups() {
        return featuresState.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    get locationItems() {
        return orgsUtil.getLocationOptions(
            orgsUtil.sortOrgsByHierarchy(orgsState.stored),
            this.hasBrands ? brandState.stored : [],
            this.hasLocationGroups ? typesState.listOptions(CrmTypeList.LOCATION_GROUPS) : []
        );
    }

    @Watch('modelValue', { immediate: true })
    async openToggled() {
        if (this.modelValue) {
            this.locationOption = null;
            const promises = [];
            promises.push(orgsState.init());
            if (this.hasBrands) {
                promises.push(brandState.init());
            }
            if (this.hasLocationGroups) {
                promises.push(typesState.initList(CrmTypeList.LOCATION_GROUPS));
            }
            loadingState.loadingIncrement(this.loadingKey);
            await Promise.all(promises);
            loadingState.loadingDecrement(this.loadingKey);
            this.locationOption = this.locationItems[0];
        }
    }

    async doCopy() {
        if (!this.locationOption) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const dto = templateService.getCopyTemplatesDto(this.templates, this.locationOption);
        await templatesRepo.copyTemplates(dto);
        loadingState.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.UPDATED);
        this.close();
    }

    close() {
        this.$emit(EventTypes.CLOSE);
        this.modelValue = false;
    }

}
