var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"my-2",attrs:{"width":"100%","elevation":0}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"3","sm":"4","lg":"3"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('base-text-field',{directives:[{name:"debounce",rawName:"v-debounce:600ms",value:(_vm.emitSearch),expression:"emitSearch",arg:"600ms"}],staticClass:"pa-0",attrs:{"id":"search-bar","prepend-inner-icon":"mdi-magnify","label":'Search ' + _vm.type,"clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('v-row',{staticClass:"mt-n8",attrs:{"no-gutters":"","dense":""}},[_c('v-checkbox',{attrs:{"label":"Only show messages to me"},on:{"change":_vm.emitFilterOnlyCurrenUser},model:{value:(_vm.onlyShowMyMessages),callback:function ($$v) {_vm.onlyShowMyMessages=$$v},expression:"onlyShowMyMessages"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('base-data-table',{staticClass:"inbox-table",attrs:{"items":_vm.ourThreads,"headers":_vm.headers,"item-key":"incoming_id","item-class":_vm.getRowClass,"show-select":"","disable-sort":"","options":_vm.options,"server-items-length":_vm.count},on:{"update:options":function($event){_vm.options=$event},"current-items":_vm.shownHandler,"click:row":_vm.openMessage},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataTypeText)+" ")]},proxy:true},{key:"header.from",fn:function(){return [_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.selectedAndShown.length,"icon":""},on:{"click":_vm.toggleArchivedSelected}},on),[(!!_vm.isArchiveMode)?_c('v-icon',[_vm._v(" mdi-package-up ")]):_c('v-icon',[_vm._v(" mdi-package-down ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!!_vm.isArchiveMode ? 'Unarchive selected' : 'Archive selected'))])])]},proxy:true},{key:"header.body",fn:function(){return [_c('base-button-group',{attrs:{"mandatory":""},model:{value:(_vm.isArchiveMode),callback:function ($$v) {_vm.isArchiveMode=$$v},expression:"isArchiveMode"}},[_c('base-button-plain',{attrs:{"small":""}},[_vm._v(" Inbox ")]),_c('base-button-plain',{attrs:{"small":""}},[_vm._v(" Archive ")])],1)]},proxy:true},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.thread[0].send_to_guardian.values.name)+" ")])]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('inbox-line',{attrs:{"meta":item}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.sent_date_time, _vm.timezone))+" "+_vm._s(_vm.formatTime(item.sent_date_time, _vm.timezone))+" ")])]}},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [(_vm.isIncoming(item.thread[0].type))?_c('span',[(item.thread[0].replied_to_staff)?_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.getAvatarBackgroundColor(item.thread[0].replied_to_staff)}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.formatStaffNameInitials(item.thread[0].replied_to_staff))+" ")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatStaffDisplay(item.thread[0].replied_to_staff))+" ")],1):_c('span',[_vm._v(" "+_vm._s('Unknown')+" ")])]}},{key:"item.replied",fn:function(ref){
var item = ref.item;
return [_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.thread[0].type !== 'incoming')?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"reply-icon"},[_vm._v(" mdi-arrow-left-top ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Replied")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleArchivedSingle(item)}}},on),[(!!_vm.isArchiveMode)?_c('v-icon',[_vm._v(" mdi-package-up ")]):_c('v-icon',[_vm._v(" mdi-package-down ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(!!_vm.isArchiveMode ? 'Unarchive' : 'Archive'))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }