import BaseAutoComplete from '@/components/base/BaseAutoComplete.vue';
import _Vue from 'vue';
import BaseButtonPlain from '@/components/base/BaseButtonPlain.vue';
import BaseButtonPrimary from '@/components/base/BaseButtonPrimary.vue';
import BaseButtonSecondary from '@/components/base/BaseButtonSecondary.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseChip from '@/components/base/BaseChip.vue';
import BaseDataTable from '@/components/base/BaseDataTable.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseMenu from '@/components/base/BaseMenu.vue';
import BaseNavigationDrawer from '@/components/base/BaseNavigationDrawer.vue';
import BaseTabs from '@/components/base/BaseTabs.vue';
import BaseTabsIconsAndText from '@/components/base/BaseTabsIconsAndText.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import BaseWidgetConfig from '@/components/base/BaseWidgetConfig.vue';
import DeemphasizedSelect from '@/components/base/DeemphasizedSelect.vue';
import EmphasizedSelect from '@/components/base/EmphasizedSelect.vue';
import Loading from '@/components/Loading.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseDateInput from '@/components/base/BaseDateInput.vue';
import BaseTimePicker from '@/components/base/BaseTimePicker.vue';
import BaseTimeInput from '@/components/base/BaseTimeInput.vue';
import BaseTextField from '@/components/base/BaseTextField.vue';
import DateFilterInput from '@/components/base/DateFilterInput.vue';
import BaseRightPopoutDrawer from '@/components/base/BaseRightPopoutDrawer.vue';
import BaseSnackbar from '@/components/base/BaseSnackbar.vue';
import PhoneNumberInput from '@/components/base/PhoneNumberInput.vue';
import BaseButtonGroup from '@/components/base/BaseButtonGroup.vue';
import DataCell from '@/components/base/DataCell.vue';
import BaseCog from '@/components/base/BaseCog.vue';
import BaseClose from '@/components/base/BaseClose.vue';
// register our vuetify component extensions globally so we don't have to manually declare them in all our
// components/views
export function BaseComponentsPlugin(Vue: typeof _Vue): void {
    Vue.component('base-button-group', BaseButtonGroup);
    Vue.component('base-button-plain', BaseButtonPlain);
    Vue.component('base-button-primary', BaseButtonPrimary);
    Vue.component('base-button-secondary', BaseButtonSecondary);
    Vue.component('base-card', BaseCard);
    Vue.component('base-checkbox', BaseCheckbox);
    Vue.component('base-chip', BaseChip);
    Vue.component('base-close', BaseClose);
    Vue.component('base-data-table', BaseDataTable);
    Vue.component('base-date-input', BaseDateInput);
    Vue.component('base-date-picker', BaseDatePicker);
    Vue.component('base-dialog', BaseDialog);
    Vue.component('base-menu', BaseMenu);
    Vue.component('base-navigation-drawer', BaseNavigationDrawer);
    Vue.component('base-right-popout-drawer', BaseRightPopoutDrawer);
    Vue.component('base-snackbar', BaseSnackbar);
    Vue.component('base-tabs', BaseTabs);
    Vue.component('base-tabs-icons-and-text', BaseTabsIconsAndText);
    Vue.component('base-text-field', BaseTextField);
    Vue.component('base-time-input', BaseTimeInput);
    Vue.component('base-time-picker', BaseTimePicker);
    Vue.component('base-tooltip', BaseTooltip);
    Vue.component('base-widget-config', BaseWidgetConfig);
    Vue.component('date-filter-input', DateFilterInput);
    Vue.component('de-emphasized-select', DeemphasizedSelect);
    Vue.component('emphasized-select', EmphasizedSelect);
    Vue.component('phone-number-input', PhoneNumberInput);
    // we're pretty much going to be using this all over the place
    Vue.component('loading', Loading);
    Vue.component('data-cell', DataCell);
    Vue.component('base-autocomplete', BaseAutoComplete);
    Vue.component('base-cog', BaseCog);
}
