
















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { ThreadMeta } from '../models/message';
import { TextMessage } from '../models/text';
import { Email } from '../models/email';
import { FacebookMessage } from '@/communications/messages/models/facebookMessage';

@Component
export default class InboxLine extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly meta!: ThreadMeta;

    private get message(): Email | TextMessage | FacebookMessage {
        return this.meta.thread[0];
    }

    private get mainClass() {
        return this.meta.is_read ? 'font-weight-normal' : 'font-weight-bold';
    }

    private get mainText() {
        if ('subject' in this.message) {
            return this.message.subject;
        } else if ('message' in this.message) {
            return this.message.message;
        } else {
            return this.message.data;
        }
    }

    private get secondaryText() {
        if ('subject' in this.message) {
            return this.message.html.replace(/<br\s*\/?>/gi, ' ').replace(/<p\s*.*>/gi, ' ');
        } else {
            return '';
        }
    }
}
