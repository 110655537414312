




























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { CustomDashboard, CustomDashboardElement } from '@/dashboards/models/custom-dashboards';
import { DashConstants, DashElementIdentifier } from '@/dashboards/models/custom-dashboards';
import CustomReports from '@/reports/custom/CustomReports.vue';
import { CustomDashboardService } from '@/dashboards/custom-dashboard-service';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import DashReportWrapper from '@/dashboards/components/DashReportWrapper.vue';
import { EditMode } from '@/core/edit-modes';

const dashService = new CustomDashboardService();
const appState = getModule(AppStateStore);

@Component({
    components: { DashReportWrapper, LocationDashboard, CustomReports }
})
export default class CustomDashboardComponent extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly dashElement!: CustomDashboardElement;
    @Prop() readonly dash!: CustomDashboard;
    // for use by a couple of Enrollment Dash components
    private tabEditMode = EditMode.SAVED;

    get isCustomReport() {
        return this.dashElement.identifier === DashElementIdentifier.CUSTOM_REPORT;
    }

    get isStandardReport() {
        return this.dashElement.identifier === DashElementIdentifier.STANDARD_REPORT;
    }

    get isActionDash() {
        return this.dashElement.identifier === DashElementIdentifier.ACTION_TABS_PLACEHOLDER;
    }

    get nonReportComponent() {
        return dashService.getComponent(this.dashElement);
    }

    get orgs() {
        return appState.storedCurrentOrg ? [appState.storedCurrentOrg.id] : [];
    }

    get span() {
        if (DashConstants.halfWidthElements.includes(this.dashElement.identifier)) {
            return 6;
        }
        return 12;
    }
}
