




































 import { LocaleMixin } from '@/locales/locale-mixin';
 import { Component, Mixins, Prop } from 'vue-property-decorator';
 import { getModule } from 'vuex-module-decorators';
 import { LoadingStore } from '@/store/loading-store';
 import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
 import { EnrollmentCenterSettings } from '@/enrollment-center/models/enrollment-center-models';
 import DashboardTasks from '@/dashboards/components/DashboardTasks.vue';
 import { AppStateStore } from '@/store/app-state-store';

 const loadingState = getModule(LoadingStore);
 const enrollmentSettingsStore = getModule(EnrollmentCenterSettingsStore);
 const appState = getModule(AppStateStore);

@Component({
     components: { DashboardTasks }
 })
 export default class EnrollmentCenterTasks extends Mixins(LocaleMixin) {
    @Prop({ default: false }) inContactMode!: boolean;

    private callCenterSettings: EnrollmentCenterSettings | null = null;
    private loadingKey = 'enrollmentCenterTasks';
    private totalCount: '...' | number = '...';
    private isExpanded = false;

    private get teamName() {
        return this.callCenterSettings?.name;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        this.isExpanded = this.inContactMode;
        this.callCenterSettings = await enrollmentSettingsStore.retrieve();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }
 }
