









































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { PeriodType } from '@/constants/stat-constants';
import {
    addDays,
    differenceInDaysLocal,
    formatDateForApi
} from '@/date-time/date-time-utils';

import { DateFilter, DateRangeKey, DateRangeOption } from '@/models/date-filter';
import { DashboardStore } from '@/store/dashboard-store';
import { getDashboardDateRange } from '@/date-time/date-filter-utils';
import BaseClose from '@/components/base/BaseClose.vue';

const dashboardStore = getModule(DashboardStore);

@Component({
    components: { BaseClose }
})
export default class DateRangeSelection extends Mixins(LocaleMixin) {
    // The ranges that the user can select
    @Prop({
        type: Array,
        required: true
    }) readonly dateRanges!: Array<DateRangeOption>;

    // Whether or not to save the date filter to vuex as the dashboard date filter.
    @Prop({
        type: Boolean,
        default: false
    }) readonly saveForDashboard!: boolean;

    @Prop({
        type: Boolean,
        default: false
    }) readonly lineLeaderEnroll!: boolean;

    // Represents if the custom date filter dialog is open.
    private customOpen = false;
    // The default date filter to pass for custom dates.
    // Also holds the date filter the user chose.
    private dateFilter: DateFilter = {
        periodType: PeriodType.DAILY,
        startDate: formatDateForApi(addDays(new Date(), -11)),
        endDate: formatDateForApi(new Date())
    };

    // The default range.
    private dateRange = DateRangeKey.LAST_7_DAYS;

    // Open the custom range dialog if the custom option is clicked again
    private openCustomIfClicked(key: string): void {
        if (key === DateRangeKey.CUSTOM) {
            this.customOpen = true;
        }
    }

    // When the date filter changes, save it to vuex.
    @Watch('dateFilter', { immediate: true, deep: true })
    private saveDateFilter(): void {
        if (this.saveForDashboard) {
            dashboardStore.storeDateFilter(this.dateFilter);
        }
    }

    @Watch('customOpen')
    private updateCustomDateFilter(): void {
        // Custom date filter was applied and dialog closed; save it
        if (!this.customOpen) {
            const dayRange = differenceInDaysLocal(this.dateFilter.startDate, this.dateFilter.endDate);
            if (dayRange < 15) {
                this.dateFilter.periodType = PeriodType.DAILY;
            } else if (dayRange < 7 * 14) {
                this.dateFilter.periodType = PeriodType.WEEKLY;
            } else if (dayRange < 360) {
                this.dateFilter.periodType = PeriodType.MONTHLY;
            } else {
                this.dateFilter.periodType = PeriodType.YEARLY;
            }
            this.saveDateFilter();
        }
    }

    // When the date range changes, update the dateFilter.
    @Watch('dateRange', { immediate: true })
    private saveDateRange(): void {
        if (this.dateRange === DateRangeKey.CUSTOM) {
            this.customOpen = true;
        } else {
            this.dateFilter = getDashboardDateRange(this.dateRange as DateRangeKey);
        }
    }
}
