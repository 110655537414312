import { daysAgo } from '@/date-time/date-time-utils';
import { StatusHistories, StatusHistory, StatusHistoryResult } from '@/families/models/status-histories';
import { differenceInDays, parseISO } from 'date-fns';
import { Status } from '@/families/models/status';
import { Family } from '@/families/models/family';
import { Child } from '@/families/models/child';

// utility method that checks for end_datetime on status histories and returns days based on that
function returnDays(history: StatusHistory): number {
    if (!history.end_datetime) {
        return daysAgo(history.start_datetime);
    } else {
        return differenceInDays(parseISO(history.end_datetime), parseISO(history.start_datetime));
    }
}

// method that returns the most advanced child by length of histories array
export function getMostAdvancedChild(familyStatusHistory: Array<StatusHistories>, statuses: Array<Status>): StatusHistories {
    const children = familyStatusHistory.filter(history => history.child);
    let maxStatusOrder = -1;
    let mostAdvancedChild: StatusHistories | null = null;
    if (children.length === 1) {
        return children[0];
    } else if (children.length > 1) {
        for (const child of children) {
            if (!child.child?.values.status) {
                continue;
            }

            for (const status of statuses) {
                if (status.id === child.child.values.status && status.order > maxStatusOrder) {
                    maxStatusOrder = status.order;
                    mostAdvancedChild = child;
                }

                if (mostAdvancedChild && status.id === child.child.values.status && status.order === maxStatusOrder) {
                    mostAdvancedChild = Date.parse(child.histories[child.histories.length - 1].start_datetime) < Date.parse(mostAdvancedChild.histories[mostAdvancedChild.histories.length - 1].start_datetime) ? child : mostAdvancedChild;
                }
            }
        }
    } else {
        // return lead item if no children exist
        mostAdvancedChild = familyStatusHistory[0];
    }
    return mostAdvancedChild as StatusHistories;
}

// method to get days in provided status from child status history
export function getDaysFromStatusHistory(child: StatusHistories | null, statusId: number): number | null {
    let days = null;
    // find the history that matches passed in statusId and hasn't been labelled deleted
    if (child) {
        const history = child.histories.find(history => history.status.id === statusId && !history.is_deleted);
        if (history) {
            days = returnDays(history);
        }
    }
    return days;
}

// filter out archived status from the status array
export function filterArchivedStatuses(statusArray: Array<Status>): Array<Status> {
    return statusArray.filter(status => !status.is_archive);
}

// get added date from lead
export function getAddedDate(family: Family, locale = 'en-US'): string {
    if (family.added_date) {
        return new Date(family.added_date).toLocaleDateString(locale);
    }
    return '';
}

// get lost opp date
export function getLostOppDate(familyStatusHistory: Array<StatusHistories>, locale = 'en-US'): string {
    const lead = familyStatusHistory.find(history => !history.child);
    if (lead && lead.histories.length) {
        return new Date(lead.histories[lead.histories.length - 1].start_datetime).toLocaleDateString(locale);
    }
    return 'Date: Bad Data';
}

export function getLostOppTitle(familyStatusHistory: Array<StatusHistories>): string | undefined {
    const lead = familyStatusHistory.find(history => !history.child);
    if (lead && lead.histories.length) {
        return lead.histories[lead.histories.length - 1].status.values.name;
    }
    return undefined;
}

// method to parse status tracking for family to return days in each status
export function getDaysFromStatusHistories(familyStatusHistory: Array<StatusHistories>, statusArray: Array<Status>): Array<StatusHistoryResult> {
    const statuses = filterArchivedStatuses(statusArray);
    const child = getMostAdvancedChild(familyStatusHistory, statuses);
    const results = [];
    for (const status of statuses) {
        results.push({
            id: status.id,
            name: status.name,
            days_in_status: getDaysFromStatusHistory(child, status.id),
            order: status.order,
            code: status.code
        });
    }
    return results;

}

/**
 * Return the ISO 9601 date time string of when the child got into their current status.
 *
 * @param child
 * @param familyStatusHistory
 */
export function getChildCurrentStatusDate(child: Child, familyStatusHistory: Array<StatusHistories>): string {
    // Find the child's status histories.
    const childStatusHistories = familyStatusHistory.find(histories => histories.child && histories.child.id === child.id);

    if (childStatusHistories) {
        // Find the current status in the histories.
        const childStatusHistory = childStatusHistories.histories.find(history => child.status && history.status.id === child.status.id);
        if (childStatusHistory) {
            // Return the ISO 9601 string.
            return childStatusHistory.start_datetime;
        }
    }

    // Nothing found, return blank.
    return '';
}
