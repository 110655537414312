
export interface SocialMediaLink {
    facebook: string;
    twitter: string;
    youtube: string;
    linkedin: string;
    instagram: string;
    pinterest: string;
    yelp: string | null; // corporate doesn't have yelp
}

export class SocialMediaLinkDto {
    facebook = '';
    twitter = '';
    youtube = '';
    linkedin = '';
    instagram = '';
    pinterest = '';
    yelp: string | null = null;
}

export enum SocialMediaRootURLConstants {
    FACEBOOK = 'www.facebook.com/',
    YOUTUBE = 'www.youtube.com/',
    LINKEDIN = 'www.linkedin.com/company/',
    INSTAGRAM = 'www.instagram.com/',
    TWITTER = 'www.twitter.com/#!/',
    PINTEREST = 'www.pinterest.com/',
    YELP = 'www.yelp.com/biz/'
}
