var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3"},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-row',[_c('v-col',{staticClass:"ml-4",attrs:{"cols":"2"}},[_c('base-button-primary',{on:{"click":_vm.openAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Campaign ")],1)],1)],1),_c('base-data-table',{staticClass:"truncated-columns",attrs:{"headers":_vm.dripCampaignHeaders,"items":_vm.dripCampaigns,"item-key":"id","must-sort":"","sort-by":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.last_edited_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLastEditedTime(item))+" ")]}},{key:"item.last_edited_by_staff",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.formatLastEditedBy(item)}},[_vm._v(" "+_vm._s(_vm.formatLastEditedBy(item))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('base-button-secondary',{attrs:{"block":""},on:{"click":function($event){return _vm.openView(item)}}},[_c('span',[_vm._v(_vm._s(_vm.canEdit(item) ? 'View/Edit' : 'View'))])])]}}])}),_c('view-drip-campaign',{attrs:{"campaign-id":_vm.viewCampaignId,"is-open":_vm.isViewOpen},on:_vm._d({},[_vm.closeEvent,_vm.closeView,_vm.updatedEvent,_vm.dripCampaignsUpdate])}),_c('manage-drip-campaign',{attrs:{"is-open":_vm.isAddOpen},on:_vm._d({},[_vm.closeEvent,_vm.closeAdd,_vm.updatedEvent,_vm.dripCampaignsUpdate])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }