import { AbstractCreateDto } from '@/models/abstract-dto';

export interface OrgLevelDtoInterface extends AbstractCreateDto {
    name: string;
    order: number;
}

export class OrgLevelDto implements OrgLevelDtoInterface {
    name = '';
    order = 0;
}
