


























































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { AvailabilityDayObject } from '../models/enrollment-center-location-models';

@Component
export default class ClassroomAvailCell extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() isEdit!: boolean;
    @Prop() nextDay!: string | null;
    @Prop() readonly value!: AvailabilityDayObject;

    private options = [
        {
            text: 'YES',
            value: true
        },
        {
            text: 'No',
            value: false
        }
    ];

    get dayObject(): AvailabilityDayObject {
        return this.value;
    }

    set dayObject(object: AvailabilityDayObject) {
        this.$emit('input', object);
    }

    get dayRules() {
        return [
            !this.dayObject.is_available || (this.dayObject.am_avail > 0 || this.dayObject.pm_avail > 0) || 'AM or PM must have availability'
        ];
    }

}
