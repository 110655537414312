













































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import type { Recording } from '@/communications/recordings/models/recording-models';

@Component({})
export default class CallRecordingActionButtons extends Mixins(LocaleMixin) {
    @Prop() recording!: Recording;

}
