




































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { ChangeStatus } from '@/families/change-status';
import { TaskResultsRepository } from '@/tasks/repositories/task-results-repository';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import { EventsRepository } from '@/families/events/repositories/events-repository';
import { Event, EventCreateDto } from '@/families/events/models/event-models';
import { formatClockTime, formatIsoDateTime, newDateForDatePicker } from '@/date-time/date-time-utils';
import { AuthStore } from '@/store/auth-store';
import type { Family } from '@/families/models/family';
import store from '@/store';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import { FeaturesStore } from '@/features/features-store';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const changeStatus = new ChangeStatus();
const eventsRepo = new EventsRepository();
const loadingState = getModule(LoadingStore);
const resultTypesRepository = new TaskResultsRepository();
const featuresState = getModule(FeaturesStore);

@Component({
    components: {
        BaseClose,
        SelectListEdit,
        CenterAscendingStaffList,
        StatusChangeSelect
    }
})
export default class LogEventModal extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly family!: Family;
    @Prop({ default: null }) readonly event!: Event | null;

    private loadingKey = 'logEventModal';
    private isDisabled = true;
    // For the snackbar when changes are made
    private isAdded = false;
    private isDeleted = false;
    private startDate = '';
    private startTime = '';
    private eventType=-1;
    private description='';
    private assignedTo: any = null;
    private familyInfo: Family | null = null;
    private resultTypes: Array<CrmTypeOption> = [];
    private textareaRules = [
        (v: string) => v.length <= 1200 || 'Message must be less than 1200 characters'
    ];

    get eventValue(): Event | null {
        return this.event;
    }

    get centerId() {
        if (this.family.center?.id) {
            return this.family.center.id;
        } else if (this.family.primary_guardian.center_id) {
            return this.family.primary_guardian.center_id;
        }

        return appState.storedCurrentCenter ? appState.storedCurrentCenter.id : 0;
    }

    get eventOptions() {
        return this.resultTypes.filter((e) => {
            if (this.event) {
                return e;
            } else {
                return e.is_log;
            }
        });
    }

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    @Watch('assignedTo')
    private checkAssignedTo() {
        this.validate();
    }

    @Watch('eventType')
    private checkEventType() {
        this.validate();
    }

    @Watch('startDate')
    private checkStartDate() {
        this.validate();
    }

    @Watch('startTime')
    private checkStartTime() {
        this.validate();
    }

    @Watch('description')
    private checkDescription() {
        this.validate();
    }

    @Watch('value')
    private checkValue() {
        if (this.value) {
            this.assignedTo = authState.userInfoObject?.id;
            this.familyInfo = this.family;
            this.startDate = newDateForDatePicker();
            this.startTime = formatClockTime(new Date());
        }
    }

    private get isReadOnly() {
        if (this.event) {
            return true;
        }
        return false;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        this.resultTypes = (await resultTypesRepository.getAll()).entities;
        loadingState.loadingDecrement(this.loadingKey);

        if (this.event) {
            this.familyInfo = this.family;
            this.description = this.event.description;
            this.eventType = this.event.type.id;
            this.startDate = this.formatDateYearFirst(this.event.event_time, authState.userTimeZone);
            this.startTime = this.formatMilitaryTime(this.event.event_time, authState.userTimeZone);
            this.assignedTo = this.event?.performed_by_staff?.id;
        }
    }

    private close() {
        changeStatus.resetQueue(this.family.id);
        this.familyInfo = null;
        this.eventType = -1;
        this.description = '';
        this.isDisabled = true;
        this.modelValue = false;
    }

    private async save() {
        this.isDisabled = true; // No more changes; disable the button
            loadingState.loadingIncrement(this.loadingKey);
            const eventDto: EventCreateDto = {
                added_by_staff: this.assignedTo,
                child: null,
                family: this.family.id,
                performed_by_staff: this.assignedTo,
                task: null,
                type: this.eventType,
                added_time: formatIsoDateTime(new Date()),
                event_time: formatIsoDateTime(this.startDate + ' ' + this.startTime, authState.userTimeZone)
            };
            if (this.description.length > 0) {
                eventDto.description = this.description;
            }

            if (this.event) {
                await eventsRepo.putOne(this.event.id, eventDto);
            } else {
                await eventsRepo.post(eventDto);
            }
            await changeStatus.writePendingChanges(this.family.id);

            this.$emit('taskAdded');

            loadingState.loadingDecrement(this.loadingKey);

            this.isAdded = true;
            this.close();
    }

    private async deleteEvent() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete this event?',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Delete',
            customClass: {
                cancelButton: 'swal2-primary-button-styling',
                confirmButton: 'swal2-secondary-button-styling',
                popup: 'narrow'
            }
        });

        if (result.isConfirmed) {
            this.isDisabled = true; // No more changes; disable the button
            loadingState.loadingIncrement(this.loadingKey);

            await eventsRepo.deleteBasic(this.event?.id!);
            await changeStatus.writePendingChanges(this.family.id);

            this.$emit('taskAdded');

            loadingState.loadingDecrement(this.loadingKey);

            this.isDeleted = true;
            this.close();
        }
    }

    private validate(): boolean {
         this.isDisabled = true;

         if (
             this.eventType !== -1 &&
             typeof this.assignedTo !== 'undefined' &&
             this.assignedTo !== null &&
             this.assignedTo !== '' &&
             typeof this.startDate !== 'undefined' &&
             this.startDate !== '' &&
             typeof this.startTime !== 'undefined' &&
             this.startTime !== '' &&
             this.description.length <= 1200
         ) {
             this.isDisabled = false;
             return true;
         }

        return false;
    }
}
