




















































































import AddEditMessageModal from '@/communications/messages/components/AddEditMessageModal.vue';
import ViewEmailModal from '@/communications/messages/components/ViewEmailModal.vue';
import { Email, EmailThread, IncomingEmail, OutgoingEmail } from '@/communications/messages/models/email';
import {
    MessageDirection,
    MessageLinkParams,
    OutgoingStatusString
} from '@/communications/messages/models/message';
import { EventTypes } from '@/constants/event-type-constants';
import { SortConstants } from '@/constants/sort-constants';
import { scrollToSelector } from '@/core/scroll-to';
import { sortByObjectProperty } from '@/core/sort-utils';
import { formatDateWithTimezone, getTimeZoneString, isAfterNow } from '@/date-time/date-time-utils';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import type { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { getReplyBody, getReplySubject } from '@/communications/messages/email-utils';
import { CommunicationTypes } from '@/communications/communication-constants';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { formatSendToGuardians } from '@/families/families-utils';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';

const authStore = getModule(AuthStore, store);
const emailsRepo = new EmailsRepository();
const emailsStore = getModule(EmailsStore);

@Component({
    components: {
        AddEditMessageModal,
        ViewEmailModal,
        SendMessageModal
    }
})
export default class FamilyActivityEmailTable extends Mixins(FamilyHubMixin, LocaleMixin, CrmBreakpointsMixin) {
    @Prop({ required: true }) readonly emailThreads!: Array<EmailThread>;
    @Prop({ required: true }) readonly family!: Family;

    private emailType = CommunicationTypes.EMAIL;
    private items: Array<Email> = [];
    private today = new Date();
    private timezone = authStore.userTimeZone;
    private showEditDialog = false;
    private showPreviewDialog = false;
    private selectedEmail: OutgoingEmail | Email | null = null;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;

    private startReplyEvent = EventTypes.START_REPLY;
    private repliedEvent = EventTypes.REPLIED;
    private closeReplyEvent = EventTypes.CLOSE;

    private replyActivated = false;
    private replyFamily: Family | null = null;
    private replySubject = '';
    private replyBody = '';
    private replyToId = 0;

    get statusWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
            case 'md':
                return '10';
            case 'lg':
                return '12ch';
            case 'xl':
                return '14ch';
        }
    }

    get sentWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
            case 'md':
                return '25ch';
            case 'lg':
                return '27ch';
            case 'xl':
                return '30ch';
        }
    }

    get toWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
            case 'md':
                return '40ch';
            case 'lg':
                return '42ch';
            case 'xl':
                return '45ch';
        }
    }

    get headers(): DataTableHeader[] {
        return [
            {
                text: 'Status',
                value: 'status',
                sortable: false,
                width: this.statusWidth
            },
            {
                text: 'Sent',
                value: 'sent_date_time',
                sortable: false,
                width: this.sentWidth
            },
            {
                text: 'To',
                value: 'send_to_guardian',
                sortable: false,
                width: this.toWidth
            },
            {
                text: 'Subject',
                value: 'subject',
                sortable: false,
                width: '50%'
            },
            {
                text: '',
                value: 'button',
                sortable: false,
                width: '23ch'
            }
        ];
    }

    getRowClass(item: Email): string {
        if (item.type === MessageDirection.INCOMING && !item.is_read) {
            return 'font-weight-bold';
        }
        return '';
    }

    @Watch('emailThreads', {
        immediate: true,
        deep: true
    })
    async threadsLoaded() {
        if (this.emailThreads.length) {
            this.setEmails(this.emailThreads.flat());
            if (this.$route.query[MessageLinkParams.EMAIL]) {
                const incomingId = Number(this.$route.query[MessageLinkParams.EMAIL]);
                for (const thread of this.emailThreads) {
                    if (thread.filter((email) => {
                        return email.type === MessageDirection.INCOMING && email.id === incomingId;
                    }).length) {
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        const query = Object.assign({}, this.$route.query);
                        delete query[MessageLinkParams.EMAIL];
                        await this.$router.replace({ query });
                        await scrollToSelector(this.$vuetify, '#email-thread-' + thread[0].id);
                    }
                }
            } else if (this.$route.query[MessageLinkParams.OUTBOUND_EMAIL]) {
                const emailId = Number(this.$route.query[MessageLinkParams.OUTBOUND_EMAIL]);
                for (const thread of this.emailThreads) {
                    if (thread.filter((email) => {
                        return email.type === MessageDirection.OUTGOING && email.id === emailId && email.status !== OutgoingStatusString.PENDING;
                    }).length) {
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await this.$nextTick();
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        const query = Object.assign({}, this.$route.query);
                        delete query[MessageLinkParams.OUTBOUND_EMAIL];
                        await this.$router.replace({ query });
                        await scrollToSelector(this.$vuetify, '#email-thread-' + thread[0].id);
                    }
                }
            }
        }
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    private isPending(email: Email): boolean {
        if (email.type !== MessageDirection.INCOMING && (email as OutgoingEmail).status === OutgoingStatusString.PENDING) {
            if (!email.sent_date_time) {
                return true;
            }
            return isAfterNow(email.sent_date_time);
        }
        return false;
    }

    private getStatusText(email: Email) {
        if (email.type === MessageDirection.INCOMING) {
            return 'Incoming';
        } else {
            if (this.isPending(email)) {
                return 'Pending';
            } else if (email.delivery_status) {
                return email.delivery_status;
            } else {
                return email.status;
            }
        }
    }

    private async handleAction(email: Email) {
        this.selectedEmail = email;
        this.showPreviewDialog = true;
        await this.markAsRead(email);
    }

    private formatSentDate(dueDateTime: string): string {
        try {
            const tz = getTimeZoneString(this.today, this.timezone, this.$i18n.locale);
            return `${this.formatDateTime(formatDateWithTimezone(dueDateTime, this.timezone))} ${tz}`;
        } catch (e) {
            return '';
        }
    }

     private getSendToNames(email: Email) {
        return formatSendToGuardians(email, this.family);
    }

    private pendingEmailUpdated() {
        this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
    }

    /**
     * Set the list of emails.
     */
    private setEmails(emails: Array<Email>) {
        this.items = emails.filter((email) => {
            if (!(email.type === MessageDirection.INCOMING) && (email as OutgoingEmail).status === OutgoingStatusString.PENDING) {
                if (!email.sent_date_time) {
                    return false;
                }
                return !isAfterNow(email.sent_date_time);
            }

            return true;
        });
        sortByObjectProperty(this.items, 'sent_date_time', SortConstants.DESC);
    }

    private async replySent(newOutgoing: OutgoingEmail, incomingId: number) {
        this.$emit(EventTypes.REPLIED, newOutgoing, incomingId);
    }

    private async startReply(email: IncomingEmail) {
        const familyId = email.send_to_guardian?.values.family_id;
        if (!familyId) {
            return;
        }
        this.replyFamily = this.family;
        this.replySubject = getReplySubject(email.subject);
        if (email.type === 'incoming') {
            this.replyBody = getReplyBody(email.html, email.send_to_guardian?.values.name ?? '');
            this.replyToId = email.id;
        } else {
            this.replyBody = getReplyBody(email.html, '');
        }
        this.replyActivated = true;
    }

    private getAllEmailsInThread(email: Email): Array<Email> | null {
        for (const thread of this.emailThreads) {
            if (thread.includes(email)) {
                return thread;
            }
        }
        return null;
    }

    private async markAsRead(email: Email) {
        if (email.type === MessageDirection.INCOMING && !email.is_read) {
            await emailsRepo.markRead(email.id, true);
            email.is_read = true;
            const thread = this.getAllEmailsInThread(email);
            if (thread && thread.length > 1) {
                for (const emails of thread) {
                    if (emails.id !== email.id && emails.type === MessageDirection.INCOMING && !emails.is_read) {
                        await emailsRepo.markRead(emails.id, true);
                        emails.is_read = true;
                    }
                }
            }
            emailsStore.decrementInboxCount();
            this.$emit(EventTypes.UPDATED);
        }
    }
}
