import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import {
    CustomField,
    CustomFieldCreateDto,
    CustomFieldUpdateDto
} from '@/crm-types/custom-fields/custom-fields-types';

export class CustomFieldsMapper extends AbstractMapper<CustomField> {
    toCreateDto(entity: CustomField): CustomFieldCreateDto {
        return {
            label: entity.label ?? '',
            order: entity.order ?? 0,
            is_client_required: entity.is_client_required ?? false,
            is_for_lead: entity.is_for_lead,
            is_for_child: entity.is_for_child,
            is_for_staff: entity.is_for_staff,
            type: entity.type.id
        };
    }

    toUpdateDto(entity: CustomField): CustomFieldUpdateDto {
        const dto = this.toCreateDto(entity);
        dto.id = entity.id;
        return dto as CustomFieldUpdateDto;
    }

}
