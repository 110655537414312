import { AbstractRepository } from '@/repositories/abstract-repository';
import { FacebookPageMapping, FacebookPageMappingDto } from '@/integrations/models/facebook';

export class FacebookMappingRepository extends AbstractRepository<FacebookPageMapping> {
    protected endpoint = 'facebook/pages';

    public async updateMapping(maps: Array<FacebookPageMappingDto>): Promise<void> {
        await this.client.patch(this.endpoint, maps);
    }
}
