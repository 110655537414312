import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { StoreModuleTypes } from '@/constants/store-constants';
import { Count } from '@/models/report/count';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import type { ApiParameters } from '@/repositories/abstract-repository';
import isEqual from 'lodash/isEqual';
import { CountByPeriods, OrganizationCounts, OrgPeriodCounts } from '@/models/report/period-statistics';
import { SortConstants } from '@/constants/sort-constants';

// Update stats if older than five minutes no matter what.
const updateThreshold = 1000 * 60 * 5;

export interface StatsState {
    stateStatusCounts: Array<Count>;
    statusCountsUpdated: number;
    statusCountsParams: ApiParameters;

    stateStatusCountPeriods: Array<CountByPeriods>;
    statusCountPeriodsUpdated: number;
    statusCountPeriodsParams: ApiParameters;

    stateStatusOrgPeriodCounts: Array<OrgPeriodCounts>;
    statusOrgPeriodCountsUpdated: number;
    statusOrgPeriodCountsParams: ApiParameters;

    stateTasksPastDueCounts: Array<OrganizationCounts>;
    tasksPastDueCountsUpdated: number;
    tasksPastDueCountsParams: ApiParameters;

    stateEstimatedRevenueCounts: Array<OrganizationCounts>;
    estimatedRevenueCountsUpdated: number;
    estimatedRevenueCountsParams: ApiParameters;

    stateFamilySourceCounts: Array<Count>;
    familySourceCountsUpdated: number;
    familySourceCountsParams: ApiParameters;

    stateInquiryTypeCounts: Array<Count>;
    inquiryTypeCountsUpdated: number;
    inquiryTypeCountsParams: ApiParameters;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.STATS
})
export class StatsStore extends VuexModule implements StatsState {
    currentDatetime = new Date();
    repository = new PeriodStatisticsRepository();
    stateStatusCounts: Array<Count> = [];
    statusCountsUpdated = 0;
    statusCountsParams: ApiParameters = {};

    stateStatusCountPeriods: Array<CountByPeriods> = [];
    statusCountPeriodsUpdated = 0;
    statusCountPeriodsParams: ApiParameters = {};

    stateStatusOrgPeriodCounts: Array<OrgPeriodCounts> = [];
    statusOrgPeriodCountsUpdated = 0;
    statusOrgPeriodCountsParams: ApiParameters = {};

    stateTasksPastDueCounts: Array<OrganizationCounts> = [];
    tasksPastDueCountsUpdated = 0;
    tasksPastDueCountsParams: ApiParameters = {};

    stateEstimatedRevenueCounts: Array<OrganizationCounts> = [];
    estimatedRevenueCountsUpdated = 0;
    estimatedRevenueCountsParams: ApiParameters = {};

    stateFamilySourceCounts: Array<Count> = [];
    familySourceCountsUpdated = 0;
    familySourceCountsParams: ApiParameters = {};

    stateInquiryTypeCounts: Array<Count> = [];
    inquiryTypeCountsUpdated = 0;
    inquiryTypeCountsParams: ApiParameters = {};

    @Mutation
    private storeStatusCounts(update: { counts: Array<Count>; params: ApiParameters }) {
        this.stateStatusCounts = update.counts;
        this.statusCountsParams = update.params;
        this.statusCountsUpdated = Date.now();
    }

    @Action({ commit: 'storeStatusCounts' })
    public async retrieveStatusCounts(params: ApiParameters = {}) {
        await this.repository.query('statuses', params);
        return { counts: this.repository.getGroupedByCount(), params: params };
    }

    @Action
    public async initStatusCounts(params: ApiParameters = {}) {
        if (!isEqual(params, this.statusCountsParams) || (Date.now() - this.statusCountsUpdated > updateThreshold)) {
            await this.retrieveStatusCounts(params);
        }
    }

    public get statusCounts() {
        return this.stateStatusCounts;
    }

    @Mutation
    private storeStatusCountPeriods(update: { counts: Array<CountByPeriods>; params: ApiParameters }) {
        this.stateStatusCountPeriods = update.counts;
        this.statusCountPeriodsParams = update.params;
        this.statusCountPeriodsUpdated = Date.now();
    }

    @Action({ commit: 'storeStatusCountPeriods' })
    public async retrieveStatusCountPeriods(params: ApiParameters = {}) {
        await this.repository.query('statuses', params);
        return { counts: this.repository.getGroupedByCountAndPeriod(), params: params };
    }

    @Action
    public async initStatusCountPeriods(params: ApiParameters = {}) {
        if (!isEqual(params, this.statusCountPeriodsParams) || (Date.now() - this.statusCountPeriodsUpdated > updateThreshold)) {
            await this.retrieveStatusCountPeriods(params);
        }
    }

    public get statusCountPeriods() {
        return this.stateStatusCountPeriods;
    }

    @Mutation
    private storeStatusOrgPeriodCounts(update: { counts: Array<OrgPeriodCounts>; params: ApiParameters }) {
        this.stateStatusOrgPeriodCounts = update.counts;
        this.statusCountPeriodsParams = update.params;
        this.statusCountPeriodsUpdated = Date.now();
    }

    @Action({ commit: 'storeStatusOrgPeriodCounts' })
    public async retrieveStatusOrgPeriodCounts(params: ApiParameters = {}) {
        await this.repository.query('statuses', params);
        return { counts: this.repository.getGroupedByOrgPeriodCount(), params: params };
    }

    @Action
    public async initStatusOrgPeriodCounts(params: ApiParameters = {}) {
        if (!isEqual(params, this.statusOrgPeriodCountsParams) || (Date.now() - this.statusOrgPeriodCountsUpdated > updateThreshold)) {
            await this.retrieveStatusOrgPeriodCounts(params);
        }
    }

    public get statusOrgPeriodCounts() {
        return this.stateStatusOrgPeriodCounts;
    }

    // Tasks Past Due
    @Mutation
    private storeTaskPastDueCounts(update: { counts: Array<OrganizationCounts>; params: ApiParameters }) {
        this.stateTasksPastDueCounts = update.counts;
        this.tasksPastDueCountsParams = update.params;
        this.tasksPastDueCountsUpdated = this.currentDatetime.getTime();
    }

    @Action({ commit: 'storeTaskPastDueCounts' })
    public async retrieveTasksPastDueCounts(
        params: ApiParameters = {
            due_date_end: this.currentDatetime.getFullYear() + '-' + (this.currentDatetime.getMonth() + 1) + '-' + this.currentDatetime.getDate(),
            included_completed: '0',
            include_cancelled: '0',
            group_by_center: '0'
        },
        orderCount: SortConstants | null = SortConstants.DESC,
        topN: number | null = 5) {
        await this.repository.query('tasks', params);
        return { counts: this.repository.getGroupedByTypeThenOrganization(orderCount, topN), params: params };
    }

    public get tasksPastDueCounts() {
        return this.stateTasksPastDueCounts;
    }

    // Estimated revenue
    @Mutation
    private storeEstimatedRevenueCounts(update: { counts: Array<OrganizationCounts>; params: ApiParameters }) {
        this.stateEstimatedRevenueCounts = update.counts;
        this.estimatedRevenueCountsParams = update.params;
        this.estimatedRevenueCountsUpdated = this.currentDatetime.getTime();
    }

    @Mutation
    public resetEstimatedRevenue() {
        this.estimatedRevenueCountsParams = {};
    }

    @Action({ commit: 'storeEstimatedRevenueCounts' })
    public async retrieveEstimatedRevenueCounts(params: ApiParameters = {}) {
        await this.repository.query('families/estimated-revenue', params);
        return { counts: this.repository.getGroupedByTypeThenOrganization(), params: params };
    }

    @Action
    public async initEstimatedRevenueCounts(params: ApiParameters = {}) {
        if (!isEqual(params, this.estimatedRevenueCountsParams) || (Date.now() - this.estimatedRevenueCountsUpdated > updateThreshold)) {
            await this.retrieveEstimatedRevenueCounts(params);
        }
    }

    public get estimatedRevenueCounts() {
        return this.stateEstimatedRevenueCounts;
    }

    // Family Sources.
    @Mutation
    private storeFamilySourceCounts(update: { counts: Array<Count>; params: ApiParameters }) {
        this.stateFamilySourceCounts = update.counts;
        this.familySourceCountsParams = update.params;
        this.familySourceCountsUpdated = Date.now();
    }

    @Action({ commit: 'storeFamilySourceCounts' })
    public async retrieveFamilySourceCounts(params: ApiParameters = {}) {
        await this.repository.query('families/source', params);
        return { counts: this.repository.getGroupedByCount(), params: params };
    }

    @Action
    public async initFamilySourceCounts(params: ApiParameters = {}) {
        if (!isEqual(params, this.familySourceCountsParams) || (Date.now() - this.familySourceCountsUpdated > updateThreshold)) {
            await this.retrieveFamilySourceCounts(params);
        }
    }

    public get familySourceCounts() {
        return this.stateFamilySourceCounts;
    }

    // Inquiry Types.
    @Mutation
    private storeInquiryTypeCounts(update: { counts: Array<Count>; params: ApiParameters }) {
        this.stateInquiryTypeCounts = update.counts;
        this.inquiryTypeCountsParams = update.params;
        this.inquiryTypeCountsUpdated = this.currentDatetime.getTime();
    }

    @Mutation
    public resetInquiryTypeCounts() {
        this.inquiryTypeCountsParams = {};
    }

    @Action({ commit: 'storeInquiryTypeCounts' })
    public async retrieveInquiryTypeCounts(params: ApiParameters = {}) {
        await this.repository.query('families/inquiry', params);
        return { counts: this.repository.getGroupedByCount(), params: params };
    }

    @Action
    public async initInquiryTypeCounts(params: ApiParameters = {}) {
        if (!isEqual(params, this.inquiryTypeCountsParams) || (Date.now() - this.inquiryTypeCountsUpdated > updateThreshold)) {
            await this.retrieveInquiryTypeCounts(params);
        }
    }

    public get inquiryTypeCounts() {
        return this.stateInquiryTypeCounts;
    }
}
