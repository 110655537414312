import { Action, Module } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { TemplateVariable } from '@/communications/templates/models/template-variable';
import { AbstractEntityState, AbstractApiStore } from '@/store/abstract-api-store';
import { TemplateVariablesRepository } from '@/communications/templates/repositories/template-variables-repository';
import store from '@/store';

export interface TemplateVariablesState extends AbstractEntityState<TemplateVariable> {
    entities: Array<TemplateVariable>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.TEMPLATE_VARIABLES
})

export class VariableTagsStore extends AbstractApiStore<TemplateVariable> implements TemplateVariablesState {
    readonly repository = new TemplateVariablesRepository();

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    // This inheritance also doesn't work right, ugh.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    // Unfortunately getters don't work with vuex-module-decorator inheritance for some reason.
    public get stored(): Array<TemplateVariable> {
        return this.entities;
    }
}
