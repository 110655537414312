import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Brand, BrandDtoInterface } from '@/organizations/brands/models/brand-models';

export class BrandMapper implements AbstractMapper<Brand> {
    toCreateDto(entity: Brand): BrandDtoInterface {
        return {
            domain: entity.domain,
            name: entity.name,
            pst_url: entity.pst_url
        };
    }

    toUpdateDto(entity: Brand): BrandDtoInterface {
        return this.toCreateDto(entity);
    }
}
