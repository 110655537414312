









import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
@Component
export default class BaseValueDisplay extends Mixins(LocaleMixin) {
    @Prop({ required: true }) displayValue!: string;
    @Prop({ required: true }) label!: string;
}
