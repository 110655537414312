import { Logo, LogoDto } from '@/logo/models/logo';

export class LogoMapper {
    toCreateDto(entity: Logo): LogoDto {
        return {
            file: entity.file,
            filename: entity.filename
        };
    }
}
