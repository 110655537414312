/**
 * Store for email templates
 */
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';

export interface EmailTemplatesState extends AbstractEntityState<MessageTemplate> {
    entities: Array<MessageTemplate>;
    centerTemplates: Array<MessageTemplate>;
    orgTemplates: Array<MessageTemplate>;
    centerId: number | null;
    orgId: number | null;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.EMAIL_TEMPLATES
})
export class EmailTemplatesStore extends AbstractApiStore<MessageTemplate> implements EmailTemplatesState {
    readonly repository = new EmailTemplatesRepository();
    centerId: number | null = null;
    orgId: number | null = null;
    centerTemplates: Array<MessageTemplate> = [];
    orgTemplates: Array<MessageTemplate> = [];

    // Boilerplate...
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    @Mutation
    clear() {
        this.centerTemplates = [];
        this.orgTemplates = [];
        this.centerId = null;
        this.orgId = null;
    }

    @Mutation
    public removeEntityWithId(id: number): void {
        super.removeEntityWithId(id);
        this.orgTemplates = this.orgTemplates.filter((template) => {
            return template.id !== id;
        });
        this.centerTemplates = this.centerTemplates.filter((template) => {
            return template.id !== id;
        });
    }

    @Mutation
    storeForCenter(payload: { centerId: number; templates: Array<MessageTemplate>}) {
        this.centerId = payload.centerId;
        this.centerTemplates = payload.templates;
    }

    @Mutation
    storeForOrg(payload: { orgId: number; templates: Array<MessageTemplate>}) {
        this.orgId = payload.orgId;
        this.orgTemplates = payload.templates;
    }

    @Action
    public async initForCenter(centerId: number) {
        if (this.centerId !== centerId || this.centerTemplates.length === 0) {
            await this.initPromise({
                hash: 'center-' + centerId,
                closure: async () => {
                    await this.retrieveForCenter(centerId);
                }
            });
        }
    }

    @Action
    public async initForOrg(orgId: number) {
        if (this.orgId !== orgId || this.orgTemplates.length === 0) {
            await this.initPromise({
                hash: 'org-' + orgId,
                closure: async () => {
                    await this.retrieveForOrg(orgId);
                }
            });
        }
    }

    @Action({ commit: 'storeForCenter' })
    public async retrieveForCenter(centerId: number) {
        const templates = (await this.repository.getAll({ center_id: centerId, no_descend: 'true', simple: 'true' })).entities;
        return { centerId, templates };
    }

    @Action({ commit: 'storeForOrg' })
    public async retrieveForOrg(orgId: number) {
        const templates = (await this.repository.getAll({ org_id: orgId, no_descend: 'true', simple: 'true' })).entities;
        return { orgId, templates };
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<MessageTemplate> {
        return this.entities;
    }
}
