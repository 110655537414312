
































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { OrgsStore } from '@/store/orgs-store';
import {
    Center,
    CenterSortKeys,
    CenterSortParameter,
    CenterTableData
} from '@/organizations/locations/models/center';
import { getPagination } from '@/core/datatables-utils';
import { ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LoadingStore } from '@/store/loading-store';
import { DataTableOptions } from '@/models/datatables';
import { SortConstants } from '@/constants/sort-constants';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { DataTableHeader } from 'vuetify';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { Timezone } from '@/core/timezones/timezone';
import { EventTypes } from '@/constants/event-type-constants';
import SearchTextField from '@/components/base/SearchTextField.vue';
import { AppStateStore } from '@/store/app-state-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const centersRepository = new CentersRepository();
const centersStore = getModule(CentersStore);
const featureStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const orgsStore = getModule(OrgsStore);
const settingsStore = getModule(InterfaceSettingsStore);
const timezonesStore = getModule(TimezonesStore);

@Component({
    components: {
        SearchTextField
    }
})
export default class Locations extends Mixins(LocaleMixin) {
    private allLocationsCount = 0;
    private areCentersLoading = false;
    private areOrgsLoaded = false;
    private currentItemCount = 1;
    private defaultSorting: CenterSortParameter = {
        sort_keys: [CenterSortKeys.NAME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private loadedCenters: Array<CenterTableData> = [];
    private loadingKey = 'location';
    private locationsSearchString = ''
    private lookupTable: Map<number, string> = new Map();
    private multipleReportingLevels = false;
    private options: DataTableOptions = {
        itemsPerPage: 25,
        page: 1
    };

    private pipelineValueSettingsShow = false;
    private reportingLevels: Map<number, string | undefined> = new Map();

    private timezoneToName(tzCode: string): string {
        for (const tz of this.timezones) {
            if (tz.code === tzCode) {
                return tz.name;
            }
        }
        return '';
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isLineLeaderEnroll(): boolean {
        return featureStore.isLineLeaderEnroll;
    }

    get isCorporateUser(): boolean {
        return authState.isCorporateUser;
    }

    get isSuperUser(): boolean {
        return authState.isSuperuser;
    }

    get isCenterUser(): boolean {
        return authState.isCenterStaff;
    }

    get locationHeaders(): Array<DataTableHeader> {
        const headers = [
            {
                text: 'Location Name',
                value: CenterSortKeys.NAME,
                class: 'location-col-header-name',
                sortable: true
            },
            {
                text: 'Street Address',
                value: CenterSortKeys.ADDRESS,
                class: 'location-col-header-street',
                sortable: false
            },
            {
                text: 'City',
                value: CenterSortKeys.LOCALITY,
                class: 'location-col-header-city',
                sortable: true
            },
            {
                text: this.$t('regionLabelAbv').toString(),
                value: CenterSortKeys.REGION,
                class: 'location-col-header-state',
                sortable: true
            },
            {
                text: this.$t('postcodeLabel').toString(),
                value: CenterSortKeys.POSTCODE,
                class: 'location-col-header-postalcode',
                sortable: true
            },
            {
                text: 'Phone',
                value: 'phone',
                class: 'location-col-header-phone',
                sortable: false
            },
            {
                text: 'Tuition',
                value: CenterSortKeys.TUITION,
                class: 'location-col-header-tuition',
                sortable: true
            },
            {
                text: 'Status',
                value: CenterSortKeys.STATUS,
                class: 'location-col-header-status',
                sortable: true
            }
        ];

        // Check if CRM+ mode is enabled and if there are multiple reporting levels
        if (this.multipleReportingLevels) {
            headers.splice(7, 0, {
                text: 'Reporting Lvl',
                value: CenterSortKeys.PARENT_ORG,
                class: 'location-col-header-reporting-lvl',
                sortable: true
            });
        } else {
            headers.splice(7, 0, {
                text: 'Time Zone',
                value: CenterSortKeys.TIMEZONE,
                class: 'location-col-header-timezone',
                sortable: true
            });
        }

        if (!this.isCenterUser) {
            headers.push({
                text: '',
                value: 'action',
                class: 'location-col-header-action',
                sortable: false
            });
        }

        if (!this.pipelineValueSettingsShow) {
            headers.splice(6, 1);
        }

        return headers;
    };

    // Show or hide inactive locations toggle
    get showInactiveToggle(): boolean {
        return centersStore.inactiveCentersCount > 0;
    }

    get showInactiveLocations(): boolean {
        return appState.showInactiveLocations;
    }

    set showInactiveLocations(flag: boolean) {
        appState.setShowInactiveLocations(flag);
    }

    get timezones(): Array<Timezone> {
        return timezonesStore.stored;
    }

    /**
     * When the options for the table change, load the centers based on the specifications.
     */
    @Watch('showInactiveLocations')
    private async onInactiveToggleChange() {
        await this.getCenters(getPagination(this.options), this.getCenterSortingFromOptions(this.options));
    }

    /**
     * When the options for the table change, load the centers based on the specifications.
     */
    @Watch('options')
    private async onOptionsChange(options: DataTableOptions) {
        if (this.areOrgsLoaded) {
            await this.getCenters(getPagination(options), this.getCenterSortingFromOptions(options));
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const orgsPromise = orgsStore.init();
        const timezonesPromise = timezonesStore.init();
        const settingsPromise = settingsStore.init();
        const centersPromise = centersStore.initInActiveCount();
        await orgsPromise;
        await timezonesPromise;
        await settingsPromise;
        await centersPromise;
        this.lookupTable = new Map();
        orgsStore.stored.forEach((element) => this.lookupTable.set(element.id, element.name));
        this.areOrgsLoaded = true;
        const tablePromise = this.getCenters(getPagination(this.options), this.getCenterSortingFromOptions(this.options));
        await tablePromise;
        this.multipleReportingLevels = (this.reportingLevels.size > 0) && this.hasCrmPlus;
        this.pipelineValueSettingsShow = settingsStore.stored.get(SettingNames.PIPELINE_VALUE)!.value as boolean;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private addLocation() {
        this.$emit(EventTypes.ADD_LOCATION_CLICKED);
    }

    private async doSearch(searchString: string) {
        this.locationsSearchString = searchString;
        if (this.locationsSearchString.length > 2 || this.locationsSearchString === '') {
            await this.getCenters(getPagination(this.options), this.getCenterSortingFromOptions(this.options));
        }
    }

    private editLocation(location: Center) {
        this.$emit(EventTypes.EDIT_LOCATION_CLICKED, location);
    }

    /**
     * Get the centers with pagination and sorting applied.
     *
     * @param pagination
     * @param sorting
     * @private
     */
    private async getCenters(
        pagination: ApiPagination = {
            limit: 25,
            offset: 0
        },
        sorting: CenterSortParameter | null = null
    ) {
        this.loadedCenters = [];
        const isLimited = !this.isCorporateUser && !this.isSuperUser;
        const filters = {
            only_accessible_centers: isLimited ? 'true' : 'false',
            simple: 'true',
            include_inactive: this.showInactiveLocations ? 'true' : 'false'
        } as ApiParameters;
        if (this.locationsSearchString.length > 2) {
            filters.search = this.locationsSearchString;
        }
        this.areCentersLoading = true;
        const data = await centersRepository.getCenters(pagination, sorting, filters);
        const loadedCenters = data.entities as Array<CenterTableData>;
        loadedCenters.forEach(element => {
            this.reportingLevels.set(
                element.id,
                this.lookupTable.get(element.parent_organization_id as number)
            );
            element.active_status = element.is_active ? 'Active' : 'Inactive';
            element.parent_organization_name = this.reportingLevels.get(element.id) as string;
            if (element.contact && element.contact.phone.number_e164) {
                element.phone = element.contact.phone.number_e164;
            }
        });
        this.loadedCenters = loadedCenters;
        this.currentItemCount = Number(data.count);
        if (!this.locationsSearchString) {
            this.allLocationsCount = this.currentItemCount;
        }
        const maxPage = Math.ceil(this.currentItemCount / this.options.itemsPerPage);
        if (this.options.page > maxPage) {
            this.options.page = Math.max(1, maxPage);
        }
        this.areCentersLoading = false;
    }

    /**
     * Get the sorting for to be applied to the centers request.
     *
     * @param options
     * @private
     */
    private getCenterSortingFromOptions(options: DataTableOptions): CenterSortParameter {
        let sorting = this.defaultSorting;
        if (options.sortBy && options.sortBy.length) {
            sorting = {
                sort_keys: [options.sortBy[0] as CenterSortKeys],
                sort_dir: [
                    options.sortDesc && options.sortDesc[0]
                        ? SortConstants.DESCENDING
                        : SortConstants.ASCENDING
                ]
            };
        }
        return sorting;
    }
}
