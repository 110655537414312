




































import { EventTypes } from '@/constants/event-type-constants';
import type { Family } from '@/families/models/family';
import { StatusesStore } from '@/families/store/statuses-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { AnyStoreKeys, AnyStoreUtils } from '@/utils/any-store-utils';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';

const anyStoreUtils = new AnyStoreUtils();
const statusesState = getModule(StatusesStore);
const loadingState = getModule(LoadingStore);

interface LocationLink {
    leadId: number;
    childName: string;
    locationId: number;
    locationName: string;
    status: string;
}

@Component({
    components: {}
})
export default class FamilyDataOtherLocations extends Mixins(LocaleMixin) {
    @Prop() family!: Family;

    private loadingKey = 'otherLocations';
    private locationLinks: Array<LocationLink> = [];
    private headers: Array<DataTableHeader> = [
        { text: 'Child', value: 'childName', width: '40%' },
        { text: 'Location', value: 'locationName', width: '40%' },
        { text: 'Status', value: 'status' }
    ];

    @Watch('family', { deep: true, immediate: true })
    private async familyChanged() {
        this.locationLinks.splice(0);
        if (!this.family.linked_families) {
            return;
        }
        for (const link of this.family.linked_families) {
            if (link.children.length > 0) {
                for (const child of link.children) {
                    if (link.center) {
                        this.locationLinks.push({
                            leadId: link.family.id,
                            childName: child.values.name,
                            locationId: link.center.id,
                            locationName: link.center.values.name,
                            status: this.getStatusById(child.values.status)
                        });
                    }
                }
            } else if (link.center) {
                this.locationLinks.push({
                    leadId: link.family.id,
                    childName: '',
                    locationName: link.center.values.name,
                    locationId: link.center.id,
                    status: this.getStatusById(link.lead.values.status)
                });
            }
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await statusesState.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

    // Open audit trail view for selected row
    showOtherFamily(itemRow: LocationLink) {
        anyStoreUtils.setStoreValue({ key: AnyStoreKeys.SHOW_OTHER_FAMILY_SNACKBAR, val: true });
        this.$router.push({ name: 'family-hub', params: { id: itemRow.leadId.toString() } });
        this.$emit(EventTypes.OTHER_LOCATION_CLICKED);
    }

    /**
     * Get a status by id, if it exists
     * @param statusId
     */
    public getStatusById(statusId: number): string {
        for (const status of statusesState.storedStatuses) {
            if (status.id === statusId) {
                return status.name;
            }
        }
        return 'Pending';
    }

}
