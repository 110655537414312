import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { CustomValuesUpdateDto, CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { Family } from '@/families/models/family';
import { Child, ChildCreateDtoInterface, ChildUpdateDtoInterface } from '@/families/models/child';

export interface CustomFamilyValueChanges {
    updates: CustomValuesUpdateDto;
    family: Family;
    child?: ChildCreateDtoInterface | ChildUpdateDtoInterface;
    field: CustomField;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CUSTOM_FAMILY_VALUES
})
export class CustomFamilyValuesChangesStore extends VuexModule {
    private changes: Set<CustomFamilyValueChanges> = new Set();

    @Mutation
    public storeChanges(changes: CustomFamilyValueChanges) {
        this.changes.add(changes);
    }

    @Mutation
    public replaceChanges(changes: CustomFamilyValueChanges) {
        const items = Array.from(this.changes).filter(change => change.child === changes.child && change.family === changes.family && change.field === changes.field);
        for (const item of items) {
            this.changes.delete(item);
        }
        this.changes.add(changes);
    }

    get changesForChild() {
        return (child: Child | ChildCreateDtoInterface | ChildUpdateDtoInterface): Array<CustomValuesUpdateDto> => {
            return Array.from(this.changes).filter(change => change.child === child).map(change => change.updates);
        };
    }

    get changesForFamily() {
        return (family: Family): Array<CustomValuesUpdateDto> => {
            return Array.from(this.changes).filter(change => change.family === family).map(change => change.updates);
        };
    }
}
