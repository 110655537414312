import { formatIsoDateTime } from '@/date-time/date-time-utils';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { Task, TaskCreateDto, TaskGroups, TasksSortParameter, TaskUpdateDto } from '@/tasks/models/task-models';
import { CrmApiResponse, HateoasLink } from '@/models/base';
import { getModule } from 'vuex-module-decorators';
import { PromiseStore } from '@/store/promise-store';

export interface TaskFiltersParameters {
    assigned_to?: number;
    center_ids?: string; // because, stupidly, it's a string of comma-separated ids
    org_id?: string;
    include_completed?: boolean;
    only_enrollment_team?: boolean;
    search?: string;
    family_id?: number;
}

const promisesStore = getModule(PromiseStore);

export class TasksRepository extends AbstractRepository<Task> {
    readonly endpoint = 'tasks';

    public async bulkCancel(taskIds: Array<number>): Promise<void> {
        await this.client.put(`${this.endpoint}/cancel`, {
            task_ids: taskIds
        });
    }

    public async bulkComplete(taskIds: Array<number>, resultId: number): Promise<void> {
        await this.client.put(`${this.endpoint}/complete`, {
            ids: taskIds,
            result_id: resultId
        });
    }

    public async cancel(task: TaskUpdateDto): Promise<Task> {
        task.is_canceled = true;
        return await this.client.put(`${this.endpoint}/${task.id}/cancel`, task);
    }

    public async create(task: TaskCreateDto): Promise<Task> {
        return await this.client.post(this.endpoint, task);
    }

    public async complete(task: TaskUpdateDto): Promise<Task> {
        task.is_completed = true;
        task.completed_date_time = formatIsoDateTime(new Date());
        return await this.client.put(`${this.endpoint}/${task.id}/complete`, task);
    }

    public async getNonTours(
        end: string | null = null,
        pagination: ApiPagination | null = null,
        extraParams: TaskFiltersParameters | null = null,
        sortParam: TasksSortParameter | null = null,
        force = false
    ): Promise<CrmApiResponse<Task>> {
        const params: ApiParameters = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            group_ids: [TaskGroups.EMAILS, TaskGroups.PHONE_CALLS, TaskGroups.TEXTS, TaskGroups.OTHER]
        };

        if (end) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params.due_before = end;
        }

        Object.assign(params, extraParams, sortParam);

        return await promisesStore.initPromise({
            hash: 'task-non-tours-' + JSON.stringify(params) + JSON.stringify(pagination),
            closure: async () => {
                return this.get(pagination, params);
            },
            force: force
        });
    }

    public async getNonToursInRange(
        start: string,
        end: string,
        pagination: ApiPagination | null = null,
        extraParams: TaskFiltersParameters | null = null,
        force = false
    ): Promise<CrmApiResponse<Task>> {
        const params: ApiParameters = {
            group_ids: [TaskGroups.EMAILS, TaskGroups.PHONE_CALLS, TaskGroups.TEXTS, TaskGroups.OTHER],
            due_after: start,
            due_before: end
        };

        Object.assign(params, extraParams);
        return await promisesStore.initPromise({
            hash: 'task-non-tours-in-range-' + JSON.stringify(params) + JSON.stringify(pagination),
            closure: async () => {
                return this.get(pagination, params);
            },
            force: force
        });
    }

    public async getPastDue(
        dueDate: string,
        orgId: number
    ): Promise<number> {
        const response = await this.client.get<Record<string, any>>(
            `${this.endpoint}/past_due`,
            {
                params: {
                    due_date_end: dueDate,
                    org_id: orgId
                }
            });

        return response.data.count;
    }

    public async getToursInRange(
        start: string,
        end: string,
        pagination: ApiPagination | null = null,
        extraParams: TaskFiltersParameters | null = null,
        force = false
    ): Promise<CrmApiResponse<Task>> {
        const params = {
            group_ids: [TaskGroups.TOURS, TaskGroups.MEETINGS],
            due_after: start,
            due_before: end,
            include_completed: 'true',
            include_pending_families: 'true'
        };

        Object.assign(params, extraParams);

        if (!pagination) {
            return await promisesStore.initPromise({
                hash: 'task-tours-in-range-' + JSON.stringify(params) + '-' + JSON.stringify(pagination),
                closure: async () => {
                    return this.getAll(params);
                },
                force: force
            });
        }

        return await promisesStore.initPromise({
            hash: 'task-tours-in-range-' + JSON.stringify(params) + '-' + JSON.stringify(pagination),
            closure: async () => {
                return this.get(pagination, params);
            },
            force: force
        });
    }

    public async getTours(
        end: string | null = null,
        pagination: ApiPagination | null = null,
        extraParams: TaskFiltersParameters | null = null,
        sortParam: TasksSortParameter | null = null,
        force = false
    ): Promise<CrmApiResponse<Task>> {
        const params: ApiParameters = {
            group_ids: [TaskGroups.TOURS, TaskGroups.MEETINGS],
            include_completed: 'true'
        };
        if (end) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params.due_before = end;
        }

        Object.assign(params, extraParams, sortParam);

        return await promisesStore.initPromise({
            hash: 'task-tours-' + JSON.stringify(params) + '-' + JSON.stringify(pagination),
            closure: async () => {
                return this.get(pagination, params);
            },
            force: force
        });
    }

    public async reassignTasks(fromUserId: number, orgId: number, toUserId: number): Promise<Array<HateoasLink>> {
        const response = await this.client.put<Array<HateoasLink>>(`${this.endpoint}/reassign`, {
            assigned_to_staff: fromUserId,
            org_id: orgId,
            reassign_to_staff: toUserId
        });

        return response.data;
    }

    public async update(task: TaskUpdateDto): Promise<Task> {
        return this.putOne(task.id, task);
    }

    public async cancelMany(taskIds: Array<number>): Promise<Array<any>> {
        return await this.client.put(`${this.endpoint}/cancel`, { task_ids: taskIds });
    }
}
