










import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import { NylasAuthCodeObject, nylasStoredEvent, nylasStoredStartEvent, nylasCalendarSelectEvent } from '@/integrations/models/nylas';

const nylasRepo = new NylasRepository();

@Component
export default class NylasAuthRedirect extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isForCalendar!: boolean;

    async mounted() {
        const codeObject = {
            code: this.$route.query.code ?? '',
            state: this.$route.query.state ?? ''
        } as NylasAuthCodeObject;

        if (!this.isForCalendar) {
            window.opener.postMessage({ event: nylasStoredStartEvent }, window.origin);
            await nylasRepo.storeAuthCode({ code: codeObject.code, uuid: codeObject.state });
            window.opener.postMessage({ event: nylasStoredEvent }, window.origin);
            window.close();
        } else {
            window.opener.postMessage({ event: nylasCalendarSelectEvent, data: codeObject }, window.origin);
            await new Promise(resolve => setTimeout(resolve, 500));
            window.close();
        }
    }
}
