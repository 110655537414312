














import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { TaskGroupsStore } from '@/tasks/stores/task-groups-store';
import { getModule } from 'vuex-module-decorators';

const taskGroupStore = getModule(TaskGroupsStore);

@Component
export default class TaskResultSelect extends Mixins(LocaleMixin) {
    // Whether or not the select list should hide hints and errors.
    @Prop({
        type: Boolean,
        default: false
    }) readonly hideDetails!: boolean;

    // The label for the select list.
    @Prop({
        type: String,
        default: 'Result'
    }) readonly label!: string;

    // The placeholder for the select list.
    @Prop({
        type: String,
        default: ''
    }) readonly placeholder!: string;

    // The default selected task result type
    @Prop({
        type: Number,
        default: null
    }) readonly selectedId!: number | null;

    // The group the task is is. This is used for determining the preferred results.
    @Prop() taskGroupId!: number;
    // Is this item disabled?
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: true }) clearable!: boolean;

    private items: Array<any> = [];

    async created() {
        await taskGroupStore.init();
        const preferredResults: Array<any> = [];
        const otherResults: Array<any> = [];
        const sortedOptions: Array<number> = [];
        taskGroupStore.entities.forEach((taskGroupEntity) => {
            for (const result of taskGroupEntity.preferred_result_types) {
                if (!result.values.is_task_result) {
                    continue;
                }
                if (sortedOptions.includes(result.id as number)) {
                    continue;
                }
                // Don't show hidden or inactive options
                if ((result.values.is_hidden || !result.values.is_active) && result.id !== this.selectedId) {
                    continue;
                }
                if (taskGroupEntity.id === this.taskGroupId) {
                    preferredResults.push({
                        text: result.values.value,
                        value: result.id
                    });
                } else {
                    otherResults.push({
                        text: result.values.value,
                        value: result.id
                    });
                }
                sortedOptions.push(result.id as number);
            }
        });
        this.items = [
            { header: 'Preferred Results' },
            preferredResults,
            { divider: true },
            { header: 'Other Results' },
            otherResults
        ].flat();
    }

    get selected() {
        return this.selectedId;
    }

    set selected(selectedOption) {
        this.$emit('input', selectedOption);
    }
}
