import Vue from 'vue';
import VueI18n, { LocaleMessages, NumberFormats } from 'vue-i18n';
import { baseLocales } from '@/locales/locales';

Vue.use(VueI18n);

const messages: LocaleMessages = {};
const numberFormats: NumberFormats = {};

for (const [localeName, locale] of Object.entries(baseLocales)) {
    messages[localeName] = locale.messages;
    numberFormats[localeName] = locale.numberFormats;
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
    messages,
    numberFormats
});
