import { locale as usLocale } from './en-US';
import { en as vuetifyLocale } from 'vuetify/src/locale';
import { LocaleDefinition } from '@/locales/locale-definition';

export const locale: LocaleDefinition = {
    crmCountry: 'uk',
    fcLocale: 'en-gb',
    label: 'United Kingdom',
    distanceUnit: 'km',
    firstDayOfWeek: 1,
    phoneRegion: 'GB',
    dateFormat: 'dd/MM/yyyy',
    yearFirstDateFormat: 'yyyy-MM-dd',
    shortDateYearFormat: 'dd/MM/yy',
    shortDateFormat: 'dd/MM',
    timeFormat: 'hh:mm a',
    shortTimeFormat: 'hh:mm',
    militaryTimeFormat: 'HH:mm',
    numberFormats: {
        currency: {
            style: 'currency', currency: 'GBP'
        },
        currencyWhole: {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }
    },
    messages: {
        $vuetify: {
            ...vuetifyLocale
        },
        ...usLocale.messages,
        center: 'centre',
        enrollment: 'enrolment',
        hello: 'cheers, {user}',
        welcome: 'Welcome to ChildcareCRM - {level}',
        dashboardRevenueBold: 'Total Estimated Lifetime Value',
        dashboardRevenue: ' of Families not yet Enrolled/Waitlisted',
        regionLabel: 'County',
        regionLabelAbv: 'CO',
        postcodeLabel: 'Postcode',
        corporate: 'Corporate',
        dashboard: 'Dashboard',
        supportEmail: 'support@golineleader.com',
        insight: 'Insight'
    }
};
