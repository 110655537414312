





















import { DashboardGaugeMixin } from '@/dashboards/dashboard-gauge-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { DashboardStore } from '@/store/dashboard-store';
import { AppStateStore } from '@/store/app-state-store';
import Highcharts, { YAxisOptions } from 'highcharts';
import {
    getSolidGaugeSeriesOptions,
    getConversionSuccessChartOptions, getGaugeStopColors
} from '@/charts/chart-utils';
import { LoadingStore } from '@/store/loading-store';
import { getScheduledToCompleted } from '@/dashboards/corp-dashboard-stats';
import { getPreviousDateFilter } from '@/date-time/date-filter-utils';
import GaugeComparison from '@/dashboards/components/GaugeComparison.vue';
import { DateFilter } from '@/models/date-filter';
import { PeriodType } from '@/constants/stat-constants';
import { diffDays } from '@/date-time/date-time-utils';

const dashboardState = getModule(DashboardStore);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
@Component({
    components: { GaugeComparison }
})
export default class ScheduledToursToComplGaugeGraph extends Mixins(DashboardGaugeMixin, LocaleMixin) {
    @Prop({ type: String }) readonly graphId!: undefined;

    private isMounted = false;
    private loadingKey = 'conversionSuccessInitial';
    private diffPct = 0;
    chartOptions: Highcharts.Options = Highcharts.merge(getConversionSuccessChartOptions(), {
        exporting: {
            chartOptions: {
                title: {
                    text: 'Scheduled Tours Getting Completed'
                }
            }
        }
    });

    get org() {
        return appState.storedCurrentOrg;
    }

    get dateRange() {
        return dashboardState.storedDateFilter;
    }

    async created() {
        await this.updateChart();
    }

    mounted() {
        this.isMounted = true;
    }

    @Watch('org', { deep: true })
    async orgChanged() {
        await this.updateChart();
    }

    @Watch('dateRange', { deep: true })
    async dateRangeChanged() {
        await this.updateChart();
    }

    private async updateChart() {
        if (!this.org) {
            return;
        }
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
            return;
        }

        (this.chartOptions.yAxis as YAxisOptions).stops = getGaugeStopColors([0.0, 0.70, 0.84, 0.95]);

        loadingState.loadingIncrement(this.loadingKey);

        const org = this.org.id;
        let mainPct = 0;
        let prevPct = 0;

        const dateRange: DateFilter = {
            startDate: getPreviousDateFilter(this.dateRange).startDate,
            endDate: this.dateRange.endDate,
            periodType: PeriodType.CUSTOM,
            periodLength: diffDays(this.dateRange.endDate, this.dateRange.startDate) + 1
        };

        getScheduledToCompleted(dateRange, org).then((value) => {
            prevPct = value[0];
            mainPct = value[1];
            this.chartOptions.series = [getSolidGaugeSeriesOptions('Conversion Rate from Tour Scheduled to Tour Completed', mainPct)];
            this.diffPct = mainPct - prevPct;

            loadingState.loadingDecrement(this.loadingKey);
        });

    }
}
