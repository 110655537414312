






































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { MessageDirection, OutgoingStatusString } from '../models/message';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { isAfterNow } from '@/date-time/date-time-utils';
import type { FacebookMessage, FacebookOutgoingMessage } from '@/communications/messages/models/facebookMessage';
import store from '@/store';

const authState = getModule(AuthStore, store);

@Component({
    components: {}
})
export default class ViewSingleFBMessage extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly fbMessage!: FacebookMessage;
    @Prop({ default: false }) readonly noDate!: boolean;

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    get isIncoming() {
        return this.fbMessage.type === MessageDirection.INCOMING;
    }

    get sentDateTime() {
        return new Date(this.fbMessage.sent_date_time as string);
    }

    get formattedDate() {
        return this.formatDate(this.sentDateTime, this.timezone);
    }

    get formattedTime() {
        return this.formatTime(this.sentDateTime, this.timezone);
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    get isPending() {
        if (!this.isIncoming && (this.fbMessage as FacebookOutgoingMessage).status === OutgoingStatusString.PENDING) {
            if (!this.fbMessage.sent_date_time) {
                return true;
            }

            return isAfterNow(this.fbMessage.sent_date_time);
        }

        return false;
    }
}
