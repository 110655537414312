import { Component, Vue } from 'vue-property-decorator';

@Component
export class ScrollLoaderMixin extends Vue {
    protected checkBottom(callback: () => void) {
        window.addEventListener('scroll', () => {
            const scrollY = window.scrollY;
            const visible = document.documentElement.clientHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const bottomOfPage = visible + scrollY >= pageHeight;
            const isBottom = bottomOfPage || pageHeight < visible;
            if (isBottom) {
                callback();
            }
        });
    }
}
