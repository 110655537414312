



















import { FeaturesStore } from '@/features/features-store';
import { ApiParameters } from '@/repositories/abstract-repository';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts, { XAxisOptions } from 'highcharts';
import {
    getBaseBarChartOptions,
    countsFromOrgCountsGroupedByNameToNamedSeries,
    orgCountNamesToXAxis, getMonochromeColorsWithLimits, getMonochromeColors
} from '@/charts/chart-utils';
import { formatDateForApi } from '@/date-time/date-time-utils';
import { SortConstants } from '@/constants/sort-constants';
import { LIGHTEST_BLUE, BASE_DARK_BLUE, LINE_LEADER_PURPLE } from '@/core/style-utils';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';

const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);
const loadingKey = 'tasksPastDueChart';
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {}
})
export default class TasksPastDueChart extends Mixins(LocaleMixin) {
    @Prop({ type: Array, required: true }) readonly orgs!: number[];
    @Prop({ default: false }) custDashMode!: boolean;

    private isMounted = false;
    private chartOptions: Highcharts.Options = {};
    private graphId = 'past-due-graph';

    @Watch('orgs')
    async orgsUpdated() {
        await this.updateTasksPastDueCounts();
    }

    async created() {
        await featuresStore.init();
        await interfaceSettingsStore.init();
        if (this.orgs.length !== 0) {
            await this.updateTasksPastDueCounts();
        }
    }

    /**
     * If the v-if="isMounted" to render the chart after everything else is mounted, or widths get out of whack.
     * https://github.com/highcharts/highcharts-vue/issues/107
     */
    public async mounted() {
        await featuresStore.init();
        this.chartOptions = Highcharts.merge(await getBaseBarChartOptions(), {
            chart: {
                height: '450px'
            },
            title: {
                align: 'left',
                margin: 30,
                text: 'Past Due',
                style: {
                    fontFamily: 'Manrope, sans-serif',
                    fontSize: featuresStore.isLineLeaderEnroll ? '17px' : '22.5px',
                    fontWeight: featuresStore.isLineLeaderEnroll ? '600' : '500'
                },
                x: -10
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: 'Past Due'
                    }
                }
            },
            plotOptions: {
                bar: {
                    colorByPoint: true
                }
            }
        });
        this.isMounted = true;
    }

    async updateTasksPastDueCounts() {
        loadingState.loadingIncrement(loadingKey);
        const endDate = formatDateForApi(new Date());
        const params = {
            due_date_end: endDate,
            include_completed: '0',
            include_cancelled: '0',
            group_by_center: 0,
            org_ids: this.orgs
        } as ApiParameters;
        await statsState.retrieveTasksPastDueCounts(params, SortConstants.DESC, 5);
        this.updateChart();
        loadingState.loadingDecrement(loadingKey);
    }

    private getMaxY(): number {
        // We need to find the biggest number of tasks past due and set the max for the y-axis.
        // If we do not do this, initial page load will not render correctly for some reason.
        let maxY = 0;

        statsState.tasksPastDueCounts.forEach((orgCounts) => {
            orgCounts.counts.forEach((count) => {
                if (count.total > maxY) {
                    maxY = count.total;
                }
            });
        });

        return maxY;
    }

    private updateChart() {
        if (!this.$refs.chart) {
            return;
        }
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;

        // Clear out previous series data. If we don't, things get weird...
        for (let i = 0; i < (this.$refs.chart as any).chart.series.length; i++) {
            (this.$refs.chart as any).chart.series[0].remove(true);
        }

        // Set the categories.
        (this.chartOptions.xAxis as XAxisOptions).categories = orgCountNamesToXAxis(statsState.tasksPastDueCounts);
        const gradientCount = (this.chartOptions.xAxis as XAxisOptions).categories!.length;
        let color = isLineLeaderEnroll ? LINE_LEADER_PURPLE : BASE_DARK_BLUE;
        if (interfaceSettingsStore.hasWhiteLabel) {
            color = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
        }

        // Set the series data.
        if (this.orgs.length === 1) {
            this.chartOptions.series = countsFromOrgCountsGroupedByNameToNamedSeries(statsState.tasksPastDueCounts, color, true, false, isLineLeaderEnroll);
        } else {
            this.chartOptions.series = countsFromOrgCountsGroupedByNameToNamedSeries(statsState.tasksPastDueCounts, color, false, false, isLineLeaderEnroll);
        }
        this.chartOptions.plotOptions!.bar!.colors = isLineLeaderEnroll
            ? getMonochromeColors(color, true)
            : getMonochromeColorsWithLimits(LIGHTEST_BLUE, color, gradientCount);

        // Update and force another redraw.
        (this.$refs.chart as any).chart.update(this.chartOptions, true, true);
        (this.$refs.chart as any).chart.redraw(false);
    }
}
