import { StoreModuleTypes } from '@/constants/store-constants';
import { Brand } from '@/organizations/brands/models/brand-models';
import { BrandsRepository } from '@/organizations/brands/repositories/brands-repository';
import store from '@/store';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';

export interface BrandsState extends AbstractEntityState<Brand> {
    entities: Array<Brand>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.BRANDS
})
export class BrandsStore extends AbstractApiStore<Brand> implements BrandsState {
    readonly repository = new BrandsRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    public get stored(): Array<Brand> {
        return this.entities;
    }

    @Mutation
    public storeBrands(updatedArray: Array<Brand>): void {
        this.entities = updatedArray;
    }
}
