


























































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import {
    LandingPageUpdateDtoInterface
} from '@/automation/landing-pages/models/landing-pages-models';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingStore = getModule(LoadingStore);
const landingPagesRepo = new LandingPagesRepository();

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class AddLandingPageModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly name!: string;
    @Prop({ required: true }) readonly formId!: number;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'editPageNameModal';
    private showModal = false;
    private pageDto: LandingPageUpdateDtoInterface = {
        name: this.name
    }

    private valid = false;

    @Watch('showModal')
    valueChanged() {
        if (this.showModal) {
            this.pageDto.name = this.name;
            this.$nextTick(() => {
                // Validate form when we popup the modal.
                this.form.validate();
            });
        }
        loadingStore.loadingStop(this.loadingKey);
    }

    private close() {
        this.form.reset();
        this.showModal = false;
    }

    private async updateName() {
        loadingStore.loadingIncrement(this.loadingKey);
        await landingPagesRepo.updatePage(this.formId, this.pageDto);
        this.$emit(EventTypes.INPUT);
        loadingStore.loadingDecrement(this.loadingKey);
        this.close();
    }
}
