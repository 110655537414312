








































































import store from '@/store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { IncontactRepository } from '@/integrations/repositories/incontact-repository';
import { BasicFamily, Family } from '@/families/models/family';
import { DataTableHeader } from 'vuetify';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import { FamiliesRepository } from '@/families/repositories/families-repository';

const loadingState = getModule(LoadingStore);
const appState = getModule(AppStateStore);
const searchRepo = new IncontactRepository();
const layoutTabsState = getModule(LayoutTabsStore, store);
const familiesRepo = new FamiliesRepository();

@Component
export default class SixAgentContactSearch extends Mixins(LocaleMixin) {
    @Prop() readonly contactId!: string;
    @Prop() readonly ani!: string | null;
    @Prop() readonly workItemPayload!: string | null;
    @Prop() readonly email!: string | null;
    @Prop() readonly familyId!: string | null;
    @Prop() readonly schoolNum!: string | null;
    private loadingKey = 'contactSearch';
    private searchComplete = false;
    private matches: Array<BasicFamily> = [];
    private resultHeaders: Array<DataTableHeader> = [
        {
            text: 'Guardian',
            value: 'guardian'
        },
        {
            text: 'Child(ren)',
            value: 'childrenNames'
        },
        {
            text: 'Location',
            value: 'centerName'
        },
        {
            text: 'Phone',
            value: 'phoneNumber',
            width: '1%'
        },
        {
            text: '',
            value: 'goToButton',
            width: '1%'
        }
    ];

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.familyId || this.workItemPayload) {
            const familyId = this.familyId || this.workItemPayload!.split('|')[0];
            let family;
            try {
                family = await familiesRepo.getByIdSilent(Number(familyId));
            } catch (ex) {
            }
            if (family) {
                if (family.status) {
                    await this.$router.push({ name: 'family-hub', params: { id: familyId } });
                    loadingState.loadingDecrement(this.loadingKey);
                    return;
                } else {
                    appState.setDefaultSearchStringPendingLeadEt(this.getGuardianName(family));
                    await this.goToEcc();
                }
            }
            this.matches = [];
        } else if (this.contactId) {
            this.matches = await searchRepo.searchContacts(this.contactId, this.ani ?? '', this.email ?? '');
        } else {
            this.matches = [];
        }
        if (this.matches.length === 0) {
            appState.setSearchNeedsFocus(true);
            const openAddFamily = this.email ? '1' : '';
            const schoolNum = this.schoolNum ?? '';
            await this.$router.push({ name: 'dashboard-et', params: { inContactMode: '1', openAddFamily, schoolNum } });
        } else if (this.matches.length === 1) {
            await this.$router.push({ name: 'family-hub', params: { id: String(this.matches[0].id) } });
        }
        this.searchComplete = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    async goToEcc() {
        const schoolNum = this.schoolNum ?? '';
        await this.$router.push({ name: 'dashboard-et', params: { schoolNum } });
    }

    async goToFamily(family: BasicFamily) {
        await layoutTabsState.addTab({ routeName: 'family-hub', routeParams: { id: String(family.id) }, goTo: true });
    }

    getChildrenNames(family: BasicFamily) {
        return family.children.map((child) => {
            return child.first_name + ' ' + child.last_name;
        }).join(', ');
    }

    getGuardianName(family: Family) {
        return `${family.primary_guardian.first_name} ${family.primary_guardian.last_name}`;
    }
}
