import { createAuthClient, createClient } from '@/transport/create-client';
import { baseUrl } from '@/core/base-url';
import { AuthStore } from '@/store/auth-store';
import { User } from '@/staff/models/user';

const loginEndpoint = '/api/v3/login';
const loginSsoEndpoint = '/api/v3/sso/login';
const refreshEndpoint = '/api/v3/login/refresh';
const legacyLogin = '/blank';
const logoutEndpoint = '/logout';
const superuserSwitch = '/superuser/switch-database/';

interface LoginResponse {
    token: string;
    refresh_token: string;
    links: Array<{ href: string; rel: string; type: string }>;
}

interface RefreshResponse {
    token: string;
    refresh_token: string;
}

export interface LoginReturn {
    success: boolean;
    token?: string;
    refreshToken?: string;
    userInfo?: User;
    errorTitle?: string;
    errorDetail?: string;
}

export interface RefreshReturn {
    success: boolean;
    token?: string;
    refreshToken?: string;
}

export async function refreshLogin(refreshToken: string): Promise<RefreshReturn> {
    const transport = createAuthClient(baseUrl);
    try {
        const refreshResponse = await transport.post<RefreshResponse>(refreshEndpoint, {
            refresh_token: refreshToken
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return { success: true, token: refreshResponse.data.token, refreshToken: refreshResponse.data.refresh_token };
    } catch (error) {
        return { success: false };
    }
}

export async function doLegacyLogin(token: string): Promise<void> {
    const legacyTransport = createAuthClient(baseUrl, true);
    await legacyTransport.post<string>(logoutEndpoint);
    await legacyTransport.get(legacyLogin, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
}

export async function doDbSwitch(clientId: number): Promise<void> {
    const legacyTransport = createAuthClient(baseUrl, true);
    await legacyTransport.get(superuserSwitch + clientId);
}

export async function legacyLoginWrapper(authState: AuthStore): Promise<void> {
    if (authState.token) {
        await doLegacyLogin(authState.token);
        if (authState.isAssumingOtherCustomer && authState.currentCustomerDb) {
            await doDbSwitch(authState.currentCustomerDb);
        }
    }
}

export async function doOidcLogin(code: string): Promise<LoginReturn> {
    const transport = createAuthClient(baseUrl);
    try {
        const loginResponse = await transport.post<LoginResponse>(loginSsoEndpoint + '?oidc_mode=1', {
            code
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const loginData = loginResponse.data;
        const userResponse = await transport.get<User>(loginData.links[0].href, {
            headers: {
                Authorization: 'Bearer ' + loginData.token
            }
        });
        return { success: true, token: loginData.token, refreshToken: loginData.refresh_token, userInfo: userResponse.data };
    } catch (error) {
        let title = '';
        let detail = '';
        if (error.response && error.response.data) {
            if (error.response.data.title) {
                title = error.response.data.title;
            }
            if (error.response.data.detail) {
                detail = error.response.data.detail;
            }
        }
        return { success: false, errorTitle: title, errorDetail: detail };
    }
}

export async function doIdpLogin(code: string, codeVerifier: string): Promise<LoginReturn> {
    const transport = createAuthClient(baseUrl);
    try {
        const loginResponse = await transport.post<LoginResponse>(loginSsoEndpoint, {
            code,
            code_verifier: codeVerifier
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const loginData = loginResponse.data;
        const userResponse = await transport.get<User>(loginData.links[0].href, {
            headers: {
                Authorization: 'Bearer ' + loginData.token
            }
        });
        return { success: true, token: loginData.token, refreshToken: loginData.refresh_token, userInfo: userResponse.data };
    } catch (error) {
        let title = '';
        let detail = '';
        if (error.response && error.response.data) {
            if (error.response.data.title) {
                title = error.response.data.title;
            }
            if (error.response.data.detail) {
                detail = error.response.data.detail;
            }
        }
        return { success: false, errorTitle: title, errorDetail: detail };
    }
}

export async function doLogin(username: string, password: string): Promise<LoginReturn> {
    const transport = createAuthClient(baseUrl);
    try {
        const loginResponse = await transport.post<LoginResponse>(loginEndpoint, {
            username: username,
            password: password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const loginData = loginResponse.data;
        const userResponse = await transport.get<User>(loginData.links[0].href, {
            headers: {
                Authorization: 'Bearer ' + loginData.token
            }
        });
        return { success: true, token: loginData.token, refreshToken: loginData.refresh_token, userInfo: userResponse.data };
    } catch (error) {
        let title = '';
        let detail = '';
        if (error.response && error.response.data) {
            if (error.response.data.title) {
                title = error.response.data.title;
            }
            if (error.response.data.detail) {
                detail = error.response.data.detail;
            }
        }
        return { success: false, errorTitle: title, errorDetail: detail };
    }
}

interface UserCheck {
    fullyLoggedIn: boolean;
    redirectLegacy?: boolean;
    step?: 'reset-password' | 'eula' | 'recovery-email';
}

export function checkUser(userInfo: User): UserCheck {
    if (!userInfo.has_new_ui) {
        return { fullyLoggedIn: false, redirectLegacy: true };
    }

    if (userInfo.force_password_change) {
        return { fullyLoggedIn: false, step: 'reset-password' };
    }

    if (userInfo.needs_eula) {
        return { fullyLoggedIn: false, step: 'eula' };
    }

    if (!userInfo.recovery_email) {
        return { fullyLoggedIn: false, step: 'recovery-email' };
    }

    return { fullyLoggedIn: true };
}

interface ForgotResponse {
    message: string;
    isSso?: boolean;
}

export async function forgotUsername(email: string): Promise<string> {
    const transport = createClient(baseUrl);
    const response = await transport.get<ForgotResponse>('/api/v3/forgot-username', { params: { email: email } });
    return response.data.message;
}

export async function forgotPassword(username: string, email: string): Promise<ForgotResponse> {
    const transport = createClient(baseUrl);
    const response = await transport.get<ForgotResponse>('/api/v3/forgot-password', { params: { username: username, email: email } });
    return response.data;
}

export async function ssoSetPassword(username: string, code: string, password: string): Promise<boolean> {
    const transport = createClient(baseUrl);
    const response = await transport.put<{ success: boolean }>('/api/v3/sso-set-password', { username, code, password });
    return response.data.success;
}

export async function verifyEmail(uuid: string, code: string): Promise<boolean> {
    const transport = createClient(baseUrl);
    const response = await transport.post<{ verified: boolean }>('/api/v3/verify-email?uuid=' + uuid + '&code=' + code);
    return response.data.verified;
}

export async function checkUserLoggedIn(authState: AuthStore): Promise<boolean> {
    return authState.loggedIn;
}

export async function doLogout(): Promise<void> {
    const transport = createAuthClient(baseUrl, true);
    await transport.post(logoutEndpoint);
}
