
















































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { NavigationGuardNext, Route } from 'vue-router';
import { AuthStore } from '@/store/auth-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { InterfaceSetting, SettingNames } from '@/dashboards/models/interface-settings-models';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { cloneDeep } from 'lodash';
import { appendHash, compareArrays, removeHash } from '@/utils/interface-settings-utils';
import { Logo } from '@/logo/models/logo';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import { LogoMapper } from '@/logo/mappers/logo-mapper';
import { LoadingStore } from '@/store/loading-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesRepository } from '@/features/features-repository';
import store from '@/store';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { Feature } from '@/features/features-models';

const authState = getModule(AuthStore, store);
const settingsStore = getModule(InterfaceSettingsStore);
const loadingStore = getModule(LoadingStore);
const clientInfoRepository = new ClientRepository();
const logoMapper = new LogoMapper();
const featuresRepo = new FeaturesRepository();
const featuresState = getModule(FeaturesStore);

@Component({ components: { UploadLogo } })
export default class SuperuserSettings extends Mixins(LocaleMixin, BasicValidationMixin) {
    private loadingKey = 'superuserSettings';
    private whiteLabelActive = false;
    private colorSettingsArray: Array<InterfaceSetting | undefined> = []
    private colorsArray: Array<InterfaceSetting | undefined> = []
    private resetArray: Array<InterfaceSetting | undefined> = []
    private validForm = false;
    private logo: Logo | null = null;
    private logoExists = false;
    private logoChange = false;
    private showUploadDialog = false;
    private manageUrl = '';
    private llpsActive = false;
    private llpsFeature: Feature | null = null;

    get isLineLeaderManage() {
        return featuresState.isLineLeaderManage;
    }

    async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
        if (authState.isFullSuperuser) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private async created() {
        loadingStore.loadingIncrement(this.loadingKey);

        await settingsStore.init();
        await featuresState.init();

        this.whiteLabelActive = settingsStore.stored.get(SettingNames.WHITE_LABEL_ENABLED)?.value as boolean ?? false;
        this.colorSettingsArray.push(
            settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_SECONDARY),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_THIRD),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_FOURTH),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_FIFTH),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_SIXTH),
            settingsStore.stored.get(SettingNames.WHITE_LABEL_SEVENTH)
        );
        // To keep from mutating settings directly.
        this.colorsArray = cloneDeep(this.colorSettingsArray);
        this.colorsArray = appendHash(this.colorsArray);
        this.resetArray = cloneDeep(this.colorsArray);
        this.logo = await clientInfoRepository.getWhiteLabelLogo();
        this.logoExists = !!this.logo;

        this.manageUrl = settingsStore.stored.get(SettingNames.WHITE_LABEL_URL)?.value as string ?? '';

        this.llpsFeature = featuresState.feature(FeatureConstants.LOGICAL_LANDING_PAGE_SITES);
        this.llpsActive = !!this.llpsFeature?.is_active;

        loadingStore.loadingDecrement(this.loadingKey);
    }

    public getTitle(index: number): string {
        switch (index) {
            case 0:
                return 'Primary Color';
            case 1:
                return 'Secondary Color';
            case 2:
                return 'Third Color';
            case 3:
                return 'Fourth Color';
            case 4:
                return 'Fifth Color';
            case 5:
                return 'Sixth Color';
            case 6:
                return 'Seventh Color';
            default:
                return 'undefined';

        }
    }

    // make sure values entered conform to BASE 16 format
    public validateHex(value: string): boolean {
        const regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;
        return regExp.test(value);
    }

    // make sure Primary and Secondary colors are not equal
    public validateDiffPrimaryFromSecondary() {
        return this.colorsArray[0]?.value?.toString().toUpperCase() !== this.colorsArray[1]?.value?.toString().toUpperCase();
    }

    // perform all validations on Hex values
    public isValid(value: any, index: number): string | boolean {
        const testValue = removeHash(value);
        if (!(testValue.length === 8 || testValue.length === 6 || testValue.length === 3) || !this.validateHex(testValue)) {
            return 'Value must be a valid Hexadecimal color.';
        }
        if (!this.validateDiffPrimaryFromSecondary() && (index === 0 || index === 1)) {
            return 'Primary and Secondary colors must be different.';
        }
        return true;
    }

    private resetValues() {
        this.colorsArray = cloneDeep(this.resetArray);
    }

    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
        this.logoChange = true;
    }

    private async deleteLogo() {
        const result = await this.$swal({
            text: 'Are you sure you want to remove this logo?',
            showConfirmButton: true,
            showCancelButton: true
        });

        if (result.isConfirmed) {
            this.logo = null;
        }
        this.logoChange = this.logoExists;
    }

    private async save() {
        loadingStore.loadingIncrement(this.loadingKey);

        const promises = [];

        // Store the white label setting.
        promises.push(settingsStore.updateSetting({
            settingId: settingsStore.stored.get(SettingNames.WHITE_LABEL_ENABLED)!.id,
            dto: { value: this.whiteLabelActive }
        }));
        // Store the manage URL
        promises.push(settingsStore.updateSetting({
            settingId: settingsStore.stored.get(SettingNames.WHITE_LABEL_URL)!.id,
            dto: { value: this.manageUrl }
        }));
        // If white label is turned off we don't care about saving anything, right?
        if (this.whiteLabelActive) {
            // only do this if any of the colors got changed
            if (!compareArrays(this.resetArray, this.colorsArray)) {
                for (const color of this.colorsArray) {
                    if (color) {
                        promises.push(settingsStore.updateSetting({
                            settingId: color.id,
                            dto: { value: removeHash(color.value) }
                        }));
                    }
                }
            }

            // Save logo.
            if (this.logoExists && (this.logo === null)) {
                await clientInfoRepository.deleteWhiteLabelLogo();
            } else if (this.logo && this.logoChange) {
                await clientInfoRepository.uploadWhiteLabelLogo(logoMapper.toCreateDto(this.logo));
            }
        }

        await Promise.all(promises);

        const llpsFeature = featuresState.feature(FeatureConstants.LOGICAL_LANDING_PAGE_SITES);
        if (llpsFeature) {
            await featuresRepo.toggle(llpsFeature, this.llpsActive);
        }

        loadingStore.loadingDecrement(this.loadingKey);
        location.reload();
    }
}
