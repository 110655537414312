















































































































































































































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getAgeFromBirthDate, isChildExportable } from '@/families/families-utils';
import { getStatusForField, sortFields } from '@/crm-types/field-utils';
import { formatDateForApi } from '@/date-time/date-time-utils';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BaseStatuses } from '@/constants/status-constants';
import { ChangeStatus } from '@/families/change-status';
import {
    ChildCreateDtoInterface,
    ChildPostDTO,
    ChildUpdateDtoInterface,
    ChildRevenue
} from '@/families/models/child';
import { ChildFields, ChildScheduleFields, Field, FieldEntityType } from '@/crm-types/models/field-models';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { CustomFamilyValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-family-values-changes-store';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import { EnrollmentCreate, EnrollmentUpdateDtoInterface } from '@/families/models/enrollment';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { EventTypes } from '@/constants/event-type-constants';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import type { Family } from '@/families/models/family';
import ChildScheduleEditor from '@/families/components/ChildScheduleEditor.vue';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import DynamicFieldEditor from '@/crm-types/components/DynamicFieldEditor.vue';
import SelectClassPickList from '@/families/components/SelectClassPickList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { IntegrationExportChildInterface, IntegrationExportChildPostDto } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const changeStatusService = new ChangeStatus();
const changeStatusUtil = new ChangeStatus();
const childrenRepo = new ChildrenRepository();
const customFamilyValuesStore = getModule(CustomFamilyValuesChangesStore);
const customFieldsStore = getModule(CustomFieldsStore);
const enrollmentsRepo = new EnrollmentsRepository();
const enrollmentsStore = getModule(EnrollmentsStore);
const featuresStore = getModule(FeaturesStore);
const fieldStore = getModule(FieldsStore);
const loadingStore = getModule(LoadingStore);
const statusChangesStore = getModule(StatusChangesStore);
const integrationRepo = new IntegrationRepository();

/**
 * @Todo: Figure out the disappearing class on new child.
 * @Todo: Layout issue with status changing.
 */
@Component({
    components: {
        BaseClose,
        ChildScheduleEditor,
        SelectClassPickList,
        CustomValuesEditor,
        DynamicFieldEditor,
        StatusChangeSelect
    }
})
export default class ChildAddEditModal extends Mixins(BasicValidationMixin, FamilyHubMixin, LocaleMixin) {
    // Props
    /** v-model whether we should show it. */
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) family!: Family;
    @Prop({ default: false }) includeClassrooms!: boolean;
    @Prop({ default: true }) hasIntegration!: boolean;
    @Prop({ default: false }) readonly hasManage!: boolean;
    @Prop({ default: '' }) managementSystemName!: string;

    // refs
    $refs!: {
        form: HTMLFormElement;
    };

    // Properties
    private isLoaded = false;
    private isValid = false;
    private loadingKey = 'FamilyDataInfoAddChild';
    private childModel: ChildCreateDtoInterface | ChildUpdateDtoInterface | null = null;
    private key = 0;

    private exportChild: IntegrationExportChildInterface | null = null;

    private exportChildCheckboxEvent = EventTypes.CHILD_EXPORT_CHECKED
    private revenue: ChildRevenue | null = null;

    // Computed getters / setters.
    get customFields(): Array<CustomField> {
        return customFieldsStore.storedChildFields;
    }

    get fields(): Array<Field> {
        // Filter out fields handled by other components or by other logic
        const fieldsToFilter: Array<string> = [
            ChildFields.COMMENTS,
            ChildFields.NOTES,
            ChildFields.CLASS,
            ChildFields.EXPECTED_CLASS,
            ChildFields.ESTIMATED_REVENUE,
            ChildFields.FIRST_NAME,
            ChildFields.LAST_NAME,
            ChildFields.BIRTHDATE,
            ChildFields.ESTIMATED_BIRTH,
            ChildFields.SCHEDULE_INFORMATION,
            ChildFields.PRIOR_LOCATION,
            ChildFields.PRIOR_CENTER,
            ChildFields.AGE_OUT_DATE,
            ChildFields.CHILD_STATUS,
            ChildFields.ADDED_DATE,
            // Status-related fields are handled by the status change select component
            ChildFields.EXPECTED_START_DATE,
            ChildFields.ACTUAL_START_DATE,
            ChildFields.WAIT_LIST_FEE_PAID_IN_FULL,
            ChildFields.WAIT_LIST_COMMENT,
            ChildFields.WAIT_LIST_COMMENT,
            ChildFields.WAIT_LIST_DATE,
            ChildFields.WAIT_LIST_DATE_FEE_PAID,
            ChildFields.WAIT_LIST_FEE,
            ChildFields.WAIT_LIST_TYPE,
            ChildFields.WAIT_LIST_REASON,
            ChildFields.ENROLLED_COMMENT,
            ChildFields.ENROLLED_REASON,
            ChildFields.TEMPORARY_LEAVE_COMMENT,
            ChildFields.TEMPORARY_LEAVE_DATE,
            ChildFields.TEMPORARY_LEAVE_REASON,
            ChildFields.WITHDRAWN_REASON,
            ChildFields.WITHDRAWN_DATE,
            ChildFields.WITHDRAWN_COMMENT,
            ChildFields.LOST_OPPORTUNITY_REASON,
            ChildFields.LOST_OPPORTUNITY_COMMENT,
            ChildFields.ACCOUNT_IN_GOOD_STANDING,
            ChildFields.SIBLING_IN_CARE,
            ChildFields.CHILD_OF_STAFF,
            ChildFields.ELIGIBLE_FOR_REENROLLMENT,
            // Schedule fields are handled in the child schedule editor component
            ChildScheduleFields.MONDAY_AM,
            ChildScheduleFields.MONDAY_PM,
            ChildScheduleFields.TUESDAY_AM,
            ChildScheduleFields.TUESDAY_PM,
            ChildScheduleFields.WEDNESDAY_AM,
            ChildScheduleFields.WEDNESDAY_PM,
            ChildScheduleFields.THURSDAY_AM,
            ChildScheduleFields.THURSDAY_PM,
            ChildScheduleFields.FRIDAY_AM,
            ChildScheduleFields.FRIDAY_PM,
            ChildScheduleFields.SATURDAY_AM,
            ChildScheduleFields.SATURDAY_PM,
            ChildScheduleFields.SUNDAY_AM,
            ChildScheduleFields.SUNDAY_PM,
            ChildScheduleFields.FULL_DAYS,
            ChildScheduleFields.HALF_DAYS
        ];
        // Sort all non-hidden fields; filter out hidden fields and explicitly filtered fields
        let sortedFields = sortFields(
            FieldEntityType.CHILD,
            fieldStore.storedChildFields.filter(field => !field.is_hidden && !fieldsToFilter.includes(field.value))
        );

        // Filter out child types 1-4, as needed
        if (!featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12)) {
            sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== 'CHILD_TYPE' && field.select_list_name !== 'CHILD_TYPE_TWO'));
        }
        if (!featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34)) {
            sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== 'CHILD_TYPE_THREE' && field.select_list_name !== 'CHILD_TYPE_FOUR'));
        }
        // Filter out status-related fields, as needed
        return sortedFields.filter((field) => {
            const status = getStatusForField(field);
            return status ? status === this.childModel?.status : true;
        });
    }

    get isCrmPlusEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isScheduleHidden(): boolean {
        return fieldStore.storedChildFields.filter(field => Object.values(ChildScheduleFields).includes(field.value as ChildScheduleFields) && field.is_hidden).length > 0;
    }

     get estimateFirstYearRevenue(): number {
        return this.revenue ? this.revenue.twelveMonthRevenue : 0;
    }

    get estimatedRevenue(): number {
        return this.revenue ? this.revenue.lifetimeRevenue : 0;
    }

     get isShowRevenue(): boolean {
        if (!this.childModel || !this.childModel.id) {
            return false;
        }

        return fieldStore.storedChildFields.filter(field => Object.values(ChildFields).includes(field.value as ChildFields.ESTIMATED_REVENUE) && !field.is_hidden).length > 0;
    }

    /**
     * Handles showing the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.modelValue) {
            // Creating a new child.
            this.revenue = null;
            this.childModel = new ChildPostDTO();

            // Create a negative ID, to be used for status change stuff. From old family hub stuff.
            this.childModel.id = -1 * (this.family.id + this.family.children.length);

            // Set the status of the child.
            this.childModel.status = 1;
            // Find the most advanced status among all the children and default to that
            if (this.family.children.length) {
                for (const child of this.family.children) {
                    if (child.status && child.id && child.id > 0) {
                        const status = changeStatusUtil.getStatusById(child.status.id);
                        const currentStatus = changeStatusUtil.getStatusById(this.childModel.status);
                        // Set a good default status, but not if it's an archived status
                        // We can't move directly to that
                        if (status && currentStatus && status.order > currentStatus.order && !status.is_archive) {
                            this.childModel.status = status.id;
                        }
                    }
                }
            } else if (this.family && this.family.status && this.family.status.id) {
                const status = changeStatusUtil.getStatusById(this.family.status.id);
                if (!status) {
                    this.childModel.status = 1;
                } else {
                    this.childModel.status = status.is_archive ? 1 : status.id;
                }
            }

            const newEnrollment = new EnrollmentCreate();
            newEnrollment.child_id = this.childModel.id;
            enrollmentsStore.storeInitialState({
                enrollment: newEnrollment,
                familyId: this.family.id,
                childId: newEnrollment.child_id
            });

            // Create a holding record for the status change for the new child
            statusChangesStore.storeChange({
                family_id: this.family.id,
                child_id: this.childModel.id,
                status: this.childModel.status,
                actual_start_date: null,
                date: formatDateForApi(new Date()),
                expected_start_date: null,
                reason: null,
                comments: null
            });
            this.isLoaded = true;
        } else {
            // Reset values and other things if needed
            this.childModel = null;
            this.isLoaded = false;
        }
        this.key++;
    }

    getAge(birthDate: string): string {
        return getAgeFromBirthDate(birthDate);
    }

    /**
     * Close the modal.
     */
    private close() {
        this.childModel = null;
        this.modelValue = false;
    }

    /**
     * Save the form contents.
     */
    private async save() {
        // Save and stuff
        if (this.childModel) {
            loadingStore.loadingIncrement(this.loadingKey);
            const childStatuses = new Map();

            if (this.isCrmPlusEnabled) {
                // Set any updates to custom values for this child.
                this.childModel.custom_values = customFamilyValuesStore.changesForChild(this.childModel);
            }
            // Start the new child at New Lead; change status will then move to correct status
            this.childModel.status = 1;
            const createdChild = (await childrenRepo.create(this.family.id, this.childModel as ChildCreateDtoInterface))[0];

            // Negative fake id.
            if (this.childModel.id) {
                // Handle status changes now that the child has been created
                // Replace the pending change with a valid one with an actual child's id
                const statusChange = statusChangesStore.changesForChildId(this.childModel.id);
                if (statusChange) {
                    statusChangesStore.updateChildId({ statusChange: statusChange, childId: createdChild.id });
                }
            }

            // Enrollment changes will need to happen too.
            for (const enrollmentChange of enrollmentsStore.changes(this.family.id)) {
                // Child of staff is set on the child; don't overwrite that change here with older data
                delete enrollmentChange.child_of_staff;
                if (enrollmentChange.id) {
                    const childStatus = childStatuses.get(enrollmentChange.child_id);
                    if (childStatus && childStatus !== BaseStatuses.REJECTED) {
                        await enrollmentsRepo.update(enrollmentChange as EnrollmentUpdateDtoInterface, true);
                    }
                } else if (createdChild && createdChild.enrollments.length) {
                    createdChild.enrollments.sort((a, b) => (a.id < b.id) ? 1 : 1);
                    enrollmentChange.id = createdChild.enrollments[0].id;
                    enrollmentChange.child_id = createdChild.id;
                    enrollmentChange.center_id = this.family.primary_guardian!.center_id!;
                    await enrollmentsRepo.update(enrollmentChange as EnrollmentUpdateDtoInterface);
                }
            }

            enrollmentsStore.clearPendingChanges(this.family.id);

            // Must come *after* the enrollment changes so status change details don't get overwritten
            await changeStatusService.writePendingChanges(this.family.id);

            if (createdChild && createdChild.id) {
                await this.exportChildEvent(createdChild.id);
                this.$emit(EventTypes.CHILD_ADDED, createdChild.id);
            }
            loadingStore.loadingDecrement(this.loadingKey);
        }

        this.close();
    }

    private async exportChildEvent(childId: number) {
        const child = await childrenRepo.retrieve(this.family.id, childId);
        if (this.hasIntegration && isChildExportable(child) && this.exportChild) {
            if (this.exportChild.export) {
                const exportChildDto = new IntegrationExportChildPostDto();
                exportChildDto.child_id = childId;
                await integrationRepo.exportChild(exportChildDto);
            }
        }
    }

    /**
     * Retrieve IntegrationExportChildInterface from StatusChangeSelect.
     *
     * @param child
     * @private
     */
    private updateExport(child: IntegrationExportChildInterface) {
        this.exportChild = child;
    }
}
