import { getModule } from 'vuex-module-decorators';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import type { CustomDashboard, CustomDashboardElement } from '@/dashboards/models/custom-dashboards';
import {
    actionDashboardType,
    CustomDashboardDto,
    CustomDashboardElementDto,
    customDashboardType,
    DashboardElementOption,
    DashboardUiObject,
    DashConstants,
    DashElementIdentifier, DashElementType,
    elementIdentifierCustomReports,
    elementIdentifierStandardReports
} from '@/dashboards/models/custom-dashboards';
import { getStandardReports } from '@/reports/standard/standard-reports';
import { nonDashReports } from '@/reports/report-constants';
import CompletedTasksChart from '@/dashboards/components/CompletedTasksChart.vue';
import TasksPastDueChart from '@/components/main/TasksPastDueChart.vue';
import ConversionSuccessGraphs from '@/dashboards/components/ConversionSuccessGraphs.vue';
import InquiryPieChart from '@/dashboards/components/InquiryPieChart.vue';
import FamilySourceChart from '@/components/main/FamilySourceChart.vue';
import OpportunitiesChart from '@/families/components/OpportunitiesChart.vue';
import StatusFilterFamilyLinks from '@/dashboards/components/StatusFilterFamilyLinks.vue';
import CorpGreeting from '@/dashboards/components/CorpGreeting.vue';
import EnrollmentCenterTasks from '@/enrollment-center/components/EnrollmentCenterTasks.vue';
import LocationInformationMain from '@/enrollment-center/components/LocationInformationMain.vue';
import LocationMapMain from '@/enrollment-center/components/LocationMapMain.vue';
import EnrollmentCenterInbox from '@/enrollment-center/components/EnrollmentCenterInbox.vue';

export class CustomDashboardService {
    readonly reportsState = getModule(SavedReportsStore);

    /**
     * We expand out the report placeholders here. For custom/standard reports, we basically append the standard report
     * identifier / saved report id to the base identifier to create a unique id usable in select lists
     * @param standardElements
     * @param userId
     */
    async getAllAvailableElements(standardElements: Array<DashElementType>, userId: number): Promise<Array<DashboardElementOption>> {
        await this.reportsState.init(userId);
        const standardReports = await getStandardReports();
        const allElements: Array<DashboardElementOption> = [];
        for (const element of this.processElements(standardElements)) {
            switch (element.identifier) {
                case elementIdentifierStandardReports:
                    for (const reportGroup of standardReports) {
                        for (const report of reportGroup.reports) {
                            if (nonDashReports.includes(report.identifier)) {
                                continue;
                            }
                            for (const view of report.reports) {
                                allElements.push({
                                    name: 'Reports > Standard > ' + reportGroup.name + ' > ' + view.name,
                                    standard_report_identifier: view.standard_report_identifier ?? '',
                                    id: (element.identifier) + '-' + view.standard_report_identifier ?? '',
                                    saved_report: null,
                                    identifier: element.identifier
                                });
                            }
                        }
                    }
                    break;
                case elementIdentifierCustomReports:
                    for (const report of this.reportsState.stored) {
                        allElements.push({
                            name: 'Reports > Custom > ' + report.name,
                            standard_report_identifier: null,
                            id: (element.identifier) + '-' + report.id,
                            saved_report: report.id,
                            identifier: element.identifier ?? ''
                        });
                    }
                    break;
                case DashElementIdentifier.ACTION_TABS_PLACEHOLDER:
                    allElements.push({
                        name: 'Dashboard > Action > Action Tabs',
                        identifier: element.identifier as DashElementIdentifier,
                        saved_report: null,
                        standard_report_identifier: null,
                        id: element.identifier
                    });
                    break;
                default:
                    allElements.push({
                        name: element.value,
                        identifier: element.identifier as DashElementIdentifier,
                        saved_report: null,
                        standard_report_identifier: null,
                        id: element.identifier
                    });
            }
        }
        return allElements;
    }

    getComponent(element: CustomDashboardElement | null) {
        if (!element) {
            return null;
        }
        switch (element.identifier) {
            case DashElementIdentifier.CHILD_COUNTS:
                return StatusFilterFamilyLinks;
            case DashElementIdentifier.GREETING:
                return CorpGreeting;
            case DashElementIdentifier.CHILDREN_BY_STATUS:
                return OpportunitiesChart;
            case DashElementIdentifier.CONVERSION_SUCCESS:
                return ConversionSuccessGraphs;
            case DashElementIdentifier.PAST_DUE:
                return TasksPastDueChart;
            case DashElementIdentifier.COMPLETED:
                return CompletedTasksChart;
            case DashElementIdentifier.INQUIRY_TYPES:
                return InquiryPieChart;
            case DashElementIdentifier.FAMILY_SOURCE:
                return FamilySourceChart;
            case DashElementIdentifier.ENROLLMENT_TODO:
                return EnrollmentCenterTasks;
            case DashElementIdentifier.ENROLLMENT_LOCATION:
                return LocationInformationMain;
            case DashElementIdentifier.ENROLLMENT_MAP:
                return LocationMapMain;
            case DashElementIdentifier.ENROLLMENT_INBOX:
                return EnrollmentCenterInbox;
            default:
                return null;
        }

    }

    /**
     * Given our dashboards, give the default one with some fallbacks in case of weird data
     * @param dashboards
     */
    getDefaultDash(dashboards: Array<CustomDashboard>): CustomDashboard {
        const realDefault = dashboards.find(dash => dash.is_default);
        if (realDefault) {
            return realDefault;
        }
        const firstEnabled = dashboards.find(dash => dash.is_enabled);
        if (firstEnabled) {
            return firstEnabled;
        }
        // somehow we don't have a default or any enabled dashboards... let's just return a default action dashboard
        return {
            is_default: true,
            is_enabled: true,
            type: actionDashboardType,
            sort_order: 0,
            name: 'Actions',
            elements: [],
            id: 1
        };
    }

    needsDateRange(dash: CustomDashboard): boolean {
        for (const elem of dash.elements) {
            if (DashConstants.dateRangeElements.includes(elem.identifier)) {
                return true;
            }
        }
        return false;
    }

    needsMineOnly(dash: CustomDashboard): boolean {
        for (const elem of dash.elements) {
            if (DashConstants.mineOnlyElements.includes(elem.identifier)) {
                return true;
            }
        }
        return false;
    }

    /**
     *
     * @param newCount
     */
    newDash(newCount: number): DashboardUiObject {
        return {
            id: newCount * -1,
            type: customDashboardType,
            is_enabled: true,
            is_default: false,
            name: '',
            elements: [
                {
                    identifier: ''
                }
            ],
            actionTabs: []
        };
    }

    /**
     * Handle turning all Action Dash elements into a single placeholder element
     * @param elements
     */
    processElements<T extends { identifier: string }>(elements: Array<T>) {
        const actionIndex = elements.findIndex(elem => DashConstants.actionDashTabs.includes(elem.identifier as DashElementIdentifier));
        // only include the first action tab element for dashboard viewing
        return elements.filter((elem, index) =>
            index === actionIndex || !DashConstants.actionDashTabs.includes(elem.identifier as DashElementIdentifier))
            .map(elem => ({
                ...elem,
                identifier: DashConstants.actionDashTabs.includes(elem.identifier as DashElementIdentifier) ? DashElementIdentifier.ACTION_TABS_PLACEHOLDER : elem.identifier
            }));
    }

    /**
     * Take API objects and map onto our UI objects. We flatten identifier/report ids into a unique ID similar to the
     * options above.
     * @param dashboards
     */
    transformToUi(dashboards: Array<CustomDashboard>): Array<DashboardUiObject> {
        return dashboards.map(dash => ({
            name: dash.name,
            id: dash.id,
            is_enabled: dash.is_enabled,
            is_default: dash.is_default,
            type: dash.type,
            sort_order: dash.sort_order,
            elements: this.processElements(dash.elements.map((elem) => {
                let identifier: string = elem.identifier;
                if (identifier === elementIdentifierStandardReports) {
                    identifier = identifier + '-' + elem.standard_report_identifier ?? '';
                } else if (identifier === elementIdentifierCustomReports) {
                    identifier = identifier + '-' + String(elem.saved_report?.id);
                }
                return { identifier };
            })),
            actionTabs: dash.elements.filter(elem => DashConstants.actionDashTabs.includes(elem.identifier))
                .map(elem => elem.identifier)
        }));
    }

    /**
     * Transform our UI objects into DTO suitable for POST/PUT. We scan through our already built option list to decode id's into
     * identifier/report id values
     * @param dashboardObject
     * @param availableElements
     * @param order
     */
    uiToDto(dashboardObject: DashboardUiObject, availableElements: Array<DashboardElementOption>, order: number): CustomDashboardDto {
        const elements: Array<CustomDashboardElementDto> = [];
        let elementOrder = 0;
        for (const elementObject of dashboardObject.elements) {
            if (elementObject.identifier === DashElementIdentifier.ACTION_TABS_PLACEHOLDER) {
                for (const actionTab of dashboardObject.actionTabs) {
                    elements.push({
                        identifier: actionTab,
                        sort_order: elementOrder,
                        saved_report: null,
                        standard_report_identifier: null
                    });
                    elementOrder++;
                }
                continue;
            }
            const matchingOption = availableElements.find((elem) => elem.id === elementObject.identifier);
            if (matchingOption) {
                elements.push({
                    identifier: matchingOption.identifier,
                    sort_order: elementOrder,
                    saved_report: matchingOption.saved_report,
                    standard_report_identifier: matchingOption.standard_report_identifier
                });
                elementOrder++;
            }
        }
        return {
            name: dashboardObject.name,
            elements,
            is_enabled: dashboardObject.is_enabled,
            is_default: dashboardObject.is_default,
            sort_order: order
        };
    }

}
