import {
    FamiliesFilter,
    FamiliesFilterDto,
    FamiliesFilterDtoInterface,
    FilterIncludeTypes
} from '@/filters/models/families-filter';
import hasha from 'hasha';
import { cloneDeep } from 'lodash';

/**
 * given a filter, return a signature we can use to identify anonymous filters
 * so we're not constantly creating new groups in the backend
 * @param filter
 */
export function getFilterSignature(filter: FamiliesFilterDtoInterface): string {
    // we want to ignore name for filter comparisons
    const localFilter = cloneDeep(filter);
    localFilter.name = '';
    return hasha(JSON.stringify(localFilter), {
        algorithm: 'md5',
        encoding: 'hex'
    });
}

/**
 * create filter DTO based on standard families table status filters
 *
 * @param statuses
 * @param orgId
 */
export function getSimpleFilterDto(statuses: Array<number>, orgId: number): FamiliesFilterDtoInterface {
    const dto = new FamiliesFilterDto();
    dto.is_anonymous = true;
    dto.organization = orgId;
    dto.statuses = statuses;
    dto.include_type = FilterIncludeTypes.ALL;
    return dto;
}

/**
 * Used to determine if we need to create a new anonymous filter or if we can use an existing one
 *
 * @param filters
 * @param newFilter
 */
export function getExistingFilter(filters: Array<FamiliesFilter>, newFilter: FamiliesFilterDtoInterface): FamiliesFilter | null {
    const newFilterSig = getFilterSignature(newFilter);
    const matching = filters.filter((filter) => {
        return filter.name === newFilterSig;
    });
    if (matching.length > 0) {
        return matching[0];
    }
    return null;
}

/**
 * get array of names of current org level filters so we can determine if there's collisions
 *
 * @param filters
 * @param org
 */
export function getCurrentOrgFilterNames(filters: Array<FamiliesFilter>, org: number): Array<string> {
    return filters.filter((filter) => {
        return !filter.is_anonymous && filter.organization.id === org;
    }).map((filter) => {
        return filter.name;
    });
}

/**
 * get array of names of all filters
 *
 * @param filters
 * @param org
 */
export function getAllOrgFilterNames(filters: Array<FamiliesFilter>): Array<string> {
    return filters.filter((filter) => {
        return !filter.is_anonymous;
    }).map((filter) => {
        return filter.name.toLowerCase();
    });
}
