import { CenterEnrollmentRepDto } from '@/staff/models/user';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';
import {
    EnrollmentCenterDetailsCreateDto,
    EnrollmentCenterDetailsUpdateDto, EnrollmentCenterSettingsUpdateDto,
    EnrollmentCenterRateOptionUpdateDto, EnrollmentCenterRateOptionCreateDto,
    EnrollmentCenterOfferingsRatingsCreateDto,
    EnrollmentCenterOfferingsRatingsUpdateDto
} from '@/enrollment-center/models/enrollment-center-models';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.ENROLLMENT_CENTER_SETTINGS_CHANGES
})
export class EnrollmentCenterSettingsChangesStore extends VuexModule {
    private deletedDetailsItems: Array<number> = [];
    private updatedDetailsItems: Array<EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto> = [];
    private enableDetails = false;
    private areDetailsChanged = false;
    private enableAvailability = false;
    private isAvailabilityChanged = false;

    private deletedRateOptionItems: Array<number> = [];
    private updatedRateOptionItems: Array<EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto> = [];
    private enableRateOptions = false;
    private areRateOptionsChanged = false;
    private isBasicInfoUpdated = false;
    private updatedBasicInfo: EnrollmentCenterSettingsUpdateDto | null = null;

    private deletedOfferingsItems: Array<number> = [];
    private updatedOfferingsItems: Array<EnrollmentCenterOfferingsRatingsCreateDto|EnrollmentCenterOfferingsRatingsUpdateDto> = [];
    private enableOfferings = false;
    private areOfferingsChanged = false;

    private deletedRatingsItems: Array<number> = [];
    private updatedRatingsItems: Array<EnrollmentCenterOfferingsRatingsCreateDto|EnrollmentCenterOfferingsRatingsUpdateDto> = [];
    private enableRatings = false;
    private areRatingsChanged = false;

    private areSupportedLocationChanges = false;
    private excludedCenters: Array<number> = [];
    private centerEnrollmentRepChanges: Map<number, CenterEnrollmentRepDto> = new Map(); // key: centerId
    private refreshLocationsSupported = false;

    /* Mutation for rates settings */
    @Mutation
    setSaveButtonForRateOptions(flag: boolean) {
        this.areRateOptionsChanged = flag;
    }

    @Mutation
    setDeletedRateOptions(items: Array<number>) {
        this.deletedRateOptionItems = items;
    }

    @Mutation
    setUpdatedRateOptions(items: Array<EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto>) {
        this.updatedRateOptionItems = items;
    }

    @Mutation
    setEnabledRateOptionsFlag(flag: boolean) {
        this.enableRateOptions = flag;
    }

    public get rateOptionsDeleted(): Array<number> {
        return this.deletedRateOptionItems;
    }

    public get rateOptionsUpdated(): Array<EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto> {
        return this.updatedRateOptionItems;
    }

    public get enableRateOptionsFlag(): boolean {
        return this.enableRateOptions;
    }

    @Mutation
    setSaveButtonForBasicInfo(flag: boolean) {
        this.isBasicInfoUpdated = flag;
    }

    @Mutation
    setUpdatedBasicInfo(item: EnrollmentCenterSettingsUpdateDto | null) {
        this.updatedBasicInfo = item;
    }

    public get basicInfoUpdated(): EnrollmentCenterSettingsUpdateDto | null {
        return this.updatedBasicInfo;
    }

    @Mutation
    setSaveButtonForDetails(flag: boolean) {
        this.areDetailsChanged = flag;
    }

    @Mutation
    setDeletedDetails(items: Array<number>) {
        this.deletedDetailsItems = items;
    }

    @Mutation
    setUpdatedDetails(items: Array<EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto>) {
        this.updatedDetailsItems = items;
    }

    @Mutation
    setEnabledDetailsFlag(flag: boolean) {
        this.enableDetails = flag;
    }

    /* Mutation for offerings settings */
    @Mutation
    setSaveButtonForOfferings(flag: boolean) {
        this.areOfferingsChanged = flag;
    }

    @Mutation
    setDeletedOfferings(items: Array<number>) {
        this.deletedOfferingsItems = items;
    }

    @Mutation
    setUpdatedOfferings(items: Array<EnrollmentCenterOfferingsRatingsCreateDto |EnrollmentCenterOfferingsRatingsUpdateDto>) {
        this.updatedOfferingsItems = items;
    }

    @Mutation
    setEnabledOfferingsFlag(flag: boolean) {
        this.enableOfferings = flag;
    }

    /* Mutation for ratings settings */
    @Mutation
    setSaveButtonForRatings(flag: boolean) {
        this.areRatingsChanged = flag;
    }

    @Mutation
    setDeletedRatings(items: Array<number>) {
        this.deletedRatingsItems = items;
    }

    @Mutation
    setUpdatedRatings(items: Array<EnrollmentCenterOfferingsRatingsCreateDto |EnrollmentCenterOfferingsRatingsUpdateDto>) {
        this.updatedRatingsItems = items;
    }

    @Mutation
    setEnabledRatingsFlag(flag: boolean) {
        this.enableRatings = flag;
    }

    public get areAvailabilityChanges(): boolean {
        return false;
    }

    @Mutation
    setEnabledAvailabilityFlag(flag: boolean) {
        this.enableAvailability = flag;
    }

    @Mutation
    setSaveButtonForAvailability(flag: boolean) {
        this.isAvailabilityChanged = flag;
    }

    @Mutation
    setSaveButtonForSupportedLocations(flag: boolean) {
        this.areSupportedLocationChanges = flag;
    }

    @Mutation
    setExcludedCenters(centerIds: Array<number>) {
        this.excludedCenters = centerIds;
    }

    @Mutation
    setCenterEnrollmentRepChanges(changes: Map<number, CenterEnrollmentRepDto>) {
        this.centerEnrollmentRepChanges = changes;
    }

    @Mutation
    setRefreshLocationsSupported(flag: boolean) {
        this.refreshLocationsSupported = flag;
    }

    public get enableAvailabilityFlag(): boolean {
        return this.enableAvailability;
    }

    public get areBasicInfoChanges(): boolean {
        return false;
    }

    public get areChanges(): boolean {
        return this.isAvailabilityChanged ||
            this.isBasicInfoUpdated ||
            this.areDetailsChanged ||
            this.areOfferingsChanged ||
            this.areRatingsChanged ||
            this.areRateOptionsChanged ||
            this.areSupportedLocationChanges;
    }

    /* Getters to track detail items/flag */
    public get detailsDeleted(): Array<number> {
        return this.deletedDetailsItems;
    }

    public get detailsUpdated(): Array<EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto> {
        return this.updatedDetailsItems;
    }

    public get enableDetailsFlag(): boolean {
        return this.enableDetails;
    }

    /* Getters to track offering items/flag */
    public get offeringsDeleted(): Array<number> {
        return this.deletedOfferingsItems;
    }

    public get offeringsUpdated(): Array<EnrollmentCenterOfferingsRatingsCreateDto|EnrollmentCenterOfferingsRatingsUpdateDto> {
        return this.updatedOfferingsItems;
    }

    public get enableOfferingsFlag(): boolean {
        return this.enableOfferings;
    }

    /* Getters to track rating items/flag */
    public get ratingsDeleted(): Array<number> {
        return this.deletedRatingsItems;
    }

    public get ratingsUpdated(): Array<EnrollmentCenterOfferingsRatingsCreateDto|EnrollmentCenterOfferingsRatingsUpdateDto> {
        return this.updatedRatingsItems;
    }

    public get enableRatingsFlag(): boolean {
        return this.enableRatings;
    }

    public get areSupportedLocationChangesFlag(): boolean {
        return this.areSupportedLocationChanges;
    }

    public get excludedCenterUpdates(): Array<number> {
        return this.excludedCenters;
    }

    public get centerEnrollmentRepChangesUpdates(): Map<number, CenterEnrollmentRepDto> {
        return this.centerEnrollmentRepChanges;
    }

    public get isRefreshLocationsSupported(): boolean {
        return this.refreshLocationsSupported;
    }
}
