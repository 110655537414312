













































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { verifyEmail } from '@/auth/auth';

const loadingState = getModule(LoadingStore);

@Component
export default class VerifyEmail extends Mixins(LocaleMixin) {
    private uuid = '';
    private code = '';
    private validForm = false;
    private notEmpty = [
        (v: string) => !!v || 'Please enter a value'
    ];

    async created() {
        if (!Array.isArray(this.$route.query.uuid)) {
            this.uuid = this.$route.query.uuid ?? '';
        }
        if (!Array.isArray(this.$route.query.code)) {
            this.code = this.$route.query.code ?? '';
        }
    }

    public async verify() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const verified = await verifyEmail(this.uuid, this.code);
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
        if (verified) {
            await this.$swal({ icon: 'info', text: 'Verified!' });
            await this.$router.push('/');
            return;
        }
        await this.$swal({ icon: 'warning', text: 'Invalid code.' });
    }
}
