import { EnrollmentsRepository, EnrollmentsStartFilterParams } from '@/families/repositories/enrollments-repository';
import { formatIsoDateTime, getSingleDayRangeFromDate } from '@/date-time/date-time-utils';
import { BaseStatuses } from '@/constants/status-constants';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { SelectListOption } from '@/core/models/select-list';
import { StatusesStore } from '@/families/store/statuses-store';
import { DataTableHeader } from 'vuetify';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { getAgeFromBirthDate } from '@/families/families-utils';
import { addDays } from 'date-fns';
import { formatDateForLocale } from '@/locales/locale-helpers';
import { ChildStartsRangeKey, ChildStartsRow } from '@/dashboards/models/child-starts-tab-models';

const appStateStore = getModule(AppStateStore);
const authStore = getModule(AuthStore, store);
const enrollmentsRepo = new EnrollmentsRepository();
const featureStore = getModule(FeaturesStore);
const statusStore = getModule(StatusesStore);

export class ChildStartsTabService {

    public dateRangeOptions: Array<SelectListOption> = [
        {
            value: ChildStartsRangeKey.NEXT_30,
            text: 'Next 30 Days'
        },
        {
            value: ChildStartsRangeKey.NEXT_7,
            text: 'Next 7 Days'
        },
        {
            value: ChildStartsRangeKey.NEXT_3,
            text: 'Next 3 Days'
        },
        {
            value: ChildStartsRangeKey.TODAY,
            text: 'Today'
        },
        {
            value: ChildStartsRangeKey.LAST_3,
            text: 'Last 3 Days'
        },
        {
            value: ChildStartsRangeKey.LAST_7,
            text: 'Last 7 Days'
        },
        {
            value: ChildStartsRangeKey.LAST_30,
            text: 'Last 30 Days'
        }
    ];

    public get tableHeaders(): Array<DataTableHeader> {
        const headers = [
            {
                text: 'Child Name',
                value: 'child_name'
            },
            {
                text: 'Guardian Name',
                value: 'guardian_name'
            },
            {
                text: 'Status',
                value: 'status'
            },
            {
                text: 'Start Date',
                value: 'start_date'
            },
            {
                text: 'Age',
                value: 'age'
            }
        ];
        if (featureStore.isFeatureEnabled(FeatureConstants.CLASSROOMS)) {
            headers.push({ text: 'Class', value: 'classroom' });
        }
        return headers;
    }

    /**
     * Get rows for the "child starts" tab based on the given date range desired
     *
     * @param locale
     */
    public async getChildrenStartingRows(locale = 'en-US'): Promise<Array<ChildStartsRow>> {
        const orgId = appStateStore.currentOrg?.id;
        if (!orgId) {
            return [];
        }
        const tz = appStateStore.currentCenter?.timezone ?? authStore.userTimeZone;
        const dateRange = getSingleDayRangeFromDate(new Date());

        // We always include today; this simple math is why the values are the correct days
        if (appStateStore.currentChildStartsRange < 0) {
            dateRange.start = addDays(dateRange.start, appStateStore.currentChildStartsRange);
        }
        if (appStateStore.currentChildStartsRange > 0) {
            dateRange.end = addDays(dateRange.end, appStateStore.currentChildStartsRange);
        }

        const params: EnrollmentsStartFilterParams = {
            org_id: orgId,
            start_before_date: formatIsoDateTime(dateRange.end, tz),
            start_after_date: formatIsoDateTime(dateRange.start, tz),
            status_ids: [BaseStatuses.REGISTERED, BaseStatuses.ENROLLED]
        };
        const childStartRows: Array<ChildStartsRow> = [];
        const response = await enrollmentsRepo.getAllChildrenStarting(params);
        for (const enrollment of response.entities) {
            let status;
            if (enrollment.child.values.status > 0) {
                status = await statusStore.getById(enrollment.child.values.status);
            }
            const startDate = enrollment.enrollment ? enrollment.enrollment.date : enrollment.expected_start_date;
            childStartRows.push({
                family_id: enrollment.family.id,
                child_name: enrollment.child.values.name,
                guardian_name: enrollment.family.values.name,
                status: status ? status.name : 'Unknown',
                start_date: startDate ? formatDateForLocale(locale, startDate as string) : 'Unknown',
                age: enrollment.child.values.birthdate ? getAgeFromBirthDate(enrollment.child.values.birthdate) : 'Unknown',
                classroom: enrollment.child.values.classroom ?? ''
            });
        }

        return childStartRows;
    }
}
