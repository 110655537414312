import Vue from 'vue';
import * as swal from 'sweetalert2';

export class ErrorDisplay {
    static async error(message: string): Promise<swal.SweetAlertResult> {
        return Vue.swal({
            text: message,
            icon: 'error'
        });
    }

    static async warning(message: string): Promise<swal.SweetAlertResult> {
        return Vue.swal({
            text: message,
            icon: 'warning'
        });
    }
}
