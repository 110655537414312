import { AbstractRepository } from '@/repositories/abstract-repository';
import { Status, StatusDto, StatusMassUpdate, StatusPatchDto } from '@/families/models/status';

export class StatusesRepository extends AbstractRepository<Status> {
    readonly endpoint = 'statuses';

    public async updateStatus(statusId: number, payload: StatusPatchDto): Promise<Status> {
        const response = await this.client.patch(this.endpoint + `/${statusId}`, payload);
        return response.data;
    }

    public async editStatus(statusId: number, payload: StatusDto): Promise<Status> {
        const response = await this.client.put(this.endpoint + `/${statusId}`, payload);
        return response.data;
    }

    public async newStatus(payload: StatusDto): Promise<Status> {
        const response = await this.client.post(this.endpoint, payload);
        return response.data;
    }

    public async deleteStatus(statusId: number): Promise<void> {
        await this.client.delete(this.endpoint + `/${statusId}`);
    }

    public async massUpdateStatus(update: StatusMassUpdate): Promise<void> {
        await this.client.put(this.endpoint + '/mass-update', update);
    }
}
