
























































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { LocationRating } from '@/enrollment-center/models/enrollment-center-location-models';
import { LocationRatingsRepository } from '@/enrollment-center/repositories/location-ratings-repository';
import { EnrollmentCenterRatings } from '@/enrollment-center/models/enrollment-center-models';

const loadingState = getModule(LoadingStore);
const settingsStore = getModule(EnrollmentCenterSettingsStore);
const ratingsRepo = new LocationRatingsRepository();

interface RatingUpdate {
    rating: EnrollmentCenterRatings;
    value: number;
}

@Component
export default class LocationRatingsTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;
    private loadingKey = 'locationRatings';
    private locationRatings: Array<LocationRating> = [];
    private locationRatingUpdates: Array<RatingUpdate> = [];

    get ratings() {
        return settingsStore.ratings;
    }

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    @Watch('location', { deep: true, immediate: true })
    async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);
        this.locationRatings = await ratingsRepo.getLocationRatings(this.location.id);
        this.locationRatingUpdates = this.ratings.map((rating) => {
            return { rating: rating, value: this.ratingSelectionId(rating.id) };
        });

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode')
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        }
        if (this.localMode === EditMode.SAVING) {
            // do saving stuff
            const promises = [];
            for (const update of this.locationRatingUpdates) {
                if (update.value !== -1) {
                    promises.push(ratingsRepo.updateLocationRating(this.location.id, update.rating.id, { value: update.value }));
                }
            }
            await Promise.all(promises);
            this.localMode = EditMode.SAVED;
        }
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await settingsStore.initRatings();
        loadingState.loadingDecrement(this.loadingKey);
    }

    ratingSelectionValue(id: number): string {
        const match: Array<LocationRating> = this.locationRatings.filter((value) => {
            return value.rating.id === id;
        });
        if (match.length > 0) {
            return match[0].value?.values.label ?? '';
        }
        return '';
    }

    ratingSelectionId(id: number): number {
        const match: Array<LocationRating> = this.locationRatings.filter((value) => {
            return value.rating.id === id;
        });
        if (match.length > 0) {
            return match[0].value?.id ?? -1;
        }
        return -1;
    }
}
