import { ApiParameters } from '@/repositories/abstract-repository';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsRepository } from '@/marketing/repositories/marketing-campaigns-repository';

export interface MarketingCampaignsState extends AbstractEntityState<MarketingCampaign> {
    entities: Array<MarketingCampaign>;
    orgCampaigns: Map<number, Array<MarketingCampaign>>;
    orgCampaignsIncludingClosed: Map<number, Array<MarketingCampaign>>;
}

export interface OrgCampaignsMapping {
    orgId: number;
    campaigns: Array<MarketingCampaign>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.MARKETING_CAMPAIGNS
})
export class MarketingCampaignsStore extends AbstractApiStore<MarketingCampaign> implements MarketingCampaignsState {
    readonly repository = new MarketingCampaignsRepository();
    orgCampaigns: Map<number, Array<MarketingCampaign>> = new Map<number, Array<MarketingCampaign>>();
    orgCampaignsIncludingClosed: Map<number, Array<MarketingCampaign>> = new Map<number, Array<MarketingCampaign>>();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    @Action
    public async initForOrgId(orgId: number) {
        if (!this.orgCampaigns.has(orgId)) {
            await this.initPromise({
                hash: 'org-' + String(orgId),
                closure: async () => {
                    await this.retrieveForOrgId(orgId);
                }
            });
        }
    }

    @Action
    public async initForOrgIdIncludeClosed(orgId: number) {
        if (!this.orgCampaignsIncludingClosed.has(orgId)) {
            await this.initPromise({
                hash: 'org-including-' + String(orgId),
                closure: async () => {
                    await this.retrieveForOrgIdIncludingClosed(orgId);
                }
            });
        }
    }

    @Action({ commit: 'storeOrgCampaigns' })
    public async retrieveForOrgId(orgId: number) {
        const params = { org_id: orgId.toString() } as ApiParameters;
        const campaigns = await this.retrieveAll(params);
        return { orgId: orgId, campaigns: campaigns } as OrgCampaignsMapping;
    }

    @Action({ commit: 'storeOrgCampaignsIncludingClosed' })
    public async retrieveForOrgIdIncludingClosed(orgId: number) {
        const params = { org_id: orgId.toString(), show_closed: 'true' } as ApiParameters;
        const campaigns = await this.retrieveAll(params);
        return { orgId: orgId, campaigns: campaigns } as OrgCampaignsMapping;
    }

    @Mutation
    public storeOrgCampaigns(mapping: OrgCampaignsMapping): void {
        this.orgCampaigns.set(mapping.orgId, mapping.campaigns);
    }

    @Mutation
    public storeOrgCampaignsIncludingClosed(mapping: OrgCampaignsMapping): void {
        this.orgCampaignsIncludingClosed.set(mapping.orgId, mapping.campaigns);
    }

    public get stored(): Array<MarketingCampaign> {
        return this.entities;
    }

    public get storedOrgCampaigns() {
        return (orgId: number): Array<MarketingCampaign> => {
            return this.orgCampaigns.has(orgId) ? this.orgCampaigns.get(orgId) as Array<MarketingCampaign> : [];
        };
    }

    public get storedOrgCampaignsIncludingClosed() {
        return (orgId: number): Array<MarketingCampaign> => {
            return this.orgCampaignsIncludingClosed.has(orgId) ? this.orgCampaignsIncludingClosed.get(orgId) as Array<MarketingCampaign> : [];
        };
    }
}
