import { CrmTypeList, CrmTypeOption, TemplateGroupItem } from '@/crm-types/models/crm-type';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';

const typesRepo = new CrmTypesRepository();
const emailTemplatesRepository = new EmailTemplatesRepository();
const textTemplatesRepository = new TextTemplatesRepository();

export async function updateTextEmailTemplateGroupItems(updatedEmailItems: Array<TemplateGroupItem>, updatedTextItems: Array<TemplateGroupItem>): Promise<void> {
    for (const item of updatedEmailItems) {
        let id = -1;
        if (item.groupItem.id) {
            id = item.groupItem.id;
            delete item.groupItem.id;
            // Update email template group
            await typesRepo.updateListItem(id, item.groupItem, CrmTypeList.TEMPLATE_GROUPS_EMAIL);
        } else {
            delete item.groupItem.id;
            // Create new email template group
            const result: CrmTypeOption = await typesRepo.addListItem(item.groupItem, CrmTypeList.TEMPLATE_GROUPS_EMAIL);
            id = result.id;
        }

        if (id !== -1) {
            item.groupItem.id = id;
        }

        if (item.groupItem.id) {
            await emailTemplatesRepository.updateGroup(item.groupItem.id, { template_ids: item.templatesInGroup });
        }
    }

    for (const item of updatedTextItems) {
        let id = -1;
        if (item.groupItem.id) {
            // Update text template group
            id = item.groupItem.id;
            delete item.groupItem.id;
            await typesRepo.updateListItem(id, item.groupItem, CrmTypeList.TEMPLATE_GROUPS_TEXT);
        } else {
            // Create new text template group
            delete item.groupItem.id;
            const result: CrmTypeOption = await typesRepo.addListItem(item.groupItem, CrmTypeList.TEMPLATE_GROUPS_TEXT);
            id = result.id;
        }

        if (id !== -1) {
            item.groupItem.id = id;
        }

        if (item.groupItem.id) {
            await textTemplatesRepository.updateGroup(item.groupItem.id, { template_ids: item.templatesInGroup });
        }
    }
}
