




















































import SaveButton from '@/components/base/SaveButton.vue';
import { ModalMixin } from '@/core/modal-mixin';
import { CallFamilyUtils } from '@/families/call-family-utils';
import { Contact } from '@/families/models/contact';
import type { Family } from '@/families/models/family';
import { Guardian } from '@/families/models/guardian';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingStore = getModule(LoadingStore);
const callFamilyUtils = new CallFamilyUtils();

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class CallFamilyModal extends Mixins(LocaleMixin, ModalMixin) {
    @Prop({ default: null }) readonly contact!: Contact | null;
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ default: null }) readonly guardian!: Guardian | null;

    private loadingKey = 'CallFamilyModal';

    /**
     * Place a call to the current guardian.
     */
    private async placeCall() {
        if (this.guardian || this.contact) {
            loadingStore.loadingIncrement(this.loadingKey);
            await callFamilyUtils.callFamily(this.family.id, this.guardian?.id ?? null, this.contact?.id ?? null);
            loadingStore.loadingDecrement(this.loadingKey);
        }
        this.close();
    }
}
