import { AbstractRepository } from '@/repositories/abstract-repository';
import { FamilyMarketingTracking } from '@/families/models/marketing';

export class FamilyMarketingRepository extends AbstractRepository<FamilyMarketingTracking> {
    protected endpoint = 'families/{id}/marketing';

    public async retrieve(familyId: number): Promise<Array<FamilyMarketingTracking>> {
        const response = await this.client.get<Array<FamilyMarketingTracking>>(`families/${familyId}/marketing`);
        return response.data;
    }
}
