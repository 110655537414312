var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-data-table',{staticClass:"truncated-columns family-data-contacts-table",class:_vm.canEditFamily ? 'clickable-rows' : '',attrs:{"headers":_vm.headers,"items":_vm.contacts,"item-key":"id","dense":"","hide-default-footer":"","disable-pagination":true},on:{"click:row":_vm.manageContact},scopedSlots:_vm._u([{key:"header.comments",fn:function(ref){
var header = ref.header;
return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('v-spacer'),(!_vm.canMakeCenterCalls && _vm.canEditFamily)?_c('base-button-secondary',{staticClass:"header-add-contact-button",on:{"click":function($event){$event.stopPropagation();return _vm.manageContact(null)}}},[_vm._v(" Add ")]):_vm._e()],1)],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('v-container',{staticClass:"pa-0 ml-n1",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('v-spacer'),(_vm.canMakeCenterCalls && _vm.canEditFamily)?_c('base-button-secondary',{staticClass:"header-add-contact-button",on:{"click":function($event){$event.stopPropagation();return _vm.manageContact(null)}}},[_vm._v(" Add ")]):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.relation_to_child",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.relation_to_child}},[_vm._v(" "+_vm._s(item.relation_to_child)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.email}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.comments}},[_vm._v(" "+_vm._s(item.comments)+" ")])]}},{key:"item.actions",fn:function(ref ){
var item = ref.item;
return [(_vm.canMakeCenterCalls && _vm.canEditFamily)?_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"data-cy":"contact-call-btn","fab":"","x-small":"","elevation":"0","disabled":_vm.isDoNotCall(item)},on:{"click":function($event){$event.stopPropagation();return _vm.callContact(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-phone-in-talk ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isDoNotCall(item) ? 'This contact cannot be called.' : 'Start Call'))])])],1):_vm._e()]}}])}),_c('family-data-contacts-modal',{attrs:{"contact":_vm.selectedContact,"family":_vm.family},on:_vm._d({},[_vm.updatedEvent,_vm.reloadData]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}}),_c('call-family-modal',{attrs:{"family":_vm.family,"contact":_vm.selectedContact},model:{value:(_vm.showCallModal),callback:function ($$v) {_vm.showCallModal=$$v},expression:"showCallModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }