
























































import { AuthMixin } from '@/auth/auth-mixin';
import { PendingFamilyRecording, Recording } from '@/communications/recordings/models/recording-models';
import { RecordingUtils } from '@/communications/recordings/recording-utils';
import { ModalMixin } from '@/core/modal-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import BaseClose from '@/components/base/BaseClose.vue';

const recordingUtils = new RecordingUtils();

@Component({
    components: { BaseClose }
})
export default class RecordingAudioModal extends Mixins(AuthMixin, LocaleMixin, ModalMixin) {
    /**
     * The entity to edit. If null, we are adding one.
     */
    @Prop({ default: () => [] }) readonly recordings!: Array<Recording | PendingFamilyRecording>;

    /**
     * Make the background scrollable.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.modelValue) {
            this.$nextTick(() => {
                (this.$refs.recordingModal as any).showScroll();
            });
        }
    }

    /**
     * Get the phone number to display in the table.
     *
     * @param recording
     */
    getPhone(recording: Recording | PendingFamilyRecording): string {
        return recordingUtils.getPersonPhone(recording);
    }
}
