import { Status } from '@/families/models/status';
import { BaseStatuses } from '@/constants/status-constants';

/**
 * returns custom status names to allow for dynamic content on the families table header
 *
 * @param statuses
 * @param base
 */
export function getStatusName(statuses: Array<Status>, base: BaseStatuses): string {
    const status = statuses.find(status => status.id === base);
    if (status) {
        let name = status.name;
        if (status.name.includes('(' && ')')) {
            name = status.name.replace(/ *\((.+)\) */, '');
        }
        const appendedText = status.child_only ? ' Children' : '';
        return name + appendedText;
    } else {
        return '';
    }
}
