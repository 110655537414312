import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { ServiceProvider } from '@/service-providers/models/service-providers';
import Vue from 'vue';
import { ServiceProvidersRepository } from '@/service-providers/repository/service-providers-repository';

interface TokensHash {
    [key: string]: string;
}

interface TokenUpdate {
    providerId: ServiceProvider;
    token: string;
}

interface ServiceProvidersState {
    tokens: TokensHash;
}

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.SERVICE_PROVIDERS
})
export class ServiceProvidersStore extends VuexModule implements ServiceProvidersState {
    readonly repository = new ServiceProvidersRepository();

    tokens: TokensHash = {};

    @Mutation
    storeToken(update: TokenUpdate) {
        Vue.set(this.tokens, update.providerId, update.token);
    }

    @Action
    async retrieveToken(providerId: ServiceProvider) {
        const token = await this.repository.getToken(providerId);
        const update: TokenUpdate = { token: token.token, providerId };
        this.context.commit('storeToken', update);
    }

    @Action
    async initToken(providerId: ServiceProvider) {
        if (!this.tokens[providerId]) {
            await this.retrieveToken(providerId);
        }
    }

    get token() {
        return (providerId: ServiceProvider) => {
            return this.tokens[providerId];
        };
    }
}
