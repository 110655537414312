


































import { EventTypes } from '@/constants/event-type-constants';
import DynamicFieldEditor from '@/crm-types/components/DynamicFieldEditor.vue';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import {
    generateGuardianFieldsList
} from '@/crm-types/field-utils';
import { Field, GuardianFields } from '@/crm-types/models/field-models';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import type { Family } from '@/families/models/family';
import { FamilyUpdateDtoInterface } from '@/families/models/family';
import { FamilyChangesStore } from '@/families/store/family-changes-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

const customFieldsStore = getModule(CustomFieldsStore);
const ecSettingsStore = getModule(EnrollmentCenterSettingsStore);
const familyChangesStore = getModule(FamilyChangesStore);
const familyMapper = new FamilyMapper();
const featureStore = getModule(FeaturesStore);
const fieldStore = getModule(FieldsStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: { CustomValuesEditor, DynamicFieldEditor }
})
export default class FamilyOtherFields extends Mixins(LocaleMixin) {
    @Prop({ required: true }) family!: Family;
    @Prop({ default: false }) isEdit!: boolean;
    /**
     * Whether or not this is being view from the pending family modal.
     */
    @Prop({ type: Boolean, default: false }) readonly isPendingView!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isNewHub!: boolean;

    private loadingKey = 'familyOtherFields';
    private familyDto: FamilyUpdateDtoInterface | null = null;
    private familyUpdateEvent = EventTypes.FAMILY_UPDATED;
    private fields: Array<Field> = [];
    private filteredFields: Array<string> = [
        GuardianFields.FIRST_NAME,
        GuardianFields.LAST_NAME,
        GuardianFields.EMAIL,
        GuardianFields.SHOWN_EMAIL,
        GuardianFields.PHONE,
        GuardianFields.PHONE_TYPE,
        GuardianFields.PHONE_EXTENSION,
        GuardianFields.PHONE_COUNTRY,
        GuardianFields.COMMENTS,
        GuardianFields.PHONE_TYPE,
        GuardianFields.ADDRESS,
        GuardianFields.ADDRESS2,
        GuardianFields.ADDRESS3,
        GuardianFields.ADDRESS4,
        GuardianFields.CITY,
        GuardianFields.STATE,
        GuardianFields.ZIP,
        GuardianFields.INQUIRY_TYPE,
        GuardianFields.RELATION_TO_CHILD,
        GuardianFields.SOURCE,
        GuardianFields.FAMILY_REP,
        GuardianFields.SPECIAL_EMPLOYER,
        GuardianFields.MARKETING_CAMPAIGN,
        GuardianFields.MARKETING_NOTE,
        GuardianFields.STATUS,
        GuardianFields.DO_NOT_CALL,
        GuardianFields.DO_NOT_TEXT,
        GuardianFields.DO_NOT_EMAIL,
        // These three are hidden because they're not available in the API
        GuardianFields.ALT_CONTACT_PHONE,
        GuardianFields.ALT_CONTACT_EMAIL,
        GuardianFields.ALT_CONTACT_PHONE_TYPE,
        GuardianFields.PRIOR_CENTER,
        GuardianFields.PRIOR_COMMENT
    ];

    private filteredNewHub: Array<string> = [
        GuardianFields.FIRST_NAME,
        GuardianFields.LAST_NAME,
        GuardianFields.EMAIL,
        GuardianFields.SHOWN_EMAIL,
        GuardianFields.PHONE,
        GuardianFields.PHONE_TYPE,
        GuardianFields.PHONE_EXTENSION,
        GuardianFields.PHONE_COUNTRY,
        GuardianFields.COMMENTS,
        GuardianFields.PHONE_TYPE,
        GuardianFields.ADDRESS,
        GuardianFields.ADDRESS2,
        GuardianFields.ADDRESS3,
        GuardianFields.ADDRESS4,
        GuardianFields.CITY,
        GuardianFields.STATE,
        GuardianFields.ZIP,
        GuardianFields.RELATION_TO_CHILD,
        GuardianFields.FAMILY_REP,
        GuardianFields.SPECIAL_EMPLOYER,
        GuardianFields.MARKETING_CAMPAIGN,
        GuardianFields.MARKETING_NOTE,
        GuardianFields.STATUS,
        GuardianFields.DO_NOT_CALL,
        GuardianFields.DO_NOT_TEXT,
        GuardianFields.DO_NOT_EMAIL,
        GuardianFields.LOST_OPPORTUNITY_REASON,
        GuardianFields.LOST_OPPORTUNITY_COMMENT,
        GuardianFields.REJECTED_REASON,
        GuardianFields.REJECTED_COMMENT,
        // These three are hidden because they're not available in the API
        GuardianFields.ALT_CONTACT_PHONE,
        GuardianFields.ALT_CONTACT_EMAIL,
        GuardianFields.ALT_CONTACT_PHONE_TYPE,
        GuardianFields.PRIOR_CENTER,
        GuardianFields.PRIOR_COMMENT
    ];

    private filteredPendingViewFields: Array<string> = [
        GuardianFields.FIRST_NAME,
        GuardianFields.LAST_NAME,
        GuardianFields.EMAIL,
        GuardianFields.SHOWN_EMAIL,
        GuardianFields.PHONE,
        GuardianFields.PHONE_EXTENSION,
        GuardianFields.PHONE_COUNTRY,
        GuardianFields.COMMENTS,
        GuardianFields.ADDRESS,
        GuardianFields.ADDRESS2,
        GuardianFields.ADDRESS3,
        GuardianFields.ADDRESS4,
        GuardianFields.CITY,
        GuardianFields.STATE,
        GuardianFields.ZIP,
        GuardianFields.FAMILY_REP,
        GuardianFields.SPECIAL_EMPLOYER,
        GuardianFields.MARKETING_CAMPAIGN,
        GuardianFields.MARKETING_NOTE,
        GuardianFields.STATUS,
        GuardianFields.DO_NOT_CALL,
        GuardianFields.DO_NOT_TEXT,
        GuardianFields.DO_NOT_EMAIL,
        GuardianFields.LOST_OPPORTUNITY_COMMENT,
        GuardianFields.LOST_OPPORTUNITY_REASON,
        GuardianFields.REJECTED_COMMENT,
        GuardianFields.REJECTED_REASON,
        GuardianFields.ENROLLMENT_REP,
        // These three are hidden because they're not available in the API
        GuardianFields.ALT_CONTACT_PHONE,
        GuardianFields.ALT_CONTACT_EMAIL,
        GuardianFields.ALT_CONTACT_PHONE_TYPE,
        GuardianFields.PRIOR_CENTER,
        GuardianFields.PRIOR_COMMENT
    ];

    private updatedEvent = EventTypes.UPDATED;

    get customFields(): Array<CustomField> {
        return customFieldsStore.storedGuardianFields;
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featureStore.init();
        const promises = [];
        const fieldsPromise = fieldStore.init();
        promises.push(fieldsPromise);
        if (this.hasCrmPlus) {
            const customPromise = customFieldsStore.init();
            promises.push(customPromise);
        }
        if (featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
            const settingsPromise = ecSettingsStore.init();
            promises.push(settingsPromise);
        }
        await Promise.all(promises);
        this.familyDto = familyMapper.toUpdateDto(this.family);
        this.fields = this.getFields();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Get the fields to display.
     */

    private getFields(): Array<Field> {
        const filteredFields: Array<string> = this.isNewHub ? this.filteredNewHub : (this.isPendingView ? this.filteredPendingViewFields : this.filteredFields);
        const status = this.family.status?.id;
        const ecSettings = ecSettingsStore.storedSettings!;
        return generateGuardianFieldsList(filteredFields, false, status, ecSettings, this.isNewHub);
    }

    emitUpdate() {
        familyChangesStore.storeChange(this.familyDto as FamilyUpdateDtoInterface);
        this.$emit(this.familyUpdateEvent);
    }

    /**
     * Get the dto model for the field.
     *
     * @param field
     * @private
     */
    private getFieldModel(field: Field): any {
        if ([GuardianFields.INQUIRY_TYPE as string, GuardianFields.SOURCE as string].includes(field.value)) {
            return this.familyDto;
        }
        return this.familyDto?.primary_guardian;
    }
}
