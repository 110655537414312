import { baseCorpGaugeGraphOptions } from '@/charts/chart-utils';
import Highcharts from 'highcharts';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export class DashboardGaugeMixin extends Vue {
    /** The options for the chart **/
    chartOptions: Highcharts.Options = baseCorpGaugeGraphOptions;
    /** The class for the gauge comparison component **/
    comparisonClass = '';
    /** The class for the title text **/
    textClass = '';

    /**
     * Adjust the spacing for different sizes.
     *
     * @param name
     */
    @Watch('$vuetify.breakpoint.name', { immediate: true })
    respond(name: string): void {
        switch (name) {
            case 'xs':
            case 'sm':
                this.chartOptions.chart!.marginBottom = 0;
                this.comparisonClass = 'mt-n1';
                this.textClass = 'mt-n1';
                break;
            case 'md':
                this.chartOptions.chart!.marginBottom = 5;
                this.comparisonClass = 'mt-n2';
                this.textClass = 'mt-n2';
                break;
            case 'lg':
                this.chartOptions.chart!.marginBottom = -5;
                this.comparisonClass = 'mt-n2';
                this.textClass = 'mt-n2';
                break;
            case 'xl':
            default:
                this.chartOptions.chart!.marginBottom = -10;
                this.comparisonClass = 'mt-n1';
                this.textClass = 'mt-n3';
                break;
        }
    }
}
