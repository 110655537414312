















































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    TrendTrackingParameters,
    TrendTrackingPeriodType,
    TrendTrackingReportType
} from '@/reports/standard/standard-special-reports';
import { StandardReportUtils } from '@/reports/standard-report-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { getGmtOffset } from '@/date-time/date-time-utils';
import { baseLocales } from '@/locales/locales';
import { Report } from 'flexmonster';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { StandardReportsStore } from '@/reports/store/standard-reports-store';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const featuresState = getModule(FeaturesStore);
const standardReportsState = getModule(StandardReportsStore);

@Component
export default class TrendTrackingView extends Mixins(LocaleMixin, BasicValidationMixin) {
    @PropSync('canView') canViewSync!: boolean;
    @Prop() readonly savedReport!: Report | null;
    private loadingKey = 'reportSelectFieldsModal';
    private reportParams: TrendTrackingParameters | null = null;
    private isLoaded = false;
    private periodTypeOptions = [
        {
            text: 'Days',
            value: TrendTrackingPeriodType.DAY
        },
        {
            text: 'Weeks',
            value: TrendTrackingPeriodType.WEEK
        },
        {
            text: 'Months',
            value: TrendTrackingPeriodType.MONTH
        },
        {
            text: 'Years',
            value: TrendTrackingPeriodType.YEAR
        }
    ];

    get selectedFields() {
        return standardReportsState.selectedFields;
    }

    get typeOptions() {
        const options = [
            {
                text: 'Status',
                value: TrendTrackingReportType.STATUS
            },
            {
                text: 'Inquiry Type',
                value: TrendTrackingReportType.INQUIRY
            },
            {
                text: 'Family Source',
                value: TrendTrackingReportType.SOURCE
            }
        ];
        if (featuresState.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS)) {
            options.push({
                text: 'Marketing Campaign',
                value: TrendTrackingReportType.MARKETING
            });
        }
        return options;
    }

    @Watch('reportParams', { immediate: true, deep: true })
    viewUpdated() {
        if (!this.reportParams) {
            return;
        }
        standardReportsState.setTrendTrackingParams(this.reportParams);
        const currentCustomerDb = authState.isAssumingOtherCustomer ? authState.currentCustomerDb : 0;
        const offsetString = 'GMT' + getGmtOffset(authState.userTimeZone) + ':';
        const dateFormat = baseLocales[this.$i18n.locale].dateFormat;
        const reportData = StandardReportUtils.getTrendTrackingReport(this.reportParams, standardReportsState.selectedFields, currentCustomerDb ?? null, offsetString, dateFormat, authState.token ?? '');
        this.$emit(EventTypes.UPDATED, reportData);
    }

    @Watch('selectedFields', { immediate: true, deep: true })
    selectedFieldsUpdated() {
        this.viewUpdated();
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresState.init();
        this.reportParams = StandardReportUtils.getDefaultTrendTrackingParams(this.savedReport);
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

}
