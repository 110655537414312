













import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Center } from '@/organizations/locations/models/center';
import type { LocationIntegrationMapping } from '@/integrations/models/integration';
import { IntegrationLocationMappingId } from '@/integrations/models/integration';

@Component({
})
export default class ManagementSystemIdSelect extends Mixins(LocaleMixin) {
    @PropSync('mapping', { type: Object }) localMapping!: LocationIntegrationMapping;
    @Prop({ required: true }) readonly externalIds!: Array<IntegrationLocationMappingId>;
    // @Prop({ required: true }) readonly items!: Array<IntegrationLocationMappingId>;
    @Prop({ required: true }) readonly centers!: Array<LocationIntegrationMapping> | null;
    @Prop({ default: false }) readonly isSecondary!: boolean;

    get value() {
        const ret = !this.isSecondary
            ? (this.localMapping.external_system_id ? this.localMapping.external_system_id : '')
            : (this.localMapping.secondary_external_system_id ? this.localMapping.secondary_external_system_id : '');
        return ret;
    }

    set value(val: string) {
        if (this.isSecondary) {
            this.localMapping.secondary_external_system_id = val;
        } else {
            this.localMapping.external_system_id = val;
        }
    }

    get mapChoices() {
        return (center: Center) => {
            return this.externalIds.filter((item) => {
                // Filter out ids that are already used, except for the one used by the current location.
                // These could be chained more, but I think it's a bit easier to read broken out.
                if (this.centers === null) {
                    return true;
                }
                const otherCenters = this.centers.filter((ctr) => {
                    return ctr.center.id !== center.id;
                });
                const usedIds = otherCenters.map((ctr) => {
                    return this.isSecondary ? ctr.secondary_external_system_id ?? '' : ctr.external_system_id ?? '';
                });

                return !usedIds.includes(item.id.toString());
            });
        };
    }

    private looseEquals(a: any, b: any) {
        return a.toString() === b.toString();
    }
}
