



















import {
    countsToGraphData,
    getBasePieChartOptions,
    getMonochromeColorsWithLimits
} from '@/charts/chart-utils';
import {
    LINE_LEADER_PURPLE
} from '@/core/style-utils';
import { FeaturesStore } from '@/features/features-store';
import { DashboardStore } from '@/store/dashboard-store';
import { LoadingStore } from '@/store/loading-store';
import { StatsStore } from '@/store/stats-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import Highcharts from 'highcharts';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { ColorUtils } from '@/utils/color-utils';

const dashboardState = getModule(DashboardStore);
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {}
})
export default class InquiryPieChart extends Mixins(LocaleMixin) {
    @Prop({ type: Array, required: true }) readonly orgs!: number[];
    @Prop({ default: false }) custDashMode!: boolean;

    private graphId = 'inquiry-type-chart';
    private chartOptions: Highcharts.Options = {};
    private isMounted = false;
    private loadingKey = 'inquirySourceChart'

    /**
     * Retrieve the date range saved for the dashboard.
     */
    private get dateRange() {
        return dashboardState.storedDateFilter;
    }

    /**
     * Whenever the orgs are changed, update the chart.
     */
    @Watch('orgs')
    private async orgsUpdated() {
        if (this.chartOptions?.series) {
            (this.chartOptions.series[0] as Highcharts.SeriesPieOptions).data = [];
        }
        await this.updateCounts();
    }

    /**
     * Whenever the date range is changed, update the chart.
     */
    @Watch('dateRange', { deep: true })
    private async dateRangeChanged() {
        if (this.chartOptions?.series) {
            (this.chartOptions.series[0] as Highcharts.SeriesPieOptions).data = [];
        }
        await this.updateCounts();
    }

    @Watch('isMounted')
    mountedLater() {
        this.updateChart();
    }

    /**
     * On component creation.
     */
    private async created() {
        if (this.orgs.length !== 0) {
            await this.updateCounts();
        }
    }

    /**
     * On component mount.
     * If the v-if="isMounted" to render the chart after everything else is mounted, or widths get out of whack.
     * https://github.com/highcharts/highcharts-vue/issues/107
     */
    private async mounted() {
        await featuresStore.init();
        await interfaceSettingsStore.init();
        this.chartOptions = Highcharts.merge(await getBasePieChartOptions(), {
            chart: {
                height: '450px'
            },
            title: {
                align: 'left',
                margin: 30,
                text: 'Inquiry Types',
                style: {
                    fontFamily: 'Manrope, sans-serif',
                    fontSize: featuresStore.isLineLeaderEnroll ? '17px' : '22.5px',
                    fontWeight: featuresStore.isLineLeaderEnroll ? '600' : '500'
                },
                x: -10
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: 'Inquiry Types'
                    }
                }
            }
        });
        this.isMounted = true;
    }

    /**
     * Update the counts displayed in the graph.
     */
    private async updateCounts() {
        if (this.orgs.length === 0 || !this.dateRange || loadingState.isLoading(this.loadingKey)) {
            return;
        }
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        const params = {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
            org_ids: this.orgs
        };
        await statsState.initInquiryTypeCounts(params);
        this.updateChart();

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Update the chart to reflect the data retrieved.
     */
    private async updateChart() {
        if (!this.chartOptions?.series) {
            return;
        }
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;
        const data = countsToGraphData(statsState.inquiryTypeCounts);
        const series0 = this.chartOptions.series![0] as Highcharts.SeriesPieOptions;
        series0.data = data;
        if (isLineLeaderEnroll) {
            series0.innerSize = '60%';
            if (this.chartOptions?.plotOptions?.pie?.colors) {
                if (interfaceSettingsStore.hasWhiteLabel) {
                    const primaryColor = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
                    const lighterShade = ColorUtils.lighten(primaryColor, 70);
                    this.chartOptions.plotOptions.pie.colors = getMonochromeColorsWithLimits(primaryColor, lighterShade, data.length);

                } else {
                    // Reverse light and dark
                    const lighterShade = ColorUtils.lighten(LINE_LEADER_PURPLE, 70);
                    this.chartOptions.plotOptions.pie.colors = getMonochromeColorsWithLimits(LINE_LEADER_PURPLE, lighterShade, data.length);
                }
            }
        }
    }
}
