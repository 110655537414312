import { WorkflowCondition, WorkflowCreateDto } from '@/automation/workflows/models/workflow-models';
import {
    WorkflowConditionType,
    WorkflowEntityTypes
} from '@/automation/workflows/constants/workflow-constants';

export function getConditionExplanation(condition: WorkflowCondition): string {
    let response = '';
    let fieldName = condition.field.values.name;
    const words = fieldName.split(' ');
    let entityName = condition.entity.values.name ?? condition.entity.values.value;
    if (entityName === 'Family') {
        entityName = 'Guardian';
    }
    if (words[0] !== entityName && words[0] !== 'Family') {
        // Do not duplicate words like "Task Task" or "Child Child" or "Guardian Family"
        response += `${entityName} `;
    }

    if (fieldName !== 'Event Type') {
        if (fieldName === 'Task Type') {
            fieldName = 'Task';
        }
        if (fieldName === 'Result Type') {
            fieldName = 'Result';
        }
        response += `${fieldName} `;
    }

    let value = null;
    if (condition.value_one) {
        if (typeof condition.value_one === 'object' && 'id' in condition.value_one) {
            value = condition.value_one?.values.name ?? condition.value_one?.values.value;
        } else if (condition.value_one !== -1) {
            value = condition.value_one;
        } else {
            value = '[DELETED]';
        }
    }
    if (condition.is_not_equal) {
        const notEqualsValue = value || '[Blank]';
        response += `doesn't equal ${notEqualsValue}`;
    } else if (!condition.value_two) {
        response += `equals ${value}`;
    } else {
        let valueTwo = null;
        if (typeof condition.value_two === 'object' && 'id' in condition.value_two) {
            valueTwo = condition.value_two?.values.name ?? condition.value_two?.values.value;
        } else {
            valueTwo = '[DELETED]';
        }
        response += `changes from ${value} to ${valueTwo}`;
    }
    return response;
}

export function getWorkflowConditionTypes(dto: WorkflowCreateDto) {
    const entityTypes = dto.conditions.map((condition) => {
        return condition.entity;
    });
    if (dto.is_for_drip_campaigns) {
        entityTypes.splice(0);
    }
    const allowedFieldTypes: Array<WorkflowConditionType> = [];
    // we always allow first condition type
    if (entityTypes[0] === WorkflowEntityTypes.CHILD) {
        allowedFieldTypes.push('Child');
    }
    if (entityTypes[0] === WorkflowEntityTypes.TASK) {
        allowedFieldTypes.push('Task');
    }
    // we also allow second condition type if it's a child
    if (entityTypes[1] === WorkflowEntityTypes.CHILD) {
        allowedFieldTypes.push('Child');
    }

    return allowedFieldTypes;
}
