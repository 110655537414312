











import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component
export default class DurationSelect extends Mixins(LocaleMixin) {
    @Prop() duration!: number | null;
    @Prop({ type: String, default: 'Duration' }) readonly label!: string;
    @Prop({ default: false }) disabled!: boolean;
    private minuteOptions = [15, 30, 45, 60, 75, 90, 105, 120, 150, 180, 210, 240, 300, 360, 420, 480, 540, 600];
    private selected = 60;
    private items: Array<any> = [];

    mounted() {
        for (const minutes of this.minuteOptions) {
            this.items.push({ value: minutes, text: this.getText(minutes) });
        }
        if (this.duration) {
            this.selected = this.duration;
        }
    }

    @Watch('selected')
    emitUpdate() {
        this.$emit('input', this.selected);
    }

    @Watch('duration')
    updateDuration() {
        if (this.duration) {
            this.selected = this.duration;
        }
    }

    private getText(totalMinutes: number) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        let text = '';
        if (hours > 0) {
            if (minutes > 0) {
                text = `${hours} hr`;
            } else {
                text = `${hours} hour`;
            }
            if (hours > 1) {
                text += 's';
            }
        }
        if (minutes > 0) {
            text += ` ${minutes} min`;
        }
        return text;
    }
}
