






















import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';

@Component
export default class SaveButton extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isDisabled!: boolean;
    @Prop({ default: 'Save' }) readonly buttonLabel!: string;

    private save() {
        this.$emit(EventTypes.UPDATED);
    }
}
