

































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ManageTaskTableData, TasksSortKeys } from '@/tasks/models/task-models';
import { DataTableHeader } from 'vuetify';
import { formatDateWithTimezone } from '@/date-time/date-time-utils';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const tasksRepo = new TasksRepository();
@Component({
    components: { BaseClose }
})
export default class MassCancelPastDueTasksModal extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    // The past due tasks.
    @Prop({ default: null }) readonly pastDueTasks!: Array<ManageTaskTableData> | null;

    private loadingKey = 'massCancelPastDueTaskModalLoading';
    private pastDueTasksToCancel: Array<ManageTaskTableData> = [];
    private timezone = 'UTC';

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get headers(): DataTableHeader[] {
        return [
            {
                text: 'Guardian Name',
                value: TasksSortKeys.Guardian,
                width: '35%'
            },
            {
                text: 'Task Type',
                value: TasksSortKeys.Type,
                width: '10%'
            },
            {
                text: 'Assigned To',
                value: TasksSortKeys.Assignee,
                width: '40%'
            },
            {
                text: 'Task Due Date',
                value: TasksSortKeys.Date,
                width: '15%'
            }
        ];
    }

    @Watch('value', { immediate: true })
    private initialSelected() {
        if (this.modelValue && this.pastDueTasks && this.pastDueTasks.length > 0) {
            this.pastDueTasksToCancel = [...this.pastDueTasks];
            return;
        }

        this.pastDueTasksToCancel = [];
    }

    /**
     * initialization hook
     */
    async mounted() {
        if (authState.userInfoObject) {
            this.timezone = authState.userInfoObject.timezone;
        }
    }

    /**
     * Format due date per specs.
     * @param dueDateTime
     */
    public formatDueDate(dueDateTime: string): string {
        return this.formatDate(formatDateWithTimezone(dueDateTime, this.timezone));
    }

    private async massCancelTasks() {
        loadingState.loadingIncrement(this.loadingKey);
        await tasksRepo.cancelMany(this.pastDueTasksToCancel.map(task => task.id));
        loadingState.loadingDecrement(this.loadingKey);

        this.$emit('reload');
        this.close();
    }

    private close() {
        this.pastDueTasksToCancel = [];
        this.modelValue = false;
    }
}
