























































































































































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getChildCurrentStatusDate } from '@/families/status-histories-utils';
import { getAgeFromBirthDate, getChildStartDate, isChildExportable } from '@/families/families-utils';
import { parseISO } from 'date-fns';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { Child } from '@/families/models/child';
import { DataTableHeader } from 'vuetify';
import { EventTypes } from '@/constants/event-type-constants';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { IntegrationExportChildPostDto } from '@/integrations/models/integration';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { StatusesStore } from '@/families/store/statuses-store';
import { StatusHistories } from '@/families/models/status-histories';
import { StatusHistoriesRepository } from '@/families/repositories/status-histories-repository';
import type { Family, FamilyIntegration } from '@/families/models/family';
import FamilyDataInfoEditChild from '@/families/components/new/FamilyDataInfoEditChild.vue';
import store from '@/store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { IntegrationPartners } from '@/integrations/integration-constants';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { getWhiteLabelColors } from '@/utils/color-utils';
import FamilyDataInfoAddChild from '@/families/components/new/FamilyDataInfoAddChild.vue';

const interfaceSettingsStore = getModule(InterfaceSettingsStore);
const authState = getModule(AuthStore, store);
const featuresStore = getModule(FeaturesStore);
const loadingStore = getModule(LoadingStore);
const statusesState = getModule(StatusesStore);
const statusHistoriesRepository = new StatusHistoriesRepository();
const integrationsStore = getModule(IntegrationStore);
const integrationRepo = new IntegrationRepository();
const settingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: { FamilyDataInfoEditChild, FamilyDataInfoAddChild }
})
export default class FamilyDataInfoChildTable extends Mixins(FamilyHubMixin, LocaleMixin) {
    // Props
    @Prop() readonly family!: Family;

    // Properties
    private loadingKey = 'childrenTable';
    private tableId = 'childrenTable';
    private childAddedEvent = EventTypes.CHILD_ADDED;
    private childDeletedEvent = EventTypes.CHILD_DELETED;
    private childEditedEvent = EventTypes.CHILD_EDITED;
    private childExportPendingEvent = EventTypes.CHILD_EXPORT_PENDING;
    private addChildModal = false;
    private editChildModal = false;
    private familyStatusHistory: Array<StatusHistories> = [];
    private hasIntegration = false;
    private hasManage = false;
    private isLoaded = false;
    private localChildren: Array<any> = [];
    private managementSystemName = '';
    private selectedItem: Child | undefined | null = null;
    private timezone = 'UTC';
    private manageUrl = '';

    // Computed Getters / Setters
    private get childrenHeaders(): Array<DataTableHeader> {
        const headers = [] as Array<DataTableHeader>;
        headers.push({
            text: `Child Name (${this.family.children.length})`,
            value: 'name',
            class: 'child-name'
        });
        headers.push({
            text: 'Birthdate',
            value: 'birthdate',
            class: 'child-birthdate'
        });
        headers.push({
            text: 'Age',
            value: 'age',
            class: 'child-age'
        });
        headers.push({
            text: 'Status',
            value: 'status',
            class: 'child-status'
        });
        headers.push({
            text: 'Status Date',
            value: 'status_date',
            class: 'child-status-date'
        });
        headers.push({
            text: 'Start Date',
            value: 'start_date',
            class: 'child-start-date'
        });
        headers.push({
            text: 'Notes',
            value: 'comments',
            class: 'child-notes',
            sortable: false
        });

        return headers;
    }

    private get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    private get hasWhiteLabel() {
        return settingsStore.hasWhiteLabel;
    }

    get manageWhiteLabelcolor() {
        getWhiteLabelColors();
        const settingsStore = getModule(InterfaceSettingsStore);
        const primaryColor = '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)?.value as string;
        return primaryColor;
    }

    hasManageIntegration(childIntegrations: Array<FamilyIntegration>) {
        if (childIntegrations.length) {
            for (const integration of childIntegrations) {
                if (integration.integration.values.name === IntegrationPartners.MANAGE) {
                    return true;
                }
            }
        }
        return false;
    }

    manageProfileUrl(childIntegrations: Array<FamilyIntegration>) {
        const baseManageUrl = process.env.VUE_APP_MANAGE_URL;
        if (childIntegrations.length) {
            for (const integration of childIntegrations) {
                if (integration.integration.values.name === IntegrationPartners.MANAGE) {
                    return `${baseManageUrl}/people/${integration.id}`;
                }
            }
        }
        return baseManageUrl;
    }

    manageWhiteLabelUrl(childIntegrations: Array<FamilyIntegration>) {
        const interfaceSettingsMap = interfaceSettingsStore.stored;
        this.manageUrl = interfaceSettingsMap.get(SettingNames.WHITE_LABEL_URL)?.value as string ?? '';
        if (childIntegrations.length) {
            for (const integration of childIntegrations) {
                if (integration.integration.values.name === IntegrationPartners.MANAGE) {
                    return `${this.manageUrl}/people/${integration.id}`;
                }
            }
        }
        return this.manageUrl;
    }

    // Watches
    @Watch('family', { immediate: true, deep: true })
    async reloadChildren() {
        this.familyStatusHistory = await statusHistoriesRepository.getStatusHistories(this.family.id);
        this.loadChildren();
        await this.checkIntegrations();
    }

    // Lifecycle
    private async created() {
        loadingStore.loadingIncrement('familyData');

        await settingsStore.init();
        await statusesState.init();
        this.timezone = authState.userTimeZone;
        await this.reloadChildren();
        this.isLoaded = true;

        loadingStore.loadingDecrement('familyData');
    }

    async checkIntegrations() {
        // Check if they have the CMS integrations add-on feature enabled
        // If not, don't allow export
        const features = featuresStore.entities;
        for (const feature of features) {
            if (feature.identifier === FeatureConstants.CMS_ADD_ON_ID && !feature.is_active) {
                this.hasIntegration = false;
                return;
            }
        }

        await integrationsStore.init();
        if (this.family.primary_guardian.center_id) {
            const centerIntegration = await integrationsStore.getByCenterId(this.family.primary_guardian.center_id);

            if (centerIntegration && centerIntegration.is_active) {
                this.hasIntegration = true;
                this.managementSystemName = centerIntegration.name;
                this.hasManage = centerIntegration.name === IntegrationPartners.MANAGE;
            }
        }
    }

    // Methods
    // Private
    /**
     * Popup the dialog, but make sure it's in 'add' mode.
     *
     * @private
     */
    private addChild() {
        this.selectedItem = null;
        this.addChildModal = true;
    }

    // Event emitting.
    private childAdded(childId: number) {
        this.$emit(EventTypes.CHILD_ADDED, childId);
    }

    private childDeleted(childId: number) {
        this.$emit(EventTypes.CHILD_DELETED, childId);
    }

    private childEdited(childIds: Array<number>) {
        this.$emit(EventTypes.CHILD_EDITED, childIds);
    }

    /**
     * Popup the model in 'edit' mode.
     *
     * @param item
     * @private
     */
    private editChild(item: any) {
        if (!item.id) {
            return;
        }

        this.selectedItem = this.family.children.find((child) => item.id === child.id);
        if (!this.selectedItem) {
            return;
        }

        this.editChildModal = true;
    }

    // Date parsing functions.
    private getChildBirthDate(birthDate: string): string {
        return birthDate ? this.formatDate(parseISO(birthDate)) : '';
    }

    private getChildBirthDateAge(birthDate: string): string {
        if (!birthDate) {
            return 'N/A';
        }
        const birthdate = parseISO(birthDate);
        const today = new Date();
        if (birthdate >= today) {
            return 'Due Date';
        }

        return getAgeFromBirthDate(birthDate);
    }

    getChildOppCount(child: any): number {
        return child.opp_count;
    }

    /**
     * Map the children to a formatted dataset that makes the table look good.
     *
     * @private
     */
    private loadChildren() {
        this.localChildren = this.family.children.map((child) => {
            return {
                id: child.id,
                name: child.name,
                birthdate: child.date_of_birth,
                age: child.date_of_birth,
                status: child.status ? child.status.values.name : 'Pending',
                status_date: getChildCurrentStatusDate(child, this.familyStatusHistory),
                start_date: getChildStartDate(child) ? this.formatDate(parseISO(getChildStartDate(child))) : '',
                comments: child.comments,
                exported: child.exported,
                integrations: child.integrations,
                opp_count: (child.previous_opportunities?.length ?? 0) + 1
            };
        });
    }

    /**
     * Check to see if this child can be exported to a management system.
     *
     * @param item
     * @private
     */
    private canBeExported(item: any): boolean {
        const child = this.family.children.find(childEntity => childEntity.id === item.id);
        if (child) {
            return isChildExportable(child);
        }
        return false;
    }

    /**
     * Export child to CMS.
     *
     * @param item
     * @private
     */
    private async exportChild(item: any) {
        const exportChildDto = new IntegrationExportChildPostDto();
        exportChildDto.child_id = item.id;
        await integrationRepo.exportChild(exportChildDto);
        this.$emit(EventTypes.CHILD_EDITED, exportChildDto.child_id);
    }
}
