











import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { doLogout, legacyLoginWrapper } from '@/auth/auth';
import { baseUrl } from '@/core/base-url';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);

@Component
export default class LegacyReports extends Mixins(LocaleMixin) {
    private legacyReportsUrl = '';
    private loadingKey = 'legacy-reports';
    private isLoaded = false;

    async mounted() {
        if (authState.token) {
            loadingState.loadingIncrement(this.loadingKey);
            await legacyLoginWrapper(authState);
            this.legacyReportsUrl = baseUrl + '/reports';
        }
    }

    async beforeDestroy() {
        await doLogout();
    }

    private async loaded() {
        this.isLoaded = true;
        loadingState.loadingStop(this.loadingKey);
    }
}
