
































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import {
    ActionItem,
    ActionItemGroupType,
    BulkActionsTypes
} from '@/dashboards/models/action-items-models';
import { getModule } from 'vuex-module-decorators';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { EventTypes } from '@/constants/event-type-constants';
import type { ActionItemResultCounts } from '@/dashboards/models/action-items-models';
import { TaskTypeCalendar } from '@/tasks/task-type-constants';

const dashboardState = getModule(LocationDashboardStore);

@Component
export default class LocationDashboardBulkActionsButton extends Mixins(LocaleMixin) {

    @Ref('bulkActionsButton') readonly bulkActionsButton!: HTMLElement;
    @Prop({ required: true }) readonly resultsCount!: ActionItemResultCounts;
    @Prop({ default: false }) readonly isPendingCommCard!: boolean;
    @Prop({ default: null }) readonly calendarTasks!: ActionItem[];

    private menuActivated = false;

    get pendAndCommType() {
        return dashboardState.pendAndCommType;
    }

    get commCount() {
        return this.resultsCount.comm ?? 0;
    }

    get itemsCount() {
        return this.resultsCount.items ?? 0;
    }

    get pendingCount() {
        return this.resultsCount.pending ?? 0;
    }

    get bulkActions() {
        const actions = [];
        if (this.isPendingCommCard) {
            switch (this.pendAndCommType) {
                case ActionItemGroupType.COMM:
                    actions.push(BulkActionsTypes.MARK_AS_READ_COMM);
                    break;
                case ActionItemGroupType.PENDING:
                    actions.push(BulkActionsTypes.ACCEPT_PENDING, BulkActionsTypes.REJECT_PENDING);
                    break;
                default:
                    if (this.pendingCount) {
                        actions.push(BulkActionsTypes.ACCEPT_PENDING, BulkActionsTypes.REJECT_PENDING);
                    }
                    if (this.commCount) {
                        actions.push(BulkActionsTypes.MARK_AS_READ_COMM);
                    }
                    break;
            }
        } else if (this.calendarTasks) {
            const meetings = this.calendarTasks.some(event => event.task_type_id === TaskTypeCalendar.MEETING);
            meetings && actions.push(BulkActionsTypes.PAST_DUE_MEETINGS);
            const tours = this.calendarTasks.some(event => event.task_type_id === TaskTypeCalendar.TOUR);
            tours && actions.push(BulkActionsTypes.PAST_DUE_TOURS);
        } else {
            actions.push(BulkActionsTypes.BULK_CANCEL_ITEMS, BulkActionsTypes.BULK_COMPLETE_ITEMS);
        }
        return actions;
    }

    expandCard() {
        this.$emit('expanded');
    }

    handleAction(action: BulkActionsTypes) {
        this.$emit(EventTypes.UPDATED, action);
    }
}
