
























































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import FacebookLink from '@/integrations/components/FacebookLink.vue';
import FacebookMapping from '@/integrations/components/FacebookMapping.vue';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: {
        BaseClose,
        FacebookMapping,
        FacebookLink
    }
})
export default class ManageFacebook extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isOpen!: boolean;
    private isLinkOpen = false;
    private isMappingOpen = false;
    private closeEvent = EventTypes.CLOSE;

    close() {
        this.$emit(EventTypes.CLOSE);
    }
}
