import { baseUrl } from '@/core/base-url';
import { UserSettings, UserSettingsDto } from '@/staff/models/user-settings-models';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { createClient } from '@/transport/create-client';
import { AxiosInstance } from 'axios';
import { getModule } from 'vuex-module-decorators';

const authState = getModule(AuthStore, store);

/**
 * Handles requests to the staff settings endpoints, which include add-on requests.
 */
export class UserSettingsRepository {
    protected client: AxiosInstance;
    readonly endpoint = 'staff';

    public constructor() {
        let url = baseUrl + '/api/v3';
        if (authState.isAssumingOtherCustomer) {
            url = `${baseUrl}/api/admin/v1/customers/${authState.currentCustomerDb}`;
        }
        this.client = createClient(url);
    };

    /**
     * Get UserSettings for the given user.
     *
     * @param userId
     */
    public async get(userId: number): Promise<UserSettings> {
        const response = await this.client.get(this.endpoint + `/${userId}/settings`);
        return response.data;
    }

    /**
     * Update the settings for a given user.
     *
     * @param userId
     * @param payload
     */
    public async updateSettings(userId: number, payload: UserSettingsDto): Promise<UserSettings> {
        const response = await this.client.patch(this.endpoint + `/${userId}/settings`, payload);
        return response.data;
    }
}
