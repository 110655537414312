import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

const authStore = getModule(AuthStore, store);

@Component
export class AuthMixin extends Vue {
    /**
     * Whether the user is a corporate user
     */
    get isCorpUser() {
        return authStore.isCorporateUser;
    }

    /**
     * Whether the user is a superuser
     */
    get isSuperUser() {
        return authStore.isSuperuser;
    }

    /**
     * Get the user's timezone.
     */
    get userTimeZone(): string {
        return authStore.userTimeZone;
    }
}
