import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { OutgoingEmailUpdateDto, OutgoingEmail } from '@/communications/messages/models/email';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';

export class OutgoingEmailMapper implements AbstractMapper<OutgoingEmail> {
    toCreateDto(): AbstractCreateDto {
        return {
            // Nothing here yet..
        };
    }

    toUpdateDto(entity: OutgoingEmail): AbstractUpdateDto {
        const obj: OutgoingEmailUpdateDto = {};

        if (entity.html) {
            obj.html = entity.html;
        }

        if (entity.subject) {
            obj.subject = entity.subject;
        }

        if (entity.send_by_user) {
            obj.send_by_user = entity.send_by_user.id;
        }

        if (entity.reply_to_user) {
            obj.reply_to_user = entity.reply_to_user.id;
        }

        if (entity.sent_date_time) {
            obj.send_date_time = entity.sent_date_time;
        }

        if (entity.cc_alternate_email !== undefined) {
            obj.cc_alternate_email = entity.cc_alternate_email;
        }

        if (entity.cc_secondary_guardian !== undefined) {
            obj.cc_secondary_guardian = entity.cc_secondary_guardian;
        }

        if (entity.cc_user) {
            obj.cc_user = entity.cc_user.id;
        }

        if (entity.bcc_user) {
            obj.bcc_user = entity.bcc_user.id;
        }

        return obj;
    }
}
