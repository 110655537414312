







































































































































import { DripCampaignsMapper } from '@/automation/drip-campaigns/mappers/drip-campaigns-mapper';
import { DripCampaign } from '@/automation/drip-campaigns/models/drip-campaign';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { WorkflowLocationsUtils } from '@/automation/workflows/workflow-locations-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { CentersEnabled } from '@/organizations/locations/models/center';
import { DataTableHeader } from 'vuetify';
import { WorkflowsRepository } from '@/automation/workflows/repositories/workflows-repository';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { WorkflowLocationsStore } from '@/automation/workflows/store/workflow-locations-store';
import { Workflow } from '@/automation/workflows/models/workflow-models';
import { ValuedLink } from '@/models/base';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const dripCampaignMapper = new DripCampaignsMapper();
const dripCampaignsRepo = new DripCampaignsRepository();
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);
const workflowRepo = new WorkflowsRepository();
const workflowLocationsStore = getModule(WorkflowLocationsStore);
const workflowLocationsUtils = new WorkflowLocationsUtils();

interface GroupsEnabled {
    locationGroup: ValuedLink;
    enabled: boolean;
}

@Component({
    components: { BaseClose }
})
export default class AffectedLocationsModal extends Mixins(LocaleMixin) {
    /**
     * The drip campaign to get the affected locations for.
     */
    @Prop({ default: null }) readonly campaign!: DripCampaign | null;
    /**
     * The drip campaign to get the affected locations for.
     */
    @Prop({ type: Boolean, default: true }) readonly isForWorkflows!: boolean;
    /**
     * The workflow to get the affected locations for.
     */
    @Prop({ default: null }) readonly workflow!: Workflow | null;
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    // Helps keep track of which locations got updated
    private originalCenterData: Array<CentersEnabled> = [];
    // Locations table data
    private centersAvailable: Array<CentersEnabled> = [];
    // Location groups table data
    private groupsAvailable: Array<GroupsEnabled> = [];
    // Map of centers in each group
    private groupsMap: Map<number, Array<CentersEnabled>> = new Map();

    // Headers for locations table
    private locationHeaders: Array<DataTableHeader> = [
        { text: 'Loc. Code', value: 'center.code' },
        { text: 'Location', value: 'center.name' },
        { text: 'Enable', value: 'enabled' }
    ];

    // Headers for location groups table
    private locationGroupHeaders: Array<DataTableHeader> = [
        { text: 'Location Group', value: 'locationGroup.value' },
        { text: 'Enable', value: 'enabled' }
    ];

    // For the snackbar when changes are made
    private isUpdated = false;
    private loadingKey = 'availableLocations';
    // Required variable in order to add a search bar to v-data-table
    private search = '';
    // To toggle between locations and location groups tables
    private locationGroupsMode = false;

    /**
     * The entity to view the locations for.
     */
    private get entity(): DripCampaign | Workflow | null {
        return this.campaign ?? this.workflow;
    }

    /**
     * The type of entity being viewed.
     */
    private get entityType(): string {
        return this.isForWorkflows ? 'automation' : 'drip campaign';
    }

    get isLocationGroupsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get className(): string {
        return this.centersAvailable.length > 50 ? 'shrink-by-twenty' : this.centersAvailable.length > 20 ? 'shrink-by-ten' : '';
    }

    @Watch('modelValue')
    async modelWatch() {
        if (this.modelValue) {
            // NOTE: workflow-locations-store is initialized whenever the parent modal (ManageWorkflow) is opened
            loadingState.loadingIncrement(this.loadingKey);
            const data = await workflowLocationsUtils.buildLocationAndGroupData(this.entity);
            this.originalCenterData = data.originalCenterData;
            this.centersAvailable = data.centersAvailable;
            this.groupsAvailable = data.groupsAvailable;
            this.groupsMap = data.groupsMap;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private close() {
        this.originalCenterData = [];
        this.centersAvailable = [];
        this.groupsAvailable = [];
        this.groupsMap = new Map();
        this.modelValue = false;
    }

    // If a center is enabled/disabled we need to look at its location groups
    // and determine if we should show them as enabled/disabled
    private toggleCenter() {
        for (const item of this.groupsAvailable) {
            let groupEnabled = false;

            if (!this.groupsMap.has(item.locationGroup.id)) {
                continue;
            }
            // get locations using group array
            const centerDataArray: Array<CentersEnabled> = this.groupsMap.get(item.locationGroup.id)!;

            for (let i = 0; i < centerDataArray.length; i++) {
                if (centerDataArray[i].enabled) {
                    groupEnabled = true;
                    break;
                }
            }
            item.enabled = groupEnabled;
        }
    }

    // If location group is enabled/disabled then all centers using that group will be enabled/disabled
    private toggleGroup(item: GroupsEnabled) {
        if (!this.groupsMap.has(item.locationGroup.id)) {
            return;
        }

        // get locations using group array
        const centerDataArray: Array<CentersEnabled> = this.groupsMap.get(item.locationGroup.id)!;

        for (let i = 0; i < centerDataArray.length; i++) {
            centerDataArray[i].enabled = item.enabled;
        }

        // need to check if any other groups are affected as well
        this.toggleCenter();
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        // if isAdd, then update the store with the center data as the workflow has not been created yet
        // NOTE: workflow-locations-store is initialized whenever the parent modal (ManageWorkflow) is opened
        workflowLocationsStore.updateLocationMappings(this.centersAvailable);

        // if isEdit, then make the PUT's and update the store so that you don't have to load in all center data again
        // Only send PUT for centers that got their enabled/disabled flags changed
        if (this.entity !== null) {
            const enabledCenterIds = this.centersAvailable.filter((centerData) => {
                return centerData.enabled;
            }).map((centerData) => {
                return centerData.center.id;
            });
            if (this.workflow) {
                const workflow = await workflowRepo.updateLocations(this.workflow.id, { enabled_center_ids: enabledCenterIds });
                this.$emit(EventTypes.UPDATED, workflow);
            } else if (this.campaign) {
                const dto = dripCampaignMapper.toUpdateDto(this.campaign);
                dto.enabled_center_ids = enabledCenterIds;
                const campaign = await dripCampaignsRepo.putOne(this.campaign.id, dto);
                this.$emit(EventTypes.UPDATED, campaign);
            }
        }
        this.isUpdated = true;
        loadingState.loadingDecrement(this.loadingKey);

        this.close();
    }
}
