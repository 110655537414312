import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { CustomValuesMapper } from '@/crm-types/custom-fields/mappers/custom-values-mapper';
import { PhoneMapper } from '@/families/mappers/phone-mapper';
import { User, UserCreateDto, UserUpdateDto } from '@/staff/models/user';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

export class UserMapper implements AbstractMapper<User> {
    private customValueMapper = new CustomValuesMapper();

    toCreateDto(entity: User): UserCreateDto {
        const userInfo = (getModule(AuthStore, store)).userInfoObject;
        const customValues = [];
        if (entity.custom_values) {
            for (const customValue of entity.custom_values) {
                customValues.push(this.customValueMapper.toDto(customValue));
            }
        }
        return {
            center_id: entity.center_id ?? null,
            email_address: entity.email_address ?? '',
            first_name: entity.first_name ?? '',
            force_password_change: entity.force_password_change ?? true,
            is_active: entity.is_active ?? true,
            last_name: entity.last_name ?? '',
            needs_eula: entity.needs_eula ?? true,
            org_id: entity.org_id ?? null,
            password: undefined,
            phone: (new PhoneMapper()).toCreateDto(entity.phone),
            alternate_phone: (new PhoneMapper()).toCreateDto(entity.alternate_phone),
            recovery_email: entity.recovery_email ?? '',
            username: entity.username ?? '',
            timezone: entity.timezone ?? (userInfo ? userInfo.timezone : ''),
            country: entity.country ?? (userInfo ? userInfo.country : ''),
            position_title: entity.position_title ? entity.position_title.id : null,
            is_brand_email_enabled: entity.is_brand_email_enabled ?? false,
            is_dual_mode_legacy_blocked: entity.is_dual_mode_legacy_blocked ?? false,
            is_on_enrollment_team: entity.is_on_enrollment_team ?? false,
            is_notified_on_replies: entity.is_notified_on_replies ?? false,
            is_manager_notification_enabled: entity.is_manager_notification_enabled ?? false,
            is_web_pending_notification_enabled: entity.is_web_pending_notification_enabled ?? false,
            is_refer_transfer_notification_enabled: entity.is_refer_transfer_notification_enabled ?? false,
            custom_values: customValues,
            termination_time: entity.termination_time ?? null
        };
    }

    toUpdateDto(entity: User): UserUpdateDto {
        const dto = this.toCreateDto(entity) as UserUpdateDto;
        dto.id = entity.id as number;
        return dto;
    }
}
