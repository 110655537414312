import { EnrollmentCenterSettings, EnrollmentCenterSettingsUpdateDto } from '@/enrollment-center/models/enrollment-center-models';
import { emailIsValid } from '@/communications/messages/email-utils';

export class EnrollmentCenterMapper {
    toUpdateDto(entity: EnrollmentCenterSettings): EnrollmentCenterSettingsUpdateDto {
        const taskTypesArray: Array<number> = [];
        for (const taskType of entity.task_types) {
            if (taskType && taskType.id) {
                taskTypesArray.push(taskType.id);
            }
        }
        if (!emailIsValid(entity.email_address)) {
            entity.email_address = '';
        }
        return {
            name: entity.name,
            flow: entity.flow ? entity.flow.id : 9100,
            url: entity.url,
            email_address: entity.email_address,
            phone_number: entity.phone_number,
            phone_extension: entity.phone_extension,
            task_types: taskTypesArray,
            enable_offerings: entity.enable_offerings,
            enable_details: entity.enable_details,
            enable_pricing: entity.enable_pricing,
            enable_availability: entity.enable_availability,
            enable_ratings: entity.enable_ratings
        } as EnrollmentCenterSettingsUpdateDto;
    }
}
