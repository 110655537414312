import { AbstractApiStore } from '@/store/abstract-api-store';
import type {
    FamiliesFilter,
    FamiliesFilterDtoInterface
} from '@/filters/models/families-filter';
import { FamiliesFilterRepository } from '@/filters/repositories/families-filter-repository';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { getFilterSignature } from '@/filters/families-filter-utils';

interface FamiliesFilterState {
    entities: Array<FamiliesFilter>;
    mappedEntities: Map<number, FamiliesFilter>;
    currentFilter: FamiliesFilter | null;
    isCustom: boolean;
    currentOrg: number;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FILTERS
})
export class FamiliesFilterStore extends AbstractApiStore<FamiliesFilter> implements FamiliesFilterState {
    readonly repository = new FamiliesFilterRepository();
    entities: Array<FamiliesFilter> = [];
    mappedEntities: Map<number, FamiliesFilter> = new Map();
    currentFilter: FamiliesFilter | null = null;
    isCustom = false;
    currentOrg = 0;

    @Mutation
    public storeCurrentFilter(filter: FamiliesFilter) {
        this.currentFilter = filter;
    }

    @Mutation
    public setIsCustom(flag: boolean) {
        this.isCustom = flag;
    }

    @Mutation
    public changeOrg(org: number) {
        this.currentOrg = org;
    }

    @Action
    public async applyAnonymousFilter(filter: FamiliesFilterDtoInterface) {
        filter.is_anonymous = true;
        const signature = getFilterSignature(filter);
        const stored = this.entities.find((filter) => {
            return filter.name === signature;
        });
        if (stored) {
            this.context.commit('storeCurrentFilter', stored);
            return;
        }
        const existing = await this.repository.getFilterByName(null, signature);
        if (existing) {
            this.context.commit('storeCurrentFilter', existing);
            this.context.commit('addOrUpdateEntity', existing);
            return;
        }
        filter.name = signature;
        const newFilter = await this.repository.createFilter(filter);
        this.context.commit('addOrUpdateEntity', newFilter);
        this.context.commit('storeCurrentFilter', newFilter);
    }

    @Action
    public async addFilter(filter: FamiliesFilterDtoInterface) {
        const newFilter = await this.repository.createFilter(filter);
        this.context.commit('addOrUpdateEntity', newFilter);
        this.context.commit('storeCurrentFilter', newFilter);
    }

    @Action
    public async updateFilter(update: { filterId: number; dto: FamiliesFilterDtoInterface }) {
        const updatedFilter = await this.repository.updateFilter(update.filterId, update.dto);
        this.context.commit('addOrUpdateEntity', updatedFilter);
        this.context.commit('storeCurrentFilter', updatedFilter);
    }

}
