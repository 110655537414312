
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VTextField from 'vuetify/lib/components/VTextField/VTextField';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VTextField] });

export default base.extend({
    name: 'base-text-field',
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: true
        },
        elevation: {
            type: Number,
            default: 0
        }
    },
    computed: {
        classes(): object {
            return {
                ...VTextField.options.computed.classes.call(this),
                'base-text-field': true
            };
        }
    }
});
