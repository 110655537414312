
















    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins } from 'vue-property-decorator';

@Component
    export default class ReportSelectFieldsLayout extends Mixins(LocaleMixin) {

    }
