






import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { checkUserLoggedIn } from '@/auth/auth';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import vueDebounce, { PluginConfig } from 'vue-debounce';
import { getLocaleForCountry } from '@/locales/locale-helpers';
import type { Dictionary } from 'vue-router/types/router';
import { baseUrl } from '@/core/base-url';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import smartlookClient from 'smartlook-client';
import { facebookAppId, smartLookKey, vueEnv } from './core/env-vars';
import { FbSdkUtils } from '@/integrations/fb-sdk-utils';
import { LoginUtils } from '@/core/login-utils';

const authState = getModule(AuthStore, store);
const familiesFiltersStore = getModule(FamiliesFilterStore);
const fbSdkUtils = new FbSdkUtils();

@Component
export default class App extends Vue {
    get isLoggedIn() {
        return authState.isLoggedIn;
    }

    get needsLegacyRedirect() {
        return authState.isNeedsLegacyRedirect;
    }

    async created() {
        if (vueEnv !== 'development') {
            smartlookClient.init(smartLookKey);
        }

        const fbsdk = fbSdkUtils.initSdk(facebookAppId, 'v18.0');
        await fbsdk;

        // double check they're still logged in and haven't logged out via some other method and/or the session expired, etc
        if (authState.isLoggedIn) {
            checkUserLoggedIn(authState)
                .then((loggedIn) => {
                    if (!loggedIn) {
                        this.$router.push({ name: 'login' });
                    }
                });

            if (authState.userInfoObject) {
                this.$root.$i18n.locale = getLocaleForCountry(authState.userInfoObject.country);
                if (authState.overrideCountry) {
                    this.$root.$i18n.locale = getLocaleForCountry(authState.overrideCountry);
                }

                // Client based translations.
                // Usage: {{ 'something' | cTranslate }}
                // OR this.$options.filters.cTranslate('Word');
                const clientId = authState.currentCustomerDb ? authState.currentCustomerDb : authState.userInfoObject.client_id;
                await LoginUtils.setupCTranslate(Number(clientId));
            }
        }
    }

    @Watch('isLoggedIn')
    async loggedInChange(val: boolean, oldVal: boolean) {
        if (oldVal && !val) {
            await this.$router.go(0);
        }
    }

    @Watch('needsLegacyRedirect', { immediate: true })
    needsRedirectChange(val: boolean) {
        if (val) {
            authState.clearNeedsLegacyRedirect();
            window.location.href = baseUrl;
        }
    }

    get routeParams(): Dictionary<any> | null | undefined {
        return this.$route.params;
    }

    @Watch('routeParams')
    public clearStoresByRouteParams(params: Dictionary<any> | null | undefined): void {
        if (!params || !params.statusFilter || params.statusFilter !== 'custom') {
            // Clear family filters when not needed.
            familiesFiltersStore.setIsCustom(false);
        }
    }
};

// Register the `debounce` plugin
Vue.use<PluginConfig>(vueDebounce, { lock: true, defaultTime: '400ms', listenTo: ['input', 'keyup'] });
