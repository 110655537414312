
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VSelect from 'vuetify/lib/components/VSelect/VSelect';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VSelect] });
export default base.extend({
    name: 'emphasized-select',
    props: {
        // set filled true, as this is the select style we want for emphasized selects
        filled: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        // add crm-card to the css class of the rendered element
        classes(): object {
            return {
                ...VSelect.options.computed.classes.call(this),
                'base-select': true,
                'emphasized-select': true
            };
        }
    }
});
