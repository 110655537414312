/**
 * Plugin to do template variable tags.
 * Originally from: https://github.com/ambassify/tinymce-variable
 * Made to actually work, but this could be a lot better probably.
 */

import tinymce from 'tinymce';

tinymce.PluginManager.add('variableTags', function(editor) {
    /**
     * Object that is used to replace the variable string to be used
     * in the HTML view
     * @type {object}
     */
    const mapper = editor.getParam('variable_mapper', {});

    /**
     * Define a list of variables that are allowed.
     * if the variable is not in the list it will not be automatically converted
     * by default no validation is done
     *
     * @type {array}
     */
    const valid = editor.getParam('variable_valid', null);

    /**
     * Get custom variable class name.
     * @type {string}
     */
    const className = editor.getParam('variable_tag_class', 'variable_tag');

    /**
     * Prefix and suffix to use to mark a variable.
     * @type {string}
     */
    const prefix = editor.getParam('variable_tag_prefix', '{{ ');
    const suffix = editor.getParam('variable_tag_suffix', ' }}');
    const stringVariableRegex = new RegExp(prefix + '([a-z._]*)?' + suffix, 'gi');

    /**
     * Check if a certain variable is valid.
     *
     * @param {string} name
     * @return {boolean}
     */
    function isValid(name) {
        if (!valid || valid.length === 0) {
            return true;
        }

        const validString = '|' + valid.join('|') + '|';

        return validString.indexOf('|' + name + '|') > -1;
    }

    function getMappedValue(cleanValue) {
        if (typeof mapper === 'function') {
            return mapper(cleanValue);
        }

        return Object.prototype.hasOwnProperty.call(mapper, cleanValue) ? mapper[cleanValue] : cleanValue;
    }

    /**
     * Strip variable to keep the plain variable string
     * @example "{{ test }}" => "test"
     * @param {string} value
     * @return {string}
     */
    function cleanVariable(value) {
        return value.replace(/[^a-zA-Z0-9._]/g, '');
    }

    /**
     * convert a text variable "x" to a span with the needed
     * attributes to style it with CSS
     * @param  {string} value
     * @return {string}
     */
    function createHTMLVariable(value) {
        const cleanValue = cleanVariable(value);

        // Check if variable is valid.
        if (!isValid(cleanValue)) {
            return value;
        }

        const cleanMappedValue = getMappedValue(cleanValue);

        editor.fire('variableToHTML', {
            value: value,
            cleanValue: cleanValue
        });

        const variable = prefix + cleanValue + suffix;

        return '<span class="' + className + '" data-original-variable="' + variable + '" contenteditable="false">' + cleanMappedValue + '</span>';
    }

    function isVariable(element) {
        return !!(typeof element.getAttribute === 'function' && element.hasAttribute('data-original-variable'));
    }

    /**
     * Convert variable strings into HTML elements.
     *
     * @return {void}
     */
    function stringToHTML() {
        const nodeList = [];
        let node;

        // Find nodes that contain a string variable.
        tinymce.walk(editor.getBody(), function(n) {
            if (n.nodeType === 3 && n.nodeValue && stringVariableRegex.test(n.nodeValue)) {
                nodeList.push(n);
            }
        }, 'childNodes');

        // Loop over all nodes that contain a string variable.
        for (let i = 0; i < nodeList.length; i++) {
            const nodeValue = nodeList[i].nodeValue.replace(stringVariableRegex, createHTMLVariable);
            const div = editor.dom.create('div', null, nodeValue);

            while ((node = div.lastChild)) {
                const variableTag = editor.dom.insertAfter(node, nodeList[i]);

                if (isVariable(node)) {
                    let next = node.nextSibling;
                    if (!next) {
                        // There is no next sibling, create one...
                        const div2 = editor.dom.create('div', null, '﻿');
                        editor.dom.insertAfter(div2.lastChild, variableTag);
                        next = node.nextSibling;
                    }

                    // Place the cursor after the variable.
                    editor.selection.setCursorLocation(next);
                }
            }

            editor.dom.remove(nodeList[i]);
        }
    }

    // Make the tags pretty.
    editor.contentStyles.push('.variable_tag { \n' +
        '    cursor: pointer;\n' +
        '    background-color: #65b9dd;\n' +
        '    color: #FFF;\n' +
        '    padding: 3px 8px;\n' +
        '    border-radius: 3px;\n' +
        '    display: inline-block;\n' +
        '    line-height: 12px;\n' +
        '}\n'
    );

    // Make tags show up as tags.
    editor.on('NodeChange', stringToHTML);
    editor.on('SetContent', stringToHTML);
    editor.on('keyup', stringToHTML);

    // Prevent the built in 'tooltip' on HTML elements from firing.
    editor.on(
        'mousedown',
        (e) => {
            if (e.target.className === 'variable_tag') {
                e.stopImmediatePropagation();
            }
        });
});
