


































































































import { Org } from '@/models/organization/org';
import { OrganizationLevelWithOrganizations } from '@/organizations/levels/models/org-level-models';
import { OrgLevelsStore } from '@/organizations/levels/store/org-levels-store';
import { Center } from '@/organizations/locations/models/center';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { OrgsStore } from '@/store/orgs-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { AppStateStore } from '@/store/app-state-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { LoadingStore } from '@/store/loading-store';
import BaseClose from '@/components/base/BaseClose.vue';
import { EventTypes } from '@/constants/event-type-constants';

const appState = getModule(AppStateStore, store);
const authStore = getModule(AuthStore, store);
const centersStore = getModule(CentersStore, store);
const orgLevelsState = getModule(OrgLevelsStore);
const orgsState = getModule(OrgsStore);
const featuresStore = getModule(FeaturesStore);
const centersRepo = new CentersRepository();
const loadingState = getModule(LoadingStore);
@Component({
    components: { BaseClose }
})
export default class CenterSelect extends Mixins(LocaleMixin) {
    @Prop({ type: Boolean, default: false }) readonly isOpen!: boolean;

    private centers: Array<Center | null> = [];
    private currentCenter: Center | null | undefined = null;
    private dataFetched = false;
    private initted = false;
    private isDisabled = true;
    private orgCenterMap: Map<number, Center | null> = new Map();
    private orgLevelsWithOrgs: Array<OrganizationLevelWithOrganizations> = [];
    private orgSelectDialog = false;
    private centerMenuOpened = false;
    private orgTableKey = 0;
    private loadingKey = 'centerSelect';

    /**
     * Whether or not the user can choose an org rather than just a center.
     */
    private get canChooseOrg() {
        return this.isCrmPlus && !this.isDisabled;
    }

    /**
     * Get the name of the current org.
     */
    private get currentOrgName(): string {
        return appState.storedCurrentOrg ? appState.storedCurrentOrg.name : '';
    }

    /**
     * Whether or not the db is in crm plus mode.
     */
    private get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    @Watch('isOpen')
    private async propOpener() {
        if (!this.canChooseOrg) {
            this.centerMenuOpened = this.isOpen;
        } else {
            this.orgSelectDialog = this.isOpen;
        }
    }

    @Watch('orgSelectDialog')
    private async orgSelectOpened() {
        if (this.orgSelectDialog && !this.dataFetched) {
            await this.fullInit();
        }
    }

    @Watch('centerMenuOpened')
    private async centersOpened() {
        if (this.centerMenuOpened && !this.dataFetched) {
            await this.fullInit();
        }
    }

    private async created() {
        // Make everything use orgs, but also set center since it is still needed for un-refactored areas.
        await featuresStore.init();

        // Set the default current org and center
        if (authStore.isCenterStaff && authStore.userInfoObject && authStore.userInfoObject.center_id) {
            this.currentCenter = await centersStore.getAccessibleCenterById(authStore.userInfoObject.center_id);
            if (this.currentCenter) {
                this.centers = [this.currentCenter];
                appState.updateCurrentOrg((await orgsState.getOrgById(this.currentCenter.organization_id)) as Org);
            }
        } else {
            if (this.isCrmPlus && authStore.userInfoObject) {
                appState.updateCurrentOrg(await orgsState.getOrgById(authStore.userInfoObject.org_id) as Org);
                if (authStore.userInfoObject.center_id) {
                    this.currentCenter = await centersStore.getAccessibleCenterById(authStore.userInfoObject.center_id);
                }
            }

            if (!this.isCrmPlus) {
                this.currentCenter = await centersRepo.getFirstAccessible();
                appState.updateCurrentOrg(await orgsState.getOrgById(this.currentCenter.organization_id) as Org);
            }

            this.isDisabled = false;
        }

        if (this.currentCenter) {
            appState.updateCurrentCenter(this.currentCenter);
        }

        this.initted = true;
        // let's still grab the centers when we can, but this shouldn't
        // block things on the page anymore because we've already set
        // currentCenter and currentOrg
        if (!authStore.isCenterStaff) {
            await centersStore.initAccessibleCenters();
            this.centers = centersStore.storedAccessibleCenters;
        }
    }

    private async fullInit() {
        loadingState.loadingIncrement(this.loadingKey);
        const promises = [];
        if (this.isCrmPlus && !authStore.isCenterStaff) {
            const orgLevelsPromise = orgLevelsState.init();
            promises.push(orgLevelsPromise);
        }

        const orgsPromise = orgsState.init();
        promises.push(orgsPromise);
        await Promise.all(promises);
        this.mapCentersToOrgs();

        if (this.canChooseOrg) {
            // Build out which org levels they can see and which orgs fall under each level
            const allOrgLevels = orgLevelsState.stored;
            const accessibleOrgs = orgsState.stored;
            const orgLevelsMap: Map<number, Array<Org>> = new Map();
            for (const org of accessibleOrgs) {
                orgLevelsMap.set(
                    org.level,
                    [
                        ...(orgLevelsMap.get(org.level) || []),
                        org
                    ]
                );
            }

            // Loop through all of the org levels and delete the unpopulated ones.
            // This will place the org levels in the correct order, and only show the ones the user has access to.
            for (const orgLevel of allOrgLevels) {
                if (orgLevelsMap.has(orgLevel.id)) {
                    this.orgLevelsWithOrgs.push({
                        organizationLevel: orgLevel,
                        organizations: orgLevelsMap.get(orgLevel.id) as Array<Org>
                    });
                }
            }
        }

        this.dataFetched = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Change the current Center for the user; change the Org too.
     */
    private async changeCenter(center: Center) {
        this.currentCenter = center;
        if (this.currentCenter) {
            appState.updateCurrentCenter(center);
            await this.setCurrentOrgFromCenter();
            this.$emit(EventTypes.CLOSE);
        }
    }

    /**
     * Change the org the user is "viewing"
     */
    private async changeOrg(org: Org) {
        appState.updateCurrentOrg(org);
        appState.updateCurrentCenter(this.orgCenterMap.get(org.id) ?? null);
        ++this.orgTableKey;
        this.orgSelectDialog = false;
        this.$emit(EventTypes.CLOSE);
    }

    /**
     * Get the placeholder text for the organization list for the org level.
     */
    private getOrgSelectionPlaceholderText(orgLevelName: string): string {
        return orgLevelName.charAt(0).match(/[aeiou]/i)
            ? 'Select an ' + orgLevelName.charAt(0).toUpperCase() + orgLevelName.slice(1)
            : 'Select a ' + orgLevelName.charAt(0).toUpperCase() + orgLevelName.slice(1);
    }

    /**
     * Map centers to org ids.
     */
    private mapCentersToOrgs() {
        // Set all the org keys for the centers
        for (const center of centersStore.storedAccessibleCenters) {
            this.orgCenterMap.set(center.organization_id, center);
        }
    }

    /**
     * Set the current org from the current Center.
     */
    private async setCurrentOrgFromCenter() {
        if (this.currentCenter && this.orgCenterMap.has(this.currentCenter.organization_id)) {
            appState.updateCurrentOrg((await orgsState.getOrgById(this.currentCenter.organization_id)) as Org);
        }
    }

    private close() {
        this.orgSelectDialog = false;
        this.$emit(EventTypes.CLOSE);
    }
}
