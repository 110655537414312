var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"text-h4 font-weight-bold mx-1"},[_c('v-col',{attrs:{"cols":"8"}},[_vm._v("Location Groups")])],1),_c('v-row',[_c('v-col',{staticClass:"ml-4",attrs:{"cols":"4"}},[_c('base-button-secondary',{on:{"click":function($event){_vm.switchToViewByLocation = !_vm.switchToViewByLocation}}},[_vm._v(" "+_vm._s(_vm.switchToViewByLocation ? 'View By Group' : 'View By Location')+" ")])],1)],1),_c('base-card',{class:_vm.isLineLeaderEnroll ? 'mx-0': ''},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('base-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.switchToViewByLocation),expression:"!switchToViewByLocation"}],attrs:{"headers":_vm.byGroupHeaders,"items":_vm.itemsByGroup},scopedSlots:_vm._u([{key:"item.currentLocationGroup.value",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[(item.currentLocationGroup.id > 0)?_c('InlineEditable',{attrs:{"input":item.currentLocationGroup.value,"col-size":"6"},on:{"update:input":function($event){return _vm.$set(item.currentLocationGroup, "value", $event)},"update":function($event){return _vm.applyChangesToSelectList(item.currentLocationGroup)}}}):_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{ref:"input",attrs:{"outlined":"","single-line":"","hide-details":"","dense":"","type":"text","rules":_vm.requiredField},on:{"keyup":function($event){return _vm.applyChangesToSelectList(item.currentLocationGroup)}},model:{value:(item.currentLocationGroup.value),callback:function ($$v) {_vm.$set(item.currentLocationGroup, "value", $$v)},expression:"item.currentLocationGroup.value"}})],1)],1)]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"label":"* Locations","multiple":"","hide-details":"","dense":"","filled":"","return-object":"","items":_vm.centersAvailable,"rules":_vm.requiredMultiSelect},model:{value:(item.currentCenters),callback:function ($$v) {_vm.$set(item, "currentCenters", $$v)},expression:"item.currentCenters"}})]}},{key:"item.copy",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.copyGroup(item)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteGroup(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('base-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.switchToViewByLocation),expression:"switchToViewByLocation"}],attrs:{"headers":_vm.byLocationHeaders,"items":_vm.itemsByLocation},scopedSlots:_vm._u([{key:"item.center.text",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('span',{staticClass:"normal-text-size ml-1"},[_vm._v(" "+_vm._s(item.center.text)+" ")])])]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"label":"Groups","multiple":"","hide-details":"","dense":"","filled":"","return-object":"","items":_vm.groupsAvailable},model:{value:(item.currentGroups),callback:function ($$v) {_vm.$set(item, "currentGroups", $$v)},expression:"item.currentGroups"}})]}}])})],1),(!_vm.switchToViewByLocation)?_c('base-button-secondary',{staticClass:"mt-n16 mb-6",on:{"click":_vm.addEmptyRow}},[_vm._v(" Add Group ")]):_vm._e()],1),_c('v-card-actions',{class:_vm.switchToViewByLocation ? 'justify-center mt-n4' : 'justify-center mt-n6'},[_c('base-button-primary',{staticClass:"ml-1 same-width-button",attrs:{"disabled":!_vm.hasChanges || !_vm.validForm},on:{"click":_vm.submit}},[_vm._v(" Save ")]),_c('base-button-secondary',{staticClass:"mr-1 same-width-button",attrs:{"disabled":!_vm.hasChanges},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1),_c('base-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('base-button-plain',_vm._b({on:{"click":function($event){_vm.showSnack = false}}},'base-button-plain',attrs,false),[_c('base-close')],1)]}}]),model:{value:(_vm.showSnack),callback:function ($$v) {_vm.showSnack=$$v},expression:"showSnack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }