


































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { customerLandingPage, LandingPage } from '@/automation/landing-pages/models/landing-pages-models';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import AddLandingPageModal from '@/automation/landing-pages/components/AddLandingPageModal.vue';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { Route } from 'vue-router';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';

const landingPagesRepo = new LandingPagesRepository();
const loadingState = getModule(LoadingStore);
const featureStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: { AddLandingPageModal }
})
export default class LandingPages extends Mixins(LocaleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const automationLandingPagesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationLandingPages);
        if (!featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) || automationLandingPagesPermissionGrant) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private loadingKey = 'landingPages';
    private landingPageHeaders: Array<DataTableHeader> = [
        { text: 'Landing Page Name', value: 'name' },
        { text: 'Landing Page Type', value: 'type.values.value' },
        { text: 'Last Edited', value: 'last_edited_datetime' },
        { text: 'Edited By', value: 'last_edited_staff' }
    ];

    private landingPages: Array<LandingPage> = [];
    private isAddNewPage = false;

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        const featuresInit = featureStore.init();
        const results = await landingPagesRepo.getAll();
        await featuresInit;
        this.landingPages = results.entities;
        if (featureStore.isFeatureEnabled(FeatureConstants.CLIENT_FORMS) && featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE)) {
            this.landingPages.unshift(customerLandingPage);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private editLandingPage(item: LandingPage) {
        if (!item.id) {
            return;
        }
        if (item.id === -1) {
            this.$router.push({ name: 'client-form-settings' });
            return;
        }
        this.$router.push({ name: 'landing-page', params: { id: item.id.toString() } });
    }

    private addNewPage() {
        this.isAddNewPage = true;
    }
}
