










































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingLogin from '@/components/LoadingLogin.vue';

@Component({
    components: { LoadingLogin }
})
export default class LoginLayout extends Vue {
    private isLoginPage = false;

    @Watch('$route.name', { immediate: true })
    updateLoginPage() {
        this.isLoginPage = this.$route.name === 'login';
    }
};
