var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-data-table',{staticClass:"truncated-columns family-data-documents-table",class:_vm.canEditFamily ? 'clickable-rows' : '',attrs:{"headers":_vm.familyDocumentsHeaders,"items":_vm.formattedDocuments,"item-key":"id","dense":""},on:{"click:row":_vm.downloadDocument},scopedSlots:_vm._u([{key:"header.action",fn:function(ref){return [(_vm.canEditFamily)?_c('base-button-secondary',{staticClass:"table-header-button",on:{"click":function($event){$event.stopPropagation();return _vm.openUploadDocumentsModal($event)}}},[_vm._v(" Add ")]):_vm._e()]}},{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.filename}},[_vm._v(" "+_vm._s(item.filename)+" ")])]}},{key:"item.linked_to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.linked_to}},[_vm._v(" "+_vm._s(item.linked_to)+" ")])]}},{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.file_size / 1024))+" KB ")]}},{key:"item.datetime_last_modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeString(item.datetime_last_modified))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canDeleteDocs && _vm.canEditFamily)?_c('v-btn',{attrs:{"data-cy":"document-delete-btn","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeDocument(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])}),_c('family-data-upload-documents-modal',{attrs:{"family":_vm.family},on:_vm._d({},[_vm.updatedEvent,_vm.reloadData]),model:{value:(_vm.showUploadModal),callback:function ($$v) {_vm.showUploadModal=$$v},expression:"showUploadModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }