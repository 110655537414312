import { customerLandingPageId, LandingPage } from '@/automation/landing-pages/models/landing-pages-models';
import {
    UserAddOns,
    UserAddOnsDto,
    UserAddOnSection,
    UserAddOnSectionDto,
    UserAddOnSectionFlag,
    UserAddOnSectionFlagDto,
    UserSettings,
    UserSettingsDto
} from '@/staff/models/user-settings-models';

/**
 * Handles setting up DTOs for user settings.
 */
export class UserSettingsMapper {
    /**
     * Finalize the settings dto for submission to the API.
     *
     * @param dto
     * @param availableLandingPages
     */
    public finalizeDtoForSubmission(dto: UserSettingsDto, availableLandingPages: Array<LandingPage>) {
        const landingPageIds: Array<number> = [];
        let customerFormNotifications: boolean | null = false;
        const availableLandingPageIds = availableLandingPages.map(landingPage => landingPage.id);
        const hasCustomerLandingPage = availableLandingPageIds.find(id => id === customerLandingPageId) !== undefined;
        for (const landingPageId of dto.notification_forms) {
            // Strip out the customer landing page since it will be handled by a separate property.
            // Only send landing page ids that are possible; remove old pages.
            if (landingPageId === customerLandingPageId && availableLandingPageIds.includes(landingPageId)) {
                customerFormNotifications = true;
            } else if (availableLandingPageIds.includes(landingPageId)) {
                landingPageIds.push(landingPageId);
            }
        }

        // Set the customer landing page notification to null if list is empty and the customer has one
        if (hasCustomerLandingPage && landingPageIds.length === 0 && !customerFormNotifications) {
            customerFormNotifications = null;
        }

        // Clear out the pages list if all are selected.
        // This represents all are selected and will allow for notifications for pages added in the future.
        if (
            (!hasCustomerLandingPage && landingPageIds.length === availableLandingPageIds.length) ||
            (hasCustomerLandingPage && (landingPageIds.length === availableLandingPageIds.length - 1) && customerFormNotifications)
        ) {
            landingPageIds.splice(0, landingPageIds.length);
            customerFormNotifications = null;
        }
        dto.notification_forms = landingPageIds;
        dto.notification_customer_form = customerFormNotifications;
    }

    /**
     * Create the DTO for updating a user's settings
     */
    toUpdateDto(settings: UserSettings): UserSettingsDto {
        const formList = [];
        for (const form of settings.notification_forms) {
            formList.push(form.id);
        }
        if (settings.notification_customer_form && (formList.length !== 0 || settings.only_customer_form_notification)) {
            formList.push(customerLandingPageId);
        }

        return {
            add_ons: this.toAddOnsDto(settings.add_ons),
            notification_centers: settings.notification_centers.map((center) => {
                return center.id;
            }),
            notification_forms: formList,
            notification_customer_form: settings.notification_customer_form
        };
    }

    private toAddOnsDto(addOns: UserAddOns): UserAddOnsDto {
        const sectionsDto = [];
        for (const section of addOns.sections) {
            sectionsDto.push(this.toAddOnSectionDto(section));
        }
        return {
            sections: sectionsDto
        };
    }

    private toAddOnSectionDto(addOnSection: UserAddOnSection): UserAddOnSectionDto {
        const flagsDto: Array<UserAddOnSectionFlagDto> = [];
        for (const flag of addOnSection.flags) {
            flagsDto.push(this.toAddOnSectionFlagDto(flag));
        }
        return {
            flags: flagsDto,
            id: addOnSection.id
        };
    }

    private toAddOnSectionFlagDto(addOnSectionFlag: UserAddOnSectionFlag): UserAddOnSectionFlagDto {
        return {
            id: addOnSectionFlag.id,
            is_enabled: addOnSectionFlag.is_enabled
        };
    }
}
