












































































import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { encodeFile, openFile } from '@/core/file-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { DataTableHeader } from 'vuetify';
import { AppStateStore } from '@/store/app-state-store';
import FileChips from '@/components/FileChips.vue';
import BaseClose from '@/components/base/BaseClose.vue';
const emailAttachmentsRepo = new EmailAttachmentsRepository();
const loadingState = getModule(LoadingStore);
const appState = getModule(AppStateStore);

@Component({
    components: {
        BaseClose,
        FileChips
    }
})
export default class EmailAttachments extends Mixins(LocaleMixin) {
    @PropSync('attachments') localAttachments!: Array<EmailAttachment>;
    /**
     * The org id to filter the results to
     */
    @Prop({ required: false, default: null }) orgId!: number | null;

    private showAddDialog = false;
    private file: File | null = null;
    private loadingKey = EmailAttachments.name;
    // Events
    private fileOpen = EventTypes.FILE_OPEN;
    private fileRemove = EventTypes.FILE_REMOVE;
    private tableHeaders: Array<DataTableHeader> = [
        { text: 'File Name', value: 'filename' },
        { text: 'File Type', value: 'mime_type' },
        { text: 'File Size', value: 'file_size' }
    ];

    private totalSize = 0;
    private maxSize = 10 * 1024 * 1024; // 10 MB max
    private items: Array<EmailAttachment> = [];

    @Watch('orgId')
    private async orgChanged() {
        await this.loadAllAttachments();
    }

    async mounted() {
        await this.loadAllAttachments();
    }

    private async loadAllAttachments() {
        loadingState.loadingIncrement(this.loadingKey);

        const response = await emailAttachmentsRepo.get(null, { 'no-pagination': 1, orgId: this.orgId ?? undefined });
        this.items = response.entities;

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async addExistingAttachment(attachment: EmailAttachment) {
        if (!this.localAttachments) {
            this.localAttachments = [];
        }

        if (!this.localAttachments.includes(attachment)) {
            this.localAttachments.push(attachment);
        }

        this.updateAttachments();
        this.showAddDialog = false;
    }

    // Check that total attachment size is not too large.
    private checkSize() {
        if (!this.file) {
            return true;
        }

        return this.file.size + this.totalSize <= this.maxSize;
    }

    private updateAttachments() {
        this.totalSize = 0;
        for (const item of this.localAttachments) {
            this.totalSize += item.file_size;
        }
    }

    async uploadAttachment() {
        loadingState.loadingIncrement(this.loadingKey);
        if (!this.file) {
            loadingState.loadingDecrement(this.loadingKey);
            return;
        }

        if (!this.localAttachments) {
            this.localAttachments = [];
        }

        if (!this.checkSize()) {
            await this.$swal({
                text: `Total size of all attachments for a template may not exceed ${this.maxSize / 1024 / 1024}MB.`,
                icon: 'error'
            });
            this.file = null;
        } else {
            const fileContents = await encodeFile(this.file);
            const attachment = await emailAttachmentsRepo.create({
                filename: this.file.name,
                file: fileContents,
                org: appState.currentOrg ? appState.currentOrg.id : 1,
                is_hidden: true
            });
            this.localAttachments.push(attachment);
            this.updateAttachments();
            this.showAddDialog = false;
            await this.loadAllAttachments();
            this.file = null;
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    // People like to click and open files.
    private async open(file: EmailAttachment) {
        const fileContent = await emailAttachmentsRepo.getFile(file);
        openFile(fileContent, file.filename, file.mime_type);
    }

    private async remove(file: EmailAttachment) {
        if (!this.localAttachments) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        const response = await this.$swal({
            text: `Are you sure you want to remove the attachment "${file.filename}" from this email?`,
            showConfirmButton: true,
            showCancelButton: true
        });
        if (response.isConfirmed) {
            // Just remove it from the list; could lead to orphaned files; don't care
            this.localAttachments = this.localAttachments.filter(attachment => attachment.id !== file.id);
            this.updateAttachments();
        }

        await this.loadAllAttachments();
        loadingState.loadingDecrement(this.loadingKey);
    }
}
