import { Class, ClassCreatePostDto, ClassUpdateDtoInterface } from '@/organizations/locations/models/class';
import { AbstractRepository } from '@/repositories/abstract-repository';

export class ClassesRepository extends AbstractRepository<Class> {
    protected endpoint = '';

    public async create(centerId: number, classData: ClassCreatePostDto): Promise<Class> {
        const response = await this.client.post<Class>(`centers/${centerId}/classrooms`, [classData]);
        return response.data;
    }

    public async retrieveAll(centerId: number): Promise<Array<Class>> {
        const response = await this.client.get<Array<Class>>(`centers/${centerId}/classrooms`);
        return response.data;
    }

    public async update(centerId: number, classData: ClassUpdateDtoInterface): Promise<Class> {
        const response = await this.client.put<Class>(`centers/${centerId}/classrooms/${classData.id}`, classData);
        return response.data;
    }

    public async delete(centerId: number, classId: number): Promise<void> {
        await this.client.delete<Class>(`centers/${centerId}/classrooms/${classId}`);
    }

    public async retrieveAllCms(centerId: number): Promise<Array<Class>> {
        const response = await this.client.get<Array<Class>>(`centers/${centerId}/classrooms?include_cms_only=1`);
        return response.data;
    }
}
