




























































import { encodeFile } from '@/core/file-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { EventTypes } from '@/constants/event-type-constants';

const loadingState = getModule(LoadingStore);
const familiesRepo = new FamiliesRepository();

@Component({
    components: { BaseClose }
})
export default class UploadLogo extends Mixins(LocaleMixin, VmodelShowMixin) {
    // Whether or not the dialog should be visible
    // The form to add the logo to
    @Ref('form') readonly uploadForm!: VForm;

    // The logo file
    private file: File | null | undefined = null;
    private loadingKey = 'uploadImport';
    private validForm = false;

    // The rules for what can be uploaded as an import
    get importRules() {
        return [
            (file: File | null | undefined) => {
                if (!file) {
                    return true;
                }
                return file.type === 'text/csv' || 'Please choose a CSV';
            }
        ];
    }

    private close() {
        this.modelValue = false;
    }

    // Handle uploading the logo
    private async upload() {
        if (!this.file) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const fileContents = await encodeFile(this.file).catch(e => Error(e));
        if (this.file.type !== 'text/csv') {
            await this.$swal({
                text: 'Please upload a CSV',
                icon: 'error'
            });
            loadingState.loadingIncrement(this.loadingKey);
            return;
        }
        if (fileContents instanceof Error) {
            await this.$swal({
                text: 'Something went wrong, and the CSV could not be uploaded. Please try again later.',
                icon: 'error'
            });
            loadingState.loadingIncrement(this.loadingKey);
            return;
        }
        await familiesRepo.uploadImport({ file: fileContents, filename: this.file.name });
        this.$emit(EventTypes.UPLOADED);
        this.file = null;
        this.close();
        loadingState.loadingDecrement(this.loadingKey);
    }
}
