








































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { LocationDetail } from '@/enrollment-center/models/enrollment-center-location-models';
import { LocationDetailsRepository } from '@/enrollment-center/repositories/location-details-repository';
import { EnrollmentCenterDetails } from '@/enrollment-center/models/enrollment-center-models';

const settingsStore = getModule(EnrollmentCenterSettingsStore);
const loadingState = getModule(LoadingStore);
const detailsRepo = new LocationDetailsRepository();

interface DetailUpdate {
    detail: EnrollmentCenterDetails;
    value: string;
}

@Component
export default class LocationDetailsTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;
    private loadingKey = 'locationDetails';
    private locationDetails: Array<LocationDetail> = [];
    private locationDetailUpdates: Array<DetailUpdate> = [];

    get detailsSettings() {
        return settingsStore.detailsSettings;
    }

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    @Watch('location', { deep: true, immediate: true })
    async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);
        this.locationDetails = await detailsRepo.getLocationDetails(this.location.id);
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode')
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        }
        if (this.localMode === EditMode.EDITING) {
            this.locationDetailUpdates = this.detailsSettings.map((detailSetting) => {
                return { detail: detailSetting, value: this.detailValue(detailSetting.id) };
            });
        }
        if (this.localMode === EditMode.SAVING) {
            const promises = [];
            for (const update of this.locationDetailUpdates) {
                promises.push(detailsRepo.updateLocationDetail(this.location.id, update.detail.id, { value: update.value }));
            }
            await Promise.all(promises);
            this.localMode = EditMode.SAVED;
        }
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await settingsStore.initDetails();
        loadingState.loadingDecrement(this.loadingKey);
    }

    detailValue(detailId: number): string {
        const matchingDetail = this.locationDetails.filter((value) => {
            return value.detail.id === detailId;
        });
        if (matchingDetail.length > 0) {
            return matchingDetail[0].value ?? '';
        }
        return '';
    }
}
