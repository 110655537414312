
































































































































































import { Component, Mixins, Prop, PropSync, Watch, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center } from '@/organizations/locations/models/center';
import { centerDistance, sortCenterByDistance } from '@/organizations/locations/center-utils';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import LocationInfoTab from '@/enrollment-center/components/LocationInfoTab.vue';
import { EditMode } from '@/core/edit-modes';
import LocationOfferingsTab from '@/enrollment-center/components/LocationOfferingsTab.vue';
import LocationPricingTab from '@/enrollment-center/components/LocationPricingTab.vue';
import LocationDetailsTab from '@/enrollment-center/components/LocationDetailsTab.vue';
import LocationAvailabilityTab from '@/enrollment-center/components/LocationAvailabilityTab.vue';
import LocationRatingsTab from '@/enrollment-center/components/LocationRatingsTab.vue';
import { LoadingStore } from '@/store/loading-store';
import LocationAdvisoryComponent from '@/enrollment-center/components/LocationAdvisoryComponent.vue';
import DashboardTourCalendar from '@/dashboards/components/DashboardTourCalendar.vue';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { AppStateStore } from '@/store/app-state-store';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { VForm } from '@/types/types';
import { FeaturesStore } from '@/features/features-store';
import { getCodeFromSchoolNum } from '@/enrollment-center/enrollment-center-utils';

const appState = getModule(AppStateStore, store);
const centersState = getModule(CentersStore);
const enrollmentCenterSettingsState = getModule(EnrollmentCenterSettingsStore);
const loadingState = getModule(LoadingStore);
const staffUtils = new StaffUtils();
const authState = getModule(AuthStore, store);
const featuresState = getModule(FeaturesStore);

type InfoTab = 'info' | 'offerings' | 'details' | 'tuition' | 'availability' | 'ratings';

@Component({
    components: {
        DashboardTourCalendar,
        LocationAdvisoryComponent,
        LocationRatingsTab,
        LocationAvailabilityTab,
        LocationDetailsTab,
        LocationPricingTab,
        LocationOfferingsTab,
        LocationInfoTab
    }
})
export default class LocationInformationMain extends Mixins(LocaleMixin) {
    @Prop({ default: false }) inContactMode!: boolean;
    @Prop({ default: '' }) schoolNum!: string;
    @PropSync('tabMode') tabEditMode!: EditMode;
    @Ref('form') readonly form!: VForm;

    private canEdit = false;
    private loadingKey = 'locationInformationMain';
    private selectedLocation: Center | null = null;
    private activeTab: InfoTab = 'info';
    private advisoryEditMode = EditMode.SAVED;
    private infoTab: InfoTab = 'info';
    private offeringsTab: InfoTab = 'offerings';
    private detailsTab: InfoTab = 'details';
    private pricingTab: InfoTab = 'tuition';
    private availabilityTab: InfoTab = 'availability';
    private ratingsTab: InfoTab = 'ratings';
    private valid = false;
    private isExpanded = false;
    private tabsKey = 1;

    /**
     * computed edit mode based on subcomponent edit modes
     */
    get isEdit() {
        return this.tabEditMode !== EditMode.SAVED || this.advisoryEditMode !== EditMode.SAVED;
    }

    get infoActive() {
        return this.activeTab === this.infoTab;
    }

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    /**
     * Sort centers by distance from selected center
     */
    get sortedCenters() {
        if (!this.selectedLocation) {
            return centersState.accessibleCenters;
        }
        if (this.selectedLocation && authState.isCenterStaff) {
            return new Array<Center>(this.selectedLocation);
        }
        return sortCenterByDistance(this.selectedLocation, centersState.accessibleCenters);
    }

    /**
     * helper for tabs compute
     */
    get eccSettings() {
        return enrollmentCenterSettingsState.settings;
    }

    /**
     * compute tabs based on settings
     */
    get tabs() {
        const tabs = [this.infoTab];
        if (this.eccSettings) {
            if (this.eccSettings.enable_offerings) {
                tabs.push(this.offeringsTab);
            }
            if (this.eccSettings.enable_details) {
                tabs.push(this.detailsTab);
            }
            if (this.eccSettings.enable_pricing) {
                tabs.push(this.pricingTab);
            }
            if (this.eccSettings.enable_availability) {
                tabs.push(this.availabilityTab);
            }
            if (this.eccSettings.enable_ratings) {
                tabs.push(this.ratingsTab);
            }
        }
        return tabs;
    }

    get locationSelect(): Center | null {
        for (const entity of centersState.accessibleCenters) {
            if (appState.storedCurrentCenterEt && entity.id === appState.storedCurrentCenterEt.id) {
                this.selectedLocation = entity;
                return entity;
            }
        }

        this.selectedLocation = null;
        return null;
    }

    set locationSelect(item: Center | null) {
        this.selectedLocation = item;
        if (this.selectedLocation) {
            appState.updateCurrentCenterEt(this.selectedLocation);
        } else {
            appState.updateCurrentCenterEt(null);
        }
    }

    /**
     * component init cycle callback
     */
    async created() {
        this.isExpanded = this.inContactMode;
        const promises = [];
        promises.push(centersState.initAccessibleCenters());
        promises.push(enrollmentCenterSettingsState.init());
        const permissionPromise = staffUtils.getUserPermission(PermissionName.EnrollmentCenterEditLocation);
        await Promise.all(promises);
        await permissionPromise;
        Promise.resolve(permissionPromise).then((result) => { this.canEdit = result; });
        this.selectedLocation = centersState.accessibleCenters[0];
        if (this.schoolNum) {
            const schoolCode = getCodeFromSchoolNum(this.schoolNum);
            const schoolMatch = centersState.accessibleCenters.find(c => c.code === schoolCode);
            if (schoolMatch) {
                this.selectedLocation = schoolMatch;
            }
        }
        appState.updateCurrentCenterEt(this.selectedLocation);
    }

    @Watch('tabEditMode')
    tabEditChanged(newMode: EditMode, oldMode: EditMode) {
        if (oldMode === EditMode.SAVING && newMode === EditMode.SAVED) {
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('advisoryEditMode')
    advisoryEditChanged(newMode: EditMode, oldMode: EditMode) {
        if (oldMode === EditMode.SAVING && newMode === EditMode.SAVED) {
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    /**
     * center select labels
     * @param center
     * @private
     */
    private centerDisplay(center: Center) {
        const code = center.code.length ? ` (${center.code})` : '';
        if (!this.selectedLocation) {
            return center.name + code;
        }
        if (this.selectedLocation.id === center.id) {
            return center.name + code;
        }
        const distance = centerDistance(this.selectedLocation, center);
        if (!isNaN(distance)) {
            return center.name + code + ' (' + this.formatDistance(distance) + ')';
        }
        return center.name + code;
    }

    private startEdit() {
        this.tabEditMode = EditMode.EDITING;
        this.advisoryEditMode = EditMode.EDITING;
        this.$nextTick(() => {
            this.form.validate();
        });
    }

    private save() {
        loadingState.loadingIncrement(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKey);
        this.tabEditMode = EditMode.SAVING;
        this.$emit('tab-mode', this.tabEditMode);
        this.advisoryEditMode = EditMode.SAVING;
    }

    private cancel() {
        this.tabEditMode = EditMode.SAVED;
        this.advisoryEditMode = EditMode.SAVED;
    }

    private toggleExpanded() {
        this.isExpanded = !this.isExpanded;
        this.tabsKey++;
    }
}
