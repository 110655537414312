







































































































































import AdditionalChildInfo from '@/families/components/AdditionalChildInfo.vue';
import { ChildMapper } from '@/families/mappers/child-mapper';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventTypes } from '@/constants/event-type-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { Child, ChildUpdateDtoInterface } from '@/families/models/child';
import { BaseStatuses } from '@/constants/status-constants';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import type { Family } from '@/families/models/family';
import type { SettingsChange } from '@/families/models/status';
import { IntegrationExportChildInterface } from '@/integrations/models/integration';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { getAgeFromBirthDate } from '@/families/families-utils';

const childMapper = new ChildMapper();
const featuresState = getModule(FeaturesStore);
const integrationsState = getModule(IntegrationStore);

@Component({
    components: { AdditionalChildInfo, StatusChangeSelect }
})
export default class ChildrenInfo extends Mixins(LocaleMixin) {
    @Prop() family!: Family;
    /**
     * Whether or not this is being view from the pending family modal.
     */
    @Prop({ type: Boolean, default: false }) readonly isPendingView!: boolean;
    @Prop({ type: Array }) onOriginalSettings!: boolean[];

    private expandedChildren: Array<boolean> = [];
    private updateEvent = EventTypes.UPDATED;
    private settingsChangedEvent = EventTypes.SETTINGS_CHANGED;
    private exportChildCheckboxEvent = EventTypes.CHILD_EXPORT_CHECKED;
    private disableSaveEvent = EventTypes.DISABLE_SAVE;
    private hasIntegration = false;
    private childrenToExport: Array<number> = [];
    private managementSystemName = '';

    async created() {
        // Check if they have the CMS integrations add-on feature enabled
        // If not, don't allow export
        await featuresState.init();
        const features = featuresState.entities;
        for (const feature of features) {
            if (feature.identifier === FeatureConstants.CMS_ADD_ON_ID && !feature.is_active) {
                this.hasIntegration = false;
                return;
            }
        }

        await integrationsState.init();
        if (this.family.primary_guardian.center_id) {
            const centerIntegration = await integrationsState.getByCenterId(this.family.primary_guardian.center_id);

            if (centerIntegration && centerIntegration.is_active) {
                this.hasIntegration = true;
                this.managementSystemName = centerIntegration.name;
            }
        }

        this.expandedChildren = this.family.children.length > 0
            ? new Array<boolean>(this.family.children.length).fill(false)
            : new Array<boolean>(1).fill(false);
    }

    /**
     * Check to see if this child can be exported to a management system.
     *
     * @param child
     * @private
     */
    private isChildExportable(child: Child): boolean {
        if (!child.status) {
            return false;
        }

        return !child.exported &&
            (child.status.id === BaseStatuses.WAIT_LIST || child.status.id === BaseStatuses.REGISTERED);
    }

    private updateStatus() {
       this.$emit(EventTypes.UPDATED);
    }

    private settingsChanged(settings: SettingsChange) {
        this.$emit(EventTypes.SETTINGS_CHANGED, settings);
    }

    private updateExport(exportChild: IntegrationExportChildInterface) {
        this.$emit(EventTypes.CHILD_EXPORT_CHECKED, exportChild);
    }

    private exportChild(child: Child) {
        this.$emit(EventTypes.CHILD_EXPORTED, child);
    }

    getChildBirthDateAge(birthDate: string): string {
        const birthdate = new Date(birthDate);
        const today = new Date();
        if (birthdate >= today) {
            return this.formatDate(birthDate) + ' (due date)';
        }

        return this.formatDate(birthDate) + ' (' + getAgeFromBirthDate(birthDate) + ')';
    }

    /**
     * Get the dto for the child.
     *
     * @param child
     */
    getChildDto(child: Child): ChildUpdateDtoInterface {
        return childMapper.toUpdateDto(child);
    }

    /**
     * Expand the additional child section for the child at the given index.
     * This is only for the pending view.
     *
     * @param index
     */
    private expandChild(index: number): void {
        this.$set(this.expandedChildren, index, !this.expandedChildren[index]);
    }
}
