import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import type { StatusChangeInterface } from '@/families/models/status';
import store from '@/store';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.STATUS_CHANGES
})
export class StatusChangesStore extends VuexModule {
    private changes: Map<string, StatusChangeInterface> = new Map();
    // For reactivity, because Vue2 can't inspect a Map()
    private changesArray: Array<StatusChangeInterface> = [];
    private hasPendingChanges = false;

    public get changesForChildId() {
        return (childId: number): StatusChangeInterface | null => {
            return this.changes.get(`c-${childId}`) ?? null;
        };
    }

    public get changesForGuardian() {
        return (familyId: number): StatusChangeInterface | null => {
            return this.changes.get(`f-${familyId}`) ?? null;
        };
    }

    /**
     * Return list of all pending status changes
     */
    public get pendingChanges() {
        return (familyId: number): Array<StatusChangeInterface> => {
            return Array.from(this.changes.values()).filter((change) => {
                return change.family_id === familyId;
            });
        };
    }

    /**
     * Because maps cannot be watched by Vue2
     */
    public get areChangesPending(): boolean {
        return this.hasPendingChanges;
    }

    // So we have a single place where we define the key string
    public static get keyForChange() {
        return (statusChange: StatusChangeInterface): string => {
            return statusChange.child_id ? `c-${statusChange.child_id}` : `f-${statusChange.family_id}`;
        };
    }

    /**
     * Clear the queue of pending status changes
     */
    @Mutation
    public clear(familyId: number) {
        this.changes.clear();
        this.changesArray = this.changesArray.filter((change) => {
            return change.family_id !== familyId;
        });
        for (const change of this.changesArray) {
            this.changes.set(StatusChangesStore.keyForChange(change), change);
        }
        this.hasPendingChanges = this.changesArray.length !== 0;
    }

    @Mutation
    public clearForChild(childId: number) {
        this.changes.clear();
        this.changesArray = this.changesArray.filter((change) => {
            return change.child_id !== childId;
        });
        for (const change of this.changesArray) {
            this.changes.set(StatusChangesStore.keyForChange(change), change);
        }
        this.hasPendingChanges = this.changesArray.length !== 0;
    }

    @Mutation
    public clearForGuardian(familyId: number) {
        this.changes.clear();
        this.changesArray = this.changesArray.filter((change) => {
            // Remove pending changes that are for the guardian only
            return change.family_id !== familyId || (change.family_id === familyId && change.child_id !== null);
        });
        for (const change of this.changesArray) {
            this.changes.set(StatusChangesStore.keyForChange(change), change);
        }
        this.hasPendingChanges = this.changesArray.length !== 0;
    }

    /**
     * Keep track of pending changes
     * @param statusChange
     */
    @Mutation
    public storeChange(statusChange: StatusChangeInterface) {
        this.changes.set(StatusChangesStore.keyForChange(statusChange), statusChange);
        this.changesArray = Array.from(this.changes.values());
        this.hasPendingChanges = true;
    }

    @Mutation
    public updateChildId(params: { statusChange: StatusChangeInterface; childId: number }) {
        params.statusChange.child_id = params.childId;
    }

}
