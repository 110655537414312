















































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import { addMonths, formatDateForApi } from '@/date-time/date-time-utils';
import { CountByPeriods } from '@/models/report/period-statistics';
import Highcharts from 'highcharts';
import { countPeriodsToAreaGraphSeries, countPeriodsToXAxis } from '@/charts/chart-utils';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);

@Component({
    components: { }
})
export default class SparklineChartExample extends Mixins(LocaleMixin) {
    private statusData: Array<any> = [];
    private monthsBack = 2;
    private months = [...Array(10).keys()].map((i) => {
        return {
            value: i + 2,
            text: String(i + 2) + ' month(s) back'
        };
    });

    async created() {
        await this.prepStatusCountPeriods();
    }

    async prepStatusCountPeriods() {
        loadingState.loadingIncrement('sparklineChart');
        const startDate = addMonths(new Date(), -1 * this.monthsBack).substr(0, 10);
        const endDate = (new Date()).toISOString().substr(0, 10);
        await statsState.initStatusCountPeriods({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3'
        });

        this.updateChart();
        loadingState.loadingDecrement('sparklineChart');
    }

    async updateStatusCountPeriods() {
        loadingState.loadingIncrement('sparklineChart');
        const startDate = formatDateForApi(addMonths(new Date(), -1 * this.monthsBack));
        const endDate = formatDateForApi(new Date());
        await statsState.retrieveStatusCountPeriods({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3'
        });
        this.updateChart();
        loadingState.loadingDecrement('sparklineChart');
    }

    public createSparklineChartForStatusPeriodStats(periodCounts: CountByPeriods): Highcharts.Options {
        // Get the counts.
        const counts = countPeriodsToAreaGraphSeries([periodCounts], '#8ECCFF', true);
        const periodsCategories = countPeriodsToXAxis([periodCounts], 3);

        return {
            chart: {
                borderWidth: 0,
                type: 'area',
                margin: [0, 0, 0, 0],
                width: 240, /* Yes, a width is needed or things don't align. */
                height: 30,
                style: {
                    overflow: 'visible'
                },
                spacing: [0, 0, 0, 0]
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                startOnTick: false,
                endOnTick: false,
                tickPositions: [],
                categories: periodsCategories
            },
            yAxis: {
                endOnTick: false,
                startOnTick: false,
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                },
                tickPositions: [0]
            },
            legend: {
                enabled: false
            },
            tooltip: {
                hideDelay: 0,
                outside: true,
                shared: false,
                headerFormat: '{point.x}: {point.y}',
                pointFormat: ''
            },
            plotOptions: {
                series: {
                    animation: false,
                    lineWidth: 1,
                    shadow: false,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    marker: {
                        radius: 1,
                        states: {
                            hover: {
                                radius: 2
                            }
                        }
                    }
                }
            },
            series: counts
        };
    }

    private updateChart() {
        this.statusData = statsState.stateStatusCountPeriods;
        this.statusData.forEach((item) => {
            this.createSparklineChartForStatusPeriodStats(item);
        });
    }
}

