import { Action, Mutation, VuexModule } from 'vuex-module-decorators';
import type { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import type { CrmApiElement, CrmEntity } from '@/models/base';

interface StorePromise {
    promise: Promise<any>;
    datetime: Date;
}

export const storePromiseTtl = 60;

export interface AbstractApiState<T extends CrmApiElement> {
    entities: Array<T>;
    mappedEntities: Map<number | string, any>;
    promisesMap: Map<string, StorePromise>;
}

export type AbstractEntityState<T extends CrmEntity> = AbstractApiState<T>;

/**
 * Base class for generic retrieval and storage of a set of CRM entities.
 */
export abstract class AbstractApiStore<T extends CrmApiElement> extends VuexModule implements AbstractApiState<T> {
    protected abstract repository: AbstractRepository<T>;
    entities: Array<T> = [];
    mappedEntities: Map<number | string, any> = new Map();
    promisesMap: Map<string, StorePromise> = new Map();

    /**
     * Call this in store inits to avoid duplicated API calls. See centers-store for
     * examples
     *
     * @param { hash: string; closure: Function; rebuild?: boolean }init
     * @protected
     */
    @Action
    protected async initPromise(init: { hash: string; closure: Function; rebuild?: boolean }): Promise<any> {
        if (init.rebuild && this.promisesMap.has(init.hash)) {
            this.promisesMap.delete(init.hash);
        }

        const stored = this.promises(init.hash);
        if (stored) {
            await stored;
            return;
        }

        const promise = init.closure();
        this.context.commit('setPromise', { hash: init.hash, promise: promise });
        await promise;
    }

    /**
     * helper for initPromise
     *
     * @protected
     */
    protected get promises() {
        return (hash: string) => {
            if (this.promisesMap.has(hash)) {
                // boo, typescript can't parse this guard
                const stored = this.promisesMap.get(hash)!;
                if ((new Date()).getTime() - stored.datetime.getTime() < (storePromiseTtl * 1000)) {
                    return stored.promise;
                }
            }
            return null;
        };
    }

    /**
     * helper for initPromise
     *
     * @param update
     * @protected
     */
    @Mutation
    protected setPromise(update: { hash: string; promise: Promise<any> }) {
        this.promisesMap.set(update.hash, {
            promise: update.promise,
            datetime: new Date()
        });
    }

    @Mutation
    private storeEntities(entities: Array<T>): void {
        this.entities = entities;

        // Reset the map.
        this.mappedEntities.clear();

        entities.forEach((entity: T) => {
            if (entity.id) {
                this.mappedEntities.set(entity.id, entity);
            }
        });
    }

    /**
     * Remove an entity, and update the map.
     *
     * @param id
     */
    @Mutation
    public removeEntityWithId(id: number): void {
        this.entities = this.entities.filter((obj) => {
            return obj.id !== id;
        });
        this.mappedEntities.delete(id);
    }

    /**
     * Replace an entity, and update the map.
     *
     * @param entity
     */
    @Mutation
    public replaceEntity(entity: T): void {
        this.entities = this.entities.map(
            (obj) => {
                return obj.id === entity.id ? entity : obj;
            }
        );

        this.mappedEntities.set(entity.id as number, entity);
    }

    @Mutation
    public addOrUpdateEntity(entity: T): void {
        if (!this.mappedEntities.has(entity.id as number)) {
            this.entities.push(entity);
        } else {
            this.entities = this.entities.map((obj) => {
                return obj.id === entity.id ? entity : obj;
            });
        }
        this.mappedEntities.set(entity.id as number, entity);
    }

    // Retrieve data no matter what, i.e. if we've made a change.
    @Action({ commit: 'storeEntities' })
    public async retrieve(pagination?: ApiPagination | null, params?: ApiParameters) {
        const apiResponse = await this.repository.get(pagination, params);
        return apiResponse.entities;
    }

    // Retrieve all of the data from the API.
    @Action({ commit: 'storeEntities' })
    public async retrieveAll(params?: ApiParameters) {
        return (await this.repository.getAll(params)).entities;
    }

    // Get one from the store, by its identifier.
    @Action({ rawError: true })
    public async getById(id: number): Promise<T> {
        if (this.mappedEntities.has(id as number)) {
            return this.mappedEntities.get(id as number);
        }

        const entity = await this.repository.getOne(id as number);
        this.mappedEntities.set(id, entity);

        return entity;
    }
}
