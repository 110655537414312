import { CrmApiElement, HateoasLink, SimpleHateoasLink } from '@/models/base';

export enum ChangeType {
    GUARDIAN = 'Guardian',
    CHILD = 'Child',
    STATUS = 'Status',
    CONTACT = 'Contact',
    EVENT = 'Event',
    TASK = 'Task',
    LEAD = 'Lead',
}

export interface FieldChange {
    type: ChangeType;
    field_name: string;
    field: {
        id: number;
        values: {
            name: string;
            form_name: string;
            db_name: string;
            type: number;
        };
        links: Array<SimpleHateoasLink>;
    };
    from: string;
    to: string;
    date_time: string;
}

export interface Change {
    date_time: string;
    type: ChangeType;
    action: string;
    details: string;
    links: Array<HateoasLink>;
    field_changes: Array<FieldChange>;
    action_date_time: string;
    event_comment: string;
}

export interface Update extends CrmApiElement {
    family: HateoasLink;
    changes: Array<Change>;
}

export enum FilterType {
    GUARDIAN = 1,
    CHILD = 2,
    STATUS = 3
}

export interface ChangesHistoryTableData {
    user: string;
    date: string;
    type: string;
    field: string;
    from: string;
    to: string;
}

export interface AuditTrailTableData {
    icon: string;
    id: number;
    user: string;
    date: string;
    type: string;
    action: string;
    details: string;
    event_comment: string;
    change: Change;
}

export interface RecentlyModifiedTableData {
    guardian_name: string;
    last_modified_datetime: string;
    family_id: number;
}
