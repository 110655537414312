import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { AuthStore } from '@/store/auth-store';

@Component
export class PageTitleMixin extends Vue {
    private authState = getModule(AuthStore, store);

    private appState = getModule(AppStateStore);
    private featuresStore = getModule(FeaturesStore);

    private layoutTabsStore = getModule(LayoutTabsStore, store);

    get canAddTabs(): boolean {
        return this.featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && this.authState.isEnrollmentTeamMember;
    }

    public setPageTitle(title: string): void {
        if (!this.canAddTabs || (this.layoutTabsStore.currentTabIndex === 0)) {
            this.appState.updateCurrentPageTitle(title);
        }
    }
}
