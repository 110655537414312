import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Task, TaskCreateDto, TaskUpdateDto } from '@/tasks/models/task-models';

export class TaskMapper implements AbstractMapper<Task> {
    toCreateDto(entity: Task): TaskCreateDto {
        return {
            family: entity.family.id,
            type: entity.type.id,
            assigned_to_staff: entity.assigned_to_staff ? entity.assigned_to_staff.id : 0,
            assigned_by_user_id: entity.assigned_by_staff ? entity.assigned_by_staff.id : null,
            due_date_time: entity.due_date_time,
            description: entity.description,
            is_completed: entity.is_completed,
            completed_by_user_id: entity.completed_by_staff ? entity.completed_by_staff.id : null,
            completed_date_time: entity.completed_date_time ? entity.completed_date_time : null,
            result_type: entity.result ? entity.result.id : null,
            result_description: entity.result_description,
            is_canceled: entity.is_cancelled,
            duration: entity.duration
        };
    }

    toUpdateDto(entity: Task): TaskUpdateDto {
        const dto = this.toCreateDto(entity) as TaskUpdateDto;
        dto.id = entity.id as number;
        return dto;
    }
}
