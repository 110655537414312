import { InterfaceSetting } from '@/dashboards/models/interface-settings-models';

/***
 * scalar comparison of two arrays of values
 * @param array1
 * @param array2
 */
export function compareArrays(array1: Array<InterfaceSetting | undefined>, array2: Array<InterfaceSetting | undefined>): boolean {
    return array1.length === array2.length && array1.map(item => item?.value).every((value, index) => {
        return value === array2.map(item => item?.value)[index];
    });
}

/***
 * appends the hashtag symbol to the front of the string hex value for UI purposes
 * @param array
 */
export function appendHash(array: Array<InterfaceSetting | undefined>): Array<InterfaceSetting | undefined> {
    const hasHash = /^#/;
    for (const color of array) {
        if (color && !hasHash.test(color.value as string)) {
            color.value = `#${color.value}`;
        }
    }
    return array;
}

/***
 * removes the hashtag symbol on the front of the string hex value for DTO and validation purposes
 * @param value
 */
export function removeHash(value: any): string {
    const hasHash = /^#/;
    if (hasHash.test(value) && typeof value === 'string') {
        return value.slice(1);
    }
    return '';
}
