















































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';
import { Hierarchy } from 'flexmonster';

@Component({})
export default class AddFilterModal extends Mixins(LocaleMixin) {
    // Props
    // v-model whether we should show it.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly hierarchyValues!: Array<Hierarchy>;

    // Properties
    private loadingKey = 'addFilterModal';
    private selectedHierarchy = '';

    get items(): Array<any> {
        return this.hierarchyValues.sort((a, b) => a.caption!.localeCompare(b.caption!));
    }

    get modelValue(): boolean {
        return this.value;
    }

    // Setters
    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    // Methods
    private addFilter() {
        this.$emit(EventTypes.REPORT_FILTER_ADDED, this.selectedHierarchy);
        this.close();
    }

    private close() {
        this.modelValue = false;
    }

    mounted() {
        if (this.hierarchyValues) {
            this.selectedHierarchy = this.items[0].uniqueName;
        }
    }
}
