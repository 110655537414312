import { AbstractRepository, ApiPagination } from '@/repositories/abstract-repository';
import { InboxCount, Message, messageThreadLimit } from '@/communications/messages/models/message';
import { CrmApiResponse } from '@/models/base';
import { InboxCountParams, InboxParams } from '@/communications/messages/models/inbox-params';
import { PromiseStore } from '@/store/promise-store';
import { getModule } from 'vuex-module-decorators';

const promisesStore = getModule(PromiseStore);

export abstract class MessagesRepository<T extends Message> extends AbstractRepository<T> {

    public async bulkMarkReadByFamilies(familyIds: Array<number>, flag = true): Promise<void> {
        await this.client.put(`${this.endpoint}/${flag ? 'mark-as-read' : 'mark-as-unread'}/families`, {
            ids: familyIds
        });
    }

    public async bulkMarkRead(messageIds: Array<number>, flag = true): Promise<void> {
        await this.client.put(`${this.endpoint}/${flag ? 'mark-as-read' : 'mark-as-unread'}`, {
            ids: messageIds
        });
    }

    public async getMessagesForFamily(familyId: number, includePendingLeadMessages = true, includeDismissed = true, pagination: ApiPagination | null = null): Promise<CrmApiResponse<T>> {
        return this.get(pagination, { family_id: familyId, include_dismissed: includeDismissed, include_pending_lead_messages: includePendingLeadMessages });
    }

    public async getThreadsForFamily(familyId: number): Promise<Array<Array<T>>> {
        const threads = await this.client.get(this.endpoint, {
            params: { family_id: familyId, threaded: 'true' }
        });
        return threads.data;
    }

    public async markRead(messageId: number, flag: boolean): Promise<void> {
        await this.client.put(this.endpoint + '/incoming/' + messageId, {
            is_read: flag
        });
    }

    public async toggleArchived(messageIds: Array<number>, flag: boolean): Promise<void> {
        await this.client.put(this.endpoint + '/' + (flag ? 'archive' : 'unarchive'), {
            ids: messageIds
        });
    }

    public async archiveByFamilies(familyIds: Array<number>): Promise<void> {
        await this.client.put(this.endpoint + '/archive/families', {
            ids: familyIds
        });
    }

    public async getInboxCount(params: InboxCountParams, force = true): Promise<number> {
        const response = await promisesStore.initPromise({
            hash: this.endpoint + '/inbox-count' + '-' + JSON.stringify(params),
            closure: async () => {
                return this.client.get<InboxCount>(this.endpoint + '/inbox-count', { params: params });
            },
            force: force
        });

        return response.data.count;
    }

    public async getInboxThreadsForOrg(orgId: number, archive: boolean, pagination: ApiPagination, force = true, isEnrollmentTeam = false, filterUserId = 0): Promise<{ threads: Array<Array<T>>; count: number }> {
        const params: InboxParams = {
            org_id: orgId,
            only_enrollment_team: isEnrollmentTeam,
            filter_user_id: filterUserId
        };
        return this.getInboxByParams(params, archive, pagination, force);
    }

    /**
     * Search for communications by a given search string
     *
     * @param searchString
     * @param isArchive
     * @param isEnrollmentTeam
     * @param orgId
     * @param pagination
     */
    public async search(searchString: string, isArchive: boolean, isEnrollmentTeam: boolean, orgId?: number, pagination?: ApiPagination, filterUserId = 0): Promise<{threads: Array<Array<T>>; count: number }> {
        const params: InboxParams = {};
        if (searchString) {
            params.search = searchString;
        }
        if (isEnrollmentTeam) {
            params.only_enrollment_team = true;
        }
        if (filterUserId) {
            params.filter_user_id = filterUserId;
        }
        if (orgId) {
            params.org_id = orgId;
        }
        if (pagination) {
            params.limit = pagination.limit;
            params.offset = pagination.offset;
        }
        return this.getInboxByParams(params, isArchive, pagination);
    }

    private async getInboxByParams(params: InboxParams, isArchive: boolean, pagination?: ApiPagination, force = true): Promise<{ threads: Array<Array<T>>; count: number }> {
        params.thread_count = messageThreadLimit;
        params.threaded = true;
        params.inbox = true;

        if (isArchive) {
            params.include_dismissed = true;
            params.only_dismissed = true;
        }

        if (pagination) {
            params.limit = pagination.limit;
            params.offset = pagination.offset;
        }

        const response = await promisesStore.initPromise({
            hash: this.endpoint + '-' + JSON.stringify(params),
            closure: async () => {
                return this.client.get(this.endpoint, { params: params });
            },
            force: force
        });

        return { threads: response.data, count: Number(response.headers['x-total-count']) };
    }
}
