






























import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts from 'highcharts';
import {
    countsToGraphData,
    getSolidGaugeSeriesOptions
} from '@/charts/chart-utils';
import { addDays, formatDateForApi } from '@/date-time/date-time-utils';
import { OrgsStore } from '@/store/orgs-store';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);
const orgsState = getModule(OrgsStore);

@Component({
    components: { }
})
export default class GaugeChartExample extends Mixins(LocaleMixin) {
    private org = 1;

    private chartOptions: Highcharts.Options = {
        chart: {
            type: 'solidgauge'
        },
        title: {
            text: 'Current Enrollments'
        },
        pane: {
            center: ['50%', '85%'],
            size: '140%',
            startAngle: -90,
            endAngle: 90,
            background: [{
                backgroundColor: '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }]
        },
        tooltip: {
            enabled: true
        },
        // the value axis
        yAxis: {
            stops: [
                [0.1, '#DF5353'], // red
                [0.5, '#DDDF0D'], // yellow
                [0.9, '#55BF3B'] // green
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            min: 0,
            max: 200,
            tickAmount: 2,
            title: {
                y: -70
            },
            labels: {
                y: 16
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [
        ]
    };

    get orgs() {
        return orgsState.stored;
    }

    async created() {
        await this.prepStatusCountPeriods();
    }

    async prepStatusCountPeriods() {
        loadingState.loadingIncrement('gaugeChart');
        const startDate = formatDateForApi(addDays(new Date(), -1));
        const endDate = formatDateForApi(new Date());
        await orgsState.init();
        await statsState.initStatusCountPeriods({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3',
            // eslint-disable-next-line @typescript-eslint/camelcase
            status_ids: [6],
            // eslint-disable-next-line @typescript-eslint/camelcase
            transitions_only: 'false',
            // eslint-disable-next-line @typescript-eslint/camelcase
            org_ids: [this.org]
        });
        this.updateChart();
        loadingState.loadingDecrement('gaugeChart');
    }

    async updateStatusCounts() {
        loadingState.loadingIncrement('gaugeChart');
        const startDate = formatDateForApi(addDays(new Date(), -1));
        const endDate = formatDateForApi(new Date());
        await statsState.retrieveStatusCounts({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3',
            // eslint-disable-next-line @typescript-eslint/camelcase
            status_ids: [6],
            // eslint-disable-next-line @typescript-eslint/camelcase
            transitions_only: 'false',
            // eslint-disable-next-line @typescript-eslint/camelcase
            org_ids: [this.org]
        });
        this.updateChart();
        loadingState.loadingDecrement('gaugeChart');
    }

    private updateChart() {
        const counts = countsToGraphData(statsState.statusCounts);
        this.chartOptions.series = [getSolidGaugeSeriesOptions('Enrollments', counts[0].y as number)] as Highcharts.SeriesSolidgaugeOptions[];
    }

}
