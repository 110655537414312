




















import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { OrgsStore } from '@/store/orgs-store';
import store from '@/store';
import { SelectListOption } from '@/core/models/select-list';
import type { StaffPositionInterface } from '@/staff/staff-position-service';
import {
    CorporateStaffPositionConstants,
    LocationStaffPositionConstants,
    NoPositionConstant,
    StaffPositionService
} from '@/staff/staff-position-service';
import { EventTypes } from '@/constants/event-type-constants';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { LoadingStore } from '@/store/loading-store';
import cloneDeep from 'lodash/cloneDeep';

const centersRepo = new CentersRepository();
const orgStore = getModule(OrgsStore, store);
const staffPositionService = new StaffPositionService();
const loadingStore = getModule(LoadingStore);

@Component
export default class StaffPositionSelect extends Mixins(LocaleMixin) {
    @Prop() readonly staffPosition!: StaffPositionInterface;
    @Prop() readonly orgId!: null | number;

    private centerId: number | null = null;
    private loadingKey = 'staff-position-select';
    private needsCenter = false;
    private staffPositionModel: StaffPositionInterface | null = null;
    private previousModel: StaffPositionInterface | null = null;

    // Get the list of available centers
    get centers(): Array<SelectListOption> {
        const centers = [];
        for (const center of orgStore.centersByOrgId(this.orgId ?? this.staffPosition.user?.org_id ?? 1)) {
            centers.push({ text: center.name, value: center.id });
        }
        return centers;
    }

    // Return the available position options
    get items(): Array<string> {
        const options: Array<any> = [NoPositionConstant];
        if (this.orgId === 1 || !this.orgId) {
            options.push(Object.values(CorporateStaffPositionConstants));
        }
        options.push(Object.values(LocationStaffPositionConstants));
        return options.flat();
    }

    @Watch('centers', { deep: true, immediate: true })
    centersChanged() {
        const centerIds = this.centers.map((center) => {
            return Number(center.value);
        });
        if (this.staffPositionModel === null) {
            this.staffPositionModel = this.staffPosition;
        }
        if (this.previousModel === null) {
            this.previousModel = cloneDeep(this.staffPosition);
        }
        if (this.staffPositionModel && this.staffPositionModel.center && !centerIds.includes(this.staffPositionModel.center.id)) {
            this.staffPositionModel.position = NoPositionConstant;
            this.$emit(EventTypes.DELETED, this.previousModel);
        }
    }

    async mounted() {
        loadingStore.loadingIncrement(this.loadingKey);
        await orgStore.init();
        this.staffPositionModel = this.staffPosition;
        this.previousModel = cloneDeep(this.staffPosition);
        if (!this.staffPositionModel.position) {
            this.staffPositionModel.position = NoPositionConstant;
        }
        if (this.staffPositionModel.center) {
            // ensure the center selected is displayed
            this.centerId = this.staffPositionModel.center.id;
            this.needsCenter = true;
        }
        loadingStore.loadingDecrement(this.loadingKey);
    }

    /**
     * So many warnings...
     */
    private async positionChanged() {
        if (!this.staffPositionModel ||
            !this.staffPositionModel.position
        ) {
            return;
        }

        let name;
        if (!this.staffPositionModel.user) {
            name = 'this new user';
        } else {
            name = `${this.staffPositionModel.user.first_name} ${this.staffPositionModel.user.last_name}`;
        }

        if (this.staffPositionModel.position === NoPositionConstant && this.previousModel && this.previousModel.position) {
            this.needsCenter = false;
            const confirm = await this.$swal({
                icon: 'warning',
                text: `Warning: this template variable tag is now vacant and can cause templates to have missing information, for example in signature blocks. To correct this, assign someone else to the variable tag ${name} is leaving.`,
                confirmButtonText: 'Continue',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (!confirm.isConfirmed) {
                // Set things back how they were
                // this.centerId = this.previousModel?.center?.id ?? null;
                this.staffPositionModel = this.previousModel;
                if (this.staffPositionModel && this.staffPositionModel.position) {
                    this.needsCenter = staffPositionService.needsCenter(this.staffPositionModel.position);
                }
                return;
            }
            this.$emit(EventTypes.DELETED, this.previousModel);
            return;
        }
        loadingStore.loadingIncrement(this.loadingKey);

        if (this.centerId) {
            this.staffPositionModel.center = await centersRepo.getOne(this.centerId);
        }

        this.needsCenter = staffPositionService.needsCenter(this.staffPositionModel.position);

        if (this.needsCenter && !this.staffPositionModel.center) {
            if (this.staffPositionModel.user?.center_id) {
                // Preselect the center if only 1 is possible
                this.centerId = this.staffPositionModel.user.center_id;
                this.staffPositionModel.center = await centersRepo.getOne(this.centerId);
            }

            if (!this.staffPositionModel.center) {
                // Prevent emitting a position that lacks necessary data
                loadingStore.loadingDecrement(this.loadingKey);
                return;
            }
        }

        // Leave this part of code in comments, I think we can reuse it in the future
        /*
        if (this.needsCenter && this.staffPositionModel!.center !== this.previousModel!.center && this.previousModel!.center) {
            const confirm = await this.$swal({
                icon: 'warning',
                text: `Warning ${name}'s information will now be used for template variables at multiple locations.  To change this, assign someone else to the position ${name} is leaving.`,
                confirmButtonText: 'Continue',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (!confirm.isConfirmed) {
                // Set centerId back to what it was
                this.centerId = this.previousModel?.center?.id ?? null;
                loadingStore.loadingDecrement(this.loadingKey);
                return;
            }
        } */

        const currentStaff = await staffPositionService.getStaffInCurrentPosition(this.staffPositionModel);
        if (currentStaff) {
            const confirm = await this.$swal({
                icon: 'warning',
                text: `Warning: ${currentStaff.values.first_name} ${currentStaff.values.last_name} was previously assigned to this template merge variable but now ${name} is assigned to this template merge variable. To change it back, open the View/Edit User pane for ${currentStaff.values.first_name} ${currentStaff.values.last_name} and reassign them to this position and center under “Use this person in email and text templates`,
                confirmButtonText: 'Continue',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (!confirm.isConfirmed) {
                // Reset values and cancel
                if (this.previousModel) {
                    this.staffPositionModel = this.previousModel;
                    if (!this.staffPositionModel.position) {
                        this.staffPositionModel.position = NoPositionConstant;
                    }
                    this.centerId = this.staffPositionModel?.center?.id ?? null;
                    this.needsCenter = staffPositionService.needsCenter(this.staffPositionModel.position);
                } else {
                    this.staffPositionModel.position = NoPositionConstant;
                    this.centerId = null;
                    this.needsCenter = false;
                }
                loadingStore.loadingDecrement(this.loadingKey);
                return;
            }
        }
        this.$emit(EventTypes.INPUT, this.staffPositionModel);
        this.$emit(EventTypes.DELETED, this.previousModel);
        loadingStore.loadingDecrement(this.loadingKey);
    }
}
