

















import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CrmTypeOption } from '@/crm-types/models/crm-type';

@Component
export default class FilterMultiSelect extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: Array<number>;
    @Prop() readonly items!: Array<CrmTypeOption>;
    @Prop() readonly label!: string;
    @Prop({ default: 'value' }) readonly text!: string;
    @Prop({ default: false }) readonly notMultiple!: boolean;

    get itemsArray(): Array<number> {
        return this.value;
    }

    set itemsArray(newValue: Array<number>) {
        this.$emit('input', newValue);
    }
}
