






































































/**
 * This component handles modifying values for a custom field.
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    CustomFieldValueCreateDto,
    CustomFieldCreateDto,
    CustomFieldUpdateDto,
    CustomFieldsTypes, CustomFieldValueUpdateDto, CustomFieldValueCreate
} from '@/crm-types/custom-fields/custom-fields-types';
import { SortableEvent } from 'sortablejs';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import CustomFieldValuesMapper from '@/crm-types/custom-fields/custom-field-values-mapper';
import { CustomFieldSettingsStore } from '@/crm-types/custom-fields/stores/custom-field-settings-store';
import { CustomFieldValuesRepository } from '@/crm-types/custom-fields/repositories/custom-field-values-repository';

const customValuesRepository = new CustomFieldValuesRepository();
const customValueMapper = new CustomFieldValuesMapper();
const customFieldsStore = getModule(CustomFieldSettingsStore);
const loadingState = getModule(LoadingStore);

@Component
export default class CustomFieldValueSettings extends Mixins(BasicValidationMixin, LocaleMixin) {
    @Prop({ required: true }) field!: CustomFieldUpdateDto | CustomFieldCreateDto;

    private loadingKey = 'custom-fields';
    private values: Array<CustomFieldValueCreateDto | CustomFieldValueUpdateDto> = [];
    private headers = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: 'Select List Options (required)',
            value: 'value',
            align: 'center'
        },
        {
            text: 'Default',
            value: 'is_default',
            align: 'center'
        },
        {
            text: 'Delete',
            value: 'delete',
            align: 'center'
        }
    ];

    // Note: the "default" radio uses object references to figure out which one is chosen
    private defaultValue: CustomFieldValueCreateDto | CustomFieldValueUpdateDto | null = null;

    private textOption = CustomFieldsTypes.TEXT;

    async mounted() {
        await this.getFieldValues();
    }

    @Watch('defaultValue')
    setDefault() {
        if (!this.defaultValue) {
            return;
        }
        // This set the default option *and* unsets any other that might be the current default
        for (const value of this.values) {
            value.is_default = value === this.defaultValue;
        }
    }

    @Watch('field')
    async getFieldValues() {
        this.values = [];
        if (!this.field) {
            return;
        }

        // If this field has values, load them now.
        if (this.field.id && this.field.type !== CustomFieldsTypes.TEXT) {
            loadingState.loadingIncrement(this.loadingKey);
            const values = await customValuesRepository.getValuesForField(this.field.id);
            for (const value of values) {
                const valueDto = customValueMapper.toUpdateDto(value);
                this.values.push(valueDto);
                if (valueDto.is_default) {
                    this.defaultValue = valueDto;
                }
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
        if (!this.values.length && this.field.type !== CustomFieldsTypes.TEXT) {
            this.addValue();
        }
    }

    @Watch('values', { deep: true })
    updateValues() {
        for (const value of this.values) {
            customFieldsStore.updateValue({ field: this.field, value: value });
        }
    }

    addValue() {
        const value = new CustomFieldValueCreate();
        value.order = this.values.length;
        this.values.push(value);
        customFieldsStore.addValue({ field: this.field, value: value });
    }

    clearDefault() {
        for (const value of this.values) {
            value.is_default = false;
            this.defaultValue = null;
        }
    }

    valuesSorted(sortEvent: SortableEvent) {
        customFieldsStore.reorderValue({ field: this.field, sortEvent: sortEvent, list: this.values });
    }

    removeValue(value: CustomFieldValueCreateDto | CustomFieldValueUpdateDto) {
        customFieldsStore.deleteValue({ field: this.field, value: value });
        this.values.splice(this.values.indexOf(value), 1);
    }
}
