import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';
import { StaffHateoas } from '@/organizations/locations/models/center';

export interface MarketingCampaign extends CrmEntity {
    code: string;
    cost: number;
    created_by_user: StaffHateoas | null;
    description: string;
    end_datetime: string | null;
    is_active: boolean;
    name: string;
    organization: HateoasLink | null;
    results_end_datetime: string | null;
    start_datetime: string | null;
    type: HateoasLink | null;
    utm_campaign: string | null;
}

export interface MarketingCampaignDto extends AbstractCreateDto, AbstractUpdateDto {
    code: string;
    cost: number | null;
    description: string;
    end_datetime: string | null;
    name: string;
    organization: number | null;
    results_end_datetime: string | null;
    start_datetime: string | null;
    type: number | null;
    utm_campaign: string | null;
}

/**
 * Translation object marketing campaign properties to a display friendly name
 */
export const MarketingCampaignTranslationObject = {
    code: 'Code',
    description: 'Description',
    endDatetime: 'End Date',
    name: 'Name',
    organization: 'Apply To',
    resultsEndDatetime: 'Results End Date',
    startDatetime: 'Start Date',
    type: 'Campaign Type',
    utmCampaign: 'UTM Campaign'
};

export interface ApplyMarketingCampaignDto {
    family_ids?: Array<number>;
    excluded_family_ids_from_group: Array<number>;
    group_id?: number;
    org_id?: number;
}
