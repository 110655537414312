



















import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import tinymce, { Editor as TinyEditor, RawEditorSettings } from 'tinymce';
import TinyEditorComponent from '@tinymce/tinymce-vue';
import TinymceUtils from '@/utils/tinymce-utils';

// Theme
import 'tinymce/icons/default';
import 'tinymce/themes/silver/theme';

// Plugins
import 'tinymce/plugins/paste';

@Component({
    components: {
        tinyEditorComponent: TinyEditorComponent
    }
})
export default class TextContentEditor extends Mixins(LocaleMixin) {
    @PropSync('messageContent', { type: String }) messageContentSynced: string | undefined;
    @Prop({ default: true }) isOpen!: boolean;

    private maxLength = 1200;
    private availLength = 1200;
    private availCountClass = 'avail_count_good'

    @Watch('messageContentSynced', { immediate: true })
    private getAvailLength() {
        this.availLength = TinymceUtils.calcAvailLength(tinymce.activeEditor, this.maxLength, true);
    }

    // You can try to break out the init if you want, but JS make me cry sometimes.
    // 1. this.* is actually a "one-way" binding on build and with deep anonymous functions,
    //    this is not this.
    private tinyInit: RawEditorSettings = {
        menubar: false,
        plugins: ['paste', 'emoticons'],
        paste_as_text: true,
        toolbar: 'undo redo emoticons',
        statusbar: false,
        forced_root_block: false,
        entity_encoding: 'raw',
        browser_spellcheck: true,
        contextmenu: false,
        setup: (editor: TinyEditor) => {
            editor.on('paste', (e) => {
                if (e.clipboardData) {
                    const data = e.clipboardData.getData('Text');
                    const availLength = TinymceUtils.calcAvailLength(editor, this.maxLength, true);

                    if (data.length > availLength) {
                        e.preventDefault();
                        e.stopPropagation();
                        alert('Paste Content Exceeds Max Character Limit.');
                        return false;
                    }
                }

                return true;
            });

            editor.on('keyDown', (e) => {
                const avail = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (avail < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });

            editor.on('keyUp', (e) => {
                const avail = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (avail < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });

            editor.on('change', (e) => {
                const availLength = TinymceUtils.calcAvailLength(editor, this.maxLength, true);
                if (availLength < 1) {
                    if (e.code !== 'Delete' && e.code !== 'Backspace') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            });
        }
    };
}
