import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    BlockedPhoneNumber,
    BlockedPhoneNumberDto
} from '@/communications/blocked-phone-numbers/models/blocked-phone-number-models';

export class BlockedPhoneNumbersRepository extends AbstractRepository<BlockedPhoneNumber> {
    protected endpoint = 'communications/blocked-numbers';

    public async getBlockedPhoneNumbers(): Promise<Array<BlockedPhoneNumber>> {
        const response = await this.client.get<Array<BlockedPhoneNumber>>(this.endpoint);
        return response.data;
    }

    // This will reject any other pending leads that have the same number as well
    public async blockPhoneNumber(payload: BlockedPhoneNumberDto): Promise<BlockedPhoneNumber> {
        const response = await this.client.post<BlockedPhoneNumber>(this.endpoint, payload);
        return response.data;
    }

    public async unblockPhoneNumber(number: string): Promise<void> {
        await this.client.delete(this.endpoint, { params: { number: number } });
    }
}
