






import { Component, Mixins } from 'vue-property-decorator';
import CorporateStaffDashboard from '@/dashboards/views/CorporateStaffDashboard.vue';
import CorporateStaffDashboardMP from '@/dashboards/views/CorporateStaffDashboardMP.vue';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { AppStateStore } from '@/store/app-state-store';
import { NavigationGuardNext, Route } from 'vue-router';
import DashboardCheckMixin from '@/dashboards/components/custom/DashboardCheckMixin.vue';
import { insightDashboardType } from '@/dashboards/models/custom-dashboards';

const featureStore = getModule(FeaturesStore);
const appState = getModule(AppStateStore);
@Component({
    components: {
        CorporateStaffDashboard,
        CorporateStaffDashboardMP
    }
})
export default class CorpDashboardWrapper extends Mixins(DashboardCheckMixin) {
    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        appState.setIsDashboard(false);
        next();
    }

    private isLoaded = false;
    dashboardType = insightDashboardType;

    private get isLineLeaderEnroll(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    async created() {
        appState.setIsDashboard(true);
        await featureStore.init();
        this.isLoaded = true;
    }
}
