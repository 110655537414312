































































































import { ActionItemUtils } from '@/dashboards/action-item-utils';
import AddFamily from '@/families/components/AddFamily.vue';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import ManageTasks from '@/tasks/components/ManageTasks.vue';
import PendingLeads from '@/components/tasks/PendingLeads.vue';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { getTodayEnd } from '@/date-time/date-time-utils';
import { AppStateStore } from '@/store/app-state-store';
import type { Org } from '@/models/organization/org';
import MassCancelPastDueTasksModal from '@/tasks/components/MassCancelPastDueTasksModal.vue';
import { ManageTaskTableData } from '@/tasks/models/task-models';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import { EventTypes } from '@/constants/event-type-constants';

const actionItemUtils = new ActionItemUtils();
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const tasksRepo = new TasksRepository();
const appState = getModule(AppStateStore);
const pendingRepo = new PendingLeadsRepository();

@Component({
    components: { AddFamily, AddTaskModal, PendingLeads, ManageTasks, MassCancelPastDueTasksModal }
})
export default class DashboardTasks extends Mixins(LocaleMixin) {
    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;
    private blockNumberEvent = EventTypes.PHONE_NUMBER_BLOCKED;
    private centerId = 0;
    private currentTab = 0;
    private familyAcceptedEvent = EventTypes.FAMILY_ACCEPTED;
    private familyRejectedEvent = EventTypes.FAMILY_REJECTED;
    /**
     * The isMineOnly prop to apply to the bulk actions modal.
     * If the user doesn't have permission to edit other's tasks, only show their own.
     */
    private isMineOnlyBulk = false;
    private pendingLeadsCount: '...' | number = '...';
    private showAddFamily = false;
    private showAddTask = false;
    private tasksCount: '...' | number = '...';
    private key = 0;
    private showMassCancelButton = false;
    private showMassCancelModal = false;
    private pastDueTasks: Array<ManageTaskTableData> | null = null;
    private totalCount: '...' | number = '...';

    created() {
        this.centerId = appState.storedCurrentCenter ? appState.storedCurrentCenter.id : 0;
    }

    async mounted() {
        const responses = await Promise.all([
            this.updateCounts(),
            actionItemUtils.getBulkTasksMineOnly(false)
        ]);
        this.isMineOnlyBulk = responses[1];
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    @Watch('org')
    private async updateOrg(org: Org) {
        if (org && org.id > 0 && !this.isEnrollmentTeamMode) {
            await this.updateCounts();
        }
    }

    @Watch('pastDueTasks', { immediate: true })
    private setShowMassCancelButton(tasks: Array<ManageTaskTableData> | null) {
        this.showMassCancelButton = (tasks && tasks.length > 0) as boolean;
    }

    @Watch('key')
    updateDashboard() {
        this.updateCounts();
    }

    async updateCounts() {
        loadingState.loadingIncrement('pendingCount');
        loadingState.loadingIncrement('tasksCount');
        if ((this.org && this.org.id > 0) || this.isEnrollmentTeamMode) {
            if (this.isEnrollmentTeamMode) {
                const pendingResults = await pendingRepo.get({ limit: 1, offset: 0 }, { enrollment_team: true });
                this.pendingLeadsCount = pendingResults.count;
            } else {
                const pendingResults = await pendingRepo.get({ limit: 1, offset: 0 }, { org_id: this.org!.id });
                this.pendingLeadsCount = pendingResults.count;
            }
            if (authState.userInfoObject) {
                const todayEnd = this.isEnrollmentTeamMode ? null : getTodayEnd(authState.userInfoObject.timezone);
                const taskResults = await tasksRepo.getNonTours(
                    todayEnd,
                    { limit: 1, offset: 0 },
                    { assigned_to: !this.isEnrollmentTeamMode ? authState.userInfoObject.id : undefined, org_id: this.isEnrollmentTeamMode ? undefined : '' + this.org?.id, only_enrollment_team: this.isEnrollmentTeamMode },
                    null,
                    true
                );
                this.tasksCount = taskResults.count;
            }
            this.$emit('pendingCount', this.pendingLeadsCount);
            this.$emit('tasksCount', this.tasksCount);
        } else {
            this.$emit('pendingCount', 0);
            this.$emit('tasksCount', 0);
        }
        if (typeof this.tasksCount === 'number' && typeof this.pendingLeadsCount === 'number') {
            this.totalCount = this.tasksCount as number + this.pendingLeadsCount as number;
        }
        this.$emit('totalCount', this.totalCount);
        loadingState.loadingDecrement('pendingCount');
        loadingState.loadingDecrement('tasksCount');
    }

    private get addNewType(): string {
        if (this.currentTab === 0) {
            return 'Family';
        }
        return 'Task';
    }

    private handleAddClick(): void {
        if (this.currentTab === 1) {
            this.showAddTask = true;
            return;
        }
        this.showAddFamily = true;
    }

    private reloadTab(): void {
        ++this.key;
    }

    private setPastDueTasks(pastDueTasks: Array<ManageTaskTableData>) {
        if (this.isMineOnlyBulk) {
            this.pastDueTasks = pastDueTasks.filter(pastDueTask => {
                return pastDueTask.assigneeDisplay?.id === (authState.userInfoObject?.id ?? 0);
            });
        } else {
            this.pastDueTasks = pastDueTasks;
        }
    }
}
