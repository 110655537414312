import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity } from '@/models/base';
import isEqual from 'lodash/isEqual';

export class FormUtils {
    /**
     * Whether a form for an entity can be saved.
     * Requires the entity to be changed (on update) before allowing save.
     *
     * @param entity
     * @param dto
     * @param mapper
     * @param isFormValid
     */
    public canSaveEntityForm(
        entity: CrmEntity | null,
        dto: AbstractCreateDto | AbstractUpdateDto | null,
        mapper: AbstractMapper<CrmEntity>,
        isFormValid: boolean
    ) {
        if (entity && dto) {
            return isFormValid && !isEqual(dto, mapper.toUpdateDto(entity));
        }
        return isFormValid;
    }
}
