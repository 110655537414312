





















































































































/**
 * This component handles custom fields and values.
 * There are some tricky things in the template. The click stops and keyup stops
 * prevent the expansion panel from expanding when you're typing in the text area
 * or simple clicking somewhere other than the expand/collapse icon.
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    CustomFieldUpdateDto,
    CustomFieldCreateDto, CustomFieldDto, CustomFieldsTypes, CustomFieldEntityTypes
} from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsRepository } from '@/crm-types/custom-fields/repositories/custom-fields-repository';
import { CustomFieldsMapper } from '@/crm-types/custom-fields/custom-fields-mapper';
import Draggable from 'vuedraggable';
import CustomFieldValueSettings from '@/families/components/CustomFieldValueSettings.vue';
import { SweetAlertResult } from 'sweetalert2';
import { getModule } from 'vuex-module-decorators';
import { CustomFieldSettingsStore } from '@/crm-types/custom-fields/stores/custom-field-settings-store';

const customFieldsRepository = new CustomFieldsRepository();
const customFieldMapper = new CustomFieldsMapper();
const customFieldsSettingsStore = getModule(CustomFieldSettingsStore);

@Component({
    components: { CustomFieldValueSettings, Draggable }
})
export default class CustomFieldSettings extends Mixins(BasicValidationMixin, LocaleMixin) {
    @Prop({ required: true }) readonly entityType!: CustomFieldEntityTypes;
    private isOpen: number | undefined = -1;
    private customFields: Array<CustomFieldCreateDto | CustomFieldUpdateDto> = [];
    private expandedItems: Array<number> = [];
    private mappedTypes: Map<number, number> = new Map();

    private radioOptions = [
        {
            text: 'Text',
            value: CustomFieldsTypes.TEXT
        },
        {
            text: 'Select List - Single Choice',
            value: CustomFieldsTypes.SINGLE_SELECT
        },
        {
            text: 'Select List - Multiple Choice',
            value: CustomFieldsTypes.MULTI_SELECT
        }
    ];

    private get isForChild(): boolean {
        return this.entityType === CustomFieldEntityTypes.CHILD;
    }

    private get isForGuardian(): boolean {
        return this.entityType === CustomFieldEntityTypes.GUARDIAN;
    }

    private get isForStaff(): boolean {
        return this.entityType === CustomFieldEntityTypes.STAFF;
    }

    /**
     * Get the label for the entity for these custom fields.
     */
    private get entityLabel(): string {
        switch (this.entityType) {
            case CustomFieldEntityTypes.CHILD:
                return 'Child';
            case CustomFieldEntityTypes.GUARDIAN:
                return 'Guardian';
            case CustomFieldEntityTypes.STAFF:
            default:
                return 'Staff';
        }
    }

    get textLabel(): string {
        return `* Custom ${this.entityLabel} Field Name`;
    }

    async mounted() {
        const customGroups = await customFieldsRepository.getFields();
        for (const field of customGroups) {
            if (this.isForGuardian && field.is_for_lead) {
                this.customFields.push(customFieldMapper.toUpdateDto(field));
            } else if (this.isForChild && field.is_for_child) {
                this.customFields.push(customFieldMapper.toUpdateDto(field));
            } else if (this.isForStaff && field.is_for_staff) {
                this.customFields.push(customFieldMapper.toUpdateDto(field));
            }
        }
        for (const item of this.customFields) {
            if (item.id) {
                this.mappedTypes.set(item.id, item.type);
            }
        }
    }

    @Watch('customFields', { deep: true })
    async updateValues() {
        for (const [index, field] of this.customFields.entries()) {
            // Handle re-ordered fields
            field.order = index;
            if (field.id && field.type !== this.mappedTypes.get(field.id)) {
                const result = await this.$swal({
                    icon: 'warning',
                    text: 'Changing data type will clear data from this field for every family that has data in this field.',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Proceed',
                    customClass: {
                        cancelButton: 'swal2-primary-button-styling',
                        confirmButton: 'swal2-secondary-button-styling'
                    }
                });
                if (result.isConfirmed) {
                    this.mappedTypes.set(field.id, field.type);
                } else {
                    field.type = this.mappedTypes.has(field.id) ? this.mappedTypes.get(field.id)! : field.type;
                }
            }
            customFieldsSettingsStore.updateField(field);
        }
    }

    addField() {
        const newField = new CustomFieldDto();
        newField.is_for_child = this.isForChild;
        newField.is_for_lead = this.isForGuardian;
        newField.is_for_staff = this.isForStaff;
        newField.order = this.customFields.length;
        this.customFields.push(newField);
        customFieldsSettingsStore.addField(newField);
        this.expandedItems.push(this.customFields.indexOf(newField));
    }

    async deleteField(field: CustomFieldUpdateDto | CustomFieldCreateDto) {
        let confirm: SweetAlertResult<unknown> | undefined;
        if (field.id) {
            // I don't feel like we need to warn about deleting a field that hasn't been saved yet
            confirm = await this.$swal({
                icon: 'warning',
                text: 'Are you sure? Any data that was already entered in this field will no longer be accessible.',
                confirmButtonText: 'Proceed',
                showConfirmButton: true,
                showCancelButton: true
            });
        }

        if (!field.id || (confirm !== undefined && confirm.isConfirmed)) {
            customFieldsSettingsStore.deleteField(field);
            this.customFields.splice(this.customFields.indexOf(field), 1);
            // If it was expanded, remove from list of expanded panels
            this.expandedItems = this.expandedItems.filter(index => index !== field.order);
        }
    }

    isExpanded(index: number): boolean {
        return this.expandedItems.includes(index);
    }
}
