import {
    customerLandingPage,
    LandingPage,
    LandingPageType
} from '@/automation/landing-pages/models/landing-pages-models';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { getModule } from 'vuex-module-decorators';

/**
 * Handles operations involved with landing pages.
 */
export class LandingPageUtils {
    private featuresStore = getModule(FeaturesStore);
    private repositoryFactoryStore: RepositoryFactoryStore = getModule(RepositoryFactoryStore);

    /**
     * Get the landing pages that can have notifications sent from them based on an org id.
     * This is used for setting restrictions for a user's notifications.
     *
     * @param orgId
     */
    public async getLandingPagesForNotifications(orgId: number): Promise<Array<LandingPage>> {
        if (!orgId) {
            orgId = 1;
        }
        const responses = await Promise.all([
            this.repositoryFactoryStore.landingPagesRepository.getAll({ org_id: orgId }),
            this.featuresStore.init()
        ]);
        const landingPages = responses[0].entities;

        if (this.featuresStore.isFeatureEnabled(FeatureConstants.CLIENT_FORMS) && this.featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE)) {
            landingPages.unshift(customerLandingPage);
        }

        const allowedTypes = [
            LandingPageType.CUSTOMER,
            LandingPageType.WEB,
            LandingPageType.SINGLE_WEB
        ];
        return landingPages.filter(landingPage => allowedTypes.includes(landingPage.type.id));
    }
}
