

































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { getModule } from 'vuex-module-decorators';
import { VariableTagsStore } from '@/communications/templates/store/variable-tags-store';
import { TemplateVariablesRepository } from '@/communications/templates/repositories/template-variables-repository';
import {
    ccfLinkType,
    CcfInterviewMapping,
    TemplateVariableUpdateDto,
    CcfVariableLinkObject
} from '@/communications/templates/models/template-variable';
import { LoadingStore } from '@/store/loading-store';
import { getLinkObjects } from '@/communications/templates/template-variables-util';
import BaseClose from '@/components/base/BaseClose.vue';

const integrationsState = getModule(IntegrationStore);
const variablesState = getModule(VariableTagsStore);
const variablesRepo = new TemplateVariablesRepository();
const loadingState = getModule(LoadingStore);
@Component({
    components: { BaseClose }
})
export default class MapCcfTemplateVariables extends Mixins(LocaleMixin, BasicValidationMixin, VmodelShowMixin) {
    private loadingKey = MapCcfTemplateVariables.name;
    private linkObjects: Array<CcfVariableLinkObject> = [];

    get ccfIntegrations() {
        return integrationsState.ccfIntegrations;
    }

    get ccfInterviews() {
        return integrationsState.ccfInterviewsByDomain;
    }

    get ccfVars() {
        return variablesState.stored.filter((templateVariable) => {
            return templateVariable.type === ccfLinkType;
        });
    }

    @Watch('modelValue')
    async shown() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            await Promise.all([variablesState.init(), integrationsState.initCcf()]);
            for (const ccfIntegration of this.ccfIntegrations) {
                await integrationsState.initCcfInterviews(ccfIntegration.dns_name);
            }
            this.linkObjects.splice(0);
            this.linkObjects = getLinkObjects(this.ccfVars, this.ccfIntegrations, this.ccfInterviews);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    close() {
        this.modelValue = false;
    }

    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const savePromises = [];
        for (const linkObject of this.linkObjects) {
            const ccfInterviewMappings: Array<CcfInterviewMapping> = linkObject.interviewLinkObjects.filter((interviewObj) => {
                return !!interviewObj.interview_id;
            }).map((interviewObj) => {
                return {
                    interview_id: interviewObj.interview_id,
                    ccf_client_id: interviewObj.ccf_client_id
                };
            });
            const updateDto: TemplateVariableUpdateDto = {
                ccf_interview_mappings: ccfInterviewMappings
            };
            savePromises.push(variablesRepo.updateVariable(linkObject.linkId, updateDto));
        }
        await Promise.all(savePromises);
        await variablesState.retrieve();
        this.close();
        loadingState.loadingDecrement(this.loadingKey);
    }

}
