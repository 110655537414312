






















import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class TaskDescription extends Mixins(LocaleMixin) {
    @PropSync('description', { type: String }) localDescription!: string;

    // Whether autofocus on the text area.
    @Prop({
        type: Boolean,
        default: false
    }) autofocus!: boolean;

    // Whether or not this input is disabled.
    @Prop({ default: false }) isDisabled!: boolean;
    // The label for the text area.
    @Prop({
        type: String,
        default: ''
    }) label!: string;

    // The placeholder for the select list.
    @Prop({
        type: String,
        default: ''
    }) readonly placeholder!: string;

    // Whether to disallow resizing for the text area.
    @Prop({
        type: Boolean,
        default: false
    }) noResize!: boolean;

    // The number of rows to display for the text area.
    @Prop({
        type: Number,
        default: 3
    }) readonly rows!: string;

    @Prop({
        type: Boolean,
        default: false
    }) readonly outlined!: string;

    private maxLength = 600;
    private descriptionRules = [(v = '') => v.length <= this.maxLength || `Max ${this.maxLength} characters`];

    private descriptionCountDown = (v = '') => {
        return this.maxLength - v.length;
    };

}
