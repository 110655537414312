import { SortConstants } from '@/constants/sort-constants';
import { DataTableOptions } from '@/models/datatables';
import { SortParameter } from '@/models/sort-parameter';

export type SortingMap = Map<string, string>;

export function getSortingParameters(options: DataTableOptions, defaultSortKey: string, map: SortingMap | null = null): SortParameter {
    let sortKey = defaultSortKey;
    if (options.sortBy && options.sortBy.length) {
        sortKey = options.sortBy[0];
        if (map && map.has(sortKey)) {
            sortKey = map.get(sortKey)!;
        }
    }
    return {
        sort_keys: [sortKey],
        sort_dir: [
            options.sortDesc && options.sortDesc[0]
                ? SortConstants.DESCENDING
                : SortConstants.ASCENDING
        ]
    };
}

/**
 * Sort an array of objects by a given property.
 *
 * @param items
 * @param prop
 * @param direction
 */
export function sortByObjectProperty(
    items: Array<Record<string, any>>,
    prop: string,
    direction: SortConstants = SortConstants.ASC,
    toLowerCase = false
): Array<Record<string, any>> {
    items.sort((a, b) => {
        let aProp = a[prop];
        let bProp = b[prop];
        if (toLowerCase) {
            aProp = aProp.toLowerCase();
            bProp = bProp.toLowerCase();
        }
        if (aProp < bProp) {
            return [SortConstants.ASCENDING, SortConstants.ASC].includes(direction) ? -1 : 1;
        }
        if (aProp > bProp) {
            return [SortConstants.ASCENDING, SortConstants.ASC].includes(direction) ? 1 : -1;
        }
        return 0;
    });

    return items;
}
