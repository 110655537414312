






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import type { Family } from '../models/family';
import { FamilyComparison, FamilyUpdateDtoInterface, PotentialDuplicateLeadActions } from '../models/family';
import { CrmTypeList, CrmTypeOption, RejectedReasonIdentifiers } from '@/crm-types/models/crm-type';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { LoadingStore } from '@/store/loading-store';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { PhoneDto } from '@/families/models/phone';
import cloneDeep from 'lodash/cloneDeep';
import { AppStateStore } from '@/store/app-state-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { Child, ChildComparison, ChildCreateDtoInterface } from '@/families/models/child';
import { compareChildren, matchingUtility, matchPendingWithExisting } from '@/families/families-utils';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import isEqual from 'lodash/isEqual';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { ChangeStatus } from '@/families/change-status';
import { BaseStatuses } from '@/constants/status-constants';
import { StatusChangeInterface } from '../models/status';
import { EventTypes } from '@/constants/event-type-constants';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import { formatDateForApi } from '@/date-time/date-time-utils';
import { ClientInfoStore } from '@/organizations/corporate/stores/client-info-store';
import {
    disableSave,
    generateActionsForPotentialDuplicate,
    generationActionsForCurrentFamily
} from '@/families/potential-duplicate-utils';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const appState = getModule(AppStateStore);
const centerStore = getModule(CentersStore);
const familyMapper = new FamilyMapper();
const familiesRepository = new FamiliesRepository();
const loadingState = getModule(LoadingStore);
const crmTypesStore = getModule(CrmTypesStore);
const childrenRepository = new ChildrenRepository();
const statusUtils = new ChangeStatus();
const statusChangesStore = getModule(StatusChangesStore);
const clientInfoStore = getModule(ClientInfoStore);

@Component({
    components: {
        BaseClose,
        StatusChangeSelect,
        InlineEditable,
        CrmTypeSelectList
    }
})
export default class PotentialDuplicateForExistingFamily extends Mixins(LocaleMixin) {
    // Whether the dialog should be visible
    @Prop({ type: Boolean, default: false }) readonly value!: boolean;
    @Prop({ type: Object, default: null }) readonly family!: Family;
    @Prop({ type: Array, default: null }) readonly matchingFamilies!: Array<number>;
    @Prop({ type: Number, default: 6 }) readonly columnSize!: number;
    @Prop({ type: String, default: 'dialog-medium' }) readonly dialogSize!: string;

    private loadingKey = 'potentialDuplicateForExistingFamily';
    private centerNames: Record<number, string> = {};
    private currentFamily: FamilyComparison = {
        original: {} as FamilyUpdateDtoInterface,
        current: {} as FamilyUpdateDtoInterface
    }

    private familySources: Array<CrmTypeOption> = [];
    private inquiryTypes: Array<CrmTypeOption> = [];
    // Existing Families
    private existingFamilies: Array<Family> = [];

    // Existing Families Comparison
    private existingFamiliesComparison: Array<FamilyComparison> = [];

    // Children from the current family that has no match with the existing families
    private currentHasNoMatch: Array<ChildComparison> = [];

    // Children from the current family that has some matches with the existing families
    private currentMatchesExisting: Array<ChildComparison> = [];

    // Children from an existing family that has no match with neither current nor other existing family
    private existingHasNoMatch: Array<ChildComparison> = [];

    // Children from an existing family has a duplicate from another existing family, but no match with current family
    private duplicateExisting: Array<any> = [];

    // Status for current children matches some children in existing families
    private statusesForCurrentMatchesExisting: Record<number, Array<number>> = {};

    // Status for existing children matches some children in other existing families, but not in the current family
    private statusesForDuplicateExisting: Record<number, Array<number>> = {};
    private mapStatusesForChildlessFamilies = new Map<number, number>();
    private statusesForChildlessFamilies: Array<number> = [];
    private mergedIndicesCurrentHasNoMatch: Array<{ child_index: number; family_index: number; family_id: number; new_child: ChildCreateDtoInterface; clone_child: Child }> = [];
    private mergedIndicesCurrentMatchesExisting: Array<{ child_index: number; family_index: number; family_id: number; new_child: ChildCreateDtoInterface; clone_child: Child }> = [];
    private duplicateReasonId: number | null = null;
    private activeStatuses = [BaseStatuses.NEW_LEAD, BaseStatuses.RESPONSIVE, BaseStatuses.TOUR_COMPLETED, BaseStatuses.TOUR_SCHEDULED, BaseStatuses.WAIT_LIST, BaseStatuses.REGISTERED];
    private isSaveDisabled = false;

    // Keep track of changes of the action options
    private familiesActions: Array<PotentialDuplicateLeadActions | undefined> = [];
    private familyMerges: Array<number | undefined> = [];
    private inquiryTypesList = CrmTypeList.FAMILY_INQUIRY;
    private familySourcesList = CrmTypeList.FAMILY_SOURCE;

    private rejectAction = PotentialDuplicateLeadActions.REJECT;

    // Setup index for potential duplicates slider
    private startIndex = 0;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get isTransitioned() {
        return clientInfoStore.storedClientInfo?.is_transitioned;
    }

    get isMini() {
        return appState.isMini;
    }

    // Get the visible set of existing family records to display
    get displayedExistingFamiliesComparisonRecords() {
        return this.existingFamiliesComparison.slice(this.startIndex, this.startIndex + 2);
    }

    // View the next set of existing family records
    private slideRight() {
        if (this.startIndex < this.existingFamiliesComparison.length - 2) {
            this.startIndex++;
        }
    }

    // View the previous set of existing family records
    private slideLeft() {
        if (this.startIndex > 0) {
            this.startIndex--;
        }
    }

    @Watch('familiesActions')
    disableSaveButton(): void {
        this.isSaveDisabled = disableSave(this.familiesActions, this.currentFamily.current, this.existingFamiliesComparison);
    }

    async created() {
        const centersCountPromise = centerStore.initCount();
        const centersPromise = centerStore.initAccessibleCenters();
        const reasonsPromise = crmTypesStore.initList(CrmTypeList.REJECTED_REASONS);
        const clientPromise = clientInfoStore.init();

        const promises = [
            centersCountPromise,
            centersPromise,
            reasonsPromise,
            clientPromise
        ];
        await Promise.all(promises);

        const reasons = crmTypesStore.listOptions(CrmTypeList.REJECTED_REASONS);
        this.duplicateReasonId = reasons.find(reason => reason.identifier === RejectedReasonIdentifiers.DUPLICATE)?.id ?? null;
    }

    @Watch('modelValue')
    private async loadFamiliesData() {
        if (this.modelValue) {
            if (!this.family || !this.matchingFamilies || !this.matchingFamilies.length) {
                return;
            }
            loadingState.loadingIncrement(this.loadingKey);
            // Map currently current family

            const familyDto = familyMapper.toUpdateDto(this.family);
            familyDto.children = familyDto.children.filter(child => child.status !== BaseStatuses.REJECTED);
            this.currentFamily.original = familyDto;
            this.currentFamily.current = cloneDeep(this.currentFamily.original);
            this.currentFamily.action = undefined;
            this.familiesActions.push(this.currentFamily.action);
            this.familyMerges.push(this.currentFamily.mergeIntoFamilyId);

            if (this.currentFamily.current.primary_guardian.center_id && !this.centerNames[this.currentFamily.current.primary_guardian.center_id]) {
                this.centerNames[this.currentFamily.current.primary_guardian.center_id] = (await centerStore.getAccessibleCenterById(this.currentFamily.current.primary_guardian.center_id)).name;
            }

            if (!this.getExactCurrentNumberOfChildren(this.currentFamily.original)) {
                if (this.currentFamily.original.status) {
                    this.mapStatusesForChildlessFamilies.set(this.currentFamily.original.id, this.currentFamily.original.status);
                }
            }

            if (this.matchingFamilies && this.matchingFamilies.length) {
                // Map matching families to comparison interfaces in order to keep track of changes
                for (const matchingFamilyId of this.matchingFamilies) {
                    const family = await familiesRepository.getOne(matchingFamilyId);
                    this.existingFamilies.push(family);
                    // Center names for other matching families
                    if (family.primary_guardian.center_id && !this.centerNames[family.primary_guardian.center_id]) {
                        this.centerNames[family.primary_guardian.center_id] = (await centerStore.getAccessibleCenterById(family.primary_guardian.center_id)).name;
                    }

                    const original = familyMapper.toUpdateDto(family);
                    if (!original.primary_guardian.primary_phone) {
                        original.primary_guardian.primary_phone = new PhoneDto();
                    }

                    if (!this.getExactCurrentNumberOfChildren(original)) {
                        if (original.status) {
                            this.mapStatusesForChildlessFamilies.set(original.id, original.status);
                        }
                    }

                    original.children = original.children.filter(child => child.status !== BaseStatuses.REJECTED);
                    const current = cloneDeep(original);
                    this.existingFamiliesComparison.push({
                        original: original,
                        current: current,
                        action: undefined,
                        mergeIntoFamilyId: undefined
                    });
                }

                // Eliminate duplicate status value options
                const statusSet = new Set(Array.from(this.mapStatusesForChildlessFamilies.values()));
                this.statusesForChildlessFamilies = Array.from(statusSet);
            }

            this.existingFamiliesComparison.forEach(family => {
                this.familiesActions.push(family.action);
                this.familyMerges.push(family.mergeIntoFamilyId);
            });
            this.getMatchesForChildren(this.currentFamily.current, this.existingFamiliesComparison);
            this.buildStatuses();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private buildStatuses() {
        let index = 0;
        for (const currentChild of this.currentMatchesExisting) {
            const statusIds = new Set<number>();
            const curChild = currentChild.current as ChildCreateDtoInterface;
            if (curChild.status) {
                statusIds.add(curChild.status);
            }
            for (const family of this.existingFamiliesComparison) {
                const child = this.getMatchingChildForExistingFamily(currentChild, family);
                if (child && child.status) {
                    statusIds.add(child.status!);
                }
            }
            if (!this.statusesForCurrentMatchesExisting[index]) {
                this.statusesForCurrentMatchesExisting[index] = Array.from(statusIds);
            }
            index++;
        }
        index = 0;
        for (const duplicateExistingChild of this.duplicateExisting) {
            const statusIds = new Set<number>();
            for (const family of this.existingFamiliesComparison) {
                const child = this.getMatchingChildForExistingFamily(duplicateExistingChild, family);
                if (child && child.status) {
                    statusIds.add(child.status!);
                }
            }
            if (!this.statusesForDuplicateExisting[index]) {
                this.statusesForDuplicateExisting[index] = Array.from(statusIds); ;
            }
            index++;
        }

    }

    private getActionsForCurrentFamily(currentFamily: FamilyUpdateDtoInterface) {
        return generationActionsForCurrentFamily(currentFamily, this.existingFamiliesComparison);
    }

    private getActionsForPotentialDuplicates(existingFamily: FamilyUpdateDtoInterface) {
        return generateActionsForPotentialDuplicate(existingFamily, this.currentFamily.current);
    }

    private getExactCurrentNumberOfChildren(family: FamilyUpdateDtoInterface): number {
        return family.children.length +
            this.mergedIndicesCurrentHasNoMatch.filter(obj => obj.family_id === family.id).length +
            this.mergedIndicesCurrentMatchesExisting.filter(obj => obj.family_id === family.id).length;
    }

    /**
     * Get the family ids to populate the 'Copy history/docs to' select list with.
     *
     * @param index
     * @private
     */
    private getFamilyIdsForMerge(index: number): Array<number> {
        const familyIds: Array<number> = [];
        if (index !== 0) {
            familyIds.push(this.family.id);
        }
        for (let i = 0; i < this.matchingFamilies.length; i++) {
            if (index !== i + 1) {
                familyIds.push(this.matchingFamilies[i]);
            }
        }
        return familyIds;
    }

    // whether current children match existing children or not
    private getMatchesForChildren(current: Family | FamilyUpdateDtoInterface, existing: Array<FamilyComparison>): void {
        for (const currentChild of current.children) {
            const matched = matchPendingWithExisting(currentChild, existing);
            const clone = cloneDeep(currentChild);
            if (!matched.length) {
                this.currentHasNoMatch.push({
                    original: currentChild,
                    current: clone
                });
            } else {
                this.currentMatchesExisting.push({
                    original: currentChild,
                    current: clone
                });
            }
        }
        // flatten both previous arrays and use them to generate whether existing has no match
        const combinedArray = [this.currentMatchesExisting, this.currentHasNoMatch].flat();
        for (const families of existing) {
            for (const child of families.original.children) {
                if (!compareChildren(child, combinedArray)) {
                    const clone = cloneDeep(child);
                    this.existingHasNoMatch.push({
                        original: child,
                        current: clone
                    });
                }
            }
        }
        // edge case where two existing families exist and have matching children that don't match the current
        if (this.existingFamiliesComparison.length > 1 && this.existingHasNoMatch.length) {
            this.duplicateExisting = this.existingHasNoMatch.map((child1: ChildComparison, index1: number) => {
                return this.existingHasNoMatch.find((child2: ChildComparison, index2: number) => {
                    if (index1 !== index2 && child1.original.first_name === child2.original.first_name && child1.original.date_of_birth === child2.original.date_of_birth) {
                        const result = child1;
                        this.existingHasNoMatch.splice(index2, 1);
                        this.existingHasNoMatch.splice(index1, 1);
                        return result;
                    }
                    return false;
                });
            }).filter(child => child);
        }
    }

    private getNewMergedCurrentChildHasNoMatch(childIndex: number, familyIndex: number): ChildCreateDtoInterface | undefined {
        const newChild = this.mergedIndicesCurrentHasNoMatch.find(obj => obj.child_index === childIndex && obj.family_index === familyIndex);
        return newChild ? newChild.new_child : undefined;
    }

    private getNewMergedCurrentChildMatchesExisting(childIndex: number, familyIndex: number): ChildCreateDtoInterface |undefined {
        const newChild = this.mergedIndicesCurrentMatchesExisting.find(obj => obj.child_index === childIndex && obj.family_index === familyIndex);
        return newChild ? newChild.new_child : undefined;

    }

    private getNewClonedChildCurrentHasNotMatch(childIndex: number, familyIndex: number): Child | undefined {
        const newClonedChild = this.mergedIndicesCurrentHasNoMatch.find(obj => obj.child_index === childIndex && obj.family_index === familyIndex);
        return newClonedChild ? newClonedChild.clone_child : undefined;
    }

    private getNewClonedChildCurrentMatchesExisting(childIndex: number, familyIndex: number): Child | undefined {
        const newClonedChild = this.mergedIndicesCurrentMatchesExisting.find(obj => obj.child_index === childIndex && obj.family_index === familyIndex);
        return newClonedChild ? newClonedChild.clone_child : undefined;
    }

    private mergeCurrentChildHasNoMatch(childIndex: number, familyIndex: number, child: ChildComparison, family: FamilyComparison): void {
        if (!child.current.id || !family.current.id) {
             return;
        }
        const copiedChild: ChildCreateDtoInterface = cloneDeep(child.current as ChildCreateDtoInterface);

        // Negative and unique
        copiedChild.id = -1 * (family.current.id + child.current.id);

        const currentChild = this.family.children.find(ch => ch.id === child.current.id);
        if (!currentChild) {
           return;
        }

        // The idea of a clone child from the one that is being copied is to have the status change select display
        // the status details of a child type (the clone will have same status details as the original one, but take
        // the new id of the copied one, so that the status change select will store the details and  keep track of
        // the changes to that id
        const cloneChild = cloneDeep(currentChild);
        cloneChild.id = copiedChild.id;

        this.mergedIndicesCurrentHasNoMatch.push({
            child_index: childIndex,
            family_index: familyIndex,
            family_id: family.current.id,
            new_child: copiedChild,
            clone_child: cloneChild
        });

        if (this.getExactCurrentNumberOfChildren(family.current)) {
            this.mapStatusesForChildlessFamilies.delete(family.current.id);
            // Eliminate duplicate status value options
            const statusSet = new Set(Array.from(this.mapStatusesForChildlessFamilies.values()));
            this.statusesForChildlessFamilies = Array.from(statusSet);
        }
    }

    private mergeCurrentChildMatchesExisting(childIndex: number, familyIndex: number, child: ChildComparison, family: FamilyComparison): void {
        if (!child.current.id || !family.current.id) {
            return;
        }

        const copiedChild: ChildCreateDtoInterface = cloneDeep(child.current as ChildCreateDtoInterface);
        copiedChild.id = -1 * (family.current.id + child.current.id);

        const currentChild = this.family.children.find(ch => ch.id === child.current.id);
        if (!currentChild) {
            return;
        }

        const cloneChild = cloneDeep(currentChild);
        cloneChild.id = copiedChild.id;

        this.mergedIndicesCurrentMatchesExisting.push({
            child_index: childIndex,
            family_index: familyIndex,
            family_id: family.current.id,
            new_child: copiedChild,
            clone_child: cloneChild
        });

        if (this.getExactCurrentNumberOfChildren(family.current)) {
            this.mapStatusesForChildlessFamilies.delete(family.current.id);
            // Eliminate duplicate status value options
            const statusSet = new Set(Array.from(this.mapStatusesForChildlessFamilies.values()));
            this.statusesForChildlessFamilies = Array.from(statusSet);
        }
    }

    private isCurrentChildHasNoMatchMerged(childIndex: number, familyIndex: number): boolean {
        return this.mergedIndicesCurrentHasNoMatch.filter(obj => obj.family_index === familyIndex && obj.child_index === childIndex).length > 0;
    }

    private isCurrentChildMatchesExistingMerged(childIndex: number, familyIndex: number): boolean {
        return this.mergedIndicesCurrentMatchesExisting.filter(obj => obj.family_index === familyIndex && obj.child_index === childIndex).length > 0;
    }

    // finds the index of a child in an array based on the static 'original' property and returns that child's dynamic 'current'
    private getMatchingChildForExistingFamily(child: ChildComparison, family: FamilyComparison): ChildCreateDtoInterface {
        const index = family.original.children.findIndex(currentChild => matchingUtility(currentChild, child.original));
        return family.current.children[index];
    }

    private getMatchingChildForCurrentFamily(child: ChildCreateDtoInterface | Child): ChildCreateDtoInterface | undefined {
       return this.currentFamily.current.children.find(obj => obj.id === child.id);
    }

    private getMatchingChildForStatus(childComparison: ChildComparison, family: Family): Child | undefined {
        return family.children.find(child => matchingUtility(child, childComparison.original));
    }

    // Close the dialog and clear out data
    private closeDialog() {
        this.centerNames = {};
        this.existingFamiliesComparison = [];
        this.currentHasNoMatch = [];
        this.currentMatchesExisting = [];
        this.existingFamilies = [];
        this.existingHasNoMatch = [];
        this.duplicateExisting = [];
        this.mergedIndicesCurrentHasNoMatch = [];
        this.mergedIndicesCurrentMatchesExisting = [];
        this.familiesActions = [];
        this.familyMerges = [];
        this.statusesForCurrentMatchesExisting = [];
        this.statusesForDuplicateExisting = [];
        this.statusesForChildlessFamilies = [];
        this.mapStatusesForChildlessFamilies.clear();
        this.isSaveDisabled = false;
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }

    // This is to change to the correct status without breaking the forward rules, very tedious workaround, this might a bit long to save
    private async changeChildStatusManually(statusChange: StatusChangeInterface, familyId: number, childId: number) {
        if (!statusChange) {
            return;
        }
        const statusChangeStepByStep: StatusChangeInterface = {
            family_id: familyId,
            child_id: childId,
            status: -1,
            actual_start_date: null,
            date: formatDateForApi(new Date()),
            expected_start_date: null,
            reason: null,
            comments: null
        };
        switch (statusChange.status) {
            case (BaseStatuses.ENROLLED):
                statusChangeStepByStep.status = BaseStatuses.REGISTERED;
                await statusUtils.changeStatus(statusChangeStepByStep);
                break;
            case (BaseStatuses.LOST_OPP):
                statusChangeStepByStep.status = BaseStatuses.NEW_LEAD;
                await statusUtils.changeStatus(statusChangeStepByStep);
                break;
            case (BaseStatuses.REJECTED):
                statusChangeStepByStep.status = BaseStatuses.NEW_LEAD;
                await statusUtils.changeStatus(statusChangeStepByStep);
                break;
            case (BaseStatuses.TEMP_LEAVE):
                statusChangeStepByStep.status = BaseStatuses.REGISTERED;
                await statusUtils.changeStatus(statusChangeStepByStep);
                statusChangeStepByStep.status = BaseStatuses.ENROLLED;
                await statusUtils.changeStatus(statusChangeStepByStep);
                break;
            case (BaseStatuses.WITHDRAWN):
                statusChangeStepByStep.status = BaseStatuses.REGISTERED;
                await statusUtils.changeStatus(statusChangeStepByStep);
                statusChangeStepByStep.status = BaseStatuses.ENROLLED;
                await statusUtils.changeStatus(statusChangeStepByStep);
                break;
        }
    }

    private async updateChildStatusChangeStore(familyId: number, child: ChildCreateDtoInterface, createdChildId?: number) {
        if (!child.id) {
            return;
        }
        const statusChange = statusChangesStore.changesForChildId(child.id);
        if (statusChange && child.status) {
            if (!this.activeStatuses.includes(statusChange.status) && this.activeStatuses.includes(child.status)) {
                await this.changeChildStatusManually(statusChange, familyId, createdChildId ?? child.id);
            }
            statusChangesStore.updateChildId({ statusChange: statusChange, childId: createdChildId ?? child.id });
        }
    }

    private async addChildren(family: FamilyUpdateDtoInterface) {
        const newChildrenForFamily: Array<ChildCreateDtoInterface> = [];
        this.mergedIndicesCurrentMatchesExisting.forEach(obj => {
            if (obj.family_id === family.id) {
                newChildrenForFamily.push(obj.new_child);
            }
        });
        this.mergedIndicesCurrentHasNoMatch.forEach(obj => {
            if (obj.family_id === family.id) {
                newChildrenForFamily.push(obj.new_child);
            }
        });
        for (const child of newChildrenForFamily) {
            child.status = BaseStatuses.NEW_LEAD;
            const createdChild = (await childrenRepository.create(family.id, child as ChildCreateDtoInterface))[0];
            if (child) {
                await this.updateChildStatusChangeStore(family.id, child, createdChild.id);
            }
        }
        await statusUtils.writePendingChanges(family.id);
    }

    private async updateChildren(family: FamilyUpdateDtoInterface) {
        for (const child of family.children) {
            if (child.id) {
                await this.updateChildStatusChangeStore(family.id, child);
            }
        }
    }

    private async updateCurrentFamily(family: FamilyComparison) {
        if (!isEqual(family.original, family.current)) {
            delete family.current.status;
            await familiesRepository.update(family.current as FamilyUpdateDtoInterface);
        }
        await this.updateChildren(family.current);
        await statusUtils.writePendingChanges(family.current.id);
    }

    private async updateExistingFamily(family: FamilyComparison) {
        if (!this.getExactCurrentNumberOfChildren(family.current)) {
            const statusChange = statusChangesStore.changesForGuardian(family.current.id);
            if (statusChange) {
                family.current.status = BaseStatuses.NEW_LEAD;
                await familiesRepository.update(family.current as FamilyUpdateDtoInterface);
            }
        }

        if (!isEqual(family.original, family.current)) {
            delete family.current.status;
            await familiesRepository.update(family.current as FamilyUpdateDtoInterface);
        }
        await this.updateChildren(family.current);
        await this.addChildren(family.current);
    }

    private async rejectFamily(rejectedFamilyId: number, keptFamilyId?: number | null) {
        await familiesRepository.rejectFamily(rejectedFamilyId, { merge_to_family_id: keptFamilyId });
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        // Matching action
        let index = 0;
        for (const action of this.familiesActions) {
            if (index === 0) {
                this.currentFamily.action = action;
                this.currentFamily.mergeIntoFamilyId = this.familyMerges[0];
            } else {
                this.existingFamiliesComparison[index - 1].action = action;
                this.existingFamiliesComparison[index - 1].mergeIntoFamilyId = this.familyMerges[index];
            }
            index++;
        }

        // Handle linking
        const existingFamiliesCanBeLinked = this.existingFamiliesComparison.filter(
            family => family.action === PotentialDuplicateLeadActions.LINK ||
                (
                    family.action === PotentialDuplicateLeadActions.KEEP &&
                    family.current.primary_guardian.center_id !== this.currentFamily.current.primary_guardian.center_id
                ) || family.action === undefined
        );
        const nonRejectedFamilies = this.existingFamiliesComparison.filter(family => family.action !== PotentialDuplicateLeadActions.REJECT);

        // If currently family viewed option is link, but the other matching families are not rejected, they should be linked with the current family
        if (this.currentFamily.action === PotentialDuplicateLeadActions.LINK) {
            for (const family of existingFamiliesCanBeLinked) {
                await familiesRepository.linkFamily(this.currentFamily.current.id, { family_id: family.current.id });
            }
            await this.updateCurrentFamily(this.currentFamily);
        }

        const keptFamilies = [];
        const existingFamiliesCanOnlyBeLinked = existingFamiliesCanBeLinked.filter(family => family.action === PotentialDuplicateLeadActions.LINK);
        // If current family is keep, and if one the other matching families is linked, they can be linked with the current family
        if (this.currentFamily.action === PotentialDuplicateLeadActions.KEEP || this.currentFamily.action === undefined) {
            keptFamilies.push(this.currentFamily.current.id);
            for (const family of existingFamiliesCanOnlyBeLinked) {
                await familiesRepository.linkFamily(this.currentFamily.current.id, { family_id: family.current.id });
            }
            if (this.currentFamily.action === PotentialDuplicateLeadActions.KEEP) {
                await this.updateCurrentFamily(this.currentFamily);
            }
        }

        // Note all the families that are being kept
        keptFamilies.push(...nonRejectedFamilies.map(family => family.current.id));

        for (const family of existingFamiliesCanBeLinked) {
            if (family.action === PotentialDuplicateLeadActions.KEEP) {
                keptFamilies.push(family.current.id);
            }
        }

        let keptFamilyId = null;
        for (const family of this.existingFamiliesComparison) {
            if (family.action === PotentialDuplicateLeadActions.KEEP && !keptFamilyId) {
                keptFamilyId = family.current.id;
            }
            if (family.action === PotentialDuplicateLeadActions.KEEP || family.action === PotentialDuplicateLeadActions.LINK) {
                await this.updateExistingFamily(family);
            }
            if (family.action === PotentialDuplicateLeadActions.REJECT) {
                // Only merge into a family if specified and it is being kept
                keptFamilyId = (family.mergeIntoFamilyId && keptFamilies.includes(family.mergeIntoFamilyId)) ? family.mergeIntoFamilyId : null;
                await this.rejectFamily(family.current.id, keptFamilyId);
            }
        }

        // Handle Rejection
        if (this.currentFamily.action === PotentialDuplicateLeadActions.REJECT) {
            // Only merge into a family if specified and it is being kept
            keptFamilyId = (this.currentFamily.mergeIntoFamilyId && keptFamilies.includes(this.currentFamily.mergeIntoFamilyId)) ? this.currentFamily.mergeIntoFamilyId : null;
            await this.rejectFamily(this.currentFamily.current.id, keptFamilyId);
        }

        this.$emit(EventTypes.UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
        this.closeDialog();
    }

}
