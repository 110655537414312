/**
 * This file should hold styling definitions for use cases outside of stylesheets.
 */
/**
 * The base font family for charts
 */
export const FONT_FAMILY = 'Manrope, sans-serif';

/**
 * The default color for text
 */
export const BASE_COLOR = '#2B2B2B';

/**
 * The default blue
 */
export const BASE_DARK_BLUE = '#0060AF';

/**
 * The lightest blue we want to use
 */
export const LIGHTEST_BLUE = '#8BCBFF';

/**
 * The blue for Moment Path charts.
 */
export const MOMENT_PATH_BLUE = '#008FFB';

/**
 * The dark purple for Moment Path that appears when hovering over buttons.
 */
export const MOMENT_PATH_PRIMARY_DARK = '#533f58';

/**
 * The base purple for Moment Path.
 */
export const MOMENT_PATH_PURPLE = '#C199D5';

/**
 * Lightest purple for gradients
 */
export const MOMENT_PATH_GRADIENT_LIGHT = '#C45BDA';

/**
 * Darkest purple for gradients
 */
export const MOMENT_PATH_GRADIENT_DARK = '#5603E7';

/**
 * The darkest color for the donut charts.
 */
export const MOMENT_PATH_DONUT_DARK = '#8343A3';

/**
 * The lightest color for the donut charts.
 */
export const MOMENT_PATH_DONUT_LIGHT = '#FAECF3';

/**
 * Default color for HighCharts.
 */
export const LINE_LEADER_PURPLE = '#AC52DB';
