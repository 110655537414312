









































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { doLogout, legacyLoginWrapper } from '@/auth/auth';
import { baseUrl } from '@/core/base-url';
import { LoadingStore } from '@/store/loading-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center } from '@/organizations/locations/models/center';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const centerStore = getModule(CentersStore);
const featureStore = getModule(FeaturesStore);

@Component
export default class TourKiosk extends Mixins(LocaleMixin) {
    @Prop({ required: true }) centerId!: number;
    private tourKioskUrl = '';
    private loadingKey = 'tourKiosk';
    private isLoaded = false;
    private center: Center | null = null;

    get centerName() {
        return this.center ? this.center.name : '';
    }

    get userName() {
        return authState.userInfoObject ? authState.userInfoObject.first_name + ' ' + authState.userInfoObject.last_name : '';
    }

    async mounted() {
        if (authState.token) {
            loadingState.loadingIncrement(this.loadingKey);

            await legacyLoginWrapper(authState);

            this.tourKioskUrl = baseUrl + '/feature/todays-tours/' + this.centerId;
        }

        this.center = await centerStore.getAccessibleCenterById(this.centerId);
    }

    private async loaded() {
        this.isLoaded = true;
        loadingState.loadingStop(this.loadingKey);
    }

    async beforeDestroy() {
        await doLogout();
    }

    get heightAlt(): string {
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
            return '96';
        }
        return '';
    }

    get logo(): string {
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
            return '/img/logos/ll_brandmark.svg';
        }
        return '/img/logos/blocks-white.svg';
    }

    get logoAlt(): string {
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
            return 'LineLeader Enroll';
        }
        return 'ChildcareCRM';
    }

    private async goHome() {
        const response = await this.$swal({
            icon: 'warning',
            text: 'For staff use only!',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Proceed'
        });
        if (response.isConfirmed) {
            await this.$router.push({ name: 'home' });
        }
    }
};
