

























































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { EventTypes } from '@/constants/event-type-constants';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import {
    CrmTypeOption,
    CrmTypeOptionCreateDto,
    CrmTypeOptionUpdateDto,
    TemplateGroupItem
} from '@/crm-types/models/crm-type';
import CrmTypeMapper from '@/crm-types/mappers/crm-type-mapper';
import Draggable from 'vuedraggable';
import { VForm } from '@/types/types';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

const crmTypeMapper = new CrmTypeMapper();
const emailTemplatesRepository = new EmailTemplatesRepository();
const loadingState = getModule(LoadingStore);
const textTemplatesRepository = new TextTemplatesRepository();

@Component({
    components: { Draggable }
})
export default class SelectListTemplateGroups extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly fields!: Array<CrmTypeOption>;
    @Prop() readonly title!: string;
    @Prop({ default: 'Warning: Are you sure you want to delete this?' }) readonly warningText!: string;
    @PropSync('panel', { type: Number }) panelIndexSynced!: number;
    @Ref('form') readonly form!: VForm;

    // List of template groups items that are expanded in the UI
    private expanded: Array<number> = [];
    private headers: Array<DataTableHeader> = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: '',
            value: 'data-table-expand'
        },
        {
            text: 'Name',
            value: 'groupItem.value',
            align: 'center'
        },
        {
            value: 'delete',
            text: 'Delete',
            align: 'center',
            width: '0'
        }
    ];

    private items: Array<TemplateGroupItem> = [];
    private itemsRemoved: Array<number> = [];
    private loadingKey = 'selectListTemplateGroups';
    private newDividerCounter = 0;
    // Pick list items representing templates in group options
    private templateItems: Array<MessageTemplate> = [];
    private validForm = false;

    // Filter out templates that are already used, except for the
    // one used by the current template group
    get mapChoices() {
        return (listItem: TemplateGroupItem) => {
            return this.templateItems.filter((template) => {
                let used = false;
                for (const item of this.items) {
                    if (item.groupItem.id === listItem.groupItem.id) {
                        continue;
                    }
                    if (item.templatesInGroup.includes(template.id)) {
                        used = true;
                        break;
                    }
                }
                return !used;
            });
        };
    }

    @Watch('fields')
    async watchFields() {
        loadingState.loadingIncrement(this.loadingKey);
        this.newDividerCounter = 0;
        this.itemsRemoved = [];
        this.items = [];
        this.templateItems = [];
        this.expanded = [];

        if (this.title === 'Email') {
            this.templateItems = (await emailTemplatesRepository.getAll({ simple: true })).entities;
        } else {
            this.templateItems = (await textTemplatesRepository.getAll({ simple: true })).entities;
        }

        const isEmail = this.title === 'Email';
        const groupData = isEmail ? await emailTemplatesRepository.getGroups() : await textTemplatesRepository.getGroups();

        this.fields.forEach((field) => {
            this.items.push({
                groupItem: crmTypeMapper.toUpdateDto(field),
                templatesInGroup: []
            });
        });
        // For the template group items look at which templates are in each group
        for (const item of this.items) {
            item.templatesInGroup = groupData.find((groupData) => groupData.group_id === item.groupItem.id)?.template_ids ?? [];
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('items', { deep: true })
    watchChanges() {
        const dtoArray: Array<TemplateGroupItem> = [];
        let order = 0;

        for (const item of this.items) {
            const fieldDto: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto = {
                is_default: false,
                value: item.groupItem.value,
                order
            };

            if (item.groupItem.id && item.groupItem.id > 0) {
                fieldDto.id = item.groupItem.id;
            }

            dtoArray.push({
                groupItem: fieldDto,
                templatesInGroup: item.templatesInGroup
            });

            ++order;
        }

        for (const item of dtoArray) {
            if (item.groupItem.value.length === 0) {
                this.$emit(EventTypes.UPDATED, {
                    deletedItems: [],
                    updatedItems: [],
                    saveDisabled: true
                });
                return;
            }
        }

        this.$emit(EventTypes.UPDATED, {
            deletedItems: this.itemsRemoved,
            updatedItems: dtoArray,
            saveDisabled: false
        });
    }

    private addSection() {
        this.newDividerCounter++;
        const typeOption: CrmTypeOptionUpdateDto = {
            id: -2 * this.newDividerCounter,
            is_default: false,
            is_editable: true,
            order: this.items.length,
            value: ''
        };

        const divider: TemplateGroupItem = {
            groupItem: typeOption,
            templatesInGroup: []
        };
        this.items.push(divider);
        this.expanded.push(this.items.indexOf(divider));
    }

    @Watch('panelIndexSynced')
    validate() {
        this.$nextTick(() => {
            this.form.validate();
        });
    }

    private async deleteSection(divider: TemplateGroupItem) {
        const arrayInd = this.items.indexOf(divider);
        if (divider.groupItem.id && divider.groupItem.id > 0) {
            const result = await this.$swal({
                text: this.warningText,
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Delete',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.items.splice(arrayInd, 1);
                    // If it was expanded, remove from list of expanded panels
                    this.expanded = this.expanded.filter(index => index !== divider.groupItem.order);
                }
                this.itemsRemoved.push(divider.groupItem.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.items.splice(arrayInd, 1);
            }
        }
    }
}
