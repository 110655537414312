






































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    LandingPageField,
    LandingPageFieldDbName,
    LandingPageFieldType
} from '@/automation/landing-pages/models/landing-page-fields';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { EventTypes } from '@/constants/event-type-constants';
import { landingPageFieldsDtoMapper } from '@/automation/landing-pages/mappers/landing-page-fields-mapper';
import type { LandingPageFields } from '@/automation/landing-pages/models/landing-page-fields';

const loadingStore = getModule(LoadingStore);
const landingPagesRepo = new LandingPagesRepository();

@Component
export default class EditLandingPageFields extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly doSave!: boolean;
    @Prop() readonly isPst!: boolean;
    @Prop({ required: true }) readonly formId!: number;
    @Prop({ default: [] }) readonly landingPageFields!: LandingPageFields;

    private loadingKey = 'editLandingPageFields';
    private sectionType = LandingPageFieldType.SECTION;
    private locationType = LandingPageFieldType.LOCATION;
    private fields: LandingPageFields = [];
    private newDividerCounter = 0;
    private requiredSst = ['firstName', 'lastName', 'phoneNumber', 'leadSource', 'email', 'emailAddress', 'primaryPhone'];
    private sstChildSet = ['firstName', 'lastName', 'dateOfBirth'];

    private get headers(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: 'CRM Field',
                value: 'name'
            },
            {
                text: 'Form Label',
                value: 'label',
                align: 'center'
            },
            {
                value: 'delete',
                text: '',
                align: 'center',
                width: '0'
            },
            {
                text: 'Use?',
                value: 'use',
                align: 'center'
            },
            {
                text: 'Required?',
                value: 'required',
                align: 'center'
            }
        ];
        if (!this.isPst) {
            headers.unshift(
                {
                    text: '',
                    value: 'handle'
                } as DataTableHeader
            );
        }
        return headers;
    }

    @Watch('doSave')
    watchSave() {
       if (this.doSave) {
           this.save();
       }
    }

    private async created() {
        loadingStore.loadingIncrement(this.loadingKey);

        if (!this.landingPageFields.length) {
            // Get and filter what fields we show. Which should be child and lead fields only.
            const landingPageFields = await landingPagesRepo.getFields(this.formId);
            landingPageFields.forEach((landingPageField) => {
                if (!landingPageField.field || landingPageField.type.values.value === 'Guardian' || landingPageField.type.values.value === 'Child') {
                    this.fields.push(landingPageField);
                    if (landingPageField.field?.values.db_name === LandingPageFieldDbName.SOURCE) {
                        this.$emit(EventTypes.LANDING_PAGE_SOURCE_TOGGLED, landingPageField.is_used);
                    }
                }
            });
        } else {
            this.fields = this.landingPageFields;
        }

        loadingStore.loadingDecrement(this.loadingKey);
    }

    private itemClass(item: LandingPageField) {
        if (item.type.id === LandingPageFieldType.SECTION) {
            return 'section';
        }
        return '';
    }

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.fields.splice(sortEvent.oldIndex!, 1)[0];
        this.fields.splice(sortEvent.newIndex!, 0, movedItem);
    }

    private isLockedActive(field: LandingPageField) {
        return this.isPst && field.type.id === LandingPageFieldType.WEEKDAYS;
    }

    private isLocked(field: LandingPageField) {
        if (field.type.id === LandingPageFieldType.LOCATION) {
            return true;
        }
        if (this.isPst && field.type.id === LandingPageFieldType.LEAD) {
            if (field.field && this.requiredSst.includes(field.field.values.db_name)) {
                return true;
            }
        }
        return false;
    }

    private getCrmName(field: LandingPageField) {
        if (field.custom_value_group) {
            const forChild = field.custom_value_group.values.for_child;
            let name = forChild ? 'Child' : 'Guardian';
            if (forChild) {
                name += ' ' + field.child_number;
            }
            name += ' ' + field.custom_value_group.values.value;
            return name;
        }
        if (!field.field) {
            // location/divider
            return field.type.values.value;
        }
        let name = field.field.values.form_name;
        if (field.type.id === LandingPageFieldType.CHILD) {
            name += ' ' + field.child_number;
        }
        name += ' ' + field.field.values.name;
        return name.replace('Lead', 'Guardian');
    }

    private deleteSection(divider: LandingPageField) {
        const arrayInd = this.fields.indexOf(divider);
        if (arrayInd >= 0) {
            this.fields.splice(arrayInd, 1);
        }
    }

    private addSection() {
        this.newDividerCounter++;
        const divider: LandingPageField = {
            id: -1 * this.newDividerCounter,
            type: {
                id: LandingPageFieldType.SECTION,
                values: { value: 'Section Divider' },
                links: []
            },
            field: null,
            label: '',
            is_used: true,
            is_required: false,
            order: 0,
            child_number: 0
        };
        this.fields.unshift(divider);
    }

    private async save() {
        loadingStore.loadingIncrement(this.loadingKey);
        const dto = landingPageFieldsDtoMapper(this.fields);
        await landingPagesRepo.updateFields(this.formId, dto);
        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.UPDATED);
    }

    private checkUse(field: LandingPageField) {
        if (!field.is_used) {
            field.is_required = false;
        }

        if (this.isPst && field.type.id === LandingPageFieldType.CHILD) {
            if (field.field && this.sstChildSet.includes(field.field.values.db_name)) {
                for (const fieldIter of this.fields) {
                    if (fieldIter.type.id === LandingPageFieldType.CHILD && fieldIter.child_number === field.child_number && fieldIter.field && this.sstChildSet.includes(fieldIter.field.values.db_name)) {
                        fieldIter.is_used = field.is_used;
                        if (!field.is_used) {
                            fieldIter.is_required = false;
                        }
                    }
                }
            }
        }

        if (field.field?.values.db_name === LandingPageFieldDbName.SOURCE) {
            this.$emit(EventTypes.LANDING_PAGE_SOURCE_TOGGLED, field.is_used);
        }
    }

    private checkRequired(field: LandingPageField) {
        if (field.is_required) {
            field.is_used = true;
        }
        if (this.isPst && field.type.id === LandingPageFieldType.CHILD) {
            if (field.field && this.sstChildSet.includes(field.field.values.db_name)) {
                for (const fieldIter of this.fields) {
                    if (fieldIter.type.id === LandingPageFieldType.CHILD && fieldIter.child_number === field.child_number && fieldIter.field && this.sstChildSet.includes(fieldIter.field.values.db_name)) {
                        fieldIter.is_required = field.is_required;
                        if (field.is_required) {
                            fieldIter.is_used = true;
                        }
                    }
                }
            }
        }
    }
}
