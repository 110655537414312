













// CRM-11417 - Remove when we kill legacy.
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { legacyLoginWrapper } from '@/auth/auth';
import { LoadingStore } from '@/store/loading-store';
import store from '@/store';

const authStore = getModule(AuthStore, store);
const featureStore = getModule(FeaturesStore);
const loadingStore = getModule(LoadingStore);

@Component
export default class GoToLegacy extends Mixins(LocaleMixin) {
    // private hasLegacyAccess = false;
    private legacyUrl = process.env.VUE_APP_CRM_URL;
    private loadingKey = 'global';

    get hasLegacyAccess() {
        if (authStore.userInfoObject) {
            return featureStore.isFeatureEnabled(FeatureConstants.CRM_DUAL_MODE) && !authStore.userInfoObject.is_dual_mode_legacy_blocked;
        }

        return false;
    }

    private async goToLegacy() {
        if (authStore.token) {
            loadingStore.loadingIncrement(this.loadingKey);
            // CreatE the legacy user cookie stuff.
            await legacyLoginWrapper(authStore);

            // Redirect to legacy, already logged in.
            window.location.href = this.legacyUrl + '/main.php?from-crm=1';
        }
    }
}
