













































import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { encodeFile } from '@/core/file-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import BaseClose from '@/components/base/BaseClose.vue';

const attachmentsRepo = new EmailAttachmentsRepository();
const loadingState = getModule(LoadingStore, store);
@Component({
    components: { BaseClose }
})
export default class EmailAttachmentUpload extends Mixins(LocaleMixin) {
    private loadingKey = EmailAttachmentUpload.name;
    private file: File | null = null;
    private showAddDialog = false;

    private close() {
        this.showAddDialog = false;
    }

    private async upload() {
        if (!this.file || !this.file.name) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const fileContents = await encodeFile(this.file);
        await attachmentsRepo.create({ filename: this.file.name, file: fileContents as string });
        this.showAddDialog = false;
        this.file = null;
        loadingState.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.UPDATED);
    }
}
