









































































import { EventTypes } from '@/constants/event-type-constants';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { SavedReport } from '@/reports/models/saved-report';
import { SortableEvent } from 'sortablejs';
import { DataTableHeader } from 'vuetify';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import { SavedReportMapper } from '@/reports/mappers/saved-report-mapper';
import { ReportSchedulesRepository } from '@/reports/repositories/report-schedules-repository';
import { ReportSchedule } from '@/reports/models/report-schedule';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import store from '@/store';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const reportSchedulesRepo = new ReportSchedulesRepository();
const savedReportMapper = new SavedReportMapper();
const savedReportsRepo = new SavedReportsRepository();
const savedReportsState = getModule(SavedReportsStore);
@Component({
    components: { BaseClose }
})
export default class EditSavedReports extends Mixins(LocaleMixin) {
    // v-model whether we should show it
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly savedReports!: Array<SavedReport>;

    private headers: Array<DataTableHeader> = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center'
        },
        {
            value: 'delete',
            text: 'Delete',
            align: 'center',
            width: '0'
        }
    ];

    private items: Array<SavedReport> = [];
    private itemsRemoved: Array<number> = [];
    private loadingKey = 'editSavedReports';

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    get userId(): number {
        return authState.userInfoObject!.id;
    }

    @Watch('items', { deep: true })
    watchChanges() {
        if (this.items.length === 0) {
            return;
        }

        let orderIndex = 0;

        for (const item of this.items) {
            item.order = orderIndex;
            orderIndex += 1;
        }
    }

    @Watch('value')
    watchModelValue() {
        this.itemsRemoved = [];
        this.items = [];
        for (const report of this.savedReports) {
            this.items.push({ ...report });
        }
    }

    private get checkSave(): boolean {
        for (const item of this.items) {
            if (item.name.length === 0) {
                return false;
            }
        }
        return true;
    }

    private close() {
        this.itemsRemoved = [];
        this.items = [];
        this.modelValue = false;
    }

    private async deleteSection(divider: SavedReport) {
        loadingState.loadingIncrement(this.loadingKey);

        const currentSchedules: Array<ReportSchedule> = await reportSchedulesRepo.getReportSchedules(this.userId, divider.id);
        const arrayInd = this.items.indexOf(divider);

        if (currentSchedules.length > 0) {
            const result = await this.$swal({
                text: ' Warning: this report is emailed to one or more people periodically. If you delete this report no more emails will be sent.',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Proceed',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.items.splice(arrayInd, 1);
                }
                this.itemsRemoved.push(divider.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.items.splice(arrayInd, 1);
            }
            this.itemsRemoved.push(divider.id);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        while (this.itemsRemoved.length > 0) {
            const reportId = this.itemsRemoved.pop();
            if (reportId) {
                await savedReportsRepo.deleteReport(this.userId, reportId);
            }
        }

        for (const report of this.items) {
            if (report.is_editable && report.id) {
                const fullReport = await savedReportsRepo.getOneReport(this.userId, report.id);
                report.report_data = fullReport.report_data;
                await savedReportsRepo.updateReport(this.userId, report.id, savedReportMapper.toUpdateDto(report));
            }
        }
        await savedReportsState.retrieveAllReports(this.userId);
        this.$emit(EventTypes.UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.items.splice(sortEvent.oldIndex!, 1)[0];
        this.items.splice(sortEvent.newIndex!, 0, movedItem);
    }

}
