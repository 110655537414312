import { AbstractRepository } from '@/repositories/abstract-repository';
import { PendingFamily, RejectFamilyInterface } from '@/families/models/family';

export class PendingLeadsRepository extends AbstractRepository<PendingFamily> {
    readonly endpoint = 'families/pending';

    public async acceptFamily<CrmEntity>(familyId: number, centerId: number | null = null): Promise<void> {
        const data = centerId === null ? undefined : { centerId: centerId };
        await this.client.put(this.endpoint + '/' + familyId.toString() + '/accept', data);
    }

    public async bulkAccept(pendingIds: Array<number>): Promise<void> {
        await this.client.put(`${this.endpoint}/accept`, {
            ids: pendingIds
        });
    }

    public async bulkReject(pendingIds: Array<number>): Promise<void> {
        await this.client.put(`${this.endpoint}/reject`, {
            ids: pendingIds
        });
    }

    /**
     * Reject a pending family.
     *
     * @param familyId
     * @param existingFamilyId
     * @param reasonId The rejected reason id
     */
    public async rejectFamily<CrmEntity>(
        familyId: number,
        existingFamilyId: number | null = null,
        reasonId: number | null = null
    ): Promise<void> {
        const rejectedPayload = {
            merge_to_family_id: existingFamilyId,
            reason: reasonId
        } as RejectFamilyInterface;
        await this.client.put(this.endpoint + '/' + familyId.toString() + '/reject', rejectedPayload);
    }
}
