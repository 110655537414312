import { ChildLink } from '@/families/models/links/child-link';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';
import { FamilyLink } from '@/families/models/family';

export interface EnrollmentAmPm {
    am: boolean;
    pm: boolean;
}

export interface EnrollmentSchedule {
    monday: EnrollmentAmPm;
    tuesday: EnrollmentAmPm;
    wednesday: EnrollmentAmPm;
    thursday: EnrollmentAmPm;
    friday: EnrollmentAmPm;
    saturday: EnrollmentAmPm;
    sunday: EnrollmentAmPm;
}

export interface EnrollmentDateReason {
    reason: HateoasLink | null;
    date: string | null;
    is_current_status?: boolean;
    comments: string;
}

export interface WaitlistDetails extends EnrollmentDateReason {
    fee_paid_datetime: string | null;
    child_of_staff: boolean;
    sibling_in_care: boolean;
    is_fee_paid: boolean;
    fee: string;
    priority: HateoasLink | null;
    type: HateoasLink | null;
}

export interface EnrollmentDateReasonWrite {
    reason: number | null;
    date: string;
    comments: string;
}

export interface WaitlistDetailsUpdate extends EnrollmentDateReasonWrite {
    fee_paid_datetime: string | null;
    is_fee_paid: boolean;
    fee: string;
    priority: number | null;
    type: number | null;
}

export class EnrollmentDateReasonWriteClass implements EnrollmentDateReasonWrite {
    comments = '';
    date = '';
    reason = null;
}

export class WaitlistWrite implements WaitlistDetailsUpdate {
    comments = '';
    date = '';
    reason = null;
    fee = '';
    fee_paid_datetime = null;
    is_fee_paid = false;
    priority = null;
    type = null;
}

export interface Enrollment extends CrmEntity {
    child: ChildLink;
    center: HateoasLink;
    family: FamilyLink;
    classroom: HateoasLink|null;
    child_of_staff: boolean;
    schedule: EnrollmentSchedule;
    wait_list: WaitlistDetails | null;
    wait_list_date: string|null;
    expected_start_date: string|null;
    register_date: string|null;
    expected_enrollment: EnrollmentDateReason|null;
    enrollment: EnrollmentDateReason|null;
    temporary_leave: EnrollmentDateReason|null;
    estimated_withdrawn: EnrollmentDateReason|null;
    withdrawn: EnrollmentDateReason|null;
    lost_opportunity_reason: HateoasLink|null;
    lost_opportunity: EnrollmentDateReason | null;
}

export interface EnrollmentCreateDtoInterface extends AbstractCreateDto {
    child_id: number;
    center_id: number;
    classroom_id?: number|null;
    child_of_staff?: boolean;
    schedule?: EnrollmentSchedule;
    wait_list_date?: string|null;
    expected_start_date?: string|null;
    wait_list?: WaitlistDetailsUpdate|null;
    enrollment?: EnrollmentDateReasonWrite|null;
    temporary_leave?: EnrollmentDateReasonWrite|null;
    withdrawn?: EnrollmentDateReasonWrite|null;
    lost_opportunity_reason?: number|null;
}

export interface EnrollmentUpdateDtoInterface extends EnrollmentCreateDtoInterface, AbstractUpdateDto {
    id: number;
}

export class EnrollmentCreate implements EnrollmentCreateDtoInterface {
    center_id = 0;
    child_id = 0;
    child_of_staff = false;
    classroom_id = null;
    enrollment = null;
    expected_start_date = null;
    lost_opportunity_reason = null;
    schedule = {
        monday: {
            am: false,
            pm: false
        },
        tuesday: {
            am: false,
            pm: false
        },
        wednesday: {
            am: false,
            pm: false
        },
        thursday: {
            am: false,
            pm: false
        },
        friday: {
            am: false,
            pm: false
        },
        saturday: {
            am: false,
            pm: false
        },
        sunday: {
            am: false,
            pm: false
        }
    };

    temporary_leave = null;
    wait_list = null;
    wait_list_date = null;
    withdrawn = null;
}
