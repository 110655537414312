






import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { Component, Mixins } from 'vue-property-decorator';
import { checkUser } from '@/auth/auth';
import { FeaturesStore } from '@/features/features-store';
import { Sso } from '@/auth/sso';

const authState = getModule(AuthStore, store);
const featureState = getModule(FeaturesStore);

@Component
export default class LoginIdp extends Mixins(LocaleMixin) {
    get isLoggedIn() {
        return authState.isLoggedIn;
    }

    public async created() {
        if (authState.needIdpLogout) {
            window.location.href = Sso.getSsoLogoutUrl(authState);
            return;
        }
        if (authState.authenticated) {
            const userCheck = checkUser(authState.userInfoObject!);
            if (userCheck.fullyLoggedIn) {
                authState.storeLoggedIn();
            } else {
                if (userCheck.redirectLegacy) {
                    authState.storeNeedsLegacyRedirect();
                    await this.$router.go(0);
                } else {
                    await this.$router.push({ name: userCheck.step });
                }
            }
        } else {
            const redirect = typeof this.$route.query.redirect === 'string' ? this.$route.query.redirect : null;
            window.location.href = Sso.getSsoAuthUrl(authState, redirect);
        }
        if (this.isLoggedIn) {
            await featureState.init();
            await this.$router.push({ name: 'home' });
        }
    }
}
