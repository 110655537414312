


































import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsTextDto, WorkflowCreateDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    WorkflowTemplateType,
    workflowTemplateTypes
} from '@/automation/workflows/constants/workflow-constants';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import WorkflowActionDaysBeforePartial from '@/automation/workflows/components/WorkflowActionDaysBeforePartial.vue';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const crmTypesStore = getModule(CrmTypesStore);
const featuresState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const templatesStore = getModule(TextTemplatesStore);

@Component({
    components: { WorkflowActionDaysBeforePartial, CrmTypeSelectList }
})
export default class WorkflowActionSendText extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsTextDto;
    @Prop() readonly dto!: WorkflowCreateDto;

    private loadingKey = 'manageWorkflow';
    private templateTypes = workflowTemplateTypes;
    private templates: Array<MessageTemplate> = [];
    private templateGroupsTextList = CrmTypeList.TEMPLATE_GROUPS_TEXT;

    /**
     * Whether or not template groups are enabled.
     */
    get areTemplateGroupsEnabled(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    get settings(): WorkflowActionSettingsTextDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsTextDto) {
        this.$emit('input', settings);
    }

    get isAutomatic() {
        return this.settings.template_method === WorkflowTemplateType.AUTOMATIC;
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresState.init();

        const templatesPromise = templatesStore.initForOrg(1);
        const promises = [templatesPromise];
        if (this.areTemplateGroupsEnabled) {
            const templateGroupsPromise = crmTypesStore.initList(CrmTypeList.TEMPLATE_GROUPS_TEXT);
            promises.push(templateGroupsPromise);
        } else {
            this.settings.template_method = WorkflowTemplateType.SINGLE;
        }
        await Promise.all(promises);

        this.templates = templatesStore.orgTemplates;
        this.templates = this.templates.filter(template => template.org.id === 1);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
