import chamaileonSdk from '@chamaileon-sdk/plugins';
import { lineLeaderDarkerPurple } from '@/core/colors';
import { BASE_DARK_BLUE } from '@/core/style-utils';

export async function initChameleon(token: string, isLineLeaderEnroll = false) {
    return await chamaileonSdk({
        accessToken: token,
        locale: 'childcarecrm-en',
        colors: {
            primary: isLineLeaderEnroll ? lineLeaderDarkerPurple : BASE_DARK_BLUE,
            secondary: '#009f4a',
            red: '#ff5546',
            darkBlue: '#2d3291',
            darkGreen: '#00af6e',
            lightGreen: '#50d791',
            weirdGreen: '#50d791',
            pink: '#ff91a0',
            yellow: '#ffd23c'
        }
    });
}
