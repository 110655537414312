import { AbstractRepository } from '@/repositories/abstract-repository';
import { CrmApiElement } from '@/models/base';

interface FreshdeskUrl extends CrmApiElement {
    url: string;
}

export class HelpRepository extends AbstractRepository<FreshdeskUrl> {
    protected endpoint = 'freshdesk-url';

    async getFreshdeskUrl(): Promise<FreshdeskUrl> {
        return (await this.client.get(this.endpoint)).data;
    }
}
