import { CrmApiElement, CrmEntity, HateoasLink, NamedLink, ValuedLink } from '@/models/base';
import { AbstractUpdateDto } from '@/models/abstract-dto';

export enum LocationSettingType {
    OFFERINGS = 'offerings'
}

export interface LocationAdvisory extends CrmEntity {
    center: NamedLink;
    description: string;
    title: string;
}

export interface LocationAdvisoryDto {
    center: number;
    description: string;
    title: string;
}

export interface RatingLink {
    id: number;
    values: {
        label: string;
        order: number;
    };
    links: Array<{ href: string; rel: string; type: string }>;
}

export interface LocationDetail extends CrmApiElement {
    detail: ValuedLink;
    value: string | null;
}

export interface LocationDetailDto {
    value: string;
}

export interface ValueLink {
    id: number;
    values: {
        label: string;
        order: number;
    };
    links: Array<{ href: string; rel: string; type: string }>;
}

export interface LocationRating extends CrmApiElement{
    rating: RatingLink;
    value: ValueLink | null;
}

export interface LocationRatingDto {
    value: number;
}

export interface ClassroomRate {
    classroom: ValuedLink;
    value: number | null;
}

export interface LocationRates extends CrmApiElement {
    rate_option: HateoasLink;
    classrooms: Array<ClassroomRate>;
}

export interface ClassroomRateDto {
    classroom: number;
    value: number;
}

export interface LocationRatesDto {
    classrooms: Array<ClassroomRateDto>;
}

export interface LocationOfferings {
    offerings: Array<LocationOffering>;
}

export interface LocationOffering {
    offering_group: HateoasLink;
    options: Array<LocationOfferingOption>;
}

export interface LocationOfferingOption {
    offering_option: HateoasLink;
    is_offered: boolean;
    is_included_in_price: boolean;
}

export interface LocationOfferingOptionDto extends AbstractUpdateDto {
    is_offered: boolean;
    is_included_in_price: boolean;
}

export interface AvailabilityDayObject {
    is_available: boolean;
    am_avail: number;
    pm_avail: number;
}

export interface AvailabilityClassroomObject {
    class_id: number;
    next_free_day: string | null;
    day_avails: Array<AvailabilityDayObject>;
}
