






























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    locationIntegrationsToMappingDto,
    locationIntegrationToToggleDtos
} from '@/integrations/mappers/location-integrations-mapper';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import { Center } from '@/organizations/locations/models/center';
import { EventTypes } from '@/constants/event-type-constants';
import { IntegrationLocationMappingId, LocationIntegrationToggle, LocationIntegrationMapping } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import BaseClose from '@/components/base/BaseClose.vue';
import IntegrationService from '@/integrations/IntegrationService';
import ManagementSystemIdSelect from '@/integrations/components/ManagementSystemIdSelect.vue';
import store from '@/store';
import { Regions } from '@/locales/locales';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const centersRepo = new CentersRepository();
const integrationsRepo = new IntegrationRepository();
const centersState = getModule(CentersStore);

@Component({
    components: { ManagementSystemIdSelect, BaseClose }
})
export default class MapLocations extends Mixins(LocaleMixin) {
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ default: null }) readonly cmsPartnerId!: number | null;
    @Prop({ default: false }) readonly isSecondary!: boolean;
    @Prop({ default: false }) readonly hasMultiCms!: boolean;

    private loadingKey = 'mapLocations' + (this.cmsPartnerId ? '_' + this.cmsPartnerId : '');
    private externalIds: Array<IntegrationLocationMappingId> = [];
    private centers: Array<Center> = [];
    private centerIntegrationMapping: Array<LocationIntegrationMapping>| null = null;
    private integrationService: IntegrationService | null = null;

    get hasIds(): boolean {
        return this.externalIds.length > 0;
    }

    get saveDisabled(): boolean {
        if (this.centerIntegrationMapping !== null) {
            if (this.cmsPartnerId && this.isSecondary && this.hasMultiCms) {
               const enabled = this.centerIntegrationMapping.find(
                    (mapping) => {
                        return mapping.is_enabled;
                    }
                );

               if (!enabled) {
                   // Save if nothing is enabled.
                   return false;
               }

               return !this.centerIntegrationMapping.find(
                    (mapping) => {
                        return mapping.secondary_external_system_id;
                    }
                );
            }

            if (!this.isSecondary) {
                return !this.centerIntegrationMapping.find(
                    (mapping) => {
                        return mapping.external_system_id;
                    }
                );
            }
        }

        if (!this.cmsPartnerId) {
            return false;
        }

        return true;
    }

    /**
     * Handles showing the modal.
     */
    get modelValue(): boolean {
        return this.value;
    }

    @Watch('modelValue')
    private async modalChanged(flag: boolean) {
        if (flag) {
            await this.init();
        }
    }

    /**
     * initialize centers and external system ids
     */
    async init() {
        loadingState.loadingIncrement(this.loadingKey);
        if (!this.integrationService) {
            let region: Regions;
            if (authState.overrideCountry && Regions[authState.overrideCountry as keyof typeof Regions]) {
                region = Regions[authState.overrideCountry as keyof typeof Regions];
            } else {
                region = authState.userInfoObject
                    ? Regions[authState.userInfoObject.country as keyof typeof Regions]
                    : Regions.us;
            }

            this.integrationService = new IntegrationService(region);
        }

        this.externalIds = await integrationsRepo.getIntegrationLocationMappingIds(this.cmsPartnerId ? this.cmsPartnerId : null);
        this.centerIntegrationMapping = [];

        const centerIntegrationToggles: Array<LocationIntegrationToggle> | null = this.cmsPartnerId
            ? await integrationsRepo.getLocationIntegrationsByPartnerId(this.cmsPartnerId)
            : null;

        await centersState.init(true);
        this.centers = centersState.entities;
        // Get QK test credentials and ProCare!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        for (const center of this.centers) {
            const mapping: LocationIntegrationMapping = {
                center: center,
                external_system_id: center.external_system_id,
                secondary_external_system_id: center.secondary_external_system_id,
                original_is_enabled: false,
                is_enabled: false
            };

            if (this.cmsPartnerId && centerIntegrationToggles && centerIntegrationToggles.length > 0) {
                const toggle = centerIntegrationToggles.find((item) => item.center_id === center.id);
                if (toggle !== undefined) {
                    mapping.original_is_enabled = toggle.is_enabled;
                    mapping.is_enabled = toggle.is_enabled;
                }
            }

            this.centerIntegrationMapping.push(mapping);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Save data, then emit close event.
     */
    async save() {
        if (this.centerIntegrationMapping !== null) {
            loadingState.loadingIncrement(this.loadingKey);
            if (this.cmsPartnerId) {
                // Send the toggle changes here.
                await integrationsRepo.setLocationIntegrationByPartnerId(
                    this.cmsPartnerId,
                    locationIntegrationToToggleDtos(this.centerIntegrationMapping, this.hasMultiCms)
                );
            }

            // Update the location mapping.
            const mappings = locationIntegrationsToMappingDto(this.centerIntegrationMapping, this.isSecondary);
            if (mappings.length > 0) {
                await centersRepo.updateLocationMappings(mappings);
            }

            await centersState.init(true);
            this.centers = centersState.entities;

            loadingState.loadingDecrement(this.loadingKey);
            this.$emit(EventTypes.UPDATED);
        }
    }

    // Make sure primary is enabled if there's a mapping id.
    private setIsEnabled(mapping: LocationIntegrationMapping) {
        if (!this.hasMultiCms || !this.isSecondary) {
            mapping.is_enabled = !!mapping.external_system_id;
        }
    }

    /**
     * Emit event to close dialog without saving.
     *
     * @private
     */
    private cancel() {
        this.$emit(EventTypes.CLOSE);
    }
}
