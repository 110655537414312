export const CorporateUserMessagesContent = {
    tips: [
        'When possible, give tours the same day a family contacts you. Speed increases enrollment rates and decreases tasks.',
        'Text often. Studies show that most families prefer texts over emails or phone calls.',
        'If you don\'t have one, get a website. Half of today\'s leads come from the web.',
        'Tip: you register/waitlist children, not families. A family needs children in the system to go past Tour Completed.',
        'Speed matters: completing tours faster means more enrollments with fewer tasks.',
        'A growing list of past due tasks doesn\'t get families signed up. Cancel what isn\'t going to get done.',
        'Need help? Click the question mark icon in the top bar.',
        'Fewer past due tasks means more system use and more success. Cancel tasks you\'re too busy to get to.',
        'Keep workloads manageable: cancel past due tasks that aren\'t realistically going to get done.'
    ],
    tourSpeed: {
        0: [
            'Try to get half of your tours done within 24 hours of first contact to help your registration rate.',
            'Moving through statuses quickly improves registration rates. This location has room for improvement.',
            'Try to get tours completed faster at this location to improve registration rates.'
        ],
        43: [
            'The number of families getting a tour completed within 24 hours is pretty standard.',
            'New families are getting tours completed at this location at a reasonable speed.',
            'The speed new families are getting tours completed supports a reasonable registration rate.'
        ],
        55: [
            'The number of families getting a tour completed within 24 hours is above average.',
            'Good job! New families are getting tours completed at an above-average speed.',
            'Congrats! New families are getting tours completed at a good speed at this location.'
        ],
        63: [
            'This location is on fire! New families are completing tours at well above average speed.',
            'This location rocks! New families get tours completed very quickly, helping registration rates.',
            'Speed boosts registration rates and this location moves through statuses like greased lightning!'
        ]
    },
    tasksPastDue: {
        0: [
            'This location is on top of their game, with few or no past due tasks!',
            'This location is excellent at keeping past due task rates down!',
            'This location is a model of managing past due tasks!'
        ],
        12: [
            'This location\'s past due task rate is a bit better than average.',
            'The past due task rate here is slightly better than average.',
            'The past due task rate here is good, better than average.'
        ],
        23: [
            'The past due task rate here is pretty standard; canceling older tasks will improve things.',
            'Past due tasks here are typical; consider canceling many or all past due tasks to improve.',
            'The past due task rate here is average.'
        ],
        50: [
            'The past due task rate here is high; seriously consider canceling past due tasks.',
            'There\'s a lot of room for improving the past due task rate here; time to cancel past due tasks.',
            'Canceling past due tasks here will help keep staff workload manageable.'
        ]
    },
    conversionSuccess: {
        0: [
            'Conversion success is suffering; consider how to give parents better pre-enrollment experiences.',
            'Consider how to get more parents across the finish line before they drop out of the process.',
            'Consider strategies for improving your conversion rate; there is much room for improvement.'
        ],
        8: [
            'Your conversion rate is fairly typical.',
            'The rate parents sign paperwork here is in keeping with your typical competition.',
            'You get parents registered/waitlisted at about the same rate as other ECE companies.'
        ],
        37: [
            'Your conversion rate is above average.',
            'Parents are somewhat more likely to sign their children up at this location than others.',
            'Congrats! You\'ve got a solid conversion rate.'
        ],
        79: [
            'Your conversion rate is awesome. Congrats!',
            'This location gets parents to sign on the dotted line well above the average rate. Excellent!',
            'Your conversion rate at this location is well above average. Great job!'
        ]
    }
};
