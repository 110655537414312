import { CrmTypeList } from '@/crm-types/models/crm-type';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HardCodedType, HateoasLink, ValuedLink } from '@/models/base';

export enum TemplateType {
    SINGLE = 2030,
    AUTOMATIC = 2031
}

export interface LandingPage extends CrmEntity {
    inquiry_type: HateoasLink | null;
    last_edited_datetime: string | null;
    last_edited_staff: HateoasLink;
    marketing_campaign: HateoasLink | null;
    name: string;
    type: LandingPageTypeLink;
    url: string;
    wait_list_reason: HateoasLink | null;
    wait_list_type: HateoasLink | null;
    customer_id: string;
    web_form_id: string;
}

interface LandingPageTypeLink extends HateoasLink {
    values: { value: string };
}

export enum LandingPageType {
    CUSTOMER = 2664,
    SINGLE_WEB = 2667,
    WEB = 2668,
    KIOSK = 2669,
    WAIT_LIST = 8850,
    PST = 9102
}

export enum PendingReplyTo {
    LOCATION_DIRECTOR = 2401,
    LOCATION_ADMIN = 2402,
    CORPORATE_ADMIN = 2403,
    CORPORATE_MARKETING = 2404
}

export enum FormProcessingFlow {
    PROCESS_PENDING_CORPORATE = 2670,
    PROCESS_PENDING_LOCATION = 2671,
    PROCESS_PENDING_ALL = 2672,
    PROCESS_PENDING_ENROLLMENT_TEAM = 2673
}

export interface LandingPageCreateDtoInterface extends AbstractCreateDto {
    inquiry_type?: number | undefined;
    name: string;
    type: LandingPageType;
}

export interface LandingPageUpdateDtoInterface extends AbstractUpdateDto {
    inquiry_type?: number | undefined;
    name: string;
    wait_list_reason?: number | null | undefined;
    wait_list_type?: number | null | undefined;
    marketing_campaign?: number | null | undefined;
}

export class LandingPageCreateDto implements LandingPageCreateDtoInterface {
    inquiry_type: number | undefined = undefined;
    name = '';
    type = LandingPageType.WEB;
}

export interface LandingPageHeaderSettings {
    use_logo: boolean;
    headline: string;
    subheader: string;
}

export interface LandingPageStyleSettings {
    header_text_color: string;
    header_background_color: string;
    background_color: string;
    border_color: string;
    field_text_color: string;
    slogan_text_color: string;
    divider_background_color: string;
    divider_text_color: string;
    submit_button_color: string;
    text_alignment: '' | 'Left' | 'Right' | 'Center';
}

export interface LandingPageConfirmationSettings {
    redirect_url: string;
    thank_you_header: string;
    thank_you_body: string;
}

export interface LandingPageCommunicationSettings {
    is_send_thank_you_email: boolean;
    family_email_from: ValuedLink | null;
    family_email_reply_to: ValuedLink | null;
    family_email_template: ValuedLink | null;
    family_email_template_method: ValuedLink | null;
    family_email_template_group: ValuedLink | null;
    family_email_delay_amount: number;
    family_email_delay_units: ValuedLink | null;
    staff_notification_email_to: ValuedLink | null;
    staff_notification_email_from: ValuedLink | null;
}

export interface LandingPageSettings {
    header: LandingPageHeaderSettings;
    style: LandingPageStyleSettings;
    confirmation: LandingPageConfirmationSettings;
    process_at: ValuedLink | null;
    communication: LandingPageCommunicationSettings | null;
}

export interface LandingPageCommunicationSettingsDto {
    is_send_thank_you_email: boolean;
    family_email_from: number | null;
    family_email_reply_to: number | null;
    family_email_template: number | null;
    family_email_template_method: number | null;
    family_email_template_group: number | null;
    family_email_delay_amount: number;
    family_email_delay_units: number | null;
    staff_notification_email_to: number | null;
    staff_notification_email_from: number | null;
}

export interface LandingPageSettingsUpdateDtoInterface {
    header: LandingPageHeaderSettings;
    style: LandingPageStyleSettings;
    confirmation: LandingPageConfirmationSettings;
    process_at: number;
    communication: null | LandingPageCommunicationSettingsDto;
}

export const DefaultLandingPageSettings: LandingPageSettingsUpdateDtoInterface = {
    header: {
        use_logo: false,
        headline: '',
        subheader: ''
    },
    style: {
        submit_button_color: '333333',
        divider_background_color: '999999',
        divider_text_color: 'FFFFFF',
        border_color: '999999',
        field_text_color: '333333',
        slogan_text_color: '333333',
        header_background_color: 'FFFFFF',
        background_color: 'FFFFFF',
        header_text_color: '999999',
        text_alignment: 'Left'
    },
    confirmation: {
        redirect_url: '',
        thank_you_header: 'Thank you for reaching out!',
        thank_you_body: 'One of our staff members will reach out to you to find a good time to speak. Have a good day!'
    },
    communication: {
        is_send_thank_you_email: false,
        family_email_from: PendingReplyTo.LOCATION_DIRECTOR,
        family_email_reply_to: PendingReplyTo.LOCATION_DIRECTOR,
        family_email_template: null,
        family_email_template_method: TemplateType.SINGLE,
        family_email_template_group: null,
        family_email_delay_amount: 0,
        family_email_delay_units: 3,
        staff_notification_email_to: null,
        staff_notification_email_from: null
    },
    process_at: FormProcessingFlow.PROCESS_PENDING_ALL
};

export interface LandingPageLink extends HateoasLink {
    values: { name: string };
}

export interface LandingPageOptionExclusion {
    exclusion: HateoasLink;
}

export interface LandingPageOptionExclusionDto {
    ids: Array<number>;
}

export type LandingPageOptionExclusionPossibilities = CrmTypeList.FAMILY_SOURCE | 'other';

export const landingPageTimeUnits: Array<HardCodedType> = [
    {
        id: 3,
        value: 'Minutes'
    },
    {
        id: 2,
        value: 'Hours'
    },
    {
        id: 1,
        value: 'Days'
    }
];

export const templateTypes: Array<HardCodedType> = [
    {
        id: TemplateType.SINGLE,
        value: 'No'
    },
    {
        id: TemplateType.AUTOMATIC,
        value: 'Yes'
    }
];

export const customerLandingPageId = -1;

export const customerLandingPage: LandingPage = {
    id: customerLandingPageId,
    customer_id: '',
    inquiry_type: null,
    last_edited_datetime: null,
    last_edited_staff: {
        id: -2,
        links: [],
        values: {}
    },
    marketing_campaign: null,
    name: 'Customer',
    type: {
        id: LandingPageType.CUSTOMER,
        links: [],
        values: {
            value: 'Customer'
        }
    },
    url: '',
    wait_list_reason: null,
    wait_list_type: null,
    web_form_id: ''
};
Object.freeze(customerLandingPage);
