import { CrmEntity, HateoasLink } from '@/models/base';

interface LandingPageFieldTypeLink extends HateoasLink {
    values: {
        value: string;
    };
}

export interface FieldLink extends HateoasLink {
    values: {
        name: string;
        form_name: string;
        db_name: string;
    };
}

export interface CustomValueGroupLink extends HateoasLink {
    values: {
        value: string;
        for_guardian: boolean;
        for_child: boolean;
        type: number;
    };
}

export interface LandingPageField extends CrmEntity {
    type: LandingPageFieldTypeLink;
    field: FieldLink | null;
    custom_value_group?: CustomValueGroupLink | null;
    label: string;
    is_used: boolean;
    is_required: boolean;
    order: number;
    child_number: number;
}

export type LandingPageFields = Array<LandingPageField>;

export interface LandingPageFieldDto {
    id: number|null;
    type: number;
    label: string;
    is_used: boolean;
    is_required: boolean;
    order: number;
}

export type LandingPageFieldsDto = Array<LandingPageFieldDto>;

export enum LandingPageFieldType {
    LEAD = 3250,
    CHILD = 3251,
    LOCATION = 3252,
    SECTION = 3253,
    WEEKDAYS = 3015
}

export enum LandingPageFieldDbName {
    SOURCE = 'leadSource'
}
