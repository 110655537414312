



































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { forgotPassword } from '@/auth/auth';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { VForm } from '@/types/types';

const loadingState = getModule(LoadingStore);

@Component
export default class ForgotPassword extends Mixins(LocaleMixin) {
    private email = '';
    private username = '';
    private validForm = false;
    private notEmpty = [
        (v: string) => !!v || 'Please enter a value'
    ];

    @Ref('passwordForm') readonly passwordForm!: VForm;

    public clearForm() {
        this.email = '';
        this.username = '';
        this.passwordForm.reset();
    }

    public async getPassword() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const usernameResponse = await forgotPassword(this.username, this.email);
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
        await this.$swal({ icon: 'info', text: usernameResponse.message });
        if (usernameResponse.isSso) {
            await this.$router.push('/sso-reset');
        } else {
            await this.$router.push('/');
        }
    }
}
