
































































































































import { ClientInfoStore } from '@/organizations/corporate/stores/client-info-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import { ClientInfo, ClientInfoDto } from '@/organizations/corporate/models/client-info';
import { ClientInfoMapper } from '@/organizations/corporate/mappers/client-info-mapper';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import BaseClose from '@/components/base/BaseClose.vue';

const clientInfoMapper = new ClientInfoMapper();
const clientInfoRepository = new ClientRepository();
const clientInfoStore = getModule(ClientInfoStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: { BaseClose }
})
export default class OrganizationInformationSettings extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    // Loading key
    private loadingKey = 'automationSettingsPopup';
    // Original number of location merge fields
    private originalMergeFieldNum = 0;
    // Current number of location merge fields - cannot be lower than original
    private curMergeFieldNum = 0;
    // v-model for expansion-panel
    private panelIndex: number | undefined = -1;
    // Save button enable/disable toggle
    private isSaveDisabled = false;
    private clientInfo: ClientInfo | null = null;
    // Controls the snackbar
    private isUpdated = false;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    @Watch('modelValue')
    async updateModalStatus() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            this.panelIndex = -1;
            this.originalMergeFieldNum = 0;
            this.curMergeFieldNum = 0;
            this.clientInfo = null;
            this.clientInfo = await clientInfoStore.retrieveClientInfo();
            if (this.clientInfo && this.clientInfo.center_variable_tags_count !== undefined) {
                // Get no. of location merge fields for each location
                this.originalMergeFieldNum = this.clientInfo.center_variable_tags_count;
                this.curMergeFieldNum = this.clientInfo.center_variable_tags_count;
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('curMergeFieldNum')
    private checkNumber() {
        // Save button disabled if curMergeFieldNum <= originalMergeFieldNum or if curMergeFieldNum == NaN(NotANumber) || +-Infinity
        this.isSaveDisabled = this.curMergeFieldNum <= this.originalMergeFieldNum || !Number.isFinite(this.curMergeFieldNum);
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.clientInfo !== null) {
            const clientInfoDto: ClientInfoDto = clientInfoMapper.toUpdateDto(this.clientInfo);
            clientInfoDto.center_variable_tags_count = this.curMergeFieldNum;
            const response = await clientInfoRepository.updateClientInfo(clientInfoDto);
            clientInfoStore.commitClientInfo(response);
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.isUpdated = true;
        this.close();
    }

    private close() {
        this.panelIndex = -1;
        this.modelValue = false;
    }
}
