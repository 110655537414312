

















































import BaseValueDisplay from '@/components/base/BaseValueDisplay.vue';
import DynamicFieldEditor from '@/crm-types/components/DynamicFieldEditor.vue';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import { sortFields } from '@/crm-types/field-utils';
import { ChildFields, ChildScheduleFields, Field, FieldEntityType } from '@/crm-types/models/field-models';
import { FieldsStore } from '@/crm-types/store/fields-store';
import ChildScheduleEditor from '@/families/components/ChildScheduleEditor.vue';
import { EnrollmentMapper } from '@/families/mappers/enrollment-mapper';
import type { ChildUpdateDtoInterface } from '@/families/models/child';
import {
    EnrollmentCreate,
    EnrollmentCreateDtoInterface,
    EnrollmentUpdateDtoInterface
} from '@/families/models/enrollment';
import type { Family } from '@/families/models/family';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';

const customFieldsStore = getModule(CustomFieldsStore);
const enrollmentMapper = new EnrollmentMapper();
const enrollmentsRepo = new EnrollmentsRepository();
const enrollmentStore = getModule(EnrollmentsStore);
const featuresStore = getModule(FeaturesStore);
const fieldStore = getModule(FieldsStore);

@Component({
    components: { BaseValueDisplay, ChildScheduleEditor, CustomValuesEditor, DynamicFieldEditor }
})
export default class AdditionalChildInfo extends Mixins(LocaleMixin) {
    @Prop({ required: true }) childDto!: ChildUpdateDtoInterface;
    @Prop({ required: true }) family!: Family;
    @Prop({ type: Boolean, default: false }) isExpanded!: boolean;

    private isCreated = false;

    /**
     * Get the custom fields for the child
     */
    get customFields(): Array<CustomField> {
        if (!this.isCrmPlusEnabled) {
            return [];
        }
        return customFieldsStore.storedChildFields;
    }

    /**
     * Get the desired child fields.
     */
    get fields(): Array<Field> {
        // Filter out fields handled by other components or by other logic
        const fieldsToInclude: Array<string> = [
            ChildFields.GENDER,
            ChildFields.PRIOR_LIKES,
            ChildFields.PRIOR_DISLIKES
        ];
        // gender, likes, dislikes
        const childTypeFieldsToInclude: Array<string> = [];
        if (this.isCrmPlusEnabled) {
            if (featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12)) {
                childTypeFieldsToInclude.push('CHILD_TYPE', 'CHILD_TYPE_TWO');
            }
            if (featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34)) {
                childTypeFieldsToInclude.push('CHILD_TYPE_THREE', 'CHILD_TYPE_FOUR');
            }
        }

        // Sort all non-hidden fields; filter out hidden fields and only include desired fields
        return sortFields(
            FieldEntityType.CHILD,
            fieldStore.storedChildFields.filter(
                field =>
                    !field.is_hidden &&
                    (
                        fieldsToInclude.includes(field.value) ||
                        (field.select_list_name && childTypeFieldsToInclude.includes(field.select_list_name))
                    )
            )
        );
    }

    /**
     * Whether or not is in crm+ mode.
     */
    get isCrmPlusEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    /**
     * Whether or not to show the child schedule.
     */
    get isScheduleHidden(): boolean {
        return fieldStore.storedChildFields.filter(
            field => Object.values(ChildScheduleFields).includes(field.value as ChildScheduleFields) && field.is_hidden
        ).length > 0;
    }

    private async created() {
        await featuresStore.init();
        const promises = [];
        promises.push(fieldStore.init());
        if (this.isCrmPlusEnabled) {
            promises.push(customFieldsStore.init());

            let enrollment: EnrollmentCreateDtoInterface | EnrollmentUpdateDtoInterface;
            // We need the enrollment here because multiple fields might get changed
            // So we need one object that all the field editor instances can use
            const enrollments = await enrollmentsRepo.getEnrollmentsForChildId(this.childDto.id);
            if (!enrollments.length) {
                enrollment = new EnrollmentCreate();
                enrollment.child_id = this.childDto.id;
                enrollment.center_id = this.family.center?.id ?? 0;
            } else {
                enrollment = enrollmentMapper.toUpdateDto(enrollments[enrollments.length - 1]);
            }
            // Store current DTO as the initial state
            enrollmentStore.storeInitialState({
                enrollment: enrollment,
                familyId: this.family.id,
                childId: enrollment.child_id
            });
        }
        await Promise.all(promises);
        this.isCreated = true;
    }

    /**
     * Get the expected start date for the child.
     *
     * @private
     */
    private getExpectedStartDate(): string {
        let date = '';
        for (const child of this.family.children) {
            if (child.id === this.childDto.id) {
                date = child.status_details?.expected_start_date ?? '';
            }
        }
        return date ? this.formatDate(date) : '';
    }
}
