





















import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { doLogout, legacyLoginWrapper } from '@/auth/auth';
import { baseUrl } from '@/core/base-url';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const featuresStore = getModule(FeaturesStore);

@Component
export default class ClientFormSettings extends Mixins(LocaleMixin) {
    private breadcrumbs = [
        {
            text: 'Automation',
            to: { name: 'automation' }
        },
        {
            text: 'Landing Pages',
            to: { name: 'landing-pages' }
        },
        {
            text: 'Landing Page Editor',
            disabled: true
        }
    ];

    private settingsUrl = '';
    private loadingKey = 'clientFormSettings';
    private isLoaded = false;

    get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    async mounted() {
        if (authState.token) {
            loadingState.loadingIncrement(this.loadingKey);
            await legacyLoginWrapper(authState);
            this.settingsUrl = baseUrl + '/pages/client-form-settings';
        }
    }

    async beforeDestroy() {
        await doLogout();
    }

    private async loaded() {
        this.isLoaded = true;
        loadingState.loadingStop(this.loadingKey);
    }

}
