





































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { OutgoingText, TextMessage } from '../models/text';
import { MessageDirection, OutgoingStatusString } from '../models/message';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { isAfterNow } from '@/date-time/date-time-utils';

const authState = getModule(AuthStore, store);

@Component({
    components: { }
})
export default class ViewSingleText extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly text!: TextMessage;
    @Prop({ default: false }) readonly noDate!: boolean;

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    get isIncoming() {
        return this.text.type === MessageDirection.INCOMING;
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    get isPending() {
        if (!this.isIncoming && (this.text as OutgoingText).status === OutgoingStatusString.PENDING) {
            if (!this.text.sent_date_time) {
                return true;
            }
            return isAfterNow(this.text.sent_date_time);
        }

        return false;
    }
}
