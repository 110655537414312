







































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    Workflow,
    WorkflowAction,
    WorkflowCondition,
    WorkflowStopTrigger
} from '@/automation/workflows/models/workflow-models';
import { getConditionExplanation } from '@/automation/workflows/workflow-condition-utils';
import { getActionSettings } from '@/automation/workflows/workflow-action-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { WorkflowActionTypes, WorkflowEntityTypes } from '@/automation/workflows/constants/workflow-constants';
import ManageWorkflow from '@/automation/workflows/components/ManageWorkflow.vue';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const authState = getModule(AuthStore, store);
const featureState = getModule(FeaturesStore);

@Component({
    components: { ManageWorkflow }
})
export default class ViewWorkflow extends Mixins(LocaleMixin) {
    @Prop() workflow!: Workflow | null;
    @Prop({ default: false }) readonly granted!: boolean;
    private isOpen = false;
    private isEditOpen = false;
    private updatedEvent = EventTypes.WORKFLOWS_UPDATED;
    private closeEvent = EventTypes.CLOSE;

    private get leadAddedType(): string {
        if (!this.workflow?.family_added_type) {
            return 'All';
        }
        return this.workflow!.family_added_type!.values.value as string;
    }

    private get timezone(): string {
        return authState.userInfoObject?.timezone ?? '';
    }

    private get hasCrmPlusMode() {
        return featureState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private getActionName(action: WorkflowAction): string {
        if (action.type.id !== WorkflowActionTypes.ADD_EVENT) {
            return action.type.name;
        }
        return 'Add Completed Task/Log';
    }

    private getActionSettings(action: WorkflowAction): string {
        return getActionSettings(action);
    }

    private getConditionExplanation(condition: WorkflowCondition): string {
        return getConditionExplanation(condition);
    }

    private getConditionTarget(condition: WorkflowCondition): string {
        if (!condition.target_entity || condition.entity.id !== WorkflowEntityTypes.CHILD) {
            return '';
        }
        switch (condition.target_entity.id) {
            case WorkflowEntityTypes.FAMILY:
                return '(Run once per Family)';
            case WorkflowEntityTypes.CHILD:
                return '(Run once per Child)';
            default:
                return '';
        }
    }

    private getStaffName(): string {
        if (!this.workflow?.last_edited_by_staff) {
            return 'Unknown';
        }
        return `${this.workflow?.last_edited_by_staff?.values.first_name} ${this.workflow?.last_edited_by_staff?.values.last_name}`;
    }

    private getStopTrigger(trigger: WorkflowStopTrigger): string {
        let stopTriggerText = '<span class="font-weight-bold pr-1">';
        let fieldName = trigger.field?.name;
        if (trigger.entity!.id === WorkflowEntityTypes.FAMILY) {
            stopTriggerText += 'Guardian ';
        }
        if (trigger.entity!.id === WorkflowEntityTypes.EVENT) {
            fieldName = 'Completed Task/Log Type';
        }
        stopTriggerText += `${fieldName}</span> becomes <span class="font-weight-bold pl-1">${trigger.value?.value}</span>`;
        return stopTriggerText;
    }

    @Watch('workflow')
    private toggleModal() {
        this.isOpen = this.workflow !== null;
    }

    private closeModal() {
        this.isOpen = false;
        this.isEditOpen = false;
        this.$emit(EventTypes.CLOSE);
    }

    private openEdit() {
        this.isEditOpen = true;
        this.isOpen = false;
    }

    private workflowsUpdated(workflow: Workflow) {
        this.$emit(this.updatedEvent, workflow);
    }
}
