




















































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { VForm } from '@/types/types';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const staffRepository = new StaffRepository();

@Component
export default class ResetPassword extends Mixins(LocaleMixin) {
    private password = '';
    private passwordConfirm = '';
    private validForm = false;
    private passwordRules = [
        (v: string) => !!v || 'Please enter a value',
        (v: string) => v.length >= 8 || 'Password must be at least 8 characters long',
        (v: string) => v.match(/(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])/) || 'Password must contain at least one upper case letter, one lower case letter, and one number'
    ];

    private get passwordMatchRule() {
        return [
            this.password === this.passwordConfirm || 'Passwords must match'
        ];
    }

    // definitions for typescript
    @Ref('resetPasswordForm') readonly resetPasswordForm!: VForm;

    public async resetPassword() {
        if (!this.validForm) {
            this.resetPasswordForm.validate();
            return;
        }
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const updatePayload = {
            id: authState.id!,
            password: this.password,
            force_password_change: false
        };
        try {
            await staffRepository.update(updatePayload);
            const userInfo = await staffRepository.getOne(authState.id!);
            authState.storeUserInfo(userInfo);
            // we'll use the created hook in LoginMain to redirect them properly
            this.$router.push('/');
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
        } catch (error) {
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
            this.$swal({ text: 'Something went wrong', icon: 'error' });
        }
    }
}
