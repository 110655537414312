

































































import { EventTypes } from '@/constants/event-type-constants';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { IntegrationRoleMappingsMapper } from '@/integrations/mappers/integration-role-mappings-mapper';
import { IntegrationPartner } from '@/integrations/models/integration';
import { IntegrationRole, IntegrationRoleMappingViewDto } from '@/integrations/models/position-roles';
import { LoadingStore } from '@/store/loading-store';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

const crmTypesStore = getModule(CrmTypesStore);
const loadingStore = getModule(LoadingStore);
const repositoryFactoryStore = getModule(RepositoryFactoryStore);

@Component({
    components: {}
})
export default class MapRoles extends Vue {
    @Prop({ default: null }) partner!: IntegrationPartner | null;
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'mapRolesModal';
    private mappings: Array<IntegrationRoleMappingViewDto> = [];
    private roles: Array<IntegrationRole> = [];

    /**
     * Handles showing the modal.
     */
    get modelValue(): boolean {
        return this.value;
    }

    @Watch('modelValue')
    private async modalChanged(flag: boolean) {
        if (flag) {
            await this.load();
        }
    }

    private async created() {
        await this.load();
    }

    /**
     * Emit event to close dialog without saving.
     *
     * @private
     */
    private cancel() {
        this.$emit(EventTypes.CLOSE);
    }

    private async load() {
        loadingStore.loadingIncrement(this.loadingKey);
        if (!this.partner) {
            return;
        }
        const results = await Promise.all([
            crmTypesStore.initList(CrmTypeList.STAFF_TITLE),
            repositoryFactoryStore.integrationRepository.getIntegrationRoles(this.partner.id),
            repositoryFactoryStore.integrationRepository.getIntegrationRoleMappings(this.partner.id)
        ]);
        this.roles = results[1];
        this.mappings = IntegrationRoleMappingsMapper.toViewMappings(results[2], crmTypesStore.listOptions(CrmTypeList.STAFF_TITLE));
        loadingStore.loadingDecrement(this.loadingKey);
    }

    /**
     * Save the role mappings.
     */
    private async save() {
        if (!this.partner) {
            return;
        }

        loadingStore.loadingIncrement(this.loadingKey);
        const dtos = this.mappings
            .filter(mapping => {
                return !!mapping.identifier;
            })
            .map(mapping => IntegrationRoleMappingsMapper.toIntegrationRoleMappingDto(mapping));
        await repositoryFactoryStore.integrationRepository.setIntegrationRoleMappings(this.partner.id, dtos);
        loadingStore.loadingDecrement(this.loadingKey);

        this.$emit(EventTypes.CLOSE);
    }
}
