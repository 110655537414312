import { CrmEntity, TimeUnitLink } from '@/models/base';
import { StaffLink } from '@/staff/models/user';

export interface ReportSchedule extends CrmEntity {
    start_datetime: string;
    last_run_datetime: string | null;
    next_run_datetime: string;
    time_unit: TimeUnitLink;
    time_amount: number;
    email_to: Array<StaffLink>;
}

export interface ReportScheduleDtoInterface {
    start_datetime: string;
    time_unit: number;
    time_amount: number;
    email_to: Array<number>;
}

export class ReportScheduleDto implements ReportScheduleDtoInterface {
    start_datetime = '';
    time_unit = 1;
    time_amount = 1;
    email_to = [] as Array<number>;
}
