



























































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { DripCampaignEventActionDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { EventTypes } from '@/constants/event-type-constants';
import {
    DripCampaignEventActionType,
    dripEventActionTypes
} from '@/automation/drip-campaigns/drip-campaign-constants';
import { WorkflowsStore } from '@/automation/workflows/store/workflows-store';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';

const crmTypesState = getModule(CrmTypesStore);
const workflowsState = getModule(WorkflowsStore);
const dripCampaignsState = getModule(DripCampaignsStore);
const dripCampaignUtils = new DripCampaignUtils();

@Component
export default class DripCampaignEventAction extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly value!: DripCampaignEventActionDto;
    @Prop() readonly isText!: boolean;

    private actionTypes = dripEventActionTypes;

    // v-model handling
    get eventAction(): DripCampaignEventActionDto {
        return this.value;
    }

    set eventAction(eventAction: DripCampaignEventActionDto) {
        this.$emit('input', eventAction);
    }

    get actionableCampaigns() {
        return dripCampaignsState.stored.filter((campaign) => {
            return dripCampaignUtils.isActionable(campaign.status.id);
        });
    }

    get emailEvents() {
        return crmTypesState.listOptions(CrmTypeList.DRIP_EMAIL_EVENTS);
    }

    get isNewDripCampaign() {
        return this.eventAction.event_action === DripCampaignEventActionType.ACTION_NEW_DRIP;
    }

    get isWorkflow() {
        return this.eventAction.event_action === DripCampaignEventActionType.ACTION_WORKFLOW;
    }

    get textEvents() {
        return crmTypesState.listOptions(CrmTypeList.DRIP_TEXT_EVENTS);
    }

    get workflows() {
        return workflowsState.stored.filter((workflow) => {
            return workflow.is_for_drip_campaigns;
        });
    }

    deleteEventAction() {
        this.$emit(EventTypes.DELETED);
    }
}
