import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { FacebookPageMapping } from '@/integrations/models/facebook';
import { FacebookMappingRepository } from '@/integrations/repositories/facebook-mapping-repository';

export interface FacebookState {
    loggedIn: boolean;
}

@Module({
    namespaced: true,
    name: StoreModuleTypes.FACEBOOK,
    dynamic: true,
    store: store
})
export class FacebookStore extends AbstractApiStore<FacebookPageMapping> implements FacebookState {
    readonly repository = new FacebookMappingRepository();

    loggedIn = false;

    @Mutation
    public setIsLoggedIn() {
        this.loggedIn = true;
    }

    @Mutation
    public setIsLoggedOut() {
        this.loggedIn = false;
    }

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

}
