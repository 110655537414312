





















































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Workflow, WorkflowCreateDto } from '../models/workflow-models';
import { WorkflowsMapper } from '@/automation/workflows/mappers/workflows-mapper';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import WorkflowCondition from '@/automation/workflows/components/WorkflowCondition.vue';
import { WorkflowsRepository } from '@/automation/workflows/repositories/workflows-repository';
import WorkflowAction from '@/automation/workflows/components/WorkflowAction.vue';
import { EventTypes } from '@/constants/event-type-constants';
import WorkflowStopTrigger from '@/automation/workflows/components/WorkflowStopTrigger.vue';
import { Center, CentersEnabled } from '@/organizations/locations/models/center';
import AffectedLocationsModal from '@/automation/workflows/components/AffectedLocationsModal.vue';
import { WorkflowLocationsStore } from '@/automation/workflows/store/workflow-locations-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { eventTypeId, facebookEventIdentifier } from '@/automation/workflows/constants/workflow-constants';
import { FacebookStore } from '@/integrations/store/facebook-store';

const workflowMapper = new WorkflowsMapper();
const loadingState = getModule(LoadingStore);
const crmTypesStore = getModule(CrmTypesStore);
const workflowRepo = new WorkflowsRepository();
const workflowLocationsStore = getModule(WorkflowLocationsStore);
const centersStore = getModule(CentersStore);
const featuresState = getModule(FeaturesStore);
const facebookState = getModule(FacebookStore);

@Component({
    components: { WorkflowStopTrigger, WorkflowCondition, WorkflowAction, AffectedLocationsModal, SaveButton }
})
export default class ManageWorkflow extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ default: null }) readonly workflow!: Workflow | null;
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    private dto: WorkflowCreateDto = workflowMapper.createBlank();
    private validForm = false;
    private loadingKey = 'manageWorkflow';
    private origins: Array<CrmTypeOption | { id: null; value: string }> = [];
    private deleteEvent = EventTypes.DELETED;
    private centers: Array<Center> = [];
    private openLocationsModal = false;
    private updatedEvent = EventTypes.UPDATED;
    private facebookEventId = 0;
    private isSaving = false;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get hasDripCampaigns() {
        return featuresState.hasDripCampaigns;
    }

    get hasFacebook() {
        return facebookState.entities.length > 0;
    }

    get isFacebookEvent() {
        if (this.dto.is_for_drip_campaigns) {
            return false;
        }
        for (const condition of this.dto.conditions) {
            if (condition.field === eventTypeId && this.facebookEventId && condition.value_one === this.facebookEventId) {
                return true;
            }
        }
        return false;
    }

    get isNew(): boolean {
        return this.workflow === null;
    }

    get isValid(): boolean {
        return this.validForm && this.dto.conditions.length > 0 && this.dto.actions.length > 0;
    }

    created() {
        loadingState.loadingStop(this.loadingKey);
    }

    @Watch('modelValue', { immediate: true })
    private async watchOpen() {
        loadingState.loadingStop(this.loadingKey);
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            await featuresState.init();
            await centersStore.initAccessibleCenters();
            await crmTypesStore.initList(CrmTypeList.FAMILY_ORIGIN);
            this.centers = centersStore.storedAccessibleCenters;
            this.origins = crmTypesStore.listOptions(CrmTypeList.FAMILY_ORIGIN);
            if (featuresState.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION)) {
                await facebookState.init();
                await crmTypesStore.initList(CrmTypeList.TASK_RESULTS);
                for (const event of crmTypesStore.listOptions(CrmTypeList.TASK_RESULTS)) {
                    if (event.identifier === facebookEventIdentifier) {
                        this.facebookEventId = event.id;
                    }
                }
            }
            this.origins.unshift({
                id: null,
                value: 'All'
            });
            if (this.workflow) {
                this.dto = workflowMapper.toCreateDto(this.workflow);
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            } else {
                this.$nextTick().then(() => {
                    this.dto = workflowMapper.createBlank();
                    this.dto.conditions.push(workflowMapper.createBlankCondition());
                });
            }
            workflowLocationsStore.updateLocationMappings(new Array<CentersEnabled>());
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private handleClose() {
        this.$emit(EventTypes.CLOSE);
        this.form.reset();
        this.modelValue = false;
    }

    private toggle2ndCondition() {
        if (this.dto.conditions.length > 1) {
            this.dto.conditions.pop();
        } else {
            this.dto.conditions.push(workflowMapper.createBlankCondition());
        }
    }

    private addAction() {
        this.dto.actions.push(workflowMapper.createBlankAction());
    }

    private deleteAction(index: number) {
        this.dto.actions.splice(index, 1);
    }

    private addStopTrigger() {
        this.dto.stop_triggers.push(workflowMapper.createBlankStopTrigger());
    }

    private cancel() {
        this.$emit(EventTypes.CLOSE);
    }

    private deleteStopTrigger(index: number) {
        this.dto.stop_triggers.splice(index, 1);
    }

    private async save() {
        this.isSaving = true;
        loadingState.loadingIncrement(this.loadingKey);
        let workflow: Workflow;
        if (this.dto.is_for_drip_campaigns) {
            this.dto.conditions.splice(0);
            this.dto.family_added_type = null;
        }
        if (this.isNew) {
            if (workflowLocationsStore.storedLocationMappings.length > 0) {
                this.dto.enabled_center_ids = workflowLocationsStore.storedLocationMappings.filter((centerData) => {
                    return centerData.enabled;
                }).map((centerData) => {
                    return centerData.center.id;
                });
            }
            workflow = await workflowRepo.post(this.dto);
        } else {
            workflow = await workflowRepo.putOne(this.workflow!.id, this.dto);
        }

        loadingState.loadingDecrement(this.loadingKey);
        this.isSaving = false;
        this.$emit(EventTypes.WORKFLOWS_UPDATED, workflow);
        this.handleClose();
    }

    private locationsUpdated(workflow: Workflow) {
        this.$emit(EventTypes.WORKFLOWS_UPDATED, workflow);
    }
}
