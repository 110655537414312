import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { PhoneMapper } from '@/families/mappers/phone-mapper';
import { Contact, ContactCreateDtoInterface, ContactUpdateDtoInterface } from '@/families/models/contact';

export class ContactMapper implements AbstractMapper<Contact> {
    toCreateDto(entity: Contact): ContactCreateDtoInterface {
        const phoneMapper = new PhoneMapper();
        return {
            name: entity.name,
            first_name: entity.first_name,
            last_name: entity.last_name,
            email: entity.email,
            phone: entity.phone ? phoneMapper.toCreateDto(entity.phone) : null,
            address: entity.address,
            child_relation: entity.child_relation ? entity.child_relation.id : null,
            is_guardian: entity.is_guardian,
            is_family: entity.is_family,
            is_emergency_contact: entity.is_emergency_contact,
            is_authorized_pickup: entity.is_authorized_pickup,
            comments: entity.comments ?? ''
        };
    }

    toUpdateDto(entity: Contact): ContactUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as ContactUpdateDtoInterface;
        dto.id = entity.id as number;
        return dto;
    }

    /**
     * Clear some data before save to make the API request valid.
     */
    cleanPreSave(dto: ContactUpdateDtoInterface | ContactCreateDtoInterface): ContactUpdateDtoInterface | ContactCreateDtoInterface {
        if (dto.phone &&
            dto.phone.number_e164 === ''
        ) {
            dto.phone = null;
        }
        if (!dto.child_relation) {
            dto.child_relation = null;
        }

        return dto;
    }
}
