


























































































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Center } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Contact } from '@/families/models/contact';
import { ContactsRepository } from '@/families/repositories/contacts-repository';
import { DripCampaignHistory } from '@/automation/drip-campaigns/models/drip-campaign';
import { FamilyDocument } from '@/families/models/documents';
import { FamilyDocumentsRepository } from '@/families/repositories/family-documents-repository';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { FamilyMarketingTracking } from '@/families/models/marketing';
import { FamilyMarketingRepository } from '@/families/repositories/family-marketing-repository';
import type { Family } from '@/families/models/family';
import FamilyDataContacts from '@/families/components/new/FamilyDataContacts.vue';
import FamilyDataDocuments from '@/families/components/new/FamilyDataDocuments.vue';
import FamilyDataDripCampaigns from '@/families/components/new/FamilyDataDripCampaigns.vue';
import FamilyDataInfo from '@/families/components/new/FamilyDataInfo.vue';
import FamilyDataMarketingCampaigns from '@/families/components/new/FamilyDataMarketingCampaigns.vue';
import FamilyDataOtherLocations from '@/families/components/new/FamilyDataOtherLocations.vue';

const centersStore = getModule(CentersStore);
const contactsRepo = new ContactsRepository();
const dripCampaignsRepo = new DripCampaignsRepository();
const dripCampaignsUtil = new DripCampaignUtils();
const familyMarketingRepo = new FamilyMarketingRepository();
const docsRepo = new FamilyDocumentsRepository();
const featuresState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);

type FamilyDataTab = 'Family Info' | 'Additional Contacts' | 'Documents' | 'Other Locations' | 'Marketing Campaigns' | 'Drip Campaigns';

@Component({
    components: {
        FamilyDataContacts,
        FamilyDataDocuments,
        FamilyDataDripCampaigns,
        FamilyDataInfo,
        FamilyDataMarketingCampaigns,
        FamilyDataOtherLocations
    }
})
export default class FamilyData extends Mixins(FamilyHubMixin, LocaleMixin) {
    // Props
    @Prop() family!: Family;

    // Properties
    private center: Center | null = null;
    private isExpanded = true;
    private loadingKey = 'familyData';

    // Tab properties
    private tabsKey = 0;
    private activeTab: FamilyDataTab = 'Family Info';
    private contactsTab: FamilyDataTab = 'Additional Contacts';
    private documentsTab: FamilyDataTab = 'Documents';
    private dripCampaignsTab: FamilyDataTab = 'Drip Campaigns';
    private infoTab: FamilyDataTab = 'Family Info';
    private otherLocationsTab: FamilyDataTab = 'Other Locations';
    private marketingCampaignsTab: FamilyDataTab = 'Marketing Campaigns';

    // Data properties
    private familyContacts: Array<Contact> = [];
    private familyDripCampaigns: Array<DripCampaignHistory> = [];
    private familyMarketingCampaigns: Array<FamilyMarketingTracking> = [];
    private familyDocs: Array<FamilyDocument> = [];
    private updateEvent = EventTypes.UPDATED;

    // Event properties
    private childAddedEvent = EventTypes.CHILD_ADDED;
    private childDeletedEvent = EventTypes.CHILD_DELETED;
    private childEditedEvent = EventTypes.CHILD_EDITED;
    private deletedEvent = EventTypes.DELETED;
    private otherLocationClicked = EventTypes.OTHER_LOCATION_CLICKED;
    private updatedEvent = EventTypes.UPDATED;

    // Computed getters / setters.
    /**
     * The count of family contacts.
     */
    private get contactsCount() {
        return this.familyContacts.length;
    }

    private get docsCount() {
        return this.familyDocs.length;
    }

    private get dripCampaignsCount() {
        return this.familyDripCampaigns.length;
    }

    private get dripCampaignsDisplayCount() {
        return this.familyDripCampaigns.filter((history) => {
            return dripCampaignsUtil.isActive(history);
        }).length;
    }

    private get familyId() {
        return this.family.id;
    }

    private get hasCrmPlus() {
        return featuresState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get hasDripCampaigns() {
        return this.hasCrmPlus && featuresState.hasDripCampaigns;
    }

    private get hasMarketingCampaigns() {
        return this.hasCrmPlus && featuresState.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    private get marketingCampaignsCount() {
        return this.familyMarketingCampaigns.length;
    }

    private get otherLocationsCount() {
        return this.family.linked_families ? this.family.linked_families.length : 0;
    }

    private get potentialTabs(): Array<FamilyDataTab> {
        if (!this.canEditFamily) {
            return [];
        }
        const potentialTabs: Array<FamilyDataTab> = [];
        if (this.hasDripCampaigns && this.dripCampaignsCount === 0 && this.activeTab !== this.dripCampaignsTab) {
            potentialTabs.push(this.dripCampaignsTab);
        }
        if (this.hasMarketingCampaigns && this.marketingCampaignsCount === 0 && this.activeTab !== this.marketingCampaignsTab) {
            potentialTabs.push(this.marketingCampaignsTab);
        }
        return potentialTabs;
    }

    // this will get more complicated later, I know
    private get tabs() {
        const tabs = [this.infoTab, this.contactsTab, this.documentsTab];
        if (this.otherLocationsCount) {
            tabs.push(this.otherLocationsTab);
        }

        if (!this.canEditFamily) {
            return tabs;
        }

        if (this.activeTab === this.dripCampaignsTab || (this.hasDripCampaigns && this.dripCampaignsCount > 0)) {
            tabs.push(this.dripCampaignsTab);
        }

        if (this.activeTab === this.marketingCampaignsTab || (this.hasMarketingCampaigns && this.marketingCampaignsCount > 0)) {
            tabs.push(this.marketingCampaignsTab);
        }

        return tabs;
    }

    // Watches
    @Watch('familyId', { immediate: true })
    async loadData() {
        loadingState.loadingIncrement(this.loadingKey);

        const promises: Array<any> = [];
        let centerIndex = 0;
        let dripIndex = 0;
        let campaignIndex = 0;
        promises.push(contactsRepo.retrieveAll(this.family.id));
        promises.push(docsRepo.retrieveAll(this.family.id));

        if (this.family.center) {
            promises.push(centersStore.getById(this.family.center.id));
            centerIndex = promises.length - 1;
        }

        if (this.hasDripCampaigns) {
            promises.push(dripCampaignsRepo.getFamilyCampaigns(this.familyId));
            dripIndex = promises.length - 1;
        }
        if (this.hasMarketingCampaigns) {
            promises.push(familyMarketingRepo.retrieve(this.familyId));
            campaignIndex = promises.length - 1;
        }

        const responses: any = await Promise.all(promises);
        this.familyContacts = responses[0];
        this.familyDocs = responses[1];

        if (centerIndex) {
            this.center = responses[centerIndex];
        }

        if (dripIndex) {
            this.familyDripCampaigns = responses[dripIndex];
        }
        if (campaignIndex) {
            this.familyMarketingCampaigns = responses[campaignIndex];
        }

        this.tabsKey++;

        loadingState.loadingDecrement(this.loadingKey);
    }

    // Methods
    private childAdded(childId: number) {
        this.$emit(EventTypes.CHILD_ADDED, childId);
    }

    private childDeleted(childId: number) {
        this.$emit(EventTypes.CHILD_DELETED, childId);
    }

    private childEdited(childIds: Array<number>) {
        this.$emit(EventTypes.CHILD_EDITED, childIds);
    }

    getTabHeaderCount(tab: FamilyDataTab): string {
        switch (tab) {
            case this.contactsTab:
                return `(${this.contactsCount})`;
            case this.documentsTab:
                return `(${this.docsCount})`;
            case this.dripCampaignsTab:
                return `(${this.dripCampaignsCount})`;
            case this.marketingCampaignsTab:
                return `(${this.marketingCampaignsCount})`;
            case this.otherLocationsTab:
                return `(${this.otherLocationsCount})`;
        }
        return '';
    }

    updateFamily() {
        this.$emit(EventTypes.UPDATED);
    }
}
