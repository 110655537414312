































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import ReportSettingsModal from '@/reports/components/ReportSettingsModal.vue';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const layoutTabsStore = getModule(LayoutTabsStore, store);
@Component({
    components: { BasePageTitle }
})
export default class ReportsLayout extends Mixins(LocaleMixin, PageTitleMixin) {
    private preFilterReports = false;
    private isSettingsVisible = false;
    private settingsComponent = ReportSettingsModal;

    async mounted() {
        this.setPageTitle('Reports');
        const settingsPermissionResponse = staffUtils.getUserPermission(PermissionName.SettingsAllGears);
        const featuresPromise = featuresStore.init();
        this.isSettingsVisible = await settingsPermissionResponse;
        await featuresPromise;
    }

    private get isLegacyReportsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LEGACY_REPORTS);
    }

    // Update the tab info in Layout.vue.
    private onChange() {
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? 'Report';
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = this.$route.params?.tabTitle ?? this.$route?.meta?.tabTitle ?? undefined;
        layoutTabsStore.updateTab(outerTab);
    }
}
