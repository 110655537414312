import {
    EmailAttachment,
    EmailAttachmentCreateDto,
    EmailAttachmentUpdateDto
} from '@/communications/templates/models/email-attachment';
import { AbstractMapper } from '@/core/mappers/abstract-mapper';

export class EmailAttachmentMapper implements AbstractMapper<EmailAttachment> {
    toCreateDto(entity: EmailAttachment): EmailAttachmentCreateDto {
        return {
            filename: entity.filename,
            org: entity.org.id
        };
    }

    toUpdateDto(entity: EmailAttachment): EmailAttachmentUpdateDto {
        return {
            id: entity.id,
            filename: entity.filename,
            org: entity.org.id
        };
    }

    createNewDto(): EmailAttachmentCreateDto {
        return {
            filename: '',
            org: 1
        };
    }
}
