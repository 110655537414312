



























































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import ManageReportingLevel from '@/organizations/levels/components/ManageReportingLevel.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { OrgsRepository } from '@/repositories/orgs-repository';
import { OrgLevelsRepository } from '@/organizations/levels/repositories/org-levels-repository';
import { OrgsUtil } from '@/organizations/orgs-util';
import cloneDeep from 'lodash/cloneDeep';
import { FeaturesStore } from '@/features/features-store';

const loadingState = getModule(LoadingStore);
const orgsRepository = new OrgsRepository();
const levelsRepo = new OrgLevelsRepository();
const orgsUtil = new OrgsUtil();
const featureStore = getModule(FeaturesStore);

@Component({
    components: { ManageReportingLevel }
})
export default class ReportingLevelCard extends Mixins(LocaleMixin) {
    @Prop({ required: true }) level!: OrganizationLevel;
    private loadingKey = 'levelsInfo';
    private headers = [
        { text: 'Name', value: 'name' },
        {
            text: 'Reporting Level',
            value: 'parent'
        }
    ];

    private levelData: Array<{ name: string; parent: string | undefined }> = [];
    private manageLevelActivated = false;
    private managedLevel: OrganizationLevel | null = null;
    private updatedLevelEvent = EventTypes.UPDATED;
    private deletedLevelEvent = EventTypes.DELETED;
    private localLevel = cloneDeep(this.level);

    private get isLineLeaderEnroll(): boolean {
        return featureStore.isLineLeaderEnroll;
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        const orgsResponse = await orgsRepository.getAll();
        const orgsResults = orgsUtil.sortOrgsByName(orgsResponse.entities);
        for (const curOrg of orgsResults) {
            if (curOrg.level === this.localLevel.id) {
                this.levelData.push({
                    name: curOrg.name,
                    parent: curOrg.parent_organization?.values.name
                });
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private editReportingLevel() {
        this.managedLevel = cloneDeep(this.level);
        this.manageLevelActivated = true;
    }

    private levelDeleted() {
        this.$emit(EventTypes.UPDATED);
    }

    private async refreshLevel() {
        loadingState.loadingIncrement(this.loadingKey);

        this.levelData = [];

        this.localLevel = await levelsRepo.getOne(this.level.id);

        const orgsResponse = await orgsRepository.getAll();
        const orgsResults = orgsUtil.sortOrgsByName(orgsResponse.entities);

        for (const curOrg of orgsResults) {
            if (curOrg.level === this.localLevel.id) {
                this.levelData.push({ name: curOrg.name, parent: curOrg.parent_organization?.values.name });
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

}
