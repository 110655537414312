































import { baseUrl } from '@/core/base-url';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { StaffRepository } from '@/staff/repositories/staff-repository';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const staffRepository = new StaffRepository();

@Component
export default class ConfirmEula extends Mixins(LocaleMixin) {
    private termsUrl = baseUrl + '/showTerms.php?file=1';

    private async confirmEula() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const updatePayload = {
            id: authState.id!,
            needs_eula: false
        };
        try {
            await staffRepository.update(updatePayload);
            const userInfo = await staffRepository.getOne(authState.id!);
            authState.storeUserInfo(userInfo);
            this.$router.push('/');
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
        } catch (error) {
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
            this.$swal({ text: 'Something went wrong', icon: 'error' });
        }
    }
}
