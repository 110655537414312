






































































import { EventTypes } from '@/constants/event-type-constants';
import LocationsSupported from '@/enrollment-center/components/LocationsSupported.vue';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import OfferingsRatingsSettings from '@/enrollment-center/components/OfferingsRatingsSettings.vue';
import {
    EnrollmentCenterDetails,
    EnrollmentCenterSettings,
    EnrollmentCenterRateOption,
    EnrollmentCenterOfferingsRatings
} from '@/enrollment-center/models/enrollment-center-models';
import { LoadingStore } from '@/store/loading-store';
import { saveECCSettings } from '@/enrollment-center/enrollment-center-utils';
import DetailsSettings from '@/enrollment-center/components/DetailsSettings.vue';
import RatesSettings from '@/enrollment-center/components/RatesSettings.vue';
import AvailabilitySettings from '@/enrollment-center/components/AvailabilitySettings.vue';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import BasicInfoSettings from '@/enrollment-center/components/BasicInfoSettings.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
const enrollmentCenterStore = getModule(EnrollmentCenterSettingsStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: {
        BaseClose,
        AvailabilitySettings,
        BasicInfoSettings,
        DetailsSettings,
        OfferingsRatingsSettings,
        RatesSettings
    }
})
export default class EnrollmentCenterSettingsModal extends Mixins(LocaleMixin) {
    private loadingKey = 'enrollmentCenterSettingsModal';
    private callCenterSettings: EnrollmentCenterSettings | null = null;
    private panelIndexSynced = -1;
    private offerings: Array<EnrollmentCenterOfferingsRatings> = [];
    private ratings: Array<EnrollmentCenterOfferingsRatings> = [];
    private ratesSettingsInfo: Array<EnrollmentCenterRateOption> = [];
    private detailsSettingsInfo: Array<EnrollmentCenterDetails> = [];
    private isLoaded = false;

    private get settingsComponents() {
        return [
            {
                component: BasicInfoSettings,
                title: 'Basic Information',
                props: {
                    callCenterSettings: this.callCenterSettings
                }
            },
            {
                component: LocationsSupported,
                title: 'Locations Supported',
                props: {}
            },
            {
                component: OfferingsRatingsSettings,
                title: 'Offerings',
                props: {
                    label: 'Name of Offering Group',
                    subLabel: 'Offering',
                    warningText: 'WARNING--deleting this offering will remove it from all locations.  Deleting cannot be undone--restoring these values would require adding this offering back to the list and then manually populating it for each location. Proceed?',
                    info: this.offerings,
                    settings: this.callCenterSettings
                }
            },
            {
                component: DetailsSettings,
                title: 'Details',
                props: {
                    settings: this.callCenterSettings,
                    detailsSettingsInfo: this.detailsSettingsInfo
                }
            },
            {
                component: RatesSettings,
                title: 'Tuition',
                props: {
                    settings: this.callCenterSettings,
                    ratesSettingsInfo: this.ratesSettingsInfo
                }
            },
            {
                component: AvailabilitySettings,
                title: 'Availability',
                props: {
                    settings: this.callCenterSettings
                }
            },
            {
                component: OfferingsRatingsSettings,
                title: 'Ratings',
                props: {
                    label: 'Rating Category',
                    subLabel: 'Rating',
                    warningText: 'WARNING--deleting this rating will remove it from all locations.  Deleting cannot be undone--restoring these values would require adding this offering back to the list and then manually populating it for each location. Proceed?',
                    info: this.ratings,
                    settings: this.callCenterSettings
                }
            }
        ];
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        this.isLoaded = false;
        const settingsPromise = enrollmentCenterStore.retrieve();
        const ratesPromise = enrollmentCenterStore.retrieveRateOptions();
        const offeringsPromise = enrollmentCenterStore.retrieveOfferings();
        const ratingsPromise = enrollmentCenterStore.retrieveRatings();
        const detailsPromise = enrollmentCenterStore.retrieveDetails();
        const responses: any = await Promise.all([
            settingsPromise,
            offeringsPromise,
            ratingsPromise,
            ratesPromise,
            detailsPromise
        ]);
        this.callCenterSettings = responses[0];
        this.offerings = responses[1];
        this.ratings = responses[2];
        this.ratesSettingsInfo = responses[3];
        this.detailsSettingsInfo = responses[4];

        settingsChangesStore.setEnabledDetailsFlag(this.callCenterSettings?.enable_details ?? false);
        settingsChangesStore.setEnabledOfferingsFlag(this.callCenterSettings?.enable_offerings ?? false);
        settingsChangesStore.setEnabledRatingsFlag(this.callCenterSettings?.enable_ratings ?? false);
        settingsChangesStore.setEnabledRateOptionsFlag(this.callCenterSettings?.enable_pricing ?? false);
        settingsChangesStore.setEnabledAvailabilityFlag(this.callCenterSettings?.enable_availability ?? false);
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Whether or not the save button should be disabled.
     */
    get isSaveDisabled(): boolean {
        return !settingsChangesStore.areChanges;
    }

    /**
     * Handles closing the dialog wrapping this component.
     */
    private handleClose(isUpdated = false) {
        // Set save button to false
        settingsChangesStore.setSaveButtonForOfferings(false);
        settingsChangesStore.setSaveButtonForRatings(false);
        settingsChangesStore.setSaveButtonForDetails(false);
        settingsChangesStore.setSaveButtonForBasicInfo(false);
        settingsChangesStore.setSaveButtonForRateOptions(false);
        settingsChangesStore.setSaveButtonForAvailability(false);
        settingsChangesStore.setSaveButtonForSupportedLocations(false);
        settingsChangesStore.setCenterEnrollmentRepChanges(new Map());
        this.$emit(isUpdated ? EventTypes.UPDATED : EventTypes.CLOSE);
    }

    /**
     * Save all of the enrollment command center settings
     */
    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        await saveECCSettings(this.callCenterSettings as EnrollmentCenterSettings);
        loadingState.loadingDecrement(this.loadingKey);
        this.handleClose(true);
    }
}
