









































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { CrmTypeOption, CrmTypeOptionCreateDto, CrmTypeOptionUpdateDto } from '../models/crm-type';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import { Field } from '@/crm-types/models/field-models';
import { EventTypes } from '@/constants/event-type-constants';
@Component({
    components: { SelectListEdit }
})
export default class CustomTypesEdit extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly typeLists!: Array<Array<CrmTypeOption>>;
    @Prop() readonly title!: string;
    @Prop() readonly fields!: Array<Field>;
    @PropSync('panel', { type: Number }) panelIndexSynced!: number;
    private panelIndexes = [
        -1,
        -1,
        -1,
        -1
    ];

    private updatedEvent = EventTypes.UPDATED;
    private updatedNameEvent = EventTypes.NAME_UPDATED;
    private updatedRequiredEvent = EventTypes.REQUIRED_UPDATED;

    private propagateUpdates(index: number, updates: { deletedItems: Array<number>; updatedItems: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto> }) {
        this.$emit(EventTypes.UPDATED, { index, deletedItems: updates.deletedItems, updatedItems: updates.updatedItems });
    };

    private propagateName(index: number, name: string) {
        this.$emit(EventTypes.NAME_UPDATED, { index, name });
    }

    private propagateRequired(index: number, required: boolean) {
        this.$emit(EventTypes.REQUIRED_UPDATED, { index, required });
    }
}
