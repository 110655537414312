import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import type { FacebookMessage } from '@/communications/messages/models/facebookMessage';
import store from '@/store';
import { MessagesStore } from '@/communications/messages/stores/messages-store';
import { InboxCountParams } from '@/communications/messages/models/inbox-params';

export interface FacebookMessagesState extends AbstractEntityState<FacebookMessage> {
    entities: Array<FacebookMessage>;
    currentFamilyId: number|undefined;
    orgInboxCount: number;
    currentOrgId: number|undefined;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FBMESSAGES
})
export class FacebookMessagesStore extends AbstractApiStore<FacebookMessage> implements FacebookMessagesState, MessagesStore {
    protected readonly repository = new FacebookMessagesRepository();

    entities: Array<FacebookMessage> = [];
    currentFamilyId: number|undefined = undefined;
    orgInboxCount = 0;
    teamInboxCount = 0;
    currentOrgId: number|undefined = undefined;
    private isTeamCountInit = false;

    @Mutation
    private storeFacebookMessagesForFamily(update: { fbMessages: Array<FacebookMessage>; familyId: number }) {
        this.entities = update.fbMessages;
        this.currentFamilyId = update.familyId;
    }

    @Action({ commit: 'storeFacebookMessagesForFamily' })
    public async retrieveFacebookMessagesForFamily(familyId: number) {
        const fbMessages = await this.repository.getMessagesForFamily(familyId);
        return { fbMessages: fbMessages.entities, familyId };
    }

    @Action
    public async initFacebookMessages(familyId: number) {
        if (this.currentFamilyId !== familyId) {
            await this.retrieveFacebookMessagesForFamily(familyId);
        }
    }

    @Mutation
    private storeFacebookMessagesInboxCount(update: { count: number; orgId: number }) {
        this.orgInboxCount = update.count;
        this.currentOrgId = update.orgId;
    }

    @Mutation
    private storeFacebookMessagesInboxCountForEnrollmentTeam(count: number) {
        this.teamInboxCount = count;
        this.isTeamCountInit = true;
    }

    @Mutation
    public incrementInboxCount() {
        this.orgInboxCount++;
    }

    @Mutation
    public decrementInboxCount() {
        this.orgInboxCount--;
    }

    @Mutation
    public incrementTeamInboxCount() {
        this.teamInboxCount++;
    }

    @Mutation
    public decrementTeamInboxCount() {
        this.teamInboxCount--;
    }

    @Action({ commit: 'storeFacebookMessagesInboxCount' })
    public async retrieveFacebookMessagesInboxCount(params: InboxCountParams) {
        const count = await this.repository.getInboxCount(params);
        return { count, orgId: params.org_id };
    }

    @Action({ commit: 'storeFacebookMessagesInboxCountForEnrollmentTeam' })
    public async retrieveFacebookMessagesInboxCountForEnrollmentTeam(params: InboxCountParams) {
        params.only_enrollment_team = true;
        return await this.repository.getInboxCount(params);
    }

    @Action
    public async initFacebookMessagesInboxCount(params: InboxCountParams) {
        if (this.currentOrgId !== params.org_id) {
            await this.retrieveFacebookMessagesInboxCount(params);
        }
    }

    @Action
    public async initFacebookMessagesInboxCountForEnrollmentTeam(params: InboxCountParams) {
        if (!this.isTeamCountInit) {
            await this.retrieveFacebookMessagesInboxCountForEnrollmentTeam(params);
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<FacebookMessage> {
        return this.entities;
    }

    public get inboxCount(): number {
        return this.orgInboxCount;
    }

    public get enrollmentTeamInboxCount(): number {
        return this.teamInboxCount;
    }
}
