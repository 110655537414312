
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VCheckbox from 'vuetify/lib/components/VCheckbox/VCheckbox';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VCheckbox] });
export default base.extend({
    name: 'base-chip',
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VCheckbox.options.computed.classes.call(this),
                'base-checkbox': true
            };
        }
    }
});
