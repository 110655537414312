

















































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { DripCampaignDripDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { EventTypes } from '@/constants/event-type-constants';
import {
    DripCommType,
    dripCommTypes,
    dripIntervalUnits
} from '@/automation/drip-campaigns/drip-campaign-constants';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import { getModule } from 'vuex-module-decorators';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import { DripCampaignsMapper } from '@/automation/drip-campaigns/mappers/drip-campaigns-mapper';
import DripCampaignEventAction from '@/automation/drip-campaigns/components/DripCampaignEventAction.vue';

const textTemplatesState = getModule(TextTemplatesStore);
const emailTemplatesState = getModule(EmailTemplatesStore);
const mapper = new DripCampaignsMapper();
@Component({
    components: { DripCampaignEventAction }
})
export default class DripCampaignDrip extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly value!: DripCampaignDripDto;
    @Prop({ required: true }) readonly order!: number;
    @Prop({ required: true }) readonly isRelative!: boolean;
    @Prop() readonly fieldString!: string;
    @Prop() readonly minPct!: number;

    private showDetails = false;
    private dripIntervalUnits = dripIntervalUnits;
    private dripCommTypes = dripCommTypes;
    private deleteEvent = EventTypes.DELETED;

    // v-model handling
    get drip(): DripCampaignDripDto {
        return this.value;
    }

    set drip(drip: DripCampaignDripDto) {
        this.$emit('input', drip);
    }

    get emailTemplates() {
        return emailTemplatesState.orgTemplates;
    }

    get isText() {
        return this.drip.comm_type === DripCommType.TEXT;
    }

    get intervalRules() {
        return [
            ...this.requiredNonNegativeInteger,
            (v: number) => (this.order === 1 || v > 0) || 'Must be greater than 0'
        ];
    }

    get pctRules() {
        return [
            ...this.requiredNonNegativeInteger,
            (v: number) => v >= this.minPct || 'Must be at least ' + String(this.minPct),
            (v: number) => v <= 100 || 'Can be at most 100'
        ];
    }

    get textTemplates() {
        return textTemplatesState.orgTemplates;
    }

    mounted() {
        if (!this.drip.name) {
            this.showDetails = true;
        }
    }

    addEventAction() {
        this.drip.event_actions.push(mapper.createBlankEventAction());
    }

    deleteDrip() {
        this.$emit(EventTypes.DELETED);
    }

    deleteEventAction(index: number) {
        this.drip.event_actions.splice(index, 1);
    }
}
