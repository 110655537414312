import { DataTableOptions } from '@/models/datatables';
import { ApiPagination } from '@/repositories/abstract-repository';
import { SortConstants } from '@/constants/sort-constants';
import { GenericSortParameter } from '@/models/sort-parameter';

export function getPagination(options: DataTableOptions): ApiPagination {
    return {
        offset: (options.page - 1) * options.itemsPerPage,
        limit: options.itemsPerPage
    };
}

export function getSortingFromOptions<T extends string>(options: DataTableOptions, defaultSorting: GenericSortParameter<T>): GenericSortParameter<T> {
    let sorting = defaultSorting;
    if (options.sortBy && options.sortBy.length) {
        sorting = {
            sort_keys: [options.sortBy[0] as T],
            sort_dir: [
                options.sortDesc && options.sortDesc[0]
                    ? SortConstants.DESCENDING
                    : SortConstants.ASCENDING
            ]
        };
    }
    return sorting;
}
