var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{staticClass:"truncated-columns family-info-datatable family-info-child-table clickable-rows",attrs:{"id":_vm.tableId,"items":_vm.localChildren,"headers":_vm.childrenHeaders,"item-key":"id","no-data-text":"This family has no children listed.","hide-default-footer":true},on:{"click:row":_vm.editChild},scopedSlots:_vm._u([{key:"header.comments",fn:function(ref){
var header = ref.header;
return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticClass:"align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('v-spacer'),(_vm.canEditFamily)?_c('base-button-secondary',{staticClass:"header-add-child-button",on:{"click":function($event){$event.stopPropagation();return _vm.addChild($event)}}},[_vm._v(" Add ")]):_vm._e()],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.getChildOppCount(item) > 1)?_c('v-badge',{staticClass:"additional-opp-count",attrs:{"offset-y":"-2"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('base-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getChildOppCount(item)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getChildOppCount(item))+" records of this child exist in your system because they have moved/are moving through the enrollment process "+_vm._s(_vm.getChildOppCount(item))+" times. Open the child record in the Family Hub to see all "+_vm._s(_vm.getChildOppCount(item))+" records. ")])])]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getChildBirthDate(item.birthdate))+" ")]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getChildBirthDateAge(item.age))+" ")]}},{key:"item.status_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_date ? _vm.formatDate(item.status_date, _vm.timezone) : '')+" ")]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticClass:"align-center"},[_c('div',{staticClass:"text-truncate",attrs:{"title":item.comments}},[_vm._v(" "+_vm._s(item.comments)+" ")]),_c('v-spacer'),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-row',{staticClass:"align-center ma-0 px-0 family-action-buttons justify-end"},[(_vm.hasIntegration && _vm.canBeExported(item) && _vm.canEditFamily && !_vm.hasManage)?_c('base-button-primary',{on:{"click":function($event){$event.stopPropagation();return _vm.exportChild(item)}}},[_vm._v(" Export ")]):_vm._e(),(_vm.hasManageIntegration(item.integrations) && !_vm.hasWhiteLabel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"button-wrapper"},'span',attrs,false),on),[_c('v-btn',{staticClass:"ll-manage-icon ml-4",attrs:{"fab":"","x-small":"","elevation":"0","icon":"","href":_vm.manageProfileUrl(item.integrations)}},[_c('img',{attrs:{"src":"/img/logos/ll_manage_icon_fullcolor.svg","alt":"Manage","width":"20","height":"20"}})])],1)]}}],null,true)},[_c('span',[_vm._v("View in Manage")])]):_vm._e(),(_vm.hasManageIntegration(item.integrations) && _vm.hasWhiteLabel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"button-wrapper"},'span',attrs,false),on),[_c('v-btn',{style:({
                                                backgroundColor : _vm.manageWhiteLabelcolor
                                            }),attrs:{"fab":"","x-small":"","elevation":"0","icon":"","href":_vm.manageWhiteLabelUrl(item.integrations)}},[_c('font-awesome-icon',{staticClass:"cms-icon",staticStyle:{"color":"white"},attrs:{"icon":"fa-solid fa-school"}})],1)],1)]}}],null,true)},[_c('span',[_vm._v("View in CMS")])]):_vm._e()],1)],1)],1)],1)]}}])}),_c('family-data-info-add-child',{attrs:{"family":_vm.family,"include-classrooms":_vm.isClassroomsFeatureEnabled,"has-integration":_vm.hasIntegration,"has-manage":_vm.hasManage,"management-system-name":_vm.managementSystemName},on:_vm._d({},[_vm.childAddedEvent,_vm.childAdded]),model:{value:(_vm.addChildModal),callback:function ($$v) {_vm.addChildModal=$$v},expression:"addChildModal"}}),_c('family-data-info-edit-child',{attrs:{"family":_vm.family,"child":_vm.selectedItem,"include-classrooms":_vm.isClassroomsFeatureEnabled,"has-integration":_vm.hasIntegration,"has-manage":_vm.hasManage,"management-system-name":_vm.managementSystemName},on:_vm._d({},[_vm.childDeletedEvent,_vm.childDeleted,_vm.childEditedEvent,_vm.childEdited]),model:{value:(_vm.editChildModal),callback:function ($$v) {_vm.editChildModal=$$v},expression:"editChildModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }