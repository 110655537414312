var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"ml-4 text-h6 font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" Group Communication History ")])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-data-table',{staticClass:"truncated-columns clickable-rows",attrs:{"headers":_vm.grpCommHistHeaders,"items":_vm.grpCommHistItems,"server-items-length":_vm.currentItemCount,"options":_vm.options,"loading":_vm.isLoading,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.viewStats},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.subject}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.type === 'email' ? 'Email' : 'Text'))])]}},{key:"item.date_sent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateSent(item.date_sent)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.can_be_deleted)?_c('base-button-secondary',{on:{"click":function($event){$event.stopPropagation();return _vm.cancelHistory(item)}}},[_vm._v(" Cancel ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }