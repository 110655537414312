import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { CentersEnabled } from '@/organizations/locations/models/center';

export interface WorkflowLocationsState {
    mappedEntities: Array<CentersEnabled>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.WORKFLOWS_LOCATIONS
})
export class WorkflowLocationsStore extends VuexModule implements WorkflowLocationsState {
    mappedEntities: Array<CentersEnabled> = [];

    @Mutation
    updateLocationMappings(locationMapping: Array<CentersEnabled>) {
        this.mappedEntities = locationMapping;
    }

    get storedLocationMappings(): Array<CentersEnabled> {
        return this.mappedEntities;
    }
}
