











































import { EventTypes } from '@/constants/event-type-constants';
import { encodeFile } from '@/core/file-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: { BaseClose }
})
export default class UploadRateFile extends Mixins(LocaleMixin) {
    // Whether or not the dialog should be visible
    @Prop({ type: Boolean, default: false }) readonly value!: boolean;

    private file: File | null | undefined = null;
    private maxSize = 5 * 1024 * 1024;
    private validForm = false;

    get fileRules() {
        return [
            (file: File | null | undefined) => {
                if (!file) {
                    return true;
                }
                return file.type.match('pdf') !== null || 'Please choose a PDF';
            },
            (file: File | null | undefined) => {
                if (!file) {
                    return true;
                }
                return file.size < this.maxSize || 'File is too large, must be less than 5MB';
            }
        ];
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    private close() {
        this.modelValue = false;
    }

    // Handle uploading the file
    private async upload() {
        if (!this.file) {
            return;
        }
        const fileContents = await encodeFile(this.file).catch(e => Error(e));
        if (this.file.type.match('pdf') === null) {
            await this.$swal({
                text: 'Please upload a PDF',
                icon: 'error'
            });
        } else if (fileContents instanceof Error) {
            await this.$swal({ text: 'Something went wrong, and the file could not be uploaded. Please try again later.', icon: 'error' });
        } else {
            this.$emit(EventTypes.UPLOADED, { file: fileContents, filename: this.file.name });
            this.file = null;
            this.close();
        }
    }
}
