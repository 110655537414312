import { CrmTypeOption } from '@/crm-types/models/crm-type';

export const INQUIRY_TYPE_WALK_IN_ID = 141;
export const PHONE_TYPE_CELL = 19;

// Hierarchy type ids
export const HIERARCHY_TYPE_BRAND_ID = 1;
export const HIERARCHY_TYPE_LOCATION_ID = 2;
export const HIERARCHY_TYPE_LOCATION_GROUP_ID = 3;

// The list of hierarchy types
export const HIERARCHY_TYPES_LIST: Array<CrmTypeOption> = [
    {
        id: HIERARCHY_TYPE_BRAND_ID,
        is_default: false,
        is_editable: false,
        order: 0,
        value: 'Brand'
    },
    {
        id: HIERARCHY_TYPE_LOCATION_ID,
        is_default: false,
        is_editable: false,
        order: 1,
        value: 'Location(s)'
    },
    {
        id: HIERARCHY_TYPE_LOCATION_GROUP_ID,
        is_default: false,
        is_editable: false,
        order: 2,
        value: 'Location Group'
    }
];
