import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    LocationRates, LocationRatesDto
} from '@/enrollment-center/models/enrollment-center-location-models';

export class LocationRatesRepository extends AbstractRepository<LocationRates> {
    readonly endpoint = '/centers';

    public async getLocationRates(centerId: number): Promise<Array<LocationRates>> {
        const response = await this.client.get(`${this.endpoint}/${centerId}/call-center-settings/rates`);
        return response.data;
    }

    public async updateLocationRate(centerId: number, rateId: number, newValues: LocationRatesDto) {
        await this.client.put(`${this.endpoint}/${centerId}/call-center-settings/rates/${rateId}`, newValues);
    }
}
