



































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { emailValidationRules } from '@/crm-types/field-utils';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LocaleMessages } from 'vue-i18n';

@Component
export default class InlineEditable extends Mixins(LocaleMixin, BasicValidationMixin) {
    $refs!: {
        input: HTMLInputElement;
        phoneInput: HTMLInputElement;
    }

    @PropSync('input', { type: String }) inputSynced!: string;
    @Prop() readonly label!: string | LocaleMessages;
    @Prop({ type: String }) readonly colSize!: string;
    @Prop({ type: Boolean, default: false }) readonly isEmailInput!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isPhoneInput!: boolean;

    private editable = false;
    private focusOnClick = false;

    private toggleEdit() {
        this.editable = !this.editable;
        if (this.editable) {
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        }
    }

    get nonRequiredEmail() {
        return emailValidationRules();
    }

    get generateSpanText(): string {
        if (this.inputSynced === '') {
            return this.label as string;
        } else {
            if (this.isPhoneInput) {
                return this.formatPhone(this.inputSynced);
            } else {
                return this.inputSynced;
            }
        }
    }
}
