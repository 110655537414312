
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VChip from 'vuetify/lib/components/VChip/VChip';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VChip] });
export default base.extend({
    name: 'base-chip',
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VChip.options.computed.classes.call(this),
                'base-chip': true
            };
        }
    }
});
