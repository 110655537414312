import { CrmApiElement } from '@/models/base';

export interface SimplePhone extends CrmApiElement {
    number?: string | null;
    extension?: string | null;
    number_e164: string;
}

export class SimplePhoneDto implements SimplePhone {
    number_e164 = '';
}
