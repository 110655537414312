
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VNavigationDrawer from 'vuetify/lib/components/VNavigationDrawer/VNavigationDrawer';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VNavigationDrawer] });
export default base.extend({
    name: 'base-navigation-drawer',
    props: {
        app: {
            type: Boolean,
            default: true
        },
        // rest the navigation drawer under the application toolbar
        clipped: {
            type: Boolean,
            default: true
        },
        // make it collapsed
        miniVariant: {
            type: Boolean,
            default: true
        },
        // keep it visible no matter the size of the screen
        permanent: {
            type: Boolean,
            default: true
        },
        // This is needed to make permanent actually work when on a small screen size and scrolling happens
        stateless: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VNavigationDrawer.options.computed.classes.call(this),
                'base-navigation-drawer': true
            };
        }
    }
});
