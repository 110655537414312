











































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { LoadingStore } from '@/store/loading-store';
import { Class } from '@/organizations/locations/models/class';
import {
    ClassroomRateDto,
    LocationRates,
    LocationRatesDto
} from '@/enrollment-center/models/enrollment-center-location-models';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import { LocationRatesRepository } from '@/enrollment-center/repositories/location-rates-repository';
import { DataTableHeader } from 'vuetify';
import { EnrollmentCenterRateOption } from '@/enrollment-center/models/enrollment-center-models';
import { classSort } from '@/enrollment-center/location-utils';

const settingsState = getModule(EnrollmentCenterSettingsStore);
const loadingState = getModule(LoadingStore);
const classesRepo = new ClassesRepository();
const ratesRepo = new LocationRatesRepository();

interface RateUpdate {
    rateOption: EnrollmentCenterRateOption;
    updateDto: LocationRatesDto;
}

@Component
export default class LocationPricingTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;
    private loadingKey = 'locationTuition';
    private classes: Array<Class> = [];
    private locationRates: Array<LocationRates> = [];
    private locationRateUpdates: Array<RateUpdate> = [];

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    get rateOptions() {
        return settingsState.rateOptions;
    }

    get headers(): Array<DataTableHeader> {
        const first: Array<DataTableHeader> = [
            {
                text: 'Classroom',
                value: 'name',
                width: '30%'
            }
        ];
        return first.concat(this.rateOptions.map((rate) => {
            return {
                text: rate.label,
                value: String(rate.id),
                align: this.isEdit ? 'center' : 'start',
                width: String(Math.round(70 / this.rateOptions.length) - 1) + '%'
            };
        }));
    }

    @Watch('location', { deep: true, immediate: true })
    async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);
        const classesPromise = classesRepo.retrieveAll(this.location.id);
        const ratesPromise = ratesRepo.getLocationRates(this.location.id);
        this.classes = await classesPromise;
        this.locationRates = await ratesPromise;
        this.classes.sort(classSort);
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode')
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        }
        if (this.localMode === EditMode.EDITING) {
            this.locationRateUpdates = this.rateOptions.map((option) => {
                return {
                    rateOption: option,
                    updateDto: {
                        classrooms: this.classes.map((classroom) => {
                            return {
                                classroom: classroom.id,
                                value: this.getRate(classroom.id, option.id)
                            };
                        })
                    }
                };
            });
        }
        if (this.localMode === EditMode.SAVING) {
            for (const update of this.locationRateUpdates) {
                await ratesRepo.updateLocationRate(this.location.id, update.rateOption.id, update.updateDto);
            }
            this.localMode = EditMode.SAVED;
        }
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await settingsState.initRateOptions();
        loadingState.loadingDecrement(this.loadingKey);
    }

    getClassroomName(classroom: Class): string {
        return classroom.name + ' (' + classroom.begin_age + '-' + classroom.end_age + ')';
    }

    getRate(classId: number, rateId: number): number {
        const matchingRate = this.locationRates.filter((rate) => {
            return rate.rate_option.id === rateId;
        });
        if (matchingRate.length > 0) {
            const matchingClass = matchingRate[0].classrooms.filter((classRate) => {
                return classRate.classroom.id === classId;
            });
            if (matchingClass.length > 0) {
                return matchingClass[0].value ?? 0;
            }
        }
        return 0;
    }

    getRateObject(classId: number, rateId: number): ClassroomRateDto {
        return this.locationRateUpdates.filter((update) => {
            return update.rateOption.id === rateId;
        })[0].updateDto.classrooms.filter((classroom) => {
            return classroom.classroom === classId;
        })[0];
    }
}
