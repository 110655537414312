
import { BASE_DARK_BLUE } from '@/core/style-utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VBtn from 'vuetify/lib/components/VBtn/VBtn';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VBtn] });
export default base.extend({
    name: 'base-button-secondary',
    props: {
        elevation: {
            type: Number,
            default: 0
        },
        outlined: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: BASE_DARK_BLUE
        },
        'min-width': {
            type: Number,
            default: 64
        },
        'min-height': {
            type: Number,
            default: 36
        }
    },
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VBtn.options.computed.classes.call(this),
                'base-button': true,
                'base-button-action': true,
                'base-button-secondary': true
            };
        }
    }
});
