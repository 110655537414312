








































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { getAvatarBackgroundFromString, getAvatarInitialsFromName } from '@/core/avatar-utils';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import IconStackedButton from '@/components/base/IconStackedButton.vue';
import Loading from '@/components/Loading.vue';
import LineLeaderBaseButton from '@/components/base/LineLeaderBaseButton.vue';
import { BasicFamilySearchResult, BasicFamilySearchResultType } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { AppStateStore } from '@/store/app-state-store';
import { tokenize } from '@/families/search-families';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';

const appState = getModule(AppStateStore);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);
const featureState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: { IconStackedButton, Loading, LineLeaderBaseButton }
})
export default class UnifiedSearch extends Mixins(LocaleMixin) {
    private loadingKey = UnifiedSearch.name;
    private familyRepo = new FamiliesRepository();
    private isNothingFound = false;
    private isOpen = false;
    private results: Array<BasicFamilySearchResult> = [];
    private searchString = '';

    get isClassroomsEnabled(): boolean {
        return featureState.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    get isLineLeaderManage(): boolean {
        return featureState.isLineLeaderManage;
    }

    get hasWhiteLabel(): boolean {
        return interfaceSettingsStore.hasWhiteLabel;
    }

    private getAvatarColor(result: BasicFamilySearchResult): string {
        return getAvatarBackgroundFromString(this.getAvatarInitials(result));
    }

    private getAvatarInitials(result: BasicFamilySearchResult): string {
        switch (result.type) {
            case BasicFamilySearchResultType.LEAD:
                return getAvatarInitialsFromName(result.lead_first_name, result.lead_last_name);
            case BasicFamilySearchResultType.CHILD:
                return getAvatarInitialsFromName(result.child_first_name ?? result.lead_first_name, result.child_last_name ?? result.lead_last_name);
            case BasicFamilySearchResultType.CONTACT:
                return getAvatarInitialsFromName(result.contact_first_name ?? result.lead_first_name, result.contact_last_name ?? result.lead_last_name);
        }
    }

    private getFullName(result: BasicFamilySearchResult): string {
        switch (result.type) {
            case BasicFamilySearchResultType.CHILD:
                return result.child_full_name ?? result.lead_full_name;
            case BasicFamilySearchResultType.CONTACT:
                return result.contact_full_name ?? result.lead_full_name;
            case BasicFamilySearchResultType.LEAD:
                // fall through
            default:
                return result.lead_full_name;
        }
    }

    clearSearch() {
        this.results = [];
        this.searchString = '';
        this.isNothingFound = false;
    }

    async goToFamilyHub(result: BasicFamilySearchResult): Promise<void> {
        if (!result.lead_id) {
            return;
        }
        this.isOpen = false;
        await this.$router.push({ name: 'family-hub', params: { id: result.lead_id.toString() } });
    }

    async search() {
        this.isNothingFound = false;
        this.results = [];
        if (!this.searchString || this.searchString === '' || this.searchString.length < 3) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);

        // Remove all non-numeric characters if the searchString looks like a phone number
        let normalizedSearchString = this.searchString;
        if (this.searchString.match(/\d{3}[^\d]*\d{3}[^\d]*\d{4}/)) { // Rough regex check for phone number
            normalizedSearchString = this.searchString.replace(/\D/g, '');// Remove all non-digits
        }

        if (appState.currentOrg) {
            this.results = await this.familyRepo.basicSearch({
                queries: tokenize(normalizedSearchString),
                org_id: appState.currentOrg.id,
                skip_children: false
            });
        }

        loadingState.loadingDecrement(this.loadingKey);
        if (!this.results.length) {
            this.isNothingFound = true;
        }
    }
}
