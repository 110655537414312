






























import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { UpdatesRepository } from '@/families/repositories/updates-repository';
import Loading from '@/components/Loading.vue';
import { Update } from '@/families/models/update';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { ScrollLoaderMixin } from '@/core/scroll-loader-mixin';

const loadingState = getModule(LoadingStore);
const updatesRepository = new UpdatesRepository();

@Component({
    components: { Loading }
})
export default class HistoryExample extends Mixins(LocaleMixin, ScrollLoaderMixin) {
    private localHistoryItems: Array<Update> = [];
    private noMore = false;

    private async created() {
        await this.fetchHistory(30);
        this.checkBottom(this.fetchMore);
    }

    private async fetchHistory(count: number, showLoading = true) {
        if (showLoading) {
            loadingState.loadingIncrement('history');
        }

        const pagination = { limit: count, offset: this.localHistoryItems.length };
        const historyResponse = await updatesRepository.get(pagination, { since: '1' });
        if (historyResponse.entities.length === 0) {
            this.noMore = true;
        }
        this.localHistoryItems.push(...historyResponse.entities);
        if (showLoading) {
            loadingState.loadingStop('history');
        }
    }

    private async fetchMore() {
        if (!this.noMore) {
            await this.fetchHistory(10, false);
        }
    }
}
