import { render, staticRenderFns } from "./DashboardTasks.vue?vue&type=template&id=3fd02261&scoped=true&"
import script from "./DashboardTasks.vue?vue&type=script&lang=ts&"
export * from "./DashboardTasks.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardTasks.vue?vue&type=style&index=0&id=3fd02261&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd02261",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VCardTitle,VIcon,VSpacer,VTab,VTabItem,VTabsItems})
