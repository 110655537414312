



































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Org, OrgCreateDtoInterface, OrgCreatePostDto, OrgUpdateDtoInterface } from '@/models/organization/org';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import cloneDeep from 'lodash/cloneDeep';
import { EventTypes } from '@/constants/event-type-constants';
import isEqual from 'lodash/isEqual';
import { OrgLevelDto } from '@/organizations/levels/models/org-level-dto';
import { OrgLevelMapper } from '@/organizations/levels/mappers/org-level-mapper';
import { OrgsRepository } from '@/repositories/orgs-repository';
import { OrgsMapper } from '@/organizations/orgs-mapper';
import { OrgLevelsRepository } from '@/organizations/levels/repositories/org-levels-repository';
import { LevelTypes } from '@/organizations/levels/level-constants';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { OrgsUtil } from '@/organizations/orgs-util';
import { OrgsStore } from '@/store/orgs-store';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const orgLevelMapper = new OrgLevelMapper();
const orgsRepository = new OrgsRepository();
const orgsMapper = new OrgsMapper();
const levelsRepo = new OrgLevelsRepository();
const orgsUtil = new OrgsUtil();
const orgsStore = getModule(OrgsStore);

interface OrgCenterComparison {
    original: OrgCreateDtoInterface | OrgUpdateDtoInterface;
    current: OrgCreateDtoInterface | OrgUpdateDtoInterface;
}

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class ManageReportingLevel extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show it
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly level!: OrganizationLevel | null;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'manageReportingLevel';
    private levelDto = new OrgLevelDto();
    private showSnack = false;
    private snackText = '';
    private newLevelId = 0;
    private orgCenters: Array<OrgCenterComparison> = [];
    private orgCentersRemoved: Array<OrgCreateDtoInterface | OrgUpdateDtoInterface> = [];
    private dropdownOrgs: Array<Org> = [];
    private isSubsDisabled = true;
    private isDisabled = true;
    private validForm = false;
    private allOrgs: Array<Org> = [];

    @Watch('levelDto.name')
    private checkLevel() {
        this.isSubsDisabled = this.levelDto.name.length <= 0;
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get isAdd() {
        return this.level === null;
    }

    get noOrgs() {
        return this.orgCenters.length === 0;
    }

    @Watch('modelValue')
    async activationChanged(flag: boolean) {
        this.dropdownOrgs.splice(0);
        if (flag) {
            if (this.level) {
                loadingState.loadingIncrement(this.loadingKey);
                this.levelDto = orgLevelMapper.toUpdateDto(this.level);
                // Get all orgs to display in the pick list from the repo
                const orgsResults = orgsUtil.sortOrgsByName(orgsStore.stored);
                ;
            this.allOrgs = orgsResults;
            orgsResults.forEach((org) => {
                if (this.level && this.level.id && (org.level === this.level.id)) {
                    this.orgCenters.push(
                        {
                            original: orgsMapper.toUpdateDto(org),
                            current: orgsMapper.toUpdateDto(org)
                        });
                }
            });
            // Get all reporting levels
            // Assuming levels are provided in ascending order
            const levelsReponse = await levelsRepo.getAll();
            const levelsResults = levelsReponse.entities;

            const reportingToLevels: Array<number> = [];
            const levelOrders = new Map<number, number>();
            if (this.level.order && this.level.order !== 0) {
                for (let i = 0; i < levelsResults.length; i++) {
                    if (levelsResults[i].id !== LevelTypes.CENTER && levelsResults[i].order < this.level.order) {
                        reportingToLevels.push(levelsResults[i].id);
                        levelOrders.set(levelsResults[i].id, levelsResults[i].order);
                    }
                }
            }

            orgsResults.forEach((org) => {
                if (reportingToLevels.includes(org.level)) {
                    this.dropdownOrgs.push(org);
                }
            });

            // Sort dropdown array by alphabetical order
            this.dropdownOrgs.sort((a, b) => {
                const aOrder = levelOrders.get(a.level);
                const bOrder = levelOrders.get(b.level);
                if (aOrder === undefined || bOrder === undefined || aOrder === bOrder) {
                    return (a.name < b.name ? -1 : 1);
                }
                return aOrder < bOrder ? 1 : -1;
            });
            this.$nextTick(() => {
                // Validate form when we popup the modal.
                    this.form.validate();
            });
            loadingState.loadingDecrement(this.loadingKey);

        } else {
            loadingState.loadingIncrement(this.loadingKey);
            this.levelDto = new OrgLevelDto();

            const levelsReponse = await levelsRepo.getAll();
            const levelsResults: Array<OrganizationLevel> = levelsReponse.entities;

            const reportingToLevels: Array<number> = [];
            const levelOrders = new Map<number, number>();
            // dang, it'd be nice if level was a link...
            for (let i = 0; i < levelsResults.length; i++) {
                if (levelsResults[i].id !== LevelTypes.CENTER) {
                    this.levelDto.order = levelsResults[i].order + 1;
                    reportingToLevels.push(levelsResults[i].id);
                    levelOrders.set(levelsResults[i].id, levelsResults[i].order);
                }
            }

            // Get all orgs to display in the pick list from the store
            const orgsResults = orgsUtil.sortOrgsByName(orgsStore.stored); ;
            this.allOrgs = orgsResults;
            orgsResults.forEach((org) => {
                if (reportingToLevels.includes(org.level)) {
                    this.dropdownOrgs.push(org);
                }
            });

            // Sort dropdown array by alphabetical order
            this.dropdownOrgs.sort((a, b): number => {
                const aOrder = levelOrders.get(a.level);
                const bOrder = levelOrders.get(b.level);
                if (aOrder === undefined || bOrder === undefined || aOrder === bOrder) {
                    return (a.name < b.name ? -1 : 1);
                }
                return aOrder < bOrder ? 1 : -1;
            });

            // Add a blank org
            this.addOrgCenter();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }
}

// async created() {
//     loadingState.loadingIncrement(this.loadingKey);
//     loadingState.loadingDecrement(this.loadingKey);
// }

private handleClose() {
    this.orgCenters = [];
    this.orgCentersRemoved = [];
    this.dropdownOrgs = [];
    this.allOrgs = [];
    this.form.reset();
    this.modelValue = false;
}

private addOrgCenter() {
    const cur = new OrgCreatePostDto();
    if (this.dropdownOrgs.length > 0) { cur.parent_organization = this.dropdownOrgs[0].id; }
    this.orgCenters.push({ original: new OrgCreatePostDto(), current: cur });
}

private async removeOrgCenter(orgToRemove: OrgCreateDtoInterface | OrgUpdateDtoInterface) {
    if (this.level && this.level.id) {
        if (!orgToRemove.id) {
            // Removing a row that was never saved
            this.orgCenters = this.orgCenters.filter(org => org.current !== orgToRemove);
            return;
        }

        // Check if unit being deleted is currently reported to by others
        let dependent = false;
        for (let i = 0; i < this.allOrgs.length; i++) {
            if (this.allOrgs[i].parent_organization?.id === orgToRemove.id) {
                dependent = true;
                break;
            }
        }

        // Check if last unit is being deleted
        let count = 0;
        let last = false;
        this.orgCenters.forEach(element => {
            if (element.current.id) {
                count++;
            }
        });
        if (count === 1) { last = true; }

        if (dependent) {
            await this.$swal({
                icon: 'warning',
                text: 'WARNING! This entity has other entities (locations, etc.) that report to it. Please change their reporting first to delete this entity.'
            });
            return;
        }

        if (last) {
            const message = await this.$swal({
                text: 'WARNING!  You are deleting the last ' + this.level.name + ' which will delete this reporting level from your organization. Do you want to proceed? ',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (message.isConfirmed) {
                // Push the removed classes in a separate array
                this.orgCentersRemoved.push(orgToRemove);
                this.orgCenters = this.orgCenters.filter(org => org.current !== orgToRemove);
            }
        } else {
            // Push the removed classes in a separate array
            this.orgCentersRemoved.push(orgToRemove);
            this.orgCenters = this.orgCenters.filter(org => org.current !== orgToRemove);
        }
    } else {
        this.orgCenters = this.orgCenters.filter(org => org.current !== orgToRemove);
    }
}

private async submit() {
    if (this.level === null) {
        loadingState.loadingIncrement(this.loadingKey);
        const newLevel = await levelsRepo.post(this.levelDto);
        this.newLevelId = newLevel.id;
        for (const org of this.orgCenters) {
            const orgClone = cloneDeep(org.current);
            orgClone.level = this.newLevelId;
            if (orgClone.name.length >= 4) { orgClone.code = orgClone.name.substring(0, 4); } else { orgClone.code = orgClone.name; }
            await orgsRepository.post(orgClone as OrgCreatePostDto);
        }
        loadingState.loadingDecrement(this.loadingKey);

        this.modelValue = false;
        this.$emit(EventTypes.UPDATED);
        this.snackText = 'Reporting Level Added';
        this.showSnack = true;
    } else {
        loadingState.loadingIncrement(this.loadingKey);
        for (const org of this.orgCenters) {
            if (!isEqual(org.original, org.current)) {
                const orgClone = cloneDeep(org.current);
                if (orgClone.name.length >= 4) { orgClone.code = orgClone.name.substring(0, 4); } else { orgClone.code = orgClone.name; }
                // If the class existed by checking the id, update it otherwise create a new class
                if (orgClone.id) {
                    await orgsRepository.putOne(orgClone.id, orgClone as OrgUpdateDtoInterface);
                } else {
                    orgClone.level = this.level.id;
                    await orgsRepository.post(orgClone as OrgCreatePostDto);
                }
            }
        }
        // Completely delete the element from the removed array by calling the delete endpoint
        while (this.orgCentersRemoved.length > 0) {
            await orgsRepository.delete(this.orgCentersRemoved.pop()?.id!);
        }
        // Check if last unit is being deleted
        let count = 0;
        let last = false;
        this.orgCenters.forEach(element => {
            if (element.current.id) {
                count++;
            }
        });
        if (count === 0) {
            last = true;
        }
        if (last) {
            await levelsRepo.deleteBasic(this.level.id);
        } else {
            await levelsRepo.putOne(this.level.id, this.levelDto);
        }
        loadingState.loadingDecrement(this.loadingKey);
        if (last) {
            this.$emit(EventTypes.DELETED);
        } else {
            this.$emit(EventTypes.UPDATED);
        }
        this.snackText = 'Reporting Level ' + (last ? 'Deleted' : 'Saved');
        this.showSnack = true;
    }
    this.handleClose();
}
}
