var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"text-center px-0 main-table-area mr-0",attrs:{"width":"100%"}},[_c('v-expansion-panels',{attrs:{"flat":"","accordion":""},model:{value:(_vm.panelIndexSynced),callback:function ($$v) {_vm.panelIndexSynced=$$v},expression:"panelIndexSynced"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"table-title text-subtitle-2 my-3",attrs:{"hide-actions":""}},[_c('span',[_c('v-icon',{attrs:{"color":_vm.panelIndexSynced > -1 ? '' : 'green'}},[_vm._v(" "+_vm._s(_vm.panelIndexSynced > -1 ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)]),_c('v-expansion-panel-content',[(_vm.isCreated)?_c('v-row',[_c('v-col',{staticClass:"col-1"}),_c('v-col',{staticClass:"col-11 pa-0"},[_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],attrs:{"items":_vm.fields,"headers":_vm.headers,"item-key":"id","hide-default-footer":"","dense":"","items-per-page":-1,"disable-sort":"","show-expand":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLabel(item))+" ")]}},{key:"item.is_client_required",fn:function(ref){
var item = ref.item;
return [(!(_vm.fieldType === _vm.childFieldEntityType && item.value === _vm.reenrollmentFieldValue))?_c('base-checkbox',{attrs:{"disabled":item.is_system_required},on:{"click":function($event){item.is_hidden = false}},model:{value:(item.is_client_required),callback:function ($$v) {_vm.$set(item, "is_client_required", $$v)},expression:"item.is_client_required"}}):_vm._e()]}},{key:"item.is_hidden",fn:function(ref){
var item = ref.item;
return [(item.can_hide)?_c('base-checkbox',{attrs:{"disabled":item.is_client_required || item.is_system_required},model:{value:(item.is_hidden),callback:function ($$v) {_vm.$set(item, "is_hidden", $$v)},expression:"item.is_hidden"}}):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('td',[(_vm.canExpand(item))?_c('v-icon',{attrs:{"color":isExpanded ? '' : 'green'},on:{"click":function($event){$event.preventDefault();return expand(!isExpanded)}}},[_vm._v(" "+_vm._s(isExpanded? 'mdi-minus' : 'mdi-plus')+" ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('standard-fields-select-list',{attrs:{"field":item},on:_vm._d({},[_vm.updatedEvent,_vm.updateTypes])})],1)]}}],null,false,1594413430)})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }