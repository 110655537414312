import { CrmTypeOption } from '@/crm-types/models/crm-type';
import { IntegrationRoleMapping, IntegrationRoleMappingDto, IntegrationRoleMappingViewDto } from '@/integrations/models/position-roles';

/**
 * Mapper for the integration role mappings.
 */
export class IntegrationRoleMappingsMapper {
    /**
     * Create the DTO for use when setting the mapping.
     *
     * @param mapping
     */
    public static toIntegrationRoleMappingDto(mapping: IntegrationRoleMappingViewDto): IntegrationRoleMappingDto {
        return {
            identifier: mapping.identifier,
            position: mapping.positionId
        };
    }

    /**
     * Create the DTO for use when building the view of the mappings.
     *
     * @param mappings
     * @param positions
     */
    public static toViewMappings(mappings: Array<IntegrationRoleMapping>, positions: Array<CrmTypeOption>): Array<IntegrationRoleMappingViewDto> {
        const viewMappings: Array<IntegrationRoleMappingViewDto> = [];
        for (const position of positions) {
            const foundMapping = mappings.find((mapping) => mapping.position.id === position.id);
            viewMappings.push({
                identifier: foundMapping?.identifier ?? '',
                positionId: position.id,
                positionName: position.value
            });
        }
        return viewMappings;
    }
}
