import { Center } from '@/organizations/locations/models/center';
import { ApiPagination } from '@/repositories/abstract-repository';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { DripCampaign } from '@/automation/drip-campaigns/models/drip-campaign';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import { DripCampaignStatus } from '@/automation/drip-campaigns/drip-campaign-constants';
import Vue from 'vue';

interface StatusesState {
    [key: number]: Array<CrmTypeOption>;
}

export interface OrgDripCampaignsMapping {
    orgId: number;
    dripCampaigns: Array<DripCampaign>;
}

export interface DripCampaignsState extends AbstractEntityState<DripCampaign> {
    entities: Array<DripCampaign>;
    dripStatuses: StatusesState;
    /** The key is a Center's organization id */
    orgDripCampaigns: Map<number, Array<DripCampaign>>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.DRIP_CAMPAIGNS
})
export class DripCampaignsStore extends AbstractApiStore<DripCampaign> implements DripCampaignsState {
    readonly repository = new DripCampaignsRepository();
    dripStatuses: StatusesState = {};
    orgDripCampaigns: Map<number, Array<DripCampaign>> = new Map();

    @Mutation
    public storeStatuses(params: { from: DripCampaignStatus; options: Array<CrmTypeOption> }) {
        Vue.set(this.dripStatuses, params.from, params.options);
    }

    @Action
    public async initStatuses(from: DripCampaignStatus) {
        if (!this.dripStatuses[from]) {
            await this.initPromise({
                hash: 'statuses-' + from,
                closure: async () => {
                    await this.retrieveStatuses(from);
                }
            });
        }
    }

    @Action
    public async retrieveStatuses(from: DripCampaignStatus) {
        const listOptions = await this.repository.getStatuses(from);
        this.context.commit('storeStatuses', { from: from, options: listOptions });
    }

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    @Action
    public async initForCenter(center: Center | undefined) {
        if (!center) {
            await this.init();
            return;
        }
        if (!this.orgDripCampaigns.has(center!.organization_id)) {
            await this.initPromise({
                hash: 'drip-campaigns-org-' + String(center!.organization_id),
                closure: async () => {
                    await this.retrieveOrgDripCampaigns(center!.organization_id);
                }
            });
        }
    }

    @Action({ commit: 'storeOrgDripCampaigns' })
    public async retrieveOrgDripCampaigns(orgId: number) {
        const limit = 100;
        const params = { org_id: orgId };
        let apiResponse = await this.repository.get(null, params);
        let entities = apiResponse.entities;
        const count = apiResponse.count;

        if (count > 100) {
            for (let i = 100; i < count; i += limit) {
                apiResponse = await this.repository.get({ limit: 100, offset: i } as ApiPagination, params);
                entities = entities.concat(apiResponse.entities);
            }
        }

        return { orgId: orgId, dripCampaigns: entities } as OrgDripCampaignsMapping;
    }

    @Mutation
    public storeOrgDripCampaigns(mapping: OrgDripCampaignsMapping): void {
        this.orgDripCampaigns.set(mapping.orgId, mapping.dripCampaigns);
    }

    /**
     * clear org map to make sure values are refreshed
     */
    @Mutation
    public clearOrgDripCampaigns(): void {
        this.orgDripCampaigns.clear();
    }

    get statusOptions() {
        return (from: DripCampaignStatus) => {
            return this.dripStatuses[from] ?? [];
        };
    }

    public get stored(): Array<DripCampaign> {
        return this.entities;
    }

    /**
     * Get all of the drip campaigns for the given center.
     * If not center is provided, return all drip campaigns.
     * This is needed for the drip campaigns that are created at the center level for franchises.
     */
    public get storedForCenter() {
        return (center: Center | undefined): Array<DripCampaign> => {
            if (!center || !this.orgDripCampaigns.has(center.organization_id)) {
                return this.entities;
            }
            return this.orgDripCampaigns.get(center.organization_id) as Array<DripCampaign>;
        };
    }
}
