import { CenterCallSettings, CenterCallSettingsDto } from '@/organizations/locations/models/center-call-settings';

/**
 * Mapper for the center call settings used for telephony.
 */
export class CenterCallSettingsMapper {
    toCreateDto(entity: CenterCallSettings): CenterCallSettingsDto {
        return {
            is_call_forwarding_enabled: entity.is_call_forwarding_enabled ?? true,
            is_system_phone_variable: entity.is_system_phone_variable ?? true,
            voicemail_message: entity.voicemail_message ?? 'Please leave a message.'
        };
    }

    toUpdateDto(entity: CenterCallSettings): CenterCallSettingsDto {
        return this.toCreateDto(entity);
    }
}
