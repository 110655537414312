import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { CustomFieldValue, CustomFieldValueCreateDto, CustomFieldValueUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';

export default class CustomFieldValuesMapper extends AbstractMapper<CustomFieldValue> {
    toCreateDto(entity: CustomFieldValue): CustomFieldValueCreateDto {
        return {
            is_default: entity.is_default,
            is_editable: entity.is_editable,
            value: entity.value,
            order: entity.order
        };
    }

    toUpdateDto(entity: CustomFieldValue): CustomFieldValueUpdateDto {
        const dto = this.toCreateDto(entity);
        dto.id = entity.id;
        return dto as CustomFieldValueUpdateDto;
    }
}
