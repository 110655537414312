import { AbstractRepository } from '@/repositories/abstract-repository';
import { WorkflowTypesList } from '@/automation/workflows/constants/workflow-types';
import { WorkflowField, WorkflowType } from '@/automation/workflows/models/workflow-type-models';

export class WorkflowTypesRepository extends AbstractRepository<WorkflowType> {
    readonly endpoint = 'settings/automation/types';

    public async getEntities(): Promise<Array<WorkflowType>> {
        const response = await this.client.get<Array<WorkflowType>>(this.endpoint + '/' + WorkflowTypesList.ENTITIES);
        return response.data;
    }

    public async getFieldsForEntity(entityId: number): Promise<Array<WorkflowField>> {
        const response = await this.client.get<Array<WorkflowField>>(`${this.endpoint}/${WorkflowTypesList.ENTITIES}/${entityId}/fields`);
        const data: WorkflowField[] = [];
        for (const datum of response.data) {
            if (datum.name === 'Child Status') {
                datum.name = 'Status';
            }
            if (datum.name === 'Event Type') {
                datum.name = 'Type';
            }
            data.push(datum);
        }
        return data;
    }

    public async getValuesForField(entityId: number, fieldId: number): Promise<Array<WorkflowType>> {
        const response = await this.client.get<Array<WorkflowType>>(`${this.endpoint}/${WorkflowTypesList.ENTITIES}/${entityId}/fields/${fieldId}/values`);
        return response.data;
    }

    public async getActions(): Promise<Array<WorkflowType>> {
        const response = await this.client.get<Array<WorkflowType>>(this.endpoint + '/' + WorkflowTypesList.ACTIONS);
        return response.data;
    }

    public async getStopTriggerFields(): Promise<Array<WorkflowField>> {
        const response = await this.client.get<Array<WorkflowField>>(`${this.endpoint}/${WorkflowTypesList.STOP_TRIGGERS}/fields`);
        return response.data;
    }
}
