import { EmailAttachment, EmailAttachmentCreateDto } from '@/communications/templates/models/email-attachment';
import { AbstractRepository } from '@/repositories/abstract-repository';

export class EmailAttachmentsRepository extends AbstractRepository<EmailAttachment> {
    protected endpoint = 'communications/emails/attachments';

    /**
     * Get the actual file contents for an attachment
     * @param entity
     */
    public async getFile(entity: EmailAttachment): Promise<string> {
        return (await this.client.get(`${this.endpoint}/${entity.id}/file`, { responseType: 'blob' })).data;
    }

    public async getFileById(id: number): Promise<string> {
        return (await this.client.get(`${this.endpoint}/${id}/file`, { responseType: 'blob' })).data;
    }

    public async getFileMetaDataById(id: number): Promise<EmailAttachment> {
        return (await this.client.get(`${this.endpoint}/${id}`)).data;
    }

    public async create(dto: EmailAttachmentCreateDto): Promise<EmailAttachment> {
        const response = await this.client.post(this.endpoint, dto);
        return response.data;
    }

    public async delete(entity: EmailAttachment): Promise<void> {
        await this.client.delete(`${this.endpoint}/${entity.id}`);
    }
}
