




































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { CenterHours } from '../models/center';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';

const featureState = getModule(FeaturesStore);

@Component
export default class ManageHoursRange extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) prefix!: string;
    @Prop({ required: true }) label!: string;
    @Prop() readonly value!: CenterHours;

    get hours(): CenterHours {
        return this.value;
    }

    set hours(hours: CenterHours) {
        this.$emit('input', hours);
    }

    get isLineLeaderEnroll() {
        return featureState.isLineLeaderEnroll;
    }

    get openField() {
        return this.prefix + '_open';
    }

    get closeField() {
        return this.prefix + '_close';
    }

    get openValue() {
        return (this.hours as any)[this.openField];
    }

    get closeValue() {
        return (this.hours as any)[this.closeField];
    }

    get rules() {
        return [
            !this.openValue || !this.closeValue || this.openValue < this.closeValue || 'Open/start must be before close/end',
            !!this.hours.mon_open ||
            !!this.hours.tue_open ||
            !!this.hours.wed_open ||
            !!this.hours.thu_open ||
            !!this.hours.fri_open ||
            !!this.hours.sat_open ||
            !!this.hours.sun_open ||
            'At least one day must have hours'
        ];
    }
}
