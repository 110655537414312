
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VDataTable from 'vuetify/lib/components/VDataTable/VDataTable';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VDataTable] });
export default base.extend({
    name: 'base-data-table',
    props: {
        footerProps: {
            type: Object,
            default: () => ({
                'items-per-page-options': [10, 25, 50, 100]
            }) as Record<string, any>
        },
        itemsPerPage: {
            type: Number,
            default: 25
        },
        noDataText: {
            type: String,
            default: 'No results'
        }
    }
});
// Number columns can be right aligned by setting align: 'end' for the DataTableHeader entry.
// Columns can be set to not sortable by setting sortable: false for the DataTableHeader entry.
// Columns can be set to not filterable by setting filterable: false for the DataTableHeader entry.
// To add the search box to a table, add a v-text-field with v-model equal to the same property
// set to :search on the table
