






















import { DashboardGaugeMixin } from '@/dashboards/dashboard-gauge-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { DashboardStore } from '@/store/dashboard-store';
import { AppStateStore } from '@/store/app-state-store';
import Highcharts from 'highcharts';
import {
    getGaugeChartOptions,
    getGaugeStopColors,
    getSolidGaugeSeriesOptions
} from '@/charts/chart-utils';
import { LoadingStore } from '@/store/loading-store';
import { getTasksPastDuePct } from '@/dashboards/corp-dashboard-stats';
import { getPreviousDateFilter } from '@/date-time/date-filter-utils';
import GaugeComparison from '@/dashboards/components/GaugeComparison.vue';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import cloneDeep from 'lodash/cloneDeep';

const dashboardState = getModule(DashboardStore);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);

@Component({
    components: { GaugeComparison }
})
export default class TasksPastDueGaugeGraph extends Mixins(DashboardGaugeMixin, LocaleMixin) {
    @Prop({ type: String }) readonly graphId!: undefined;

    private isMounted = false;
    private loadingKey = 'tasksPastDueGauge';
    private diffPct = 0;
    private stops = [0.11, 0.22, 0.49, 1.00];

    chartOptions: Highcharts.Options = Highcharts.merge(cloneDeep(getGaugeChartOptions()), {
        spacingRight: 0,
        spacingLeft: 0,
        yAxis: {
            stops: getGaugeStopColors(this.stops, true)
        },
        exporting: {
            chartOptions: {
                title: {
                    text: 'Tasks That Are Past Due'
                }
            }
        }
    });

    get timezone() {
        return authState.userInfoObject ? authState.userInfoObject.timezone : 'UTC';
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get dateRange() {
        return dashboardState.storedDateFilter;
    }

    async created() {
        await this.updateChart();
    }

    mounted() {
        this.isMounted = true;
    }

    @Watch('org', { deep: true })
    async orgChanged() {
        await this.updateChart();
    }

    @Watch('dateRange', { deep: true })
    async dateRangeChanged() {
        await this.updateChart();
    }

    private async updateChart() {
        if (!this.org) {
            return;
        }
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKey);

        const org = this.org.id;
        let mainPct = 0;
        let prevPct = 0;

        getTasksPastDuePct(this.dateRange, org, this.timezone).then((value) => {
            mainPct = value;
            this.diffPct = mainPct - prevPct;
            this.chartOptions.series = [getSolidGaugeSeriesOptions('Percentage of tasks that are past due', mainPct)];
            loadingState.loadingDecrement(this.loadingKey);
            this.$emit('updated', mainPct);
            (this.$refs.chart as any).chart.reflow();
        });

        getTasksPastDuePct(getPreviousDateFilter(this.dateRange), org, this.timezone).then((value) => {
            prevPct = value;
            this.diffPct = mainPct - prevPct;
            loadingState.loadingDecrement(this.loadingKey);
            (this.$refs.chart as any).chart.reflow();
        });
    }
}
