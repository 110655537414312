















































































































































































































































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import { FeaturesStore } from '@/features/features-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CrmTypeList, CustomTypeIdentifiers } from '@/crm-types/models/crm-type';
import {
    FamiliesFilter,
    FamiliesFilterDto,
    FamiliesFilterDtoInterface,
    FilterIncludeTypes
} from '@/filters/models/families-filter';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { FamiliesFilterMapper } from '@/filters/mappers/families-filter-mapper';
import { Org } from '@/models/organization/org';
import { AppStateStore } from '@/store/app-state-store';
import FilterDateRange from '@/families/components/FilterDateRange.vue';
import FilterMultiSelect from '@/families/components/FilterMultiSelect.vue';
import FilterTextChildAge from '@/families/components/FilterTextChildAge.vue';
import { MarketingCampaignsStore } from '@/marketing/stores/marketing-campaigns-store';
import FiltersTable from '@/filters/components/FiltersTable.vue';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import { Class } from '@/organizations/locations/models/class';
import { Center } from '@/organizations/locations/models/center';
import { VForm } from '@/types/types';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import { CustomFieldWithOption, CustomFieldsTypes } from '@/crm-types/custom-fields/custom-fields-types';
import { FamiliesFilterRepository } from '@/filters/repositories/families-filter-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const statusesStore = getModule(StatusesStore);
const featuresStore = getModule(FeaturesStore);
const crmTypesStore = getModule(CrmTypesStore);
const familiesFilterStore = getModule(FamiliesFilterStore);
const filtersRepo = new FamiliesFilterRepository();
const filterMapper = new FamiliesFilterMapper();
const appState = getModule(AppStateStore);
const marketingCampaignsStore = getModule(MarketingCampaignsStore);
const classesRepo = new ClassesRepository();
const crmTypesRepo = new CrmTypesRepository();

@Component({
    components: {
        BaseClose,
        FiltersTable,
        FilterMultiSelect,
        FilterDateRange,
        FilterTextChildAge
    }
})
export default class FamilyFilterModalDrawer extends Mixins(LocaleMixin) {
    // v-model stuff
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    private checkAllStatusesCheckbox = false;
    private classrooms: Array<Class> = [];
    private currentFilter: FamiliesFilterDtoInterface = new FamiliesFilterDto();
    private customChildFields: Array<CustomFieldWithOption> = [];
    private customFields: Array<CustomFieldWithOption> = [];
    private customGuardianFields: Array<CustomFieldWithOption> = [];
    private disabledChildAgeRange = false;
    private disabledChildBirthDate = false;
    private inputEvent = EventTypes.INPUT;
    private includeTypes = FilterIncludeTypes;
    private inUseArray: Array<number> = [];
    private isSingleSelect = CustomFieldsTypes.SINGLE_SELECT;
    private listTypes = CrmTypeList;
    private loadedEvent = EventTypes.LOADED;
    private loadingKey = 'familyFilterModalDrawer';
    private marketingCampaigns: Array<MarketingCampaign> = [];
    private saveDialog = false;
    private saveLoadingKey = 'saveFilterLoading';
    private saveName = '';
    private statuses: Array<Status> = [];
    private tableOpen = false;
    private typeIdentifiers = CustomTypeIdentifiers;
    private validForm = false;
    private validSave = false;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get currentStoreFilter(): FamiliesFilter | null {
        return familiesFilterStore.currentFilter;
    }

    get isCustom() {
        return familiesFilterStore.isCustom;
    }

    get currentStatuses() {
        return this.currentFilter.statuses;
    }

    get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    get currentCenter(): Center | null {
        return appState.storedCurrentCenter;
    }

    get areLocationGroupsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    get isCorp(): boolean {
        return this.org?.id === 1;
    }

    get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    get isMarketingCampaignsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    get isLegacyLeadTypes12Enabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_12);
    }

    get isLegacyLeadTypes34Enabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_34);
    }

    get isLegacyChildTypes12Enabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12);
    }

    get isLegacyChildTypes34Enabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34);
    }

    get canUpdate() {
        return this.isCustom && this.currentStoreFilter && !this.currentStoreFilter.is_anonymous && this.org && (this.org.id === this.currentStoreFilter.organization.id || this.org.id === 1);
    }

    get saveNameRules() {
        return [
            !!this.saveName || 'Please enter a name'
        ];
    }

    @Watch('currentCenter', {
        deep: true,
        immediate: true
    })
    public async locationChanged() {
        if (this.isClassroomsFeatureEnabled && this.currentCenter) {
            loadingState.loadingIncrement(this.loadingKey);
            this.classrooms = await classesRepo.retrieveAll(this.currentCenter.id);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('org')
    public async orgChanged() {
        if (this.org) {
            this.currentFilter.organization = this.org.id;
            if (this.isMarketingCampaignsEnabled) {
                await marketingCampaignsStore.initForOrgIdIncludeClosed(this.org.id);
                this.marketingCampaigns = marketingCampaignsStore.storedOrgCampaignsIncludingClosed(this.org.id);
            }
        }
    }

    @Watch('currentStoreFilter')
    public storedFilterChanged() {
        this.syncFilters();
    }

    public syncFilters() {
        if (this.currentStoreFilter && familiesFilterStore.isCustom) {
            this.currentFilter = filterMapper.toCreateDto(this.currentStoreFilter);
            if (this.currentStoreFilter.guardian_custom_values) {
                filterMapper.mapCustomValues(this.customGuardianFields, this.currentStoreFilter.guardian_custom_values, this.currentFilter.guardian_custom_values);
            }
            if (this.currentStoreFilter.child_custom_values) {
                filterMapper.mapCustomValues(this.customChildFields, this.currentStoreFilter.child_custom_values, this.currentFilter.child_custom_values);
            }
        } else {
            this.currentFilter = new FamiliesFilterDto();
        }
        if (this.org) {
            this.currentFilter.organization = this.org.id;
        }
    }

    private updateDisabledForChildAgeRange(value: boolean) {
        this.disabledChildAgeRange = value;
    }

    private updateDisabledForChildBirthDate(value: boolean) {
        this.disabledChildBirthDate = value;
    }

    @Watch('modelValue')
    public opened() {
        if (this.modelValue) {
            this.syncFilters();
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const statusInit = statusesStore.init();
        const featuresInit = featuresStore.init();
        await statusInit;
        await featuresInit;
        this.statuses = statusesStore.statuses;
        if (this.isCrmPlus) {
            const inits: Array<Promise<void>> = [];
            let setCampaigns = false;
            inits.push(crmTypesStore.initLabels());
            const typesNeeded = [
                CrmTypeList.TASKS,
                CrmTypeList.TASK_RESULTS,
                CrmTypeList.REASONS_LOST_OPP,
                CrmTypeList.CLASSROOM_GROUPS,
                CrmTypeList.LOCATION_GROUPS,
                CrmTypeList.LEAD_TYPE_3,
                CrmTypeList.LEAD_TYPE_4,
                CrmTypeList.CHILD_TYPE_1,
                CrmTypeList.CHILD_TYPE_2,
                CrmTypeList.CHILD_TYPE_3,
                CrmTypeList.CHILD_TYPE_4,
                CrmTypeList.MONTHS,
                CrmTypeList.FAMILY_INQUIRY,
                CrmTypeList.FAMILY_SOURCE,
                CrmTypeList.GENDER,
                CrmTypeList.REASON_WITHDRAWN,
                CrmTypeList.ENROLLED_REASON,
                CrmTypeList.REASON_TEMPORARY_LEAVE,
                CrmTypeList.WAIT_LIST_TYPE,
                CrmTypeList.WAIT_LIST_PRIORITY,
                CrmTypeList.REASON_WAIT_LIST
            ];
            this.customFields = await crmTypesRepo.getCustomFields();
            await this.getCustomFieldOptions();

            if (this.isLegacyLeadTypes12Enabled) {
                typesNeeded.push(CrmTypeList.LEAD_TYPE_1);
                typesNeeded.push(CrmTypeList.LEAD_TYPE_2);
            }

            if (this.isLegacyLeadTypes34Enabled) {
                typesNeeded.push(CrmTypeList.LEAD_TYPE_3);
                typesNeeded.push(CrmTypeList.LEAD_TYPE_4);
            }

            if (this.isLegacyChildTypes12Enabled) {
                typesNeeded.push(CrmTypeList.CHILD_TYPE_1);
                typesNeeded.push(CrmTypeList.CHILD_TYPE_2);
            }

            if (this.isLegacyChildTypes34Enabled) {
                typesNeeded.push(CrmTypeList.CHILD_TYPE_3);
                typesNeeded.push(CrmTypeList.CHILD_TYPE_4);
            }

            for (const list of typesNeeded) {
                inits.push(crmTypesStore.retrieveList(list));
            }

            if (this.isMarketingCampaignsEnabled) {
                if (this.org) {
                    setCampaigns = true;
                    inits.push(marketingCampaignsStore.initForOrgIdIncludeClosed(this.org.id));
                }
            }

            await Promise.all(inits);

            if (setCampaigns && this.org) {
                this.marketingCampaigns = marketingCampaignsStore.storedOrgCampaignsIncludingClosed(this.org.id);
            }

            if (this.isClassroomsFeatureEnabled && this.currentCenter) {
                this.classrooms = await classesRepo.retrieveAll(this.currentCenter.id);
            }
        }
        this.syncFilters();
        loadingState.loadingDecrement(this.loadingKey);
    }

    public hasOptions(list: CrmTypeList): boolean {
        return this.listOptions(list).length > 0;
    }

    @Watch('currentStatuses', { immediate: true })
    public uncheckCheckAllStatusesCheckbox() {
        this.checkAllStatusesCheckbox = this.currentFilter.statuses.length === this.statuses.length;
        if (this.currentFilter.statuses.length !== 1) {
            this.currentFilter.status_date_start = null;
            this.currentFilter.status_date_end = null;
        }
    }

    private listOptions(type: CrmTypeList) {
        return crmTypesStore.listOptions(type);
    }

    private customLabel(identifier: string) {
        return crmTypesStore.customLabel(identifier);
    }

    private async getCustomFieldOptions() {
        // filter out 'text' type options and staff only fields
        this.customFields = this.customFields.filter(item => item.type.id !== 3 && !item.is_for_staff);
        for (const item of this.customFields) {
            item.options = await crmTypesRepo.getCustomFieldOptions(item.id);
        }
        this.customChildFields = this.customFields.filter(item => item.is_for_child);
        this.customGuardianFields = this.customFields.filter(item => !item.is_for_child);
    }

    public checkAllFamilyStatusFilters(value: any) {
        // Clear out the checkboxes.
        this.currentFilter.statuses = [];

        if (value) {
            // Check all the checkboxes if true.
            this.statuses.forEach((status) => {
                this.currentFilter.statuses.push(status.id);
            });
        }
    }

    private flattenCustomArray() {
        // flatten custom array values and remove null values for API ingestion
        this.currentFilter.guardian_custom_values = this.currentFilter.guardian_custom_values.filter(item => item).flat();
        this.currentFilter.child_custom_values = this.currentFilter.child_custom_values.filter(item => item).flat();
    }

    private async applyFilters() {
        this.flattenCustomArray();
        familiesFilterStore.setIsCustom(true);
        loadingState.loadingIncrement(this.loadingKey);
        this.currentFilter.age_in_months_start = this.currentFilter.age_in_months_start?.toString() === '' ? null : this.currentFilter.age_in_months_start;
        this.currentFilter.age_in_months_end = this.currentFilter.age_in_months_end?.toString() === '' ? null : this.currentFilter.age_in_months_end;
        await familiesFilterStore.applyAnonymousFilter(this.currentFilter);
        loadingState.loadingDecrement(this.loadingKey);
        this.handleClose();
    }

    private async clearFilter() {
        if (this.currentStoreFilter || familiesFilterStore.isCustom) {
            familiesFilterStore.setIsCustom(false);
            loadingState.loadingIncrement(this.loadingKey);
            const cleanFilter = new FamiliesFilterDto();
            this.statuses.forEach((status) => {
                cleanFilter.statuses.push(status.id);
            });
            await familiesFilterStore.applyAnonymousFilter(cleanFilter);
            loadingState.loadingDecrement(this.loadingKey);
        }

        this.form.reset();
        this.handleClose();
    }

    private async saveFilter() {
        if (!this.canUpdate && this.org) {
            const nameCheck = await filtersRepo.getFilterByName(this.org.id, this.saveName);
            if (nameCheck) {
                await this.$swal({
                    icon: 'warning',
                    text: 'Please choose a unique name'
                });
                return;
            }
        }
        if (this.canUpdate && this.currentStoreFilter && this.saveName === this.currentStoreFilter.name) {
            const response = await this.$swal({
                icon: 'warning',
                text: 'Confirm you want to override the existing saved filters',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Confirm'
            });
            if (response.isConfirmed) {
                this.closeSave();
                await this.updateFilter();
            }
            return;
        }

        familiesFilterStore.setIsCustom(true);
        loadingState.loadingIncrement(this.saveLoadingKey);
        this.currentFilter.name = this.saveName;
        this.currentFilter.is_anonymous = false;
        await familiesFilterStore.addFilter(this.currentFilter);
        loadingState.loadingDecrement(this.saveLoadingKey);
        this.handleClose();
    }

    private async updateFilter() {
        if (this.currentStoreFilter) {
            familiesFilterStore.setIsCustom(true);
            loadingState.loadingIncrement(this.loadingKey);
            // if we're updating a filter, make sure we keep the original org
            // used to not matter since you couldn't save outside the org level, but now
            // corp users can
            this.currentFilter.organization = this.currentStoreFilter.organization.id;
            await familiesFilterStore.updateFilter({
                filterId: this.currentStoreFilter.id,
                dto: this.currentFilter
            });
            loadingState.loadingDecrement(this.loadingKey);
        }
        this.handleClose();
    }

    private handleClose() {
        this.modelValue = false;
    }

    private loaded() {
        this.syncFilters();
        this.handleClose();
    }

    private openTable() {
        this.tableOpen = true;
    }

    private openSave() {
        this.saveName = '';
        if (this.canUpdate && this.currentStoreFilter) {
            this.saveName = this.currentStoreFilter.name;
        }

        this.currentFilter.age_in_months_start = this.currentFilter.age_in_months_start?.toString() === '' ? null : this.currentFilter.age_in_months_start;
        this.currentFilter.age_in_months_end = this.currentFilter.age_in_months_end?.toString() === '' ? null : this.currentFilter.age_in_months_end;

        this.saveDialog = true;
    }

    private closeSave() {
        this.saveDialog = false;
    }
}
