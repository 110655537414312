import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    TemplateVariable,
    TemplateVariableCreateDto,
    TemplateVariableUpdateDto
} from '@/communications/templates/models/template-variable';

export class TemplateVariablesRepository extends AbstractRepository<TemplateVariable> {
    readonly endpoint = 'communications/templates/variables';

    public async createVariable(createDto: TemplateVariableCreateDto): Promise<TemplateVariable> {
        const response = await this.client.post(this.endpoint, createDto);
        return response.data;
    }

    public async updateVariable(id: number, updateDto: TemplateVariableUpdateDto): Promise<TemplateVariable> {
        const response = await this.client.put(this.endpoint + '/' + id, updateDto);
        return response.data;
    }
}
