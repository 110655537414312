import { AbstractRepository } from '@/repositories/abstract-repository';
import { ReportSchedule, ReportScheduleDtoInterface } from '@/reports/models/report-schedule';

export class ReportSchedulesRepository extends AbstractRepository<ReportSchedule> {
    readonly endpoint = 'staff';

    public async getReportSchedules(userId: number, reportId: number): Promise<Array<ReportSchedule>> {
        const response = await this.client.get(this.endpoint + `/${userId}/reports/${reportId}/schedules`);
        return response.data;
    }

    public async getOneReportSchedule(userId: number, reportId: number, scheduleId: number): Promise<ReportSchedule> {
        const response = await this.client.get(this.endpoint + `/${userId}/reports/${reportId}/schedules/${scheduleId}`);
        return response.data;
    }

    public async createReportSchedule(userId: number, reportId: number, scheduleDto: ReportScheduleDtoInterface): Promise<ReportSchedule> {
        const response = await this.client.post(this.endpoint + `/${userId}/reports/${reportId}/schedules`, scheduleDto);
        return response.data;
    }

    public async updateReportSchedule(userId: number, reportId: number, scheduleId: number, scheduleDto: ReportScheduleDtoInterface): Promise<ReportSchedule> {
        const response = await this.client.put(this.endpoint + `/${userId}/reports/${reportId}/schedules/${scheduleId}`, scheduleDto);
        return response.data;
    }

    public async deleteReportSchedule(userId: number, reportId: number, scheduleId: number): Promise<void> {
        await this.client.delete(this.endpoint + `/${userId}/reports/${reportId}/schedules/${scheduleId}`);
    }
}
