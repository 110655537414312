














































































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { MessageDirection, ThreadMeta } from '@/communications/messages/models/message';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import InboxLine from '@/communications/messages/components/InboxLine.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { StaffLink } from '@/staff/models/user';
import { getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import { DataTableOptions } from '@/models/datatables';
import { AppStateStore } from '@/store/app-state-store';
import { AnyStoreUtils } from '@/utils/any-store-utils';

const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore);
const anyStoreUtils = new AnyStoreUtils();

@Component({
    components: { InboxLine }
})
export default class InboxTable extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly threads!: Array<ThreadMeta>;
    @Prop({ required: true }) readonly type!: 'Texts' | 'Emails' | 'Facebook Messenger';
    @Prop({ required: true, default: false }) showOnlyMyMessages!: boolean;
    @Prop({ required: true }) readonly count!: number;

    @PropSync('archiveMode') isArchiveMode!: number;
    @PropSync('tableOptions') options!: DataTableOptions;
    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;

    private searchString: string | null = null;
    private selected: Array<ThreadMeta> = [];
    private shown: Array<ThreadMeta> = [];
    // Note: Do not make the totals equal 100%. It causes the checkboxes to be weird in some browsers
    private headers: Array<DataTableHeader> = [
        {
            text: 'from',
            value: 'from',
            width: '20%'
        },
        {
            text: 'body',
            value: 'body',
            width: '40%'
        },
        {
            text: '',
            value: 'staff',
            width: '16%'
        },
        {
            text: '',
            value: 'date',
            width: '13%'
        },
        {
            text: '',
            value: 'replied',
            width: '3%'
        },
        {
            text: '',
            value: 'action',
            width: '5%'
        }
    ];

    private get ourThreads() {
        return this.threads;
    }

    private get noDataTypeText() {
        switch (this.type) {
            case 'Texts':
                return 'No text messages';
            case 'Emails':
                return 'No email messages';
            case 'Facebook Messenger':
                return 'No Facebook messages';
            default:
                return 'No results';
        }
    }

    private get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    private get selectedAndShown() {
        const selectedSet = new Set(this.selected);
        return [...new Set(this.shown)].filter((meta) => selectedSet.has(meta));
    }

    get inboxSearchString() {
        return anyStoreUtils.storedValue('inboxSearchString');
    }

    get onlyShowMyMessages() {
        return this.showOnlyMyMessages;
    }

    set onlyShowMyMessages(flag: boolean) {
        if (this.type === 'Texts') {
            appState.setOnlyShowMyTexts(flag);
        } else if (this.type === 'Emails') {
            appState.setOnlyShowMyEmails(flag);
        } else {
            appState.setOnlyShowMyFacebookMessages(flag);
        }
    }

    getRowClass(item: ThreadMeta): string {
        return !item.is_read ? 'font-weight-bold' : '';
    }

    emitFilterOnlyCurrenUser() {
        if (this.searchString == null || this.searchString.length === 0) {
            this.$emit(EventTypes.INBOX_SEARCH_CLEARED);
        } else {
            this.$emit(EventTypes.INBOX_SEARCH, this.searchString);
        }
    }

    @Watch('isArchiveMode')
    modeChanged() {
        this.selected = [];
        this.options.page = 1;
    }

    @Watch('inboxSearchString', { immediate: true })
    setSearchString() {
        this.searchString = this.inboxSearchString;
    }

    private clearSearch() {
        this.searchString = null;
        anyStoreUtils.setStoreValue({ key: 'inboxSearchString', val: this.searchString });
        this.$emit(EventTypes.INBOX_SEARCH_CLEARED);
    }

    private emitSearch() {
        if (!this.searchString) {
            this.$emit(EventTypes.INBOX_SEARCH_CLEARED);
            return;
        }
        if (this.searchString.length < 3) {
            // Do not search unless we have at least 3 characters
            return;
        }
        anyStoreUtils.setStoreValue({ key: 'inboxSearchString', val: this.searchString });
        // Threads are generated in a parent component
        this.$emit(EventTypes.INBOX_SEARCH, this.searchString);
    }

    private isIncoming(type: string): boolean {
        return type === MessageDirection.INCOMING;
    }

    private formatStaffDisplay(staff: StaffLink | null): string {
        if (!staff) {
            return 'Unknown';
        }

        return this.formatStaffName(staff.values);
    }

    private formatStaffNameInitials(staff: StaffLink | null): string {
        if (!staff) {
            return '';
        }

        return staff.values.first_name.substr(0, 1).toUpperCase() + staff.values.last_name.substr(0, 1).toUpperCase();
    }

    private getAvatarBackgroundColor(staff: StaffLink | null): string {
        if (!staff) {
            return '';
        }

        return getAvatarBackgroundFromUser(staff.id);
    }

    private shownHandler(items: Array<ThreadMeta>) {
        this.shown = items;
    }

    private toggleArchivedSingle(thread: ThreadMeta) {
        this.$emit(EventTypes.TOGGLE_ARCHIVED, [thread], !!this.isArchiveMode);
    }

    private toggleArchivedSelected() {
        this.$emit(EventTypes.TOGGLE_ARCHIVED, this.selectedAndShown, !!this.isArchiveMode);
    }

    private openMessage(thread: ThreadMeta) {
        this.$emit(EventTypes.INBOX_READ, thread, !!this.isArchiveMode, this.ourThreads.indexOf(thread));
    }
}
