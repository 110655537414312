import { Vue } from 'vue-property-decorator';
import { clientTranslationsType } from '@/clients/translations/client-translations-type';
import { ClientTranslator } from '@/clients/client-translator';

export class LoginUtils {
    // Client based translations.
    // Usage: {{ 'something' | cTranslate }}
    // OR this.$options.filters.cTranslate('Word');
    static async setupCTranslate(customerId: number) {
        let cTranslations: clientTranslationsType | null = null;
        if (customerId === 1808 || customerId === 1842 || customerId === 1843) {
            const translations = await import('../clients/translations/code-ninja-translations');
            cTranslations = translations.clientTranslations;
        }

        const cTranslator = new ClientTranslator(cTranslations);

        // Translate a single word.
        Vue.filter('cTranslate', function(word: string) {
            return cTranslator.translate(word);
        });

        // Perform any and all possible translations.
        Vue.filter('cTranslateChunk', function(chunk: string) {
            return cTranslator.translateChunk(chunk);
        });
    }
}
