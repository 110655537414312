import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import { User } from '@/staff/models/user';
import { Center, StaffHateoas } from '@/organizations/locations/models/center';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { CenterMapper } from '@/organizations/locations/mappers/center-mapper';
import { ClientInfoMapper } from '@/organizations/corporate/mappers/client-info-mapper';

const centerMapper = new CenterMapper();
const centersStore = getModule(CentersStore);
const centersRepo = new CentersRepository();
const clientRepo = new ClientRepository();
const clientInfoMapper = new ClientInfoMapper();

export const NoPositionConstant = 'None';

export enum LocationStaffPositionConstants {
    LOCATION_DIRECTOR = 'Location Director',
    LOCATION_ASST_DIRECTOR = 'Location Asst. Director',
    LOCATION_ADMIN = 'Location Admin',
    FAMILY_REP = 'Family Rep'
}

export const locationPositionFields = {
    [LocationStaffPositionConstants.LOCATION_DIRECTOR]: 'director',
    [LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR]: 'assistant_director',
    [LocationStaffPositionConstants.LOCATION_ADMIN]: 'administrator',
    [LocationStaffPositionConstants.FAMILY_REP]: 'family_rep'
};

export enum CorporateStaffPositionConstants {
    CORPORATE_MARKETING = 'Corporate Marketing',
    CORPORATE_ADMIN = 'Corporate Admin'
}

export interface StaffPositionInterface {
    user: User | null;
    position: LocationStaffPositionConstants | CorporateStaffPositionConstants | string | null;
    center: Center | null;
}

interface CenterStaffDeletions {
    center: Center;
    deletions: Array<LocationStaffPositionConstants>;
}

/**
 * Handles staff positions for template variables
 */
export class StaffPositionService {
    async batchDelete(deletions: Array<StaffPositionInterface>) {
        const centerDeletions: Array<StaffPositionInterface> = [];
        for (const deletion of deletions) {
            if (!deletion.center) {
                // handle corp deletions like normal
                await this.delete(deletion);
                continue;
            }
            centerDeletions.push(deletion);
        }
        const consolidatedArray = this.consolidateDeletions(centerDeletions);
        for (const consolidated of consolidatedArray) {
            await this.deleteConsolidated(consolidated);
        }
    }

    consolidateDeletions(centerDeletions: Array<StaffPositionInterface>): Array<CenterStaffDeletions> {
        const consolidated: Array<CenterStaffDeletions> = [];
        for (const deletion of centerDeletions) {
            if (!deletion.center) {
                // shouldn't really happen, but..
                continue;
            }
            const existing = consolidated.filter((consolidatedDeletion) => {
                return consolidatedDeletion.center.id === deletion.center!.id;
            });
            if (existing.length) {
                existing[0].deletions.push(deletion.position as LocationStaffPositionConstants);
            } else {
                consolidated.push({
                    center: deletion.center,
                    deletions: [deletion.position as LocationStaffPositionConstants]
                });
            }
        }
        return consolidated;
    }

    /**
     * delete all positions for a center at once to avoid permission issues
     * @param consolidatedDeletion
     */
    async deleteConsolidated(consolidatedDeletion: CenterStaffDeletions) {
        let center = await centersRepo.getOne(consolidatedDeletion.center.id);
        const centerData = centerMapper.toUpdateDto(center);
        for (const position of consolidatedDeletion.deletions) {
            switch (position) {
                case LocationStaffPositionConstants.LOCATION_DIRECTOR:
                    centerData.staff.director = 0;
                    break;
                case LocationStaffPositionConstants.LOCATION_ADMIN:
                    centerData.staff.administrator = 0;
                    break;
                case LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR:
                    centerData.staff.assistant_director = 0;
                    break;
                case LocationStaffPositionConstants.FAMILY_REP:
                    centerData.staff.family_rep = 0;
                    break;
                default:
                    // Huh?! Do nothing.
                    return;
            }
        }
        center = await centersRepo.putOne(consolidatedDeletion.center.id, centerData);
        // Update the store for this center's new data
        centersStore.replaceEntity(center);
    }

    /**
     * Remove a position -- set to null or System
     * @param position
     */
    async delete(position: StaffPositionInterface) {
        if (position.center) {
            // Make sure we get the latest data so we don't put wrong values
            let center = await centersRepo.getOne(position.center.id);
            const centerData = centerMapper.toUpdateDto(center);
            switch (position.position) {
                case LocationStaffPositionConstants.LOCATION_DIRECTOR:
                    centerData.staff.director = 0;
                    break;
                case LocationStaffPositionConstants.LOCATION_ADMIN:
                    centerData.staff.administrator = 0;
                    break;
                case LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR:
                    centerData.staff.assistant_director = 0;
                    break;
                case LocationStaffPositionConstants.FAMILY_REP:
                    centerData.staff.family_rep = 0;
                    break;
                default:
                    // Huh?! Do nothing.
                    return;
            }
            center = await centersRepo.putOne(position.center.id, centerData);
            // Update the store for this center's new data
            centersStore.replaceEntity(center);
            return;
        }
        const clientInfo = await clientRepo.getClientInfo();
        const clientInfoData = clientInfoMapper.toUpdateDto(clientInfo);
        switch (position.position) {
            case CorporateStaffPositionConstants.CORPORATE_MARKETING:
                clientInfoData.marketing_staff = null;
                break;
            case CorporateStaffPositionConstants.CORPORATE_ADMIN:
                clientInfoData.admin_staff = null;
                break;
            default:
                return;
        }
        await clientRepo.updateClientInfo(clientInfoData);
    }

    /**
     * Returns an array of positions the given user holds across the org.
     *
     * @param user
     */
    async getPositionsForUser(user: User): Promise<Array<StaffPositionInterface>> {
        const positions: Array<StaffPositionInterface> = [];
        const centers = await centersStore.retrieveAll({ only_accessible_centers: 'true' });
        for (const storedCenter of centers) {
            // So we get any replaced entities, we grab from the store by id.
            // The accessible centers is just an array, not a map.
            const center = await centersStore.getById(storedCenter.id);
            if (center.staff?.director && center.staff.director.id === user.id) {
                positions.push({
                    user: user,
                    position: LocationStaffPositionConstants.LOCATION_DIRECTOR,
                    center: center
                });
            }
            if (center.staff?.assistant_director && center.staff.assistant_director.id === user.id) {
                positions.push({
                    user: user,
                    position: LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR,
                    center: center
                });
            }
            if (center.staff?.administrator && center.staff.administrator.id === user.id) {
                positions.push({
                    user: user,
                    position: LocationStaffPositionConstants.LOCATION_ADMIN,
                    center: center
                });
            }
            if (center.staff?.family_rep && center.staff.family_rep.id === user.id) {
                positions.push({
                    user: user,
                    position: LocationStaffPositionConstants.FAMILY_REP,
                    center: center
                });
            }
        }
        const clientInfo = await clientRepo.getClientInfo();
        if (clientInfo.admin_staff && clientInfo.admin_staff.id === user.id) {
            positions.push({
                user: user,
                position: CorporateStaffPositionConstants.CORPORATE_ADMIN,
                center: null
            });
        }
        if (clientInfo.marketing_staff && clientInfo.marketing_staff.id === user.id) {
            positions.push({
                user: user,
                position: CorporateStaffPositionConstants.CORPORATE_MARKETING,
                center: null
            });
        }
        return positions;
    }

    /**
     * Given a position, returns null if that position is not taken by anyone else.
     * Returns the staff info of whomever has that position, if anyone else.
     * @param position
     */
    async getStaffInCurrentPosition(position: StaffPositionInterface): Promise<StaffHateoas | null> {
        if (position.center) {
            // Get a fresh copy of the center's current data
            const center = await centersRepo.getOne(position.center.id);
            switch (position.position) {
                case LocationStaffPositionConstants.LOCATION_DIRECTOR:
                    return center.staff && center.staff.director?.id !== position.user?.id
                        ? center.staff.director
                        : null;
                case LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR:
                    return center.staff && center.staff.assistant_director?.id !== position.user?.id
                        ? center.staff.assistant_director
                        : null;
                case LocationStaffPositionConstants.LOCATION_ADMIN:
                    return center.staff && center.staff.administrator?.id !== position.user?.id
                        ? center.staff.administrator
                        : null;
                case LocationStaffPositionConstants.FAMILY_REP:
                    return center.staff && center.staff.family_rep?.id !== position.user?.id
                        ? center.staff.family_rep
                        : null;
            }
        } else {
            const clientInfo = await clientRepo.getClientInfo();
            switch (position.position) {
                case CorporateStaffPositionConstants.CORPORATE_MARKETING:
                    return clientInfo.marketing_staff?.id !== position.user?.id ? clientInfo.marketing_staff : null;
                case CorporateStaffPositionConstants.CORPORATE_ADMIN:
                    return clientInfo.admin_staff?.id !== position.user?.id ? clientInfo.admin_staff : null;
            }
        }
        return null;
    }

    needsCenter(position: (LocationStaffPositionConstants | CorporateStaffPositionConstants | string)): boolean {
        return (Object as any).values(LocationStaffPositionConstants).includes(position);
    }

    /**
     * Used to save the values to the correct place
     *
     * @param position
     */
    async save(position: StaffPositionInterface) {
        if (!position.user) {
            throw new TypeError('User must be set to save!');
        }
        if (position.center) {
            // Make sure we get the latest data so we don't put wrong values
            let center = await centersRepo.getOne(position.center.id);
            const centerData = centerMapper.toUpdateDto(center);
            switch (position.position) {
                case LocationStaffPositionConstants.LOCATION_DIRECTOR:
                    centerData.staff.director = position.user.id;
                    break;
                case LocationStaffPositionConstants.LOCATION_ADMIN:
                    centerData.staff.administrator = position.user.id;
                    break;
                case LocationStaffPositionConstants.LOCATION_ASST_DIRECTOR:
                    centerData.staff.assistant_director = position.user.id;
                    break;
                case LocationStaffPositionConstants.FAMILY_REP:
                    centerData.staff.family_rep = position.user.id;
                    break;
                default:
                    // Huh?! Do nothing.
                    return;
            }
            center = await centersRepo.putOne(position.center.id, centerData);
            // Update the store for this center's new data
            centersStore.replaceEntity(center);
            return;
        }

        // Not a center-level staff; update client info
        const clientInfo = await clientRepo.getClientInfo();
        const clientInfoData = clientInfoMapper.toUpdateDto(clientInfo);
        switch (position.position) {
            case CorporateStaffPositionConstants.CORPORATE_MARKETING:
                clientInfoData.marketing_staff = position.user.id;
                break;
            case CorporateStaffPositionConstants.CORPORATE_ADMIN:
                clientInfoData.admin_staff = position.user.id;
                break;
            default:
                return;
        }
        await clientRepo.updateClientInfo(clientInfoData);
    }
}
