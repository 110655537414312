import { AbstractApiStore } from '@/store/abstract-api-store';
import { CrmTypeList, CrmTypeOption, CustomTypeLabel } from '@/crm-types/models/crm-type';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

interface CrmTypesHash {
    [key: string]: Array<CrmTypeOption>;
}
interface CrmTypesState {
    allCrmTypes: CrmTypesHash;
    crmTypes: CrmTypesHash;
    customLabels: Array<CustomTypeLabel>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CRM_TYPES
})
export class CrmTypesStore extends AbstractApiStore<CrmTypeOption> implements CrmTypesState {
    readonly repository = new CrmTypesRepository();

    allCrmTypes: CrmTypesHash = {};
    crmTypes: CrmTypesHash = {};
    customLabels: Array<CustomTypeLabel> = [];

    @Mutation
    public storeList(params: { list: CrmTypeList; options: Array<CrmTypeOption> }) {
        this.crmTypes[params.list] = params.options;
    }

    @Mutation
    public storeAllList(params: { list: CrmTypeList; options: Array<CrmTypeOption> }) {
        this.allCrmTypes[params.list] = params.options;
    }

    @Mutation
    public storeCustomLabels(labels: Array<CustomTypeLabel>) {
        this.customLabels = labels;
    }

    @Action
    public async initList(list: CrmTypeList) {
        if (!this.crmTypes[list]) {
            await this.initPromise({
                hash: 'list-' + list,
                closure: async () => {
                    await this.retrieveList(list);
                }
            });
        }
    }

    @Action
    public async initAllList(list: CrmTypeList) {
        if (!this.allCrmTypes[list]) {
            await this.initPromise({
                hash: 'allList-' + list,
                closure: async () => {
                    await this.retrieveAllList(list);
                }
            });
        }
    }

    @Action
    public async initLabels() {
        if (this.customLabels.length === 0) {
            await this.initPromise({
                hash: 'labels',
                closure: async () => {
                    await this.retrieveLabels();
                }
            });
        }
    }

    /**
     * Retrieve only active options
     * @param list
     */
    @Action
    public async retrieveList(list: CrmTypeList) {
        const listOptions = await this.repository.getList(list);
        this.context.commit('storeList', { list: list, options: listOptions });
    }

    /**
     * Retrieve active and inactive options, basically every options
     * @param list
     */
    @Action
    public async retrieveAllList(list: CrmTypeList) {
        const listOptions = await this.repository.getAllList(list);
        this.context.commit('storeAllList', { list: list, options: listOptions });
    }

    @Action({ commit: 'storeCustomLabels' })
    public async retrieveLabels() {
        return await this.repository.getCustomLabels();
    }

    /**
     *  Get only active options
     */
    get listOptions() {
        return (list: CrmTypeList) => {
            return this.crmTypes[list] ?? [];
        };
    }

    /**
     *  Get only active options
     */
    get listVisibleOptions() {
        return (list: CrmTypeList) => {
            return this.crmTypes[list]?.filter((option: CrmTypeOption) => (option.is_active && !option.is_hidden)) ?? [];
        };
    }

    /**
     *  Include inactive options, basically all options
     */
    get allListOptions() {
        return (list: CrmTypeList) => {
            return this.allCrmTypes[list] ?? [];
        };
    }

    get customLabel() {
        return (identifier: string) => {
            const matchLabels = this.customLabels.filter((label) => {
                return label.identifier === identifier;
            });
            if (matchLabels.length) {
                return matchLabels[0].value;
            }
            return '';
        };
    }

}
