export enum FeatureConstants {
    BRANDS = 'brand',
    BRAND_EMAILS = 'brandEmail',
    CHILD_TYPE_12 = 'childTypeOneAndTwo',
    CHILD_TYPE_34 = 'childTypeThreeAndFour',
    CLASSROOMS = 'classrooms',
    CLIENT_FORMS = 'clientForm',
    CMS_ADD_ON_ID = 'cmsAddon',
    CRM_DUAL_MODE = 'crmDualMode',
    CRM_MODE = 'crmMode',
    CRM_PLUS_MODE = 'crmPlusMode',
    DRIP_CAMPAIGNS = 'dripCampaign',
    ENROLLMENT_CENTER = 'enrollmentCenter',
    FACEBOOK_INTEGRATION = 'facebookIntegration',
    FRANCHISE_MODE = 'franchiseMode',
    LEAD_TYPE_12 = 'leadTypeOneAndTwo',
    LEAD_TYPE_34 = 'leadTypeThreeAndFour',
    LEGACY_DUAL_MODE = 'legacyDualMode',
    LEGACY_REPORTS = 'legacyReports',
    LINE_LEADER_MANAGE = 'lineLeaderManage',
    LINE_LEADER_ENROLL = 'lineLeaderEnroll',
    LOCATION_GROUPS = 'locationGroup',
    LOCATION_MERGE_FIELDS = 'locationMergeFields',
    MARKETING_CAMPAIGNS = 'marketingCampaigns',
    TEMPLATE_GROUPS = 'templateGroup',
    TODAYS_TOURS = 'todaysTours',
    TWO_WAY_EMAILS = 'twoWayEmail',
    TWO_WAY_TEXTS = 'twoWayText',
    FAMILY_SCORE = 'familyScore',
    INCONTACT = 'inContact',
    TEMPLATE_DELETE_CORP_ONLY = 'templateDeleteCorpOnly',
    NYLAS_EMAIL = 'nylasEmail',
    NYLAS_CALENDAR = 'nylasCalendar',
    NYLAS_GOOGLE_LOGIN = 'nylasGoogleLogin',
    COMMUNICATION_TYPES = 'communicationTypes',
    CUSTOM_DASHBOARDS = 'customDashboards',
    LOGICAL_LANDING_PAGE_SITES = 'logicalLandingPages'
}
