









































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { DashboardGaugeMixin } from '@/dashboards/dashboard-gauge-mixin';

const featureState = getModule(FeaturesStore);

@Component
export default class GaugeComparison extends Mixins(DashboardGaugeMixin) {
    @Prop({ default: false }) reverse!: boolean;
    @Prop({ type: String, required: true }) caption!: string;
    @Prop({ required: true }) value!: number;

    get arrow(): string {
        if (this.value > 0) {
            return '&uarr;';
        } else if (this.value < 0) {
            return '&darr;';
        } else {
            return '&varr;';
        }
    }

    get changeMessage(): string {
        const change = Math.round(Math.abs(this.value));
        if (change < 0.5) {
            return 'Unchanged';
        }
        if (this.value > 0) {
            return `Up ${change}%`;
        } else {
            return `Down ${change}%`;
        }
    }

    get changeClass(): string {
        if (Math.abs(this.value) < 0.5) {
            return '';
        }
        const goodClass = this.hasLineLeaderManage ? 'good-llm' : 'good';
        const badClass = this.hasLineLeaderManage ? 'bad-llm' : 'bad';
        if (this.value > 0) {
            return this.reverse ? badClass : goodClass;
        }
        return this.reverse ? goodClass : badClass;
    }

    get hasLineLeaderManage(): boolean {
        return featureState.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    get captionClass(): string {
        return this.hasLineLeaderManage
            ? 'dashboard-gauge-text-sm'
            : 'px-0 dashboard-gauge-text';
    }
}
