import {
    CountByPeriod,
    CountByPeriods,
    OrganizationCounts,
    OrgPeriodCounts,
    PeriodCounts,
    PeriodStatistics,
    StatusStatisticsParameters
} from '@/models/report/period-statistics';
import { Count } from '@/models/report/count';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { AxiosInstance } from 'axios';
import { createClient } from '@/transport/create-client';
import { baseUrl } from '@/core/base-url';
import { cloneDeep } from 'lodash';
import { SortConstants } from '@/constants/sort-constants';
import qs from 'qs';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';

// Statistic types
type statTypes = 'statuses' | 'tasks' | 'texts' | 'emails' | 'families/estimated-revenue' | 'families/source' | 'families/inquiry' | 'conversions';

const authState = getModule(AuthStore, store);
const statusStore = getModule(StatusesStore);

export class PeriodStatisticsRepository {
    protected statistics: PeriodStatistics | undefined;
    protected client: AxiosInstance;
    readonly endpoint = 'statistics';

    public constructor() {
        let url = baseUrl + '/api/v3';
        if (authState.isAssumingOtherCustomer) {
            url = `${baseUrl}/api/admin/v1/customers/${authState.currentCustomerDb}`;
        }

        this.client = createClient(url);
    };

    /**
     * Query and load the repository.
     *
     * @param statType
     * @param params
     */
    public async query(statType: statTypes, params?: object): Promise<PeriodStatistics | undefined> {
        if (params === undefined) {
            params = {};
        }

        // No need to type this response, it should create the correct object or fail.
        const response = await this.client.get(this.endpoint + '/' + statType, {
            params: params,
            paramsSerializer: params1 => {
                return qs.stringify(params1);
            }
        });
        this.statistics = response.data;

        return this.statistics;
    };

    /**
     * Query status statistics -- simple wrapper function
     *
     * @param params
     */
    public async queryStatuses(params?: StatusStatisticsParameters): Promise<PeriodStatistics | undefined> {
        return this.query('statuses', params);
    }

    /**
     * Query status statistics for active statuses
     *
     * @param params
     */
    public async queryActiveStatuses(params?: StatusStatisticsParameters): Promise<PeriodStatistics | undefined> {
        if (params === undefined) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            params = { status_ids: [] };
        }

        // Override any status id parameters, but let other params pass through
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.status_ids = statusStore.activeStatusIds;
        return this.queryStatuses(params);
    }

    /**
     * Return the statistics property.
     */
    public getStatistics(): PeriodStatistics | undefined {
        return this.statistics;
    }

    /**
     * Group counts by period, no orgs here.
     */
    public getGroupedByPeriod(): PeriodCounts[] | [] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const periods: PeriodCounts[] = [];

        this.statistics.periods.forEach((period) => {
            // Temp constant that will get shoved into periods, no need to search for duplicates.
            const periodCounts: PeriodCounts = {
                start_date: period.start_date,
                end_date: period.end_date,
                counts: []
            };

            period.organizations.forEach((organizationCounts) => {
                // No need to filter the orgs.
                // Loop thought the counts and merge them in periodCounts based on their id.
                organizationCounts.counts.forEach((count) => {
                    const countItem: undefined | Count = periodCounts.counts.find(({ id }) => id === count.id);

                    if (countItem === undefined) {
                        // Add it into the counts, and go to next iteration.
                        periodCounts.counts.push({
                            id: count.id,
                            name: count.name,
                            total: count.total
                        });
                        return;
                    }

                    const index: number = periodCounts.counts.indexOf(countItem);
                    periodCounts.counts[index].total += count.total;
                });
            });

            if (periodCounts.counts.length > 0) {
                periods.push(periodCounts);
            }
        });

        return periods;
    }

    /**
     * Group data by Organization
     */
    public getGroupedByOrg(): OrganizationCounts[] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const orgs: OrganizationCounts[] = [];

        this.statistics.periods.forEach((periodOrganizations) => {
            periodOrganizations.organizations.forEach((organizationCounts) => {
                // Check to see if we have the Organization and counts in the array.
                const orgCounts: undefined | OrganizationCounts = orgs.find(({ id }) => id === organizationCounts.id);

                if (orgCounts === undefined) {
                    // Not found, add it.
                    // We need a deep copy of the object, not references.
                    orgs.push(cloneDeep(organizationCounts));

                    // We are pushing the whole object, there is no need for any more iteration.
                    // Since we are in a function, we need to return to skip this iteration (equivalent of continue).
                    return;
                }

                // Org has been found, let check the counts.
                organizationCounts.counts.forEach((count) => {
                    const orgCount: undefined | Count = orgCounts.counts.find(({ id }) => id === count.id);

                    if (orgCount === undefined) {
                        // Shove it on the stack.
                        orgCounts.counts.push({
                            id: count.id,
                            name: count.name,
                            total: count.total
                        });

                        // Go to next iteration.
                        return;
                    }

                    // Get the index of the count object.
                    const index: number = orgCounts.counts.indexOf(orgCount);

                    // Make total bigger.
                    orgCounts.counts[index].total += count.total;
                });
            });
        });

        return orgs;
    }

    public getGroupedByCount(): Count[] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const counts: Count[] = [];

        this.statistics.periods.forEach((periodOrganizations) => {
            periodOrganizations.organizations.forEach((organizationCounts) => {
                organizationCounts.counts.forEach((count) => {
                    const orgCount: undefined | Count = counts.find(({ id }) => id === count.id);

                    if (orgCount === undefined) {
                        // Shove this item in the counts.
                        counts.push({
                            id: count.id,
                            name: count.name,
                            total: count.total
                        });

                        // Go to next iteration.
                        return;
                    }

                    // Get the index of the count object.
                    const index: number = counts.indexOf(orgCount);

                    // Make total bigger.
                    counts[index].total += count.total;
                });
            });
        });

        return counts;
    }

    /**
     * For use with something like stacked area charts or multi axes line charts
     */
    public getGroupedByCountAndPeriod(): CountByPeriods[] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const countByPeriodsCollection: CountByPeriods[] = [];

        this.statistics.periods.forEach((periodOrganizations) => {
            periodOrganizations.organizations.forEach((organizationCounts) => {
                organizationCounts.counts.forEach((count) => {
                    let countByPeriods = countByPeriodsCollection.find(({ id }) => id === count.id);

                    if (countByPeriods === undefined) {
                        // Shove this item in the counts.
                        countByPeriods = {
                            id: count.id,
                            name: count.name,
                            counts: []
                        };
                        countByPeriodsCollection.push(countByPeriods);
                    }

                    const countByPeriod = countByPeriods.counts.find((elem: CountByPeriod) => {
                        return elem.start_date === periodOrganizations.start_date && elem.end_date === periodOrganizations.end_date;
                    });

                    if (countByPeriod === undefined) {
                        countByPeriods.counts.push({
                            start_date: periodOrganizations.start_date!,
                            end_date: periodOrganizations.end_date!,
                            total: count.total
                        });

                        return;
                    }

                    countByPeriod.total += count.total;
                });
            });
        });

        return countByPeriodsCollection;
    }

    /**
     * This just basically swaps periods and orgs from raw data
     */
    public getGroupedByOrgPeriodCount(): OrgPeriodCounts[] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const orgPeriodCountsCollection: OrgPeriodCounts[] = [];

        this.statistics.periods.forEach((periodOrganizations) => {
            periodOrganizations.organizations.forEach((organizationCounts) => {
                let orgPeriodCounts = orgPeriodCountsCollection.find(({ id }) => id === organizationCounts.id);

                if (orgPeriodCounts === undefined) {
                    // Shove this item in the counts.
                    orgPeriodCounts = {
                        id: organizationCounts.id,
                        name: organizationCounts.name,
                        periods: []
                    };
                    orgPeriodCountsCollection.push(orgPeriodCounts);
                }

                orgPeriodCounts.periods.push({
                    start_date: periodOrganizations.start_date!,
                    end_date: periodOrganizations.end_date!,
                    counts: organizationCounts.counts
                });
            });
        });

        return orgPeriodCountsCollection;
    }

    /**
     * This will take the type of the count, and make it the primary grouping, then orgs w/ the count for the type
     * will be grouped under that. Kind of an abuse of the type, since the organization will be the type, and the
     * counts will be the organization plus it's type count.
     *
     * @param orderCounts Direction in which to order the counts in the types, or not.
     * @param topN Return the n top results by counts or all (null).
     */
    public getGroupedByTypeThenOrganization(orderCounts: SortConstants|null = null, topN: number|null = null): OrganizationCounts[] {
        if (this.statistics === undefined) {
            // Shouldn't be able to run this if the statistics property haven't been created corrected.
            throw new TypeError('Statistics not populated.');
        }

        const typesCountsCollection: OrganizationCounts[] = [];
        this.statistics.periods[0].organizations.forEach((organizationCounts) => {
            organizationCounts.counts.forEach((typeCount) => {
                let typeCounts = typesCountsCollection.find(({ id }) => id === typeCount.id);

                if (typeCounts === undefined) {
                    // Create OrganizationCounts (using the type, instead of the org...)
                    typeCounts = {
                        id: typeCount.id,
                        name: typeCount.name,
                        counts: []
                    };
                    typesCountsCollection.push(typeCounts);
                }

                typeCounts.counts.push({
                    id: organizationCounts.id,
                    name: organizationCounts.name,
                    total: typeCount.total
                });
            });
        });

        // Do we want to order counts in the type?
        if (orderCounts !== null) {
            typesCountsCollection.forEach((typeCountsCollection) => {
                // Order the counts of each type.
                if (orderCounts === SortConstants.DESC) {
                    typeCountsCollection.counts.sort((a: Count, b: Count) => b.total - a.total);
                } else if (orderCounts === SortConstants.ASC) {
                    typeCountsCollection.counts.sort((a: Count, b: Count) => a.total - b.total);
                }
            });
        }

        // Do we only want the top n counts only?
        if (topN !== null) {
            // First sort collection by overall total by type in descending order.
            typesCountsCollection.sort((a: OrganizationCounts, b: OrganizationCounts) => {
                let aTotal = 0;
                let bTotal = 0;

                a.counts.forEach((count: Count) => {
                    aTotal += count.total;
                });

                b.counts.forEach((count: Count) => {
                    bTotal += count.total;
                });

                return bTotal - aTotal;
            });

            if (typesCountsCollection.length > topN) {
                // Trim down our collection to n elements.
                typesCountsCollection.length = topN;
            }
        }

        return typesCountsCollection;
    }
}
