





































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import type { EnrollmentCenterOfferingsRatingsOption } from '@/enrollment-center/models/enrollment-center-models';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';

const featuresState = getModule(FeaturesStore);

@Component
export default class OfferingsRatingsOptionsSettings extends Mixins(LocaleMixin) {
  @PropSync('options') optionsSynced!: Array<EnrollmentCenterOfferingsRatingsOption>;
  @PropSync('newOptionsCount') newOptionsCountSynced!: number;
  @Prop({ default: 'Warning: Are you sure you want to delete this?' }) readonly warningText!: string;
  @Prop() readonly subLabel!: string;

  private headers: Array<DataTableHeader> = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: '',
            value: 'label',
            align: 'center'
        },
      {
          text: '',
          value: 'delete',
          align: 'center',
          width: '0'
      }
  ]

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    private addOption() {
        const newOption: EnrollmentCenterOfferingsRatingsOption = {
            id: -Math.abs(++this.newOptionsCountSynced),
            label: '',
            order: this.optionsSynced.length - 1
        };
        this.optionsSynced.push(newOption);
    }

    private async deleteOption(option: EnrollmentCenterOfferingsRatingsOption) {
        const index = this.optionsSynced.indexOf(option);
        if (option.id && option.id > 0) {
            const result = await this.$swal({
                text: this.warningText,
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Delete',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });
            if (result.isConfirmed) {
                if (index >= 0) {
                    this.optionsSynced.splice(index, 1);
                }
            }
        } else {
            if (index >= 0) {
                this.optionsSynced.splice(index, 1);
            }
        }
    }

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.optionsSynced.splice(sortEvent.oldIndex!, 1)[0];
        this.optionsSynced.splice(sortEvent.newIndex!, 0, movedItem);
    }

    @Watch('optionsSynced', { deep: true })
    updateOrderChanges() {
        let order = 0;
        for (const option of this.optionsSynced) {
            option.order = order++;
        }
    }
}
