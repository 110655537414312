var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"6","lg":"6","md":"8","sm":"12"}},[_c('base-card',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-title',{staticClass:"mb-n4"},[_vm._v(" Service Accounts "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"color":"primary","dark":""}},on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Service Accounts are used to access ChildcareCRM’s API.")])]),_c('v-spacer'),_c('base-button-secondary',{staticClass:"mt-1",on:{"click":_vm.addServiceAccount}},[_vm._v(" Add Account ")])],1),_c('v-card-text',{staticClass:"pb-0"},[_c('base-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [(item.username)?_c('span',[_vm._v(" "+_vm._s(item.username)+" ")]):_vm._e()]}},{key:"item.partner",fn:function(ref){
var item = ref.item;
return [(item.partner)?_c('span',[_vm._v(" "+_vm._s(item.partner.values.name)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('base-button-secondary',{on:{"click":function($event){return _vm.editServiceAccount(item)}}},[_vm._v(" View / Edit ")])]}}])})],1)],1)],1),_c('service-account-modal',{attrs:{"cur-service-account-details":_vm.serviceAccountSelection,"include-partners-list":_vm.includePartnersList,"partners-list":_vm.referralServiceOptions()},on:_vm._d({},[_vm.serviceAccountsUpdate,function($event){return _vm.updateServicesSection()},_vm.deleteServiceAccountEvent,function($event){return _vm.deactivateServiceAccount($event)}]),model:{value:(_vm.showServiceAccountModal),callback:function ($$v) {_vm.showServiceAccountModal=$$v},expression:"showServiceAccountModal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }