


















































































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Status } from '@/families/models/status';
import isEqual from 'lodash/isEqual';
import { StatusesRepository } from '@/families/repositories/statuses-repository';
import { LoadingStore } from '@/store/loading-store';
import { StatusesStore } from '@/families/store/statuses-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import Draggable from 'vuedraggable';
import ManageStatusModal from '@/settings/components/ManageStatusModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { StatusMapper } from '@/families/mappers/status-mapper';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { IntegrationPartners } from '@/integrations/integration-constants';
import { BaseStatuses } from '@/constants/status-constants';

const statusState = getModule(StatusesStore);
const statusesRepository = new StatusesRepository();
const loadingState = getModule(LoadingStore);
const integrationsState = getModule(IntegrationStore);
const statusMapper = new StatusMapper();
interface StatusComparison {
    original: Status;
    current: Status;
}

@Component({
    components: { Draggable, ManageStatusModal, SaveButton }
})
export default class StatusesSettings extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Ref('form') readonly form!: VForm;

    private statuses: Array<StatusComparison> = [];
    private countChanges = 0;
    private loadingKey = 'statusesSettings';
    private maxLengthNameField = 75;
    private maxLengthCodeField = 12;
    private validForm = true;
    private archiveItems: Array<StatusComparison> = [];
    private nonArchiveItems: Array<StatusComparison> = [];
    private managedStatus: Status | null = null;
    private newStatusIsArchive = false;
    private manageOpen = false;
    private hasManageIntegration = false;
    private exportCheckWarned = false;
    private updatedEvent = EventTypes.STATUSES_UPDATED;

    public async mounted() {
        await this.loadStatuses();
        await statusState.init();
        await integrationsState.init();
        this.hasManageIntegration = integrationsState.stored.some(i => i.name === IntegrationPartners.MANAGE);
    }

    private async loadStatuses() {
        loadingState.loadingIncrement(this.loadingKey);
        const response = await statusesRepository.getAll();
        this.statuses = [];
        for (const status of response.entities as Array<Status>) {
            this.statuses.push({
                original: { ...status },
                current: { ...status }
            });
        }
        this.$nextTick(() => {
            // Validate form when we popup the modal.
            this.form.validate();
        });

        this.archiveItems = this.statuses.filter(s => s.current.is_archive);
        this.nonArchiveItems = this.statuses.filter(s => !s.current.is_archive);
        loadingState.loadingDecrement(this.loadingKey);
    }

    private get hasChanges(): boolean {
        for (const status of this.archiveItems) {
            if (!isEqual(status.current, status.original)) {
                this.countChanges++;
            }
        }
        for (const status of this.nonArchiveItems) {
            if (!isEqual(status.current, status.original)) {
                this.countChanges++;
            }
        }
        return this.countChanges > 0;
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        // Hardcoded - Hacky fix
        // Issue: re-ordering multiple statuses not working for certain edge cases. Unlike other sortable lists we cannot
        // send PUT's to all items, resulting in certain cases where multiple orderings are not being correctly reflected
        // Fix: Assuming that there are going to be 9 maximum statuses out of which 6 are un-editable, we expect to
        // be working with at most 3 custom statuses. This for loop hence fixes our issue
        for (let i = 0; i < 3; i++) {
            for (const status of this.nonArchiveItems) {
                if (!isEqual(status.current, status.original)) {
                    if (status.original.is_sortable && status.original.can_edit_logic && status.original.order !== status.current.order) {
                        await statusesRepository.editStatus(status.current.id, statusMapper.toUpdateDto(status.current));
                    } else if (status.current.name !== status.original.name || status.current.code !== status.original.code || status.current.is_exported !== status.original.is_exported) {
                        await statusesRepository.updateStatus(status.current.id, { name: status.current.name, code: status.current.code ?? '', is_exported: status.current.is_exported });
                        status.original.name = status.current.name;
                        status.original.code = status.current.code;
                    }
                }
            }
        }

        // Hardcoded - Hacky fix
        // Issue: re-ordering multiple statuses not working for certain edge cases. Unlike other sortable lists we cannot
        // send PUT's to all items, resulting in certain cases where multiple orderings are not being correctly reflected
        // Fix: Assuming that there are going to be 9 maximum statuses out of which 5 are un-editable, we expect to
        // be working with at most 4 custom statuses. This for loop hence fixes our issue
        for (let i = 0; i < 4; i++) {
            for (const status of this.archiveItems) {
                if (!isEqual(status.current, status.original)) {
                    if (status.original.is_sortable && status.original.can_edit_logic && status.original.order !== status.current.order) {
                        await statusesRepository.editStatus(status.current.id, statusMapper.toUpdateDto(status.current));
                    } else if (status.current.name !== status.original.name || status.current.code !== status.original.code || status.current.is_exported !== status.original.is_exported) {
                        await statusesRepository.updateStatus(status.current.id, { name: status.current.name, code: status.current.code ?? '', is_exported: status.current.is_exported });
                        status.original.name = status.current.name;
                        status.original.code = status.current.code;
                    }

                }
            }
        }

        const statusPromise = statusState.retrieveStatuses();
        await statusPromise;
        await this.cancel();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async cancel() {
        await this.loadStatuses();
        this.validForm = true;
        this.countChanges = 0;
    }

    private checkMove(event: any): boolean {
        if (!event.draggedContext.element.current.is_sortable) { return false; }
        return event.draggedContext.futureIndex !== 0;
    }

    @Watch('archiveItems')
    checkArchiveItems() {
        if (this.archiveItems.length === 0) {
            return;
        }
        let orderIndex = this.archiveItems[0].current.order;

        for (const item of this.archiveItems) {
            if (item.current.id === this.archiveItems[0].current.id || item.current.order === orderIndex) {
                orderIndex += 1;
                continue;
            }
            item.current.order = orderIndex;
            orderIndex += 1;
        }
    }

    @Watch('nonArchiveItems')
    checkNonArchiveItems() {
        if (this.nonArchiveItems.length === 0) {
            return;
        }
        let orderIndex = this.nonArchiveItems[0].current.order;

        for (const item of this.nonArchiveItems) {
            if (item.current.id === this.nonArchiveItems[0].current.id || item.current.order === orderIndex) {
                orderIndex += 1;
                continue;
            }
            item.current.order = orderIndex;
            orderIndex += 1;
        }

    }

    private editStatus(status: Status) {
        this.managedStatus = status;
        this.manageOpen = true;
    }

    private addStatus(isArchive: boolean) {
        this.newStatusIsArchive = isArchive;
        this.managedStatus = null;
        this.manageOpen = true;
    }

    private canAdd(isArchive: boolean) {
        const currentCount = this.statuses.filter((comp) => {
            return comp.current.is_archive === isArchive;
        }).length;
        return currentCount < 9;
    }

    private async warnExportCheck() {
        if (this.exportCheckWarned) {
            return true;
        }
        const waitListName = this.statuses.map(s => s.current).find(s => s.id === BaseStatuses.WAIT_LIST)?.name ?? 'Wait List';
        const enrolledName = this.statuses.map(s => s.current).find(s => s.id === BaseStatuses.ENROLLED)?.name ?? 'Enrolled';
        this.exportCheckWarned = true;
        const text =
            '<p>This checkbox controls whether children moved to this status will export to Manage.' +
            ' Guardians without children will not export.</p>' +
            `<p>As a general rule, this should be checked for statuses between ${waitListName} and ${enrolledName}` +
            " and shouldn't be checked otherwise";
        const response = await this.$swal({
            icon: 'warning',
            html: text,
            confirmButtonText: 'Ok',
            showConfirmButton: true,
            showCancelButton: false
        });
        return response.isConfirmed;
    }
}
