
































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { DataTableHeader } from 'vuetify';
import { FamilyDocument } from '@/families/models/documents';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { getTimeZoneString } from '@/date-time/date-time-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { openFile } from '@/core/file-utils';
import { FamilyDocumentsRepository } from '@/families/repositories/family-documents-repository';
import { LoadingStore } from '@/store/loading-store';
import FamilyDataUploadDocumentsModal from '@/families/components/new/FamilyDataUploadDocumentsModal.vue';
import store from '@/store';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';

const authState = getModule(AuthStore, store);
const docsRepo = new FamilyDocumentsRepository();
const loadingState = getModule(LoadingStore);
const staffUtils = new StaffUtils();

@Component({
    components: { FamilyDataUploadDocumentsModal }
})
export default class FamilyDataDocuments extends Mixins(FamilyHubMixin, LocaleMixin) {
    @Prop() family!: Family;
    @Prop({ required: true }) documents!: Array<FamilyDocument>;

    private loadingKey = 'familyDataDocuments'
    private showUploadModal = false;
    private updatedEvent = EventTypes.UPDATED;
    private canDeleteDocs = false;

    get formattedDocuments() {
        return this.documents.map((document) => {
            return {
                ...document,
                linked_to: this.linkedTo(document)
            };
        });
    }

    private get familyDocumentsHeaders(): Array<DataTableHeader> {
        return [
            {
                text: 'File Name',
                value: 'filename',
                width: '45%'
            },
            {
                text: 'Linked To',
                value: 'linked_to',
                width: '15%'
            },
            {
                text: 'File Size',
                value: 'file_size',
                width: '15%'
            },
            {
                text: 'Date Added',
                value: 'datetime_last_modified',
                width: '15%'
            },
            {
                text: '',
                value: 'action',
                width: '10%',
                align: 'end',
                sortable: false
            }
        ];
    }

    private get timezone(): string {
        return authState.userTimeZone;
    }

    async created() {
        this.canDeleteDocs = await staffUtils.getUserPermission(PermissionName.FamilyDeleteDocs);
    }

    private dateTimeString(date: string): string {
        if (this.timezone) {
            return this.formatDate(date, this.timezone) + ' ' + this.formatTime(date, this.timezone) + ' ' + getTimeZoneString(new Date(date), this.timezone, this.$i18n.locale);
        }
        return '';
    }

    private async downloadDocument(document: FamilyDocument) {
        if (!this.canEditFamily) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const file = await docsRepo.retrieveFile(this.family.id, document);
        openFile(file, document.filename, document.file_type);
        loadingState.loadingDecrement(this.loadingKey);
    }

    private openUploadDocumentsModal() {
        this.showUploadModal = true;
    }

    private linkedTo(document: FamilyDocument): string {
        if (document.child) {
            return document.child.values.name;
        }
        return 'Family';
    }

    /**
     * Reload the documents.
     */
    private reloadData() {
        this.$emit(this.updatedEvent);
    }

    // Remove existed document
    private async removeDocument(document: FamilyDocument) {
        const response = await this.$swal({
            icon: 'info',
            text: `Are you sure you want to remove the document ${document.name}?`,
            showCancelButton: true,
            showConfirmButton: true
        });
        if (response.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await docsRepo.delete(this.family.id, document);
            loadingState.loadingDecrement(this.loadingKey);
            this.reloadData();
        }
    }
}
