

























    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Watch } from 'vue-property-decorator';
    import { EventTypes } from '@/constants/event-type-constants';
    import { getModule } from 'vuex-module-decorators';
    import { LoadingStore } from '@/store/loading-store';
    import { ChildStartsTabService } from '@/dashboards/child-starts-tab-service';
    import { DataTableOptions } from '@/models/datatables';
    import { RouteConstants } from '@/router/route-constants';
    import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
    import { AppStateStore } from '@/store/app-state-store';
    import { ChildStartsRow } from '@/dashboards/models/child-starts-tab-models';

    const appState = getModule(AppStateStore);
    const loadingState = getModule(LoadingStore);

@Component
    export default class ChildStartsTab extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
        private childrenStarting: Array<ChildStartsRow> = [];
        private service = new ChildStartsTabService();
        private dateRangeSelected = appState.currentChildStartsRange;
        private loadingKey = ChildStartsTab.name;
        private tableOptions: DataTableOptions = {
            page: 1,
            itemsPerPage: 10
        };

        get org() {
            return appState.storedCurrentOrg;
        }

        @Watch('dateRangeSelected')
        async updateFilter() {
            appState.setChildStartsRange(this.dateRangeSelected);
            await this.update();
        }

        @Watch('org')
        async orgUpdated() {
            await this.update();
        }

        async created() {
            await this.update();
        }

        goToFamily(row: ChildStartsRow) {
            this.$router.push({ name: RouteConstants.FAMILY_HUB, params: { id: row.family_id.toString() } });
        }

        async update() {
            loadingState.loadingIncrement(this.loadingKey);
            this.childrenStarting = await this.service.getChildrenStartingRows(this.$i18n.locale);
            this.$emit(EventTypes.COUNT, this.childrenStarting.length);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }
