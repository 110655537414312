




















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { NavigationGuardNext, Route } from 'vue-router';
import { CustomDashboardsStore } from '@/dashboards/store/custom-dashboards-store';
import { PageTitleMixin } from '@/core/page-title-mixin';
import CustomDashboardComponent from '@/dashboards/components/custom/CustomDashboardComponent.vue';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { CustomDashboardService } from '@/dashboards/custom-dashboard-service';
import DateRangeSelection from '@/components/main/DateRangeSelection.vue';
import { dashboardDateRanges } from '@/models/date-filter';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';

const appState = getModule(AppStateStore);
const dashboardsState = getModule(CustomDashboardsStore);
const actionDashState = getModule(LocationDashboardStore);
const savedReportsState = getModule(SavedReportsStore);
const authState = getModule(AuthStore, store);
const dashService = new CustomDashboardService();

@Component({
    components: { DateRangeSelection, CustomDashboardComponent, BasePageTitle }
})
export default class CustomDashboardView extends Mixins(LocaleMixin, PageTitleMixin) {
    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        appState.setIsDashboard(false);
        next();
    }

    @Prop({ type: Number, required: true }) readonly dashId!: number;

    private dateRanges = dashboardDateRanges;

    get isMineOnly(): boolean {
        return actionDashState.onlyMine;
    }

    set isMineOnly(flag: boolean) {
        actionDashState.setOnlyMine(flag);
    }

    get dash() {
        return dashboardsState.stored.find(dash => dash.id === this.dashId) ?? null;
    }

    get dashElements() {
        return this.dash ? dashService.processElements(this.dash.elements) : [];
    }

    get showDateRange() {
        return this.dash ? dashService.needsDateRange(this.dash) : false;
    }

    get showMineOnly() {
        return this.dash ? dashService.needsMineOnly(this.dash) : false;
    }

    async created() {
        appState.setIsDashboard(true);
        await dashboardsState.init();
        await savedReportsState.init(authState.id!);
        this.dashChanged();
    }

    @Watch('dash', { deep: true })
    dashChanged() {
        if (!this.dash || !this.dash.is_enabled) {
            this.$router.push({ name: 'home' });
        }
        this.setPageTitle(this.dash?.name ?? '');
    }

}
