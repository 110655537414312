
export interface Address {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    locality: string;
    region: string;
    postcode: string;
    country_code: string;
    longitude: number|string;
    latitude: number|string;
}

export class AddressDto {
    address1 = '';
    locality = '';
    region = '';
    postcode = '';
}
