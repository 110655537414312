




























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { ConversionTimingPeriodType } from '@/reports/standard/standard-special-reports';
import type { ConversionTimingPeriod } from '@/reports/standard/standard-special-reports';
import { EventTypes } from '@/constants/event-type-constants';

@Component
export default class ConversionTimingPeriodComponent extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: ConversionTimingPeriod;
    @Prop() readonly periodIndex!: number;

    private periodTypeOptions = [
        {
            text: 'Days',
            value: ConversionTimingPeriodType.DAY
        },
        {
            text: 'Hours',
            value: ConversionTimingPeriodType.HOUR
        }
    ]

    get period() {
        return this.value;
    }

    set period(period: ConversionTimingPeriod) {
        this.$emit(EventTypes.INPUT, period);
    }

    get canDelete() {
        return this.periodIndex > 0;
    }

    get toRules() {
        return [
            ...this.requiredNonNegativeInteger,
            this.period.from <= this.period.to || 'To cannot be less than from'
        ];
    }

    deletePeriod() {
        this.$emit(EventTypes.DELETED);
    }
}
