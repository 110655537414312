import { StoreModuleTypes } from '@/constants/store-constants';
import { Permission } from '@/staff/models/user-permission-models';
import { PermissionsRepository } from '@/staff/repositories/permissions-repository';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';

export interface PermissionsState extends AbstractEntityState<Permission> {
    entities: Array<Permission>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.PERMISSIONS
})
export class PermissionsStore extends AbstractApiStore<Permission> implements PermissionsState {
    readonly repository = new PermissionsRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    @Mutation
    public storePermissions(updatedArray: Array<Permission>): void {
        this.entities = updatedArray;
    }

    public get stored(): Array<Permission> {
        return this.entities;
    }
}
