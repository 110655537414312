




































































































































































































































































































































































































































































































































































































































































































































































































import BaseClose from '@/components/base/BaseClose.vue';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import CenterSelect from '@/components/CenterSelect.vue';
import Loading from '@/components/Loading.vue';
import CenterOrUserDrawer from '@/components/main/CenterOrUserDrawer.vue';
import SuperuserSwitchCustomerModal from '@/customers/components/SuperuserSwitchCustomerModal.vue';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import AddFamily from '@/families/components/AddFamily.vue';
import NylasEmailAuth from '@/integrations/components/NylasEmailAuth.vue';
import { LocaleMixin } from '@/locales/locale-mixin';
import { MenuItem } from '@/models/menu-item';
import UnifiedSearch from '@/search/UnifiedSearch.vue';
import ManageStaffModal from '@/staff/components/ManageStaffModal.vue';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import LayoutMixin from '@/views/LayoutMixin.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import LineLeaderBaseButton from '@/components/base/LineLeaderBaseButton.vue';
import { Logo } from '@/logo/models/logo';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';
import HelpPopup from '@/staff/components/HelpPopup.vue';
import { UiNotification, UiNotificationIdentifiers } from '@/staff/models/ui-notifications';
import { UiNotificationsRepository } from '@/staff/repositories/ui-notifications-repository';
import { UiNotificationUtils } from '@/staff/ui-notification-utils';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';
import { getWhiteLabelColors, ColorUtils } from '@/utils/color-utils';
import DashboardControls from '@/dashboards/components/custom/DashboardControls.vue';
import { AppStateStore } from '@/store/app-state-store';

const uiNotificationsRepo = new UiNotificationsRepository();
const interfaceSettingsStore = getModule(InterfaceSettingsStore);
const clientRepository = new ClientRepository();
const layoutTabsStore = getModule(LayoutTabsStore, store);
const appState = getModule(AppStateStore);

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: {
        DashboardControls,
        HelpPopup,
        BasePageTitle,
        UnifiedSearch,
        BaseClose,
        CenterSelect,
        NylasEmailAuth,
        AddFamily,
        CenterOrUserDrawer,
        Loading,
        SuperuserSwitchCustomerModal,
        ManageStaffModal,
        LineLeaderBaseButton
    }
})
export default class LayoutMP extends Mixins(LocaleMixin, LayoutMixin, CrmBreakpointsMixin) {
    private activeCms = '';
    private activeIntercom = '';
    private activeSixAgent = '';
    private activeSwitchDb = '';
    private drawerMpOpen = false;
    private globalColors: Record<string, string> = {};
    private groupValue = false;
    private showCenterUserDrawer = false;
    private uiNotifications: Array<UiNotification> = [];
    private showInboxPointer = false;
    private whiteLabelLogo: Logo | null = null;
    private imageUrl = '';
    private manageUrl = '';
    private inboxPopperOptions = {
        placement: 'bottom-end',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10]
                }
            }
        ]
    }

    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        await interfaceSettingsStore.init();
        next();
    }

    get redirectTarget() {
        if (this.isLineLeaderManage && this.hasWhiteLabel) {
            return this.manageUrl;
        } else {
            return this.isLineLeaderManage ? 'https://app.momentpath.com/my-site' : 'https://www.golineleader.com/manage-engage-demo';
        }
    }

    get accountName(): string {
        if (!this.userObject) {
            return '';
        }
        return `${this.userObject.first_name} ${this.userObject.last_name}`;
    }

    get appBarHeight(): string {
        switch (this.crmBreakpoint) {
        case 'sm':
            return '55px';
        default:
            return '65px';
        }
    }

    get avatar(): string {
        if (!this.userObject) {
            return 'NA';
        }
        let avatar = this.userObject.first_name.substring(0, 1) + this.userObject.last_name.substring(0, 1);
        if (!avatar) {
            avatar = this.userObject.username.substring(0, 2);
        }
        return avatar;
    }

    get orgName(): string {
        if (!this.currentOrg) {
            return 'Organization Name';
        }
        return this.currentOrg.name;
    }

    get hasWhiteLabel(): boolean {
        return interfaceSettingsStore.hasWhiteLabel;
    }

    get isDashboard() {
      return appState.isDashboard;
    }

    get isInNorthAmerica(): boolean {
       return (this.userObject?.country === 'us' || this.userObject?.country === 'ca');
    }

    get showManage(): boolean {
        return (this.isLineLeaderManage || this.isInNorthAmerica);
    }

    get showPageTitle(): boolean {
        if (this.canAddTabs && layoutTabsStore.tabs.length > 1) {
            return false;
        }
        return true;
    }

    async created() {
        document.getElementsByTagName('body')[0].classList.add('moment-path');
        document.getElementsByTagName('html')[0].classList.add('moment-path');
        const interfaceSettingsMap = interfaceSettingsStore.stored;
        this.manageUrl = interfaceSettingsMap.get(SettingNames.WHITE_LABEL_URL)?.value as string ?? '';
        if (this.hasWhiteLabel) {
            getWhiteLabelColors();
            const primary = '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
            const secondary = '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_SECONDARY)!.value as string;
            this.globalColors = {
                '--primary-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string,
                '--secondary-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_SECONDARY)!.value as string,
                '--third-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_THIRD)!.value as string,
                '--fourth-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_FOURTH)!.value as string,
                '--fifth-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_FIFTH)!.value as string,
                '--sixth-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_SIXTH)!.value as string,
                '--seventh-color': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_SEVENTH)!.value as string,
                '--primary-dark': ColorUtils.darken(primary, 15),
                '--primary-superdark': ColorUtils.darken(primary, 30),
                '--primary-supersuperdark': ColorUtils.darken(primary, 40),
                '--primary-midlight': ColorUtils.lighten(primary, 20),
                '--primary-light': ColorUtils.lighten(primary, 40),
                '--primary-superlight': ColorUtils.lighten(primary, 60),
                '--primary-supersuperlight': ColorUtils.lighten(primary, 80),
                '--primary-ultralight': ColorUtils.adjustColorOpacity(primary, 15),
                '--primary-light10': ColorUtils.lighten(primary, 10),
                '--primary-light20': ColorUtils.lighten(primary, 20),
                '--primary-light30': ColorUtils.lighten(primary, 30),
                '--primary-light40': ColorUtils.lighten(primary, 40),
                '--primary-light50': ColorUtils.lighten(primary, 50),
                '--secondary-light': ColorUtils.lighten(secondary, 40),
                '--secondary-light30': ColorUtils.lighten(secondary, 30),
                '--secondary-light50': ColorUtils.lighten(secondary, 50),
                '--fc-meeting': '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_THIRD)!.value as string,
                '--fc-meetinglight': ColorUtils.lighten('#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_THIRD)!.value as string, 80),
                '--primary-color-50-opacity': ColorUtils.adjustColorOpacity(primary, 50),
                '--secondary-color-50-opacity': ColorUtils.adjustColorOpacity(secondary, 50)
            };
            document.body.style.setProperty('--primary-color', '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string);
            document.body.style.setProperty('--secondary-color', '#' + interfaceSettingsMap.get(SettingNames.WHITE_LABEL_SECONDARY)!.value as string);
            this.whiteLabelLogo = await clientRepository.getWhiteLabelLogo();
            if (this.whiteLabelLogo) {
                const content = await clientRepository.getWhiteLabelLogoContent();
                if (content && this.whiteLabelLogo.mime_type) {
                    const fileBlob = new Blob([content], { type: this.whiteLabelLogo.mime_type });
                    this.imageUrl = URL.createObjectURL(fileBlob);
                }
            } else {
                this.imageUrl = '/img/logos/ll_brandmark.svg';
            }
        } else {
            this.imageUrl = '/img/logos/ll_brandmark.svg';
        }
    }

    async mounted() {
        if (this.userObject?.id) {
            this.uiNotifications = await uiNotificationsRepo.getUiNotifications(this.userObject.id);
            const inboxPointerNotification = UiNotificationUtils.getNotificationByIdentifier(this.uiNotifications, UiNotificationIdentifiers.INBOX_POINTER);
            if (inboxPointerNotification) {
                this.showInboxPointer = inboxPointerNotification.is_active;
                window.dispatchEvent(new Event('resize'));
            }
        }
    }

    @Watch('crmIsMobileSmall', { immediate: true })
    updateLineLeaderStyledDrawer() {
        this.drawerMpOpen = !this.crmIsMobileSmall;
    }

    @Watch('groupValue')
    updateItemGroup() {
        this.drawerMpOpen = false;
    }

    @Watch('orgName')
    orgNameChanged() {
        // make sure inbox pointer doesn't get misaligned
        window.dispatchEvent(new Event('resize'));
    }

    private async goToInbox() {
        await this.$router.push({ name: 'inbox' });
        this.makeEveryItemsInactive();
        this.collapseOtherNavs();
    }

    private makeEveryItemsInactive() {
        this.makeEveryOtherMenuItemInactive();
        this.makeAdditionalItemsInactive();
    }

    private clickActionMobileSmall(tab: MenuItem) {
        if (tab.children) {
           tab.expanded = !tab.expanded;
        } else {
            this.clickNav(tab);
        }
        this.collapseOtherNavs();
    }

    private clickNav(tab: MenuItem) {
        this.handleNav(tab.route, null);
        this.collapseOtherNavs();
        this.makeEveryItemsInactive();
        this.makeItemActive(tab);
        if (this.crmIsMobileSmall) {
            this.drawerMpOpen = false;
        }
    }

    private clickSubNav(subTab: MenuItem) {
        this.handleNav(subTab.route, subTab.routeParams);
        this.makeEveryItemsInactive();
        this.makeItemActive(subTab);
        if (this.crmIsMobileSmall) {
            this.drawerMpOpen = false;
        }
    }

    private clickSixAgent() {
        this.toggleSixAgent();
        this.collapseOtherNavs();
        this.makeEveryItemsInactive();
        this.activeSixAgent = 'active-item';
    }

    private clickIntercom() {
        this.collapseOtherNavs();
        this.makeEveryItemsInactive();
        this.activeIntercom = 'active-item';
    }

    private clickSwitchDb() {
        this.collapseOtherNavs();
        this.makeEveryItemsInactive();
        this.activeSwitchDb = 'active-item';
    }

    private clickCms() {
        this.collapseOtherNavs();
        this.makeEveryItemsInactive();
        this.activeCms = 'active-item';
    }

    async dismissInboxPointer() {
        this.showInboxPointer = false;
        if (this.userObject?.id) {
            const inboxPointerNotification = UiNotificationUtils.getNotificationByIdentifier(this.uiNotifications, UiNotificationIdentifiers.INBOX_POINTER);
            if (inboxPointerNotification) {
                await uiNotificationsRepo.updateUiNotification(this.userObject.id, inboxPointerNotification.id, { is_active: false });
            }
        }
    }

    private makeAdditionalItemsInactive() {
        this.activeSixAgent = '';
        this.activeIntercom = '';
        this.activeSwitchDb = '';
        this.activeCms = '';
    }

    private getIcon(tab: MenuItem) {
        if (tab.icon) {
            return `fad fa-${tab.icon}`;
        }
        return '';
    }
}
