



























































































import CustomFieldsService from '@/crm-types/custom-fields/custom-fields-service';
import { CustomFieldEntityTypes } from '@/crm-types/custom-fields/custom-fields-types';
import CustomFieldSettings from '@/families/components/CustomFieldSettings.vue';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { CrmTypeOption, CrmTypeOptionCreateDto, CrmTypeOptionUpdateDto } from '@/crm-types/models/crm-type';
import { StaffTitleRepository } from '@/staff/repositories/staff-title-repository';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { getHostString } from '@/core/url-utils';
import { baseUrl } from '@/core/base-url';
import { FeaturesRepository } from '@/features/features-repository';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { InterfaceSetting, SettingNames } from '@/dashboards/models/interface-settings-models';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const staffTitleRepository = new StaffTitleRepository();
const featuresStore = getModule(FeaturesStore);
const featuresRepo = new FeaturesRepository();
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {
        BaseClose,
        CustomFieldSettings,
        SelectListEdit
    }
})
export default class SettingsUserSettings extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    private customFieldsKey = 0;
    private customFieldsService = new CustomFieldsService();
    private deletedItems: Array<number> = [];
    private dualModeFlag = true;
    private entityType = CustomFieldEntityTypes.STAFF;
    private fields: Array<CrmTypeOption> = [];
    private loadingKey = 'userSettingsPopup';
    private panelTitlesIndex = -1;
    private updatedEvent = EventTypes.UPDATED;
    private updatedItems: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto> = [];
    private enhancedPermissionsToggle = false;
    private enhancedPermissionsSettingObject: InterfaceSetting | null = null;
    private enhancedPermissionsHint = 'Gives 16 user permissions to control user access rather than 10. Enables more nuanced control over access but requires more work when setting up new users.';
    private validForm = false;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get dualModeActive() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_DUAL_MODE);
    }

    get dualHint() {
        const crmUrl = getHostString(window.location.href);
        const legacyUrl = getHostString(baseUrl);
        return `Allow users access to both ${legacyUrl} and ${crmUrl}.  When enabled, all users granted access to both by default.  To restrict access to CRM+, click  Settings > User Information > [VIEW/EDIT] > Only allow access to ${crmUrl}.`;
    }

    @Watch('modelValue')
    async updateModalStatus() {
        if (this.modelValue) {
            this.panelTitlesIndex = -1;
            loadingState.loadingIncrement(this.loadingKey);
            this.fields = await staffTitleRepository.getStaffTitles();
            await interfaceSettingsStore.init();
            this.enhancedPermissionsSettingObject = interfaceSettingsStore.stored.get(SettingNames.ENHANCED_PERMISSIONS) ?? null;
            this.enhancedPermissionsToggle = interfaceSettingsStore.enhancedPermissions;
            await featuresStore.init();
            if (this.dualModeActive) {
                this.dualModeFlag = true;
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private async updateStaffTitles(updates: {deletedItems: Array<number>; updatedItems: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto>}) {
        this.updatedItems = updates.updatedItems;
        this.deletedItems = updates.deletedItems;
    }

    private async save() {
        if (this.dualModeActive && !this.dualModeFlag) {
            const dualConfirm = await this.$swal({
                icon: 'warning',
                text: 'Warning!  Templates will migrate to CRM+ and access to the legacy system will be disabled for all users',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Proceed'
            });
            if (!dualConfirm.isConfirmed) {
                return;
            }
        }
        loadingState.loadingIncrement(this.loadingKey);
        if (this.enhancedPermissionsSettingObject) {
            await interfaceSettingsStore.updateSetting({ settingId: this.enhancedPermissionsSettingObject.id, dto: { value: this.enhancedPermissionsToggle } });
        }

        if (this.dualModeActive && !this.dualModeFlag) {
            const dualModeFeature = featuresStore.stored.filter((feature) => {
                return feature.identifier === FeatureConstants.CRM_DUAL_MODE;
            });
            if (dualModeFeature.length) {
                await featuresRepo.toggle(dualModeFeature[0], false);
            }
        }

        while (this.deletedItems.length > 0) {
            const id = this.deletedItems.pop();
            if (id) {
                await staffTitleRepository.deleteBasic(id);
            }
        }

        for (const title of this.updatedItems) {
            if (title.id) {
                const id = title.id;
                delete title.id;
                await staffTitleRepository.updateStaffTitle(id, title);
            } else {
                delete title.id;
                await staffTitleRepository.addStaffTitle(title);
            }
        }
        // Save all changes to custom fields
        await this.customFieldsService.save();
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
        await this.$nextTick();
        location.reload();
    }

    private close() {
        this.modelValue = false;
        this.customFieldsService.cancelChanges();
        ++this.customFieldsKey;
    }
}
