import { Action, Module, Mutation } from 'vuex-module-decorators';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import type { InterfaceSetting } from '@/dashboards/models/interface-settings-models';
import { OrgInterfaceSettingUpdateDto, SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsRepository } from '@/dashboards/repositories/interface-settings-repository';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';

export interface InterfaceSettingsState extends AbstractEntityState<InterfaceSetting> {
    entities: Array<InterfaceSetting>;
    mappedEntities: Map<SettingNames, InterfaceSetting>;
    preFilterReports: boolean;
    enhancedPermissions: boolean;
}

@Module({
    dynamic: true,
    name: StoreModuleTypes.ORG_INTERFACE_SETTINGS,
    namespaced: true,
    store
})
export class InterfaceSettingsStore extends AbstractApiStore<InterfaceSetting> implements InterfaceSettingsState {
    readonly repository = new InterfaceSettingsRepository();

    mappedEntities: Map<SettingNames, InterfaceSetting> = new Map();
    preFilterReports = false;
    enhancedPermissions = false;

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'all',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    @Action({ commit: 'storeAll', rawError: true })
    public async retrieveAll() {
        const response = await this.repository.getAll();
        return response.entities;
    }

    @Action({ commit: 'updateOrgInterfaceSetting' })
    public async updateSetting(update: { settingId: number; dto: OrgInterfaceSettingUpdateDto }): Promise<InterfaceSetting> {
        return await this.repository.update(update.settingId, update.dto);
    }

    @Mutation
    public storeAll(interfaceSettings: Array<InterfaceSetting>): void {
        this.entities = interfaceSettings;
        this.entities.forEach((setting) => {
            this.mappedEntities.set(setting.name, setting);
        });
        this.preFilterReports = Boolean(this.mappedEntities.get(SettingNames.PRE_FILTER_REPORTS)?.value);
        this.enhancedPermissions = Boolean(this.mappedEntities.get(SettingNames.ENHANCED_PERMISSIONS)?.value);
    }

    @Mutation
    public updateOrgInterfaceSetting(setting: InterfaceSetting): void {
        this.mappedEntities.set(setting.name, setting);
        this.preFilterReports = Boolean(this.mappedEntities.get(SettingNames.PRE_FILTER_REPORTS)?.value);
        this.enhancedPermissions = Boolean(this.mappedEntities.get(SettingNames.ENHANCED_PERMISSIONS)?.value);
    }

    public get stored(): Map<SettingNames, InterfaceSetting> {
        return this.mappedEntities;
    }

    public get settingByName() {
        return (settingName: string) => {
            const setting = this.entities.find(setting => setting.name === settingName);
            return setting ? setting.value : null;
        };
    }

    public get hasWhiteLabel(): boolean {
        const whiteLabelSettings = this.stored.get(SettingNames.WHITE_LABEL_ENABLED);
        if (!whiteLabelSettings) {
            return false;
        }
        return !!whiteLabelSettings.value;
    }
}
