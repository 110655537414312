import { Enrollment, EnrollmentCreateDtoInterface, EnrollmentUpdateDtoInterface } from '@/families/models/enrollment';
import { EnrollmentLink } from '@/families/models/links/enrollment-link';
import { CrmApiResponse } from '@/models/base';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';

export interface EnrollmentsStartFilterParams extends ApiParameters {
    org_id?: number;
    start_before_date: string;
    start_after_date: string;
    status_ids: Array<number>;
}

export interface EnrollmentsWithdrawnFilterParams extends ApiParameters {
    org_id?: number;
    withdrawn_before_date: string;
    withdrawn_after_date: string;
    status_ids: Array<number>;
}

export class EnrollmentsRepository extends AbstractRepository<Enrollment> {
    readonly endpoint = 'enrollments';

    public async getChildrenStarting(
        pagination: ApiPagination|null = null,
        params: EnrollmentsStartFilterParams
    ): Promise<CrmApiResponse<Enrollment>> {
        return super.get(pagination, params);
    }

    public async getAllChildrenStarting(
        params: EnrollmentsStartFilterParams
    ): Promise<CrmApiResponse<Enrollment>> {
        return super.getAll(params);
    }

    public async getChildrenWithdrawing(
        pagination: ApiPagination|null = null,
        params: EnrollmentsWithdrawnFilterParams
    ): Promise<CrmApiResponse<Enrollment>> {
        return super.get(pagination, params);
    }

    /**
     * Given an array of enrollment links, return the current enrollment
     * @param enrollments
     */
    public async getCurrent(enrollments: Array<EnrollmentLink>): Promise<Enrollment | null> {
        if (!enrollments.length) {
            return null;
        }
        const id = enrollments[enrollments.length - 1].id;
        return await super.getOne<Enrollment>(id);
    }

    public async getEnrollmentsForChildId(childId: number): Promise<Array<Enrollment>> {
        const response = await super.getAll({ children_ids: [childId] });
        return response.entities;
    }

    public async create(enrollment: EnrollmentCreateDtoInterface): Promise<Enrollment> {
        return (await this.client.post<Enrollment>(this.endpoint, enrollment)).data;
    }

    public async update(enrollment: EnrollmentUpdateDtoInterface, noStatus = false): Promise<Enrollment> {
        return (await this.client.put<Enrollment>(`${this.endpoint}/${enrollment.id}` + (noStatus ? '?status_change=false' : ''), enrollment)).data;
    }
}
