






























































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsTaskDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    workflowTimeUnits
} from '@/automation/workflows/constants/workflow-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const loadingState = getModule(LoadingStore);
const crmTypesStore = getModule(CrmTypesStore);

@Component({
    components: { CrmTypeSelectList }
})
export default class WorkflowActionAddTask extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsTaskDto;

    private loadingKey = 'manageWorkflow';
    private units = workflowTimeUnits;
    private taskTypes: Array<CrmTypeOption> = [];
    private assignTos: Array<CrmTypeOption> = [];
    private notifyOptions: Array<CrmTypeOption> = [];
    private taskTypesList = CrmTypeList.TASKS;

    get settings(): WorkflowActionSettingsTaskDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsTaskDto) {
        this.$emit('input', settings);
    }

    @Watch('settings', { deep: true, immediate: true })
    private resetZeroes() {
        if (!this.settings.notification_type) {
            this.settings.notification_type = 0;
        }
        if (!this.settings.family_reminder_text_amount) {
            this.settings.family_reminder_text_amount = 0;
        }
        if (!this.settings.family_reminder_email_amount) {
            this.settings.family_reminder_email_amount = 0;
        }
        if (!this.settings.staff_reminder_text_amount) {
            this.settings.staff_reminder_text_amount = 0;
        }
        if (!this.settings.staff_reminder_email_amount) {
            this.settings.staff_reminder_email_amount = 0;
        }
    }

    async mounted() {
        this.resetZeroes();
        loadingState.loadingIncrement(this.loadingKey);
        await crmTypesStore.initList(CrmTypeList.TASKS);
        await crmTypesStore.initList(CrmTypeList.STAFF_POSITIONS);
        await crmTypesStore.initList(CrmTypeList.WORKFLOW_TASK_NOTIFY);
        this.assignTos = crmTypesStore.listOptions(CrmTypeList.STAFF_POSITIONS);
        this.notifyOptions = crmTypesStore.listOptions(CrmTypeList.WORKFLOW_TASK_NOTIFY);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
