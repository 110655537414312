












































































































































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import FamilyTextsPane from '@/families/components/FamilyTextsPane.vue';
import { Family } from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { LoadingStore } from '@/store/loading-store';
import PhoneNumberDisplay from '@/communications/PhoneNumberDisplay.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { Message, MessageDirection, MessageThread, ThreadMeta } from '@/communications/messages/models/message';
import { buildThreadMeta } from '@/communications/messages/inbox-utils';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import FamilyFBMessagesPane from '@/families/components/FamilyFBMessagesPane.vue';
import { MessagesRepository } from '@/communications/messages/repositories/messages-repository';
import { MessagesStore } from '@/communications/messages/stores/messages-store';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import ViewEmailThread from '@/communications/messages/components/ViewEmailThread.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import { FeatureConstants } from '@/features/feature-constants';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';
import { AuthStore } from '@/store/auth-store';
import { AppStateStore } from '@/store/app-state-store';

const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const familiesRepo = new FamiliesRepository();
const textsState = getModule(TextsStore);
const textsRepo = new TextsRepository();

const fbState = getModule(FacebookMessagesStore);
const fbRepo = new FacebookMessagesRepository();

const emailsState = getModule(EmailsStore);
const emailsRepo = new EmailsRepository();

const layoutTabsStore = getModule(LayoutTabsStore, store);
const featuresStore = getModule(FeaturesStore);
const authStore = getModule(AuthStore, store);

@Component({
    components: {
        BaseClose,
        ViewEmailThread,
        FamilyFBMessagesPane,
        FamilyTextsPane,
        PhoneNumberDisplay
    }
})
export default class ViewMessageModal extends Mixins(LocaleMixin, VmodelShowMixin) {
    @Ref('messagesContainer') readonly messagesContainer!: HTMLElement;
    @Ref('messageDialog') readonly messageDialog!: HTMLElement;
    @Ref('addFamilyDrawer') readonly addFamilyDrawer!: HTMLElement;

    @Prop() readonly familyId!: number;
    @Prop({ default: false }) readonly isFb!: boolean;
    @Prop() readonly emailId!: number;
    @Prop({ default: false }) readonly isFromPendingLead!: boolean;
    @Prop({ default: false }) readonly etDashMode!: boolean;

    private loadingKey = ViewMessageModal.name;
    private family: Family | null = null;
    private messages: MessageThread = [];
    private meta: ThreadMeta | null = null;

    private quickTextEvent = EventTypes.NEW_QUICK_TEXT;
    private pendingTextCancelledEvent= EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent= EventTypes.PENDING_TEXT_UPDATED;
    private newFacebookMessageEvent = EventTypes.NEW_FACEBOOK_MESSAGE
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;

    private emailFamilyId: number | null = null;
    private showAddFamily = false;

    get archiveMode() {
        return this.meta?.is_dismissed ?? false;
    }

    get contentClass() {
        let classString = this.showAddFamily ? 'move-left ' : '';
        classString += this.isEmail ? 'dialog-large dialog-fuller' : 'dialog-medium';
        return classString;
    }

    get isEmail() {
        return !!this.emailId;
    }

    get isText() {
        return !this.isFb && !this.emailId;
    }

    get messagesRepo(): MessagesRepository<Message> {
        return this.isText ? textsRepo : (this.isEmail ? emailsRepo : fbRepo);
    }

    get messagesState(): MessagesStore {
        return this.isText ? textsState : (this.isEmail ? emailsState : fbState);
    }

    get canAddCrmTabs(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && authStore.isEnrollmentTeamMember;
    }

    get newTabStatus() {
        return this.etDashMode && appState.familyHubTargetMode;
    }

    async created() {
        if (this.isEmail) {
            await this.loadEmail(true);
        } else {
            await this.loadData(true);
        }
    }

    private acceptAsNewFamily() {
        this.showAddFamily = true;
        this.$emit(EventTypes.FAMILY_ACCEPTED, this.showAddFamily);
    }

    close() {
        if (this.isFromPendingLead) {
            this.markUnread();
        }
        this.modelValue = false;
    }

    private async goToFamilyHub() {
        if (!this.family) {
            return;
        }

        if (this.newTabStatus) {
            if (this.canAddCrmTabs) {
                await layoutTabsStore.addTab({
                    routeName: 'family-hub',
                    routeParams: { id: this.family.id.toString() },
                    goTo: true,
                    tabTitle: this.family.primary_guardian.last_name
                });
            } else {
                const routeData = this.$router.resolve({
                    name: 'family-hub',
                    params: { id: this.family.id.toString() }
                });
                window.open(routeData.href, '_blank');
            }
        } else {
            await this.$router.push({
                name: 'family-hub',
                params: { id: this.family.id.toString() }
            });
        }
    }

    async loadData(isInitial = false) {
        loadingState.loadingIncrement(this.loadingKey);
        let familyPromise;
        if (isInitial && !this.isEmail) {
            familyPromise = familiesRepo.getOne(this.familyId);
        }
        let messagesPromise;
        if (this.isText) {
            messagesPromise = textsState.retrieveTextsForFamily(this.familyId);
        } else {
            messagesPromise = fbState.retrieveFacebookMessagesForFamily(this.familyId);
        }

        if (familyPromise) {
            this.family = await familyPromise;
        }
        await messagesPromise;
        if (this.isText) {
            this.messages = textsState.stored;
        } else {
            this.messages = fbState.stored;
        }

        if (isInitial) {
            this.meta = buildThreadMeta([this.messages], this.isText, false)[0];
            await this.markRead(true);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    async loadEmail(isInitial = false) {
        loadingState.loadingIncrement(this.loadingKey);

        this.messages = await emailsRepo.getSingleThread(this.emailId);

        if (isInitial) {
            this.meta = buildThreadMeta([this.messages], false, true)[0];
            this.emailFamilyId = this.meta?.family_id ?? 0;
            this.family = await familiesRepo.getOne(this.emailFamilyId);
            await this.markRead(true);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async markRead(flag = true) {
        if (!this.meta || !this.messages.length) {
            return;
        }
        if (!this.isFromPendingLead) {
            if (!this.archiveMode && flag && !this.meta.is_read) {
                this.messagesState.decrementInboxCount();
            }
            if (!this.archiveMode && !flag && this.meta.is_read) {
                this.messagesState.incrementInboxCount();
            }
        }

        const updatePromises = [];
        for (let iter = 0; iter < this.messages.length; iter++) {
            if (this.messages[iter].type === MessageDirection.INCOMING) {
                updatePromises.push(this.messagesRepo.markRead(this.messages[iter].id, flag));
            }
        }
        this.meta.is_read = flag;
        await Promise.all(updatePromises);
        this.$emit(EventTypes.UPDATED, this.meta.is_read);
    }

    private async markUnread() {
        loadingState.loadingIncrement(this.loadingKey);
        await this.markRead(false);
        this.modelValue = false;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private rejectFamily() {
        this.modelValue = false;
        this.$emit(EventTypes.FAMILY_REJECTED, this.familyId);
    }

    private rejectAndBlock() {
        this.modelValue = false;
        this.$emit(EventTypes.PHONE_NUMBER_BLOCKED);
    }

    scrollUp() {
        this.messagesContainer.scrollTop = 0;
    }

    private async toggleArchived() {
        if (!this.meta || !this.messages.length) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        if (!this.meta.is_read && this.archiveMode) {
            this.messagesState.incrementInboxCount();
        }
        if (!this.meta.is_read && !this.archiveMode) {
            this.messagesState.decrementInboxCount();
        }

        this.meta.is_dismissed = !this.meta.is_dismissed;
        const ids = [];
        for (let iter = 0; iter < this.messages.length; iter++) {
            if (this.messages[iter].type === MessageDirection.INCOMING) {
                ids.push(this.messages[iter].id);
            }
        }

        await this.messagesRepo.toggleArchived(ids, this.archiveMode);
        this.$emit(EventTypes.UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
    }
}
