
































































    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { ReportMappingObject, ReportParameters } from '@/reports/models/reports';
    import { ReportDateFiltersService } from '@/reports/report-date-filters-service';
    import { SelectListOption } from '@/core/models/select-list';
    import { ReportIdentifier } from '@/reports/report-constants';
    import { addDays, formatDateForApi } from '@/date-time/date-time-utils';
    import { EventTypes } from '@/constants/event-type-constants';
    import { Report } from 'flexmonster';
    import { getDateParametersFromSavedReport } from '@/reports/report-utils';

    const reportDateFilterService = new ReportDateFiltersService();

@Component
    export default class ReportDateFilter extends Mixins(LocaleMixin) {
        @Prop({ required: true }) mappingObject!: ReportMappingObject;
        @Prop({ required: true }) activeReport!: ReportIdentifier;
        @Prop() readonly savedReport!: Report | null;

        private inputEvent = EventTypes.INPUT;
        private isLoaded = false;
        private reportParameters: ReportParameters = {
            start_date: formatDateForApi(addDays(new Date(), -31)),
            end_date: formatDateForApi(addDays(new Date(), -1))
        };

        get items(): Array<SelectListOption> {
            return reportDateFilterService.getDateFiltersAsListItems(this.mappingObject);
        }

        updateFilters() {
            this.$emit(EventTypes.REPORT_DATE_FILTER_UPDATED, this.reportParameters);
        }

        mounted() {
            if (this.savedReport) {
                this.reportParameters = getDateParametersFromSavedReport(this.savedReport);
                this.$emit(EventTypes.REPORT_DATE_FILTER_UPDATED, this.reportParameters);
                this.isLoaded = true;
                return;
            }
            this.reportParameters.date_field = reportDateFilterService.getDefaultFilter(this.activeReport);
            if (this.activeReport === ReportIdentifier.LOCATIONS) {
                // Because the AC said no date range default for locations
                this.reportParameters.end_date = '';
                this.reportParameters.start_date = '';
            }
            this.$emit(EventTypes.REPORT_DATE_FILTER_UPDATED, this.reportParameters);
            this.isLoaded = true;
        }
    }
