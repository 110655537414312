import { AbstractApiStore } from '@/store/abstract-api-store';
import { CustomFieldValue } from '@/crm-types/custom-fields/custom-fields-types';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { CustomFieldValuesRepository } from '@/crm-types/custom-fields/repositories/custom-field-values-repository';
import type { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import store from '@/store';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CUSTOM_FIELD_VALUES
})
export class CustomFieldsValuesStore extends AbstractApiStore<CustomFieldValue> {
    protected repository = new CustomFieldValuesRepository();
    entities: Array<CustomFieldValue> = [];
    entitiesByField: Map<number, Array<CustomFieldValue>> = new Map();

    @Mutation
    public storeValues(params: { fieldId: number; values: Array<CustomFieldValue> }) {
        this.entitiesByField.set(params.fieldId, params.values);
    }

    @Action
    public async initValuesForField(field: CustomField) {
        if (this.entitiesByField.get(field.id) === undefined) {
            const values = await this.repository.getValuesForField(field.id);
            this.context.commit('storeValues', { fieldId: field.id, values: values });
        }
    }

    public get stored(): Array<CustomFieldValue> {
        return this.entities;
    }

    get valuesForField() {
        return (field: CustomField): Array<CustomFieldValue> => {
            return this.entitiesByField.get(field.id) ?? [];
        };
    }
}
