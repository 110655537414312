



















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { WorkflowEntityTypes, workflowStopTriggers } from '@/automation/workflows/constants/workflow-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { DripCampaignStopTriggerDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList } from '@/crm-types/models/crm-type';

const statusesState = getModule(StatusesStore);
const crmTypesState = getModule(CrmTypesStore);

@Component
export default class WorkflowStopTrigger extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: DripCampaignStopTriggerDto;

    private stopTriggerOptions = workflowStopTriggers;

    get stopTrigger(): DripCampaignStopTriggerDto {
        return this.value;
    }

    set stopTrigger(stopTrigger: DripCampaignStopTriggerDto) {
        this.$emit('input', stopTrigger);
    }

    get guardianStatuses() {
        return statusesState.statuses.filter((status) => {
            return !status.child_only;
        });
    }

    get events() {
        return crmTypesState.listOptions(CrmTypeList.TASK_RESULTS);
    }

    get isChildStatus() {
        return this.stopTrigger.type === WorkflowEntityTypes.CHILD;
    }

    get isEvent() {
        return this.stopTrigger.type === WorkflowEntityTypes.EVENT;
    }

    get statuses() {
        return this.isChildStatus ? statusesState.statuses : this.guardianStatuses;
    }

    get type() {
        return this.stopTrigger.type;
    }

    @Watch('type')
    typeChanged() {
        if (!this.isEvent && !this.isChildStatus && this.stopTrigger.status_value && !this.statuses.map((status) => {
            return status.id;
        }).includes(this.stopTrigger.status_value)) {
            this.stopTrigger.status_value = null;
        }
    }

    private deleteStopTrigger() {
        this.$emit(EventTypes.DELETED);
    }
}
