
















































import { EventTypes } from '@/constants/event-type-constants';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AuthMixin } from '@/auth/auth-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';

@Component({
    components: {}
})
export default class MultiSelectAutoComplete extends Mixins(AuthMixin, LocaleMixin) {
    /**
     * The item's property to return as the selected value.
     */
    @Prop({ type: String }) readonly allSelectedText: string | undefined;
    /**
     * The items in the list.
     */
    @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>;
    /**
     * The item's property or callback to use for the display as the select list option.
     */
    @Prop({ type: [String, Function], default: 'name' }) readonly itemText!: string | Function;
    /**
     * The item's property to return as the selected value.
     */
    @Prop({ type: String, default: 'id' }) readonly itemValue!: string;
    /**
     * The label for the select list.
     */
    @Prop({ type: String, required: true }) readonly label!: string;
    /**
     * The maximum number of selected items to show before adding a placeholder for the remaining selected items.
     * -1 means to show all selected items.
     */
    @Prop({ type: Number, default: -1 }) readonly maxSelectionDisplay!: number;
    /**
     * The placeholder for the select list.
     */
    @Prop({ type: String, default: undefined }) readonly placeholder: string | undefined;
    /**
     * The validation rules.
     */
    @Prop({ default: () => [] }) readonly rules!: Array<Function | boolean | string>;
    /**
     * The selected values
     */
    @Prop({ default: () => [] }) readonly value!: Array<number | string>;

    /**
     * Whether or not to show some custom text when all items are selected.
     */
    private get showSingleAllSelection(): boolean {
        return this.allSelected && this.allSelectedText !== undefined;
    }

    /**
     * Whether all of the items are selected.
     */
    private get allSelected() {
        return this.localValue.length === this.items.length;
    }

    /**
     * The icon for the select all checkbox.
     */
    private get icon() {
        if (this.allSelected) {
            return 'mdi-checkbox-marked';
        }
        if (this.someSelected) {
            return 'mdi-minus-box';
        }
        return 'mdi-checkbox-blank-outline';
    }

    get localValue() {
        return this.value;
    }

    set localValue(value) {
        this.$emit(EventTypes.INPUT, value);
    }

    /**
     * Whether only some of the items are selected.
     */
    private get someSelected() {
        return this.localValue && this.localValue.length > 0 && !this.allSelected;
    }

    /**
     * Get the text to display for the selected items.
     *
     * @param item
     */
    private getSelectionDisplay(item: any) {
        // If itemText is a function, apply the function to the selected item in the list
        if (this.itemText && {}.toString.call(this.itemText) === '[object Function]') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            return this.itemText(item);
        }
        // Otherwise, treat the itemText as a property on the item
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return item[this.itemText];
    }

    /**
     * Toggle all selected items.
     */
    private toggle() {
        this.$nextTick(() => {
            if (this.allSelected) {
                this.localValue = [];
            } else {
                this.localValue = this.items.map(item => item[this.itemValue]);
            }
        });
    }
}
