import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';
import { StatusDetailsChild, StatusLink } from '@/families/models/status';
import { ContactLink } from '@/families/models/links/contact-link';
import { EnrollmentLink } from '@/families/models/links/enrollment-link';
import { Address } from '@/families/models/address';
import { CustomValues, CustomValuesUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';
import { formatIsoDateTime } from '@/date-time/date-time-utils';
import { FamilyIntegration } from '@/families/models/family';
import { ChildLink } from '@/families/models/links/child-link';

export interface Child extends CrmEntity {
    status: StatusLink | null;
    name: string;
    first_name: string;
    last_name: string;
    preferred_name: string;
    gender: string;
    date_of_birth: string;
    exported: boolean;
    exported_date: string | null;
    added_date: string;
    system_date: string;
    comments: string;
    primary_language: string;
    secondary_language: string;
    immunizations_compliant: boolean;
    allergies: string;
    medical_conditions: string;
    dietary_requirements: string;
    emergency_contacts: Array<ContactLink>;
    address: Address | Array<never>;
    enrollments: Array<EnrollmentLink>;
    child_of_staff?: boolean;
    custom_values: Array<CustomValues>;
    is_eligible_for_reenrollment: boolean;
    good_standing: boolean;
    is_child_of_staff: boolean;
    is_estimated_date_of_birth: boolean;
    current_situation: HateoasLink | null;
    prior_likes: string;
    prior_dislikes: string;
    prior_situation_comment: string;
    reason_for_change: HateoasLink | null;
    is_sibling_in_care: boolean;
    custom_type_1?: HateoasLink | null;
    custom_type_2?: HateoasLink | null;
    custom_type_3?: HateoasLink | null;
    custom_type_4?: HateoasLink | null;
    status_details?: StatusDetailsChild;
    integrations?: Array<FamilyIntegration>;
    previous_opportunities?: Array<Child>;
}

export interface ChildCreateDtoInterface extends AbstractCreateDto {
    id: number | undefined;
    status?: number | null;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    comments?: string;
    added_date?: string;
    preferred_name?: string;
    gender?: string;
    custom_values?: Array<CustomValuesUpdateDto> | null;
    good_standing?: boolean;
    is_eligible_for_reenrollment?: boolean;
    is_estimated_date_of_birth: boolean;
    is_child_of_staff: boolean;
    current_situation: number | null;
    prior_likes: string;
    prior_dislikes: string;
    prior_situation_comment: string;
    reason_for_change: number | null;
    is_sibling_in_care: boolean;
    custom_type_1?: number | null;
    custom_type_2?: number | null;
    custom_type_3?: number | null;
    custom_type_4?: number | null;
    enrollments?: Array<EnrollmentLink>;
    enrolled_reason?: number | null;
    enrolled_comment?: string | null;
    actual_start_date?: string | null;
    expected_start_date?: string | null;
    temp_leave_date?: string | null;
    temp_leave_reason?: number | null;
    temp_leave_comment?: string | null;
    is_wait_list_fee_paid?: boolean;
    wait_list_fee_paid_date?: string | null;
    wait_list_date?: string | null;
    wait_list_reason?: number | null;
    wait_list_comment?: string | null;
    wait_list_fee?: string | null;
    wait_list_type?: number | null;
    wait_list_priority?: number | null;
    withdrawn_date?: string | null;
    withdrawn_reason?: number | null;
    withdrawn_comment?: string | null;
    integrations?: Array<FamilyIntegration>;
}

export class ChildPostDTO implements ChildCreateDtoInterface {
    id: undefined;
    status: number | null = 1;
    first_name = '';
    last_name = '';
    date_of_birth = '';
    current_situation = null;
    is_estimated_date_of_birth = false;
    is_sibling_in_care = false;
    prior_dislikes = '';
    prior_likes = '';
    prior_situation_comment = '';
    reason_for_change = null;
    is_child_of_staff = false;
    good_standing = true;
    preferred_name = '';
    gender = '';
    is_eligible_for_reenrollment = true;
    added_date = formatIsoDateTime(new Date());
    comments = '';
    custom_values: Array<CustomValuesUpdateDto> = [];
    custom_type_1 = null;
    custom_type_2 = null;
    custom_type_3 = null;
    custom_type_4 = null;
    enrollments = [];
    enrolled_reason = null;
    enrolled_comment = null;
    actual_start_date = null;
    expected_start_date = null;
    temp_leave_date = null;
    temp_leave_reason = null;
    temp_leave_comment = null;
    is_wait_list_fee_paid = false;
    wait_list_fee_paid_date = null;
    wait_list_date = null;
    wait_list_reason = null;
    wait_list_comment = null;
    wait_list_fee = null;
    wait_list_type = null;
    wait_list_priority = null;
    withdrawn_date = null;
    withdrawn_reason = null;
    withdrawn_comment = null;
    integrations: Array<FamilyIntegration> = [];
}

export interface ChildUpdateDtoInterface extends ChildCreateDtoInterface, AbstractUpdateDto {
    id: number;
}

export interface ChildRevenue {
    fte: number;
    twelveMonthRevenue: number;
    lifetimeRevenue: number;
    child: ChildLink;
}

export interface ChildComparison {
    original: Child | ChildCreateDtoInterface;
    current: Child | ChildCreateDtoInterface;
}
