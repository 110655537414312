import { CrmEntity } from '@/models/base';
import { AbstractUpdateDto } from '@/models/abstract-dto';

export enum SettingTypes {
    BOOL = 'bool',
    CHOICE = 'choice',
    INT = 'int',
    TIME = 'time'
}

export enum SettingNames {
    CHILD_STATUS_GRAPH_ENROLLED = 'childStatusGraphEnrolledShow',
    CHILD_STATUS_GRAPH_PENDING_OPPORTUNITIES = 'childStatusGraphPendingOpportunitiesShow',
    PIPELINE_VALUE = 'pipelineValueShow',
    INCLUDE_MEETINGS = 'includeMeetings',
    SHOW_CHILD_STARTS = 'showChildStarts',
    PRE_FILTER_REPORTS = 'preFilterReports',
    ENHANCED_PERMISSIONS = 'enhancedUserPermissions',
    AUTOMATION_MOVE_LOST_OPP = 'automationMoveLostOpp',
    AUTOMATION_MOVE_LOST_OPP_DAYS = 'automationMoveLostOppDays',
    WHITE_LABEL_URL = 'whiteLabelUrl',
    WHITE_LABEL_ENABLED = 'whiteLabelEnabled',
    WHITE_LABEL_PRIMARY = 'whiteLabelPrimaryColor',
    WHITE_LABEL_SECONDARY = 'whiteLabelSecondaryColor',
    WHITE_LABEL_THIRD = 'whiteLabelThirdColor',
    WHITE_LABEL_FOURTH = 'whiteLabelFourthColor',
    WHITE_LABEL_FIFTH = 'whiteLabelFifthColor',
    WHITE_LABEL_SIXTH = 'whiteLabelSixthColor',
    WHITE_LABEL_SEVENTH = 'whiteLabelSeventhColor'
}

export interface InterfaceSetting extends CrmEntity {
    id: number;
    name: SettingNames;
    description: string;
    type: SettingTypes;
    default: string | number | boolean;
    value?: string | number | boolean;
}

export interface OrgInterfaceSettingUpdateDto extends AbstractUpdateDto {
    value: string | number | boolean;
}
