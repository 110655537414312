


























































import {
    DefaultLandingPageSettings,
    LandingPageCreateDto,
    LandingPageType
} from '@/automation/landing-pages/models/landing-pages-models';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { INQUIRY_TYPE_WALK_IN_ID } from '@/crm-types/crm-types-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingStore = getModule(LoadingStore);
const landingPagesRepo = new LandingPagesRepository();
@Component({
    components: { BaseClose }
})
export default class AddLandingPageModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show it
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'addPageModal';
    private newPageDto = new LandingPageCreateDto();
    private valid = false;
    private landingPageOptions = [
        {
            text: 'Kiosk',
            value: LandingPageType.KIOSK
        },
        {
            text: 'Parent Scheduled Tour',
            value: LandingPageType.PST
        },
        {
            text: 'Wait List',
            value: LandingPageType.WAIT_LIST
        },
        {
            text: 'Web',
            value: LandingPageType.WEB
        }
    ];

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    @Watch('modelValue')
    valueChanged() {
        this.newPageDto = new LandingPageCreateDto();
        this.form.reset();
        loadingStore.loadingStop(this.loadingKey);
    }

    private close() {
        this.modelValue = false;
    }

    private async postNewPage() {
        loadingStore.loadingIncrement(this.loadingKey);
        if ([LandingPageType.KIOSK, LandingPageType.WAIT_LIST].includes(this.newPageDto.type)) {
            this.newPageDto.inquiry_type = INQUIRY_TYPE_WALK_IN_ID;
        }
        const newPage = await landingPagesRepo.createPage(this.newPageDto);
        await landingPagesRepo.updatePageSettings(newPage.id, DefaultLandingPageSettings);
        this.$router.push({ name: 'landing-page', params: { id: newPage.id.toString() } });
        this.close();
        loadingStore.loadingDecrement(this.loadingKey);
    }
}
