import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import type { TrendTrackingParameters } from '@/reports/standard/standard-special-reports';

export interface StandardReportsState {
    trendTrackingParams: TrendTrackingParameters | null;
    selectedFields: Array<string>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.STANDARD_REPORTS
})
export class StandardReportsStore extends VuexModule implements StandardReportsState {
    trendTrackingParams: TrendTrackingParameters | null = null;
    selectedFields: Array<string> = [];

    @Mutation
    setTrendTrackingParams(params: TrendTrackingParameters) {
        this.trendTrackingParams = params;
    }

    @Mutation
    setSelectedFields(fields: Array<string>) {
        this.selectedFields = fields;
    }
}
