

























































































































































































































    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import type { LandingPage } from '@/automation/landing-pages/models/landing-pages-models';
    import { baseUrl } from '@/core/base-url';
    import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
    import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
    import { EventTypes } from '@/constants/event-type-constants';

    const landingPagesRepo = new LandingPagesRepository();

    @Component
    export default class ParentScheduledToursEmbed extends Mixins(LocaleMixin) {
        @Prop({ required: true }) form!: LandingPage;
        @Prop({ default: false }) hasMarketingCampaigns!: boolean;
        @Prop() marketingCampaigns!: Array<MarketingCampaign>;

        private centers: Array<object> = [];
        private center: number | null = null;
        private nextLabel = 'Next';
        private submitLabel = 'Submit';
        private addChildLabel = 'Additional Child';
        private removeChildLabel = 'Remove Child';
        private changeTimeLabel = 'Change Time';
        private disclaimerText = 'By clicking "Submit" I agree to be sent text and email notifications from <% center_name %>.'
        private callbackCode = '';
        private showConfirmation = true;
        private marketingCampaignId = 0;

        // Code snippet for the header
        private headCode = `&lt;link rel="stylesheet" type="text/css" href="${baseUrl}/form/v1.css">\n&lt;script type="text/javascript" src="${baseUrl}/form/v1.js">&lt;/script>`;

        get bodyCode() {
            const disclaimerText = this.disclaimerText.replace(/"/g, '\\"');
            return `&lt;div id="childcarecrm-tour-form">&lt;/div>
&lt;script type="text/javascript">
    var crmForm = new ChildCareCRM.Forms.Form({
        centerId: ${this.center ? this.center : null},
        customerId: "${this.form.customer_id}",
        formId: "${this.form.web_form_id}",
        selector: "#childcarecrm-tour-form",
        labels: {
            "next": "${this.nextLabel}",
            "submit": "${this.submitLabel}",
            "additional_child": "${this.addChildLabel}",
            "remove_child": "${this.removeChildLabel}",
            "change_time": "${this.changeTimeLabel}",
            "disclaimer_text": "${disclaimerText}"
        },
        callback: ${this.callbackCode && this.callbackCode !== '' && !this.callbackCode.match(/^\s+$/) ? this.callbackCode : null},
        show_confirmation: ${this.showConfirmation}
    });
    crmForm.render();
&lt;/script>`;
        }

        private async mounted() {
            await this.updateCenters();
            this.marketingCampaignId = this.form.marketing_campaign ? this.form.marketing_campaign.id : 0;
        }

        @Watch('marketingCampaignId')
        private marketingCampaignChanged() {
            this.$emit(EventTypes.MARKETING_CAMPAIGN_CHANGED, this.marketingCampaignId);
        }

        @Watch('form', { deep: true })
        private async updateCenters() {
            const centers = await landingPagesRepo.getPstCenters(this.form.id);
            for (const center of centers) {
                this.centers.push({ text: center.values.name, value: center.id });
            }
        }

        private async copyToClipboard(content: string) {
            content = content.replace(/&lt;/g, '<');
            await navigator.clipboard.writeText(content);
        }
    }
