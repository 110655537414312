




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { LandingPageUtils } from '@/automation/landing-pages/landing-page-utils';
import { LandingPage, LandingPageType } from '@/automation/landing-pages/models/landing-pages-models';
import MultiSelectAutoComplete from '@/components/base/MultiSelectAutoComplete.vue';
import { SortConstants } from '@/constants/sort-constants';
import { sortByObjectProperty } from '@/core/sort-utils';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import { CustomStaffValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-staff-values-changes-store';
import { PermissionsStore } from '@/staff/store/permissions-store';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventTypes } from '@/constants/event-type-constants';
import { getAvatarBackgroundFromUser, getAvatarInitialsFromUser } from '@/core/avatar-utils';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { LocaleMixin } from '@/locales/locale-mixin';
import { UserMapper } from '@/staff/mappers/user-mapper';
import { UserPermissionMapper } from '@/staff/mappers/user-permission-mapper';
import { StaffPositionUpdateDto, User, UserCreateDto, UserUpdateDto } from '@/staff/models/user';
import { PermissionName, UserPermission, UserPermissionViewDto } from '@/staff/models/user-permission-models';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { UserPermissionsRepository } from '@/staff/repositories/user-permissions-repository';
import { UserPermissionsStore } from '@/staff/store/user-permissions-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { Timezone } from '@/core/timezones/timezone';
import { CenterStaffMapping, StaffStore } from '@/staff/store/staff-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { StaffPositionInterface, StaffPositionService } from '@/staff/staff-position-service';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import { Center } from '@/organizations/locations/models/center';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { getHostString } from '@/core/url-utils';
import { baseUrl } from '@/core/base-url';
import { StaffUtils } from '@/staff/staff-utils';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import store from '@/store';
import StaffPositionSelect from '@/staff/components/StaffPositionSelect.vue';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import { EnrollmentRepFlowConstants } from '@/enrollment-center/models/enrollment-center-models';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { UserSettingsDto } from '@/staff/models/user-settings-models';
import { UserSettingsMapper } from '@/staff/mappers/user-settings-mapper';
import { UserSettingsRepository } from '@/staff/repositories/user-settings-repository';
import NotificationLocations from '@/staff/components/NotificationLocations.vue';
import NylasEmailAuth from '@/integrations/components/NylasEmailAuth.vue';
import NylasEmailRevoke from '@/integrations/components/NylasEmailRevoke.vue';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import BaseClose from '@/components/base/BaseClose.vue';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const staffUtils = new StaffUtils();
const customFieldsStore = getModule(CustomFieldsStore);
const customStaffValuesStore = getModule(CustomStaffValuesChangesStore);
const landingPageUtils = new LandingPageUtils();
const loadingState = getModule(LoadingStore);
const orgsState = getModule(OrgsStore);
const staffRepository = new StaffRepository();
const timezonesStore = getModule(TimezonesStore);
const userMapper = new UserMapper();
const userPermissionMapper = new UserPermissionMapper();
const userPermissionsState = getModule(UserPermissionsStore);
const userPermissionsRepository = new UserPermissionsRepository();
const userSettingsMapper = new UserSettingsMapper();
const userSettingsRepository = new UserSettingsRepository();
const staffStore = getModule(StaffStore);
const featureStore = getModule(FeaturesStore);
const staffPositionService = new StaffPositionService();
const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const taskRepo = new TasksRepository();
const enrollmentCenterState = getModule(EnrollmentCenterSettingsStore);
const permissionsStore = getModule(PermissionsStore);
const centersStore = getModule(CentersStore);
const interfaceSettingsState = getModule(InterfaceSettingsStore);
const nylasRepo = new NylasRepository();

@Component({
    components: {
        BaseClose,
        NylasEmailRevoke,
        NylasEmailAuth,
        NotificationLocations,
        CustomValuesEditor,
        StaffPositionSelect,
        SaveButton,
        CrmTypeSelectList,
        MultiSelectAutoComplete
    }
})
export default class ManageStaffModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // The staff member to update; will be null if creating a new staff member
    @Prop({ default: null }) readonly user!: User | null;
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    // Checkbox value for the staff being able to view both dashboards.
    private canAccessBothDashboards = false;
    private corpOnlyPermissions: Array<PermissionName | string> = [];
    // The permission for accessing both dashboards
    private dashboardPermission: UserPermission | null = null;
    private deleteEvent = EventTypes.DELETED;
    private addedEvent = EventTypes.ADDED;
    private ecEditCenterPermission: PermissionName = PermissionName.EnrollmentCenterEditLocation;
    private inputEvent = EventTypes.INPUT;
    private isCorpLevel = false;
    private isCenterLevel = false;
    private loadingKey = 'manageStaff';
    private locations: Array<Center | Record<any, any>> = [];
    private modalKey = 0;
    private notificationLandingPages: Array<LandingPage> = [];
    // References the index for the open change password expansion panel
    private notificationTooltip = 'You will receive email notifications when the guardian is replying to a message you sent or a message the system sent';
    private panelIndex: number | undefined = -1;
    // Password confirmation model
    private passwordConfirm = '';
    // List of options for phone type
    private phoneTypes: Array<CrmTypeOption> = [];
    // List of options for position title
    private positionTitles: Array<CrmTypeOption> = [];
    private positionsRefreshKey = 0;
    // Reassign tasks from a person in a center.
    private reassignFromLocation: number | null = null;
    // The user to reassign tasks to.
    private reassignToUser: number | null = null;
    // Whether or not to show the password change fields.
    private showPasswordChange = false;
    // Whether or not to show the user permissions stuff
    private expandUserPermission = false;
    private showUserPermissionsSection = false;
    // Whether to show positions for merge variable use
    private showPositionOptions = false;
    // Whether to show the 'reassign task stuff'.
    private showReassignTasks = false;
    private showNotificationsSection = false;
    private showNotificationLocations = false;
    private userSettingsDto: UserSettingsDto = { add_ons: null, notification_centers: [], notification_forms: [], notification_customer_form: false };
    private updatedEvent = EventTypes.UPDATED;
    // Whether to show the snackbar
    private showSnack = false;
    // The text for the snackbar
    private snackText = '';
    // Data for assigning this user to position(s) for template variables
    private staffPositions: Array<StaffPositionInterface> = [];
    // List of changes to save
    private staffPositionsToSave: Array<StaffPositionInterface> = [];
    private staffPositionsToDelete: Array<StaffPositionInterface> = [];
    private staffPositionsChanged = false;
    // The dto for the new/updated staff member
    private userDto: UserCreateDto | UserUpdateDto = userMapper.toCreateDto({} as User);
    private userPermissionDtos: Array<UserPermissionViewDto> = [];

    // List of users which can have task reassigned to them by org/center.
    private userReassignList: Array<User> = [];
    // Whether the form is valid
    private validForm = false;
    private phoneTypesList = CrmTypeList.PHONE_TYPE;
    private staffTitlesList = CrmTypeList.STAFF_TITLE;
    private etRepAvailableLocations: Array<Center> = [];
    private etRepAssignedLocations: Array<number> = [];
    private ogEtRepAssignedLocations: Array<number> = [];
    private mappedEtCenters: Map<number, Center> = new Map<number, Center>();
    private isLoaded = false;

    private nylasEmailOpen = false;
    private nylasRevokeOpen = false;
    private userHasNylas = false;
    private userHasNylasEmail = false;
    private userHasNylasCalendar = false;

    private terminationDate: string | null = null;

    private get avatarBackgroundColor() {
        if (!this.user) {
            return '';
        }
        return getAvatarBackgroundFromUser(this.user.id);
    }

    private get avatarInitials() {
        if (!this.user) {
            return '';
        }
        return getAvatarInitialsFromUser(this.user as User);
    }

    // Indicates if the user wants to change the password
    private get changePassword() {
        return this.panelIndex === 0; // 0 is the index for the password fields
    }

    private get crmUrl() {
        return getHostString(window.location.href);
    }

    get customFields(): Array<CustomField> {
        return customFieldsStore.storedStaffFields;
    }

    private get dualModeActive() {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_DUAL_MODE);
    }

    private get enrollmentCenterActive() {
        return featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    get etRepFlow(): EnrollmentRepFlowConstants | null {
        return enrollmentCenterState.settings ? enrollmentCenterState.settings.flow.id : null;
    }

    get etName(): string {
        return enrollmentCenterState.settings ? enrollmentCenterState.settings.name : '';
    }

    get hasCustomDash() {
        if (!this.isCrmPlus) {
            return false;
        }
        if (!featureStore.isFeatureEnabled(FeatureConstants.CUSTOM_DASHBOARDS)) {
            return false;
        }
        if (!interfaceSettingsState.enhancedPermissions) {
            return true;
        }
        const dashPerm = this.userPermissionDtos.find(dto => dto.label === PermissionName.CustomDashboards);
        return dashPerm?.grants.update;
    }

    get hasNylasEmailEnabled() {
        return featureStore.isFeatureEnabled(FeatureConstants.NYLAS_EMAIL);
    }

    // Whether a staff member is being added
    private get isAdd() {
        return this.user === null;
    }

    get isBrandsEmailFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.BRAND_EMAILS) &&
            featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isFranchiseModeFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    private get isCorpUser() {
        return authState.isCorporateUser;
    }

    private get isCrmPlus() {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isActuallyCrm() {
        return featureStore.isActuallyCrm;
    }

    private get isCurUser() {
        return this.user?.id === authState.id;
    }

    private get isSingleCenter() {
        return centersStore.count <= 1;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    private get legacyUrl() {
        return getHostString(baseUrl);
    }

    private get hasTwoWayEmail(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.TWO_WAY_EMAILS);
    }

    private get hasTwoWayText(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.TWO_WAY_TEXTS);
    }

    private get showNylas(): boolean {
        return this.hasNylasEmailEnabled && this.isCurUser && this.isCenterLevel;
    }

    private get userOrgLocations() {
        if (!this.userDto.org_id) {
            return [];
        }
        return orgsState.centersByOrgId(this.userDto.org_id);
    }

    // Handles showing the modal
    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    // The orgs in the org level list
    private get orgs() {
        return orgsState.stored;
    }

    private get passwordFieldRules() {
        return (this.isAdd || this.changePassword) ? this.passwordField : [];
    }

    private get passwordMatchRule() {
        return [
            this.userDto?.password === this.passwordConfirm || 'Passwords must match'
        ];
    }

    private get showEtRepLocationsList(): boolean {
        return this.enrollmentCenterActive && this.userDto.is_on_enrollment_team && this.etRepFlow === EnrollmentRepFlowConstants.ASSIGNMENT_FLOW_LOCATION;
    }

    private get timezones(): Array<Timezone> {
        return timezonesStore.stored;
    }

    // Set up dto for the staff member
    @Watch('modelValue')
    private async activationChanged(flag: boolean) {
        customStaffValuesStore.clearChanges();
        this.userHasNylas = false;
        this.userHasNylasEmail = false;
        this.userHasNylasCalendar = false;
        if (flag) {
            this.isLoaded = false;
            loadingState.loadingIncrement(this.loadingKey);
            if (this.user) {
                this.userDto = userMapper.toUpdateDto(this.user);
                this.userHasNylas = this.user.is_nylas_active;
                this.userHasNylasEmail = this.user.is_nylas_email_active;
                this.userHasNylasCalendar = this.user.is_nylas_calendar_active;
                const settings = await userSettingsRepository.get(this.user.id);
                this.userSettingsDto = userSettingsMapper.toUpdateDto(settings);
                this.staffPositions = await staffPositionService.getPositionsForUser(this.user);

                let terminationTimeTz = null;
                if (this.userDto && this.userDto.termination_time) {
                    terminationTimeTz = utcToZonedTime(this.userDto.termination_time, this.user.timezone);
                }
                this.terminationDate = terminationTimeTz
                    ? terminationTimeTz.toISOString().replace('.000', '')
                    : null;

                if (this.staffPositions.length === 0) {
                    // Provide a blank position for the select so it has the user
                    this.staffPositions = [{
                        user: this.user,
                        position: null,
                        center: null
                    } as StaffPositionInterface];
                } else {
                    // Open the drop-down by default if they have positions
                    this.showPositionOptions = true;
                }
                if (this.enrollmentCenterActive && this.etRepFlow === EnrollmentRepFlowConstants.ASSIGNMENT_FLOW_LOCATION) {
                    const assignedCentersPositions = await staffRepository.getStaffMemberPositions(this.user.id);
                    this.etRepAssignedLocations = assignedCentersPositions.filter(entity => (entity.enrollment_rep && entity.enrollment_rep.id === this.userDto.id)).map(entity => entity.center.id);
                    this.ogEtRepAssignedLocations = cloneDeep(this.etRepAssignedLocations);
                }
                this.dashboardPermission = await userPermissionsState.getPermissionByName({
                    userId: this.user.id,
                    permissionName: PermissionName.DashboardBothViews
                }) as UserPermission;
                this.canAccessBothDashboards = this.dashboardPermission.grants.update;

                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            } else {
                this.userDto = userMapper.toCreateDto({} as User);
                this.userDto.is_notified_on_replies = true;
                this.userSettingsDto = { add_ons: null, notification_centers: [], notification_forms: [], notification_customer_form: false };
                this.dashboardPermission = null;
                if (this.userDto && !this.userDto.center_id) {
                    this.canAccessBothDashboards = true;
                }

                this.staffPositions = [{
                    user: null,
                    position: null,
                    center: null
                } as StaffPositionInterface];
            }

            if (this.isCrmPlus) {
                this.userPermissionDtos = await this.setUserPermissionViewDtos();
            }
            this.isLoaded = true;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('etRepAssignedLocations')
    async assignedLocationsUpdated(newValue: Array<number>, oldValue: Array<number>) {
        if (newValue.length > oldValue.length && this.isLoaded) {
            const lastAddedCenterId = newValue[newValue.length - 1];
            if (this.mappedEtCenters.get(lastAddedCenterId)) {
                const lastAddedCenter = this.mappedEtCenters.get(lastAddedCenterId);
                if (lastAddedCenter) {
                    await this.etMembersCheck(lastAddedCenter);
                }
            } else {
                const lastAddedCenter = await centersStore.getAccessibleCenterById(lastAddedCenterId);
                this.mappedEtCenters.set(lastAddedCenterId, lastAddedCenter);
                await this.etMembersCheck(lastAddedCenter);
            }

        }
    }

    @Watch('userDto.org_id', { immediate: true })
    async updateLocations(data: number) {
        this.reassignFromLocation = null;
        this.locations = [];
        this.reassignToUser = null;

        if (data) {
            this.isCorpLevel = false;
            this.locations = orgsState.centersByOrgId(data);
            this.etRepAvailableLocations = orgsState.centersByOrgId(data);
            const org = orgsState.stored.find(org => org.id === data) ?? null;
            this.isCenterLevel = !!org && !!org.center;
            if (org && !org.parent_organization) {
                this.isCorpLevel = true;
            }
            await this.enableDashboardPermission();
            // Default all user permissions to unchecked unless/until Org Level is set to something
            // other than a single location. Then default everything to checked for Corp
            // and only view/edit settings nav for area,district,regional etc.
            // This won't override user's who already have the permissions set.
            if (this.isCrmPlus) {
                let isLocation = false;
                if (
                    (this.locations.length === 1 && this.locations[0].organization_id === data) ||
                    (this.locations.length === 2 && this.locations[0].organization_id === null && this.locations[1].organization_id === data)
                ) {
                    isLocation = true;
                }
                this.corpOnlyPermissions = [PermissionName.AutomationReminders, PermissionName.AutomationWorkflows];
                if (this.isFranchiseModeFeatureEnabled && !isLocation) {
                    this.showUserPermissionsSection = true;
                } else {
                    if (!this.isCorpLevel) {
                        this.corpOnlyPermissions = [PermissionName.AutomationReminders, PermissionName.AutomationWorkflows, PermissionName.AutomationDripCampaign];
                    }
                    this.showUserPermissionsSection = !this.isCurUser || this.isSuperUser;
                }
                const setDefaults = this.user ? null : !isLocation;
                this.userPermissionDtos = await this.setUserPermissionViewDtos(setDefaults, this.isCorpLevel);
                if (!this.userPermissionDtos.length) {
                    this.showUserPermissionsSection = false;
                }
            }

            // Populate the list of landing pages the user can receive notifications for
            this.notificationLandingPages = await landingPageUtils.getLandingPagesForNotifications(data);
            sortByObjectProperty(this.notificationLandingPages, 'name', SortConstants.ASC, true);
            if (this.userSettingsDto && this.userSettingsDto.notification_forms.length === 0) {
                this.userSettingsDto.notification_forms = this.notificationLandingPages.map(landingPage => landingPage.id);
            }
        }

        this.locations.unshift({
            id: null,
            name: 'Select a Location',
            organization_id: null
        });
    }

    // When a user is marked as inactive, but has staff positions, warn about that
    @Watch('userDto.is_active', { immediate: true })
    async checkForPositions() {
        if (this.userDto.is_active || !this.user || !this.modelValue) {
            if (this.userDto.is_active) {
                this.terminationDate = this.userDto.termination_time;
            }
            return;
        }
        if (!this.userDto.is_active && !this.userDto.termination_time) {
            this.terminationDate = format(
                utcToZonedTime(
                    new Date(), this.user.timezone
                ),
                'yyyy-MM-dd',
                { timeZone: this.user.timezone }
            );
        }

        const positions = await staffPositionService.getPositionsForUser(this.user);
        if (positions.length > 0) {
            const confirm = await this.$swal({
                icon: 'warning',
                text: `Warning ${this.userDto.first_name} ${this.userDto.last_name}'s information was being used for template variables. If you proceed, nobody will be assigned to these merge variables. Please immediately reassign someone to these merge variables to prevent problems with your text and email communications`,
                showConfirmButton: true,
                confirmButtonText: 'Continue',
                showCancelButton: true
            });
            if (!confirm.isConfirmed) {
                // Set the user back to active
                this.userDto.is_active = true;
                return;
            }
            for (const position of positions) {
                // Mark them all to delete so we don't have inactive users in there
                this.staffPositionsToDelete.push(position);
            }
        }
    }

    // Check the 'can access both dashboards' checkbox when user is set to the enrollment team.
    @Watch('userDto.is_on_enrollment_team')
    private autoCheckBothDashboards() {
        if (this.userDto && this.userDto.is_on_enrollment_team) {
            this.canAccessBothDashboards = true;
        }
    }

    @Watch('terminationDate')
    private updateTerminationTime() {
        if (this.terminationDate && this.terminationDate.length > 0) {
            this.terminationDate = this.terminationDate.split('T')[0];
            this.userDto.termination_time = zonedTimeToUtc(this.terminationDate + ' 00:00:00', this.userDto.timezone).toISOString().replace('.000', '');
        } else {
            this.userDto.termination_time = '';
        }
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);

        const orgsResponse = orgsState.init();
        const timezonesResponse = timezonesStore.init();
        const featuresResponse = featureStore.init();
        await orgsResponse;
        await timezonesResponse;
        await featuresResponse;

        const promises = [];
        promises.push(centersStore.initAccessibleCenters());
        promises.push(centersStore.initCount());
        if (this.enrollmentCenterActive) {
            const ecPromise = enrollmentCenterState.init();
            promises.push(ecPromise);
        }
        if (this.isCrmPlus) {
            const permissionPromise = permissionsStore.init();
            const customFieldsPromise = customFieldsStore.init();
            const interfaceSettingsPromise = interfaceSettingsState.init();
            promises.push(permissionPromise, customFieldsPromise, interfaceSettingsPromise);
        }
        await Promise.all(promises);
        // Remove drip campaign permission filter when in franchise mode
        if (this.isFranchiseModeFeatureEnabled) {
            this.corpOnlyPermissions.splice(2, 1);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private addAnotherPosition() {
        this.staffPositions.push({
            user: this.user,
            position: null,
            center: null
        } as StaffPositionInterface);
    }

    async etMembersCheck(lastAddedCenter: Center) {
        if (lastAddedCenter.staff && lastAddedCenter.staff.enrollment_rep) {
            if ((this.user && lastAddedCenter.staff.enrollment_rep.id !== this.user.id) || !this.user) {
                const result = await this.$swal({
                    icon: 'warning',
                    text: `Warning: Another enrollment team member has already been assigned to ${lastAddedCenter.name}.  Do you want to reassign?`,
                    showConfirmButton: true,
                    confirmButtonText: 'Proceed',
                    showCancelButton: true
                });
                if (result.isDismissed) {
                    this.etRepAssignedLocations.pop();
                }
            }
        }
    }

    private async checkOrg(V: any) {
        const newOrg = await orgsState.getOrgById(V);
        if (this.user && !newOrg?.center && this.userHasNylas) {
            // @TODO: Calendar message.
            const confirm = await this.$swal({
                icon: 'warning',
                html: '<font size="+2"><b>WARNING!</b><br></font>' +
                '<p style="text-align:left;"><br>Changing this user\'s Org Level will disable their email integration; users over multiple locations cannot have integrated email accounts because it\'s not always possible to know which location to copy a guardian\'s email to when they\'re interested in multiple locations.<br></p' +
                '<p style="text-align:left;"><br>Do you want to disable this user\'s email integration and move them to a higher Org Level?</p>',
                showConfirmButton: true,
                confirmButtonText: 'Proceed',
                showCancelButton: true,
                reverseButtons: true
            });
            if (confirm.isConfirmed) {
                // @TODO: Need the calendar stuff in here too.
                // Disable the integration(s) and close the modal.
                if (this.userHasNylasEmail) {
                    nylasRepo.revokeEmailIntegrationByUserId(this.user.id).catch();
                    this.userHasNylas = this.userHasNylasCalendar;
                }
            } else {
                // return the org level back to what it was and close the modal
                this.userDto.org_id = this.user.org_id;
            }
        }
    }

    async enableDashboardPermission() {
        const org = await orgsState.getOrgById(this.userDto.org_id);
        if (org?.center) {
            this.canAccessBothDashboards = false;
        } else {
            this.canAccessBothDashboards = true;
        }
    }

    /**
     * Get the name of the landing page.
     *
     * @param landingPage
     */
    getLandingPageName(landingPage: LandingPage) {
        if (!landingPage.type) {
            return landingPage.name;
        }
        if (landingPage.type.id === LandingPageType.CUSTOMER) {
            return landingPage.name + ' Landing Page';
        }
        return `${landingPage.name} (${landingPage.type.values.value})`;
    }

    // Handles closing the modal
    private async handleClose() {
        // mutation for false;
        appState.updateManageStaffModalStatus(false);
        this.showPositionOptions = false;
        this.expandUserPermission = false;
        // Wait until the permission section is closed, otherwise, the dtos will have undefined values
        await this.$nextTick();

        this.modelValue = false;
        this.staffPositions = [];

        this.staffPositionsChanged = false;
        this.staffPositionsToSave = [];
        this.staffPositionsToDelete = [];
        this.reassignFromLocation = null;
        this.locations = [];
        this.reassignToUser = null;
        this.userPermissionDtos = [];
        this.dashboardPermission = null;
        this.panelIndex = -1;
        this.passwordConfirm = '';
        this.userPermissionDtos = [];
        this.mappedEtCenters.clear();
        this.etRepAssignedLocations = [];
        this.etRepAvailableLocations = [];
        this.form.reset();
        customStaffValuesStore.clearChanges();
    }

    isGrouped(label: string) {
        return staffUtils.getGroupedPermissionControl(label, permissionsStore.stored, interfaceSettingsState.enhancedPermissions);
    }

    isLocked(label: string) {
        return staffUtils.isGroupLocked(label, this.userPermissionDtos, permissionsStore.stored, interfaceSettingsState.enhancedPermissions);
    }

    notificationUpdated() {
        if (!this.userDto.is_notified_on_replies) {
            this.userDto.is_manager_notification_enabled = false;
        }
    }

    async nylasAdded() {
        this.userHasNylas = true;
        this.userHasNylasEmail = true;
        if (authState.id) {
            const userInfo = await staffRepository.getOne(authState.id);
            authState.storeUserInfo(userInfo);
        }
    }

    async nylasDeleted() {
        this.userHasNylas = this.user?.is_nylas_active ?? false;
        this.userHasNylasEmail = false;
        if (authState.id) {
            const userInfo = await staffRepository.getOne(authState.id);
            authState.storeUserInfo(userInfo);
        }
    }

    private positionChosen(position: StaffPositionInterface) {
        this.staffPositionsToSave.push(position);
    }

    private positionRemoved(position: StaffPositionInterface) {
        this.staffPositionsToDelete.push(position);
    }

    postPermissionClick(permission: UserPermissionViewDto) {
        staffUtils.handlePostClick(permission.grants.update, permission.label, this.userPermissionDtos, permissionsStore.stored, interfaceSettingsState.enhancedPermissions);
    }

    private async doReassign() {
        if (this.userDto && this.reassignFromLocation && this.reassignToUser) {
            loadingState.loadingIncrement(this.loadingKey);

            await taskRepo.reassignTasks(this.userDto.id as number, this.reassignFromLocation as number, this.reassignToUser as number);
            this.snackText = 'Tasks reassigned.';
            this.showSnack = true;

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    async resendVerification() {
        if (!this.user?.id) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        await staffRepository.resendVerificationEmail(this.user.id);
        loadingState.loadingDecrement(this.loadingKey);
        await this.$swal({ icon: 'info', text: 'Verification email sent.' });
    }

    // Handles saving the staff member
    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        if (!this.isCorpLevel) {
            // Prevent non-corporate user from being on the enrollment team.
            this.userDto.is_on_enrollment_team = false;
        }

        if (this.isCrmPlus) {
            // Set any updates to custom values.
            this.userDto.custom_values = customStaffValuesStore.changesForStaff(this.userDto);
        }

        // Finalize and settings dto
        const userSettingsDto = cloneDeep(this.userSettingsDto);
        userSettingsMapper.finalizeDtoForSubmission(userSettingsDto, this.notificationLandingPages);

        if (this.isAdd) {
            const user = await staffRepository.post(this.userDto);
            await userSettingsRepository.updateSettings(user.id, userSettingsDto);
            // Get store of current center
            if (user.center_id) {
                const mapping: CenterStaffMapping = await staffStore.retrieveCenterStaff(user.center_id);
                // Add new user into that center store
                mapping.staff.push(user);
                // Update store
                staffStore.storeCenterStaff(mapping);
            }

            if (this.canAccessBothDashboards) {
                // Save the dashboard permission for the staff
                // Retrieve the permission first
                this.dashboardPermission = await userPermissionsState.getPermissionByName({
                    userId: user.id,
                    permissionName: PermissionName.DashboardBothViews
                }) as UserPermission;
                const dashboardPermission = userPermissionMapper.toCreateDto(this.dashboardPermission as UserPermission);
                dashboardPermission.grants.update = true;
                await userPermissionsRepository.setPermissions(user.id, [dashboardPermission]);
            }

            if (this.isCrmPlus && this.userPermissionDtos.length) {
                // Set the other permissions
                await staffUtils.updateNewUserPermissions(user.id, this.userPermissionDtos);
            }

            await this.savePositions(user);
            if (this.enrollmentCenterActive && user.is_on_enrollment_team) {

                const payload: Array<StaffPositionUpdateDto> = this.etRepAssignedLocations.map(item => Object.assign({}, { center: item, enrollment_rep: true }));
                await staffRepository.updateStaffMemberPositions(user.id, payload);
            }
            await this.handleClose();

            this.snackText = 'User Added';
            this.showSnack = true;

            this.$emit(EventTypes.ADDED, user);
        } else {
            // Revoke Nylas if user is no longer at center level.
            if (this.userHasNylas && !this.isCenterLevel) {
                try {
                    await nylasRepo.revokeEmailIntegration();
                } catch (error) {
                    // Just ignore for now.
                }

                try {
                    await nylasRepo.revokeCalendarIntegration();
                } catch (error) {
                    // Just ignore for now.
                }

                this.userHasNylas = false;
                this.userHasNylasEmail = false;
                this.userHasNylasCalendar = false;
            }

            const userUpdateResponse = staffRepository.update(this.userDto as UserUpdateDto);
            await userSettingsRepository.updateSettings(this.user!.id, userSettingsDto);
            const dashboardPermission = userPermissionMapper.toUpdateDto(this.dashboardPermission as UserPermission);
            dashboardPermission.grants.update = this.canAccessBothDashboards;
            const permissionUpdateResponse = userPermissionsRepository.setPermissions(this.user?.id as number, [dashboardPermission]);
            await userUpdateResponse;
            await permissionUpdateResponse;

            if (this.isCrmPlus && (!this.isCurUser || this.isSuperUser)) {
                if (this.userDto.org_id !== 1) {
                    // Set the permissions to false that aren't visible
                    for (const dto of this.userPermissionDtos) {
                        if (this.corpOnlyPermissions.includes(dto.label)) {
                            dto.grants.update = false;
                        }
                    }
                }
                await staffUtils.updateUserPermissions(this.user?.id as number, this.userPermissionDtos);
            }

            if (this.userDto.center_id) {
                await staffStore.retrieveCenterStaff(this.userDto.center_id as number);
            }

            if (this.user && (this.user.center_id !== this.userDto.center_id)) {
                await staffStore.retrieveCenterStaff(this.user.center_id as number);
            }

            await this.savePositions();
            if (this.enrollmentCenterActive && this.user && this.userDto.is_on_enrollment_team && !isEqual(this.ogEtRepAssignedLocations, this.etRepAssignedLocations)) {
                // Combine original assigned locations and current assigned locations into a new array through a set first to de-duplicate, so we can grab both original ids and current ids to update
                const payload = staffUtils.getPayloadForUpdatingEtRepLocations(this.ogEtRepAssignedLocations, this.etRepAssignedLocations);
                await staffRepository.updateStaffMemberPositions(this.user.id, payload);
            }
            await this.handleClose();
            this.snackText = `${this.user?.first_name} ${this.user?.last_name} Updated`;
            this.showSnack = true;
            if (this.user?.id) {
                if (authState.id === this.user.id) {
                    const userInfo = await staffRepository.getOne(this.user.id);
                    authState.storeUserInfo(userInfo);
                }
                this.$emit(EventTypes.UPDATED, this.user.id);
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async savePositions(user?: User) {
        for (const position of this.staffPositionsToSave) {
            // If we just created the user, inject it now.
            if (user) {
                position.user = user;
            }

            await staffPositionService.save(position);
            this.staffPositionsChanged = true;
        }

        await staffPositionService.batchDelete(this.staffPositionsToDelete);

        if (this.staffPositionsToDelete.length > 0) {
            this.staffPositionsChanged = true;
        }
    }

    /**
     * Set the dtos for user permissions.
     */
    private async setUserPermissionViewDtos(
        setGrantDefaults: boolean | null = null,
        isCorpLevel = false
    ): Promise<Array<UserPermissionViewDto>> {
        const dtos: Array<UserPermissionViewDto> = [];
        let entity = null;
        let setDefaults = setGrantDefaults;
        const permissionsAvailable = await staffUtils.getPermissionsAvailableToEdit(this.user?.id ?? null);
        for (const permission of permissionsAvailable) {
            if (this.user !== null) {
                entity = await userPermissionsState.getPermissionByName({
                    userId: this.user.id,
                    permissionName: permission.label
                }) as UserPermission;
            }
            setDefaults = setGrantDefaults;
            if (setDefaults !== null && permission.label !== PermissionName.SettingsNav && !isCorpLevel) {
                setDefaults = false;
            }
            if (setDefaults !== null && permission.is_for_enhanced) {
                setDefaults = staffUtils.getEnhancedDefault(permission.label, isCorpLevel);
            }
            dtos.push(userPermissionMapper.toViewDto(entity, permission, setDefaults));
        }
        return dtos;
    }

    /**
     * Update the list of users which you can assign tasks to.
     * @param orgId
     * @private
     */
    private async updateUserList(orgId: number | null) {
        this.userReassignList = [];
        this.reassignToUser = null;
        if (orgId) {
            loadingState.loadingIncrement(this.loadingKey);
            this.userReassignList = (await staffRepository.getAllStaffThatCanAccessOrgByOrgId(orgId)).filter((user) => {
                return !user.is_service_account && (!this.user || user.id !== this.user.id);
            });
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private updateUserSettings(payload: Array<number>) {
        this.userSettingsDto.notification_centers = payload;
    }
}
