import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { CrmEntity, HateoasLink } from '@/models/base';

export abstract class BaseMapper<T extends CrmEntity> extends AbstractMapper<T> {
    protected linksToDto(links: Array<HateoasLink>): Array<number> {
        return links.map((link) => {
            return link.id;
        });
    }
}
