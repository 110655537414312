






























































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import type { Family } from '@/families/models/family';
import { FamilyMarketingRepository } from '@/families/repositories/family-marketing-repository';
import { FamilyMarketingTracking } from '@/families/models/marketing';
import { DataTableHeader } from 'vuetify';
import { MarketingCampaignsRepository } from '@/marketing/repositories/marketing-campaigns-repository';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { SelectListOption } from '@/core/models/select-list';
import { EventTypes } from '@/constants/event-type-constants';
import { LoadingStore } from '@/store/loading-store';

const authState = getModule(AuthStore, store);
const familyMarketingRepository = new FamilyMarketingRepository();
const loadingState = getModule(LoadingStore);
const marketingCampaignsRepository = new MarketingCampaignsRepository();

@Component
export default class FamilyMarketingCampaigns extends Mixins(LocaleMixin) {
    /**
     * Whether or not to allow editing.
     */
    @Prop({ type: Boolean, default: true }) readonly canEdit!: boolean;
    @Prop() family!: Family;

    private campaigns: Array<SelectListOption> = [];
    private campaignsPrev: Array<number> = [];
    private familyCampaigns: Array<SelectListOption> = [];
    private isEditing = false;
    private items: Array<FamilyMarketingTracking> = [];
    private loadingKey = 'family-marketing-campaigns';

    get headers(): DataTableHeader[] {
        const headers = [
            {
                text: 'Campaign Name',
                value: 'marketing_campaign.values.name'
            },
            {
                text: 'Added Date',
                value: 'added_datetime'
            }
        ];

        // Only include columns if anything in the list has a value
        if (this.items.filter(tracking => tracking.utm_source !== null).length > 0) {
            headers.push({ text: 'UTM Source', value: 'utm_source' });
        }

        if (this.items.filter(tracking => tracking.utm_medium !== null).length > 0) {
            headers.push({ text: 'UTM Medium', value: 'utm_medium' });
        }

        return headers;
    }

    get timezone(): string {
        return authState.userInfoObject!.timezone;
    }

    async mounted() {
        await this.updateFamily();
    }

    @Watch('family')
    async updateFamily() {
        this.isEditing = false;
        this.familyCampaigns = [];
        this.campaignsPrev = [];
        this.campaigns = [];
        this.items = [];
        if (!this.family) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        this.items = await familyMarketingRepository.retrieve(this.family.id);
        if (this.family.marketing_campaigns) {
            for (const campaign of this.family.marketing_campaigns) {
                if (!this.familyCampaigns.find(e => e.value === campaign!.id!)) {
                    this.familyCampaigns.push({ value: campaign!.id!, text: campaign.values!.name! });
                    this.campaignsPrev.push(campaign.id);
                }
            }
        }
        // Ensure we have at least 1 empty slot to add campaigns
        if (this.familyCampaigns.length === 0) {
            this.familyCampaigns.push({ value: 0, text: 'None' });
        }

        // Build a list of active campaigns
        const campaigns = (await marketingCampaignsRepository.retrieve(authState.userInfoObject!.org_id)).filter(campaign => campaign.is_active);
        if (campaigns.length) {
            // Ensure there's a "none" option for campaigns, if there are any available
            this.campaigns.push({ value: 0, text: 'None' });
        }
        for (const campaign of campaigns) {
            this.campaigns.push({ value: campaign!.id, text: campaign.name });
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('familyCampaigns', { deep: true })
    emitUpdate() {
        if (!this.isEditing) {
            return;
        }
        // id 0 means "None". Filter those out and send "null" if nothing left.
        const ids = this.familyCampaigns.map(item => item.value).filter(id => id !== 0);
        // Has anything changed and are all values selected?
        if (ids !== this.campaignsPrev) {
            this.$emit(EventTypes.UPDATED, ids.length ? ids : null);
        }
    }

    addAnother() {
        this.familyCampaigns.push({ value: 0, text: 'None' });
    }
}
