






































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    CrmTypeList,
    CrmTypeOption,
    CrmTypeOptionCreateDto,
    CrmTypeOptionUpdateDto
} from '@/crm-types/models/crm-type';
import { getCrmListForField } from '@/crm-types/field-utils';
import type { Field } from '@/crm-types/models/field-models';
import { ChildFields, FieldTypes, GuardianFields } from '@/crm-types/models/field-models';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';

const typesStore = getModule(CrmTypesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class StandardFieldsSelectList extends Mixins(BasicValidationMixin, LocaleMixin) {
    @Prop({ required: true }) readonly field!: Field;

    private default_radio_select = -1;
    private loadingKey = 'standardFieldsSelectList';
    private newDividerCounter = 0;
    private optionsRemoved: Array<number> = [];
    private selectListOptions: Array<CrmTypeOptionUpdateDto> = [];
    private orginalOptions: Array<CrmTypeOption> = [];
    private typeList: CrmTypeList | null = null;

    private get headers(): Array<DataTableHeader> {
        return [
            {
                text: '',
                value: 'handle'
            },
            {
                text: 'Select List Options (required)',
                value: 'value',
                align: 'center'
            },
            {
                text: 'Default',
                value: 'is_default',
                align: 'center'
            },
            {
                text: 'Delete',
                value: 'delete',
                align: 'center'
            }
        ];
    }

    private get isSelect(): boolean {
        return (this.field.type.id === FieldTypes.SELECT_LIST && this.field.value !== GuardianFields.PHONE_TYPE && this.field.value !== GuardianFields.ENROLLMENT_REP && this.field.value !== ChildFields.CLASS);
    }

    private async created(): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);
        this.default_radio_select = -1;
        this.newDividerCounter = 0;
        this.optionsRemoved = [];
        if (this.isSelect) {
            this.typeList = getCrmListForField(this.field);
            if (!this.typeList) {
                return;
            }
            const typesPromise = typesStore.initList(this.typeList);
            await typesPromise;

            this.orginalOptions = typesStore.listVisibleOptions(this.typeList);
            this.selectListOptions = this.orginalOptions.map(a => Object.assign({}, a) as CrmTypeOptionUpdateDto);

            for (const option of this.selectListOptions) {
                if (option.is_default) {
                    this.default_radio_select = option.id;
                    break;
                }
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('default_radio_select')
    watchRadio() {
        for (const option of this.selectListOptions) {
            option.is_default = !!(option.id && (this.default_radio_select === option.id));
        }
    }

    @Watch('selectListOptions', { deep: true })
    watchChanges() {
        const dtoArray: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto> = [];
        let order = 0;

        for (const option of this.selectListOptions) {
            let defaultCheck = false;
            if (option.id && (this.default_radio_select === option.id)) {
                defaultCheck = true;
            }
            const fieldDto: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto = {
                is_default: defaultCheck,
                value: option.value,
                order
            };

            if (option.id > 0) {
                fieldDto.id = option.id;
            }
            if (option.id && option.id < 0) {
                // Emit new selectListOptions added to the select list
                dtoArray.push(fieldDto);
            } else {
                // Only emits selectListOptions that have updated
                for (const e of this.orginalOptions) {
                    if (option.id && (option.id === e.id) && (e.is_default !== option.is_default || e.value !== option.value || e.order !== order)) {
                        dtoArray.push(fieldDto);
                        break;
                    }
                }
            }
            ++order;
        }
        this.$emit(EventTypes.UPDATED, { deletedOptions: this.optionsRemoved, updatedOptions: dtoArray, crmTypeList: this.typeList });
    }

    private clearRadioSelection() {
        this.default_radio_select = -1;
    }

    private optionAdded() {
        this.newDividerCounter++;
        const divider: CrmTypeOptionUpdateDto = {
            id: -2 * this.newDividerCounter,
            is_default: false,
            is_editable: true,
            order: 0,
            value: ''
        };
        this.selectListOptions.push(divider);
    }

    private optionsSorted(sortEvent: SortableEvent) {
        const movedOption = this.selectListOptions.splice(sortEvent.oldIndex!, 1)[0];
        this.selectListOptions.splice(sortEvent.newIndex!, 0, movedOption);
    }

    private async optionRemoved(divider: CrmTypeOptionUpdateDto) {
        const arrayInd = this.selectListOptions.indexOf(divider);
        if (divider.id && divider.id > 0) {
            const result = await this.$swal({
                text: 'Warning: Are you sure you want to delete this?',
                showCancelButton: true,
                confirmButtonText: 'Proceed'
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.selectListOptions.splice(arrayInd, 1);
                }
                this.optionsRemoved.push(divider.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.selectListOptions.splice(arrayInd, 1);
            }
        }
    }

}
