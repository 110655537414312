import { AuthStore } from '@/store/auth-store';
import { RandomUtils } from '@/core/random-utils';
import pkceChallenge from 'pkce-challenge';
import { idpBaseUrl } from '@/core/env-vars';

export class Sso {
    static getSsoAuthUrl(authState: AuthStore, redirect: string | null) {
        const nonce = RandomUtils.getRandomString(32);
        const challenge = pkceChallenge();
        const params = {
            client_id: 'enroll',
            response_type: 'code',
            scope: 'openid',
            redirect_uri: window.location.protocol + '//' + window.location.host + '/#/code',
            code_challenge: challenge.code_challenge,
            code_challenge_method: 'S256',
            state: nonce
        };
        const baseIdp = new URL(idpBaseUrl + '/authorize');
        baseIdp.search = new URLSearchParams(params).toString();
        authState.storeOidcSetup({ state: nonce, verifier: challenge.code_verifier });
        authState.storeRedirectUrl(redirect);
        return baseIdp.toString();
    }

    static getSsoLogoutUrl(authState: AuthStore, redirect: string | null = null) {
        const baseIdp = new URL(idpBaseUrl + '/logout');
        const params = {
            post_logout_redirect_uri: window.location.protocol + '//' + window.location.host
        };
        if (redirect) {
            params.post_logout_redirect_uri = redirect;
        }
        baseIdp.search = new URLSearchParams(params).toString();
        authState.clearIdpLogout();
        return baseIdp.toString();
    }

    static getSsoUnauthorizedUrl() {
        const baseIdp = new URL(idpBaseUrl + '/unauthorized');
        const params = {
            from: 'enroll'
        };
        baseIdp.search = new URLSearchParams(params).toString();
        return baseIdp.toString();
    }

}
