



























import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { VEmojiPicker } from 'v-emoji-picker';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: {
        BaseClose,
        VEmojiPicker
    }
})
export default class EmojiPicker extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @PropSync('textField', { type: String }) textFieldSynced!: string;
    get modalValue(): boolean {
        return this.value;
    }

    set modalValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    private close() {
        this.modalValue = false;
        this.$emit('closed');
    }

    private selectEmoji(emoji: any) {
        this.textFieldSynced += emoji.data;
        this.close();
    }
}
