import { AbstractRepository } from '@/repositories/abstract-repository';
import { LocationDetail, LocationDetailDto } from '@/enrollment-center/models/enrollment-center-location-models';

export class LocationDetailsRepository extends AbstractRepository<LocationDetail> {
    readonly endpoint = '/centers';

    public async getLocationDetails(centerId: number): Promise<Array<LocationDetail>> {
        const response = await this.client.get(`${this.endpoint}/${centerId}/call-center-settings/details`);
        return response.data;
    }

    public async updateLocationDetail(centerId: number, detailId: number, newValue: LocationDetailDto) {
        await this.client.put(`${this.endpoint}/${centerId}/call-center-settings/details/${detailId}`, newValue);
    }
}
