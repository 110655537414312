






















































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { StatusesStore } from '@/families/store/statuses-store';
import { ConversionTimingParameters, ConversionTimingReportType } from '@/reports/standard/standard-special-reports';
import { StandardReportUtils } from '@/reports/standard-report-utils';
import ConversionTimingPeriodComponent
    from '@/reports/components/select-fields-modal/ConversionTimingPeriodComponent.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { getGmtOffset } from '@/date-time/date-time-utils';
import { baseLocales } from '@/locales/locales';
import { Report } from 'flexmonster';

const loadingState = getModule(LoadingStore);
const statusesState = getModule(StatusesStore);
const authState = getModule(AuthStore, store);

@Component({
    components: { ConversionTimingPeriodComponent }
})
export default class ConversionTimingView extends Mixins(LocaleMixin, BasicValidationMixin) {
    @PropSync('canView') canViewSync!: boolean;
    @Prop() readonly savedReport!: Report | null;
    private loadingKey = 'reportSelectFieldsModal';
    private reportParams: ConversionTimingParameters | null = null;
    private isLoaded = false;
    private deletedEvent = EventTypes.DELETED;

    private typeOptions = [
        {
            text: 'View results as Average Time to Convert',
            value: ConversionTimingReportType.AVG
        },
        {
            text: 'View results as Range of Time to Convert (#)',
            value: ConversionTimingReportType.PERIOD_COUNT
        },
        {
            text: 'View results as Range Time to Convert (%)',
            value: ConversionTimingReportType.PERIOD_PCT
        }
    ];

    get fromStatusName() {
        return this.statuses.find(status => status.id === this.reportParams?.from_status)?.name ?? '';
    }

    get statuses() {
        return statusesState.statuses;
    }

    get needPeriods() {
        return this.reportParams && this.reportParams.report_type !== ConversionTimingReportType.AVG;
    }

    @Watch('reportParams', { immediate: true, deep: true })
    viewUpdated() {
        if (!this.reportParams) {
            return;
        }
        const currentCustomerDb = authState.isAssumingOtherCustomer ? authState.currentCustomerDb : 0;
        const offsetString = 'GMT' + getGmtOffset(authState.userTimeZone) + ':';
        const dateFormat = baseLocales[this.$i18n.locale].dateFormat;
        const reportData = StandardReportUtils.getConversionTimingReport(this.reportParams, currentCustomerDb ?? null, offsetString, dateFormat, authState.token ?? '');
        this.$emit(EventTypes.UPDATED, reportData);
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await statusesState.init();
        this.reportParams = StandardReportUtils.getDefaultConversionTimingParams(statusesState.statuses, this.savedReport);
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    addPeriod() {
        if (!this.reportParams) {
            return;
        }
        StandardReportUtils.addConversionTimingPeriod(this.reportParams.periods);
    }

    deletePeriod(index: number) {
        if (!this.reportParams) {
            return;
        }
        this.reportParams.periods.splice(index, 1);
    }
}
