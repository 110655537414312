import {
    CreateTextDto,
    TextGroupDto,
    TextMessage,
    OutgoingTextUpdateDto,
    GroupTextDto
} from '@/communications/messages/models/text';
import { MessagesRepository } from '@/communications/messages/repositories/messages-repository';

export class TextsRepository extends MessagesRepository<TextMessage> {
    protected endpoint = 'texts';

    public async sendText(newText: CreateTextDto | TextGroupDto): Promise<void> {
        await this.client.post(this.endpoint + '/outgoing', newText);
    }

    public async sendFacebookMessage(newFBMessage: CreateTextDto): Promise<void> {
        await this.client.post('facebook/outgoing', newFBMessage);
    }

    public async sendGroupText(filterId: number, dto: GroupTextDto): Promise<void> {
        await this.client.post(`groups/${filterId}/histories/texts`, dto);
    }

    /**
     * Delete a pending outgoing text.
     * Should return empty data.
     *
     * @param msgId
     */
    public async cancelPendingText(msgId: number): Promise<any> {
        const response = await this.client.delete(`communications/${this.endpoint}/outgoing/${msgId}`);
        return response.data;
    }

    /**
     * Update a pending outgoing text.
     *
     * @param msgId
     * @param dto
     */
    public async updatePendingText(msgId: number, dto: OutgoingTextUpdateDto): Promise<Text> {
        const response = await this.client.patch(`communications/${this.endpoint}/outgoing/${msgId}`, dto);
        return response.data;
    }
}
