import { StoreModuleTypes } from '@/constants/store-constants';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import { OrgLevelsRepository } from '@/organizations/levels/repositories/org-levels-repository';
import store from '@/store';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module } from 'vuex-module-decorators';

export interface OrgLevelsState extends AbstractEntityState<OrganizationLevel> {
    entities: Array<OrganizationLevel>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.ORG_LEVELS
})
export class OrgLevelsStore extends AbstractApiStore<OrganizationLevel> implements OrgLevelsState {
    readonly repository = new OrgLevelsRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    public get stored(): Array<OrganizationLevel> {
        return this.entities;
    }
}
