import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity } from '@/models/base';

export interface Class extends CrmEntity {
    name: string;
    code: string;
    capacity: number;
    begin_age: number;
    end_age: number;
    external_system_id?: number | string | null;
    external_system_id_two?: number | string | null;
    next_free_date: string | null;
    mon_am_free: number;
    mon_pm_free: number;
    tue_am_free: number;
    tue_pm_free: number;
    wed_am_free: number;
    wed_pm_free: number;
    thur_am_free: number;
    thur_pm_free: number;
    fri_am_free: number;
    fri_pm_free: number;
    sat_am_free: number;
    sat_pm_free: number;
    sun_am_free: number;
    sun_pm_free: number;
}

export interface ClassCreateDtoInterface extends AbstractCreateDto {
    name: string;
    capacity: number;
    begin_age: number | null;
    end_age: number | null;
    external_system_id?: number | string | null;
    external_system_id_two?: number | string | null;
    next_free_date: string | null;
    mon_am_free: number;
    mon_pm_free: number;
    tue_am_free: number;
    tue_pm_free: number;
    wed_am_free: number;
    wed_pm_free: number;
    thur_am_free: number;
    thur_pm_free: number;
    fri_am_free: number;
    fri_pm_free: number;
    sat_am_free: number;
    sat_pm_free: number;
    sun_am_free: number;
    sun_pm_free: number;
}

export class ClassCreatePostDto implements ClassCreateDtoInterface {
    name = '';
    capacity = 1;
    begin_age: number | null = null;
    end_age: number | null = null;
    external_system_id?: number | string | null;
    external_system_id_two?: number | string | null;
    next_free_date: string | null = null;
    mon_am_free = 0;
    mon_pm_free = 0;
    tue_am_free = 0;
    tue_pm_free = 0;
    wed_am_free = 0;
    wed_pm_free = 0;
    thur_am_free = 0;
    thur_pm_free = 0;
    fri_am_free = 0;
    fri_pm_free = 0;
    sat_am_free = 0;
    sat_pm_free = 0;
    sun_am_free = 0;
    sun_pm_free = 0;
}

export interface ClassUpdateDtoInterface extends ClassCreateDtoInterface, AbstractUpdateDto {
    id: number;
}
