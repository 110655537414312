













import { Component, Vue, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';

const loadingState = getModule(LoadingStore);

// we use VueX to store whether or not loading is happening
@Component
export default class Loading extends Vue {
    @Prop({
        type: String,
        default: LOADING_GLOBAL_KEY
    }) readonly loadingKey!: string;

    private get loading() {
        return loadingState.isLoading(this.loadingKey);
    }
};
