import { getModule } from 'vuex-module-decorators';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import {
    Integration,
    IntegrationDisplayInfo,
    IntegrationPartner,
    MultiCmsInfo,
    ServiceAccount
} from '@/integrations/models/integration';
import { IntegrationPartnerIds, IntegrationPartners, IntegrationTypes } from '@/integrations/integration-constants';
import { Regions } from '@/locales/locales';
import { IntegrationStore } from '@/integrations/store/integration-store';
/**
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);
/**/
const integrationRepo = new IntegrationRepository();
const integrationState = getModule(IntegrationStore);

export default class IntegrationService {
    private readonly region: Regions;
    private activeCmsIntegrations: Array<Integration> =[];
    private activeIntegrations: Array<Integration> = []
    private activeReferralServicesArray: Array<ServiceAccount> = [];
    private activeReferralServicesMap: Map<number, ServiceAccount> = new Map();
    private ccfIntegrations: Array<string> = [];
    private cmsIds: Array<number> = [];
    private cmsPartners: Array<IntegrationPartner> = [];
    private activeCmsPartners: Array<IntegrationPartner> = [];
    private partnersList: Array<IntegrationPartner> = [];
    private referralServiceOptions: Array<IntegrationPartner> = [];
    private referralServicePartner: IntegrationPartner | null = null;
    private serviceAccounts: Array<ServiceAccount> = [];

    constructor(
        region: Regions = Regions.us
    ) {
        this.region = region;
    }

    /**
     * Retrieves the list of id's for all CMS integrations.
     *
     * @param { boolean }rebuild
     *
     * @returns {Promise<Array<number>>}
     */
    async getCmsIds(rebuild = false): Promise<Array<number>> {
        if (!this.cmsIds || this.cmsIds.length < 1 || rebuild) {
            this.cmsIds = [1, 2, 3, 6, 7, 8, 10, 11, 12];
            const partners = await this.getPartnerList(rebuild);
            for (const partner of partners) {
                if (IntegrationTypes.CMS === partner.type.id) {
                    // If we missed an CMS id, add it in while we do this.
                    if (!this.cmsIds.includes(partner.id)) {
                        this.cmsIds.push(partner.id);
                    }
                }
            }
        }

        return this.cmsIds;
    }

    /**
     * Retrieves a list of all the active integrations for the client.
     *
     * @param { boolean }rebuild
     *
     * @returns {Promise<Array<Integration>>}
     */
    async getActiveIntegrations(rebuild = false): Promise<Array<Integration>> {
        if (!this.activeIntegrations || this.activeIntegrations.length < 1 || rebuild) {
            await integrationState.init(rebuild);
            this.activeIntegrations = integrationState.activeIntegrations;
        }

        return this.activeIntegrations;
    }

    /**
     * Retrieves all the active CMS integrations.
     *
     * @param { boolean }rebuild
     * @returns {Promise<Array<Integration>>}
     */
    async getActiveCmsIntegrations(rebuild = false): Promise<Array<Integration>> {
        if (!this.activeCmsIntegrations || this.activeCmsIntegrations.length < 1 || rebuild) {
            await this.getCmsIds(rebuild);
            await this.getActiveIntegrations();
            // Filter out any active integrations that are not CMS integrations.
            this.activeCmsIntegrations = this.activeIntegrations.filter(activeCmsIntegration => this.cmsIds.includes(activeCmsIntegration.partner_id));

            // Sort the integrations so that ProCare and QikKids are first.
            this.activeCmsIntegrations = this.activeCmsIntegrations
                .sort(
                    (a) =>
                        a.partner_id === IntegrationPartnerIds.PROCARE ||
                        a.partner_id === IntegrationPartnerIds.QIKKIDS
                            ? -1
                            : 1
                );
        }

        return this.activeCmsIntegrations;
    }

    /**
     * Return a list of the activated CMS partners.
     *
     * @param {boolean}rebuild
     * @returns Promise<Array<IntegrationPartner>>
     */
    async getActiveCmsPartners(rebuild = false): Promise<Array<IntegrationPartner>> {
        if (!this.activeCmsPartners || this.activeCmsPartners.length < 1 || rebuild) {
            await this.getPartnerList(rebuild);
            await this.getActiveCmsIntegrations(rebuild);
            this.activeCmsPartners = [];
            const partnerIds: Array<number> = [];

            for (const integration of this.activeCmsIntegrations) {
                if (!partnerIds.includes(integration.partner_id)) {
                    const found = this.partnersList.find(partner => partner.id === integration.partner_id);
                    if (found && found.type.id === IntegrationTypes.CMS) {
                        found.credentials = integration.credentials;
                        this.activeCmsPartners.push(found);
                        partnerIds.push(found.id);
                    }
                }
            }
        }

        return this.activeCmsPartners;
    }

    /**
     * Retrieves a list of the active referral service.
     *
     * @param {boolean}rebuild
     * @returns {Promise<Array<ServiceAccount>>}
     */
    async getActiveReferralServicesArray(rebuild = false): Promise<Array<ServiceAccount>> {
        if (!this.activeReferralServicesArray || this.activeReferralServicesArray.length < 1 || rebuild) {
            await this.getActiveReferralServicesMap(rebuild);
            this.activeReferralServicesArray = Array.from(this.activeReferralServicesMap.values());
        }

        return this.activeReferralServicesArray;
    }

    /**
     * Retrieves a map of the active referral services.
     *
     * @param {boolean}rebuild
     * @returns {Promise<Map<number, ServiceAccount>>}
     */
    async getActiveReferralServicesMap(rebuild = false): Promise<Map<number, ServiceAccount>> {
        if (!this.activeReferralServicesMap || this.activeReferralServicesMap.size < 1 || rebuild) {
            await this.getServiceAccounts(rebuild);
            for (const serviceAccountObject of this.serviceAccounts) {
                if (serviceAccountObject && serviceAccountObject.partner && serviceAccountObject.partner.id) {
                    this.activeReferralServicesMap.set(serviceAccountObject.partner.id, serviceAccountObject);
                }
            }
        }

        return this.activeReferralServicesMap;
    }

    /**
     * Return a list of CCF integrations.
     *
     * @param {boolean}rebuild
     *
     * @returns Promise<Array<string>>
     */
    async getCcfIntegrations(rebuild = false): Promise<Array<string>> {
        if (!this.ccfIntegrations || this.ccfIntegrations.length < 1 || rebuild) {
            await integrationState.initCcf(rebuild);
            for (const integrationObj of integrationState.ccfIntegrations) {
                if (integrationObj.organization) {
                    this.ccfIntegrations.push(integrationObj.organization.values.name);
                } else if (integrationObj.location_group) {
                    this.ccfIntegrations.push(integrationObj.location_group.values.value);
                }
            }
        }

        return this.ccfIntegrations;
    }

    /**
     * Retrieves all the CMS partners that are available to the user.
     *
     * @param {Array<number>}excludeIds
     * @param {boolean}rebuild
     *
     * @returns {Promise<Array<IntegrationPartner>>}
     */
    async getCmsPartners(excludeIds: Array<number> = [], rebuild = false): Promise<Array<IntegrationPartner>> {
        if (!this.cmsPartners || this.cmsPartners.length < 1 || rebuild) {
            this.cmsPartners = [];
            const partners = await this.getPartnerList(rebuild);
            await this.getActiveCmsPartners();
            partners.forEach(
                partner => {
                    let region = null;
                    if (partner.region) {
                        region = Regions[partner.region as keyof typeof Regions];
                        if (region && this.region !== null && this.region > -1 && this.region !== region) {
                            // Skip over this item - Regions don't match.
                            return;
                        }
                    }

                    if (IntegrationTypes.CMS === partner.type.id) {
                        // We don't talk about ProCare.
                        if (
                            IntegrationPartners.PROCARE !== partner.name ||
                            (
                                this.activeCmsPartners.length > 0 &&
                                IntegrationPartners.PROCARE === this.activeCmsPartners[0].name
                            )
                        ) {
                            this.cmsPartners.push(partner);
                        }
                    }
                }
            );
            // LineLeader Manage will lead this line.
            this.cmsPartners = this.cmsPartners.sort((a) => a.name === IntegrationPartners.MANAGE ? -1 : 1);
        }

        return this.cmsPartners.filter(partner => !excludeIds.includes(partner.id));
    }

    /**
     * Get some info about multi CMS support for the client.
     *
     * @param { Boolean }rebuild
     *
     * @returns { Promise<{ allowMultiCms: boolean; hasMultiCms: boolean }>}
     */
    async getMultiCmsInfo(rebuild = false): Promise<MultiCmsInfo> {
        await this.getActiveCmsIntegrations(rebuild);

        const ret: MultiCmsInfo = {
            allowMultiCms: false,
            hasMultiCms: false
        };

        // Determine if we can have a secondary CMS.
        // p = Primary CMS Integration.
        const p = this.activeCmsIntegrations
            .find(
                (partner) =>
                    IntegrationPartners.PROCARE === partner.name || IntegrationPartners.QIKKIDS === partner.name
            );
        if (p) {
            ret.allowMultiCms = true;
        }

        // s = Secondary CMS integration.
        const s = this.activeCmsIntegrations
            .find(
                (partner) =>
                    IntegrationPartners.MANAGE === partner.name || IntegrationPartners.KANGAROO_TIME === partner.name
            );

        if (p && s) {
            ret.hasMultiCms = true;
        }

        return ret;
    }

    /**
     * Retrieves some information about the partner and what to display for them.
     *
     * @param { string | null | undefined }partnerName
     *
     * @returns { IntegrationDisplayInfo | null }
     */
    getPartnerInfo(partnerName: string | null | undefined): IntegrationDisplayInfo | null {
        if (!partnerName) {
            return null;
        }

        switch (partnerName) {
            case IntegrationPartners.DCW:
                return {
                    href: 'https://family.daycareworks.com/',
                    img: {
                        src: require('@/assets/integrations-assets/daycare-works-logo.png'),
                        style: 'margin-top: 25px;',
                        textStyle: 'margin-top: 23px;'
                    },
                    show: true
                };
            case IntegrationPartners.EZ_CARE_ONLINE:
                return {
                    href: 'https://www.ezcaresoftware.com/',
                    img: {
                        src: require('@/assets/integrations-assets/ezcare-logo.png'),
                        style: 'margin-top: 10px;',
                        textStyle: 'margin-top: 17px;'
                    },
                    show: true
                };
            case IntegrationPartners.JACKRABBIT:
                return {
                    href: 'https://www.jackrabbitcare.com/',
                    img: {
                        src: require('@/assets/integrations-assets/jackrabbit-logo.png'),
                        style: 'margin-top: 21px;',
                        textStyle: 'margin-top: 21px;'
                    },
                    show: true
                };
            case IntegrationPartners.KANGAROO_TIME:
                return {
                    href: 'https://www.kangarootime.com/',
                    img: {
                        src: require('@/assets/integrations-assets/kangaroo-time-logo.png'),
                        style: 'margin-top: 28px;',
                        textStyle: 'margin-top: 35px;'
                    },
                    show: true
                };
            case IntegrationPartners.ONCARE_OLD:
                return {
                    href: 'https://1coresolution.com/',
                    img: {
                        src: require('@/assets/integrations-assets/1corelogo.png'),
                        style: 'margin-top: 28px;',
                        textStyle: 'margin-top: 27px;'
                    },
                    show: true
                };
            case IntegrationPartners.ONECORE:
                return {
                    href: 'https://1coresolution.com/',
                    img: {
                        src: require('@/assets/integrations-assets/1corelogo.png'),
                        style: 'margin-top: 28px;',
                        textStyle: 'margin-top: 27px;'
                    },
                    show: true
                };
            case IntegrationPartners.PROCARE:
                return {
                    href: 'https://www.procaresoftware.com/',
                    img: {
                        src: require('@/assets/integrations-assets/procare-logo.svg'),
                        style: 'margin-top: 25px;',
                        textStyle: 'margin-top: 22px;'
                    },
                    show: Regions.us === this.region
                };
            case IntegrationPartners.QIKKIDS:
                return {
                    href: 'https://qikkids.com.au/',
                    img: {
                        src: require('@/assets/integrations-assets/qikkids-logo.png'),
                        style: 'margin-top: 10px;',
                        textStyle: 'margin-top: 14px;'
                    },
                    show: Regions.aus === this.region /** || authState.isSuperuser /**/
                };
            case IntegrationPartners.SMART_CARE:
                return {
                    href: 'https://smartcare.com/',
                    img: {
                        src: require('@/assets/integrations-assets/smartcare-logo.png'),
                        style: 'margin-top: 30px;',
                        textStyle: 'margin-top: 32px;'
                    },
                    show: true
                };
            case IntegrationPartners.CARE_FOR_KIDS:
                return {
                    href: 'https://www.careforkids.com.au/',
                    img: {
                        src: require('@/assets/integrations-assets/care-for-kids-logo.png'),
                        style: 'margin-top: 30px;',
                        textStyle: 'margin-top: 26px;'
                    },
                    show: Regions.aus === this.region
                };
            case IntegrationPartners.WINNIE:
                return {
                    href: 'https://winnie.com/providers',
                    img: {
                        src: require('@/assets/integrations-assets/Winnie.png'),
                        style: 'margin-top: 8px; margin-left: 7px',
                        textStyle: 'margin-top: 8px;'
                    },
                    show: true
                };
            case IntegrationPartners.CARE_COM:
                return {
                    href: 'https://www.care.com/',
                    img: {
                        src: require('@/assets/integrations-assets/careLogo.png'),
                        style: 'margin-top: 25px;',
                        textStyle: 'margin-top: 24px;'
                    },
                    show: true
                };
            case IntegrationPartners.KINSIDE:
                return {
                    href: 'https://www.kinside.com/caregivers',
                    img: {
                        src: require('@/assets/integrations-assets/kinside-logo.svg'),
                        style: 'margin-top: 27px; margin-left: 7px',
                        textStyle: 'margin-top: 30px;'
                    },
                    show: true
                };
            case IntegrationPartners.KIDSOFT:
                return {
                    href: 'https://www.kidsoft.com.au/',
                    img: {
                        src: require('@/assets/integrations-assets/kidsoft-logo.png'),
                        style: 'margin-top: 11px;',
                        textStyle: 'margin-top: 10px;'
                    },
                    show: Regions.aus === this.region
                };
            case IntegrationPartners.INCONTACT:
                return {
                    href: 'https://www.niceincontact.com/',
                    img: {
                        src: '/img/partners/skybox.png',
                        style: 'margin-top: -12px; margin-left: 10px',
                        textStyle: 'margin-top: 5px;'
                    },
                    show: true
                };
            case IntegrationPartners.MANAGE:
                return {
                    href: 'https://www.golineleader.com/manage-engage-demo',
                    img: {
                        src: '/img/logos/ll_manage_fullcolor.svg',
                        style: 'margin-top: 24px; margin-right: 4px;',
                        textStyle: 'margin-top: 8px; text-align: center;'
                    },
                    show: true
                };
            default:
                return null;
        }
    }

    /**
     * Retrieves a list of the integration partners.
     *
     * @param rebuild
     *
     * @returns { Promise<Array<IntegrationPartner>> }
     */
    async getPartnerList(rebuild = false): Promise<Array<IntegrationPartner>> {
        if (!this.partnersList || this.partnersList.length < 1 || rebuild) {
            await integrationState.init(rebuild);
            this.partnersList = integrationState.partners;
        }

        return this.partnersList;
    }

    /**
     * Retrieves a list referral service that can be used for lead sources.
     *
     * @param {boolean}rebuild
     * @returns {Promise<Array<IntegrationPartner>>}
     */
    async getReferralServiceOptions(rebuild = false): Promise<Array<IntegrationPartner>> {
        await this.getPartnerList(rebuild);
        await this.getActiveReferralServicesMap(rebuild);

        if (
            !this.referralServiceOptions ||
            this.referralServiceOptions.length < 1 ||
            rebuild
        ) {
            this.referralServiceOptions = [];

            for (const partner of this.partnersList) {
                if (partner.type.id === IntegrationTypes.LEAD_REFERRAL && !this.activeReferralServicesMap.has(partner.id)) {
                    if (partner.name === IntegrationPartners.CARE_FOR_KIDS && this.region !== Regions.aus) {
                        continue;
                    }
                    if (partner.name === IntegrationPartners.CARE_COM && this.region !== Regions.us) {
                        continue;
                    }

                    this.referralServiceOptions.push(partner);
                }
            }

            if ((this.partnersList.length !== 0) && (this.referralServiceOptions.length > 0)) {
                this.referralServiceOptions.sort((a, b) => (a.name > b.name) ? 1 : -1);
                this.referralServicePartner = this.referralServiceOptions[0];
            }
        }

        return this.referralServiceOptions;
    }

    /**
     * Get the referral service partner.
     *
     * @param {boolean}rebuild
     * @returns {Promise<IntegrationPartner | null>}
     */
    async getReferralServicePartner(rebuild = false): Promise<IntegrationPartner | null> {
        await this.getReferralServiceOptions(rebuild);

        return this.referralServicePartner;
    }

    /**
     * Retrieves a list of service accounts.
     *
     * @param {boolean}rebuild
     * @returns {Promise<Array<ServiceAccount>>}
     */
    async getServiceAccounts(rebuild = false): Promise<Array<ServiceAccount>> {
        if (!this.serviceAccounts || this.serviceAccounts.length < 1 || rebuild) {
            this.serviceAccounts = await integrationRepo.getServiceAccounts();
        }

        return this.serviceAccounts;
    }
}
