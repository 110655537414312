import { AbstractApiStore } from '@/store/abstract-api-store';
import { TimezonesRepository } from '@/core/timezones/timezones-repository';
import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { Timezone } from '@/core/timezones/timezone';

export interface TimezonesState {
    entities: Array<Timezone>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.TIMEZONES
})
export class TimezonesStore extends AbstractApiStore<Timezone> {
    readonly repository = new TimezonesRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Timezone> {
        return this.entities;
    }
}
