import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { CrmTypeOption, CrmTypeOptionCreateDto, CrmTypeOptionUpdateDto } from '@/crm-types/models/crm-type';

export default class CrmTypeMapper extends AbstractMapper<CrmTypeOption> {
    toCreateDto(entity: CrmTypeOption): CrmTypeOptionCreateDto {
        return {
            value: entity.value,
            order: entity.order,
            is_default: entity.is_default
        };
    }

    toUpdateDto(entity: CrmTypeOption): CrmTypeOptionUpdateDto {
        const dto = this.toCreateDto(entity) as CrmTypeOptionUpdateDto;
        dto.id = entity.id;
        dto.is_editable = entity.is_editable;
        return dto;
    }

}
