import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import {
    Permission,
    PermissionGrants,
    UserPermission,
    UserPermissionDto,
    UserPermissionViewDto
} from '@/staff/models/user-permission-models';

export class UserPermissionMapper implements AbstractMapper<UserPermission> {
    toCreateDto(entity: UserPermission): UserPermissionDto {
        return {
            id: entity.id,
            grants: entity.grants ?? {} as PermissionGrants
        };
    }

    toUpdateDto(entity: UserPermission): UserPermissionDto {
        return this.toCreateDto(entity) as UserPermissionDto;
    }

    /**
     * Create a dto that can be directly sent to the API from the dto used in the interface.
     *
     * @param dto
     */
    toUpdateDtoFromViewDto(dto: UserPermissionViewDto): UserPermissionDto {
        delete dto.description;
        delete dto.help_description;
        delete dto.label;
        return dto as UserPermissionDto;
    }

    /**
     * Create a dto for use inside of templates.
     *
     * @param entity           Pass in when the user permission already exists.
     * @param permission       Acts as the backup when there is no entity.
     * @param setGrantDefaults Whether or not to set the default for grants, and what they should be.
     */
    toViewDto(entity: UserPermission | null, permission: Permission | null, setGrantDefaults: boolean | null = null): UserPermissionViewDto {
        const grantDefaults = {} as PermissionGrants;
        if (setGrantDefaults !== null) {
            grantDefaults.update = setGrantDefaults;
        }
        return {
            id: entity ? entity.id : undefined,
            grants: entity?.grants ?? grantDefaults,
            description: entity?.description ?? permission?.description ?? '',
            help_description: entity?.help_description ?? permission?.help_description ?? null,
            label: entity?.label ?? permission?.label ?? ''
        };
    }
}
