import { Reminder } from '@/communications/reminders/models/reminder-models';
import { AbstractRepository } from '@/repositories/abstract-repository';
import { EmailReminderUpdateDto } from '@/communications/templates/models/message-template';

export class EmailRemindersRepository extends AbstractRepository<Reminder> {
    readonly endpoint = 'communications/reminders/emails';

    public async update(updateDto: EmailReminderUpdateDto): Promise<Reminder> {
        const response = await this.client.put(`${this.endpoint}/${updateDto.id}`, updateDto);
        return response.data;
    }
}
