import { CrmEntity, HateoasLink } from '@/models/base';

export interface CcfInterviewMapping {
    ccf_client_id: number;
    interview_id: number;
}

export const ccfLinkType = 2136;

export interface TemplateVariable extends CrmEntity {
    tag: string;
    group: HateoasLink;
    is_link: boolean;
    is_image: boolean;
    label: null|string;
    description: null|string;
    sample: null|string;
    type: number;
    ccf_interview_mappings: Array<CcfInterviewMapping> | null;
}

export interface TemplateVariableUpdateDto {
    ccf_interview_mappings: Array<CcfInterviewMapping> | null;
}

export interface TemplateVariableCreateDto extends TemplateVariableUpdateDto {
    tag: string;
    type: number;
}

// next two are used just for display/form purposes
export interface CcfInterviewObject extends CcfInterviewMapping {
    domain: string;
}

export interface CcfVariableLinkObject {
    linkId: number;
    linkTag: string;
    interviewLinkObjects: Array<CcfInterviewObject>;
}
