export class BasicValidation {

    static passwordField = [
        (v: string) => !!v || 'Please enter a value',
        (v: string) => (!!v && v.length >= 8) || 'Password must be at least 8 characters long',
        (v: string) => (!!v && v.match(/(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])/) !== null) || 'Password must contain at least one upper case letter, one lower case letter, and one number'
    ];

    static requiredField = [
        (v: string|number) => !!v || 'Please enter a value'
    ];

    static requiredMultiSelect = [
        (v: Array<any>) => v.length > 0 || 'Please make at least one selection'
    ];

    static requiredBoolean = [
        (v: boolean|undefined) => (v === true || v === false) || 'Please enter a value'
    ];

    static requiredNumber = [
        (v: string|number) => !!v || 'Please enter a value',
        (v: string|number) => (typeof v === 'number') || 'Please enter a valid number'
    ];

    static nonRequiredNumber = [
        (v: string|number) => !v || (/^[0-9]+$/).test(String(v)) || 'Please enter a valid whole number'
    ];

    static requiredFloat = [
        (v: string|number) => !!v || 'Please enter a value',
        (v: string|number) => (/^[0-9]*\.?[0-9]+$/).test(String(v)) || 'Please enter a valid number'
    ]

    static nonRequiredFloat = [
        (v: string|number) => !v || (/^[0-9]*\.?[0-9]+$/).test(String(v)) || 'Please enter a valid number'
    ]

    static requiredNonNegativeInteger = [
        (v: string|number) => (/^[0-9]+$/).test(String(v)) || 'Please enter a valid whole number'
    ];

    static requiredInteger = [
        (v: string|number) => !!v || 'Please enter a value',
        (v: string|number) => (/^[0-9]+$/).test(String(v)) || 'Please enter a valid whole number'
    ];

    static requiredEmail = [
        (v: string) => /.+@.+\...+/.test(v) || 'Please enter valid email address'
    ];

    static notRequiredEmail = [
        (v: string) => !v || /.+@.+\...+/.test(v) || 'Invalid email address'
    ];

    static notRequiredUrl = [
        (v: string|undefined) => (
            typeof v === 'undefined' ||
            v === '' ||
                v === null ||
            /^(http|https)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i.test(v)
        ) || 'Please enter a valid URL, which must begin with http:// or https://'
    ];
}
