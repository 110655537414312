import { Editor as TinyEditor } from 'tinymce';
import StringUtils from '@/utils/string-utils';

export default class TinymceUtils {
    /**
     * Figure out the available length.
     *
     * @param tinyEditor
     * @param maxLength
     * @param forTextApi
     */
    public static calcAvailLength(tinyEditor: TinyEditor, maxLength: number, forTextApi = false) {
        if (tinyEditor.contentDocument.body.innerHTML === '<br data-mce-bogus="1">') {
            // Deal with a strange issue when TinyMCE puts in this tag when you clear content sometimes.
            tinyEditor.contentDocument.body.innerHTML = '';
        }

        let innerText = tinyEditor.getContent();

        if (forTextApi) {
            innerText = StringUtils.cleanContentForTextApi(StringUtils.convertHtmlVariableTagsToText(innerText));
        }

        return maxLength - innerText.length;
    }
}
