












    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import {
        EnrollmentCenterSettings
    } from '@/enrollment-center/models/enrollment-center-models';
    import { getModule } from 'vuex-module-decorators';
    import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
    import { LoadingStore } from '@/store/loading-store';

    const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
    const loadingState = getModule(LoadingStore);

@Component
    export default class AvailabilitySettings extends Mixins(LocaleMixin) {
        @Prop({ required: true }) readonly settings!: EnrollmentCenterSettings | null;

        private availabilitySwitch = false;
        private loadingKey = 'availabilitySettings';

        async mounted() {
            loadingState.loadingIncrement(this.loadingKey);
            this.availabilitySwitch = !this.settings?.enable_availability ?? true;
            loadingState.loadingDecrement(this.loadingKey);
        }

        @Watch('availabilitySwitch')
        watchAvailabilitySwitch() {
            if (this.availabilitySwitch !== (!this.settings?.enable_availability ?? true)) {
                settingsChangesStore.setSaveButtonForAvailability(true);
                settingsChangesStore.setEnabledAvailabilityFlag(!this.availabilitySwitch);
            } else {
                settingsChangesStore.setSaveButtonForAvailability(false);
                settingsChangesStore.setEnabledAvailabilityFlag(this.settings?.enable_availability ?? false);
            }
        }
    }
