import { Framework } from 'vuetify';
import { GoToOptions } from 'vuetify/types/services/goto';

export async function scrollToSelector(vuetify: Framework, selector: string, container: string | null = null) {
    const options: GoToOptions = {
        duration: 600,
        offset: 30,
        easing: 'easeInOutCubic'
    };
    if (container) {
        options.container = container;
    }
    await vuetify.goTo(
        selector,
        options
    );
}
