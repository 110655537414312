import { FamilyContactLink } from '@/families/models/contact';
import { FamilyLink } from '@/families/models/family';
import { GuardianLink } from '@/families/models/guardian';
import { CrmEntity } from '@/models/base';

export interface Recording extends PendingFamilyRecording {
    contact: FamilyContactLink | null;
    family: FamilyLink | null;
    guardian: GuardianLink | null;
}

export interface PendingFamilyRecording extends CrmEntity {
    datetime: string | null;
    duration: number | null;
    from: string;
    is_dismissed: boolean;
    is_dismissed_by_enrollment_team: boolean;
    is_incoming: boolean;
    is_read: boolean;
    is_read_by_enrollment_team: boolean;
    recording_url: string | null;
    to: string;
    opportunity_statuses: Array<number>;
}

export enum RecordingActions {
    PLAY = 'Play',
    MARK_AS_LISTENED = 'Mark as Listened',
    VIEW_FAMILY = 'View Family'
}
