/**
 * Store for email template blocks
 */
import { EmailTemplateBlock } from '@/communications/templates/models/email-template-block';
import { EmailTemplateBlocksRepository } from '@/communications/templates/repositories/email-template-blocks-repository';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

export interface EmailTemplateBlocksState extends AbstractEntityState<EmailTemplateBlock> {
    entities: Array<EmailTemplateBlock>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.EMAIL_TEMPLATE_BLOCKS
})
export class EmailTemplateBlocksStore extends AbstractApiStore<EmailTemplateBlock> implements EmailTemplateBlocksState {
    readonly repository = new EmailTemplateBlocksRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.retrieveAll();
        }
    }

    public get stored(): Array<EmailTemplateBlock> {
        return this.entities;
    }
}
