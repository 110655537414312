




















import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { DataTableHeader } from 'vuetify';
import { FamilyLastViewedInfo } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { AppStateStore } from '@/store/app-state-store';
import RecentlyViewedTable from '@/dashboards/components/LocationDashboardTabs/RecentlyViewedTable.vue';

const appState = getModule(AppStateStore);
const familyRepository = new FamiliesRepository();

@Component({
    components: { RecentlyViewedTable }
})
export default class LocationDashboardRecentlyViewed extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    // Properties
    private items1: Array<FamilyLastViewedInfo> = [];
    private items2: Array<FamilyLastViewedInfo> = [];
    private headers: Array<DataTableHeader> = [
        {
            text: 'Guardian Name',
            value: 'guardian'
        },
        {
            text: 'Last Viewed',
            value: 'lastViewed'
        }
    ];

    // Computed Getters / Setters
    private get org() {
        return appState.storedCurrentOrg;
    }

    get viewedKey() {
        return appState.refreshViewed;
    }

    // Watches
    @Watch('org', {
        immediate: true,
        deep: true
    })
    async orgUpdated() {
        this.items1 = [];
        this.items2 = [];
        const viewedFamiliesPromise = familyRepository.getLastViewFamiliesByStaff(this.org?.id);
        const viewedFamilies = await viewedFamiliesPromise;

        if (viewedFamilies.length > 10) {
            for (let i = 0; i < 10; i++) {
                this.items1.push(viewedFamilies.shift() as FamilyLastViewedInfo);
            }

            this.items2 = viewedFamilies;
        } else {
            this.items1 = viewedFamilies;
        }
    }

    @Watch('viewedKey')
    async viewAdded() {
        await this.orgUpdated();
    }
}
