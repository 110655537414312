import { CrmTypeOption } from '@/crm-types/models/crm-type';

export function getDefault(options: Array<CrmTypeOption>): CrmTypeOption {
    const definedDefault = options.filter((option) => {
        return option.is_default;
    });
    if (definedDefault.length) {
        return definedDefault[0];
    }
    return options[0];
}
