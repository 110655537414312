





























































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import type { EnrollmentCenterSettings } from '@/enrollment-center/models/enrollment-center-models';
import {
    EnrollmentCenterOfferingsRatings,
    EnrollmentCenterOfferingsRatingsCreateDto,
    EnrollmentCenterOfferingsRatingsUpdateDto
} from '@/enrollment-center/models/enrollment-center-models';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import OfferingsRatingsOptionsSettings from '@/enrollment-center/components/OfferingsRatingsOptionsSettings.vue';
import isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import { cloneDeep } from 'lodash';
import { FeaturesStore } from '@/features/features-store';
const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);

const featuresState = getModule(FeaturesStore);

@Component({
    components: { Draggable, OfferingsRatingsOptionsSettings }
})
export default class OfferingsRatingsSettings extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly info!: Array<EnrollmentCenterOfferingsRatings>;
    @Prop({ required: true }) readonly settings!: EnrollmentCenterSettings;
    @Prop() readonly label!: string;
    @Prop() readonly subLabel!: string;
    @Prop({ default: 'Warning: Are you sure you want to delete this?' }) readonly warningText!: string;

    private expanded: Array<number> = [];

    private loadingKey = 'offeringEccSettings';
    private items: Array<EnrollmentCenterOfferingsRatingsUpdateDto> = [];
    private newItemsCount = 0;
    private itemsRemoved: Array<number> = [];
    private newOptionsCount = 0;
    private switchToggle = false;

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    private get switchToggleLabel(): string {
        return `Disable ${pluralize(this.subLabel)}`;
    }

    async mounted() {
        this.newItemsCount = 0;
        this.newOptionsCount = 0;
        if (this.subLabel === 'Offering') {
            this.switchToggle = !this.settings?.enable_offerings;
        }

        if (this.subLabel === 'Rating') {
            this.switchToggle = !this.settings?.enable_ratings;
        }
        // Using a cloneDeep to keep the original data pulled from the API for a later comparison
        this.items = cloneDeep(this.info).map(a => Object.assign({}, a) as EnrollmentCenterOfferingsRatingsUpdateDto);
    }

    private addGroup() {
        const newGroup: EnrollmentCenterOfferingsRatingsUpdateDto = {
            id: -Math.abs(++this.newItemsCount),
            label: '',
            order: this.items.length - 1,
            options: []
        };
        this.items.push(newGroup);
        this.expanded.push(this.items.indexOf(newGroup));
    }

    private async deleteGroup(group: EnrollmentCenterOfferingsRatingsUpdateDto) {
        const index = this.items.indexOf(group);
        if (group.id && group.id > 0) {
            const result = await this.$swal({
                text: this.warningText,
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Delete',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });
            if (result.isConfirmed) {
                if (index >= 0) {
                    this.items.splice(index, 1);
                }
                this.itemsRemoved.push(group.id);
            }
        } else {
            if (index >= 0) {
                this.items.splice(index, 1);
            }
        }
        this.expanded = this.expanded.filter(index => index !== group.order);
    }

    @Watch('switchToggle')
    watchSwitch() {
        this.updateChanges();
    }

    @Watch('items', { deep: true })
    updateChanges() {
        const dtoArray: Array<EnrollmentCenterOfferingsRatingsCreateDto | EnrollmentCenterOfferingsRatingsUpdateDto> = [];
        let order = 0;
        let changedFlag = true;
        for (const item of this.items) {
            const groupDto: EnrollmentCenterOfferingsRatingsUpdateDto | EnrollmentCenterOfferingsRatingsCreateDto = {
                label: item.label,
                options: item.options,
                order: order
            };
            if (item.id > 0) {
                groupDto.id = item.id;
            }

            if (item.id && item.id < 0) {
                dtoArray.push(groupDto);
            } else {
                for (const group of this.info) {
                    // This is the comparison using the original data pulled from the API
                    if (item.id && (item.id === group.id) && (item.label !== group.label || item.order !== order || !isEqual(item.options, group.options))) {
                        dtoArray.push(groupDto);
                        break;
                    }
                }
            }
            ++order;
        }

        let initialSwitchValue = false;
        if (this.subLabel === 'Offering') {
            initialSwitchValue = !this.settings?.enable_offerings ?? true;
        }

        if (this.subLabel === 'Rating') {
            initialSwitchValue = !this.settings?.enable_ratings ?? true;
        }

        if (this.itemsRemoved.length === 0 && dtoArray.length === 0 && this.switchToggle === initialSwitchValue) {
            changedFlag = false;
        } else {
            for (const group of dtoArray) {
                if (group.label === '') {
                    changedFlag = false;
                    break;
                }
                if (group.options.length > 0) {
                    for (const option of group.options) {
                        if (option.label === '') {
                            changedFlag = false;
                            break;
                        }
                    }
                } else {
                    changedFlag = false;
                    break;
                }
            }
        }

        if (this.subLabel === 'Offering') {
            if (changedFlag) {
                settingsChangesStore.setSaveButtonForOfferings(true);
                settingsChangesStore.setUpdatedOfferings(dtoArray);
                settingsChangesStore.setDeletedOfferings(this.itemsRemoved);
                settingsChangesStore.setEnabledOfferingsFlag(!this.switchToggle);
            } else {
                settingsChangesStore.setSaveButtonForOfferings(false);
                settingsChangesStore.setUpdatedOfferings([]);
                settingsChangesStore.setDeletedOfferings([]);
                settingsChangesStore.setEnabledOfferingsFlag(!this.settings?.enable_offerings ?? false);
            }
        }
        if (this.subLabel === 'Rating') {
            if (changedFlag) {
                settingsChangesStore.setSaveButtonForRatings(true);
                settingsChangesStore.setUpdatedRatings(dtoArray);
                settingsChangesStore.setDeletedRatings(this.itemsRemoved);
                settingsChangesStore.setEnabledRatingsFlag(!this.switchToggle);
            } else {
                settingsChangesStore.setSaveButtonForRatings(false);
                settingsChangesStore.setUpdatedRatings([]);
                settingsChangesStore.setDeletedRatings([]);
                settingsChangesStore.setEnabledRatingsFlag(!this.settings?.enable_ratings ?? false);
            }
        }
    }

}
