import { ApiParameters } from '@/repositories/abstract-repository';
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import store from '@/store';
import { Task } from '@/tasks/models/task-models';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import {
    addDays,
    formatIsoDateTime,
    getTodayEnd,
    getTodayStart
} from '@/date-time/date-time-utils';
import { AuthStore } from '@/store/auth-store';
import { isTaskPastDue } from '@/tasks/task-utils';

export interface TasksState extends AbstractEntityState<Task> {
    entities: Array<Task>;
    familyTasks: Map<number, Array<Task>>;
    pastDueToursWithin30DaysCount: number;
    currentOrgId: number|undefined;
}

export interface FamilyTasksMapping {
    familyId: number;
    tasks: Array<Task>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.TASKS
})

export class TasksStore extends AbstractApiStore<Task> implements TasksState {
    readonly repository = new TasksRepository();
    readonly authState = getModule(AuthStore, store);
    familyTasks: Map<number, Array<Task>> = new Map<number, Array<Task>>();
    pastDueToursWithin30DaysCount = 0;
    currentOrgId: number|undefined = undefined;

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async initForFamily(familyId: number) {
        if (!this.familyTasks.has(familyId)) {
            await this.initPromise({
                hash: 'family-' + String(familyId),
                closure: async () => {
                    await this.retrieveFamilyTasks(familyId);
                }
            });
        }
    }

    @Action
    public async initPastDueToursWithin30DaysCount(orgId: number) {
        if (this.currentOrgId !== orgId) {
            await this.initPromise({
                hash: 'past30-' + String(orgId),
                closure: async () => {
                    await this.retrievePastDueToursWithin30DaysCount(orgId);
                }
            });
        }
    }

    @Action({ commit: 'storeFamilyTasks' })
    public async retrieveFamilyTasks(familyId: number) {
        const params = { family_id: familyId.toString(), include_completed: '1' } as ApiParameters;
        const tasks = await this.retrieveAll(params);
        return { familyId: familyId, tasks: tasks } as FamilyTasksMapping;
    }

    @Action({ commit: 'storePastDueToursWithin30DaysCount' })
    public async retrievePastDueToursWithin30DaysCount(orgId: number) {
        const todayEnd = getTodayEnd(this.authState.userTimeZone);
        const todayStart = getTodayStart(this.authState.userTimeZone);
        const dateOf30DaysAgo = addDays(todayStart, -30);
        const response = await this.repository.getToursInRange(
            formatIsoDateTime(dateOf30DaysAgo),
            todayEnd,
            null,
            {
                org_id: '' + orgId,
                include_completed: false
            });
        const count = response.entities.filter(tour => isTaskPastDue(tour)).length;
        return { count, orgId };
    }

    @Mutation
    public storeFamilyTasks(familyTasksMapping: FamilyTasksMapping): void {
        this.familyTasks.set(familyTasksMapping.familyId, familyTasksMapping.tasks);
    }

    @Mutation
    public storePastDueToursWithin30DaysCount(update: { count: number; orgId: number }) {
        this.pastDueToursWithin30DaysCount = update.count;
        this.currentOrgId = update.orgId;
    }

    @Mutation
    public decrementPastDueToursWithin30DaysCount() {
        this.pastDueToursWithin30DaysCount--;
    }

    @Mutation
    public incrementPastDueToursWithin30DaysCount() {
        this.pastDueToursWithin30DaysCount++;
    }

    public get storedPastDueToursWithin30DaysCount(): number {
        return this.pastDueToursWithin30DaysCount;
    }

    // Unfortunately getters don't work with vuex-module-decorator inheritance for some reason.
    public get storedFamilyTasks(): Map<number, Array<Task>> {
        return this.familyTasks;
    }
}
