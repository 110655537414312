export enum RouteConstants {
    DASHBOARD_LOCATION_MAIN = 'dashboards/location',
    DASHBOARD_ENROLLMENT_MAIN = 'dashboard-et',
    DASHBOARD_LOCATION_CALENDAR = 'dashboard-actions-tour-schedule',
    DASHBOARD_LOCATION_RECENT = 'dashboard-actions-recent',
    DASHBOARD_LOCATION_STARTS = 'dashboard-actions-starts',
    DASHBOARD_LOCATION_TODO = 'dashboard-actions-todo',
    FAMILY_HUB = 'family-hub'
}

export const TabTitles = {
    HOME: 'Home',
    DASHBOARD: 'Dashboard',
    CORPORATE_DASHBOARD: 'Insight Dashboard',
    LOCATION_DASHBOARD: 'Action Dashboard',
    ENROLLMENT_DASHBOARD: 'Enrollment Dashboard',
    INBOX: 'Inbox',
    INBOX_TEXTS: 'Texts',
    INBOX_EMAILS: 'Emails',
    INBOX_FACEBOOK: 'Facebook',
    INBOX_RECORDINGS: 'Recordings',
    FAMILIES: 'Families',
    WORKFLOW_AUTOMATION: 'Workflow',
    MESSAGE_TEMPLATES: 'Templates',
    REMINDER_TEMPLATES: 'Reminders',
    EMAIL_ATTACHMENTS: 'Attachments',
    MARKETPLACE: 'Marketplace',
    LANDING_PAGES: 'Landing Pages',
    DRIP_CAMPAIGNS: 'Drip Campaigns',
    PARENT_SCHEDULED_TOURS: 'Parent-Scheduled Tours',
    REPORTS: 'Reports',
    STANDARD_REPORTS: 'Standard Reports',
    CUSTOM_REPORTS: 'Custom Reports',
    PREV_SCHEDULED_REPORTS: 'Previously Scheduled',
    LEGACY_REPORTS: 'Legacy Reports',
    GROUP_COMM_HISTORY: 'Group Comm History',
    SETTINGS: 'Settings',
    ORGANIZATION_SETTINGS: 'Organizations',
    USER_SETTINGS: 'Users',
    SYSTEM_SETTINGS: 'System',
    FEATURE_SETTINGS: 'Features',
    STATUS_SETTINGS: 'Statuses',
    MARKETING_SETTINGS: 'Marketing'
};
