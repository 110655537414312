












































































































































































































import FamilySourceChart from '@/components/main/FamilySourceChart.vue';
import { BannerMessages } from '@/dashboards/banner-messages';
import BannerGreetingCorporateUser from '@/dashboards/components/BannerGreetingCorporateUser.vue';
import CompletedTasksChart from '@/dashboards/components/CompletedTasksChart.vue';
import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import TasksPastDueChart from '@/components/main/TasksPastDueChart.vue';
import DateRangeSelection from '@/components/main/DateRangeSelection.vue';
import { dashboardDateRanges } from '@/models/date-filter';
import DashboardBanner from '@/dashboards/components/DashboardBanner.vue';
import { StatsStore } from '@/store/stats-store';
import ToursCompletedGaugeGraph from '@/dashboards/components/ToursCompletedGaugeGraph.vue';
import GoodLeadsGaugeGraph from '@/dashboards/components/GoodLeadsGaugeGraph.vue';
import TasksPastDueGaugeGraph from '@/dashboards/components/TasksPastDueGaugeGraph.vue';
import OpportunitiesChart from '@/families/components/OpportunitiesChart.vue';
import InquiryPieChart from '@/dashboards/components/InquiryPieChart.vue';
import RecentlyModifiedFamilies from '@/dashboards/components/RecentlyModifiedFamilies.vue';
import GoToLegacy from '@/dashboards/views/GoToLegacy.vue';
import { StaffUtils } from '@/staff/staff-utils';
import { FeaturesStore } from '@/features/features-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import DashboardSettingsModal from '@/dashboards/components/DashboardSettingsModal.vue';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import { TabTitles } from '@/router/route-constants';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import NewFamilyToScheduledGaugeGraph from '@/dashboards/components/NewFamilyToScheduledGaugeGraph.vue';
import NewFamilyToTourComplGaugeGraph from '@/dashboards/components/NewFamilyToTourComplGaugeGraph.vue';
import ScheduledToursToComplGaugeGraph from '@/dashboards/components/ScheduledToursToComplGaugeGraph.vue';
import RegisteredAfterTourCompGaugeGraph from '@/dashboards/components/RegisteredAfterTourCompGaugeGraph.vue';

const loadingState = getModule(LoadingStore);
const loadingKey = 'corporateDashboard';
const statsState = getModule(StatsStore);
const appState = getModule(AppStateStore);
const staffUtils = new StaffUtils();
const featuresStore = getModule(FeaturesStore);
const authState = getModule(AuthStore, store);
const settingsStore = getModule(InterfaceSettingsStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        RegisteredAfterTourCompGaugeGraph,
        NewFamilyToTourComplGaugeGraph,
        BasePageTitle,
        BannerGreetingCorporateUser,
        TasksPastDueGaugeGraph,
        GoodLeadsGaugeGraph,
        TasksPastDueChart,
        DateRangeSelection,
        DashboardBanner,
        ToursCompletedGaugeGraph,
        CompletedTasksChart,
        OpportunitiesChart,
        FamilySourceChart,
        InquiryPieChart,
        NewFamilyToScheduledGaugeGraph,
        ScheduledToursToComplGaugeGraph,
        RecentlyModifiedFamilies,
        GoToLegacy,
        DashboardSettingsModal
    }
})
export default class CorporateStaffDashboard extends Mixins(LocaleMixin, PageTitleMixin) {
    private orgIds: number[] = [];
    private dateRanges = dashboardDateRanges;
    private revenue = 0;
    private loaded = false;
    private salutation = '';
    private tasksPastDueRate = 0;
    private conversionSuccessPercent = 0;
    private tourCompletedSpeedPercent = 0;
    private closing = '';
    private canSeeSettings = false;
    private settingsComponent = DashboardSettingsModal;
    private pipelineValueSettingsShow = false;

    get org() {
        return appState.storedCurrentOrg;
    }

    get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isSuperUser() {
        return authState.isSuperuser;
    }

    async created() {
        loadingState.loadingIncrement(loadingKey);

        if (this.org !== undefined) {
            await this.loadData();
        }

        this.salutation = BannerMessages.getGreeting();
        this.closing = BannerMessages.getClosing();
        this.canSeeSettings = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);

        loadingState.loadingDecrement(loadingKey);
        this.loaded = true;
    }

    async mounted() {
        // Update the tab title, since 'Add Tab' can't set it correctly.
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? TabTitles.DASHBOARD;
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = TabTitles.CORPORATE_DASHBOARD;
        layoutTabsStore.updateTab(outerTab);
        this.setPageTitle(`${this.$t('insight').toString()} ${this.$t('dashboard').toString()}`);
    }

    @Watch('org')
    private async loadData() {
        if (this.org) {
            if (!loadingState.isLoading(loadingKey)) {
                loadingState.loadingIncrement(loadingKey);
                this.loaded = false;
            }

            this.orgIds = [this.org.id];
            await settingsStore.init();
            this.pipelineValueSettingsShow = settingsStore.stored.get(SettingNames.PIPELINE_VALUE)!.value as boolean;
            if (this.pipelineValueSettingsShow) {
                await statsState.initEstimatedRevenueCounts({
                    org_ids: this.orgIds
                });
                this.revenue = statsState.estimatedRevenueCounts[0].counts[0].total;
            }

            this.loaded = true;

            loadingState.loadingDecrement(loadingKey);
        }
    }

    private updateToursCompletedRate(rate: number) {
        this.tourCompletedSpeedPercent = rate;
    }

    private updateTasksPastDueRate(rate: number) {
        this.tasksPastDueRate = rate;
    }

    private updateConversionRate(rate: number) {
        this.conversionSuccessPercent = rate;
    }
}
