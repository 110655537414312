import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';
import Vue from 'vue';

interface AnyHash {
    [key: string]: any;
}

export interface AnyStoreParams {
    key: string;
    val: any;
}

export interface AnyState {
    anyHash: AnyHash;
}

/**
 * A store that stores keyed data.
 * Great for when you don't want to bubble up events.
 */
@Module({
    namespaced: true,
    name: StoreModuleTypes.ANY,
    dynamic: true,
    store: store
})
export class AnyStore extends VuexModule implements AnyState {
    anyHash: AnyHash = {};

    @Mutation
    setValue(params: AnyStoreParams): void {
        Vue.set(this.anyHash, params.key, params.val);
    }

    /**
     * Return value of a key...
     */
    public get value() {
        return (anyKey: string): any => {
            return this.anyHash[anyKey] !== undefined ? this.anyHash[anyKey] : null;
        };
    }
}
