


















import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const featureStore = getModule(FeaturesStore);
@Component
export default class BaseCog extends Vue {

    private lineLeaderEnroll = false;

    async created() {
        await featureStore.init();
        this.lineLeaderEnroll = featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }
}
