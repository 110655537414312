import {
    EmailTemplateCreateDto,
    EmailTemplateUpdateDto,
    MessageTemplate
} from '@/communications/templates/models/message-template';
import { AbstractMapper } from '@/core/mappers/abstract-mapper';

export class EmailTemplateMapper implements AbstractMapper<MessageTemplate> {
    toCreateDto(entity: MessageTemplate): EmailTemplateCreateDto {
        const dto = {
            name: entity.name,
            subject: entity.subject ?? '',
            template_group: entity.template_group?.id ?? null,
            brand: entity.brand?.id ?? null,
            communication_type: entity.communication_type.id,
            org: entity.org.id,
            is_active: entity.is_active,
            is_corporate_only: entity.is_corporate_only,
            content: entity.content ?? '',
            template_data: JSON.stringify(entity.template_data) ?? '',
            attachments: null,
            hierarchy_type: entity.hierarchy_type?.id ?? 2,
            location_group: entity.location_group?.id ?? null
        } as EmailTemplateCreateDto;
        if (entity.attachments) {
            dto.attachments = entity.attachments.map(attachment => attachment.id);
        }
        return dto;
    }

    toUpdateDto(entity: MessageTemplate): EmailTemplateUpdateDto {
        const dto = this.toCreateDto(entity) as EmailTemplateUpdateDto;
        dto.id = entity.id;
        return dto;
    }

}
