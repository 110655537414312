export type TreeNode<T> = T & {
    id: number;
    children?: Array<TreeNode<T>>;
}

/**
 * Find a particular node in a tree
 * Based on https://stackoverflow.com/a/50590586
 * @param treeRoot
 * @param id
 */
export function findTreeNode<T>(treeRoot: TreeNode<T>, id: number): TreeNode<T> | null {
    const treeStack = [treeRoot];
    while (treeStack.length > 0) {
        const node = treeStack.shift();
        if (!node) {
            return null;
        }
        if (node.id === id) {
            return node;
        }
        if (node.children) {
            treeStack.push(...node.children);
        }
    }
    return null;
}
