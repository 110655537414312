import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.INTERFACE_SETTINGS_CHANGES
})
export class InterfaceSettingsChangesStore extends VuexModule {
    private isChildStatusGraphEnrolledToggleChanged = false;
    private isChildStatusGraphPendingOpportunitiesToggleChanged = false;
    private isPipelineValueToggleChanged = false;
    private isIncludeMeetingsToggleChanged = false;
    private isShowChildStartsToggleChanged = false;

    /* Mutation for child status graph enrolled toggle */
    @Mutation
    setSaveButtonForChildStatusGraphEnrolledToggle(flag: boolean) {
        this.isChildStatusGraphEnrolledToggleChanged = flag;
    }

    /* Mutation for child status graph enrolled toggle */
    @Mutation
    setSaveButtonForChildStatusGraphPendingOpportunitiesToggle(flag: boolean) {
        this.isChildStatusGraphPendingOpportunitiesToggleChanged = flag;
    }

    /* Mutation for child status graph enrolled toggle */
    @Mutation
    setSaveButtonForPipelineValueToggle(flag: boolean) {
        this.isPipelineValueToggleChanged = flag;
    }

    @Mutation
    setSaveButtonForIncludeMeetingsToggle(flag: boolean) {
        this.isIncludeMeetingsToggleChanged = flag;
    }

    @Mutation
    setSaveButtonForShowChildStartsToggle(flag: boolean) {
        this.isShowChildStartsToggleChanged = flag;
    }

    public get areChanges(): boolean {
        return this.isChildStatusGraphEnrolledToggleChanged ||
            this.isChildStatusGraphPendingOpportunitiesToggleChanged ||
            this.isPipelineValueToggleChanged ||
            this.isIncludeMeetingsToggleChanged ||
            this.isShowChildStartsToggleChanged;
    }
}
