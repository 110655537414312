
















import { getBaseBarChartOptions, groupCommStatsToGraphSeries, groupCommStatsToXAxis } from '@/charts/chart-utils';
import type { GroupCommHistory, GroupEmailStats, GroupTextStats } from '@/communications/messages/models/message';
import { GroupCommHistoryRepository } from '@/communications/messages/repositories/group-comm-history-repository';
import { BASE_DARK_BLUE, LINE_LEADER_PURPLE } from '@/core/style-utils';
import { FeaturesStore } from '@/features/features-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts, { TooltipOptions, XAxisOptions } from 'highcharts';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';

const featuresStore = getModule(FeaturesStore);
const groupHistoryRepository = new GroupCommHistoryRepository();
const loadingState = getModule(LoadingStore);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {}
})
export default class GroupHistoryPerformance extends Mixins(LocaleMixin) {
    /**
     * The group history to show stats for.
     */
    @Prop({ required: true }) readonly history!: GroupCommHistory;

    // The Highcharts configuration options
    private chartOptions: Highcharts.Options = {};
    // Whether or not the component has been mounted
    private isMounted = false;
    // The component loading key
    private loadingKey = 'groupHistoryPerformance';
    // The stats for the group history
    private stats: GroupEmailStats | GroupTextStats | null = null;

    /**
     * If the v-if="isMounted" to render the chart after everything else is mounted, or widths get out of whack.
     * https://github.com/highcharts/highcharts-vue/issues/107
     */
    private async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresStore.init();
        await interfaceSettingsStore.init();
        this.chartOptions = await getBaseBarChartOptions();
        // Get the chart to show earlier since the above shouldn't really have a wait
        this.isMounted = true;
        this.stats = await groupHistoryRepository.getGroupCommHistoryStats(this.history.id, this.history.type);
        this.updateChart();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Update the chart.
     */
    private updateChart() {
        (this.chartOptions.tooltip as TooltipOptions).pointFormat = '<span style="color:{series.color}"></span><b>{point.y:.0f}</b><br/>';
        // Set the categories.
        (this.chartOptions.xAxis as XAxisOptions).categories = groupCommStatsToXAxis(this.stats as GroupEmailStats);
        // Set the series data.
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;
        let color = isLineLeaderEnroll ? LINE_LEADER_PURPLE : BASE_DARK_BLUE;
        if (interfaceSettingsStore.hasWhiteLabel) {
            color = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
        }
        this.chartOptions.series = groupCommStatsToGraphSeries(
            this.stats as GroupEmailStats | GroupTextStats,
            color,
            true
        );
    }
}
