


































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import Inbox from '@/communications/messages/views/Inbox.vue';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { AppStateStore } from '@/store/app-state-store';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';

const loadingState = getModule(LoadingStore);
const enrollmentSettingsStore = getModule(EnrollmentCenterSettingsStore);
const fbMessagesStore = getModule(FacebookMessagesStore);
const emailsStore = getModule(EmailsStore);
const textsStore = getModule(TextsStore);
const featuresState = getModule(FeaturesStore);
const facebookState = getModule(FacebookStore);
const recordingsStore = getModule(RecordingsStore);
const appState = getModule(AppStateStore);

@Component({
    components: { Inbox }
})
export default class EnrollmentCenterInbox extends Mixins(LocaleMixin) {
    private loadingKey = 'enrollmentCenterInbox';
    private isExpanded = false;

    private get inboxCount(): number {
        return emailsStore.enrollmentTeamInboxCount + textsStore.enrollmentTeamInboxCount + fbMessagesStore.enrollmentTeamInboxCount + recordingsStore.enrollmentTeamInboxCount;
    }

    private get teamName() {
        return enrollmentSettingsStore.settings ? enrollmentSettingsStore.settings.name : '';
    }

    private get org() {
        return appState.storedCurrentOrg;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        const promises: Array<Promise<void | number | { count: number; orgId: number }>> = [];
        promises.push(enrollmentSettingsStore.init());
        promises.push(emailsStore.retrieveEmailsInboxCountForEnrollmentTeam({ org_id: 1 }));
        promises.push(textsStore.retrieveTextsInboxCountForEnrollmentTeam({ org_id: 1 }));
        promises.push(recordingsStore.retrieveRecordingsEnrollmentInboxCount(this.org ? this.org.id : 1));
        promises.push(featuresState.init());
        if (featuresState.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION)) {
            promises.push(facebookState.init());
            if (facebookState.entities.length > 0) {
                promises.push(fbMessagesStore.retrieveFacebookMessagesInboxCountForEnrollmentTeam({ org_id: 1 }));
            }
        }
        await Promise.all(promises);
        loadingState.loadingDecrement(this.loadingKey);
    }

    private toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

}
