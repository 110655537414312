
































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { VForm } from '@/types/types';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { ssoSetPassword } from '@/auth/auth';

const loadingState = getModule(LoadingStore);

@Component
export default class SsoResetPassword extends Mixins(LocaleMixin, BasicValidationMixin) {
    private username = '';
    private code = '';
    private password = '';
    private passwordConfirm = '';
    private validForm = false;
    private passwordRules = [
        (v: string) => !!v || 'Please enter a value',
        (v: string) => v.length >= 8 || 'Password must be at least 8 characters long',
        (v: string) => v.match(/(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])/) || 'Password must contain at least one upper case letter, one lower case letter, and one number'
    ];

    private get passwordMatchRule() {
        return [
            this.password === this.passwordConfirm || 'Passwords must match'
        ];
    }

    // definitions for typescript
    @Ref('resetPasswordForm') readonly resetPasswordForm!: VForm;

    public async resetPassword() {
        if (!this.validForm) {
            this.resetPasswordForm.validate();
            return;
        }
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const success = await ssoSetPassword(this.username, this.code, this.password);
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
        if (success) {
            await this.$swal({ text: 'Password successfully updated. Please log in.', icon: 'info' });
            await this.$router.push('/');
        } else {
            await this.$swal({ text: 'Unsuccessful update. Please check your values and try again.', icon: 'error' });
        }
    }
}
