import { Brand } from '@/organizations/brands/models/brand-models';
import { AbstractRepository } from '@/repositories/abstract-repository';
import { Logo, LogoDto } from '@/logo/models/logo';

export class BrandsRepository extends AbstractRepository<Brand> {
    protected endpoint = 'marketing/brands';

    // Get logo for a given brand
    public async getLogo(id: number): Promise<Logo> {
        const response = await this.client.get(`${this.endpoint}/${id}/logo`);
        return response.data;
    }

    // Upload a logo for the given brand
    public async uploadLogo(id: number, logoDto: LogoDto): Promise<Logo> {
        const response = await this.client.put<Logo>(`${this.endpoint}/${id}/logo`, logoDto);
        return response.data;
    }

    // Delete the logo for a given brand
    public async deleteLogo(id: number) {
        await this.client.delete<void>(`${this.endpoint}/${id}/logo`);
    }
}
