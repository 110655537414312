import { AbstractRepository } from '@/repositories/abstract-repository';
import { Customer } from '@/customers/models/customer-models';
import { baseUrl } from '../../core/base-url';
import { createClient } from '../../transport/create-client';

/**
 * Repository for customer requests.
 * This is an admin API repository and should only be made by superusers.
 */
export class CustomersRepository extends AbstractRepository<Customer> {
    readonly endpoint = 'customers';

    public constructor() {
        super();
        this.client = createClient(baseUrl + '/api/admin/v1/');
    };
}
