var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{staticClass:"truncated-columns has-header-button family-data-marketing-campaigns",attrs:{"items":_vm.familyCampaignsLocal,"headers":_vm.headers,"must-sort":""},scopedSlots:_vm._u([{key:_vm.lastColumnName,fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))]),_c('base-button-secondary',{staticClass:"table-header-button mb-n1",on:{"click":function($event){$event.stopPropagation();_vm.isEditing = true}}},[_vm._v(" Add/Edit ")])]}},{key:"item.marketing_campaign.values.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.marketing_campaign ? item.marketing_campaign.values.name : ''}},[_vm._v(" "+_vm._s(item.marketing_campaign ? item.marketing_campaign.values.name : '')+" ")])]}},{key:"item.added_datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.added_datetime ? _vm.formatDate(item.added_datetime) : '')+" ")]}},_vm._l((_vm.utmColumns),function(col){return {key:_vm.getSlot(col),fn:function(ref){
var item = ref.item;
return [_c('div',{key:col.value,staticClass:"text-truncate",attrs:{"title":item[col.value]}},[_vm._v(" "+_vm._s(item[col.value])+" ")])]}}})],null,true)}),_c('base-dialog',{attrs:{"content-class":"dialog-narrow edit-campaigns-dialog"},model:{value:(_vm.isEditing),callback:function ($$v) {_vm.isEditing=$$v},expression:"isEditing"}},[_c('base-card',{staticClass:"edit-campaigns-card"},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-title',[_vm._v(" Marketing Campaigns "),_c('v-spacer'),_c('base-button-plain',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.isEditing = false}}},[_c('base-close')],1)],1),_c('v-card-text',{staticClass:"justify-center"},[_c('v-container',_vm._l((_vm.campaignIds),function(selected,index){return _c('v-row',{key:'editRow' + index},[_c('v-col',{attrs:{"cols":"8"}},[_c('emphasized-select',{attrs:{"items":_vm.getFilteredOptions(_vm.campaignIds[index]),"hide-details":""},model:{value:(_vm.campaignIds[index]),callback:function ($$v) {_vm.$set(_vm.campaignIds, index, $$v)},expression:"campaignIds[index]"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(index === _vm.campaignIds.length - 1 && _vm.getFilteredOptions(0).length > 1)?_c('base-button-secondary',{staticClass:"another-campaign-button",on:{"click":function($event){return _vm.campaignIds.push(0)}}},[_vm._v(" Add another ")]):_vm._e()],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('base-button-primary',{staticClass:"save-campaigns-button",on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }