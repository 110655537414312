import { AbstractRepository } from '@/repositories/abstract-repository';
import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';

export class MarketingCampaignsRepository extends AbstractRepository<MarketingCampaign> {
    protected endpoint = 'marketing/campaigns';

    public async retrieve(orgId: number): Promise<Array<MarketingCampaign>> {
        const response = await this.get(null, { org_id: orgId });
        return response.entities;
    }
}
