






















































import { Brand } from '@/organizations/brands/models/brand-models';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import ManageBrand from '@/organizations/brands/components/ManageBrand.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const brandsState = getModule(BrandsStore);
const loadingState = getModule(LoadingStore);
const featureStore = getModule(FeaturesStore);

@Component({
    components: { ManageBrand }
})
export default class Brands extends Mixins(LocaleMixin) {
    private loadingKey = 'brandsLoadingKey';
    private manageActivated = false;
    private managedBrand: Brand | null = null;
    private updateBrandEvent = EventTypes.UPDATED;
    private key = 0;

    /**
     * The headers for the brands table.
     */
    private get brandHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            { text: 'Brand Name', value: 'name' }
        ];
        if (featureStore.isFeatureEnabled(FeatureConstants.BRAND_EMAILS)) {
            headers.push({
                text: 'Email Domain',
                value: 'domain'
            });
        }
        if (featureStore.isFeatureEnabled(FeatureConstants.BRANDS)) {
            headers.push({
                text: 'Logo',
                value: 'logo.values.filename'
            });
        }
        headers.push({
            text: '',
            value: 'action',
            width: '5%',
            sortable: false
        });

        return headers;
    }

    private get isLineLeaderEnroll() {
        return featureStore.isLineLeaderEnroll;
    }

    private get brands() {
        return brandsState.stored;
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await brandsState.init();
        await featureStore.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Go to the add brand modal
     */
    private addBrand(): void {
        this.managedBrand = null;
        this.manageActivated = true;
    }

    /**
     * Open the modal to edit a brand.
     *
     * @param brand
     */
    private editBrand(brand: Brand): void {
        this.managedBrand = brand;
        this.manageActivated = true;
    }

    @Watch('brands')
    private refreshTable() {
        this.key++;
    }
}
