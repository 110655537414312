var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"my-2",attrs:{"width":"100%","elevation":0}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('base-text-field',{directives:[{name:"debounce",rawName:"v-debounce:600ms",value:(_vm.emitSearch),expression:"emitSearch",arg:"600ms"}],staticClass:"pa-0",attrs:{"id":"search-bar","prepend-inner-icon":"mdi-magnify","label":'Search Recordings',"clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.emitSearch($event)},"click:clear":_vm.clearSearch},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1)],1)],1)],1),_c('v-card-text',[_c('base-data-table',{staticClass:"truncated-columns",attrs:{"items":_vm.localRecordings,"headers":_vm.headers,"item-class":_vm.getRowClass,"item-key":"id","show-select":"","disable-sort":"","options":_vm.options,"server-items-length":_vm.count},on:{"update:options":function($event){_vm.options=$event},"current-items":_vm.shownHandler},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" You have no call recordings ")]},proxy:true},{key:"header.do_not_call",fn:function(){return [_c('base-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedAndShown.length,"icon":""},on:{"click":_vm.toggleArchivedSelected}},'v-btn',attrs,false),on),[(!!_vm.isArchiveMode)?_c('v-icon',[_vm._v(" mdi-package-up ")]):_c('v-icon',[_vm._v(" mdi-package-down ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!!_vm.isArchiveMode ? 'Unarchive selected' : 'Archive selected'))])])]},proxy:true},{key:"header.contact",fn:function(){return [_c('base-button-group',{staticClass:"pb-2",attrs:{"mandatory":""},model:{value:(_vm.isArchiveMode),callback:function ($$v) {_vm.isArchiveMode=$$v},expression:"isArchiveMode"}},[_c('base-button-plain',{attrs:{"small":""}},[_vm._v(" Inbox ")]),_c('base-button-plain',{attrs:{"small":""}},[_vm._v(" Archive ")])],1)]},proxy:true},{key:"item.do_not_call",fn:function(ref){
var item = ref.item;
return [(_vm.getCallStatus(item))?_c('v-icon',{attrs:{"title":"Do Not Call","color":"red"}},[_vm._v(" mdi-phone-off ")]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate phone",attrs:{"title":_vm.formatPhone(_vm.contactPhone(item))}},[_vm._v(" "+_vm._s(_vm.formatPhone(_vm.contactPhone(item)))+" ")])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate name",attrs:{"title":_vm.contactName(item)}},[_vm._v(" "+_vm._s(_vm.contactName(item))+" ")])]}},{key:"item.family_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate status",attrs:{"title":_vm.getStatus(item)}},[_vm._v(" "+_vm._s(_vm.getStatus(item))+" ")])]}},{key:"item.time_stamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate date",attrs:{"title":_vm.formatSentDate(item.datetime)}},[_vm._v(" "+_vm._s(_vm.formatSentDate(item.datetime))+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate duration",attrs:{"title":_vm.duration(item)}},[_vm._v(" "+_vm._s(_vm.duration(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-lg-space-between align-center ma-0 px-0"},[_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"play-button",attrs:{"icon":""},on:{"click":function($event){return _vm.playRecording(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-play-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Play")])]),(!_vm.enrollmentTeamMode)?_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.markRecording(item)}}},'v-btn',attrs,false),on),[(!item.is_read)?_c('v-icon',[_vm._v(" mdi-bell-check-outline ")]):_c('v-icon',[_vm._v(" mdi-bell-ring ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_read ? 'Mark as Unlistened' : 'Mark as Listened'))])]):_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.markRecording(item)}}},'v-btn',attrs,false),on),[(!item.is_read_by_enrollment_team)?_c('v-icon',[_vm._v(" mdi-bell-check-outline ")]):_c('v-icon',[_vm._v(" mdi-bell-ring ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_read_by_enrollment_team ? 'Mark Unlistened' : 'Mark Listened'))])]),_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleArchivedSingle(item)}}},'v-btn',attrs,false),on),[(!!_vm.isArchiveMode)?_c('v-icon',[_vm._v(" mdi-package-up ")]):_c('v-icon',[_vm._v(" mdi-package-down ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(!!_vm.isArchiveMode? 'Unarchive' : 'Archive'))])]),_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToFamilyHub(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visit Family")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showRecordingModal)?_c('recording-audio-modal',{attrs:{"recordings":_vm.played},model:{value:(_vm.showRecordingModal),callback:function ($$v) {_vm.showRecordingModal=$$v},expression:"showRecordingModal"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }