import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { SavedReport, SavedReportDtoInterface } from '@/reports/models/saved-report';

export class SavedReportMapper implements AbstractMapper<SavedReport> {
    toCreateDto(entity: SavedReport): SavedReportDtoInterface {
        const dto = {
            description: entity.description ?? '',
            name: entity.name ?? '',
            report_data: entity.report_data ?? '',
            shared_with: [] as Array<number>,
            shared_name: entity.shared_name ? entity.shared_name : entity.name
        };
        if (entity.shared_with.length) {
            for (const sharedWith of entity.shared_with) {
                dto.shared_with.push(sharedWith.id);
            }
        }

        return dto;
    }

    toUpdateDto(entity: SavedReport): SavedReportDtoInterface {
        const dto = this.toCreateDto(entity) as SavedReportDtoInterface;
        if (entity.order !== undefined) {
            dto.order = entity.order;
        }
        return dto;
    }
}
