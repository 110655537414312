import { Action, Module } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import store from '@/store';
import { TaskGroup } from '@/tasks/models/task-models';
import { TaskGroupsRepository } from '@/tasks/repositories/task-groups-repository';

export interface TaskGroupState extends AbstractEntityState<TaskGroup> {
    entities: Array<TaskGroup>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.TASK_GROUPS
})
export class TaskGroupsStore extends AbstractApiStore<TaskGroup> implements TaskGroupState {
    readonly repository = new TaskGroupsRepository()

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    // Unfortunately getters don't work with vuex-module-decorator inheritance for some reason.
    public get stored(): Array<TaskGroup> {
        return this.entities;
    }
}
