











import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { Component, Vue } from 'vue-property-decorator';
import { FeaturesStore } from '@/features/features-store';
import { getModule } from 'vuex-module-decorators';
import Layout from '@/views/Layout.vue';
import LayoutMP from '@/views/LayoutMP.vue';
import { Route } from 'vue-router';
import { LayoutTabUtils } from '@/utils/layout-tab-utils';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';

const featuresState = getModule(FeaturesStore);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: { Layout, LayoutMP }
})
export default class LayoutWrapper extends Vue {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        await LayoutTabUtils.createTabComponents();
        await LayoutTabUtils.preRegisterTabComponents();
        layoutTabsStore.syncMapWithArray();
        await Promise.all([
            featuresState.init(),
            interfaceSettingsStore.init()
        ]);
        next();
    }

    private loaded = false;

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    async created() {
        this.loaded = true;
    }
}
