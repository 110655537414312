import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Field } from '@/crm-types/models/field-models';
import { FieldsRepository } from '@/crm-types/repositories/fields-repository';

export interface FieldsState extends AbstractEntityState<Field> {
    entities: Array<Field>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FIELDS
})
export class FieldsStore extends AbstractApiStore<Field> implements FieldsState {
    readonly repository = new FieldsRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    public get stored(): Array<Field> {
        return this.entities;
    }

    /**
     * Get all stored child fields.
     */
    public get storedChildFields() {
        return this.entities.filter((field) => {
            return field.is_child_field;
        });
    }

    /**
     * Get all stored guardian fields.
     */
    public get storedGuardianFields() {
        return this.entities.filter((field) => {
            return field.is_guardian_field;
        });
    }
}
