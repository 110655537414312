













































































































































































































































































































































































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Family } from '@/families/models/family';
import { FamilyUpdateDtoInterface } from '@/families/models/family';
import {
    Guardian,
    GuardianCreateDto,
    GuardianCreateDtoInterface,
    GuardianUpdateDtoInterface
} from '@/families/models/guardian';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { LoadingStore } from '@/store/loading-store';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { GuardianMapper } from '@/families/mappers/guardian-mapper';
import { GuardiansRepository } from '@/families/repositories/guardians-repository';
import { VForm } from '@/types/types';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { sortFields } from '@/crm-types/field-utils';
import { Field, FieldEntityType, GuardianFields } from '@/crm-types/models/field-models';
import isEqual from 'lodash/isEqual';
import { getCanDeleteFamily, getDefaultRelationship, getFamilyCell, getFamilyEmail, retrieveDoNotsVerbiage } from '@/families/families-utils';
import SaveButton from '@/components/base/SaveButton.vue';
import { EventTypes } from '@/constants/event-type-constants';
import FamilyOtherFields from '@/families/components/FamilyOtherFields.vue';
import { FamilyChangesStore } from '@/families/store/family-changes-store';
import { CustomFamilyValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-family-values-changes-store';
import cloneDeep from 'lodash/cloneDeep';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { ChangeStatus } from '@/families/change-status';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { MarketingSubscriptionTypes } from '../../../marketing/models/marketing-model';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CommunicationTypes } from '@/communications/communication-constants';

const crmTypesStore = getModule(CrmTypesStore);
const loadingState = getModule(LoadingStore);
const familyMapper = new FamilyMapper();
const familiesRepository = new FamiliesRepository();
const fieldsStore = getModule(FieldsStore);
const guardianMapper = new GuardianMapper();
const guardiansRepository = new GuardiansRepository();
const familyChangesStore = getModule(FamilyChangesStore);
const customValuesChangesStore = getModule(CustomFamilyValuesChangesStore);
const changeStatusUtil = new ChangeStatus();
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();

@Component({
    components: {
        BaseClose,
        StatusChangeSelect,
        FamilyOtherFields,
        SaveButton,
        CrmTypeSelectList
    }
})
export default class FamilyDataInfoAddEditGuardian extends Mixins(LocaleMixin, BasicValidationMixin, FamilyHubMixin, VmodelShowMixin) {

    @Prop() family!: Family;
    @Prop() guardian!: Guardian | null;

    private loadingKey = 'addEditGuardian';
    private originalGuardianDto: GuardianCreateDtoInterface | null = null;
    private guardianDto: GuardianCreateDtoInterface = new GuardianCreateDto();
    private originalFamilyDto: FamilyUpdateDtoInterface | null = null;
    private familyDto: FamilyUpdateDtoInterface | null = null;
    private mergedFamilyDto: FamilyUpdateDtoInterface | null = null;
    private validForm = false;
    private hasValidStatusChange = false;
    private fields: Array<Field> = [];
    private relationships: Array<CrmTypeOption> = [];
    private showAddress = false;
    private updatedEvent = EventTypes.UPDATED;
    private familyUpdatedEvent = EventTypes.FAMILY_UPDATED;
    private disableSaveEvent = EventTypes.DISABLE_SAVE;
    private phoneTypesList = CrmTypeList.PHONE_TYPE;
    private indeterminateState= false;
    private hasFamilyDelete = false;
    private isLoading = false;

    @Ref('form') readonly form!: VForm;

    // we can delete an existing secondary guardian
    get canDelete() {
        return !this.isPrimaryGuardian && !this.isNew;
    }

    get canDeleteFamily() {
        return this.isPrimaryGuardian && this.hasFamilyDelete && getCanDeleteFamily(this.family);
    }

    get canSave() {
        if (!this.validForm) {
            return false;
        }
        if (this.isPrimaryGuardian) {
            return !isEqual(this.mergedFamilyDto, this.originalFamilyDto) ||
                !isEqual(this.guardianDto, this.originalGuardianDto) ||
                this.hasValidStatusChange;
        }
        if (!this.isNew) {
            return !isEqual(this.guardianDto, this.originalGuardianDto);
        }
        // new secondary guardian, as long as the form is valid we're good
        return true;
    }

    get isNew() {
        return !this.guardian;
    }

    get isPrimaryGuardian() {
        return !!this.guardian && this.guardian.id === this.family.primary_guardian.id;
    }

    get isRelationshipRequired() {
        return this.isRequiredField('Relation to Child');
    }

    private get isEmailDisabled() {
        return !getFamilyEmail(this.family);
    }

    private get isTextDisabled() {
        return !getFamilyCell(this.family);
    }

    get communicationTypesOptedOutEmails() {
        return Object.keys(this.family.subscriptions.email)
                     .filter(key => !this.family.subscriptions.email[key as keyof MarketingSubscriptionTypes] && key !== 'urgent');
    }

    get communicationTypesOptedOutTexts() {
        return Object.keys(this.family.subscriptions.text)
                     .filter(key => !this.family.subscriptions.text[key as keyof MarketingSubscriptionTypes] && key !== 'urgent');
    }

    get communicationSecondaryGuardianTypesOptedOutEmails() {
        if (this.isPrimaryGuardian || !this.guardian || !this.guardian.subscriptions || !this.guardian.subscriptions.email) {
            return [];
        }
        const emailSubscriptions = this.guardian.subscriptions.email;
        return Object.keys(emailSubscriptions)
                     .filter(key => !emailSubscriptions[key as keyof MarketingSubscriptionTypes] && key !== 'urgent');
    }

    get tooltipEmailMessage() {
        if (this.isEmailDisabled) {
            return 'This family may not be emailed.';
        }
        if (this.communicationTypesOptedOutEmails.length > 0 && this.hasCommunicationTypes) {
            return this.getVerbiage(this.communicationTypesOptedOutEmails);
        }
        return '';
    }

    get tooltipEmailMessageSecondaryGuardian() {
        if (this.isEmailDisabled) {
            return 'This guardian may not be emailed.';
        }
        if (this.communicationSecondaryGuardianTypesOptedOutEmails.length > 0 && this.hasCommunicationTypes) {
            return this.getVerbiage(this.communicationSecondaryGuardianTypesOptedOutEmails, CommunicationTypes.EMAIL, this.isPrimaryGuardian);
        }
        return '';
    }

    get tooltipTextMessage() {
        if (this.isTextDisabled) {
            return 'This family may not be texted.';
        }
        if (this.communicationTypesOptedOutTexts.length > 0 && this.hasCommunicationTypes) {
            return this.getVerbiage(this.communicationTypesOptedOutTexts, CommunicationTypes.TEXT);
        }
        return '';
    }

    get hasCommunicationTypes(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    get indeterminateStatusEmailSecondaryGuardian() {
        if (this.communicationSecondaryGuardianTypesOptedOutEmails.length > 0 && this.hasCommunicationTypes) {
            return !this.guardianDto?.do_not_email;
        } else {
            return false;
        }
    }

    get indeterminateStatusEmail() {
        if (this.communicationTypesOptedOutEmails.length > 0 && this.hasCommunicationTypes) {
            return !this.familyDto?.do_not_email;
        } else {
            return false;
        }
    }

    get indeterminateStatusText() {
        if (this.communicationTypesOptedOutTexts.length > 0 && this.hasCommunicationTypes) {
            return !this.familyDto?.do_not_text;
        } else {
            return false;
        }
    }

    @Watch('familyDto', { deep: true })
    doNotsChanged() {
        if (!this.isLoading) {
            this.updateMergedDto();
        }
    }

    @Watch('modelValue', { immediate: true })
    async showModal() {
        if (this.modelValue) {
            this.isLoading = true;
            // set up our dtos, etc
            this.familyDto = familyMapper.toUpdateDto(this.family);
            this.originalFamilyDto = cloneDeep(this.familyDto);
            if (this.guardian) {
                this.guardianDto = this.isPrimaryGuardian ? cloneDeep(this.familyDto.primary_guardian) : guardianMapper.toUpdateDto(this.guardian);
                this.originalGuardianDto = cloneDeep(this.guardianDto);
            } else {
                this.originalGuardianDto = null;
                this.guardianDto = new GuardianCreateDto();
            }
            // sort custom values dto to ensure equality check works properly
            if (this.originalFamilyDto.custom_values) {
                this.originalFamilyDto.custom_values.sort((custDtoA, custDtoB) => {
                    return custDtoA.custom_value_group > custDtoB.custom_value_group ? 1 : -1;
                });
            }
            this.showAddress = !!this.guardianDto.address && !!this.guardianDto.address.address1;
            this.hasValidStatusChange = false;

            const relationshipTypes = crmTypesStore.allListOptions(CrmTypeList.RELATIONSHIP);
            if (!this.isNew && this.guardianDto.child_relation) {
                this.relationships = relationshipTypes.filter(option => option.id === this.guardianDto.child_relation || option.is_active);
            }
            if (this.isNew || (this.isPrimaryGuardian && !this.guardianDto.child_relation)) {
                this.relationships = relationshipTypes.filter(option => option.is_active);
                this.guardianDto.child_relation = getDefaultRelationship(this.relationships);
            }
            if (!this.isNew && this.guardianDto.child_relation) {

            }
            this.mergedFamilyDto = cloneDeep(this.familyDto);
            if (!this.isNew) {
                await this.$nextTick();
                this.form.validate();
            }
            this.isLoading = false;
        }
    }

    async created() {
        this.hasFamilyDelete = await staffUtils.getUserPermission(PermissionName.FamilyDelete);
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);

        // Load in the lists.
        const promises = [];
        promises.push(fieldsStore.init());
        promises.push(await crmTypesStore.initAllList(CrmTypeList.RELATIONSHIP));
        await Promise.all(promises);
        this.fields = sortFields(FieldEntityType.GUARDIAN, fieldsStore.storedGuardianFields);
        loadingState.loadingDecrement(this.loadingKey);
    }

    badStatusEvent() {
        this.hasValidStatusChange = false;
    }

    close() {
        this.form.reset();
        this.modelValue = false;
        this.isLoading = false;
    }

    familyValuesUpdated() {
        this.updateMergedDto();
    }

    /**
     * Taken from old modal
     * @param fieldName
     * @private
     */
    private isRequiredField(fieldName: string): boolean | undefined {
        switch (fieldName) {
            case GuardianFields.PHONE_TYPE:
                if (this.guardianDto.primary_phone &&
                    this.guardianDto.primary_phone.number_e164 &&
                    this.guardianDto.primary_phone.number_e164.length > 0
                ) {
                    return true;
                }

                break;
        }

        const field = this.fields.find(field => field.value === fieldName);
        return !!field && field.is_client_required;
    }

    private getVerbiage(commTypesDisabled: string[], method = CommunicationTypes.EMAIL, isPrimary = true): string {
        return retrieveDoNotsVerbiage(this.family, this.guardian, commTypesDisabled, method, isPrimary);
    }

    async removeFamily() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete this family? This cannot be undone.',
            showConfirmButton: true,
            showCancelButton: true
        });
        if (result.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await familiesRepository.deleteFamily(this.family.id);
            loadingState.loadingDecrement(this.loadingKey);
            this.$emit(EventTypes.FAMILY_DELETED);
            this.close();
        }
    }

    /**
     * taken from old modal
     */
    async removeSecondaryGuardian() {
        if (this.guardian) {
            const result = await this.$swal({
                text: 'Are you sure you want to remove this guardian?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Remove'
            });

            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                await guardiansRepository.deleteOne(this.family.id, this.guardian.id);
                this.$emit(EventTypes.UPDATED);
                loadingState.loadingDecrement(this.loadingKey);
                this.close();
            }
        }
    }

    /**
     * modeled after old modal as well as family hub
     */
    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        // Should we update the main family (updating parts of the primary guardian (lead))
        if (this.isPrimaryGuardian && this.mergedFamilyDto && !isEqual(this.mergedFamilyDto, this.originalFamilyDto)) {
            // Force update familyDto.added_date = familyDto.primary_guardian.added_date because the added date field value is set through
            // familyDto.primary_guardian.added_date, but familyDto.added_date will still remain unchanged, and the
            // API looks at the familyDto.added_date to set the added_date for lead data. BUGS-1202
            if (this.mergedFamilyDto.primary_guardian.added_date) {
                this.mergedFamilyDto.added_date = this.mergedFamilyDto.primary_guardian.added_date;
            }
            delete this.mergedFamilyDto.status;
            await familiesRepository.update(this.mergedFamilyDto);
            familyChangesStore.clear(this.family.id);
        }

        if (this.isPrimaryGuardian && this.hasValidStatusChange) {
            await changeStatusUtil.writePendingChanges(this.family.id);
        }
        // Should we update the guardian?
        if (this.isNew || !isEqual(this.guardianDto, this.originalGuardianDto)) {
            // Make sure we have a null object for empty phone numbers.
            if (this.guardianDto.primary_phone &&
                this.guardianDto.primary_phone.number_e164 === ''
            ) {
                this.guardianDto.primary_phone = null;
            }
            if (this.guardian) {
                const updateDto: GuardianUpdateDtoInterface = {
                    ...this.guardianDto,
                    id: this.guardian.id
                };
                await guardiansRepository.updateOne(this.family.id, updateDto);
            } else {
                await guardiansRepository.createOne(
                    this.family.id,
                    this.guardianDto as GuardianCreateDto
                );
            }
        }

        // Emit to reload parent page.
        this.$emit(EventTypes.UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }

    statusChangedEvent() {
        this.hasValidStatusChange = true;
    }

    /**
     * We have to create a merged DTO because of how FamilyOtherFields handles things.
     * At some point I'd love to rip that stuff out, but that would be a pretty big refactor
     */
    updateMergedDto() {
        if (!this.familyDto) {
            return;
        }
        const otherFieldsDto = familyChangesStore.changesForFamilyId(this.family.id);
        if (otherFieldsDto) {
            otherFieldsDto.do_not_email = this.familyDto.do_not_email;
            otherFieldsDto.do_not_text = this.familyDto.do_not_text;
            otherFieldsDto.do_not_call = this.familyDto.do_not_call;
            otherFieldsDto.custom_values = customValuesChangesStore.changesForFamily(this.family);
            otherFieldsDto.custom_values.sort((custDtoA, custDtoB) => {
                return custDtoA.custom_value_group > custDtoB.custom_value_group ? 1 : -1;
            });
            this.mergedFamilyDto = otherFieldsDto;
        } else {
            this.mergedFamilyDto = cloneDeep(this.familyDto);
        }
    }

}
