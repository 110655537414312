import { DownloadFamiliesParameters, Opportunity, OpportunitySortParameter } from '@/families/models/opportunity';
import { CrmApiResponse } from '@/models/base';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { openFile } from '@/core/file-utils';

export class OpportunitiesRepository extends AbstractRepository<Opportunity> {
    readonly endpoint = 'families/opportunities';

    public async getSorted(
        pagination: ApiPagination | null = null,
        sortParam: OpportunitySortParameter | null = null,
        queryParams: ApiParameters | null = null,
        abortController: AbortController | null = null
    ): Promise<CrmApiResponse<Opportunity>> {
        const params = {};
        // Merge all the params together so we get types on the parameters first
        Object.assign(params, queryParams, sortParam);
        return super.get(pagination, params as ApiParameters, null, abortController);
    }

    public async getIds(queryParams: ApiParameters | null = null): Promise<Array<number>> {
        if (!queryParams) {
            queryParams = {};
        }
        const response = await this.client.get<Array<number>>(this.endpoint + '/ids', { params: queryParams });
        return response.data;
    }

    public async downloadSelectedFamilies(params: DownloadFamiliesParameters): Promise<void> {
        const response = await this.client.get('opportunities/csv', {
            params: params
        });
        openFile(response.data, 'families.csv', 'text/csv');
    }
}
