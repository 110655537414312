import {
    LocationOffering, LocationOfferingOption,
    LocationOfferingOptionDto
} from '@/enrollment-center/models/enrollment-center-location-models';

export class EnrollmentCenterLocationOfferingsMapper {
    getUpdatedOfferingDtos(originalOfferings: Array<LocationOffering>, updatedOfferings: Array<LocationOffering>) {
        const updates: Array<LocationOfferingOptionDto> = [];
        const map: Map<number, LocationOfferingOption> = new Map();

        // First, create a map of the original options
        for (const offering of originalOfferings) {
            for (const option of offering.options) {
                map.set(option.offering_option.id, option);
            }
        }

        // Next, loop through the updated array of offerings and create the dtos
        let originalOption: LocationOfferingOption | null = null;
        for (const offering of updatedOfferings) {
            for (const option of offering.options) {
                if (!map.has(option.offering_option.id)) {
                    // This shouldn't ever happen unless deliberately modifying objects to be different
                    continue;
                }
                originalOption = map.get(option.offering_option.id) as LocationOfferingOption;
                if (
                    originalOption.is_included_in_price !== option.is_included_in_price ||
                    originalOption.is_offered !== option.is_offered
                ) {
                    updates.push({
                        id: option.offering_option.id,
                        is_included_in_price: option.is_included_in_price,
                        is_offered: option.is_offered
                    });
                }
            }
        }

        return updates;
    }
}
