import { HateoasLink } from '@/models/base';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmTypeOption, CrmTypeOptionCreateDto } from '@/crm-types/models/crm-type';

export enum CustomFieldsTypes {
    MULTI_SELECT = 1,
    SINGLE_SELECT = 2,
    TEXT = 3
}

export enum CustomFieldEntityTypes {
    GUARDIAN,
    CHILD,
    STAFF
}

export interface CustomField {
    id: number;
    label: string;
    order: number;
    is_client_required: boolean;
    is_for_lead: boolean;
    is_for_child: boolean;
    is_for_staff: boolean;
    type: HateoasLink;
}

export interface CustomFieldWithOption extends CustomField {
    options: Array<CustomFieldValue>;
}

export interface CustomFieldValue extends CrmTypeOption {
    is_editable: boolean;
    is_hidden: boolean;
    is_system_required: boolean;
    is_client_required: boolean;
    custom_value_group_id: number;
    is_used: boolean;
}

export interface CustomFieldValueCreateDto extends CrmTypeOptionCreateDto {
    is_editable: boolean;
}

export interface CustomFieldValueUpdateDto extends CustomFieldValueCreateDto, AbstractUpdateDto {
    id: number;
}

export interface CustomFieldCreateDto extends AbstractCreateDto {
    label: string;
    order: number;
    is_client_required: boolean;
    is_for_lead: boolean;
    is_for_child: boolean;
    is_for_staff: boolean;
    type: number;
}

export interface CustomFieldUpdateDto extends CustomFieldCreateDto {
    id: number;
}

export class CustomFieldDto implements CustomFieldCreateDto {
    label = '';
    order = 0;
    is_client_required = false;
    is_for_lead = true;
    is_for_child = false;
    is_for_staff = false;
    type = CustomFieldsTypes.SINGLE_SELECT;
}

export class CustomFieldValueCreate implements CustomFieldValueCreateDto {
    value = '';
    order = 0;
    is_default = false;
    is_editable = true;
}

export interface CustomValues {
    custom_value_group: HateoasLink;
    custom_values: Array<HateoasLink> | null;
    custom_text_value: string | null;
}

export interface CustomValuesUpdateDto {
    custom_value_group: number;
    custom_values: Array<number> | null;
    custom_text_value: string | null;
}
