


































































import { EventTypes } from '@/constants/event-type-constants';
import { Center } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AcceptFamilyEventPayload } from '@/families/models/family';
import BaseClose from '@/components/base/BaseClose.vue';

const centersState = getModule(CentersStore);
const loadingState = getModule(LoadingStore);

@Component({
    components: { BaseClose }
})
export default class CenterlessFamilySelection extends Mixins(LocaleMixin, BasicValidationMixin) {
    /** v-model value - Show or not to show the modal. */
    @Prop({ type: Boolean, default: false }) readonly value!: boolean;

    private centerId: number | null = null;
    private loadingKey = 'centerlessFamilyModal';
    private validForm = false;

    /**
     * A list of centers for the user to choose from.
     */
    get centers(): Array<Center> {
        return centersState.storedAccessibleCenters;
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Reset the selected center when changing the state of the modal.
     */
    @Watch('modelValue')
    private modalShown() {
        this.centerId = null;
    }

    /**
     * Load the list of centers.
     */
    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await centersState.initAccessibleCenters();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Emit the chosen center id to be applied to the family.
     */
    private applyCenter() {
        this.$emit(EventTypes.UPDATED, { centerId: this.centerId } as AcceptFamilyEventPayload);
        this.handleClose();
    }

    /**
     * Handle closing the modal.
     */
    private handleClose() {
        this.modelValue = false;
    }
}
