




























































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch, PropSync } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import { CrmTypeOption, CrmTypeOptionCreateDto, CrmTypeOptionUpdateDto } from '@/crm-types/models/crm-type';
import { EventTypes } from '@/constants/event-type-constants';
import { Field } from '@/crm-types/models/field-models';

@Component
export default class SelectListEdit extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly fields!: Array<CrmTypeOption>;
    @Prop({ default: false }) readonly radio!: boolean;
    @Prop() readonly title!: string;
    @Prop({ default: 'Warning: Are you sure you want to delete this?' }) readonly warningText!: string;
    @PropSync('panel', { type: Number }) panelIndexSynced!: number;
    @Prop() readonly selectListField!: Field | undefined;

    private newDividerCounter = 0;
    private itemsRemoved: Array<number> = [];
    private default_radio_select = -1;
    private headers: Array<DataTableHeader> = [];
    private items: Array<CrmTypeOptionUpdateDto> = [];

    private fieldTitle = '';
    private required = false;

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.items.splice(sortEvent.oldIndex!, 1)[0];
        this.items.splice(sortEvent.newIndex!, 0, movedItem);
    }

    private async deleteSection(divider: CrmTypeOptionUpdateDto) {
        const arrayInd = this.items.indexOf(divider);
        if (divider.id && divider.id > 0) {
            const result = await this.$swal({
                text: this.warningText,
                showCancelButton: true,
                confirmButtonText: 'Proceed'
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.items.splice(arrayInd, 1);
                }
                this.itemsRemoved.push(divider.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.items.splice(arrayInd, 1);
            }
        }
    }

    private addSection() {
        this.newDividerCounter++;
        const divider: CrmTypeOptionUpdateDto = {
            id: -2 * this.newDividerCounter,
            is_default: false,
            is_editable: true,
            order: 0,
            value: ''
        };
        this.items.unshift(divider);
    }

    @Watch('fields', { immediate: true })
    watchFields() {
        this.default_radio_select = -1;
        this.newDividerCounter = 0;
        this.itemsRemoved = [];
        this.items = this.fields.map(a => Object.assign({}, a) as CrmTypeOptionUpdateDto);

        for (const field of this.items) {
            if (field.is_default) {
                this.default_radio_select = field.id;
                break;
            }
        }
    }

    private async created() {
        this.headers = [
            {
                text: '',
                value: 'handle'
            },
            {
                text: 'Name',
                value: 'value',
                align: 'center'
            },
            {
                value: 'delete',
                text: 'Delete',
                align: 'center',
                width: '0'
            }
        ];
        if (this.radio) {
            this.headers.splice(2, 0, {
                text: 'Default',
                value: 'is_default',
                align: 'center'
            });
        }
        if (this.selectListField) {
            this.fieldTitle = this.selectListField.value;
            this.required = this.selectListField.is_client_required;
        }
    }

    private clearRadioSelection() {
        this.default_radio_select = -1;
    }

    @Watch('default_radio_select')
    watchRadio() {
        for (const item of this.items) {
            item.is_default = !!(item.id && (this.default_radio_select === item.id));
        }
    }

    @Watch('items', { deep: true })
    watchChanges() {
        const dtoArray: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto> = [];
        let order = 0;

        for (const item of this.items) {
            let defaultCheck = false;
            if (item.id && (this.default_radio_select === item.id)) {
                defaultCheck = true;
            }
            const fieldDto: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto = {
                is_default: defaultCheck,
                value: item.value,
                order
            };

            if (item.id > 0) {
                fieldDto.id = item.id;
            }
            if (item.id && item.id < 0) {
                // Emit new items added to the select list
                dtoArray.push(fieldDto);
            } else {
                // Only emits items that have updated
                for (const e of this.fields) {
                    if (item.id && (item.id === e.id) && (e.is_default !== item.is_default || e.value !== item.value || e.order !== order)) {
                        dtoArray.push(fieldDto);
                        break;
                    }
                }
            }
            ++order;
        }
        this.$emit(EventTypes.UPDATED, { deletedItems: this.itemsRemoved, updatedItems: dtoArray });
    }

    @Watch('fieldTitle')
    nameUpdated() {
        this.$emit(EventTypes.NAME_UPDATED, this.fieldTitle);
    }

    @Watch('required')
    requiredUpdated() {
        this.$emit(EventTypes.REQUIRED_UPDATED, this.required);
    }
}
