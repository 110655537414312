import { AbstractRepository } from '@/repositories/abstract-repository';
import { LocationRating, LocationRatingDto } from '@/enrollment-center/models/enrollment-center-location-models';

export class LocationRatingsRepository extends AbstractRepository<LocationRating> {
    readonly endpoint = '/centers';

    public async getLocationRatings(centerId: number): Promise<Array<LocationRating>> {
        const response = await this.client.get(`${this.endpoint}/${centerId}/call-center-settings/ratings`);
        return response.data;
    }

    public async updateLocationRating(centerId: number, ratingId: number, newValue: LocationRatingDto) {
        await this.client.put(`${this.endpoint}/${centerId}/call-center-settings/ratings/${ratingId}`, newValue);
    }
}
