











































































































































import MapRoles from '@/integrations/components/MapRoles.vue';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import { IntegrationLocationMappingId, IntegrationPartner, IntegrationPartnerToggle } from '../models/integration';
import { EventTypes } from '@/constants/event-type-constants';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { IntegrationPartnerIds, IntegrationPartners, IntegrationTypes } from '@/integrations/integration-constants';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { LoadingStore } from '@/store/loading-store';
import { Regions } from '@/locales/locales';
import store from '@/store';
import IntegrationMappedClassrooms from '@/integrations/components/IntegrationMappedClassrooms.vue';
import IntegrationService from '@/integrations/IntegrationService.ts';
import MapLocations from '@/integrations/components/MapLocations.vue';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);
const integrationRepository = new IntegrationRepository();
const integrationsState = getModule(IntegrationStore);
const repositoryFactoryStore = getModule(RepositoryFactoryStore);

@Component({
    components: { IntegrationMappedClassrooms, MapLocations, MapRoles }
})
export default class CmsIntegrationModal extends Mixins(LocaleMixin) {
    @Prop({ default: false }) isOpen!: boolean;
    @Prop({ default: null }) currentCmsPartner!: IntegrationPartner | null;
    @Prop({ default: false }) readonly isSecondary!: boolean;
    @Prop({ default: false }) readonly hasMultiCms!: boolean;

    private apiCustomerId = '';
    private apiKey = '';
    private apiUsername = '';
    private apiPassword = '';
    private closeEvent = EventTypes.CLOSE;
    private cmsPartner: IntegrationPartner | undefined;
    private cmsPartnerId = 0; // Partner dropdown selected value
    private cmsPartnersList: Array<IntegrationPartner> = [];
    private currentApiUsername = '';
    private currentApiPassword = '';
    private currentApiKey = '';
    private currentApiCustomerId = '';
    private currentCmsPartnerId = 0;
    private currentCmsPartners: Array<IntegrationPartner> = [];
    private integrationService: IntegrationService | null = null;
    private isMapLocationsOpen = false;
    private isMapRolesOpen = false;
    private loadingKey = 'cmsIntegrationModal';
    private locationMappingSaved = EventTypes.LOCATION_MAPPING_SAVED;
    private roleMappingSaved = EventTypes.ROLES_MAPPING_SAVED;
    private mappedLocationIds: Array<IntegrationLocationMappingId> = [];
    private kangarooTimeId = IntegrationPartnerIds.KANGAROO_TIME;
    private key = 0;
    private manageId = IntegrationPartnerIds.MANAGE;
    private procareId = IntegrationPartnerIds.PROCARE;
    private qikkidsId = IntegrationPartnerIds.QIKKIDS;

    private saveEvent = EventTypes.UPDATED;
    private showMapClassesModal = false;
    private region: Regions = Regions.us;

    // Disable/enable save button when valid input
    private isDisabled = false;

    /**
     * Whether there are mapped locations.
     */
    private get hasMappedLocations(): boolean {
        return this.mappedLocationIds.length > 0;
    }

    private get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    private get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isFacebookIntegrationEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    /**
     * Whether centers need to be mapped for the integration.
     */
    private get needsCentersMapped(): boolean {
        if (!this.isSuperUser || !this.cmsPartnerId || !this.cmsPartner) {
            return false;
        }

        return this.cmsPartner.id === this.currentCmsPartnerId && this.cmsPartner.needs_centers_mapped;
    }

    /**
     * Whether classrooms need to be mapped for the integration.
     */
    private get needsClassroomsMapped(): boolean {
        if (!this.cmsPartner) {
            return false;
        }

        return this.cmsPartner.needs_classrooms_mapped;
    }

    /**
     * Whether credentials are needed for the integration.
     */
    private get needsCredentials(): boolean {
        if (this.cmsPartner) {
            return this.cmsPartner.needs_credentials;
        }

        return false;
    }

    /**
     * Whether selected cmsPartner is QikKids
     */
    private get isQikkids(): boolean {
        if (!this.cmsPartner) {
            return false;
        }

        return this.cmsPartner.name === IntegrationPartners.QIKKIDS;
    }

    /**
     * Whether selected cmsPartner is Manage
     */
    private get isManage(): boolean {
        if (!this.cmsPartner) {
            return false;
        }

        return this.cmsPartner.name === IntegrationPartners.MANAGE;
    }

    /**
     * Whether selected cmsPartner is Kangaroo Time
     */
    private get isKangarooTime(): boolean {
        if (!this.cmsPartner) {
            return false;
        }

        return this.cmsPartner.name === IntegrationPartners.KANGAROO_TIME;
    }

    async created() {
        if (authState.overrideCountry && Regions[authState.overrideCountry as keyof typeof Regions]) {
            this.region = Regions[authState.overrideCountry as keyof typeof Regions];
        } else {
            this.region = authState.userInfoObject
                ? Regions[authState.userInfoObject.country as keyof typeof Regions]
                : Regions.us;
        }

        this.integrationService = new IntegrationService(this.region);
    }

    async mounted() {
        if (this.isOpen) {
            loadingState.loadingIncrement(this.loadingKey);

            if (this.integrationService) {
                await this.updateCurrentPartner();
            }

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('cmsPartnerId', { immediate: true })
    private async updatePartner(): Promise<void> {
        if (this.isOpen && !loadingState.isLoading(this.loadingKey)) {
            loadingState.loadingIncrement(this.loadingKey);

            this.cmsPartner = undefined;
            this.apiCustomerId = '';
            this.apiKey = '';
            this.apiPassword = '';
            this.apiUsername = '';

            if (this.cmsPartners && this.cmsPartnerId) {
                if (this.integrationService && (this.cmsPartnersList.length === 0 || this.currentCmsPartners.length === 0)) {
                    this.cmsPartnersList = await this.integrationService.getCmsPartners();
                    this.currentCmsPartners = await this.integrationService.getActiveCmsPartners();
                }

                if (this.currentCmsPartnerId !== this.cmsPartnerId) {
                    const cmsPartner = this.cmsPartners.find((partner) => partner.id === this.cmsPartnerId);
                    if (cmsPartner) {
                        this.cmsPartner = cmsPartner;
                        this.$forceUpdate();
                        ++this.key;
                    }
                } else if (this.currentCmsPartner) {
                    this.cmsPartner = this.currentCmsPartner;

                    this.currentCmsPartnerId = this.currentCmsPartner.id;
                    if (this.currentCmsPartner.needs_credentials && this.currentCmsPartner.credentials) {
                        this.currentApiUsername = this.currentCmsPartner.credentials.username ? this.currentCmsPartner.credentials.username : '';
                        this.currentApiPassword = this.currentCmsPartner.credentials.password ? this.currentCmsPartner.credentials.password : '';
                        this.currentApiCustomerId = this.currentCmsPartner.credentials?.customer_id ? this.currentCmsPartner.credentials.customer_id : '';
                        this.currentApiKey = this.currentCmsPartner.credentials?.api_key ? this.currentCmsPartner.credentials?.api_key : '';
                    } else if (this.currentCmsPartner.name === IntegrationPartners.MANAGE) {
                        this.currentApiCustomerId = this.currentCmsPartner.credentials?.customer_id ? this.currentCmsPartner.credentials.customer_id : '';
                    }

                    this.apiCustomerId = this.currentApiCustomerId;
                    this.apiKey = this.currentApiKey;
                    this.apiPassword = this.currentApiPassword;
                    this.apiUsername = this.currentApiUsername;
                }

                this.$forceUpdate();
                ++this.key;
            }

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('currentCmsPartner', { immediate: true })
    private async updateCurrentPartner(): Promise<void> {
        if (this.isOpen) {
            loadingState.loadingIncrement(this.loadingKey);
        }

        if (this.integrationService && (this.cmsPartnersList.length === 0 || this.currentCmsPartners.length === 0)) {
            this.currentCmsPartners = await this.integrationService.getActiveCmsPartners();
            this.cmsPartnersList = await this.integrationService.getCmsPartners();
        }

        this.cmsPartner = undefined;
        this.cmsPartnerId = 0;
        this.apiCustomerId = '';
        this.apiKey = '';
        this.apiPassword = '';
        this.apiUsername = '';
        this.currentCmsPartnerId = 0;
        this.currentApiCustomerId = '';
        this.currentApiKey = '';
        this.currentApiPassword = '';
        this.currentApiUsername = '';

        if (this.currentCmsPartner) {
            this.cmsPartner = this.currentCmsPartner;
            this.cmsPartnerId = this.currentCmsPartner.id;
            this.currentCmsPartnerId = this.currentCmsPartner.id;

            if (this.currentCmsPartner.needs_credentials && this.currentCmsPartner.credentials) {
                this.currentApiUsername = this.currentCmsPartner.credentials.username ? this.currentCmsPartner.credentials.username : '';
                this.currentApiPassword = this.currentCmsPartner.credentials.password ? this.currentCmsPartner.credentials.password : '';
                this.currentApiCustomerId = this.currentCmsPartner.credentials?.customer_id ? this.currentCmsPartner.credentials.customer_id : '';
                this.currentApiKey = this.currentCmsPartner.credentials?.api_key ? this.currentCmsPartner.credentials?.api_key : '';
            } else if (this.currentCmsPartner.name === IntegrationPartners.MANAGE) {
                this.currentApiCustomerId = this.currentCmsPartner.credentials?.customer_id ? this.currentCmsPartner.credentials.customer_id : '';
            }

            this.apiCustomerId = this.currentApiCustomerId;
            this.apiKey = this.currentApiKey;
            this.apiPassword = this.currentApiPassword;
            this.apiUsername = this.currentApiUsername;
            await this.checkForMappedLocations();
        }

        if (this.isOpen) {
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    /**
     * Check and set the list of locations that are mapped.
     *
     * @private
     */
    private async checkForMappedLocations() {
        this.mappedLocationIds = await repositoryFactoryStore.integrationRepository.getIntegrationLocationMappingIds(this.cmsPartnerId ? this.cmsPartnerId : null);
    }

    private closeMapLocations() {
        this.isMapLocationsOpen = false;
    }

    /**
     * Close the roles modal.
     */
    private closeMapRoles() {
        this.isMapRolesOpen = false;
    }

    /**
     * The list of CMS partners.
     */
    private get cmsPartners(): Array<IntegrationPartner> | undefined {
        if (!this.integrationService || !this.cmsPartnersList) {
            return;
        }

        const list: Array<IntegrationPartner> = [];
        for (const partner of this.cmsPartnersList) {
            if (partner) {
                if (partner.type.id === IntegrationTypes.CMS) {
                    if (this.region !== Regions.us &&
                        (partner.name === IntegrationPartners.PROCARE || partner.name === IntegrationPartners.MANAGE)) {
                        continue;
                    }

                    if (
                        this.region !== Regions.aus &&
                        (
                            (
                                partner.name === IntegrationPartners.QIKKIDS ||
                                partner.name === IntegrationPartners.KIDSOFT
                            ) ||
                            (
                                partner.name === IntegrationPartners.PROCARE &&
                                !(
                                    this.currentCmsPartners &&
                                    this.currentCmsPartners[0].name === IntegrationPartners.PROCARE
                                )
                            )
                        )
                    ) {
                        continue;
                    }

                    if (partner.name === IntegrationPartners.ONCARE_OLD) {
                        partner.name = IntegrationPartners.ONECORE;
                    }

                    if (this.currentCmsPartners.length > 1) {
                        if (
                            partner.id === this.currentCmsPartner?.id &&
                            (
                                this.currentCmsPartners[0].id === this.currentCmsPartner.id ||
                                this.currentCmsPartners[1].id === this.currentCmsPartner.id
                            ) &&
                            (
                                IntegrationPartnerIds.PROCARE === this.currentCmsPartners[0].id ||
                                IntegrationPartnerIds.QIKKIDS === this.currentCmsPartners[0].id
                            )
                        ) {
                            // If we have multiple CMSs setup, and we are editing the primary CMS,
                            // only add this entry to the drop-down.
                            list.push(partner);
                            this.cmsPartnerId = partner.id;
                        }
                    } else if (this.currentCmsPartners.length === 1 && this.isSecondary) {
                        if (
                            (
                                IntegrationPartnerIds.PROCARE === this.currentCmsPartners[0].id &&
                                IntegrationPartnerIds.MANAGE === partner.id
                            ) ||
                            (
                                IntegrationPartnerIds.QIKKIDS === this.currentCmsPartners[0].id &&
                                IntegrationPartnerIds.KANGAROO_TIME === partner.id
                            )
                        ) {
                            // Only push if the partner is valid for a secondary CMS.
                            list.push(partner);
                        }
                    } else {
                        list.push(partner);
                        if (partner.id === this.currentCmsPartner?.id) {
                            this.cmsPartnerId = partner.id;
                        }
                    }
                }
            }
        }

        if (list.length !== 0) {
            list.sort((a, b) => (a.name > b.name) ? -1 : 1);
            list.unshift({
                has_centers_list: false,
                name: 'None',
                needs_centers_mapped: false,
                needs_classrooms_mapped: false,
                needs_credentials: false,
                type: {
                    id: 2,
                    values: {
                        value: 'Management System'
                    },
                    links: [
                        {
                            href: '/api/v3/types/integrations/2',
                            rel: 'integration_type',
                            type: 'GET'
                        }
                    ]
                },
                needs_service_account: false,
                region: null,
                id: 0
            });
        }

        return list;
    }

    private closeModal() {
        // Make sure everything is reset to the original values before closing.
        this.apiCustomerId = '';
        this.apiKey = '';
        this.apiUsername = '';
        this.apiPassword = '';
        this.closeEvent = EventTypes.CLOSE;
        this.cmsPartner = undefined;
        this.cmsPartnerId = 0; // Partner dropdown selected value
        this.cmsPartnersList = [];
        this.currentApiUsername = '';
        this.currentApiPassword = '';
        this.currentApiKey = '';
        this.currentApiCustomerId = '';
        this.currentCmsPartnerId = 0;
        this.currentCmsPartners = [];
        this.integrationService = null;
        this.isMapLocationsOpen = false;
        this.isMapRolesOpen = false;
        this.$emit(this.closeEvent);
    }

    private async mapLocationsSaved() {
        this.$emit(this.locationMappingSaved);
        this.isMapLocationsOpen = false;
        await this.checkForMappedLocations();
    }

    private async mapRolesSaved() {
        this.$emit(this.roleMappingSaved);
        this.isMapRolesOpen = false;
    }

    private openMapLocations() {
        this.isMapLocationsOpen = true;
    }

    private openMapRoles() {
        this.isMapRolesOpen = true;
    }

    private async callExportFamilies() {
        loadingState.loadingIncrement(this.loadingKey);
        await integrationRepository.manageSyncAll();
        loadingState.loadingDecrement(this.loadingKey);
        await this.$swal({
            text: 'Exports Scheduled.',
            icon: 'info'
        });
    }

    private async save() {
        if (this.isOpen) {
            loadingState.loadingIncrement(this.loadingKey);
        }

        if (this.currentCmsPartnerId !== 0 || this.cmsPartnerId !== 0) {
            const partnerId = this.cmsPartnerId === 0 ? this.currentCmsPartnerId : this.cmsPartnerId;
            const newPartner = this.cmsPartnersList.find((partner) => partner.id === partnerId)?.name ?? null;
            const payload: IntegrationPartnerToggle = {
                is_active: this.cmsPartnerId !== 0,
                partner_id: partnerId
            };

            if (this.needsCredentials && !this.isKangarooTime) {
                payload.credentials = {
                    username: this.apiUsername,
                    password: this.apiPassword
                };
            }
            if (this.needsCredentials && this.isQikkids) {
                if (payload.credentials) {
                    payload.credentials.customer_id = this.apiCustomerId;
                }
            }

            if (this.needsCredentials && this.isKangarooTime) {
                payload.credentials = {
                    api_key: this.apiKey
                };
            }

            if (newPartner === IntegrationPartners.MANAGE) {
                payload.credentials = {
                    customer_id: this.apiCustomerId
                };
            }

            await integrationRepository.togglePartner(payload);

            let snackText = '';
            if (this.cmsPartnerId === 0) {
                snackText = 'Integration Partner Removed';
            } else if (this.cmsPartnerId === this.currentCmsPartnerId) {
                snackText = 'Integration Partner Updated';
            } else {
                snackText = 'Integration Partner Added';
            }
            await integrationsState.init(true);

            if (this.isOpen) {
                loadingState.loadingDecrement(this.loadingKey);
            }

            this.$emit(EventTypes.SETTINGS_CHANGED, { snackText: snackText });
            this.closeModal();
        }
    }
}
