
// don't love that we have to do these next two lines here, but jest bombs out on this component otherwise
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VCard from 'vuetify/lib/components/VCard/VCard';
import Vue from 'vue';

// grab the base component we're modifying
const base = Vue.extend({ mixins: [VCard] });
export default base.extend({
    name: 'base-card',
    props: {
        // set the default elevation value here. It actually is already defaulted to 2 in Vuetify, but
        // with this we can set it for all our cards if it's ever desired to be changed
        elevation: {
            type: Number,
            default: 2
        }
    },
    computed: {
        // add crm-card to the css class of the rendered element
        classes(): object {
            return {
                ...VCard.options.computed.classes.call(this),
                'base-card': true
            };
        }
    }
});
