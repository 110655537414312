



















































































import { EventTypes } from '@/constants/event-type-constants';
import {
    filterGroupedFields,
    getEnrollmentRepFieldName,
    getPlaceholderFieldChanges,
    sortFields
} from '@/crm-types/field-utils';
import FieldMapper from '@/crm-types/mappers/field-mapper';
import {
    CrmTypeList,
    CrmTypeOptionCreateDto,
    CrmTypeOptionsChangesInterface,
    CrmTypeOptionUpdateDto,
    CustomTypeIdentifiers
} from '@/crm-types/models/crm-type';
import {
    ChildFields,
    Field,
    FieldEntityType,
    FieldTypes,
    FieldUpdateDto,
    GroupedFieldKeys,
    GuardianFields
} from '@/crm-types/models/field-models';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import StandardFieldsSelectList from '@/crm-types/components/StandardFieldsSelectList.vue';

const ecSettingsStore = getModule(EnrollmentCenterSettingsStore);
const featureStore = getModule(FeaturesStore);
const fieldMapper = new FieldMapper();
const fieldsState = getModule(FieldsStore);
@Component({
    components: { StandardFieldsSelectList }
})
export default class ManageFields extends Mixins(LocaleMixin) {
    /**
     * Which type of fields to show, such as guardian or child.
     */
    @Prop({ required: true }) readonly fieldType!: FieldEntityType
    /**
     * The title of this template.
     */
    @Prop({ required: true }) readonly title!: string;
    /**
     * Index for use with syncing with the parent.
     */
    @PropSync('panel', { type: Number }) panelIndexSynced!: number;

    /**
     * Whether or not the created lifecycle hook is finished.
     */
    private isCreated = false;

    /**
     * The fields that will be used in the table for updates.
     */
    private fields: Array<Field> = [];

    /**
     * On Standard Child Fields remove the "required" box for Eligible for Re-enrollment
     */
    private childFieldEntityType = FieldEntityType.CHILD;
    private reenrollmentFieldValue = ChildFields.ELIGIBLE_FOR_REENROLLMENT;

    /**
     * The fields to not show in the child list.
     */
    private filteredChildFields: Array<string> = [
        ChildFields.FIRST_NAME,
        ChildFields.LAST_NAME,
        ChildFields.CHILD_STATUS,
        ChildFields.BIRTHDATE,
        ChildFields.LOST_TO_COMPETITOR,
        CustomTypeIdentifiers.CHILD_TYPE_1,
        CustomTypeIdentifiers.CHILD_TYPE_2,
        CustomTypeIdentifiers.CHILD_TYPE_3,
        CustomTypeIdentifiers.CHILD_TYPE_4
    ];

    /**
     * The fields to not show in the guardian list.
     */
    private filteredGuardianFields: Array<string> = [
        GuardianFields.FIRST_NAME,
        GuardianFields.LAST_NAME,
        GuardianFields.STATUS,
        GuardianFields.LOST_TO_COMPETITOR,
        CustomTypeIdentifiers.LEAD_TYPE_1,
        CustomTypeIdentifiers.LEAD_TYPE_2,
        CustomTypeIdentifiers.LEAD_TYPE_3,
        CustomTypeIdentifiers.LEAD_TYPE_4
    ];

    private headers: Array<DataTableHeader> = [
        { text: '', value: 'data-table-expand', width: '10%' },
        { text: 'Field Name', value: 'value', width: '30%' },
        { text: 'Required', value: 'is_client_required', align: 'center', width: '30%' },
        { text: 'Remove From CRM', value: 'is_hidden', align: 'center', width: '30%' }
    ]

    /**
     *  Keep track of changes for all different type of select options
     */
    private optionsChanges: Array<CrmTypeOptionsChangesInterface> = [];
    private updatedEvent = EventTypes.UPDATED;

    get isEnrollmentCenterEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    /**
     * Get the fields from the store that match the passed in field type.
     * These should be readonly is use.
     * This component expects the store to already be populated.
     */
    private get readonlyFields(): Array<Field> {
        switch (this.fieldType) {
            case FieldEntityType.CHILD:
                return fieldsState.storedChildFields.filter((field) => {
                    return !this.filteredChildFields.includes(field.value) && (!field.select_list_name || !this.filteredChildFields.includes(field.select_list_name));
                });
            case FieldEntityType.GUARDIAN:
            default:
                // return fieldsState.storedGuardianFields.filter(field => !this.filteredGuardianFields.includes(field.value));
                return fieldsState.storedGuardianFields.filter((field) => {
                    return !this.filteredGuardianFields.includes(field.value) && (!field.select_list_name || !this.filteredGuardianFields.includes(field.select_list_name));
                });
        }
    }

    /**
     * When the fields are loaded from the store, populate the fields for use in the table.
     * Do this so that the original fields will not be modified and can be checked against for changes.
     */
    @Watch('readonlyFields', { deep: true, immediate: true })
    private fieldsLoaded() {
        this.fields = cloneDeep(
            sortFields(
                this.fieldType,
                filterGroupedFields(this.fieldType, this.readonlyFields)
            )
        );
    }

    /**
     * Watch changes to the fields to determine if anything has changed.
     * Emit to the parent any changes.
     */
    @Watch('fields', { deep: true })
    private async watchChanges() {
        let dto: FieldUpdateDto | null = null;
        const dtoArray: Array<FieldUpdateDto> = [];
        const groupedIds = Object.values(GroupedFieldKeys);

        for (const field of this.fields) {
            if (groupedIds.includes(field.id)) {
                dtoArray.push(...getPlaceholderFieldChanges(field, this.readonlyFields));
                continue;
            }
            dto = fieldMapper.toUpdateDto(field);
            // There shouldn't actually be a wait here since it will be in the store
            const originalField = await fieldsState.getById(field.id);
            if (!isEqual(dto, fieldMapper.toUpdateDto(originalField))) {
                dtoArray.push(dto);
            }
        }

        this.$emit(EventTypes.NAME_UPDATED, dtoArray);
    }

    @Watch('optionsChanges', { deep: true })
    private emitChanges() {
        if (this.optionsChanges.length > 0) {
            this.$emit(EventTypes.UPDATED, this.optionsChanges);
        }
    }

    private async created() {
        await featureStore.init();
        if (this.isEnrollmentCenterEnabled) {
            await ecSettingsStore.init();
        }
        this.isCreated = true;
    }

    /**
     * Get the label for the field.
     *
     * @param field
     */
    private getLabel(field: Field): string {
        if (this.isEnrollmentCenterEnabled && field.value === GuardianFields.ENROLLMENT_REP) {
            return getEnrollmentRepFieldName(ecSettingsStore.storedSettings!.name);
        }
        if (field.value === ChildFields.ELIGIBLE_FOR_REENROLLMENT) {
            return `Eligible for Re-${this.$t('enrollment')}`;
        }
        return field.value;
    }

    private canExpand(item: Field): boolean {
        return (item.type.id === FieldTypes.SELECT_LIST && item.value !== GuardianFields.PHONE_TYPE && item.value !== GuardianFields.ENROLLMENT_REP && item.value !== ChildFields.CLASS);
    }

    /**
     *  Combine all of changes into one array of changes with identifier as the crmTypeList
     */
    private updateTypes(updates: {deletedOptions: Array<number>; updatedOptions: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto>; crmTypeList: CrmTypeList}) {
        if (updates.crmTypeList) {
            if (!this.optionsChanges.find(option => option.crmTypeList === updates.crmTypeList)) {
                this.optionsChanges.push(updates);
            } else {
                for (const optionChange of this.optionsChanges) {
                    if (optionChange.crmTypeList === updates.crmTypeList) {
                        optionChange.updatedOptions = updates.updatedOptions;
                        optionChange.deletedOptions = updates.deletedOptions;
                    }
                }
            }
        }
    }

}
