import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Field, FieldUpdateDto } from '@/crm-types/models/field-models';

export default class FieldMapper extends AbstractMapper<Field> {
    toCreateDto(entity: Field): FieldUpdateDto {
        return {
            is_client_required: entity.is_client_required ?? false,
            is_hidden: entity.is_hidden ?? true
        };
    }

    toUpdateDto(entity: Field): FieldUpdateDto {
        const dto = this.toCreateDto(entity) as FieldUpdateDto;
        // The id won't be needed in the requests, but it is used for tracking purposes because all field updates
        // are handled in bulk actions, currently.
        dto.id = entity.id;
        return dto;
    }
}
