import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { SavedReport, StandardReportGroup, StandardReportType } from '@/reports/models/saved-report';
import { ReportFormatNames } from '@/reports/report-format-constants';
import { ReportsRepository } from '@/reports/repositories/reports-repository';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { getGmtOffset } from '@/date-time/date-time-utils';
import { addMonths, format, subMonths, subWeeks, subYears } from 'date-fns';
import {
    activeStatusesPlaceholder,
    conversionTimingIdentifier,
    ReportIdentifier,
    StandardReportIdentifier, tourMeetingTypesPlaceholder, trendTrackingIdentifier
} from '@/reports/report-constants';
import { StatusesStore } from '@/families/store/statuses-store';
import {
    addDynamicStatusQuery,
    addOnlyActiveStatusFilters,
    addRejectedStatusAndBlankFilter, addTourMeetingTypesToReport
} from '@/reports/report-utils';
import { SliceHierarchy } from 'flexmonster';
import store from '@/store';
import { BaseStatuses } from '@/constants/status-constants';
import { TaskGroupsStore } from '@/tasks/stores/task-groups-store';
import { TaskUtilities } from '@/tasks/task-utils';
import { TaskGroups } from '@/tasks/models/task-models';

const authState = getModule(AuthStore, store);
const today = new Date();

const dateTimePattern = 'GMT' + getGmtOffset(authState.userTimeZone) + ':MM/dd/yyyy hh:mm:ss TT';
const featuresStore = getModule(FeaturesStore);
const reportsRepository = new ReportsRepository();
const statusesStore = getModule(StatusesStore);
const taskGroupsStore = getModule(TaskGroupsStore);

const baseStandardReport: SavedReport = {
    id: 0,
    description: '',
    report_data: '',
    is_editable: false,
    name: '',
    shared_by: null,
    shared_name: '',
    shared_with: [],
    is_standard_report: true
};

// https://dataformat.net/json/converter/to-javascript was very helpful for a lot of this

export const scheduledTasksChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'original_due_date'
            },
            {
                uniqueName: 'assigned_by_name'
            },
            {
                uniqueName: 'assigned_to_name'
            },
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'description'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'type',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name'
        ],
        reportFilters: [
            {
                filter: {
                    members: [
                        'is_completed.[no]'
                    ]
                },
                uniqueName: 'is_completed'
            },
            {
                filter: {
                    members: [
                        'is_cancelled.[no]'
                    ]
                },
                uniqueName: 'is_cancelled'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true,
        filter: {
            datePattern: 'MM/dd/yyyy'
        }
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const scheduledTasksTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'center_code'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'due_time'
            },
            {
                uniqueName: 'original_due_date'
            },
            {
                uniqueName: 'original_due_time'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'description'
            },
            {
                uniqueName: 'assigned_by_name'
            },
            {
                uniqueName: 'assigned_to_name'
            },
            {
                uniqueName: 'is_completed'
            },
            {
                uniqueName: 'is_cancelled'
            },
            {
                uniqueName: 'is_cancelled_by_workflow_automation'
            },
            {
                filter: {
                    members: [
                        'is_cancelled.[no]'
                    ]
                },
                uniqueName: 'is_cancelled'
            },
            {
                filter: {
                    members: [
                        'is_cancelled_by_workflow_automation.[no]'
                    ]
                },
                uniqueName: 'is_cancelled_by_workflow_automation'
            },
            {
                filter: {
                    members: [
                        'is_completed.[no]'
                    ]
                },
                uniqueName: 'is_completed'
            }
        ],
        measures: [
            {
                uniqueName: 'added_date',
                aggregation: 'max'
            },
            {
                uniqueName: 'due_date',
                aggregation: 'max'
            },
            {
                uniqueName: 'original_due_date',
                aggregation: 'max'
            }
        ],
        flatOrder: [
            'center_name',
            'center_code',
            'primary_guardian_name',
            'added_date',
            'due_date',
            'due_time',
            'original_due_date',
            'original_due_time',
            'type',
            'description',
            'assigned_by_name',
            'assigned_to_name',
            'is_completed',
            'is_cancelled',
            'is_cancelled_by_workflow_automation'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'line'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const currentOppsChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                filter: {
                    members: [activeStatusesPlaceholder]
                },
                uniqueName: 'status'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'child_birthdate'
            },
            {
                uniqueName: 'family_added_datetime'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'last_contacted_datetime'
            },
            {
                uniqueName: 'child_expected_start_date'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'status',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'status'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Current Statuses',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/families'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const currentOppsSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'child_birthdate'
            },
            {
                uniqueName: 'family_added_datetime'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'last_contacted_datetime'
            },
            {
                uniqueName: 'child_expected_start_date'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                filter: {
                    members: [activeStatusesPlaceholder]
                },
                uniqueName: 'status'
            }
        ],
        measures: [
            {
                uniqueName: 'status',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'status'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Current Statuses',
            showTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/families'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const currentOppsTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'child_birthdate'
            },
            {
                uniqueName: 'family_added_datetime'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'last_contacted_datetime'
            },
            {
                uniqueName: 'child_expected_start_date'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'status',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'status'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Current Statuses',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/families'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const tasksChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'actioned_by_name'
            },
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'original_due_date'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'actioned_date'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'description'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'result'
            },
            {
                uniqueName: 'is_completed'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        sorting: {
            column: {
                type: 'desc',
                measure: {
                    uniqueName: 'type',
                    aggregation: 'count'
                }
            }
        },
        measures: [
            {
                uniqueName: 'type',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'actioned_by_name',
            'added_date',
            'original_due_date',
            'original_due_time',
            'due_date',
            'due_time',
            'actioned_date',
            'actioned_time',
            'type',
            'description',
            'result',
            'primary_guardian_name',
            'is_completed'
        ],
        reportFilters: [
            {
                filter: {
                    members: [
                        'is_completed.[yes]'
                    ]
                },
                uniqueName: 'is_completed'
            },
            {
                uniqueName: 'added_date'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const tasksTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'actioned_by_name'
            },
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'original_due_date'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'actioned_date'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'description'
            },
            {
                uniqueName: 'result'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'is_completed'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        sorting: {
            column: {
                type: 'desc',
                measure: {
                    uniqueName: 'type',
                    aggregation: 'count'
                }
            }
        },
        measures: [
            {
                uniqueName: 'type',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'actioned_by_name',
            'added_date',
            'original_due_date',
            'original_due_time',
            'due_date',
            'due_time',
            'actioned_date',
            'actioned_time',
            'type',
            'description',
            'result',
            'primary_guardian_name',
            'is_completed'
        ],
        reportFilters: [
            {
                filter: {
                    members: [
                        'is_completed.[yes]'
                    ]
                },
                uniqueName: 'is_completed'
            },
            {
                uniqueName: 'added_date'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const sourceChart = {
    slice: {
        rows: [
            {
                uniqueName: 'family_source'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'family_added_datetime'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        sorting: {
            column: {
                type: 'desc',
                measure: {
                    uniqueName: 'family_source',
                    aggregation: 'count'
                }
            }
        },
        measures: [
            {
                uniqueName: 'family_source',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'family_source',
            'child_name',
            'guardian_name',
            'status',
            'family_added_datetime'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Current Statuses',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/families'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const sourceTable = {
    slice: {
        rows: [
            {
                uniqueName: 'family_source'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'family_added_datetime'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        sorting: {
            column: {
                type: 'desc',
                tuple: [
                ],
                measure: {
                    uniqueName: 'family_source',
                    aggregation: 'count'
                }
            }
        },
        measures: [
            {
                uniqueName: 'family_source',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'family_source',
            'child_name',
            'guardian_name',
            'status',
            'family_added_datetime'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Current Statuses',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/families'
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const leadListTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'primary_guardian_added_datetime'
            },
            {
                uniqueName: 'primary_guardian_address'
            },
            {
                uniqueName: 'primary_guardian_city'
            },
            {
                uniqueName: 'primary_guardian_state'
            },
            {
                uniqueName: 'primary_guardian_postal_code'
            },
            {
                uniqueName: 'primary_guardian_phone_number'
            },
            {
                uniqueName: 'primary_guardian_alternate_phone'
            },
            {
                uniqueName: 'primary_guardian_email'
            }
        ],
        measures: [
            {
                uniqueName: 'primary_guardian_added_datetime',
                aggregation: 'max'
            }
        ],
        flatOrder: [
            'center_name',
            'primary_guardian_name',
            'primary_guardian_added_datetime',
            'primary_guardian_address',
            'primary_guardian_city',
            'primary_guardian_state',
            'primary_guardian_postal_code',
            'primary_guardian_phone_number',
            'primary_guardian_alternate_phone',
            'primary_guardian_email'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Guardians',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'line'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/guardians',
        mapping: reportsRepository.url + '/reports/guardians&getMapping=1',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.

    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const leadListSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'primary_guardian_added_datetime'
            },
            {
                uniqueName: 'primary_guardian_city'
            },
            {
                uniqueName: 'primary_guardian_state'
            },
            {
                uniqueName: 'primary_guardian_postal_code'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'primary_guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'primary_guardian_name',
            'primary_guardian_added_datetime',
            'primary_guardian_address',
            'primary_guardian_city',
            'primary_guardian_state',
            'primary_guardian_postal_code',
            'primary_guardian_phone_number',
            'primary_guardian_alternate_phone',
            'primary_guardian_email'
        ],
        reportFilters: [
            {
                uniqueName: 'primary_guardian_added_datetime'
            },
            {
                uniqueName: 'primary_guardian_city'
            },
            {
                uniqueName: 'primary_guardian_state'
            },
            {
                uniqueName: 'primary_guardian_postal_code'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Guardians',
            showTotals: 'off',
            showGrandTotals: 'rows',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'line'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/guardians',
        mapping: reportsRepository.url + '/reports/guardians&getMapping=1',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const leadListGraph = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'primary_guardian_added_datetime'
            },
            {
                uniqueName: 'primary_guardian_city'
            },
            {
                uniqueName: 'primary_guardian_state'
            },
            {
                uniqueName: 'primary_guardian_postal_code'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        sorting: {
            column: {
                type: 'desc',
                tuple: [],
                measure: {
                    uniqueName: 'primary_guardian_name',
                    aggregation: 'count'
                }
            }
        },
        measures: [
            {
                uniqueName: 'primary_guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'primary_guardian_name',
            'primary_guardian_added_datetime',
            'primary_guardian_address',
            'primary_guardian_city',
            'primary_guardian_state',
            'primary_guardian_postal_code',
            'primary_guardian_phone_number',
            'primary_guardian_alternate_phone',
            'primary_guardian_email'
        ],
        reportFilters: [
            {
                uniqueName: 'primary_guardian_added_datetime'
            },
            {
                uniqueName: 'primary_guardian_city'
            },
            {
                uniqueName: 'primary_guardian_state'
            },
            {
                uniqueName: 'primary_guardian_postal_code'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Guardians',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'bar_h'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/guardians',
        mapping: reportsRepository.url + '/reports/guardians&getMapping=1',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const toursMeetingsTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                uniqueName: 'primary_phone'
            },
            {
                filter: {
                    members: [tourMeetingTypesPlaceholder]
                },
                uniqueName: 'type'
            },
            {
                uniqueName: 'result'
            },
            {
                uniqueName: 'assigned_by_name'
            },
            {
                uniqueName: 'assigned_to_name'
            },
            {
                uniqueName: 'actioned_by_name'
            },
            {
                uniqueName: 'is_completed'
            },
            {
                uniqueName: 'is_cancelled'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'due_time'
            }
        ],
        flatSort: [
            {
                sort: 'asc',
                uniqueName: 'due_date'
            }
        ],
        measures: [
            {
                uniqueName: 'due_date',
                aggregation: 'max'
            }
        ],
        flatOrder: [
            'center_name',
            'is_completed',
            'is_cancelled',
            'due_date',
            'due_time',
            'type',
            'result',
            'primary_guardian_name',
            'primary_phone',
            'assigned_by_name',
            'assigned_to_name',
            'actioned_by_name'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'line'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks',
        mapping: reportsRepository.url + '/reports/tasks&getMapping=1',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const toursMeetingsSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'primary_guardian_name'
            },
            {
                filter: {
                    members: [tourMeetingTypesPlaceholder]
                },
                uniqueName: 'type'
            },
            {
                uniqueName: 'is_completed'
            },
            {
                uniqueName: 'is_cancelled'
            },
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'due_date'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'is_completed'
            }
        ],
        measures: [
            {
                uniqueName: 'primary_guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'center_name',
            'primary_guardian_name',
            'type',
            'is_completed',
            'is_cancelled'
        ],
        reportFilters: [
            {
                uniqueName: 'added_date'
            },
            {
                uniqueName: 'due_date'
            },
            {
                uniqueName: 'is_cancelled'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            decimalPlaces: 0,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            divideByZeroValue: 'Infinity',
            thousandsSeparator: ' ',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Tasks',
            showTotals: 'off',
            showGrandTotals: 'off',
            showFilter: false,
            showHierarchies: false
        },
        chart: {
            type: 'line'
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        showAggregations: true
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/tasks',
        mapping: reportsRepository.url + '/reports/tasks&getMapping=1',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

export const conversionSuccessNumbers = {
    slice:
        {
            rows: [
                {
                    uniqueName: 'center_name'
                },
                {
                    uniqueName: 'child_added_date'
                }
            ],
            columns:
                [
                    {
                        uniqueName: '[Measures]'
                    }
                ],
            measures:
                [
                    {
                        uniqueName: 'status_1',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_2',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_11',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_3',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_4',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_5',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_6',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_9',
                        aggregation: 'count'
                    }
                ],
            flatOrder:
                [
                    'center_name',
                    'child_added_date'
                ]
        },
    formats:
        [
            {
                name: '',
                nullValue: ' ',
                maxSymbols: 20,
                decimalPlaces: 0,
                infinityValue: 'Infinity',
                currencySymbol: '',
                decimalSeparator: '.',
                divideByZeroValue: 'Infinity',
                thousandsSeparator: ' ',
                positiveCurrencyFormat: '$1'
            }
        ],
    options:
        {
            grid:
                {
                    type: 'classic',
                    title: 'Conversion Success (Number & Percentage)',
                    showTotals: 'off',
                    showGrandTotals: 'rows',
                    showFilter: false,
                    showHierarchies: false
                },
            chart:
                {
                    type: 'line',
                    showFilter: false
                },
            filter:
                {
                    dateFormat: 'MM/dd/yyyy'
                },
            datePattern: 'MM/dd/yyyy',
            dateTimePattern: dateTimePattern,
            showAggregations: true
        },
    version: '2.8.30',
    dataSource:
        {
            type: 'json',
            mapping: reportsRepository.url + '/reports/conversion-success?getMapping=1',
            filename: reportsRepository.url + '/reports/conversion-success',
            useStreamLoader: true
            // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
        },
    localization:
        {
            grid:
                {
                    dateInvalidCaption: ''
                }
        }
};

export const conversionSuccessPercentages = {
    slice:
        {
            rows:
                [
                    {
                        uniqueName: 'center_name'
                    },
                    {
                        uniqueName: 'child_added_date'
                    }
                ],
            columns:
                [
                    {
                        uniqueName: '[Measures]'
                    }
                ],
            measures:
                [
                    {
                        uniqueName: 'status_1',
                        aggregation: 'count'
                    },
                    {
                        format: '-p9qijxq80dd00',
                        caption: 'Engaged (%)',
                        formula: 'count("status_2") / count("status_1")',
                        uniqueName: 'Engaged (%)'
                    },
                    {
                        format: '-v8n1qto5w1c00',
                        caption: 'Tour Scheduled (%)',
                        formula: 'count("status_11") / count("status_1")',
                        uniqueName: 'Tour Scheduled (%)'
                    },
                    {
                        format: '-l2wchhtl5dt00',
                        caption: 'Tour Completed (%)',
                        formula: 'count("status_3") / count("status_1")',
                        uniqueName: 'Tour Completed (%)'
                    },
                    {
                        format: '-79j6oosherv00',
                        caption: 'Wait List (%)',
                        formula: 'count("status_4") / count("status_1")',
                        uniqueName: 'Wait List %'
                    },
                    {
                        format: '-1d8aw62ofx2600',
                        caption: 'Registered (%)',
                        formula: 'count("status_5") / count("status_1")',
                        uniqueName: 'Registered (%)'
                    },
                    {
                        format: '-i8i5rwh407800',
                        caption: 'Enrolled (%)',
                        formula: 'count("status_6") / count("status_1")',
                        uniqueName: 'Enrolled (%)'
                    },
                    {
                        format: '-h16o5qbs56l00',
                        caption: 'Lost Opportunity (%)',
                        formula: 'count("status_9") / count("status_1")',
                        uniqueName: 'Lost Opportunity (%)'
                    }
                ],
            flatOrder:
                [
                    'center_name',
                    'child_added_date'
                ]
        },
    formats:
        [
            {
                name: '',
                nullValue: ' ',
                maxSymbols: 20,
                decimalPlaces: 0,
                infinityValue: 'Infinity',
                currencySymbol: '',
                decimalSeparator: '.',
                divideByZeroValue: 'Infinity',
                thousandsSeparator: ' ',
                positiveCurrencyFormat: '$1'
            },
            {
                name: '-p9qijxq80dd00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-v8n1qto5w1c00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-l2wchhtl5dt00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-79j6oosherv00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-1d8aw62ofx2600',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-i8i5rwh407800',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-h16o5qbs56l00',
                isPercent: true,
                decimalPlaces: 2
            }
        ],
    options:
        {
            grid:
                {
                    type: 'classic',
                    title: 'Conversion Success (# and %)',
                    showTotals: 'off',
                    showGrandTotals: 'rows',
                    showFilter: false,
                    showHierarchies: false,
                    showEmptyData: true
                },
            chart:
                {
                    type: 'line',
                    showFilter: false
                },
            filter:
                {
                    dateFormat: 'MM/dd/yyyy'
                },
            datePattern: 'MM/dd/yyyy',
            dateTimePattern: dateTimePattern,
            showAggregations: true
        },
    version: '2.8.30',
    dataSource:
        {
            type: 'json',
            mapping: reportsRepository.url + '/reports/conversion-success?getMapping=1',
            filename: reportsRepository.url + '/reports/conversion-success',
            useStreamLoader: true
            // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
        },
    creationDate: '2021-11-16T19:48:49.018Z',
    localization:
        {
            grid:
                {
                    dateInvalidCaption: ''
                }
        }
};

export const conversionSuccessNumbersAndPercentages = {
    slice:
        {
            rows: [
                {
                    uniqueName: 'center_name'
                },
                {
                    uniqueName: 'child_added_date'
                }
            ],
            columns:
                [
                    {
                        uniqueName: '[Measures]'
                    }
                ],
            measures:
                [
                    {
                        uniqueName: 'status_1',
                        aggregation: 'count'
                    },
                    {
                        uniqueName: 'status_2',
                        aggregation: 'count'
                    },
                    {
                        format: '-p9qijxq80dd00',
                        caption: 'Engaged (%)',
                        formula: 'count("status_2") / count("status_1")',
                        uniqueName: 'Engaged (%)'
                    },
                    {
                        uniqueName: 'status_11',
                        aggregation: 'count'
                    },
                    {
                        format: '-v8n1qto5w1c00',
                        caption: 'Tour Scheduled (%)',
                        formula: 'count("status_11") / count("status_1")',
                        uniqueName: 'Tour Scheduled (%)'
                    },
                    {
                        uniqueName: 'status_3',
                        aggregation: 'count'
                    },
                    {
                        format: '-l2wchhtl5dt00',
                        caption: 'Tour Completed (%)',
                        formula: 'count("status_3") / count("status_1")',
                        uniqueName: 'Tour Completed (%)'
                    },
                    {
                        uniqueName: 'status_4',
                        aggregation: 'count'
                    },
                    {
                        format: '-79j6oosherv00',
                        caption: 'Wait List (%)',
                        formula: 'count("status_4") / count("status_1")',
                        uniqueName: 'Wait List %'
                    },
                    {
                        uniqueName: 'status_5',
                        aggregation: 'count'
                    },
                    {
                        format: '-1d8aw62ofx2600',
                        caption: 'Registered (%)',
                        formula: 'count("status_5") / count("status_1")',
                        uniqueName: 'Registered (%)'
                    },
                    {
                        uniqueName: 'status_6',
                        aggregation: 'count'
                    },
                    {
                        format: '-i8i5rwh407800',
                        caption: 'Enrolled (%)',
                        formula: 'count("status_6") / count("status_1")',
                        uniqueName: 'Enrolled (%)'
                    },
                    {
                        uniqueName: 'status_9',
                        aggregation: 'count'
                    },
                    {
                        format: '-h16o5qbs56l00',
                        caption: 'Lost Opportunity (%)',
                        formula: 'count("status_9") / count("status_1")',
                        uniqueName: 'Lost Opportunity (%)'
                    }
                ],
            flatOrder:
                [
                    'center_name',
                    'child_added_date'
                ]
        },
    formats:
        [
            {
                name: '',
                nullValue: ' ',
                maxSymbols: 20,
                decimalPlaces: 0,
                infinityValue: 'Infinity',
                currencySymbol: '',
                decimalSeparator: '.',
                divideByZeroValue: 'Infinity',
                thousandsSeparator: ' ',
                positiveCurrencyFormat: '$1'
            },
            {
                name: '-p9qijxq80dd00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-v8n1qto5w1c00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-l2wchhtl5dt00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-79j6oosherv00',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-1d8aw62ofx2600',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-i8i5rwh407800',
                isPercent: true,
                decimalPlaces: 2
            },
            {
                name: '-h16o5qbs56l00',
                isPercent: true,
                decimalPlaces: 2
            }
        ],
    options:
        {
            grid:
                {
                    type: 'classic',
                    title: 'Conversion Success (# and %)',
                    showTotals: 'off',
                    showGrandTotals: 'rows',
                    showFilter: false,
                    showHierarchies: false
                },
            chart:
                {
                    type: 'line',
                    showFilter: false
                },
            filter:
                {
                    dateFormat: 'MM/dd/yyyy'
                },
            datePattern: 'MM/dd/yyyy',
            dateTimePattern: dateTimePattern,
            showAggregations: true
        },
    version: '2.8.30',
    dataSource:
        {
            type: 'json',
            mapping: reportsRepository.url + '/reports/conversion-success?getMapping=1',
            filename: reportsRepository.url + '/reports/conversion-success',
            useStreamLoader: true
            // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
        },
    localization:
        {
            grid:
                {
                    dateInvalidCaption: ''
                }
        }
};

const waitListTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'child_age'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subYears(today, 6), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_birthdate'
            },
            {
                uniqueName: 'child_comments'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subMonths(today, 1), 'yyyy-MM-dd')}`,
                            `${format(addMonths(today, 1), 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                uniqueName: 'child_gender'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'wait_list_date'
            },
            {
                uniqueName: 'wait_list_days'
            },
            {
                uniqueName: 'wait_list_fee'
            },
            {
                uniqueName: 'wait_list_date_fee_paid'
            },
            {
                uniqueName: 'wait_list_reason'
            },
            {
                uniqueName: 'child_class'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'family_added_datetime'
            },
            {
                uniqueName: 'last_contacted_datetime'
            },
            {
                uniqueName: 'status'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'child_monday_am'
            },
            {
                uniqueName: 'child_monday_pm'
            },
            {
                uniqueName: 'child_tuesday_am'
            },
            {
                uniqueName: 'child_tuesday_pm'
            },
            {
                uniqueName: 'child_wednesday_am'
            },
            {
                uniqueName: 'child_wednesday_pm'
            },
            {
                uniqueName: 'child_thursday_am'
            },
            {
                uniqueName: 'child_thursday_pm'
            },
            {
                uniqueName: 'child_friday_am'
            },
            {
                uniqueName: 'child_friday_pm'
            },
            {
                uniqueName: 'child_saturday_am'
            },
            {
                uniqueName: 'child_saturday_pm'
            },
            {
                uniqueName: 'child_sunday_am'
            },
            {
                uniqueName: 'child_sunday_pm'
            },
            {
                uniqueName: 'do_not_call'
            },
            {
                uniqueName: 'do_not_email'
            },
            {
                uniqueName: 'do_not_text'
            },
            {
                uniqueName: 'email'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'phone_number'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        flatSort: [
            {
                sort: 'asc',
                uniqueName: 'child_sunday_pm'
            }
        ],
        flatOrder: [
            'status',
            'status_datetime',
            'family_added_datetime',
            'center_name',
            'wait_list_date',
            'wait_list_days',
            'child_expected_start_date',
            'last_contacted_datetime',
            'child_name',
            'child_gender',
            'child_age',
            'child_birthdate',
            'wait_list_reason',
            'wait_list_fee',
            'wait_list_date_fee_paid',
            'child_class',
            'child_comments',
            'guardian_name',
            'do_not_email',
            'email',
            'do_not_call',
            'do_not_text',
            'phone_number',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Family & Status',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    tableSizes: {
        columns: [
            {
                tuple: [],
                width: 75,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 60,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 70,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 123,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 140,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 89,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 133,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 135,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 99,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 123,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 126,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 124,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 123,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 160,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 182,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 147,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 65,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 166,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 116,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 116,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 119,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 123,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 68,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 73,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 73,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 68,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 68,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 72,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 71,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 68,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 70,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 65,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 64,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 67,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 66,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 70,
                measure: {
                    uniqueName: ''
                }
            },
            {
                tuple: [],
                width: 69,
                measure: {
                    uniqueName: ''
                }
            }
        ]
    },
    creationDate: '2022-07-21T18:46:01.873Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const waitListSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'child_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'status',
            'status_datetime',
            'family_added_datetime',
            'center_name',
            'wait_list_date',
            'wait_list_days',
            'child_expected_start_date',
            'last_contacted_datetime',
            'child_name',
            'child_gender',
            'child_age',
            'child_birthdate',
            'wait_list_reason',
            'wait_list_fee',
            'wait_list_date_fee_paid',
            'child_class',
            'child_comments',
            'guardian_name',
            'do_not_email',
            'email',
            'do_not_call',
            'do_not_text',
            'phone_number',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'status_datetime'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subMonths(today, 1), 'yyyy-MM-dd')}`,
                            `${format(addMonths(today, 1), 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'family_added_datetime'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subYears(today, 6), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_birthdate'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Wait List (Table)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    tableSizes: {
        columns: [
            {
                tuple: [],
                width: 162,
                measure: {
                    uniqueName: 'child_name',
                    aggregation: 'count'
                }
            }
        ]
    },
    creationDate: '2022-07-21T19:10:16.155Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const waitListChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'child_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'status',
            'status_datetime',
            'family_added_datetime',
            'center_name',
            'wait_list_date',
            'wait_list_days',
            'child_expected_start_date',
            'last_contacted_datetime',
            'child_name',
            'child_gender',
            'child_age',
            'child_birthdate',
            'wait_list_reason',
            'wait_list_fee',
            'wait_list_date_fee_paid',
            'child_class',
            'child_comments',
            'guardian_name',
            'do_not_email',
            'email',
            'do_not_call',
            'do_not_text',
            'phone_number',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'status_datetime'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subMonths(today, 1), 'yyyy-MM-dd')}`,
                            `${format(addMonths(today, 1), 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'family_added_datetime'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(subYears(today, 6), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_birthdate'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Wait List (Summary)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'bar_h',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    tableSizes: {
        columns: [
            {
                tuple: [],
                width: 162,
                measure: {
                    uniqueName: 'child_name',
                    aggregation: 'count'
                }
            }
        ]
    },
    creationDate: '2022-07-21T19:10:43.163Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const estimatedRevenueTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                uniqueName: 'child_name'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'opp_added_datetime'
            },
            {
                uniqueName: 'child_ftes'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_yearly_rev'
            },
            {
                uniqueName: 'child_lifetime_rev'
            },
            {
                uniqueName: 'child_monthly_rev'
            },
            {
                uniqueName: 'child_weekly_rev'
            },
            {
                uniqueName: 'family_status'
            },
            {
                uniqueName: 'family_status_date'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                active: false,
                format: '-12tdoxqoapb800',
                uniqueName: 'child_ftes',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-j95o3immflm00',
                uniqueName: 'child_yearly_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-5m3rm8xnuet00',
                uniqueName: 'child_lifetime_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-ipu4hx2c3hp00',
                uniqueName: 'child_monthly_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-1808peg90b6m0',
                uniqueName: 'child_weekly_rev',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'opp_added_datetime',
            'child_expected_start_date',
            'center_name',
            'family_status',
            'family_status_date',
            'guardian_name',
            'child_name',
            'child_ftes',
            'child_weekly_rev',
            'child_monthly_rev',
            'child_yearly_rev',
            'child_lifetime_rev'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: '-j95o3immflm00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-ipu4hx2c3hp00',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-1808peg90b6m0',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-5m3rm8xnuet00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-12tdoxqoapb800',
            decimalPlaces: 1
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'All Family Data',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families-all-data?getMapping=1',
        filename: reportsRepository.url + '/reports/families-all-data',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T22:39:22.711Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const estimatedRevenueSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'family_status'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'count'
            },
            {
                format: '-12tdoxqoapb800',
                uniqueName: 'child_ftes',
                aggregation: 'sum'
            },
            {
                format: '-1808peg90b6m0',
                uniqueName: 'child_weekly_rev',
                aggregation: 'sum'
            },
            {
                format: '-ipu4hx2c3hp00',
                uniqueName: 'child_monthly_rev',
                aggregation: 'sum'
            },
            {
                format: '-j95o3immflm00',
                uniqueName: 'child_yearly_rev',
                aggregation: 'sum'
            },
            {
                format: '-5m3rm8xnuet00',
                uniqueName: 'child_lifetime_rev',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'opp_added_datetime',
            'child_expected_start_date',
            'center_name',
            'family_status',
            'family_status_date',
            'guardian_name',
            'child_name',
            'child_ftes',
            'child_weekly_rev',
            'child_monthly_rev',
            'child_yearly_rev',
            'child_lifetime_rev'
        ],
        reportFilters: [
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'opp_added_datetime'
            },
            {
                uniqueName: 'family_status'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: '-j95o3immflm00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-ipu4hx2c3hp00',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-1808peg90b6m0',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-5m3rm8xnuet00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-12tdoxqoapb800',
            decimalPlaces: 1
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Estimated Revenue (Table)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families-all-data?getMapping=1',
        filename: reportsRepository.url + '/reports/families-all-data',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T22:54:59.393Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const estimatedRevenueChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                format: '-j95o3immflm00',
                uniqueName: 'child_yearly_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-12tdoxqoapb800',
                uniqueName: 'child_ftes',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-5m3rm8xnuet00',
                uniqueName: 'child_lifetime_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-ipu4hx2c3hp00',
                uniqueName: 'child_monthly_rev',
                aggregation: 'sum'
            },
            {
                active: false,
                format: '-1808peg90b6m0',
                uniqueName: 'child_weekly_rev',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'opp_added_datetime',
            'child_expected_start_date',
            'center_name',
            'family_status',
            'family_status_date',
            'guardian_name',
            'child_name',
            'child_ftes',
            'child_weekly_rev',
            'child_monthly_rev',
            'child_yearly_rev',
            'child_lifetime_rev'
        ],
        reportFilters: [
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'child_expected_start_date'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'opp_added_datetime'
            },
            {
                uniqueName: 'family_status'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: '-j95o3immflm00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-ipu4hx2c3hp00',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-1808peg90b6m0',
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        },
        {
            name: '-5m3rm8xnuet00',
            decimalPlaces: 2,
            currencySymbol: '$',
            thousandsSeparator: ','
        },
        {
            name: '-12tdoxqoapb800',
            decimalPlaces: 1
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Estimated Revenue (Chart)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'bar_h',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: 'GMT-5:MM/dd/yyyy',
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families-all-data?getMapping=1',
        filename: reportsRepository.url + '/reports/families-all-data',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-26T19:04:39.205Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const lostOpportunityTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                uniqueName: 'child_age'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'child_custom_type_1'
            },
            {
                uniqueName: 'child_custom_type_2'
            },
            {
                uniqueName: 'child_custom_type_3'
            },
            {
                uniqueName: 'child_custom_type_4'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'opp_added_datetime'
            },
            {
                uniqueName: 'child_ftes'
            },
            {
                uniqueName: 'lost_opp_comment'
            },
            {
                uniqueName: 'lost_opp_reason'
            },
            {
                uniqueName: 'prior_status'
            },
            {
                uniqueName: 'status'
            },
            {
                filter: {
                    query: {
                        between: [
                            `${format(new Date(`1/1/${today.getFullYear() - 1}`), 'yyyy-MM-dd')}`,
                            `${format(today, 'yyyy-MM-dd')}`
                        ]
                    }
                },
                uniqueName: 'status_datetime'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        flatOrder: [
            'status',
            'child_custom_type_1',
            'child_custom_type_2',
            'child_custom_type_3',
            'child_custom_type_4',
            'center_name',
            'prior_status',
            'opp_added_datetime',
            'status_datetime',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'lost_opp_reason',
            'lost_opp_comment'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Lost Opportunity (Table)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T20:33:13.190Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};
const lostOpportunitySummaryStatusNumber = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'prior_status'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'status',
            'inquiry_type',
            'family_source',
            'marketing_campaign_names',
            'marketing_campaign_types',
            'guardian_custom_type_1',
            'guardian_custom_type_2',
            'guardian_custom_type_3',
            'guardian_custom_type_4',
            'child_custom_type_1',
            'child_custom_type_2',
            'child_custom_type_3',
            'child_custom_type_4',
            'enrollment_rep',
            'Division',
            'Area',
            'Region',
            'Location Groups',
            'center_name',
            'prior_status',
            'opp_added_datetime',
            'status_datetime',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'lost_opp_reason',
            'lost_opp_comment',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'child_custom_type_4'
            },
            {
                uniqueName: 'child_custom_type_3'
            },
            {
                uniqueName: 'child_custom_type_2'
            },
            {
                uniqueName: 'child_custom_type_1'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'opp_added_datetime'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Lost Opportunity (Summ. Prior Status #)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'rows',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T20:46:18.606Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};
const lostOpportunitySummaryStatusPercent = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'prior_status'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'percentofrow'
            }
        ],
        flatOrder: [
            'status',
            'inquiry_type',
            'family_source',
            'marketing_campaign_names',
            'marketing_campaign_types',
            'guardian_custom_type_1',
            'guardian_custom_type_2',
            'guardian_custom_type_3',
            'guardian_custom_type_4',
            'child_custom_type_1',
            'child_custom_type_2',
            'child_custom_type_3',
            'child_custom_type_4',
            'enrollment_rep',
            'Division',
            'Area',
            'Region',
            'Location Groups',
            'center_name',
            'prior_status',
            'opp_added_datetime',
            'status_datetime',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'lost_opp_reason',
            'lost_opp_comment',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'child_custom_type_4'
            },
            {
                uniqueName: 'child_custom_type_3'
            },
            {
                uniqueName: 'child_custom_type_2'
            },
            {
                uniqueName: 'child_custom_type_1'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'opp_added_datetime'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Lost Opportunity (Summ. Prior Status %)',
            showFilter: false,
            showTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T20:35:04.158Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};
const lostOpportunitySummaryReasonNumber = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'lost_opp_reason'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'status',
            'inquiry_type',
            'family_source',
            'marketing_campaign_names',
            'marketing_campaign_types',
            'guardian_custom_type_1',
            'guardian_custom_type_2',
            'guardian_custom_type_3',
            'guardian_custom_type_4',
            'child_custom_type_1',
            'child_custom_type_2',
            'child_custom_type_3',
            'child_custom_type_4',
            'enrollment_rep',
            'Division',
            'Area',
            'Region',
            'Location Groups',
            'center_name',
            'prior_status',
            'opp_added_datetime',
            'status_datetime',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'lost_opp_reason',
            'lost_opp_comment',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'child_custom_type_4'
            },
            {
                uniqueName: 'child_custom_type_3'
            },
            {
                uniqueName: 'child_custom_type_2'
            },
            {
                uniqueName: 'child_custom_type_1'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'opp_added_datetime'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Lost Opportunity (Summ. Reason #)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'rows',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T20:44:30.785Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};
const lostOpportunitySummaryReasonPercent = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'lost_opp_reason'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'percentofrow'
            }
        ],
        flatOrder: [
            'status',
            'inquiry_type',
            'family_source',
            'marketing_campaign_names',
            'marketing_campaign_types',
            'guardian_custom_type_1',
            'guardian_custom_type_2',
            'guardian_custom_type_3',
            'guardian_custom_type_4',
            'child_custom_type_1',
            'child_custom_type_2',
            'child_custom_type_3',
            'child_custom_type_4',
            'enrollment_rep',
            'Division',
            'Area',
            'Region',
            'Location Groups',
            'center_name',
            'prior_status',
            'opp_added_datetime',
            'status_datetime',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'lost_opp_reason',
            'lost_opp_comment',
            'child_monday_am',
            'child_monday_pm',
            'child_tuesday_am',
            'child_tuesday_pm',
            'child_wednesday_am',
            'child_wednesday_pm',
            'child_thursday_am',
            'child_thursday_pm',
            'child_friday_am',
            'child_friday_pm',
            'child_saturday_am',
            'child_saturday_pm',
            'child_sunday_am',
            'child_sunday_pm'
        ],
        reportFilters: [
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'child_custom_type_4'
            },
            {
                uniqueName: 'child_custom_type_3'
            },
            {
                uniqueName: 'child_custom_type_2'
            },
            {
                uniqueName: 'child_custom_type_1'
            },
            {
                uniqueName: 'status_datetime'
            },
            {
                uniqueName: 'opp_added_datetime'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Lost Opportunity (Summ. Reason %)',
            showFilter: false,
            showTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families?getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-22T20:42:40.740Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const stalledFamiliesTable = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            },
            {
                uniqueName: 'guardian_name'
            },
            {
                filter: {
                    members: [
                        'is_from_api.[no]'
                    ]
                },
                uniqueName: 'is_from_api'
            },
            {
                uniqueName: 'child_age'
            },
            {
                uniqueName: 'child_expected_start_date'
            },
            {
                uniqueName: 'child_name'
            },
            {
                uniqueName: 'child_ftes'
            },
            {
                uniqueName: 'family_added_datetime'
            },
            {
                filter: {
                    query: {
                        before_equal: `${format(subWeeks(today, 2), 'yyyy-MM-dd')}`
                    }
                },
                uniqueName: 'last_contacted_datetime'
            },
            {
                filter: {
                    members: [
                        'next_future_or_past_task_due_date.[]'
                    ]
                },
                uniqueName: 'next_future_or_past_task_due_date'
            },
            {
                uniqueName: 'status'
            },
            {
                uniqueName: 'status_datetime'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        flatOrder: [
            'is_from_api',
            'center_name',
            'status',
            'status_datetime',
            'last_contacted_datetime',
            'next_future_or_past_task_due_date',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'family_added_datetime',
            'child_expected_start_date'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Stalled Families (Table2)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families&getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-26T18:06:35.296Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const stalledFamiliesSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            },
            {
                uniqueName: 'status'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'is_from_api',
            'center_name',
            'status',
            'status_datetime',
            'last_contacted_datetime',
            'next_future_or_past_task_due_date',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'family_added_datetime',
            'child_expected_start_date'
        ],
        reportFilters: [
            {
                filter: {
                    members: [
                        'is_from_api.[no]'
                    ]
                },
                uniqueName: 'is_from_api'
            },
            {
                filter: {
                    query: {
                        before_equal: `${format(subWeeks(today, 2), 'yyyy-MM-dd')}`
                    }
                },
                uniqueName: 'last_contacted_datetime'
            },
            {
                filter: {
                    members: [
                        'next_future_or_past_task_due_date.[]'
                    ]
                },
                uniqueName: 'next_future_or_past_task_due_date'
            },
            {
                uniqueName: 'status'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Stalled Families (Table3)',
            showFilter: false,
            showTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families&getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-26T18:24:08.118Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const stalledFamiliesChart = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                uniqueName: 'guardian_name',
                aggregation: 'count'
            }
        ],
        flatOrder: [
            'is_from_api',
            'center_name',
            'status',
            'status_datetime',
            'last_contacted_datetime',
            'next_future_or_past_task_due_date',
            'guardian_name',
            'child_name',
            'child_age',
            'child_ftes',
            'family_added_datetime',
            'child_expected_start_date'
        ],
        reportFilters: [
            {
                filter: {
                    members: [
                        'is_from_api.[no]'
                    ]
                },
                uniqueName: 'is_from_api'
            },
            {
                filter: {
                    query: {
                        before_equal: `${format(subWeeks(today, 2), 'yyyy-MM-dd')}`
                    }
                },
                uniqueName: 'last_contacted_datetime'
            },
            {
                filter: {
                    members: [
                        'next_future_or_past_task_due_date.[]'
                    ]
                },
                uniqueName: 'next_future_or_past_task_due_date'
            },
            {
                uniqueName: 'status'
            }
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Stalled Families (Summary)',
            showFilter: false,
            showTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'bar_h',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        viewType: 'charts',
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    version: '2.8.30',
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/families&getMapping=1',
        filename: reportsRepository.url + '/reports/families',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    creationDate: '2022-07-26T18:27:21.729Z',
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const marketingCampaignsEnrolled = {
    slice: {
        rows: [
            {
                uniqueName: 'name'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'cost'
            },
            {
                uniqueName: 'enrolled_count'
            },
            {
                uniqueName: 'enrolled_cost'
            },
            {
                uniqueName: 'annual_enrolled_revenue'
            },
            {
                uniqueName: 'lifetime_enrolled_revenue'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_0',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_1',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_2',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_3',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_4',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_5',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_6',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_7',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_8',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'enrolled_cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lost_opp_cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'weekly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'monthly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'annual_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lifetime_enrolled_revenue',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'name',
            'type',
            'cost',
            'enrolled_count',
            'enrolled_cost',
            'annual_enrolled_revenue',
            'lifetime_enrolled_revenue'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: ReportFormatNames.CURRENCY,
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Marketing Campaigns',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/marketing-campaigns?getMapping=1',
        filename: reportsRepository.url + '/reports/marketing-campaigns',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const marketingCampaignsLocationSum = {
    slice: {
        rows: [
            {
                uniqueName: 'organization_name'
            },
            {
                uniqueName: 'name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'cost',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_0',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_1',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_2',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_3',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_4',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_5',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_6',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_7',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_8',
                aggregation: 'sum'
            },
            {
                uniqueName: 'enrolled_count',
                aggregation: 'sum'
            },
            {
                uniqueName: 'lost_opp_count',
                aggregation: 'sum'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_0',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_1',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_2',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_3',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_4',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_5',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_6',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_7',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_8',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'enrolled_cost',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lost_opp_cost',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'annual_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lifetime_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'weekly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'monthly_enrolled_revenue',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'name',
            'type',
            'cost',
            'active_status_count_0',
            'active_status_count_1',
            'active_status_count_2',
            'active_status_count_3',
            'active_status_count_4',
            'active_status_count_5',
            'active_status_count_6',
            'active_status_count_7',
            'active_status_count_8',
            'enrolled_count',
            'lost_opp_count',
            'active_status_cost_0',
            'active_status_cost_1',
            'active_status_cost_2',
            'active_status_cost_3',
            'active_status_cost_4',
            'active_status_cost_5',
            'active_status_cost_6',
            'active_status_cost_7',
            'active_status_cost_8',
            'enrolled_cost',
            'lost_opp_cost',
            'annual_enrolled_revenue',
            'lifetime_enrolled_revenue'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: ReportFormatNames.CURRENCY,
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Marketing Campaign RoI (Sum by Location)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'rows',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/marketing-campaigns?getMapping=1',
        filename: reportsRepository.url + '/reports/marketing-campaigns',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const marketingCampaignsSummary = {
    slice: {
        rows: [
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'name'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'cost',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_0',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_1',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_2',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_3',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_4',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_5',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_6',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_7',
                aggregation: 'sum'
            },
            {
                uniqueName: 'active_status_count_8',
                aggregation: 'sum'
            },
            {
                uniqueName: 'enrolled_count',
                aggregation: 'sum'
            },
            {
                uniqueName: 'lost_opp_count',
                aggregation: 'sum'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_0',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_1',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_2',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_3',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_4',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_5',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_6',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_7',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_8',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'enrolled_cost',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lost_opp_cost',
                aggregation: 'average'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'annual_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lifetime_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'weekly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'monthly_enrolled_revenue',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'name',
            'type',
            'cost',
            'active_status_count_0',
            'active_status_count_1',
            'active_status_count_2',
            'active_status_count_3',
            'active_status_count_4',
            'active_status_count_5',
            'active_status_count_6',
            'active_status_count_7',
            'active_status_count_8',
            'enrolled_count',
            'lost_opp_count',
            'active_status_cost_0',
            'active_status_cost_1',
            'active_status_cost_2',
            'active_status_cost_3',
            'active_status_cost_4',
            'active_status_cost_5',
            'active_status_cost_6',
            'active_status_cost_7',
            'active_status_cost_8',
            'enrolled_cost',
            'lost_opp_cost',
            'annual_enrolled_revenue',
            'lifetime_enrolled_revenue'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: ReportFormatNames.CURRENCY,
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        }
    ],
    options: {
        grid: {
            type: 'classic',
            title: 'Marketing Campaign RoI (Summary by Type)',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'rows',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/marketing-campaigns?getMapping=1',
        filename: reportsRepository.url + '/reports/marketing-campaigns',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

const marketingCampaignsTable = {
    slice: {
        rows: [
            {
                uniqueName: 'name'
            },
            {
                uniqueName: 'type'
            },
            {
                uniqueName: 'cost'
            },
            {
                uniqueName: 'begin_date'
            },
            {
                uniqueName: 'end_date'
            },
            {
                uniqueName: 'results_end_date'
            },
            {
                uniqueName: 'utm_campaign'
            },
            {
                uniqueName: 'organization_name'
            },
            {
                uniqueName: 'active_status_count_0'
            },
            {
                uniqueName: 'active_status_count_1'
            },
            {
                uniqueName: 'active_status_count_2'
            },
            {
                uniqueName: 'active_status_count_3'
            },
            {
                uniqueName: 'active_status_count_4'
            },
            {
                uniqueName: 'active_status_count_5'
            },
            {
                uniqueName: 'active_status_count_6'
            },
            {
                uniqueName: 'active_status_count_7'
            },
            {
                uniqueName: 'active_status_count_8'
            },
            {
                uniqueName: 'enrolled_count'
            },
            {
                uniqueName: 'lost_opp_count'
            },
            {
                uniqueName: 'active_status_cost_0'
            },
            {
                uniqueName: 'active_status_cost_1'
            },
            {
                uniqueName: 'active_status_cost_2'
            },
            {
                uniqueName: 'active_status_cost_3'
            },
            {
                uniqueName: 'active_status_cost_4'
            },
            {
                uniqueName: 'active_status_cost_5'
            },
            {
                uniqueName: 'active_status_cost_6'
            },
            {
                uniqueName: 'active_status_cost_7'
            },
            {
                uniqueName: 'active_status_cost_8'
            },
            {
                uniqueName: 'enrolled_cost'
            },
            {
                uniqueName: 'lost_opp_cost'
            },
            {
                uniqueName: 'annual_enrolled_revenue'
            },
            {
                uniqueName: 'lifetime_enrolled_revenue'
            }
        ],
        columns: [
            {
                uniqueName: '[Measures]'
            }
        ],
        measures: [
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_0',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_1',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_2',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_3',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_4',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_5',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_6',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_7',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'active_status_cost_8',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'enrolled_cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lost_opp_cost',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'weekly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'monthly_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'annual_enrolled_revenue',
                aggregation: 'sum'
            },
            {
                active: false,
                format: ReportFormatNames.CURRENCY,
                uniqueName: 'lifetime_enrolled_revenue',
                aggregation: 'sum'
            }
        ],
        flatOrder: [
            'name',
            'type',
            'cost',
            'begin_date',
            'end_date',
            'results_end_date',
            'utm_campaign',
            'organization_name',
            'active_status_count_0',
            'active_status_count_1',
            'active_status_count_2',
            'active_status_count_3',
            'active_status_count_4',
            'active_status_count_5',
            'active_status_count_6',
            'active_status_count_7',
            'active_status_count_8',
            'enrolled_count',
            'lost_opp_count',
            'active_status_cost_0',
            'active_status_cost_1',
            'active_status_cost_2',
            'active_status_cost_3',
            'active_status_cost_4',
            'active_status_cost_5',
            'active_status_cost_6',
            'active_status_cost_7',
            'active_status_cost_8',
            'enrolled_cost',
            'lost_opp_cost',
            'annual_enrolled_revenue',
            'lifetime_enrolled_revenue'
        ]
    },
    formats: [
        {
            name: '',
            nullValue: ' ',
            maxSymbols: 20,
            infinityValue: 'Infinity',
            currencySymbol: '',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            divideByZeroValue: 'Infinity',
            thousandsSeparator: '',
            positiveCurrencyFormat: '$1'
        },
        {
            name: ReportFormatNames.CURRENCY,
            decimalPlaces: 2,
            currencySymbol: '$',
            decimalSeparator: '.',
            thousandsSeparator: ','
        }
    ],
    options: {
        grid: {
            type: 'flat',
            title: 'Marketing Campaigns',
            showFilter: false,
            showTotals: 'off',
            showGrandTotals: 'off',
            showHierarchies: false
        },
        chart: {
            type: 'line',
            showFilter: false
        },
        filter: {
            dateFormat: 'MM/dd/yyyy'
        },
        datePattern: 'MM/dd/yyyy',
        dateTimePattern: dateTimePattern,
        showDefaultSlice: false
    },
    dataSource: {
        type: 'json',
        mapping: reportsRepository.url + '/reports/marketing-campaigns?getMapping=1',
        filename: reportsRepository.url + '/reports/marketing-campaigns',
        useStreamLoader: true
        // useStreamLoader: false // Work around for BUGS-1678 till FM fixes it.
    },
    localization: {
        grid: {
            dateInvalidCaption: ''
        }
    }
};

/**
 * Build the list of marketing standard reports.
 */
async function buildMarketingStandardReports(): Promise<Array<StandardReportType>> {
    const enrolledStatus = await statusesStore.getById(BaseStatuses.ENROLLED);
    let enrolledCode = 'Enrolled';
    if (enrolledStatus) {
        enrolledCode = enrolledStatus.code ?? 'Enrolled';
    }
    // Set the marketing reports
    const marketingReports = [{
        name: 'Marketing Source',
        identifier: ReportIdentifier.MARKETING_SOURCE,
        reports: [
            {
                ...baseStandardReport,
                id: -9,
                name: 'Marketing Source (Table)',
                report_data: JSON.stringify(sourceTable),
                standard_report_identifier: StandardReportIdentifier.SOURCE_TABLE,
                radioLabel: 'View results as a table'
            },
            {
                ...baseStandardReport,
                id: -8,
                name: 'Marketing Source (Chart)',
                report_data: JSON.stringify(sourceChart),
                standard_report_identifier: StandardReportIdentifier.SOURCE_CHART,
                radioLabel: 'View results as a chart'
            }
        ]
    }];
    if (featuresStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS)) {
        marketingReports.push({
            name: 'Marketing Campaign ROI',
            identifier: ReportIdentifier.MARKETING_CAMPAIGN_ANALYSIS,
            reports: [
                {
                    ...baseStandardReport,
                    id: -30,
                    name: 'Marketing Campaign ROI (Table)',
                    report_data: JSON.stringify(marketingCampaignsTable),
                    standard_report_identifier: StandardReportIdentifier.MARKETING_CAMPAIGNS_TABLE,
                    radioLabel: 'View results as a table'
                },
                {
                    ...baseStandardReport,
                    id: -31,
                    name: `Marketing Campaign ROI (${enrolledCode} Only)`,
                    report_data: JSON.stringify(marketingCampaignsEnrolled),
                    standard_report_identifier: StandardReportIdentifier.MARKETING_CAMPAIGNS_ENROLLED,
                    radioLabel: `View results as a table, ${enrolledCode} only`
                },
                {
                    ...baseStandardReport,
                    id: -32,
                    name: 'Marketing Campaign ROI (Summary by Type)',
                    report_data: JSON.stringify(marketingCampaignsSummary),
                    standard_report_identifier: StandardReportIdentifier.MARKETING_CAMPAIGNS_SUMMARY,
                    radioLabel: 'View results by Campaign Type'
                },
                {
                    ...baseStandardReport,
                    id: -33,
                    name: 'Marketing Campaign ROI (Summary by Location)',
                    report_data: JSON.stringify(marketingCampaignsLocationSum),
                    standard_report_identifier: StandardReportIdentifier.MARKETING_CAMPAIGNS_LOCATION_SUM,
                    radioLabel: 'View results by Location'
                }
            ]
        });
    }
    marketingReports.push({
        name: 'Estimated Revenue',
        identifier: ReportIdentifier.ESTIMATED_REVENUE,
        reports: [
            {
                ...baseStandardReport,
                id: -21,
                name: 'Estimated Revenue (Table)',
                report_data: JSON.stringify(estimatedRevenueTable),
                standard_report_identifier: StandardReportIdentifier.ESTIMATED_REVENUE_TABLE,
                radioLabel: 'View results as a table'
            },
            {
                ...baseStandardReport,
                id: -22,
                name: 'Estimated Revenue (Summary)',
                report_data: JSON.stringify(estimatedRevenueSummary),
                standard_report_identifier: StandardReportIdentifier.ESTIMATED_REVENUE_SUMMARY,
                radioLabel: 'View results as a summary'
            },
            {
                ...baseStandardReport,
                id: -23,
                name: 'Estimated Revenue (Chart)',
                report_data: JSON.stringify(estimatedRevenueChart),
                standard_report_identifier: StandardReportIdentifier.ESTIMATED_REVENUE_CHART,
                radioLabel: 'View results as a chart'
            }
        ]
    });

    return marketingReports;
}

export const trendTrackingBase = {
    slice: {
        rows: [
            {
                uniqueName: 'center_name'
            }
        ],
        flatOrder: [
            'center_name'
        ]
    },
    dataSource: {
        type: 'json',
        filename: reportsRepository.url + '/reports/trend-tracking'
    }
};

/**
 * Return a dynamically built filtered object for standard reports.
 */
export async function getStandardReports(): Promise<Array<StandardReportGroup>> {
    // Make sure statuses and features are loaded up.
    await Promise.all([
        statusesStore.init(),
        featuresStore.init(),
        taskGroupsStore.init()
    ]);

    // Add in the filters.
    // casting this, since it looks like flexmonster.d.ts (which, is from them) isn't as up to date?
    addRejectedStatusAndBlankFilter(
        statusesStore.storedStatuses,
        (currentOppsChart.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addRejectedStatusAndBlankFilter(
        statusesStore.storedStatuses,
        (currentOppsSummary.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addRejectedStatusAndBlankFilter(
        statusesStore.storedStatuses,
        (currentOppsTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addRejectedStatusAndBlankFilter(
        statusesStore.storedStatuses,
        (sourceChart.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addRejectedStatusAndBlankFilter(
        statusesStore.storedStatuses,
        (sourceTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addDynamicStatusQuery(
        statusesStore.storedStatuses,
        (waitListTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
        BaseStatuses.WAIT_LIST
    );
    addDynamicStatusQuery(
        statusesStore.storedStatuses,
        (waitListSummary.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
        BaseStatuses.WAIT_LIST
    );
    addDynamicStatusQuery(
        statusesStore.storedStatuses,
        (waitListChart.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
        BaseStatuses.WAIT_LIST
    );
    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (estimatedRevenueTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'family_status')
    );
    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (estimatedRevenueSummary.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'family_status')
    );
    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (estimatedRevenueChart.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'family_status')
    );
    if (statusesStore.hasLostOpp) {
        addDynamicStatusQuery(
            statusesStore.storedStatuses,
            (lostOpportunityTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
            BaseStatuses.LOST_OPP
        );
        addDynamicStatusQuery(
            statusesStore.storedStatuses,
            (lostOpportunitySummaryReasonPercent.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
            BaseStatuses.LOST_OPP
        );
        addDynamicStatusQuery(
            statusesStore.storedStatuses,
            (lostOpportunitySummaryReasonNumber.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
            BaseStatuses.LOST_OPP
        );
        addDynamicStatusQuery(
            statusesStore.storedStatuses,
            (lostOpportunitySummaryStatusPercent.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
            BaseStatuses.LOST_OPP
        );
        addDynamicStatusQuery(
            statusesStore.storedStatuses,
            (lostOpportunitySummaryStatusNumber.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status'),
            BaseStatuses.LOST_OPP
        );
    }

    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (stalledFamiliesTable.slice.rows as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (stalledFamiliesSummary.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );
    addOnlyActiveStatusFilters(
        statusesStore.storedStatuses,
        (stalledFamiliesChart.slice.reportFilters as Array<SliceHierarchy>).find(row => row.uniqueName === 'status')
    );

    const tourMeetingNames = TaskUtilities.getTaskNames(taskGroupsStore.stored, [TaskGroups.TOURS, TaskGroups.MEETINGS]);
    addTourMeetingTypesToReport(toursMeetingsSummary, tourMeetingNames);
    addTourMeetingTypesToReport(toursMeetingsTable, tourMeetingNames);

    // Set the marketing reports
    const marketingReports = await buildMarketingStandardReports();

    // Build out our object and return it.
    const reports = [
        {
            name: 'Family Reports',
            reports: [{
                name: 'Current Families',
                identifier: ReportIdentifier.CURRENT_OPPS,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -5,
                        name: 'Current Families (Table)',
                        report_data: JSON.stringify(currentOppsTable),
                        standard_report_identifier: StandardReportIdentifier.CURRENT_FAMILIES_TABLE,
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -4,
                        name: 'Current Families (Summary)',
                        report_data: JSON.stringify(currentOppsSummary),
                        standard_report_identifier: StandardReportIdentifier.CURRENT_FAMILIES_SUMMARY,
                        radioLabel: 'View results as a summary'
                    },
                    {
                        ...baseStandardReport,
                        id: -3,
                        name: 'Current Families (Chart)',
                        report_data: JSON.stringify(currentOppsChart),
                        standard_report_identifier: StandardReportIdentifier.CURRENT_FAMILIES_CHART,
                        expand_centers: true,
                        radioLabel: 'View results as a chart'
                    }
                ]
            }, {
                name: 'Guardian List',
                identifier: ReportIdentifier.GUARDIAN_LIST,
                reports: [

                    {
                        ...baseStandardReport,
                        id: -10,
                        name: 'Guardian List (Table)',
                        report_data: JSON.stringify(leadListTable),
                        standard_report_identifier: StandardReportIdentifier.LEAD_LIST_TABLE,
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -11,
                        name: 'Guardian List (Summary)',
                        report_data: JSON.stringify(leadListSummary),
                        standard_report_identifier: StandardReportIdentifier.LEAD_LIST_SUMMARY,
                        radioLabel: 'View results as a summary'
                    },
                    {
                        ...baseStandardReport,
                        id: -12,
                        name: 'Guardian List (Graph)',
                        report_data: JSON.stringify(leadListGraph),
                        standard_report_identifier: StandardReportIdentifier.LEAD_LIST_GRAPH,
                        radioLabel: 'View results as a graph'
                    }
                ]
            }, {
                name: 'Wait List',
                identifier: ReportIdentifier.WAIT_LIST,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -18,
                        name: 'Wait List (Table)',
                        report_data: JSON.stringify(waitListTable),
                        standard_report_identifier: 'waitListTable',
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -19,
                        name: 'Wait List (Summary)',
                        report_data: JSON.stringify(waitListSummary),
                        standard_report_identifier: 'waitListSummary',
                        radioLabel: 'View results as a summary'
                    },
                    {
                        ...baseStandardReport,
                        id: -20,
                        name: 'Wait List (Chart)',
                        report_data: JSON.stringify(waitListChart),
                        standard_report_identifier: 'waitListChart',
                        radioLabel: 'View results as a chart'
                    }
                ]
            }, {
                name: 'Stalled Families',
                identifier: ReportIdentifier.STALLED_FAMILIES,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -27,
                        name: 'Stalled Families (Table)',
                        report_data: JSON.stringify(stalledFamiliesTable),
                        standard_report_identifier: 'stalledFamiliesTable',
                        radioLabel: 'View results as a table '
                    },
                    {
                        ...baseStandardReport,
                        id: -28,
                        name: 'Stalled Families (Summary)',
                        report_data: JSON.stringify(stalledFamiliesSummary),
                        standard_report_identifier: 'stalledFamiliesSummary',
                        radioLabel: 'View results as a summary '
                    },
                    {
                        ...baseStandardReport,
                        id: -29,
                        name: 'Stalled Families (Chart)',
                        report_data: JSON.stringify(stalledFamiliesChart),
                        standard_report_identifier: 'stalledFamiliesChart',
                        radioLabel: 'View results as a chart '
                    }
                ]
            }]
        },
        {
            name: 'Task Reports',
            reports: [{
                name: 'Scheduled Tasks',
                identifier: ReportIdentifier.SCHEDULED_TASKS,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -2,
                        name: 'Scheduled Tasks (Table)',
                        report_data: JSON.stringify(scheduledTasksTable),
                        standard_report_identifier: StandardReportIdentifier.SCHEDULED_TASK_TABLE,
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -1,
                        name: 'Scheduled Tasks (Chart)',
                        report_data: JSON.stringify(scheduledTasksChart),
                        standard_report_identifier: StandardReportIdentifier.SCHEDULED_TASK_CHART,
                        expand_centers: true,
                        radioLabel: 'View results as a chart'
                    }
                ]
            }, {
                name: 'Completed Tasks',
                identifier: ReportIdentifier.COMPLETED_TASKS,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -7,
                        name: 'Completed Tasks By Staff (Table)',
                        report_data: JSON.stringify(tasksTable),
                        standard_report_identifier: StandardReportIdentifier.TASKS_TABLE,
                        expand_centers: true,
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -6,
                        name: 'Completed Tasks (Chart)',
                        report_data: JSON.stringify(tasksChart),
                        standard_report_identifier: StandardReportIdentifier.TASKS_CHART,
                        expand_centers: true,
                        radioLabel: 'View results as a chart'
                    }
                ]
            }, {
                name: 'Tours & Meetings',
                identifier: ReportIdentifier.TOURS_MEETINGS,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -13,
                        name: 'Tours & Meetings (Table)',
                        report_data: JSON.stringify(toursMeetingsTable),
                        standard_report_identifier: StandardReportIdentifier.TOURS_MEETINGS_TABLE,
                        radioLabel: 'View results as a table'
                    },
                    {
                        ...baseStandardReport,
                        id: -14,
                        name: 'Tours & Meetings (Summary)',
                        report_data: JSON.stringify(toursMeetingsSummary),
                        standard_report_identifier: StandardReportIdentifier.TOURS_MEETINGS_SUMMARY,
                        radioLabel: 'View results as a summary'
                    }
                ]
            }]
        },
        {
            name: 'Conversion Reports',
            reports: [{
                name: 'Conversion Success',
                identifier: ReportIdentifier.CONVERSION_SUCCESS,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -15,
                        name: 'Conversion Success (Numbers)',
                        report_data: JSON.stringify(conversionSuccessNumbers),
                        standard_report_identifier: StandardReportIdentifier.CONVERSION_SUCCESS_NUMBERS,
                        radioLabel: 'View results as a numbers'
                    },
                    {
                        ...baseStandardReport,
                        id: -16,
                        name: 'Conversion Success (Percentages)',
                        report_data: JSON.stringify(conversionSuccessPercentages),
                        standard_report_identifier: StandardReportIdentifier.CONVERSION_SUCCESS_PERCENTAGES,
                        radioLabel: 'View results as a percentages'
                    },
                    {
                        ...baseStandardReport,
                        id: -17,
                        name: 'Conversion Success (Numbers & Percentages)',
                        report_data: JSON.stringify(conversionSuccessNumbersAndPercentages),
                        standard_report_identifier: StandardReportIdentifier.CONVERSION_SUCCESS_BOTH,
                        radioLabel: 'View results as both'
                    }
                ]
            }, {
                name: 'Conversion Timing',
                identifier: conversionTimingIdentifier,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -99,
                        name: 'Conversion Timing',
                        report_data: '{}',
                        standard_report_identifier: conversionTimingIdentifier,
                        radioLabel: ''
                    }
                ]
            }, {
                name: 'Trend Tracking',
                identifier: trendTrackingIdentifier,
                reports: [
                    {
                        ...baseStandardReport,
                        id: -100,
                        name: 'Trend Tracking',
                        report_data: JSON.stringify(trendTrackingBase),
                        standard_report_identifier: trendTrackingIdentifier,
                        radioLabel: ''
                    }
                ]
            }]
        },
        {
            name: 'Marketing Reports',
            reports: marketingReports
        }
    ];

    if (statusesStore.hasLostOpp) {
        reports[0].reports.push({
            name: 'Lost Opportunity',
            identifier: ReportIdentifier.LOST_OPP,
            reports: [
                {
                    ...baseStandardReport,
                    id: -24,
                    name: 'Lost Opportunity (Table)',
                    report_data: JSON.stringify(lostOpportunityTable),
                    standard_report_identifier: 'lostOpportunityTable',
                    radioLabel: 'View results as a table '
                },
                {
                    ...baseStandardReport,
                    id: -25,
                    name: 'Lost Opportunity (Summ. Prior Status #)',
                    report_data: JSON.stringify(lostOpportunitySummaryStatusNumber),
                    standard_report_identifier: 'lostOpportunitySummaryStatusNumber',
                    radioLabel: 'View results as a Summary by Prior Status (#)'
                },
                {
                    ...baseStandardReport,
                    id: -26,
                    name: 'Lost Opportunity (Summ. Prior Status %)',
                    report_data: JSON.stringify(lostOpportunitySummaryStatusPercent),
                    standard_report_identifier: 'lostOpportunitySummaryStatusPercent',
                    radioLabel: 'View results as a Summary by Prior Status (%)'
                },
                {
                    ...baseStandardReport,
                    id: -27,
                    name: 'Lost Opportunity (Summ. Reason #)',
                    report_data: JSON.stringify(lostOpportunitySummaryReasonNumber),
                    standard_report_identifier: 'lostOpportunitySummaryReasonNumber',
                    radioLabel: 'View results as a Summary by Reason (#)'
                },
                {
                    ...baseStandardReport,
                    id: -28,
                    name: 'Lost Opportunity (Summ. Reason %)',
                    report_data: JSON.stringify(lostOpportunitySummaryReasonPercent),
                    standard_report_identifier: 'lostOpportunitySummaryReasonPercent',
                    radioLabel: 'View results as a Summary by Reason (%)'
                }
            ]
        });
    }
    return reports;
}
