








































import { cloneDeep } from 'lodash';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
    InterfaceSetting,
    SettingNames
} from '../models/interface-settings-models';
import { getModule } from 'vuex-module-decorators';
import { InterfaceSettingsChangesStore } from '@/dashboards/store/interface-settings-changes-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';

const settingsChangesStore = getModule(InterfaceSettingsChangesStore);
const settingsStore = getModule(InterfaceSettingsStore);

@Component
export default class CorporateDashboardSettings extends Mixins(LocaleMixin) {
    private childStatusGraphEnrolledOriginalToggle = true;
    private childStatusGraphPendingOriginalToggle = false;
    private childStatusGraphEnrolledToggle: InterfaceSetting | null = null;
    private childStatusGraphPendingToggle: InterfaceSetting | null = null;
    private pipelineValueSettingsOriginalToggle = true;
    private pipelineValueSettingsToggle: InterfaceSetting | null = null;

    async created() {
        this.childStatusGraphEnrolledToggle = cloneDeep(settingsStore.stored.get(SettingNames.CHILD_STATUS_GRAPH_ENROLLED)!);
        this.childStatusGraphPendingToggle = cloneDeep(settingsStore.stored.get(SettingNames.CHILD_STATUS_GRAPH_PENDING_OPPORTUNITIES)!);
        this.childStatusGraphEnrolledOriginalToggle = this.childStatusGraphEnrolledToggle.value as boolean;
        this.childStatusGraphPendingOriginalToggle = this.childStatusGraphPendingToggle.value as boolean;
        this.pipelineValueSettingsToggle = cloneDeep(settingsStore.stored.get(SettingNames.PIPELINE_VALUE)!);
        this.pipelineValueSettingsOriginalToggle = this.pipelineValueSettingsToggle.value as boolean;
    }

    @Watch('childStatusGraphEnrolledToggle', { deep: true })
    updateEnrolledToggle() {
        settingsChangesStore.setSaveButtonForChildStatusGraphEnrolledToggle(this.childStatusGraphEnrolledToggle!.value !== this.childStatusGraphEnrolledOriginalToggle);
        if (this.childStatusGraphEnrolledToggle!.value !== this.childStatusGraphEnrolledOriginalToggle) {
            this.$emit('statusChange', this.childStatusGraphEnrolledToggle);
        }
    }

    @Watch('childStatusGraphPendingToggle', { deep: true })
    updatePendingToggle() {
        settingsChangesStore.setSaveButtonForChildStatusGraphPendingOpportunitiesToggle(this.childStatusGraphPendingToggle!.value !== this.childStatusGraphPendingOriginalToggle);
        if (this.childStatusGraphPendingToggle!.value !== this.childStatusGraphPendingOriginalToggle) {
            this.$emit('statusChange', this.childStatusGraphPendingToggle);
        }
    }

    @Watch('pipelineValueSettingsToggle', { deep: true })
    updatePipelineToggle() {
        settingsChangesStore.setSaveButtonForPipelineValueToggle(this.pipelineValueSettingsToggle!.value !== this.pipelineValueSettingsOriginalToggle);
        if (this.pipelineValueSettingsToggle!.value !== this.pipelineValueSettingsOriginalToggle) {
            this.$emit('statusChange', this.pipelineValueSettingsToggle);
        }
    }
}
