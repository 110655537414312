













































import { Logo, LogoDto } from '@/logo/models/logo';
import type { LandingPage } from '@/automation/landing-pages/models/landing-pages-models';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { encodeFile } from '@/core/file-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const formsRepository = new LandingPagesRepository();
const loadingState = getModule(LoadingStore);

@Component({
    components: { BaseClose }
})
export default class UploadLogo extends Mixins(LocaleMixin) {
    // Whether or not the dialog should be visible
    @Prop({ type: Boolean, default: false }) readonly value!: boolean;
    // The form to add the logo to
    @Prop() readonly form!: LandingPage;
    @Ref('form') readonly uploadForm!: VForm;

    // The logo file
    private file: File | null | undefined = null;
    private loadingKey = 'logoUpload';
    private maxSize = 2 * 1024 * 1024;
    private validForm = false;

    // The rules for what can be uploaded as a logo
    get logoRules() {
        return [
            (file: File | null | undefined) => {
                if (!file) {
                    return true;
                }
                return file.type.match('image.*') !== null || 'Please choose an image';
            },
            (file: File | null | undefined) => {
                if (!file) {
                    return true;
                }
                return file.size < this.maxSize || 'Image is too large, an image size should be less than 2MB';
            }
        ];
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    private close() {
        this.modelValue = false;
    }

    // Handle uploading the logo
    private async upload() {
        loadingState.loadingIncrement(this.loadingKey);
        const fileContents = await encodeFile(this.file as File).catch(e => Error(e));
        if (this.file?.type.match('image.*') === null) {
            await this.$swal({
                text: 'Please upload an image file',
                icon: 'error'
            });
        } else if (fileContents instanceof Error) {
            this.$swal({ text: 'Something went wrong, and the logo could not be uploaded. Please try again later.', icon: 'error' });
        } else if (this.form) {
                const logo = await formsRepository.uploadLogo(
                    this.form.id, { file: fileContents, filename: this.file?.name } as LogoDto
                );
                this.file = null;
                this.$emit('logoUploaded', logo as Logo);
        } else {
            this.$emit('logoUploaded', { file: fileContents, filename: this.file?.name } as Logo);
            this.file = null;
            this.close();
        }
        loadingState.loadingDecrement(this.loadingKey);
    }
}
