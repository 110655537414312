import { FlexmonsterReportColumnTypes, ReportIdentifier } from '@/reports/report-constants';
import { ReportMappingObject } from '@/reports/models/reports';
import { SelectListOption } from '@/core/models/select-list';

export class ReportDateFiltersService {

    getDateFiltersAsListItems(mapping: ReportMappingObject): Array<SelectListOption> {
        const options: Array<SelectListOption> = [];
        for (const [field, value] of Object.entries(mapping)) {
            if (value.type === FlexmonsterReportColumnTypes.DATE ||
                value.type === FlexmonsterReportColumnTypes.DATETIME ||
                value.type === FlexmonsterReportColumnTypes.DATE_STRING) {
                options.push({ value: field, text: value.caption });
            }
        }
        return options.sort((a, b) => (a.text < b.text) ? -1 : 1);
    }

    // Note: these are defined in the mappings on the backend
    getDefaultFilter(activeReport: ReportIdentifier): string | null {
        switch (activeReport) {
            case ReportIdentifier.COMMUNICATIONS:
                return 'sent_date';
            case ReportIdentifier.COMPLETED_TASKS:
                return 'due_date';
            case ReportIdentifier.CONVERSION_TRACKING:
                return 'child_added_date';
            case ReportIdentifier.CURRENT_OPPS:
                return 'family_added_datetime';
            case ReportIdentifier.FAMILY_STATUS:
                return 'family_added_datetime';
            case ReportIdentifier.ALL_FAMILY:
                return 'family_added_datetime';
            case ReportIdentifier.GUARDIAN_LIST:
                return 'primary_guardian_added_datetime';
            case ReportIdentifier.GUARDIANS:
                return 'primary_guardian_added_datetime';
            case ReportIdentifier.MARKETING_SOURCE:
                return 'family_added_datetime';
            case ReportIdentifier.SCHEDULED_TASKS:
                return 'due_date';
            case ReportIdentifier.STAFF_SESSIONS:
                return 'last_logged_in_datetime';
            case ReportIdentifier.STATUS_HISTORY:
                return 'status_start_datetime';
            case ReportIdentifier.TASKS:
                return 'due_date';
            case ReportIdentifier.TOURS_MEETINGS:
                return 'due_date';
            case ReportIdentifier.STALLED_FAMILIES:
                return 'family_added_datetime';
            case ReportIdentifier.LOST_OPP:
                return 'family_added_datetime';
            case ReportIdentifier.WAIT_LIST:
                return 'family_added_datetime';
            case ReportIdentifier.ESTIMATED_REVENUE:
                return 'family_added_datetime';
            default:
                return null;
        }
    }

    isDateFilterable(mapping: ReportMappingObject): boolean {
        for (const val of Object.values(mapping)) {
            if (val.type === FlexmonsterReportColumnTypes.DATE ||
                val.type === FlexmonsterReportColumnTypes.DATETIME ||
                val.type === FlexmonsterReportColumnTypes.DATE_STRING
            ) {
                return true;
            }
        }

        return false;
    }
}
