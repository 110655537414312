import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink, NamedLink, SimpleHateoasLink } from '@/models/base';
import { StaffLink } from '@/staff/models/user';
import { FamilyLink } from '@/families/models/family';
import { CenterLink } from '@/organizations/locations/models/center';
import { SortParameter } from '@/models/sort-parameter';

export interface TaskTypeLink extends HateoasLink {
    values: {
        value: string;
        is_active: boolean;
        is_hidden: boolean;
    };
}

export interface TaskType extends CrmEntity {
    is_default: boolean;
    value: string;
    identifier?: string | null;
}

export interface TaskReminderTimes {
    staff_email_reminder: string | null;
    staff_text_reminder: string | null;
    primary_guardian_email_reminder: string | null;
    primary_guardian_text_reminder: string | null;
}

export interface Task extends CrmEntity {
    family: FamilyLink;
    center: CenterLink;
    type: TaskTypeLink;
    group: NamedLink;
    description: string;
    assigned_by_staff: StaffLink | null;
    assigned_to_staff: StaffLink | null;
    original_due_date_time: string;
    due_date_time: string;
    show_on_calendar: boolean;
    is_completed: boolean;
    completed_date_time: string | null;
    completed_by_staff: HateoasLink | null;
    result: HateoasLink | null;
    result_description: string;
    is_cancelled: boolean;
    reminder_times: TaskReminderTimes;
    added_date_time: string;
    assigned_to_enrollment_team: number | null;
    source: HateoasLink;
    duration: number | null;
    event: HateoasLink | null;
}

export interface ResultType extends CrmEntity {
    values: {
        value: string;
        is_task_result: boolean;
        is_active: boolean;
        is_hidden: boolean;
    };
    links?: Array<SimpleHateoasLink>;
}

export interface TaskGroup extends CrmEntity {
    name: string;
    task_types: Array<TaskTypeLink>;
    preferred_result_types: Array<ResultType>;
}

export interface TaskCreateDto extends AbstractCreateDto {
    family: number;
    type: number;
    assigned_to_staff: number;
    assigned_by_user_id?: number | null;
    due_date_time: string;
    primary_guardian_email_reminder_amount?: number | null;
    primary_guardian_email_reminder_unit?: string | null;
    primary_guardian_text_reminder_amount?: number | null;
    primary_guardian_text_reminder_unit?: string | null;
    staff_email_reminder_amount?: number | null;
    staff_email_reminder_unit?: string | null;
    staff_text_reminder_amount?: number | null;
    staff_text_reminder_unit?: string | null;
    show_on_calendar?: boolean | null;
    assigned_to_enrollment_team_group?: number | null;
    description?: string | null;
    is_completed?: boolean | null;
    completed_by_user_id?: number | null;
    completed_date_time?: string | null;
    result_type?: number | null;
    result_description?: string | null;
    is_canceled?: boolean | null;
    reminder_time?: number | null;
    duration?: number | null;
}

export enum TaskReminderUnits {
    MINUTES = 'minute',
    HOURS = 'hour',
    DAYS = 'day'
}

export interface TaskUpdateDto extends AbstractUpdateDto, TaskCreateDto {
    id: number;
}

export interface TaskTableData {
    id: number;
    name: string;
    type: string;
    assignee: string;
    assigneeDisplay: StaffLink;
    location: string;
    due: string;
}

export interface ManageTaskTableData {
    id: number;
    guardian_name: string;
    type: string;
    assignee_name: string;
    assigneeDisplay: StaffLink;
    timezone: string;
    location: string;
    due_date: string;
    icon?: string;
    description?: string;
}

export enum TaskGroups {
    PHONE_CALLS = 1,
    EMAILS = 2,
    TEXTS = 3,
    TOURS = 4,
    MEETINGS = 5,
    OTHER = 6
}

export enum TaskTypes {
    TOUR = 'taskTypeTour',
    CCF_INTERVIEW_DUE = 'taskTypeCcfInterviewDue',
    CCF_INTERVIEW_DUE_FOLLOWUP = 'taskTypeCcfInterviewDueFollowUp'
}

export enum TasksSortKeys {
    Assignee = 'assignee_name',
    Date = 'due_date',
    Type = 'type',
    Location = 'location',
    Guardian = 'guardian_name',
    Timezone = 'timezone'
}

export interface TasksSortParameter extends SortParameter {
    sort_keys: Array<TasksSortKeys.Assignee
        | TasksSortKeys.Date
        | TasksSortKeys.Type
        | TasksSortKeys.Location
        | TasksSortKeys.Guardian
        | TasksSortKeys.Timezone>;
}
