export enum SocialMediaLinkTags {
    ORGANIZATION_FACEBOOK = 'OrganizationFacebook',
    ORGANIZATION_LINKEDIN = 'OrganizationLinkedin',
    ORGANIZATION_TWITTER = 'OrganizationTwitter',
    ORGANIZATION_GOOGLEPLUS = 'OrganizationGooglePlus',
    ORGANIZATION_YOUTUBE = 'OrganizationYouTube',
    ORGANIZATION_PINTREST = 'OrganizationPintrest',
    ORGANIZATION_INSTAGRAM = 'OrganizationInstagram',
    LOCATION_FACEBOOK = 'LocationFacebook',
    LOCATION_LINKEDIN = 'LocationLinkedin',
    LOCATION_TWITTER = 'LocationTwitter',
    LOCATION_GOOGLEPLUS = 'LocationGooglePlus',
    LOCATION_YOUTUBE = 'LocationYouTube',
    LOCATION_PINTREST = 'LocationPintrest',
    LOCATION_INSTAGRAM = 'LocationInstagram'
}

export const chamaileonLinkButtonIds = ['button-link', 'image-link'];
export const chamaileonMergeButtonIds = [
    'button-link-title',
    'image-link-title',
    'merge',
    'button-link',
    'image-link',
    'image-alt',
    'video-alt'
];
