import { Child } from '@/families/models/child';
import { CrmEntity, HateoasLink } from '@/models/base';
import { AbstractCreateDto } from '@/models/abstract-dto';

export interface StatusLink extends HateoasLink {
    values: {
        name: string;
        status?: number;
    };
}

export interface Status extends CrmEntity {
    name: string;
    code?: string;
    child_only: boolean;
    is_archive: boolean;
    order: number;
    is_optional: boolean;
    is_sortable: boolean;
    is_exported: boolean;
    can_edit_logic: boolean;
    from_statuses: Array<StatusLink>;
    to_statuses: Array<StatusLink>;
}

export interface StatusChangeWaitList {
    isChildOfStaff: boolean | null;
    fee: string | null;
    feeDatePaid: string | null;
    isFeePaid: boolean | null;
    priority: number | null;
    type: number | null;
    isSiblingInCare: boolean | null;
}

export interface StatusChangeWithdrawn {
    goodStanding: boolean | null;
    isEligibleForReEnrollment: boolean | null;
}

export interface StatusChangeWaitListDto {
    is_child_of_staff: boolean | null;
    is_sibling_in_care: boolean | null;
    fee: string | null;
    fee_paid_date: string | null;
    is_fee_paid: boolean | null;
    priority: number | null;
    type: number | null;
}

export interface StatusChangeWithdrawnDto {
    good_standing: boolean | null;
    is_eligible_for_reenrollment: boolean | null;
}

export interface StatusChangeInterface {
    family_id: number;
    child_id: number | null;
    status: number;
    actual_start_date: string | null;
    date: string | null;
    expected_start_date: string | null;
    reason: number | null;
    comments: string | null;
    wait_list_details?: StatusChangeWaitListDto | null;
    withdrawn_details?: StatusChangeWithdrawnDto | null;
}

export interface SettingsChange {
    isOriginalSettings: boolean;
    child: Child;
}

export interface StatusDto extends AbstractCreateDto {
    name: string;
    code: string;
    child_only?: boolean;
    order?: number;
    is_archive?: boolean;
    is_optional?: boolean;
    is_exported: boolean;
    from_statuses?: Array<number>;
    to_statuses?: Array<number>;
}

export interface StatusPatchDto {
    name: string;
    code: string;
    is_exported: boolean;
}

export interface StatusMassUpdate {
    from: number;
    to: number;
}

export interface StatusDetails {
    lost_opportunity_reason?: HateoasLink | null;
    lost_opportunity_comment?: string | null;
    rejected_reason?: HateoasLink | null;
    rejected_comment?: string | null;
}

export interface StatusDetailsChild extends StatusDetails {
    enrolled_date?: string | null;
    enrolled_reason?: HateoasLink | null;
    enrolled_comment?: string | null;
    expected_start_date?: string | null;
    temp_leave_date?: string | null;
    temp_leave_reason?: HateoasLink | null;
    temp_leave_comments?: string | null;
    is_wait_list_fee_paid?: boolean;
    wait_list_fee_paid_date?: string | null;
    wait_list_date?: string | null;
    wait_list_reason?: HateoasLink | null;
    wait_list_comment?: string | null;
    wait_list_fee?: string | null;
    wait_list_type?: HateoasLink | null;
    wait_list_priority?: HateoasLink | null;
    withdrawn_date?: string | null;
    withdrawn_reason?: HateoasLink | null;
    withdrawn_comment?: string | null;
}

export class StatusChangeWrite implements StatusChangeInterface {
    family_id = 0;
    child_id: number | null = null;
    status = 0;
    actual_start_date: string | null = null;
    date: string | null = null;
    expected_start_date: string | null = null;
    reason: number | null = null;
    comments: string | null = null;
    wait_list_details: StatusChangeWaitListDto | undefined = undefined;
    withdrawn_details: StatusChangeWithdrawnDto | undefined = undefined;
}

export class StatusChangeWaitListWrite implements StatusChangeWaitListDto {
    is_child_of_staff = false;
    fee = null;
    fee_paid_date = null;
    is_fee_paid = false;
    priority = null;
    type = null;
    is_sibling_in_care = false;
}

export class StatusChangeWithdrawnWrite implements StatusChangeWithdrawnDto {
    good_standing = false;
    is_eligible_for_reenrollment = true;
}
