







































































































































    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { Task } from '@/tasks/models/task-models';

@Component
    export default class ViewTasksHistory extends Mixins(LocaleMixin) {
    @Prop() taskItem!: Task | null;
    @Prop({ type: String, required: true }) readonly guardianName!: string;

    }
