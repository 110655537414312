import { Reminder, TextReminderDto } from '@/communications/reminders/models/reminder-models';
import { AbstractRepository } from '@/repositories/abstract-repository';

export class TextRemindersRepository extends AbstractRepository<Reminder> {
    readonly endpoint = 'communications/reminders/texts';

    public async update(reminderId: number, updateDto: TextReminderDto): Promise<Reminder> {
        const response = await this.client.put(`${this.endpoint}/${reminderId}`, updateDto);
        return response.data;
    }
}
