import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { DateFilter } from '@/models/date-filter';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store/index';

export interface DashboardState {
    dateFilter: DateFilter;
}

/**
 * State of dashboard specific view elements
 */
@Module({
    namespaced: true,
    name: StoreModuleTypes.DASHBOARD,
    dynamic: true,
    store: store
})
export class DashboardStore extends VuexModule implements DashboardState {
    dateFilter: DateFilter = {
        periodType: null,
        startDate: '',
        endDate: ''
    };

    public get storedDateFilter(): DateFilter {
        return this.dateFilter;
    }

    @Mutation
    public storeDateFilter(dateFilter: DateFilter) {
        this.dateFilter = dateFilter;
    }
}
