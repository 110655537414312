import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Center } from '@/organizations/locations/models/center';
import { CenterDtoInterface } from '@/organizations/locations/models/center-dto';
import { fixUrl } from '@/core/url-utils';

export class CenterMapper implements AbstractMapper<Center> {
    /**
     * Make sure null isn't sent in the response for variable tags that weren't filled in.
     *
     * @param dto
     */
    cleanVariableTags(dto: CenterDtoInterface) {
        if ('variable_tags' in dto && dto.variable_tags && dto.variable_tags.length > 0) {
            for (let i = 0; i < dto.variable_tags.length; i++) {
                dto.variable_tags[i] = dto.variable_tags[i] ?? '';
            }
        }
    }

    toCreateDto(entity: Center): CenterDtoInterface {
        return {
            id: entity.id,
            name: entity.name,
            code: entity.code,
            avg_weekly_tuition: entity.avg_weekly_tuition ?? 0,
            is_active: entity.is_active,
            timezone: entity.timezone,
            email: entity.email,
            url: fixUrl(entity.url),
            capacity: entity.capacity,
            status: entity.status ? entity.status.id : 0,
            contact: {
                phone: {
                    number_e164: entity.contact.phone.number_e164 ?? ''
                },
                address: {
                    address1: entity.contact.address.address1,
                    locality: entity.contact.address.locality,
                    region: entity.contact.address.region,
                    postcode: entity.contact.address.postcode
                },
                call_center_phone: {
                    number_e164: entity.contact.call_center_phone ? entity.contact.call_center_phone.number_e164 ?? '' : ''
                }
            },
            parent_organization_id: entity.parent_organization_id ?? 1,
            brand: entity.brand?.id ?? null,
            staff: {
                director: entity.staff && entity.staff.director ? entity.staff.director.id : null,
                assistant_director: entity.staff && entity.staff.assistant_director ? entity.staff.assistant_director.id : null,
                administrator: entity.staff && entity.staff.administrator ? entity.staff.administrator.id : null,
                family_rep: entity.staff && entity.staff.family_rep ? entity.staff.family_rep.id : null,
                enrollment_rep: entity.staff && entity.staff.enrollment_rep ? entity.staff.enrollment_rep.id : null
            },
            social_media: {
                facebook: entity.social_media?.facebook ?? '',
                twitter: entity.social_media?.twitter ?? '',
                youtube: entity.social_media?.youtube ?? '',
                linkedin: entity.social_media?.linkedin ?? '',
                instagram: entity.social_media?.instagram ?? '',
                pinterest: entity.social_media?.pinterest ?? '',
                yelp: entity.social_media?.yelp ?? ''
            },
            hours: {
                mon_open: entity.hours.mon_open,
                mon_close: entity.hours.mon_close,
                tue_open: entity.hours.tue_open,
                tue_close: entity.hours.tue_close,
                wed_open: entity.hours.wed_open,
                wed_close: entity.hours.wed_close,
                thu_open: entity.hours.thu_open,
                thu_close: entity.hours.thu_close,
                fri_open: entity.hours.fri_open,
                fri_close: entity.hours.fri_close,
                sat_open: entity.open_saturday ? entity.hours.sat_open : null,
                sat_close: entity.open_saturday ? entity.hours.sat_close : null,
                sun_open: entity.open_sunday ? entity.hours.sun_open : null,
                sun_close: entity.open_sunday ? entity.hours.sun_close : null
            },
            communication_hours: entity.communication_hours,
            open_saturday: entity.open_saturday,
            open_sunday: entity.open_sunday,
            variable_tags: entity.variable_tags
        };
    }

    toUpdateDto(entity: Center): CenterDtoInterface {
        return this.toCreateDto(entity);
    }
}
