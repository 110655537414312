













































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { facebookAppId } from '@/core/env-vars';
import { FacebookPageLinkData, FacebookPageMappingDto } from '@/integrations/models/facebook';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { getModule } from 'vuex-module-decorators';
import { OrgsStore } from '@/store/orgs-store';
import { DataTableOptions } from '@/models/datatables';
import { EventTypes } from '@/constants/event-type-constants';
import { DataTableHeader } from 'vuetify';
import { LoadingStore } from '@/store/loading-store';
import { FbSdkUtils } from '@/integrations/fb-sdk-utils';
import { FacebookMappingRepository } from '@/integrations/repositories/facebook-mapping-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const integrationRepository = new IntegrationRepository();
const loadingState = getModule(LoadingStore);
const facebookState = getModule(FacebookStore);
const facebookMappingRepo = new FacebookMappingRepository();
const orgsState = getModule(OrgsStore);
const fbSdkUtils = new FbSdkUtils();

/* Note: interfaces starting with FB are for data coming from Facebook */

interface FBPageSubResponse {
    success: boolean;
}

interface FBAppInfo {
    id: string;
}

interface FBPageSubsInfo {
    data: Array<FBAppInfo>;
}

interface FBPageInfo {
    id: string;
    access_token: string;
    name: string;
}

interface FBPageInfoResponse {
    data: Array<FBPageInfo>;
}

interface PageDataObject extends FacebookPageMappingDto {
    isNew: boolean; // is the page already stored in CRM
    accessToken: string | null; // to help with saving
    needsSub: boolean; // does this page need to be subscribed if we map it to an org
}

interface OrgChoice {
    id: number | null;
    name: string;
}

@Component({
    components: { BaseClose }
})
export default class FacebookMapping extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isOpen!: boolean;
    @Prop({ default: false }) readonly fromLogin!: boolean;

    private appId = facebookAppId;
    private tableKey = 1;
    private loadingKey = 'facebookMapping';
    private pageDataObjects: Array<PageDataObject> = [];
    private tableOptions: DataTableOptions = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name']
    };

    private headers: Array<DataTableHeader> = [
        {
            text: 'Facebook Page',
            value: 'name',
            width: '44%'
        },
        {
            text: '',
            value: 'middle',
            width: '10%',
            align: 'center',
            sortable: false,
            class: 'text-truncate'
        },
        {
            text: 'Organization Level',
            value: 'organization',
            width: '44%'
        }
    ];

    get isLoggedIn() {
        return facebookState.loggedIn;
    }

    get invalidPages() {
        return facebookState.entities.filter(page => !page.page_still_valid);
    }

    get invalidPagesText() {
        return this.invalidPages.map(page => page.name).join(', ');
    }

    /**
     * Root org, centers, and do not map
     */
    get orgChoices(): Array<OrgChoice> {
        const choices: Array<OrgChoice> = orgsState.stored.filter((org) => {
            return org.center !== null;
        }).map((org) => {
            return {
                id: org.id,
                name: org.name
            };
        });
        choices.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        choices.unshift({
            id: 1,
            name: orgsState.stored[0].name
        });
        choices.unshift({
            id: null,
            name: ' - Do Not Map - '
        });
        return choices;
    }

    @Watch('isOpen')
    async opened() {
       if (this.isOpen) {
           this.tableOptions.sortBy = ['name'];
           this.pageDataObjects = facebookState.entities.filter(page => page.page_still_valid).map((mapping) => {
               let org = mapping.organization ? mapping.organization.id : null;
               const stillThere = this.orgChoices.filter((choice) => {
                   return choice.id === org;
               });
               if (!stillThere.length) {
                   org = 1;
               }
               return {
                   page_id: mapping.page_id,
                   name: mapping.name,
                   needsSub: false,
                   isNew: false,
                   accessToken: null,
                   organization: org
               };
           });
           if (this.fromLogin && this.isLoggedIn) {
               this.getPageData();
           }
       }
    }

    async created() {
        await facebookState.init();
        await orgsState.init();
    }

    async close() {
        if (this.fromLogin) {
            await fbSdkUtils.logout();
            facebookState.setIsLoggedOut();
        }
        this.$emit(EventTypes.CLOSE);
    }

    /**
     * custom sorting for the table, especially annoying because of org name
     */
    customSort() {
        this.pageDataObjects.sort((a, b) => {
            // make sure new pages are always at the top, no matter what
            if (a.isNew && !b.isNew) {
                return -1;
            }
            if (!a.isNew && b.isNew) {
                return 1;
            }
            let sortNameA = '';
            let sortNameB = '';
            if (this.tableOptions.sortBy && this.tableOptions.sortBy[0] === 'organization') {
                sortNameA = this.getOrgName(a.organization);
                sortNameB = this.getOrgName(b.organization);
            } else {
                sortNameA = a.name;
                sortNameB = b.name;
            }
            let compare = sortNameA.localeCompare(sortNameB);
            if (this.tableOptions.sortDesc && this.tableOptions.sortDesc[0]) {
                compare *= -1;
            }
            return compare;
        });
        return this.pageDataObjects;
    }

    // helper for sort
    getOrgName(id: number | null): string {
        const matching = this.orgChoices.filter((choice) => {
            return choice.id === id;
        });
        if (matching.length) {
            return matching[0].name;
        }
        return '';
    }

    // Function used to get each pages name, id, and access_token.
    getPageData() {
        // /me/accounts acts as /user/accounts?
        loadingState.loadingIncrement(this.loadingKey);
        window.FB.api(
            '/me/accounts',
            'get',
            { fields: 'id,name,access_token' },
            async (pageInformation: FBPageInfoResponse) => {
                const pages = pageInformation.data;
                for (const page of pages) {
                    // process fb api pages and match them up with CRM db pages
                    const existing = this.pageDataObjects.filter((pageObject) => {
                        return (pageObject.page_id === page.id);
                    });
                    if (existing.length) {
                        existing[0].name = page.name;
                        existing[0].accessToken = page.access_token;
                        if (existing[0].organization === null) {
                            existing[0].needsSub = true;
                        }
                    } else {
                        this.pageDataObjects.push({
                            page_id: page.id,
                            needsSub: true,
                            accessToken: page.access_token,
                            name: page.name,
                            organization: 1,
                            isNew: true
                        });
                    }
                }
                loadingState.loadingDecrement(this.loadingKey);
            }
        );
    }

    // Function used to check if each page is already subscribed or not.
    async getPageSubscriptionStatus(page: FBPageInfo): Promise<boolean> {
        return await new Promise(resolve => {
            window.FB.api(
                '/' + page.id + '/subscribed_apps',
                'get',
                { access_token: page.access_token },

                (response: FBPageSubsInfo) => {
                    let isSubscribed = false;
                    // check if the pages are subscribed to anything
                    if (response.data.length !== 0) {
                        // if they are, make sure it's subscribed to our app
                        response.data.forEach((element) => {
                            if (element.id === this.appId) {
                                isSubscribed = true;
                            }
                        });
                    }
                    resolve(isSubscribed);
                }
            );
        });
    }

    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const mapRequest: Array<FacebookPageMappingDto> = [];
        let error = false;
        for (const page of this.pageDataObjects) {
            if (page.needsSub && page.organization) {
                if (!page.accessToken) {
                    // shouldn't ever happen, but just in case
                    continue;
                }
                const subbed = await this.subscribeApp({ id: page.page_id, name: page.name, access_token: page.accessToken });
                if (!subbed) {
                    // we couldn't actually successfully subscribe, so don't save the mapping at all
                    error = true;
                    continue;
                }
            }
            mapRequest.push({
                page_id: page.page_id,
                name: page.name,
                organization: page.organization
            });
        }
        await facebookMappingRepo.updateMapping(mapRequest);
        await facebookState.retrieve();
        loadingState.loadingDecrement(this.loadingKey);
        if (error) {
            await this.$swal({
                icon: 'warning',
                text: 'Some pages could not be linked at this time. Please try again later.'
            });
        }
        await this.close();
    }

    // function used to subscribe each page to the FB app.
    async subscribeApp(page: FBPageInfo): Promise<boolean> {
        return new Promise(resolve => {
            window.FB.api(
                '/' + page.id + '/subscribed_apps',
                'post',
                { access_token: page.access_token, subscribed_fields: ['leadgen', 'messages', 'messaging_postbacks'] },
                async (response: FBPageSubResponse) => {
                    const auth = window.FB.getAuthResponse();

                    if (response.success && auth !== null) {
                        const pageDataObj: FacebookPageLinkData = {
                            userId: auth.userID,
                            userToken: auth.accessToken,
                            pageId: page.id,
                            pageName: page.name,
                            pageAccessToken: page.access_token
                        };
                        await integrationRepository.sendFacebookPageData(pageDataObj);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            );
        });
    }

}
