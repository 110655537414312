import { LayoutTabsStore } from '@/store/layout-tabs-store';
import Vue from 'vue';
import Vuex from 'vuex';
import { StoreModuleTypes } from '@/constants/store-constants';
import VuexPersistence from 'vuex-persist';
import { AuthStore } from '@/store/auth-store';

Vue.use(Vuex);

const vuexStorage = new VuexPersistence({
    storage: localStorage,
    modules: [StoreModuleTypes.AUTH, StoreModuleTypes.LAYOUT_TABS]
});

const store = new Vuex.Store({
    plugins: [vuexStorage.plugin],
    // for whatever reason, dynamic modules + vuex-persistence breaks in chrome, so we
    // have to manually register the auth module here
    modules: {
        [StoreModuleTypes.AUTH]: AuthStore,
        [StoreModuleTypes.LAYOUT_TABS]: LayoutTabsStore
    }
});

export default store;
