
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VSelect from 'vuetify/lib/components/VSelect/VSelect';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VSelect] });
export default base.extend({
    name: 'de-emphasized-select',
    props: {
        // set filled false, as this is the select style we want for de-emphasized selects
        filled: {
            type: Boolean,
            default: false
        },
        // set default menu options
        menuProps: {
            type: [String, Array, Object],
            default: () => ({
                transition: 'slide-x-transition'
            }) as Record<string, any>
        }
    },
    computed: {
        // add crm-card to the css class of the rendered element
        classes(): object {
            return {
                ...VSelect.options.computed.classes.call(this),
                'base-select': true,
                'de-emphasized-select': true
            };
        }
    }
});
