












































































import type { Family } from '@/families/models/family';
import { StatusesStore } from '@/families/store/statuses-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { StatusHistoriesRepository } from '@/families/repositories/status-histories-repository';
import {
    getAddedDate,
    getDaysFromStatusHistories,
    getLostOppDate,
    getLostOppTitle
} from '@/families/status-histories-utils';
import { StatusHistories, StatusHistoryResult } from '@/families/models/status-histories';
import { baseLocales } from '@/locales/locales';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { getMonochromeColorsWithLimits } from '@/charts/chart-utils';
import {
    BASE_DARK_BLUE,
    LIGHTEST_BLUE, LINE_LEADER_PURPLE
} from '@/core/style-utils';
import Highcharts from 'highcharts';
import { ColorUtils } from '@/utils/color-utils';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';

const statusesState = getModule(StatusesStore);
const featureStore = getModule(FeaturesStore);
const statusHistoriesRepository = new StatusHistoriesRepository();
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {}
})
export default class FamilyStatusBar extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly family!: Family;

    private familyStatusHistory: Array<StatusHistories> = [];
    private addedDate = '';
    // The statuses to display in the stepper
    private activeStatuses: Array<StatusHistoryResult> = [];
    private colors: Array<Highcharts.ColorType> = [];

    private get isArchive() {
        const inactiveStatus = statusesState.statuses.filter(status => status.is_archive);
        // check if family is archived status before children
        for (const status of inactiveStatus) {
            if (this.family.status?.id === status.id) {
                return true;
            }
        }
        return this.family.children.length > 0 && this.familyStatusHistory.length === 1;
    }

    // Get the min status of any of the opportunities in the family
    private get familyStatusOrder() {
        let minStatusOrder = 0;
        const allStatusIds = statusesState.storedStatuses.map(status => status.id);
        if (this.isArchive) {
            minStatusOrder = this.activeStatuses.length;
        } else if (this.family.children.length > 0) {
            for (const child of this.family.children) {
                if (!child.status) {
                    continue;
                }

                for (const status of this.activeStatuses) {
                    if (status.id === child.status.id && status.order > minStatusOrder) {
                        minStatusOrder = status.order;
                    }
                }
            }
        } else {
            minStatusOrder = allStatusIds.indexOf(this.family.primary_guardian.status.id) > minStatusOrder ? allStatusIds.indexOf(this.family.primary_guardian.status.id) : minStatusOrder;
        }
        return minStatusOrder;
    }

    private async created() {
        this.familyStatusHistory = await statusHistoriesRepository.getStatusHistories(this.family.id);
        const promises = [];
        promises.push(statusesState.init(), featureStore.init(), interfaceSettingsStore.init());
        await Promise.all(promises);
        const statuses = statusesState.statuses;
        // Set the stepper to show the active statuses
        this.addedDate = getAddedDate(this.family, baseLocales[this.$i18n.locale].fcLocale);
        this.activeStatuses = getDaysFromStatusHistories(this.familyStatusHistory, statuses);
        if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
            if (interfaceSettingsStore.hasWhiteLabel) {
                const primaryColor = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
                const lighterShade = ColorUtils.lighten(primaryColor, 70);
                this.colors = getMonochromeColorsWithLimits(lighterShade, primaryColor, this.activeStatuses.length);
            } else {
                const lighterShade = ColorUtils.lighten(LINE_LEADER_PURPLE, 70);
                this.colors = getMonochromeColorsWithLimits(lighterShade, LINE_LEADER_PURPLE, this.activeStatuses.length);
            }
        } else {
            this.colors = getMonochromeColorsWithLimits(LIGHTEST_BLUE, BASE_DARK_BLUE, this.activeStatuses.length);
        }
    }

    private classNames(index: number): string {
        let className = 'pb-0 pt-0 ';
        if (this.familyStatusOrder >= index) {
            className += 'complete-status';
        }
        return className;
    }

    private getColors(index: number) {
        if (this.familyStatusOrder >= index) {
            return `${this.colors[index]}`;
        }
        return '';
    }

    @Watch('family')
    private async updateFamilyStatusBar() {
        this.familyStatusHistory = await statusHistoriesRepository.getStatusHistories(this.family.id);
        const statuses = statusesState.statuses;
        this.addedDate = getAddedDate(this.family, baseLocales[this.$i18n.locale].fcLocale);
        this.activeStatuses = getDaysFromStatusHistories(this.familyStatusHistory, statuses);
    }

    private generateNumberText(status: StatusHistoryResult) {
        if (status.days_in_status !== null && !this.isArchive) {
            return `${status.days_in_status}`;
        } else {
            return '';
        }
    }

    private generateDayText(status: StatusHistoryResult) {
        if (status.days_in_status !== null && !this.isArchive) {
            if (status.days_in_status === 1) {
                return 'Day';
            } else {
                return 'Days';
            }
        } else {
            return '';
        }
    }

    get isLeadArchived() {
        const inactiveStatus = statusesState.statuses.filter(status => status.is_archive);
        // check if family is archived
        for (const status of inactiveStatus) {
            if (this.family.status?.id === status.id) {
                return true;
            }
        }
        return false;
    }

    private lostOppDate(arg: Array<StatusHistories>) {
        const lostOppDate = getLostOppDate(arg, baseLocales[this.$i18n.locale].fcLocale);
        if (Date.parse(lostOppDate) < Date.parse(this.addedDate)) {
            return 'Date: Bad Data';
        } else {
            return lostOppDate;
        }
    }

    private lostOppTitle(arg: Array<StatusHistories>) {
        const result = getLostOppTitle(arg);
        if (result === undefined) {
            return '';
        } else {
            return result;
        }
    }
}
