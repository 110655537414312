import {
    add, differenceInDays, differenceInMinutes, differenceInMonths, differenceInYears,
    endOfDay,
    endOfMonth,
    endOfYear,
    format,
    formatISO,
    isAfter, isBefore,
    isValid,
    parseISO,
    startOfDay
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { AuthStore } from '@/store/auth-store';
import pluralize from 'pluralize';
import { Timezone, TimezoneOption } from '@/core/timezones/timezone';

export const isoFormat = 'yyyy-MM-dd';

function normDate(date: Date | string): Date {
    if (typeof date === 'string') {
        return parseISO(date);
    }

    return date;
}

export function baseFormatDate(date: Date | string, formatString: string) {
    date = normDate(date);
    return format(date, formatString);
}

export function formatDateForApi(date: Date | string): string {
    return baseFormatDate(date, isoFormat);
}

export const isoFormatLong = 'yyyy-MM-dd HH:mm:ss';

export const isoFormatLong2 = 'yyyy-MM-dd\'T\'HH:mm:ss';

export const dayOfWeekFormat = 'iiii';

export const timeFormat = 'HH:mm';

export const yearMonthFormat = 'yyyy-MM';

// Mostly useful for the time picker
export function formatClockTime(date: Date | string): string {
    return baseFormatDate(date, timeFormat);
}

export function getTimeZoneString(date: Date, timezone: string, locale = 'en-US'): string {
    return Intl.DateTimeFormat(locale, { timeZone: timezone, timeZoneName: 'short' }).format(date).split(' ')[1];
}

export function getTimeZoneOptions(timezones: Array<Timezone>, referenceDate: Date, locale = 'en-US'): Array<TimezoneOption> {
    return timezones.map((tz) => {
        return {
            text: getTimeZoneString(referenceDate, tz.code, locale),
            value: tz.code
        };
    });
}

export function differenceInDaysLocal(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
}

export function differenceInHours(startDate: string, endDate: string): number {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return (end.getTime() - start.getTime()) / (1000 * 60 * 60);
}

export function formatIsoDateTime(date: Date | string, timezone: string | null = null, useZOffset = true): string {
    const offset = useZOffset ? 'Z' : '+00:00';
    if (timezone) {
        return zonedTimeToUtc(date, timezone).toISOString().slice(0, -5) + offset;
    }

    date = normDate(date);

    return date.toISOString().slice(0, -5) + offset;
}

export function newDateForDatePicker(): string {
    return formatDateForApi(new Date());
}

export interface DateRange {
    start: Date;
    end: Date;
}

export function getSingleDayRangeFromDate(date: Date): DateRange {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    const dateEnd = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
    );
    return {
        start: date,
        end: dateEnd
    };
}

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];
export function isDateInPast(dateString: string) {
    return (new Date()) > (new Date(dateString));
}

export function isDateToday(dateString: string) {
    const date = (new Date(dateString)).getTime();
    const todayStart = (new Date()).setHours(0, 0, 0, 0) as number;
    const todayEnd = (new Date()).setHours(23, 59, 59, 999) as number;
    return (date >= todayStart) && (date <= todayEnd);
}

export function isDateInFuture(dateString: string) {
    return (new Date()) < (new Date(dateString));
}

/**
 * get the start of the day in the given timezone
 * @param timezone
 */
export function getTodayStart(timezone: string): string {
    return formatIsoDateTime(zonedTimeToUtc(startOfDay(utcToZonedTime(new Date(), timezone)), timezone));
}

/**
 * get the end of the day in the given timezone
 * @param timezone
 */
export function getTodayEnd(timezone: string): string {
    return formatIsoDateTime(zonedTimeToUtc(endOfDay(utcToZonedTime(new Date(), timezone)), timezone));
}

/**
 * get the end of the month in the given timezone
 * @param timezone
 */
export function getMonthEnd(timezone: string): string {
    return formatIsoDateTime(zonedTimeToUtc(endOfMonth(utcToZonedTime(new Date(), timezone)), timezone));
}

/**
 * get the end of the year in the given timezone
 * @param timezone
 */
export function getYearEnd(timezone: string): string {
    return formatIsoDateTime(zonedTimeToUtc(endOfYear(utcToZonedTime(new Date(), timezone)), timezone));
}

/**
 * take a UTC date and convert it to display in timezone
 * @param date
 * @param timezone
 * @param formatString
 */
export function formatDateWithTimezone(date: string | Date, timezone: string, formatString?: string): string {
    date = normDate(date);
    const zonedDate = utcToZonedTime(date, timezone);

    if (!formatString) {
        formatString = 'yyyy-MM-dd\'T\'HH:mm:ssxxx';
    }

    return format(zonedDate, formatString);
}

/**
 * get the year part of a date
 * @param date
 */
export function formatYear(date: string | Date): string {
    return baseFormatDate(date, 'yyyy');
}

/**
 * get the month year part of a date
 * @param date
 */
export function formatMonth(date: string | Date): string {
    return baseFormatDate(date, 'MMM yyyy');
}

/**
 * get the week part of a date (eg 'oct 1, 1996')
 * @param date
 */
export function formatWeek(date: string | Date): string {
    return baseFormatDate(date, 'MMM d, yyyy');
}

/**
 * calculate difference in whole days (rounding up) between two dates
 * @param date1
 * @param date2
 */
export function diffDays(date1: string | Date, date2: string | Date): number {
    date1 = normDate(date1);
    date2 = normDate(date2);
    const wholeDiff = differenceInDays(date1, date2);
    if (isBefore(add(date2, { days: wholeDiff }), date1)) {
        return wholeDiff + 1;
    }

    return wholeDiff;
}

/**
 * calculate difference in minutes (rounding down) between two dates
 * @param date1
 * @param date2
 */
export function diffMinutes(date1: string | Date, date2: string | Date): number {
    date1 = normDate(date1);
    date2 = normDate(date2);

    return differenceInMinutes(date1, date2);
}

/**
 * does the string represent a valid date?
 * @param date
 */
export function isValidDate(date: string): boolean {
    return isValid(parseISO(date));
}

/**
 * is the date later than current time
 * @param date
 */
export function isAfterNow(date: string): boolean {
    return isAfter(parseISO(date), new Date());
}

/**
 * add a number of minutes to the given date
 * @param date
 * @param addend
 */
export function addMinutes(date: string | Date, addend: number): string {
    date = normDate(date);
    return formatISO(add(date, { minutes: addend }));
}

/**
 * add a number of hours to the given date
 * @param date
 * @param addend
 */
export function addHours(date: string | Date, addend: number): string {
    date = normDate(date);
    return formatISO(add(date, { hours: addend }));
}

/**
 * add a number of days to the given date
 * @param date
 * @param addend
 */
export function addDays(date: string | Date, addend: number): string {
    date = normDate(date);
    return formatISO(add(date, { days: addend }));
}

/**
 * add a number of months to the given date
 * @param date
 * @param addend
 */
export function addMonths(date: string | Date, addend: number): string {
    date = normDate(date);
    return formatISO(add(date, { months: addend }));
}

/**
 * add a number of years to the given date
 * @param date
 * @param addend
 */
export function addYears(date: string | Date, addend: number): string {
    date = normDate(date);
    return formatISO(add(date, { years: addend }));
}

/**
 * how many years before now is the date?
 * @param date
 */
export function yearsAgo(date: string | Date): number {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return differenceInYears(now, date);
}

/**
 * how many months before now is the date?
 * @param date
 */
export function monthsAgo(date: string | Date): number {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return differenceInMonths(now, date);
}

/**
 * how many days before now is the date?
 * @param date
 */
export function daysAgo(date: string | Date): number {
    const now = new Date();

    if (typeof date === 'string') {
        date = parseISO(date);
    }

    return differenceInDays(now, date);
}

export function getDateString(date: Date, authState: AuthStore): string {
    return formatDateWithTimezone(new Date(), authState.userTimeZone, 'EEEE, MMMM d, Y');
}

export function getGmtOffset(tz: string): string {
    const offset = Math.round(getTimezoneOffset(tz) / 1000 / 3600);
    return (offset >= 0 ? '+' : '') + offset.toFixed(0);
}

/**
 * convert months into years and months
 * @param monthCount
 */
export function getYearsAndMonths(monthCount: number | null): string {
    if (monthCount === null) {
        return '';
    }
    const months = monthCount % 12;
    const years = Math.floor(monthCount / 12);
    const result = [];

    years && result.push(`${years} ${pluralize('yr', years)}`);
    months && result.push(`${months} ${pluralize('mo', months)}`);

    return result.join(', ');
}
