

































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { AvailabilityClassroomObject } from '@/enrollment-center/models/enrollment-center-location-models';
import ClassroomAvailCell from '@/enrollment-center/components/ClassroomAvailCell.vue';

@Component({
    components: { ClassroomAvailCell }
})
export default class ClassroomAvailRow extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() hasSaturday!: boolean;
    @Prop() hasSunday!: boolean;
    @Prop() isEdit!: boolean;
    @Prop() name!: string;
    @Prop() readonly value!: AvailabilityClassroomObject;

    get classObject(): AvailabilityClassroomObject {
        return this.value;
    }

    set classObject(object: AvailabilityClassroomObject) {
        this.$emit('input', object);
    }

    get baseDays() {
        return [0, 1, 2, 3, 4];
    }

    get canHaveNext() {
        let canHaveNext = false;
        for (let iter = 0; iter < 7; iter++) {
            if (!this.classObject.day_avails[iter].is_available) {
                // if any relevant day has no availability, we
                // allow next free day to be input
                if (iter < 5) {
                    canHaveNext = true;
                }
                if (iter === 5 && this.hasSaturday) {
                    canHaveNext = true;
                }
                if (iter === 6 && this.hasSunday) {
                    canHaveNext = true;
                }
            }
            if (canHaveNext) {
                break;
            }
        }
        return canHaveNext;
    }
}
