import { CustomValues, CustomValuesUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';
import { Phone, PhoneDtoInterface } from '@/families/models/phone';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmApiElement, CrmEntity, HateoasLink } from '@/models/base';
import { UserPermission, UserPermissionDto } from '@/staff/models/user-permission-models';
import { SortParameter } from '@/models/sort-parameter';
import { OrganizationLink } from '@/organizations/models/organization';
import { CenterLink } from '@/organizations/locations/models/center';

export interface PositionLink extends HateoasLink {
    values: {
        value: string;
    };
}

export interface UserStub {
    first_name: string;
    last_name: string;
    username: string;
}

export interface User extends CrmEntity, UserStub {
    email_address: string;
    recovery_email: string;
    phone: Phone | null;
    hire_time: string | null;
    alternate_phone: Phone | null;
    center_id: number | null;
    center: CenterLink | null;
    force_password_change: boolean;
    is_active: boolean;
    needs_eula: boolean;
    org_id: number;
    org: OrganizationLink;
    timezone: string;
    country: string;
    position_title: PositionLink | null;
    client_id: number;
    is_superuser: boolean;
    is_full_superuser?: boolean;
    has_new_ui: boolean;
    has_crm_plus: boolean;
    is_service_account: boolean;
    is_brand_email_enabled?: boolean;
    is_email_verified: boolean;
    is_dual_mode_legacy_blocked?: boolean;
    is_on_enrollment_team: boolean;
    is_notified_on_replies: boolean;
    is_manager_notification_enabled: boolean;
    is_web_pending_notification_enabled: boolean;
    is_refer_transfer_notification_enabled: boolean;
    is_nylas_active: boolean;
    is_nylas_email_active: boolean;
    is_nylas_calendar_active: boolean;
    nylas_email: string | null;
    custom_values: Array<CustomValues>;
    termination_time: string | null;
}

export interface UserCreateDto extends AbstractCreateDto {
    center_id?: number | null;
    email_address: string;
    first_name: string;
    force_password_change: boolean;
    is_active: boolean;
    last_name: string;
    needs_eula: boolean;
    org_id: number | null;
    password?: string;
    phone: PhoneDtoInterface;
    alternate_phone: PhoneDtoInterface;
    recovery_email: string;
    username: string;
    timezone: string;
    country: string;
    position_title: number | null;
    is_brand_email_enabled: boolean;
    is_dual_mode_legacy_blocked: boolean;
    is_on_enrollment_team: boolean;
    is_notified_on_replies: boolean;
    is_manager_notification_enabled: boolean;
    is_web_pending_notification_enabled: boolean;
    is_refer_transfer_notification_enabled: boolean;
    custom_values?: Array<CustomValuesUpdateDto> | null;
    termination_time: string | null;
}

export interface UserUpdateDto extends AbstractUpdateDto, UserCreateDto {
    id: number;
}

export interface UserUpdate {
    id: number;
    password?: string;
    force_password_change?: boolean;
    recovery_email?: string;
    needs_eula?: boolean;
}

export type UserUpdateWrapper = Array<UserUpdate>;

export interface StaffLink extends HateoasLink {
    values: UserStub;
}

export interface UserWithAllPermissions extends CrmApiElement {
    staff: StaffLink;
    org: OrganizationLink | null;
    position: PositionLink | null;
    center_id: number | null;
    center: CenterLink | null;
    phone: string;
    phone_formatted: string;
    email: string;
    permissions: Array<UserPermission>;
}

export interface UserWithAllPermissionsTableItem {
    staff: StaffLink;
    permissions: Array<UserPermission>;
    fullName: string;
    position: string;
    positionId: number;
    orgLevel: OrganizationLink | null;
    isCenterUser: boolean;
    center: CenterLink | null;
    phone: string;
    phone_formatted: string;
    email: string;
}

export interface UserWithAllPermissionsDto {
    staff: number;
    permissions: Array<UserPermissionDto>;
}

// Translation object for user properties to a display friendly name
export const userPropertyTranslationObject = {
    centerId: 'Center',
    emailAddress: 'Contact Email',
    firstName: 'First Name',
    forcePasswordChange: 'Force Password Change',
    isActive: 'Active',
    lastName: 'Last Name',
    needsEula: 'Needs Eula',
    orgId: 'Org Level',
    password: 'Password',
    phone: 'Phone',
    alternatePhone: 'Alternate Phone',
    recoveryEmail: 'Recovery Email',
    username: 'Username',
    timezone: 'Timezone',
    country: 'Country',
    positionTitle: 'Position',
    clientId: 'Customer'
};

export enum StaffSortKeys {
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    STATUS = 'status',
    POSITION = 'position',
    ORG = 'org'
}

export interface StaffSortParameter extends SortParameter {
    sort_keys: Array<StaffSortKeys.FIRST_NAME
        | StaffSortKeys.LAST_NAME
        | StaffSortKeys.STATUS
        | StaffSortKeys.POSITION
        | StaffSortKeys.ORG>;
}

export interface StaffPosition {
    center: CenterLink;
    director: StaffLink | null;
    asst_director: StaffLink | null;
    manager: StaffLink | null;
    admin: StaffLink | null;
    family_rep: StaffLink | null;
    enrollment_rep: StaffLink | null;
}

export interface StaffPositionUpdateDto {
    center: number;
    director?: boolean;
    asst_director?: boolean;
    manager?: boolean;
    admin?: boolean;
    family_rep?: boolean;
    enrollment_rep?: boolean;
}

export interface CenterStaffPositionsTableData {
    centerId: number;
    centerName: string;
    enrollmentRep: number | null;
}

export interface CenterEnrollmentRepDto {
    userId: number;
    value: boolean;
}
