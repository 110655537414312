export function getHostString(url: string): string {
    const urlObject = new URL(url);
    return urlObject.host;
}

export function fixUrl(url: string): string {
    if (url) {
        return url.match(/^http/) ? url : 'http://' + url;
    }
    return '';
}

export function getWhiteLabelUrl(url: string): string {
    return url.replace('https://www.', 'https://');
}
