import { SavedReport } from '@/reports/models/saved-report';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';

export interface SavedReportsState {
    entities: Array<SavedReport>;
    userId: number;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.SAVED_REPORTS
})
export class SavedReportsStore extends AbstractApiStore<SavedReport> implements SavedReportsState {
    readonly repository = new SavedReportsRepository();
    userId = 0;

    @Mutation
    private storeReports(reports: { userId: number; entities: Array<SavedReport> }): void {
        this.userId = reports.userId;
        this.entities = reports.entities;
    }

    @Action({ commit: 'storeReports', rawError: true })
    public async retrieveAllReports(userId: number) {
        const entities = await this.repository.getReports(userId);
        return { userId, entities };
    }

    // initialize for data retrieval
    // don't retrieve if we already have data
    @Action
    public async init(userId: number) {
        if (this.entities.length === 0 || userId !== this.userId) {
            await this.retrieveAllReports(userId);
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<SavedReport> {
        return this.entities;
    }

}
