import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';
import { Address, AddressDto } from '@/families/models/address';
import { Phone, PhoneDto, PhoneDtoInterface } from '@/families/models/phone';
import { StatusDetails, StatusLink } from '@/families/models/status';
import { RelationshipLink } from '@/families/models/relationship';
import { formatIsoDateTime } from '@/date-time/date-time-utils';
import { FamilyIntegration } from '@/families/models/family';
import { MarketingSubscriptions } from '@/marketing/models/marketing-model';

export interface Guardian extends CrmEntity {
    name: string;
    first_name: string;
    last_name: string;
    title: string;
    gender: string;
    date_of_birth: string | null;
    added_date: string;
    email: string;
    alternate_email: string;
    address: Address;
    primary_phone: Phone | null;
    alternate_phone: Phone | null;
    relation_to_child: string | null;
    child_relation: RelationshipLink | null;
    center_id: number | null;
    external_system_id: string;
    status: StatusLink;
    referred_by: string;
    prior_likes: string;
    prior_dislikes: string;
    integrations: Array<FamilyIntegration>;
    custom_type_1?: HateoasLink | null;
    custom_type_2?: HateoasLink | null;
    custom_type_3?: HateoasLink | null;
    custom_type_4?: HateoasLink | null;
    status_details?: StatusDetails;
    do_not_call?: boolean;
    do_not_email?: boolean;
    do_not_text?: boolean;
    subscriptions?: MarketingSubscriptions;
}

export interface GuardianCreateDtoInterface extends AbstractCreateDto {
    first_name: string;
    last_name: string;
    status?: number | null;
    center_id: number | null;
    email: string;
    primary_phone: PhoneDtoInterface | null;
    alternate_phone: PhoneDtoInterface | null;
    alternate_email: string | null;
    address: Address | AddressDto | null;
    child_relation: number | null;
    added_date: string;
    gender: string;
    date_of_birth: string;
    referred_by: string;
    prior_likes: string;
    prior_dislikes: string;
    custom_type_1?: number | null;
    custom_type_2?: number | null;
    custom_type_3?: number | null;
    custom_type_4?: number | null;
    enrollment_rep?: number | null; // This is only setup to work when mapping an entire family, not just a guardian
    do_not_call?: boolean;
    do_not_email?: boolean;
    do_not_text?: boolean;
}

export class GuardianCreateDto implements GuardianCreateDtoInterface {
    first_name = '';
    last_name = '';
    status: number | null = null;
    center_id: number | null = null;
    email = '';
    primary_phone = new PhoneDto();
    alternate_phone = new PhoneDto();
    address = new AddressDto();
    child_relation: number | null = null;
    alternate_email = '';
    added_date = formatIsoDateTime(new Date());
    date_of_birth = '';
    gender = '';
    referred_by = '';
    prior_likes = '';
    prior_dislikes = '';
    custom_type_1 = null;
    custom_type_2 = null;
    custom_type_3 = null;
    custom_type_4 = null;
    enrollment_rep = null;
}

export interface GuardianUpdateDtoInterface extends GuardianCreateDtoInterface, AbstractUpdateDto {
    id: number;
}

export interface GuardianLink extends HateoasLink {
    values: {
        name: string;
        family_id: number;
        is_primary: boolean;
        last_name: string;
        first_name: string;
    };
}
