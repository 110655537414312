





import Flexmonster from 'flexmonster';
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class Pivot extends Vue {
    @Prop({ type: Function, default: () => { return null; } }) readonly afterchartdraw!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly aftergriddraw!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly beforegriddraw!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly beforetoolbarcreated!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly cellclick!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly celldoubleclick!: Function;
    @Prop({ type: String, default: '' }) readonly componentFolder!: string;
    @Prop({ type: Function, default: () => { return null; } }) readonly customizeAPIRequest!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly customizeChartElement!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly customizeContextMenu!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly datachanged!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly dataerror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly datafilecancelled!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly drillthroughclose!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly drillthroughopen!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly fieldslistclose!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly fieldslistopen!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly filterclose!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly filteropen!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly fullscreen!: string ;
    @Prop({ type: Object, default: null }) readonly global!: object;
    @Prop({ type: [String, Number], default: '' || 0 }) readonly height!: [string, number];
    @Prop({ type: String, default: '' }) readonly licenseKey!: string;
    @Prop({ type: String, default: '' }) readonly licenseFilePath!: string;
    @Prop({ type: Function, default: () => { return null; } }) readonly loadingdata!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly loadinglocalization!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly loadingolapstructure!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly loadingreportfile!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly localizationerror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly localizationloaded!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly olapstructureerror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly olapstructureloaded!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly openingreportfile!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly querycomplete!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly queryerror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly ready!: Function;
    @Prop({ type: [String, Object], default: '' || null }) readonly report!: [string, object];
    @Prop({ type: Function, default: () => { return null; } }) readonly reportchange!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly reportcomplete!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly reportfilecancelled!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly reportfileerror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly reportfileloaded!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly runningquery!: Function;
    @Prop({ type: Boolean, default: false }) readonly toolbar!: boolean;
    @Prop({ type: Function, default: () => { return null; } }) readonly unauthorizederror!: Function;
    @Prop({ type: Function, default: () => { return null; } }) readonly update!: Function;
    @Prop({ type: [Number, String], default: 0 || '' }) readonly width!: [number, string];

    private flexmonster: any;

    mounted() {
        this.flexmonster = new Flexmonster({
            ...this.$props,
            container: this.$el
        });
    }

    beforeUpdate() {
        return false;
    }
};
