export default class MiscUtils {
    /**
     * TS version of PHP's empty(), stolen from https://locutus.io/php/empty/
     * @param mixedVar
     */
    public static empty(mixedVar: any): boolean {
        let undef;
        let key;
        let i;
        let len;
        const emptyValues = [undef, null, false, 0, '', '0'];

        for (i = 0, len = emptyValues.length; i < len; i++) {
            if (mixedVar === emptyValues[i]) {
                return true;
            }
        }

        if (typeof mixedVar === 'object') {
            for (key in mixedVar) {
                if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }

    /**
     * get the ordinal suffix for a number, eg 1st, 2nd, 3rd, 4th
     * @param n
     */
    public static ordinalSuffix(n: number): string {
        if (n > 3 && n < 21) {
            // 11th, 15th, etc
            return 'th';
        }
        const mod = n % 10;
        switch (mod) {
            case 1:
                // 101st, 31st
                return 'st';
            case 2:
                // 22nd, 1022nd
                return 'nd';
            case 3:
                // 3rd, 103rd
                return 'rd';
        }
        // 0th
        return 'th';
    }
}
