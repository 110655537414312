













































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { Route } from 'vue-router';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const featuresStore = getModule(FeaturesStore);
const authState = getModule(AuthStore, store);
const layoutTabsStore = getModule(LayoutTabsStore, store);

// Register a component route guard to prevent access, as needed
Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: { BasePageTitle }
})
export default class SystemSettings extends Mixins(LocaleMixin, PageTitleMixin) {
    // This component just has a router-view so that nested children routes can load their components correctly
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        if (authState.isCorporateUser || authState.isSuperuser) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isFullSuperuser() {
        return authState.isFullSuperuser;
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    get showSuperuser() {
        return this.isFullSuperuser && this.isLineLeaderEnroll;
    }

    async mounted() {
        this.setPageTitle('System Settings');
        await featuresStore.init();
        if (this.showSuperuser) {
            await this.$router.push({ name: 'superuser-settings' });
        } else {
            await this.$router.push({ name: 'features-settings' });
        }
    }

    // Update the tab info in Layout.vue.
    private onChange() {
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? 'Settings';
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = this.$route.params?.tabTitle ?? this.$route?.meta?.tabTitle ?? undefined;
        layoutTabsStore.updateTab(outerTab);
    }
}
