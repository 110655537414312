

















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsEventDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { WorkflowsTypesStore } from '@/automation/workflows/store/workflows-types-store';
import { LoadingStore } from '@/store/loading-store';
import {
    eventTypeId,
    WorkflowEntityTypes
} from '@/automation/workflows/constants/workflow-constants';
import { WorkflowType } from '@/automation/workflows/models/workflow-type-models';

const workflowsTypesStore = getModule(WorkflowsTypesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class WorkflowActionAddEvent extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsEventDto;

    private loadingKey = 'manageWorkflow';
    private eventTypes: Array<WorkflowType> = [];

    get settings(): WorkflowActionSettingsEventDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsEventDto) {
        this.$emit('input', settings);
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await workflowsTypesStore.retrieveFieldValues({ entityId: WorkflowEntityTypes.EVENT, fieldId: eventTypeId });
        this.eventTypes = workflowsTypesStore.fieldValues(WorkflowEntityTypes.EVENT, eventTypeId);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
