







































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventTypes } from '@/constants/event-type-constants';
import { Center, CenterLink } from '@/organizations/locations/models/center';
import { DataTableHeader } from 'vuetify';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const formsRepository = new LandingPagesRepository();
const loadingState = getModule(LoadingStore);

interface CentersEnabled {
    center: Center;
    enabled: boolean;
}

@Component({
    components: { BaseClose }
})
export default class AvailableLocationsModal extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly centers!: Array<Center>;
    @Prop({ required: true }) readonly formId!: number;
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    private centersAvailable: Array<CentersEnabled> = [];
    private curLocations: Array<CenterLink> = [];
    private isAllEnabled = false;

    private headers: Array<DataTableHeader> = [
        { text: 'Location', value: 'center.name' },
        { text: 'Enable', value: 'enabled' }
    ];

    private isDisabled = true;
    // For the snackbar when changes are made
    private isUpdated = false;
    private loadingKey = 'availableLocations';
    private search = '';
    private snackbarMessage = 'Locations Updated';

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get className(): string {
        return this.centersAvailable.length > 50 ? 'shrink-by-twenty' : this.centersAvailable.length > 20 ? 'shrink-by-ten' : '';
    }

    @Watch('modelValue')
    async modelWatch() {
        if (this.modelValue) {
            this.isDisabled = true;
            this.centersAvailable = [];
            loadingState.loadingIncrement(this.loadingKey);
            this.curLocations = await formsRepository.getLocations(this.formId);
            const locationSet: Set<number> = new Set();

            for (const location of this.curLocations) {
                locationSet.add(location.id);
            }

            for (const center of this.centers) {
                if (locationSet.size === 0 || locationSet.has(center.id)) {
                    this.centersAvailable.push({ center: center, enabled: true });
                } else {
                    this.centersAvailable.push({ center: center, enabled: false });
                }
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private close() {
        this.centersAvailable = [];
        this.modelValue = false;
    }

    private checkEnabledSwitches() {
        this.isDisabled = this.centersAvailable.filter(center => center.enabled).length === 0;
    }

    private enableDisableAll() {
        for (const item of this.centersAvailable) {
            item.enabled = this.isAllEnabled;
        }
        this.isDisabled = !this.isAllEnabled;
        this.isAllEnabled = !this.isAllEnabled;
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        this.isDisabled = true; // No more changes; disable the button
        const centerIds: Array<number> = [];

        let allAreEnabled = true;
        for (const item of this.centersAvailable) {
            if (item.enabled) {
                centerIds.push(item.center.id);
            } else {
                allAreEnabled = false;
            }
        }
        if (allAreEnabled) {
            // if everything's enabled, we set the centers to be empty to reflect
            // no restrictions
            centerIds.splice(0, centerIds.length);
        }

        await formsRepository.setLocations(this.formId, centerIds);

        this.isDisabled = false;
        loadingState.loadingDecrement(this.loadingKey);
        this.isUpdated = true;
        this.$emit(EventTypes.UPDATED);
        this.close();
    }
}
