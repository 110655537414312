import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ErrorHandler } from '@/errors/error-handler';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { refreshLogin } from '@/auth/auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { enableXdebug, vueEnv } from '@/core/env-vars';

const authState = getModule(AuthStore, store);

// avoid circular dependencies
export function createAuthClient(baseURL: string, withCredentials = false) {
    const instance = axios.create({
        baseURL: baseURL,
        withCredentials: withCredentials,
        headers: {
            'X-UI-Request': 'true'
        }
    });
    instance.interceptors.request.use(function(config: AxiosRequestConfig): AxiosRequestConfig {
        if (vueEnv === 'development' && enableXdebug) {
            config.params = {
                XDEBUG_SESSION_START: 'PHPSTORM',
                ...config.params
            };
        }
        return config;
    }, function(error: AxiosError) {
        return Promise.reject(error);
    });
    return instance;
}

export function createClient(baseURL: string, errorHandle = true, withCredentials = false) {
    const instance = axios.create({
        baseURL: baseURL,
        withCredentials: withCredentials,
        headers: {
            'X-UI-Request': 'true'
        }
    });
    createAuthRefreshInterceptor(instance, async (): Promise<void> => {
        if (authState.refreshToken) {
            if (authState.refreshToken) {
                const refreshResponse = await refreshLogin(authState.refreshToken);
                if (refreshResponse.success) {
                    authState.storeRefresh(refreshResponse);
                } else {
                    authState.storeLoggedOut();
                }
            } else {
                authState.storeLoggedOut();
            }
        }
        return Promise.resolve();
    });
    instance.interceptors.request.use(function(config: AxiosRequestConfig): AxiosRequestConfig {
        if (authState.token && config.headers) {
            config.headers.Authorization = 'Bearer ' + authState.token;
        }
        if (vueEnv === 'development' && enableXdebug) {
            config.params = {
                XDEBUG_SESSION_START: 'PHPSTORM',
                ...config.params
            };
        }
        return config;
    }, function(error: AxiosError) {
        return Promise.reject(error);
    });
    instance.interceptors.response.use(function(response: AxiosResponse) {
        return response;
    }, function(error: AxiosError) {
        // this pops up when navigating before everything loads
        if (error.message === 'Request aborted' || axios.isCancel(error)) {
            return Promise.resolve();
        }
        if (errorHandle) {
            ErrorHandler(error).then(() => {
                return Promise.reject(error);
            });
        }
        return Promise.reject(error);
    });
    return instance;
}

export function createExternalClient() {
    return createClient('');
}
