import { Child, ChildCreateDtoInterface, ChildRevenue, ChildUpdateDtoInterface } from '@/families/models/child';
import { AbstractRepository } from '@/repositories/abstract-repository';

export class ChildrenRepository extends AbstractRepository<Child> {
    protected endpoint = ''; // Unused; here to fulfill implementation contract

    public async retrieve(familyId: number, childId: number): Promise<Child> {
        const response = await this.client.get<Child>(`families/${familyId}/children/${childId}`);
        return response.data;
    }

    public async create(familyId: number, childData: ChildCreateDtoInterface): Promise<Array<Child>> {
        // POST for the children controller is post many, only!
        const response = await this.client.post<Array<Child>>(`families/${familyId}/children`, [childData]);
        return response.data;
    }

    public async createArchived(familyId: number, childData: ChildCreateDtoInterface): Promise<Array<Child>> {
        const response = await this.client.post<Array<Child>>(`families/${familyId}/children/archived`, [childData]);
        return response.data;
    }

    public async update(familyId: number, childData: ChildUpdateDtoInterface): Promise<Child> {
        const response = await this.client.put<Child>(`families/${familyId}/children/${childData.id}`, childData);
        return response.data;
    }

    public async delete(familyId: number, childId: number): Promise<void> {
        await this.client.delete<Child>(`families/${familyId}/children/${childId}`);
    }

    public async getChildFromHref(href: string): Promise<Child> {
        const response = await this.client.get<Child>(href);
        return response.data;
    }

    public async getRevenue(familyId: number, childId: number): Promise<ChildRevenue> {
        const response = await this.client.get(`families/${familyId}/children/${childId}/revenue`);
        return response.data;
    }

    public async getChildrenRevenue(familyId: number): Promise<Array<ChildRevenue>> {
        const response = await this.client.get(`families/${familyId}/children/revenue`);
        return response.data;
    }
}
