



















































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { CustomDashboardsStore } from '@/dashboards/store/custom-dashboards-store';
import { LoadingStore } from '@/store/loading-store';
import { CustomDashboardsRepository } from '@/dashboards/repositories/custom-dashboards-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { CustomDashboardService } from '@/dashboards/custom-dashboard-service';
import { DashboardElementOption, DashboardUiObject, DashElementType } from '@/dashboards/models/custom-dashboards';
import DashboardRow from '@/dashboards/components/custom/DashboardRow.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { AppStateStore } from '@/store/app-state-store';
import Draggable from 'vuedraggable';

const dashboardsState = getModule(CustomDashboardsStore);
const dashboardsRepo = new CustomDashboardsRepository();
const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const dashboardService = new CustomDashboardService();
const appState = getModule(AppStateStore);

@Component({
  components: { DashboardRow, Draggable }
})
export default class DashboardControls extends Mixins(LocaleMixin, BasicValidationMixin) {
    private loadingKey = 'dashboardControls';
    private isOpen = false;
    private isValid = false;
    private dashboardObjects: Array<DashboardUiObject> = [];
    private standardElements: Array<DashElementType> = [];
    private allElements: Array<DashboardElementOption> = [];
    private toBeDeleted: Array<number> = [];
    private newDashCounter = 0;
    private clickEvent = EventTypes.CLICKED;
    private deleteEvent = EventTypes.DELETED;

    get userId() {
        return authState.userInfoObject?.id ?? 0;
    }

    async created() {
        await this.reset();
    }

    @Watch('isOpen')
    async openToggled() {
        if (this.isOpen) {
            await this.reset();
        }
    }

    addDash() {
        this.newDashCounter++;
        this.dashboardObjects.push(dashboardService.newDash(this.newDashCounter));
    }

    close() {
        this.isOpen = false;
    }

    defaultSelected(dashId: number) {
        for (const dash of this.dashboardObjects) {
            if (dash.id !== dashId) {
                dash.is_default = false;
            }
        }
    }

    deleteDash(index: number) {
        this.toBeDeleted.push(this.dashboardObjects[index].id);
        this.dashboardObjects.splice(index, 1);
    }

    async reset() {
        loadingState.loadingIncrement(this.loadingKey);
        await dashboardsState.init();
        this.dashboardObjects = dashboardService.transformToUi(dashboardsState.stored);
        this.standardElements = await dashboardsRepo.getAvailableElements();
        this.allElements = (await dashboardService.getAllAvailableElements(this.standardElements, this.userId));
        this.toBeDeleted = [];
        loadingState.loadingDecrement(this.loadingKey);
    }

    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const promises: Array<Promise<any>> = [];
        let order = 0;
        for (const dashObject of this.dashboardObjects) {
            const dto = dashboardService.uiToDto(dashObject, this.allElements, order);
            if (dashObject.id > 0) {
                promises.push(dashboardsRepo.updateDash(dashObject.id, dto));
            } else {
                promises.push(dashboardsRepo.createDash(dto));
            }
            order++;
        }
        for (const dashId of this.toBeDeleted) {
            if (dashId < 0) {
                continue;
            }
            promises.push(dashboardsRepo.deleteDash(dashId));
        }
        await Promise.all(promises);
        await dashboardsState.retrieveDashboards();
        loadingState.loadingDecrement(this.loadingKey);
        appState.incrementMenu();
        this.close();
    }
}
