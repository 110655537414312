























import type { GroupCommHistory, GroupHistoryRecipient } from '@/communications/messages/models/message';
import { CommunicationType } from '@/communications/messages/models/message';
import { GroupCommHistoryRepository } from '@/communications/messages/repositories/group-comm-history-repository';
import { getPagination } from '@/core/datatables-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableOptions } from '@/models/datatables';
import { ApiPagination } from '@/repositories/abstract-repository';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

const groupHistoryRepository = new GroupCommHistoryRepository();

@Component({
    components: {}
})
export default class GroupHistoryRecipients extends Mixins(LocaleMixin) {
    /**
     * The group history to show stats for.
     */
    @Prop({ required: true }) readonly history!: GroupCommHistory;

    // The number of results in the table
    private currentItemCount = 1;
    // If the table is loading or not
    private isLoading = true;
    // The pagination options for the table
    private options: DataTableOptions = { itemsPerPage: 25, page: 1 };
    // The families that were include in the group action
    private recipients: Array<GroupHistoryRecipient> = [];

    /**
     * The headers for the table.
     */
    private get headers(): Array<DataTableHeader> {
        const commColumn = this.history.type === CommunicationType.EMAIL
            ? { text: 'Email Address', value: 'email' }
            : { text: 'Phone', value: 'phone_number' };
        return [
            { text: 'Guardian', value: 'recipient_name' },
            commColumn,
            { text: 'Status', value: 'delivery_status' }
        ];
    }

    /**
     * Watch for changes on the datatable and reload the data.
     *
     * @param options
     */
    @Watch('options')
    private async onOptionsChange(options: DataTableOptions) {
        await this.getRecipients(getPagination(options));
    }

    private async created() {
        await this.getRecipients(getPagination(this.options));
    }

    /**
     * Get the recipients to show in the table.
     *
     * @param pagination
     */
    private async getRecipients(pagination: ApiPagination = { limit: 25, offset: 0 }) {
        this.isLoading = true;
        const data = await groupHistoryRepository.getGroupCommHistoryRecipients(this.history.id, this.history.type, pagination);
        this.recipients = data.entities;
        this.currentItemCount = Number(data.count);
        this.isLoading = false;
    }

    /**
     * View a family member by going to the family hub.
     *
     * @param recipient
     */
    private viewFamily(recipient: GroupHistoryRecipient) {
        this.$router.push({ name: 'family-hub', params: { id: recipient.family_id.toString() } });
    }
}
