// The assignment flow that goes to the rep
export const ENROLLMENT_REP_ASSIGNMENT_FLOW_REP = 9100;

// The assignment flow that goes to the location
export const ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION = 9101;

// The assignment flow that goes to the enrollment team
export const ENROLLMENT_REP_ASSIGNMENT_FLOW_TEAM = 9102;

export const ASSIGNMENT_FLOW_LABELS = {
    ENROLLMENT_REP_ASSIGNMENT_FLOW_REP: 'The rep who added the lead',
    ENROLLMENT_REP_ASSIGNMENT_FLOW_LOCATION: 'The rep for the location',
    ENROLLMENT_REP_ASSIGNMENT_FLOW_TEAM: 'The enrollment team'
};
