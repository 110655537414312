import {
    LandingPageFieldDto,
    LandingPageFields,
    LandingPageFieldsDto
} from '@/automation/landing-pages/models/landing-page-fields';

export function landingPageFieldsDtoMapper(fields: LandingPageFields): LandingPageFieldsDto {
    const dto: LandingPageFieldsDto = [];
    let order = 0;
    for (const field of fields) {
        order++;
        const fieldDto: LandingPageFieldDto = {
            id: field.id > 0 ? field.id : null,
            type: field.type.id,
            label: field.label,
            is_used: field.is_used,
            is_required: field.is_required,
            order
        };
        dto.push(fieldDto);
    }
    return dto;
}
