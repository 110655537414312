


























































































import RecordingAudioModal from '@/communications/recordings/components/RecordingAudioModal.vue';
import { Recording } from '@/communications/recordings/models/recording-models';
import { RecordingUtils } from '@/communications/recordings/recording-utils';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import type { Family } from '@/families/models/family';
import { LoadingStore } from '@/store/loading-store';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AuthMixin } from '@/auth/auth-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import { EventTypes } from '@/constants/event-type-constants';

const loadingStore = getModule(LoadingStore);
const recordingsRepo = new RecordingsRepository();
const recordingUtils = new RecordingUtils();
const recordingsStore = getModule(RecordingsStore);

@Component({
    components: { RecordingAudioModal }
})
export default class FamilyActivityCalls extends Mixins(AuthMixin, CrmBreakpointsMixin, LocaleMixin) {
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ required: false }) readonly familyRecordings!: Array<Recording>;

    private loadingKey = 'FamilyActivityCalls';
    private recordings: Array<Recording> = [];
    private selectedRecordings: Array<Recording> = [];
    private showRecordingModal = false;

    /**
     * The headers for the table.
     */
    private get headers(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [];
        if (this.family.do_not_call) {
            headers.push({ text: '', value: 'no_call', width: '4ch' });
        }
        headers.push(
            { text: 'Direction', value: 'direction', width: '15ch' },
            { text: 'Caller ID', value: 'phone' },
            { text: 'Name', value: 'name' },
            { text: 'Call Placed', value: 'datetime' },
            { text: 'Duration', value: 'duration', width: '12ch' },
            { text: '', value: 'actions', width: '8ch' }
        );
        return headers;
    }

    /**
     * When a new family is loaded, reload the data.
     */
    @Watch('family.id')
    private async loadData() {
        loadingStore.loadingIncrement(this.loadingKey);
        this.recordings = await recordingsRepo.getRecordingsForFamily(this.family.id, true);
        loadingStore.loadingDecrement(this.loadingKey);
    }

    private async created() {
        if (this.familyRecordings) {
            this.recordings = this.familyRecordings;
        } else {
            await this.loadData();
        }
    }

    /**
     * Get the direction to display in the table.
     *
     * @param recording
     */
    getDirection(recording: Recording): string {
        return recording.is_incoming ? 'Inbound' : 'Outbound';
    }

    /**
     * Get the duration to display in the table.
     *
     * @param recording
     */
    getDuration(recording: Recording): string {
        return recordingUtils.getFormattedDuration(recording);
    }

    /**
     * Get the name to display in the table.
     *
     * @param recording
     */
    getName(recording: Recording): string {
        return recordingUtils.getPersonName(recording);
    }

    /**
     * Get the phone number to display in the table.
     *
     * @param recording
     */
    getPhone(recording: Recording): string {
        return recordingUtils.getPersonPhone(recording);
    }

    /**
     * Get the class to apply to the row
     *
     * @param recording
     */
    getRowClass(recording: Recording): string {
        return (recording.is_incoming && !recording.is_read) ? 'font-weight-bold' : '';
    }

    /**
     * Open the modal to listen to the recording.
     *
     * @param recording
     */
    private listenToRecording(recording: Recording) {
        this.selectedRecordings = [recording];
        this.showRecordingModal = true;
        this.markAsRead(recording);
    }

    /**
     * Mark a recording as read.
     *
     * @param recording
     */
    async markAsRead(recording: Recording) {
        if (!recording.is_read && recording.is_incoming) {
            await recordingsRepo.markAsRead(recording, true);
            recordingsStore.decrementInboxCount();
            recording.is_read = true;
            this.$emit(EventTypes.UPDATED);
        }
    }
}
