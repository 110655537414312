















































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventTypes } from '@/constants/event-type-constants';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import SelectListTemplateGroups from '@/automation/components/SelectListTemplateGroups.vue';
import {
    CrmTypeList,
    CrmTypeOption,
    CrmTypeOptionCreateDto,
    CrmTypeOptionUpdateDto,
    TemplateGroupItem
} from '@/crm-types/models/crm-type';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { updateTextEmailTemplateGroupItems } from '@/automation/template-group-utils';
import SaveButton from '@/components/base/SaveButton.vue';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const featureStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const typesRepo = new CrmTypesRepository();
const crmTypesStore = getModule(CrmTypesStore);
const staffUtils = new StaffUtils();

@Component({
    components: {
        BaseClose,
        SelectListTemplateGroups,
        SaveButton,
        SelectListEdit
    }
})
export default class AutomationSettings extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'automationSettingsPopup';
    private updatedEvent = EventTypes.UPDATED;

    // Type Items
    private emailTemplateGroups: Array<CrmTypeOption> = [];
    private textTemplateGroups: Array<CrmTypeOption> = [];
    private campaignTypes: Array<CrmTypeOption> = [];

    // Tracking updates
    private updatedEmailItems: Array<TemplateGroupItem> = [];
    private updatedTextItems: Array<TemplateGroupItem> = [];
    private updatedCampaignItems: Array<CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto> = [];

    // Tracking deletions
    private deletedEmailItems: Array<number> = [];
    private deletedTextItems: Array<number> = [];
    private deletedCampaignItems: Array<number> = [];

    // Panel indices
    private panelEmailIndex = -1;
    private panelTextIndex = -1;
    private panelCampaignIndex = -1;

    // Text and Email Templates
    private textTemplates: Array<MessageTemplate> = [];
    private emailTemplates: Array<MessageTemplate> = [];

    private isSaveDisabled = false;
    private hasAccessMarketingCampaign = false;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get hasMarketingCampaigns(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    get isCrmPlusMode() {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    async created() {
        await featureStore.init();
        this.hasAccessMarketingCampaign = await staffUtils.getUserPermission(PermissionName.AutomationViewMarketing);
    }

    @Watch('modelValue')
    async updateModalStatus() {
        if (this.modelValue) {
            this.panelEmailIndex = -1;
            this.panelTextIndex = -1;
            this.panelCampaignIndex = -1;
            loadingState.loadingIncrement(this.loadingKey);
            const emailTemplateGroupsResponse = crmTypesStore.retrieveList(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
            const textTemplateGroupsResponse = crmTypesStore.retrieveList(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
            const campaignTypesResponse = crmTypesStore.retrieveList(CrmTypeList.MARKTING_CAMPAIGNS);
            await emailTemplateGroupsResponse;
            await textTemplateGroupsResponse;
            await campaignTypesResponse;
            this.emailTemplateGroups = crmTypesStore.listOptions(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
            this.textTemplateGroups = crmTypesStore.listOptions(CrmTypeList.TEMPLATE_GROUPS_TEXT);
            this.campaignTypes = crmTypesStore.listOptions(CrmTypeList.MARKTING_CAMPAIGNS);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    // Updated event for Email Template Groups
    private async updateEmailGroups(updates: {deletedItems: Array<number>; updatedItems: Array<TemplateGroupItem>; saveDisabled: boolean}) {
        if (updates.saveDisabled) {
            this.isSaveDisabled = true;
        } else {
            this.isSaveDisabled = false;
            this.updatedEmailItems = updates.updatedItems;
            this.deletedEmailItems = updates.deletedItems;
        }
    }

    // Updated event for Text Template Groups
    private async updateTextGroups(updates: {deletedItems: Array<number>; updatedItems: Array<TemplateGroupItem>; saveDisabled: boolean}) {
        if (updates.saveDisabled) {
            this.isSaveDisabled = true;
        } else {
            this.isSaveDisabled = false;
            this.updatedTextItems = updates.updatedItems;
            this.deletedTextItems = updates.deletedItems;
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        const promises: Array<Promise<any>> = [];
        // Delete email template group(s)
        this.deletedEmailItems.forEach((id) => { promises.push(typesRepo.deleteListItem(id, CrmTypeList.TEMPLATE_GROUPS_EMAIL)); });

        // Delete text template group(s)
        this.deletedTextItems.forEach((id) => { promises.push(typesRepo.deleteListItem(id, CrmTypeList.TEMPLATE_GROUPS_TEXT)); });

        // At the end, fire off all the requests in parallel
        await Promise.all(promises);

        await updateTextEmailTemplateGroupItems(this.updatedEmailItems, this.updatedTextItems);
        await this.updateCampaignTypesItems(this.deletedCampaignItems, this.updatedCampaignItems);
        await crmTypesStore.retrieveAllList(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
        await crmTypesStore.retrieveAllList(CrmTypeList.TEMPLATE_GROUPS_TEXT);
        await crmTypesStore.retrieveAllList(CrmTypeList.MARKTING_CAMPAIGNS);
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }

    private async updateCampaignTypesItems(deletedItems: Array<number>, updatedItems: Array<CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto>) {
        let updatePromises = [];
        let id: number | undefined = 0;
        while (deletedItems.length > 0) {
            id = deletedItems.pop();
            if (id) {
                updatePromises.push(typesRepo.deleteListItem(id, CrmTypeList.MARKTING_CAMPAIGNS));
            }
        }

        // Wait until the deletions are done
        await Promise.all(updatePromises);
        updatePromises = [];

        for (const item of updatedItems) {
            if (item.id) {
                id = item.id;
                delete item.id;
                updatePromises.push(typesRepo.updateListItem(id, item, CrmTypeList.MARKTING_CAMPAIGNS));
            } else {
                delete item.id;
                updatePromises.push(typesRepo.addListItem(item, CrmTypeList.MARKTING_CAMPAIGNS));
            }
        }

        // Wait until the updates are done
        await Promise.all(updatePromises);
    }

    private setCampaignTypesUpdates(updates: {deletedItems: Array<number>; updatedItems: Array<CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto>}) {
        this.updatedCampaignItems = updates.updatedItems;
        this.deletedCampaignItems = updates.deletedItems;
        this.isSaveDisabled = this.updatedCampaignItems.filter(item => item.value.length === 0).length > 0;
    }

    private close() {
        this.modelValue = false;
    }
}
