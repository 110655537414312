import { CrmApiResponse } from '@/models/base';
import {
    CenterCallSettings, CenterCallSettingsDto,
    CenterProvisionNumberResponse
} from '@/organizations/locations/models/center-call-settings';
import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import {
    Center,
    CenterLink,
    CenterAgeRange,
    CenterFilters,
    CenterSortParameter,
    LocationGroupAndCentersEntity, CenterUpdates
} from '@/organizations/locations/models/center';
import { CenterDtoInterface } from '@/organizations/locations/models/center-dto';
import { LocationsMappingDto } from '@/integrations/models/integration';
import { Logo, LogoDto } from '@/logo/models/logo';

export class CentersRepository extends AbstractRepository<Center> {
    readonly endpoint = 'centers';

    public async getCenters(
        pagination: ApiPagination | null = null,
        sortParam: CenterSortParameter | null = null,
        queryParams: ApiParameters | null = null
    ): Promise<CrmApiResponse<Center>> {
        const params = {};
        // Merge all the params together so we get types on the parameters first
        Object.assign(params, queryParams, sortParam);
        return await super.get(pagination, params as ApiParameters);
    }

    public async getCount(): Promise<number> {
        const response = await this.client.get(this.endpoint, {
            params: {
                limit: 0
            }
        });
        return parseInt(response.headers['x-total-count']);
    }

    public async getFirstAccessible(): Promise<Center> {
        const response = await this.client.get(
            this.endpoint,
            {
                params: {
                    only_accessible_centers: 'true',
                    limit: 1,
                    simple: 'true'
                }
            });
        return response.data[0];
    }

    public async deleteLogo(id: number): Promise<void> {
        await this.client.delete<void>(`${this.endpoint}/${id}/logo`);
    }

    public async getLogo(id: number): Promise<Logo> {
        const response = await this.client.get<Logo>(`${this.endpoint}/${id}/logo`);
        return response.data;
    }

    public async uploadLogo(id: number, logoDto: LogoDto): Promise<Logo> {
        const response = await this.client.put<Logo>(`${this.endpoint}/${id}/logo`, logoDto);
        return response.data;
    }

    public async getOne(id: number | string, simple = false): Promise<Center> {
        const response = await this.client.get<Center>(`${this.endpoint}/${id}?simple=${simple ? 1 : 0}`);
        return response.data;
    }

    public async createCenter(center: CenterDtoInterface) {
        const response = await this.client.post(this.endpoint, center);
        return response.data;
    }

    public async updateCenter(id: number, center: CenterDtoInterface) {
        const response = await this.client.put(`${this.endpoint}/${id}`, center);
        return response.data;
    }

    public async updateLocationMappings(update: LocationsMappingDto) {
        await this.client.put(this.endpoint, update);
    }

    public async getCenterLinksForLocationGroup(locationGroupId: number): Promise<Array<CenterLink>> {
        const response = await this.client.get(`${this.endpoint}/groups/${locationGroupId}`);
        return response.data;
    }

    /**
     * Get all location groups and their centers.
     */
    public async getLocationGroupsAndCenters(): Promise<Array<LocationGroupAndCentersEntity>> {
        const response = await this.client.get(`${this.endpoint}/groups`);
        return response.data;
    }

    public async updateCentersForLocationGroup(locationGroupId: number, centerIds: Array<number>): Promise<Array<CenterLink>> {
        const response = await this.client.put(`${this.endpoint}/groups/${locationGroupId}`, { centers: centerIds });
        return response.data;
    }

    public async getAgeRange(): Promise<CenterAgeRange> {
        const response = await this.client.get<CenterAgeRange>(`${this.endpoint}/age-range`);
        return response.data;
    }

    public async filterCenters(filters: CenterFilters): Promise<Array<number>> {
        const params: ApiParameters = {
            ids_only: true
        };
        Object.assign(params, filters);
        const response = await this.client.get<Array<number>>(this.endpoint, { params: params });
        return response.data;
    }

    public async getCenterLastUpdated(centerId: number): Promise<CenterUpdates> {
        const response = await this.client.get(this.endpoint + `/${centerId}/updates`);
        return response.data;
    }

    /**
     * Send a request to provision a phone number for a center.
     *
     * @param centerId
     */
    public async provisionCenterNumber(centerId: number): Promise<CenterProvisionNumberResponse> {
        const response = await this.client.post(`${this.endpoint}/${centerId}/telephony/number`);
        return response.data;
    }

    /**
     * Get the center settings for calls.
     *
     * @param centerId
     */
    public async getCenterCallSettings(centerId: number): Promise<CenterCallSettings> {
        const response = await this.client.get(`${this.endpoint}/${centerId}/settings`);
        return response.data;
    }

    /**
     * Set the center settings for calls.
     *
     * @param centerId
     * @param dto
     */
    public async setCenterCallSettings(centerId: number, dto: CenterCallSettingsDto): Promise<CenterCallSettings> {
        const response = await this.client.put(`${this.endpoint}/${centerId}/settings`, dto);
        return response.data;
    }
}
