

















































import { getDateString } from '@/date-time/date-time-utils';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);

@Component
export default class MpDashboardBanner extends Mixins(LocaleMixin) {
    get dateString(): string {
        return getDateString(new Date(), authState);
    }
}
