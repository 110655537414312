



























































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { SavedReport } from '../models/saved-report';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { ReportSchedule, ReportScheduleDto } from '@/reports/models/report-schedule';
import { ReportSchedulesRepository } from '@/reports/repositories/report-schedules-repository';
import {
    formatDateWithTimezone,
    formatIsoDateTime,
    isoFormatLong
} from '@/date-time/date-time-utils';
import { StaffStore } from '@/staff/store/staff-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { User } from '@/staff/models/user';
import { scheduleTimeUnits } from '@/reports/report-utils';
import { addMinutes } from 'date-fns';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import { SavedReportMapper } from '@/reports/mappers/saved-report-mapper';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const reportSchedulesRepo = new ReportSchedulesRepository();
const staffState = getModule(StaffStore);
const authState = getModule(AuthStore, store);
const reportsStore = getModule(SavedReportsStore);
const savedReportMapper = new SavedReportMapper();
const savedReportsRepo = new SavedReportsRepository();
@Component({
    components: { BaseClose }
})
export default class ReportScheduleModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // Props
    // v-model whether we should show it.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly savedReport!: SavedReport;
    @Prop({ required: true }) readonly userId!: number;

    private loadingKey = 'reportScheduleModal';
    private validForm = false;
    private startDate = '';
    private startTime = '';
    private emailTos: Array<User> = [];
    private scheduleDto = new ReportScheduleDto();
    private timeUnits = scheduleTimeUnits;
    private schedule: ReportSchedule | null = null;
    private savedReportId = 0;

    // Getters
    get allStaff(): Array<User> {
        return staffState.storedAllStaff.filter((user) => {
            return !user.is_service_account && user.is_active;
        });
    }

    get alreadyRun() {
        return this.schedule !== null && this.schedule.last_run_datetime !== null;
    }

    get savedReports() {
        return reportsStore.stored;
    }

    get modelValue(): boolean {
        return this.value;
    }

    // Setters
    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    // lifecycle
    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        this.savedReportId = this.savedReport.id;
        if (this.savedReport.is_standard_report) {
            // when we're dealing with a standard report, we have to create a stub saved report on the user
            // to facilitate scheduling the report. first check and see if we've already created the stub.
            const existingStubs = this.savedReports.filter((report) => {
                return report.is_standard_report && report.standard_report_identifier === this.savedReport.standard_report_identifier;
            });
            if (existingStubs.length > 0) {
                this.savedReportId = existingStubs[0].id;
            } else {
                const reportStubDto = savedReportMapper.toCreateDto(this.savedReport);
                reportStubDto.is_standard_report = true;
                reportStubDto.standard_report_identifier = this.savedReport.standard_report_identifier;
                const reportStub = await savedReportsRepo.createReport(this.userId, reportStubDto);
                this.savedReportId = reportStub.id;
                await reportsStore.retrieveAllReports(this.userId);
            }
        }
        const currentSchedules = await reportSchedulesRepo.getReportSchedules(this.userId, this.savedReportId);
        let isoTz = '';
        if (currentSchedules.length > 0) {
            this.schedule = currentSchedules[0];
            this.scheduleDto.time_amount = this.schedule.time_amount;
            this.scheduleDto.time_unit = this.schedule.time_unit.id;
            isoTz = formatDateWithTimezone(this.schedule.start_datetime, authState.userTimeZone, isoFormatLong);
            this.emailTos = this.allStaff.filter((user) => {
                return this.schedule!.email_to.map((link) => {
                    return link.id;
                }).includes(user.id);
            });
        } else {
            isoTz = formatDateWithTimezone(addMinutes(new Date(), 5), authState.userTimeZone);
            this.emailTos = this.allStaff.filter((user) => {
                return user.id === this.userId;
            });
        }
        this.startDate = isoTz.substr(0, 10);
        this.startTime = isoTz.substr(11, 5);
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async saveSchedule() {
        loadingState.loadingIncrement(this.loadingKey);
        const fullTimeTz = this.startDate + ' ' + this.startTime;
        this.scheduleDto.start_datetime = formatIsoDateTime(fullTimeTz, authState.userTimeZone);
        if (this.scheduleDto.start_datetime < formatIsoDateTime(new Date())) {
            await this.$swal({
                icon: 'warning',
                text: 'Delivery must start in the future'
            });
            loadingState.loadingDecrement(this.loadingKey);
            return;
        }
        this.scheduleDto.email_to = this.emailTos.map((user) => {
            return user.id;
        });
        if (this.schedule) {
            if (this.alreadyRun) {
                this.scheduleDto.start_datetime = this.schedule.start_datetime;
            }
            await reportSchedulesRepo.updateReportSchedule(this.userId, this.savedReportId, this.schedule.id, this.scheduleDto);
            this.$emit(EventTypes.REPORT_SCHEDULE_UPDATED);
        } else {
            await reportSchedulesRepo.createReportSchedule(this.userId, this.savedReportId, this.scheduleDto);
            this.$emit(EventTypes.REPORT_SCHEDULE_ADDED);
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }

    async deleteSchedule() {
        if (this.schedule) {
            loadingState.loadingIncrement(this.loadingKey);
            await reportSchedulesRepo.deleteReportSchedule(this.userId, this.savedReportId, this.schedule.id);
            this.$emit(EventTypes.REPORT_SCHEDULE_DELETED);
            this.close();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private close() {
        this.modelValue = false;
    }

    private staffName(staff: User) {
        return `${staff.first_name} ${staff.last_name} (${staff.org ? staff.org.values.name : ''})`;
    }
}
