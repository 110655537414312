import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';

/**
 * standard show/hide v-model handling for a component
 */
@Component
export class VmodelShowMixin extends Vue {
    @Prop({ default: false }) readonly value!: boolean;

    // handle v-model
    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }
}
