











































































import { Component, Mixins, Watch, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import BannerGreetingCorporateUser from '@/dashboards/components/BannerGreetingCorporateUser.vue';
import ToursCompletedGaugeGraph from '@/dashboards/components/ToursCompletedGaugeGraph.vue';
import TasksPastDueGaugeGraph from '@/dashboards/components/TasksPastDueGaugeGraph.vue';
import GoodLeadsGaugeGraph from '@/dashboards/components/GoodLeadsGaugeGraph.vue';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { BannerMessages } from '../banner-messages';
import MpDashboardBanner from '@/dashboards/components/MpDashboardBanner.vue';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { InterfaceSettingsStore } from '../store/interface-settings-store';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts from 'highcharts';
import { SettingNames } from '../models/interface-settings-models';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);
const appState = getModule(AppStateStore);
const settingsStore = getModule(InterfaceSettingsStore);
@Component({
    components: { MpDashboardBanner, GoodLeadsGaugeGraph, TasksPastDueGaugeGraph, ToursCompletedGaugeGraph, BannerGreetingCorporateUser }
})
export default class CorpGreeting extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    @Prop({ default: false }) readonly custDashMode!: boolean;
    private orgIds: number[] = [];
    private loadingKey = 'corporateDashboardGreeting';
    private revenue = 0;
    private loaded = false;
    private salutation = '';
    private tasksPastDueRate = 0;
    private conversionSuccessPercent = 0;
    private tourCompletedSpeedPercent = 0;
    private closing = '';

    get org() {
        return appState.storedCurrentOrg;
    }

    get hasWhiteLabel(): boolean {
        return settingsStore.hasWhiteLabel;
    }

    get pipelineValueSettingsShow() {
        return Boolean(settingsStore.settingByName(SettingNames.PIPELINE_VALUE) ?? false);
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        if (this.org) {
            await this.loadData();
        }

        this.salutation = BannerMessages.getGreeting();
        this.closing = BannerMessages.getClosing();

        loadingState.loadingDecrement(this.loadingKey);
        this.loaded = true;
    }

    async mounted() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore-next-line
        Highcharts.wrap(Highcharts.Renderer.prototype, 'label', function(proceed: any, str: any, x: any, y: any, shape: any, anchorX: any, anchorY: any, useHTML: boolean) {
            if (/class="fa/.test(str)) {
                useHTML = true;
            }

            // Run original proceed method
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore-next-line
            return proceed.call(this, str, x, y, shape, anchorX, anchorY, useHTML);
        });
    }

    @Watch('org')
    private async loadData() {
        if (this.org) {
            if (!loadingState.isLoading(this.loadingKey)) {
                loadingState.loadingIncrement(this.loadingKey);
                this.loaded = false;
            }

            this.orgIds = [this.org.id];
            await settingsStore.init();
            if (this.pipelineValueSettingsShow) {
                await statsState.initEstimatedRevenueCounts({
                    org_ids: this.orgIds
                });
                this.revenue = statsState.estimatedRevenueCounts[0].counts[0].total;
            }

            this.loaded = true;

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('pipelineValueSettingsShow')
    async pipelineSettingChanged() {
        if (this.pipelineValueSettingsShow) {
            await this.loadData();
        }
    }

    private updateToursCompletedRate(rate: number) {
        this.tourCompletedSpeedPercent = rate;
    }

    private updateTasksPastDueRate(rate: number) {
        this.tasksPastDueRate = rate;
    }

    private updateConversionRate(rate: number) {
        this.conversionSuccessPercent = rate;
    }
}

