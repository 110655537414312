import { DateFilter } from '@/models/date-filter';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import { BaseStatuses } from '@/constants/status-constants';
import { ApiParameters } from '@/repositories/abstract-repository';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';

async function getTourCompletes(dateRange: DateFilter, org: number, within1Day: boolean): Promise<number> {
    const statsRepo = new PeriodStatisticsRepository();
    const params: ApiParameters = {
        transitions_only: 'true',
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        status_ids: [BaseStatuses.TOUR_COMPLETED],
        org_ids: [org]
    };
    if (within1Day) {
        params.status_ids = [BaseStatuses.NEW_LEAD, BaseStatuses.TOUR_COMPLETED];
        params.speed = 24;
    }
    const stats = await statsRepo.query('statuses', params);
    if (!stats) {
        return 0;
    }

    return stats.periods[0].organizations[0].counts[0].total ?? 0;
}

export async function get1DayTourCompletesPct(dateRange: DateFilter, org: number): Promise<number> {
    const speedyTourCompletes = await getTourCompletes(dateRange, org, true);
    const allTourCompletes = await getTourCompletes(dateRange, org, false);
    return allTourCompletes ? 100.0 * speedyTourCompletes / allTourCompletes : 0;
}

async function getLeadsCount(dateRange: DateFilter, org: number, statuses: Array<BaseStatuses>): Promise<number> {
    const statsRepo = new PeriodStatisticsRepository();
    const params: ApiParameters = {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        status_ids: statuses,
        org_ids: [org]
    };
    const stats = await statsRepo.query('statuses', params);
    if (!stats) {
        return 0;
    }

    let total = 0;
    if (stats.periods[0].organizations[0].counts) {
        for (const count of stats.periods[0].organizations[0].counts) {
            total += count.total;
        }
    }
    return total;
}

export async function getGoodLeadsPct(dateRange: DateFilter, org: number, includeLostOpp = true): Promise<number> {
    const goodLeadsCount = await getLeadsCount(dateRange, org, [BaseStatuses.WAIT_LIST, BaseStatuses.REGISTERED]);
    if (!includeLostOpp) {
        return goodLeadsCount === 0 ? 0 : 100;
    }
    const goodBadLeadsCount = await getLeadsCount(dateRange, org, [BaseStatuses.WAIT_LIST, BaseStatuses.REGISTERED, BaseStatuses.LOST_OPP]);
    return goodBadLeadsCount ? 100.0 * goodLeadsCount / goodBadLeadsCount : 0;
}

async function getConversionRate(dateRange: DateFilter, org: number, statuses: Array<BaseStatuses>, groupByCenter = false): Promise<Array<number>> {
    const statsRepo = new PeriodStatisticsRepository();
    const params: ApiParameters = {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
        from_status: statuses[0],
        to_status: statuses[1],
        org_ids: [org],
        group_by_center: groupByCenter
    };

    if (dateRange.periodType && dateRange.periodLength && dateRange.periodLength > 0) {
        params.period_type = dateRange.periodType;
        params.period_length = dateRange.periodLength;
    }

    const stats = await statsRepo.query('conversions', params);
    if (!stats) {
        return [0, 0];
    }

    const percentages = [];
    let fromStatusCnt = 0;
    let toStatusCnt = 0;

    // Loop through the "orgs", since technically we could have many centers in the results.
    if (stats.periods && stats.periods.length) {
        stats.periods[0].organizations.forEach((orgCounts) => {
            // Get the "from" and "to" status counts.
            fromStatusCnt += orgCounts.counts[0].total;
            toStatusCnt += orgCounts.counts[1].total;
        });
    }

    percentages.push(fromStatusCnt ? 100.0 * toStatusCnt / fromStatusCnt : 0);

    fromStatusCnt = 0;
    toStatusCnt = 0;

    // Loop through the "orgs", since technically we could have many centers in the results.
    if (stats.periods && stats.periods.length) {
        stats.periods[1].organizations.forEach((orgCounts) => {
            // Get the "from" and "to" status counts.
            fromStatusCnt += orgCounts.counts[0].total;
            toStatusCnt += orgCounts.counts[1].total;
        });
    }

    percentages.push(fromStatusCnt ? 100.0 * toStatusCnt / fromStatusCnt : 0);

    // Return the percentages.
    return percentages;
}

export async function getNewFamiliesToScheduled(dateRange: DateFilter, org: number): Promise<Array<number>> {
    return await getConversionRate(dateRange, org, [BaseStatuses.NEW_LEAD, BaseStatuses.TOUR_SCHEDULED]);
}

export async function getNewFamiliesToCompleted(dateRange: DateFilter, org: number): Promise<Array<number>> {
    return await getConversionRate(dateRange, org, [BaseStatuses.NEW_LEAD, BaseStatuses.TOUR_COMPLETED]);
}

export async function getScheduledToCompleted(dateRange: DateFilter, org: number): Promise<Array<number>> {
    return await getConversionRate(dateRange, org, [BaseStatuses.TOUR_SCHEDULED, BaseStatuses.TOUR_COMPLETED]);
}

export async function getRegisteredAfterCompletion(dateRange: DateFilter, org: number): Promise<Array<number>> {
    return await getConversionRate(dateRange, org, [BaseStatuses.TOUR_COMPLETED, BaseStatuses.REGISTERED]);
}

async function getPastDueTasksCount(dateRange: DateFilter, orgId: number): Promise<number> {
    const taskRepo = new TasksRepository();
    return await taskRepo.getPastDue(dateRange.endDate, orgId);
}

async function getIncompleteTasksCount(dateRange: DateFilter, org: number, timezone: string, onlyPastDue: boolean): Promise<number> {
    const statsRepo = new PeriodStatisticsRepository();
    const params: ApiParameters = {
        org_ids: [org],
        due_date_end: dateRange.endDate
    };

    if (onlyPastDue) {
        params.past_due_mode = 1;
    } else {
        params.incomplete_mode = 1;
    }

    const stats = await statsRepo.query('tasks', params);
    if (!stats) {
        return 0;
    }

    let total = 0;

    if (stats.periods[0].organizations[0].counts) {
        for (const count of stats.periods[0].organizations[0].counts) {
            total += count.total;
        }
    }

    return total;
}

export async function getTasksPastDuePct(dateRange: DateFilter, org: number, timezone: string): Promise<number> {
    // const pastDueCount = await getIncompleteTasksCount(dateRange, org, timezone, true);
    const pastDueCount = await getPastDueTasksCount(dateRange, org);
    const allCount = await getIncompleteTasksCount(dateRange, org, timezone, false);

    return allCount ? 100.0 * pastDueCount / allCount : 0;
}
