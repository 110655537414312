var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"text-h6 mb-2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-phone-classic ")]),_vm._v(" Work Dispositions ")],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inContactEvents},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell date"},[_vm._v(" "+_vm._s(_vm.formatEventDate(item.datetime))+" ")])]}},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell staff"},[_vm._v(" "+_vm._s(item.staff ? ((item.staff.values.last_name) + ", " + (item.staff.values.first_name)) : '')+" ")])]}},{key:"item.skill",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell skill"},[_vm._v(" "+_vm._s(item.skill)+" ")])]}},{key:"item.media_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell media-type"},[_vm._v(" "+_vm._s(item.media_type)+" ")])]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell direction"},[_vm._v(" "+_vm._s(item.direction)+" ")])]}},{key:"item.disposition",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data-cell disposition"},[_vm._v(" "+_vm._s(item.disposition)+" ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('div',{staticClass:"data-cell link caption"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(" Link ")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }