

































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type {
    DashboardElementUiObject,
    DashboardUiObject,
    DashElementType
} from '@/dashboards/models/custom-dashboards';
import {
    customDashboardType,
    DashboardElementOption, DashConstants, DashElementIdentifier
} from '@/dashboards/models/custom-dashboards';
import { EventTypes } from '@/constants/event-type-constants';
import Draggable from 'vuedraggable';

@Component({ components: { Draggable } })
export default class DashboardRow extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: DashboardUiObject;
    @Prop() readonly elementOptions!: Array<DashboardElementOption>;
    @Prop() readonly standardElements!: Array<DashElementType>;
    private isOpen = false;

    $refs!: {
        name: HTMLInputElement;
    };

    get dash(): DashboardUiObject {
        return this.value;
    }

    set dash(dash: DashboardUiObject) {
        this.$emit('input', dash);
    }

    get requiredChecked() {
        return [
            this.dash.actionTabs.length > 0 || 'At least one tab must be selected'
        ];
    }

    get actionTabOptions() {
        return this.standardElements.filter(elem => DashConstants.actionDashTabs.includes(elem.identifier as DashElementIdentifier));
    }

    get isCustom() {
        return this.dash.type === customDashboardType;
    }

    get isValid() {
        if (!this.dash.name) {
            return false;
        }
        for (const element of this.dash.elements) {
            if (!element.identifier) {
                return false;
            }
            if (element.identifier === DashElementIdentifier.ACTION_TABS_PLACEHOLDER && this.dash.actionTabs.length === 0) {
                return false;
            }
        }
        return true;
    }

    async mounted() {
        if (!this.isValid) {
            this.isOpen = true;
            await this.$nextTick();
            this.$refs.name.focus();
            this.$refs.name.scrollIntoView({ });
        }
    }

    addElement(index: number) {
        this.dash.elements.splice(index + 1, 0, { identifier: '' });
    }

    defaultSelected() {
        this.$emit(EventTypes.CLICKED, this.dash.id);
    }

    async deleteDash() {
        const confirm = await this.$swal({
            icon: 'warning',
            text: 'Are you sure you want to delete this custom dashboard?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel'
        });
        if (confirm.isConfirmed) {
          this.$emit(EventTypes.DELETED);
        }
    }

    deleteElement(index: number) {
        this.dash.elements.splice(index, 1);
    }

    filterOptions(options: Array<DashboardElementOption>, index: number) {
        const alreadyChosen = this.dash.elements.map(elem => elem.identifier);
        return options.filter((option) => !alreadyChosen.includes(option.id) || option.id === this.dash.elements[index].identifier);
    }

    formatActionTab(label: string) {
        return label.replace(/^Dashboard > Action > (.*) tab$/, '$1');
    }

    isActionTabs(element: DashboardElementUiObject) {
        return element.identifier === DashElementIdentifier.ACTION_TABS_PLACEHOLDER;
    }

}
