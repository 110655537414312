import { Reminder } from '@/communications/reminders/models/reminder-models';
import { EmailRemindersRepository } from '@/communications/reminders/repositories/email-reminders-repository';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

export interface EmailRemindersState extends AbstractEntityState<Reminder> {
    entities: Array<Reminder>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.EMAIL_REMINDERS
})
export class EmailRemindersStore extends AbstractApiStore<Reminder> implements EmailRemindersState {
    readonly repository = new EmailRemindersRepository();

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.retrieveAll();
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Reminder> {
        return this.entities;
    }
}
