import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { OrganizationLevel } from '@/organizations/levels/models/org-level-models';
import { OrgLevelDtoInterface } from '@/organizations/levels/models/org-level-dto';

export class OrgLevelMapper implements AbstractMapper<OrganizationLevel> {
    toCreateDto(entity: OrganizationLevel): OrgLevelDtoInterface {
        return {
            name: entity.name,
            order: entity.order
        };
    }

    toUpdateDto(entity: OrganizationLevel): OrgLevelDtoInterface {
        return this.toCreateDto(entity);
    }
}
