import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore } from '@/store/abstract-api-store';
import type { Center, CenterAgeRange } from '@/organizations/locations/models/center';
import { LocationGroup } from '@/organizations/locations/models/center';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import store from '@/store';
import { CenterDtoInterface } from '@/organizations/locations/models/center-dto';

interface CentersState {
    accessibleCenters: Array<Center>;
    entities: Array<Center>;
    mappedEntities: Map<number, Center>;
    mappedAccessibleCenters: Map<number, Center>;
    count: number;
    inactiveCount: number | null;
    ageRange: CenterAgeRange | undefined;
    locationGroups: Array<LocationGroup>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CENTERS
})
export class CentersStore extends AbstractApiStore<Center> implements CentersState {
    readonly repository = new CentersRepository();
    ageRange: CenterAgeRange | undefined = undefined;
    accessibleCenters: Array<Center> = [];
    accessibleCentersWithInactive: Array<Center> = [];
    referrableCenters: Array<Center> = [];
    inactiveCenters: Array<Center> = [];
    entities: Array<Center> = [];
    mappedEntities: Map<number, Center> = new Map();
    mappedAccessibleCenters: Map<number, Center> = new Map();
    count = 0;
    inactiveCount: number | null = null;
    locationGroups: Array<LocationGroup> = [];

    @Action
    public async init(rebuild = false) {
        if (this.entities.length === 0 || rebuild) {
            await this.initPromise({
                hash: 'allCenters',
                closure: async () => {
                    await this.retrieveAllCenters();
                },
                rebuild
            });
        }
    }

    @Action
    public async initAgeRange() {
        if (!this.ageRange) {
            await this.initPromise({
                hash: 'ageRange',
                closure: async () => {
                    await this.retrieveAgeRange();
                }
            });
        }
    }

    @Action
    public async initCount() {
        if (this.count === 0) {
            await this.initPromise({
                hash: 'count',
                closure: async () => {
                    await this.retrieveCount();
                }
            });
        }
    }

    @Action
    public async initInActiveCount() {
        if (this.inactiveCount === null) {
            await this.initPromise({
                hash: 'inactiveCount',
                closure: async () => {
                    await this.retrieveInactiveCount();
                }
            });
        }
    }

    // Initialize the centers the user has access to
    @Action
    public async initAccessibleCenters() {
        if (this.accessibleCenters.length === 0) {
            await this.initPromise({
                hash: 'accessible',
                closure: async () => {
                    await this.retrieveAccessibleCenters();
                }
            });
        }
    }

    /**
     * @param{boolean} rebuild
     */
    @Action
    public async initAccessibleCentersWithInactive(rebuild = false) {
        if (this.accessibleCentersWithInactive.length === 0) {
            await this.initPromise({
                hash: 'accessibleWithInactive',
                closure: async () => {
                    await this.retrieveAccessibleCentersWithInactive();
                },
                rebuild: rebuild
            });
        }
    }

    /**
     * @param{boolean} rebuild
     */
    @Action
    public async initInactiveCenters(rebuild = false) {
        if (this.inactiveCenters.length === 0) {
            await this.initPromise({
                hash: 'inactive',
                closure: async () => {
                    await this.retrieveInactiveCenters();
                },
                rebuild: rebuild
            });
        }
    }

    /**
     * @param{boolean} rebuild
     */
    @Action
    public async initReferrableCenters(rebuild = false) {
        if (this.referrableCenters.length === 0) {
            await this.initPromise({
                hash: 'referrable',
                closure: async () => {
                    await this.retrieveReferrableCenters();
                },
                rebuild: rebuild
            });
        }
    }

    /**
     * @param{boolean} rebuild
     */
    @Action
    public async initLocationGroups(rebuild = false) {
        if (this.locationGroups.length === 0) {
            await this.initPromise({
                hash: 'locationGroups',
                closure: async () => {
                    await this.retrieveLocationGroups();
                },
                rebuild: rebuild
            });
        }
    }

    // Fetch the centers the user has access to
    @Action({ commit: 'storeAccessibleCenters' })
    public async retrieveAccessibleCenters() {
        return await this.retrieveAll({ only_accessible_centers: true, simple: true });
    }

    // Fetch the centers the user has access to
    @Action({ commit: 'storeAccessibleCentersWithInactive' })
    public async retrieveAccessibleCentersWithInactive() {
        return await this.retrieveAll({ only_accessible_centers: true, include_inactive: true, simple: true });
    }

    @Action({ commit: 'storeInactiveCenters' })
    public async retrieveInactiveCenters() {
        return await this.retrieveAll({ inactive_only: true, simple: true });
    }

    // Fetch the centers that can be referred to
    @Action({ commit: 'storeReferrableCenters' })
    public async retrieveReferrableCenters() {
        return await this.retrieveAll({ is_referrable: true, simple: true });
    }

    @Action({ commit: 'storeLocationGroups' })
    public async retrieveLocationGroups() {
        return await this.repository.getLocationGroupsAndCenters();
    }

    @Action
    public async retrieveAllCenters() {
        await this.retrieveAll({ include_inactive: 'true' });
    }

    @Action({ commit: 'storeAgeRange' })
    public async retrieveAgeRange() {
        return await this.repository.getAgeRange();
    }

    @Action({ commit: 'storeCount' })
    public async retrieveCount() {
        return await this.repository.getCount();
    }

    @Action({ commit: 'storeInactiveCount' })
    public async retrieveInactiveCount() {
        const response = await this.repository.filterCenters({ inactive_only: true });
        return response.length;
    }

    @Mutation
    private storeCount(count: number): void {
        this.count = count;
    }

    @Mutation
    private storeInactiveCount(count: number): void {
        this.inactiveCount = count;
    }

    // Get simple one from the store, by its identifier.
    @Action({ rawError: true })
    public async getAccessibleCenterById(id: number, forceRefresh = false): Promise<Center> {
        if (!forceRefresh && this.mappedAccessibleCenters.has(id as number)) {
            return this.mappedAccessibleCenters.get(id as number) as Center;
        }

        return await this.repository.getOne(id as number);
    }

    @Action({ commit: 'addOrUpdateEntity' })
    public async updateCenter(update: { centerId: number; dto: CenterDtoInterface }) {
        const updated = await this.repository.updateCenter(update.centerId, update.dto);
        this.context.commit('updateAccessibleCenter', updated);
        return updated;
    }

    // Store the centers the user has access to
    @Mutation
    private storeAccessibleCenters(centers: Array<Center>): void {
        this.accessibleCenters = centers;
    }

    @Mutation
    private storeAccessibleCentersWithInactive(centers: Array<Center>): void {
        this.accessibleCentersWithInactive = centers;
    }

    @Mutation
    private storeInactiveCenters(centers: Array<Center>): void {
        this.inactiveCenters = centers;
    }

    @Mutation
    private storeReferrableCenters(centers: Array<Center>): void {
        this.referrableCenters = centers;
    }

    @Mutation
    private storeAgeRange(ageRange: CenterAgeRange): void {
        this.ageRange = ageRange;
    }

    @Mutation
    private storeLocationGroups(locationGroups: Array<LocationGroup>): void {
        this.locationGroups = locationGroups;
    }

    @Mutation
    private updateAccessibleCenter(center: Center): void {
        this.accessibleCenters = this.accessibleCenters.map((obj) => {
            return obj.id === center.id ? center : obj;
        });
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Center> {
        return this.entities.filter((center) => {
            return center.is_active;
        });
    }

    // Get the centers the user has access to
    public get storedAccessibleCenters(): Array<Center> {
        return this.accessibleCenters;
    }

    public get storedAccessibleCentersWithInactive(): Array<Center> {
        return this.accessibleCentersWithInactive;
    }

    // Get the center age range
    public get storedAgeRageForCenter(): CenterAgeRange | undefined {
        return this.ageRange;
    }

    public get storedAll(): Array<Center> {
        return this.entities;
    }

    public get storedReferrableCenters(): Array<Center> {
        return this.referrableCenters;
    }

    public get storedLocationGroups(): Array<LocationGroup> {
        return this.locationGroups;
    }

    public get inactiveCentersCount(): number {
        return this.inactiveCount ?? 0;
    }

    @Mutation
    public storeAll(centers: Array<Center>): void {
        this.entities = centers;
        // Reset the map.
        this.mappedEntities.clear();

        centers.forEach((center: Center) => {
            if (center.id) {
                this.mappedEntities.set(center.id, center);
            }
        });
    }
}
