










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { VariableTagsStore } from '@/communications/templates/store/variable-tags-store';
import {
    getGroupLabel,
    getTagLabel,
    getTagValue,
    groupTemplateVariables
} from '@/communications/templates/template-variables-util';

const variableTagsState = getModule(VariableTagsStore);

@Component
export default class VariableTagSelect extends Mixins(LocaleMixin) {
    @Prop() isOpen!: boolean;
    @Prop() value!: string;
    @Prop({ default: false }) readonly linkMode!: boolean;

    private items: Array<{text?: string; value?: string; header?: string }> = [];

    get mergeTag() {
        return this.value;
    }

    set mergeTag(tag: string) {
        this.$emit(EventTypes.INPUT, tag);
    }

    async mounted() {
        await variableTagsState.init();
        this.items.splice(0);
        const groupedTags = groupTemplateVariables(variableTagsState.stored, this.linkMode);
        for (const [group, tags] of groupedTags) {
            this.items.push({ header: getGroupLabel(group) });
            for (const tag of tags) {
                this.items.push({ text: getTagLabel(tag), value: getTagValue(tag) });
            }
        }
    }

    cancel() {
        this.mergeTag = '';
        this.$emit(EventTypes.CLOSE);
    }

    insert() {
        this.$emit(EventTypes.CLOSE);
    }
}
