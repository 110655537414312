










import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import CustomReports from '@/reports/custom/CustomReports.vue';

@Component({
    components: { CustomReports }
})
export default class DashReportWrapper extends Mixins(LocaleMixin) {
@Prop() readonly id!: number | undefined;
@Prop() readonly standardReportIdentifier!: string | undefined;

}
