




























import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts, { XAxisOptions } from 'highcharts';
import {
    countPeriodsToLineGraphSeries,
    countPeriodsToXAxis
} from '@/charts/chart-utils';
import { addMonths, formatDateForApi } from '@/date-time/date-time-utils';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);

@Component({
    components: { }
})
export default class LineChartExample extends Mixins(LocaleMixin) {
    private monthsBack = 2;
    private months = [...Array(10).keys()].map((i) => {
        return {
            value: i + 2,
            text: String(i + 2) + ' month(s) back'
        };
    });

    private chartOptions: Highcharts.Options = {
        chart: {
            type: 'line'
        },
        title: {
            text: 'Some Statuses Over Time'
        },
        subtitle: {
            text: 'Timeline of Statuses'
        },
        xAxis: {
            categories: []
        },
        yAxis: {
            title: {
                text: 'Number of Opportunities'
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}</b><br/>',
            split: true
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [
        ]
    };

    async created() {
        await this.prepStatusCountPeriods();
    }

    async prepStatusCountPeriods() {
        loadingState.loadingIncrement('lineChart');
        const startDate = formatDateForApi(addMonths(new Date(), -1 * this.monthsBack));
        const endDate = (new Date()).toISOString().substr(0, 10);
        await statsState.initStatusCountPeriods({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3',
            // eslint-disable-next-line @typescript-eslint/camelcase
            status_ids: [1, 2, 11]
        });
        this.updateChart();
        loadingState.loadingDecrement('lineChart');
    }

    async updateStatusCountPeriods() {
        loadingState.loadingIncrement('lineChart');
        const startDate = formatDateForApi(addMonths(new Date(), -1 * this.monthsBack));
        const endDate = formatDateForApi(new Date());
        await statsState.retrieveStatusCountPeriods({
            // eslint-disable-next-line @typescript-eslint/camelcase
            start_date: startDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            end_date: endDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            period_type: '3',
            // eslint-disable-next-line @typescript-eslint/camelcase
            status_ids: [1, 2, 11]
        });
        this.updateChart();
        loadingState.loadingDecrement('lineChart');
    }

    private updateChart() {
        this.chartOptions.series = countPeriodsToLineGraphSeries(statsState.statusCountPeriods);
        (this.chartOptions.xAxis as XAxisOptions).categories = countPeriodsToXAxis(statsState.statusCountPeriods, 3);
        this.chartOptions.title!.text = 'Some Statuses Over Last ' + this.monthsBack + ' Months';
    }

}
