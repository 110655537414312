import { AbstractRepository } from '@/repositories/abstract-repository';
import { UserWithAllPermissions, UserWithAllPermissionsDto } from '@/staff/models/user';

export class BulkUserPermissionsRepository extends AbstractRepository<UserWithAllPermissions> {
    readonly endpoint = 'staff/permissions';

    public async setPermissionsForAllStaff(payload: Array<UserWithAllPermissionsDto>): Promise<void> {
        await this.client.patch(this.endpoint, payload);
    }
}
