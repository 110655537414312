import { AbstractRepository } from '@/repositories/abstract-repository';
import { RateFile } from '@/enrollment-center/models/rate-file';

export class RateFileRepository extends AbstractRepository<RateFile> {
    readonly endpoint = '/centers';

    public async getRateFile(centerId: number): Promise<RateFile | null> {
        try {
            const response = await this.silentClient.get<RateFile>(`${this.endpoint}/${centerId}/call-center-settings/rates-file`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    public async uploadRateFile(centerId: number, file: RateFile): Promise<void> {
        await this.client.put(`${this.endpoint}/${centerId}/call-center-settings/rates-file`, file);
    }

    public async deleteRateFile(centerId: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/${centerId}/call-center-settings/rates-file`);
    }
}
