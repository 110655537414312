























import { CommunicationType, GroupCommHistory } from '@/communications/messages/models/message';
import { GroupCommHistoryRepository } from '@/communications/messages/repositories/group-comm-history-repository';
import GroupHistoryPerformance from '@/reports/components/group-history/GroupHistoryPerformance.vue';
import GroupHistoryPreview from '@/reports/components/group-history/GroupHistoryPreview.vue';
import GroupHistoryRecipients from '@/reports/components/group-history/GroupHistoryRecipients.vue';
import GroupHistoryStatsHeader from '@/reports/components/group-history/GroupHistoryStatsHeader.vue';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';

const groupHistoryRepository = new GroupCommHistoryRepository();
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);

@Component({
    components: { GroupHistoryPerformance, GroupHistoryPreview, GroupHistoryRecipients, GroupHistoryStatsHeader }
})
export default class ViewGroupTextHistoryStats extends Mixins(LocaleMixin) {
    /**
     * The id of the group text history
     */
    @Prop({
        type: Number,
        required: true
    }) readonly id!: number;

    // The history entry to display stats for.
    private history: GroupCommHistory | null = null;
    private loadingKey = 'groupTextHistoryStats';

    get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    /**
     * Watch the route. This is needed since the component is recreated each time the route changes.
     */
    @Watch('id', { immediate: true })
    private async loadPage() {
        loadingState.loadingIncrement(this.loadingKey);
        this.history = await groupHistoryRepository.getSingleGroupCommHistory(this.id, CommunicationType.TEXT);
        loadingState.loadingDecrement(this.loadingKey);
    }
}
