
















































































import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import Loading from '@/components/Loading.vue';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import EmailSubject from '@/communications/templates/components/EmailSubject.vue';
import { AppStateStore } from '@/store/app-state-store';
import { EventTypes } from '@/constants/event-type-constants';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const emailTemplateRepo = new EmailTemplatesRepository();
const textTemplateRepo = new TextTemplatesRepository();
const loadingState = getModule(LoadingStore);
const emailTemplatesState = getModule(EmailTemplatesStore);
const textTemplatesState = getModule(TextTemplatesStore);
const appState = getModule(AppStateStore);
const featuresState = getModule(FeaturesStore);

@Component({ components: { EmailSubject, Loading } })
export default class CreateTemplate extends Mixins(LocaleMixin) {
    private loadingKey = 'createTemplate';
    private isOpen = false;
    private templateType = 'text';
    private templateName = '';
    private emailSubject = '';

    private close() {
        this.isOpen = false;
        this.templateName = '';
        this.templateType = 'text';
        this.emailSubject = '';
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get hasCommunicationTypes(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    @Watch('isOpen')
    opened() {
        if (this.isOpen) {
            this.$emit(EventTypes.VIEW);
        }
    }

    private async createTemplate() {
        loadingState.loadingIncrement(this.loadingKey);
        let template;
        if (this.templateType === 'email') {
            template = await emailTemplateRepo.create({
                name: this.templateName,
                subject: this.emailSubject,
                org: this.org ? this.org.id : 1,
                communication_type: this.hasCommunicationTypes ? 2 : 1,
                hierarchy_type: 2, // location
                brand: null,
                is_active: true,
                is_corporate_only: false,
                template_group: null,
                attachments: null,
                template_data: null, // Set in editor
                content: null, // Ditto
                location_group: null
            });
            emailTemplatesState.addOrUpdateEntity(template);
        } else {
            // create text template
            template = await textTemplateRepo.create({
                name: this.templateName,
                org: this.org ? this.org.id : 1,
                communication_type: this.hasCommunicationTypes ? 2 : 1, // Marketing
                hierarchy_type: 2, // location,
                brand: null,
                is_active: true,
                is_corporate_only: false,
                template_group: null,
                content: null,
                location_group: null
            });
            textTemplatesState.addOrUpdateEntity(template);
        }

        loadingState.loadingDecrement(this.loadingKey);
        await this.$router.push({
            name: `template-editor-${this.templateType}`,
            params: { id: template.id.toString() }
        });
    }
}
