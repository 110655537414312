




    import { Component, Mixins } from 'vue-property-decorator';
    import { getModule } from 'vuex-module-decorators';
    import { FeaturesStore } from '@/features/features-store';
    import { Route } from 'vue-router';
    import { FeatureConstants } from '@/features/feature-constants';
    import { LocaleMixin } from '@/locales/locale-mixin';
    import { StaffUtils } from '@/staff/staff-utils';
    import { PermissionName } from '@/staff/models/user-permission-models';
    import store from '@/store';
    import { AuthStore } from '@/store/auth-store';

    const featureStore = getModule(FeaturesStore);
    const staffUtils = new StaffUtils();
    const authState = getModule(AuthStore, store);

    // Register a component route guard to prevent access, as needed
    Component.registerHooks([
        'beforeRouteEnter'
    ]);

@Component
    export default class SettingsLayout extends Mixins(LocaleMixin) {
        // This component just has a router-view so that nested children routes can load their components correctly
        async beforeRouteEnter(to: Route, from: Route, next: Function) {
            const settingsNavPermissionGrant = await staffUtils.getUserPermission(PermissionName.SettingsNav);

            if (!featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) || settingsNavPermissionGrant || authState.isSuperuser) {
                // Allow user to navigate to this page.
                next();
            } else {
                // Access denied. Send user home.
                next({ name: 'home' });
            }
        }
    }
