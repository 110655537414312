
































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowConditionDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { WorkflowsTypesStore } from '@/automation/workflows/store/workflows-types-store';
import { LoadingStore } from '@/store/loading-store';
import {
    commentsFieldId,
    eventTypeId,
    facebookEventIdentifier,
    WorkflowConditionLogic,
    workflowEntities,
    WorkflowEntityTypes,
    WorkflowFamilyEntityFieldTypes
} from '@/automation/workflows/constants/workflow-constants';
import {
    WorkflowField,
    WorkflowType
} from '@/automation/workflows/models/workflow-type-models';
import {
    getDefaultInquiryType,
    getDefaultSource
} from '@/families/families-utils';

const workflowsTypesStore = getModule(WorkflowsTypesStore);
const loadingStore = getModule(LoadingStore);

@Component
export default class WorkflowCondition extends Mixins(
    LocaleMixin,
    BasicValidationMixin
) {
    @Prop() readonly value!: WorkflowConditionDto;
    @Prop({ default: false }) readonly hasFacebook!: boolean;

    private workflowOptions = workflowEntities;
    private fields: Array<WorkflowField> = [];
    private fieldValues: Array<WorkflowType> = [];
    private loadingKey = 'manageWorkflow';
    private operator = WorkflowConditionLogic.EQUALS;
    private toFromLogic = WorkflowConditionLogic.TO_FROM;

    private operatorOptions = [
        {
            text: 'Equals',
            value: WorkflowConditionLogic.EQUALS
        },
        {
            text: "Doesn't Equal",
            value: WorkflowConditionLogic.NOT_EQUAL
        },
        {
            text: 'To/From',
            value: WorkflowConditionLogic.TO_FROM
        }
    ];

    private targetOptions = [
        {
            text: 'Child',
            value: WorkflowEntityTypes.CHILD
        },
        {
            text: 'Family',
            value: WorkflowEntityTypes.FAMILY
        }
    ];

    get condition(): WorkflowConditionDto {
        return this.value;
    }

    set condition(condition: WorkflowConditionDto) {
        this.$emit('input', condition);
    }

    get entity() {
        return this.condition.entity;
    }

    get field() {
        return this.condition.field;
    }

    get isComment() {
        return this.field === commentsFieldId;
    }

    get isChild() {
        return this.entity === WorkflowEntityTypes.CHILD;
    }

    get allFieldValues() {
        if (this.condition.is_not_equal) {
            return [{ id: '', value: '[Blank]' }, ...this.fieldValues];
        }
        return this.fieldValues;
    }

    get valueLabel() {
        switch (this.operator) {
            case WorkflowConditionLogic.EQUALS:
                return 'Equals';
            case WorkflowConditionLogic.TO_FROM:
                return 'From';
            case WorkflowConditionLogic.NOT_EQUAL:
                return "Doesn't Equal";
        }
    }

    async created() {
        if (this.condition.is_not_equal) {
            this.operator = WorkflowConditionLogic.NOT_EQUAL;
        } else if (this.condition.value_two) {
            this.operator = WorkflowConditionLogic.TO_FROM;
        } else {
            this.operator = WorkflowConditionLogic.EQUALS;
        }
        await this.loadEntity();
        await this.loadField();
    }

    private async loadEntity() {
        if (this.entity) {
            loadingStore.loadingIncrement(this.loadingKey);
            await workflowsTypesStore.retrieveEntityFields(this.entity);
            const fields = workflowsTypesStore.entityFields(this.entity);
            fields.forEach((field) => {
                if (this.$options && this.$options.filters && this.$options.filters.cTranslateChunk) {
                    field.name = this.$options.filters.cTranslateChunk(field.name);
                }
            });
            this.fields = fields;

            loadingStore.loadingDecrement(this.loadingKey);
        }
    }

    private async loadField() {
        if (this.entity && this.field && !this.isComment) {
            loadingStore.loadingIncrement(this.loadingKey);
            await workflowsTypesStore.retrieveFieldValues({ entityId: this.entity, fieldId: this.field });
            this.fieldValues = workflowsTypesStore.fieldValues(this.entity, this.field).filter((value) => {
                if (this.field !== eventTypeId) {
                    return true;
                }
                return this.hasFacebook || value.identifier !== facebookEventIdentifier;
            });
            loadingStore.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('entity')
    private async entityChanged() {
        await this.loadEntity();
        this.fieldsChanged();
        if (this.entity !== WorkflowEntityTypes.CHILD) {
            this.condition.target_entity = WorkflowEntityTypes.CHILD;
        }
    }

    @Watch('field')
    private async fieldChanged() {
        await this.loadField();
        this.fieldValuesChanged();
    }

    @Watch('operator')
    private operatorChanged() {
        if (this.operator === WorkflowConditionLogic.EQUALS) {
            this.condition.value_two = null;
        }
        this.condition.is_not_equal = this.operator === WorkflowConditionLogic.NOT_EQUAL;
    }

    private fieldsChanged() {
        if (!this.fields.map((field) => (field.id)).includes(this.condition.field)) {
            this.condition.field = 0;
        }
    }

    private fieldValuesChanged() {
        if (!this.fieldValues.map((value) => (value.id)).includes(this.condition.value_one)) {
            this.condition.value_one = 0;
        }
        if (this.condition.value_two && !this.fieldValues.map((value) => (value.id)).includes(this.condition.value_two)) {
            this.condition.value_two = null;
        }

        if (this.entity === WorkflowEntityTypes.FAMILY && this.field === WorkflowFamilyEntityFieldTypes.INQUIRY && this.operator === WorkflowConditionLogic.EQUALS) {
            const defaultInquiryType = getDefaultInquiryType(this.fieldValues);
            if (defaultInquiryType) {
                this.condition.value_one = defaultInquiryType;
            }
        } else if (this.entity === WorkflowEntityTypes.FAMILY && this.field === WorkflowFamilyEntityFieldTypes.LEAD_SOURCE && this.operator === WorkflowConditionLogic.EQUALS) {
            const defaultSourceType = getDefaultSource(this.fieldValues);
            if (defaultSourceType) {
                this.condition.value_one = defaultSourceType;
            }
        }
    }
}
