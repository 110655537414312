import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '../i18n';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        t: (key, ...params) => (i18n.t(key, params) as string)
    },
    theme: {
        themes: {
            light: {
                primary: '#0060AF', // CRM blue,
                secondary: '#2B2B2B' // base black
            }
        }
    }
});
