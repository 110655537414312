

































import FamilySearch from '@/families/components/FamilySearch.vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { FeatureConstants } from '@/features/feature-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import { FeaturesStore } from '@/features/features-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { StaffUtils } from '@/staff/staff-utils';
import SettingsFamilyHub from '@/families/components/SettingsFamilyHub.vue';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore);
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();

@Component({
    components: { BasePageTitle, FamilySearch }
})
export default class FamilyPageHeader extends Mixins(LocaleMixin, PageTitleMixin) {
    // Title of the page.
    private toggleTarget = true;
    private settingsFamilyPermissionGrant = false;
    private familySettings = SettingsFamilyHub;
    async created() {
        this.toggleTarget = appState.familyHubTargetMode;
        this.settingsFamilyPermissionGrant = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
    }

    mounted() {
        this.setPageTitle('Families');
    }

    private get hasCrmPlusMode() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    @Watch('toggleTarget')
    private updateTargetMode() {
        appState.updateFamilyHubTargetMode(this.toggleTarget);
    }
}
