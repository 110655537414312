import { Component, Vue } from 'vue-property-decorator';
import { BasicValidation } from '@/validation/basic-validation';

@Component
export class BasicValidationMixin extends Vue {
    passwordField = BasicValidation.passwordField;
    requiredField = BasicValidation.requiredField;
    requiredMultiSelect = BasicValidation.requiredMultiSelect;
    requiredBoolean = BasicValidation.requiredBoolean;
    requiredNumber = BasicValidation.requiredNumber;
    nonRequiredNumber = BasicValidation.nonRequiredNumber;
    requiredFloat = BasicValidation.requiredFloat;
    nonRequiredFloat = BasicValidation.nonRequiredFloat;
    requiredInteger = BasicValidation.requiredInteger;
    requiredNonNegativeInteger = BasicValidation.requiredNonNegativeInteger;
    requiredEmail = BasicValidation.requiredEmail;
    notRequiredEmail = BasicValidation.notRequiredEmail;
    notRequiredUrl = BasicValidation.notRequiredUrl;
}
