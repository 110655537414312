export enum CommunicationTypes {
    TEXT = 'text',
    EMAIL = 'email',
    FACEBOOK = 'facebook',
    RECORDING = 'recording',
    GENERAL = 1,
    MARKETING = 2,
    SERVICE = 3,
    URGENT = 4
}
