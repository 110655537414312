

















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { GroupDripCampaignDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { ApiParameters } from '@/repositories/abstract-repository';
import { OpportunitiesRepository } from '@/families/repositories/opportunities-repository';

const loadingState = getModule(LoadingStore);
const familiesRepo = new FamiliesRepository();
const oppsRepo = new OpportunitiesRepository();

@Component
export default class GroupDeleteRejectedFamilies extends Mixins(LocaleMixin) {
    @Prop() readonly extraOrgId!: number;
    /** The family ids to exclude in the group. */
    @Prop() readonly familyExcludedIds!: Array<number>;
    /** The id of the filter (group). */
    @Prop() readonly familyFilterId!: number;
    /** The family ids to exclude in the group. */
    @Prop() readonly familyIncludedIds!: Array<number>;
    /** v-model whether we should show it. */
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'bulkDeleteFamilies';
    private oppCount = 0;
    private loaded = false;

    /**
     * Handles when to show the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    private get oppLabel() {
        return this.oppCount === 1 ? 'family' : 'families';
    }

    @Watch('modelValue')
    async opened() {
        if (!this.modelValue) {
            return;
        }
        this.loaded = false;
        loadingState.loadingIncrement(this.loadingKey);
        const params: ApiParameters = {
            marketing_group_id: this.familyFilterId,
            excluded_family_ids_from_group: this.familyExcludedIds,
            family_ids: this.familyIncludedIds,
            org_ids: [this.extraOrgId]
        };
        const data = await oppsRepo.getSorted({ limit: 1, offset: 0 }, null, params);
        this.oppCount = Number(data.count);
        this.loaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Apply the marketing campaign to the group.
     */
    private async doDelete() {
        loadingState.loadingIncrement(this.loadingKey);

        const dto = {
            excluded_families: this.familyExcludedIds,
            included_families: this.familyIncludedIds,
            org_id: this.extraOrgId
        } as GroupDripCampaignDto;
        await familiesRepo.bulkDeleteFamilies(this.familyFilterId, dto);

        loadingState.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.DELETED);
        this.close();
    }

    /**
     * Close the modal.
     */
    private close() {
        this.modelValue = false;
    }

}
