import { AbstractRepository, ApiPagination } from '@/repositories/abstract-repository';
import {
    CommunicationType,
    GroupCommHistory,
    GroupHistoryRecipient,
    GroupEmailStats, GroupTextStats
} from '@/communications/messages/models/message';
import { CrmApiResponse } from '@/models/base';
import qs from 'qs';

export class GroupCommHistoryRepository extends AbstractRepository<GroupCommHistory> {
    readonly endpoint = 'groups/histories';

    /**
     * Cancel a group communication history by calling the delete endpoint.
     *
     * @param historyId The id of the history
     * @param commType  The type of communication
     */
    public async cancelGroupCommHistory(historyId: number, commType: CommunicationType): Promise<void> {
        const response = await this.client.delete(`${this.endpoint}/${commType}s/${historyId}`);
        return response.data;
    }

    /**
     * Get the group comm history.
     *
     * @param orgId      The org id to filter the results to
     * @param pagination The pagination
     */
    public async getGroupCommHistory(orgId: number, pagination: ApiPagination | null = null): Promise<CrmApiResponse<GroupCommHistory>> {
        return await this.get(pagination, { org_id: orgId }, `${this.endpoint}`);
    }

    /**
     * Get a single group communication history.
     *
     * @param historyId The id of the history
     * @param commType  The type of communication
     */
    public async getSingleGroupCommHistory(historyId: number, commType: CommunicationType): Promise<GroupCommHistory> {
        const response = await this.client.get<GroupCommHistory>(`${this.endpoint}/${commType}s/${historyId}`);
        return response.data;
    }

    /**
     * Get the recipients for the given communication history.
     *
     * @param historyId The id of the history
     * @param commType  The type of communication
     * @param pagination
     */
    public async getGroupCommHistoryRecipients(historyId: number, commType: CommunicationType, pagination: ApiPagination): Promise<CrmApiResponse<GroupHistoryRecipient>> {
        const params = {
            limit: String(pagination.limit),
            offset: String(pagination.offset)
        };

        const response = await this.client.get<Array<GroupHistoryRecipient>>(`${this.endpoint}/${commType}s/${historyId}/recipients`, {
            params: params,
            paramsSerializer: params1 => { return qs.stringify(params1); }
        });

        if (response === undefined) {
            // no data was pulled that matched the request
            return { entities: [], count: 0 };
        }

        const count = parseInt(response.headers['x-total-count']);

        return { entities: response.data, count: !isNaN(count) ? count : 0 };
    }

    /**
     * Get the statistics for the given communication history.
     *
     * @param historyId The id of the history
     * @param commType  The type of communication
     */
    public async getGroupCommHistoryStats(historyId: number, commType: CommunicationType): Promise<GroupEmailStats | GroupTextStats> {
        const response = await this.client.get<GroupEmailStats | GroupTextStats>(`${this.endpoint}/${commType}s/${historyId}/statistics`);
        return response.data;
    }
}
