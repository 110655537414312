import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import type { FamilyUpdateDtoInterface } from '@/families/models/family';
import store from '@/store';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FAMILY_CHANGES
})
export class FamilyChangesStore extends VuexModule {
    private changes: Map<number, FamilyUpdateDtoInterface> = new Map();
    private hasPendingChanges = false;

    @Mutation
    clear(familyId: number) {
        this.changes.delete(familyId);
        this.hasPendingChanges = Array.from(this.changes.keys()).length > 0;
    }

    @Mutation
    storeChange(familyDto: FamilyUpdateDtoInterface) {
        this.changes.set(familyDto.id, familyDto);
        this.hasPendingChanges = true;
    }

    public get areChangesPending(): boolean {
        return this.hasPendingChanges;
    }

    public get changesForFamilyId() {
        return (familyId: number): FamilyUpdateDtoInterface | null => {
            return this.changes.get(familyId) ?? null;
        };
    }
}
