










































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import {
    Class,
    ClassCreateDtoInterface,
    ClassUpdateDtoInterface
} from '@/organizations/locations/models/class';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { EventTypes } from '@/constants/event-type-constants';
import { Center } from '@/organizations/locations/models/center';
import { AppStateStore } from '@/store/app-state-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { ClassMapper } from '@/organizations/locations/mappers/class-mapper';
import isEqual from 'lodash/isEqual';
import { LoadingStore } from '@/store/loading-store';
import BaseClose from '@/components/base/BaseClose.vue';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { LocationIntegrationToggle } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';

const appState = getModule(AppStateStore, store);
const integrationsRepo = new IntegrationRepository();
const classesRepo = new ClassesRepository();
const centersStore = getModule(CentersStore, store);
const centersRepo = new CentersRepository();
const classMapper = new ClassMapper();
const loadingState = getModule(LoadingStore);
interface ClassComparison {
    original: ClassCreateDtoInterface | ClassUpdateDtoInterface;
    current: ClassCreateDtoInterface | ClassUpdateDtoInterface;
}

@Component({
    components: { BaseClose }
})
export default class IntegrationMappedClassrooms extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ default: false }) readonly isSecondary!: boolean;
    @Prop({ default: 0 }) readonly cmsPartnerId!: number;

    private loadingKey = 'mappingClassrooms';
    private classrooms: Array<ClassComparison | null> = [];
    private classroomsCms: Array<Class | null> = [];
    private centers: Array<Center> = [];
    private integratedCenters: Array<LocationIntegrationToggle> = [];
    private centerId = 0;

    get filteredCenters() {
        return this.centers.filter(center => this.integratedCenters
            .filter(toggle => toggle.is_enabled)
            .map(toggle => toggle.center_id)
            .includes(center.id));
    }

    private cmsClasses(currentId: number | null) {
        if (this.classroomsCms.length === 0) {
            return [];
        }
        const chosen = (this.classrooms ?? [])
            .map(
                (classRoom) => {
                    return this.isSecondary
                        ? classRoom?.current.external_system_id_two
                        : classRoom?.current.external_system_id;
                }
            ).filter(
                (id) => {
                    return id !== currentId;
                }
            );
        const possible = this.classroomsCms.filter(
            (cmsClass) => {
                return !chosen.includes(this.isSecondary ? cmsClass?.external_system_id_two : cmsClass?.external_system_id);
            }
        );

        return [
            {
                external_system_id: null, name: 'None'
            },
            ...possible
        ];
    }

    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    @Watch('centerId', { immediate: true })
    async updateTable() {
        if (!this.centerId || !this.modelValue) {
            this.classrooms = [];
            this.classroomsCms = [];
        } else {
            loadingState.loadingIncrement(this.loadingKey);

            this.classrooms = [];
            const classroomsResponse = await classesRepo.retrieveAll(this.centerId);
            classroomsResponse.forEach(
                (classroom) => {
                    this.classrooms.push(
                        {
                            original: classMapper.toUpdateDto(classroom),
                            current: classMapper.toUpdateDto(classroom)
                        }
                    );
                }
            );
            this.classroomsCms = await classesRepo.retrieveAllCms(this.centerId);

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

     async mounted() {
        loadingState.loadingIncrement(this.loadingKey);

        this.integratedCenters = await integrationsRepo.getLocationIntegrationsByPartnerId(this.cmsPartnerId);
        this.centers = [];
        const centerArray: Array<Center> = centersStore.storedAll;
         centerArray.forEach(
             element => {
                 if (element.is_active) {
                     this.centers.push(element);
                 }
             }
         );
        this.centerId = appState.storedCurrentCenter?.id as number;

        loadingState.loadingDecrement(this.loadingKey);
    }

    private close() {
        this.centerId = appState.storedCurrentCenter?.id as number;
        this.classrooms = [];
        this.classroomsCms = [];
        this.modelValue = false;
        this.$emit('closed');
    }

    @Watch('modelValue', { immediate: true })
    async populate(val: boolean) {
        if (val) {
            await this.updateTable();
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        for (const classroom of this.classrooms) {
            if (!isEqual(classroom?.original, classroom?.current)) {
                await classesRepo.update(this.centerId, classroom?.current as ClassUpdateDtoInterface);
            }
        }

        await centersRepo.updateLocationMappings([]);

        loadingState.loadingDecrement(this.loadingKey);

        this.centerId = appState.storedCurrentCenter?.id as number;
        this.classrooms = [];
        this.classroomsCms = [];
        this.modelValue = false;
    }
}
