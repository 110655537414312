






import store from '@/store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import type { LayoutTab } from '@/store/layout-tabs-store';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';

@Component({
    components: {}
})
export default class LayoutTabContent extends Mixins(LocaleMixin) {
    /**
     * The tab information.
     */
    @Prop() readonly layoutTab!: LayoutTab;

    /**
     * Change the route when the tab route is changed.
     */
    @Watch('layoutTab', { immediate: true, deep: true })
    private change() {
        if (
            (
                !isEqual(this.layoutTab.props.routeName, this.$route.name) &&
                !isEqual(this.layoutTab.props.routeParams ?? undefined, this.$route.params ?? undefined)
            ) ||
            !this.$route.name
        ) {
            this.$router.push({
                name: this.layoutTab.props.routeName,
                params: this.layoutTab.props.routeParams ?? undefined
            });
        }
    }

    /**
     * When the route is changed in a child component, record the changes.
     */
    @Watch('$route', { immediate: true })
    saveRoute() {
        // Save the route information to the store
        if (!this.layoutTab || !this.$route.name) {
            return;
        }
        if (
            !isEqual(this.layoutTab.props.routeName, this.$route.name) ||
            (
                isEqual(this.layoutTab.props.routeName, this.$route.name) &&
                !isEqual(this.layoutTab.props.routeParams, this.$route.params)
            )
        ) {
            const tab = cloneDeep(this.layoutTab);
            tab.props.routeName = this.$route.name ?? '';
            tab.props.routeParams = this.$route.params;
            const layoutTabsStore = getModule(LayoutTabsStore, store);
            layoutTabsStore.updateTab(tab);
        }
    }

    created() {
        this.saveRoute();
    }
}
