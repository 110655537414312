































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';

@Component
export default class BaseWidgetConfig extends Vue {
    @Prop({ type: String, default: '' }) readonly contentClass!: string;
    @Prop({ required: true, type: Function }) menuComponent!: string;
    @Prop({ type: Boolean, default: true }) readonly showSnack!: boolean;

    private componentKey = 0;
    private isOpen = false;
    private closeEvent = EventTypes.CLOSE;
    private updateEvent = EventTypes.UPDATED;
    private showSnackBar = false;
    private snackMessage = '';

    close() {
        this.isOpen = false;
        ++this.componentKey;
    }

    update(message: string) {
        if (this.showSnack) {
            this.snackMessage = message;
            this.showSnackBar = true;
        }
        this.isOpen = false;
        ++this.componentKey;
        this.$emit(EventTypes.UPDATED);
    }
}
