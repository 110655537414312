import { Class, ClassUpdateDtoInterface } from '@/organizations/locations/models/class';
import {
    AvailabilityClassroomObject,
    AvailabilityDayObject
} from '@/enrollment-center/models/enrollment-center-location-models';
import { baseFormatDate, formatIsoDateTime, isoFormat } from '@/date-time/date-time-utils';

export function classSort(classA: Class, classB: Class): number {
    if (classA.begin_age === classB.begin_age && classA.end_age === classB.end_age) {
        if (classA.name === classB.name) {
            return 0;
        } else if (classA.name < classB.name) {
            return -1;
        }
        return 1;
    } else if (classA.begin_age === classB.begin_age) {
        return classA.end_age - classB.end_age;
    }
    return classA.begin_age - classB.begin_age;
}

function dayAvailHelper(am: number, pm: number): AvailabilityDayObject {
    return {
        am_avail: am,
        pm_avail: pm,
        is_available: am > 0 || pm > 0
    };
}

/**
 * create form object from class dto
 * @param classDto
 */
export function createAvailObject(classDto: ClassUpdateDtoInterface): AvailabilityClassroomObject {
    const daysArray: Array<AvailabilityDayObject> = [
        dayAvailHelper(classDto.mon_am_free, classDto.mon_pm_free),
        dayAvailHelper(classDto.tue_am_free, classDto.tue_pm_free),
        dayAvailHelper(classDto.wed_am_free, classDto.wed_pm_free),
        dayAvailHelper(classDto.thur_am_free, classDto.thur_pm_free),
        dayAvailHelper(classDto.fri_am_free, classDto.fri_pm_free),
        dayAvailHelper(classDto.sat_am_free, classDto.sat_pm_free),
        dayAvailHelper(classDto.sun_am_free, classDto.sun_pm_free)
    ];
    return {
        class_id: classDto.id,
        next_free_day: classDto.next_free_date ? baseFormatDate(classDto.next_free_date, isoFormat) : null,
        day_avails: daysArray
    };
}

export function applyAvailToDto(availObject: AvailabilityClassroomObject, classDto: ClassUpdateDtoInterface, tz = 'UTC') {
    classDto.next_free_date = availObject.next_free_day ? formatIsoDateTime(availObject.next_free_day, tz) : null;
    for (const day of availObject.day_avails) {
        if (!day.is_available) {
            day.am_avail = 0;
            day.pm_avail = 0;
        }
    }
    classDto.mon_am_free = availObject.day_avails[0].am_avail;
    classDto.mon_pm_free = availObject.day_avails[0].pm_avail;
    classDto.tue_am_free = availObject.day_avails[1].am_avail;
    classDto.tue_pm_free = availObject.day_avails[1].pm_avail;
    classDto.wed_am_free = availObject.day_avails[2].am_avail;
    classDto.wed_pm_free = availObject.day_avails[2].pm_avail;
    classDto.thur_am_free = availObject.day_avails[3].am_avail;
    classDto.thur_pm_free = availObject.day_avails[3].pm_avail;
    classDto.fri_am_free = availObject.day_avails[4].am_avail;
    classDto.fri_pm_free = availObject.day_avails[4].pm_avail;
    classDto.sat_am_free = availObject.day_avails[5].am_avail;
    classDto.sat_pm_free = availObject.day_avails[5].pm_avail;
    classDto.sun_am_free = availObject.day_avails[6].am_avail;
    classDto.sun_pm_free = availObject.day_avails[6].pm_avail;
}
