/**
 * Email and text templates are basically the same, except for the endpoints
 */
import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    EmailTemplateCreateDto,
    EmailTemplateUpdateDto,
    MessageTemplate,
    FileImageUrl, TemplateGroupTemplates, TemplateGroupUpdate, OutgoingEmailTemplatePreviewDto
} from '@/communications/templates/models/message-template';
import { PreviewTemplate, PreviewTemplateRequest } from '@/communications/templates/models/preview-template';
import { EmailAttachmentCreateDto } from '@/communications/templates/models/email-attachment';
import { Email } from '@/communications/messages/models/email';

interface PreviewPostData {
    subject: string;
    body: string;
    org_id?: number;
    center_id?: number;
    user_id?: number;
}

export class EmailTemplatesRepository extends AbstractRepository<MessageTemplate> {
    readonly endpoint = 'communications/templates/emails';

    public async create(createDto: EmailTemplateCreateDto): Promise<MessageTemplate> {
        const response = await this.client.post(this.endpoint, createDto);
        return response.data;
    }

    public async update(updateDto: EmailTemplateUpdateDto): Promise<MessageTemplate> {
        const response = await this.client.put(`${this.endpoint}/${updateDto.id}`, updateDto);
        return response.data;
    }

    public async delete(templateId: number): Promise<any> {
        const response = await this.client.delete(`${this.endpoint}/${templateId}`);
        return response.data;
    }

    public async getGroups(): Promise<Array<TemplateGroupTemplates>> {
        const response = await this.client.get(this.endpoint + '/groups');
        return response.data;
    }

    public async updateGroup(groupId: number, update: TemplateGroupUpdate): Promise<void> {
        await this.client.put(this.endpoint + '/groups/' + groupId, update);
    }

    /**
     * Create a preview from a template that doesn't exist, or was edited.
     *
     * @param subject
     * @param body
     * @param centerId
     * @param organizationId
     * @param userId
     */
    public async generatePreview(subject: string, body: string, centerId?: number | null, organizationId?: number | null, userId?: number | null): Promise<PreviewTemplate> {
        const postData: PreviewPostData = {
            subject: subject,
            body: body
        };

        if (centerId) {
            postData.center_id = centerId;
        }

        if (organizationId) {
            postData.org_id = organizationId;
        }

        if (userId) {
            postData.user_id = userId;
        }

        return (await this.client.post<PreviewTemplate>(`${this.endpoint}/preview`, postData)).data;
    }

    /**
     * Generate a preview for a specific template and family
     *
     * @param template
     * @param request
     */
    public async generatePreviewForTemplate(template: MessageTemplate, request: PreviewTemplateRequest): Promise<PreviewTemplate> {
        const response = await this.client.get(`${this.endpoint}/${template.id}/preview`, { params: request });
        return response.data;
    }

    /**
     * Generate an image URL to upload to AWS
     *
     * @param attachmentDto
     */
    public async generateFileImageUrl(attachmentDto: EmailAttachmentCreateDto): Promise<FileImageUrl> {
        const response = await this.client.post<FileImageUrl>(`${this.endpoint}/images`, attachmentDto);
        return response.data;
    }

    public async sendPreviewEmail(dto: OutgoingEmailTemplatePreviewDto): Promise<Email> {
        const response = await this.client.post<Email>(`${this.endpoint}/send-preview`, dto);
        return response.data;
    }
}
