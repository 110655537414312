import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Class, ClassCreateDtoInterface, ClassUpdateDtoInterface } from '@/organizations/locations/models/class';

export class ClassMapper implements AbstractMapper<Class> {
    toCreateDto(entity: Class): ClassCreateDtoInterface {
        return {
            name: entity.name,
            begin_age: entity.begin_age,
            end_age: entity.end_age,
            capacity: entity.capacity,
            external_system_id: entity.external_system_id,
            next_free_date: entity.next_free_date,
            mon_am_free: entity.mon_am_free,
            mon_pm_free: entity.mon_pm_free,
            tue_am_free: entity.tue_am_free,
            tue_pm_free: entity.tue_pm_free,
            wed_am_free: entity.wed_am_free,
            wed_pm_free: entity.wed_pm_free,
            thur_am_free: entity.thur_am_free,
            thur_pm_free: entity.thur_pm_free,
            fri_am_free: entity.fri_am_free,
            fri_pm_free: entity.fri_pm_free,
            sat_am_free: entity.sat_am_free,
            sat_pm_free: entity.sat_pm_free,
            sun_am_free: entity.sun_am_free,
            sun_pm_free: entity.sun_pm_free
        };
    }

    toUpdateDto(entity: Class): ClassUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as ClassUpdateDtoInterface;
        dto.id = entity.id as number;
        return dto;
    }
}
