





































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { BulkUserPermissionsRepository } from '@/staff/repositories/bulk-user-permissions-repository';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    UserWithAllPermissions,
    UserWithAllPermissionsDto,
    UserWithAllPermissionsTableItem
} from '@/staff/models/user';
import {
    Permission,
    PermissionName,
    UserPermission
} from '@/staff/models/user-permission-models';
import { UserPermissionMapper } from '@/staff/mappers/user-permission-mapper';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { OrgsStore } from '@/store/orgs-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { StaffUtils } from '@/staff/staff-utils';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { OrganizationLink } from '@/organizations/models/organization';
import { PermissionsStore } from '@/staff/store/permissions-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import cloneDeep from 'lodash/cloneDeep';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const bulkUserPermissionsRepository = new BulkUserPermissionsRepository();
const loadingState = getModule(LoadingStore);
const userPermissionMapper = new UserPermissionMapper();
const crmTypesStore = getModule(CrmTypesStore);
const orgsState = getModule(OrgsStore);
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const permissionsState = getModule(PermissionsStore);
const interfaceSettingsState = getModule(InterfaceSettingsStore);

@Component({
    components: { BaseClose }
})
export default class UserPermissionsCard extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'userPermissionsCard';
    private staff: Array<UserWithAllPermissionsTableItem> = [];
    private search: string | null = '';
    // List of options for position title
    private positionTitles: Array<CrmTypeOption> = [];
    // Filter by position pick list selections
    private positionFilter: Array<number> = [];
    // Filter by org level pick list selections
    private orgLevelFilter: Array<number> = [];

    // Custom sorting fields
    private sortNameParam = true;
    private sortPositionParam = true;
    private sortOrgLevelParam = true;

    private permissionsList: Array<Permission> = [];

    // copy/paste
    private copyStaffObject: UserWithAllPermissionsTableItem | null = null;

    private get headers() {
        const headers = [
            {
                text: 'Name',
                value: 'fullName',
                sortable: false,
                width: '10%',
                description: ''
            },
            {
                text: 'Position',
                value: 'position',
                sortable: false,
                width: '15%',
                description: ''
            },
            {
                text: 'Organization Level',
                value: 'orgLevel',
                sortable: false,
                width: '16%',
                description: ''
            }
        ];
        headers.push({
            text: '',
            value: 'copy',
            sortable: false,
            width: '6%',
            description: ''
        });
        for (const permission of this.permissionsList) {
            headers.push({
                text: permission.description,
                value: permission.label,
                sortable: false,
                width: '6%',
                description: permission.help_description ?? ''
            });
        }
        return headers;
    }

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    private get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    get isEnrollmentCenterFeature() {
        return featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    get getStaff(): Array<UserWithAllPermissionsTableItem> {
        let returnArray: Array<UserWithAllPermissionsTableItem> = [];

        // If search bar is used, ignore the filters
        if (this.search !== null && this.search.length >= 3) {
            const searchString = this.search;
            returnArray = this.staff.filter(item => StaffUtils.searchUserPermissionsObject(searchString, item));
        } else if (this.positionFilter.length === 0 && this.orgLevelFilter.length === 0) {
            // If no filters are used, let the items be as is
            returnArray = this.staff;
        } else {
            if (this.positionFilter.length !== 0) {
                // Case 1: Filter by Position
                returnArray = this.staff.filter(staff => this.positionFilter.includes(staff.positionId));

                // Case 2: Filter by Position & Org-Level
                if (this.orgLevelFilter.length !== 0) {
                    returnArray = returnArray.filter(staff => !!staff.orgLevel && this.orgLevelFilter.includes(staff.orgLevel.id));
                }
            } else if (this.orgLevelFilter.length !== 0) {
                // Case 3: Filter by Org-Level
                returnArray = this.staff.filter(staff => !!staff.orgLevel && this.orgLevelFilter.includes(staff.orgLevel.id));
            }

        }

        return returnArray;
    }

    get tableClass() {
        return this.crmBreakpoint === 'xl' ? 'thinner' : '';
    }

    @Watch('modelValue')
    openChanged() {
        if (!this.modelValue) {
            this.clearCopy();
        }
    }

    @Watch('sortPositionParam')
    private checkPositionSort() {
        // Custom sorting
        if (this.sortPositionParam) {
            this.staff.sort((a, b) => (a.position.toLowerCase() > b.position.toLowerCase()) ? 1 : ((b.position.toLowerCase() > a.position.toLowerCase()) ? -1 : 0));
        } else {
            this.staff.sort((a, b) => (a.position.toLowerCase() > b.position.toLowerCase()) ? -1 : ((b.position.toLowerCase() > a.position.toLowerCase()) ? 1 : 0));
        }
    }

    @Watch('sortOrgLevelParam')
    private checkOrgLevelSort() {
        // Custom sorting
        this.staff.sort((a, b) => (
            (a.orgLevel ? a.orgLevel.values.name.toLowerCase() : '').localeCompare(b.orgLevel ? b.orgLevel.values.name.toLowerCase() : '') * (this.sortOrgLevelParam ? 1 : -1)
        ));
    }

    @Watch('sortNameParam')
    private checkNameSort() {
        // Custom sorting
        if (this.sortNameParam) {
            this.staff.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : ((b.fullName.toLowerCase() > a.fullName.toLowerCase()) ? -1 : 0));
        } else {
            this.staff.sort((a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? -1 : ((b.fullName.toLowerCase() > a.fullName.toLowerCase()) ? 1 : 0));
        }
    }

    get copyStaffId() {
        return this.copyStaffObject ? this.copyStaffObject.staff.id : null;
    }

    // The orgs in the org level list
    private get orgs() {
        return orgsState.stored;
    }

    private get isCorpUser() {
        return authState.isCorporateUser;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    private get isCenterStaff() {
        return authState.isCenterStaff;
    }

    // Get full name in format 'Last Name, First Name'
    private getName(item: UserWithAllPermissions): string {
        if (item.staff.values.last_name.length === 0 && item.staff.values.first_name.length === 0) {
            return item.staff.values.username;
        }
        return item.staff.values.last_name + ', ' + item.staff.values.first_name;
    }

    // Get user/staff position
    private getPosition(item: UserWithAllPermissions): string {
        return item.position ? item.position.values.value : '';
    }

    // Get user/staff org level
    private getOrgLevel(item: UserWithAllPermissions): OrganizationLink | null {
        return item.org;
    }

    // Get enabled/disabled status for specified user permission
    private getGrant(item: UserWithAllPermissionsTableItem, permissionName: string) {
        for (const permission of item.permissions) {
            if (permission.label === permissionName) {
                return permission.grants.update;
            }
        }
        return false;
    }

    // Get header checkbox input value
    private getPermissionHeader(permissionName: PermissionName) {
        const items: Array<UserWithAllPermissionsTableItem> = this.getStaff;
        for (const item of items) {
            if (this.showPermissionCheckBox(item, permissionName)) {
                for (const permission of item.permissions) {
                    if (permission.label === permissionName && !permission.grants.update) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresStore.init();
        await Promise.all([permissionsState.init(), interfaceSettingsState.init()]);
        const itemsPromise = bulkUserPermissionsRepository.getAll();
        const positionsPromise = crmTypesStore.initList(CrmTypeList.STAFF_TITLE);
        const permsPromise = staffUtils.getPermissionsAvailableToEdit(null);
        const items: Array<UserWithAllPermissions> = (await itemsPromise).entities;
        for (const item of items) {
            this.staff.push({
                staff: item.staff,
                center: item.center,
                email: item.email,
                phone: item.phone,
                phone_formatted: item.phone_formatted,
                permissions: item.permissions,
                fullName: this.getName(item),
                position: this.getPosition(item),
                positionId: item.position ? item.position.id : 0,
                orgLevel: this.getOrgLevel(item),
                isCenterUser: item.center_id !== null
            });
        }
        await positionsPromise;
        this.positionTitles = crmTypesStore.listOptions(CrmTypeList.STAFF_TITLE);

        this.permissionsList = await permsPromise;

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Reset c&p mode
     */
    clearCopy() {
        this.copyStaffObject = null;
        document.removeEventListener('keydown', this.handleEsc);
    }

    private close() {
        this.staff = [];
        this.search = '';
        this.positionTitles = [];
        this.positionFilter = [];
        this.orgLevelFilter = [];
        this.modelValue = false;
    }

    /**
     * Copy and paste permissions and then resets c&p mode
     *
     * @param target
     */
    doPaste(target: UserWithAllPermissionsTableItem) {
        if (!this.copyStaffObject) {
            return;
        }
        const isFranchise = this.isFranchiseModeFeatureEnabled && !this.isCenterStaff;
        StaffUtils.copyPerms(this.copyStaffObject, target, isFranchise);
        this.clearCopy();
    }

    getHeaderSlot(perm: Permission) {
        return 'header.' + perm.label;
    }

    getItemSlot(perm: Permission) {
        return 'item.' + perm.label;
    }

    /**
     * keypress callback for c&p mode
     *
     * @param evt
     */
    handleEsc(evt: KeyboardEvent) {
        if (evt.key === 'Escape') {
            this.clearCopy();
        }
    }

    isGrouped(label: string) {
        return staffUtils.getGroupedPermissionControl(label, permissionsState.stored, interfaceSettingsState.enhancedPermissions);
    }

    isGroupLocked(item: UserWithAllPermissionsTableItem, label: string) {
        return staffUtils.isGroupLocked(label, item.permissions, permissionsState.stored, interfaceSettingsState.enhancedPermissions);
    }

    private isReadOnly(item: UserWithAllPermissionsTableItem): boolean {
        if (this.isCorpUser) {
            return false;
        }
        return item.staff.id === authState.id;
    }

    // Changes to any user's permission checkbox needs to get recorded
    private permissionChange(e: boolean, item: UserWithAllPermissionsTableItem, permissionName: string) {
        for (const user of this.staff) {
            if (user.staff.id === item.staff.id) {
                for (const permission of user.permissions) {
                    if (permission.label === permissionName) {
                        permission.grants.update = e;
                        staffUtils.handlePostClick(e, permissionName, user.permissions, permissionsState.stored, interfaceSettingsState.enhancedPermissions);
                        break;
                    }
                }
            }
        }
    }

    private showPermissionCheckBox(item: UserWithAllPermissionsTableItem, permissionName: PermissionName) {
        const isCorp = !item.orgLevel || item.orgLevel.id === 1;
        // mimicking original logic
        const isFranchise = this.isFranchiseModeFeatureEnabled && !this.isCenterStaff;
        return StaffUtils.isValidPerm(permissionName, isFranchise, isCorp, item.isCenterUser);
    }

    /**
     * Enter c&p mode
     * @param item
     */
    startCopy(item: UserWithAllPermissionsTableItem) {
        this.copyStaffObject = cloneDeep(item);
        document.addEventListener('keydown', this.handleEsc);
    }

    // The checkboxes in the header row are select/deselect all
    private togglePermissionColumn(e: boolean, permissionName: string) {
        const items: Array<UserWithAllPermissionsTableItem> = this.getStaff;
        for (const item of items) {
            if (!this.isReadOnly(item)) {
               for (const permission of item.permissions) {
                    if (permission.label === permissionName && !this.isGroupLocked(item, permissionName)) {
                        permission.grants.update = e;
                        staffUtils.handlePostClick(e, permissionName, item.permissions, permissionsState.stored, interfaceSettingsState.enhancedPermissions);
                        break;
                    }
                }
            }
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const userPermissionsDtoArray: Array<UserWithAllPermissionsDto> = [];
        for (const item of this.staff) {
            const permissionsDto = {
                staff: item.staff.id,
                permissions: []
            } as UserWithAllPermissionsDto;
            for (const permission of item.permissions) {
                permissionsDto.permissions.push(userPermissionMapper.toUpdateDto(permission as UserPermission));
            }
            userPermissionsDtoArray.push(permissionsDto);
        }

        await bulkUserPermissionsRepository.setPermissionsForAllStaff(userPermissionsDtoArray);
        this.close();
        loadingState.loadingDecrement(this.loadingKey);
    }
}
