import { AbstractRepository } from '@/repositories/abstract-repository';
import { Contact, ContactCreateDto, ContactUpdateDtoInterface } from '@/families/models/contact';

export class ContactsRepository extends AbstractRepository<Contact> {
    protected endpoint = ''; // Unused; here to fulfill implementation contract

    public async retrieveAll(familyId: number): Promise<Array<Contact>> {
        const response = await this.client.get<Array<Contact>>(`families/${familyId}/contacts`);
        return response.data;
    }

    public async retrieveOne(familyId: number, contactId: number): Promise<Contact> {
        const response = await this.client.get<Contact>(`families/${familyId}/contacts/${contactId}`);
        return response.data;
    }

    public async createOne(familyId: number, newContact: ContactCreateDto): Promise<Contact> {
        // We don't have a single createOne on the API, so pass in an a single item array.
        return (await this.client.post<Contact>(`families/${familyId}/contacts`, [newContact])).data;
    }

    public async updateOne(familyId: number, contact: ContactUpdateDtoInterface): Promise<Contact> {
        return (await this.client.put<Contact>(`families/${familyId}/contacts/${contact.id}`, contact)).data;
    }

    public async deleteOne(familyId: number, contactId: number): Promise<void> {
        await this.client.delete<Contact>(`families/${familyId}/contacts/${contactId}`);
    }
}
