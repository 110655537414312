import { WorkflowField, WorkflowType } from '@/automation/workflows/models/workflow-type-models';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { WorkflowTypesRepository } from '@/automation/workflows/repositories/workflow-types-repository';

interface EntityFieldsHash {
    [key: number]: Array<WorkflowField>;
}

interface ValuesHash {
    [key: number]: Array<WorkflowType>;
}

interface FieldValuesHash {
    [key: number]: ValuesHash;
}

export interface WorkflowTypesState {
    entity_fields: EntityFieldsHash;
    field_values: FieldValuesHash;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.WORKFLOWS_TYPES
})
export class WorkflowsTypesStore extends VuexModule implements WorkflowTypesState {
    readonly repository = new WorkflowTypesRepository();

    entity_fields: EntityFieldsHash = {};
    field_values: FieldValuesHash = {};

    @Mutation
    private storeEntityFields(params: { entityId: number; fields: Array<WorkflowField> }) {
        this.entity_fields[params.entityId] = params.fields;
    }

    @Action
    public async retrieveEntityFields(entityId: number) {
        if (!this.entity_fields[entityId]) {
            const fields = await this.repository.getFieldsForEntity(entityId);
            this.context.commit('storeEntityFields', { entityId, fields });
        }
    }

    get entityFields() {
        return (entityId: number) => {
            return this.entity_fields[entityId];
        };
    }

    @Mutation
    private storeFieldValues(params: { entityId: number; fieldId: number; values: Array<WorkflowField> }) {
        if (!this.field_values[params.entityId]) {
            this.field_values[params.entityId] = {};
        }
        this.field_values[params.entityId][params.fieldId] = params.values;
    }

    @Action
    public async retrieveFieldValues(params: {entityId: number; fieldId: number }) {
        if (!this.field_values[params.entityId] || !this.field_values[params.entityId][params.fieldId]) {
            const values = await this.repository.getValuesForField(params.entityId, params.fieldId);
            this.context.commit('storeFieldValues', { entityId: params.entityId, fieldId: params.fieldId, values });
        }
    }

    get fieldValues() {
        return (entityId: number, fieldId: number) => {
            return this.field_values[entityId][fieldId];
        };
    }
}
