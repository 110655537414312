


























































    import { LocaleMixin } from '@/locales/locale-mixin';
    import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
    import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
    import type { FamiliesFilterDtoInterface } from '@/filters/models/families-filter';
    import { getYearsAndMonths } from '@/date-time/date-time-utils';

@Component
    export default class FilterTextChildAge extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: FamiliesFilterDtoInterface;
    @Prop() readonly fieldPrefix!: string;
    @Prop() readonly label!: string;
    @Prop({ default: false }) readonly isDisabled!: boolean;

    get displayEndYearsAndMonths(): string {
        return (this.endingAge === 0 || this.endingAge === null || this.endingAge?.toString() === '' || typeof this.endingAge !== 'number')
            ? 'mo' : 'mo' + ' ' + '(' + getYearsAndMonths(this.endingAge) + ')';
    }

    get displayStartYearsAndMonths(): string {
        return (this.startingAge === 0 || this.startingAge === null || this.startingAge?.toString() === '' || typeof this.startingAge !== 'number')
            ? 'mo' : 'mo' + ' ' + '(' + getYearsAndMonths(this.startingAge) + ')';
    }

    get endingAge() {
        return this.filter.age_in_months_end;
    }

    get endField() {
        return this.fieldPrefix + '_end';
    }

    get filter(): FamiliesFilterDtoInterface {
        return this.value;
    }

    set filter(newValue: FamiliesFilterDtoInterface) {
        this.$emit('input', newValue);
    }

    get nonRequiredInteger() {
        return [
            (v: string | number) => (/^[0-9]+$/).test(String(v)) || !v || 'Please enter a valid whole number'
        ];
    }

    get nonRequiredIntegerLargerThanStartingAge() {
        return [
            (v: string | number) => (/^[0-9]+$/).test(String(v)) || !v || 'Please enter a valid whole number',
            this.startingAge?.toString() === '' || this.endingAge?.toString() === '' || this.startingAge === null || this.endingAge === null || this.startingAge < this.endingAge || 'Ending Age must be larger than Starting Age'
        ];
    }

    get startingAge() {
        return this.filter.age_in_months_start;
    }

    get startField() {
        return this.fieldPrefix + '_start';
    }

    @Watch('startingAge')
    updateStartingAgeOutput() {
        if (this.startingAge !== null && this.startingAge?.toString() !== '') {
                this.$emit('input-starting-age', true);
        } else {
            if (this.endingAge === null || this.endingAge?.toString() === '') {
                this.$emit('input-starting-age', false);
            } else {
                this.$emit('input-starting-age', true);
            }
        }
    }

    @Watch('endingAge')
    updateEndingAgeOutput() {
        if (this.endingAge !== null && this.endingAge?.toString() !== '') {
            this.$emit('input-ending-age', true);
        } else {
            if (this.startingAge === null || this.startingAge?.toString() === '') {
                this.$emit('input-ending-age', false);
            } else {
                this.$emit('input-ending-age', true);
            }
        }
    }
    }
