import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { Status } from '@/families/models/status';
import { StatusesRepository } from '@/families/repositories/statuses-repository';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { BaseStatuses } from '@/constants/status-constants';

interface StatusesState {
    storedStatuses: Array<Status>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.STATUSES
})
export class StatusesStore extends AbstractApiStore<Status> implements StatusesState {
    readonly repository = new StatusesRepository();

    storedStatuses: Array<Status> = [];

    @Mutation
    private storeStatuses(statuses: Array<Status>): void {
        this.storedStatuses = statuses;
    }

    @Action
    public async init() {
        if (this.storedStatuses.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveStatuses();
                }
            });
        }
    }

    @Action({ commit: 'storeStatuses' })
    public async retrieveStatuses() {
        const statuses = await this.retrieveAll();
        return statuses;
    }

    get statuses() {
        return this.storedStatuses;
    }

    get activeStatuses(): Array<Status> {
        return this.storedStatuses.filter(status => !status.is_archive);
    }

    get activeStatusIds(): Array<number> {
        return this.activeStatuses.map(status => status.id);
    }

    get hasLostOpp(): boolean {
        return this.storedStatuses.map((status) => status.id).includes(BaseStatuses.LOST_OPP);
    }
}
