





































































































































































import FamilyActivityCalls from '@/families/components/new/FamilyActivityCalls.vue';
import FamilyActivityPendingEmails from '@/families/components/new/FamilyActivityPendingEmails.vue';
import FamilyActivityPendingTexts from '@/families/components/new/FamilyActivityPendingTexts.vue';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { TextMessage } from '@/communications/messages/models/text';
import { EmailThread } from '@/communications/messages/models/email';
import { EventTypes } from '@/constants/event-type-constants';
import { FacebookMessage } from '@/communications/messages/models/facebookMessage';
import type { Family } from '@/families/models/family';
import FamilyTextsPane from '@/families/components/FamilyTextsPane.vue';
import FamilyFBMessagesPane from '@/families/components/FamilyFBMessagesPane.vue';
import FamilyActivityEmailTable from '@/families/components/new/FamilyActivityEmailTable.vue';
import FamilyCallCenterPane from '@/families/components/FamilyCallCenterPane.vue';
import { InContactEvent } from '@/integrations/models/in-contact-event';
import { Recording } from '@/communications/recordings/models/recording-models';

@Component({
    components: {
        FamilyActivityCalls,
        FamilyActivityPendingEmails,
        FamilyActivityPendingTexts,
        FamilyTextsPane,
        FamilyFBMessagesPane,
        FamilyCallCenterPane,
        FamilyActivityEmailTable
    }
})
export default class FamilyActivityCommunications extends Mixins(FamilyHubMixin, LocaleMixin) {
    // The id of the family
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ required: true }) readonly texts!: Array<TextMessage>;
    @Prop({ required: true }) readonly fbMessages!: Array<FacebookMessage>;
    @Prop({ required: true }) readonly emailThreads!: Array<EmailThread>;
    @Prop({ required: true }) readonly inContactEvents!: Array<InContactEvent>;
    @Prop({ required: true }) readonly recordings!: Array<Recording>;
    @Prop({ required: true }) readonly unreadChat!: number;
    @Prop({ required: true }) readonly unreadEmail!: number;
    @Prop({ required: true }) readonly unlistenedCall!: number;
    @Prop() readonly renderKey!: number;
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;
    private pendingTextCancelledEvent = EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent = EventTypes.PENDING_TEXT_UPDATED;
    private updatedEvent = EventTypes.UPDATED;
    private commExpansionPanels: Array<number> = [];
    // indices for commExpansionPanels
    private chatIndex = 0;
    private pendingIndex = 1;
    private emailIndex = 2;
    private callsIndex = 3;
    private callCenterIndex = 4;

    newQuickText(newText: TextMessage) {
        this.$emit('new-quick-text', newText);
    }

    newFacebookMessage(newFacebookMessage: FacebookMessage) {
        this.$emit('new-facebook-message', newFacebookMessage);
    }

    @Watch('renderKey', { immediate: true })
    private dataChanged() {
        this.updatePanels();
    }

    private updatePanels() {
        this.commExpansionPanels = [];
        // expand the panels that have unread messages
        if (this.unreadChat) {
            this.commExpansionPanels.splice(this.chatIndex, 0, this.chatIndex);
        }
        if (this.unreadEmail) {
            this.commExpansionPanels.splice(this.emailIndex, 0, this.emailIndex);
        }
        if (this.unlistenedCall) {
            this.commExpansionPanels.splice(this.callsIndex, 0, this.callsIndex);
        }
    }

    private replied() {
        this.$emit(EventTypes.REPLIED);
    }

    private pendingEmailCancelled() {
        this.$emit(EventTypes.PENDING_EMAIL_CANCELLED);
    }

    private pendingEmailUpdated() {
        this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
    }

    private pendingTextCancelled() {
        this.$emit(EventTypes.PENDING_TEXT_CANCELLED);
    }

    private pendingTextUpdated() {
        this.$emit(EventTypes.PENDING_TEXT_UPDATED);
    }

    private updateToDos() {
        this.$emit(EventTypes.UPDATED);
    }
}
