var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-dialog',{attrs:{"id":"affected-locations-modal","scrollable":"","persistent":"","content-class":"dialog-medium"},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.isForWorkflows ? 'Affected Locations' : 'Available Locations')+" "),_c('v-spacer'),_c('base-button-plain',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('base-close')],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"ma-0"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.isLocationGroupsFeatureEnabled)?_c('base-button-secondary',{staticClass:"mt-4",on:{"click":function($event){_vm.locationGroupsMode = !_vm.locationGroupsMode}}},[_vm._v(" "+_vm._s(_vm.locationGroupsMode ? 'Switch to Locations' : 'Switch to Location Groups')+" ")]):_vm._e()],1),(!_vm.locationGroupsMode)?_c('v-data-table',{staticClass:"affected-locations-modal-all-locations-list",attrs:{"dense":"","headers":_vm.locationHeaders,"items":_vm.centersAvailable,"sort-by":"center.name","item-key":"center.id","disable-pagination":"","hide-default-footer":"","items-per-page":-1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.center.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.center.code)+" ")]}},{key:"item.center.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.center.name)+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-n1",attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.toggleCenter()}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}}],null,false,1620087123)}):_c('v-data-table',{staticClass:"affected-locations-modal-all-locations-list",attrs:{"dense":"","headers":_vm.locationGroupHeaders,"items":_vm.groupsAvailable,"sort-by":"locationGroup.value","item-key":"locationGroup.id","disable-pagination":"","hide-default-footer":"","items-per-page":-1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.locationGroup.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.locationGroup.values.value)+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-n1",attrs:{"dense":"","hide-details":""},on:{"change":function($event){return _vm.toggleGroup(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}}])}),(_vm.locationGroupsMode)?_c('span',{staticStyle:{"color":"grey"}},[_vm._v(" Note that if you enable "+_vm._s(_vm.isForWorkflows ? 'an automation' : 'a drip campaign')+" for a location group and then disable it for one of the locations within that group, or vice-versa, the location-specific setting will take precedence over the group setting. "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.isForWorkflows ? 'An automation' : 'A drip campaign')+" will appear “Enabled” if any location within that group has the "+_vm._s(_vm.entityType)+" enabled. To ensure all locations in that group have "+_vm._s(_vm.isForWorkflows ? 'an automation' : 'a drip campaign')+" enabled, disable and then enable the rule for that group. ")]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('base-button-primary',{staticClass:"mb-2",on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('base-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.isUpdated=false}}},'v-btn',attrs,false),[_c('base-close')],1)]}}]),model:{value:(_vm.isUpdated),callback:function ($$v) {_vm.isUpdated=$$v},expression:"isUpdated"}},[_vm._v(" "+_vm._s(_vm.workflow !== null ? 'Affected Locations Updated' : 'Changes Applied')+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }