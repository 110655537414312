





































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { ModalMixin } from '@/core/modal-mixin';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import { encodeFile } from '@/core/file-utils';
import { FamilyDocumentsRepository } from '@/families/repositories/family-documents-repository';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import type { Family } from '@/families/models/family';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const docsRepo = new FamilyDocumentsRepository();
const loadingState = getModule(LoadingStore);

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class FamilyDataUploadDocumentsModal extends Mixins(BasicValidationMixin, LocaleMixin, ModalMixin) {
    @Prop() family!: Family;

    @Ref('form') readonly form!: VForm;

    private loadingKey = 'familyDataUploadDocumentModal';
    private isValid = false;
    private files: Array<File> = [];
    private maxSize = 16 * 1024 * 1024; // 16 MB max
    private selectedChild: number | null = null;

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private activationChanged() {
        if (this.form) {
            this.form.reset();
        }
        this.selectedChild = null;
    }

    private get children() {
        return this.family.children;
    }

    // Check that a single document size is not exceeding 16MB
    private checkSize() {
        for (const file of this.files) {
            if (!file) {
                this.isValid = false;
            }
            if (file.size > this.maxSize) {
                this.isValid = false;
                return 'File size is too large';
            } else {
                this.isValid = true;
            }
        }
        return true;
    }

    // Remove file
    private async removeFile(index: number) {
        this.files.splice(index, 1);
    }

    // Upload a new document
    private async uploadFiles() {
        this.isValid = false;
        loadingState.loadingIncrement(this.loadingKey);
        for (const file of this.files) {

            if (!file || !file.name || !this.family) {
                return;
            }
            const fileContents = await encodeFile(file);
            await docsRepo.createDoc(this.family.id, {
                name: file.name.split('.').slice(0, -1).join('.'),
                filename: file.name,
                content: fileContents
            }, this.selectedChild);
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.closeDialog();
        this.$emit(EventTypes.UPDATED);
    }

    private closeDialog() {
        this.isValid = false;
        this.files = [];
        this.close();
    }
}
