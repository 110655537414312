import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

export interface RequiredFieldPayload {
    familyId: number | null;
    fieldName: string;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.REQUIRED_FIELDS
})
export class RequiredFieldsStore extends VuexModule {
    private requiredFields: Set<RequiredFieldPayload> = new Set();
    private requiredFieldsArray: Array<RequiredFieldPayload> = [];

    @Mutation
    addToList(payload: RequiredFieldPayload) {
        this.requiredFields.add(payload);
        this.requiredFieldsArray.push(payload);
    }

    @Mutation
    clear(familyId: number | null) {
        this.requiredFields.clear();
        this.requiredFieldsArray = this.requiredFieldsArray.filter((fieldPayload) => {
            return fieldPayload.familyId !== familyId;
        });
        for (const fieldPayload of this.requiredFieldsArray) {
            this.requiredFields.add(fieldPayload);
        }
    }

    @Mutation
    removeFromList(payload: RequiredFieldPayload) {
        this.requiredFields.forEach(
            (value) => {
                if (value.familyId === payload.familyId && value.fieldName === payload.fieldName) {
                    this.requiredFields.delete(value);
                }
            }
        );
        this.requiredFieldsArray = Array.from(this.requiredFields);
    }

    get fieldsRequired() {
        return (familyId: number | null): boolean => {
            return this.fields(familyId).length > 0;
        };
    }

    get fields() {
        return (familyId: number | null): Array<RequiredFieldPayload> => {
            return this.requiredFieldsArray.filter((fieldPayload) => {
                return fieldPayload.familyId === familyId;
            });
        };
    }
}
