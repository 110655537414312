










































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Email, EmailThread, IncomingEmail, OutgoingEmail } from '@/communications/messages/models/email';
import ViewSingleEmail from '@/communications/messages/components/ViewSingleEmail.vue';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { FamiliesStore } from '@/families/store/families-store';
import { EventTypes } from '@/constants/event-type-constants';
import { Family } from '@/families/models/family';
import { LoadingStore } from '@/store/loading-store';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getReplyBody, getReplySubject } from '@/communications/messages/email-utils';
import { CommunicationTypes } from '@/communications/communication-constants';
import { MessageDirection, MessageLinkParams, OutgoingStatusString } from '@/communications/messages/models/message';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import store from '@/store';
import { isAfterNow } from '@/date-time/date-time-utils';
import { scrollToSelector } from '@/core/scroll-to';

const authState = getModule(AuthStore, store);
const familyStore = getModule(FamiliesStore);
const loadingState = getModule(LoadingStore);
const emailsRepo = new EmailsRepository();

@Component({
    components: { SendMessageModal, ViewSingleEmail }
})
export default class ViewEmailThread extends Mixins(LocaleMixin) {
    $refs!: {
        mainDiv: HTMLElement;
        email: HTMLElement;
    }

    @Prop({ required: true }) readonly thread!: EmailThread;
    @Prop({ default: false }) readonly inbox!: boolean;
    @Prop({ default: false }) readonly fromEverything!: boolean;

    private loadingKey = LOADING_GLOBAL_KEY;
    private showDetails = false;
    private showDetailsForPendingEmails = false;
    private needsMore = false;
    private isEllipsisActive = false;
    private emailType = CommunicationTypes.EMAIL;
    private startReplyEvent = EventTypes.START_REPLY;
    private closeReplyEvent = EventTypes.CLOSE;
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;
    private replyActivated = false;
    private replyFamily: Family | null = null;
    private replySubject = '';
    private replyBody = '';
    private replyToId = 0;

    get isMulti() {
        return this.thread.length > 1;
    }

    get openable() {
        return this.needsMore || this.isMulti;
    }

    get selector() {
        if (!this.thread.length) {
            return '';
        }
        return (this.fromEverything ? 'email-everything-' : 'email-thread-') + this.thread[0].id;
    }

    get showMoreText() {
        return this.isMulti ? 'View Thread(' + this.thread.length + ')' : 'Show More';
    }

    get shownEmails() {
        return this.showDetails ? this.thread : this.thread.slice(0, 1);
    }

    get shownPendingEmails() {
        return this.showDetailsForPendingEmails ? this.thread : this.thread.slice(0, 1);
    }

    get mainClass() {
        const classes = ['thread-container'];
        classes.push(this.showDetailsForPendingEmails ? 'thread-container-open' : 'thread-container-closed');
        if (this.needsMore && !this.showDetailsForPendingEmails) {
            classes.push('thread-fade');
        }
        return classes.join(' ');
    }

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    @Watch('inbox', { immediate: true })
    inboxMode(flag: boolean) {
        if (flag) {
            this.showDetails = true;
            this.showDetailsForPendingEmails = true;
        }
    }

    @Watch('showDetails')
    private async scrollToCard() {
        if (this.showDetails) {
            return;
        }
        await this.$nextTick();
        // So we jump to the top of the collapsed card
        await scrollToSelector(this.$vuetify, '#' + this.selector);
    }

    async mounted() {
        if (this.inbox) {
            return;
        }
        if (this.$route.query[MessageLinkParams.EMAIL]) {
            const incomingId = Number(this.$route.query[MessageLinkParams.EMAIL]);
            if (this.thread.filter((email) => {
                return email.type === MessageDirection.INCOMING && email.id === incomingId;
            }).length) {
                this.expand();
            }
        }
        if (this.isPending(this.thread[0])) {
            if (this.$refs.mainDiv.scrollHeight > this.$refs.mainDiv.clientHeight) {
                this.needsMore = true;
            }
        }
    }

    expand() {
        if (!this.showDetails) {
            this.showDetails = true;
        }
    }

    showMore() {
        if (!this.showDetailsForPendingEmails && this.openable) {
            this.showDetailsForPendingEmails = true;
        }
    }

    private isIncoming(email: Email): boolean {
        return email.type === MessageDirection.INCOMING;
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    private isPending(email: Email): boolean {
        if (!this.isIncoming(email) && (email as OutgoingEmail).status === OutgoingStatusString.PENDING) {
            if (!email.sent_date_time) {
                return true;
            }
            return isAfterNow(email.sent_date_time);
        }

        return false;
    }

    private async startReply(email: IncomingEmail) {
        const familyId = email.send_to_guardian?.values.family_id;
        if (!familyId) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        this.replyFamily = await familyStore.getById(familyId);
        this.replySubject = getReplySubject(email.subject);
        this.replyBody = getReplyBody(email.html, email.send_to_guardian?.values.name ?? '');
        this.replyToId = email.id;
        this.replyActivated = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async replySent(newOutgoing: OutgoingEmail, incomingId: number) {
        this.$emit(EventTypes.REPLIED, newOutgoing, incomingId);
    }

    private async cancelPendingEmail(id: number) {
        await this.$swal({
            text: 'Are you sure you want to cancel sending this email message?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                await emailsRepo.cancelPendingEmail(id);
                this.pendingEmailCancelled();
            }
        });
    }

    private pendingEmailCancelled() {
        this.$emit(EventTypes.PENDING_EMAIL_CANCELLED);
    }

    private pendingEmailUpdated() {
        this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
    }

}
