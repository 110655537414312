import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import {
    Enrollment,
    EnrollmentCreateDtoInterface,
    EnrollmentDateReason, EnrollmentDateReasonWrite,
    EnrollmentUpdateDtoInterface, WaitlistDetails, WaitlistDetailsUpdate
} from '@/families/models/enrollment';

export class EnrollmentMapper extends AbstractMapper<Enrollment> {
    toCreateDto(entity: Enrollment): EnrollmentCreateDtoInterface {
        return {
            child_id: entity.child.id,
            center_id: entity.center.id,
            classroom_id: entity.classroom ? entity.classroom.id : null,
            child_of_staff: entity.child_of_staff,
            schedule: entity.schedule,
            wait_list_date: entity.wait_list_date,
            wait_list: this.waitlistDto(entity.wait_list),
            expected_start_date: entity.expected_start_date,
            enrollment: this.objectToDto(entity.enrollment),
            temporary_leave: this.objectToDto(entity.temporary_leave),
            withdrawn: this.objectToDto(entity.withdrawn),
            lost_opportunity_reason: entity.lost_opportunity_reason ? entity.lost_opportunity_reason.id : null
        };
    }

    toUpdateDto(entity: Enrollment): EnrollmentUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as EnrollmentUpdateDtoInterface;
        if (entity.id != null) {
            dto.id = entity.id;
        }
        return dto;
    }

    private objectToDto(dateReason: EnrollmentDateReason|null = null): EnrollmentDateReasonWrite|null {
        if (dateReason === null) {
            return dateReason;
        }
        return {
            date: dateReason.date as string,
            reason: dateReason.reason ? dateReason.reason.id : null,
            comments: dateReason.comments ? dateReason.comments : ''
        };
    }

    private waitlistDto(details: WaitlistDetails | null = null): WaitlistDetailsUpdate | null {
        if (!details) {
            return details;
        }
        return {
            date: details.date as string,
            reason: details.reason ? details.reason.id : null,
            comments: details.comments ?? '',
            fee: details.fee,
            fee_paid_datetime: details.fee_paid_datetime,
            is_fee_paid: details.is_fee_paid,
            priority: details.priority ? details.priority.id : null,
            type: details.type ? details.type.id : null
        };
    }
}
