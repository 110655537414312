

























































































































import SaveButton from '@/components/base/SaveButton.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { ModalMixin } from '@/core/modal-mixin';
import { CenterCallSettingsMapper } from '@/organizations/locations/mappers/center-call-settings-mapper';
import type { Center } from '@/organizations/locations/models/center';
import {
    CenterCallSettings,
    CenterCallSettingsDto,
    CenterSettingsModalPayload
} from '@/organizations/locations/models/center-call-settings';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const centersRepo = new CentersRepository();
const loadingStore = getModule(LoadingStore);
const mapper = new CenterCallSettingsMapper();

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class LocationCallSettingsModal extends Mixins(BasicValidationMixin, LocaleMixin, ModalMixin) {
    /**
     * The center to modify call settings for.
     */
    @Prop({ required: true }) readonly location!: Center;
    @Prop({ default: false }) readonly isAdd!: boolean;

    @Ref('form') readonly form!: VForm;

    private dto: CenterCallSettingsDto | null = null;
    private isValid = false;
    private loadingKey = '';

    /**
     * Whether the form can be saved or not.
     */
    private get canSave() {
        return this.isValid;
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.form) {
            this.form.reset();
        }
        if (this.modelValue) {
            // Create the dto and other stuff
            this.dto = mapper.toCreateDto({} as CenterCallSettings);
        }
    }

    /**
     * Request a phone number and save the settings.
     */
    private async save() {
        if (!this.dto) {
            return;
        }
        if (this.isAdd) {
            this.$emit(
                EventTypes.UPDATED,
                {
                    crm_phone_number: 'tbd',
                    dto: cloneDeep(this.dto)
                } as CenterSettingsModalPayload
            );
            this.close();
            return;
        }
        loadingStore.loadingIncrement(this.loadingKey);
        const responses = await Promise.all([
            centersRepo.provisionCenterNumber(this.location.id),
            centersRepo.setCenterCallSettings(this.location.id, this.dto)
        ]);
        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(
            EventTypes.UPDATED,
            {
                crm_phone_number: responses[0].crm_phone_number,
                dto: cloneDeep(this.dto)
            } as CenterSettingsModalPayload
        );
        this.close();
    }
}
