
















    import { LocaleMixin } from '@/locales/locale-mixin';
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { ChangesHistoryTableData, ChangeType, FieldChange } from '@/families/models/update';
    import { DataTableHeader } from 'vuetify';
    import { CrmTypeOption } from '@/crm-types/models/crm-type';
    import type { Change } from '@/families/models/update';

@Component
    export default class ViewChangesHistory extends Mixins(LocaleMixin) {
        @Prop({ required: true }) readonly change!: Change;
        @Prop({ required: true }) readonly typeOptions!: Array<CrmTypeOption> | null;
        @Prop() readonly user!: string;

        private changesHistoryHeaders: Array<DataTableHeader> = [
            { text: 'User', value: 'user' },
            { text: 'Date', value: 'date' },
            { text: 'Type', value: 'type' },
            { text: 'Field', value: 'field' },
            { text: 'Previously', value: 'from' },
            { text: 'Changed To', value: 'to' }
        ];

    private get changesHistoryTableData() {
        return this.change.field_changes.map((fieldChange: FieldChange) => {
            return {
                user: this.user,
                date: fieldChange.date_time,
                type: fieldChange.type === ChangeType.LEAD ? ChangeType.GUARDIAN : fieldChange.type,
                field: fieldChange.field_name,
                from: fieldChange.from,
                to: fieldChange.to
            } as ChangesHistoryTableData;
        });
    }

    }
