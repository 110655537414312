<template>
    <router-view />
</template>

<script>
    import { Vue, Component } from 'vue-property-decorator';
    @Component
    export default class EmptyRouterLayout extends Vue {
        // This component just has a router-view so that nested children routes can load their components correctly

    }
</script>
