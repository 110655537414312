






























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import { AppStateStore } from '@/store/app-state-store';
import { DataTableHeader } from 'vuetify';
import { FamilyLastViewedInfo } from '@/families/models/family';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const featuresStore = getModule(FeaturesStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component
export default class LocationDashboardRecentlyViewed extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    // Props
    @Prop({ required: true }) readonly items!: Array<FamilyLastViewedInfo>;
    @Prop() readonly breakpoint!: string;

    // Properties
    private headers: Array<DataTableHeader> = [
        {
            text: 'Guardian Name',
            value: 'guardian'
        },
        {
            text: 'Last Viewed',
            value: 'lastViewed',
            width: this.lastViewedWidth
        }
    ];

    // Computed Getter / Setters
    get canAddCrmTabs(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && authState.isEnrollmentTeamMember;
    }

    get lastViewedWidth() {
        switch (this.breakpoint) {
            case 'sm':
                return '18ch';
            case 'md':
                return '21ch';
            case 'lg':
                return '22ch';
            case 'xl':
            default:
                return '25ch';
        }
    }

    get newTabStatus(): boolean {
        return appState.familyHubTargetMode;
    }

    private get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    // Methods
    private getToolTip(item: FamilyLastViewedInfo): string {
        const noChildren = 'Children: none';

        if (item.children) {
            const childrenNames: Array<string> = [];

            switch (item.children.length) {
                case 0:
                    return noChildren;
                case 1:
                    return 'Child: ' + item.children[0].values.name;
                default:
                    item.children.forEach((child) => {
                        childrenNames.push(child.values.name as string);
                    });

                    return 'Children: ' + childrenNames.join(', ');
            }
        }

        return noChildren;
    }

    // Methods
    private async goToFamilyHub(item: FamilyLastViewedInfo) {
        if (this.newTabStatus) {
            if (this.canAddCrmTabs) {
                await layoutTabsStore.addTab({ routeName: 'family-hub', routeParams: { id: String(item.primary_guardian.values.family_id) }, goTo: true });
                return;
            }

            const routeData = this.$router.resolve({ name: 'family-hub', params: { id: item.primary_guardian.values.family_id.toString() } });
            window.open(routeData.href, '_blank');
        } else {
            await this.$router.push({ name: 'family-hub', params: { id: item.primary_guardian.values.family_id.toString() } });
        }
    }
}
