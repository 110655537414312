import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Org, OrgCreateDtoInterface, OrgUpdateDtoInterface } from '@/models/organization/org';

export class OrgsMapper implements AbstractMapper<Org> {
    toCreateDto(entity: Org): OrgCreateDtoInterface {
        return {
            code: entity.code,
            name: entity.name,
            level: entity.level,
            parent_organization: entity.parent_organization!.id
        };
    }

    toUpdateDto(entity: Org): OrgUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as OrgUpdateDtoInterface;
        dto.id = entity.id as number;
        return dto;
    }
}
