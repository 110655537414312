var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-0 pt-0"},[_c('v-row',{staticClass:"justify-space-between align-center ma-0 px-0 family-action-buttons",attrs:{"dense":"","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"play-button"},[_vm._v(" mdi-play-circle ")])],1)]}}])},[_c('span',[_vm._v("Play")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(!_vm.recording.is_read)?_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-marked-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.recording.is_read ? 'Mark Unread' : 'Mark Read'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(!_vm.recording.is_dismissed)?_c('v-icon',[_vm._v(" mdi-package-down ")]):_c('v-icon',[_vm._v(" mdi-package-up ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.recording.is_dismissed ? 'Unarchive' : 'Archive'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account ")])],1)]}}])},[_c('span',[_vm._v("Visit Family")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }