
































import { AppStateStore } from '@/store/app-state-store';
import { BasicFamilySearchResult, BasicFamilySearchResultType } from '@/families/models/family';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { basicSearchByOrgId } from '@/families/search-families';
import { getModule } from 'vuex-module-decorators';
import { FamiliesStore } from '@/families/store/families-store';

const appState = getModule(AppStateStore);
const familyStore = getModule(FamiliesStore);

@Component({})
export default class FamilySearch extends Mixins(LocaleMixin) {
    // Whether or not to append an icon
    @Prop({ type: Boolean, default: true }) readonly appendIcon!: boolean;
    // Whether or not to autofocus
    @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;
    // Whether or not the clear out the search string after a selection is made.
    @Prop({ type: Boolean, default: false }) readonly clearChoice!: boolean;
    // Whether or not results should be disabled if an email may not be sent to family.
    @Prop({ type: Boolean, default: false }) readonly disableBadEmail!: boolean;
    // Whether or not results should be disabled if a text message may not be sent to family.
    @Prop({ type: Boolean, default: false }) readonly disableBadText!: boolean;
    // Should there be elevation?
    @Prop({ type: Boolean, default: false }) readonly flat!: boolean;
    // The label.
    @Prop({ type: String, default: 'Search Families' }) readonly label!: string;
    // Whether to return the family entity or just the id.
    @Prop({ type: Boolean, default: false }) readonly returnEntity!: boolean;
    // Whether or not to have the solo style
    @Prop({ type: Boolean, default: false }) readonly solo!: boolean;
    // Skip over children in search.
    @Prop({ type: Boolean, default: false }) readonly skipChildren!: boolean;
    // skip over contacts in search.
    @Prop({
        type: Boolean,
        default: false
    }) readonly skipContacts!: boolean;

    @Prop({
        type: Boolean,
        default: false
    }) readonly dense!: boolean;

    $refs!: {
        searchComponentInput: HTMLSelectElement;
    };

    private isDisabled = true;
    private items: Array<any> = [];
    private familyString: string | null | undefined = null;
    private searching = false;
    private searchResults: Array<BasicFamilySearchResult> | null = null;
    private searchString = '';
    private searchIcon = this.appendIcon ? 'search' : '';

    /**
     * Get the stored center.
     */
    private get center() {
        return appState.storedCurrentCenter;
    }

    private get isSearching() {
        if (!this.searchResults) {
            return true;
        } else if (this.searchString && this.searchString.length < 3) {
            return true;
        } else {
            return this.searching;
        }
    }

    // Get the stored org
    private get org() {
        return appState.storedCurrentOrg;
    }

    private get needsFocusFlag() {
        return appState.searchNeedsFocus;
    }

    // Whether or not disabled families may appear in the search results
    private get isDisableMode() {
        return this.disableBadEmail || this.disableBadText;
    }

    // Make sure the org is loaded before enabling the search.
    @Watch('org', { immediate: true })
    private enableSearch() {
        // change this to be org
        if (this.org) {
            this.isDisabled = false;
        }
    }

    @Watch('needsFocusFlag')
    focusFlagChanged() {
        if (this.needsFocusFlag && this.solo) {
            this.$refs.searchComponentInput.focus();
            appState.setSearchNeedsFocus(false);
        }
    }

    // Watch the family selection, parse the entity/id from the choice, and return the formatted choice.
    @Watch('familyString')
    private async changeFamily() {
        let response = null;
        if (this.familyString !== null && this.familyString !== undefined) {
            const familyId = parseInt(this.familyString.split('-')[0]);
            if (this.returnEntity) {
                if (this.searchResults !== null && typeof this.searchResults !== 'undefined') {
                    // Get a family entity to return from the family store.
                    response = await familyStore.getById(familyId);
                }
            } else {
                response = familyId;
            }
        }

        // Send the family or id to the parent component.
        this.$emit('input', response);

        // Clear the input after selection, if desired.
        // This is needed after page navigation.
        if (this.clearChoice && this.familyString !== null && this.familyString !== undefined) {
            // Wait for a rendering cycle to make sure the clearing appears in the dom.
            this.$nextTick(() => {
                this.searchString = '';
                this.familyString = null;
            });
        }
    }

    private clearWithBackspace() {
        if (!this.searchString) {
            this.clearSearch();
        }
    }

    // Clear out the search results and options
    private clearSearch(emitBlur = false): void {
        if (this.clearChoice) {
            this.searchString = '';
            this.items = [];
            this.searchResults = null;
            if (emitBlur) {
                this.$emit('blur');
            }
        }
    }

    // Perform a search against the API.
    private async doFamilySearch(searchString: string) {
        this.searching = false;
        this.items = [];

        if (searchString && searchString !== '') {
            this.searching = true;
            const selectItems: Array<any> = [];
            let disabled = false;

            // Do search, await results, update data with results.
            const isAtCenter = Boolean(this.center);
            const orgId = this.org?.id as number;
            let appendedCode = '';
            let statusCode = '';
            let phone = '';
            const phoneQuery = searchString.replace(/\D+/g, '');

            this.searchResults = await basicSearchByOrgId(searchString, orgId, this.skipChildren, this.skipContacts);
            this.searchResults.forEach((result) => {
                appendedCode = !isAtCenter ? result.center_code : '';
                if (appendedCode !== '') {
                    appendedCode = ` (${appendedCode})`;
                }

                statusCode = result.status_code ? result.status_code : '';
                if (statusCode !== '') {
                    statusCode = ` (${statusCode})`;
                }

                disabled = false;
                if (this.disableBadEmail) {
                    disabled = result.do_not_email;
                }
                if (this.disableBadText) {
                    disabled = disabled || result.do_not_text;
                }

                if (result.type === BasicFamilySearchResultType.LEAD) {
                    phone = result.lead_phone ?? '';
                    if (result.lead_full_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                        selectItems.push({
                            text: result.lead_full_name + appendedCode + statusCode,
                            value: result.lead_id + '-g' + result.lead_id,
                            disabled: disabled
                        });
                    } else {
                        if (result.lead_email && result.lead_email.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                            selectItems.push({
                                text: result.lead_email + ' - ' + result.lead_full_name + appendedCode + statusCode,
                                value: result.lead_id + '-g' + result.lead_id,
                                disabled: disabled
                            });
                        } else if (phoneQuery !== '' && (this.formatPhone(phone).replace(/\D+/g, '')).includes(phoneQuery)) {
                            selectItems.push({
                                phone: phone,
                                text: this.formatPhone(phone) + ' - ' + result.lead_full_name + appendedCode + statusCode,
                                value: result.lead_id + '-g' + result.lead_id,
                                disabled: disabled
                            });
                        }
                    }
                } else if (result.type === BasicFamilySearchResultType.CHILD) {
                    selectItems.push({
                        text: result.child_full_name + appendedCode + statusCode,
                        value: result.lead_id + '-c' + result.child_id,
                        disabled: disabled
                    });
                } else if (result.type === BasicFamilySearchResultType.CONTACT) {
                    phone = result.contact_phone ?? '';
                    if (result.contact_full_name && result.contact_full_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                        selectItems.push({
                            text: result.contact_full_name + appendedCode + statusCode,
                            value: result.lead_id + '-ac' + result.contact_id,
                            disabled: disabled
                        });
                    } else {
                        if (result.contact_email && result.contact_email.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                            selectItems.push({
                                text: result.contact_email + ' - ' + result.contact_full_name + appendedCode + statusCode,
                                value: result.lead_id + '-ac' + result.contact_id,
                                disabled: disabled
                            });
                        } else if (phoneQuery !== '' && (this.formatPhone(phone).replace(/\D+/g, '')).includes(phoneQuery)) {
                            selectItems.push({
                                phone: phone,
                                text: this.formatPhone(phone) + ' - ' + result.contact_full_name + appendedCode + statusCode,
                                value: result.lead_id + '-ac' + result.contact_id,
                                disabled: disabled
                            });
                        }
                    }
                }
            });

            this.items = selectItems;
            this.searching = false;
        }
    }

    // Filter search results for match.
    private filterFamily(item: any, queryText: string, itemText: string) {
        if (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) {
            return true;
        } else if ('phone' in item) {
            return this.formatPhone(item.phone).replace(/\D+/g, '').includes(queryText.replace(/\D+/g, ''));
        }

        return false;
    }
}
