export interface ChildStartsRow {
    family_id: number;
    child_name: string;
    guardian_name: string;
    status: string;
    start_date: string | null;
    age: string;
    classroom?: string;
}

// Why these numbers? Because it makes it easier to set the date ranges later.
export enum ChildStartsRangeKey {
    NEXT_30 = 30,
    NEXT_7 = 7,
    NEXT_3 = 3,
    TODAY = 0,
    LAST_3 = -3,
    LAST_7 = -7,
    LAST_30 = -30
}
