



















































































































































































































































import type { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Center } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { ChildCreateDtoInterface, ChildUpdateDtoInterface } from '@/families/models/child';
import { EnrollmentCreate, EnrollmentSchedule } from '@/families/models/enrollment';
import { EventTypes } from '@/constants/event-type-constants';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { ChildFields, ChildScheduleFields } from '@/crm-types/models/field-models';
import { RequiredFieldsStore } from '@/crm-types/store/required-fields-store';

const centersStore = getModule(CentersStore);
const enrollmentsStore = getModule(EnrollmentsStore);
const fieldsStore = getModule(FieldsStore);
const requiredFieldsStore = getModule(RequiredFieldsStore);

@Component
export default class ChildScheduleEditor extends Mixins(LocaleMixin) {
    @Prop({ required: true }) child!: ChildCreateDtoInterface | ChildUpdateDtoInterface | null;
    @Prop({ required: true }) family!: Family | null;
    @Prop() centerId!: number;
    @Prop() value!: EnrollmentSchedule | null;
    @Prop() required!: boolean;
    @Prop({
        type: Boolean,
        default: false
    }) isDisabled!: boolean;

    private center: Center | null = null;
    private isRequired = this.required ? this.required : false;
    private schedule: EnrollmentSchedule | null = null;
    private fake = false;

    get modelValue(): EnrollmentSchedule | null {
        return this.value;
    }

    set modelValue(value: EnrollmentSchedule | null) {
        this.$emit(EventTypes.INPUT, value);
    }

    get fte() {
        let fte = 0;
        if (!this.schedule) {
            return fte;
        }

        for (const [day, hours] of Object.entries(this.schedule)) {
            if (!this.isOpenSaturday && day === 'saturday') {
                continue;
            }
            if (!this.isOpenSunday && day === 'sunday') {
                continue;
            }
            if (hours.am) {
                fte += 0.5;
            }
            if (hours.pm) {
                fte += 0.5;
            }
        }
        let daysOpen = 5;
        if (this.isOpenSaturday) {
            daysOpen++;
        }
        if (this.isOpenSunday) {
            daysOpen++;
        }
        return Number(fte / daysOpen).toPrecision(2);
    }

    get isOpenSaturday(): boolean {
        return this.center?.open_saturday ?? false;
    }

    get isOpenSunday(): boolean {
        return this.center?.open_sunday ?? false;
    }

    get scheduleRules() {
        return [
            !this.isRequired || this.fte > 0 || 'Some schedule is required'
        ];
    };

    @Watch('modelValue')
    private modelUpdated() {
        if (this.modelValue !== this.schedule) {
            this.schedule = this.modelValue;
        }
    }

    @Watch('schedule', { deep: true })
    updateSchedule() {
        // make sure the fake checkbox is "dirty" so the validation message will show
        this.fake = !this.fake;
        if (this.family && this.child) {
            const enrollment = enrollmentsStore.pendingForChildId(this.child.id);
            if (!enrollment || !this.schedule) {
                return;
            }
            if (this.isRequired) {
                if (this.fte > 0) {
                    requiredFieldsStore.removeFromList({
                        familyId: this.family.id,
                        fieldName: ChildFields.SCHEDULE_INFORMATION
                    });
                } else {
                    // Something is required
                    requiredFieldsStore.addToList({
                        familyId: this.family.id,
                        fieldName: ChildFields.SCHEDULE_INFORMATION
                    });
                }
            }
            enrollment.schedule = this.schedule;
            enrollmentsStore.replaceForChildId({
                childId: this.child.id,
                enrollment: enrollment,
                familyId: this.family.id
            });
            this.$emit(EventTypes.UPDATED);
        } else if (this.centerId) {
            // for add family modal purposes, we'll update enrollments in that component after the family is created
            this.modelValue = this.schedule;
        }
    }

    async mounted() {
        if (this.family && this.child) {
            const enrollment = enrollmentsStore.pendingForChildId(this.child.id);
            this.schedule = enrollment!.schedule!;
            // If any schedule fields are required, they all are. They're grouped together under a single heading
            // in the ManageFields component
            this.isRequired = fieldsStore.storedChildFields.filter(field => Object.values(ChildScheduleFields).includes(field.value as ChildScheduleFields) && (field.is_client_required || field.is_system_required)).length > 0;
            if (this.isRequired) {
                requiredFieldsStore.addToList({
                    familyId: this.family.id,
                    fieldName: ChildFields.SCHEDULE_INFORMATION
                });
            }

            if (this.family.center) {
                this.center = await centersStore.getById(this.family.center.id);
            }
        } else if (this.centerId) {
            const enrollments = new EnrollmentCreate();
            this.schedule = enrollments.schedule;
            this.center = await centersStore.getById(this.centerId);
        }
    }
}
