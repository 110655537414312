






































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionDto, WorkflowCreateDto } from '../models/workflow-models';
import { workflowActions, WorkflowActionTypes } from '@/automation/workflows/constants/workflow-constants';
import { EventTypes } from '@/constants/event-type-constants';
import WorkflowActionChangeStatus from '@/automation/workflows/components/WorkflowActionChangeStatus.vue';
import WorkflowActionAddEvent from '@/automation/workflows/components/WorkflowActionAddEvent.vue';
import WorkflowActionSendText from '@/automation/workflows/components/WorkflowActionSendText.vue';
import WorkflowActionNotifyStaff from '@/automation/workflows/components/WorkflowActionNotifyStaff.vue';
import WorkflowActionSendEmail from '@/automation/workflows/components/WorkflowActionSendEmail.vue';
import WorkflowActionAddTask from '@/automation/workflows/components/WorkflowActionAddTask.vue';
import WorkflowActionStartDripCampaign from '@/automation/workflows/components/WorkflowActionStartDripCampaign.vue';
import WorkflowActionSendFacebookMessage
    from '@/automation/workflows/components/WorkflowActionSendFacebookMessage.vue';

@Component({
    components: { WorkflowActionSendFacebookMessage, WorkflowActionStartDripCampaign, WorkflowActionAddTask, WorkflowActionSendEmail, WorkflowActionNotifyStaff, WorkflowActionSendText, WorkflowActionAddEvent, WorkflowActionChangeStatus }
})
export default class WorkflowAction extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionDto;
    @Prop({ default: false }) readonly canDelete!: boolean;
    @Prop({ default: false }) hasDripCampaigns!: boolean;
    @Prop({ default: false }) isFacebookEvent!: boolean;
    @Prop() readonly dto!: WorkflowCreateDto;

    get action(): WorkflowActionDto {
        return this.value;
    }

    set action(action: WorkflowActionDto) {
        this.$emit('input', action);
    }

    get actionOptions() {
        return workflowActions.filter((action) => {
            if (action.id === WorkflowActionTypes.SEND_FB_MESSAGE && !this.isFacebookEvent) {
                return false;
            }
            if (action.id === WorkflowActionTypes.START_DRIP_CAMPAIGN && !this.hasDripCampaigns) {
                return false;
            }
            return true;
        });
    }

    get isChangeStatus() {
        return this.action.type === WorkflowActionTypes.CHANGE_STATUS;
    }

    get isAddEvent() {
        return this.action.type === WorkflowActionTypes.ADD_EVENT;
    }

    get isAddTask() {
        return this.action.type === WorkflowActionTypes.ADD_TASK;
    }

    get isNotifyStaff() {
        return this.action.type === WorkflowActionTypes.NOTIFY_STAFF;
    }

    get isSendEmail() {
        return this.action.type === WorkflowActionTypes.SEND_EMAIL;
    }

    get isSendFacebook() {
        return this.action.type === WorkflowActionTypes.SEND_FB_MESSAGE;
    }

    get isSendText() {
        return this.action.type === WorkflowActionTypes.SEND_TEXT;
    }

    get isStartDripCampaign() {
        return this.action.type === WorkflowActionTypes.START_DRIP_CAMPAIGN;
    }

    @Watch('isFacebookEvent')
    facebookChanged() {
        if (!this.isFacebookEvent && this.action.type === WorkflowActionTypes.SEND_FB_MESSAGE) {
            this.action.type = 0;
        }
    }

    private actionChanged() {
        this.action.settings = {};
    }

    private deleteAction() {
        this.$emit(EventTypes.DELETED);
    }
}
