import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';

export interface Field extends CrmEntity {
    can_hide: boolean;
    is_client_required: boolean;
    is_child_field: boolean;
    is_default: boolean;
    is_guardian_field: boolean;
    is_hidden: boolean;
    is_system_required: boolean;
    use_in_drip_campaigns: boolean;
    select_list: HateoasLink | null;
    select_list_name: string | null;
    type: FieldType;
    value: string;
}

export enum FieldEntityType {
    CHILD = 'child',
    GUARDIAN = 'guardian'
}

export interface FieldType extends CrmEntity {
    is_default: boolean;
    value: string;
}

export interface FieldUpdateDto extends AbstractCreateDto, AbstractUpdateDto {
    is_client_required: boolean;
    is_hidden: boolean;
}

export enum FieldTypes {
    TEXT_FIELD = 3000,
    SELECT_LIST = 3001,
    CHECKBOX = 3002,
    TEXT_AREA = 3004,
    DATE = 3005,
    TIME = 3006,
    EMAIL = 3007,
    PHONE = 3008,
    RADIO = 3014,
    CURRENCY = 3011
}

export enum ChildFields {
    FIRST_NAME = 'First Name',
    LAST_NAME = 'Last Name',
    BIRTHDATE = 'Date of Birth',
    ESTIMATED_BIRTH = 'Estimated Date of Birth',
    ADDED_DATE = 'Added Date',
    ACCOUNT_IN_GOOD_STANDING= 'Account in Good Standing',
    ACTUAL_START_DATE = 'Actual Start Date',
    AGE_OUT_DATE = 'Age Out Date',
    CHILD_OF_STAFF = 'Child of Staff',
    CHILD_STATUS = 'Child Status', // Is this just the status field?
    CLASS = 'Class',
    CURRENT_SITUATION = 'Current Situation',
    DATE_FEE_PAID = 'Date Fee Paid',
    ELIGIBLE_FOR_REENROLLMENT = 'Eligible for Re-Enrollment',
    ENROLLED_COMMENT = 'Enrolled Comment',
    ENROLLED_REASON = 'Enrolled Reason',
    ESTIMATED_REVENUE = 'Estimated Revenue',
    EXPECTED_CLASS = 'Expected Class',
    EXPECTED_START_DATE = 'Expected Start Date',
    GENDER = 'Gender',
    LOST_OPPORTUNITY_COMMENT = 'Lost Opportunity Comment',
    LOST_OPPORTUNITY_REASON = 'Lost Opportunity Reason',
    LOST_TO_COMPETITOR = 'Lost to Competitor',
    LOST_TO_COMPETITOR_REASON = 'Lost to Competitor Reason',
    NOTES = 'Notes',
    COMMENTS = 'Comments',
    PREFERRED_NAME = 'Preferred Name',
    PRIORITY_STATUS = 'Priority Status',
    PRIOR_DISLIKES = 'Prior Dislikes',
    PRIOR_LIKES = 'Prior Likes',
    PRIOR_LOCATION = 'Prior Location',
    PRIOR_CENTER = 'Prior Center',
    PRIOR_CARE = 'Prior Child Care',
    PRIOR_SITUATION_COMMENT = 'Prior Situation Comment',
    PRIOR_SITUATION_COMMENT2 = 'Prior Comment (currently Cost of Care)',
    REASON_FOR_CHANGE = 'Reason for Change',
    REJECTED_COMMENT = 'Rejected Comment',
    REJECTED_REASON = 'Rejected Reason',
    SCHEDULE_INFORMATION = 'Schedule Information',
    SIBLING_IN_CARE = 'Sibling in Care',
    TEMPORARY_LEAVE_COMMENT = 'Temporary Leave Comment',
    TEMPORARY_LEAVE_DATE = 'Temporary Leave Date',
    TEMPORARY_LEAVE_REASON = 'Temporary Leave Reason',
    WAIT_LIST_DATE = 'Wait List Date',
    WAIT_LIST_REASON = 'Wait List Reason',
    WAIT_LIST_TYPE = 'Wait List Type',
    WAIT_LIST_FEE = 'Wait List Fee',
    WAIT_LIST_DATE_FEE_PAID = 'Date Fee Paid',
    WAIT_LIST_FEE_PAID_IN_FULL = 'Wait List Fee Paid In Full',
    WAIT_LIST_COMMENT = 'Wait List Comment',
    WITHDRAWN_REASON = 'Withdrawn Reason',
    WITHDRAWN_DATE = 'Withdrawn Date',
    WITHDRAWN_COMMENT = 'Withdrawn Comment',
}

/**
 * Child schedule fields.
 * Keep track of these since they will be grouped under the same "field" display.
 */
export enum ChildScheduleFields {
    MONDAY_AM = 'Schedule Information (Mon AM)',
    MONDAY_PM = 'Schedule Information (Mon PM)',
    TUESDAY_AM = 'Schedule Information (Tue AM)',
    TUESDAY_PM = 'Schedule Information (Tue PM)',
    WEDNESDAY_AM = 'Schedule Information (Wed AM)',
    WEDNESDAY_PM = 'Schedule Information (Wed PM)',
    THURSDAY_AM = 'Schedule Information (Thu AM)',
    THURSDAY_PM = 'Schedule Information (Thu PM)',
    FRIDAY_AM = 'Schedule Information (Fri AM)',
    FRIDAY_PM = 'Schedule Information (Fri PM)',
    SATURDAY_AM = 'Schedule Information (Sat AM)',
    SATURDAY_PM = 'Schedule Information (Sat PM)',
    SUNDAY_AM = 'Schedule Information (Sun AM)',
    SUNDAY_PM = 'Schedule Information (Sun PM)',
    FULL_DAYS = 'Full Days',
    HALF_DAYS = 'Half Days'
}

/**
 * Child Type 1-4 fields
 * these will be checked against select_list_name since the name value is custom
 */
export enum ChildTypeFields {
    CHILD_TYPE_ONE = 'CHILD_TYPE',
    CHILD_TYPE_TWO = 'CHILD_TYPE_TWO',
    CHILD_TYPE_THREE = 'CHILD_TYPE_THREE',
    CHILD_TYPE_FOUR = 'CHILD_TYPE_FOUR',
}

/**
 * The order the child fields should appear in the list
 */
export enum ChildFieldsOrder {
    FIRST_NAME,
    LAST_NAME,
    BIRTHDATE,
    ESTIMATED_BIRTH,
    CLASS,
    PREFERRED_NAME,
    GENDER,
    ADDED_DATE,
    SCHEDULE_INFORMATION,
    CURRENT_SITUATION,
    PRIOR_LOCATION,
    REASON_FOR_CHANGE,
    PRIOR_LIKES,
    PRIOR_DISLIKES,
    PRIOR_SITUATION_COMMENT,
    PRIOR_CARE,
    PRIOR_CENTER,
    WAIT_LIST_DATE,
    WAIT_LIST_TYPE,
    WAIT_LIST_REASON,
    PRIORITY_STATUS,
    WAIT_LIST_FEE,
    WAIT_LIST_DATE_FEE_PAID,
    WAIT_LIST_FEE_PAID_IN_FULL,
    DATE_FEE_PAID,
    WAIT_LIST_COMMENT,
    EXPECTED_START_DATE,
    ACTUAL_START_DATE,
    ENROLLED_REASON,
    ENROLLED_COMMENT,
    TEMPORARY_LEAVE_REASON,
    TEMPORARY_LEAVE_DATE,
    TEMPORARY_LEAVE_COMMENT,
    WITHDRAWN_REASON,
    WITHDRAWN_DATE,
    ELIGIBLE_FOR_REENROLLMENT,
    WITHDRAWN_COMMENT,
    LOST_OPPORTUNITY_REASON,
    LOST_OPPORTUNITY_COMMENT,
    LOST_TO_COMPETITOR,
    LOST_TO_COMPETITOR_REASON,
    ACCOUNT_IN_GOOD_STANDING,
    CHILD_OF_STAFF,
    SIBLING_IN_CARE,
    REJECTED_REASON,
    REJECTED_COMMENT,
    ESTIMATED_REVENUE,
    NOTES,
    COMMENTS
}

/**
 * Keys for fields that will be grouped together with the same data.
 */
export enum GroupedFieldKeys {
    ChildSchedule = -1
}

export enum GuardianFields {
    FIRST_NAME = 'First Name',
    LAST_NAME = 'Last Name',
    ADDRESS = 'Street Address',
    ADDRESS2 = 'Address 2',
    ADDRESS3 = 'Address 3',
    ADDRESS4 = 'Address 4',
    CITY = 'City',
    STATE = 'State',
    ZIP = 'Zip',
    EMAIL = 'Email',
    SHOWN_EMAIL = 'Shown Email Address',
    PHONE = 'Phone',
    PHONE_COUNTRY = 'Primary Phone Country Code',
    SOURCE = 'Source',
    INQUIRY_TYPE = 'Inquiry Type',
    COMMENTS = 'Comments',
    PHONE_TYPE = 'Phone Type',
    PHONE_EXTENSION = 'Primary Phone Extension',
    LEAD_DATE = 'Lead Date',
    ADDED_DATE = 'Added Date',
    ALTERNATE_PHONE = 'Alternate Phone',
    ALTERNATE_EMAIL = 'Alternate Email',
    ALTERNATE_PHONE_TYPE = 'Alternate Phone Type',
    RELATION_TO_CHILD = 'Relation to Child',
    LOST_OPPORTUNITY_REASON = 'Lost Opportunity Reason',
    LOST_OPPORTUNITY_COMMENT = 'Lost Opportunity Comment',
    REJECTED_REASON = 'Rejected Reason',
    REJECTED_COMMENT = 'Rejected Comment',
    FAMILY_REP = 'Family Rep',
    ENROLLMENT_REP = 'Enrollment Rep',
    REFERRED_BY = 'Referred By',
    SPECIAL_EMPLOYER = 'Special Employer',
    MARKETING_CAMPAIGN = 'Marketing Campaign',
    MARKETING_NOTE = 'Marketing Note',
    LOST_TO_COMPETITOR = 'Lost to Competitor',
    LOST_TO_COMPETITOR_REASON = 'Lost to Competitor Reason',
    STATUS = 'Status',
    DO_NOT_EMAIL = 'Do Not Email',
    DO_NOT_TEXT = 'Do Not Text',
    DO_NOT_CALL = 'Do Not Call',
    ALT_CONTACT_EMAIL = 'Alternate Contact Email',
    ALT_CONTACT_PHONE = 'Alternate Contact Phone',
    ALT_CONTACT_PHONE_TYPE = 'Alternate Contact Phone Type',
    PRIOR_CHILD_CARE = 'Prior Child Care',
    PRIOR_CENTER = 'Prior Center',
    PRIOR_COMMENT = 'Prior Comment (currently Cost of Care)',
    PRIOR_COMMENT2 = 'Prior Comment',
    REASON_FOR_CHANGE = 'Reason for Change',
    GENDER = 'Gender',
    BIRTHDATE = 'Date of Birth',
    CONTACT_METHOD = 'Contact Method'
}

/**
 * Guardian Type 1-4 fields
 * these will be checked against select_list_name since the name value is custom
 */
export enum GuardianTypeFields {
    LEAD_TYPE_ONE = 'LEAD_TYPE',
    LEAD_TYPE_TWO = 'LEAD_TYPE_TWO',
    LEAD_TYPE_THREE = 'LEAD_TYPE_THREE',
    LEAD_TYPE_FOUR = 'LEAD_TYPE_FOUR',
}

/**
 * The order the guardian fields should appear in the list.
 */
export enum GuardianFieldsOrder {
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    SHOWN_EMAIL,
    PHONE,
    PHONE_TYPE,
    PHONE_EXTENSION,
    PHONE_COUNTRY,
    ADDRESS,
    ADDRESS2,
    ADDRESS3,
    ADDRESS4,
    CITY,
    STATE,
    ZIP,
    INQUIRY_TYPE,
    SOURCE,
    RELATION_TO_CHILD,
    ALTERNATE_EMAIL,
    ALTERNATE_PHONE,
    ALTERNATE_PHONE_TYPE,
    FAMILY_REP,
    ENROLLMENT_REP,
    REFERRED_BY,
    SPECIAL_EMPLOYER,
    LOST_OPPORTUNITY_REASON,
    LOST_OPPORTUNITY_COMMENT,
    LOST_TO_COMPETITOR,
    LOST_TO_COMPETITOR_REASON,
    REJECTED_REASON,
    REJECTED_COMMENT,
    COMMENTS,
    BIRTHDATE,
    GENDER,
    DO_NOT_TEXT,
    DO_NOT_EMAIL,
    DO_NOT_CALL,
    PRIOR_COMMENT,
    PRIOR_CENTER,
    PRIOR_CHILD_CARE,
    REASON_FOR_CHANGE,
    MARKETING_CAMPAIGN,
    MARKETING_NOTE,
    LEAD_DATE,
    ADDED_DATE,
    STATUS,
    ALT_CONTACT_PHONE,
    ALT_CONTACT_PHONE_TYPE
}

export interface StatusDateFields {
    actualStartDate?: Field | null;
    expectedStartDate?: Field | null;
    tempLeaveDate?: Field | null;
    waitListDate?: Field | null;
    withdrawnDate?: Field | null;
}
export interface ExtraDataFields {
    [key: string]: Field | null | undefined;
    reason: Field | null;
    comment: Field | null;
}

export interface WaitListExtraDataFields extends ExtraDataFields {
    type: Field | null;
    priorityStatus: Field | null;
    childOfStaff: Field | null;
    siblingInCare: Field | null;
    fee: Field | null;
    feePaidDate: Field | null;
    feePaidInFull: Field | null;
}

export interface WithdrawnExtraDataFields extends ExtraDataFields {
    goodStanding: Field | null;
    reEnrollment: Field | null;
    competitor?: Field | null;
    competitorReason?: Field | null;
}

export interface LostOppExtraDataFields extends ExtraDataFields {
    competitor?: Field | null;
    competitorReason?: Field | null;
}

export interface ExtraStatusFields {
    statusDateFields: StatusDateFields;
    waitListFields: WaitListExtraDataFields;
    enrolledFields: ExtraDataFields;
    tempLeaveFields: ExtraDataFields;
    withdrawnFields: WithdrawnExtraDataFields;
    lostOppFields: Record<any, LostOppExtraDataFields>;
    rejectedFields: Record<any, ExtraDataFields>;
}

export class ExtraStatusDataFields implements ExtraStatusFields {
    statusDateFields: StatusDateFields = {
        actualStartDate: null,
        expectedStartDate: null,
        tempLeaveDate: null,
        waitListDate: null,
        withdrawnDate: null
    };

    waitListFields: WaitListExtraDataFields = {
        reason: null,
        comment: null,
        type: null,
        priorityStatus: null,
        childOfStaff: null,
        siblingInCare: null,
        fee: null,
        feePaidDate: null,
        feePaidInFull: null
    };

    enrolledFields: ExtraDataFields = {
        reason: null,
        comment: null
    };

    tempLeaveFields: ExtraDataFields = {
        reason: null,
        comment: null
    };

    withdrawnFields: WithdrawnExtraDataFields = {
        reason: null,
        comment: null,
        goodStanding: null,
        reEnrollment: null
    };

    lostOppFields: Record<any, LostOppExtraDataFields> = {
        child: {
            reason: null,
            comment: null
        },
        guardian: {
            reason: null,
            comment: null
        }
    }

    rejectedFields: Record<any, ExtraDataFields> = {
        child: {
            reason: null,
            comment: null
        },
        guardian: {
            reason: null,
            comment: null
        }
    };
}
