







































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { OutgoingText, OutgoingTextUpdateDto, TextMessage } from '@/communications/messages/models/text';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { MessageDirection, OutgoingStatusString } from '@/communications/messages/models/message';
import { EventTypes } from '@/constants/event-type-constants';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import store from '@/store';
import {
    formatClockTime,
    formatDateForApi,
    formatDateWithTimezone, formatIsoDateTime,
    isAfterNow,
    isoFormat, timeFormat
} from '@/date-time/date-time-utils';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import TextContentEditor from '@/communications/messages/components/TextContentEditor.vue';
import { OutgoingTextMapper } from '@/communications/mappers/outgoing-text-mapper';
import { LoadingStore } from '@/store/loading-store';
import { scrollToSelector } from '@/core/scroll-to';
import BaseClose from '@/components/base/BaseClose.vue';

const authStore = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const textsRepo = new TextsRepository();
const outgoingTextMapper = new OutgoingTextMapper();

@Component({
    components: {
        BaseClose,
        TextContentEditor,
        CenterAscendingStaffList
    }
})
export default class FamilySingleText extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly text!: TextMessage;
    @Prop({ default: false }) readonly inbox!: boolean;
    $refs!: {
        textMessage: HTMLElement;
    }

    private showEditDialog = false;
    private loadingKey = 'textEditDialog';
    private centerId = 0;
    private isEllipsisActive = false;
    // Properties used by editing the text.
    private textUpdateDto: OutgoingTextUpdateDto = { };
    private messageContent = '';
    private sendDate = '';
    private sendFrom = 0;
    private sendTime = '';
    // Whether or not additional details of the text message.
    private showDetails = false;
    get timezone() {
        return authStore.userInfoObject?.timezone ?? 'UTC';
    }

    get isIncoming() {
        return this.text.type === MessageDirection.INCOMING;
    }

    get hasChanges() {
        if (this.isPending && this.textUpdateDto) {
            const origDate = this.text.sent_date_time;
            const origSendDate = origDate ? formatDateForApi(origDate) : '';
            const origSendTime = origDate ? formatClockTime(origDate) : '';

            return this.messageContent !== '' &&
                (
                    this.text.data !== this.messageContent ||
                    (this.text as OutgoingText).send_by_user.id !== this.sendFrom ||
                    origSendDate !== this.sendDate ||
                    origSendTime !== this.sendTime
                );
        }

        return false;
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    get isPending() {
        if (!this.isIncoming && (this.text as OutgoingText).status === OutgoingStatusString.PENDING) {
            if (!this.text.sent_date_time) {
                return true;
            }
            return isAfterNow(this.text.sent_date_time);
        }

        return false;
    }

    @Watch('text', { deep: true, immediate: true })
    async textChanged() {
        if (this.text.type === MessageDirection.OUTGOING) {
            if ((this.text as OutgoingText).send_by_user) {
                this.textUpdateDto = outgoingTextMapper.toUpdateDto((this.text as OutgoingText));
                this.messageContent = this.text.data;
                this.sendFrom = (this.text as OutgoingText).send_by_user.id;

                const sentDateTime = (this.text as OutgoingText).sent_date_time;
                if (sentDateTime) {
                    this.sendDate = formatDateWithTimezone(sentDateTime, this.timezone, isoFormat);
                    this.sendTime = formatDateWithTimezone(sentDateTime, this.timezone, timeFormat);
                }
            }
        }
    }

    @Watch('showDetails')
    private scrollToCard() {
        if (this.showDetails) {
            return;
        }
        // So we jump to the top of the collapsed card
        scrollToSelector(this.$vuetify, `#text-${this.text.id}`);
    }

    private openEditDialog() {
        if (this.isPending) {
            // Just to make sure it's there.
            this.messageContent = this.text.data;
            this.showEditDialog = true;
        }
    }

    private closeEditDialog() {
        this.showEditDialog = false;
        this.messageContent = '';
    }

    private mounted() {
        if (this.inbox) {
            return;
        }
        const offsetWidth = this.$refs.textMessage.offsetWidth;
        const scrollWidth = this.$refs.textMessage.scrollWidth;
        this.isEllipsisActive = scrollWidth > offsetWidth;
    }

    private async updateText() {
        // Update the DTO
        if (this.textUpdateDto) {
            loadingState.loadingIncrement(this.loadingKey);

            this.textUpdateDto.data = this.messageContent;
            this.textUpdateDto.send_by_user = this.sendFrom;
            this.textUpdateDto.send_date_time = formatIsoDateTime(this.sendDate + ' ' + this.sendTime, this.timezone);

            try {
                await textsRepo.updatePendingText(this.text.id, this.textUpdateDto);
                this.$emit(EventTypes.PENDING_TEXT_UPDATED);

                this.closeEditDialog();
                loadingState.loadingDecrement(this.loadingKey);
            } catch (e) {
                loadingState.loadingDecrement(this.loadingKey);
                await this.$swal({ text: e.message, icon: 'error' });
            }
        }
    }

    private async cancelPendingText(id: number) {
        await this.$swal({
            text: 'Are you sure you want to cancel sending this text message?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                await textsRepo.cancelPendingText(id);
                this.$emit(EventTypes.PENDING_TEXT_CANCELLED);
            }
        });
    }
}
