
export interface SimpleAddress {
    address1: string;
    locality: string;
    region: string;
    postcode: string;
    country: string;
}

export class SimpleAddressDto {
    address1 = '';
    locality = '';
    region = '';
    postcode = '';
    country = '';
}
