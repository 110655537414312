

































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { FamilyMarketingTracking } from '@/families/models/marketing';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { SelectListOption } from '@/core/models/select-list';
import { LoadingStore } from '@/store/loading-store';
import { MarketingCampaignsRepository } from '@/marketing/marketing-campaigns-repository';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const familiesMapper = new FamilyMapper();
const familiesRepo = new FamiliesRepository();
const marketingCampaignsRepo = new MarketingCampaignsRepository();
@Component({
    components: { BaseClose }
})
export default class FamilyDataMarketingCampaigns extends Mixins(LocaleMixin) {
    @Prop() family!: Family;
    @PropSync('familyCampaigns') familyCampaignsLocal!: Array<FamilyMarketingTracking>;
    private isEditing = false;
    private loadingKey = 'familyMarketingCampaigns';
    private campaignIds: Array<number> = [];
    private campaignOptions: Array<SelectListOption> = [];

    get headers(): Array<DataTableHeader> {
        const base: Array<DataTableHeader> = [
            {
                text: 'Marketing Campaign Name',
                value: 'marketing_campaign.values.name',
                width: this.utmColumns.length > 3 ? '27.5ch' : 'auto'
            },
            {
                text: 'Added Date',
                value: 'added_datetime',
                width: this.utmColumns.length === 0 ? '15rem' : '16ch'
            }
        ];
        base.push(...this.utmColumns);
        if (this.utmColumns.length > 3) {
            base[base.length - 1].width = '23%';
        }
        return base;
    }

    get lastColumnName() {
        return 'header.' + this.headers[this.headers.length - 1].value;
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get utmColumns() {
        const utmColums: Array<DataTableHeader> = [
            {
                text: 'UTM Camp.',
                value: 'utm_campaign'
            },
            {
                text: 'UTM Source',
                value: 'utm_source'
            },
            {
                text: 'UTM Medium',
                value: 'utm_medium'
            },
            {
                text: 'UTM Term',
                value: 'utm_term'
            },
            {
                text: 'UTM Content',
                value: 'utm_content'
            }
        ];
        return utmColums.filter((column) => {
            return this.familyCampaignsLocal.filter((campaign) => campaign[column.value as keyof FamilyMarketingTracking]).length > 0;
        });
    }

    @Watch('isEditing')
    async editChanged() {
        if (this.isEditing) {
            loadingState.loadingIncrement(this.loadingKey);
            const campaigns = (await marketingCampaignsRepo.retrieve(authState.userInfoObject!.org_id)).filter(campaign => campaign.is_active);
            this.campaignOptions = campaigns.map((campaign) => {
                return {
                    value: campaign.id,
                    text: campaign.name
                };
            });
            this.campaignOptions.unshift({
                text: 'None',
                value: 0
            });
            this.campaignIds = this.familyCampaignsLocal.map((marketing) => {
                return marketing.marketing_campaign ? marketing.marketing_campaign.id : 0;
            });
            // don't erase inactive campaigns
            for (const campaign of this.familyCampaignsLocal) {
                if (campaign.marketing_campaign && !this.campaignOptions.find(option => option.value === campaign.marketing_campaign!.id)) {
                    this.campaignOptions.push({
                        text: campaign.marketing_campaign.values.name,
                        value: campaign.marketing_campaign.id
                    });
                }
            }
            if (this.campaignIds.length === 0) {
                this.campaignIds.push(0);
            }
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    getFilteredOptions(current: number) {
        return this.campaignOptions.filter((option) => {
            if (option.value === 0) {
                return true;
            }
            if (option.value === current) {
                return true;
            }
            return !this.campaignIds.includes(option.value as number);
        });
    }

    /**
     * helper function for utm slots
     * @param col
     */
    getSlot(col: DataTableHeader): string {
        return 'item.' + col.value;
    }

    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const dto = familiesMapper.toUpdateDto(this.family);
        const ids = this.campaignIds.filter((id) => {
            return id > 0;
        });
        dto.marketing_campaign = ids.length ? ids : null;
        await familiesRepo.update(dto);
        this.$emit(EventTypes.UPDATED);
        if (ids.length === 0) {
            this.$emit(EventTypes.DELETED);
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.isEditing = false;
    }
}
