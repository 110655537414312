import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractEntityState, AbstractApiStore } from '@/store/abstract-api-store';
import { StaffSortKeys, User } from '@/staff/models/user';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import { SortConstants } from '@/constants/sort-constants';
import store from '@/store';

export interface StaffState extends AbstractEntityState<User> {
    allStaff: Array<User>;
    entities: Array<User>;
    mappedEntities: Map<number, User>;
}

export interface CenterStaffMapping {
    centerId: number;
    staff: Array<User>;
}

// organizationId = The lowest organization id of which the staff member can access.
export interface OrgAccessibleStaffMapping {
    organizationId: number;
    staff: Array<User>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.STAFF
})
export class StaffStore extends AbstractApiStore<User> implements StaffState {
    readonly repository = new StaffRepository();
    private centerStaff: Map<number, Array<User>> = new Map<number, Array<User>>();
    private orgAccessibleStaff: Map<number, Array<User>> = new Map<number, Array<User>>();
    mappedEntities: Map<number, User> = new Map();
    allStaff: Array<User> = [];

    /**
     * Initialize all staff members. Only to be called when org hierarchies are ignored.
     */
    @Action
    public async initAllStaff(force = false) {
        if (this.allStaff.length === 0 || force) {
            await this.initPromise({
                hash: 'all',
                closure: async () => {
                    await this.retrieveAllStaff();
                }
            });
        }
    }

    // Initialize for data retrieval for staff at (and above) a given center.
    // Don't retrieve if we already have data, unless override is true.
    @Action
    public async initForCenter(centerId: number, override = false) {
        if (!this.centerStaff.has(centerId) || override) {
            await this.initPromise({
                hash: 'center-' + String(centerId),
                closure: async () => {
                    await this.retrieveCenterStaff(centerId);
                }
            });
        }
    }

    @Action
    public async initForOrg(orgId: number, override = false) {
        if (!this.orgAccessibleStaff.has(orgId) || override) {
            await this.initPromise(
                {
                    hash: 'organization-' + String(orgId),
                    closure: async () => {
                        await this.retrieveOrgAccessibleStaff(orgId);
                    }
                }
            );
        }
    }

    /**
     * Retrieve all staff members. Only to be called when org hierarchies are ignored.
     */
    @Action({ commit: 'storeAllStaff', rawError: true })
    public async retrieveAllStaff() {
        const limit = 100;
        const params = {
            all_staff: 'true',
            only_active: '1'
        } as ApiParameters;
        let response = await this.repository.get(null, params);
        let entities = response.entities;
        const count = response.count;

        if (count > 100) {
            for (let i = 100; i < count; i += limit) {
                response = await this.repository.get({ limit: 100, offset: i } as ApiPagination, params);
                entities = entities.concat(response.entities);
            }
        }

        return entities;
    }

    @Action({ commit: 'storeCenterStaff', rawError: true })
    public async retrieveCenterStaff(centerId: number) {
        const response = await this.repository.get(
            null,
            {
                sort_keys: [StaffSortKeys.FIRST_NAME],
                sort_dir: [SortConstants.ASCENDING],
                center_id: centerId.toString(),
                only_active: '1',
                no_pagination: '1'
            } as ApiParameters
        );

        this.mappedEntities.clear();
        response.entities.forEach((entity) => {
            if (entity.id) {
                this.mappedEntities.set(entity.id, entity);
            }
        });

        return { centerId: centerId, staff: response.entities } as CenterStaffMapping;
    }

    @Action({ commit: 'storeOrgAccessibleStaff', rawError: true })
    public async retrieveOrgAccessibleStaff(orgId: number) {
        const response = await this.repository.getAllStaffThatCanAccessOrgByOrgId(orgId);

        this.mappedEntities.clear();
        response.forEach(
            (entity) => {
                if (entity.id) {
                    this.mappedEntities.set(entity.id, entity);
                }
            }
        );

        return { organizationId: orgId, staff: response } as OrgAccessibleStaffMapping;
    }

    /**
     * Store all the staff members.
     *
     * @param staff
     */
    @Mutation
    public storeAllStaff(staff: Array<User>): void {
        this.allStaff = staff;
    }

    @Mutation
    public storeCenterStaff(centerStaffMapping: CenterStaffMapping): void {
        this.centerStaff.set(centerStaffMapping.centerId, centerStaffMapping.staff);
    }

    @Mutation
    public storeOrgAccessibleStaff(orgAccessibleStaffMapping: OrgAccessibleStaffMapping): void {
        this.orgAccessibleStaff.set(orgAccessibleStaffMapping.organizationId, orgAccessibleStaffMapping.staff);
    }

    /**
     * Get all staff members; ignore hierarchy restrictions.
     */
    public get storedAllStaff(): Array<User> {
        return this.allStaff;
    }

    public get storedCenterStaff(): Map<number, Array<User>> {
        return this.centerStaff;
    }

    public get storedOrgAccessibleStaff(): Map<number, Array<User>> {
        return this.orgAccessibleStaff;
    }
}
