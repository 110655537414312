import { CustomValues, CustomValuesUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';

export class CustomValuesMapper {
    toDto(entity: CustomValues): CustomValuesUpdateDto {
        return {
            custom_value_group: entity.custom_value_group.id,
            custom_values: entity.custom_values ? entity.custom_values.map(value => value.id) : null,
            custom_text_value: entity.custom_text_value
        };
    }
}
