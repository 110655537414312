



















import { FeaturesStore } from '@/features/features-store';
import { OrganizationCounts } from '@/models/report/period-statistics';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import { DashboardStore } from '@/store/dashboard-store';
import Highcharts, { XAxisOptions } from 'highcharts';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    getBaseBarChartOptions,
    countsFromOrgCountsGroupedByNameToNamedSeries,
    orgCountNamesToXAxis, getMonochromeColorsWithLimits, getMonochromeColors
} from '@/charts/chart-utils';
import { LIGHTEST_BLUE, BASE_DARK_BLUE, LINE_LEADER_PURPLE } from '@/core/style-utils';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';

const dashboardState = getModule(DashboardStore);
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const statisticsRepository = new PeriodStatisticsRepository();
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component
export default class CompletedTasksChart extends Mixins(LocaleMixin) {
    @Prop({ type: Array, required: true }) readonly orgs!: number[];
    @Prop({ default: false }) custDashMode!: boolean;

    private graphId = 'completed-graph';
    private chartOptions: Highcharts.Options = {};

    private isMounted = false;
    private loadingKey = 'completedTasksChart';
    private statusStats: Array<OrganizationCounts> = [];

    get dateRange() {
        return dashboardState.storedDateFilter;
    }

    @Watch('orgs')
    async orgsUpdated() {
        this.chartOptions.series = [];
        await this.updateCounts();
    }

    @Watch('dateRange', { deep: true })
    async dateRangeChanged() {
        this.chartOptions.series = [];
        await this.updateCounts();
    }

    async created() {
        await featuresStore.init();
        await interfaceSettingsStore.init();
        if (this.orgs.length !== 0) {
            await this.updateCounts();
        }
    }

    /**
     * If the v-if="isMounted" to render the chart after everything else is mounted, or widths get out of whack.
     * https://github.com/highcharts/highcharts-vue/issues/107
     */
    public async mounted() {
        await featuresStore.init();
        await interfaceSettingsStore.init();
        this.chartOptions = Highcharts.merge(await getBaseBarChartOptions(), {
            chart: {
                height: '450px'
            },
            title: {
                align: 'left',
                margin: 30,
                text: 'Completed',
                style: {
                    fontFamily: 'Manrope, sans-serif',
                    fontSize: featuresStore.isLineLeaderEnroll ? '17px' : '22.5px',
                    fontWeight: featuresStore.isLineLeaderEnroll ? '600' : '500'
                },
                x: -10
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: 'Completed'
                    }
                }
            },
            plotOptions: {
                bar: {
                    colorByPoint: true
                }
            }
        });
        this.isMounted = true;
    }

    async updateCounts() {
        if (this.orgs.length === 0 || !this.dateRange || loadingState.isLoading(this.loadingKey)) {
            return;
        }
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);

        const params = {
            org_ids: this.orgs,
            completed_only: '1',
            completed_date_start: this.dateRange.startDate,
            completed_date_end: this.dateRange.endDate
        };

        await statisticsRepository.query('tasks', params);
        this.statusStats = statisticsRepository.getGroupedByTypeThenOrganization();
        this.statusStats.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.updateChart();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private updateChart() {
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;
        let color = '';

        // Set the categories
        (this.chartOptions.xAxis as XAxisOptions).categories = orgCountNamesToXAxis(this.statusStats);

        color = isLineLeaderEnroll ? LINE_LEADER_PURPLE : BASE_DARK_BLUE;
        if (interfaceSettingsStore.hasWhiteLabel) {
            color = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
        }
        // Set the series data.
        if (this.orgs.length === 1) {
            this.chartOptions.series = countsFromOrgCountsGroupedByNameToNamedSeries(this.statusStats, color, true, false, isLineLeaderEnroll);
        } else {
            this.chartOptions.series = countsFromOrgCountsGroupedByNameToNamedSeries(this.statusStats, color, false, false, isLineLeaderEnroll);
        }
        this.chartOptions.plotOptions!.bar!.colors = isLineLeaderEnroll
            ? getMonochromeColors(color, true)
            : getMonochromeColorsWithLimits(LIGHTEST_BLUE, color, this.statusStats.length);
    }
}
