
// don't love that we have to do these next two lines here, but jest bombs out on this component otherwise
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VTimePicker from 'vuetify/lib/components/VTimePicker/VTimePicker';
import Vue from 'vue';
import { LocaleMixin } from '@/locales/locale-mixin';

// grab the base component we're modifying
const base = Vue.extend({ mixins: [VTimePicker, LocaleMixin] });
export default base.extend({
    name: 'base-time-picker',
    props: {
        landscape: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#0060AF'
        },
        ampmInTitle: {
            type: Boolean,
            default: true
        },
        scrollable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes(): object {
            return {
                ...VTimePicker.options.computed.classes.call(this),
                'base-time-picker': true
            };
        }
    }
});
