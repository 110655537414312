var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"content-class":"dialog-large"},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card',{attrs:{"data-cy":"customer-switch-modal-content"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentlyManaging)+" "),_c('v-spacer'),(_vm.isFullSuperuser)?_c('base-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-button-plain',_vm._g({attrs:{"icon":""},on:{"click":_vm.goToIMS}},on),[_c('v-icon',[_vm._v("mdi-hammer-wrench")])],1)]}}],null,false,3665747082)},[_c('span',[_vm._v("Go to IMS")])]):_vm._e(),_c('base-button-plain',{attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('base-close')],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","offset":"6"}},[_c('base-text-field',{attrs:{"hide-details":"","outlined":false,"prepend-inner-icon":"mdi-magnify","single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.chooseSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('base-data-table',{ref:"customersTable",attrs:{"dense":"","headers":_vm.headers,"items":_vm.customers,"item-key":"id","must-sort":"","search":_vm.search,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.country))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.activeStatus(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('base-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-button-plain',_vm._g({attrs:{"icon":"","disabled":item.is_under_maintenance,"data-cy":"go-to-customer"},on:{"click":function($event){return _vm.goToCustomer(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to Customer")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }