















































import { formatClockTime, formatDateForApi, getTimeZoneOptions } from '@/date-time/date-time-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { TimezoneOption } from '@/core/timezones/timezone';

const timezonesState = getModule(TimezonesStore);

@Component
export default class TaskDueDateTime extends Mixins(LocaleMixin) {
    @Prop() isDisabled!: boolean;
    @Prop() dueDateTime!: string;
    @PropSync('timezone') timezoneSync!: string;
    @Prop({ default: false }) showTimezones!: boolean;
    private startDate = '';
    private startTime = '';
    private timezoneOptions: Array<TimezoneOption> = [];

    async mounted() {
        const date = new Date(this.dueDateTime.replace(/-/g, '/'));
        this.startDate = formatDateForApi(date);
        this.startTime = formatClockTime(date);
        if (this.showTimezones) {
            await timezonesState.init();
            this.timezoneOptions = getTimeZoneOptions(timezonesState.stored, date, this.$i18n.locale);
        }
    }

    private emitUpdate() {
        const timestamp = Date.parse(`${this.startDate.replace(/-/g, '/')} ${this.startTime}`);
        if (!isNaN(timestamp) && this.startTime) {
            this.$emit('input', new Date(timestamp));
        } else {
            this.$emit('input', null);
        }
    }

    @Watch('startDate')
    private updateDate() {
        this.emitUpdate();
    }

    @Watch('startTime')
    private updateTime() {
        this.emitUpdate();
    }
}
