import {
    TextTemplateCreateDto,
    TextTemplateUpdateDto,
    MessageTemplate
} from '@/communications/templates/models/message-template';
import { AbstractMapper } from '@/core/mappers/abstract-mapper';

export class TextTemplateMapper implements AbstractMapper<MessageTemplate> {
    toCreateDto(entity: MessageTemplate): TextTemplateCreateDto {
        const dto = {
            name: entity.name,
            template_group: entity.template_group?.id ?? null,
            brand: entity.brand?.id ?? null,
            communication_type: entity.communication_type.id,
            org: entity.org.id,
            is_active: entity.is_active,
            is_corporate_only: entity.is_corporate_only,
            content: entity.content ?? '',
            hierarchy_type: entity.hierarchy_type?.id ?? 2,
            location_group: entity.location_group?.id ?? null
        } as TextTemplateCreateDto;

        return dto;
    }

    toUpdateDto(entity: MessageTemplate): TextTemplateUpdateDto {
        const dto = this.toCreateDto(entity) as TextTemplateUpdateDto;
        dto.id = entity.id;
        return dto;
    }
}
