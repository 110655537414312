import {
    EnrollmentCenterOfferingsRatingsCreateDto,
    EnrollmentCenterOfferingsRatingsUpdateDto,
    EnrollmentCenterDetailsCreateDto,
    EnrollmentCenterDetailsUpdateDto,
    EnrollmentCenterRateOptionCreateDto,
    EnrollmentCenterRateOptionUpdateDto,
    EnrollmentCenterSettingsUpdateDto,
    EnrollmentCenterSettings
} from '@/enrollment-center/models/enrollment-center-models';
import { EnrollmentCenterLocationOfferingsStore } from '@/enrollment-center/store/enrollment-center-location-offerings-store';
import { CenterStaffPositionsTableData, StaffPositionUpdateDto } from '@/staff/models/user';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { EnrollmentCenterSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-settings-repository';
import { EnrollmentCenterMapper } from '@/enrollment-center/mappers/enrollment-center-mapper';

const locationOfferingsStore = getModule(EnrollmentCenterLocationOfferingsStore);
const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
const enrollmentCenterRepo = new EnrollmentCenterSettingsRepository();
const enrollmentCenterMapper = new EnrollmentCenterMapper();
const repoFactoryStore = getModule(RepositoryFactoryStore);

/**
 * Get the selections for the table data in the ec supported locations table.
 *
 * @param centerStaffPositionsTableData
 * @param excludedCenterIds
 */
export function getSelectedSupportedLocationsTableData(
    centerStaffPositionsTableData: Array<CenterStaffPositionsTableData>,
    excludedCenterIds: Array<number>
): Array<CenterStaffPositionsTableData> {
    return centerStaffPositionsTableData.filter((tableData: CenterStaffPositionsTableData) => {
        return !excludedCenterIds.includes(tableData.centerId);
    });
}

/**
 * Get the staff position dtos from the settings store.
 */
export function getPositionDtosFromSettingsStore(): Map<number, Array<StaffPositionUpdateDto>> {
    const userPositionDtoMap: Map<number, Array<StaffPositionUpdateDto>> = new Map();
    for (const [centerId, dto] of settingsChangesStore.centerEnrollmentRepChangesUpdates) {
        const positionDto = {
            center: centerId,
            enrollment_rep: dto.value
        };
        if (userPositionDtoMap.has(dto.userId)) {
            const tmp: Array<StaffPositionUpdateDto> = userPositionDtoMap.get(dto.userId)!;
            tmp.push(positionDto);
            userPositionDtoMap.set(dto.userId, tmp);
        } else {
            userPositionDtoMap.set(dto.userId, [positionDto]);
        }
    }
    return userPositionDtoMap;
}

/**
 * Save the changes to be made on the "Locations Supported" section of the settings.
 */
export async function saveLocationsSupportedData(): Promise<void> {
    if (!settingsChangesStore.areSupportedLocationChangesFlag) {
        return;
    }
    settingsChangesStore.setRefreshLocationsSupported(true);

    const promises = [];

    // Update the excluded centers
    promises.push(
        repoFactoryStore.enrollmentCenterSettingsRepository.updateExcludedLocations(
            { center_ids: settingsChangesStore.excludedCenterUpdates }
        )
    );

    // Update the enrollment reps for centers
    for (const [userId, dto] of getPositionDtosFromSettingsStore()) {
        promises.push(repoFactoryStore.staffRepository.updateStaffMemberPositions(userId, dto));
    }
    await Promise.all(promises);
}

/**
 * Potentially brittle CDS school number to CDS location code mapping
 * @param schoolNum
 */
export function getCodeFromSchoolNum(schoolNum: string) {
    return 'SCH' + schoolNum.padStart(3, '0');
}

/**
 * Save the settings.
 *
 * @param callCenterSettings
 */
export async function saveECCSettings(callCenterSettings: EnrollmentCenterSettings) {
    const detailsSettingsUpdatedItems: Array<EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto> = settingsChangesStore.detailsUpdated;
    const detailsSettingsDeletedItems: Array<number> = settingsChangesStore.detailsDeleted;
    const offeringsSettingsUpdatedItems: Array<EnrollmentCenterOfferingsRatingsUpdateDto|EnrollmentCenterOfferingsRatingsCreateDto> = settingsChangesStore.offeringsUpdated;
    const offeringsSettingsDeletedItems: Array<number> = settingsChangesStore.offeringsDeleted;
    const ratingsSettingsUpdatedItems: Array<EnrollmentCenterOfferingsRatingsUpdateDto|EnrollmentCenterOfferingsRatingsCreateDto> = settingsChangesStore.ratingsUpdated;
    const ratingsSettingsDeletedItems: Array<number> = settingsChangesStore.ratingsDeleted;
    let updatedCallCenterSettings: EnrollmentCenterSettingsUpdateDto | null = settingsChangesStore.basicInfoUpdated;
    if (!updatedCallCenterSettings) {
        updatedCallCenterSettings = enrollmentCenterMapper.toUpdateDto(callCenterSettings);
    }

    const rateOptionsUpdatedItems: Array<EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto> = settingsChangesStore.rateOptionsUpdated;
    const rateOptionsDeletedItems: Array<number> = settingsChangesStore.rateOptionsDeleted;

    let offeringsChanged = false;

    /* Handle saving details settings */
    while (detailsSettingsDeletedItems.length > 0) {
        const id = detailsSettingsDeletedItems.pop();
        if (id) {
            await enrollmentCenterRepo.deleteDetail(id);
        }
    }

    for (const item of detailsSettingsUpdatedItems) {
        if (item.id) {
            const id = item.id;
            delete item.id;
            await enrollmentCenterRepo.updateDetail(id, item);
        } else {
            delete item.id;
            await enrollmentCenterRepo.addDetail(item);
        }
    }

    /* Handle saving offering settings */
    while (offeringsSettingsDeletedItems.length > 0) {
        offeringsChanged = true;
        const id = offeringsSettingsDeletedItems.pop();
        if (id) {
            await enrollmentCenterRepo.deleteOffering(id);
        }
    }

    for (const item of offeringsSettingsUpdatedItems) {
        offeringsChanged = true;
        for (const option of item.options) {
            if (option.id < 0) {
                delete option.id;
            }
        }
        if (item.id) {
            const id = item.id;
            delete item.id;
            await enrollmentCenterRepo.updateOffering(id, item);
        } else {
            delete item.id;
            await enrollmentCenterRepo.addOffering(item);
        }
    }

    if (offeringsChanged) {
        locationOfferingsStore.clear();
    }

    /* Handle saving ratings settings */
    while (ratingsSettingsDeletedItems.length > 0) {
        const id = ratingsSettingsDeletedItems.pop();
        if (id) {
            await enrollmentCenterRepo.deleteRating(id);
        }
    }

    for (const item of ratingsSettingsUpdatedItems) {
        for (const option of item.options) {
            if (option.id < 0) {
                delete option.id;
            }
        }
        if (item.id) {
            const id = item.id;
            delete item.id;
            await enrollmentCenterRepo.updateRating(id, item);
        } else {
            delete item.id;
            await enrollmentCenterRepo.addRating(item);
        }
    }

    /* Handle saving rate options settings */
    while (rateOptionsDeletedItems.length > 0) {
        const id = rateOptionsDeletedItems.pop();
        if (id) {
            await enrollmentCenterRepo.deleteRateOption(id);
        }
    }

    for (const item of rateOptionsUpdatedItems) {
        if (item.id) {
            const id = item.id;
            delete item.id;
            await enrollmentCenterRepo.updateRateOption(id, item);
        } else {
            delete item.id;
            await enrollmentCenterRepo.addRateOption(item);
        }
    }

    /* Handle saving flags */
    updatedCallCenterSettings.enable_details = settingsChangesStore.enableDetailsFlag;
    updatedCallCenterSettings.enable_offerings = settingsChangesStore.enableOfferingsFlag;
    updatedCallCenterSettings.enable_ratings = settingsChangesStore.enableRatingsFlag;
    updatedCallCenterSettings.enable_pricing = settingsChangesStore.enableRateOptionsFlag;
    updatedCallCenterSettings.enable_availability = settingsChangesStore.enableAvailabilityFlag;
    await Promise.all([
        enrollmentCenterRepo.updateSettings(updatedCallCenterSettings as EnrollmentCenterSettingsUpdateDto),
        saveLocationsSupportedData()
    ]);
}
