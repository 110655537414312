

































import { CustomStaffValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-staff-values-changes-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { UserCreateDto, UserUpdateDto } from '@/staff/models/user';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import {
    CustomValuesUpdateDto,
    CustomFieldsTypes,
    CustomFieldValue
} from '@/crm-types/custom-fields/custom-fields-types';
import { ChildCreateDtoInterface, ChildPostDTO, ChildUpdateDtoInterface } from '@/families/models/child';
import { getModule } from 'vuex-module-decorators';
import { CustomFieldsValuesStore } from '@/crm-types/custom-fields/stores/custom-fields-values-store';
import { SelectListOption } from '@/core/models/select-list';
import { EventTypes } from '@/constants/event-type-constants';
import { CustomFamilyValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-family-values-changes-store';
import { RequiredFieldsStore } from '@/crm-types/store/required-fields-store';
import { CustomValuesMapper } from '@/crm-types/custom-fields/mappers/custom-values-mapper';
import type { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import type { Family, FamilyUpdateDtoInterface, FamilyCreateDto } from '@/families/models/family';
import BaseValueDisplay from '@/components/base/BaseValueDisplay.vue';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

const customFieldValuesStore = getModule(CustomFieldsValuesStore);
const customFamilyValuesChanges = getModule(CustomFamilyValuesChangesStore);
const customStaffValuesChanges = getModule(CustomStaffValuesChangesStore);
const customValueMapper = new CustomValuesMapper();
const requiredFieldsStore = getModule(RequiredFieldsStore);

@Component({
    components: { BaseValueDisplay }
})
export default class CustomValueEditor extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) customField!: CustomField;
    @Prop() family!: Family | undefined;
    @Prop() familyDto!: FamilyCreateDto | FamilyUpdateDtoInterface | undefined;
    @Prop() child!: ChildUpdateDtoInterface | ChildCreateDtoInterface | undefined;
    @Prop() childDto!: ChildPostDTO | ChildUpdateDtoInterface | undefined;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop() staffDto!: UserCreateDto | UserUpdateDto | undefined;

    private items: Array<SelectListOption> = [];
    private model: Array<number> | number | string | null = null;
    private updatedEvent = EventTypes.UPDATED;

    get isMultiSelect(): boolean {
        return this.customField.type.id === CustomFieldsTypes.MULTI_SELECT;
    }

    get isSingleSelect(): boolean {
        return this.customField.type.id === CustomFieldsTypes.SINGLE_SELECT;
    }

    get isText(): boolean {
        return this.customField.type.id === CustomFieldsTypes.TEXT;
    }

    get label(): string {
        return `${this.customField.is_client_required ? '*' : ''} ${this.customField.label}`;
    }

    get displayValue() {
        if (this.isText) {
            return this.model;
        }
        // Do not show a raw id; show the label(s)
        if (this.isSingleSelect) {
            for (const item of this.items) {
                if (item.value === this.model) {
                    return item.text;
                }
            }
        }
        if (this.isMultiSelect && this.model) {
            const values = [];
            for (const item of this.items) {
                if ((this.model as Array<number>).includes(item.value as number)) {
                    values.push(item.text);
                }
            }
            return values.join(', ');
        }
        return '';
    }

    @Watch('child')
    async setChildValues() {
        await this.setValues();
    }

    @Watch('family')
    async setFamilyValues() {
        await this.setValues();
    }

    @Watch('staffDto')
    async setStaffValues() {
        await this.setValues();
    }

    @Watch('familyDto')
    async setFamilyDtoValues() {
        await this.setValues();
    }

    @Watch('model', { immediate: true })
    private resetMultiSelects() {
        // if a multiselect is required and you de-select choices it doesnt register as being invalid so im resetting it to null.
        if (this.isMultiSelect && this.customField.is_client_required && (typeof this.model === 'object' && this.model!.length === 0)) {
            this.model = null;
        }
    }

    async mounted() {
        await this.setValues();
    }

    async setValues() {
        if (!this.isText) {
            await customFieldValuesStore.initValuesForField(this.customField);
            this.items = this.valueToSelectItems(customFieldValuesStore.valuesForField(this.customField));
        }

        if (this.staffDto) {
            if (!this.staffDto.custom_values) {
                this.staffDto.custom_values = [];
            }
            this.setModel(this.staffDto.custom_values);
        } else if (this.familyDto) {
            if (!this.familyDto.custom_values) {
                this.familyDto.custom_values = [];
            }
            this.setModel(this.familyDto.custom_values);
        } else if (!this.child && this.family) {
            const customValues = [];
            for (const customValue of this.family.custom_values) {
                customValues.push(customValueMapper.toDto(customValue));
            }
            this.setModel(customValues);
        } else if (this.child) {
            if (!this.child.custom_values) {
                this.child.custom_values = [];
            }
            this.setModel(this.child.custom_values);
        } else if (this.childDto) {
            if (!this.childDto.custom_values) {
                this.childDto.custom_values = [];
            }
            this.setModel(this.childDto.custom_values);
        }
        if (this.customField.is_client_required && !this.model && !this.isDisabled) {
            requiredFieldsStore.addToList({ familyId: this.family?.id ?? null, fieldName: this.customField.label });
        }
    }

    updated() {
        if (!this.model) {
            return;
        }
        const dto = {
            custom_value_group: this.customField.id,
            custom_text_value: null,
            custom_values: null
        } as CustomValuesUpdateDto;
        if (this.isSingleSelect) {
            // For API simplicity, we always make the select options an array even if only 1 is allowed
            dto.custom_values = [this.model as number];
        } else if (this.isMultiSelect) {
            dto.custom_values = this.model as Array<number>;
        } else if (this.isText) {
            dto.custom_text_value = this.model as string;
        }
        if (this.familyDto || this.childDto) {
            this.$emit(EventTypes.UPDATED, dto);
        } else if (this.child || this.family) {
            // Replace the current value with new selections
            customFamilyValuesChanges.replaceChanges({
                updates: dto,
                family: this.family!,
                child: this.child,
                field: this.customField
            });
        } else if (this.staffDto) {
            customStaffValuesChanges.replaceChanges({
                updates: dto,
                staffDto: this.staffDto,
                field: this.customField
            });
        }

        if (this.customField.is_client_required && !this.isDisabled) {
            if ((dto.custom_values && dto.custom_values.length) || dto.custom_text_value) {
                requiredFieldsStore.removeFromList({ familyId: this.family?.id ?? null, fieldName: this.customField.label });
            } else {
                requiredFieldsStore.addToList({ familyId: this.family?.id ?? null, fieldName: this.customField.label });
            }
        }
        this.$emit(EventTypes.UPDATED);
    }

    private setModel(customValues: Array<CustomValuesUpdateDto>) {
        for (const customValue of customValues) {
            if (customValue.custom_value_group === this.customField.id) {
                if (this.isMultiSelect && customValue.custom_values) {
                    this.model = customValue.custom_values;
                } else if (this.isSingleSelect && customValue.custom_values) {
                    this.model = customValue.custom_values[0];
                } else {
                    this.model = customValue.custom_text_value;
                }
            }
        }
        if (this.customField.is_client_required && !this.isDisabled) {
            this.model
                ? requiredFieldsStore.removeFromList({
                    familyId: this.family?.id ?? null,
                    fieldName: this.customField.label
                })
                : requiredFieldsStore.addToList({
                    familyId: this.family?.id ?? null,
                    fieldName: this.customField.label
                });
        }
        if (!this.model || (this.familyDto || this.childDto)) {
            // Set default values
            const defaultValue = this.valueToSelectItems(customFieldValuesStore.valuesForField(this.customField).filter(value => value.is_default))[0];
            if (this.isMultiSelect && defaultValue) {
                this.model = [defaultValue.value as number];
            } else if (this.isSingleSelect && defaultValue) {
                this.model = defaultValue.value;
            } else if (this.isText) {
                this.model = '';
            }
        }
    }

    private valueToSelectItems(options: Array<CustomFieldValue>): Array<SelectListOption> {
        return options.map(value => {
            return { text: value.value, value: value.id };
        });
    }
}
