








































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { InContactEvent } from '@/integrations/models/in-contact-event';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);

@Component({})

export default class FamilyCallCenterPane extends Mixins(LocaleMixin) {
    private headers = [{
        text: 'Date',
        value: 'datetime',
        width: '1%'
    }, {
        text: 'User',
        value: 'staff'
    }, {
        text: 'Skill',
        value: 'skill'
    }, {
        text: 'Media Type',
        value: 'media_type'
    }, {
        text: 'Direction',
        value: 'direction',
        width: '1%'
    }, {
        text: 'Disposition',
        value: 'disposition'
    }, {
        text: 'Link',
        value: 'link',
        width: '1%'
    }];

    @Prop({ required: true }) readonly inContactEvents!: Array<InContactEvent>;

    private formatEventDate(date: string): string {
        return this.formatDate(date, authState.userTimeZone) + ' ' + this.formatTime(date, authState.userTimeZone);
    }

}
