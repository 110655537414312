































































































































































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { checkUserLoggedIn, doLogin, checkUser, doLogout } from '@/auth/auth';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { ErrorDisplay } from '@/errors/error-display';
import { getLocaleForCountry } from '@/locales/locale-helpers';
import { VForm } from '@/types/types';
import { serversForLocale } from '@/locales/locales';
import { LoginUtils } from '@/core/login-utils';
import { FeaturesStore } from '@/features/features-store';
import { baseUrl } from '@/core/base-url';
import { vueEnv } from '@/core/env-vars';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const featureState = getModule(FeaturesStore);

@Component
export default class LoginLLE extends Mixins(LocaleMixin) {
    private username = '';
    private password = '';
    private validForm = false;
    private termsOpen = false;
    private privacyOpen = false;
    private termsUrl = baseUrl + '/showTerms.php?file=1';
    private privacyUrl = baseUrl + '/showTerms.php?file=2';
    private notEmpty = [
        (v: string) => !!v || 'Please enter a value'
    ];

    get isLoggedIn() {
        return authState.isLoggedIn;
    }

    // definitions for typescript
    @Ref('loginForm') readonly loginForm!: VForm;

    public async created() {
        document.getElementsByTagName('body')[0].classList.add('moment-path');
        if (!this.isLoggedIn) {
            await checkUserLoggedIn(authState);
        }
        if (authState.authenticated) {
            const userCheck = checkUser(authState.userInfoObject!);
            if (userCheck.fullyLoggedIn) {
                authState.storeLoggedIn();
            } else {
                if (userCheck.redirectLegacy) {
                    authState.storeNeedsLegacyRedirect();
                    await this.$router.go(0);
                } else {
                    await this.$router.push({ name: userCheck.step });
                }
            }
        }
        if (this.isLoggedIn) {
            await featureState.init();
            await this.$router.push({ name: 'home' });
        }
    }

    public clearForm() {
        this.username = '';
        this.password = '';
        this.loginForm.reset();
    }

    private learnMore() {
        window.open('https://hubs.ly/Q014Pp230', '_blank');
    }

    public async login() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const loginResponse = await doLogin(this.username, this.password);
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
        if (loginResponse.success) {
            authState.storeAuthenticated(loginResponse);
            const userCheck = checkUser(loginResponse.userInfo!);
            if (userCheck.fullyLoggedIn) {
                this.$root.$i18n.locale = getLocaleForCountry(loginResponse.userInfo!.country);

                // Redirect users to the correct server
                if (vueEnv !== 'development' && this.$root.$i18n.locale !== process.env.VUE_APP_I18N_LOCALE) {
                    const server = serversForLocale[this.$root.$i18n.locale];
                    const url = new URL(server);
                    if (url.hostname !== location.hostname) {
                        authState.storeLoggedOut();
                        await doLogout();
                        window.location.replace(server);
                    }
                }

                authState.storeLoggedIn();
                await LoginUtils.setupCTranslate(Number(authState.currentCustomerDb ?? 0));

                if (this.$route.query.redirect) {
                    await this.$router.push({ path: this.$route.query.redirect as string });
                } else {
                    await featureState.init();
                    await this.$router.push({ name: 'home' });
                }
            } else {
                if (userCheck.redirectLegacy) {
                    authState.storeNeedsLegacyRedirect();
                    await this.$router.go(0);
                } else {
                    await this.$router.push({ name: userCheck.step });
                }
            }
        } else {
            let errorMsg = 'Login failed: Please verify that your username and password are correct and try again.';
            if (loginResponse.errorDetail) {
                if (loginResponse.errorTitle === 'User locked.') {
                    errorMsg = loginResponse.errorDetail;
                } else {
                    errorMsg = 'Login failed. ' + loginResponse.errorDetail;
                }
            }
            await ErrorDisplay.error(errorMsg);
        }
    }
}
