














import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { EventTypes } from '@/constants/event-type-constants';
import FamilyActivityTable from '@/families/components/new/FamilyActivityTable.vue';
import { ActionItem } from '@/dashboards/models/action-items-models';

@Component({
    components: {
        FamilyActivityTable
    }
})
export default class FamilyActivityTasks extends Mixins(LocaleMixin) {
    @Prop() family!: Family;
    @Prop() tasks!: Array<ActionItem>;
    @Prop({ default: false }) future!: boolean;

    private updatedEvent = EventTypes.UPDATED;

    loadData() {
        this.$emit(EventTypes.UPDATED);
    }

    get noDataText() {
        if (this.future) {
            return 'There is no future activity scheduled for this family. Click the ADD button to schedule something.';
        }
        return 'There are no currently due or past due items for this family.';
    }

}
