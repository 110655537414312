
























































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { InterfaceSetting, OrgInterfaceSettingUpdateDto } from '@/dashboards/models/interface-settings-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { InterfaceSettingsChangesStore } from '@/dashboards/store/interface-settings-changes-store';
import { EventTypes } from '@/constants/event-type-constants';
import { AuthStore } from '@/store/auth-store';
import CorporateDashboardSettings from '@/dashboards/components/CorporateDashboardSettings.vue';
import store from '@/store';
import LocationDashboardSettings from '@/dashboards/components/LocationDashboardSettings.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const settingsStore = getModule(InterfaceSettingsStore);
const settingsChangesStore = getModule(InterfaceSettingsChangesStore);
const authState = getModule(AuthStore, store);

@Component({
    components: {
        BaseClose,
        LocationDashboardSettings,
        CorporateDashboardSettings
    }
})
export default class DashboardSettingsModal extends Mixins(LocaleMixin) {
    private loadingKey = 'dashboardSettingsModal';
    private changedSettings: Array<InterfaceSetting> = [];
    private isLoaded = false;

    private get isCorpUser() {
        return authState.isCorporateUser;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    private get settingsComponents() {
        const components = [];
        if (this.isCorpUser || this.isSuperUser) {
            components.push(
                {
                    component: CorporateDashboardSettings,
                    title: 'Insight Dashboard'
                }
            );
        }
        components.push(
            {
                component: LocationDashboardSettings,
                title: 'Action Dashboard'
            }
        );
        return components;
    }

    private storeChangedSettings(setting: InterfaceSetting) {
        let exists = false;
        for (const existingSetting of this.changedSettings) {
            if (setting.id === existingSetting.id) {
                existingSetting.value = setting.value;
                exists = true;
            }
        }
        if (!exists) {
            this.changedSettings.push(setting);
        }
    }

    private async updateSettingStore() {
        loadingState.loadingIncrement(this.loadingKey);
        for (const setting of this.changedSettings) {
            await settingsStore.updateSetting({
                settingId: setting.id,
                dto: { value: setting.value } as OrgInterfaceSettingUpdateDto
            });
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Whether or not the save button should be disabled
     */
    get isSaveDisabled(): boolean {
        return !settingsChangesStore.areChanges;
    }

    /**
     * Handles closing the dialog
     */
    private handleClose() {
        // Set save button to false
        settingsChangesStore.setSaveButtonForChildStatusGraphEnrolledToggle(false);
        settingsChangesStore.setSaveButtonForChildStatusGraphPendingOpportunitiesToggle(false);
        settingsChangesStore.setSaveButtonForPipelineValueToggle(false);
        // reset changed settings array
        this.changedSettings = [];
        this.$emit(EventTypes.CLOSE);
    }

    /**
     * Save everything
     */
    private async save() {
        await this.updateSettingStore();
        this.handleClose();
        this.$emit(EventTypes.UPDATED, 'Dashboard Settings Updated');
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        this.isLoaded = false;
        const settingsResponse = settingsStore.retrieveAll();
        await settingsResponse;
        this.changedSettings = [];
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }
}
