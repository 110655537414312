














































































































import { Reminder } from '@/communications/reminders/models/reminder-models';
import { EmailRemindersStore } from '@/communications/reminders/store/email-reminders-store';
import { TextRemindersStore } from '@/communications/reminders/store/text-reminders-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { AppStateStore } from '@/store/app-state-store';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { MessageTableData, MessageTemplate } from '@/communications/templates/models/message-template';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { AuthStore } from '@/store/auth-store';
import { OrgsStore } from '@/store/orgs-store';
import { Org } from '@/models/organization/org';
import { ApiParameters } from '@/repositories/abstract-repository';
import { TemplatesRepository } from '@/communications/templates/repositories/templates-repository';
import { DataTableOptions } from '@/models/datatables';
import { getPagination } from '@/core/datatables-utils';
import { getSortingParameters } from '@/core/sort-utils';
import CreateTemplate from '@/communications/templates/components/CreateTemplate.vue';
import Loading from '@/components/Loading.vue';
import store from '@/store';
import MassCopyTemplatesModal from '@/communications/templates/components/MassCopyTemplatesModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { Route } from 'vue-router';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const orgStore = getModule(OrgsStore);
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const emailReminderStore = getModule(EmailRemindersStore);
const textReminderStore = getModule(TextRemindersStore);
const templatesRepo = new TemplatesRepository();

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: { MassCopyTemplatesModal, CreateTemplate, Loading }
})
export default class MessageTemplates extends Mixins(CrmBreakpointsMixin, LocaleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
        if (canViewTemplatesRemindersAttachments) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    // Whether or not this is an email/text template or a reminder/notification
    @Prop({ type: Boolean, default: true }) readonly isTemplate!: boolean;

    private loadingKey = this.isTemplate ? 'messageTemplates' : 'remindersKey';
    private isLoading = true;
    private rootOrg: Org | null = null;
    private automationMessageTemplatesPermissionGrant = false;
    private selectedItem: MessageTemplate | Reminder | MessageTableData | null = null;

    // Normal email and text template stuff - pagination.
    private currentItemCount = 0;
    private templateItems: Array<MessageTableData> = [];
    private options: DataTableOptions = { itemsPerPage: 25, page: 1 };
    private search = '';

    // copy mode stuff
    private isCopyMode = false;
    private selectedCopyTemplates: Array<MessageTableData> = [];
    private isCopyModalShown = false;
    private closeEvent = EventTypes.CLOSE;
    private updatedEvent = EventTypes.UPDATED;
    private viewEvent = EventTypes.VIEW;
    private batchUpdateFlag = false;

    get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isSuperUser() {
        return authState.isSuperuser;
    }

    get isCorpUser() {
        return authState.isCorporateUser;
    }

    get canAddEditTemplate() {
        if (this.isTemplate) {
            if (this.isSuperUser) {
                return true;
            }

            if (!this.isCrmPlus && this.isCorpUser) {
                return true;
            }

            if (this.automationMessageTemplatesPermissionGrant) {
                return true;
            }
        }

        return false;
    }

    private get messageTemplateHeaders() {
        const messageTemplateTableDataHeaders: Array<DataTableHeader> = [
            { text: 'Template Name', value: 'name' },
            { text: 'Type', value: 'type' }
        ];

        if (this.isTemplate) {
            messageTemplateTableDataHeaders.push(
                { text: 'Location(s) Available', value: 'location' },
                { text: 'Subject (Emails only)', value: 'subject' },
                { text: 'Modified', value: 'last_edited_datetime', width: this.modifiedWidth }
            );
        } else {
            messageTemplateTableDataHeaders.push(
                { text: 'Subject (Emails only)', value: 'subject' },
                { text: 'Modified', value: 'last_edited_datetime' },
                { text: 'Modified By', value: 'last_edited_by_staff' }
            );
        }
        return messageTemplateTableDataHeaders;
    }

    /**
     * Formatted items to use in the table.
     */
    private get messageTemplates(): Array<MessageTableData> {
        return this.templates.map((message: MessageTemplate | Reminder) => {
            return {
                id: message.id,
                name: message.name,
                type: message.media_type ?? '',
                subject: message.subject ?? '',
                last_edited_datetime: this.formatDate(message.last_edited_datetime!),
                last_edited_by_staff: this.staffName(message),
                location: this.getLocationName(message),
                item_key: message.media_type + '-' + message.id
            };
        });
    }

    private get modifiedWidth() {
        switch (this.crmBreakpoint) {
        case 'sm':
            return '11ch';
        case 'md':
            return '13ch';
        case 'lg':
            return '14ch';
        case 'xl':
            return '15ch';
        }
    }

    /**
     * The current org the user is at within the UI.
     */
    get org() {
        return appState.storedCurrentOrg;
    }

    get showCopyButton() {
        return (this.isCorpUser || this.isSuperUser) && this.isTemplate;
    }

    /**
     * Templates or reminders retrieved from the API.
     */
    private get templates(): Array<MessageTemplate | Reminder> {
        // Duplicate logic from above with constants, otherwise, the reminders won't be reactive to the store
        const reminders = [...emailReminderStore.stored, ...textReminderStore.stored];
        for (const reminder of reminders) {
            reminder.media_type = this.templateType(reminder);
        }

        return reminders;
    }

    @Watch('isCopyMode')
    copyModeStarted() {
        if (this.isCopyMode) {
            this.selectedCopyTemplates = [];
        }
    }

    @Watch('isTemplate')
    private async loadOtherTemplates() {
        await this.fetchTemplates();

        if (!this.isTemplate) {
            this.search = '';
        }
    }

    // Watch for changes to the Datatables options -- pagination.
    @Watch('options')
    async onOptionsChange() {
        if (!this.batchUpdateFlag) {
            await this.getTemplateItems();
        }
        if (this.isCopyMode) {
            this.selectedCopyTemplates = this.selectedCopyTemplates.filter((template) => {
                return this.templateItems.map((item) => {
                    return item.item_key;
                }).includes(template.item_key);
            });
        }
    }

    @Watch('org')
    private async orgChanged() {
        await this.getTemplateItems();
    }

    /** lifecycle hooks */
    private async created() {
        const fetchPromise = this.fetchTemplates();
        const featuresPromise = featuresStore.init();
        await fetchPromise;
        await featuresPromise;

        if (this.isCrmPlus) {
            this.automationMessageTemplatesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationMessageTemplates);
        }

        this.rootOrg = await orgStore.getOrgById(1);
    }

    private async editTemplate(itemRow: MessageTableData) {
        if (this.isTemplate) {
            this.selectedItem = itemRow;
        } else {
            this.selectedItem = this.templates.find(template => template.id === itemRow.id &&
                template.media_type === itemRow.type) as MessageTemplate;
        }

        if (!this.selectedItem.id) {
            return;
        }

        if (this.isEmail(this.selectedItem)) {
            // Casting item.id as string because that's what Vue Router expects
            if (this.isTemplate) {
                await this.$router.push({
                    name: 'template-editor-email',
                    params: { id: this.selectedItem.id.toString() }
                });
            } else {
                await this.$router.push({
                    name: 'template-editor-email-reminder',
                    params: { id: this.selectedItem.id.toString() }
                });
            }
        } else {
            if (this.isTemplate) {
                // Handle editing text templates
                await this.$router.push({ name: 'template-editor-text', params: { id: this.selectedItem.id.toString() } });
            } else {
                // Handle text reminders/notifications
                await this.$router.push({ name: 'template-editor-text-reminder', params: { id: this.selectedItem.id.toString() } });
            }
        }
    }

    /** methods */
    private async clearSearch() {
        // Update items, no search term.
        this.search = '';
        await this.getTemplateItems();
    }

    private async doSearch() {
        if (!this.search) {
            // Update items, no search term.
            await this.getTemplateItems();
            return;
        }

        if (this.search.length < 3) {
            // Do not search unless we have at least 3 characters
            return;
        }

        await this.getTemplateItems();
    }

    /**
     * Get the items to display in the table.
     */
    private async getTemplateItems() {
        if (!this.org) {
            return;
        }
        this.isLoading = true;
        const pagination = getPagination(this.options);
        const extraParams: ApiParameters = getSortingParameters(this.options, 'name');
        extraParams.org_id = this.org.id;

        if (this.search) {
            extraParams.search = this.search;
        }

        const data = await templatesRepo.get(pagination, extraParams);
        this.templateItems = data.entities.map((message: MessageTemplate) => {
            return {
                id: message.id,
                name: message.name,
                type: message.media_type ?? '',
                subject: message.subject ?? '',
                last_edited_datetime: this.formatDate(message.last_edited_datetime!),
                last_edited_by_staff: this.staffName(message),
                location: this.getLocationName(message),
                item_key: message.media_type + '-' + message.id
            };
        });

        this.currentItemCount = Number(data.count);
        const maxPage = Math.ceil(this.currentItemCount / this.options.itemsPerPage);
        if (this.options.page > maxPage) {
            this.options.page = Math.max(1, maxPage);
        }
        this.isLoading = false;
    }

    // This is for reminders now.
    private async fetchTemplates() {
        loadingState.loadingIncrement(this.loadingKey);
        // Retrieve templates/reminders in parallel
        if (!this.isTemplate) {
            const emailReminders = emailReminderStore.init();
            const textReminders = textReminderStore.init();
            await emailReminders;
            await textReminders;
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    isEmail(template: MessageTemplate | Reminder | MessageTableData): boolean {
        if ('type' in template && 'location' in template) {
            return template.type === 'Email';
        }

        return 'subject' in template;
    }

    staffName(template: MessageTemplate | Reminder): string {
        return template.last_edited_by_staff
            ? `${template.last_edited_by_staff.values.first_name} ${template.last_edited_by_staff.values.last_name}`
            : '';

    }

    private getLocationName(template: MessageTemplate | Reminder): string {
        let location;

        if (this.isTemplate) {
            if ((template as MessageTemplate).brand !== null) {
                location = (template as MessageTemplate).brand?.values.name ?? this.rootOrg?.name ?? '';
            } else if ((template as MessageTemplate).location_group !== null) {
                location = (template as MessageTemplate).location_group?.values.value ?? this.rootOrg?.name ?? '';
            } else {
                location = (template as MessageTemplate).org?.values?.name ?? this.rootOrg?.name ?? '';
            }
        } else {
            location = this.rootOrg?.name ?? '';
        }

        return location;
    }

    async showLatest() {
        this.batchUpdateFlag = true;
        this.options.sortBy = ['last_edited_datetime'];
        this.options.sortDesc = [true];
        await this.getTemplateItems();
        this.batchUpdateFlag = false;
    }

    private templateType(template: MessageTemplate | Reminder): string {
        if (template.media_type) {
            return template.media_type;
        }

        return template.subject !== undefined ? 'Email' : 'Text';
    }
};
