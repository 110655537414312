import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { OutgoingText, OutgoingTextUpdateDto } from '@/communications/messages/models/text';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';

export class OutgoingTextMapper implements AbstractMapper<OutgoingText> {
    toCreateDto(): AbstractCreateDto {
        return {
            // Nothing here yet..
        };
    }

    toUpdateDto(entity: OutgoingText): AbstractUpdateDto {
        const obj: OutgoingTextUpdateDto = {};

        if (entity.data) {
            obj.data = entity.data;
        }

        if (entity.send_by_user) {
            obj.send_by_user = entity.send_by_user.id;
        }

        if (entity.sent_date_time) {
            obj.send_date_time = entity.sent_date_time;
        }

        return obj;
    }
}
