



































































import { AuthMixin } from '@/auth/auth-mixin';
import AddEditMessageModal from '@/communications/messages/components/AddEditMessageModal.vue';
import { MessageDirection, OutgoingStatusString } from '@/communications/messages/models/message';
import { OutgoingText, TextMessage } from '@/communications/messages/models/text';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { SortConstants } from '@/constants/sort-constants';
import { sortByObjectProperty } from '@/core/sort-utils';
import { isAfterNow } from '@/date-time/date-time-utils';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import type { Family } from '@/families/models/family';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';

const textsRepo = new TextsRepository();

@Component({
    components: { AddEditMessageModal }
})
export default class FamilyActivityPendingTexts extends Mixins(AuthMixin, CrmBreakpointsMixin, FamilyHubMixin, LocaleMixin) {
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ required: true }) readonly texts!: Array<TextMessage>;

    private updatedEvent = EventTypes.PENDING_TEXT_UPDATED;
    private selectedText: TextMessage | null = null;
    private showEditModal = false;

    get deliveryWidth() {
        if (!this.canEditFamily) {
            return '25%';
        }
        switch (this.crmBreakpoint) {
            case 'sm':
            case 'md':
                return '25ch';
            case 'lg':
                return '27ch';
            case 'xl':
                return '30ch';
        }
    }

    private get headers(): Array<DataTableHeader> {
        const headers = [
            { text: 'Delivery', value: 'sent_date_time', width: this.deliveryWidth },
            { text: 'Message', value: 'data', width: '75%' }
        ];
        if (this.canEditFamily) {
            headers.push({ text: '', value: 'actions', width: '23ch' });
        }
        return headers;
    }

    /**
     * The list of pending texts.
     */
    get pendingTexts(): Array<TextMessage> {
        return sortByObjectProperty(
            this.texts.filter((text) => {
                if (!(text.type === MessageDirection.INCOMING) && (text as OutgoingText).status === OutgoingStatusString.PENDING) {
                    if (!text.sent_date_time) {
                        return true;
                    }
                    return isAfterNow(text.sent_date_time);
                }

                return false;
            }),
            'sent_date_time',
            SortConstants.ASC
        ) as Array<TextMessage>;
    }

    /**
     * Cancel the pending text message.
     *
     * @param text
     */
    private async cancelPending(text: TextMessage) {
        await this.$swal({
            text: 'Are you sure you want to cancel sending this text message?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                await textsRepo.cancelPendingText(text.id);
                this.$emit(EventTypes.PENDING_TEXT_CANCELLED);
            }
        });
    }

    /**
     * Show the edit text modal.
     *
     * @param text
     */
    private editPending(text: TextMessage) {
        this.selectedText = text;
        this.showEditModal = true;
    }

    private pendingTextUpdated() {
        this.$emit(this.updatedEvent);
    }
}
