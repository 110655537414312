import {
    EnrollmentCenterLocationExclusions,
    EnrollmentCenterLocationExclusionsDto
} from '@/enrollment-center/models/enrollment-center-location-exclusions-models';
import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    EnrollmentCenterDetails,
    EnrollmentCenterDetailsCreateDto,
    EnrollmentCenterDetailsUpdateDto,
    EnrollmentCenterRateOption, EnrollmentCenterRateOptionCreateDto,
    EnrollmentCenterRateOptionUpdateDto,
    EnrollmentCenterSettings,
    EnrollmentCenterSettingsUpdateDto,
    EnrollmentCenterOfferingsRatings,
    EnrollmentCenterOfferingsRatingsCreateDto,
    EnrollmentCenterOfferingsRatingsUpdateDto
} from '@/enrollment-center/models/enrollment-center-models';

export class EnrollmentCenterSettingsRepository extends AbstractRepository<EnrollmentCenterSettings> {
    readonly endpoint = 'client/settings/call-centers';

    public async getSettings(): Promise<EnrollmentCenterSettings> {
        const response = await this.client.get(this.endpoint);
        return response.data;
    }

    public async updateSettings(payload: EnrollmentCenterSettingsUpdateDto): Promise<EnrollmentCenterSettings> {
        const response = await this.client.put(this.endpoint, payload);
        return response.data;
    }

    public async getOfferings(): Promise<Array<EnrollmentCenterOfferingsRatings>> {
        const response = await this.client.get(`${this.endpoint}/offerings`);
        return response.data;
    }

    public async getOfferingOption(id: number): Promise<EnrollmentCenterOfferingsRatings> {
        const response = await this.client.get(`${this.endpoint}/offerings/options/${id}`);
        return response.data;
    }

    public async deleteOffering(id: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/offerings/${id}`);
    }

    public async updateOffering(id: number, payload: EnrollmentCenterOfferingsRatingsUpdateDto | EnrollmentCenterOfferingsRatingsCreateDto): Promise<EnrollmentCenterOfferingsRatings> {
        const response = await this.client.put(`${this.endpoint}/offerings/${id}`, payload);
        return response.data;
    }

    public async addOffering(payload: EnrollmentCenterOfferingsRatingsUpdateDto | EnrollmentCenterOfferingsRatingsCreateDto): Promise<EnrollmentCenterOfferingsRatings> {
        const response = await this.client.post(`${this.endpoint}/offerings`, payload);
        return response.data;
    }

    public async getRatings(): Promise<Array<EnrollmentCenterOfferingsRatings>> {
        const response = await this.client.get(`${this.endpoint}/ratings`);
        return response.data;
    }

    public async deleteRating(id: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/ratings/${id}`);
    }

    public async updateRating(id: number, payload: EnrollmentCenterOfferingsRatingsUpdateDto | EnrollmentCenterOfferingsRatingsCreateDto): Promise<EnrollmentCenterOfferingsRatings> {
        const response = await this.client.put(`${this.endpoint}/ratings/${id}`, payload);
        return response.data;
    }

    public async addRating(payload: EnrollmentCenterOfferingsRatingsUpdateDto | EnrollmentCenterOfferingsRatingsCreateDto): Promise<EnrollmentCenterOfferingsRatings> {
        const response = await this.client.post(`${this.endpoint}/ratings`, payload);
        return response.data;
    }

    public async getSettingsForDetails(): Promise<Array<EnrollmentCenterDetails>> {
        const response = await this.client.get(this.endpoint + '/details');
        return response.data;
    }

    public async deleteDetail(id: number): Promise<void> {
        await this.client.delete(this.endpoint + '/details/' + `${id}`);
    }

    public async updateDetail(id: number, payload: EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto): Promise<EnrollmentCenterDetails> {
        const response = await this.client.put(this.endpoint + '/details/' + id, payload);
        return response.data;
    }

    public async addDetail(payload: EnrollmentCenterDetailsUpdateDto|EnrollmentCenterDetailsCreateDto): Promise<EnrollmentCenterDetails> {
        const response = await this.client.post(this.endpoint + '/details', payload);
        return response.data;
    }

    public async getRateOptions(): Promise<Array<EnrollmentCenterRateOption>> {
        const response = await this.client.get(this.endpoint + '/rate-options');
        return response.data;
    }

    public async deleteRateOption(id: number): Promise<void> {
        await this.client.delete(this.endpoint + '/rate-options/' + `${id}`);
    }

    public async updateRateOption(id: number, payload: EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto): Promise<EnrollmentCenterDetails> {
        const response = await this.client.put(this.endpoint + '/rate-options/' + id, payload);
        return response.data;
    }

    public async addRateOption(payload: EnrollmentCenterRateOptionUpdateDto|EnrollmentCenterRateOptionCreateDto): Promise<EnrollmentCenterRateOption> {
        const response = await this.client.post(this.endpoint + '/rate-options', payload);
        return response.data;
    }

    /**
     * Get the centers that are excluded from the enrollment team/center.
     */
    public async getExcludedLocations(): Promise<EnrollmentCenterLocationExclusions> {
        const response = await this.client.get(this.endpoint + '/center-exclusions');
        return response.data;
    }

    /**
     * Update the centers that are excluded from the enrollment team/center.
     */
    public async updateExcludedLocations(payload: EnrollmentCenterLocationExclusionsDto) {
        await this.client.put(this.endpoint + '/center-exclusions', payload);
    }
}
