









































import { Component, Mixins } from 'vue-property-decorator';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const nylasRepo = new NylasRepository();
const loadingState = getModule(LoadingStore);
@Component({
    components: { BaseClose }
})
export default class NylasEmailAuth extends Mixins(VmodelShowMixin, LocaleMixin) {
    private loadingKey = 'nylasEmailRevoke';

    close() {
        this.modelValue = false;
    }

    async proceed() {
        loadingState.loadingIncrement(this.loadingKey);
        // If there is an error, we don't care.
        await nylasRepo.revokeEmailIntegration().catch();
        loadingState.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.DELETED);
        this.close();
    }
}
