






























































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    DripCampaign,
    DripCampaignDrip,
    DripCampaignEventAction, DripCampaignStopTrigger
} from '@/automation/drip-campaigns/models/drip-campaign';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import ManageWorkflow from '@/automation/workflows/components/ManageWorkflow.vue';
import ManageDripCampaign from '@/automation/drip-campaigns/components/ManageDripCampaign.vue';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import { DripCampaignTiming, DripCommType } from '@/automation/drip-campaigns/drip-campaign-constants';
import { WorkflowEntityTypes, workflowStopTriggers } from '@/automation/workflows/constants/workflow-constants';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';

const campaignsRepo = new DripCampaignsRepository();
const campaignsState = getModule(DripCampaignsStore);
const dripCampaignUtils = new DripCampaignUtils();
const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const featuresStore = getModule(FeaturesStore);

@Component({
    components: { ManageDripCampaign, ManageWorkflow }
})
export default class ViewDripCampaign extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isOpen!: boolean;
    @Prop({ required: true }) readonly campaignId!: number;

    private loadingKey = 'viewDripCampaign';
    private statusLoadingKey = 'statusWarning';
    private fullCampaign: DripCampaign | null = null;
    private isManageOpen = false;
    private statusWarningOpen = false;
    private newStatus = 0;
    private newStatuses: Array<CrmTypeOption> = [];
    private updatedEvent = EventTypes.DRIP_CAMPAIGNS_UPDATED;
    private closeEvent = EventTypes.CLOSE;

    get canEdit() {
        return this.fullCampaign && this.fullCampaign.organization.id === this.currentOrgId;
    }

    get fieldString() {
        if (!this.fullCampaign || !this.fullCampaign.relative_field) {
            return '';
        }
        return this.fullCampaign.relative_field.values.form_name + ' ' + this.fullCampaign.relative_field.values.name;
    }

    get intervalString() {
        if (!this.fullCampaign) {
            return '';
        }
        if (this.fullCampaign.timing_type.id === DripCampaignTiming.FIXED) {
            return 'Fixed';
        }
        return 'Relative to ' + this.fieldString;
    }

    get isArchived() {
        return this.fullCampaign && dripCampaignUtils.isArchived(this.fullCampaign.status.id);
    }

    get isRelative() {
        return this.fullCampaign && this.fullCampaign.timing_type.id === DripCampaignTiming.RELATIVE;
    }

    get statusWarning() {
        return dripCampaignUtils.getStatusWarning(this.newStatus);
    }

    get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get currentOrgId(): number {
        if (!this.isFranchiseModeFeatureEnabled) {
            return 1;
        } else {
            return authState.userInfoObject && authState.userInfoObject.org_id > 0 ? authState.userInfoObject.org_id : 1;
        }
    }

    @Watch('isOpen')
    async opening() {
        if (this.isOpen && this.campaignId) {
            loadingState.loadingIncrement(this.loadingKey);
            this.fullCampaign = await campaignsRepo.getOne(this.campaignId);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    campaignUpdated(campaign: DripCampaign) {
        this.fullCampaign = campaign;
        this.$emit(EventTypes.DRIP_CAMPAIGNS_UPDATED, this.fullCampaign);
    }

    async changeStatus() {
        if (!this.fullCampaign) {
            return;
        }
        // handle status warning caused status changes
        loadingState.loadingIncrement(this.statusLoadingKey);
        this.fullCampaign = await campaignsRepo.updateStatus(this.fullCampaign.id, this.newStatus);
        await campaignsState.initStatuses(this.fullCampaign.status.id);
        this.newStatuses = campaignsState.statusOptions(this.fullCampaign.status.id);
        campaignsState.addOrUpdateEntity(this.fullCampaign);
        campaignsState.clearOrgDripCampaigns();
        this.closeStatusWarning();
        this.isManageOpen = true;
        loadingState.loadingDecrement(this.statusLoadingKey);
    }

    closeManage() {
        this.isManageOpen = false;
    }

    closeModal() {
        this.$emit(EventTypes.CLOSE);
    }

    closeStatusWarning() {
        this.statusWarningOpen = false;
    }

    async editCampaign() {
        if (!this.fullCampaign) {
            return;
        }
        if (!dripCampaignUtils.isEditable(this.fullCampaign.status.id)) {
            // handle case when they're not in an editable status
            this.newStatus = this.fullCampaign.status.id;
            loadingState.loadingIncrement(this.statusLoadingKey);
            this.statusWarningOpen = true;
            // make sure our status list is up to date
            await campaignsState.initStatuses(this.fullCampaign.status.id);
            this.newStatuses = campaignsState.statusOptions(this.fullCampaign.status.id);
            loadingState.loadingDecrement(this.statusLoadingKey);
            return;
        }
        this.isManageOpen = true;
    }

    /**
     * Template type/name formatting
     * @param drip
     */
    getCommString(drip: DripCampaignDrip) {
        const templateName = drip.comm_type.id === DripCommType.EMAIL ? drip.email_template?.values.name : drip.text_template?.values.name;
        return 'Send ' + drip.comm_type.values.value + ' Template: <strong>' + templateName + '</strong>';
    }

    /**
     * Drip timing formatting
     * @param drip
     * @param index
     */
    getDripIntervalString(drip: DripCampaignDrip, index: number) {
        if (!this.fullCampaign || !this.fullCampaign.drips) {
            return '';
        }
        if (this.isRelative) {
            return 'To be sent after <strong>' + drip.due_percentage + '%</strong> of the time between the campaign start and the ' + this.fieldString.toLowerCase() + ' has passed.';
        }
        return 'To be sent <strong>' + dripCampaignUtils.getFixedDripLength(this.fullCampaign.drips, index) + '</strong> after the campaign start.';
    }

    /**
     * format last edited info
     */
    getEditedString() {
        if (!this.fullCampaign || !this.fullCampaign.last_edited_datetime || !this.fullCampaign.last_edited_by_staff) {
            return '';
        }
        return 'Last edited ' + this.formatDate(this.fullCampaign.last_edited_datetime, this.timezone) + ' @ ' + this.formatTime(this.fullCampaign.last_edited_datetime, this.timezone) + ' by ' + this.formatStaffName(this.fullCampaign.last_edited_by_staff.values);
    }

    /**
     * Event Action formatting
     * @param drip
     * @param eventAction
     */
    getEventActionString(drip: DripCampaignDrip, eventAction: DripCampaignEventAction): string {
        const isText = drip.comm_type.id === DripCommType.TEXT;
        // shouldn't happen, but...
        if (isText && !eventAction.text_event) {
            return '';
        }
        if (!isText && !eventAction.email_event) {
            return '';
        }
        const eventString = isText ? eventAction.text_event!.values.value : eventAction.email_event!.values.value;
        let str = 'If the ' + eventString.toLowerCase() + ', ' + eventAction.event_action.values.value.toLowerCase();
        if (eventAction.drip_campaign) {
            str += ': ' + eventAction.drip_campaign.values.name;
        }
        if (eventAction.workflow) {
            str += ': ' + eventAction.workflow.values.name;
        }
        return str;
    }

    /**
     * Stop Trigger formatting
     * @param stopTrigger
     */
    getStopTriggerString(stopTrigger: DripCampaignStopTrigger): string {
        const stopTriggerType = workflowStopTriggers.filter((type) => {
            return stopTrigger.type.id === type.id;
        });
        if (!stopTriggerType.length) {
            return '';
        }
        const prefix = '<strong>' + stopTriggerType[0].value + '</strong>';
        if (stopTrigger.type.id === WorkflowEntityTypes.EVENT) {
            if (!stopTrigger.event_type_value) {
                return '';
            }
            return prefix + ' with type <strong>' + stopTrigger.event_type_value.values.value + '</strong>';
        }
        if (!stopTrigger.status_value) {
            return '';
        }
        return prefix + ' becomes <strong>' + stopTrigger.status_value.values.name + '</strong>';
    }
}
