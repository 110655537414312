


















































import { getMonochromeColorsWithLimits } from '@/charts/chart-utils';
import {
    BASE_DARK_BLUE,
    LIGHTEST_BLUE,
    LINE_LEADER_PURPLE
} from '@/core/style-utils';
import Highcharts from 'highcharts';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { StatusesStore } from '@/families/store/statuses-store';
import { AppStateStore } from '@/store/app-state-store';
import { Status } from '@/families/models/status';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import { StatusStatisticsParameters } from '@/models/report/period-statistics';
import { Count } from '@/models/report/count';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { getSimpleFilterDto } from '@/filters/families-filter-utils';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { ColorUtils } from '@/utils/color-utils';
import { SettingNames } from '@/dashboards/models/interface-settings-models';

const statusStore = getModule(StatusesStore);
const featureStore = getModule(FeaturesStore);
const appState = getModule(AppStateStore);
const periodStatsRepo = new PeriodStatisticsRepository();
const familiesFiltersStore = getModule(FamiliesFilterStore);
const authState = getModule(AuthStore, store);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

@Component({})
export default class StatusFilterFamilyLinks extends Mixins(LocaleMixin, CrmBreakpointsMixin) {
    private activeStatusText: Array<Status> = [];
    private colors: Array<Highcharts.ColorType> = [];
    private statusCounts: Array<Count> = [];

    public get org() {
        return appState.storedCurrentOrg;
    }

    get orgId() {
        return this.org?.id ?? authState.userInfoObject!.org_id;
    }

    public get characterCount() {
        let count = 0;
        this.activeStatusText.forEach((status, index) => {
            count += this.formatText(status.name, index).length - 5;
        });
        return count;
    }

    public get breakpoint() {
        return this.crmBreakpoint;
    }

    public get isCondensed() {
        if (this.breakpoint === 'lg' && this.characterCount > 100) {
            return true;
        } else if (this.breakpoint === 'md' && this.characterCount > 80) {
            return true;
        } else if (this.breakpoint === 'sm' && this.characterCount > 65) {
            return true;
        } else if (this.$vuetify.breakpoint.width < 1080 && this.characterCount > 50) {
            return true;
        } else {
            return this.activeStatusText.length > 9;
        }
    }

    public formatText(string: string, index: number) {
        if (this.statusCounts.length) {
            return `${this.statusCounts[index].total}<br />${string}`;
        }
        return `0<br />${string}`;
    }

    public handleRouting(status: Status) {
        familiesFiltersStore.setIsCustom(true);
        const filterDto = getSimpleFilterDto([status.id], this.orgId);
        familiesFiltersStore.applyAnonymousFilter(filterDto);
        this.$router.push({
            name: 'families-filtered',
            params: { statusFilter: 'custom' }
        });
    }

    async created() {
        const promises = [];
        promises.push(interfaceSettingsStore.init());
        promises.push(statusStore.init());
        promises.push(featureStore.init());
        await Promise.all(promises).then(() => {
            this.activeStatusText = statusStore.storedStatuses.filter(s => !s.is_archive);
            if (featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
                if (interfaceSettingsStore.hasWhiteLabel) {
                    const primaryColor = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
                    const lighterShade = ColorUtils.lighten(primaryColor, 70);
                    this.colors = getMonochromeColorsWithLimits(lighterShade, primaryColor, this.activeStatusText.length);
                } else {
                    const lighterShade = ColorUtils.lighten(LINE_LEADER_PURPLE, 70);
                    this.colors = getMonochromeColorsWithLimits(lighterShade, LINE_LEADER_PURPLE, this.activeStatusText.length);
                }
            } else {
                this.colors = getMonochromeColorsWithLimits(LIGHTEST_BLUE, BASE_DARK_BLUE, this.activeStatusText.length);
            }
        });
    }

    @Watch('org', {
        immediate: true,
        deep: true
    })
    async fetchData() {
        const params = { transitions_only: false } as StatusStatisticsParameters;
        params.org_ids = [this.orgId];
        params.current_counts_only = true;
        await periodStatsRepo.queryStatuses(params);
        this.statusCounts = periodStatsRepo.getGroupedByCount();

    }

}
