

















































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { EnrollmentCenterMapper } from '@/enrollment-center/mappers/enrollment-center-mapper';
import {
    EnrollmentCenterSettings,
    EnrollmentCenterSettingsUpdateDto
} from '@/enrollment-center/models/enrollment-center-models';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { VForm } from '@/types/types';

const loadingState = getModule(LoadingStore);
const enrollmentCenterMapper = new EnrollmentCenterMapper();
const crmTypesStore = getModule(CrmTypesStore);
const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);

@Component({
    components: {}
})
export default class BasicInfoSettings extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly callCenterSettings!: EnrollmentCenterSettings | null;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'basicInfoSettings';
    private callCenterSettingsDto: EnrollmentCenterSettingsUpdateDto | null = null;
    private enrollmentRepTypes: Array<CrmTypeOption> = [];
    private taskTypes: Array<CrmTypeOption> = [];

    private validForm = false;

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        const taskTypesResponse = crmTypesStore.initList(CrmTypeList.TASKS);
        const enrollmentRepTypesResponse = crmTypesStore.initList(CrmTypeList.CALL_CENTERS_ENROLLMENT_REP_FLOWS);
        await taskTypesResponse;
        await enrollmentRepTypesResponse;
        this.enrollmentRepTypes = crmTypesStore.listOptions(CrmTypeList.CALL_CENTERS_ENROLLMENT_REP_FLOWS);
        this.taskTypes = crmTypesStore.listOptions(CrmTypeList.TASKS);
        if (this.callCenterSettings) {
            this.callCenterSettingsDto = enrollmentCenterMapper.toUpdateDto(this.callCenterSettings);
            if (this.callCenterSettingsDto.name.length === 0) {
                this.callCenterSettingsDto.name = 'Enrollment Team';
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('validForm')
    watchForm() {
        if (this.validForm) {
            this.watchChanges();
        } else {
            settingsChangesStore.setSaveButtonForBasicInfo(false);
            settingsChangesStore.setUpdatedBasicInfo(null);
        }
    }

    @Watch('callCenterSettingsDto', { deep: true })
    watchChanges() {
        if (this.callCenterSettings !== null && this.callCenterSettingsDto !== null) {
            if ((JSON.stringify(this.callCenterSettingsDto) !== JSON.stringify(enrollmentCenterMapper.toUpdateDto(this.callCenterSettings))) && this.callCenterSettingsDto.task_types.length > 0) {
                settingsChangesStore.setSaveButtonForBasicInfo(true);
                settingsChangesStore.setUpdatedBasicInfo(this.callCenterSettingsDto);
            } else {
                settingsChangesStore.setSaveButtonForBasicInfo(false);
                settingsChangesStore.setUpdatedBasicInfo(null);
            }
        }
    }

    private nonRequiredEmail = [
        (v: string) => {
            if (v.length > 0) {
                return /.+@.+\..*/.test(v) || 'Please enter valid email address';
            }

            return true;
        }
    ];
}
