

































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { CenterHours } from '../models/center';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import ManageHoursRange from '@/organizations/locations/components/ManageHoursRange.vue';
@Component({
    components: { ManageHoursRange }
})
export default class ManageHours extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model hours
    @Prop() readonly value!: CenterHours;
    @Prop({ default: false }) readonly longLabels!: boolean;
    @Prop({ default: false }) readonly isAutomatedCommunicationHours!: boolean;

    get hours(): CenterHours {
        return this.value;
    }

    set hours(hours: CenterHours) {
        this.$emit('input', hours);
    }

    get areDaysMissing() {
        return Object.values(this.hours).some(dayHours => !dayHours);
    }
}
