import {
    WorkflowConditionBoundType,
    WorkflowType
} from '@/automation/workflows/models/workflow-type-models';

export enum WorkflowActionTypes {
    ADD_EVENT = 2501,
    ADD_TASK = 2500,
    CHANGE_STATUS = 2504,
    NOTIFY_STAFF = 2512,
    SEND_EMAIL = 2502,
    SEND_FB_MESSAGE = 2523,
    SEND_TEXT = 2511,
    START_DRIP_CAMPAIGN = 2516
}

export enum WorkflowEntityTypes {
    FAMILY = 1,
    CHILD = 2,
    EVENT = 3,
    TASK = 4
}

export type WorkflowConditionType = 'Family' | 'Child' | 'Task' | 'Event';

export enum WorkflowFamilyEntityFieldTypes {
    LEAD_SOURCE = 65,
    INQUIRY = 66
}

export const commentsFieldId = 67;
export const familyStatusId = 11;
export const childStatusId = 21;
export const eventTypeId = 25;
export const facebookEventIdentifier = 'taskResultTypeFacebookMessageReceived';

export const workflowTimeUnits: Array<WorkflowType> = [
    {
        id: 1,
        value: 'Days'
    },
    {
        id: 2,
        value: 'Hours'
    },
    {
        id: 3,
        value: 'Minutes'
    }
];

export const notifyStaffGroupId = 2410;

export enum WorkflowDaysBeforeField {
    BIRTHDAY = 9991,
    START_DATE = 9992,
    WITHDRAWN_DATE = 9993,
    DUE_DATE = 9994
}

export const workflowDaysBeforeFields: Array<WorkflowConditionBoundType> = [
    {
        id: WorkflowDaysBeforeField.BIRTHDAY,
        value: 'Birthday',
        type: 'Child'
    },
    {
        id: WorkflowDaysBeforeField.START_DATE,
        value: 'Expected Start Date',
        type: 'Child'
    },
    {
        id: WorkflowDaysBeforeField.WITHDRAWN_DATE,
        value: 'Expected Withdrawn Date',
        type: 'Child'
    },
    {
        id: WorkflowDaysBeforeField.DUE_DATE,
        value: 'Task Due Date',
        type: 'Task'
    }
];

export enum WorkflowConditionLogic {
    TO_FROM = 'tofrom',
    NOT_EQUAL = 'notEqual',
    EQUALS = 'equals'
}

export enum WorkflowTemplateType {
    SINGLE = 2030,
    AUTOMATIC = 2031
}

export const workflowTemplateTypes: Array<WorkflowType> = [
    {
        id: WorkflowTemplateType.SINGLE,
        value: 'No'
    },
    {
        id: WorkflowTemplateType.AUTOMATIC,
        value: 'Yes'
    }
];

export const workflowStopTriggers: Array<WorkflowType> = [
    {
        id: WorkflowEntityTypes.FAMILY,
        value: 'Guardian Status'
    },
    {
        id: WorkflowEntityTypes.CHILD,
        value: 'Child Status'
    },
    {
        id: WorkflowEntityTypes.EVENT,
        value: 'Completed Task/Log Is Created'
    }
];

export const workflowActions: Array<WorkflowType> = [
    {
        id: WorkflowActionTypes.ADD_EVENT,
        value: 'Add Completed Task/Log'
    },
    {
        id: WorkflowActionTypes.ADD_TASK,
        value: 'Add Task'
    },
    {
        id: WorkflowActionTypes.CHANGE_STATUS,
        value: 'Change Status'
    },
    {
        id: WorkflowActionTypes.NOTIFY_STAFF,
        value: 'Notify Staff'
    },
    {
        id: WorkflowActionTypes.SEND_EMAIL,
        value: 'Send Email'
    },
    {
        id: WorkflowActionTypes.SEND_FB_MESSAGE,
        value: 'Send Facebook Message'
    },
    {
        id: WorkflowActionTypes.SEND_TEXT,
        value: 'Send Text'
    },
    {
        id: WorkflowActionTypes.START_DRIP_CAMPAIGN,
        value: 'Start Drip Campaign'
    }
];

export const workflowEntities: Array<WorkflowType> = [
    {
        id: WorkflowEntityTypes.FAMILY,
        value: 'Guardian'
    },
    {
        id: WorkflowEntityTypes.CHILD,
        value: 'Child'
    },
    {
        id: WorkflowEntityTypes.TASK,
        value: 'Task'
    },
    {
        id: WorkflowEntityTypes.EVENT,
        value: 'Completed Task/Log'
    }
];

export enum WorkflowActiveStatus {
    FULLY_ACTIVE = 1,
    PARTIALLY_ACTIVE = 2,
    INACTIVE = 3
}
