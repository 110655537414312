import { EmailThread } from '@/communications/messages/models/email';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { InboxCountParams } from '@/communications/messages/models/inbox-params';
import { MessagesStore } from '@/communications/messages/stores/messages-store';

export interface EmailsState {
    familyThreads: Array<EmailThread>;
    currentFamilyId: number|undefined;
    orgInboxCount: number;
    currentOrgId: number|undefined;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.EMAILS
})
export class EmailsStore extends VuexModule implements EmailsState, MessagesStore {
    protected readonly repository = new EmailsRepository();

    familyThreads: Array<EmailThread> = [];
    currentFamilyId: number|undefined = undefined;
    orgInboxCount = 0;
    currentOrgId: number|undefined = undefined;
    teamInboxCount = 0;
    private isTeamCountInit = false;

    @Mutation
    private storeEmailsForFamily(update: { emailThreads: Array<EmailThread>; familyId: number }) {
        this.familyThreads = update.emailThreads;
        this.currentFamilyId = update.familyId;
    }

    @Action({ commit: 'storeEmailsForFamily' })
    public async retrieveEmailsForFamily(familyId: number) {
        const emailThreads = await this.repository.getThreadsForFamily(familyId);
        return { emailThreads, familyId };
    }

    @Action
    public async initEmailsForFamily(familyId: number) {
        if (this.currentFamilyId !== familyId) {
            await this.retrieveEmailsForFamily(familyId);
        }
    }

    @Mutation
    private storeEmailsInboxCountForOrg(update: { count: number; orgId: number }) {
        this.orgInboxCount = update.count;
        this.currentOrgId = update.orgId;
    }

    @Mutation
    private storeEmailsInboxCountForEnrollmentTeam(count: number) {
        this.teamInboxCount = count;
        this.isTeamCountInit = true;
    }

    @Mutation
    public incrementInboxCount() {
        this.orgInboxCount++;
    }

    @Mutation
    public decrementInboxCount() {
        this.orgInboxCount--;
    }

    @Mutation
    public incrementTeamInboxCount() {
        this.teamInboxCount++;
    }

    @Mutation
    public decrementTeamInboxCount() {
        this.teamInboxCount--;
    }

    @Action({ commit: 'storeEmailsInboxCountForOrg' })
    public async retrieveEmailsInboxCountForOrg(params: InboxCountParams) {
        const count = await this.repository.getInboxCount(params);
        return { count, orgId: params.org_id };
    }

    @Action({ commit: 'storeEmailsInboxCountForEnrollmentTeam' })
    public async retrieveEmailsInboxCountForEnrollmentTeam(params: InboxCountParams) {
        params.only_enrollment_team = true;
        return await this.repository.getInboxCount(params);
    }

    @Action
    public async initEmailsInboxCountForOrg(params: InboxCountParams) {
        if (this.currentOrgId !== params.org_id) {
            await this.retrieveEmailsInboxCountForOrg(params);
        }
    }

    @Action
    public async initEmailsInboxCountForEnrollmentTeam(params: InboxCountParams) {
        if (!this.isTeamCountInit) {
            await this.retrieveEmailsInboxCountForEnrollmentTeam(params);
        }
    }

    public get stored(): Array<EmailThread> {
        return this.familyThreads;
    }

    public get inboxCount(): number {
        return this.orgInboxCount;
    }

    public get enrollmentTeamInboxCount(): number {
        return this.teamInboxCount;
    }
}
