import {
    actionableDripCampaignStatuses,
    DripCampaignIntervalUnit,
    DripCampaignStatus,
    DripCampaignTiming,
    dripIntervalUnits,
    editableDripCampaignStatuses
} from '@/automation/drip-campaigns/drip-campaign-constants';
import {
    activeDripStatuses,
    DripCampaign,
    DripCampaignDrip,
    DripCampaignDto,
    DripCampaignHistory,
    DripCampaignHistoryDisplay
} from '@/automation/drip-campaigns/models/drip-campaign';
import { Center } from '@/organizations/locations/models/center';
import { getWorkflowConditionTypes } from '@/automation/workflows/workflow-condition-utils';
import { WorkflowCreateDto } from '@/automation/workflows/models/workflow-models';
import { WorkflowConditionType } from '@/automation/workflows/constants/workflow-constants';

export class DripCampaignUtils {
    /**
     * calculate and format the amount of time since the start of a campaign,
     * adding up potentially different units
     * @param drips
     * @param index
     */
    getFixedDripLength(drips: Array<DripCampaignDrip>, index: number): string {
        const counter = new Map<DripCampaignIntervalUnit, number>();
        // add up the amount of time for each unit
        for (let iter = 0; iter <= index && iter < drips.length; iter++) {
            const amt = drips[iter].interval_amount;
            const unitLink = drips[iter].interval_unit;
            if (!unitLink) {
                continue;
            }
            if (!amt) {
                continue;
            }
            const unit = unitLink.id;
            const prev = counter.get(unit) ?? 0;
            counter.set(unit, prev + amt);
        }
        const strArray = [];
        for (let iter = dripIntervalUnits.length - 1; iter >= 0; iter--) {
            const unit = dripIntervalUnits[iter];
            if (counter.get(unit.id)) {
                const amt = counter.get(unit.id)!;
                const unitStr = amt > 1 ? unit.value : unit.value!.substr(0, unit.value!.length - 1);
                strArray.push(amt + ' ' + unitStr);
            }
        }
        if (!strArray.length) {
            return 'immediately';
        }
        return strArray.join(', ').toLowerCase();
    }

    /**
     * Calculate minimum percentage for a drip based on previous drips
     * @param dto
     * @param index
     */
    getMinDripPct(dto: DripCampaignDto, index: number): number {
        if (index === 0) {
            return 0;
        }
        let max = 0;
        for (let iter = 0; iter < index && iter < dto.drips.length; iter++) {
            if (dto.drips[iter].due_percentage && dto.drips[iter].due_percentage! > max) {
                max = dto.drips[iter].due_percentage!;
            }
        }
        return max + 1;
    }

    /**
     * fully based on legacy dripCampaign.js
     * @param status
     */
    getStatusWarning(status: DripCampaignStatus): string {
        if (status === DripCampaignStatus.PAUSED) {
            return 'This will pause the campaign, and no new emails or texts will be sent until this is republished.';
        }
        if (status === DripCampaignStatus.UNPUBLISHED) {
            return 'This will unpublish the campaign, and it will not be available for future use. All pending emails and texts will be cancelled.';
        }
        if (status === DripCampaignStatus.ARCHIVED) {
            return 'This will archive the campaign, and it will only be available for reporting and statistics. This is not reversible.';
        }
        return '';
    }

    /**
     * Can the drip campaign be added as an action to a drip campaign event
     * @param status
     */
    isActionable(status: DripCampaignStatus) {
        return actionableDripCampaignStatuses.includes(status);
    }

    /**
     * Is a drip campaign active on the family? We say yes if any of the drips are still
     * pending
     * @param familyDrip
     */
    isActive(familyDrip: DripCampaignHistory): boolean {
        return familyDrip.drips.filter((drip) => {
            return activeDripStatuses.includes(drip.status);
        }).length > 0;
    }

    /**
     * Is campaign addable to families on the family hub?
     *
     * @param campaign
     * @param center
     */
    isAddable(campaign: DripCampaign, center: Center | undefined = undefined): boolean {
        if (center && !campaign.enabled_center_ids.includes(center.id)) {
            return false;
        }
        return actionableDripCampaignStatuses.includes(campaign.status.id) && campaign.timing_type.id === DripCampaignTiming.FIXED;
    }

    isArchived(status: DripCampaignStatus) {
        return status === DripCampaignStatus.ARCHIVED;
    }

    /**
     * is campaign allowed as an action in workflows? The entityTypes is an array of the workflow condition
     * entities
     * @param campaign
     * @param dto
     */
    isWorkflowAddable(campaign: DripCampaign, dto: WorkflowCreateDto): boolean {
        // have to be in right status
        if (!actionableDripCampaignStatuses.includes(campaign.status.id)) {
            return false;
        }
        // If no centers are enabled, don't allow
        if (campaign.enabled_center_ids.length === 0) {
            return false;
        }
        // if in right status and is a fixed campaign, we're good to go
        if (campaign.timing_type.id === DripCampaignTiming.FIXED) {
            return true;
        }
        const allowedFieldTypes = getWorkflowConditionTypes(dto);
        // don't like casting, but needed to workaround TS limitation. See: https://github.com/microsoft/TypeScript/issues/26255
        return !!campaign.relative_field && allowedFieldTypes.includes(campaign.relative_field.values.form_name as WorkflowConditionType);
    }

    /**
     *  Do we allow the drip campaign to be edited?
     * @param status
     */
    isEditable(status: DripCampaignStatus) {
        return editableDripCampaignStatuses.includes(status);
    }

    /**
     * Convert drip campaign histories into rows for display
     * @param familyDrips
     */
    unfoldFamilyDrips(familyDrips: Array<DripCampaignHistory>): Array<DripCampaignHistoryDisplay> {
        const displayRows: Array<DripCampaignHistoryDisplay> = [];
        for (const history of familyDrips) {
            let first = true;
            for (const drip of history.drips) {
                const row: DripCampaignHistoryDisplay = {
                    campaignName: '',
                    dripName: drip.name,
                    templateName: drip.template_name,
                    sendDatetime: drip.send_datetime,
                    dripStatus: drip.status
                };
                if (first) {
                    row.campaignName = history.drip_campaign.values.name;
                    first = false;
                }
                displayRows.push(row);
            }
            // no drips?
            if (first) {
                displayRows.push({
                    campaignName: history.drip_campaign.values.name,
                    dripName: '',
                    templateName: '',
                    sendDatetime: '',
                    dripStatus: 'Cancelled'
                });
            }
        }
        return displayRows;
    }
}
