



















































































































































































































































































































































































































































































































































import AvailableLocationsModal from '@/automation/landing-pages/components/AvailableLocationsModal.vue';
import ChangePageNameModal from '@/automation/landing-pages/components/ChangePageNameModal.vue';
import EditLandingPageFields from '@/automation/landing-pages/components/EditLandingPageFields.vue';
import ParentScheduledToursEmbed from '@/automation/landing-pages/components/ParentScheduledToursEmbed.vue';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { LandingPageMapper, LandingPageSettingsMapper } from '@/automation/landing-pages/mappers/landing-page-mapper';
import {
    LandingPageOptionExclusionsMapper
} from '@/automation/landing-pages/mappers/landing-page-option-exclusions-mapper';
import { LandingPageFieldDbName, LandingPageFields } from '@/automation/landing-pages/models/landing-page-fields';
import {
    LandingPage,
    LandingPageOptionExclusion,
    LandingPageSettings,
    LandingPageSettingsUpdateDtoInterface,
    LandingPageType,
    LandingPageUpdateDtoInterface
} from '@/automation/landing-pages/models/landing-pages-models';
import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import BaseColorInput from '@/components/base/BaseColorInput.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { INQUIRY_TYPE_WALK_IN_ID } from '@/crm-types/crm-types-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Logo } from '@/logo/models/logo';
import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsRepository } from '@/marketing/repositories/marketing-campaigns-repository';
import { Center } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import TinyEditorComponent from '@tinymce/tinymce-vue';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { RawEditorSettings } from 'tinymce';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import CommSettingsSection from '@/automation/landing-pages/components/CommSettingsSection.vue';
import { HardCodedType } from '@/models/base';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const crmTypesStore = getModule(CrmTypesStore);
const formsRepository = new LandingPagesRepository();
const landingPageMapper = new LandingPageMapper();
const optionExclusionsMapper = new LandingPageOptionExclusionsMapper();
const loadingState = getModule(LoadingStore);
const appState = getModule(AppStateStore);
const featureStore = getModule(FeaturesStore);
const marketingCampaignsRepo = new MarketingCampaignsRepository();
const centersStore = getModule(CentersStore);
const landingPageSettingsMapper = new LandingPageSettingsMapper();

@Component({
    components: { CommSettingsSection, ParentScheduledToursEmbed, ChangePageNameModal, UploadLogo, BaseColorInput, TinyEditorComponent, EditLandingPageFields, AvailableLocationsModal, CrmTypeSelectList },
    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        if ((this as any).isEdited && !(this as any).isDelete) {
            const confirm = await this.$swal({
                icon: 'warning',
                text: 'You have made changes that are not yet saved.  Leave anyway?',
                showConfirmButton: true,
                showCancelButton: true
            });
            if (confirm.isConfirmed) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
})
export default class ViewLandingPage extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ type: Number, required: true }) readonly id!: number;

    $refs!: {
        url: HTMLDivElement;
    };

    private centers: Array<Center> = [];
    private editFieldsFlag = false;
    private editFieldsMode = false;
    private excludedOthers: Array<number> = [];
    private familySources: Array<CrmTypeOption> = [];
    private fields: LandingPageFields = [];
    private form: LandingPage | null = null;
    private iframeKey = 0;
    private includedFamilySources: Array<number> = [];
    private inquiryTypes: Array<CrmTypeOption> = [];
    private isDelete = false;
    private isEditName = false;
    private isRedirect = false;
    private loadingKey = 'form';
    private logo: Logo | null = null;
    private marketingCampaignChangedEvent = EventTypes.MARKETING_CAMPAIGN_CHANGED;
    private marketingCampaigns: Array<MarketingCampaign> = [];
    private openLocationsModal = false;
    private originalIncludedFamilySources: Array<number> = [];
    private originalRedirect = false;
    private originalSettings: LandingPageSettingsUpdateDtoInterface | null = null;
    private originalTypeProperties: LandingPageUpdateDtoInterface | null = null;
    private settings: LandingPageSettingsUpdateDtoInterface | null = null;
    private settingsTitle = 'Edit';
    private showFamilySources = true;
    private showOptions = true;
    private showUploadDialog = false;
    private sourceToggledEvent = EventTypes.LANDING_PAGE_SOURCE_TOGGLED;
    private typeProperties: LandingPageUpdateDtoInterface | null = null;
    private updatedEvent = EventTypes.UPDATED;
    private url = '';
    private validEdit = false;
    private validComms = false;
    private processAts: Array<CrmTypeOption> = [];
    private pendingStaffTos: Array<CrmTypeOption|HardCodedType> = [];
    private isLoaded = false;
    private inquiryTypesList = CrmTypeList.FAMILY_INQUIRY;
    private waitListReasonsList = CrmTypeList.REASON_WAIT_LIST;
    private waitListTypesList = CrmTypeList.WAIT_LIST_TYPE;
    private familySourcesList = CrmTypeList.FAMILY_SOURCE;

    private tinyInit: RawEditorSettings = {
        selector: '.tiny',
        menubar: false,
        height: 200,
        plugins: ['textcolor'],
        paste_as_text: true,
        toolbar: 'undo redo | formatselect fontsizeselect forecolor | bold italic underline strikethrough',
        branding: false,
        statusbar: false,
        browser_spellcheck: true
    };

    private breadcrumbs = [
        {
            text: 'Automation',
            to: { name: 'automation' }
        },
        {
            text: 'Landing Pages',
            to: { name: 'landing-pages' }
        },
        {
            text: 'Landing Page Editor',
            disabled: true
        }
    ];

    private get hasMarketingCampaigns() {
        return featureStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    private get isLineLeaderEnroll() {
        return featureStore.isLineLeaderEnroll;
    }

    private get hasTemplateGroups() {
        return featureStore.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    private get isEdited() {
        return !isEqual(this.originalSettings, this.settings) ||
            this.originalRedirect !== this.isRedirect ||
            !isEqual(this.originalTypeProperties, this.typeProperties) ||
            (this.showFamilySources && !isEqual(this.originalIncludedFamilySources, this.includedFamilySources));
    }

    /**
     * If the form is a wait list form.
     */
    private get isKioskForm() {
        return this.form ? this.form.type.id === LandingPageType.KIOSK : false;
    }

    /**
     * Is it a web form?
     */
    private get isWebForm(): boolean {
        return !!this.form && (this.form.type.id === LandingPageType.WEB || this.form.type.id === LandingPageType.SINGLE_WEB);
    }

    /**
     * If the form is a wait list form.
     */
    private get isWaitListForm() {
        return this.form ? this.form.type.id === LandingPageType.WAIT_LIST : false;
    }

    private get isPst() {
        return this.form!.type.id === LandingPageType.PST;
    }

    private get org() {
        return appState.storedCurrentOrg;
    }

    private get redirectRules() {
        if (this.isRedirect) {
            return this.requiredField;
        }
        return [];
    }

    /**
     * Whether or not the show the inquiry type field.
     */
    private get showInquiryType() {
        return this.form ? this.form.type.id !== LandingPageType.WEB : false;
    }

    @Watch('org')
    private async orgUpdated() {
        if (this.org) {
            this.marketingCampaigns = await marketingCampaignsRepo.retrieve(this.org.id);
        }
    }

    private async created() {
        this.settingsTitle = this.isLineLeaderEnroll ? 'Page Settings' : 'Edit';
        await this.refresh();
    }

    private cancelEditFields() {
        this.editFieldsMode = false;
    }

    private async copyToClipboard(content: string) {
        content = content.replace(/&lt;/g, '<');
        await navigator.clipboard.writeText(content);
    }

    // Delete the form's logo
    private async deleteLogo() {
        const result = await this.$swal({
            text: 'Are you sure you want to delete the logo for this landing page?',
            showConfirmButton: true,
            showCancelButton: true
        });

        if (result.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await formsRepository.deleteLogo(this.form?.id as number);
            this.logo = null;
            ++this.iframeKey;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private async deletePage() {
        const confirm = await this.$swal({
            icon: 'warning',
            text: 'Are you sure you want to delete this landing page?',
            showConfirmButton: true,
            showCancelButton: true
        });
        if (confirm.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await formsRepository.deletePage(this.id);
            loadingState.loadingDecrement(this.loadingKey);
            // make sure we don't prompt for navigating away
            this.isDelete = true;
            this.$router.push({ name: 'landing-pages' });
        }
    }

    private editName() {
        this.isEditName = true;
    }

    private locationsListUpdated() {
        ++this.iframeKey;
    }

    // Actions to perform after uploading the logo
    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
        ++this.iframeKey;
    }

    private openEditFields() {
        this.editFieldsFlag = false;
        this.editFieldsMode = true;
    }

    private async refresh() {
        this.isLoaded = false;
        loadingState.loadingIncrement(this.loadingKey);
        const centersPromise = centersStore.initAccessibleCenters();
        const familySourcePromise = crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);
        const exclusionsPromise = formsRepository.getOptionExclusions(this.id);
        const fieldsPromise = formsRepository.getFields(this.id);

        // Only to make sure that nothing complains about this being null...
        if (this.org) {
            this.marketingCampaigns = await marketingCampaignsRepo.retrieve(this.org.id);
        }

        this.form = await formsRepository.getOne(this.id);
        if (this.form) {
            this.url = this.form.url;
            this.logo = await formsRepository.getLogo(this.id);
            const settingsResponse = await formsRepository.getPageSettings(this.id) as LandingPageSettings;
            this.settings = landingPageSettingsMapper.toUpdateDto(settingsResponse);
            this.originalSettings = cloneDeep(this.settings);
            if (this.originalSettings && this.originalSettings.style.text_alignment === '') {
                this.originalSettings.style.text_alignment = 'Left';
            }
            this.isRedirect = Boolean(this.settings.confirmation.redirect_url);
            this.originalRedirect = this.isRedirect;
            this.originalTypeProperties = landingPageMapper.toUpdateDto(this.form);
            this.typeProperties = cloneDeep(this.originalTypeProperties);
            if (this.typeProperties.inquiry_type === undefined &&
                [LandingPageType.KIOSK, LandingPageType.WAIT_LIST].includes(this.form.type.id)
            ) {
                this.typeProperties.inquiry_type = INQUIRY_TYPE_WALK_IN_ID;
            }

            ++this.iframeKey;
        }

        if (this.isWebForm) {
            const webFormPromises = [];
            webFormPromises.push(crmTypesStore.initList(CrmTypeList.PENDING_PROCESS_ATS));
            webFormPromises.push(crmTypesStore.initList(CrmTypeList.PENDING_STAFF_TOS));
            await Promise.all(webFormPromises);
        }

        await centersPromise;
        await familySourcePromise;
        await exclusionsPromise;
        await fieldsPromise;

        this.centers = centersStore.storedAccessibleCenters;
        this.familySources = crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE);
        if (this.isWebForm) {
            this.pendingStaffTos = crmTypesStore.listOptions(CrmTypeList.PENDING_STAFF_TOS);
            this.pendingStaffTos.unshift({
                id: null,
                value: 'Do Not Send',
                order: 0,
                is_editable: false,
                is_default: true
            });
            this.processAts = crmTypesStore.listOptions(CrmTypeList.PENDING_PROCESS_ATS);
        }

        Promise.resolve(exclusionsPromise).then(async (result) => {
            const mappedExclusions = optionExclusionsMapper.mapExclusions(result);
            this.includedFamilySources = await optionExclusionsMapper.getIncludedOptionsForList(
                mappedExclusions,
                CrmTypeList.FAMILY_SOURCE
            );
            this.originalIncludedFamilySources = this.includedFamilySources;
            this.excludedOthers = mappedExclusions.get('other')!.map(exclusion => exclusion.exclusion.id);
        });

        Promise.resolve(fieldsPromise).then((result) => {
            result.forEach((landingPageField) => {
                if (!landingPageField.field || landingPageField.type.values.value === 'Guardian' || landingPageField.type.values.value === 'Child') {
                    this.fields.push(landingPageField);
                    if (landingPageField.field?.values.db_name === LandingPageFieldDbName.SOURCE) {
                        this.showFamilySources = landingPageField.is_used;
                    }
                }
            });
            this.fields = result;
        });

        loadingState.loadingDecrement(this.loadingKey);
        this.isLoaded = true;
        this.$vuetify.goTo(0);
    }

    private saveEditFields() {
        this.editFieldsFlag = true;
    }

    private async savePage() {
        if (!this.settings) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        if (!this.isRedirect) {
            this.settings.confirmation.redirect_url = '';
        } else {
            this.settings.confirmation.thank_you_body = '';
            this.settings.confirmation.thank_you_header = '';
        }

        let settingsUpdate = Promise.resolve(this.settings);
        if (!isEqual(this.originalSettings, this.settings)) {
            if (!this.settings.process_at) {
                this.settings.process_at = 2672;
            }
            settingsUpdate = formsRepository.updatePageSettings(this.id, this.settings as LandingPageSettingsUpdateDtoInterface);
        }

        let formUpdate = Promise.resolve(this.form);
        if (!isEqual(this.originalTypeProperties, this.typeProperties)) {
            if (this.originalTypeProperties && this.typeProperties) {
                if (this.originalTypeProperties.wait_list_type && !this.typeProperties.wait_list_type) {
                    this.typeProperties.wait_list_type = null;
                }

                if (this.originalTypeProperties.wait_list_reason && !this.typeProperties.wait_list_reason) {
                    this.typeProperties.wait_list_reason = null;
                }

                if (this.originalTypeProperties.marketing_campaign && !this.typeProperties.marketing_campaign) {
                    this.typeProperties.marketing_campaign = null;
                }
            }

            formUpdate = formsRepository.updatePage(this.id, this.typeProperties as LandingPageUpdateDtoInterface);
        }

        let exclusionsUpdate: Promise<Array<number | LandingPageOptionExclusion>> = Promise.resolve(this.includedFamilySources);
        if (this.showFamilySources && !isEqual(this.originalIncludedFamilySources, this.includedFamilySources)) {
            exclusionsUpdate = formsRepository.updateOptionExclusions(
                this.id,
                optionExclusionsMapper.getExclusionsDtoFromExcludedOptionIds(
                    await optionExclusionsMapper.getExcludedOptionIdsFromList(this.includedFamilySources, CrmTypeList.FAMILY_SOURCE),
                    this.excludedOthers
                )
            );
        }

        await settingsUpdate;
        await formUpdate;
        await exclusionsUpdate;

        loadingState.loadingDecrement(this.loadingKey);

        await this.refresh();
    }

    private updateMarketingCampaign(marketingCampaignId: number) {
        if (this.typeProperties) {
            this.typeProperties.marketing_campaign = marketingCampaignId || null;
        }
    }

}
