export const serverCountry: string = process.env.VUE_APP_SERVER_COUNTRY ?? 'us';
export const intercomAppId: string = process.env.VUE_APP_INTERCOM_APPID ?? '';
export const intercomHashKey: string = process.env.VUE_APP_INTERCOM_HASH_KEY ?? '';
export const timeoutMinutes: number = Number(process.env.VUE_APP_TIMEOUT_MINUTES) ?? 60;
export const rollbarToken: string = process.env.VUE_APP_ROLLBAR_TOKEN ?? '';
export const rollbarEnv: string = process.env.VUE_APP_ROLLBAR_ENV ?? 'prod-ui';
export const flexmonsterKey: string = process.env.VUE_APP_FLEXMONSTER_KEY ?? 'Z7SQ-XH551C-53693Y-0L0Z6F-0O432B-6B3L3Z-6W3I0F-3Y5Q0N-6U';
export const vueEnv: string = process.env.NODE_ENV ?? 'production';
export const enableXdebug: boolean = (process.env.VUE_APP_ENABLE_XDEBUG ?? 'false') === 'true';
export const googleMapsKey: string = process.env.VUE_APP_GOOGLE_MAPS_KEY ?? '';
export const facebookAppId: string = process.env.VUE_APP_FACEBOOK_APP_ID ?? '';
export const smartLookKey: string = process.env.VUE_APP_SMARTLOOK_KEY ?? '';
export const satismeterKey: string = process.env.VUE_APP_SATISMETER_KEY ?? '';
export const chmlnThumnailUrl: string = process.env.VUE_APP_CHMLN_THUMBNAIL_URL ?? '';
export const sixAgentHost: string = process.env.VUE_APP_SIX_AGENT_HOST ?? '';
export const idpBaseUrl: string = process.env.VUE_APP_IDP_BASE_URL ?? '';
