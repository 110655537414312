












































import { Component, Mixins, Watch } from 'vue-property-decorator';
import FullCalendar, { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { LocaleMixin } from '@/locales/locale-mixin';

const todayStr = new Date().toISOString().replace(/T.*$/, '');

@Component({
    components: {
        FullCalendar // make the <FullCalendar> tag available
    }
})
export default class DemoApp extends Mixins(LocaleMixin) {
    private idCounter = 1;

    private calendarOptions: CalendarOptions = {
        plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        firstDay: this.firstDay,
        initialView: 'dayGridMonth',
        initialEvents: [
            {
                id: String(this.idCounter++),
                title: 'all-day',
                start: todayStr
            },
            {
                id: String(this.idCounter++),
                title: 'timed',
                start: todayStr + 'T12:00:00'
            }
        ],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        nowIndicator: true
    }

    private currentEvents: EventApi[] = [];

    @Watch('firstDay')
    firstDayChange(firstDay: number) {
        this.calendarOptions.firstDay = firstDay;
    };

    mounted() {
        this.calendarOptions.firstDay = this.firstDay;
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        const title = prompt('Please enter a new title for your event');
        const calendarApi = selectInfo.view.calendar;

        calendarApi.unselect();

        if (title) {
            calendarApi.addEvent({
                id: String(this.idCounter++),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            });
        }
    }

    handleEventClick(clickInfo: EventClickArg) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove();
        }
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
    }

}
