






























import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
    InterfaceSetting,
    SettingNames
} from '../models/interface-settings-models';
import { getModule } from 'vuex-module-decorators';
import { InterfaceSettingsChangesStore } from '@/dashboards/store/interface-settings-changes-store';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { cloneDeep } from 'lodash';

const settingsChangesStore = getModule(InterfaceSettingsChangesStore);
const settingsStore = getModule(InterfaceSettingsStore);

@Component
export default class LocationDashboardSettings extends Mixins(LocaleMixin) {
    private pipelineValueSettingsOriginalToggle = true;
    private pipelineValueSettingsToggle: InterfaceSetting | null = null;
    private includeMeetingsSettingsOriginalToggle = false;
    private includeMeetingsSettingsToggle: InterfaceSetting | null = null;
    private showChildStartsSettingsOriginalToggle = true;
    private showChildStartsSettingsToggle: InterfaceSetting | null = null;

    created() {
        this.pipelineValueSettingsToggle = cloneDeep(settingsStore.stored.get(SettingNames.PIPELINE_VALUE)!);
        this.pipelineValueSettingsOriginalToggle = this.pipelineValueSettingsToggle.value as boolean;
        this.includeMeetingsSettingsToggle = cloneDeep(settingsStore.stored.get(SettingNames.INCLUDE_MEETINGS)!);
        this.includeMeetingsSettingsOriginalToggle = this.includeMeetingsSettingsToggle.value as boolean;
        this.showChildStartsSettingsToggle = cloneDeep(settingsStore.stored.get(SettingNames.SHOW_CHILD_STARTS)!);
        this.showChildStartsSettingsOriginalToggle = this.showChildStartsSettingsToggle.value as boolean;
    }

    @Watch('pipelineValueSettingsToggle', { deep: true })
    updateStore() {
        settingsChangesStore.setSaveButtonForPipelineValueToggle(this.pipelineValueSettingsToggle!.value !== this.pipelineValueSettingsOriginalToggle);
        if (this.pipelineValueSettingsToggle!.value !== this.pipelineValueSettingsOriginalToggle) {
            this.$emit('statusChange', this.pipelineValueSettingsToggle);
        }
    }

    @Watch('includeMeetingsSettingsToggle', { deep: true })
    updateIncludeMeetingsToggle() {
        settingsChangesStore.setSaveButtonForPipelineValueToggle(this.includeMeetingsSettingsToggle!.value !== this.includeMeetingsSettingsOriginalToggle);
        if (this.includeMeetingsSettingsToggle!.value !== this.includeMeetingsSettingsOriginalToggle) {
            this.$emit('statusChange', this.includeMeetingsSettingsToggle);
        }
    }

    @Watch('showChildStartsSettingsToggle', { deep: true })
    updateShowChildStartsToggle() {
        settingsChangesStore.setSaveButtonForPipelineValueToggle(this.showChildStartsSettingsToggle!.value !== this.showChildStartsSettingsOriginalToggle);
        if (this.showChildStartsSettingsToggle!.value !== this.showChildStartsSettingsOriginalToggle) {
            this.$emit('statusChange', this.showChildStartsSettingsToggle);
        }
    }
}
