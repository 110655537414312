






















































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';
import { AuthMixin } from '@/auth/auth-mixin';

@Component({
    components: { BaseClose }
})
export default class NylasCalendarAddition extends Mixins(VmodelShowMixin, LocaleMixin, BasicValidationMixin, AuthMixin) {
    @Prop({ default: '' }) readonly reauthCalendar!: string;

    private loadingKey = 'nylasCalendarAddition';
    private showCalendarDetails = false;

    mounted() {
        this.showCalendarDetails = this.isCorpUser || this.isSuperUser;
    }

    async save() {
        // This is where it would save the value of showCalendarDetails to the database
        this.$emit(EventTypes.ADDED, this.showCalendarDetails);
        this.modelValue = false;
    }

    async cancelled() {
        // This is where it would cancel the addition of the calendar
        this.$emit(EventTypes.CLOSE, this.showCalendarDetails);
        this.modelValue = false;
    }
}
