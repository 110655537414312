import { Change, ChangeType } from '@/families/models/update';
import { HateoasLink } from '@/models/base';
import { formatDateWithTimezone } from '@/date-time/date-time-utils';
import { baseLocales } from '@/locales/locales';

export class FamilyHistoryUtils {
    /**
     * Get the main changes on the family.
     * They are considered "main" when they are a action that has been performed on/by the family,
     * such as a task or communication.
     * Don't return changes that haven't happened yet.
     *
     * @param changes
     */
    public filterMinimalView(changes: Array<Change>): Array<Change> {
        const now = (new Date()).toISOString();
        const excludedTypes = [
            ChangeType.CHILD,
            ChangeType.CONTACT,
            ChangeType.GUARDIAN,
            ChangeType.LEAD,
            ChangeType.STATUS
        ];
        return changes.filter((change: Change) => {
            // Only include the completed or cancelled task history entries when representing a task
            if (change.type === ChangeType.TASK) {
                return change.action === 'Cancelled' || change.action === 'Completed';
            }
            // Exclude some types
            if (excludedTypes.includes(change.type)) {
                return false;
            }
            // Exclude events that are created from completing a task
            if (change.type === ChangeType.EVENT && change.details.slice(-6).toLowerCase() === '(task)') {
                return false;
            }
            // Don't return history representing something in the future
            return change.action_date_time <= now;
        });
    }

    /**
     * Get the id for the change.
     *
     * @param change
     */
    public getChangeId(change: Change): number | null {
        for (const hateoasLink of change.links) {
            if (hateoasLink == null) {
                continue;
            }
            for (const link of hateoasLink.links) {
                if (change.type === ChangeType.EVENT && link.rel === 'event') {
                    return hateoasLink.id;
                }
                if (change.type === ChangeType.TASK && link.rel === 'task') {
                    return hateoasLink.id;
                }
            }
        }
        return null;
    }

    /**
     * Get the full name of the user responsible for the change.
     * Return the name of the workflow regardless of if the user is set if the change was made by a workflow.
     *
     * @param change
     */
    public getChangeUser(change: Change): string {
        const hateoasLinks: Array<HateoasLink> = change.links;
        let userName = 'System';
        for (const hateoasLink of hateoasLinks) {
            if (hateoasLink == null) {
                continue;
            }
            const simpleHateoasLinks = hateoasLink.links;
            if (simpleHateoasLinks.find(element => element.rel === 'workflow') !== undefined) {
                return hateoasLink.values.name ?? '';
            }
            if (simpleHateoasLinks.find(element => element.rel === 'staff') !== undefined) {
                userName = hateoasLink.values.first_name ?? '';
            }
        }
        return userName;
    }

    /**
     * Get a more intuitive details of a pending communication event or a task/meeting is scheduled with date/time specified
     *
     * @param change
     */
    public getIntuitiveDetails(change: Change, timezone: string, locale: string, changes: Array<Change>): string {
        const now = (new Date()).toISOString();
        // Localize date format
        const dateFormat = baseLocales[locale].dateFormat;
        const timeFormat = baseLocales[locale].timeFormat;
        const dateTimeFormat = `${dateFormat} ${timeFormat}`;
        const hateoasLinks: Array<HateoasLink> = change.links;
        if (change.type === ChangeType.EVENT) {
            for (const hateoasLink of hateoasLinks) {
                if (hateoasLink == null) {
                    continue;
                }
                const simpleHateoasLinks = hateoasLink.links;
                // Details for pending communications so it action_date_time must be in the future
                if (change.action_date_time > now) {
                    if (simpleHateoasLinks.find(element => element.rel === 'email')) {
                        return `Email Scheduled for Delivery ${formatDateWithTimezone(change.action_date_time, timezone, dateTimeFormat)}`;
                    }
                    if (simpleHateoasLinks.find(element => element.rel === 'text')) {
                        return `Text Scheduled for Delivery ${formatDateWithTimezone(change.action_date_time, timezone, dateTimeFormat)}`;
                    }
                }
            }
        }
        if (change.type === ChangeType.TASK && change.action === 'Added') {
            const taskId = this.getChangeId(change);
            if (taskId) {
                const existedDoneTask = changes.find(item => this.getChangeId(item) === taskId && item.type === ChangeType.TASK &&
                    (item.action === 'Completed' || item.action === 'Cancelled'));
                if (existedDoneTask) {
                    return change.details;
                }
            }
            for (const hateoasLink of hateoasLinks) {
                if (hateoasLink == null) {
                    continue;
                }
                if (hateoasLink.values && hateoasLink.values.type_value) {
                    return `${hateoasLink.values.type_value} Scheduled, due ${formatDateWithTimezone(change.action_date_time, timezone, dateFormat)}`;
                }
            }
        }
        return change.details;
    }
}
