import { Action, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { ReportJobLog } from '@/reports/models/reports';
import { ReportJobLogsRepository } from '@/reports/repositories/report-job-logs-repository';

export interface ReportJobLogsState {
    entities: Array<ReportJobLog>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.REPORT_JOB_LOGS
})
export class ReportJobLogsStore extends AbstractApiStore<ReportJobLog> implements ReportJobLogsState {
    readonly repository = new ReportJobLogsRepository();
    entities: Array<ReportJobLog> = [];

    @Mutation
    private storeReportJobLogs(entities: Array<ReportJobLog>): void {
        this.entities = entities;
    }

    @Action({ commit: 'storeReportJobLogs' })
    public async retrieveLogs() {
        const response = await this.repository.get();
        return response.entities;
    }

    // initialize for data retrieval
    @Action
    public async init() {
        await this.initPromise({
            hash: 'all',
            closure: async () => {
                await this.retrieveLogs();
            }
        });
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<ReportJobLog> {
        return this.entities;
    }

}
