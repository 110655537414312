import { FamilyComparison, FamilyUpdateDtoInterface, PotentialDuplicateLeadActions } from '@/families/models/family';

export function disableSave(familiesActions: Array<PotentialDuplicateLeadActions | undefined>, currentFamily: FamilyUpdateDtoInterface, existingFamilies: Array<FamilyComparison>): boolean {
    let countReject = 0;
    let countUndefined = 0;
    let isSaveDisabled: boolean;

    const linkToRejectedCurrentFamily = familiesActions[0] === PotentialDuplicateLeadActions.REJECT && familiesActions.slice(1).filter(action => action === PotentialDuplicateLeadActions.LINK).length > 0;
    let countCurrentFamilyLinkToRejectedExisting = 0;

    familiesActions.forEach((action, index) => {
        if (action === undefined) {
            countUndefined++;
        }
        if (action === PotentialDuplicateLeadActions.REJECT) {
            countReject++;
        }
        // this is to make sure that save is disabled if you end up with a link-reject-any option, and reject is from the different center
        if (familiesActions[0] === PotentialDuplicateLeadActions.LINK && action === PotentialDuplicateLeadActions.REJECT && index !== 0 && existingFamilies[index - 1].current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id) {
            countCurrentFamilyLinkToRejectedExisting++;
        }
    });

    if (familiesActions.length > 2) {
        const bothExistingFamiliesAtDiffCenter = existingFamilies.filter(family => family.current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id).length === 2;
        const currentFamilyLinkToRejectedExisting = bothExistingFamiliesAtDiffCenter ? countCurrentFamilyLinkToRejectedExisting === 2 : countCurrentFamilyLinkToRejectedExisting === 1;
        isSaveDisabled = (countUndefined > 0 ||
                        countReject === 3 ||
                        linkToRejectedCurrentFamily ||
                        currentFamilyLinkToRejectedExisting);
    } else {
        isSaveDisabled = (countUndefined > 0 ||
                        countReject === 2 ||
                        linkToRejectedCurrentFamily ||
                        countCurrentFamilyLinkToRejectedExisting === 1);
    }

    return isSaveDisabled;
}

/**
 * Generate actions for current family
 * @param currentFamily
 * @param existingFamilies
 */
export function generationActionsForCurrentFamily(currentFamily: FamilyUpdateDtoInterface, existingFamilies: Array<FamilyComparison>): Array<{ value: PotentialDuplicateLeadActions; label: string }> {
    const actionOptions = [
        {
            value: PotentialDuplicateLeadActions.KEEP,
            label: 'Keep this record'
        },
        {
            value: PotentialDuplicateLeadActions.REJECT,
            label: 'Reject this record'
        }
    ];

    // AC wants different labels for linking families 😭

    // Handle 2 duplicate families case (current - existing - existing)
    if (existingFamilies.length > 1) {
        const existingFamiliesInDiffLoc = existingFamilies.filter(existingFamily => existingFamily.current.primary_guardian.center_id !== currentFamily.primary_guardian.center_id).length;

        // Handle action for when two duplicate families have different centers with the current one (center1 - center2 - center3)
        if (existingFamiliesInDiffLoc > 1) {
            actionOptions.push({
                value: PotentialDuplicateLeadActions.LINK,
                label: 'Link to the Existing Family/Families'
            });
            // Handle action for when only duplicate families have different centers with the current one (center1 - center2 - center1)
        } else if (existingFamiliesInDiffLoc === 1) {
            actionOptions.push({
                value: PotentialDuplicateLeadActions.LINK,
                label: 'Link to the Different Location Family'
            });
        }
    } else {
        // Handle 1 duplicate family (current - existing)
        for (const existingFamily of existingFamilies) {
            if (currentFamily.primary_guardian.center_id !== existingFamily.current.primary_guardian.center_id) {
                actionOptions.push({
                    value: PotentialDuplicateLeadActions.LINK,
                    label: 'Link to the Existing Family'
                });
                break;
            }
        }
    }

    return actionOptions;
}

/**
 * Generate actions for potential duplicate family
 * @param existingFamily
 * @param currentFamily
 */
export function generateActionsForPotentialDuplicate(existingFamily: FamilyUpdateDtoInterface, currentFamily: FamilyUpdateDtoInterface): Array<{ value: PotentialDuplicateLeadActions; label: string }> {
    const actionOptions = [
        {
            value: PotentialDuplicateLeadActions.KEEP,
            label: 'Keep this record'
        },
        {
            value: PotentialDuplicateLeadActions.REJECT,
            label: 'Reject this record'
        }
    ];

    // If potential duplicate families is at different center than the current one, offer this linking option
    if (existingFamily.primary_guardian.center_id !== currentFamily.primary_guardian.center_id) {
        actionOptions.push({
            value: PotentialDuplicateLeadActions.LINK,
            label: 'Link to the Family Being Viewed'
        });
    }

    return actionOptions;
}
