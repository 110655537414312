import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { ChildMapper } from '@/families/mappers/child-mapper';
import { GuardianMapper } from '@/families/mappers/guardian-mapper';
import { Family, FamilyCreateDtoInterface, FamilyUpdateDtoInterface } from '@/families/models/family';
import { CustomValuesMapper } from '@/crm-types/custom-fields/mappers/custom-values-mapper';

const customValueMapper = new CustomValuesMapper();

export class FamilyMapper implements AbstractMapper<Family> {
    toCreateDto(entity: Family): FamilyCreateDtoInterface {
        const childMapper = new ChildMapper();
        const children = [];
        for (const child of entity.children) {
            children.push(childMapper.toCreateDto(child));
        }

        const customValues = [];
        if (entity.custom_values) {
            for (const customValue of entity.custom_values) {
                customValues.push(customValueMapper.toDto(customValue));
            }
        }

        const guardianMapper = new GuardianMapper();

        let campaigns = [];
        if (entity.marketing_campaigns !== null && entity.marketing_campaigns !== undefined) {
            campaigns = entity.marketing_campaigns.map(item => item.id);
        } else if (entity.marketing_campaign) {
            campaigns.push(entity.marketing_campaign.id);
        }

        const primaryGuardianDto = guardianMapper.toCreateDto(entity.primary_guardian);
        if (Object.prototype.hasOwnProperty.call(entity, 'enrollment_rep')) {
            primaryGuardianDto.enrollment_rep = entity.enrollment_rep ? entity.enrollment_rep.id : null;
        }

        return {
            is_active: entity.is_active,
            inquiry_type: entity.inquiry_type ? entity.inquiry_type.id : 0,
            source_type: entity.source_type ? entity.source_type.id : 0,
            primary_guardian: primaryGuardianDto,
            good_standing: entity.good_standing,
            added_date: entity.added_date,
            children: children,
            do_not_call: entity.do_not_call,
            do_not_email: entity.do_not_email,
            do_not_text: entity.do_not_text,
            comments: entity.comments ? entity.comments : '',
            marketing_campaign: campaigns,
            custom_values: customValues
        } as FamilyCreateDtoInterface;
    }

    toUpdateDto(entity: Family): FamilyUpdateDtoInterface {
        if (!entity.id) {
            throw new Error('Cannot update family without an id');
        }

        const dto = this.toCreateDto(entity) as FamilyUpdateDtoInterface;
        dto.id = entity.id;
        dto.primary_guardian.id = entity.primary_guardian.id;
        if (entity.status) {
            dto.status = entity.status.id;
        }
        const childMapper = new ChildMapper();
        const children = [];

        for (const child of entity.children) {
            children.push(childMapper.toUpdateDto(child));
        }
        dto.children = children;

        return dto;
    }
}
