import { Module, Action, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { CustomDashboard } from '@/dashboards/models/custom-dashboards';
import { AbstractApiStore } from '@/store/abstract-api-store';
import { CustomDashboardsRepository } from '@/dashboards/repositories/custom-dashboards-repository';

interface CustomDashboardsState {
    entities: Array<CustomDashboard>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.CUSTOM_DASHBOARDS
})
export class CustomDashboardsStore extends AbstractApiStore<CustomDashboard> implements CustomDashboardsState {
    readonly repository = new CustomDashboardsRepository();

    @Mutation
    private storeDashboards(entities: Array<CustomDashboard>): void {
        this.entities = entities;
    }

    @Action({ commit: 'storeDashboards' })
    public async retrieveDashboards() {
        const response = await this.repository.get();
        return response.entities;
    }

    // initialize for data retrieval
    @Action
    public async init() {
        await this.initPromise({
            hash: 'all',
            closure: async () => {
                await this.retrieveDashboards();
            }
        });
    }

    public get stored(): Array<CustomDashboard> {
        return this.entities;
    }

}
