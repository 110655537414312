

































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: { BaseClose }
})
export default class BaseRightPopoutDrawer extends Mixins(LocaleMixin) {
    // v-model stuff
    @Prop({ default: false }) readonly value!: boolean;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    // whether we prevent outside clicks from closing the modal
    @Prop({ default: true, type: Boolean }) stateless!: boolean;

    // don't automatically close, just emit close event
    @Prop({ default: false, type: Boolean }) externalClose!: boolean;

    @Prop({ default: '16rem' }) width!: number|string;

    @Prop({ default: 'Title Here' }) title!: string;

    // Let the escape key close the drawer.
    async created() {
        document.addEventListener('keyup', this.handleKeyup);
    }

    private handleKeyup(e: any) {
        if (e.keyCode === 27) {
            this.close();
        }
    }

    private close() {
        this.$emit('close');
        if (!this.externalClose) {
            this.modelValue = false;
        }
    }

    // clicking off the drawer doesn't automatically send a close event to the parent, this fixes that.
    private handleInput(event: any) {
        if (!this.stateless) {
            if (event === false && this.modelValue === true) {
                this.close();
            }
        }
    }
}
