




















































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { EventTypes } from '@/constants/event-type-constants';
import { EmailTemplateMapper } from '@/communications/mappers/email-template-mapper';
import { TextTemplateMapper } from '@/communications/mappers/text-template-mapper';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const emailTemplateMapper = new EmailTemplateMapper();
const textTemplateMapper = new TextTemplateMapper();
const loadingState = getModule(LoadingStore);
const emailTemplatesState = getModule(EmailTemplatesStore);
const textTemplatesState = getModule(TextTemplatesStore);
const emailTemplateRepo = new EmailTemplatesRepository();
const textTemplateRepo = new TextTemplatesRepository();
@Component({
    components: { BaseClose }
})
export default class CopyTemplate extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) messageTemplate!: MessageTemplate;

    @Ref('form') readonly form!: VForm;

    private templateName = '';
    private loadingKey = 'copyTemplate';
    private validForm = false;

    /**
     * Handles when to show the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    get copiedTemplateNameRules() {
        return [
            (v: string|number) => !!v || 'Please enter a value',
            this.templateName !== this.messageTemplate.name || 'Please choose a different template name than the original one'
        ];
    }

    private close() {
        this.modelValue = false;
        this.templateName = '';
        this.form.reset();
    }

    private async copy() {
        loadingState.loadingIncrement(this.loadingKey);
        let dto;
        let template;
        if (this.messageTemplate.subject) {
            dto = emailTemplateMapper.toCreateDto(this.messageTemplate as MessageTemplate);
            dto.name = this.templateName;
            template = await emailTemplateRepo.create(dto);
            emailTemplatesState.addOrUpdateEntity(template);
            emailTemplatesState.clear();
        } else {
            dto = textTemplateMapper.toCreateDto(this.messageTemplate as MessageTemplate);
            dto.name = this.templateName;
            template = await textTemplateRepo.create(dto);
            textTemplatesState.addOrUpdateEntity(template);
            textTemplatesState.clear();
        }
        loadingState.loadingDecrement(this.loadingKey);
        await this.$router.push({
            name: `template-editor-${this.messageTemplate.subject ? 'email' : 'text'}`,
            params: { id: template.id.toString() }
        });
        this.$emit('template-copied');
        this.close();
    }
}
