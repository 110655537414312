


















































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Event } from '@/families/events/models/event-models';
@Component
export default class ViewEventsHistory extends Mixins(LocaleMixin) {
    @Prop() eventItem!: Event | null;
    @Prop({ type: String, required: true }) readonly guardianName!: string;

}
