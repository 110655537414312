import { baseUrl } from '@/core/base-url';
import {
    LocationOfferingOption,
    LocationOfferingOptionDto,
    LocationOfferings,
    LocationSettingType
} from '@/enrollment-center/models/enrollment-center-location-models';
import { ApiParameters } from '@/repositories/abstract-repository';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { createClient } from '@/transport/create-client';
import { AxiosInstance } from 'axios';
import { getModule } from 'vuex-module-decorators';

const authState = getModule(AuthStore, store);

/**
 * Handles API requests for the enrollment center data for a center.
 */
export class EnrollmentCenterLocationSettingsRepository {
    readonly endpoint = '/centers/{id}/call-center-settings/';
    protected client: AxiosInstance;
    protected silentClient: AxiosInstance;
    protected defaultParams: ApiParameters | undefined = undefined;

    public constructor() {
        let url = baseUrl + '/api/v3/';
        if (authState.isAssumingOtherCustomer) {
            url = `${baseUrl}/api/admin/v1/customers/${authState.currentCustomerDb}/`;
        }
        this.client = createClient(url);
        this.silentClient = createClient(url, false);
    };

    /**
     * Get the offerings for the given center.
     *
     * @param centerId
     */
    public async getOfferings(centerId: number): Promise<Array<LocationOfferings>> {
        const response = await this.client.get(this.getEndpoint(centerId, LocationSettingType.OFFERINGS));
        return response.data;
    }

    /**
     * Update an offering option.
     *
     * @param centerId
     * @param dto
     */
    public async updateOffering(centerId: number, dto: LocationOfferingOptionDto): Promise<LocationOfferingOption> {
        const id = dto.id;
        delete dto.id;
        const response = await this.client.put(this.getEndpoint(centerId, LocationSettingType.OFFERINGS) + '/' + id, dto);
        return response.data;
    }

    /**
     * Get the endpoint for the API request.
     *
     * @param centerId
     * @param type
     */
    private getEndpoint(centerId: number, type: LocationSettingType): string {
        const endpoint = this.endpoint;
        return endpoint.replace('{id}', centerId.toString()) + type;
    }
}
