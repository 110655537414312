import { AbstractCreateDto } from '@/models/abstract-dto';
import { BasePhoneDto, BasePhoneDtoInterface } from '@/families/models/phone';
import { AddressDto } from '@/families/models/address';
import { SocialMediaLinkDto } from '@/core/models/social-media-link';
import { CenterHours, CommunicationHours, CommunicationHoursDay } from '@/organizations/locations/models/center';

export interface CenterDtoInterface extends AbstractCreateDto {
    name: string;
    code: string;
    is_active: boolean;
    avg_weekly_tuition: number;
    timezone: string;
    email: string;
    url: string;
    capacity: number;
    contact: {
        phone: BasePhoneDtoInterface;
        address: AddressDto;
        call_center_phone: BasePhoneDtoInterface;
    };
    parent_organization_id: number;
    status?: undefined | number;
    brand: number | null;
    staff: CenterStaffDtoInterface;
    social_media: SocialMediaLinkDto;
    hours: CenterHours;
    communication_hours: CommunicationHours;
    open_saturday: boolean;
    open_sunday: boolean;
    variable_tags?: Array<string>;
}

export interface CenterStaffDtoInterface {
    director: number | null;
    assistant_director: number | null;
    administrator: number | null;
    family_rep: number | null;
    enrollment_rep: number | null;
}

export class CenterStaffDto implements CenterStaffDtoInterface {
    director: number | null = null;
    assistant_director: number | null = null;
    administrator: number | null = null;
    family_rep: number | null = null;
    enrollment_rep: number | null = null;
}

export class CenterHoursDto implements CenterHours {
    mon_open: string | null = '07:00';
    mon_close: string | null = '18:00';
    tue_open: string | null = '07:00';
    tue_close: string | null = '18:00';
    wed_open: string | null = '07:00';
    wed_close: string | null = '18:00';
    thu_open: string | null = '07:00';
    thu_close: string | null = '18:00';
    fri_open: string | null = '07:00';
    fri_close: string | null = '18:00';
    sat_open: string | null = null;
    sat_close: string | null = null;
    sun_open: string | null = null;
    sun_close: string | null = null;
}

export class CommunicationHoursDto implements CommunicationHours {
    mon: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    tue: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    wed: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    thu: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    fri: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    sat: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };

    sun: CommunicationHoursDay = {
        start: '07:00',
        end: '18:00'
    };
}

export class CenterDto implements CenterDtoInterface {
    name = '';
    code = '';
    is_active = true;
    avg_weekly_tuition = 0;
    timezone = '';
    email = '';
    url = '';
    capacity = 0;
    contact = {
        phone: new BasePhoneDto(),
        address: new AddressDto(),
        call_center_phone: new BasePhoneDto()
    };

    parent_organization_id = 1;
    brand: number | null = null;
    staff = new CenterStaffDto();
    social_media = new SocialMediaLinkDto();
    hours = new CenterHoursDto();
    communication_hours = new CommunicationHoursDto();
    open_saturday = false;
    open_sunday = false;
    variable_tags = [];
}
