import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import type { LocationAdvisoryDto } from '@/enrollment-center/models/enrollment-center-location-models';
import { LocationAdvisory } from '@/enrollment-center/models/enrollment-center-location-models';
import { EnrollmentCenterLocationAdvisoriesRepository } from '@/enrollment-center/repositories/enrollment-center-location-advisories-repository';

export interface AdvisoriesState extends AbstractEntityState<LocationAdvisory> {
    entities: Array<LocationAdvisory>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.ADVISORIES
})
export class EnrollmentCenterLocationAdvisoriesStore extends AbstractApiStore<LocationAdvisory> implements AdvisoriesState {
    readonly repository = new EnrollmentCenterLocationAdvisoriesRepository();

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    @Action({ commit: 'addOrUpdateEntity' })
    public async updateCenterAdvisory(update: LocationAdvisoryDto) {
        // refresh to avoid race conditions as much as possible
        await this.retrieve();
        // if there's an existing advisory for this center, update that, otherwise
        // create a new one
        const existing = this.entities.filter((advisory) => {
            return advisory.center.id === update.center;
        });
        if (existing.length) {
            return await this.repository.updateAdvisory(existing[0].id, update);
        }
        return await this.repository.createAdvisory(update);
    }

    public get stored(): Array<LocationAdvisory> {
        return this.entities;
    }
}
