import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

const authStore = getModule(AuthStore, store);

const BannerMessageClosings = [
    'And remember—you\'re awesome!',
    'Have a great day!',
    'Have a wonderful day!',
    'Have an awesome day!',
    'Make today great!',
    'And remember, you\'ve got this!',
    'Make a child smile today. :)',
    'Good luck today!',
    'Have a good one!'
];

const date = new Date();

/**
 * Class that generates messages for the banner
 */
export class BannerMessages {
    // Given an array of messages, choose a random one
    public static getRandomMessage(messages: Array<string>): string {
        return messages[Math.floor(Math.random() * messages.length)];
    }

    // Given an array of messages and a number, return a random message in that range
    public static getMessageByThreshold(messageOptions: object, count: number): string {
        let ret = '';
        for (const [i, messages] of Object.entries(messageOptions)) {
            if (parseInt(i) > count) {
                break;
            }
            ret = this.setCount(this.getRandomMessage(messages), count);
        }
        return ret;
    }

    public static getGreeting(): string {
        let greeting = 'Good';
        if (date.getHours() < 12) {
            greeting += ' morning';
        } else {
            greeting += ' afternoon';
        }

        const firstName = authStore.userInfoObject?.first_name;
        if (firstName) {
            greeting += `, ${firstName}`;
        }

        greeting += '. ';
        return greeting;
    }

    public static getClosing(): string {
        return date.getHours() < 16
            ? BannerMessages.getRandomMessage(BannerMessageClosings)
            : '';
    }

    private static setCount(message: string, count: number): string {
        return message.replace('#', count.toString());
    }
}
