























































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { InterfaceSetting, SettingNames } from '@/dashboards/models/interface-settings-models';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const settingsState = getModule(InterfaceSettingsStore);
@Component({
    components: { BaseClose }
})
export default class ReportSettingsModal extends Mixins(LocaleMixin) {
    private loadingKey = 'reportSettingsModal';
    private isLoaded = false;

    private initialPreFilterSetting: InterfaceSetting | null = null;
    private currentPreFilterToggle = false;

    get canSave() {
        return this.currentPreFilterToggle !== this.initialPreFilterToggle;
    }

    get initialPreFilterToggle() {
        return Boolean(this.initialPreFilterSetting?.value);
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await settingsState.init();
        this.initialPreFilterSetting = settingsState.stored.get(SettingNames.PRE_FILTER_REPORTS) ?? null;
        this.currentPreFilterToggle = this.initialPreFilterToggle;
        this.isLoaded = true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    handleClose() {
        this.$emit(EventTypes.CLOSE);
    }

    async save() {
        if (!this.initialPreFilterSetting) {
            this.handleClose();
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        await settingsState.updateSetting({ settingId: this.initialPreFilterSetting.id, dto: { value: this.currentPreFilterToggle } });
        this.$emit(EventTypes.UPDATED, 'Report Settings Updated');
        loadingState.loadingDecrement(this.loadingKey);
        this.handleClose();
    }
}
