import { CrmEntity, HateoasLink } from '@/models/base';
import { Address, AddressDto } from '@/families/models/address';
import { Phone, PhoneDto, PhoneDtoInterface } from '@/families/models/phone';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { RelationshipLink } from '@/families/models/relationship';

export interface Contact extends CrmEntity {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: Phone | null;
    address: Address;
    external_system_id: string;
    gender?: string;
    date_of_birth?: string | null;
    added_date: string;
    integrations: Array<any>;
    relation_to_child: string | null;
    child_relation: RelationshipLink | null;
    is_guardian: boolean;
    is_family: boolean;
    is_emergency_contact: boolean;
    is_authorized_pickup: boolean;
    comments: string | null;
}

export interface SimpleContact extends CrmEntity {
    name: string;
    email: string;
    phone: Phone | null;
}

export interface ContactCreateDtoInterface extends AbstractCreateDto {
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: PhoneDtoInterface | null;
    address: Address | AddressDto | null;
    child_relation: number | null;
    is_guardian: boolean;
    is_family: boolean;
    is_emergency_contact: boolean;
    is_authorized_pickup: boolean;
    comments: string;
}

export class ContactCreateDto implements ContactCreateDtoInterface {
    name = '';
    email = '';
    first_name = '';
    last_name = '';
    phone = new PhoneDto();
    address = new AddressDto();
    child_relation: number | null = null;
    is_guardian = false;
    is_family = true;
    is_emergency_contact = false;
    is_authorized_pickup = false;
    comments = '';
}

export interface ContactUpdateDtoInterface extends ContactCreateDtoInterface, AbstractUpdateDto {
    id: number;
}

export interface FamilyContactLink extends HateoasLink {
    values: {
        name: string;
    };
}
