
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { CustomDashboardsStore } from '@/dashboards/store/custom-dashboards-store';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { FeatureConstants } from '@/features/feature-constants';

const featuresState = getModule(FeaturesStore);
const customDashState = getModule(CustomDashboardsStore);
const staffUtils = new StaffUtils();

@Component
export default class DashboardCheckMixin extends Vue {
    private hasCustomDashboards = false;
    // should be overridden by actual dashboard using this mixin
    protected dashboardType = 0;

    get dashes() {
        return customDashState.stored;
    }

    async created() {
        await featuresState.init();
        this.hasCustomDashboards = featuresState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) &&
            await staffUtils.getUserPermission(PermissionName.CustomDashboards) &&
            featuresState.isFeatureEnabled(FeatureConstants.CUSTOM_DASHBOARDS);
        if (this.hasCustomDashboards) {
            await customDashState.init();
        }
        this.dashesUpdated();
    }

    @Watch('dashes', { deep: true })
    dashesUpdated() {
        if (!this.hasCustomDashboards) {
            return;
        }
        if (!this.dashboardType) {
            return;
        }
        const validDash = this.dashes.find(dash => dash.type === this.dashboardType && dash.is_enabled);
        if (!validDash) {
            this.$router.push({ name: 'home' });
        }
    }

}

