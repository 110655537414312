import { WorkflowType } from '@/automation/workflows/models/workflow-type-models';

export enum DripCampaignStatus {
    DRAFT = 1,
    PUBLISHED = 2,
    PAUSED = 3,
    UNPUBLISHED = 4,
    ARCHIVED = 5
}

export const editableDripCampaignStatuses = [
    DripCampaignStatus.DRAFT,
    DripCampaignStatus.PAUSED,
    DripCampaignStatus.UNPUBLISHED
];

export const actionableDripCampaignStatuses = [
    DripCampaignStatus.PUBLISHED,
    DripCampaignStatus.PAUSED
];

export enum DripCampaignTiming {
    FIXED = 9010,
    RELATIVE = 9011
}

export enum DripCampaignIntervalUnit {
    DAY = 1,
    WEEK = 4,
    MONTH = 5
}

export const dripIntervalUnits: Array<WorkflowType> = [
    {
        id: DripCampaignIntervalUnit.DAY,
        value: 'Days'
    },
    {
        id: DripCampaignIntervalUnit.WEEK,
        value: 'Weeks'
    },
    {
        id: DripCampaignIntervalUnit.MONTH,
        value: 'Months'
    }
];

export const dripTimingTypes: Array<WorkflowType> = [
    {
        id: DripCampaignTiming.FIXED,
        value: 'Fixed'
    },
    {
        id: DripCampaignTiming.RELATIVE,
        value: 'Relative'
    }
];

export enum DripFieldType {
    CHILD = 'child',
    TASK = 'task'
}

export enum DripCommType {
    EMAIL = 9000,
    TEXT = 9001
}

export const dripCommTypes: Array<WorkflowType> = [
    {
        id: DripCommType.EMAIL,
        value: 'Email'
    },
    {
        id: DripCommType.TEXT,
        value: 'Text'
    }
];

export enum DripCampaignEventActionType {
    ACTION_WORKFLOW = 8950,
    ACTION_NEW_DRIP = 8951,
    ACTION_CANCEL_DRIP = 8952
}

export const dripEventActionTypes: Array<WorkflowType> = [
    {
        id: DripCampaignEventActionType.ACTION_WORKFLOW,
        value: 'Trigger Workflow'
    },
    {
        id: DripCampaignEventActionType.ACTION_NEW_DRIP,
        value: 'Start New Drip Campaign'
    },
    {
        id: DripCampaignEventActionType.ACTION_CANCEL_DRIP,
        value: 'Cancel Drip Campaign'
    }
];
