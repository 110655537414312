
























































import { LocaleMixin } from '@/locales/locale-mixin';
import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { RecentlyModifiedTableData, Update } from '@/families/models/update';
import { UpdatesRepository } from '@/families/repositories/updates-repository';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import type { Org } from '@/models/organization/org';
import { OrgsStore } from '@/store/orgs-store';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const updatesRepository = new UpdatesRepository();
const orgsState = getModule(OrgsStore);

@Component({
    components: {}
})
export default class RecentlyModifiedFamilies extends Mixins(LocaleMixin) {
    private loadingKey = 'recentlyModifiedTable';
    private items: Array<RecentlyModifiedTableData> = [];
    get center() {
        return appState.storedCurrentCenter;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get tableHeaders(): Array<DataTableHeader> {
        // Using the sort keys as column headers to make it easier to pass the sort params to the API
        return [
            {
                text: 'Guardian Name',
                value: 'guardian_name'
            },
            {
                text: 'Last Modified',
                value: 'last_modified_datetime'
            }
        ];
    }

    @Watch('org')
    private async updateOrg(org: Org) {
        if (org && org.id > 0) {
            await this.loadTableEntries();
        }
    }

    public async created() {
        const orgsPromise = orgsState.init();
        await orgsPromise;
        await this.loadTableEntries();
    }

    async loadTableEntries() {
        loadingState.loadingIncrement(this.loadingKey);
        this.items = [];
        if (authState.userInfoObject?.id && this.org?.id) {
            const allUpdates: Array<Update> = await updatesRepository.updatesByStaff(1, authState.userInfoObject?.id, orgsState.centersByOrgId(this.org.id).map(center => center.id));

            for (const item of allUpdates) {
                item.changes.sort(function(a, b) {
                    const dateA = new Date(b.date_time as string);
                    const dateB = new Date(a.date_time as string);
                    return dateA.getTime() - dateB.getTime();
                });
                this.items.push({
                    guardian_name: item.family.values.name,
                    last_modified_datetime: item.changes[0].date_time,
                    family_id: item.family.id
                } as RecentlyModifiedTableData);
            }

            this.items.sort(function(a, b) {
                const dateA = new Date(b.last_modified_datetime as string);
                const dateB = new Date(a.last_modified_datetime as string);
                return dateA.getTime() - dateB.getTime();
            });

            if (this.items.length > 8) {
                this.items = this.items.slice(0, 8);
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private goToFamilyHub(item: RecentlyModifiedTableData) {
        this.$router.push({ name: 'family-hub', params: { id: item.family_id.toString() } });
    }
}
