import {
    Email,
    EmailCreateDto,
    EmailGroupDto,
    EmailThread, GroupEmailDto, OutgoingEmail,
    OutgoingEmailUpdateDto
} from '@/communications/messages/models/email';
import { MessagesRepository } from '@/communications/messages/repositories/messages-repository';

export class EmailsRepository extends MessagesRepository<Email> {
    protected endpoint = 'emails';

    public async getSingleThread(msgId: number): Promise<EmailThread> {
        const response = await this.client.get(this.endpoint + '/incoming/' + msgId + '/thread');
        return response.data;
    }

    /**
     * Get a single outgoing email.
     *
     * @param msgId
     */
    public async getOutgoing(msgId: number): Promise<OutgoingEmail> {
        const response = await this.client.get(this.endpoint + '/outgoing/' + msgId);
        return response.data;
    }

    public async sendEmail(dto: EmailCreateDto | EmailGroupDto): Promise<Email> {
        const response = await this.client.post(`${this.endpoint}/outgoing`, dto);
        return response.data;
    }

    public async sendGroupEmail(filterId: number, dto: GroupEmailDto): Promise<void> {
        await this.client.post(`groups/${filterId}/histories/emails`, dto);
    }

    /**
     * Delete a pending outgoing email.
     * Should return empty data.
     *
     * @param msgId
     */
    public async cancelPendingEmail(msgId: number): Promise<any> {
        const response = await this.client.delete(`communications/${this.endpoint}/outgoing/${msgId}`);
        return response.data;
    }

    /**
     * Update a pending outgoing email.
     *
     * @param msgId
     * @param dto
     */
    public async updatePendingEmail(msgId: number, dto: OutgoingEmailUpdateDto): Promise<Email> {
        const response = await this.client.patch(`communications/${this.endpoint}/outgoing/${msgId}`, dto);
        return response.data;
    }

    async archiveByFamilies(): Promise<void> {
        throw new Error('Not implemented');
    }
}
