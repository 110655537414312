import { AbstractRepository } from '@/repositories/abstract-repository';
import { CrmTypeOption, CrmTypeOptionCreateDto, CrmTypeOptionUpdateDto } from '@/crm-types/models/crm-type';

export class StaffTitleRepository extends AbstractRepository<CrmTypeOption> {
    protected endpoint = 'types/staff-title'

    public async getStaffTitles(): Promise<Array<CrmTypeOption>> {
        const response = await this.client.get(this.endpoint);
        return response.data;
    }

    public async updateStaffTitle(id: number, payload: CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto): Promise<CrmTypeOption> {
        const response = await this.client.put(this.endpoint + '/' + id, payload);
        return response.data;
    }

    public async addStaffTitle(payload: CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto): Promise<CrmTypeOption> {
        const response = await this.client.post(this.endpoint, payload);
        return response.data;
    }
}
