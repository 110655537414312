import { LandingPagesRepository } from '@/automation/landing-pages/repositories/landing-pages-repository';
import { EnrollmentCenterSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-settings-repository';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { RepositoryFactory } from '@/repositories/repository-factory';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { Module, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store/index';

export interface RepositoryState {
    repoFactory: RepositoryFactory;
}

/**
 * Helper store for retrieving repositories without creating duplicates throughout the application.
 */
@Module({
    namespaced: true,
    name: StoreModuleTypes.REPOSITORY_FACTORY,
    dynamic: true,
    store: store
})
export class RepositoryFactoryStore extends VuexModule {
    repoFactory: RepositoryFactory = new RepositoryFactory();

    /**
     * Get the children repository.
     */
    public get childrenRepository(): ChildrenRepository {
        return this.repoFactory.getChildrenRepository();
    }

    /**
     * Get the ec settings repository.
     */
    public get enrollmentCenterSettingsRepository(): EnrollmentCenterSettingsRepository {
        return this.repoFactory.getEnrollmentCenterSettingsRepository();
    }

    /**
     * Get the integration repository.
     */
    public get integrationRepository(): IntegrationRepository {
        return this.repoFactory.getIntegrationRepository();
    }

    /**
     * Get the landing pages repository.
     */
    public get landingPagesRepository(): LandingPagesRepository {
        return this.repoFactory.getLandingPagesRepository();
    }

    /**
     * Get the staff repository.
     */
    public get staffRepository(): StaffRepository {
        return this.repoFactory.getStaffRepository();
    }
}
