











import { LocaleMixin } from '@/locales/locale-mixin';
import tinymce, { RawEditorSettings } from 'tinymce';
import { Component, Mixins, PropSync } from 'vue-property-decorator';
import TinyEditorComponent from '@tinymce/tinymce-vue';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

(global as any).window.tinymce = tinymce; // So plugins will load

@Component({ components: { TinyEditorComponent } })
export default class EmailContentEditor extends Mixins(LocaleMixin) {
    @PropSync('messageContent', { type: String }) messageContentSynced!: string;

    private get tinyInit(): RawEditorSettings {
        return {
            menubar: false,
            height: 300,
            allow_conditional_comments: true,
            convert_fonts_to_spans: false,
            allow_html_in_named_anchor: true,
            fix_list_elements: false,
            preserve_cdata: true,
            validate: false,
            plugins: ['image', 'link', 'lists', 'emoticons', 'fullpage'],
            paste_as_text: true,
            toolbar: 'undo redo emoticons | formatselect fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link | removeformat help',
            font_formats: 'Arial=arial,helvetica,sans-serif; Verdana=verdana,geneva; Helvetica=helvetica; Georgia=georgia,palatino; Tahoma=tahoma,arial,helvetica,sans-serif; Lucida=lucida sans; Trebuchet=trebuchet ms,geneva; Times New Roman=times new roman,times',
            branding: false,
            valid_children: '+body[style],+a[span|table],+span[table],+*[*]',
            valid_elements: '*[*]',
            extended_valid_elements: 'pre[*],style[*],table[*]',
            statusbar: false,
            browser_spellcheck: true,
            default_link_target: '_blank',
            target_list: false,
            contextmenu: false
        };
    };
}
