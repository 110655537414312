import { Phone, PhoneDtoInterface } from '@/families/models/phone';
import { HateoasLink } from '@/models/base';

export class PhoneMapper {
    toCreateDto(entity: Phone | PhoneDtoInterface | null): PhoneDtoInterface {
        if (entity) {
            let type = null;
            if (entity.type) {
                type = typeof entity.type === 'string' ? entity.type as string : (entity.type as HateoasLink).values?.value;
            }

            return {
                number_e164: entity.number_e164,
                type: type ?? null
            };
        }
        return {
            number_e164: '',
            type: null
        };
    }
}
