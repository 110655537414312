





























































































































import { EventTypes } from '@/constants/event-type-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { UserPermissionsStore } from '@/staff/store/user-permissions-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { StaffSortKeys, StaffSortParameter, User } from '@/staff/models/user';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { AppStateStore } from '@/store/app-state-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import ManageStaffModal from '@/staff/components/ManageStaffModal.vue';
import SettingsUserSettings from '@/staff/components/SettingsUserSettings.vue';
import ServiceAccountsTable from '@/staff/components/ServiceAccountsTable.vue';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import UserPermissionsCard from '@/staff/components/UserPermissionsCard.vue';
import { DataTableOptions } from '@/models/datatables';
import { ApiPagination } from '@/repositories/abstract-repository';
import { getPagination } from '@/core/datatables-utils';
import { SortConstants } from '@/constants/sort-constants';
import { PermissionName } from '@/staff/models/user-permission-models';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const loadingState = getModule(LoadingStore);
const staffRepository = new StaffRepository();
const userPermissionsState = getModule(UserPermissionsStore);
const appState = getModule(AppStateStore);
const featuresStore = getModule(FeaturesStore);
const authState = getModule(AuthStore, store);

@Component({
    components: { BasePageTitle, ManageStaffModal, SettingsUserSettings, ServiceAccountsTable, UserPermissionsCard }
})
export default class ManageUsers extends Mixins(LocaleMixin, PageTitleMixin) {
    private addedEvent = EventTypes.ADDED;
    private loadingKey = 'staffData';
    private staff: Array<User> = [];
    private showStaffModal = false;
    private selectedStaff: User | null = null;
    private updatedEvent = EventTypes.UPDATED;
    private hasSettingsPermission = false;
    private headers = [
        {
            text: 'First Name',
            value: StaffSortKeys.FIRST_NAME,
            width: '7rem'
        },
        {
            text: 'Last Name',
            value: StaffSortKeys.LAST_NAME,
            width: '7rem'
        },
        {
            text: 'Position',
            value: StaffSortKeys.POSITION,
            width: '8rem'
        },
        {
            text: 'Organization Level',
            value: StaffSortKeys.ORG,
            width: '14rem'
        },
        {
            text: 'Status',
            value: StaffSortKeys.STATUS,
            width: '5rem'
        },
        {
            text: '',
            value: 'action',
            sortable: false,
            width: '15%',
            align: 'center'
        }
    ];

    private showUserSettingsModal = false;
    private userPermissionsToggle = false;
    private options: DataTableOptions = { itemsPerPage: 25, page: 1, sortBy: [StaffSortKeys.FIRST_NAME] };
    private defaultOptions: DataTableOptions = { itemsPerPage: 25, page: 1, sortBy: [StaffSortKeys.FIRST_NAME] };
    private defaultSorting: StaffSortParameter = {
        sort_keys: [StaffSortKeys.FIRST_NAME],
        sort_dir: [SortConstants.ASCENDING]
    };

    private isLoading = true;
    private currentItemsCount = 1;
    private search = '';
    private prevSearch = '';
    private allCount = 0;

    private get isCorpUser() {
        return authState.isCorporateUser;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    private get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    get showInactive(): boolean {
        return appState.showInactiveUsers;
    }

    set showInactive(flag: boolean) {
        appState.setShowInactiveUsers(flag);
    }

    async created() {
        await featuresStore.init();
        if (this.isCrmPlus) {
            if (this.isSuperUser) {
                this.hasSettingsPermission = true;
            } else {
                const perm = await userPermissionsState.getPermissionByName({
                    userId: authState!.id as number,
                    permissionName: PermissionName.SettingsAllGears
                });
                this.hasSettingsPermission = perm?.grants.update ?? false;
            }
        }
    }

    mounted() {
        this.setPageTitle('User Information');
    }

    @Watch('options')
    async onOptionsChange() {
        await this.fetchAccessibleStaff();
    }

    @Watch('showInactive')
    async showInactiveToggled() {
        await this.fetchAccessibleStaff();
    }

    public addStaff(): void {
        if (!appState.manageStaffModalOpen) {
            appState.updateManageStaffModalStatus(true);
            this.selectedStaff = null;
            this.showStaffModal = true;
        }
    }

    // Add a new user to the table.
    private addUserToTable(user: User) {
        this.staff.push(user);
    }

    private async fetchAccessibleStaff() {
        this.isLoading = true;
        const pagination: ApiPagination = getPagination(this.options);
        const sorting: StaffSortParameter = this.getSortingFromOptions(this.options);
        const staffResponse = await staffRepository.getAllAccessibleStaff(pagination, sorting, this.search, !this.showInactive);
        this.staff = staffResponse.entities;
        this.currentItemsCount = staffResponse.count;

        if (!this.search) {
            this.allCount = staffResponse.count;
        }

        this.isLoading = false;
    }

    private editStaff(staff: User): void {
        if (!appState.manageStaffModalOpen) {
            appState.updateManageStaffModalStatus(true);
            this.selectedStaff = staff;
            this.showStaffModal = true;
        }
    }

    private getSortingFromOptions(options: DataTableOptions): StaffSortParameter {
        let sorting = this.defaultSorting;
        if (options.sortBy && options.sortBy.length) {
            sorting = {
                sort_keys: [options.sortBy[0] as StaffSortKeys],
                sort_dir: [
                    options.sortDesc && options.sortDesc[0]
                        ? SortConstants.DESCENDING
                        : SortConstants.ASCENDING
                ]
            };
        }
        return sorting;
    }

    private openUserSettings(): void {
        this.showUserSettingsModal = true;
    }

    private showUserPermissionsCard() {
        this.userPermissionsToggle = !this.userPermissionsToggle;
    }

    // Update a user in the table
    private async updateUserInTable(userId: number) {
        loadingState.loadingIncrement(this.loadingKey);
        const permissionsResponse = userPermissionsState.retrieveAllPermissions(userId);

        for (const user of this.staff) {
            const i = this.staff.indexOf(user);
            if (user.id === userId) {
                this.$set(this.staff, i, await staffRepository.getOne(userId));
            }
        }

        await permissionsResponse;
        await this.fetchAccessibleStaff();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async clearSearch() {
        // Update items, no search term.
        this.search = '';
        this.prevSearch = '';
        this.options = this.defaultOptions;

        await this.fetchAccessibleStaff();
    }

    private async doSearch() {
        if (!this.search) {
            // Update items, no search term.
            await this.fetchAccessibleStaff();
            return;
        }

        if (this.search.length < 2) {
            // Do not search unless we have at least 2 characters
            return;
        }

        // Make sure search resets pagination and sorting the first time.
        if (this.search !== this.prevSearch) {
            this.options = this.defaultOptions;
            this.prevSearch = this.search;
        }

        await this.fetchAccessibleStaff();
    }
}
