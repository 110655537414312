











































































































































































































































































































































































































import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import {
    formatClockTime,
    formatDateForApi,
    formatIsoDateTime,
    getTimeZoneOptions
} from '@/date-time/date-time-utils';
import type { Family } from '@/families/models/family';
import { searchByCenterId, searchByOrgId } from '@/families/search-families';
import { LocaleMixin } from '@/locales/locale-mixin';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import DurationSelect from '@/tasks/components/DurationSelect.vue';
import TaskDescription from '@/tasks/components/TaskDescription.vue';
import TaskTypeSelect from '@/tasks/components/TaskTypeSelect.vue';
import { TaskCreateDto, TaskReminderUnits, TaskType, TaskUpdateDto } from '@/tasks/models/task-models';
import { TasksPostPutRepository } from '@/tasks/repositories/tasks-post-put-repository';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TaskResultSelect from '@/tasks/components/TaskResultSelect.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import SendReminderInputs from '@/tasks/components/SendReminderInputs.vue';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { ChangeStatus } from '@/families/change-status';
import { isDoNotEmail, isDoNotText } from '@/families/families-utils';
import { AppStateStore } from '@/store/app-state-store';
import { enrollmentTeamPlaceholder } from '@/enrollment-center/models/enrollment-center-models';
import { TasksStore } from '@/tasks/stores/tasks-store';
import { isTaskPastDue } from '@/tasks/task-utils';
import { TimezoneOption } from '@/core/timezones/timezone';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { TimezonesStore } from '@/core/timezones/timezones-store';
import { downloadIcsFile } from '@/calendar/calendar-utils';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const tasksRepository = new TasksRepository();
const tasksStore = getModule(TasksStore);
const changeStatus = new ChangeStatus();
const appState = getModule(AppStateStore);
const featureState = getModule(FeaturesStore);
const centersState = getModule(CentersStore);
const timezoneState = getModule(TimezonesStore);

@Component({
    components: {
        BaseClose,
        SendReminderInputs,
        DurationSelect,
        TaskTypeSelect,
        TaskDescription,
        CenterAscendingStaffList,
        StatusChangeSelect,
        TaskResultSelect
    }
})
export default class AddTaskModal extends Mixins(LocaleMixin) {
    @Prop() readonly centerId!: number | undefined;
    @Prop() dueDateTime!: string;
    // Did somebody already tell us the family?
    @Prop() family!: Family;
    @Prop() initialDuration!: number | undefined;
    // Is this a meeting-type task?
    @Prop({ default: false }) isMeeting!: boolean;
    // v-model stuff
    @Prop({ default: false }) readonly value!: boolean;

    private familySelect: any = null;
    private familyId = 0;
    private defaultStaffId: any = null;
    private familySearchResults: Array<Family> | null | undefined = null;
    private familySearching = false;
    private familySelectItems: Array<any> = [];
    private familyInfo: Family | null | undefined = null;
    private taskType: any = null;
    private assignedTo: any = null;
    private centerIdStaff: any = null;
    private description = '';
    private exportToIcsFile = false;
    private startDate = '';
    private startTime = '';
    private isDisabled = true;
    private isSubsDisabled = true;
    private overlay = false;
    private taskAdded = false;
    private isTour = true;
    private sendTextReminderGuardian = true;
    private sendEmailReminderGuardian = true;
    private sendTextReminderStaff = true;
    private sendEmailReminderStaff = true;
    private duration = 60;
    private canEmail = true; // Is "email" reminder allowed?
    private canText = true; // Is "text" reminder allowed?
    private showCompleteOptions = false;
    private isComplete = false;
    private resultDescription = '';
    private result_type?: number | null;
    private commentsAdded = false;
    private guardianTextReminderAmount: string | null = null;
    private guardianTextReminderUnit: TaskReminderUnits | null = null;
    private guardianEmailReminderAmount: string | null = null;
    private guardianEmailReminderUnit: TaskReminderUnits | null = null;
    private staffTextReminderAmount: string | null = null;
    private staffTextReminderUnit: TaskReminderUnits | null = null;
    private staffEmailReminderAmount: string | null = null;
    private staffEmailReminderUnit: TaskReminderUnits | null = null;
    private chosenTimezone = 'UTC';
    private timezoneOptions: Array<TimezoneOption> = [];
    private showAllAccessible = false;

    async created() {
        if (this.centerId) {
            this.centerIdStaff = this.centerId;
        }
        if (this.familyInfo && this.familyInfo.center) {
            this.centerIdStaff = this.familyInfo.center.id;
        }
        if (this.centerIdStaff) {
        const center = await centersState.getAccessibleCenterById(this.centerIdStaff);
        this.defaultStaffId = center.staff?.director?.id;
        }
    }

    async mounted() {
        if (this.isMeeting) {
            this.initializeRemindersData();
        }

        if (this.dueDateTime) {
            this.updateDueDate();
        } else {
            this.startTime = '17:00';
        }

        if (this.initialDuration) {
            this.duration = this.initialDuration;
        }

        this.updateEmailReminderStaff();
        this.updateTextReminderStaff();

        await featureState.init();

        if (this.family) {
            await this.setFamily();
        }

        if (this.showTimezone) {
            const centersPromise = centersState.initAccessibleCenters();
            const timezonePromise = timezoneState.init();
            await centersPromise;
            await timezonePromise;
            this.timezoneOptions = getTimeZoneOptions(timezoneState.stored, new Date(), this.$i18n.locale);
        }

        this.showAllAccessible = !featureState.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get descriptionLabel() {
        let label = 'Task';
        if (this.isMeeting) {
            label = 'Meeting';
        }
        return `${label} Description`;
    }

    get saveButtonText() {
        if (
            this.isMeeting &&
            (
                this.sendTextReminderGuardian ||
                this.sendEmailReminderGuardian ||
                this.sendTextReminderStaff ||
                this.sendEmailReminderStaff
            )
        ) {
            return 'Save and Send Reminder';
        }

        return 'Save';
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get staffCenterId() {
        if (this.centerId) {
            return this.centerId;
        }
        if (this.familyInfo && this.familyInfo.center) {
            return this.familyInfo.center.id;
        }
        return null;
    }

    get showTimezone() {
        return featureState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get userTimezone() {
        return authState.userTimeZone;
    }

    @Watch('dueDateTime')
    private updateDueDate() {
        this.startDate = formatDateForApi(this.dueDateTime);
        this.startTime = formatClockTime(this.dueDateTime);
    }

    @Watch('value')
    private checkValue() {
      if (this.value) {
          this.exportToIcsFile = false;
          this.assignedTo = authState.userInfoObject?.id;
          this.initializeRemindersData();
        }
    }

    @Watch('centerId')
    private centerChanged() {
        this.close();
    }

    // Sets the data from the prop
    @Watch('family')
    private async setFamily() {
        this.familyInfo = this.family;
        this.familyId = this.family.id;
        this.isSubsDisabled = false;
        this.canEmail = !isDoNotEmail(this.family);
        this.sendEmailReminderGuardian = this.canEmail;
        this.canText = !isDoNotText(this.family);
        this.sendTextReminderGuardian = this.canText;
        this.sendEmailReminderStaff = this.isMeeting;
        this.sendTextReminderStaff = this.isMeeting;

        if (this.showTimezone && this.familyInfo && this.familyInfo.center) {
            await this.setDefaultTimezone(this.familyInfo.center.id);
        }

        await this.autoAssign();
    }

    @Watch('familySelect')
    private async checkFamily() {
        // Reset state when family changes
        this.familyInfo = null;
        this.familyId = 0;
        this.sendTextReminderGuardian = true;
        this.canText = true;
        this.sendEmailReminderGuardian = true;
        this.canEmail = true;
        this.isTour = true;
        this.description = '';
        // Reset duration, and make sure it's not 0 FFS.
        this.duration = this.initialDuration && this.initialDuration > 0
            ? this.initialDuration
            : 60;
        // Reset task type
        if (!this.isMeeting) {
            this.taskType = undefined;
        }

        // Disable all the sub inputs.
        this.isSubsDisabled = true;

        if (this.familySelect !== null && typeof this.familySelect !== 'undefined') {
            // Get the selected family.
            if (this.familySearchResults !== null && typeof this.familySearchResults !== 'undefined') {
                this.familyId = AddTaskModal.parseFamilyId(this.familySelect);
                this.familyInfo = this.familySearchResults.find(family => family.id === this.familyId);
                this.isSubsDisabled = false;

                if (this.familyInfo?.do_not_email || !this.familyInfo?.primary_guardian.email) {
                    this.canEmail = false;
                    this.sendEmailReminderGuardian = false;
                }

                if (this.familyInfo?.do_not_text || !this.familyInfo?.primary_guardian.primary_phone?.number) {
                    this.canText = false;
                    this.sendTextReminderGuardian = false;
                }

                if (this.showTimezone && this.familyInfo && this.familyInfo.center) {
                    await this.setDefaultTimezone(this.familyInfo.center.id);
                }
            }
        } else if (this.familySelect === null || typeof this.familySelect === 'undefined') {
            // Clear out the previous search results.
            this.familySelectItems = [];
            // reset timezone
            this.chosenTimezone = this.userTimezone;
        }

        await this.autoAssign();
        this.validate();
    }

    @Watch('taskType')
    private checkTaskTypeId() {
        this.validate();
    }

    @Watch('assignedTo')
    private checkAssignedTo() {
        this.validate();
    }

    @Watch('startDate')
    private checkStartDate() {
        this.validate();
    }

    @Watch('startTime')
    private checkStartTime() {
        this.validate();
    }

    @Watch('description')
    private checkDescription() {
        this.validate();
    }

    @Watch('isComplete')
    private checkCompletedTask() {
        this.validate();
    }

    @Watch('resultDescription')
    private checkResultDescription() {
        this.commentsAdded = this.resultDescription !== '';
        this.validate();
    }

    private async setDefaultTimezone(centerId: number) {
        const matchingCenter = await centersState.getAccessibleCenterById(centerId);
        if (matchingCenter) {
            this.chosenTimezone = matchingCenter.timezone;
            return;
        }
        this.chosenTimezone = this.userTimezone;
    }

    private async doFamilySearch(searchString: string) {
        this.familySearching = false;
        this.familySelectItems = [];

        if (searchString && searchString !== '') {
            this.familySearching = true;
            const selectItems: Array<any> = [];

            // Do search, await results, update prop with results.
            this.familySearchResults = [];
            if (this.centerId) {
                this.familySearchResults = await searchByCenterId(searchString, this.centerId);
            } else if (this.org) {
                this.familySearchResults = await searchByOrgId(searchString, this.org.id);
            }

            this.familySearchResults.forEach((result) => {
                result.guardians.forEach((guardian) => {
                    selectItems.push({ text: guardian.name, value: result.id + '-g' + guardian.id });
                });

                result.children.forEach((child) => {
                    selectItems.push({ text: child.name, value: result.id + '-c' + child.id });
                });
            });

            this.familySelectItems = selectItems;
            this.familySearching = false;
        } else {
            this.familySelect = null;
        }
    }

    private initializeRemindersData() {
        this.sendEmailReminderGuardian = this.isMeeting && this.canEmail;
        this.sendTextReminderGuardian = this.isMeeting && this.canText;
        this.sendEmailReminderStaff = this.isMeeting;
        this.sendTextReminderStaff = this.isMeeting;
        this.guardianTextReminderAmount = this.sendTextReminderGuardian ? '2' : null;
        this.guardianTextReminderUnit = this.sendTextReminderGuardian ? TaskReminderUnits.HOURS : null;
        this.staffTextReminderAmount = this.sendTextReminderStaff ? '15' : null;
        this.staffTextReminderUnit = this.sendTextReminderStaff ? TaskReminderUnits.MINUTES : null;
        this.guardianEmailReminderAmount = this.sendEmailReminderGuardian ? '24' : null;
        this.guardianEmailReminderUnit = this.sendEmailReminderGuardian ? TaskReminderUnits.HOURS : null;
        this.staffEmailReminderAmount = this.sendEmailReminderStaff ? '10' : null;
        this.staffEmailReminderUnit = this.sendEmailReminderStaff ? TaskReminderUnits.HOURS : null;
    }

    @Watch('sendTextReminderStaff')
    updateTextReminderStaff() {
        this.staffTextReminderAmount = this.sendTextReminderStaff ? '15' : null;
        this.staffTextReminderUnit = this.sendTextReminderStaff ? TaskReminderUnits.MINUTES : null;
    }

    @Watch('sendEmailReminderStaff')
    updateEmailReminderStaff() {
        this.staffEmailReminderAmount = this.sendEmailReminderStaff ? '10' : null;
        this.staffEmailReminderUnit = this.sendEmailReminderStaff ? TaskReminderUnits.HOURS : null;
    }

    private static parseFamilyId(id: string) {
        const fId = id.split('-')[0];
        return parseInt(fId);
    }

    private async autoAssign() {
        if (this.familyInfo?.center) {
            if (authState.userInfoObject!.is_on_enrollment_team &&
                this.isMeeting &&
                featureState.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)
            ) {
                const center = await centersState.getAccessibleCenterById(this.familyInfo.center.id);
                this.assignedTo = center.staff?.director?.id;
            } else if (
                !authState.userInfoObject!.is_on_enrollment_team &&
                (this.isMeeting || this.isTour)
            ) {
                // New Task / Meeting / Tour: Assign to user if not on enrollment team.
                this.assignedTo = authState.userInfoObject?.id;
            }
        }
    }

    private async setTaskType(taskType: TaskType) {
        if (taskType && taskType.id) {
            this.taskType = taskType.id;
        }
    }

    private updateDuration(duration: number) {
        this.duration = duration;
    }

    private validate(): boolean {
        this.isDisabled = true;
        if (this.familyId > 0 &&
            typeof this.taskType !== 'undefined' &&
            this.taskType !== null &&
            this.taskType !== '' &&
            typeof this.assignedTo !== 'undefined' &&
            this.assignedTo !== null &&
            this.assignedTo !== '' &&
            typeof this.startDate !== 'undefined' &&
            this.startDate !== '' &&
            typeof this.startTime !== 'undefined' &&
            this.startTime !== '' &&
            ((!this.commentsAdded && !this.isComplete) || (this.commentsAdded && this.isComplete) || (!this.commentsAdded && this.isComplete))
        ) {
            this.isDisabled = false;
            return true;
        }

        return false;
    }

    private async saveTask() {
        this.isDisabled = true;

        if (this.validate()) {
            this.overlay = true;

            try {
                const tasksPostPutRepository = new TasksPostPutRepository();
                const timeZone = this.showTimezone ? this.chosenTimezone : this.userTimezone;
                const dueDateFormatted = formatIsoDateTime(this.startDate + ' ' + this.startTime, timeZone);

                const task: TaskCreateDto = {
                    family: this.familyId,
                    type: this.taskType,
                    assigned_to_staff: this.assignedTo,
                    due_date_time: dueDateFormatted,
                    description: this.description ? this.description : ''
                };

                if (task.assigned_to_staff === enrollmentTeamPlaceholder) {
                    task.assigned_to_staff = 0;
                    task.assigned_to_enrollment_team_group = 1;
                }

                // Set meeting-related stuff
                if (this.isMeeting) {
                    task.duration = this.duration;
                    if (this.sendEmailReminderGuardian) {
                        task.primary_guardian_email_reminder_amount = this.guardianEmailReminderAmount !== null ? parseInt(this.guardianEmailReminderAmount) : null;
                        task.primary_guardian_email_reminder_unit = this.guardianEmailReminderUnit !== null ? this.guardianEmailReminderUnit : null;
                    }
                    if (this.sendTextReminderGuardian) {
                        task.primary_guardian_text_reminder_amount = this.guardianTextReminderAmount !== null ? parseInt(this.guardianTextReminderAmount) : null;
                        task.primary_guardian_text_reminder_unit = this.guardianTextReminderUnit !== null ? this.guardianTextReminderUnit : null;
                    }
                }
                if (this.sendEmailReminderStaff) {
                    task.staff_email_reminder_amount = this.staffEmailReminderAmount !== null ? parseInt(this.staffEmailReminderAmount) : null;
                    task.staff_email_reminder_unit = this.staffEmailReminderUnit !== null ? this.staffEmailReminderUnit : null;
                }

                if (this.sendTextReminderStaff) {
                    task.staff_text_reminder_amount = this.staffTextReminderAmount !== null ? parseInt(this.staffTextReminderAmount) : null;
                    task.staff_text_reminder_unit = this.staffTextReminderUnit !== null ? this.staffTextReminderUnit : null;
                }
                if (isTaskPastDue(task) && this.isMeeting) {
                    tasksStore.incrementPastDueToursWithin30DaysCount();
                }
                const newTask = await tasksPostPutRepository.addTask(task);
                if (this.isMeeting && this.exportToIcsFile) {
                    downloadIcsFile(newTask);
                }
                // If task needs to be recorded as completed
                if (this.isComplete) {
                    const taskId = newTask.id;
                    const updateTask: TaskUpdateDto = {
                        id: taskId,
                        family: this.familyId,
                        type: this.taskType,
                        assigned_to_staff: this.assignedTo,
                        due_date_time: dueDateFormatted,
                        description: this.description ? this.description : '',
                        is_completed: true,
                        completed_by_user_id: authState.userInfoObject?.id,
                        completed_date_time: formatIsoDateTime(new Date()),
                        result_type: this.result_type,
                        result_description: this.resultDescription
                    };
                    const completedTask = await tasksRepository.complete(updateTask);
                    if (this.isMeeting && this.exportToIcsFile) {
                        downloadIcsFile(completedTask);
                    }
                    await changeStatus.writePendingChanges(this.family.id);
                }
                this.taskAdded = true;
                this.$emit('created', newTask);
                this.close();
            } catch (e) {
                this.isDisabled = false;
                this.overlay = false;
            }
        }
    }

    // Close the modal and clean up some stuff.
    private close() {
        this.familySelect = null;
        this.familyId = this.family ? this.family.id : 0;
        this.familySearchResults = null;
        this.familySearching = false;
        this.familySelectItems = [];
        this.familyInfo = this.family; // often null
        this.taskType = null;
        this.assignedTo = null;
        this.description = '';
        this.duration = 60;
        this.startDate = '';
        this.startTime = '17:00';
        this.isSubsDisabled = !this.family; // If we have a prop, don't disable
        this.isDisabled = true;
        this.overlay = false;
        this.showCompleteOptions = false;
        this.isComplete = false;
        this.exportToIcsFile = false;
        this.resultDescription = '';
        this.result_type = null;
        changeStatus.resetQueue(this.familyId);
        this.commentsAdded = false;
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }

    private setTaskResult(result: number|undefined) {
        this.result_type = result;
        this.isComplete = result !== undefined;

        // Set start date and start time
        this.startDate = formatDateForApi(new Date());
        this.startTime = formatClockTime(new Date());
    }

    private dropdownFunc() {
        if (!this.isSubsDisabled) { this.showCompleteOptions = !this.showCompleteOptions; }
    }
};
