var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{staticClass:"flex d-flex flex-column",attrs:{"width":"100%","height":"100%"}},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-title',[_vm._v("Recently Modified Families")]),_c('v-card-text',[_c('base-data-table',{staticClass:"recently-modified-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.goToFamilyHub(item)}}},[_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"truncate"},'td',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.guardian_name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.guardian_name))])]),_c('base-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({staticClass:"truncate"},'td',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.formatDate(item.last_modified_datetime))+" "+_vm._s(_vm.formatTime(item.last_modified_datetime))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.last_modified_datetime))+" "+_vm._s(_vm.formatTime(item.last_modified_datetime))+" ")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }