import {
    CreateFacebookOutgoingMessageDto,
    FacebookMessage
} from '@/communications/messages/models/facebookMessage';
import { MessagesRepository } from '@/communications/messages/repositories/messages-repository';

export class FacebookMessagesRepository extends MessagesRepository<FacebookMessage> {
    protected endpoint = 'communications/facebook';

    public async sendFacebookMessage(newMessage: CreateFacebookOutgoingMessageDto): Promise<void> {
        await this.client.post(this.endpoint + '/outgoing', newMessage);
    }
}
