




import { BannerMessages } from '@/dashboards/banner-messages';
import { CorporateUserMessagesContent } from '@/dashboards/corporate-user-messages';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component
export default class BannerGreetingCorporateUser extends Mixins(LocaleMixin) {
    @Prop() conversionSuccessPercent!: number;
    @Prop() tasksPastDueRate!: number;
    @Prop() tourCompletedSpeedPercent!: number;
    @Prop() greetingLength!: number;

    get analysis(): string {
        let analysis = `${this.getToursTip()} ${this.getTasksPastDueTip()} ${this.getConversionSuccessTip()} `;
        if (analysis.length + this.greetingLength < 250) {
           analysis += BannerMessages.getRandomMessage(CorporateUserMessagesContent.tips);
        }
        return analysis;
    }

    @Watch('conversionSuccessPercent')
    private getConversionSuccessTip(): string {
        return BannerMessages.getMessageByThreshold(
            CorporateUserMessagesContent.conversionSuccess,
            this.conversionSuccessPercent
        );
    }

    @Watch('tasksPastDueRate')
    private getTasksPastDueTip(): string {
        return BannerMessages.getMessageByThreshold(
            CorporateUserMessagesContent.tasksPastDue,
            this.tasksPastDueRate
        );
    }

    @Watch('tourCompletedSpeedPercent')
    private getToursTip(): string {
        return BannerMessages.getMessageByThreshold(
            CorporateUserMessagesContent.tourSpeed,
            this.tourCompletedSpeedPercent
        );
    }
}
