import { PendingFamilyRecording, Recording } from '@/communications/recordings/models/recording-models';

/**
 * Handle functionality regarding call recordings.
 */
export class RecordingUtils {
    /**
     * Get the formatted duration string in M:SS.
     * E.g. '3:16', '12:11', or '0:41'.
     *
     * @param recording
     */
    public getFormattedDuration(recording: Recording): string {
        if (!recording.duration) {
            return '0:00';
        }

        const minutes = Math.floor(recording.duration / 60).toString().padStart(1, '0');
        const seconds = Math.floor(recording.duration % 60).toString().padStart(2, '0');

        return minutes + ':' + seconds;
    }

    /**
     * Get the name of the person (guardian or contact) from the phone used in the recording.
     * Return in the order of contact, guardian, and family.
     *
     * @param recording
     */
    public getPersonName(recording: Recording): string {
        if (recording.contact) {
            return recording.contact.values.name;
        }
        if (recording.guardian) {
            return recording.guardian.values.name;
        }
        if (recording.family) {
            return recording.family.values.name;
        }
        return '';
    }

    /**
     * Get the phone number of the person (guardian or contact) from the phone used in the recording.
     *
     * @param recording
     */
    public getPersonPhone(recording: Recording | PendingFamilyRecording): string {
        return recording.is_incoming ? recording.from : recording.to;
    }
}
