



















import { FeaturesStore } from '@/features/features-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import Highcharts, { SeriesBarOptions, XAxisOptions, YAxisOptions } from 'highcharts';
import { BaseStatuses } from '@/constants/status-constants';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { AppStateStore } from '@/store/app-state-store';
import { getBaseBarChartOptions, getMonochromeColors, getMonochromeColorsWithLimits } from '@/charts/chart-utils';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { getSimpleFilterDto } from '@/filters/families-filter-utils';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { LIGHTEST_BLUE, BASE_DARK_BLUE, LINE_LEADER_PURPLE } from '@/core/style-utils';

const familiesFiltersStore = getModule(FamiliesFilterStore);
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const statusesStore = getModule(StatusesStore);
const statsRepo = new PeriodStatisticsRepository();
const familiesRepo = new FamiliesRepository();
const appState = getModule(AppStateStore);
const settingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {}
})
export default class OpportunitiesChart extends Mixins(LocaleMixin) {
    @Prop({
        type: Array,
        required: true
    }) readonly orgs!: number[];

    @Prop({ default: false }) custDashMode!: boolean;

    private graphId = 'children-by-status-graph';
    private isMounted = false;
    private statuses: Array<Status> = [];
    private enrolledStatus: Status | null = null;
    private opportunityChartSeries: Array<SeriesBarOptions> = [];
    private childStatusGraphEnrolledShow = true;
    private childStatusGraphPendingShow = true;
    private pendingFamilies = 0;
    private chartOptions: Highcharts.Options = {};

    private loadingKey = 'opportunitiesChart';

    // handles the toggling back and forth of whether or not to show the enrolled status.
    private updateEnrolledField() {
        if (this.enrolledStatus) {
            this.childStatusGraphEnrolledShow = settingsStore.stored.get(SettingNames.CHILD_STATUS_GRAPH_ENROLLED)!.value as boolean;
            if (this.childStatusGraphEnrolledShow && !this.statuses.includes(this.enrolledStatus)) {
                this.statuses.push(this.enrolledStatus);
            } else if (!this.childStatusGraphEnrolledShow) {
                this.statuses = this.statuses.filter(status => status.id !== this.enrolledStatus!.id);
            }
        }
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    private async getPendingFamilyData() {
        // check for status update
        if (!settingsStore.stored) {
            await settingsStore.init();
        }
        this.childStatusGraphPendingShow = settingsStore.stored?.get(SettingNames.CHILD_STATUS_GRAPH_PENDING_OPPORTUNITIES)?.value as boolean ?? false;
        this.pendingFamilies = 0;
        if (this.childStatusGraphPendingShow) {
            const familyData = await familiesRepo.getPending(this.orgs[0]);
            for (const family of familyData) {
                // families with 0 children are assumed to have 1
                if (family.children.length === 0) {
                    this.pendingFamilies += 1;
                } else {
                    this.pendingFamilies += family.children.length;
                }
            }

        }
    }

    @Watch('orgs')
    async orgsUpdated() {
        this.updateEnrolledField();
        await this.updateOpportunitiesCounts();
    }

    async created() {
        await Promise.all([
            statusesStore.init(),
            settingsStore.init(),
            featuresStore.init()
        ]);

        this.childStatusGraphEnrolledShow = settingsStore.stored.get(SettingNames.CHILD_STATUS_GRAPH_ENROLLED)!.value as boolean;
        const allStatuses = statusesStore.statuses;

        for (const status of allStatuses) {
            if (status.id) {
                if (status.id === BaseStatuses.ENROLLED) {
                    this.enrolledStatus = status;
                } else if (!status.is_archive) {
                    this.statuses.push(status);
                }
            }
        }
        if (this.enrolledStatus && this.childStatusGraphEnrolledShow) {
            this.statuses.push(this.enrolledStatus);
        }

        if (this.orgs.length !== 0) {
            await this.updateOpportunitiesCounts();
        }
    }

    /**
     * If the v-if="isMounted" to render the chart after everything else is mounted, or widths get out of whack.
     * https://github.com/highcharts/highcharts-vue/issues/107
     */
    public async mounted() {
        await featuresStore.init();
        await settingsStore.init();
        this.chartOptions = Highcharts.merge(await getBaseBarChartOptions(), {
            chart: {
                height: '450px'
            },
            title: {
                align: 'left',
                margin: 30,
                text: 'Children by Status',
                style: {
                    fontFamily: 'Manrope, sans-serif',
                    fontSize: featuresStore.isLineLeaderEnroll ? '17px' : '22.5px',
                    fontWeight: featuresStore.isLineLeaderEnroll ? '600' : '500'
                },
                x: -10
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: 'Children By Status'
                    }
                }
            },
            plotOptions: {
                bar: {
                    colorByPoint: true
                }
            }
        });
        this.isMounted = true;
    }

    async updateOpportunitiesCounts() {
        loadingState.loadingIncrement(this.loadingKey);
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;
        let color = isLineLeaderEnroll ? LINE_LEADER_PURPLE : BASE_DARK_BLUE;
        if (settingsStore.hasWhiteLabel) {
            color = '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
        }
        const colors = getMonochromeColors(color, true);
        const opportunityChartSeries: any =
            {
                type: isLineLeaderEnroll ? 'column' : 'bar',
                name: this.org!.name,
                data: [],
                color: colors[0],
                dataLabels: {
                    enabled: isLineLeaderEnroll,
                    style: {
                        fontFamily: 'Manrope, sans-serif',
                        fontWeight: 'bold'
                    }
                },
                point: {
                    events: {}
                }
            };

        const statusIds = this.statuses.map((status) => { return status.id; });
        const queryPromise = statsRepo.queryStatuses({
            transitions_only: false,
            current_counts_only: true,
            status_ids: statusIds,
            org_ids: [this.orgs[0]]
        });
        const pendingPromise = this.getPendingFamilyData();
        await queryPromise;
        await pendingPromise;
        const statusCounts = statsRepo.getGroupedByCount();

        // we can probably just take the counts directly since the endpoint also sorts by status and fills
        // in 0 values, but just in case...
        for (const statusId of statusIds) {
            const count = statusCounts.filter((count) => {
                return count.id === statusId;
            });
            opportunityChartSeries.data.push(count ? count[0].total : 0);
        }

        if (this.childStatusGraphPendingShow) {
            opportunityChartSeries.data.unshift(this.pendingFamilies);
        }

        this.opportunityChartSeries = [opportunityChartSeries]; // reset data
        this.updateChart();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private updateChart() {
        if (!this.$refs.chart) {
            return;
        }
        const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;

        // Clear out previous series data. If we don't, things get weird...
        if ((this.$refs.chart as any).chart.series.length > 0) {
            while ((this.$refs.chart as any).chart.series.length > 0) {
                (this.$refs.chart as any).chart.series[0].remove(false);
            }
        }

        // Set the categories.
        (this.chartOptions.xAxis as XAxisOptions).categories = this.statuses.map(status => status.name);
        if (this.childStatusGraphPendingShow) {
            (this.chartOptions.xAxis as XAxisOptions).categories!.unshift('Pending');
        }

        // Set the disclaimer.
        if (isLineLeaderEnroll) {
            (this.chartOptions.xAxis as XAxisOptions).title = {
                text: 'Note: Families without any children in the system are assumed to have one child.'
            };
        } else {
            (this.chartOptions.yAxis as YAxisOptions).title = {
                text: 'Note: Families without any children in the system are assumed to have one child.'
            };
        }

        const gradientCount = this.opportunityChartSeries[0].data!.length;
        // Set the series data.
        this.chartOptions.series = this.opportunityChartSeries;

        // Set cursor to pointer and set color gradients
        if (!isLineLeaderEnroll && this.chartOptions?.plotOptions?.bar?.cursor) {
            this.chartOptions.plotOptions.bar.cursor = 'pointer';
            this.chartOptions.plotOptions.bar.colors = getMonochromeColorsWithLimits(LIGHTEST_BLUE, BASE_DARK_BLUE, gradientCount);
        } else if (isLineLeaderEnroll && this.chartOptions?.plotOptions?.column?.cursor) {
            this.chartOptions.plotOptions.column.cursor = 'pointer';
            if (settingsStore.hasWhiteLabel) {
                const color = '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
                this.chartOptions.plotOptions.column.colors = getMonochromeColors(color, true);
            } else {
                this.chartOptions.plotOptions.column.colors = getMonochromeColors(LINE_LEADER_PURPLE, true);
            }
        }

        // Update and force another redraw.
        (this.$refs.chart as any).chart.update(this.chartOptions, true, true);
        (this.$refs.chart as any).chart.redraw(false);

        Highcharts.addEvent((this.$refs.chart as any).chart.series[0], 'click', (e: any) => {
            if (e.point && 'index' in e.point && typeof e.point.index === 'number') {
                const index = this.childStatusGraphPendingShow ? e.point.index - 1 : e.point.index;
                if (index !== -1) {
                    if (this.statuses[index].id === BaseStatuses.ENROLLED) {
                        this.$router.push({
                            name: 'families-filtered',
                            params: { statusFilter: 'enrolled' }
                        });
                        return;
                    }

                    familiesFiltersStore.setIsCustom(true);
                    const filterDto = getSimpleFilterDto([this.statuses[index].id], this.org ? this.org.id : 1);
                    familiesFiltersStore.applyAnonymousFilter(filterDto);
                    this.$router.push({
                        name: 'families-filtered',
                        params: { statusFilter: 'custom' }
                    });
                } else {
                    this.$router.push({
                        path: 'tasks/pending-family'
                    });
                }
            }
        });
    }
}
