
































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import PieChartExample from '@/components/examples/PieChartExample.vue';
import AreaChartExample from '@/components/examples/AreaChartExample.vue';
import LineChartExample from '@/components/examples/LineChartExample.vue';
import SemiCircleDonutChartExample from '@/components/examples/SemiCircleDonutChartExample.vue';
import BarChartExample from '@/components/examples/BarChartExample.vue';
import SparklineChartExample from '@/components/examples/SparklineChartExample.vue';
import GaugeChartExample from '@/components/examples/GaugeChartExample.vue';

@Component({
    components: { PieChartExample, AreaChartExample, LineChartExample, SemiCircleDonutChartExample, BarChartExample, SparklineChartExample, GaugeChartExample }
})
export default class ChartExamples extends Mixins(LocaleMixin) {
}
