import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type {
    CustomFieldCreateDto,
    CustomFieldUpdateDto,
    CustomFieldValueCreateDto, CustomFieldValueUpdateDto
} from '@/crm-types/custom-fields/custom-fields-types';
import { SortableEvent } from 'sortablejs';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

export interface CustomValueUpdate {
    field: CustomFieldCreateDto | CustomFieldUpdateDto;
    value?: CustomFieldValueCreateDto | CustomFieldValueUpdateDto;
    sortEvent?: SortableEvent;
    list?: Array<CustomFieldValueCreateDto| CustomFieldValueUpdateDto>;
}

/**
 * This store is for tracking state change related to custom fields/values across components.
 */
@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CUSTOM_FIELDS_SETTINGS
})
export class CustomFieldSettingsStore extends VuexModule {
    fieldAdditions: Set<CustomFieldCreateDto> = new Set();
    fieldUpdates: Set<CustomFieldUpdateDto> = new Set();
    fieldDeletions: Set<number> = new Set();
    valueAdds: Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<CustomFieldValueCreateDto>> = new Map();
    valueDeletes: Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<number>> = new Map();
    valueUpdates: Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<CustomFieldValueUpdateDto>> = new Map();

    public get addedFields(): Set<CustomFieldCreateDto> {
        return this.fieldAdditions;
    }

    public get deletedFields(): Set<number> {
        return this.fieldDeletions;
    }

    public get updatedFields(): Set<CustomFieldUpdateDto> {
        return this.fieldUpdates;
    }

    public get addedValues(): Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<CustomFieldValueCreateDto>> {
        return this.valueAdds;
    }

    public get deletedValues(): Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<number>> {
        return this.valueDeletes;
    }

    public get updatedValues(): Map<CustomFieldCreateDto | CustomFieldUpdateDto, Set<CustomFieldValueUpdateDto>> {
        return this.valueUpdates;
    }

    @Mutation
    clearState() {
        this.valueAdds.clear();
        this.valueUpdates.clear();
        this.valueDeletes.clear();
        this.fieldAdditions.clear();
        this.fieldDeletions.clear();
        this.fieldUpdates.clear();
    }

    /**
     * Add a value to a field
     */
    @Mutation
    public addValue(payload: CustomValueUpdate): void {
        if (!payload.value) {
            return;
        }
        const values = this.valueAdds.get(payload.field) ?? new Set();
        values.add(payload.value);
        this.valueAdds.set(payload.field, values);
    }

    /**
     * Delete a value from a custom field
     */
    @Mutation
    public deleteValue(payload: CustomValueUpdate): void {
        if (!payload.value) {
            return;
        }
        if (!payload.value.id) {
            // Make sure we don't add new values that they changed their mind about
            const adds = this.valueAdds.get(payload.field);
            if (adds && adds.has(payload.value)) {
                adds.delete(payload.value);
            }
            return;
        }

        // Did they make changes to a field and then delete it?
        // If so, remove it from the list of updates.
        const updates = this.valueUpdates.get(payload.field);
        if (updates && updates.has(payload.value as CustomFieldValueUpdateDto)) {
            updates.delete(payload.value as CustomFieldValueUpdateDto);
        }

        const deletes = this.valueDeletes.get(payload.field) ?? new Set();
        deletes.add(payload.value.id);
        this.valueDeletes.set(payload.field, deletes);
    }

    /**
     * Re-order values.
     */
    @Mutation
    public reorderValue(payload: CustomValueUpdate): void {
        if (!payload.sortEvent || !payload.list || payload.sortEvent.newIndex === undefined || payload.sortEvent.oldIndex === undefined) {
            return;
        }
        for (const value of payload.list) {
            if (value.order === payload.sortEvent.oldIndex) {
                const movedItem = payload.list.splice(payload.list.indexOf(value), 1)[0];
                movedItem.order = payload.sortEvent.newIndex;
                payload.list.splice(payload.sortEvent.newIndex, 0, movedItem);
                break;
            }
        }
        // Reset all the orders now
        for (const [i, value] of payload.list.entries()) {
            value.order = i;
            this.updateValue(payload);
        }
    }

    /**
     * Update a value
     */
    @Mutation
    public updateValue(payload: CustomValueUpdate): void {
        if (!payload.value) {
            return;
        }
        if (!payload.value.id) {
            this.addValue(payload);
            return;
        }
        const values = this.valueUpdates.get(payload.field) ?? new Set();
        values.add(payload.value as CustomFieldValueUpdateDto);
        this.valueUpdates.set(payload.field, values);
    }

    /**
     * Add a new field
     *
     * @param field
     */
    @Mutation
    public addField(field: CustomFieldCreateDto): void {
        this.fieldAdditions.add(field);
    }

    /**
     * Delete a field
     *
     * @param field
     */
    @Mutation
    public deleteField(field: CustomFieldUpdateDto | CustomFieldCreateDto): void {
        if (!field.id) {
            // Nothing to do; provided as a convenience to avoid type checks
            return;
        }
        this.fieldDeletions.add(field.id);
    }

    /**
     * Update a field
     *
     * @param field
     */
    @Mutation
    public updateField(field: CustomFieldUpdateDto | CustomFieldCreateDto): void {
        if (!field.id) {
            this.addField(field);
            return;
        }
        this.fieldUpdates.add(field as CustomFieldUpdateDto);
    }
}
