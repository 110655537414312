import { AuthStore } from '@/store/auth-store';
import { AxiosInstance } from 'axios';
import { createClient } from '@/transport/create-client';
import { baseUrl } from '@/core/base-url';
import { getModule } from 'vuex-module-decorators';
import { ReportMappingObject } from '@/reports/models/reports';
import store from '@/store';

const authState = getModule(AuthStore, store);

export class ReportsRepository {
    readonly endpoint = 'reports';

    public get url(): string {
        let currentUrl = baseUrl + '/api/v3';
        if (authState.isAssumingOtherCustomer) {
            currentUrl = `${baseUrl}/api/admin/v1/customers/${authState.currentCustomerDb}`;
        }
        return currentUrl;
    }

    private get client(): AxiosInstance {
        return createClient(this.url);
    }

    /**
     * Query mapping object from mapping url
     *
     * @param mappingUrl
     */
    public async getMapObjectFromUrl(mappingUrl: string): Promise<ReportMappingObject> {
        const response = await this.client.get(mappingUrl);
        return response.data;
    }
}
