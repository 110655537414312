var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-row',{staticClass:"text-h6 mb-2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-phone-classic ")]),_vm._v(" Call Recordings ")],1),(_vm.recordings.length)?_c('base-data-table',{staticClass:"truncated-columns",attrs:{"id":"call-recordings-table","headers":_vm.headers,"items":_vm.recordings,"no-data-text":"No Call Recordings","dense":"","disable-pagination":"","hide-default-footer":"","disable-sort":"","item-class":_vm.getRowClass},scopedSlots:_vm._u([{key:"item.no_call",fn:function(){return [_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-phone-off ")])]},proxy:true},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.getDirection(item)}},[_vm._v(" "+_vm._s(_vm.getDirection(item))+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.formatPhone(_vm.getPhone(item))}},[_vm._v(" "+_vm._s(_vm.formatPhone(_vm.getPhone(item)))+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.getName(item)}},[_vm._v(" "+_vm._s(_vm.getName(item))+" ")])]}},{key:"item.datetime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? _vm.formatDateTimeWithTimezone(value, _vm.userTimeZone) : '')+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDuration(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.listenToRecording(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-play ")])],1)],1)]}}],null,false,486532065)}):_c('div',{staticClass:"no-recordings"},[_vm._v(" No Call Recordings ")]),(_vm.showRecordingModal)?_c('recording-audio-modal',{attrs:{"recordings":_vm.selectedRecordings},model:{value:(_vm.showRecordingModal),callback:function ($$v) {_vm.showRecordingModal=$$v},expression:"showRecordingModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }