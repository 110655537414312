
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VBtn from 'vuetify/lib/components/VBtn/VBtn';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VBtn] });
export default base.extend({
    name: 'base-button-plain',
    props: {
        outlined: {
            type: Boolean,
            default: false
        },
        text: {
            type: Boolean,
            default: true
        },
        'min-width': {
            type: Number,
            default: 64
        },
        'min-height': {
            type: Number,
            default: 36
        }
    },
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VBtn.options.computed.classes.call(this),
                'base-button': true,
                'base-button-plain': true
            };
        }
    }
});
