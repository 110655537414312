
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VDialog from 'vuetify/lib/components/VDialog/VDialog';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VDialog] });
export default base.extend({
    name: 'base-dialog',
    props: {
        scrollable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        // add our CSS classes to the default vuetify classes
        classes(): object {
            return {
                ...VDialog.options.computed.classes.call(this),
                'base-dialog': true
            };
        }
    }
});
