






import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { doIdpLogin, checkUser, doOidcLogin } from '@/auth/auth';
import { LoginUtils } from '@/core/login-utils';
import { FeaturesStore } from '@/features/features-store';
import { Sso } from '@/auth/sso';
import { baseUrl } from '@/core/base-url';

const authState = getModule(AuthStore, store);
const featureState = getModule(FeaturesStore);

@Component
export default class SsoCode extends Vue {
    async created() {
        const oidcRoute = !!this.$route.query.oidc;
        if (!authState.oauthCodeVerifier && !oidcRoute) {
            return;
        }
        if (authState.oauthState !== this.$route.query.state && !oidcRoute) {
            return;
        }
        let loginResponse = null;
        if (!oidcRoute && authState.oauthCodeVerifier) {
            loginResponse = await doIdpLogin(String(this.$route.query.code), authState.oauthCodeVerifier);
        } else if (oidcRoute) {
            loginResponse = await doOidcLogin(String(this.$route.query.code));
        } else {
            return;
        }
        if (loginResponse?.success) {
            authState.storeAuthenticated(loginResponse);
            const userCheck = checkUser(loginResponse.userInfo!);
            if (userCheck.fullyLoggedIn) {
                authState.storeLoggedIn();
                await LoginUtils.setupCTranslate(Number(authState.currentCustomerDb ?? 0));

                if (authState.redirectUrl) {
                    await featureState.init();
                    await this.$router.push({ path: authState.redirectUrl as string });
                } else {
                    await featureState.init();
                    await this.$router.push({ name: 'home' });
                }
            } else {
                if (userCheck.redirectLegacy) {
                    authState.storeNeedsLegacyRedirect();
                    authState.clearNeedsLegacyRedirect();
                    window.location.href = Sso.getSsoLogoutUrl(authState, baseUrl);
                } else {
                    await this.$router.push({ name: userCheck.step });
                }
            }
        } else {
            window.location.href = Sso.getSsoUnauthorizedUrl();
        }
    }
}
