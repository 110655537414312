import type {
    EnrollmentCenterDetails,
    EnrollmentCenterSettings,
    EnrollmentCenterRateOption,
    EnrollmentCenterOfferingsRatings, EnrollmentCenterOfferingsRatingsOption
} from '@/enrollment-center/models/enrollment-center-models';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { EnrollmentCenterSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-settings-repository';

interface EnrollmentCenterSettingsState {
    settings: EnrollmentCenterSettings | null;
    detailsSettings: Array<EnrollmentCenterDetails>;
    ratings: Array<EnrollmentCenterOfferingsRatings>;
    rateOptions: Array<EnrollmentCenterRateOption>;
    offerings: Array<EnrollmentCenterOfferingsRatings>;
    mappedOfferingOptions: Map<number, EnrollmentCenterOfferingsRatingsOption>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.ENROLLMENT_CENTER_SETTINGS
})
export class EnrollmentCenterSettingsStore extends VuexModule implements EnrollmentCenterSettingsState {
    private repository = new EnrollmentCenterSettingsRepository();
    settings: EnrollmentCenterSettings | null = null;
    detailsSettings: Array<EnrollmentCenterDetails> = [];
    ratings: Array<EnrollmentCenterOfferingsRatings> = [];
    rateOptions: Array<EnrollmentCenterRateOption> = [];
    offerings: Array<EnrollmentCenterOfferingsRatings> = [];
    mappedOfferingOptions: Map<number, EnrollmentCenterOfferingsRatingsOption> = new Map();

    @Mutation
    storeSettings(settings: EnrollmentCenterSettings) {
        this.settings = settings;
    }

    @Action({ commit: 'storeSettings' })
    public async retrieve() {
        return await this.repository.getSettings();
    }

    @Action
    public async init() {
        if (this.settings === null) {
            await this.retrieve();
        }
    }

    @Mutation
    storeDetailsSettings(settings: Array<EnrollmentCenterDetails>) {
        this.detailsSettings = settings;
    }

    @Action({ commit: 'storeDetailsSettings' })
    public async retrieveDetails() {
        return await this.repository.getSettingsForDetails();
    }

    @Action
    public async initDetails() {
        if (this.detailsSettings.length === 0) {
            await this.retrieveDetails();
        }
    }

    @Mutation
    storeOfferingsSettings(settings: Array<EnrollmentCenterOfferingsRatings>) {
        this.offerings = settings;
        // Reset the map.
        this.mappedOfferingOptions.clear();
        this.offerings.forEach((entity: EnrollmentCenterOfferingsRatings) => {
            entity.options.forEach((option: EnrollmentCenterOfferingsRatingsOption) => {
                if (option.id) {
                    this.mappedOfferingOptions.set(option.id, option);
                }
            });
        });
    }

    // Get one from the store, by its identifier.
    @Action({ rawError: true })
    public async getOfferingById(id: number, forceRefresh = false): Promise<EnrollmentCenterOfferingsRatingsOption | undefined> {
        if (!forceRefresh && this.mappedOfferingOptions.has(id as number)) {
            return this.mappedOfferingOptions.get(id as number);
        }
        return await this.repository.getOfferingOption(id as number);
    }

    @Action({ commit: 'storeOfferingsSettings' })
    public async retrieveOfferings() {
        return await this.repository.getOfferings();
    }

    @Action
    public async initOfferings() {
        if (this.offerings.length === 0) {
            await this.retrieveOfferings();
        }
    }

    @Mutation
    storeRatings(items: Array<EnrollmentCenterOfferingsRatings>) {
        this.ratings = items;
    }

    @Action({ commit: 'storeRatings' })
    public async retrieveRatings() {
        return await this.repository.getRatings();
    }

    @Action
    public async initRatings() {
        if (this.ratings.length === 0) {
            await this.retrieveRatings();
        }
    }

    @Mutation
    storeRateOptions(rateOptions: Array<EnrollmentCenterRateOption>) {
        this.rateOptions = rateOptions;
    }

    @Action({ commit: 'storeRateOptions' })
    public async retrieveRateOptions() {
        return await this.repository.getRateOptions();
    }

    @Action
    public async initRateOptions() {
        if (this.rateOptions.length === 0) {
            await this.retrieveRateOptions();
        }
    }

    public get storedSettings(): EnrollmentCenterSettings | null {
        return this.settings;
    }

    public get storedOfferings(): Array<EnrollmentCenterOfferingsRatings> {
        return this.offerings;
    }
}
