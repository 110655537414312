


















































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { IntegrationPartnerIds, IntegrationPartners, IntegrationTypes } from '@/integrations/integration-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { IntegrationPartner, ServiceAccount } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { AuthStore } from '@/store/auth-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { Route } from 'vue-router';
import { Regions } from '@/locales/locales';
import { IntegrationStore } from '../store/integration-store';
import store from '@/store';
import IntegrationMappedClassrooms from '@/integrations/components/IntegrationMappedClassrooms.vue';
import MapLocations from '@/integrations/components/MapLocations.vue';
import ServiceAccountModal from '@/integrations/components/ServiceAccountModal.vue';
import FacebookLink from '@/integrations/components/FacebookLink.vue';
import ManageFacebook from '@/integrations/components/ManageFacebook.vue';
import ManageCcfTemplateVariables from '@/integrations/components/ManageCcfTemplateVariables.vue';
import MapCcfTemplateVariables from '@/integrations/components/MapCcfTemplateVariables.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import IntegrationService from '@/integrations/IntegrationService.ts';
import CurrentIntegrations from '@/integrations/views/CurrentIntegrations.vue';
import CmsIntegrationModal from '@/integrations/components/CmsIntegrationModal.vue';

const authState = getModule(AuthStore, store);
const integrationRepository = new IntegrationRepository();
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const facebookState = getModule(FacebookStore);
const integrationsState = getModule(IntegrationStore);

// Register a component route guard to prevent access, as needed
Component.registerHooks([
    'beforeRouteEnter'
]);

@Component({
    components: {
        CmsIntegrationModal,
        CurrentIntegrations,
        BaseClose,
        MapCcfTemplateVariables,
        ManageCcfTemplateVariables,
        ManageFacebook,
        FacebookLink,
        ServiceAccountModal,
        MapLocations,
        IntegrationMappedClassrooms
    }
})
export default class Integrations extends Mixins(LocaleMixin, BasicValidationMixin) {
    // This component just has a router-view so that nested children routes can load their components correctly
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const hasAccessMarketPlace = await staffUtils.getUserPermission(PermissionName.AutomationMarketplace);

        if (hasAccessMarketPlace) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private AUcheck = false;
    private ccfIntegrationToken = false;
    private ccfIntegrations: Array<string> = [];
    private loadingKey = 'integrations';
    private loadingKeyServices = 'serviceAccounts'
    private partnersList: Array<IntegrationPartner> = [];
    private showSnack = false;
    private snackText = 'Integration Partner Added';
    private UScheck=false;
    private integrationService: IntegrationService | null = null;
    private currentCmsPartners: Array<IntegrationPartner> = []; // We can have more than one!

    private cmsIntegrationToken = false;
    private cmsPartnerId = 0;
    private cmsPartner: IntegrationPartner | null = null;
    private primaryCmsPartner: IntegrationPartner | null = null;
    private primaryCmsPartnerId = 0;
    private apiUsername = '';
    private apiPassword = '';
    private apiKey = '';
    private apiCustomerID = '';
    private currentApiUsername = '';
    private currentApiPassword = '';
    private currentApiKey = '';
    private currentApiCustomerID = '';
    private showMapClassesModal = false;
    private integrationKey = 0;
    // Disable/enable save button when valid input
    private isDisabled = false;

    // CRM-13906: Support for multiple CMS integrations.
    private isSecondaryDisabled = false;
    private allowMultiCms = false;
    private hasMultiCms = false;
    private cmsIntegrationTokenSecondary = false;
    private cmsPartnerSecondary: IntegrationPartner | null = null;
    private cmsPartnerIdSecondary = 0;
    private secondaryCmsPartner: IntegrationPartner | null = null;
    private secondaryCmsPartnerId = 0;
    private apiUsernameSecondary = '';
    private apiPasswordSecondary = '';
    private apiKeySecondary = '';
    private apiCustomerIDSecondary = '';
    private currentApiUsernameSecondary = '';
    private currentApiPasswordSecondary = '';
    private currentApiKeySecondary = '';
    private currentApiCustomerIDSecondary = '';
    private showMapClassesModalSecondary = false;
    // END: CRM-13906: Support for multiple CMS integrations.

    private isMapLocationsOpen = false;
    private saveEvent = EventTypes.UPDATED;
    private closeEvent = EventTypes.CLOSE;
    private deleteServiceAccountEvent = EventTypes.DELETED;
    private CmsId = IntegrationTypes.CMS;
    private leadReferralId = IntegrationTypes.LEAD_REFERRAL;
    private callCenterTypeId = IntegrationTypes.CALL_CENTER;
    private referralToken = false;
    private referralServicePartner: IntegrationPartner | null = null;
    private showServiceAccountModal = false;
    private activeReferralServicesMap: Map<number, ServiceAccount> = new Map();
    private activeReferralServicesArray: Array<ServiceAccount> = [];
    private manageSAPartner: IntegrationPartner | null = null;
    private manageCurSA: ServiceAccount | null = null;
    private serviceAccountsUpdate = EventTypes.UPDATED;
    private closingSAModal = EventTypes.CLOSE;
    private referralServiceOptionsResponse: Array<IntegrationPartner> = [];
    private careComId = IntegrationPartnerIds.CARE_COM;
    private kangarooTimeId = IntegrationPartnerIds.KANGAROO_TIME;
    private manageId = IntegrationPartnerIds.MANAGE;
    private procareId = IntegrationPartnerIds.PROCARE;
    private qikkidsId = IntegrationPartnerIds.QIKKIDS;
    private showCcfIntegrations = false;
    private showManageCcfLinks = false;
    private showMapCcfLinks = false;
    private isFacebookLinkOpen = false;
    private isFacebookManageOpen = false;
    private locationMappingSaved = EventTypes.LOCATION_MAPPING_SAVED;
    private rolesMappingSaved = EventTypes.ROLES_MAPPING_SAVED;
    private cmsIntegrationChanged = EventTypes.SETTINGS_CHANGED;
    private cmsModalOpened = EventTypes.MODAL_OPENED;
    private showCmsIntegrationModal = false;
    private loader = loadingState;
    private region: Regions | null = null;
    private renderComponent = true;
    private integrationToEdit: IntegrationPartner | null = null;

    /**
     * Get the user setting state of classroom add-ons
     */
    private get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    private get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isFacebookIntegrationEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    private get hasEnrollmentCenter(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    private get hasFacebookPages(): boolean {
        return facebookState.entities.length > 0;
    }

    private get hasInContact() {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT);
    }

    private get inContactPartner() {
        return this.partnersList.filter((partner) => {
            return partner.name === IntegrationPartners.INCONTACT;
        })[0];
    }

    private get cmsPartnersList(): Array<IntegrationPartner> {
        const returnArray: Array<IntegrationPartner> = [];

        if (this.integrationService) {
            for (const partner of this.partnersList) {
                const partnerRegion = Regions[partner.region as keyof typeof Regions];

                if (
                    partner.name !== IntegrationPartners.PROCARE &&
                    partner.type.id === this.CmsId &&
                    this.integrationService.getPartnerInfo(partner.name) !== null &&
                    this.integrationService.getPartnerInfo(partner.name)?.show &&
                    partner.id !== this.primaryCmsPartnerId &&
                    partner.id !== this.secondaryCmsPartnerId &&
                    (
                        partner.region === null ||
                        (
                            this.region !== null &&
                            partnerRegion === this.region
                        )
                    )
                ) {
                    returnArray.push(partner);
                }
            }
        }

        // LineLeader Manage will lead this line.
        return returnArray.sort((a) => a.name === IntegrationPartners.MANAGE ? -1 : 1);
    }

    private get callCenterPartners() {
        return this.partnersList.filter((partner) => {
            return partner.type.id === this.callCenterTypeId && (!this.hasInContact || partner.name !== IntegrationPartners.INCONTACT);
        });
    }

    // Get Lead Source items
    private referralServiceOptions(): Array<IntegrationPartner> {
        const list = [];

        for (const partner of this.partnersList) {
            if (partner.type.id === IntegrationTypes.LEAD_REFERRAL && !this.activeReferralServicesMap.has(partner.id)) {
                if (partner.name === IntegrationPartners.CARE_FOR_KIDS && !this.AUcheck) {
                    continue;
                }
                if (partner.name === IntegrationPartners.CARE_COM && !this.UScheck) {
                    continue;
                }
                list.push(partner);
            }
        }

        if ((this.partnersList.length !== 0) && (list.length > 0)) {
            list.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.referralServicePartner = list[0];
        }

        return list;
    }

    /**
     * The list of CMS partners.
     */
    private get cmsPartners() {
        const list = [];
        if (this.region) {
            for (const partner of this.partnersList) {
                if (partner.type.id === IntegrationTypes.CMS) {
                    if (!this.UScheck &&
                        (partner.name === IntegrationPartners.PROCARE || partner.name === IntegrationPartners.MANAGE)) {
                        continue;
                    }

                    if (
                        !this.AUcheck &&
                        (
                            (partner.name === IntegrationPartners.QIKKIDS || partner.name === IntegrationPartners.KIDSOFT) ||
                            (partner.name === IntegrationPartners.PROCARE && this.primaryCmsPartner?.name !== IntegrationPartners.PROCARE)
                        )
                    ) {
                        continue;
                    }

                    if (partner.name === IntegrationPartners.ONCARE_OLD) {
                        partner.name = IntegrationPartners.ONECORE;
                    }

                    if (this.cmsIntegrationTokenSecondary) {
                        if (
                            (this.cmsPartnerId === this.procareId && partner.id === this.manageId) ||
                            (this.cmsPartnerId === this.qikkidsId && partner.id === this.kangarooTimeId)
                        ) {
                            list.push(partner);

                            if (partner.id === this.cmsPartnerIdSecondary) {
                                this.cmsPartnerSecondary = partner;
                            }
                        }
                    } else {
                        list.push(partner);

                        if (partner.id === this.cmsPartnerId) {
                            this.cmsPartner = partner;
                        }
                    }
                }
            }

            if (this.partnersList.length !== 0) {
                list.sort((a, b) => (a.name > b.name) ? -1 : 1);
                list.unshift({
                    id: 0,
                    name: 'None'
                });
            }
        }

        return list;
    }

    private get hasCurrent() {
        return this.activeReferralServicesMap.size !== 0 ||
            this.primaryCmsPartner ||
            this.ccfIntegrations.length !== 0 ||
            this.hasInContact ||
            (this.isFacebookIntegrationEnabled && this.hasFacebookPages);
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    /**
     * Whether the user is allowed to enable a CMS partner.
     */
    private get isCmsEnabled(): boolean {
        // Check if they have the CMS integrations add-on feature enabled.
        // If not, don't allow them to integrate with a partner.
        const features = featuresStore.entities;
        for (const feature of features) {
            if (feature.identifier === FeatureConstants.CMS_ADD_ON_ID) {
                if (feature.is_active) {
                    return true;
                }

                break;
            }
        }

        return false;
    }

    /**
     * Whether centers need to be mapped for the integration.
     */
    private get needsCentersMapped(): boolean {
        if (!this.isSuperUser) {
            return false;
        }

        return this.primaryCmsPartner === null
            ? false
            : this.primaryCmsPartner.needs_centers_mapped && this.primaryCmsPartnerId === this.cmsPartnerId;
    }

    /**
     * Whether classrooms need to be mapped for the integration.
     */
    private get needsClassroomsMapped(): boolean {
        return this.primaryCmsPartner === null ? false : this.primaryCmsPartner.needs_classrooms_mapped;
    }

    /**
     * Whether credentials are needed for the integration.
     */
    private get needsCredentials(): boolean {
        return this.cmsPartner === null ? false : this.cmsPartner.needs_credentials;
    }

    /**
     * Whether selected cmsPartner is Qikkids
     */
    private get isQikkids(): boolean {
        return this.cmsPartner === null ? false : this.cmsPartner.name === IntegrationPartners.QIKKIDS;
    }

    /**
     * Whether selected cmsPartner is Manage
     */
    private get isManage(): boolean {
        return this.cmsPartner === null ? false : this.cmsPartner.name === IntegrationPartners.MANAGE;
    }

    /**
     * Whether selected cmsPartner is Kangaroo Time
     */
    private get isKangarooTime(): boolean {
        return this.cmsPartner === null ? false : this.cmsPartner.name === IntegrationPartners.KANGAROO_TIME;
    }

    /**
     * Set the stored partner when changing the selected partner in the list.
     */
    @Watch('cmsPartnerId')
    private setCmsPartner() {
        // The save button needs to be disabled if credentials are required.
        if (this.cmsPartnerId === 0) {
            this.cmsPartner = null;
            this.checkSaveButton();
            return;
        }
        for (const partner of this.partnersList) {
            if (partner.type.id === IntegrationTypes.CMS && partner.id === this.cmsPartnerId) {
                this.cmsPartner = partner;

                if (this.primaryCmsPartnerId !== this.cmsPartnerId) {
                    if (this.needsCredentials) {
                        this.apiUsername = '';
                        this.apiPassword = '';
                        if (this.isQikkids) {
                            this.apiCustomerID = '';
                        }
                        if (this.isKangarooTime) {
                            this.apiKey = '';
                        }
                    }
                } else {
                    if (this.needsCredentials) {
                        this.apiUsername = this.currentApiUsername;
                        this.apiPassword = this.currentApiPassword;
                        if (this.isQikkids) {
                            this.apiCustomerID = this.currentApiCustomerID;
                        }
                        if (this.isKangarooTime) {
                            this.apiKey = this.currentApiKey;
                        }
                    }
                    if (this.isManage) {
                        this.apiCustomerID = this.currentApiCustomerID;
                    }
                }

                this.checkSaveButton();
                return;
            }
        }
    }

    /**
    * Set the stored partner when changing the selected partner in the list.
    */
    @Watch('primaryCmsPartnerId')
    private setPrimaryCmsPartner() {
        if (this.primaryCmsPartnerId === 0) {
            this.primaryCmsPartner = null;
            return;
        }

        for (const partner of this.partnersList) {
            if (partner.type.id === IntegrationTypes.CMS && partner.id === this.primaryCmsPartnerId) {
                this.primaryCmsPartner = partner;
                return;
            }
        }
    }

    @Watch('apiUsername')
    private setUsername() {
        this.checkSaveButton();
    }

    @Watch('apiPassword')
    private setPassword() {
        this.checkSaveButton();
    }

    @Watch('apiCustomerID')
    private setCustomerID() {
        this.checkSaveButton();
    }

    @Watch('apiKey')
    private setKey() {
        this.checkSaveButton();
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKeyServices);

        // if (this.isFacebookIntegrationEnabled) {
        //     await facebookState.init();
        // }
        if (authState.overrideCountry && Regions[authState.overrideCountry as keyof typeof Regions]) {
            this.region = Regions[authState.overrideCountry as keyof typeof Regions];
        } else {
            this.region = authState.userInfoObject
                ? Regions[authState.userInfoObject.country as keyof typeof Regions]
                : Regions.us;
        }

        await this.setInitial();

        loadingState.loadingDecrement(this.loadingKey);
        loadingState.loadingDecrement(this.loadingKeyServices);
    }

    private async addServiceAccount(partner: IntegrationPartner) {
        // If selected Integration is Care.com, pop the following modal
        if (partner.id === this.careComId) {
            const supportEmail = this.$t('supportEmail');
            await this.$swal({
                html: `<div style="text-align: left;">Please contact Customer Support to complete an integration with Care.com by one of the following <ul><li>Click the "Chat With Us" icon near the bottom of the navigation window</li><li>Email ${supportEmail}</li><li>Call 866-306-1985 option 2</li></ul></div>`,
                showCloseButton: true,
                showConfirmButton: false
            });
        } else {
            this.referralToken = false;
            this.$nextTick(() => {
                this.renderComponent = false;
                this.$forceUpdate();
                this.renderComponent = true;
                this.manageSAPartner = partner;
                this.manageCurSA = null;
                this.showServiceAccountModal = true;
            });
        }
    }

    private checkSaveButton() {
        if (this.isManage && !this.apiCustomerID) {
            this.isDisabled = true;
            return;
        }
        if (!this.needsCredentials) {
            this.isDisabled = false;
        } else {
            this.isDisabled = ((!this.isQikkids && !this.isKangarooTime) && (this.apiUsername === '' || this.apiPassword === '')) || (this.isQikkids && (this.apiUsername === '' || this.apiPassword === '' || this.apiCustomerID === '')) || (this.isKangarooTime && (this.apiKey === ''));
        }
    }

    private mapLocationsSaved() {
        this.snackText = 'Locations Mapping Saved';
        this.showSnack = true;
    }

    /**
     * When roles are saved, show a snack.
     */
    private mapRolesSaved() {
        this.snackText = 'Roles Mapping Saved';
        this.showSnack = true;
    }

    /**
     * Handle clicking on the button to integrate with a CMS partner.f
     *
     * @param {boolean} isSecondary
     */
    private handleEnableCms(isSecondary = false) {
        if (!this.isCmsEnabled) {
            this.$swal({
                text: 'Please contact ChildcareCRM using the chat feature to discuss upgrading your subscription to include Childcare Management System integration.',
                icon: 'info'
            });
        } else {
            this.cmsIntegrationToken = !isSecondary;
            this.cmsIntegrationTokenSecondary = isSecondary;
            this.showCmsIntegrationModal = true;
        }
    }

    private async confirmCallCenter() {
        const answer = await this.$swal({
            icon: 'warning',
            text: 'Warning! Enabling this integration without an active Skybox inContact account will cause your CRM+ system to malfunction. Do you have an active Skybox inContact account?',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });
        if (answer.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await integrationRepository.togglePartner({
                partner_id: this.inContactPartner.id,
                is_active: true
            });
            await featuresStore.retrieve();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private async updateServicesSection() {
        loadingState.loadingIncrement(this.loadingKey);
        this.renderComponent = false;
        await this.setInitial();
        await this.$nextTick();
        this.$forceUpdate();
        ++this.integrationKey;
        this.renderComponent = true;
        this.$forceUpdate();
        await this.$nextTick();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async setInitial(): Promise<void> {
        await integrationsState.init(true);
        if (!this.region) {
            if (authState.overrideCountry && Regions[authState.overrideCountry as keyof typeof Regions]) {
                this.region = Regions[authState.overrideCountry as keyof typeof Regions];
            } else {
                this.region = authState.userInfoObject
                    ? Regions[authState.userInfoObject.country as keyof typeof Regions]
                    : Regions.us;
            }
        }

        this.integrationService = new IntegrationService(this.region);

        const multiCmsInfo = await this.integrationService.getMultiCmsInfo(true);
        this.partnersList = await this.integrationService.getPartnerList();
        this.ccfIntegrations = await this.integrationService.getCcfIntegrations();
        this.currentCmsPartners = await this.integrationService.getActiveCmsPartners(true);
        this.allowMultiCms = multiCmsInfo.allowMultiCms;
        this.hasMultiCms = multiCmsInfo.hasMultiCms;

        if (this.currentCmsPartners.length > 0) {
            if (!this.allowMultiCms || !this.hasMultiCms) {
                this.setInitialCMSInfo(this.currentCmsPartners[0]);
                this.unsetInitialCMSInfoSecondary();
            } else if (this.allowMultiCms && this.hasMultiCms) {
                // If we have multiple CMS integrations, we need to set the initial values for both.
                // The cmsPartners are pre-sorted by if they are primary or secondary.
                this.setInitialCMSInfo(this.currentCmsPartners[0]);
                this.setInitialCMSInfoSecondary(this.currentCmsPartners[1]);
            }
        } else {
            this.unsetInitialCMSInfo();
            this.unsetInitialCMSInfoSecondary();
        }

        for (const serviceAccountObject of await this.integrationService.getServiceAccounts(true)) {
            if (serviceAccountObject && serviceAccountObject.partner && serviceAccountObject.partner.id) {
                this.activeReferralServicesMap.set(serviceAccountObject.partner.id, serviceAccountObject);
            }
        }
        this.activeReferralServicesArray = Array.from(this.activeReferralServicesMap.values());

        this.referralServiceOptionsResponse = await this.integrationService.getReferralServiceOptions();

        // Check if user is in the AU region.
        if (this.region === Regions.aus) {
            this.AUcheck = true;
        }

        // Check if user is in the US region
        if (this.region === Regions.us) {
            this.UScheck = true;
        }
    }

    private setInitialCMSInfo(integration: IntegrationPartner): void {
        this.cmsPartnerId = integration.id;
        this.primaryCmsPartnerId = integration.id;
        if (integration.credentials) {
            this.currentApiUsername = integration.credentials.username ? integration.credentials.username : '';
            this.currentApiPassword = integration.credentials.password ? integration.credentials.password : '';
            if (integration.credentials.customer_id) {
                this.currentApiCustomerID = integration.credentials.customer_id;
            }
            if (integration.credentials.api_key) {
                this.currentApiKey = integration.credentials.api_key;
            }
        }
    }

    /**
     * @param integration
     */
    private setInitialCMSInfoSecondary(integration: IntegrationPartner | null): void {
        this.cmsPartnerIdSecondary = integration?.id ? integration.id : 0;
        this.secondaryCmsPartnerId = integration?.id ? integration.id : 0;
        if (integration?.credentials) {
            this.currentApiUsernameSecondary = integration.credentials.username ? integration.credentials.username : '';
            this.currentApiPasswordSecondary = integration.credentials.password ? integration.credentials.password : '';
            if (integration.credentials.customer_id) {
                this.currentApiCustomerIDSecondary = integration.credentials.customer_id;
            }
            if (integration.credentials.api_key) {
                this.currentApiKeySecondary = integration.credentials.api_key;
            }
        }
    }

    private async saveCmsIntegration(data: any) {
        loadingState.loadingIncrement(this.loadingKey);
        this.renderComponent = false;
        await this.setInitial();
        await this.$nextTick();
        this.$forceUpdate();
        ++this.integrationKey;
        this.renderComponent = true;
        this.$forceUpdate();
        await this.$nextTick();
        loadingState.loadingDecrement(this.loadingKey);
        if (data && data.snackText) {
            this.snackText = data.snackText;
            this.showSnack = true;
        }
    }

    private unsetInitialCMSInfo(): void {
        this.cmsPartnerId = 0;
        this.primaryCmsPartnerId = 0;
        this.cmsPartner = null;
        this.primaryCmsPartner = null;
        this.currentApiUsername = '';
        this.currentApiPassword = '';
        this.currentApiCustomerID = '';
        this.currentApiKey = '';
    }

    private unsetInitialCMSInfoSecondary(): void {
        this.cmsPartnerIdSecondary = 0;
        this.secondaryCmsPartnerId = 0;
        this.cmsPartnerSecondary = null;
        this.secondaryCmsPartner = null;
        this.currentApiUsernameSecondary = '';
        this.currentApiPasswordSecondary = '';
        this.currentApiCustomerIDSecondary = '';
        this.currentApiKeySecondary = '';

    }

    private closeCmsModal() {
        this.cmsIntegrationToken = false;
        this.cmsIntegrationTokenSecondary = false;
        this.integrationToEdit = null;
        this.showCmsIntegrationModal = false;

    }

    /**
     * @param {Object}event
     * @param {IntegrationPartner}event.integrationToEdit
     * @param {boolean}event.isSecondary
     *
     * @private
     */
    private openCmsModal(event: { integrationToEdit: IntegrationPartner; isSecondary: boolean }) {
        this.integrationToEdit = event.integrationToEdit;
        this.cmsIntegrationTokenSecondary = event.isSecondary;
        this.showCmsIntegrationModal = true;
    }
}
