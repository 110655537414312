





























































































































































import { Child } from '@/families/models/child';
import { Family } from '@/families/models/family';
import { FamiliesStore } from '@/families/store/families-store';
import ManageStaffModal from '@/staff/components/ManageStaffModal.vue';
import store from '@/store';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import FamilySearch from '@/families/components/FamilySearch.vue';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import AddEditContactModal from '@/families/components/AddEditContactsModal.vue';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { getModule } from 'vuex-module-decorators';

const authState = getModule(AuthStore, store);
const familyStore = getModule(FamiliesStore, store);

@Component({
    components: { AddTaskModal, FamilySearch, StatusChangeSelect, CenterAscendingStaffList, AddEditContactModal, ManageStaffModal }
})
export default class Samples2 extends Mixins(LocaleMixin) {
    private selectItems = [
        { text: 'item a', value: 1 },
        { text: 'item b', value: 2 },
        { text: 'item c', value: 3 }
    ];

    private date = (new Date()).toISOString().substr(0, 10);
    private time = (new Date()).toISOString().substr(11, 5);
    private addTaskModal = false;
    private addEditContactModal = false;
    public localVar = [];
    private exampleRightDrawer = false;
    private centerStaff = null;

    private child: Child | null = null;
    private family: Family | null = null;
    private showStaffModal = false;

    async created() {
        const families = await familyStore.retrieve() as Array<Family>;
        for (const family of families) {
            if (family.children.length > 0 && family.primary_guardian.center_id === this.getCenterId()) {
                this.family = family;
                this.child = family.children[0];
                break;
            }
        }
    }

    centerStaffLoaded() {
        // do nothing
    }

    // Todo: Update when CRM-9712 is finished.
    private getCenterId(): number {
        return authState.userInfoObject?.center_id ? authState.userInfoObject.center_id : 1;
    }
};
