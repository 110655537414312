import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    InterfaceSetting,
    OrgInterfaceSettingUpdateDto
} from '@/dashboards/models/interface-settings-models';

export class InterfaceSettingsRepository extends AbstractRepository<InterfaceSetting> {
    readonly endpoint = 'settings/system';

    // Update the interface setting for the whole org.
    public async update(id: number, orgInterfaceSetting: OrgInterfaceSettingUpdateDto): Promise<InterfaceSetting> {
        return this.putOne(id, orgInterfaceSetting);
    }
}
