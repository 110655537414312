import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { MarketingCampaign, MarketingCampaignDto } from '@/marketing/models/marketing-campaigns-model';

export default class MarketingCampaignMapper extends AbstractMapper<MarketingCampaign> {
    toCreateDto(entity: MarketingCampaign): MarketingCampaignDto {
        return {
            code: entity.code ?? '',
            cost: entity.cost,
            description: entity.description ?? '',
            end_datetime: entity.end_datetime,
            name: entity.name ?? '',
            organization: entity.organization ? entity.organization.id : null,
            results_end_datetime: entity.results_end_datetime,
            start_datetime: entity.start_datetime,
            type: entity.type ? entity.type.id : null,
            utm_campaign: entity.utm_campaign ?? null
        };
    }

    toUpdateDto(entity: MarketingCampaign): MarketingCampaignDto {
        return this.toCreateDto(entity) as MarketingCampaignDto;
    }
}
