










































































































































































import ManageFields from '@/crm-types/components/ManageFields.vue';
import { CustomFieldEntityTypes } from '@/crm-types/custom-fields/custom-fields-types';
import { Field, FieldEntityType, FieldUpdateDto } from '@/crm-types/models/field-models';
import { FieldsRepository } from '@/crm-types/repositories/fields-repository';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    CrmTypeOption,
    CrmTypeOptionCreateDto, CrmTypeOptionsChangesInterface,
    CrmTypeOptionUpdateDto, CustomTypeLabelDto
} from '@/crm-types/models/crm-type';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import CustomFieldSettings from '@/families/components/CustomFieldSettings.vue';
import CustomFieldsService from '@/crm-types/custom-fields/custom-fields-service';
import { getCustomTypes, getCustomTypesDbName, getCustomTypesShown } from '@/crm-types/custom-types-utils';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import CustomTypesEdit from '@/crm-types/components/CustomTypesEdit.vue';
import TaskSettings from '@/tasks/components/TaskSettings.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import { StatusesStore } from '@/families/store/statuses-store';
import { BaseStatuses } from '@/constants/status-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';

const crmTypesStore = getModule(CrmTypesStore);
const fieldsRepository = new FieldsRepository();
const fieldsState = getModule(FieldsStore);
const loadingState = getModule(LoadingStore);
const typesRepo = new CrmTypesRepository();
const featuresStore = getModule(FeaturesStore);
const statusStore = getModule(StatusesStore);
const settingsStore = getModule(InterfaceSettingsStore);

@Component({
    components: {
        BaseClose,
        TaskSettings,
        CustomTypesEdit,
        CustomFieldSettings,
        ManageFields,
        SelectListEdit
    }
})
export default class SettingsFamilyHub extends Mixins(LocaleMixin, BasicValidationMixin) {

    private childFieldType = FieldEntityType.CHILD;
    private guardianFieldType = FieldEntityType.GUARDIAN;
    private loadingKey = 'familySettingsPopup';

    private customFieldsService = new CustomFieldsService();
    private customFieldsKey = 0;

    // auto lost opp
    private daysUntilStatusChange = 120;
    private autoStatusChangeToggle = false;
    private minimumDaysAllowed = 14;
    private lostOppName = '';
    private waitListName = '';
    private enrolledName = '';

    // Tracking Updates
    private guardianTypeOptionsChange: Array<CrmTypeOptionsChangesInterface> = [];
    private childTypeOptionsChange: Array<CrmTypeOptionsChangesInterface> = [];
    private guardianCustomTypeUpdates: Array<Array<CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto>> = [];
    private childCustomTypeUpdates: Array<Array<CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto>> = [];
    private guardianFieldUpdates: Array<FieldUpdateDto> = [];
    private childFieldUpdates: Array<FieldUpdateDto> = [];

    // Tracking Deletions
    private guardianCustomTypeDeletes: Array<Array<number>> = [];
    private childCustomTypeDeletes: Array<Array<number>> = [];

    // Panel indices
    private panelStandardGuardianIndex: number | undefined = -1;
    private panelStandardChildIndex: number | undefined = -1;
    private panelGuardianCustomTypesIndex: number | undefined = -1;
    private panelChildCustomTypesIndex: number | undefined = -1;
    private panelTasksIndex: number | undefined = -1;

    // custom types
    // the CrmTypeLists
    private shownGuardianCustomTypes: Array<number> = [];
    private shownChildCustomTypes: Array<number> = [];
    // actual select list data
    private guardianCustomTypeOptions: Array<Array<CrmTypeOption>> = [];
    private childCustomTypeOptions: Array<Array<CrmTypeOption>> = [];
    // used for labels/required
    private guardianCustomTypeFields: Array<Field> = [];
    private childCustomTypeFields: Array<Field> = [];
    // custom type specific updates
    private guardianCustomTypeNameUpdates: Array<string | null> = [];
    private childCustomTypeNameUpdates: Array<string | null> = [];
    private guardianCustomTypeRequiredUpdates: Array<boolean | null> = [];
    private childCustomTypeRequiredUpdates: Array<boolean | null> = [];

    private updatedEvent = EventTypes.UPDATED;
    private updatedNameEvent = EventTypes.NAME_UPDATED;
    private updatedRequiredEvent = EventTypes.REQUIRED_UPDATED;

    private guardianCustomEntityType = CustomFieldEntityTypes.GUARDIAN;
    private childCustomEntityType = CustomFieldEntityTypes.CHILD;

    private tasksSaveResolve: Function | null = null;
    private tasksSaveFlag = false;

    // validate days input
    areDaysValid(value: number): boolean | string {
        return !(this.autoStatusChangeToggle && value < this.minimumDaysAllowed);

    }

    get hasLostOpp() {
        return statusStore.storedStatuses.find(status => status.id === BaseStatuses.LOST_OPP);
    }

    // do we show the panel?
    get showGuardianCustomTypesPanel() {
        return this.shownGuardianCustomTypes.length > 0;
    }

    get showChildCustomTypesPanel() {
        return this.shownChildCustomTypes.length > 0;
    }

    // translate array indexes into CrmTypeLists
    get guardianCustomTypes() {
        return getCustomTypes('LEAD').filter((list, index) => {
            return this.shownGuardianCustomTypes.includes(index);
        });
    }

    get childCustomTypes() {
        return getCustomTypes('CHILD').filter((list, index) => {
            return this.shownChildCustomTypes.includes(index);
        });
    }

    // translate array indexes into db names for field matching
    get guardianCustomTypesDbNames() {
        return getCustomTypesDbName('LEAD').filter((list, index) => {
            return this.shownGuardianCustomTypes.includes(index);
        });
    }

    get childCustomTypesDbNames() {
        return getCustomTypesDbName('CHILD').filter((list, index) => {
            return this.shownChildCustomTypes.includes(index);
        });
    }

    async mounted() {
            loadingState.loadingIncrement(this.loadingKey);
            await this.refreshLists();
            loadingState.loadingDecrement(this.loadingKey);
    }

    private close() {
        this.panelStandardGuardianIndex = -1;
        this.panelStandardChildIndex = -1;
        this.panelGuardianCustomTypesIndex = -1;
        this.panelChildCustomTypesIndex = -1;
        this.panelTasksIndex = -1;
        this.customFieldsService.cancelChanges();
        this.customFieldsKey++;
        this.$emit(EventTypes.CLOSE);
    }

    /**
     * Refresh all of the data called from the API in this modal.
     */
    private async refreshLists() {
        const fieldsPromise = fieldsState.retrieveAll();
        const featuresPromise = featuresStore.init();
        const statusPromise = statusStore.init();
        const settingsPromise = settingsStore.init();

        await fieldsPromise;
        await featuresPromise;
        await statusPromise;
        await settingsPromise;

        // get the indexes
        this.shownGuardianCustomTypes = getCustomTypesShown(
            fieldsState.storedGuardianFields,
            'LEAD',
            featuresStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_12),
            featuresStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_34)
        );
        this.shownChildCustomTypes = getCustomTypesShown(
            fieldsState.storedChildFields,
            'CHILD',
            featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12),
            featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34)
        );
        // load our select lists
        const customTypesPromises = [];
        for (const list of this.guardianCustomTypes) {
            customTypesPromises.push(crmTypesStore.initList(list));
        }
        for (const list of this.childCustomTypes) {
            customTypesPromises.push(crmTypesStore.initList(list));
        }
        await Promise.all(customTypesPromises);
        // initialize our custom type arrays
        this.guardianCustomTypeOptions.splice(0);
        this.childCustomTypeOptions.splice(0);
        this.guardianCustomTypeUpdates.splice(0);
        this.childCustomTypeUpdates.splice(0);
        this.guardianCustomTypeDeletes.splice(0);
        this.childCustomTypeDeletes.splice(0);
        this.guardianCustomTypeNameUpdates.splice(0);
        this.childCustomTypeNameUpdates.splice(0);
        this.guardianCustomTypeRequiredUpdates.splice(0);
        this.childCustomTypeRequiredUpdates.splice(0);
        for (const list of this.guardianCustomTypes) {
            this.guardianCustomTypeOptions.push(crmTypesStore.listOptions(list));
            this.guardianCustomTypeUpdates.push([]);
            this.guardianCustomTypeDeletes.push([]);
            this.guardianCustomTypeNameUpdates.push(null);
            this.guardianCustomTypeRequiredUpdates.push(null);
        }
        for (const list of this.childCustomTypes) {
            this.childCustomTypeOptions.push(crmTypesStore.listOptions(list));
            this.childCustomTypeUpdates.push([]);
            this.childCustomTypeDeletes.push([]);
            this.childCustomTypeNameUpdates.push(null);
            this.childCustomTypeRequiredUpdates.push(null);
        }
        // grab our field data
        this.guardianCustomTypeFields.splice(0);
        this.childCustomTypeFields.splice(0);
        for (const i of this.shownGuardianCustomTypes) {
            this.guardianCustomTypeFields.push(fieldsState.storedGuardianFields.filter((field) => {
                return field.select_list_name === getCustomTypesDbName('LEAD')[i];
            })[0]);
        }
        for (const i of this.shownChildCustomTypes) {
            this.childCustomTypeFields.push(fieldsState.storedChildFields.filter((field) => {
                return field.select_list_name === getCustomTypesDbName('CHILD')[i];
            })[0]);
        }
        // set variables for automation lost opp setting
        if (this.hasLostOpp) {
            this.autoStatusChangeToggle = settingsStore.stored.get(SettingNames.AUTOMATION_MOVE_LOST_OPP)?.value as boolean ?? false;
            this.daysUntilStatusChange = settingsStore.stored.get(SettingNames.AUTOMATION_MOVE_LOST_OPP_DAYS)?.value as number ?? 120;
            this.lostOppName = statusStore.storedStatuses.find(status => status.id === BaseStatuses.LOST_OPP)!.name;
            this.waitListName = statusStore.storedStatuses.find(status => status.id === BaseStatuses.WAIT_LIST)!.name;
            this.enrolledName = statusStore.storedStatuses.find(status => status.id === BaseStatuses.ENROLLED)!.name;
        }

    }

    resolveTasksSave() {
        if (this.tasksSaveResolve) {
            this.tasksSaveResolve();
            this.tasksSaveResolve = null;
        }
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);

        // Save all changes to custom fields
        await this.customFieldsService.save();

        for (const typeOptionChange of this.guardianTypeOptionsChange) {
            while (typeOptionChange.deletedOptions.length > 0) {
                const id = typeOptionChange.deletedOptions.pop();
                if (id) {
                    await typesRepo.deleteListItem(id, typeOptionChange.crmTypeList);
                }
            }

            for (const item of typeOptionChange.updatedOptions) {
                if (item.id) {
                    const id = item.id;
                    delete item.id;
                    await typesRepo.updateListItem(id, item, typeOptionChange.crmTypeList);
                } else {
                    delete item.id;
                    await typesRepo.addListItem(item, typeOptionChange.crmTypeList);
                }
            }
        }

        for (const typeOptionChange of this.childTypeOptionsChange) {
            while (typeOptionChange.deletedOptions.length > 0) {
                const id = typeOptionChange.deletedOptions.pop();
                if (id) {
                    await typesRepo.deleteListItem(id, typeOptionChange.crmTypeList);
                }
            }

            for (const item of typeOptionChange.updatedOptions) {
                if (item.id) {
                    const id = item.id;
                    delete item.id;
                    await typesRepo.updateListItem(id, item, typeOptionChange.crmTypeList);
                } else {
                    delete item.id;
                    await typesRepo.addListItem(item, typeOptionChange.crmTypeList);
                }
            }
        }

        for (const [i, deletes] of this.guardianCustomTypeDeletes.entries()) {
            while (deletes.length > 0) {
                const id = deletes.pop();
                if (id) {
                    await typesRepo.deleteListItem(id, this.guardianCustomTypes[i]);
                }
            }
        }

        for (const [i, updates] of this.guardianCustomTypeUpdates.entries()) {
            for (const item of updates) {
                if (item.id) {
                    const id = item.id;
                    delete item.id;
                    await typesRepo.updateListItem(id, item, this.guardianCustomTypes[i]);
                } else {
                    delete item.id;
                    await typesRepo.addListItem(item, this.guardianCustomTypes[i]);
                }
            }
        }

        for (const [i, deletes] of this.childCustomTypeDeletes.entries()) {
            while (deletes.length > 0) {
                const id = deletes.pop();
                if (id) {
                    await typesRepo.deleteListItem(id, this.childCustomTypes[i]);
                }
            }
        }

        for (const [i, updates] of this.childCustomTypeUpdates.entries()) {
            for (const item of updates) {
                if (item.id) {
                    const id = item.id;
                    delete item.id;
                    await typesRepo.updateListItem(id, item, this.childCustomTypes[i]);
                } else {
                    delete item.id;
                    await typesRepo.addListItem(item, this.childCustomTypes[i]);
                }
            }
        }

        const fieldUpdatePromises = [];
        const nameUpdates = [];

        for (const [i, name] of this.guardianCustomTypeNameUpdates.entries()) {
            if (name) {
                const labelDto: CustomTypeLabelDto = {
                    identifier: this.guardianCustomTypesDbNames[i],
                    value: name
                };
                nameUpdates.push(typesRepo.updateCustomLabel(labelDto));
            }
        }

        for (const [i, name] of this.childCustomTypeNameUpdates.entries()) {
            if (name) {
                const labelDto: CustomTypeLabelDto = {
                    identifier: this.childCustomTypesDbNames[i],
                    value: name
                };
                nameUpdates.push(typesRepo.updateCustomLabel(labelDto));
            }
        }

        await Promise.all(nameUpdates);

        for (const [i, required] of this.guardianCustomTypeRequiredUpdates.entries()) {
            if (required !== null) {
                const fieldDto: FieldUpdateDto = {
                    is_client_required: required,
                    is_hidden: false
                };
                const field = this.guardianCustomTypeFields[i];
                fieldUpdatePromises.push(fieldsRepository.patchOne(field.id, fieldDto));
            }
        }

        for (const [i, required] of this.childCustomTypeRequiredUpdates.entries()) {
            if (required !== null) {
                const fieldDto: FieldUpdateDto = {
                    is_client_required: required,
                    is_hidden: false
                };
                const field = this.childCustomTypeFields[i];
                fieldUpdatePromises.push(fieldsRepository.patchOne(field.id, fieldDto));
            }
        }

        let id = 0;
        for (const item of this.guardianFieldUpdates) {
            id = item.id as number;
            delete item.id;
            fieldUpdatePromises.push(fieldsRepository.patchOne(id, item));
        }

        for (const item of this.childFieldUpdates) {
            id = item.id as number;
            delete item.id;
            fieldUpdatePromises.push(fieldsRepository.patchOne(id, item));
        }

        await Promise.all(fieldUpdatePromises);
        this.tasksSaveFlag = true;
        await new Promise(resolve => {
            this.tasksSaveResolve = () => {
                resolve();
            };
        });

        // save automation lost opp toggle and days
        if (this.hasLostOpp) {
            const lostOppPromises = [];
            lostOppPromises.push(settingsStore.updateSetting({
                settingId: settingsStore.stored.get(SettingNames.AUTOMATION_MOVE_LOST_OPP)!.id,
                dto: { value: this.autoStatusChangeToggle }
            }));
            if (this.autoStatusChangeToggle) {
                lostOppPromises.push(settingsStore.updateSetting({
                    settingId: settingsStore.stored.get(SettingNames.AUTOMATION_MOVE_LOST_OPP_DAYS)!.id,
                    dto: { value: this.daysUntilStatusChange }
                }));
            }
            await Promise.all(lostOppPromises);
        }

        // Refresh the store and the lists
        await this.refreshLists();
        this.$emit(EventTypes.UPDATED, 'Family Settings Updated');
        location.reload();
        this.panelStandardGuardianIndex = -1;
        this.customFieldsKey++;
        this.panelStandardChildIndex = -1;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private setChildTypeOptionsUpdates(updates: Array<CrmTypeOptionsChangesInterface>) {
        this.childTypeOptionsChange = updates;
    }

    private setGuardianTypeOptionsUpdates(updates: Array<CrmTypeOptionsChangesInterface>) {
        this.guardianTypeOptionsChange = updates;
    }

    private setChildFieldUpdates(updates: Array<FieldUpdateDto>) {
        this.childFieldUpdates = updates;
    }

    private setGuardianFieldUpdates(updates: Array<FieldUpdateDto>) {
        this.guardianFieldUpdates = updates;
    }

    private setGuardianCustomTypeUpdates(updates: { index: number; deletedItems: Array<number>; updatedItems: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto>}) {
        this.guardianCustomTypeUpdates[updates.index] = updates.updatedItems;
        this.guardianCustomTypeDeletes[updates.index] = updates.deletedItems;
    }

    private setChildCustomTypeUpdates(updates: { index: number; deletedItems: Array<number>; updatedItems: Array<CrmTypeOptionUpdateDto|CrmTypeOptionCreateDto>}) {
        this.childCustomTypeUpdates[updates.index] = updates.updatedItems;
        this.childCustomTypeDeletes[updates.index] = updates.deletedItems;
    }

    private setGuardianCustomTypeName(update: { index: number; name: string }) {
        this.guardianCustomTypeNameUpdates[update.index] = update.name;
    }

    private setChildCustomTypeName(update: { index: number; name: string }) {
        this.childCustomTypeNameUpdates[update.index] = update.name;
    }

    private setGuardianCustomTypeRequired(update: { index: number; required: boolean }) {
        this.guardianCustomTypeRequiredUpdates[update.index] = update.required;
    }

    private setChildCustomTypeRequired(update: { index: number; required: boolean }) {
        this.childCustomTypeRequiredUpdates[update.index] = update.required;
    }
}
