import { CrmApiElement } from '@/models/base';
import { GenericSortParameter } from '@/models/sort-parameter';
import { PendingFamily } from '@/families/models/family';
import { SelectListOption } from '@/core/models/select-list';
import { DataTableOptions } from '@/models/datatables';
import { AvatarValues } from '@/core/avatar-utils';
import { Center } from '@/organizations/locations/models/center';

export enum ActionItemsSortKey {
    GUARDIAN = 'guardian',
    TYPE = 'type',
    DATETIME = 'datetime',
    ASSIGNEE = 'assignee',
    LOCATION = 'location'
}

export type ActionItemsSortParameter = GenericSortParameter<ActionItemsSortKey>;

export enum ActionItemGroupType {
    PENDING = 'pending',
    TASK = 'task',
    MEETING = 'meeting',
    COMM = 'comm'
}

export enum ActionItemType {
    CALL = 'call',
    EMAIL = 'email',
    FACEBOOK = 'facebook',
    TEXT = 'text',
    TASK = 'task',
    PENDING = 'pending'
}

export interface ActionItem {
    item_type: ActionItemType;
    item_id: number;
    guardian_first_name: string;
    guardian_last_name: string;
    display_type: string;
    assignee_first_name: string;
    assignee_last_name: string;
    assignee_id: number | null;
    location_name: string;
    date_time: string;
    pending_family: PendingFamily | null;
    description: string | null;
    task_type_id: number | null;
    task_group_id: number | null;
}

export interface ActionItemResultCounts {
    pending?: number;
    task?: number;
    meeting?: number;
    comm?: number;
    total: number;
    items?: number;
    past?: number;
    today?: number;
    future?: number;
}

export interface ActionItemsResults extends CrmApiElement {
    items: Array<ActionItem>;
    counts: ActionItemResultCounts;
}

export enum ToDoDateRangeKey {
    ALL = 'all',
    FUTURE = 'future',
    NEXT7 = 'next7',
    TOMORROW = 'tomorrow',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    LAST7 = 'last7',
    PAST = 'past'
}

export const toDoDateRangeOptions: Array<SelectListOption> = [
    { value: ToDoDateRangeKey.ALL, text: 'All' },
    { value: ToDoDateRangeKey.FUTURE, text: 'Future' },
    { value: ToDoDateRangeKey.NEXT7, text: 'Next 7 Days' },
    { value: ToDoDateRangeKey.TOMORROW, text: 'Tomorrow' },
    { value: ToDoDateRangeKey.TODAY, text: 'Today' },
    { value: ToDoDateRangeKey.YESTERDAY, text: 'Yesterday' },
    { value: ToDoDateRangeKey.LAST7, text: 'Last 7 Days' },
    { value: ToDoDateRangeKey.PAST, text: 'All Past Due' }
];

export interface ToDoDateRange {
    startDate: string | null;
    endDate: string | null;
}

export type ToDoCountsType = Record<ActionItemGroupType, number>;

export class ToDoCounts implements ToDoCountsType {
    pending = 0;
    meeting = 0;
    comm = 0;
    task = 0;
}

export interface BulkDisplayItem {
    assignee: string;
    avatar: AvatarValues | null;
    description: string | null;
    guardian: string;
    item_id: number;
    item_key: string;
    item_type: ActionItemType;
    location: string;
    time: string;
    type: string;
    type_icon: string;
}

export type ActionTaskDate = 'past' | 'today' | 'future';
export type PendAndCommType = ActionItemGroupType.PENDING | ActionItemGroupType.COMM | null;

export interface ToDoDashboardState {
    onlyMine: boolean;
    pendAndCommType: PendAndCommType;
    pendAndCommPagination: DataTableOptions;
    taskDates: Array<ActionTaskDate>;
    tasksPagination: DataTableOptions;
    taskTypeFilter: number | null;
    taskMultiSelect: boolean;
    availabilityTabCurrentCenter: Center | null;
}

export enum BulkActionsTypes {
    ACCEPT_PENDING = 'Bulk Accept Pending Families',
    BULK_COMPLETE_ITEMS = 'Bulk Complete Items',
    BULK_CANCEL_ITEMS = 'Bulk Cancel Items',
    MARK_AS_READ_COMM = 'Bulk Update Comms as Read/Listened-to',
    REJECT_PENDING = 'Bulk Reject Pending Families',
    PAST_DUE_TOURS = 'Past Due Tours',
    PAST_DUE_MEETINGS = 'Past Due Meetings',
}
