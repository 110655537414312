




















































































































































































































import { EventTypes } from '@/constants/event-type-constants';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { formatDateWithTimezone, formatIsoDateTime, isoFormat } from '@/date-time/date-time-utils';
import MarketingCampaignMapper from '@/marketing/mappers/marketing-campaign-mapper';
import { MarketingCampaign, MarketingCampaignDto } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsRepository } from '@/marketing/repositories/marketing-campaigns-repository';
import { Org } from '@/models/organization/org';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import isEqual from 'lodash/isEqual';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const campaignMapper = new MarketingCampaignMapper();
const campaignsRepository = new MarketingCampaignsRepository();
const loadingState = getModule(LoadingStore);
const orgsState = getModule(OrgsStore);

@Component({
    components: {
        BaseClose,
        CrmTypeSelectList,
        SaveButton
    }
})
export default class ManageMarketingCampaign extends Mixins(LocaleMixin, BasicValidationMixin) {
    /**
     * The marketing campaign to edit.
     */
    @Prop({ default: null }) readonly campaign!: MarketingCampaign | null;
    /**
     * v-model whether we should show the modal
     */
    @Prop({ default: false }) readonly value!: boolean;

    @Ref('form') readonly form!: VForm;

    // Hold campaign data to be sent in the request
    private campaignDto: MarketingCampaignDto | null = null;
    // The deleted event
    private deletedEvent = EventTypes.DELETED;
    // The campaign end date
    private endDate: string | null = '';
    // The input event
    private inputEvent = EventTypes.INPUT;
    // Loading key
    private loadingKey = 'manageCampaign';
    // Key for refreshing the modal
    private modalKey = 0;
    // The campaign end date for results
    private resultsEndDate: string | null = '';
    // Whether or not to show the snackbar
    private showSnack = false;
    // The text for the snackbar
    private snackText = '';
    // The campaign start date
    private startDate: string | null = '';
    // Whether or not the form is valid
    private validForm = false;
    private marketingCampaignList = CrmTypeList.MARKTING_CAMPAIGNS;

    /**
     * Whether or not the a campaign is being added or edited.
     * A campaign is being added when there is no campaign in the prop.
     */
    private get isAdd() {
        return this.campaign === null;
    }

    /**
     * Whether a valid submission can be made.
     */
    private get isValid(): boolean {
        if (this.campaign) {
            return this.validForm && !isEqual(this.campaignDto, campaignMapper.toUpdateDto(this.campaign));
        }
        return this.validForm;
    }

    /**
     * Handles showing the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(this.inputEvent, showIt);
    }

    /**
     * The orgs to show in the "Apply To" list.
     */
    get orgs(): Array<Org> {
        return orgsState.stored;
    }

    /**
     * Handles operations when the modal is shown.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.modelValue) {
            this.campaignDto = campaignMapper.toUpdateDto(this.campaign ?? {} as MarketingCampaign);
            if (this.campaign) {
                this.startDate = this.campaign.start_datetime ? formatDateWithTimezone(this.campaign.start_datetime, authState.userTimeZone, isoFormat) : null;
                this.endDate = this.campaign.end_datetime ? formatDateWithTimezone(this.campaign.end_datetime, authState.userTimeZone, isoFormat) : null;
                this.resultsEndDate = this.campaign.results_end_datetime ? formatDateWithTimezone(this.campaign.results_end_datetime, authState.userTimeZone, isoFormat) : null;
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            }
        } else {
            this.startDate = null;
            this.endDate = null;
            this.resultsEndDate = null;
        }
    }

    /**
     * Set the end date with the correct timezone.
     */
    @Watch('endDate')
    private setEndDate() {
        if (this.modelValue) {
            if (this.endDate?.length) {
                this.campaignDto!.end_datetime = formatIsoDateTime(this.endDate as string, authState.userTimeZone, false);
            } else {
                this.campaignDto!.end_datetime = null;
            }
        }
    }

    /**
     * Set the results end date with the correct timezone.
     */
    @Watch('resultsEndDate')
    private setResultsEndDate() {
        if (this.modelValue) {
            if (this.resultsEndDate?.length) {
                this.campaignDto!.results_end_datetime = formatIsoDateTime(this.resultsEndDate as string, authState.userTimeZone, false);
            } else {
                this.campaignDto!.results_end_datetime = null;
            }
        }
    }

    /**
     * Set the start date with the correct timezone.
     */
    @Watch('startDate')
    private setStartDate() {
        if (this.modelValue) {
            if (this.startDate?.length) {
                this.campaignDto!.start_datetime = formatIsoDateTime(this.startDate as string, authState.userTimeZone, false);
            } else {
                this.campaignDto!.start_datetime = null;
            }
        }
    }

    /**
     * Component creation.
     */
    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const orgsPromise = orgsState.init();
        await orgsPromise;
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Handle deleting the campaign.
     */
    private async deleteCampaign() {
        await this.$swal({
            text: 'Are you sure you want to delete this marketing campaign?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                this.snackText = 'Marketing Campaign Deleted';
                await campaignsRepository.deleteBasic(this.campaign!.id);
                loadingState.loadingDecrement(this.loadingKey);
                this.showSnack = true;
                this.$emit(EventTypes.DELETED);
                this.handleClose();
            }
        });
    }

    /**
     * Handles closing the modal.
     */
    private handleClose() {
        this.form.reset();
        this.modelValue = false;
    }

    /**
     * Handle saving the campaign.
     */
    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        let eventType = EventTypes.ADDED;
        this.snackText = 'Marketing Campaign Added';
        if (this.campaignDto && !this.campaignDto.cost) {
            this.campaignDto.cost = null;
        }
        if (this.isAdd) {
            await campaignsRepository.post(this.campaignDto as MarketingCampaignDto);
        } else {
            await campaignsRepository.putOne(this.campaign!.id, this.campaignDto as MarketingCampaignDto);
            this.snackText = 'Marketing Campaign Edited';
            eventType = EventTypes.UPDATED;
        }

        loadingState.loadingDecrement(this.loadingKey);
        this.showSnack = true;
        this.$emit(eventType);
        this.handleClose();
    }

    /**
     * Handle setting the utm campaign to null when an empty value is present.
     */
    private setUtmCampaign() {
        if (this.modelValue && this.campaignDto!.utm_campaign === '') {
            this.campaignDto!.utm_campaign = null;
        }
    }

}
