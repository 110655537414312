
































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SelectListEdit from '@/crm-types/components/SelectListEdit.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import type { Family } from '@/families/models/family';
import { Child } from '@/families/models/child';
import {
    ReferFamilyInterface,
    TransferFamilyInterface
} from '@/families/models/linkReferTransfer';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const centersStore = getModule(CentersStore);
const familiesRepo = new FamiliesRepository();

@Component({
    components: {
        BaseClose,
        SelectListEdit
    }
})
export default class ReferTransferFamily extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly family!: Family;

    private loadingKey = 'referOrTransferFamily';
    private updatedEvent = EventTypes.UPDATED;
    private radioGroupSelection = 'Refer';
    private children: Array<Child> = [];
    private selectedChildren: Array<number> = [];
    private isDisabled = true;
    private locationSelect = -1;
    // For the snackbar when changes are made
    private isUpdated = false;
    private snackbarMessage = '';
    private centersSet = new Set();

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    // Centers available to refer to
    private get centers() {
        return centersStore.storedReferrableCenters.filter(
            center => center.is_active && center.id !== this.family.primary_guardian.center_id && !this.centersSet.has(center.id)
        );
    }

    private async created() {
        const centersPromise = centersStore.initReferrableCenters();
        await centersPromise;
        this.isDisabled = true;
    }

    @Watch('family')
    async checkFamily() {
        if (this.family) {
            this.children = this.family.children;
            this.centersSet.clear();
            if (this.family.linked_families) {
                for (const link of this.family.linked_families) {
                    if (link.center) {
                        this.centersSet.add(link.center.id);
                    }
                }
            }
        }
    }

    @Watch('radioGroupSelection')
    private watchRadioSelection() {
        if (this.radioGroupSelection === 'Transfer') {
            for (const child of this.children) {
                this.selectedChildren.push(child.id);
            }
        } else {
            this.selectedChildren = [];
        }
    }

    @Watch('selectedChildren', { deep: true })
    private watchChildSelections() {
        this.isDisabled = !(this.locationSelect !== -1);
    }

    @Watch('locationSelect')
    private watchLocationSelection() {
        this.isDisabled = !(this.locationSelect !== -1);
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        this.isDisabled = true; // No more changes; disable the button
        if (this.locationSelect !== -1) {
            try {
                if (this.radioGroupSelection === 'Refer') {
                    const referFamilyServiceObject: ReferFamilyInterface = { center_id: this.locationSelect, child_ids: this.selectedChildren };
                    await familiesRepo.referFamily(this.family.id, referFamilyServiceObject);
                    this.snackbarMessage = 'Family referred.';
                } else {
                    const transferFamilyServiceObject: TransferFamilyInterface = { center_id: this.locationSelect };
                    await familiesRepo.transferFamily(this.family.id, transferFamilyServiceObject);
                    this.snackbarMessage = 'Family transferred.';
                }

                if (this.radioGroupSelection === 'Transfer') {
                    this.locationSelect = -1;
                    this.selectedChildren = [];
                    loadingState.loadingDecrement(this.loadingKey);
                    // this.isUpdated = true;
                    this.close();
                    await this.$router.push({ name: 'families' });
                } else {
                    this.radioGroupSelection = 'Refer';
                    this.locationSelect = -1;
                    this.selectedChildren = [];
                    loadingState.loadingDecrement(this.loadingKey);
                    this.isUpdated = true;
                    this.close();
                    this.$emit(EventTypes.UPDATED);
                }
            } catch (e) {
                this.isDisabled = false;
                loadingState.loadingDecrement(this.loadingKey);
                await this.$swal({ text: 'Something went wrong. Please check if family already exists in that center.', icon: 'error' });
            }
        } else {
            this.isDisabled = false;
            loadingState.loadingDecrement(this.loadingKey);
            await this.$swal({ text: 'Something went wrong. Please check if location is valid.', icon: 'error' });
        }
    }

    private close() {
        this.modelValue = false;
    }
}
