
























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { LoadingStore } from '@/store/loading-store';
import { StatusesRepository } from '@/families/repositories/statuses-repository';
import { EventTypes } from '@/constants/event-type-constants';
import type { Status } from '@/families/models/status';
import BaseClose from '@/components/base/BaseClose.vue';

const statusStore = getModule(StatusesStore);
const loadingStore = getModule(LoadingStore);
const statusRepo = new StatusesRepository();
@Component({
    components: { BaseClose }
})
export default class DeleteStatusModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) readonly status!: Status;

    private targetStatus = 0;
    private loadingKey = 'deleteStatus';

    // Handles showing the modal
    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    private get statusOptions() {
        return statusStore.statuses.filter((status) => {
            return status.id !== this.status.id && this.status.to_statuses.map((link) => {
                return link.id;
                // if to be deleted status is child only, we can move to any it wants
                // a family status has to move to a family status
            }).includes(status.id) && (this.status.child_only || !status.child_only);
        });
    }

    private close() {
        this.modelValue = false;
    }

    private async doDelete() {
        loadingStore.loadingIncrement(this.loadingKey);
        await statusRepo.massUpdateStatus({ from: this.status.id, to: this.targetStatus });
        await statusRepo.deleteStatus(this.status.id);
        await statusStore.retrieveStatuses();
        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.STATUS_DELETED);
        this.close();
    }
}
