






















































































































































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Recording } from '@/communications/recordings/models/recording-models';
import { DataTableHeader } from 'vuetify';
import { RecordingUtils } from '@/communications/recordings/recording-utils';
import CallRecordingActionButtons from '@/communications/recordings/components/CallRecordingActionButtons.vue';
import { getModule } from 'vuex-module-decorators';
import { Family } from '@/families/models/family';
import { StatusesStore } from '@/families/store/statuses-store';
import { DataTableOptions } from '@/models/datatables';
import { EventTypes } from '@/constants/event-type-constants';
import { AuthMixin } from '@/auth/auth-mixin';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import RecordingAudioModal from '@/communications/recordings/components/RecordingAudioModal.vue';
import { AnyStoreUtils } from '@/utils/any-store-utils';

const recordingUtils = new RecordingUtils();
const statusStore = getModule(StatusesStore);
const anyStoreUtils = new AnyStoreUtils();

@Component({
    components: { CallRecordingActionButtons, RecordingAudioModal }
})
export default class InboxCallRecordingTable extends Mixins(AuthMixin, CrmBreakpointsMixin, LocaleMixin) {
    @PropSync('archiveMode') isArchiveMode!: number;
    @PropSync('tableOptions') options!: DataTableOptions;

    @Prop({ required: true }) readonly recordings!: Array<Recording>;
    @Prop({ required: true }) readonly count!: number;
    @Prop({ required: true }) families!: Array<Family>;
    @Prop({
        required: false,
        default: false
    }) readonly enrollmentTeamMode!: boolean;

    private selected: Array<Recording> = [];
    private shown: Array<Recording> = [];
    private played: Array<Recording> = [];
    private showRecordingModal = false;
    private searchString: string | null = null;

    get headers(): Array<DataTableHeader> {
        return [
            {
                text: '',
                value: 'do_not_call',
                width: '25px'
            },
            {
                text: '',
                value: 'phone'
            },
            {
                text: '',
                value: 'contact'
            },
            {
                text: '',
                value: 'family_status'
            },
            {
                text: '',
                value: 'time_stamp'
            },
            {
                text: '',
                value: 'duration'
            },
            {
                text: '',
                value: 'actions'
            }
        ];
    }

    get statuses() {
        return statusStore.statuses;
    }

    get localRecordings() {
        return this.recordings;
    }

    get selectedAndShown() {
        const selectedSet = new Set(this.selected);
        return [...new Set(this.shown)].filter((meta) => selectedSet.has(meta));
    }

    get inboxSearchString() {
        return anyStoreUtils.storedValue('inboxSearchString');
    }

    async created() {
        const statusResponse = statusStore.init();
        await statusResponse;
    }

    @Watch('isArchiveMode')
    modeChanged() {
        this.selected = [];
        this.options.page = 1;
    }

    @Watch('inboxSearchString', { immediate: true })
    setSearchString() {
        this.searchString = this.inboxSearchString;
    }

    formatSentDate(dueDateTime: string): string {
        return dueDateTime ? this.formatDateTimeWithTimezone(dueDateTime, this.userTimeZone) : '';
    }

    contactName(recording: Recording) {
        return recordingUtils.getPersonName(recording);
    }

    contactPhone(recording: Recording) {
        return recordingUtils.getPersonPhone(recording);
    }

    duration(recording: Recording) {
        return recordingUtils.getFormattedDuration(recording);
    }

    getStatus(recording: Recording) {
        // if no status return empty
        if (recording.opportunity_statuses.length < 1) {
            return '';
            // if just one status return its name
        } else if (recording.opportunity_statuses.length === 1) {
            return this.statuses.find(status => status.id === recording.opportunity_statuses[0])!.name;
            // if multiple status do all the things...
        } else if (recording.opportunity_statuses.length > 1) {
            // separate the archived ones from the rest because things get weird here...
            const archived = recording.opportunity_statuses.filter(status => status >= 6 && status !== 11);
            const active = recording.opportunity_statuses.filter(status => status < 6 || status === 11);
            let mostAdvanced = 1;
            // if both archived and active exist OR if only active exists, do not consider archived statuses
            if ((archived.length && active.length) || (!archived.length && active.length)) {
                for (const oppStatus of active) {
                    if (oppStatus === 11 && mostAdvanced < 3) {
                        mostAdvanced = oppStatus;
                    } else if (oppStatus !== 11 && (oppStatus > mostAdvanced || (oppStatus > 2 && mostAdvanced === 11))) {
                        mostAdvanced = oppStatus;
                    }
                }
                // if only archived exist, only use archived statuses.
            } else if (archived.length && !active.length) {
                for (const oppStatus of archived) {
                    if (oppStatus > mostAdvanced) {
                        mostAdvanced = oppStatus;
                    }
                }
            }
            return this.statuses.find(status => status.id === mostAdvanced)!.name;
        } else {
            return '';
        }
    }

    getCallStatus(recording: Recording) {
        if (recording.family) {
            return this.families.find(family => family.id === recording.family!.id)!.do_not_call;
        } else {
            return false;
        }
    }

    getRowClass(recording: Recording): string {
        if (this.enrollmentTeamMode) {
            return !recording.is_read_by_enrollment_team ? 'font-weight-bold' : '';
        }
        return !recording.is_read ? 'font-weight-bold' : '';
    }

    private clearSearch() {
        this.searchString = null;
        anyStoreUtils.setStoreValue({ key: 'inboxSearchString', val: this.searchString });
        this.$emit(EventTypes.INBOX_SEARCH_CLEARED);
    }

    private emitSearch() {
        if (!this.searchString) {
            this.$emit(EventTypes.INBOX_SEARCH_CLEARED);
            return;
        }
        if (this.searchString.length < 3) {
            // Do not search unless we have at least 3 characters
            return;
        }
        anyStoreUtils.setStoreValue({ key: 'inboxSearchString', val: this.searchString });
        // Threads are generated in a parent component
        this.$emit(EventTypes.INBOX_SEARCH, this.searchString);
    }

    private goToFamilyHub(item: Recording) {
        if (item.family) {
            this.$router.push({ name: 'family-hub', params: { id: item.family.id.toString() } });
        }
    }

    private markRecording(item: Recording) {
        if (this.enrollmentTeamMode) {
            if (item.is_read_by_enrollment_team) {
                this.$emit(EventTypes.MARK_UNREAD, item, false, this.localRecordings.indexOf(item));
            } else {
                this.$emit(EventTypes.INBOX_READ, item, true, this.localRecordings.indexOf(item));
            }
        } else {
            if (item.is_read) {
                this.$emit(EventTypes.MARK_UNREAD, item, false, this.localRecordings.indexOf(item));
            } else {
                this.$emit(EventTypes.INBOX_READ, item, true, this.localRecordings.indexOf(item));
            }
        }
    }

    private playRecording(item: Recording) {
        this.played = [item];
        this.showRecordingModal = true;
        this.$emit(EventTypes.INBOX_READ, item, true, this.localRecordings.indexOf(item));
    }

    private shownHandler(items: Array<Recording>) {
        this.shown = items;
    }

    private toggleArchivedSingle(item: Recording) {
        this.$emit(EventTypes.TOGGLE_ARCHIVED, [item], !!this.isArchiveMode);
    }

    private toggleArchivedSelected() {
        this.$emit(EventTypes.TOGGLE_ARCHIVED, this.selectedAndShown, !!this.isArchiveMode);
    }
}
