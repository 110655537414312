import { AbstractApiStore } from '@/store/abstract-api-store';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsRepository } from '@/crm-types/custom-fields/repositories/custom-fields-repository';
import { Action, Module } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CUSTOM_FIELDS
})
export class CustomFieldsStore extends AbstractApiStore<CustomField> {
    protected repository: CustomFieldsRepository = new CustomFieldsRepository();
    entities: Array<CustomField> = [];

    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.retrieveAll();
        }
    }

    public get stored(): Array<CustomField> {
        return this.entities;
    }

    /**
     * Get all stored child fields.
     */
    public get storedChildFields() {
        return this.entities.filter((field) => {
            return field.is_for_child;
        });
    }

    /**
     * Get all stored guardian fields.
     */
    public get storedGuardianFields() {
        return this.entities.filter((field) => {
            return field.is_for_lead;
        });
    }

    /**
     * Get all stored staff fields.
     */
    public get storedStaffFields() {
        return this.entities.filter((field) => {
            return field.is_for_staff;
        });
    }
}
