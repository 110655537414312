










































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import { IntegrationPartner, ServiceAccount } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { IntegrationPartners } from '@/integrations/integration-constants';
import { AuthStore } from '@/store/auth-store';
import ServiceAccountModal from '@/integrations/components/ServiceAccountModal.vue';
import store from '@/store';

const loadingState = getModule(LoadingStore);
const integrationRepository = new IntegrationRepository();
const authState = getModule(AuthStore, store);

@Component({
    components: { ServiceAccountModal }
})
export default class ServiceAccountsTable extends Mixins(LocaleMixin) {
    private loadingKey = 'serviceAccountsTable';
    private headers = [
        { text: 'User Name', value: 'username' },
        { text: 'Integration', value: 'partner.values.name' },
        { text: '', value: 'action', sortable: false }
    ];

    private items: Array<ServiceAccount> = [];
    private showServiceAccountModal = false;
    private serviceAccountSelection: ServiceAccount | null = null;
    private includePartnersList = true;
    private activeReferralServicesMap: Map<number, ServiceAccount> = new Map();
    private partnersList: Array<IntegrationPartner> = [];
    private serviceAccountsUpdate = EventTypes.UPDATED;
    private deleteServiceAccountEvent = EventTypes.DELETED;

    async created() {
        loadingState.loadingIncrement(this.loadingKey);

        await this.updateServicesSection();
        this.partnersList = await integrationRepository.getAllIntegrationPartners();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async deactivateServiceAccount(serviceAcc: ServiceAccount) {
        loadingState.loadingIncrement(this.loadingKey);

        if (serviceAcc) {
            await integrationRepository.deleteServiceAccount(serviceAcc.id);
            await this.updateServicesSection();
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async updateServicesSection() {
        loadingState.loadingIncrement(this.loadingKey);

        this.activeReferralServicesMap = new Map();
        this.items = await integrationRepository.getServiceAccounts();
        for (const serviceAccountObject of this.items) {
            if (serviceAccountObject && serviceAccountObject.partner && serviceAccountObject.partner.id) {
                this.activeReferralServicesMap.set(serviceAccountObject.partner.id, serviceAccountObject);
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private addServiceAccount() {
        this.serviceAccountSelection = null;
        this.includePartnersList = true;
        this.showServiceAccountModal = true;
    }

    private editServiceAccount(account: ServiceAccount) {
        this.serviceAccountSelection = account;
        this.includePartnersList = false;
        this.showServiceAccountModal = true;
    }

    private referralServiceOptions(): Array<IntegrationPartner> {
        const list: Array<IntegrationPartner> = [];

        for (const partner of this.partnersList) {
            if ((partner.region === null || partner.region === authState.userInfoObject?.country) && partner.needs_service_account && !this.activeReferralServicesMap.has(partner.id)) {
                if (partner.name === IntegrationPartners.CARE_FOR_KIDS && !(authState.userInfoObject?.country === 'aus')) {
                    continue;
                }
                if (partner.name === IntegrationPartners.CARE_COM) {
                    continue;
                }

                list.push(partner);
            }
        }

        if ((this.partnersList.length !== 0) && (list.length > 0)) {
            list.sort((a, b) => (a.name > b.name) ? 1 : -1);
        }

        return list;
    }

}
