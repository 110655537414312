import { EventTypes } from '@/constants/event-type-constants';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export class ModalMixin extends Vue {
    /**
     * v-model whether we should show it.
     */
    @Prop({ default: false }) readonly value!: boolean;

    /**
     * Handles when to show the modal.
     */
    public get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    public set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Close the modal.
     */
    public close() {
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }
}
