















































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Center } from '@/organizations/locations/models/center';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { Guardian } from '@/families/models/guardian';
import FamilyActionButtons from '@/families/components/new/FamilyActionButtons.vue';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { DataTableHeader } from 'vuetify';
import { EventTypes } from '@/constants/event-type-constants';
import FamilyDataInfoAddEditGuardian from '@/families/components/new/FamilyDataInfoAddEditGuardian.vue';

@Component({
    components: { FamilyDataInfoAddEditGuardian, FamilyActionButtons }
})
export default class FamilyDataInfoGuardians extends Mixins(FamilyHubMixin, LocaleMixin, CrmBreakpointsMixin) {
    // Props
    @Prop() readonly center!: Center | null;
    @Prop() readonly family!: Family;

    private deletedEvent = EventTypes.FAMILY_DELETED;
    private updatedEvent = EventTypes.UPDATED;
    private editedGuardian: Guardian | null = null;
    private isEditingGuardian = false;

    /**
     * Whether calls can be made to families in this family's center.
     */
    get canMakeCenterCalls(): boolean {
        if (!this.center) {
            return false;
        }
        return this.center.contact.crm_phone.number_e164 !== null;
    }

    get guardians() {
        return this.family.guardians.slice(0, 2);
    }

    get actionsWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '15rem';
            case 'md':
                return '16rem';
            case 'lg':
                return '16rem';
            case 'xl':
                return '17rem';
        }
    }

    get headers(): Array<DataTableHeader> {
        return [
            {
                text: 'Guardian Name',
                value: 'name',
                class: 'guardian-name'
            },
            {
                text: 'Relationship',
                value: 'relationship',
                width: this.relationshipWidth
            },
            {
                text: 'Phone',
                value: 'phone',
                width: this.phoneWidth
            },
            {
                text: 'Email',
                value: 'email',
                width: '30%'
            },
            {
                text: '',
                value: 'actions',
                width: this.actionsWidth
            }
        ];
    }

    get isDoNotCall() {
        return this.family.do_not_call;
    }

    get phoneWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '18ch';
            case 'md':
                return '20ch';
            case 'lg':
                return '22ch';
            case 'xl':
                return '24ch';
        }
    }

    get relationshipWidth() {
        switch (this.crmBreakpoint) {
            case 'sm':
                return '18ch';
            case 'md':
                return '20ch';
            case 'lg':
                return '22ch';
            case 'xl':
                return '24ch';
        }
    }

    private activityAdded() {
        this.$emit(EventTypes.UPDATED);
    }

    private editGuardian(guardian: Guardian | null) {
        this.editedGuardian = guardian;
        this.isEditingGuardian = true;
    }

    async familyDeleted() {
        await this.$router.push({ name: 'families' });
    }

    private familyUpdated() {
        this.$emit(EventTypes.UPDATED);
    }

    private isPrimaryGuardian(guardian: Guardian) {
        return guardian.id === this.family.primary_guardian.id;
    }

}
