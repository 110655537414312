

























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Child, ChildCreateDtoInterface } from '@/families/models/child';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import type { Family } from '../models/family';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { EventTypes } from '@/constants/event-type-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

const classesRepo = new ClassesRepository();
const enrollmentStore = getModule(EnrollmentsStore);
const loadingState = getModule(LoadingStore);

@Component
export default class SelectClassPickList extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly child!: Child | ChildCreateDtoInterface | null;
    @Prop() readonly family!: Family | null;
    @Prop() readonly centerId!: number;
    @Prop() value!: number | null;
    @Prop({ default: false }) readonly required!: boolean;

    private currClassId: number | null = null;

    // List of classrooms in location (Used when "Classes" on the "Add Ons" page is enabled)
    private classrooms: Array<{ id: number; name: string }> = [];

    private loadingKey = 'childClassInfo';

    get label() {
        return this.required ? '* Class' : 'Class';
    }

    get modelValue(): number | null {
        return this.value;
    }

    set modelValue(value: number | null) {
        this.$emit(EventTypes.INPUT, value);
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);

        // Load classrooms for pick list
        this.classrooms = [];
        if (this.family && this.family.primary_guardian) {
            const centerId = this.family.primary_guardian.center_id;
            const classroomsResponse = await classesRepo.retrieveAll(centerId as number);
            classroomsResponse.forEach((classroom) => {
                this.classrooms.push({
                    id: classroom.id,
                    name: classroom.name
                });
            });
        }

        if (this.child) {
            const pendingChange = enrollmentStore.pendingForChildId(this.child.id);
            if (pendingChange && pendingChange.classroom_id) {
                this.currClassId = pendingChange.classroom_id;
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('currClassId')
    private updateClass() {
        if (this.centerId) {
            this.modelValue = this.currClassId;
        }
        if (this.child && this.family) {
            const pendingChange = enrollmentStore.pendingForChildId(this.child.id);
            if (!pendingChange) {
                return;
            }
            if (this.currClassId) {
                pendingChange.classroom_id = this.currClassId;
                enrollmentStore.replaceForChildId({
                    childId: this.child.id,
                    enrollment: pendingChange,
                    familyId: this.family.id
                });
                this.$emit(EventTypes.UPDATED);
            }
        }
    }

    @Watch('centerId', { immediate: true })
    private async locationChanged() {
        if (this.centerId) {
            this.classrooms = [];
            const classroomsResponse = await classesRepo.retrieveAll(this.centerId);
            classroomsResponse.forEach((classroom) => {
                this.classrooms.push({
                    id: classroom.id,
                    name: classroom.name
                });
            });
        }
    }

    @Watch('modelValue')
    private modelUpdated() {
        if (this.modelValue !== this.currClassId) {
            this.currClassId = this.modelValue;
        }
    }
}
