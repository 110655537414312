



































import type { Family } from '@/families/models/family';
import { TaskGroups, TaskType, TaskTypes } from '@/tasks/models/task-models';
import { TaskGroupsStore } from '@/tasks/stores/task-groups-store';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList } from '@/crm-types/models/crm-type';

const taskGroupState = getModule(TaskGroupsStore);
const crmTypesStore = getModule(CrmTypesStore);

@Component
export default class TaskTypeSelect extends Mixins(LocaleMixin) {
    // Whether autofocus on the text area.
    @Prop({
        type: Boolean,
        default: false
    }) autofocus!: boolean;

    @Prop() isDisabled!: boolean;
    @Prop({ default: null }) taskType!: number | null;
    @Prop() family!: Family;
    @Prop() isMeeting!: boolean;
    @PropSync('isAddModalOpened') localAddModalValue!: boolean;
    @Prop({
        type: Boolean,
        default: false
    }) readonly emphasized!: boolean;

    private isCreated = false;
    private localTaskType = this.taskType;
    private taskTypeItems: Array<any> = [];
    private taskTypes: Array<TaskType> = [];
    private callTaskTypeItemIndexes: Array<number> = [];
    private emailTaskTypeItemIndexes: Array<number> = [];
    private textTaskTypeItemIndexes: Array<number> = [];

    async created() {
        await this.loadPickListData();
    }

    get hasLabel() {
        // only show label if add task modal is open and not on the family task list
        if (this.localAddModalValue) {
            return this.isMeeting ? 'Meeting Type *' : 'Task Type *';
        }
        return '';
    }

    @Watch('taskType')
    private updateLocalTaskType() {
        this.localTaskType = this.taskType;
    }

    @Watch('localTaskType')
    private emitChange() {
        this.$emit('input', this.taskTypes[this.localTaskType ?? -1]);
    }

    @Watch('localAddModalValue')
    async loadDataForAddModal() {
        if (this.localAddModalValue) {
            await this.loadPickListData();
        } else {
            this.taskTypeItems = [];
        }
    }

    private async loadPickListData() {
        await crmTypesStore.initList(CrmTypeList.TASKS);
        for (const taskType of crmTypesStore.listOptions(CrmTypeList.TASKS)) {
            this.taskTypes[taskType.id as number] = taskType;
        }
        // Get the task types and filter by group.
        // Also disable text and email task types when the lead has doNotText/Email enabled.
        await taskGroupState.init();
        taskGroupState.stored.forEach((taskGroupEntity) => {
            this.taskTypeItems.forEach((selectItem) => {
                selectItem.disabled = false;
            });

            if (taskGroupEntity.id === TaskGroups.TOURS || taskGroupEntity.id === TaskGroups.MEETINGS) {
                // Do not present tours & meetings as options if this isn't a meeting type
                if (!this.isMeeting) {
                    return;
                } else {
                    taskGroupEntity.task_types.forEach((taskType) => {
                        // Don't show hidden or inactive options
                        if ((taskType.values.is_hidden || !taskType.values.is_active) && taskType.id !== this.localTaskType) {
                            return;
                        }
                        this.taskTypeItems.push({
                            value: taskType.id,
                            text: taskType.values.value,
                            disabled: false
                        });
                    });
                }
            }

            if ((this.taskType === null || !this.taskType) && this.isMeeting) {
                for (const taskType of this.taskTypes) {
                    if (taskType && taskType.identifier === TaskTypes.TOUR) {
                        this.localTaskType = taskType.id;
                        break;
                    }
                }
            }

            if (this.isMeeting) {
                // For meetings, don't include any other task type options
                return;
            }

            // Add all non-meeting tasks to type select options
            taskGroupEntity.task_types.forEach((taskType) => {
                // Don't show hidden or inactive options
                if ((taskType.values.is_hidden || !taskType.values.is_active) && taskType.id !== this.localTaskType) {
                    return;
                }
                this.taskTypeItems.push({
                    value: taskType.id,
                    text: taskType.values.value,
                    disabled: false
                });
            });

            // Get all the task ids for phone calls
            if (taskGroupEntity.id === TaskGroups.PHONE_CALLS) {
                taskGroupEntity.task_types.forEach((taskType) => {
                    this.addTaskIdToIndex(taskType.id, this.callTaskTypeItemIndexes);
                });
            }

            if (taskGroupEntity.id === TaskGroups.EMAILS) {
                taskGroupEntity.task_types.forEach((taskType) => {
                    this.addTaskIdToIndex(taskType.id, this.emailTaskTypeItemIndexes);
                });
            }

            if (taskGroupEntity.id === TaskGroups.TEXTS) {
                taskGroupEntity.task_types.forEach((taskType) => {
                    this.addTaskIdToIndex(taskType.id, this.textTaskTypeItemIndexes);
                });
            }
        });
        this.isCreated = true;
        if (this.family) {
            this.updateAllowedTypes();
        }
    }

    private addTaskIdToIndex(taskId: number, indexList: Array<number>) {
        const index = this.taskTypeItems.findIndex(taskTypeItem => taskTypeItem.value === taskId);
        if (index > -1) {
            indexList.push(index);
        }
    }

    @Watch('family')
    private updateAllowedTypes() {
        this.taskTypeItems.forEach((selectItem) => {
            selectItem.disabled = false;
        });

        // Filter out task types by family (primary guardian) preference.
        if (this.family?.do_not_call) {
            this.callTaskTypeItemIndexes.forEach((index) => {
                this.taskTypeItems[index].disabled = true;
            });
        }

        if (this.family?.do_not_email) {
            this.emailTaskTypeItemIndexes.forEach((index) => {
                this.taskTypeItems[index].disabled = true;
            });
        }

        if (this.family?.do_not_text) {
            this.textTaskTypeItemIndexes.forEach((index) => {
                this.taskTypeItems[index].disabled = true;
            });
        }
    }
}
