




















import FamilyDataInfoNotes from '@/families/components/new/FamilyDataInfoNotes.vue';
import { Center } from '@/organizations/locations/models/center';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import type { Family } from '@/families/models/family';
import FamilyDataInfoChildTable from '@/families/components/new/FamilyDataInfoChildTable.vue';
import FamilyDataInfoGuardians from '@/families/components/new/FamilyDataInfoGuardians.vue';

@Component({
    components: { FamilyDataInfoGuardians, FamilyDataInfoChildTable, FamilyDataInfoNotes }
})
export default class FamilyDataInfo extends Mixins(LocaleMixin) {
    // Props
    @Prop() readonly center!: Center | null;
    @Prop() family!: Family;

    private childAddedEvent = EventTypes.CHILD_ADDED;
    private childDeletedEvent = EventTypes.CHILD_DELETED;
    private childEditedEvent = EventTypes.CHILD_EDITED;
    private updatedEvent = EventTypes.UPDATED;

    private childAdded(childId: number) {
        this.$emit(EventTypes.CHILD_ADDED, childId);
    }

    private childDeleted(childId: number) {
        this.$emit(EventTypes.CHILD_DELETED, childId);
    }

    private childEdited(childId: number) {
        this.$emit(EventTypes.CHILD_EDITED, childId);
    }

    /**
     * Emit an event to reload the family data.
     */
    private familyUpdated() {
        this.$emit(EventTypes.UPDATED);
    }
}
