export const activeStatusesPlaceholder = 'active-statuses-placeholder';
export const archiveStatusesPlaceholder = 'archive-statuses-placeholder';
export const tourMeetingTypesPlaceholder = 'tour-meeting-placeholder';

export enum RecordIdKeys {
    EMAIL = 'email_id',
    LEAD = 'lead_id',
    TEXT = 'text_id'
}

export enum FlexmonsterReportColumnTypes {
    DATE = 'date',
    DATETIME = 'datetime',
    DATE_STRING = 'date string',
    ID = 'id',
    NUMBER = 'number',
    STRING = 'string',
    TIME = 'time'
}

export enum ReportIdentifier {
    ALL_FAMILY = 'families-all-data',
    COMMUNICATIONS = 'communications',
    COMPLETED_TASKS = 'completed-tasks',
    CONVERSION_SUCCESS = 'conversion-success',
    CONVERSION_TIMING = 'conversion-timing',
    CONVERSION_TRACKING = 'conversion-success',
    CURRENT_OPPS = 'current-opportunities',
    ESTIMATED_REVENUE = 'estimated-revenue',
    FAMILY_STATUS = 'families',
    GUARDIAN_LIST = 'lead-list',
    GUARDIANS = 'guardians',
    LOCATIONS = 'locations',
    LOST_OPP = 'lost-opp',
    MARKETING_CAMPAIGNS = 'marketing-campaigns',
    MARKETING_SOURCE = 'marketing-source',
    MARKETING_CAMPAIGN_ANALYSIS = 'marketing-campaign-analysis',
    SCHEDULED_TASKS = 'scheduled-tasks',
    STAFF = 'staff',
    STAFF_SESSIONS = 'staff_sessions',
    STALLED_FAMILIES = 'stalled-families',
    STATUS_HISTORY = 'status-history',
    TASKS = 'tasks',
    TREND_TRACKING = 'trend-tracking',
    TOURS_MEETINGS = 'tours-meetings',
    WAIT_LIST = 'wait-list'
}

export enum StandardReportIdentifier {
    ESTIMATED_REVENUE_CHART = 'estimatedRevenueChart',
    ESTIMATED_REVENUE_SUMMARY = 'estimatedRevenueSummary',
    ESTIMATED_REVENUE_TABLE = 'estimatedRevenueTable',
    SOURCE_TABLE = 'sourceTable',
    SOURCE_CHART = 'sourceChart',
    SCHEDULED_TASK_TABLE = 'scheduledTasksTable',
    SCHEDULED_TASK_CHART = 'scheduledTasksChart',
    CURRENT_FAMILIES_TABLE= 'currentFamiliesTable',
    CURRENT_FAMILIES_SUMMARY= 'currentFamiliesSummary',
    CURRENT_FAMILIES_CHART = 'currentFamiliesChart',
    TASKS_TABLE= 'tasksTable',
    TASKS_CHART= 'tasksChart',
    LEAD_LIST_TABLE = 'leadListTable',
    LEAD_LIST_SUMMARY = 'leadListSummary',
    LEAD_LIST_GRAPH = 'leadListGraph',
    MARKETING_CAMPAIGNS_ENROLLED = 'marketingCampaignsEnrolled',
    MARKETING_CAMPAIGNS_LOCATION_SUM = 'marketingCampaignsLocationSum',
    MARKETING_CAMPAIGNS_SUMMARY = 'marketingCampaignsSummary',
    MARKETING_CAMPAIGNS_TABLE = 'marketingCampaignsTable',
    TOURS_MEETINGS_TABLE = 'toursMeetingsTable',
    TOURS_MEETINGS_SUMMARY = 'toursMeetingsSummary',
    CONVERSION_SUCCESS_NUMBERS = 'conversionSuccessNumbers',
    CONVERSION_SUCCESS_PERCENTAGES = 'conversionSuccessPercentages',
    CONVERSION_SUCCESS_BOTH = 'conversionSuccessNumbersAndPercentages',
}

export interface ReportDataSource {
    name: string;
}

export type ReportDataSources = Partial<Record<ReportIdentifier, ReportDataSource>>;

export const DataSources: ReportDataSources = {
    guardians: {
        name: 'Guardians'
    },
    tasks: {
        name: 'Tasks & Logs'
    },
    families: {
        name: 'Family & Status'
    },
    'status-history': {
        name: 'Status History'
    },
    'conversion-success': {
        name: 'Status Conversion'
    },
    'families-all-data': {
        name: 'All Family Data'
    },
    locations: {
        name: 'Locations'
    },
    communications: {
        name: 'Communications'
    },
    [ReportIdentifier.MARKETING_CAMPAIGNS]: {
        name: 'Marketing Campaigns'
    },
    staff: {
        name: 'Staff'
    },
    staff_sessions: {
        name: 'Staff Sessions'
    }
};

/**
 * Identifiers for report column names.
 * These need not be unique across reports.
 */
export enum ReportColumnIdentifiers {
    COST = 'cost',
    COST_0 = 'active_status_cost_0',
    COST_1 = 'active_status_cost_1',
    COST_2 = 'active_status_cost_2',
    COST_3 = 'active_status_cost_3',
    COST_4 = 'active_status_cost_4',
    COST_5 = 'active_status_cost_5',
    COST_6 = 'active_status_cost_6',
    COST_7 = 'active_status_cost_7',
    COST_8 = 'active_status_cost_8',
    COST_ENROLLED = 'enrolled_cost',
    COST_LOST_OPP = 'lost_opp_cost',
    REV_WEEKLY = 'weekly_enrolled_revenue',
    REV_MONTHLY = 'monthly_enrolled_revenue',
    REV_ANNUAL = 'annual_enrolled_revenue',
    REV_LIFETIME = 'lifetime_enrolled_revenue'
}

export const conversionTimingIdentifier = ReportIdentifier.CONVERSION_TIMING;
export const conversionSuccessIdentifier = ReportIdentifier.CONVERSION_SUCCESS;
export const trendTrackingIdentifier = ReportIdentifier.TREND_TRACKING;

export const nonDashReports = [conversionTimingIdentifier, conversionSuccessIdentifier, trendTrackingIdentifier];
