












































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { StandardReportIdentifier } from '@/reports/report-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { ConversionSuccessReportUtils } from '@/reports/conversion-success-report-utils';
import { formatDateForApi } from '@/date-time/date-time-utils';

const conversionSuccessReportUtils = new ConversionSuccessReportUtils();
const statusesStore = getModule(StatusesStore);

@Component
export default class ConversionSuccessReportSlot extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ default: null, required: true }) readonly selectedStandardReportType!: string | StandardReportIdentifier | null;

    private convertStatusDate = '';
    private isLoaded = false;
    private inputEvent = EventTypes.INPUT;
    private selectedStatus: Status | null = null;
    private statuses: Array<Status> = [];

    get hasPercentageCalculations(): boolean {
        return conversionSuccessReportUtils.hasPercentageCalculations(this.selectedStandardReportType);
    }

    @Watch('selectedStatus')
    private emitStatusSelected() {
        this.$emit(EventTypes.CONVERSION_SUCCESS_REPORT_STATUS_SELECTED, this.selectedStatus);
    }

    @Watch('hasPercentageCalculations')
    updateDefaultStatus() {
         this.selectedStatus = this.hasPercentageCalculations ? this.statuses[0] : null;
    }

    private emitDateInput() {
        this.$emit(EventTypes.CONVERSION_SUCCESS_REPORT_DATE_INPUT, this.convertStatusDate);
    }

    async mounted() {
        this.isLoaded = false;
        await statusesStore.init();
        // We show this combination of statuses in conversion success report
        this.statuses = statusesStore.activeStatuses;
        this.convertStatusDate = formatDateForApi(new Date());
        this.isLoaded = true;
    }
}
