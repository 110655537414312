import { FamilyDocument, FamilyDocumentCreateDto } from '@/families/models/documents';
import { AbstractRepository } from '@/repositories/abstract-repository';

export class FamilyDocumentsRepository extends AbstractRepository<FamilyDocument> {
    readonly endpoint = ''; // do not use

    public async createDoc(familyId: number, familyDocData: FamilyDocumentCreateDto, childId: number | null = null): Promise<FamilyDocument> {
        if (childId) {
            return (await this.client.post(`families/${familyId}/children/${childId}/documents`, familyDocData)).data;
        }
        return (await this.client.post(`families/${familyId}/documents`, familyDocData)).data;
    }

    public async retrieveAll(familyId: number): Promise<Array<FamilyDocument>> {
        return (await this.client.get(`families/${familyId}/documents`)).data;
    }

    public async retrieveFile(familyId: number, document: FamilyDocument): Promise<string> {
        // Note the response type; must be set or files will be corrupted
        return (await this.client.get(`families/${familyId}/documents/${document.id}/file`, { responseType: 'blob' })).data;
    }

    public async retrieveFileById(familyId: number, documentId: number): Promise<string> {
        // Note the response type; must be set or files will be corrupted
        return (await this.client.get(`families/${familyId}/documents/${documentId}/file`, { responseType: 'blob' })).data;
    }

    public async retrieveFileMetaDataById(familyId: number, documentId: number): Promise<FamilyDocument> {
        // Note the response type; must be set or files will be corrupted
        return (await this.client.get(`families/${familyId}/documents/${documentId}`)).data;
    }

    public async delete(familyId: number, document: FamilyDocument) {
        await this.client.delete(`families/${familyId}/documents/${document.id}`);
    }
}
