

































































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { DripCampaign } from '@/automation/drip-campaigns/models/drip-campaign';
import { StaffLink } from '@/staff/models/user';
import ViewDripCampaign from '@/automation/drip-campaigns/components/ViewDripCampaign.vue';
import { EventTypes } from '@/constants/event-type-constants';
import ManageDripCampaign from '@/automation/drip-campaigns/components/ManageDripCampaign.vue';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { OrgsStore } from '@/store/orgs-store';
import { Route } from 'vue-router';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';

const loadingState = getModule(LoadingStore);
const dripCampaignsState = getModule(DripCampaignsStore);
const authState = getModule(AuthStore, store);
const featuresStore = getModule(FeaturesStore);
const orgsStore = getModule(OrgsStore);
const staffUtils = new StaffUtils();

@Component({
    components: { ManageDripCampaign, ViewDripCampaign }
})
export default class DripCampaigns extends Mixins(LocaleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const hasAccessDripCampaigns = await staffUtils.getUserPermission(PermissionName.AutomationDripCampaign);
        const hasDripCampaigns = featuresStore.hasDripCampaigns;
        if (featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) && hasDripCampaigns && hasAccessDripCampaigns) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private loadingKey = 'dripCampaigns';
    private isAddOpen = false;
    private isViewOpen = false;
    private viewCampaignId = 0;
    private closeEvent = EventTypes.CLOSE;
    private updatedEvent = EventTypes.DRIP_CAMPAIGNS_UPDATED;
    private dripCampaigns: Array<DripCampaign> = [];

    get dripCampaignHeaders(): Array<DataTableHeader> {
        const headers = [
            { text: 'Campaign Name', value: 'name', width: '25%' },
            { text: 'Status', value: 'status.values.value', width: '10%' },
            { text: 'Last Edited', value: 'last_edited_datetime', width: '20%' },
            {
                text: 'Last Edited By',
                value: 'last_edited_by_staff',
                width: '15%',
                sort: (a: StaffLink | null, b: StaffLink | null) => {
                    return (a ? this.formatStaffName(a.values) : '').localeCompare(b ? this.formatStaffName(b.values) : '');
                }
            },
            {
                text: 'Organization Level',
                value: 'organization.values.name',
                width: '15%',
                sort: (a: string, b: string) => {
                    return a.localeCompare(b);
                }
            },
            { text: '', value: 'action', sortable: false, width: '10%' }
        ];
        if (!this.isFranchiseModeFeatureEnabled) {
            headers.splice(4, 1);
        }
        return headers;
    }

    get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get currentOrgId(): number {
        if (!this.isFranchiseModeFeatureEnabled) {
            return 1;
        } else {
            return authState.userInfoObject && authState.userInfoObject.org_id > 0 ? authState.userInfoObject.org_id : 1;
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const dripCampaignsPromise = dripCampaignsState.retrieveOrgDripCampaigns(this.currentOrgId);
        const featuresPromise = featuresStore.init();
        const orgsPromise = orgsStore.init();
        await dripCampaignsPromise;
        await featuresPromise;
        await orgsPromise;

        this.dripCampaigns = dripCampaignsState.orgDripCampaigns.get(this.currentOrgId) as Array<DripCampaign>;
        loadingState.loadingDecrement(this.loadingKey);
    }

    canEdit(dripCampaign: DripCampaign) {
        return this.currentOrgId === dripCampaign.organization.id;
    }

    closeAdd() {
        this.isAddOpen = false;
    }

    closeView() {
        this.isViewOpen = false;
        this.viewCampaignId = 0;
    }

    async dripCampaignsUpdate(dripCampaign: DripCampaign) {
        loadingState.loadingIncrement(this.loadingKey);
        await dripCampaignsState.retrieveOrgDripCampaigns(this.currentOrgId);
        this.dripCampaigns = dripCampaignsState.orgDripCampaigns.get(this.currentOrgId) as Array<DripCampaign>;
        loadingState.loadingDecrement(this.loadingKey);
        if (!this.isViewOpen) {
            this.openView(dripCampaign);
        }

    }

    formatLastEditedTime(campaign: DripCampaign) {
        return this.formatDateTime(campaign.last_edited_datetime, this.timezone);
    }

    formatLastEditedBy(campaign: DripCampaign) {
        return campaign.last_edited_by_staff ? this.formatStaffName(campaign.last_edited_by_staff.values) : '';
    }

    formatStatus(campaign: DripCampaign) {
        return campaign.status.values.value;
    }

    openAdd() {
        this.isAddOpen = true;
    }

    openView(campaign: DripCampaign) {
        this.closeView();
        if (campaign.id) {
            this.viewCampaignId = campaign.id;
            this.isViewOpen = true;
        }
    }
}
