import store from '@/store';
import { AnyStore, AnyStoreParams } from '@/store/any-store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import { getModule } from 'vuex-module-decorators';

const anyStore = getModule(AnyStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

export enum AnyStoreKeys {
    CAN_EDIT_FAMILY = 'canEditFamily',
    SHOW_OTHER_FAMILY_SNACKBAR = 'show-other-family-snackbar'
}

export class AnyStoreUtils {
    /**
     * Holds the current layout tab id. Needs to be static and non-reactive.
     */
    private currentLayoutTabId = layoutTabsStore.currentTabIdNumber;

    /**
     * Get the stored value for the key.
     */
    public get storedValue() {
        return (anyKey: string): any => {
            return anyStore.value(this.getKey(anyKey));
        };
    }

    /**
     * Set the store value.
     *
     * @param params
     */
    public setStoreValue(params: AnyStoreParams) {
        params.key = this.getKey(params.key);
        anyStore.setValue(params);
    }

    /**
     * Modify keys to include the current layout tab id. Don't use this externally.
     */
    private getKey(anyKey: string): string {
        return anyKey + '_' + this.currentLayoutTabId;
    }
}
