import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    LandingPage,
    LandingPageCreateDto, LandingPageOptionExclusion, LandingPageOptionExclusionDto,
    LandingPageSettings, LandingPageSettingsUpdateDtoInterface,
    LandingPageUpdateDtoInterface
} from '@/automation/landing-pages/models/landing-pages-models';
import { CenterLink } from '@/organizations/locations/models/center';
import { LandingPageFields, LandingPageFieldsDto } from '@/automation/landing-pages/models/landing-page-fields';
import { Logo, LogoDto } from '@/logo/models/logo';

export class LandingPagesRepository extends AbstractRepository<LandingPage> {
    readonly endpoint = 'forms';

    public async createPage(newPage: LandingPageCreateDto): Promise<LandingPage> {
        const response = await this.client.post<LandingPage>(this.endpoint, newPage);
        return response.data;
    }

    // Delete the logo for the given form
    public async deleteLogo(formId: number): Promise<void> {
        await this.client.delete<void>(`forms/${formId}/settings/logo`);
    }

    public async deletePage(id: number): Promise<void> {
        await this.client.delete(this.endpoint + '/' + id);
    }

    public async getFields(id: number): Promise<LandingPageFields> {
        const response = await this.client.get<LandingPageFields>(this.endpoint + '/' + id + '/fields');
        return response.data;
    }

    // Get Landing Page Locations
    public async getLocations(formId: number): Promise<Array<CenterLink>> {
        const response = await this.client.get(`forms/${formId}/centers`);
        return response.data;
    }

    // Get the logo for the given form
    public async getLogo(formId: number): Promise<Logo> {
        const response = await this.client.get<Logo>(`forms/${formId}/settings/logo`);
        return response.data;
    }

    /**
     * Get the options to exclude from the lists in the form.
     *
     * @param formId
     */
    public async getOptionExclusions(formId: number): Promise<Array<LandingPageOptionExclusion>> {
        const response = await this.client.get(`${this.endpoint}/${formId}/exclusions`);
        return response.data;
    }

    public async getPageSettings(id: number): Promise<LandingPageSettings> {
        const response = await this.client.get<LandingPageSettings>(this.endpoint + '/' + id + '/settings');
        return response.data;
    }

    public async getPstCenters(id: number): Promise<Array<CenterLink>> {
        const response = await this.client.get(`${this.endpoint}/${id}/centers`);
        return response.data;
    }

    // Set Landing Page Locations
    public async setLocations(formId: number, centerIds: Array<number>): Promise<Array<CenterLink>> {
        const response = await this.client.put(`forms/${formId}/centers`, { center_ids: centerIds });
        return response.data;
    }

    /**
     * Update the option exclusions for the given form id.
     *
     * @param formId
     * @param exclusionDto
     */
    public async updateOptionExclusions(formId: number, exclusionDto: LandingPageOptionExclusionDto): Promise<Array<LandingPageOptionExclusion>> {
        const response = await this.client.put(`${this.endpoint}/${formId}/exclusions`, exclusionDto);
        return response.data;
    }

    public async updateFields(id: number, updateData: LandingPageFieldsDto): Promise<LandingPageFields> {
        const response = await this.client.put<LandingPageFields>(this.endpoint + '/' + id + '/fields', updateData);
        return response.data;
    }

    public async updatePage(id: number, page: LandingPageUpdateDtoInterface): Promise<LandingPage> {
        const response = await this.client.put<LandingPage>(this.endpoint + '/' + id, page);
        return response.data;
    }

    public async updatePageSettings(id: number, newSettings: LandingPageSettingsUpdateDtoInterface): Promise<LandingPageSettingsUpdateDtoInterface> {
        const response = await this.client.put<LandingPageSettingsUpdateDtoInterface>(this.endpoint + '/' + id + '/settings', newSettings);
        return response.data;
    }

    // Upload a logo for the given form
    public async uploadLogo(formId: number, logoDto: LogoDto): Promise<Logo> {
        const response = await this.client.put<Logo>(`forms/${formId}/settings/logo`, logoDto);
        return response.data;
    }
}
