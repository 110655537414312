























































































































































































































































































































































// See: https://github.com/diegoazh/gmap-vue
// See: https://diegoazh.github.io/gmap-vue/
// See: https://diegoazh.github.io/gmap-vue/examples/
import { Component, Mixins, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center, CenterAgeRange, CenterFilters } from '@/organizations/locations/models/center';
import {
    getNearbyCenters, NearbyCenter,
    sortCenterByDistance
} from '@/organizations/locations/center-utils';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { LoadingStore } from '@/store/loading-store';
import { serverCountry } from '@/core/env-vars';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { AppStateStore } from '@/store/app-state-store';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EditMode } from '@/core/edit-modes';
import { EnrollmentCenterOfferingsRatings } from '@/enrollment-center/models/enrollment-center-models';
import { EnrollmentCenterLocationOfferingsStore } from '@/enrollment-center/store/enrollment-center-location-offerings-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { BrandsStore } from '@/organizations/brands/store/brands-store';

const loadingState = getModule(LoadingStore);
const centersStore = getModule(CentersStore);
const centersRepo = new CentersRepository();
const enrollmentCenterSettingsStore = getModule(EnrollmentCenterSettingsStore);
const locationOfferingsState = getModule(EnrollmentCenterLocationOfferingsStore);
const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore, store);
const featuresStore = getModule(FeaturesStore);
const brandsStore = getModule(BrandsStore);

interface CenterMarker {
    id?: number;
    marker?: google.maps.MarkerOptions;
}

interface InfoWindowData {
    center: Center | null;
    address: string | null;
    phone: string | null;
}

@Component({
    components: { }
})
export default class LocationMapMain extends Mixins(LocaleMixin, BasicValidationMixin) {
    @PropSync('tabMode') tabEditMode!: EditMode;

    $refs!: {
        mapRef: any;
    }

    private loadingKey = 'locationMapMain';
    private selectedLocation: Center | null = null;
    private isExpanded = false
    private google: any;
    private mapCenter: google.maps.LatLng | google.maps.LatLngLiteral | null = null;
    private centerFilters: CenterFilters = {
        brand_ids: []
    };

    private centerMarkers: Array<CenterMarker> = [];
    private shownCenterMarkers: Array<CenterMarker> = [];
    private infoWindowPos: google.maps.LatLng | google.maps.LatLngLiteral | null = null;
    private isLimitByOpenings = false;
    private ageRangeFilter: CenterAgeRange = {
        min: 0,
        max: 144
    }

    private infoWinOpen = false;
    private currentMarkerIndex = 0;
    private infoWindowData: InfoWindowData = {
        center: null,
        address: '',
        phone: ''
    }

    private accessibleCenters: Array<Center> = [];

    private infoOptions = {
        // content: '',
        // optional: offset info window so it visually sits nicely on top of our marker
        pixelOffset: {
            width: 0,
            height: -35
        }
    };

    private locationSelect: CenterMarker | null = null;

    private nearbyLocations: Array<NearbyCenter> = [];

    // Variables to deal with address searching.
    // Yes, that full on namespace is required, since we can't import the type...
    private mapStartPointAddress: string | null = null;
    private mapEndPointAddress: string | null = null;
    private mapStartPointResult: google.maps.places.PlaceResult | null = null;
    private mapEndPointResult: google.maps.places.PlaceResult | null = null;
    private mapStartPointMarker: google.maps.MarkerOptions | null = null;
    private mapEndPointMarker: google.maps.MarkerOptions | null = null;
    private directionsService: google.maps.DirectionsService | null = null;
    private directionsDisplay: google.maps.DirectionsRenderer | null = null;

    private offerings: Array<EnrollmentCenterOfferingsRatings> = [];
    private offeringOptionsFilter: Array<number> = [];
    private offeringOptionsItems: Array<any> = [];
    private showOptions = true;
    private tooltipContent: string | null = null;
    private isLoading = true;
    private scrollWidth = 0;
    private offsetWidth = 0;
    private cleared = false;
    // Getters and setters.
    // Restrict search to the current country.
    get autocompleteRestrictions() {
        let country = 'us';

        if (authState && authState.userInfoObject) {
            country = authState.overrideCountry ?? authState.userInfoObject.country ?? serverCountry ?? 'us';
        }

        return {
            country: [country === 'aus' ? 'au' : country]
        };
    }

    // Sort centers by distance from selected center
    get sortedCenters() {
        if (!this.selectedLocation) {
            return this.accessibleCenters;
        }

        return sortCenterByDistance(this.selectedLocation, this.accessibleCenters);
    }

    get minMaxRule() {
        return [
            (v: string | number) => (/^[0-9]+$/).test(String(v)) || !v || 'Please enter a valid whole number',
            this.ageRangeFilter.min < this.ageRangeFilter.max || 'Maximum Age must be larger than Minimum Age'
        ];
    }

    get offeringsChanged() {
        return locationOfferingsState.clearedCount;
    }

    get isBrandsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.BRANDS);
    }

    get brands() {
        return brandsStore.stored;
    }

    // Watches
    @Watch('mapStartPointAddress')
    private autoClearStartPoint() {
        if (!this.mapStartPointAddress || this.mapStartPointAddress.length === 0) {
            this.unsetMapStartPoint();
        }
    }

    @Watch('mapEndPointAddress')
    private autoClearEndPoint() {
        if (!this.mapEndPointAddress || this.mapEndPointAddress.length === 0) {
            this.unsetMapEndPoint();
        }
    }

    @Watch('tabEditMode')
    private async reloadData() {
        if (this.tabEditMode === EditMode.SAVING) {
            await this.loadData();
        }
    }

    @Watch('locationSelect')
    private watchLocationPickList() {
        if (this.locationSelect && this.locationSelect.id &&
            this.locationSelect?.marker?.position?.lat &&
            this.locationSelect?.marker?.position?.lng) {
            this.mapCenter = {
                lat: this.locationSelect.marker.position.lat as number,
                lng: this.locationSelect.marker.position.lng as number
            };
            this.infoWinOpen = false;
            this.onMarkerClick(this.locationSelect.id, this.locationSelect.marker, this.shownCenterMarkers.indexOf(this.locationSelect));
        }

    }

    @Watch('isLimitByOpenings')
    private async toggleOpeningsFilters() {
        if (!this.isLimitByOpenings) {
            delete this.centerFilters.has_availability_min;
            delete this.centerFilters.has_availability_max;
        }
        await this.applyFilters();
    }

    private async applyFilters() {
        // Work around to catch empty string since isNaN('') is false
        if (!this.isLoading) {
            const ageMinRange = parseInt(this.ageRangeFilter.min.toString());
            const ageMaxRange = parseInt(this.ageRangeFilter.max.toString());
            let filterCenters = this.accessibleCenters;
            if (this.isLimitByOpenings && !isNaN(ageMinRange) && !isNaN(ageMaxRange) && ageMinRange < ageMaxRange) {
                this.centerFilters.has_availability_min = ageMinRange;
                this.centerFilters.has_availability_max = ageMaxRange;
            }

            if (Object.keys(this.centerFilters).length !== 0) {
                const filterCentersIds = await centersRepo.filterCenters(this.centerFilters);
                filterCenters = filterCenters.filter(center => filterCentersIds.includes(Number(center.id)));
            }

            this.shownCenterMarkers = this.makeCenterMarkers(filterCenters);
            if (!this.shownCenterMarkers.includes(this.centerMarkers[this.currentMarkerIndex])) {
                this.currentMarkerIndex = -1;
                this.infoWinOpen = false;
            }
            this.updateNearbyLocations();
        }

    }

    // Refresh when the offering settings have changed.
    @Watch('offeringsChanged')
    private async refresh() {
        await enrollmentCenterSettingsStore.retrieveOfferings();
        await this.loadData();
    }

    @Watch('offeringOptionsFilter')
    private async updateOfferingOptionsFilter() {
        this.centerFilters.offering_ids = this.offeringOptionsFilter;
        const values: Array<string> = [];
        for (const group of this.offeringOptionsFilter) {
            const offering = await enrollmentCenterSettingsStore.getOfferingById(group);
            if (offering) {
                values.push(offering.label);
            }
        }

        this.tooltipContent = values.length > 0 ? values.join(', ') : null;
        if (document.getElementsByClassName('offering-selection')[0]) {
            if (this.cleared) {
                this.scrollWidth = this.offsetWidth;
                this.cleared = false;
            } else {
                this.offsetWidth = document.getElementsByClassName('offering-selection')[0].parentElement!.offsetWidth;
                this.scrollWidth = document.getElementsByClassName('offering-selection')[0].parentElement!.scrollWidth;
            }
        }
        await this.applyFilters();
    }

    // Life cycle callbacks.
    async created() {
        await this.loadData();
    }

    private async loadData() {
        this.isLoading = true;
        loadingState.loadingIncrement(this.loadingKey);
        this.isLimitByOpenings = false;
        this.offeringOptionsFilter = [];
        this.scrollWidth = 0;
        this.offsetWidth = 0;
        this.cleared = false;
        const promises = [];
        promises.push(centersStore.initAccessibleCenters());
        promises.push(centersStore.initAgeRange());
        promises.push(enrollmentCenterSettingsStore.init());
        promises.push(brandsStore.init());
        promises.push(enrollmentCenterSettingsStore.initOfferings());
        await Promise.all(promises);
        this.ageRangeFilter = centersStore.storedAgeRageForCenter as CenterAgeRange;
        this.accessibleCenters = centersStore.storedAccessibleCenters;
        this.selectedLocation = this.accessibleCenters[0];
        this.centerMarkers = this.makeCenterMarkers(this.accessibleCenters);
        this.offerings = enrollmentCenterSettingsStore.storedOfferings;
        this.offeringOptionsItems = this.convertToDisplayOfferingItems(this.offerings);
        this.shownCenterMarkers = this.centerMarkers;
        if (this.shownCenterMarkers.length > 0 &&
            this.shownCenterMarkers[0] &&
            this.shownCenterMarkers[0]?.marker?.position?.lat &&
            this.shownCenterMarkers[0]?.marker?.position?.lng
        ) {
            this.mapCenter = {
                lat: this.shownCenterMarkers[0].marker.position.lat as number,
                lng: this.shownCenterMarkers[0].marker.position.lng as number
            };
            this.locationSelect = this.shownCenterMarkers[0];
        } else {
            this.mapCenter = {
                lat: this.selectedLocation.contact.address.latitude as number,
                lng: this.selectedLocation.contact.address.longitude as number
            };
        }
        this.infoWindowPos = this.mapCenter;
        this.updateNearbyLocations();
        loadingState.loadingDecrement(this.loadingKey);
        this.isLoading = false;
    }

    // Methods
    private getCenterNameHeader(center: Center): string {
        let header = center.name;
        if (center.code && center.code.length > 0) {
            header = `${header} (${center.code})`;
        }

        return header;
    }

    private async doAddressSearch() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.mapStartPointResult && !this.mapEndPointResult) {
            // Single point to center the map on.
            this.mapStartPointMarker = {
                position: this.mapStartPointResult.geometry?.location ?? null
            };
            this.mapCenter = this.mapStartPointResult.geometry?.location ?? null;
        } else if (
            this.directionsService &&
            this.directionsDisplay &&
            this.mapStartPointResult &&
            this.mapStartPointResult.geometry &&
            this.mapEndPointResult &&
            this.mapEndPointResult.geometry
        ) {
            // Route 'em!
            // Make sure this marker is removed.
            this.mapStartPointMarker = null;
            const routeObj = {
                origin: this.mapStartPointResult.geometry.location as google.maps.LatLng,
                destination: this.mapEndPointResult.geometry.location as google.maps.LatLng,
                travelMode: this.google.maps.TravelMode.DRIVING
            };

            const routeRes = await this.directionsService.route(routeObj);
            this.directionsDisplay.setDirections(routeRes);
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private convertToDisplayOfferingItems(offerings: Array<EnrollmentCenterOfferingsRatings>): Array<any> {
        const optionsDisplay: Array<any> = [];
        for (const group of offerings) {
            optionsDisplay.push({ header: group.label });
            for (const option of group.options) {
                optionsDisplay.push(option);
            }
            optionsDisplay.push({ divider: true });
        }
        return optionsDisplay;
    }

    private makeCenterMarkers(centers: Array<Center>): Array<CenterMarker> {
        return centers.filter((center) => {
            return center.contact.address.latitude as number !== 0 &&
                center.contact.address.longitude as number !== 0;
        }).map((center) => {
            return {
                id: center.id,
                marker: {
                    position: {
                        lat: center.contact.address.latitude as number,
                        lng: center.contact.address.longitude as number
                    },
                    title: center.name + '(' + center.code + ')'
                }
            };
        });
    }

    // Do stuff when a marker is clicked.
    private async onMarkerClick(centerId: number, marker: google.maps.MarkerOptions, index: number) {
        loadingState.loadingIncrement(this.loadingKey);

        // Reset Data.
        this.infoWindowData = {
            center: null,
            address: '',
            phone: ''
        };

        if (marker && marker.position) {
            this.mapCenter = marker.position;
            this.infoWindowPos = marker.position;

            // Check if its the same marker that was selected, if yes toggle.
            if (this.currentMarkerIndex === index) {
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                // If different marker set info window to open and reset current marker index.
                this.infoWinOpen = true;
                this.currentMarkerIndex = index;
            }

            // Get and format some data for the info window.
            const center = await centersStore.getAccessibleCenterById(centerId);

            this.infoWindowData.center = center;
            this.infoWindowData.address = '';
            if (center.contact.address.address1) {
                this.infoWindowData.address += center.contact.address.address1 + ', ';
            }

            if (center.contact.address.locality) {
                this.infoWindowData.address += center.contact.address.locality + ', ';
            }

            if (center.contact.address.region) {
                this.infoWindowData.address += center.contact.address.region + ', ';
            }

            if (center.contact.address.postcode) {
                this.infoWindowData.address += center.contact.address.postcode;
            }

            if (center.contact.phone.number) {
                this.infoWindowData.phone = center.contact.phone.number;
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private onNearbyLocationClick(centerId: number) {
        this.currentMarkerIndex = -1;
        for (const marker of this.centerMarkers) {
            if (marker.id === centerId) {
                this.onMarkerClick(centerId, marker.marker as google.maps.MarkerOptions, this.centerMarkers.indexOf(marker));
            }
        }
    }

    private setMapStartPointResult(place: google.maps.places.PlaceResult | null) {
        this.mapStartPointResult = place;
    }

    private setMapEndPointResult(place: google.maps.places.PlaceResult | null) {
        this.mapEndPointResult = place;
    }

    private sendToLocInfo(center: Center) {
        appState.updateCurrentCenterEt(center);
        for (const location of this.centerMarkers) {
            if (appState.storedCurrentCenterEt && (location.id === appState.storedCurrentCenterEt.id)) {
                this.locationSelect = location;
                break;
            }
        }
    }

    private async toggleExpanded() {
        this.isExpanded = !this.isExpanded;

        if (this.isExpanded) {
            loadingState.loadingIncrement(this.loadingKey);
            this.$nextTick(() => {
                this.$refs.mapRef.$mapPromise.then(() => {
                    this.google = window.google;
                    if (!this.google) {
                        throw Error('Google not available at global level!');
                    }
                    this.directionsService = new this.google.maps.DirectionsService();
                    this.directionsDisplay = new this.google.maps.DirectionsRenderer();

                    if (!this.directionsService) {
                        throw Error('Direction Service not not initialized!');
                    }

                    if (!this.directionsDisplay) {
                        throw Error('Directions Renderer not initialized!');
                    }

                    if (!this.$refs.mapRef.$mapObject) {
                        throw Error('Map Object not initialized!');
                    }

                    this.directionsDisplay.setMap(this.$refs.mapRef.$mapObject);

                    /*
                    When the Map and Location Search card is expanded, default the select
                    list to the same location on the Location Information card, and default the map to that location.
                    * */
                    this.isLimitByOpenings = false;
                    this.centerFilters.brand_ids = [];
                    for (const location of this.centerMarkers) {
                        if (appState.storedCurrentCenterEt && (location.id === appState.storedCurrentCenterEt.id)) {
                            this.locationSelect = location;
                            this.watchLocationPickList();
                            break;
                        }
                    }
                    loadingState.loadingDecrement(this.loadingKey);
                });
            });
        } else {
            this.infoWinOpen = false;
        }
    }

    /**
     * When clearing out the the 2nd address box, this will clear out all the rest of the stuff.
     *
     * @private
     */
    private unsetMapEndPoint() {
        this.mapEndPointResult = null;
        this.mapEndPointMarker = null;

        if (this.directionsDisplay) {
            this.directionsDisplay.set('directions', null);
        }
    }

    /**
     * When clearing out the the 1st address box, this will clear out all the rest of the stuff.
     *
     * @private
     */
    private unsetMapStartPoint() {
        this.mapStartPointResult = null;
        this.mapStartPointMarker = null;

        if (this.directionsDisplay) {
            this.directionsDisplay.set('directions', null);
        }
    }

    /**
     * Update the list of nearby centers
     * @private
     */
    @Watch('currentMarkerIndex')
    private updateNearbyLocations() {
        if (this.currentMarkerIndex === -1) {
            this.nearbyLocations = [];
            return;
        }
        for (const marker of this.shownCenterMarkers) {
            if (marker.id === this.centerMarkers[this.currentMarkerIndex].id) {
                const center = this.accessibleCenters.find(center => center.id === marker.id) as Center;
                // Limit centers to map for nearby to only those that are in the current filters
                const availCenters = this.accessibleCenters.filter(
                    center => this.shownCenterMarkers.map(marker => marker.id).includes(center.id)
                );
                this.nearbyLocations = getNearbyCenters(center, availCenters, this.$i18n.locale);
                break;
            }
        }
    }
}
