import { AnyStoreKeys, AnyStoreUtils } from '@/utils/any-store-utils';
import { Component, Vue } from 'vue-property-decorator';
const anyStoreUtils = new AnyStoreUtils();
@Component
export class FamilyHubMixin extends Vue {
    /**
     * Whether the user is a corporate user
     */
    public get canEditFamily(): boolean {
        return anyStoreUtils.storedValue(AnyStoreKeys.CAN_EDIT_FAMILY) ?? false;
    }
}
