

























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsStaffNotificationDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { notifyStaffGroupId, workflowTimeUnits } from '@/automation/workflows/constants/workflow-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';

const loadingState = getModule(LoadingStore);
const crmTypesStore = getModule(CrmTypesStore);
const templatesStore = getModule(EmailTemplatesStore);

@Component
export default class WorkflowActionNotifyStaff extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsStaffNotificationDto;

    private loadingKey = 'manageWorkflow';
    private units = workflowTimeUnits;
    private staffGroups: Array<CrmTypeOption> = [];
    private sendTos: Array<CrmTypeOption> = [];
    private replyTos: Array<CrmTypeOption> = [];
    private templates: Array<MessageTemplate> = [];

    get settings(): WorkflowActionSettingsStaffNotificationDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsStaffNotificationDto) {
        this.$emit('input', settings);
    }

    get isStaffGroup() {
        return this.settings.send_to_type === notifyStaffGroupId;
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await templatesStore.initForOrg(1);
        this.templates = templatesStore.orgTemplates;
        await crmTypesStore.initList(CrmTypeList.STAFF_GROUPS);
        await crmTypesStore.initList(CrmTypeList.REPLY_TOS);
        await crmTypesStore.initList(CrmTypeList.STAFF_NOTIFY);
        this.staffGroups = crmTypesStore.listOptions(CrmTypeList.STAFF_GROUPS);
        this.replyTos = crmTypesStore.listOptions(CrmTypeList.REPLY_TOS);
        this.sendTos = crmTypesStore.listOptions(CrmTypeList.STAFF_NOTIFY);
        loadingState.loadingDecrement(this.loadingKey);
    }

}
