export enum ConversionTimingReportType {
    AVG = 1,
    PERIOD_COUNT = 2,
    PERIOD_PCT = 3
}

export enum ConversionTimingPeriodType {
    DAY = 1,
    HOUR = 2
}

export interface ConversionTimingPeriod {
    type: ConversionTimingPeriodType;
    from: number;
    to: number;
}

export interface ConversionTimingParameters {
    from_status: number;
    to_status: number;
    by_families: boolean;
    start_date: string;
    end_date: string;
    report_type: ConversionTimingReportType;
    periods: Array<ConversionTimingPeriod>;
}

export enum TrendTrackingReportType {
    STATUS = 1,
    INQUIRY = 2,
    SOURCE = 3,
    MARKETING = 4
}

export enum TrendTrackingPeriodType {
    DAY = 1,
    WEEK = 2,
    MONTH = 3,
    YEAR = 4
}

export interface TrendTrackingParameters {
    report_type: TrendTrackingReportType;
    start_date: string;
    period_type: TrendTrackingPeriodType;
    period_count: number;
    count_children: boolean;
}
