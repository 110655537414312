
























































import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowActionSettingsEmailDto, WorkflowCreateDto } from '../models/workflow-models';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    WorkflowTemplateType,
    workflowTemplateTypes
} from '@/automation/workflows/constants/workflow-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import WorkflowActionDaysBeforePartial from '@/automation/workflows/components/WorkflowActionDaysBeforePartial.vue';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const crmTypesStore = getModule(CrmTypesStore);
const featuresState = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const templatesStore = getModule(EmailTemplatesStore);

@Component({
    components: { WorkflowActionDaysBeforePartial, CrmTypeSelectList }
})
export default class WorkflowActionSendEmail extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsEmailDto;
    @Prop() readonly dto!: WorkflowCreateDto;

    private loadingKey = 'manageWorkflow';
    private templateTypes = workflowTemplateTypes;
    private templates: Array<MessageTemplate> = [];
    private replyTos: Array<CrmTypeOption> = [];
    private fromEmails: Array<CrmTypeOption> = [];
    private fromNames: Array<CrmTypeOption> = [];
    private templateGroupsEmailList = CrmTypeList.TEMPLATE_GROUPS_EMAIL;

    /**
     * Whether or not template groups are enabled.
     */
    get areTemplateGroupsEnabled(): boolean {
        return featuresState.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    get settings(): WorkflowActionSettingsEmailDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsEmailDto) {
        this.$emit('input', settings);
    }

    get isAutomatic() {
        return this.settings.template_method === WorkflowTemplateType.AUTOMATIC;
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresState.init();

        const templatesPromise = templatesStore.initForOrg(1);
        const replyPromise = crmTypesStore.initList(CrmTypeList.REPLY_TOS);
        const emailFromPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM);
        const emailFromNamesPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM_NAMES);
        const promises = [
            templatesPromise,
            replyPromise,
            emailFromPromise,
            emailFromNamesPromise
        ];
        if (this.areTemplateGroupsEnabled) {
            const templateGroupsPromise = crmTypesStore.initList(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
            promises.push(templateGroupsPromise);
        } else {
            this.settings.template_method = WorkflowTemplateType.SINGLE;
        }
        await Promise.all(promises);

        this.templates = templatesStore.orgTemplates;
        this.replyTos = crmTypesStore.listOptions(CrmTypeList.REPLY_TOS);
        this.fromEmails = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM);
        this.fromNames = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM_NAMES);
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('settings.template')
    async checkTemplate() {
        for (const template of this.templates) {
            if (template.id === this.settings.template) {
                if (template.has_bad_image_link) {
                    const confirm = await this.$swal({
                        icon: 'warning',
                        text: 'WARNING: the template you selected is missing a picture or video. You should remove the broken image (and replace it if desired) before sending this message.',
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Proceed Anyway'
                    });
                    if (confirm.isDismissed) {
                        this.settings.template = null;
                    }
                }
                return;
            }
        }
    }

}
