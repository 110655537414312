import haversine from 'haversine-distance';
import { Center, CenterHours, CommunicationHours } from '@/organizations/locations/models/center';
import { CommunicationHoursDto } from '@/organizations/locations/models/center-dto';

const METERS_IN_MILE = 1609.344;

export interface NearbyCenter {
    id: number;
    name: string;
    distance: string;
}

export function centerDistance(centerA: Center, centerB: Center): number {
    return haversine({ latitude: Number(centerA.contact.address.latitude), longitude: Number(centerA.contact.address.longitude) },
        { latitude: Number(centerB.contact.address.latitude), longitude: Number(centerB.contact.address.longitude) });
}

export function sortCenterByDistance(center: Center, centers: Array<Center>): Array<Center> {
    return [...centers].sort((centerA, centerB) => {
        const distanceA = centerDistance(center, centerA);
        const distanceB = centerDistance(center, centerB);
        // make sure center in question is always sorted to top of list
        if (centerA.id === center.id) {
            return -1;
        }
        if (centerB.id === center.id) {
            return 1;
        }
        // invalid center distances are put last
        if (isNaN(distanceA) && isNaN(distanceB)) {
            if (centerA.name.toLowerCase() < centerB.name.toLowerCase()) {
                return -1;
            }
            if (centerB.name.toLowerCase() < centerA.name.toLowerCase()) {
                return 1;
            }
            return 0;
        }
        if (isNaN(distanceA)) {
            return 1;
        }
        if (isNaN(distanceB)) {
            return -1;
        }
        if (distanceA > distanceB) {
            return 1;
        }
        if (distanceA < distanceB) {
            return -1;
        }
        if (centerA.name.toLowerCase() < centerB.name.toLowerCase()) {
            return -1;
        }
        if (centerB.name.toLowerCase() < centerA.name.toLowerCase()) {
            return 1;
        }
        return 0;
    });
}

/**
 * Gets a list of nearby centers with the distance formatted for the locale
 *
 * @param currentCenter
 * @param centers
 * @param locale
 */
export function getNearbyCenters(currentCenter: Center, centers: Array<Center>, locale: string): Array<NearbyCenter> {
    const locations = [];
    for (const center of sortCenterByDistance(currentCenter, centers)) {
        if (center.id === currentCenter.id) {
            // Do not show current center as nearby
            continue;
        }
        let distance = centerDistance(currentCenter, center);
        let distanceString = '';
        if (isNaN(distance) || distance > 30000) {
            // Skip bad geo-coded centers; we can't show them on the map anyway
            // Skip centers farther than 30km
            continue;
        }
        if (locale === 'en-US') {
            distance /= METERS_IN_MILE;
            distanceString = `${distance.toFixed(2)} mi`;
        } else {
            distance /= 1000; // convert from meters to kilometers
            distanceString = `${distance.toFixed(2)} km`;
        }
        locations.push({ id: center.id, name: center.name, distance: distanceString });
    }
    return locations;
}

/**
 * Return center with lowest id among the given centers
 *
 * @param centers
 */
export function getCenterWithLowestId(centers: Array<Center>): Center | null {
    if (!centers.length) {
        return null;
    }
    return centers.reduce((previousValue, currentValue) => {
        return previousValue.id < currentValue.id ? previousValue : currentValue;
    });
}

/**
 * Make sure we don't send blanks for hours
 * @param hours
 */
export function nullifyBlankHours(hours: CenterHours): void {
    for (const day of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
        for (const oc of ['open', 'close']) {
            const hourKey = (day + '_' + oc) as keyof CenterHours;
            hours[hourKey] = hours[hourKey] ? hours[hourKey] : null;
        }
    }
}

/**
 * reformat communications hours into center hours
 *
 * @param payload
 */
export function reformatCommHours(payload: CommunicationHoursDto): CenterHours {
    return {
        mon_open: payload.mon.start,
        mon_close: payload.mon.end,
        tue_open: payload.tue.start,
        tue_close: payload.tue.end,
        wed_open: payload.wed.start,
        wed_close: payload.wed.end,
        thu_open: payload.thu.start,
        thu_close: payload.thu.end,
        fri_open: payload.fri.start,
        fri_close: payload.fri.end,
        sat_open: payload.sat.start,
        sat_close: payload.sat.end,
        sun_open: payload.sun.start,
        sun_close: payload.sun.end
    };
}

/**
 * reformat center hours into comm hours
 *
 * @param payload
 */
export function reformatCenterHours(payload: CenterHours): CommunicationHours {
    const data = new CommunicationHoursDto();
    data.mon.start = payload.mon_open ? payload.mon_open : null;
    data.mon.end = payload.mon_close ? payload.mon_close : null;
    data.tue.start = payload.tue_open ? payload.tue_open : null;
    data.tue.end = payload.tue_close ? payload.tue_close : null;
    data.wed.start = payload.wed_open ? payload.wed_open : null;
    data.wed.end = payload.wed_close ? payload.wed_close : null;
    data.thu.start = payload.thu_open ? payload.thu_open : null;
    data.thu.end = payload.thu_close ? payload.thu_close : null;
    data.fri.start = payload.fri_open ? payload.fri_open : null;
    data.fri.end = payload.fri_close ? payload.fri_close : null;
    data.sat.start = payload.sat_open ? payload.sat_open : null;
    data.sat.end = payload.sat_close ? payload.sat_close : null;
    data.sun.start = payload.sun_open ? payload.sun_open : null;
    data.sun.end = payload.sun_close ? payload.sun_close : null;
    return data;
}
