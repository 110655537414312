import { StoreModuleTypes } from '@/constants/store-constants';
import { UserSettings } from '@/staff/models/user-settings-models';
import { UserSettingsRepository } from '@/staff/repositories/user-settings-repository';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

export interface UserSettingsState {
    settings: UserSettings | null;
    userId: number;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.USER_SETTINGS
})
export class UserSettingsStore extends VuexModule implements UserSettingsState {
    private repository = new UserSettingsRepository();
    settings: UserSettings | null = null;
    userId = 0;

    @Action
    public async init(userId: number) {
        if (this.userId !== userId) {
            await this.retrieveUserSettings(userId);
        }
    }

    @Action({ commit: 'storeUserSettings' })
    public async retrieveUserSettings(userId: number) {
        return {
            userId: userId,
            settings: await this.repository.get(userId)
        };
    }

    @Mutation
    public storeUserSettings(settingsMapping: UserSettingsState): void {
        this.settings = settingsMapping.settings;
        this.userId = settingsMapping.userId;
    }

    public get storedUserId(): number {
        return this.userId;
    }

    public get storedUserSettings(): UserSettings | null {
        return this.settings;
    }
}
