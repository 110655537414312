/**
 * This is for the 'all templates' endpoint. This is only GET.
 */
import { AbstractRepository } from '@/repositories/abstract-repository';
import { CopyTemplatesDto, MessageTemplate } from '@/communications/templates/models/message-template';

export class TemplatesRepository extends AbstractRepository<MessageTemplate> {
    readonly endpoint = 'communications/templates';

    public async copyTemplates(payload: CopyTemplatesDto): Promise<void> {
        await this.client.post(this.endpoint + '/copy', payload);
    }
}
