import { Status, StatusDto } from '@/families/models/status';
import { BaseMapper } from '@/core/mappers/base-mapper';

export class StatusMapper extends BaseMapper<Status> {
    toUpdateDto(entity: Status): StatusDto {
        return {
            name: entity.name,
            code: entity.code!,
            is_archive: entity.is_archive,
            is_optional: entity.is_optional,
            child_only: entity.child_only,
            order: entity.order,
            is_exported: entity.is_exported,
            from_statuses: this.linksToDto(entity.from_statuses),
            to_statuses: this.linksToDto(entity.to_statuses)
        };
    }

    toCreateDto(entity: Status): StatusDto {
        return this.toUpdateDto(entity);
    }

    createNewDto(): StatusDto {
        return {
            name: '',
            code: '',
            is_archive: false,
            is_optional: false,
            order: 0,
            child_only: true,
            is_exported: false,
            from_statuses: [],
            to_statuses: []
        };
    }
}
