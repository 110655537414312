































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import {
    EnrollmentCenterRateOption, EnrollmentCenterRateOptionCreateDto,
    EnrollmentCenterRateOptionUpdateDto,
    EnrollmentCenterSettings
} from '@/enrollment-center/models/enrollment-center-models';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { LoadingStore } from '@/store/loading-store';

const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class RatesSettings extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly settings!: EnrollmentCenterSettings | null;
    @Prop({ required: true }) readonly ratesSettingsInfo!: Array<EnrollmentCenterRateOption>;

    private ratesSwitch = false;
    private loadingKey = 'ratesSettings';
    private newDividerCounter = 0;
    private itemsRemoved: Array<number> = [];
    private headers: Array<DataTableHeader> = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: '',
            value: 'label',
            align: 'center'
        },
        {
            value: 'delete',
            text: '',
            align: 'center',
            width: '0'
        }
    ];

    private items: Array<EnrollmentCenterRateOptionUpdateDto> = [];

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        this.newDividerCounter = 0;
        this.itemsRemoved = [];
        this.items = this.ratesSettingsInfo.map(a => Object.assign({}, a) as EnrollmentCenterRateOptionUpdateDto);
        this.ratesSwitch = !this.settings?.enable_pricing ?? true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('ratesSwitch')
    watchRatesSwitch() {
        this.watchChanges();
    }

    @Watch('items', { deep: true })
    watchChanges() {
        const dtoArray: Array<EnrollmentCenterRateOptionUpdateDto | EnrollmentCenterRateOptionCreateDto> = [];
        let order = 0;

        for (const item of this.items) {
            const fieldDto: EnrollmentCenterRateOptionUpdateDto | EnrollmentCenterRateOptionCreateDto = {
                label: item.label,
                order
            };

            if (item.id > 0) {
                fieldDto.id = item.id;
            }
            if (item.id && item.id < 0) {
                // Emit new items added to the select list
                dtoArray.push(fieldDto);
            } else {
                // Only emits items that have updated
                for (const e of this.ratesSettingsInfo) {
                    if (item.id && (item.id === e.id) && (e.label !== item.label || e.order !== order)) {
                        dtoArray.push(fieldDto);
                        break;
                    }
                }
            }
            ++order;
        }

        let changesFlag = true;
        if (this.itemsRemoved.length === 0 && dtoArray.length === 0 && this.ratesSwitch === (!this.settings?.enable_pricing ?? true)) {
            changesFlag = false;
        } else {
            for (const item of dtoArray) {
                if (item.label.length === 0) {
                    changesFlag = false;
                    break;
                }
            }
        }

        // If valid changes
        // send mutation for save button (Enable)
        // send mutation for updated and deleted changes
        if (changesFlag) {
            settingsChangesStore.setSaveButtonForRateOptions(true);
            settingsChangesStore.setUpdatedRateOptions(dtoArray);
            settingsChangesStore.setDeletedRateOptions(this.itemsRemoved);
            settingsChangesStore.setEnabledRateOptionsFlag(!this.ratesSwitch);
        } else {
            // If invalid changes
            // send mutation for save button (Disable)
            // send mutation to clear updated and deleted changes
            settingsChangesStore.setSaveButtonForRateOptions(false);
            settingsChangesStore.setUpdatedRateOptions([]);
            settingsChangesStore.setDeletedRateOptions([]);
            settingsChangesStore.setEnabledRateOptionsFlag(this.settings?.enable_pricing ?? false);
        }
    }

    private addSection() {
        this.newDividerCounter++;
        const divider: EnrollmentCenterRateOptionUpdateDto = {
            id: -2 * this.newDividerCounter,
            label: '',
            order: 0
        };
        this.items.push(divider);
    }

    private async deleteSection(divider: EnrollmentCenterRateOptionUpdateDto) {
        const arrayInd = this.items.indexOf(divider);
        if (divider.id && divider.id > 0) {
            const result = await this.$swal({
                text: 'WARNING – deleting this category will remove it from all locations. Deleting cannot be undone – restoring these values would require adding this category back to the list and then manually populating it for each location. Proceed?',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Delete',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.items.splice(arrayInd, 1);
                }
                this.itemsRemoved.push(divider.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.items.splice(arrayInd, 1);
            }
        }
    }

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.items.splice(sortEvent.oldIndex!, 1)[0];
        this.items.splice(sortEvent.newIndex!, 0, movedItem);
    }
}
