













































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { FamiliesFilter, FilterSortKey, FilterSortParameter } from '@/filters/models/families-filter';
import { DataTableHeader } from 'vuetify';
import { FamiliesFilterRepository } from '@/filters/repositories/families-filter-repository';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import { DataTableOptions } from '@/models/datatables';
import { SortConstants } from '@/constants/sort-constants';
import { getPagination, getSortingFromOptions } from '@/core/datatables-utils';
import BaseClose from '@/components/base/BaseClose.vue';

const familiesFilterStore = getModule(FamiliesFilterStore);
const familiesFilterRepo = new FamiliesFilterRepository();
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
@Component({
    components: { BaseClose }
})
export default class FiltersTable extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'filtersTable';
    private filters: Array<FamiliesFilter> = [];
    private totalCount = 0;
    private options: DataTableOptions = {
        itemsPerPage: 25,
        page: 1,
        sortBy: [FilterSortKey.NAME],
        sortDesc: [false]
    }

    private defaultSort: FilterSortParameter = {
        sort_keys: [FilterSortKey.NAME],
        sort_dir: [SortConstants.ASCENDING]
    };

    headers: Array<DataTableHeader> = [
        {
            text: 'Name',
            value: 'name',
            width: '50%'
        },
        {
            text: 'Location',
            value: 'location',
            width: '40%'
        },
        {
            text: 'Delete',
            sortable: false,
            value: 'delete',
            width: '10%'
        }
    ];

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get currentFilterId(): number {
        return familiesFilterStore.currentFilter ? familiesFilterStore.currentFilter.id : 0;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    @Watch('options', { deep: true })
    async tableChanged() {
        await this.loadTableData();
    }

    @Watch('modelValue')
    async opened() {
        if (this.modelValue) {
            await this.loadTableData();
        }
    }

    close() {
        this.modelValue = false;
    }

    async deleteFilter(filter: FamiliesFilter) {
        const response = await this.$swal({
            text: 'Are you sure you want to delete this filter?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Delete'
        });
        if (response.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await familiesFilterRepo.deleteFilter(filter.id);
            await this.loadTableData();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    loadFilter(filter: FamiliesFilter) {
        familiesFilterStore.setIsCustom(true);
        familiesFilterStore.storeCurrentFilter(filter);
        this.$emit(EventTypes.LOADED);
        this.close();
    }

    async loadTableData() {
        if (!this.org) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const pagination = getPagination(this.options);
        const sort = getSortingFromOptions(this.options, this.defaultSort);
        const result = await familiesFilterRepo.getFiltersForOrg(this.org.id, pagination, sort);
        this.totalCount = result.count;
        this.filters = result.filters;
        loadingState.loadingDecrement(this.loadingKey);
    }
}
