import { CallFamilyDto } from '@/families/models/call-family-models';
import { FamiliesRepository } from '@/families/repositories/families-repository';

const familiesRepo = new FamiliesRepository();

export class CallFamilyUtils {
    /**
     * Call a family; choose who in the family to call.
     *
     * @param familyId
     * @param guardianId
     * @param contactId
     */
    public async callFamily(familyId: number, guardianId: number | null, contactId: number | null = null) {
        const dto = {
            guardian_id: null,
            contact_id: null
        } as CallFamilyDto;

        if (contactId) {
            dto.contact_id = contactId;
        } else if (guardianId) {
            dto.guardian_id = guardianId;
        }

        await familiesRepo.callFamily(familyId, dto);
    }
}
