import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { LocationOffering } from '@/enrollment-center/models/enrollment-center-location-models';
import { EnrollmentCenterLocationSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-location-settings-repository';

export interface LocationOfferingsMapping {
    centerId: number;
    offerings: Array<LocationOffering>;
}

export interface OfferingsState {
    cleared: number;
    locationOfferings: Map<number, Array<LocationOffering>>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.OFFERINGS
})
export class EnrollmentCenterLocationOfferingsStore extends VuexModule implements OfferingsState {
    cleared = 0;
    locationOfferings: Map<number, Array<LocationOffering>> = new Map();
    readonly repository = new EnrollmentCenterLocationSettingsRepository();

    // Initialize for data retrieval.
    // Don't retrieve if we already have data.
    @Action
    public async init(centerId: number) {
        if (!this.locationOfferings.has(centerId)) {
            await this.retrieve(centerId);
        }
    }

    @Action({ commit: 'storeLocationOfferings' })
    public async retrieve(centerId: number) {
        const apiResponse = await this.repository.getOfferings(centerId);
        return { centerId: centerId, offerings: apiResponse[0].offerings } as LocationOfferingsMapping;
    }

    @Mutation
    public clear() {
        this.locationOfferings.clear();
        ++this.cleared;
    }

    @Mutation
    public storeLocationOfferings(locationOfferingsMapping: LocationOfferingsMapping): void {
        this.locationOfferings.set(locationOfferingsMapping.centerId, locationOfferingsMapping.offerings);
    }

    public get clearedCount() {
        return this.cleared;
    }

    public get storedLocationOfferings() {
        return (centerId: number): Array<LocationOffering> => {
            return this.locationOfferings.has(centerId) ? this.locationOfferings.get(centerId) as Array<LocationOffering> : [];
        };
    }
}
