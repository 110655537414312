import { ClientInfo, ClientInfoDto } from '@/organizations/corporate/models/client-info';

export class ClientInfoMapper {
    toUpdateDto(entity: ClientInfo): ClientInfoDto {
        return {
            name: entity.name,
            address: {
                address1: entity.address.address1,
                locality: entity.address.locality,
                region: entity.address.region,
                postcode: entity.address.postcode,
                country: entity.address.country
            },
            phone: {
                number_e164: entity.phone.number_e164 ?? ''
            },
            url: entity.url,
            admin_staff: entity.admin_staff ? entity.admin_staff.id : null,
            marketing_staff: entity.marketing_staff ? entity.marketing_staff.id : null,
            billing_account_name: entity.billing_account_name,
            billing_address: {
                address1: entity.billing_address.address1,
                locality: entity.billing_address.locality,
                region: entity.billing_address.region,
                postcode: entity.billing_address.postcode,
                country: entity.billing_address.country
            },
            billing_contact_first_name: entity.billing_contact_first_name,
            billing_contact_last_name: entity.billing_contact_last_name,
            billing_contact_phone: {
                number_e164: entity.billing_contact_phone.number_e164 ?? ''
            },
            billing_contact_email: entity.billing_contact_email,
            sales_contact_first_name: entity.sales_contact_first_name,
            sales_contact_last_name: entity.sales_contact_last_name,
            sales_contact_phone: {
                number_e164: entity.sales_contact_phone.number_e164 ?? ''
            },
            sales_contact_email: entity.sales_contact_email,
            comments: entity.comments,
            center_variable_tags_count: entity.center_variable_tags_count,
            social_media: {
                facebook: entity.social_media.facebook,
                twitter: entity.social_media.twitter,
                youtube: entity.social_media.youtube,
                linkedin: entity.social_media.linkedin,
                instagram: entity.social_media.instagram,
                pinterest: entity.social_media.pinterest,
                yelp: null
            },
            business_registration_identifier: entity.business_registration_identifier,
            legal_business_name: entity.legal_business_name
        };
    }
}
