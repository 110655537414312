


































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { VariableTagsStore } from '@/communications/templates/store/variable-tags-store';
import { getModule } from 'vuex-module-decorators';
import { TemplateVariablesRepository } from '@/communications/templates/repositories/template-variables-repository';
import { LoadingStore } from '@/store/loading-store';
import { ccfLinkType, TemplateVariableCreateDto } from '@/communications/templates/models/template-variable';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const variablesState = getModule(VariableTagsStore);
const variablesRepo = new TemplateVariablesRepository();
const loadingState = getModule(LoadingStore);
@Component({
    components: { BaseClose }
})
export default class ManageCcfTemplateVariables extends Mixins(LocaleMixin, BasicValidationMixin, VmodelShowMixin) {
    private loadingKey = ManageCcfTemplateVariables.name;
    private newTag = '';
    private isValid = false;

    @Ref('form') readonly form!: VForm;

    async created() {
        await this.loadVars();
    }

    get ccfVars() {
        return variablesState.stored.filter((templateVariable) => {
            return templateVariable.type === ccfLinkType;
        });
    }

    get existing() {
        return variablesState.stored.map((ccfVariable) => {
            return ccfVariable.tag;
        });
    }

    get tagRules() {
        return [
            ...this.requiredField,
            (v: string) => !this.existing.includes(v) || 'Link name already in use'
        ];
    }

    close() {
        this.modelValue = false;
    }

    async loadVars() {
        loadingState.loadingIncrement(this.loadingKey);
        await variablesState.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

    async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const createDto: TemplateVariableCreateDto = {
            ccf_interview_mappings: null,
            tag: this.newTag,
            type: ccfLinkType
        };
        await variablesRepo.createVariable(createDto);
        this.newTag = '';
        this.form.reset();
        await variablesState.retrieve();
        loadingState.loadingDecrement(this.loadingKey);
    }
}
