import { Reminder, TextReminderDto } from '@/communications/reminders/models/reminder-models';
import { TextRemindersRepository } from '@/communications/reminders/repositories/text-reminders-repository';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import {
    MessageTemplate,
    TextTemplateUpdateDto
} from '@/communications/templates/models/message-template';
import {
    HIERARCHY_TYPE_BRAND_ID,
    HIERARCHY_TYPE_LOCATION_GROUP_ID,
    HIERARCHY_TYPE_LOCATION_ID
} from '@/crm-types/crm-types-constants';
import StringUtils from '@/utils/string-utils';

const textRemindersRepository = new TextRemindersRepository();
const textTemplatesRepository = new TextTemplatesRepository();

export class TextTemplateUtils {
    async save(textMessage: string, template: MessageTemplate | Reminder, templateDto: TextTemplateUpdateDto | TextReminderDto): Promise<MessageTemplate | Reminder | null> {
        // Get the HTML content, and convert to text for the API.
        templateDto.content = StringUtils.cleanContentForTextApi(
            StringUtils.convertHtmlVariableTagsToText(textMessage)
        );

        let updatedTemplate = null;

        if ('communication_type' in template) {
            try {
                templateDto = this.setHierarchyData(template, templateDto as TextTemplateUpdateDto);
                // Update any metadata first
                updatedTemplate = await textTemplatesRepository.update(templateDto as TextTemplateUpdateDto);
            } catch (e) {
                return null;
            }
        } else {
            try {
                // Update any metadata first
                updatedTemplate = await textRemindersRepository.update(template.id, { content: templateDto.content } as TextReminderDto);
            } catch (e) {
                return null;
            }
        }

        return updatedTemplate;
    }

    /**
     * Set data that is related to the hierarchy choice.
     */
    setHierarchyData(template: MessageTemplate, dto: TextTemplateUpdateDto): TextTemplateUpdateDto {
        switch (dto.hierarchy_type) {
            case HIERARCHY_TYPE_BRAND_ID:
                dto.location_group = null;
                dto.org = template.org.id;
                break;
            case HIERARCHY_TYPE_LOCATION_ID:
                dto.brand = null;
                dto.location_group = null;
                break;
            case HIERARCHY_TYPE_LOCATION_GROUP_ID:
                dto.brand = null;
                dto.org = template.org.id;
                break;
        }
        return dto;
    }
}
