import { Center } from '@/organizations/locations/models/center';
import {
    LocationIntegrationDto,
    LocationIntegrationMapping,
    LocationIntegrationToggle,
    LocationsMappingDto
} from '@/integrations/models/integration';

export function centersToLocationMappingDto(centers: Array<Center>): LocationsMappingDto {
    return centers.map((center) => {
        return {
            id: center.id,
            organization_id: center.organization_id,
            external_system_id: center.external_system_id ?? ''
        };
    });
}

/**
 * @param {LocationIntegrationMapping[]} locations
 * @param {boolean} isSecondary
 *
 * @returns {LocationsMappingDto}
 */
export function locationIntegrationsToMappingDto(locations: Array<LocationIntegrationMapping>, isSecondary = false): LocationsMappingDto {
    // If no change in the id's, then we should include it in the update.
    /** @type {LocationIntegrationMapping[]} */
    const filtered = locations.filter(
        (location) => {
            return (location.is_enabled || location.is_enabled !== location.original_is_enabled) &&
                (
                    location.external_system_id !== location.center.external_system_id ||
                    location.secondary_external_system_id !== location.center.secondary_external_system_id
                );
        }
    );

    /** @type {LocationsMappingDto} */
    return filtered.map((location) => {
        const ret: LocationIntegrationDto = {
            id: location.center.id,
            organization_id: location.center.organization_id
        };

        if (isSecondary) {
            ret.secondary_external_system_id = location.secondary_external_system_id && location.is_enabled
                ? location.secondary_external_system_id
                : '';
        } else {
            ret.external_system_id = location.external_system_id && location.is_enabled
                ? location.external_system_id
                : '';
        }

        return ret;
    });
}

/**
 * @param {LocationIntegrationMapping[]} locations
 * @param {boolean} hasMulti
 *
 * @return {LocationIntegrationToggle[]}
 */
export function locationIntegrationToToggleDtos(locations: Array<LocationIntegrationMapping>, hasMulti: boolean): Array<LocationIntegrationToggle> {
    // If no change in the id's, then we should include it in the update.
    /** @type {LocationIntegrationMapping[]} */
    const filtered = locations.filter((location) => {
        return (!hasMulti && location.external_system_id) ||
            (
                location.is_enabled !== undefined &&
                location.is_enabled !== null &&
                location.original_is_enabled !== undefined &&
                location.original_is_enabled !== null &&
                location.is_enabled !== location.original_is_enabled
            );
    });

    /** @type {LocationIntegrationToggle[]} */
    return filtered.map((location): LocationIntegrationToggle => {
        location.external_system_id = location.is_enabled ? (location.external_system_id ?? '') : '';
        location.secondary_external_system_id = location.is_enabled ? (location.secondary_external_system_id ?? '') : '';

        return {
            center_id: location.center.id as number,
            is_enabled: location.is_enabled as boolean || (!hasMulti && !!location.external_system_id)
        };
    });
}
