import { CrmEntity } from '@/models/base';

export enum UiNotificationIdentifiers {
    INBOX_POINTER = 'inboxPointer'
}

export interface UiNotification extends CrmEntity {
    identifier: string;
    description: string;
    is_active: boolean;
}

export interface UiNotificationUpdate {
    is_active: boolean;
}
