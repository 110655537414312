import { AbstractRepository } from '@/repositories/abstract-repository';
import { UiNotification, UiNotificationUpdate } from '@/staff/models/ui-notifications';

export class UiNotificationsRepository extends AbstractRepository<UiNotification> {
    protected endpoint = 'staff';

    public async getUiNotifications(userId: number): Promise<Array<UiNotification>> {
        const response = await this.client.get(this.endpoint + `/${userId}/notifications`);
        return response.data;
    }

    public async updateUiNotification(userId: number, notificationId: number, payload: UiNotificationUpdate): Promise<void> {
        await this.client.put(this.endpoint + `/${userId}/notifications/${notificationId}`, payload);
    }

}
