



































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { PeriodType } from '@/constants/stat-constants';
import { EventTypes } from '@/constants/event-type-constants';
import type { DateFilter, DateRange } from '@/models/date-filter';
import {
    addDays, addMonths,
    diffDays,
    formatDateForApi, formatMonth, formatYear,
    getMonthEnd,
    getYearEnd
} from '@/date-time/date-time-utils';
import { RangeItem } from '@/date-time/date-filter-utils';

@Component
export default class DateFilterInput extends Mixins(LocaleMixin) {
    private now = new Date().toISOString().substr(0, 10);
    private startDateMenu = false;
    private endDateMenu = false;
    private startMin = '2010-01-01';
    private updatedEvent = EventTypes.UPDATED;
    private currentRange: DateRange | null = null;
    private isStartValid = false;
    private isEndValid = false;
    // local date filter, we manipulate this and only update modelValue on save
    private localDateFilter: DateFilter = {
        periodType: PeriodType.MONTHLY,
        startDate: formatDateForApi(addMonths(new Date(), -5)),
        endDate: formatDateForApi(getMonthEnd('UTC'))
    };

    private periodTypeOptions = [
        {
            text: 'Days',
            value: PeriodType.DAILY
        },
        {
            text: 'Weeks',
            value: PeriodType.WEEKLY
        },
        {
            text: 'Months',
            value: PeriodType.MONTHLY
        },
        {
            text: 'Years',
            value: PeriodType.YEARLY
        }
    ];

    // refs for typescript
    $refs!: {
        startPicker: any;
        endPicker: any;
    }

    @Prop({ default: false }) showControls!: boolean;
    @Prop({ default: false }) usePeriods!: boolean;
    @Prop({ default: true }) useRanges!: boolean;

    // v-model stuff
    @Prop() readonly value!: DateFilter;

    get modelValue(): DateFilter {
        return this.value;
    }

    set modelValue(newValue: DateFilter) {
        this.$emit('input', newValue);
    }

    mounted() {
        if (!this.usePeriods) {
            this.localDateFilter.periodType = null;
            this.adjustRange();
        }
    }

    private handleInvalidStart(eventPayload: boolean) {
        this.isStartValid = eventPayload;
    }

    private handleInvalidEnd(eventPayload: boolean) {
        this.isEndValid = eventPayload;
    }

    // set local filter based on passed in value
    @Watch('value', { immediate: true })
    public valueChange(newValue: DateFilter) {
        this.localDateFilter.periodType = newValue.periodType;
        this.localDateFilter.startDate = newValue.startDate;
        this.localDateFilter.endDate = newValue.endDate;
        this.adjustRange();
    }

    @Watch('startDateMenu')
    public startMenuChanged(newValue: boolean) {
        this.handleMenu(newValue, true);
    }

    @Watch('endDateMenu')
    public endMenuChanged(newValue: boolean) {
        this.handleMenu(newValue, false);
    }

    /**
     * trigger updates automatically if we don't have controls
     */
    @Watch('localDateFilter', { deep: true })
    public valueChanged() {
        if (!this.showControls) {
            this.copyToModel();
            this.$emit('updated');
        }
    }

    /**
     * have there been changes?
     */
    get edited(): boolean {
        return this.localDateFilter.periodType !== this.modelValue.periodType ||
          this.localDateFilter.startDate !== this.modelValue.startDate ||
          this.localDateFilter.endDate !== this.modelValue.endDate;
    }

    /**
     * year picker isn't supported built-in, so only monthly has special type
     */
    get pickerType(): string {
        return (this.localDateFilter.periodType === PeriodType.MONTHLY ? 'month' : 'date');
    }

    /**
     * max allowed date, has to be different per period type
     */
    get dateMax(): string {
        switch (this.localDateFilter.periodType) {
            case PeriodType.YEARLY:
                return formatDateForApi(getYearEnd('UTC'));
            case PeriodType.MONTHLY:
                return formatDateForApi(getMonthEnd('UTC'));
            default:
                return this.now;
        }
    }

    get startMax(): string {
        return formatDateForApi(addDays(this.localDateFilter.endDate, -1));
    }

    get endMin(): string {
        return formatDateForApi(addDays(this.localDateFilter.startDate, 1));
    }

    /**
     * several date picker flags use this
     */
    get isYearly(): boolean {
        return this.localDateFilter.periodType === PeriodType.YEARLY;
    }

    /**
     * Get all the ranges we want
     */
    get ranges(): RangeItem[] {
        return [];
    }

    /**
     * manually triggered update
     */
    private applyFilter() {
        this.copyToModel();
        this.$emit('updated');
    }

    /**
     * reset
     */
    private resetFilter() {
        this.copyToLocal();
        this.adjustRange();
    }

    /**
     * helper functions. avoiding lodash for such a small object
     */
    private copyToLocal() {
        this.localDateFilter.periodType = this.modelValue.periodType;
        this.localDateFilter.startDate = this.modelValue.startDate;
        this.localDateFilter.endDate = this.modelValue.endDate;
    }

    private copyToModel() {
        this.modelValue.periodType = this.localDateFilter.periodType;
        this.modelValue.startDate = this.localDateFilter.startDate;
        this.modelValue.endDate = this.localDateFilter.endDate;
    }

    /**
     * make needed adjustments when period type changes
     */
    private periodTypeUpdated() {
        this.adjustRange();
    }

    /**
     * If they pick a date range that matches a pre-defined range, change the range
     * dropdown to match
     *
     * @private
     */
    private checkRanges() {
        for (const range of this.ranges) {
            if (range.value && this.localDateFilter.startDate === range.value.startDate && this.localDateFilter.endDate === range.value.endDate) {
                this.currentRange = range.value;
                return;
            }
        }
        this.currentRange = null;
    }

    /**
     * make sure the date pickers open on the right state
     *
     * @param newValue
     * @param start
     * @private
     */
    private handleMenu(newValue: boolean, start: boolean) {
        if (newValue) {
            setTimeout(() => {
                const picker = start ? this.$refs.startPicker : this.$refs.endPicker;
                switch (this.localDateFilter.periodType) {
                    case PeriodType.YEARLY:
                        picker.activePicker = 'YEAR';
                        break;
                    case PeriodType.MONTHLY:
                        picker.activePicker = 'MONTH';
                        break;
                    default:
                        picker.activePicker = 'DATE';
                        break;
                }
            });
        }
    }

    /**
     * change the displayed date based on period type
     * @param date
     * @private
     */
    private formatLocal(date: string) {
        switch (this.localDateFilter.periodType) {
            case PeriodType.YEARLY:
                return formatYear(date);
            case PeriodType.MONTHLY:
                return formatMonth(date);
            default:
                return this.formatDate(date);
        }
    }

    /**
     * special handler for year clicks
     * @private
     */
    private yearStart() {
        if (this.localDateFilter.periodType !== PeriodType.YEARLY) {
            return;
        }
        this.adjustRange();
        this.startDateMenu = false;
    }

    /**
     * special handler for year clicks
     * @private
     */
    private yearEnd() {
        if (this.localDateFilter.periodType !== PeriodType.YEARLY) {
            return;
        }
        this.adjustRange();
        this.endDateMenu = false;
    }

    /**
     * callback for when a range is chosen
     * @private
     */
    private setRange() {
        if (this.currentRange) {
            this.localDateFilter.startDate = this.currentRange.startDate;
            this.localDateFilter.endDate = this.currentRange.endDate;
            if (this.showControls) {
                this.applyFilter();
            }
        }
    }

    /**
     * adjust ranges based on period type
     * @private
     */
    private adjustRange() {
        if (this.dateMax < this.localDateFilter.endDate) {
            this.localDateFilter.endDate = this.dateMax;
        }
        if (this.localDateFilter.startDate > this.localDateFilter.endDate) {
            this.localDateFilter.startDate = this.localDateFilter.endDate;
        }
        this.checkRanges();
    }

    /**
     * filter allowed dates in weekly mode (day mode doesn't need this and the other
     * modes don't show days)
     *
     * @param date
     * @private
     */
    private allowedStart(date: string): boolean {
        if (this.localDateFilter.periodType !== PeriodType.WEEKLY) {
            return true;
        }

        return (diffDays(this.now, date) + 1) % 7 === 0;
    }

    /**
     * filter allowed dates in weekly mode (day mode doesn't need this and the other
     * modes don't show days)
     *
     * @param date
     * @private
     */
    private allowedEnd(date: string): boolean {
        if (this.localDateFilter.periodType !== PeriodType.WEEKLY) {
            return true;
        }

        return diffDays(this.now, date) % 7 === 0;
    }
}
