import { AbstractRepository, ApiPagination } from '@/repositories/abstract-repository';
import { ReportLog, ReportLogSortParameter, SavedReport, SavedReportDtoInterface } from '@/reports/models/saved-report';
import { CrmApiResponse } from '@/models/base';

export class SavedReportsRepository extends AbstractRepository<SavedReport> {
    readonly endpoint = 'staff';

    public async getReports(userId: number): Promise<Array<SavedReport>> {
        const response = await this.client.get(this.endpoint + `/${userId}/reports?no_pagination=1`);
        return response.data;
    }

    public async getOneReport(userId: number, reportId: number): Promise<SavedReport> {
        const response = await this.client.get(this.endpoint + `/${userId}/reports/${reportId}`);
        return response.data;
    }

    public async createReport(userId: number, reportDto: SavedReportDtoInterface): Promise<SavedReport> {
        const response = await this.client.post(this.endpoint + `/${userId}/reports`, reportDto);
        return response.data;
    }

    public async updateReport(userId: number, reportId: number, reportDto: SavedReportDtoInterface): Promise<SavedReport> {
        const response = await this.client.put(this.endpoint + `/${userId}/reports/${reportId}`, reportDto);
        return response.data;
    }

    public async deleteReport(userId: number, reportId: number): Promise<void> {
        await this.client.delete(this.endpoint + `/${userId}/reports/${reportId}`);
    }

    public async getOneReportLog(userId: number, logId: number): Promise<ReportLog> {
        const response = await this.client.get(this.endpoint + `/${userId}/reports/logs/${logId}`);
        return response.data;
    }

    public async getStaffReportLogs(userId: number, pagination: ApiPagination | null = null, sortParam: ReportLogSortParameter|null = null): Promise<CrmApiResponse<ReportLog>> {
        const params = {
            limit: String(pagination?.limit),
            offset: String(pagination?.offset)
        };

        Object.assign(params, sortParam);

        const response = await this.client.get<Array<ReportLog>>(this.endpoint + `/${userId}/reports/logs`, {
            params: params
        });

        if (response === undefined) {
            // no data was pulled that matched the request
            return { entities: [], count: 0 };
        }

        const count = parseInt(response.headers['x-total-count']);

        return { entities: response.data, count: !isNaN(count) ? count : 0 };
    }
}
