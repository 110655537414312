import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import type {
    ActionTaskDate, PendAndCommType,
    ToDoDashboardState
} from '@/dashboards/models/action-items-models';
import { DataTableOptions } from '@/models/datatables';
import { Center } from '@/organizations/locations/models/center';

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.LOCATION_DASHBOARD
})
export class LocationDashboardStore extends VuexModule implements ToDoDashboardState {
    onlyMine = false;
    taskMultiSelect = false;
    pendAndCommType: PendAndCommType = null;
    taskDates: Array<ActionTaskDate> = ['today'];
    taskTypeFilter: number | null = null;
    tasksPagination: DataTableOptions = {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['datetime'],
        sortDesc: [false]
    };

    pendAndCommPagination: DataTableOptions = {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['datetime'],
        sortDesc: [false]
    };

     availabilityTabCurrentCenter: Center | null = null;

    @Mutation
     setOnlyMine(flag: boolean) {
         this.onlyMine = flag;
     }

    @Mutation
    setTaskMultiSelect(flag: boolean) {
        this.taskMultiSelect = flag;
    }

    @Mutation
    setPendAndCommPagination(options: DataTableOptions) {
        this.pendAndCommPagination = options;
    }

    @Mutation
    setPendAndCommType(type: PendAndCommType) {
        this.pendAndCommType = type;
    }

    @Mutation
    setTasksPagination(options: DataTableOptions) {
        this.tasksPagination = options;
    }

    @Mutation
    setTaskTypeFilter(filter: number | null) {
        this.taskTypeFilter = filter;
    }

    @Mutation
    setTaskDates(taskDates: Array<ActionTaskDate>) {
        this.taskDates = taskDates;
    }

    @Mutation
    setAvailabilityTabCurrentCenter(center: Center | null) {
        this.availabilityTabCurrentCenter = center;
    }
}
