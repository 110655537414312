


































































import { EventTypes } from '@/constants/event-type-constants';
import { SavedReportMapper } from '@/reports/mappers/saved-report-mapper';
import { SavedReport, SavedReportDto, SavedReportDtoInterface } from '@/reports/models/saved-report';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import { User } from '@/staff/models/user';
import { StaffStore } from '@/staff/store/staff-store';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import isEqual from 'lodash/isEqual';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const savedReportMapper = new SavedReportMapper();
const savedReportsRepository = new SavedReportsRepository();
const savedReportsState = getModule(SavedReportsStore);
const staffState = getModule(StaffStore);

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class ShareReportModal extends Mixins(LocaleMixin) {
    /**
     * The saved report without the report data.
     */
    @Prop() readonly partialSavedReport!: SavedReport | null;
    /**
     * v-model: whether we should show it.
     */
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'shareReportKey';
    private savedReport: SavedReport | null = null;
    private savedReportDto: SavedReportDtoInterface = new SavedReportDto();
    private validForm = false;

    private nameRules = [
        (v: string|number) => !!v || 'Please enter a name',
        (v: string) => v.length <= 40 || 'Max 40 characters'
    ];

    /**
     * Get all of the staff from the store, excluding the current user.
     */
    private get allStaff(): Array<User> {
        return staffState.storedAllStaff.filter((staff: User) => {
            return staff.id !== this.userId;
        });
    }

    /**
     * Whether the form is valid or not.
     */
    private get isValid(): boolean {
        // Not valid when there is no saved report present
        if (!this.savedReport) {
            return false;
        }
        // Not valid when no users are selected if there were no users to begin with
        if (!this.savedReportDto.shared_with.length && !this.savedReport?.shared_with.length) {
            return false;
        }
        // Make sure the form follows the other rules and there is some difference.
        return this.validForm && !isEqual(this.savedReportDto, savedReportMapper.toUpdateDto(this.savedReport));
    }

    private get modelValue(): boolean {
        // Don't allow this dialog to appear when there is no saved report or it isn't editable
        if (!this.partialSavedReport || !this.partialSavedReport.is_editable) {
            this.modelValue = false;
            return false;
        }
        return this.value;
    }

    private set modelValue(isShown: boolean) {
        this.form.reset();
        this.$emit(EventTypes.INPUT, isShown);
    }

    private get userId(): number {
        return authState.userInfoObject!.id as number;
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const allStaff = staffState.initAllStaff();
        const savedReport = savedReportsRepository.getOneReport(this.userId, this.partialSavedReport!.id);
        await allStaff;
        await savedReport;
        Promise.resolve(savedReport).then((result) => {
            this.savedReport = result;
            this.savedReportDto = savedReportMapper.toUpdateDto(this.savedReport);
        });
        this.$nextTick(() => {
            // Validate form when we popup the modal.
            this.form.validate();
        });
        loadingState.loadingDecrement(this.loadingKey);
    }

    private close() {
        this.form.reset();
        this.modelValue = false;
    }

    /**
     * Save the changes for sharing the report.
     */
    private async shareReport() {
        loadingState.loadingIncrement(this.loadingKey);
        await savedReportsRepository.updateReport(this.userId, this.savedReport!.id, this.savedReportDto);
        await savedReportsState.retrieveAllReports(this.userId);
        this.$emit(EventTypes.SHARED_REPORT);
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }

    private staffName(staff: User) {
        return `${staff.first_name} ${staff.last_name} (${staff.org.values.name})`;
    }
}
