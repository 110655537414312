var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{class:'text-center px-0' + _vm.embedded ? '' : ' main-table-area',attrs:{"elevation":0}},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),(!_vm.embedded)?_c('v-card-title',{attrs:{"id":"to-do-settings-top"}},[_vm._v(" To Do Settings "),_c('v-spacer'),_c('base-button-plain',{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c('base-close')],1)],1):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"ml-4 mr-2",attrs:{"cols":"16"}},[_c('span',{staticClass:"table-title text-subtitle-2 my-3"},[_vm._v("Tasks Drop-Down List Options")]),(_vm.isLoaded)?_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],key:_vm.tasksKey,staticClass:"main-table",attrs:{"items":_vm.taskItems,"headers":_vm.headers,"hide-default-footer":"","dense":"","items-per-page":-1,"disable-sort":""},on:{"sorted":_vm.tasksSorted},scopedSlots:_vm._u([{key:"item.handle",fn:function(){return [_c('v-icon',{staticClass:"drag-handle mx-0 px-0"},[_vm._v("mdi-arrow-up-down-bold")])]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.isEditable ? null : item.typeValue}},[_c('base-text-field',{attrs:{"disabled":!item.isEditable,"hide-details":"","prepend-inner-icon":!item.isEditable ? 'mdi-lock' : ''},model:{value:(item.typeValue),callback:function ($$v) {_vm.$set(item, "typeValue", $$v)},expression:"item.typeValue"}})],1)]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('emphasized-select',{attrs:{"label":"Task Group","items":_vm.taskGroupItems,"hide-details":""},model:{value:(item.groupId),callback:function ($$v) {_vm.$set(item, "groupId", $$v)},expression:"item.groupId"}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(false)?_c('v-icon',{on:{"click":function($event){return _vm.deleteTask(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,1877548478)}):_vm._e(),_c('base-button-secondary',{staticClass:"mt-2",on:{"click":_vm.addTaskType}},[_vm._v(" Add Another ")])],1),_c('v-col',{staticClass:"ml-2 mr-4",attrs:{"cols":"16"}},[_c('span',{staticClass:"table-title text-subtitle-2 my-3"},[_vm._v("Results Drop-Down List Options")]),(_vm.isLoaded)?_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],key:_vm.resultsKey,staticClass:"main-table",attrs:{"items":_vm.resultsItems,"headers":_vm.resultHeaders,"hide-default-footer":"","dense":"","items-per-page":-1,"disable-sort":""},on:{"sorted":_vm.resultsSorted},scopedSlots:_vm._u([{key:"header.log",fn:function(){return [_c('span',[_vm._v(" Log ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"color":"primary","dark":""}},on),[_vm._v(" mdi-information ")])]}}],null,false,2093402162)},[_c('span',[_vm._v(" Use this Result Name as an option for Logs ")])])]},proxy:true},{key:"item.handle",fn:function(){return [_c('v-icon',{staticClass:"drag-handle mx-0 px-0"},[_vm._v("mdi-arrow-up-down-bold")])]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.isEditable ? null : item.typeValue}},[_c('base-text-field',{attrs:{"disabled":!item.isEditable,"hide-details":"","prepend-inner-icon":!item.isEditable ? 'mdi-lock' : ''},model:{value:(item.typeValue),callback:function ($$v) {_vm.$set(item, "typeValue", $$v)},expression:"item.typeValue"}})],1)]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(!item.isSystemEvent)?_c('emphasized-select',{attrs:{"label":"Task Group","items":_vm.taskResultGroups,"hide-details":""},on:{"change":function($event){return _vm.checkOnlyLogs(item)}},model:{value:(item.groupId),callback:function ($$v) {_vm.$set(item, "groupId", $$v)},expression:"item.groupId"}}):_c('span',[_vm._v(" System Event ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(false)?_c('v-icon',{on:{"click":function($event){return _vm.deleteResult(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.log",fn:function(ref){
var item = ref.item;
return [(!item.isSystemEvent)?_c('base-checkbox',{attrs:{"disabled":item.groupId === _vm.onlyLogsId,"hide-details":""},model:{value:(item.isLog),callback:function ($$v) {_vm.$set(item, "isLog", $$v)},expression:"item.isLog"}}):_vm._e()]}}],null,false,3257609398)}):_vm._e(),_c('base-button-secondary',{staticClass:"mt-2",on:{"click":_vm.addResultType}},[_vm._v(" Add Another ")])],1)],1)],1),(!_vm.embedded)?_c('v-card-actions',{staticClass:"justify-center"},[_c('base-button-primary',{on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('base-button-secondary',{on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }