import { Workflow } from '@/automation/workflows/models/workflow-models';
import { WorkflowsRepository } from '@/automation/workflows/repositories/workflows-repository';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Action, Module } from 'vuex-module-decorators';

export interface WorkflowsState extends AbstractEntityState<Workflow> {
    entities: Array<Workflow>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.WORKFLOWS
})
export class WorkflowsStore extends AbstractApiStore<Workflow> implements WorkflowsState {
    readonly repository = new WorkflowsRepository();

    // Initialize for data retrieval.
    // Don't retrieve if we already have data, or if force is false.
    @Action
    public async init(force = false) {
        if (this.entities.length === 0 || force) {
            await this.initPromise(
                {
                    hash: 'workflows',
                    closure: async () => {
                        await this.retrieveAll();
                    },
                    rebuild: force
                }
            );
        }
    }

    public get stored(): Array<Workflow> {
        return this.entities;
    }
}
