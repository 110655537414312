import FieldMapper from '@/crm-types/mappers/field-mapper';
import {
    ChildFields,
    ChildFieldsOrder,
    ChildScheduleFields, ChildTypeFields,
    ExtraStatusDataFields,
    Field,
    FieldEntityType,
    FieldTypes,
    FieldUpdateDto,
    GroupedFieldKeys,
    GuardianFields,
    GuardianFieldsOrder, GuardianTypeFields
} from '@/crm-types/models/field-models';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import {
    Enrollment,
    EnrollmentCreateDtoInterface,
    EnrollmentDateReason,
    EnrollmentDateReasonWrite,
    EnrollmentDateReasonWriteClass,
    EnrollmentUpdateDtoInterface,
    WaitlistDetails,
    WaitlistDetailsUpdate,
    WaitlistWrite
} from '@/families/models/enrollment';
import { getModule } from 'vuex-module-decorators';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { StatusChangeInterface } from '@/families/models/status';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { EnrollmentCenterSettings } from '@/enrollment-center/models/enrollment-center-models';

const fieldStore = getModule(FieldsStore);
const featureStore = getModule(FeaturesStore);
const crmModeFields: Array<string> = [
    GuardianFields.INQUIRY_TYPE,
    GuardianFields.SOURCE
];

const childScheduleFields: Array<string> = [
    ChildFields.SCHEDULE_INFORMATION,
    ChildScheduleFields.MONDAY_AM,
    ChildScheduleFields.MONDAY_PM,
    ChildScheduleFields.TUESDAY_AM,
    ChildScheduleFields.TUESDAY_PM,
    ChildScheduleFields.WEDNESDAY_AM,
    ChildScheduleFields.WEDNESDAY_PM,
    ChildScheduleFields.THURSDAY_AM,
    ChildScheduleFields.THURSDAY_PM,
    ChildScheduleFields.FRIDAY_AM,
    ChildScheduleFields.FRIDAY_PM,
    ChildScheduleFields.SATURDAY_AM,
    ChildScheduleFields.SATURDAY_PM,
    ChildScheduleFields.SUNDAY_AM,
    ChildScheduleFields.SUNDAY_PM,
    ChildScheduleFields.FULL_DAYS,
    ChildScheduleFields.HALF_DAYS
];

/**
 * Filter out the grouped fields and replace them with placeholder fields.
 *
 * @param fieldType
 * @param fields
 */
export function filterGroupedFields(fieldType: FieldEntityType, fields: Array<Field>): Array<Field> {
    switch (fieldType) {
        case FieldEntityType.CHILD: {
            // Filter out all of the schedule fields
            const filterValues = Object.values(ChildScheduleFields) as Array<string>;
            let placeholderField: Field | null = null;
            fields = fields.filter((field: Field) => {
                const found = filterValues.includes(field.value);
                if (found && placeholderField === null) {
                    // All schedule fields should have the same data other than the id and value, so just replace those
                    placeholderField = clone(field);
                    placeholderField.id = GroupedFieldKeys.ChildSchedule;
                    placeholderField.value = ChildFields.SCHEDULE_INFORMATION;
                }
                return !found;
            });

            // Add a placeholder field in for the schedule; this will need to be replaced when saving the field changes.
            if (placeholderField !== null) {
                fields.push(placeholderField);
            }
            break;
        }
        default:
            break;
    }

    return fields;
}

/**
 * Get the changes to apply to the fields covered in the placeholder field group.
 *
 * @param placeholderField The field that is acting as a placeholder for a group of fields.
 * @param fields           An array of fields that have not been edited for display purposes.
 */
export function getPlaceholderFieldChanges(placeholderField: Field, fields: Array<Field>): Array<FieldUpdateDto> {
    const updates = [];
    const fieldMapper = new FieldMapper();
    if (placeholderField.id === GroupedFieldKeys.ChildSchedule) {
        const scheduleFields = Object.values(ChildScheduleFields) as Array<string>;
        // Loop through the fields to create dtos for the schedule fields
        for (const readonlyField of fields) {
            if (scheduleFields.includes(readonlyField.value)) {
                const dto = fieldMapper.toUpdateDto(readonlyField);
                // Set dto data to match the placeholder field data
                dto.is_client_required = placeholderField.is_client_required;
                dto.is_hidden = placeholderField.is_hidden;

                if (!isEqual(dto, fieldMapper.toUpdateDto(readonlyField))) {
                    updates.push(dto);
                }
            }
        }
    }

    return updates;
}

/**
 * Sort fields according to order shown in the UI.
 *
 * @param fieldType
 * @param fields
 */
export function sortFields(fieldType: FieldEntityType, fields: Array<Field>): Array<Field> {
    const map: Map<number, Field> = new Map();

    let orderEnum;
    let fieldKeys: Array<string> | null;
    let fieldValues: Array<string> | null;
    switch (fieldType) {
        case FieldEntityType.CHILD:
            orderEnum = ChildFieldsOrder;
            fieldKeys = Object.keys(ChildFields);
            fieldValues = Object.values(ChildFields);
            break;
        case FieldEntityType.GUARDIAN:
        default:
            orderEnum = GuardianFieldsOrder;
            fieldKeys = Object.keys(GuardianFields);
            fieldValues = Object.values(GuardianFields);
    }

    let minUnknownIndex = 100;
    for (const field of fields) {
        const index = fieldValues?.indexOf(field.value);
        if (index !== -1) {
            map.set((orderEnum as any)[fieldKeys[index]], field);
        } else {
            map.set(minUnknownIndex++, field);
        }
    }

    return Array.from((new Map([...map].sort((a, b) => a[0] - b[0]))).values());
}

/**
 * Is this lead type 1-4 or child type 1-4?
 * @param field
 */
export function isCustomTypeOneToFour(field: Field): boolean {
    return field.select_list?.links[0].rel.includes('custom_') ?? false;
}

/**
 * This tedious function takes in a field and returns the corresponding property,
 * because the API does not currently tell us what property in what object each field connects to.
 * I don't like all the strings, but they have to be set somewhere...
 *
 * NOTE: Because this is used for editing values, it links to the properties in DTOs, not the original entity.
 */
export function getPropertyNameForField(field: Field): string | null {
    // Lead/Child Types 1-4 can change their name, so inspect the links
    if (isCustomTypeOneToFour(field)) {
        const string = field.select_list!.links[0]!.rel;
        const number = string.slice(string.length - 1);
        return `custom_type_${number}`;
    }

    switch (field.value) {
        case 'Added Date':
            return 'added_date';
        case 'First Name':
            return 'first_name';
        case 'Last Name':
            return 'last_name';
        case GuardianFields.STATUS:
            return 'status';
        case GuardianFields.INQUIRY_TYPE:
            return 'inquiry_type';
        case GuardianFields.SOURCE:
            return 'source_type';
        case GuardianFields.PHONE_TYPE:
            return 'primary_phone.type';
        case GuardianFields.RELATION_TO_CHILD:
            return 'child_relation';
        case GuardianFields.LOST_OPPORTUNITY_REASON:
            return 'status_details.lost_opportunity_reason';
        case GuardianFields.LOST_OPPORTUNITY_COMMENT:
            return 'status_details.lost_opportunity_comment';
        case ChildFields.REJECTED_REASON:
        case GuardianFields.REJECTED_REASON:
            return 'status_details.rejected_reason';
        case ChildFields.REJECTED_COMMENT:
        case GuardianFields.REJECTED_COMMENT:
            return 'status_details.rejected_comment';
        case GuardianFields.ALTERNATE_PHONE:
            return 'alternate_phone.number_e164';
        case GuardianFields.ALTERNATE_PHONE_TYPE:
            return 'alternate_phone.type';
        case GuardianFields.ALTERNATE_EMAIL:
            return 'alternate_email';
        case GuardianFields.ADDED_DATE:
        case GuardianFields.LEAD_DATE:
            return 'added_date';
        case GuardianFields.ALT_CONTACT_PHONE_TYPE:
            return 'alternate_phone.type';
        case GuardianFields.REFERRED_BY:
            return 'referred_by';
        case GuardianFields.BIRTHDATE:
            return 'date_of_birth';
        case GuardianFields.ENROLLMENT_REP:
            return 'enrollment_rep';
        case ChildFields.PREFERRED_NAME:
            return 'preferred_name';
        case GuardianFields.GENDER:
        case ChildFields.GENDER:
            return 'gender';
        case ChildFields.NOTES:
            // Fall through; somehow we have "notes" and "comments" as field names for the same thing
        case ChildFields.COMMENTS:
            return 'comments';
        case ChildFields.ADDED_DATE:
            return 'added_date';
        case ChildFields.BIRTHDATE:
            return 'date_of_birth';
        case ChildFields.ESTIMATED_BIRTH:
            return 'is_estimated_date_of_birth';
        case ChildFields.ACCOUNT_IN_GOOD_STANDING:
            return 'good_standing';
        case ChildFields.ELIGIBLE_FOR_REENROLLMENT:
            return 'is_eligible_for_reenrollment';
        case ChildFields.PRIOR_LIKES:
            return 'prior_likes';
        case ChildFields.PRIOR_DISLIKES:
            return 'prior_dislikes';
        case ChildFields.PRIOR_SITUATION_COMMENT:
        case ChildFields.PRIOR_SITUATION_COMMENT2:
            return 'prior_situation_comment';
        case ChildFields.PRIOR_CARE:
            return 'current_situation';
        case ChildFields.REASON_FOR_CHANGE:
            return 'reason_for_change';
        case ChildFields.SIBLING_IN_CARE:
            return 'is_sibling_in_care';
        case ChildFields.CURRENT_SITUATION:
            return 'current_situation';
        case ChildFields.CHILD_OF_STAFF:
            return 'is_child_of_staff';
        case ChildFields.PRIORITY_STATUS:
            return 'priority';
        default:
            return null;
    }
}

/**
* This tedious function takes in a field and returns the corresponding property,
* because the API does not currently tell us what property in what object each field connects to.
* I don't like all the strings, but they have to be set somewhere...
*
* NOTE: Because this is used for editing values, it links to the properties in DTOs, not the original entity.
*/
export function getStatusChangePropertyNameForField(field: Field): string | null {
    switch (field.value) {
        case ChildFields.ENROLLED_COMMENT:
        case ChildFields.LOST_OPPORTUNITY_COMMENT:
        case ChildFields.REJECTED_COMMENT:
        case ChildFields.TEMPORARY_LEAVE_COMMENT:
        case ChildFields.WAIT_LIST_COMMENT:
        case ChildFields.WITHDRAWN_COMMENT:
        case GuardianFields.LOST_OPPORTUNITY_COMMENT:
        case GuardianFields.REJECTED_COMMENT:
            return 'comments';
        case ChildFields.ACTUAL_START_DATE:
            return 'actual_start_date';
        case ChildFields.EXPECTED_START_DATE:
            return 'expected_start_date';
        case ChildFields.TEMPORARY_LEAVE_DATE:
        case ChildFields.WAIT_LIST_DATE:
        case ChildFields.WITHDRAWN_DATE:
            return 'date';
        case ChildFields.ENROLLED_REASON:
        case ChildFields.LOST_OPPORTUNITY_REASON:
        case ChildFields.REJECTED_REASON:
        case ChildFields.TEMPORARY_LEAVE_REASON:
        case ChildFields.WAIT_LIST_REASON:
        case ChildFields.WITHDRAWN_REASON:
        case GuardianFields.LOST_OPPORTUNITY_REASON:
        case GuardianFields.REJECTED_REASON:
            return 'reason';
        case ChildFields.CHILD_OF_STAFF:
            return 'wait_list_details.is_child_of_staff';
        case ChildFields.SIBLING_IN_CARE:
            return 'wait_list_details.is_sibling_in_care';
        case ChildFields.WAIT_LIST_FEE:
            return 'wait_list_details.fee';
        case ChildFields.WAIT_LIST_DATE_FEE_PAID:
            return 'wait_list_details.fee_paid_date';
        case ChildFields.WAIT_LIST_FEE_PAID_IN_FULL:
            return 'wait_list_details.is_fee_paid';
        case ChildFields.WAIT_LIST_TYPE:
            return 'wait_list_details.type';
        case ChildFields.PRIORITY_STATUS:
            return 'wait_list_details.priority';
        case ChildFields.ACCOUNT_IN_GOOD_STANDING:
            return 'withdrawn_details.good_standing';
        case ChildFields.ELIGIBLE_FOR_REENROLLMENT:
            return 'withdrawn_details.is_eligible_for_reenrollment';
        default:
            return null;
    }
}

/**
 * Get the correct property from an object.
 * There must be some way to make this recursive...
 *
 * @param propertyName
 * @param model
 * @param value -- If provided, set the property to that value
 * @param isSetNull -- Set value to null, if desired
 */
function accessProperty(propertyName: string, model: any, value: any = null, isSetNull = false): any {
    let prop, subProp, subSubProp;
    prop = propertyName;

    if (propertyName.includes('.')) {
        const props = propertyName.split('.');
        prop = props[0];
        subProp = props[1];
        subSubProp = props[2];
    }

    if (Object.prototype.hasOwnProperty.call(model, prop)) {
        if (!subProp) {
            if (value !== null) {
                model[prop as string] = value;
            }
            if (isSetNull) {
                model[prop as string] = null;
            }

            return model[prop as string];
        }

        if (!subSubProp && model[prop as string] && Object.prototype.hasOwnProperty.call(model[prop as string], subProp)) {
            if (value !== null) {
                model[prop as string][subProp as string] = value;
            }
            if (isSetNull) {
                model[prop as string][subProp as string] = null;
            }

            return model[prop as string][subProp as string];
        }

        if (subSubProp && Object.prototype.hasOwnProperty.call(model[prop as string], subProp) &&
            model[prop as string][subProp as string] &&
            Object.prototype.hasOwnProperty.call(model[prop as string][subProp as string], subSubProp)
        ) {
            if (value !== null) {
                model[prop as string][subProp as string][subSubProp as string] = value;
            }
            if (isSetNull) {
                model[prop as string][subProp as string][subSubProp as string] = null;
            }

            return model[prop as string][subProp as string][subSubProp as string];
        }

        if (subSubProp && !model[prop as string][subProp as string]) {
            if (value !== null) {
                model[prop as string][subProp as string] = value;
            }
            if (isSetNull) {
                model[prop as string][subProp as string] = null;
            }

            return model[prop as string][subProp as string];
        }
    }

    return null;
}

/**
 * Given a field and an object that should have that field as a property,
 * return the actual property from the model/object
 *
 * Example: field is First Name, model is a Child. Returns the child.first_name property.
 *
 * @param model
 * @param field
 */
export function getPropertyForFieldFromModel(model: any, field: Field) {
    const propertyName = getPropertyNameForField(field);

    if (!propertyName) {
        return null;
    }

    return accessProperty(propertyName, model);
}

/**
 * Sets a value for a given property tied to a field.
 *
 * @param model
 * @param field
 * @param value
 */
export function setPropertyForFieldFromModel(model: any, field: Field, value: any): void {
    const propertyName = getPropertyNameForField(field);

    if (!propertyName) {
        return;
    }

    accessProperty(propertyName, model, value);
}

/**
 * Allow values in a status change to be set to null
 *
 * @param statusChange
 * @param field
 */
export function clearPropertyForStatusChange(statusChange: StatusChangeInterface, field: Field): void {
    const propertyName = getStatusChangePropertyNameForField(field);
    if (!propertyName) {
        return;
    }

    accessProperty(propertyName, statusChange, null, true);
}

/**
 * Given a field and an object that should have that status change field as a property,
 * return the actual property from the model/object
 *
 * @param model
 * @param field
 */
export function getStatusPropertyForFieldFromModel(model: any, field: Field) {
    const propertyName = getStatusChangePropertyNameForField(field);
    if (!propertyName) {
        return null;
    }

    return accessProperty(propertyName, model);
}

/**
 * Sets a value for a given status change property tied to a field.
 *
 * @param model
 * @param field
 * @param value
 */
export function setStatusChangePropertyForFieldFromModel(model: any, field: Field, value: any): void {
    const propertyName = getStatusChangePropertyNameForField(field);
    if (!propertyName) {
        return;
    }

    accessProperty(propertyName, model, value);
}

/**
 * Get the list of options for a select list field
 *
 * @param field
 */
export function getCrmListForField(field: Field): CrmTypeList | null {
    if (field.select_list !== null) {
        // Extract the list name from the link, if available
        return field.select_list.links[0].href.replace('/api/v3/types/', '') as CrmTypeList;
    }

    // That didn't work? Ok. Hard-coded it is.
    switch (field.value) {
        case ChildFields.GENDER:
            return CrmTypeList.GENDER;
        case ChildFields.REASON_FOR_CHANGE:
            return CrmTypeList.REASON_FOR_CHANGE;
        case ChildFields.LOST_OPPORTUNITY_REASON:
        case GuardianFields.LOST_OPPORTUNITY_REASON:
            return CrmTypeList.REASONS_LOST_OPP;
        case ChildFields.REJECTED_REASON:
        case GuardianFields.REJECTED_REASON:
            return CrmTypeList.REJECTED_REASONS;
        case ChildFields.WAIT_LIST_REASON:
            return CrmTypeList.REASON_WAIT_LIST;
        case ChildFields.WAIT_LIST_TYPE:
            return CrmTypeList.WAIT_LIST_TYPE;
        case ChildFields.PRIORITY_STATUS:
            return CrmTypeList.WAIT_LIST_PRIORITY;
        case ChildFields.ENROLLED_REASON:
            return CrmTypeList.ENROLLED_REASON;
        case ChildFields.WITHDRAWN_REASON:
            return CrmTypeList.REASON_WITHDRAWN;
        case ChildFields.TEMPORARY_LEAVE_REASON:
            return CrmTypeList.REASON_TEMPORARY_LEAVE;
        case GuardianFields.PHONE_TYPE:
        case GuardianFields.ALTERNATE_PHONE_TYPE:
        case GuardianFields.ALT_CONTACT_PHONE_TYPE:
            return CrmTypeList.PHONE_TYPE;
        case GuardianFields.GENDER:
            return CrmTypeList.GENDER;
        case GuardianFields.PRIOR_CHILD_CARE:
            return CrmTypeList.PRIOR_CHILD_CARE;
        case GuardianFields.LOST_TO_COMPETITOR_REASON:
            return CrmTypeList.REASON_LOST_TO_COMPETITOR;
        case GuardianFields.CONTACT_METHOD:
            return CrmTypeList.CONTACT_METHOD;
    }

    return null;
}

/**
 * Returns the status id for a field if it's a status-specific field.
 * Otherwise, returns null.
 */
export function getStatusForField(field: Field): number | null {
    // Herein we list the fields that are status-specific
    const statusFields = {
        1: [
            ChildFields.EXPECTED_START_DATE
        ],
        2: [
            ChildFields.EXPECTED_START_DATE
        ],
        3: [
            ChildFields.EXPECTED_START_DATE
        ],
        // Wait list
        4: [
            ChildFields.WAIT_LIST_COMMENT,
            ChildFields.WAIT_LIST_DATE,
            ChildFields.WAIT_LIST_FEE,
            ChildFields.WAIT_LIST_FEE_PAID_IN_FULL,
            ChildFields.WAIT_LIST_DATE_FEE_PAID,
            ChildFields.WAIT_LIST_REASON,
            ChildFields.WAIT_LIST_TYPE,
            ChildFields.PRIORITY_STATUS,
            ChildFields.DATE_FEE_PAID,
            ChildFields.EXPECTED_START_DATE,
            ChildFields.CHILD_OF_STAFF,
            ChildFields.SIBLING_IN_CARE
        ],
        5: [
            ChildFields.EXPECTED_START_DATE
        ],
        // Enrolled
        6: [
            ChildFields.ENROLLED_COMMENT,
            ChildFields.ENROLLED_REASON,
            ChildFields.ACTUAL_START_DATE
        ],
        // Temp leave
        7: [
            ChildFields.TEMPORARY_LEAVE_COMMENT,
            ChildFields.TEMPORARY_LEAVE_DATE,
            ChildFields.TEMPORARY_LEAVE_REASON
        ],
        // Withdrawn
        8: [
            ChildFields.WITHDRAWN_COMMENT,
            ChildFields.WITHDRAWN_DATE,
            ChildFields.WITHDRAWN_REASON
        ],
        // Lost opp
        9: [
            GuardianFields.LOST_OPPORTUNITY_COMMENT,
            GuardianFields.LOST_OPPORTUNITY_REASON,
            GuardianFields.LOST_TO_COMPETITOR,
            GuardianFields.LOST_TO_COMPETITOR_REASON,
            ChildFields.LOST_OPPORTUNITY_COMMENT,
            ChildFields.LOST_OPPORTUNITY_REASON,
            ChildFields.LOST_TO_COMPETITOR,
            ChildFields.LOST_TO_COMPETITOR_REASON
        ],
        // Rejected
        10: [
            GuardianFields.REJECTED_COMMENT,
            GuardianFields.REJECTED_REASON,
            ChildFields.REJECTED_COMMENT,
            ChildFields.REJECTED_REASON
        ],
        11: [
            ChildFields.EXPECTED_START_DATE
        ]
    };

    for (const [statusId, fieldNames] of Object.entries(statusFields)) {
        for (const fieldName of fieldNames) {
            if (field.value === fieldName) {
                return parseInt(statusId);
            }
        }
    }
    return null;
}

/**
 * Is this date field used for enrollments?
 *
 * @param field
 */
export function isEnrollmentField(field: Field): boolean {
    if (field.is_guardian_field) {
        return false;
    }

    const enrollmentFields: Array<string> = [
        ChildFields.WITHDRAWN_DATE,
        ChildFields.EXPECTED_START_DATE,
        ChildFields.ACTUAL_START_DATE,
        ChildFields.ENROLLED_COMMENT,
        ChildFields.ENROLLED_REASON,
        ChildFields.TEMPORARY_LEAVE_DATE,
        ChildFields.TEMPORARY_LEAVE_COMMENT,
        ChildFields.TEMPORARY_LEAVE_REASON,
        ChildFields.WAIT_LIST_DATE,
        ChildFields.WAIT_LIST_TYPE,
        ChildFields.WAIT_LIST_REASON,
        ChildFields.WAIT_LIST_FEE_PAID_IN_FULL,
        ChildFields.WAIT_LIST_FEE,
        ChildFields.WAIT_LIST_DATE_FEE_PAID,
        ChildFields.WAIT_LIST_COMMENT,
        ChildFields.PRIORITY_STATUS,
        ChildFields.CHILD_OF_STAFF,
        ChildFields.SIBLING_IN_CARE,
        ChildFields.SCHEDULE_INFORMATION,
        ChildFields.LOST_OPPORTUNITY_COMMENT,
        ChildFields.LOST_OPPORTUNITY_REASON,
        ChildScheduleFields.MONDAY_AM,
        ChildScheduleFields.MONDAY_PM,
        ChildScheduleFields.TUESDAY_AM,
        ChildScheduleFields.TUESDAY_PM,
        ChildScheduleFields.WEDNESDAY_AM,
        ChildScheduleFields.WEDNESDAY_PM,
        ChildScheduleFields.THURSDAY_AM,
        ChildScheduleFields.THURSDAY_PM,
        ChildScheduleFields.FRIDAY_AM,
        ChildScheduleFields.FRIDAY_PM,
        ChildScheduleFields.SATURDAY_AM,
        ChildScheduleFields.SATURDAY_PM,
        ChildScheduleFields.SUNDAY_AM,
        ChildScheduleFields.SUNDAY_PM
    ];

    return enrollmentFields.includes(field.value);
}

export function isEnrollmentDate(field: Field): boolean {
    if (field.type.id !== FieldTypes.DATE) {
        return false;
    }

    const enrollmentDates: Array<string> = [
        ChildFields.WITHDRAWN_DATE,
        ChildFields.EXPECTED_START_DATE,
        ChildFields.ACTUAL_START_DATE,
        ChildFields.TEMPORARY_LEAVE_DATE,
        ChildFields.WAIT_LIST_DATE
    ];
    return enrollmentDates.includes(field.value);
}

/**
 * Like the method to get the property for a child/guardian, except for enrollments.
 *
 * @param field
 * @param enrollment
 */
export function getEnrollmentProperty(field: Field, enrollment: Enrollment | EnrollmentUpdateDtoInterface | EnrollmentCreateDtoInterface): EnrollmentDateReasonWrite | EnrollmentDateReason | WaitlistDetailsUpdate | WaitlistDetails | null {
    switch (field.value) {
        // Many cases fall through because they all land on the same enrollment property
        case ChildFields.WAIT_LIST_DATE:
        case ChildFields.WAIT_LIST_TYPE:
        case ChildFields.WAIT_LIST_REASON:
        case ChildFields.WAIT_LIST_COMMENT:
        case ChildFields.WAIT_LIST_FEE:
        case ChildFields.WAIT_LIST_DATE_FEE_PAID:
        case ChildFields.WAIT_LIST_FEE_PAID_IN_FULL:
        case ChildFields.PRIORITY_STATUS:
        case ChildFields.CHILD_OF_STAFF:
        case ChildFields.SIBLING_IN_CARE:
            if (!enrollment.wait_list) {
                enrollment.wait_list = new WaitlistWrite();
            }
            return enrollment.wait_list;
        case ChildFields.EXPECTED_START_DATE:
        case ChildFields.ACTUAL_START_DATE:
        case ChildFields.ENROLLED_REASON:
        case ChildFields.ENROLLED_COMMENT:
            if (!enrollment.enrollment) {
                enrollment.enrollment = new EnrollmentDateReasonWriteClass();
            }

            return enrollment.enrollment;
        case ChildFields.TEMPORARY_LEAVE_DATE:
        case ChildFields.TEMPORARY_LEAVE_REASON:
        case ChildFields.TEMPORARY_LEAVE_COMMENT:
            if (!enrollment.temporary_leave) {
                enrollment.temporary_leave = new EnrollmentDateReasonWriteClass();
            }

            return enrollment.temporary_leave;
        case ChildFields.WITHDRAWN_DATE:
        case ChildFields.WITHDRAWN_REASON:
        case ChildFields.WITHDRAWN_COMMENT:
            if (!enrollment.withdrawn) {
                enrollment.withdrawn = new EnrollmentDateReasonWriteClass();
            }

            return enrollment.withdrawn;
        default:
            return null;
    }
}

/**
 * Returns a list of relevant fields sorted in correct order.
 * Does not include hidden fields or status-related fields that are not relevant.
 *
 * @param type
 * @param fields
 * @param status
 */
function getSortedFields(type: FieldEntityType, fields: Array<Field>, status: number | undefined): Array<Field> {
    const sortedFields = sortFields(
        type,
        fields.filter(field => !field.is_hidden)
    );

    if (!status) {
        return sortedFields;
    }

    // Filter out status-related fields, as needed
    return sortedFields.filter((field) => {
        const fieldStatus = getStatusForField(field);
        if (!fieldStatus) {
            return true;
        }
        return fieldStatus === status;
    });
}

export function getSortedChildFields(status: number | undefined): Array<Field> {
    return getSortedFields(FieldEntityType.CHILD, fieldStore.storedChildFields, status);
}

export function getSortedGuardianFields(status: number | undefined): Array<Field> {
    return getSortedFields(FieldEntityType.GUARDIAN, fieldStore.storedGuardianFields, status);
}

export function emailValidationRules(): Array<Function> {
    return [
        (v: string) => {
            if (v.length > 0) {
                return /.+@.+\..*/.test(v) || 'Please enter valid email address';
            }

            return true;
        }
    ];
}

export function getExtraStatusDataFields(fieldEntities: Array<Field>) {
    const fields = new ExtraStatusDataFields();

    fieldEntities.forEach((field) => {
        if (field.is_child_field) {
            switch (field.value) {
                // Date fields.
                case ChildFields.ACTUAL_START_DATE:
                    fields.statusDateFields.actualStartDate = field;
                    return;
                case ChildFields.EXPECTED_START_DATE:
                    fields.statusDateFields.expectedStartDate = field;
                    return;
                case ChildFields.TEMPORARY_LEAVE_DATE:
                    fields.statusDateFields.tempLeaveDate = field;
                    return;
                case ChildFields.WAIT_LIST_DATE:
                    fields.statusDateFields.waitListDate = field;
                    return;
                case ChildFields.WITHDRAWN_DATE:
                    fields.statusDateFields.withdrawnDate = field;
                    return;
                // Wait list fields.
                case ChildFields.WAIT_LIST_REASON:
                    fields.waitListFields.reason = field;
                    return;
                case ChildFields.WAIT_LIST_COMMENT:
                    fields.waitListFields.comment = field;
                    return;
                case ChildFields.WAIT_LIST_TYPE:
                    fields.waitListFields.type = field;
                    return;
                case ChildFields.PRIORITY_STATUS:
                    fields.waitListFields.priorityStatus = field;
                    return;
                case ChildFields.CHILD_OF_STAFF:
                    fields.waitListFields.childOfStaff = field;
                    return;
                case ChildFields.SIBLING_IN_CARE:
                    fields.waitListFields.siblingInCare = field;
                    return;
                case ChildFields.WAIT_LIST_FEE:
                    fields.waitListFields.fee = field;
                    return;
                case ChildFields.WAIT_LIST_DATE_FEE_PAID:
                    fields.waitListFields.feePaidDate = field;
                    return;
                case ChildFields.WAIT_LIST_FEE_PAID_IN_FULL:
                    fields.waitListFields.feePaidInFull = field;
                    return;
                // Temp leave fields.
                case ChildFields.TEMPORARY_LEAVE_REASON:
                    fields.tempLeaveFields.reason = field;
                    return;
                case ChildFields.TEMPORARY_LEAVE_COMMENT:
                    fields.tempLeaveFields.comment = field;
                    return;
                // Enrolled fields.
                case ChildFields.ENROLLED_REASON:
                    fields.enrolledFields.reason = field;
                    return;
                case ChildFields.ENROLLED_COMMENT:
                    fields.enrolledFields.comment = field;
                    return;
                // Withdrawn fields.
                case ChildFields.WITHDRAWN_REASON:
                    fields.withdrawnFields.reason = field;
                    return;
                case ChildFields.WITHDRAWN_COMMENT:
                    fields.withdrawnFields.comment = field;
                    return;
                case ChildFields.ACCOUNT_IN_GOOD_STANDING:
                    fields.withdrawnFields.goodStanding = field;
                    return;
                case ChildFields.ELIGIBLE_FOR_REENROLLMENT:
                    fields.withdrawnFields.reEnrollment = field;
                    return;
                // Lost Opportunity fields.
                case ChildFields.LOST_OPPORTUNITY_REASON:
                    fields.lostOppFields.child.reason = field;
                    return;
                case ChildFields.LOST_OPPORTUNITY_COMMENT:
                    fields.lostOppFields.child.comment = field;
                    return;
                // Rejected fields.
                case ChildFields.REJECTED_REASON:
                    fields.rejectedFields.child.reason = field;
                    return;
                case ChildFields.REJECTED_COMMENT:
                    fields.rejectedFields.child.comment = field;
                    return;
                // Shared fields.
                case ChildFields.LOST_TO_COMPETITOR:
                    fields.withdrawnFields.competitor = field;
                    fields.lostOppFields.child.competitor = field;
                    return;
                default:
                    return;
            }
        }

        if (field.is_guardian_field) {
            switch (field.value) {
                // Lost Opportunity fields.
                case GuardianFields.LOST_OPPORTUNITY_REASON:
                    fields.lostOppFields.guardian.reason = field;
                    return;
                case GuardianFields.LOST_OPPORTUNITY_COMMENT:
                    fields.lostOppFields.guardian.comment = field;
                    return;
                // Rejected fields.
                case GuardianFields.REJECTED_REASON:
                    fields.rejectedFields.guardian.reason = field;
                    return;
                case GuardianFields.REJECTED_COMMENT:
                    fields.rejectedFields.guardian.comment = field;
                    return;
                // Shared fields.
                case GuardianFields.LOST_TO_COMPETITOR:
                    fields.lostOppFields.guardian.competitor = field;
                    break;
            }
        }
    });

    return fields;
}

export function getFieldByName(fieldName: string): Field | undefined {
    return fieldStore.stored.find(field => field.value === fieldName);
}

/**
 * Get the name to show for the enrollment rep field.
 *
 * @param teamName
 */
export function getEnrollmentRepFieldName(teamName: string): string {
    return teamName + ' Rep';
}

/**
 * Whether or not the field is a user list field.
 *
 * @param field
 */
export function isUserListField(field: Field): boolean {
    switch (field.value) {
        case GuardianFields.ENROLLMENT_REP:
            return true;
        default:
            return false;
    }
}

/**
 * Get the unique props for the user list field.
 *
 * @param field
 */
export function getUserListFieldProps(field: Field): Record<string, any> | null {
    if (!isUserListField(field)) {
        return null;
    }

    switch (field.value) {
        default:
            return {
                onlyET: true,
                noDefault: true
            };
    }
}

/**
 * extracted method to generate guardian fields list in FamilyOtherFields & AddFamily modal.
 *
 * @param filteredFields
 * @param requiredOnly
 * @param statusId
 * @param ecSettings
 * @param newHub
 */
export function generateGuardianFieldsList(filteredFields: Array<string>, requiredOnly: boolean, statusId: number | undefined, ecSettings: EnrollmentCenterSettings, newHub = true): Array<Field> {
    let sortedFields = getSortedGuardianFields(statusId).filter(field => !filteredFields.includes(field.value));
    if (requiredOnly) {
        sortedFields = sortedFields.filter(field => field.is_client_required);
    }
    if (!featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE)) {
        sortedFields = sortedFields.filter(field => crmModeFields.includes(field.value));
    }
    if (!featureStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_12)) {
        sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== GuardianTypeFields.LEAD_TYPE_ONE && field.select_list_name !== GuardianTypeFields.LEAD_TYPE_TWO));
    }
    if (!featureStore.isFeatureEnabled(FeatureConstants.LEAD_TYPE_34)) {
        sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== GuardianTypeFields.LEAD_TYPE_THREE && field.select_list_name !== GuardianTypeFields.LEAD_TYPE_FOUR));
    }
    if (featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER) && ecSettings) {
        // First, change the enrollment rep name to reflect the name of the team
        sortedFields.map((field: Field) => {
            if (field.value === GuardianFields.ENROLLMENT_REP) {
                field.value = getEnrollmentRepFieldName(ecSettings.name);
            }
            return field;
        });
        // Next, sort all of the fields again
        sortedFields.sort((a, b) => {
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
                return -1;
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        // Then, replace the name again with the field default
        sortedFields.map((field: Field) => {
            if (field.value === ecSettings.name + ' Rep') {
                field.value = GuardianFields.ENROLLMENT_REP;
            }
            return field;
        });
    }
    // sort lead types 1 - 4 to the end
    if (newHub) {
        sortedFields.sort((a, b) => {
            if (a.value.toLowerCase() < b.value.toLowerCase()) {
                return -1;
            }
            if (a.value.toLowerCase() > b.value.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        for (const leadType of [GuardianTypeFields.LEAD_TYPE_ONE, GuardianTypeFields.LEAD_TYPE_TWO, GuardianTypeFields.LEAD_TYPE_THREE, GuardianTypeFields.LEAD_TYPE_FOUR]) {
            const field = sortedFields.find((field) => !!field.select_list_name && field.select_list_name === leadType);
            if (field) {
                sortedFields.push(sortedFields.splice(sortedFields.indexOf(field), 1)[0]);
            }
        }
    }
    return sortedFields;
}

/**
 * method to generate child fields list in  AddFamily modal.
 *
 * @param filteredFields
 * @param requiredOnly
 * @param statusId
 */
export function generateChildFieldsList(filteredFields: Array<string>, requiredOnly: boolean, statusId: number | undefined): Array<Field> {
    let sortedFields = getSortedChildFields(statusId).filter(field => !filteredFields.includes(field.value));
    if (requiredOnly) {
        sortedFields = sortedFields.filter(field => field.is_client_required);
    }
    if (!featureStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12)) {
        sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== ChildTypeFields.CHILD_TYPE_ONE && field.select_list_name !== ChildTypeFields.CHILD_TYPE_TWO));
    }
    if (!featureStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34)) {
        sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== ChildTypeFields.CHILD_TYPE_THREE && field.select_list_name !== ChildTypeFields.CHILD_TYPE_FOUR));
    }
    // remove scheduling type fields from the list
    sortedFields = sortedFields.filter(field => !childScheduleFields.includes(field.value));
    // sort lead types 1 - 4 to the end
    sortedFields.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
            return -1;
        }
        if (a.value.toLowerCase() > b.value.toLowerCase()) {
            return 1;
        }
        return 0;
    });
    for (const leadType of [ChildTypeFields.CHILD_TYPE_ONE, ChildTypeFields.CHILD_TYPE_TWO, ChildTypeFields.CHILD_TYPE_THREE, ChildTypeFields.CHILD_TYPE_FOUR]) {
        const field = sortedFields.find((field) => !!field.select_list_name && field.select_list_name === leadType);
        if (field) {
            sortedFields.push(sortedFields.splice(sortedFields.indexOf(field), 1)[0]);
        }
    }
    return sortedFields;
}
