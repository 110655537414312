






























































































































































































































































import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { Family } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { Component, Mixins } from 'vue-property-decorator';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import { baseLocales } from '@/locales/locales';
import { LocaleMixin } from '@/locales/locale-mixin';
import FamilySearch from '@/families/components/FamilySearch.vue';

const authState = getModule(AuthStore, store);
const familiesRepo = new FamiliesRepository();

@Component({
    components: { SendMessageModal, FamilySearch }
})
export default class Samples extends Mixins(LocaleMixin) {
    private dialogOpen = false;
    private numTours = 0;
    private tourOptions = [0, 1, 2, 3, 4];
    private menuItems = [1, 2, 3, 4, 5, 6];
    private sendMessage = false;
    private family: Family | null = null;

    async mounted() {
        this.family = (await familiesRepo.get()).entities[0];
    }

    private get localeOptions() {
        const options = [];
        for (const [localeName, locale] of Object.entries(baseLocales)) {
            options.push({ text: locale.label, value: localeName });
        }
        return options;
    }

    private get username(): string {
        return authState.username;
    }

    private alwaysError = [
        (v: string) => v.length > 100 || 'errors for you!'
    ];

    openDialog() {
        this.dialogOpen = true;
    }

    closeDialog() {
        this.dialogOpen = false;
    }

    doNothing() {
        return '';
    }
};
