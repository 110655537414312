import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    CustomFieldValue,
    CustomFieldValueCreateDto,
    CustomFieldValueUpdateDto
} from '@/crm-types/custom-fields/custom-fields-types';

export class CustomFieldValuesRepository extends AbstractRepository<CustomFieldValue> {
    endpoint = 'types/fields/custom';

    public async getValuesForField(fieldId: number): Promise<Array<CustomFieldValue>> {
        return (await this.get(null, {}, `${this.endpoint}/${fieldId}/values`)).entities as Array<CustomFieldValue>;
    }

    public async addValueToField(fieldId: number, value: CustomFieldValueCreateDto): Promise<CustomFieldValue> {
        return (await this.client.post(`${this.endpoint}/${fieldId}/values`, value)).data;
    }

    public async deleteValueFromField(fieldId: number, valueId: number): Promise<void> {
        await this.client.delete(`${this.endpoint}/${fieldId}/values/${valueId}`);
    }

    public async updateValueForField(fieldId: number, value: CustomFieldValueUpdateDto): Promise<CustomFieldValue> {
        return (await this.client.patch(`${this.endpoint}/${fieldId}/values/${value.id}`, value)).data;
    }
}
