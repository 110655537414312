















































import { LocaleMixin } from '@/locales/locale-mixin';
import { SavedReportMapper } from '@/reports/mappers/saved-report-mapper';
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { SavedReport, SavedReportDto } from '@/reports/models/saved-report';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { SavedReportsStore } from '@/reports/store/saved-reports-store';
import { getReportNameCollision } from '@/reports/report-utils';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const savedReportMapper = new SavedReportMapper();
const savedReportsRepo = new SavedReportsRepository();
const savedReportsStore = getModule(SavedReportsStore);

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class ReportFilterModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // Props
    // v-model whether we should show it.
    @Prop({ default: false }) readonly value!: boolean;

    // The data for the report.
    @Prop({ required: true }) readonly reportData: any;

    // existing saved report
    @Prop() readonly savedReport!: SavedReport | null;

    @Prop({ required: true }) readonly userId!: number;
    @Ref('form') readonly form!: VForm;

    // Properties
    private loadingKey = 'addFilterModal';
    private validForm = false;
    private localSavedReport = new SavedReportDto();

    private nameRules = [
        (v: string|number) => !!v || 'Please enter a value',
        (v: string) => v.length <= 40 || 'Max 40 characters'
    ];

    // Getters
    get nonEditable(): boolean {
        return this.savedReport !== null && !this.savedReport.is_editable;
    }

    get savedReports() {
        return savedReportsStore.stored;
    }

    get modelValue(): boolean {
        return this.value;
    }

    // Setters
    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    get isValid(): boolean {
        return this.validForm;
    }

    created() {
        if (this.savedReport) {
            this.localSavedReport = savedReportMapper.toUpdateDto(this.savedReport);
            this.$nextTick(() => {
                // Validate form when we popup the modal.
                this.form.validate();
            });
        }
    }

    // Methods
    private close() {
        this.form.reset();
        this.modelValue = false;
    }

    private async postNewFilter() {
        loadingState.loadingIncrement(this.loadingKey);
        this.localSavedReport.report_data = JSON.stringify(this.reportData);
        this.localSavedReport.shared_name = this.localSavedReport.name;
        let newReport: SavedReport | null = null;
        if (this.savedReport && !this.savedReport.is_standard_report && this.savedReport.name === this.localSavedReport.name) {
            if (this.nonEditable) {
                await this.$swal({
                    icon: 'warning',
                    text: 'This report cannot be edited. Please choose a different name to save this report.'
                });
                loadingState.loadingStop(this.loadingKey);
                return;
            }
            await savedReportsRepo.updateReport(this.userId, this.savedReport.id, this.localSavedReport);
        } else {
            const collisionId = getReportNameCollision(this.savedReports, this.localSavedReport.name);
            if (collisionId) {
                const prompt = await this.$swal({
                    icon: 'warning',
                    text: 'You are about to overwrite an existing report',
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Proceed'
                });
                if (!prompt.isConfirmed) {
                    loadingState.loadingStop(this.loadingKey);
                    return;
                }
                await savedReportsRepo.deleteReport(this.userId, collisionId);
            }
            newReport = await savedReportsRepo.createReport(this.userId, this.localSavedReport);
        }
        await savedReportsStore.retrieveAllReports(this.userId);
        if (newReport) {
            this.$emit(EventTypes.NEW_REPORT, newReport);
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }
}
