
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VTooltip from 'vuetify/lib/components/VTooltip/VTooltip';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VTooltip] });
export default base.extend({
    name: 'base-tooltip',
    props: {
        contentClass: {
            type: String,
            default: 'base-tooltip__content'
        },
        // set open on hover
        openOnHover: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        // add base-tooltip to the css class of the rendered element
        classes(): object {
            return {
                ...VTooltip.options.computed.classes.call(this),
                'base-tooltip': true
            };
        }
    }
});
