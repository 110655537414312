












import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { baseUrl } from '@/core/base-url';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { doLogout, legacyLoginWrapper } from '@/auth/auth';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);

@Component
export default class PstSettings extends Mixins(LocaleMixin) {
    private settingsUrl = '';
    private loadingKey = 'pstFrame';
    private isLoaded = false;

    async mounted() {
        if (authState.token) {
            loadingState.loadingIncrement(this.loadingKey);
            await legacyLoginWrapper(authState);
            this.settingsUrl = baseUrl + '/pages/pst-settings';
        }
    }

    async beforeDestroy() {
        await doLogout();
    }

    private async loaded() {
        this.isLoaded = true;
        loadingState.loadingStop(this.loadingKey);
    }
}
