import { Family } from '@/families/models/family';
import { SixAgentPrefillInfo } from '@/integrations/models/six-agent';
import { parsePhoneNumber } from 'libphonenumber-js';

export class SixAgentUtils {
    addPossibleContact(family: Family): void {
        const id = family.id;
        let name = family.primary_guardian.first_name + ' ' + family.primary_guardian.last_name;
        if (family.center) {
            name += ' (' + family.center.values.code + ')';
        }
        // six agent does weird stuff with the name, we need to sanitize the string
        // should be fine for quick discernment between leads
        name = name.replace(/['"]/g, '');
        window.sixAgentInterface.addWhoHistory(id, name, 'N');
    }

    static matchPrefill(a: SixAgentPrefillInfo, b: SixAgentPrefillInfo) {
        if (a.email && b.email && a.email === b.email) {
            return true;
        }
        if (a.phoneNumber && b.phoneNumber && a.phoneNumber === b.phoneNumber) {
            return true;
        }
        return false;
    }

    static normalizePhone(ani: string | null) {
        if (!ani) {
            return null;
        }
        try {
            const parsed = parsePhoneNumber(ani, 'US');
            if (!parsed) {
                return null;
            }
            return parsed.number.toString();
        } catch (ex) {
            return null;
        }
    }
}
