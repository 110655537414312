
















import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { PageTitleMixin } from '@/core/page-title-mixin';
import BasePageTitle from '@/components/base/BasePageTitle.vue';

const appState = getModule(AppStateStore);

@Component({
    components: { BasePageTitle }
})
export default class GroupHistoryStatsHeader extends Mixins(LocaleMixin, PageTitleMixin) {
    /**
     * The title for the group history page.
     */
    @Prop({ type: String, required: true }) readonly title!: string;

    /**
     * Get the current org
     */
    get org() {
        return appState.storedCurrentOrg;
    }

    /**
     * Go back to the histories table when the org is changed.
     */
    @Watch('org')
    private orgChanged() {
        this.$router.push({ name: 'group-comm-history-report' });
    }

    @Watch('title')
    updateTitle(): void {
        this.setPageTitle(this.title);
    }

    mounted() {
        this.updateTitle();
    }
}
