import { AbstractRepository } from '@/repositories/abstract-repository';
import { Task, TaskCreateDto } from '@/tasks/models/task-models';

export class TasksPostPutRepository extends AbstractRepository<TaskCreateDto> {
    readonly endpoint = 'tasks';

    public async addTask(task: TaskCreateDto): Promise<Task> {
        const response = await this.client.post(this.endpoint, task);
        return response.data;
    }
}
