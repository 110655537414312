














































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';
import { NylasCalendarListItem } from '@/integrations/models/nylas';

@Component({
    components: { BaseClose }
})
export default class NylasCalendarSelect extends Mixins(VmodelShowMixin, LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) readonly calendars!: Array<NylasCalendarListItem>;

    private loadingKey = 'nylasCalendarSelect';
    private calendar: string | null = null;

    get items(): Array<any> {
        const options: Array<any> = [];
        this.calendars.forEach((calendar: NylasCalendarListItem) => {
            options.push({ value: calendar.id, text: calendar.name });
        });

        return options;
    }

    private close() {
        this.calendar = null;
        this.$emit(EventTypes.CLOSE);
        this.modelValue = false;
    }

    private next() {
        this.$emit(EventTypes.NEXT, this.calendar);
        this.modelValue = false;
    }
}
