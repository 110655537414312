



















import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { StandardReportType } from '@/reports/models/saved-report';
import { EventTypes } from '@/constants/event-type-constants';
import { StandardReportIdentifier } from '@/reports/report-constants';

@Component
export default class ViewRadioOptions extends Mixins(LocaleMixin) {
    @Prop({ default: null, required: true }) readonly standardReportType!: StandardReportType | null;
    @Prop({ default: false }) readonly resetValues!: boolean;

    private selectedStandardReportType: StandardReportIdentifier | string | null = null;

    @Watch('selectedStandardReportType')
    private emitUpdate() {
        this.$emit(EventTypes.REPORT_VIEW_SELECTED, this.selectedStandardReportType);
    }

    mounted() {
        if (this.resetValues && this.standardReportType) {
            const defaultRadioValue = this.standardReportType.reports[0].standard_report_identifier;
            if (defaultRadioValue) {
                this.selectedStandardReportType = defaultRadioValue;
            }
        }
    }
}
