
























import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import PendingAndCommToDos from '@/dashboards/components/LocationDashboardTabs/PendingAndCommToDos.vue';
import { EventTypes } from '@/constants/event-type-constants';
import TaskMeetingToDos from '@/dashboards/components/LocationDashboardTabs/TaskMeetingToDos.vue';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const featuresStore = getModule(FeaturesStore);

@Component({
    components: { TaskMeetingToDos, PendingAndCommToDos }
})
export default class LocationDashboardToDoWrapper extends Mixins(LocaleMixin) {
    @Prop() isCenter!: boolean;
    @Prop() isMineOnly!: boolean;
    @Prop() includeMeetings!: boolean | null;
    @Prop({ default: false }) readonly etDashMode!: boolean;
    private pendAndCommCount = 0;
    private tasksCount = 0;
    private countEvent = EventTypes.COUNT;
    private firstPendCommLoad = true;
    private firstTasksLoad = true;

    private pendAndCommExpanded = true;
    private tasksExpanded = false;

    get totalCount() {
        return this.pendAndCommCount + this.tasksCount;
    }

    private get isInboxEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_EMAILS) || featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_TEXTS);
    }

    updatePendAndCommCount(count: number) {
        this.pendAndCommCount = count;
        this.$emit(this.countEvent, this.totalCount);
        if (this.firstPendCommLoad && this.pendAndCommCount === 0) {
            this.pendAndCommExpanded = false;
            this.tasksExpanded = true;
        }
        if (this.pendAndCommCount > 0) {
            this.pendAndCommExpanded = true;
        }
        this.firstPendCommLoad = false;
    }

    updateTasksCount(count: number) {
        this.tasksCount = count;
        this.$emit(this.countEvent, this.totalCount);
    }
}
