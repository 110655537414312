var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-col',{staticClass:"pa-0"},[(!_vm.isEditing)?_c('v-data-table',{staticClass:"truncated-columns view-drips-table",attrs:{"id":"family-drip-campaigns-table","items":_vm.viewRows,"headers":_vm.headers,"disable-pagination":"","disable-sort":"","hide-default-footer":"","dense":"","items-per-page":-1},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('tr',{staticStyle:{"visibility":"hidden"}})]},proxy:true},{key:"item.campaignName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate campaign-name",attrs:{"title":item.campaignName}},[_vm._v(" "+_vm._s(item.campaignName)+" ")])]}},{key:"item.dripName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate drip-name",attrs:{"title":item.dripName}},[_vm._v(" "+_vm._s(item.dripName)+" ")])]}},{key:"item.templateName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.templateName}},[_vm._v(" "+_vm._s(item.templateName)+" ")])]}},{key:"item.sendDatetime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.sendDatetime ? _vm.formatDateTime(item.sendDatetime, _vm.timezone) : ''}},[_vm._v(" "+_vm._s(item.sendDatetime ? _vm.formatDateTime(item.sendDatetime, _vm.timezone) : '')+" ")])]}},{key:"item.dripStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.dripStatus}},[_vm._v(" "+_vm._s(item.dripStatus)+" ")])]}}],null,false,1569652047)}):_c('v-data-table',{staticClass:"truncated-columns edit-drips-table",attrs:{"items":_vm.familyCampaignsLocal,"headers":_vm.editHeaders,"disable-pagination":"","disable-sort":"","hide-default-footer":"","dense":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header.action",fn:function(ref){return [_c('base-button-secondary',{staticClass:"add-drip-button",on:{"click":function($event){$event.stopPropagation();return _vm.openAdd($event)}}},[_vm._v(" Add ")])]}},{key:"item.campaignName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate campaign-name",attrs:{"title":item.drip_campaign.values.name}},[_vm._v(" "+_vm._s(item.drip_campaign.values.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isActive(item) ? 'Active' : 'Inactive')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isActive(item))?_c('base-button-secondary',{on:{"click":function($event){return _vm.cancelDrip(item)}}},[_vm._v(" Cancel ")]):_vm._e()]}}])})],1),(!_vm.isEditing)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('base-button-secondary',{staticClass:"add-edit-mode-button",on:{"click":function($event){$event.stopPropagation();_vm.isEditing = true}}},[_vm._v(" Add/Edit ")])],1)],1):_vm._e(),(_vm.isEditing)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('base-button-secondary',{on:{"click":function($event){_vm.isEditing = false}}},[_vm._v(" Back ")])],1)],1):_vm._e(),_c('base-dialog',{attrs:{"content-class":"dialog-narrow"},model:{value:(_vm.isAddOpen),callback:function ($$v) {_vm.isAddOpen=$$v},expression:"isAddOpen"}},[_c('base-card',{staticClass:"add-drip-card"},[_c('v-card-title',[_vm._v(" Add Drip Campaign ")]),_c('v-card-text',[_c('v-form',{ref:"addForm",model:{value:(_vm.validAdd),callback:function ($$v) {_vm.validAdd=$$v},expression:"validAdd"}},[_c('emphasized-select',{attrs:{"label":"Drip Campaign","items":_vm.addableCampaigns,"item-value":"id","item-text":"name","rules":_vm.requiredField},model:{value:(_vm.addCampaignId),callback:function ($$v) {_vm.addCampaignId=$$v},expression:"addCampaignId"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('base-button-primary',{staticClass:"add-drip-apply-button",attrs:{"disabled":!_vm.validAdd},on:{"click":_vm.doAdd}},[_vm._v(" Apply ")]),_c('base-button-secondary',{on:{"click":function($event){_vm.isAddOpen = false}}},[_vm._v(" Cancel ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }