import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Child, ChildCreateDtoInterface, ChildUpdateDtoInterface } from '@/families/models/child';
import { IntegrationExportChildDtoInterface } from '@/integrations/models/integration';
import { CustomValuesMapper } from '@/crm-types/custom-fields/mappers/custom-values-mapper';

const customValueMapper = new CustomValuesMapper();

export class ChildMapper implements AbstractMapper<Child> {
    toCreateDto(entity: Child): ChildCreateDtoInterface {
        const customValues = [];
        if (entity.custom_values) {
            for (const customValue of entity.custom_values) {
                customValues.push(customValueMapper.toDto(customValue));
            }
        }

        const dto = {
            status: entity.status ? entity.status!.id : 0,
            first_name: entity.first_name,
            last_name: entity.last_name,
            date_of_birth: entity.date_of_birth,
            comments: entity.comments,
            preferred_name: entity.preferred_name,
            gender: entity.gender,
            added_date: entity.added_date,
            custom_values: customValues,
            is_eligible_for_reenrollment: entity.is_eligible_for_reenrollment,
            good_standing: entity.good_standing,
            is_child_of_staff: entity.is_child_of_staff,
            is_estimated_date_of_birth: entity.is_estimated_date_of_birth,
            current_situation: entity.current_situation ? entity.current_situation.id : null,
            is_sibling_in_care: entity.is_sibling_in_care,
            prior_dislikes: entity.prior_dislikes,
            prior_likes: entity.prior_likes,
            prior_situation_comment: entity.prior_situation_comment,
            reason_for_change: entity.reason_for_change ? entity.reason_for_change.id : null
        } as ChildCreateDtoInterface;

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_1')) {
            dto.custom_type_1 = entity.custom_type_1 ? entity.custom_type_1.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_2')) {
            dto.custom_type_2 = entity.custom_type_2 ? entity.custom_type_2.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_3')) {
            dto.custom_type_3 = entity.custom_type_3 ? entity.custom_type_3.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_4')) {
            dto.custom_type_4 = entity.custom_type_4 ? entity.custom_type_4.id : null;
        }

        return dto;
    }

    toUpdateDto(entity: Child): ChildUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as ChildUpdateDtoInterface;
        dto.id = entity.id as number;
        return dto;
    }

    toIntegrationExportDto(entity: Child): IntegrationExportChildDtoInterface {
        return {
            child_id: entity.id as number
        };
    }
}
