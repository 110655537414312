// Encode a file to base64
export function encodeFile(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // split removes the prepended mime type info
        reader.onload = () => resolve((reader.result as string).split(',')[1]);
        reader.onerror = error => reject(error);
    });
}
export function downloadFile(fileUrl: string, filename: string): string {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    // Open the link for the user
    link.click();
    return link.href;
}

export function openFile(fileContents: string, filename: string, mimeType: string) {
    const fileBlob = new Blob([fileContents], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    const href = downloadFile(fileUrl, filename);
    URL.revokeObjectURL(href);
}

export function openFileBase64(fileContents: string, filename: string, mimeType: string, newTab = false) {
    // Create a URL we can open
    const fileUrl = 'data:' + mimeType + ';base64,' + fileContents;
    const link = document.createElement('a');
    link.href = fileUrl;
    if (newTab) {
        link.target = '_blank';
    }
    link.download = filename;
    // Open the link for the user
    link.click();
}
