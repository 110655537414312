




































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: { BaseClose }
})
export default class RejectFamily extends Mixins(LocaleMixin) {
    @Prop({
        type: Boolean,
        default: false
    }) readonly dialog!: boolean;

    private closeDialog() {
        this.$emit(EventTypes.CLOSE);
    }

    private rejectFamily() {
        this.$emit(EventTypes.FAMILY_REJECTED);
    }
}
