























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    WorkflowActionSettingsWithSendTimeDto,
    WorkflowCreateDto
} from '@/automation/workflows/models/workflow-models';
import { workflowDaysBeforeFields, workflowTimeUnits } from '@/automation/workflows/constants/workflow-constants';
import { getWorkflowConditionTypes } from '@/automation/workflows/workflow-condition-utils';

@Component
export default class WorkflowActionDaysBeforePartial extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsWithSendTimeDto;
    @Prop() readonly dto!: WorkflowCreateDto;

    private daysBeforeMode = false;
    private units = workflowTimeUnits;

    get settings(): WorkflowActionSettingsWithSendTimeDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsWithSendTimeDto) {
        this.$emit('input', settings);
    }

    get daysBeforeOptions() {
        const allowedFieldTypes = getWorkflowConditionTypes(this.dto);
        return workflowDaysBeforeFields.filter((field) => {
            return allowedFieldTypes.includes(field.type);
        });
    }

    created() {
        if (this.settings.days_before_field) {
            this.daysBeforeMode = true;
        }
    }

    @Watch('daysBeforeMode')
    private modeChanged() {
        if (!this.daysBeforeMode) {
            this.settings.days_before_field = null;
        } else {
            if (!this.settings.send_delay_units) {
                this.settings.send_delay_units = 1;
            }
            if (!this.settings.send_delay_amount) {
                this.settings.send_delay_amount = 0;
            }
        }
    }

    @Watch('daysBeforeOptions', { immediate: true, deep: true })
    optionsChanged() {
        if (this.daysBeforeOptions.length === 0) {
            this.daysBeforeMode = false;
            this.settings.days_before_field = null;
        }
    }
}
