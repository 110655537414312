
































import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import type { FamiliesFilterDtoInterface } from '@/filters/models/families-filter';

@Component
export default class FilterDateRange extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: FamiliesFilterDtoInterface;
    @Prop() readonly fieldPrefix!: string;
    @Prop() readonly label!: string;
    @Prop({ default: false }) readonly isDisabled!: boolean;

    get endingBirthDate() {
        return this.filter.birth_date_end;
    }

    get endField() {
        return this.fieldPrefix + '_end';
    }

    get filter(): FamiliesFilterDtoInterface {
        return this.value;
    }

    set filter(newValue: FamiliesFilterDtoInterface) {
        this.$emit('input', newValue);
    }

    get startingBirthDate() {
        return this.filter.birth_date_start;
    }

    get startField() {
        return this.fieldPrefix + '_start';
    }

    @Watch('startingBirthDate')
    updateStartingBirthDateOutput() {
        if (this.filter.birth_date_start !== null && this.filter.birth_date_start !== '') {
            this.$emit('input-starting-birth-date', true);
        } else {
            if (this.filter.birth_date_end === null || this.filter.birth_date_end === '') {
                this.$emit('input-starting-birth-date', false);
            } else {
                this.$emit('input-starting-birth-date', true);
            }
        }
    }

    @Watch('endingBirthDate')
    updateEndingBirthDateOutput() {
        if (this.filter.birth_date_end !== null && this.filter.birth_date_end !== '') {
            this.$emit('input-ending-birth-date', true);
        } else {
            if (this.filter.birth_date_start === null || this.filter.birth_date_start === '') {
                this.$emit('input-ending-birth-date', false);
            } else {
                this.$emit('input-ending-birth-date', true);
            }
        }
    }
}
