import { PermissionName, UserPermission } from '@/staff/models/user-permission-models';
import { UserPermissionsRepository } from '@/staff/repositories/user-permissions-repository';
import { Action, Module, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import store from '@/store/index';

export interface UserPermissionsState extends AbstractEntityState<UserPermission> {
    entities: Array<UserPermission>;
    userId: number;
}

export interface PermissionByNameMapping {
    userId: number;
    permissionName: PermissionName | string;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.USER_PERMISSIONS
})
export class UserPermissionsStore extends AbstractApiStore<UserPermission> implements UserPermissionsState {
    readonly repository = new UserPermissionsRepository();
    userId = 0;

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<UserPermission> {
        return this.entities;
    }

    // initialize for data retrieval
    // don't retrieve if we already have data
    @Action
    public async init(userId: number, force = false) {
        if (this.entities.length === 0 || userId !== this.userId || force) {
            await this.initPromise({
                hash: 'perms-' + String(userId),
                closure: async () => {
                    await this.retrieveAllPermissions(userId);
                }
            });
        }
    }

    @Action({ rawError: true })
    public async getPermissionByName(permissionByNameMapping: PermissionByNameMapping): Promise<UserPermission | null> {
        if (!this.stored.length || this.userId !== permissionByNameMapping.userId) {
            await this.retrieveAllPermissions(permissionByNameMapping.userId);
        }
        for (const userPermission of this.stored) {
            if (userPermission.label === permissionByNameMapping.permissionName) {
                return userPermission;
            }
        }
        return null;
    }

    @Action({ commit: 'storePermissions', rawError: true })
    public async retrieveAllPermissions(userId: number) {
        const entities = await this.repository.getPermissions(userId);
        return { userId, entities };
    }

    @Mutation
    private storePermissions(permissions: { userId: number; entities: Array<UserPermission> }): void {
        this.userId = permissions.userId;
        this.entities = permissions.entities;
    }
}
