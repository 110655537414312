import { AbstractRepository } from '@/repositories/abstract-repository';
import { CustomDashboard, CustomDashboardDto, DashElementType } from '@/dashboards/models/custom-dashboards';

export class CustomDashboardsRepository extends AbstractRepository<CustomDashboard> {
    readonly endpoint = 'dashboards';

    async getAvailableElements(): Promise<Array<DashElementType>> {
        const results = await this.client.get(this.endpoint + '/elements');
        return results.data;
    }

    async createDash(dto: CustomDashboardDto): Promise<CustomDashboard> {
        const result = await this.client.post(this.endpoint, dto);
        return result.data;
    }

    async updateDash(dashId: number, dto: CustomDashboardDto): Promise<CustomDashboard> {
        const result = await this.client.put(this.endpoint + '/' + dashId, dto);
        return result.data;
    }

    async deleteDash(dashId: number): Promise<void> {
        await this.client.delete(this.endpoint + '/' + dashId);
    }
}
