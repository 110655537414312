import { daysAgo } from '@/date-time/date-time-utils';

// Get the number of days ago from the given date.
// The given date is expected to be in the past.
export const daysAgoFromToday = (value: string | Date) => {
    const diff = daysAgo(value);
    const appendText = diff === 1 ? ' day ago' : ' days ago';
    return diff === 0 ? 'less than 1 day ago' : diff + appendText;
};

/**
 * Deep search an object for a key/value, return it and it's children.
 *
 * @deprecated doesn't necessarily return the node you want
 * @param obj
 * @param key
 * @param value
 */
export const findNestedObject = (obj: Record<any, any>, key: string, value: any) => {
    try {
        // Use JSON.stringify to search text instead of an object.
        JSON.stringify(obj, (_, nestedValue) => {
            if (nestedValue && nestedValue[key] === value) {
                // Key / Value pair found, return it in a throw, stopping the function from going any further.
                throw nestedValue;
            }

            return nestedValue;
        });
    } catch (result) {
        // Catch the throw and return to the caller.
        return result;
    }
};
