




























































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CenterSelect from '@/components/CenterSelect.vue';
import ManageStaffModal from '@/staff/components/ManageStaffModal.vue';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { AppStateStore } from '@/store/app-state-store';
import store from '@/store';
import { EventTypes } from '@/constants/event-type-constants';
import { User } from '@/staff/models/user';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const featureStore = getModule(FeaturesStore);

@Component({
    components: {
        CenterSelect,
        ManageStaffModal
    }
})
export default class CenterOrUserDrawer extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;

    private isCenterSelect = false;
    private isManageStaff = false;

    get isLineLeaderEnroll(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    get accountName(): string {
        return authState.userFullName;
    }

    get email(): string {
        return authState.userEmailAddress;
    }

    get orgName(): string {
        return appState.currentOrg?.name ?? appState.currentCenter?.name ?? '';
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(value: boolean) {
        this.$emit(EventTypes.INPUT, value);
    }

    get user(): User | undefined {
        return authState.userInfoObject;
    }

    get userRole(): string {
        return authState.userInfoObject?.position_title?.values.value ?? '';
    }

    async logout() {
        this.close();
        await this.$router.push({ name: 'logout' });
    }

    private close(): void {
        this.modelValue = false;
    }

    private manageStaff(): void {
        this.close();
        appState.updateManageStaffModalStatus(true);
        this.isManageStaff = true;
    }

    private switchLocation(): void {
        this.close();
        this.isCenterSelect = true;
    }
}
