export enum BaseStatuses {
    NEW_LEAD = 1,
    RESPONSIVE = 2,
    TOUR_SCHEDULED = 11,
    TOUR_COMPLETED = 3,
    WAIT_LIST = 4,
    REGISTERED = 5,
    ENROLLED = 6,
    TEMP_LEAVE = 7,
    WITHDRAWN = 8,
    LOST_OPP = 9,
    REJECTED = 10
}
