


































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { SortableEvent } from 'sortablejs';
import {
    EnrollmentCenterDetails,
    EnrollmentCenterDetailsCreateDto,
    EnrollmentCenterDetailsUpdateDto, EnrollmentCenterSettings
} from '@/enrollment-center/models/enrollment-center-models';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { LoadingStore } from '@/store/loading-store';

const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class DetailsSettings extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly settings!: EnrollmentCenterSettings | null;
    @Prop({ required: true }) readonly detailsSettingsInfo!: Array<EnrollmentCenterDetails>;

    private detailsSwitch = false;
    private loadingKey = 'detailSettings';
    private newDividerCounter = 0;
    private itemsRemoved: Array<number> = [];
    private headers: Array<DataTableHeader> = [
        {
            text: '',
            value: 'handle'
        },
        {
            text: '',
            value: 'header',
            align: 'center'
        },
        {
            text: '',
            value: 'description',
            align: 'center'
        },
        {
            value: 'delete',
            text: '',
            align: 'center',
            width: '0'
        }
    ];

    private items: Array<EnrollmentCenterDetailsUpdateDto> = [];

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        this.newDividerCounter = 0;
        this.itemsRemoved = [];
        this.items = this.detailsSettingsInfo.map(a => Object.assign({}, a) as EnrollmentCenterDetailsUpdateDto);
        this.detailsSwitch = !this.settings?.enable_details ?? true;
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('detailsSwitch')
    watchDetailsSwitch() {
        this.watchChanges();
    }

    @Watch('items', { deep: true })
    watchChanges() {
        const dtoArray: Array<EnrollmentCenterDetailsUpdateDto | EnrollmentCenterDetailsCreateDto> = [];
        let order = 0;

        for (const item of this.items) {
            const fieldDto: EnrollmentCenterDetailsUpdateDto | EnrollmentCenterDetailsCreateDto = {
                header: item.header,
                description: item.description,
                order
            };

            if (item.id > 0) {
                fieldDto.id = item.id;
            }
            if (item.id && item.id < 0) {
                // Emit new items added to the select list
                dtoArray.push(fieldDto);
            } else {
                // Only emits items that have updated
                for (const e of this.detailsSettingsInfo) {
                    if (item.id && (item.id === e.id) && (e.header !== item.header || e.description !== item.description || e.order !== order)) {
                        dtoArray.push(fieldDto);
                        break;
                    }
                }
            }
            ++order;
        }

        let changesFlag = true;
        if (this.itemsRemoved.length === 0 && dtoArray.length === 0 && this.detailsSwitch === (!this.settings?.enable_details ?? true)) {
            changesFlag = false;
        } else {
            for (const item of dtoArray) {
                if (item.header.length === 0 || item.description.length === 0) {
                    changesFlag = false;
                    break;
                }
            }
        }

        // If valid changes
        // send mutation for save button (Enable)
        // send mutation for updated and deleted changes
        if (changesFlag) {
            settingsChangesStore.setSaveButtonForDetails(true);
            settingsChangesStore.setUpdatedDetails(dtoArray);
            settingsChangesStore.setDeletedDetails(this.itemsRemoved);
            settingsChangesStore.setEnabledDetailsFlag(!this.detailsSwitch);
        } else {
            // If invalid changes
            // send mutation for save button (Disable)
            // send mutation to clear updated and deleted changes
            settingsChangesStore.setSaveButtonForDetails(false);
            settingsChangesStore.setUpdatedDetails([]);
            settingsChangesStore.setDeletedDetails([]);
            settingsChangesStore.setEnabledDetailsFlag(this.settings?.enable_details ?? false);
        }
    }

    private addSection() {
        this.newDividerCounter++;
        const divider: EnrollmentCenterDetailsUpdateDto = {
            id: -2 * this.newDividerCounter,
            description: '',
            header: '',
            order: 0
        };
        this.items.unshift(divider);
    }

    private async deleteSection(divider: EnrollmentCenterDetailsUpdateDto) {
        const arrayInd = this.items.indexOf(divider);
        if (divider.id && divider.id > 0) {
            const result = await this.$swal({
                text: 'WARNING--deleting this detail will remove it from all locations.  Deleting cannot be undone--restoring these values would require adding this detail back to the list and then manually populating it for each location.  Proceed?',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Delete',
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });

            if (result.isConfirmed) {
                if (arrayInd >= 0) {
                    this.items.splice(arrayInd, 1);
                }
                this.itemsRemoved.push(divider.id);
            }
        } else {
            if (arrayInd >= 0) {
                this.items.splice(arrayInd, 1);
            }
        }
    }

    private updateFields(sortEvent: SortableEvent) {
        const movedItem = this.items.splice(sortEvent.oldIndex!, 1)[0];
        this.items.splice(sortEvent.newIndex!, 0, movedItem);
    }
}
