import { BaseStatuses } from '@/constants/status-constants';
import { ChildMapper } from '@/families/mappers/child-mapper';
import { AcceptFamilyEventPayload, Family, FamilyComparison, PendingFamily } from '@/families/models/family';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { RepositoryFactoryStore } from '@/store/repository-factory-store';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';

export interface RejectedFamilyEventPayload {
    merged_family_id?: number;
    reason_id?: number;
}

const appState = getModule(AppStateStore);
const familiesRepository = new FamiliesRepository();
const pendingLeadsRepository = new PendingLeadsRepository();
const recordingStore = getModule(RecordingsStore);
const repositoryFactoryStore = getModule(RepositoryFactoryStore);

export class PendingFamilyService {
    public async acceptFamily(familyId: number, payload?: AcceptFamilyEventPayload): Promise<void> {
        const centerId = payload?.centerId ?? appState.currentCenter?.id;
        await pendingLeadsRepository.acceptFamily(familyId, centerId);
        if (payload && payload.familiesToLink) {
            // keys are always strings in JS, but I actually just want numbers
            for (const id of Object.keys(payload.familiesToLink).map(Number)) {
                if (!payload.familiesToLink[id]) {
                    continue;
                }
                await familiesRepository.linkFamily(familyId, { family_id: id });
            }
            if (payload.hasCall) {
                recordingStore.incrementInboxCount();
            }
        }
    }

    /**
     * Add children from a pending family to the families that are being updated.
     * The updated family will be moved back to the "new family" status.
     *
     * @param { Array<FamilyComparison> } updateFamilyStatus - The families that are being updated.
     * @param { PendingFamily } pendingFamily - The family that is pending (probably being rejected).
     */
    public async addChildrenFromPendingFamily(updateFamilyStatus: Array<FamilyComparison>, pendingFamily: PendingFamily | Family): Promise<void> {
        const childMapper = new ChildMapper();
        const childrenRepository = repositoryFactoryStore.childrenRepository;
        for (const family of updateFamilyStatus) {
            family.current.status = 1;
            if (pendingFamily && pendingFamily.children.length > 0) {
                for (const child of pendingFamily.children) {
                    const dto = childMapper.toCreateDto(child);
                    if (dto.status === 0) {
                        dto.status = BaseStatuses.NEW_LEAD;
                    }
                    await childrenRepository.create(family.current.id, dto);
                }
            }
        }
    }

    public async rejectFamily(familyId: number, payload?: RejectedFamilyEventPayload): Promise<void> {
        await pendingLeadsRepository.rejectFamily(familyId, payload?.merged_family_id, payload?.reason_id);
    }
}
