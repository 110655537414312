














































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Org } from '@/models/organization/org';
import { DataTableOptions } from '@/models/datatables';
import { ApiPagination } from '@/repositories/abstract-repository';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { getPagination } from '@/core/datatables-utils';
import { SavedReportsRepository } from '@/reports/repositories/saved-reports-repository';
import { PastReportSortKeys, ReportLog, ReportLogSortParameter } from '@/reports/models/saved-report';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { SortConstants } from '@/constants/sort-constants';

const appState = getModule(AppStateStore);
const savedReportsRepo = new SavedReportsRepository();
const authState = getModule(AuthStore, store);

@Component
export default class PastReports extends Mixins(LocaleMixin) {
    private currentItemCount = 1;
    private options: DataTableOptions = { itemsPerPage: 25, page: 1 };
    private defaultSorting: ReportLogSortParameter = {
        sort_keys: [PastReportSortKeys.Date],
        sort_dir: [SortConstants.DESCENDING]
    };

    private isLoading = true;
    private pastReportsHeaders = [
        {
            text: 'Report Name',
            value: PastReportSortKeys.Name
        },
        {
            text: 'Date Created',
            value: PastReportSortKeys.Date
        },
        {
            text: '',
            value: 'view'
        }
    ];

    private pastReportsItems: Array<ReportLog> = [];

    get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    get userId(): number {
        return authState.userInfoObject!.id;
    }

    private get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

     // Watch for changes to the Datatables options -- pagination.
    @Watch('options')
    async onOptionsChange() {
        await this.getPastReportItems();
    }

    @Watch('org', { deep: true, immediate: true })
    async changeOrg() {
        if (this.org) {
            this.$set(this.options, 'page', 1);
            await this.getPastReportItems();
        }
    }

    async created() {
        await this.getPastReportItems();
    }

    async getPastReportItems() {
        this.isLoading = true;
        if (this.org && this.org.id) {
            const pagination: ApiPagination = getPagination(this.options);
            const sorting: ReportLogSortParameter = this.getSortingFromOptions(this.options);
            const data = await savedReportsRepo.getStaffReportLogs(this.userId, pagination, sorting);
            this.pastReportsItems = data.entities;
            this.currentItemCount = Number(data.count);
        }

        this.isLoading = false;
    }

    private viewReport(item: ReportLog) {
        this.$router.push({ name: 'report-log', params: { id: String(item.id) } });
    }

    private getSortingFromOptions(options: DataTableOptions): ReportLogSortParameter {
        let sorting = this.defaultSorting;
        if (options.sortBy && options.sortBy.length) {
            // Right now we don't support multiSort; only single column sort
            // It works in the API, but I don't want to implement it here yet
            sorting = {
                sort_keys: [options.sortBy[0] as PastReportSortKeys],
                sort_dir: [
                    options.sortDesc && options.sortDesc[0]
                        ? SortConstants.DESCENDING
                        : SortConstants.ASCENDING
                ]
            };
        }
        return sorting;
    }
}
