






































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { WorkflowStopTriggerDto } from '../models/workflow-models';
import { EventTypes } from '@/constants/event-type-constants';
import {
    childStatusId, eventTypeId,
    familyStatusId,
    WorkflowEntityTypes,
    workflowStopTriggers
} from '@/automation/workflows/constants/workflow-constants';
import { getModule } from 'vuex-module-decorators';
import { WorkflowsTypesStore } from '@/automation/workflows/store/workflows-types-store';
import { WorkflowType } from '@/automation/workflows/models/workflow-type-models';
import { LoadingStore } from '@/store/loading-store';

const workflowsTypesStore = getModule(WorkflowsTypesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class WorkflowStopTrigger extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowStopTriggerDto;

    private stopTriggerOptions = workflowStopTriggers;
    private fieldValues: Array<WorkflowType> = [];
    private loadingKey = 'manageWorkflow';

    get stopTrigger(): WorkflowStopTriggerDto {
        return this.value;
    }

    set stopTrigger(stopTrigger: WorkflowStopTriggerDto) {
        this.$emit('input', stopTrigger);
    }

    get isEvent() {
        return this.entity === WorkflowEntityTypes.EVENT;
    }

    get entity() {
        return this.stopTrigger.entity;
    }

    async created() {
        await this.loadEntity();
    }

    private async loadEntity() {
        switch (this.entity) {
            case WorkflowEntityTypes.FAMILY:
                this.stopTrigger.field = familyStatusId;
                break;
            case WorkflowEntityTypes.CHILD:
                this.stopTrigger.field = childStatusId;
                break;
            case WorkflowEntityTypes.EVENT:
                this.stopTrigger.field = eventTypeId;
                break;
        }
        if (this.entity && this.stopTrigger.field) {
            loadingState.loadingIncrement(this.loadingKey);
            await workflowsTypesStore.retrieveFieldValues({ entityId: this.entity, fieldId: this.stopTrigger.field });
            this.fieldValues = workflowsTypesStore.fieldValues(this.entity, this.stopTrigger.field);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('entity')
    private async entityChanged() {
        await this.loadEntity();
        this.fieldValuesChanged();
    }

    private fieldValuesChanged() {
        if (!this.fieldValues.map((value) => (value.id)).includes(this.stopTrigger.value)) {
            this.stopTrigger.value = 0;
        }
    }

    private deleteStopTrigger() {
        this.$emit(EventTypes.DELETED);
    }
}
