import { StaffLink } from '@/staff/models/user';
import { CrmEntity } from '@/models/base';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { Report } from 'flexmonster';
import { SortParameter } from '@/models/sort-parameter';
import { ReportIdentifier, StandardReportIdentifier } from '@/reports/report-constants';
import { Status } from '@/families/models/status';
import { ReportParameters } from '@/reports/models/reports';

export interface SavedReport extends CrmEntity {
    description: string;
    is_editable: boolean;
    name: string;
    report_data: string;
    shared_with: Array<StaffLink>;
    shared_by: StaffLink | null;
    shared_name: string;
    is_standard_report: boolean;
    standard_report_identifier?: string | StandardReportIdentifier | null;
    expand_centers?: boolean;
    order?: number;
    radioLabel?: string;
}

export interface SavedReportDtoInterface extends AbstractCreateDto, AbstractUpdateDto {
    description: string;
    name: string;
    report_data: string;
    shared_with: Array<number>;
    shared_name: string;
    is_standard_report?: boolean;
    standard_report_identifier?: string | StandardReportIdentifier | null;
    order?: number;
}

export class SavedReportDto implements SavedReportDtoInterface {
    description = '';
    name = '';
    report_data = '';
    shared_with: Array<number> = [];
    shared_name = '';
}

export interface ReportLog extends CrmEntity {
    name: string;
    description: string;
    datetime: string;
    report_data: Report;
}

export enum PastReportSortKeys {
    Name = 'name',
    Date = 'datetime'
}

export interface ReportLogSortParameter extends SortParameter {
    sort_keys: Array<PastReportSortKeys.Name
        | PastReportSortKeys.Date>;
}

export interface StandardReportType {
    identifier: ReportIdentifier;
    name: string;
    reports: Array<SavedReport>;
}

export interface ConversionSuccessReportPayload {
    selectedFields: Array<string>;
    standardReportType: StandardReportType | null;
    selectedView: string | StandardReportIdentifier | null;
    selectedStatus: Status | null;
    convertedStatusDate: string;
    isDateFilterable: boolean;
    reportParams: ReportParameters;
}

export interface StandardReportGroup {
    name: string;
    reports: Array<StandardReportType>;
}
