import { AbstractRepository } from '@/repositories/abstract-repository';
import { Update } from '@/families/models/update';

export class UpdatesRepository extends AbstractRepository<Update> {
    readonly endpoint = 'updates';

    async getAllUpdatesForFamily(familyId: number, filterComments = false): Promise<Array<Update>> {
        const response = await this.client.get<Array<Update>>('families/' + familyId + '/updates', {
            params: {
                since: 1,
                ignore_center_ids_filter: 'true',
                filter_comments: filterComments
            }
        });
        return response.data;
    }

    async updatesByStaff(timestamp: number, staff_id: number, center_ids: Array<number>): Promise<Array<Update>> {
        const response = await this.client.get<Array<Update>>(this.endpoint, {
            params: {
                since: timestamp,
                updated_by_staff_id: staff_id,
                center_ids: center_ids,
                recent: 1,
                limit: 15
            }
        });
        return response.data;
    }
}
