import { convertHexToDecimal, TinyColor } from '@ctrl/tinycolor';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { getModule } from 'vuex-module-decorators';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { convertDecimalToHex } from '@ctrl/tinycolor/dist/conversion';

export class ColorUtils {
    // lighten and darken a relative percentage, similar to SASS scale.color rather than built in TinyColor or Color functionality
    static lighten(color: string, pct: number): string {
        const hsl = new TinyColor(color).toHsl();
        const oldLightness = hsl.l;
        const newLightness = (1 - oldLightness) * (pct / 100) + oldLightness;
        return new TinyColor({ h: hsl.h, s: hsl.s, l: newLightness }).toHexString();
    }

    static darken(color: string, pct: number): string {
        const hsl = new TinyColor(color).toHsl();
        const oldLightness = hsl.l;
        const newLightness = oldLightness * (1 - (pct / 100));
        return new TinyColor({ h: hsl.h, s: hsl.s, l: newLightness }).toHexString();
    }

    static convertPercentToHex(value: number): string {
        if (value > 100) {
            return 'FF';
        }
        const decimal = Math.round(value * 255 / 100);
        if (value < 7) {
            return '0' + decimal.toString(16).toUpperCase();
        } else {
            return decimal.toString(16).toUpperCase();
        }
    }

    // adjusts color opacity by given percentage
    static adjustColorOpacity(color: string, percent: number): string {
        const alpha = this.convertPercentToHex(percent);
        // if saved hex value is 3 digits ie. #1A0, double the values (#11AA00) and return with alpha
        if (color.length === 4) {
            const array = color.slice(1).split('');
            return '#' + array.map(value => `${value}${value}`).join('') + alpha;
        }
        // just return color and alpha together.
        if (color.length === 7) {
            return color + alpha;
        }
        // split the alpha value from the 8-digit hex, convert it to decimal and reduce by percentage, then reconvert to hex.
        if (color.length === 9) {
            const value = color.slice(0, 7);
            const oldAlpha = color.slice(7);
            let newAlpha = convertDecimalToHex(convertHexToDecimal(oldAlpha) * (percent / 100));
            if (newAlpha.length === 1) {
                newAlpha = '0' + newAlpha;
            }
            return value + newAlpha.toUpperCase();
        }
        return color;
    }
}
const settingsStore = getModule(InterfaceSettingsStore);
export const whiteLabelColors: string[] = [];
export function getWhiteLabelColors() {
    if (!whiteLabelColors.length) {
        whiteLabelColors.push(
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_SECONDARY)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_THIRD)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_FOURTH)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_FIFTH)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_SIXTH)!.value as string,
            '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_SEVENTH)!.value as string
        );
    }
}
