

























































































































import ManageWorkflow from '@/automation/workflows/components/ManageWorkflow.vue';
import { WorkflowActiveStatus } from '@/automation/workflows/constants/workflow-constants';
import { Workflow } from '@/automation/workflows/models/workflow-models';
import { WorkflowsRepository } from '@/automation/workflows/repositories/workflows-repository';
import { WorkflowsStore } from '@/automation/workflows/store/workflows-store';
import ViewWorkflow from '@/automation/workflows/views/ViewWorkflow.vue';
import { WorkflowLocationsUtils } from '@/automation/workflows/workflow-locations-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Org } from '@/models/organization/org';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import store from '@/store';
import { AppStateStore } from '@/store/app-state-store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';

Component.registerHooks([
    'beforeRouteEnter'
]);

const loadingState = getModule(LoadingStore);
const workflowsRepository = new WorkflowsRepository();
const workflowsState = getModule(WorkflowsStore);
const featuresStore = getModule(FeaturesStore);
const centersStore = getModule(CentersStore);
const orgsState = getModule(OrgsStore);
const appState = getModule(AppStateStore);
const staffUtils = new StaffUtils();
const authState = getModule(AuthStore, store);
const workflowLocationsUtils = new WorkflowLocationsUtils();

@Component({
    components: {
        ManageWorkflow,
        ViewWorkflow
    }
})
export default class Workflows extends Mixins(LocaleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
        const canViewWorkflows = await staffUtils.getUserPermission(PermissionName.AutomationViewWorkflows);
        if (canViewWorkflows) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    };

    private canEditWorkflows = false;
    private closeEvent = EventTypes.CLOSE;
    private updatedEvent = EventTypes.WORKFLOWS_UPDATED;
    private isWorkflowSelected = false;
    private loadingKey = 'workflowsLoadingKey';
    private workflow: Workflow | null = null;
    private workflowHeaders: Array<DataTableHeader> = [
        {
            text: 'Automation Name',
            value: 'name',
            width: '35%'
        },
        {
            text: 'Description',
            value: 'description',
            width: '42%'
        },
        {
            text: 'View Details',
            value: 'view',
            sortable: false,
            width: '12%',
            align: 'center'
        },
        {
            text: '',
            value: 'has_mixed_coverage',
            width: '3%'
        },
        {
            text: 'Active',
            value: 'is_enabled',
            width: '8%'
        }
    ];

    private key = 0;
    private isAddNew = false;
    // Workflows shown in the table
    private workFlowsArray: Array<Workflow> = [];
    // Show workflows for specific location
    private selectedLocation: Org | null = null;
    private includeInactiveWorkflows = false;

    get center() {
        return appState.storedCurrentCenter;
    }

    private get centers() {
        return centersStore.storedAccessibleCenters;
    }

    /**
     * Whether the db is in crm plus mode or not.
     */
    private get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    /**
     * Whether the user is a corporate user.
     * @private
     */
    private get isCorpUser() {
        return authState.isCorporateUser;
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get orgs() {
        return orgsState.stored;
    }

    @Watch('org')
    private orgChanged() {
        if (this.org) {
            this.selectedLocation = this.org;
        }
    }

    @Watch('includeInactiveWorkflows')
    private toggleActiveWorkflowsView() {
        this.workflowsForCenter();
    }

    @Watch('selectedLocation')
    private async workflowsForCenter() {
        if (this.selectedLocation === null) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        // Reload workflows to ensure we have the latest data.
        await workflowsState.init(true);

        // this.workFlowsArray = workflowLocationsUtils.getVisibleWorkflows(this.selectedLocation);
        this.workFlowsArray = workflowLocationsUtils
            .getVisibleWorkflows(this.selectedLocation)
            .filter(
                (workflow) => {
                    // don't show drip campaign workflows if drip campaign feature is disabled
                    return !workflow.is_for_drip_campaigns || featuresStore.hasDripCampaigns;
                }
            ).map(
                (workflow) => {
                    // Add a property to the workflow to indicate the active status
                    workflow.active_status = this.getWorkflowActiveStatus(workflow);

                    // Temporarily change the `is_enabled` property in order to indicate whether
                    // the workflow is active given the selected org.
                    workflow.is_enabled = this.isWorkflowActive(workflow);

                    return workflow;
                }
            ).filter((workflow) => {
                    // Filter out inactive workflows if the user has selected to only show active workflows
                    return this.includeInactiveWorkflows || workflow.is_enabled;
                }
            );

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * On creation, ensure store data.
     */
    private async created() {
        loadingState.loadingIncrement(this.loadingKey);

        await workflowsState.init();
        await featuresStore.init();
        await centersStore.initAccessibleCenters();
        this.selectedLocation = this.org ? this.org : null;
        this.canEditWorkflows = await staffUtils.getUserPermission(PermissionName.AutomationWorkflows);
        if (!this.isCrmPlus) {
            this.includeInactiveWorkflows = true;
        }
        await this.workflowsForCenter();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private addNewWorkflow() {
        this.isAddNew = true;
    }

    /**
     * Get the active status of the workflow.
     *
     * @param workflow
     * @private
     */
    private getWorkflowActiveStatus(workflow: Workflow) {
        if (!this.selectedLocation) {
            return WorkflowActiveStatus.INACTIVE;
        }

        return workflowLocationsUtils.getWorkflowActiveStatus(workflow, this.selectedLocation);
    }

    private hasMixedCoverage(workflow: Workflow) {
        return workflowLocationsUtils.hasMixedCoverage(workflow, this.selectedLocation as Org, this.centers);
    }

    /**
     * Whether the workflow is active.
     *
     * @param workflow
     * @private
     */
    private isWorkflowActive(workflow: Workflow) {
        return workflow.active_status !== WorkflowActiveStatus.INACTIVE;
    }

    /**
     * Whether the workflow is partially active.
     *
     * @param workflow
     * @private
     */
    private isWorkflowPartiallyActive(workflow: Workflow) {
        return workflow.active_status === WorkflowActiveStatus.PARTIALLY_ACTIVE;
    }

    /**
     * When view modal is closed, reset data
     **/
    private reset() {
        this.isWorkflowSelected = false;
        this.workflow = null;
    }

    /**
     * Handle toggling the workflow on or off.
     *
     * @param workflow The workflow to enable/disable. The is_enabled flag should have already been updated.
     */
    private async toggleWorkflow(workflow: Workflow): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);

        // Org id might need to be passed in; if passed, only update toggle centers under the org
        const updatedWorkflow = await workflowsRepository.toggleWorkflow(
            workflow.id,
            workflow.is_enabled,
            this.selectedLocation?.id === 1 ? undefined : this.selectedLocation?.id
        );
        workflowsState.replaceEntity(updatedWorkflow);

        for (let i = 0; i < this.workFlowsArray.length; ++i) {
            if (this.workFlowsArray[i].id === updatedWorkflow.id) {
                updatedWorkflow.active_status = this.getWorkflowActiveStatus(updatedWorkflow);
                updatedWorkflow.is_enabled = this.isWorkflowActive(updatedWorkflow);
                this.workFlowsArray[i] = updatedWorkflow;
                ++this.key;
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Handle clicking on the view details button.
     *
     * @param workflow
     */
    private viewWorkflow(workflow: Workflow): void {
        if (workflow.id) {
            this.workflow = workflow;
            this.isWorkflowSelected = true;
        }
    }

    private async workflowsUpdated(workflow: Workflow) {
        loadingState.loadingIncrement(this.loadingKey);

        workflowsState.addOrUpdateEntity(workflow);
        await this.workflowsForCenter();

        if (!this.isWorkflowSelected) {
            this.viewWorkflow(workflow);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }
}
