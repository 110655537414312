import { Action, Module } from 'vuex-module-decorators';
import { Family } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';

export interface FamiliesState extends AbstractEntityState<Family> {
    entities: Array<Family>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.FAMILIES
})
export class FamiliesStore extends AbstractApiStore<Family> implements FamiliesState {
    readonly repository = new FamiliesRepository();

    // initialize for data retrieval
    // don't retrieve if we already have data
    // this inheritance also doesn't work right, ugh
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieve();
                }
            });
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Family> {
        return this.entities;
    }
}
