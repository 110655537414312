import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import type { ClientInfo } from '@/organizations/corporate/models/client-info';
import { ClientRepository } from '@/organizations/corporate/repositories/client-repository';

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CLIENT_INFO
})
export class ClientInfoStore extends VuexModule {
    readonly repository = new ClientRepository();
    private clientInfo: ClientInfo | null = null;

    @Action
    public async init() {
        if (!this.clientInfo) {
            await this.retrieveClientInfo();
        }
    }

    @Action({ commit: 'commitClientInfo' })
    public async retrieveClientInfo() {
        return await this.repository.getClientInfo();
    }

    public get storedClientInfo(): ClientInfo | null {
        return this.clientInfo;
    }

    @Mutation
    public commitClientInfo(clientInfo: ClientInfo): void {
        this.clientInfo = clientInfo;
    }
}
