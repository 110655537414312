





































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Center } from '@/organizations/locations/models/center';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import cloneDeep from 'lodash/cloneDeep';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: { BaseClose }
})
export default class NotificationLocations extends Mixins(LocaleMixin, BasicValidationMixin, VmodelShowMixin) {
    @Prop() readonly locations!: Array<Center>;
    @Prop() readonly notificationLocations!: Array<number>;
    private selectAll = false;
    private localLocations: Array<number> = [];

    get locationIds() {
        return this.uniqueLocations.map(center => center.id);
    }

    get uniqueLocations() {
        return this.locations.filter((center, index) => {
            const findIndex = this.locations.findIndex((findCenter) => {
                return findCenter.id === center.id;
            });
            return findIndex === index;
        });
    }

    @Watch('localLocations')
    selectionChanged() {
        if (this.localLocations.length === this.uniqueLocations.length) {
            this.selectAll = true;
        } else {
            this.selectAll = false;
        }
    }

    created() {
        if (this.notificationLocations.length === 0) {
            this.selectAll = true;
            this.selectAllChanged();
        } else {
            this.localLocations = cloneDeep(this.notificationLocations);
        }
    }

    close() {
        this.modelValue = false;
    }

    save() {
        if (this.selectAll) {
            this.$emit(EventTypes.UPDATED, []);
        } else {
            this.$emit(EventTypes.UPDATED, this.localLocations);
        }
        this.close();
    }

    /**
     * Manual clicking on select all
     */
    selectAllChanged() {
        if (this.selectAll) {
            this.localLocations = cloneDeep(this.locationIds);
        } else {
            this.localLocations = [];
        }
    }
}
