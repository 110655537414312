






























import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts from 'highcharts';
import { countsToGraphData, getMonochromeColors } from '@/charts/chart-utils';
import { OrgsStore } from '@/store/orgs-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);
const orgsState = getModule(OrgsStore);
const authState = getModule(AuthStore, store);

@Component({
    components: { }
})
export default class PieChartExample extends Mixins(LocaleMixin) {
    private org = authState.userInfoObject!.org_id;
    private chartOptions: Highcharts.Options = {
        chart: {
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Statuses'
        },
        subtitle: {
            text: 'Breakdown of current opportunity statuses'
        },
        tooltip: {
            pointFormat: '{point.percentage:.0f} %<br>Count: {point.y}'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                colors: getMonochromeColors('#0060AF'),
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.0f} %<br>Count: {point.y}'
                }
            }
        },
        series: [
            {
                name: 'Status',
                type: 'pie',
                data: []
            } as Highcharts.SeriesPieOptions
        ]
    };

    get orgs() {
        return orgsState.stored;
    }

    async created() {
        await this.prepStatusCounts();
    }

    async prepStatusCounts() {
        loadingState.loadingIncrement('pieChart');
        await orgsState.init();
        await statsState.initStatusCounts({ orgIds: [this.org] });
        this.updateChart();
        loadingState.loadingDecrement('pieChart');
    }

    async updateStatusCounts() {
        loadingState.loadingIncrement('pieChart');
        await statsState.retrieveStatusCounts({ orgIds: [this.org] });
        this.updateChart();
        loadingState.loadingDecrement('pieChart');
    }

    private updateChart() {
        const orgName = this.orgs.find(org => org.id === this.org)!.name;
        this.chartOptions.title!.text = 'Statuses - ' + orgName;
        (this.chartOptions.series![0] as Highcharts.SeriesPieOptions).data = countsToGraphData(statsState.statusCounts);
    }
};
