import {
    WorkflowAction,
    WorkflowActionChangeStatusSettings,
    WorkflowActionDripCampaignSettings,
    WorkflowActionEmailSettings,
    WorkflowActionEventSettings,
    WorkflowActionFacebookMessageSettings,
    WorkflowActionNotificationSettings,
    WorkflowActionSendTimeSettings,
    WorkflowActionTaskSettings,
    WorkflowActionTextSettings
} from '@/automation/workflows/models/workflow-models';
import { WorkflowActionTypes, WorkflowDaysBeforeField } from '@/automation/workflows/constants/workflow-constants';
import pluralize from 'pluralize';

interface ResponseLine {
    label: string;
    value: string | undefined | null;
}

function getSendTimeLine(settings: WorkflowActionSendTimeSettings): ResponseLine {
    if (settings.days_before_field) {
        const daysBeforeLabel = settings.days_before_field.id === WorkflowDaysBeforeField.DUE_DATE ? '' : "child's ";
        return {
            label: 'Send',
            value: `${settings.days_before} ${pluralize('day', settings.days_before)} before ${daysBeforeLabel}${settings.days_before_field?.values.value}`
        };
    }
    return {
        label: 'Send In',
        value: `${settings.send_delay_amount} ${pluralize(
            settings.send_delay_units.values.value as string, settings.send_delay_amount)}`
    };
}

export function getActionSettings(action: WorkflowAction): string {
    const responseData: Array<ResponseLine> = [];
    let settings;
    switch (action.type.id) {
        case WorkflowActionTypes.ADD_EVENT:
            settings = action.settings as WorkflowActionEventSettings;
            responseData.push({ label: 'Type', value: settings.type.values.value });
            responseData.push({ label: 'Description', value: settings.description });
            break;
        case WorkflowActionTypes.ADD_TASK:
            settings = action.settings as WorkflowActionTaskSettings;
            responseData.push({ label: 'Type', value: settings.type.values.value });
            responseData.push({ label: 'Description', value: settings.description });
            responseData.push({
                label: 'Task Due In',
                value: `${settings.due_amount} ${pluralize(settings.due_units.values.value as string, settings.due_amount)}`
            });
            break;
        case WorkflowActionTypes.CHANGE_STATUS:
            settings = action.settings as WorkflowActionChangeStatusSettings;
            responseData.push({ label: 'To', value: settings.status.values.name });
            break;
        case WorkflowActionTypes.NOTIFY_STAFF:
            settings = action.settings as WorkflowActionNotificationSettings;
            responseData.push({ label: 'Single Template', value: settings.template.values.name });
            responseData.push({
                label: 'Send In',
                value: `${settings.send_delay_amount} ${pluralize(
                    settings.send_delay_units.values.value as string,
                    settings.send_delay_amount
                )}`
            });
            break;
        case WorkflowActionTypes.SEND_EMAIL:
            settings = action.settings as WorkflowActionEmailSettings;
            if (settings.template) {
                responseData.push({ label: 'Single Template', value: settings.template.values.name });
            } else if (settings.template_group) {
                responseData.push({ label: 'Template Group', value: settings.template_group.values.value });
            }
            responseData.push(getSendTimeLine(settings));
            break;
        case WorkflowActionTypes.SEND_FB_MESSAGE:
            settings = action.settings as WorkflowActionFacebookMessageSettings;
            if (settings.template) {
                responseData.push({ label: 'Single Template', value: settings.template.values.name });
            } else if (settings.template_group) {
                responseData.push({ label: 'Template Group', value: settings.template_group.values.value });
            }
            break;
        case WorkflowActionTypes.SEND_TEXT:
            settings = action.settings as WorkflowActionTextSettings;
            if (settings.template) {
                responseData.push({ label: 'Single Template', value: settings.template.values.name });
            } else if (settings.template_group) {
                responseData.push({ label: 'Template Group', value: settings.template_group.values.value });
            }
            responseData.push(getSendTimeLine(settings));
            break;
        case WorkflowActionTypes.START_DRIP_CAMPAIGN:
            settings = action.settings as WorkflowActionDripCampaignSettings;
            responseData.push({ label: '', value: settings.drip_campaign.values.name });
            break;
        default: {
            break;
        }
    }
    let response = '';
    for (const line of responseData) {
        response += '<div class="row">';
        if (line.label) {
            response += `${line.label}: `;
        }
        response += `<span class="font-weight-bold pl-1">${line.value}</span>`;
        response += '</div>';
    }
    return response;
}
