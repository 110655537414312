




























import { EventTypes } from '@/constants/event-type-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AbstractFile } from '@/models/files';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class FileChips extends Mixins(LocaleMixin) {
    @Prop() files!: Array<AbstractFile>;

    private fileOpenEvent = EventTypes.FILE_OPEN;
    private fileRemoveEvent = EventTypes.FILE_REMOVE;
}
