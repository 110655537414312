import { render, staticRenderFns } from "./BaseColorInput.vue?vue&type=template&id=39e3efb4&scoped=true&"
import script from "./BaseColorInput.vue?vue&type=script&lang=ts&"
export * from "./BaseColorInput.vue?vue&type=script&lang=ts&"
import style0 from "./BaseColorInput.vue?vue&type=style&index=0&id=39e3efb4&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e3efb4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardActions,VCardText,VCardTitle,VCol,VColorPicker,VRow,VSpacer})
