


















































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import LocationAvailabilityTab from '@/enrollment-center/components/LocationAvailabilityTab.vue';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { LoadingStore } from '@/store/loading-store';
import { Center } from '@/organizations/locations/models/center';

import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { centerDistance, getCenterWithLowestId, sortCenterByDistance } from '@/organizations/locations/center-utils';
import { EditMode } from '@/core/edit-modes';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';

const centersStore = getModule(CentersStore);
const dashboardStore = getModule(LocationDashboardStore);
const loadingState = getModule(LoadingStore);
const staffUtils = new StaffUtils();

@Component({ components: { LocationAvailabilityTab } })
export default class LocationAvailabilityWrapper extends Mixins(LocaleMixin) {

    private loadingKey = 'locationAvailabilityWrapper';
    private editLocationInfoPermission = false;
    private selectedLocation: Center | null = null;
    private tabEditMode = EditMode.SAVED;
    private valid = false;

    get canEdit(): boolean {
        if (this.editLocationInfoPermission && this.selectedLocation) {
            const selectedId = this.selectedLocation.id;
            return !!(centersStore.storedAccessibleCenters.find(center => center.id === selectedId));
        }
        return false;
    }

    get isEdit() {
        return this.tabEditMode !== EditMode.SAVED;
    }

    get locationSelect(): Center | null {
        for (const entity of centersStore.storedReferrableCenters) {
            if (dashboardStore.availabilityTabCurrentCenter && entity.id === dashboardStore.availabilityTabCurrentCenter.id) {
                this.selectedLocation = entity;
                return entity;
            }
        }

        this.selectedLocation = null;
        return null;
    }

    set locationSelect(item: Center | null) {
        this.selectedLocation = item;
        if (this.selectedLocation) {
            dashboardStore.setAvailabilityTabCurrentCenter(this.selectedLocation);
        } else {
            dashboardStore.setAvailabilityTabCurrentCenter(null);
        }
    }

    get sortedCenters() {
        if (!this.selectedLocation) {
            return centersStore.storedReferrableCenters;
        }
        return sortCenterByDistance(this.selectedLocation, centersStore.storedReferrableCenters);
    }

    @Watch('tabEditMode')
    tabEditChanged(newMode: EditMode, oldMode: EditMode) {
        if (oldMode === EditMode.SAVING && newMode === EditMode.SAVED) {
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const promises = [];
        promises.push(centersStore.initReferrableCenters());
        promises.push(centersStore.initAccessibleCenters());
        const permissionPromise = staffUtils.getUserPermission(PermissionName.EnrollmentCenterEditLocation);
        await Promise.all(promises);
        await permissionPromise;
        Promise.resolve(permissionPromise).then((result) => { this.editLocationInfoPermission = result; });
        const lowestIdCenter = getCenterWithLowestId(centersStore.storedAccessibleCenters);
        dashboardStore.setAvailabilityTabCurrentCenter(lowestIdCenter);
        loadingState.loadingDecrement(this.loadingKey);
    }

    private cancel() {
        this.tabEditMode = EditMode.SAVED;
    }

    /**
     * center select labels
     * @param center
     * @private
     */
    private centerDisplay(center: Center) {
        if (!this.selectedLocation || this.selectedLocation.id === center.id) {
            return center.name;
        }
        const distance = centerDistance(this.selectedLocation, center);
        if (!isNaN(distance)) {
            return center.name + ' (' + this.formatDistance(distance) + ')';
        }
        return center.name;
    }

    private startEdit() {
        this.tabEditMode = EditMode.EDITING;
    }

    private save() {
        loadingState.loadingIncrement(this.loadingKey);
        this.tabEditMode = EditMode.SAVING;
    }
}
