




































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { ReportMappingDisplayInterface, ReportMappingObject } from '@/reports/models/reports';
import cloneDeep from 'lodash/cloneDeep';
import { Slice } from 'flexmonster';
import { ReportObjectUtils } from '@/reports/report-object-utils';
import { getModule } from 'vuex-module-decorators';
import { StandardReportUtils } from '@/reports/standard-report-utils';
import { StandardReportsStore } from '@/reports/store/standard-reports-store';

const reportObjectUtils = new ReportObjectUtils();
const standardReportsState = getModule(StandardReportsStore);

@Component
export default class ReportFieldsList extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly defaultAllSelected!: boolean;
    @Prop({ default: false }) readonly resetValues!: boolean;
    @Prop({ default: {}, required: true }) readonly mappingObject!: ReportMappingObject;
    @Prop() readonly slice!: Slice;
    @PropSync('selectedFields') selectedFieldsLocal!: Array<string>
    @Prop({ default: false }) readonly isTrendTracking!: boolean;

    private isExpanded = false;
    private clonedMappingDisplayItems: Array<ReportMappingDisplayInterface> = [];
    private mappingDisplayItems: Array<ReportMappingDisplayInterface> = [];
    private sorted = true;

    get selectAll(): boolean {
        return this.mappingDisplayItems.length ? this.selectedFieldsLocal.length === this.mappingDisplayItems.length : false;
    }

    set selectAll(value: boolean) {
        const selectedArray: Array<string> = [];
        if (value) {
            this.mappingDisplayItems.forEach(item => {
                selectedArray.push(item.uniqueName);
            });
        }
        this.selectedFieldsLocal = selectedArray;
    }

    get filteredItems() {
        if (!this.isTrendTracking) {
            return this.mappingDisplayItems;
        }
        return this.mappingDisplayItems.filter(mapping => StandardReportUtils.trendTrackingMapFilter(mapping, standardReportsState.trendTrackingParams));
    }

    mounted() {
        if (this.resetValues && this.mappingObject && this.mappingObject !== {}) {
            for (const key of Object.keys(this.mappingObject)) {
                this.mappingDisplayItems.push(Object.assign(this.mappingObject[key], { uniqueName: key }));
            }
            // Remove mapping with type 'id' cause flexmonster does not display it as fields
            this.mappingDisplayItems = this.mappingDisplayItems.filter(item => item.type !== 'id');
            this.clonedMappingDisplayItems = cloneDeep(this.mappingDisplayItems);
            this.isExpanded = this.defaultAllSelected;
            this.sortFields();
            this.selectAll = this.defaultAllSelected;
            if (!this.defaultAllSelected) {
                this.selectedFieldsLocal = reportObjectUtils.buildArrayOfStringFromSlice(this.slice);
            }
        }

    }

    @Watch('slice.rows', { deep: true })
    updateSelectedFields() {
        if (!this.defaultAllSelected && !this.resetValues) {
            this.selectedFieldsLocal = reportObjectUtils.buildArrayOfStringFromSlice(this.slice);
        }
    }

    @Watch('selectedFieldsLocal', { deep: true, immediate: true })
    selectedFieldsChanged() {
        standardReportsState.setSelectedFields(this.selectedFieldsLocal);
    }

    @Watch('filteredItems', { deep: true, immediate: true })
    optionsChanged() {
        this.selectedFieldsLocal = this.selectedFieldsLocal.filter((field) => {
            return this.filteredItems.map((option) => {
                return option.uniqueName;
            }).includes(field);
        });
    }

    /**
     * Sorting list
     * @private
     */
    private sortFields() {
        if (this.sorted) {
            this.mappingDisplayItems.sort((a, b) => a.caption.toLocaleLowerCase().localeCompare(b.caption.toLocaleLowerCase()));
        } else {
            this.mappingDisplayItems = cloneDeep(this.clonedMappingDisplayItems);
        }
    }

}
