













import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';

const appState = getModule(AppStateStore);

@Component
export default class SearchTextField extends Mixins(LocaleMixin) {
    @Prop({ default: 'Search' }) label!: string;
    @Prop({ default: true }) clearable!: boolean;
    @Prop({ default: 'auto' }) hideDetails!: string | boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) isPendingLeadsEt!: boolean;

    private searchString = '';

    get defaultSearchPendingLeadEt() {
        return appState.defaultSearchPendingLeadEt;
    }

    private created() {
        // Only pending leads in ET dashboard have default search with incontact mode, otherwise search bar should be empty
        if (this.defaultSearchPendingLeadEt && this.isPendingLeadsEt) {
            this.searchString = this.defaultSearchPendingLeadEt;
            this.doSearch();
            appState.setDefaultSearchStringPendingLeadEt('');
        }
    }

    private clearSearch() {
        this.searchString = '';
        this.doSearch();
    }

    private doSearch() {
        this.$emit('input', this.searchString);
    }
}
