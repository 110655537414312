




















































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import AutomationSettings from '@/automation/components/AutomationSettings.vue';
import store from '@/store';
import { Route } from 'vue-router';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';

const featureStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const layoutTabsStore = getModule(LayoutTabsStore, store);
// Register a component route guard to prevent access, as needed
Component.registerHooks([
    'beforeRouteEnter'
]);

@Component({
    components: { BasePageTitle, AutomationSettings }
})
export default class AutomationLayout extends Mixins(LocaleMixin, PageTitleMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const hasAutomationAccess = await staffUtils.getUserPermission(PermissionName.AutomationAccess);
        if (hasAutomationAccess) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    private automationAccess = false;
    private automationLandingPagesPermissionGrant = false;
    private gearsGrant = false;
    private hasAccessDripCampaigns = false;
    private hasAccessMarketingCampaign = false;
    private canViewWorkflowAutomations = false;
    private hasAccessMarketPlace = false;
    private canViewTemplatesRemindersAttachments = false;
    private showAutomationSettings = false;
    private tabIndex = 0;
    private tabs: Array<Record<string, string>> = [];
    $refs!: {
        tabs: any;
    };

    get isCrmPlusMode() {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isTemplateGroupsFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    get hasDripCampaigns(): boolean {
        return featureStore.hasDripCampaigns;
    }

    get isLineLeaderEnroll(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    get hasMarketingCampaigns(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    created() {
        this.tabIndex = layoutTabsStore.currentTabIndex;
    }

    async mounted() {
        this.setPageTitle('Automation');
        this.automationAccess = await staffUtils.getUserPermission(PermissionName.AutomationAccess);
        this.automationLandingPagesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationLandingPages);
        this.gearsGrant = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
        this.hasAccessDripCampaigns = await staffUtils.getUserPermission(PermissionName.AutomationDripCampaign);
        this.hasAccessMarketingCampaign = await staffUtils.getUserPermission(PermissionName.AutomationViewMarketing);
        this.canViewWorkflowAutomations = await staffUtils.getUserPermission(PermissionName.AutomationViewWorkflows);
        this.hasAccessMarketPlace = await staffUtils.getUserPermission(PermissionName.AutomationMarketplace);
        this.canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
        await featureStore.init();

        // Generate the list of tabs
        const tabs = [];
        if (this.canViewTemplatesRemindersAttachments) {
            tabs.push(
                { name: 'message-templates', text: 'Message Templates' },
                { name: 'email-attachments', text: 'Email Attachments' },
                { name: 'reminders', text: 'Reminders' }
            );
        }
        if (!this.isCrmPlusMode || this.automationLandingPagesPermissionGrant) {
            tabs.push({ name: 'landing-pages', text: 'Landing Pages' });
        }
        tabs.push({ name: 'parent-scheduled-tours', text: 'Parent-Scheduled Tours' });
        if (this.isCrmPlusMode && this.hasMarketingCampaigns && this.hasAccessMarketingCampaign) {
            tabs.push({ name: 'marketing-settings', text: 'Marketing Campaigns' });
        }
        if (!this.isCrmPlusMode || this.canViewWorkflowAutomations) {
            tabs.push({ name: 'workflows-automation', text: 'Workflow Automation' });
        }
        if (this.isCrmPlusMode && this.hasDripCampaigns && this.hasAccessDripCampaigns) {
            tabs.push({ name: 'drip-campaigns', text: 'Drip Campaigns' });
        }
        if (this.hasAccessMarketPlace) {
            tabs.push({ name: 'marketplace', text: 'Marketplace' });
        }
        this.tabs = tabs;
    }

    // Update the tab info in Layout.vue.
    private onChange() {
        const outerTab = layoutTabsStore.tabs[this.tabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? 'Automation';
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = this.$route.params?.tabTitle ?? this.$route?.meta?.tabTitle ?? undefined;
        layoutTabsStore.updateTab(outerTab);
    }

    private onResize() {
        this.$refs.tabs.onResize();
    }
}
