







































import FamilyContactButtons from '@/families/components/FamilyContactButtons.vue';
import { EventTypes } from '@/constants/event-type-constants';
import GuardianInfo from '@/families/components/GuardianInfo.vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
@Component({
    components: { FamilyContactButtons, GuardianInfo }
})
export default class GuardiansCard extends Mixins(LocaleMixin) {
    @Prop() family!: Family;

    private viewFamilyEvent = EventTypes.FAMILY_VIEW;
}
