import { ErrorDisplay } from '@/errors/error-display';
import { MarketingCampaignTranslationObject } from '@/marketing/models/marketing-campaigns-model';
import { userPropertyTranslationObject } from '@/staff/models/user';
import { LoadingStore } from '@/store/loading-store';
import { AxiosError } from 'axios';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);

/**
 * Translate an error message to be more readable to the user.
 *
 * @param error             The error from the API
 * @param errorMessage      The default message grabbed from the API
 * @param translationObject Holds API property names with the corresponding names to display in the UI
 */
function translateErrorMessage(
    error: AxiosError,
    errorMessage: string,
    translationObject: Record<string, string> | null
): string {
    if (error.response && error.response.data.violations) {
        let key = error.response.data.violations[0][0].key;
        let detail: string = error.response.data.violations[0][0].detail;

        if (translationObject) {
            if (key in translationObject) {
                // Replace the key with the display name
                key = (translationObject as Record<string, string>)[key];
            } else {
                key = key.split('_')
                    .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
                    .join(' ');
            }

            // Replace the property names in the detail with the appropriate display names
            const properties = Object.keys(translationObject);
            let regex = null;
            for (const property of properties) {
                regex = new RegExp(property, 'g');
                detail = detail.replace(regex, translationObject[property]);
            }
        } else {
            // Make the key look nicer?
            key = key.split('_')
                .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(' ');
        }

        errorMessage = `Error with ${key}: ${detail}`;
    }

    return errorMessage;
}

/**
 * Get an error message to display to the user.
 *
 * @param error
 */
export function getErrorMessage(error: AxiosError): string {
    let errorMessage = error.response && error.response.data.message ? error.response.data.message : error.message;
    if (error.response) {
        if (error.response.data.status === 400) {
            // Parse the error for specific entities
            let translationObject: any | null = null;
            let skipTranslateErrorMessage = false;
            if (error.config.url) {
                if (error.config.url.startsWith('staff')) {
                    translationObject = userPropertyTranslationObject;
                } else if (error.config.url.startsWith('service-accounts')) {
                    skipTranslateErrorMessage = true;
                    errorMessage = 'Username already exists. Please create another username.';
                } else if (error.config.url.startsWith('marketing/campaigns')) {
                    translationObject = MarketingCampaignTranslationObject;
                }
            }

            if (!skipTranslateErrorMessage) {
                // Make the error message more readable for the user
                errorMessage = translateErrorMessage(error, errorMessage, translationObject);
            }
        } else if (error.response.data.status === 404) {
            // If a resource can't be found, it is probably best for the user to refresh and reload all of the data.
            errorMessage = 'The request could not be completed. Please refresh the page and try again.';
        } else if (error.response.status === 409) {
            if (Array.isArray(error.response.data)) {
                errorMessage = error.response.data[0];
            } else {
                errorMessage = error.response.data;
            }
        }
    }

    return errorMessage;
}

export async function ErrorHandler(error: AxiosError): Promise<void> {
    if (error.message === 'canceled') {
        // We canceled the request; just ignore it
        return;
    }
    if (error.response && (error.response.status === 401)) {
        // if anything comes back with a 401, that means they're not really logged in, so we also mark them as not
        // logged in in our Vuex store
        authState.storeLoggedOut();
    } else {
        await ErrorDisplay.error(getErrorMessage(error))
            .then((result) => {
                if (result.isConfirmed) {
                    loadingState.loadingStop(loadingState.naiveCurrentLoadingKey);
                }
            });
    }
}
