import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import {
    Workflow,
    WorkflowActionChangeStatusSettings, WorkflowActionDripCampaignSettings,
    WorkflowActionDto,
    WorkflowActionEmailSettings,
    WorkflowActionEventSettings, WorkflowActionFacebookMessageSettings,
    WorkflowActionNotificationSettings,
    WorkflowActionSettingsChangeStatusDto, WorkflowActionSettingsDripCampaignDto,
    WorkflowActionSettingsEmailDto,
    WorkflowActionSettingsEventDto, WorkflowActionSettingsFacebookMessageDto,
    WorkflowActionSettingsStaffNotificationDto,
    WorkflowActionSettingsTaskDto, WorkflowActionSettingsTextDto,
    WorkflowActionTaskSettings, WorkflowActionTextSettings,
    WorkflowConditionDto,
    WorkflowCreateDto,
    WorkflowStopTriggerDto,
    WorkflowUpdateDto
} from '@/automation/workflows/models/workflow-models';
import { WorkflowActionTypes, WorkflowEntityTypes } from '@/automation/workflows/constants/workflow-constants';

export class WorkflowsMapper extends AbstractMapper<Workflow> {
    createBlank(): WorkflowCreateDto {
        return {
            name: '',
            description: '',
            is_enabled: true,
            family_added_type: null,
            is_for_drip_campaigns: false,
            conditions: [
            ],
            actions: [
                this.createBlankAction()
            ],
            stop_triggers: [],
            enabled_center_ids: null
        };
    }

    createBlankCondition(): WorkflowConditionDto {
        return {
            entity: 0,
            field: 0,
            target_entity: WorkflowEntityTypes.CHILD,
            value_one: 0,
            value_two: null,
            is_not_equal: false
        };
    }

    createBlankAction(): WorkflowActionDto {
        return {
            type: 0,
            settings: {
            }
        };
    }

    createBlankStopTrigger(): WorkflowStopTriggerDto {
        return {
            entity: 0,
            field: 0,
            value: 0
        };
    }

    toCreateDto(entity: Workflow): WorkflowCreateDto {
        return {
            name: entity.name,
            description: entity.description,
            is_enabled: entity.is_enabled,
            family_added_type: entity.family_added_type ? entity.family_added_type.id : null,
            is_for_drip_campaigns: entity.is_for_drip_campaigns,
            enabled_center_ids: null,
            conditions: this.mapConditions(entity),
            actions: this.mapActions(entity),
            stop_triggers: this.mapStopTriggers(entity)
        };
    }

    toUpdateDto(entity: Workflow): WorkflowUpdateDto {
        return this.toCreateDto(entity) as WorkflowUpdateDto;
    }

    /**
     * Convert a workflow's action into DTOs
     *
     * @param entity
     * @private
     */
    private mapActions(entity: Workflow): Array<WorkflowActionDto> {
        const actions: Array<WorkflowActionDto> = [];
        for (const action of entity.actions) {
            let settingsDto = null;
            let settings = null;
            switch (action.type.id) {
                case WorkflowActionTypes.ADD_EVENT:
                    settings = action.settings as WorkflowActionEventSettings;
                    settingsDto = {
                        type: settings.type.id,
                        description: settings.description
                    } as WorkflowActionSettingsEventDto;
                    break;
                case WorkflowActionTypes.ADD_TASK:
                    settings = action.settings as WorkflowActionTaskSettings;
                    settingsDto = {
                        type: settings.type.id,
                        description: settings.description,
                        due_amount: settings.due_amount,
                        due_units: settings.due_units.id,
                        assign_to_position: settings.assign_to_position.id,
                        notification_type: settings.notification_type?.id ?? null,
                        staff_reminder_email_amount: settings.staff_reminder_email_amount,
                        staff_reminder_email_units: settings.staff_reminder_email_units.id,
                        staff_reminder_text_amount: settings.staff_reminder_text_amount,
                        staff_reminder_text_units: settings.staff_reminder_text_units.id,
                        family_reminder_email_amount: settings.family_reminder_email_amount,
                        family_reminder_email_units: settings.family_reminder_email_units.id,
                        family_reminder_text_amount: settings.family_reminder_text_amount,
                        family_reminder_text_units: settings.family_reminder_text_units.id
                    } as WorkflowActionSettingsTaskDto;
                    break;
                case WorkflowActionTypes.CHANGE_STATUS:
                    settings = action.settings as WorkflowActionChangeStatusSettings;
                    settingsDto = {
                        status: settings.status.id
                    } as WorkflowActionSettingsChangeStatusDto;
                    break;
                case WorkflowActionTypes.NOTIFY_STAFF:
                    settings = action.settings as WorkflowActionNotificationSettings;
                    settingsDto = {
                        template: settings.template.id,
                        send_to_type: settings.send_to_type.id,
                        reply_to_position: settings.reply_to_position ? settings.reply_to_position.id : 0,
                        send_delay_amount: settings.send_delay_amount,
                        send_delay_units: settings.send_delay_units.id,
                        staff_group: settings.staff_group ? settings.staff_group.id : null
                    } as WorkflowActionSettingsStaffNotificationDto;
                    break;
                case WorkflowActionTypes.SEND_EMAIL:
                    settings = action.settings as WorkflowActionEmailSettings;
                    settingsDto = {
                        template: settings.template ? settings.template.id : null,
                        template_method: settings.template_method.id,
                        template_group: settings.template_group ? settings.template_group.id : null,
                        send_delay_amount: settings.send_delay_amount,
                        send_delay_units: settings.send_delay_units.id,
                        reply_to_position: settings.reply_to_position ? settings.reply_to_position.id : 0,
                        from_email_type: settings.from_email_type.id,
                        from_name_type: settings.from_name_type.id,
                        days_before: settings.days_before,
                        days_before_field: settings.days_before_field ? settings.days_before_field.id : null
                    } as WorkflowActionSettingsEmailDto;
                    break;
                case WorkflowActionTypes.SEND_FB_MESSAGE:
                    settings = action.settings as WorkflowActionFacebookMessageSettings;
                    settingsDto = {
                        template: settings.template ? settings.template.id : null,
                        template_method: settings.template_method.id,
                        template_group: settings.template_group ? settings.template_group.id : null
                    } as WorkflowActionSettingsFacebookMessageDto;
                    break;
                case WorkflowActionTypes.SEND_TEXT:
                    settings = action.settings as WorkflowActionTextSettings;
                    settingsDto = {
                        template: settings.template ? settings.template.id : null,
                        template_group: settings.template_group ? settings.template_group.id : null,
                        template_method: settings.template_method.id,
                        send_delay_amount: settings.send_delay_amount,
                        send_delay_units: settings.send_delay_units.id,
                        days_before: settings.days_before,
                        days_before_field: settings.days_before_field ? settings.days_before_field.id : null
                    } as WorkflowActionSettingsTextDto;
                    break;
                case WorkflowActionTypes.START_DRIP_CAMPAIGN:
                    settings = action.settings as WorkflowActionDripCampaignSettings;
                    settingsDto = {
                        drip_campaign: settings.drip_campaign.id
                    } as WorkflowActionSettingsDripCampaignDto;
                    break;
            }
            if (!settingsDto) {
                continue;
            }
            actions.push({
                type: action.type.id,
                settings: settingsDto
            });
        }
        return actions;
    }

    /**
     * Convert a workflow's conditions into DTOs
     *
     * @param entity
     * @private
     */
    private mapConditions(entity: Workflow): Array<WorkflowConditionDto> {
        const conditions: Array<WorkflowConditionDto> = [];
        let value1: any = null;
        let value2: any = null;
        for (const condition of entity.conditions) {
            value1 = null;
            value2 = null;
            if (condition.value_one) {
                if (typeof condition.value_one === 'object' && 'id' in condition.value_one) {
                    value1 = condition.value_one.id;
                } else if (condition.value_one !== -1) {
                    value1 = condition.value_one;
                }
            }
            value1 = value1 || '';
            if (condition.value_two && typeof condition.value_two === 'object' && 'id' in condition.value_two) {
                value2 = condition.value_two.id;
            }
            conditions.push({
                entity: condition.entity.id,
                target_entity: condition.target_entity?.id ?? null,
                field: condition.field.id,
                value_one: value1,
                value_two: value2,
                is_not_equal: condition.is_not_equal
            });
        }
        if (conditions.length === 0) {
            // so drip campaign workflows still play nice if it becomes unchecked in manage workflow
            conditions.push(this.createBlankCondition());
        }
        return conditions;
    }

    /**
     * Convert a workflow's stop triggers to the DTO
     *
     * @param entity
     * @private
     */
    private mapStopTriggers(entity: Workflow): Array<WorkflowStopTriggerDto> {
        const stopTriggers: Array<WorkflowStopTriggerDto> = [];
        for (const stopTrigger of entity.stop_triggers) {
            stopTriggers.push({
                entity: stopTrigger.entity.id,
                field: stopTrigger.field?.id ?? null,
                value: stopTrigger.value.id
            } as WorkflowStopTriggerDto);
        }
        return stopTriggers;
    }
}
