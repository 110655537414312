import { Field } from '@/crm-types/models/field-models';
import { CrmTypeList } from '@/crm-types/models/crm-type';

/**
 * returns an array of indexes that are used
 * @param fields
 * @param entity
 * @param flag12
 * @param flag34
 */
export function getCustomTypesShown(fields: Array<Field>, entity: 'LEAD' | 'CHILD', flag12: boolean, flag34: boolean): Array<number> {
    const possible = [
        entity + '_TYPE',
        entity + '_TYPE_TWO',
        entity + '_TYPE_THREE',
        entity + '_TYPE_FOUR'
    ];
    const shown: Array<number> = [];
    for (const [i, customType] of possible.entries()) {
        if (i < 2 && !flag12) {
            continue;
        }
        if (i >= 2 && !flag34) {
            continue;
        }
        const matchingFields = fields.filter((field) => {
            return field.select_list_name === customType;
        });
        if (matchingFields.length === 0) {
            continue;
        }
        shown.push(i);
    }
    return shown;
}

export function getCustomTypesDbName(entity: 'LEAD' | 'CHILD'): Array<string> {
    return [
        entity + '_TYPE',
        entity + '_TYPE_TWO',
        entity + '_TYPE_THREE',
        entity + '_TYPE_FOUR'
    ];
}

export function getCustomTypes(entity: 'LEAD' | 'CHILD'): Array<CrmTypeList> {
    if (entity === 'LEAD') {
        return [
            CrmTypeList.LEAD_TYPE_1,
            CrmTypeList.LEAD_TYPE_2,
            CrmTypeList.LEAD_TYPE_3,
            CrmTypeList.LEAD_TYPE_4
        ];
    }
    return [
        CrmTypeList.CHILD_TYPE_1,
        CrmTypeList.CHILD_TYPE_2,
        CrmTypeList.CHILD_TYPE_3,
        CrmTypeList.CHILD_TYPE_4
    ];
}
