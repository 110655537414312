








































































































































































































































































































































































import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { AppStateStore } from '@/store/app-state-store';
import { buildThreadMeta } from '@/communications/messages/inbox-utils';
import { LoadingStore } from '@/store/loading-store';
import {
    MessageDirection,
    ThreadMeta
} from '@/communications/messages/models/message';
import InboxTable from '@/communications/messages/components/InboxTable.vue';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { EventTypes } from '@/constants/event-type-constants';
import InboxViewMessage from '@/communications/messages/components/InboxViewMessage.vue';
import { OutgoingText, TextThread } from '@/communications/messages/models/text';
import { EmailThread, OutgoingEmail } from '@/communications/messages/models/email';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { DataTableOptions } from '@/models/datatables';
import { getPagination } from '@/core/datatables-utils';
import { CommunicationTypes } from '@/communications/communication-constants';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { FacebookOutgoingMessage, FacebookThread } from '@/communications/messages/models/facebookMessage';
import cloneDeep from 'lodash/cloneDeep';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { Recording } from '@/communications/recordings/models/recording-models';
import InboxCallRecordingTable from '@/communications/recordings/components/InboxCallRecordingTable.vue';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { Family } from '@/families/models/family';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import { Route } from 'vue-router';

const emailsStore = getModule(EmailsStore);
const textsStore = getModule(TextsStore);
const facebookMessagesStore = getModule(FacebookMessagesStore);
const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);
const textsRepo = new TextsRepository();
const emailsRepo = new EmailsRepository();
const facebookMessagesRepo = new FacebookMessagesRepository();
const facebookState = getModule(FacebookStore);
const recordingsRepo = new RecordingsRepository();
const recordingsStore = getModule(RecordingsStore);
const familiesRepo = new FamiliesRepository();

Component.registerHooks([
    'beforeRouteLeave'
]);

@Component({
    components: {
        BasePageTitle,
        InboxCallRecordingTable,
        InboxViewMessage,
        InboxTable,
        SendMessageModal
    }
})
export default class Inbox extends Mixins(LocaleMixin, PageTitleMixin) {

    // Clear some global state for the page titles before we leave
    async beforeRouteLeave(to: Route, from: Route, next: Function) {
        appState.setIsInboxPage(false);
        next();
    }

    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;
    private activeTab = CommunicationTypes.TEXT;
    private saveTab = CommunicationTypes.TEXT;
    private closeViewEvent = EventTypes.CLOSE;
    private emails: Array<ThreadMeta> = [];
    private emailsStored: Array<ThreadMeta> = [];
    private emailsCountStored = 0;
    private emailsTab = CommunicationTypes.EMAIL;
    private isSendMessage = false;
    private loadingKey = 'myInbox';
    private quickTextEvent = EventTypes.NEW_QUICK_TEXT;
    private readEvent = EventTypes.INBOX_READ;
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;
    private pendingTextCancelledEvent= EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent= EventTypes.PENDING_TEXT_UPDATED;
    private newFacebookMessageEvent = EventTypes.NEW_FACEBOOK_MESSAGE
    private nextEvent = EventTypes.NEXT;
    private prevEvent = EventTypes.PREV;
    private scrollSave = 0;
    private searchEvent = EventTypes.INBOX_SEARCH;
    private searchClearedEvent = EventTypes.INBOX_SEARCH_CLEARED;
    private texts: Array<ThreadMeta> = [];
    private textsStored: Array<ThreadMeta> = [];
    private textsCountStored = 0;
    private textsTab = CommunicationTypes.TEXT;
    private toggleArchivedEvent = EventTypes.TOGGLE_ARCHIVED;
    private unreadEvent = EventTypes.MARK_UNREAD;
    private viewMode = false;
    private viewMessages: Array<ThreadMeta> = [];
    private viewArchiveMode = false;
    private viewId = 0;
    private viewTexts = false;
    private viewEmails = false;
    private facebookMessages: Array<ThreadMeta> = [];
    private facebookMessagesStored: Array<ThreadMeta> = [];
    private facebookMessagesCountStored = 0;
    private facebookTab = CommunicationTypes.FACEBOOK;
    private recordingsTab = CommunicationTypes.RECORDING;
    private recordings: Array<Recording> = [];
    private recordingsMemoized: Array<Recording> | null = null;
    private recordingsCountMemoized = 0;

    private emailsArchiveMode = 0;
    private textsArchiveMode = 0;
    private facebookMessagesArchiveMode = 0;
    private recordingsArchiveMode = 0;
    private searchString = '';

    private emailsTableOptions: DataTableOptions = {
        page: 1,
        itemsPerPage: 10
    };

    private textsTableOptions: DataTableOptions = {
        page: 1,
        itemsPerPage: 10
    };

    private facebookMessagesTableOptions: DataTableOptions = {
        page: 1,
        itemsPerPage: 10
    };

    private recordingsTableOptions: DataTableOptions = {
        page: 1,
        itemsPerPage: 10
    };

    private emailsCount = 1;
    private textsCount = 1;
    private facebookMessagesCount = 1;
    private recordingsCount = 1;
    private familiesForRecording: Array<Family> = [];
    private familiesForRecordingMemoized: Array<Family> | null = null;

    private areMessagesLoaded = false;

    private get hasTwoWayEmail(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_EMAILS);
    }

    private get hasTwoWayText(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_TEXTS);
    }

    private get hasFacebook(): boolean {
        return this.hasFacebookIntegration && facebookState.entities.length > 0;
    }

    private get hasFacebookIntegration(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION);
    }

    private get org() {
        return appState.storedCurrentOrg;
    }

    private get unreadCount() {
        if (this.isEnrollmentTeamMode) {
            return textsStore.enrollmentTeamInboxCount + emailsStore.enrollmentTeamInboxCount + facebookMessagesStore.enrollmentTeamInboxCount + recordingsStore.enrollmentTeamInboxCount;
        }

        return textsStore.inboxCount + emailsStore.inboxCount + facebookMessagesStore.inboxCount + recordingsStore.inboxCount;
    }

    private get unreadFacebookCount() {
        if (this.isEnrollmentTeamMode) {
            return facebookMessagesStore.enrollmentTeamInboxCount.toString();
        }

        return facebookMessagesStore.inboxCount.toString();
    }

    private get unreadEmailCount() {
        if (this.isEnrollmentTeamMode) {
            return emailsStore.enrollmentTeamInboxCount.toString();
        }

        return emailsStore.inboxCount.toString();
    }

    private get unreadTextCount() {
        if (this.isEnrollmentTeamMode) {
            return textsStore.enrollmentTeamInboxCount.toString();
        }

        return textsStore.inboxCount.toString();
    }

    private get unlistenedRecordingsCount() {
        if (this.isEnrollmentTeamMode) {
            return recordingsStore.enrollmentTeamInboxCount.toString();
        }
        return recordingsStore.inboxCount.toString();
    }

    private get onlyShowMyEmails() {
        return appState.onlyMyEmails;
    }

    private get onlyShowMyFacebookMessages() {
        return appState.onlyMyFacebookMessages;
    }

    private get onlyShowMyTexts() {
        return appState.onlyMyTexts;
    }

    private get route() {
        return this.$route;
    }

    @Watch('org', {
        immediate: true,
        deep: true
    })
    async orgUpdated() {
        this.areMessagesLoaded = false;
        if (!this.isEnrollmentTeamMode) {
            await this.retrieveMessages();
            await this.updateInboxCount();
            this.viewMode = false;
        }
        this.areMessagesLoaded = true;
    }

    // watch the route and set the active tab to whatever the named route is
    @Watch('route', { immediate: true })
    private async setActiveTab() {
        this.viewMode = false;
        if (!this.isEnrollmentTeamMode) {
            switch (this.$route.name) {
                case 'inbox-text-messages':
                    this.activeTab = CommunicationTypes.TEXT;
                    if (this.searchString.length >= 3) {
                        await this.textSearch(this.searchString);
                    } else {
                        await this.retrieveTexts();
                    }
                    break;
                case 'inbox-email-messages':
                    this.activeTab = CommunicationTypes.EMAIL;
                    if (this.searchString.length >= 3) {
                        await this.emailSearch(this.searchString);
                    } else {
                        await this.retrieveEmails();
                    }
                    break;
                case 'inbox-facebook-messages':
                    this.activeTab = CommunicationTypes.FACEBOOK;
                    if (this.searchString.length >= 3) {
                        await this.facebookMessageSearch(this.searchString);
                    } else {
                        await this.retrieveFacebookMessages();
                    }
                    break;
                case 'inbox-call-recordings':
                    this.activeTab = CommunicationTypes.RECORDING;
                    if (this.searchString.length >= 3) {
                        await this.recordingSearch(this.searchString);
                    } else {
                        await this.retrieveCallRecordings();
                    }
                    break;
                default:
                    this.activeTab = CommunicationTypes.TEXT;
            }
        }
    }

    @Watch('emailsArchiveMode')
    async emailsArchiveFlipped() {
        if (this.searchString.length >= 3) {
            await this.emailSearch(this.searchString);
        } else {
            await this.retrieveEmails();
        }
    }

    @Watch('textsArchiveMode')
    async textsArchiveFlipped() {
        if (this.searchString.length >= 3) {
            await this.textSearch(this.searchString);
        } else {
            await this.retrieveTexts();
        }
    }

    @Watch('facebookMessagesArchiveMode')
    async facebookMessagesFlipped() {
        if (this.searchString.length >= 3) {
            await this.facebookMessageSearch(this.searchString);
        } else {
            await this.retrieveFacebookMessages();
        }
    }

    @Watch('recordingsArchiveMode')
    async recordingsArchiveFlipped() {
        if (this.searchString.length >= 3) {
            await this.recordingSearch(this.searchString);
        } else {
            await this.retrieveCallRecordings();
        }
    }

    @Watch('textsTableOptions.page')
    async textsPageOptionsChanged() {
        await this.retrieveTexts();
    }

    @Watch('emailsTableOptions.page')
    async emailsPageOptionsChanged() {
        await this.retrieveEmails();
    }

    @Watch('facebookMessagesTableOptions.page')
    async facebookMessagesPageOptionsChanged() {
        await this.retrieveFacebookMessages();
    }

    @Watch('recordingsTableOptions.page')
    async recordingPageOptionsChanged() {
        await this.retrieveCallRecordings();
    }

    @Watch('textsTableOptions.itemsPerPage')
    async textsItemsPerPageOptionsChanged() {
        await this.retrieveTexts();
    }

    @Watch('emailsTableOptions.itemsPerPage')
    async emailsItemsPerPageOptionsChanged() {
        await this.retrieveEmails();
    }

    @Watch('facebookMessagesTableOptions.itemsPerPage')
    async facebookMessagesItemsPerPageOptionsChanged() {
        await this.retrieveFacebookMessages();
    }

    @Watch('recordingsTableOptions.itemsPerPage')
    async recordingItemsPerPageOptionsChanged() {
        await this.retrieveCallRecordings();
    }

    @Watch('onlyShowMyEmails')
    async updateUnreadEmailsCount() {
        if (this.isEnrollmentTeamMode) {
            await emailsStore.retrieveEmailsInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyEmails });
        } else {
            await emailsStore.retrieveEmailsInboxCountForOrg({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyEmails });
        }
    }

    @Watch('onlyShowMyTexts')
    async updateUnreadTextsCount() {
        if (this.isEnrollmentTeamMode) {
            await textsStore.retrieveTextsInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyTexts });
        } else {
            await textsStore.retrieveTextsInboxCount({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyTexts });
        }
    }

    @Watch('onlyShowMyFacebookMessages')
    async updateUnreadFbMessagesCount() {
        if (this.isEnrollmentTeamMode) {
            await facebookMessagesStore.retrieveFacebookMessagesInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyFacebookMessages });
        } else {
            await facebookMessagesStore.retrieveFacebookMessagesInboxCount({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyFacebookMessages });
        }
    }

    @Watch('unreadCount')
    updateTitle() {
        if (this.isEnrollmentTeamMode) {
            // That page has its own title
            return;
        }
        appState.setIsInboxPage(true);
        appState.setUnreadCount(this.unreadCount);
        this.setPageTitle(`Inbox (${this.unreadCount})`);
    }

    async created() {
        await featuresStore.init();
        const promises = [];
        if (this.hasFacebookIntegration) {
            promises.push(facebookState.init());
        }
        if (this.isEnrollmentTeamMode) {
            promises.push(this.retrieveMessages());
            promises.push(this.updateInboxCount());
        }
        await Promise.all(promises);
    }

    mounted() {
        this.updateTitle();
    }

    private async updateInboxCount() {
        if (!this.org && !this.isEnrollmentTeamMode) {
            return;
        }
        const inboxPromises = [];

        if (this.hasTwoWayText) {
            if (this.isEnrollmentTeamMode) {
                inboxPromises.push(textsStore.initTextsInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyTexts }));
            } else if (this.org) {
                inboxPromises.push(textsStore.initTextsInboxCount({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyTexts }));
            }
        }

        if (this.hasTwoWayEmail) {
            if (this.isEnrollmentTeamMode) {
                inboxPromises.push(emailsStore.initEmailsInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyEmails }));
            } else if (this.org) {
                inboxPromises.push(emailsStore.initEmailsInboxCountForOrg({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyEmails }));
            }
        }

        if (this.hasFacebook) {
            if (this.isEnrollmentTeamMode) {
                inboxPromises.push(facebookMessagesStore.initFacebookMessagesInboxCountForEnrollmentTeam({ org_id: 1, only_me: this.onlyShowMyFacebookMessages }));
            } else if (this.org) {
                inboxPromises.push(facebookMessagesStore.initFacebookMessagesInboxCount({ org_id: this.org ? this.org.id : 1, only_me: this.onlyShowMyFacebookMessages }));
            }
        }

        if (this.isEnrollmentTeamMode) {
            inboxPromises.push(recordingsStore.initRecordingsEnrollmentInboxCount(this.org ? this.org.id : 1));
        } else {
            inboxPromises.push(recordingsStore.initRecordingsInboxCount(this.org ? this.org.id : 1));
        }

        await Promise.all(inboxPromises);
    }

    private async retrieveMessages() {
        if (this.hasTwoWayText) {
            await this.retrieveTexts(false);
        }

        if (this.hasTwoWayEmail) {
            await this.retrieveEmails(false);
        }

        if (this.hasFacebook) {
            await this.retrieveFacebookMessages();
        }

        await this.retrieveCallRecordings();
    }

    private async getFamiliesForRecording(recordings: Array<Recording>) {
        const familyIds = new Set<number>();
        const promises = [];
        for (const recording of recordings) {
            if (recording.family && !familyIds.has(recording.family.id)) {
                familyIds.add(recording.family.id);
                promises.push(familiesRepo.getOne(recording.family.id));
            }
        }
        if (promises.length) {
            await Promise.all(promises).then(values => {
                this.familiesForRecording = values;
                loadingState.loadingDecrement(this.loadingKey);
            });
        } else {
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private async retrieveCallRecordings(force = true) {
        if (!this.org && !this.isEnrollmentTeamMode) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        const recordingsPromise = recordingsRepo.getRecordingsForInbox(this.org ? this.org.id : 1, !!this.recordingsArchiveMode, getPagination(this.recordingsTableOptions), force, this.isEnrollmentTeamMode);

        const recordingsResponse = await recordingsPromise;

        this.recordings = recordingsResponse.data;
        this.recordingsCount = recordingsResponse.count;

        this.recordingsMemoized = cloneDeep(this.recordings);
        this.recordingsCountMemoized = this.recordingsCount.valueOf();

        await this.getFamiliesForRecording(this.recordings);
        this.familiesForRecordingMemoized = cloneDeep(this.familiesForRecording);
    }

    private async retrieveFacebookMessages(force = true) {
        if (!this.org && !this.isEnrollmentTeamMode) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        const facebookMessagesPromise = facebookMessagesRepo.getInboxThreadsForOrg(this.org ? this.org.id : 1, !!this.facebookMessagesArchiveMode, getPagination(this.facebookMessagesTableOptions), force, this.isEnrollmentTeamMode);

        const facebookMessageThreadsResponse = await facebookMessagesPromise;
        this.facebookMessages = buildThreadMeta(facebookMessageThreadsResponse.threads, false, false);
        this.facebookMessagesCount = facebookMessageThreadsResponse.count;
        // Stash the original values, so we can restore the inbox without re-querying the API.
        this.facebookMessagesStored = cloneDeep(this.facebookMessages);
        this.facebookMessagesCountStored = this.facebookMessagesCount.valueOf();
        if (this.onlyShowMyFacebookMessages) {
            this.facebookMessages = this.facebookMessages.filter(thread => thread.user_ids.has(authState.id!));
            this.facebookMessagesCount = this.facebookMessages.length;
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async retrieveTexts(force = true) {
        if (!this.org && !this.isEnrollmentTeamMode) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        const filterUserId = this.onlyShowMyTexts ? authState.id! : 0;
        const textsPromise = textsRepo.getInboxThreadsForOrg(
            this.org ? this.org.id : 1,
            !!this.textsArchiveMode,
            getPagination(this.textsTableOptions),
            force,
            this.isEnrollmentTeamMode,
            filterUserId
        );

        const textThreadsResponse = await textsPromise;
        this.texts = buildThreadMeta(textThreadsResponse.threads, true, false);
        this.textsCount = textThreadsResponse.count;
        // Stash the original values, so we can restore the inbox without re-querying the API.
        this.textsStored = cloneDeep(this.texts);
        this.textsCountStored = this.textsCount.valueOf();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async retrieveEmails(force = true) {
        if (!this.org && !this.isEnrollmentTeamMode) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        const filterUserId = this.onlyShowMyEmails ? authState.id! : 0;
        const emailsPromise = emailsRepo.getInboxThreadsForOrg(this.org ? this.org.id : 1, !!this.emailsArchiveMode, getPagination(this.emailsTableOptions), force, this.isEnrollmentTeamMode, filterUserId);

        const emailThreadsResponse = await emailsPromise;
        this.emails = buildThreadMeta(emailThreadsResponse.threads, false, true);
        this.emailsCount = emailThreadsResponse.count;
        // Stash the original values so we can restore the inbox after a search without re-querying the API
        this.emailsStored = cloneDeep(this.emails);
        this.emailsCountStored = this.emailsCount.valueOf();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async emailSearch(searchString: string) {
        loadingState.loadingIncrement(this.loadingKey);
        this.searchString = searchString;
        const filterUserId = this.onlyShowMyEmails ? authState.id! : 0;
        const emails = await emailsRepo.search(searchString, !!this.emailsArchiveMode, this.isEnrollmentTeamMode, this.org?.id, undefined, filterUserId);
        this.emails = buildThreadMeta(emails.threads, false, true);
        this.emailsCount = emails.count;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async emailSearchClear() {
        this.searchString = '';
        await this.retrieveEmails();
    }

    private async textSearch(searchString: string) {
        loadingState.loadingIncrement(this.loadingKey);
        this.searchString = searchString;
        const filterUser = this.onlyShowMyTexts ? authState.id! : 0;
        const texts = await textsRepo.search(searchString, !!this.textsArchiveMode, this.isEnrollmentTeamMode, this.org?.id, undefined, filterUser);
        this.texts = buildThreadMeta(texts.threads, true, false);
        this.textsCount = texts.count;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async textSearchClear() {
        this.searchString = '';
        await this.retrieveTexts();
    }

    private async facebookMessageSearch(searchString: string) {
        loadingState.loadingIncrement(this.loadingKey);
        this.searchString = searchString;
        const facebookMessages = await facebookMessagesRepo.search(searchString, !!this.facebookMessagesArchiveMode, this.isEnrollmentTeamMode, this.org?.id);
        this.facebookMessages = buildThreadMeta(facebookMessages.threads, false, false);
        this.facebookMessagesCount = facebookMessages.count;
        if (this.onlyShowMyFacebookMessages) {
            this.facebookMessages = this.facebookMessages.filter(thread => thread.user_ids.has(authState.id!));
            this.facebookMessagesCount = this.facebookMessages.length;
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async facebookMessageSearchClear() {
        this.searchString = '';
        await this.retrieveFacebookMessages();
        if (this.onlyShowMyFacebookMessages) {
            this.facebookMessages = this.facebookMessages.filter(thread => thread.user_ids.has(authState.id!));
            this.facebookMessagesCount = this.facebookMessages.length;
        }
    }

    private async recordingSearch(searchString: string) {
        this.searchString = searchString;
        loadingState.loadingIncrement(this.loadingKey);
        const recordingsResponse = await recordingsRepo.search(searchString, !!this.recordingsArchiveMode, this.org?.id);
        this.recordings = recordingsResponse.data;
        this.recordingsCount = this.recordings.length;
        await this.getFamiliesForRecording(this.recordings);
    }

    private async recordingSearchClear() {
        this.searchString = '';
        if (this.recordingsMemoized && this.recordingsCountMemoized && this.familiesForRecordingMemoized) {
            this.recordings = this.recordingsMemoized;
            this.recordingsCount = this.recordingsCountMemoized;
            this.familiesForRecording = this.familiesForRecordingMemoized;
        } else {
            await this.retrieveCallRecordings();
        }
    }

    private async toggleArchived(meta: Array<ThreadMeta>, mode: boolean) {
        if (meta[0].is_text) {
            await this.toggleArchivedTexts(meta, mode);
            await this.retrieveTexts();
        } else if (meta[0].is_email) {
            await this.toggleArchivedEmails(meta, mode);
            await this.retrieveEmails();
        } else {
            await this.toggleArchivedFacebookMessages(meta, mode);
            await this.retrieveFacebookMessages();
        }
    }

    private async toggleArchivedEmails(emails: Array<ThreadMeta>, mode: boolean) {
        loadingState.loadingIncrement(this.loadingKey);
        const ids = new Set(emails.map((meta) => {
            return meta.incoming_id;
        }));

        for (const email of emails) {
            if (!email.is_read && mode) {
                emailsStore.incrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    emailsStore.incrementTeamInboxCount();
                }
            }
            if (!email.is_read && !mode) {
                emailsStore.decrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    emailsStore.decrementTeamInboxCount();
                }
            }
        }

        for (let iter = 0; iter < this.emails.length; iter++) {
            if (ids.has(this.emails[iter].incoming_id)) {
                // mode is where we came from, so always flip for flag
                this.emails[iter].is_dismissed = !mode;
                // make sure vue sees the change
                this.$set(this.emails, iter, this.emails[iter]);
                for (const message of this.emails[iter].thread) {
                    if (message.type === MessageDirection.INCOMING) {
                        ids.add(message.id);
                    }
                }
            }
        }

        await emailsRepo.toggleArchived(Array.from(ids), !mode);
        await this.retrieveEmails();

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async toggleArchivedTexts(texts: Array<ThreadMeta>, mode: boolean) {
        loadingState.loadingIncrement(this.loadingKey);
        const ids = new Set(texts.map((meta) => {
            return meta.incoming_id;
        }));
        for (const text of texts) {
            if (!text.is_read && mode) {
                textsStore.incrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    textsStore.incrementTeamInboxCount();
                }
            }
            if (!text.is_read && !mode) {
                textsStore.decrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    textsStore.decrementTeamInboxCount();
                }
            }
        }

        for (let iter = 0; iter < this.texts.length; iter++) {
            if (ids.has(this.texts[iter].incoming_id)) {
                // mode is where we came from, so always flip for flag
                this.texts[iter].is_dismissed = !mode;
                // make sure vue sees the change
                this.$set(this.texts, iter, this.texts[iter]);
                for (const message of this.texts[iter].thread) {
                    if (message.type === MessageDirection.INCOMING) {
                        ids.add(message.id);
                    }
                }
            }
        }

        const filteredIds = Array.from(ids).filter(id => id !== 0);
        await textsRepo.toggleArchived(filteredIds, !mode);
        await this.retrieveTexts();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async toggleArchivedFacebookMessages(facebookMessages: Array<ThreadMeta>, mode: boolean) {
        loadingState.loadingIncrement(this.loadingKey);
        const ids = new Set(facebookMessages.map((meta) => {
            return meta.incoming_id;
        }));
        for (const facebookMessage of facebookMessages) {
            if (!facebookMessage.is_read && mode) {
                facebookMessagesStore.incrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    facebookMessagesStore.incrementTeamInboxCount();
                }
            }
            if (!facebookMessage.is_read && !mode) {
                facebookMessagesStore.decrementInboxCount();
                if (this.isEnrollmentTeamMode) {
                    facebookMessagesStore.decrementTeamInboxCount();
                }
            }
        }

        for (let iter = 0; iter < this.facebookMessages.length; iter++) {
            if (ids.has(this.facebookMessages[iter].incoming_id)) {
                // mode is where we came from, so always flip for flag
                this.facebookMessages[iter].is_dismissed = !mode;
                // make sure vue sees the change
                this.$set(this.facebookMessages, iter, this.facebookMessages[iter]);
                for (const message of this.facebookMessages[iter].thread) {
                    if (message.type === MessageDirection.INCOMING) {
                        ids.add(message.id);
                    }
                }
            }
        }

        await facebookMessagesRepo.toggleArchived(Array.from(ids), !mode);
        await this.retrieveFacebookMessages();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async toggleArchivedRecordings(recordings: Array<Recording>, mode: boolean) {
        loadingState.loadingIncrement(this.loadingKey);
        const ids = new Set(recordings.map((recording) => {
            return recording.id;
        }));
        for (const recording of recordings) {
            if (this.isEnrollmentTeamMode) {
                if (!recording.is_read_by_enrollment_team && mode) {
                    recordingsStore.incrementEnrollmentInboxCount();
                }
                if (!recording.is_read_by_enrollment_team && !mode) {
                    recordingsStore.decrementEnrollmentInboxCount();
                }
            } else {
                if (!recording.is_read && mode) {
                    recordingsStore.incrementInboxCount();
                }

                if (!recording.is_read && !mode) {
                    recordingsStore.decrementInboxCount();
                }
            }
        }

        for (let iter = 0; iter < this.recordings.length; iter++) {
            if (ids.has(this.recordings[iter].id)) {
                // mode is where we came from, so always flip for flag
                if (this.isEnrollmentTeamMode) {
                    this.recordings[iter].is_dismissed_by_enrollment_team = !mode;
                } else {
                    this.recordings[iter].is_dismissed = !mode;
                }
                // make sure vue sees the change
                this.$set(this.recordings, iter, this.recordings[iter]);
            }
        }

        if (this.isEnrollmentTeamMode) {
            await recordingsRepo.toggleArchived(Array.from(ids), !mode, true);
        } else {
            await recordingsRepo.toggleArchived(Array.from(ids), !mode);
        }
        await this.retrieveCallRecordings();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async viewNext() {
        if (this.viewTexts) {
            if (this.viewId >= this.texts.length - 1) {
                this.textsTableOptions.page++;
                await this.retrieveTexts();
                this.viewId = 0;
            } else {
                this.viewId++;
            }

            this.markReadText(this.texts[this.viewId]);
        } else if (this.viewEmails) {
            if (this.viewId >= this.emails.length - 1) {
                this.emailsTableOptions.page++;
                await this.retrieveEmails();
                this.viewMessages = [...this.emails];
                this.viewId = 0;
            } else {
                this.viewId++;
            }

            this.markReadEmail(this.emails[this.viewId]);
        } else {
            if (this.viewId >= this.facebookMessages.length - 1) {
                this.facebookMessagesTableOptions.page++;
                await this.retrieveFacebookMessages();
                this.viewMessages = [...this.facebookMessages];
                this.viewId = 0;
            } else {
                this.viewId++;
            }

            this.markReadFacebookMessage(this.facebookMessages[this.viewId]);
        }
    }

    private async viewPrev() {
        if (this.viewTexts) {
            if (this.viewId <= 0) {
                this.textsTableOptions.page--;
                await this.retrieveTexts();
                this.viewId = this.textsTableOptions.itemsPerPage - 1;
            } else {
                this.viewId--;
            }

            this.markReadText(this.texts[this.viewId]);
        } else if (this.viewEmails) {
            if (this.viewId <= 0) {
                this.emailsTableOptions.page--;
                await this.retrieveEmails();
                this.viewMessages = [...this.emails];
                this.viewId = this.emailsTableOptions.itemsPerPage - 1;
            } else {
                this.viewId--;
            }

            this.markReadEmail(this.emails[this.viewId]);
        } else {
            if (this.viewId <= 0) {
                this.facebookMessagesTableOptions.page--;
                await this.retrieveFacebookMessages();
                this.viewMessages = [...this.facebookMessages];
                this.viewId = this.facebookMessagesTableOptions.itemsPerPage - 1;
            } else {
                this.viewId++;
            }

            this.markReadFacebookMessage(this.facebookMessages[this.viewId]);
        }
    }

    private readText(text: ThreadMeta, archiveMode: boolean, index: number) {
        this.saveTab = this.activeTab;
        this.scrollSave = window.scrollY;
        this.viewId = index;
        this.viewArchiveMode = archiveMode;
        this.viewMessages = this.texts;
        this.viewTexts = true;
        this.viewEmails = false;
        this.viewMode = true;
        this.markReadText(text, true);
    }

    private readEmail(email: ThreadMeta, archiveMode: boolean, index: number) {
        this.saveTab = this.activeTab;
        this.scrollSave = window.scrollY;
        this.viewId = index;
        this.viewTexts = false;
        this.viewEmails = true;
        this.viewMode = true;
        this.viewArchiveMode = archiveMode;
        this.viewMessages = this.emails;
        this.markReadEmail(email, true);
    }

    private readFacebookMessages(facebookMessage: ThreadMeta, archiveMode: boolean, index: number) {
        this.saveTab = this.activeTab;
        this.scrollSave = window.scrollY;
        this.viewId = index;
        this.viewTexts = false;
        this.viewEmails = false;
        this.viewMode = true;
        this.viewArchiveMode = archiveMode;
        this.viewMessages = this.facebookMessages;
        this.markReadFacebookMessage(facebookMessage, true);
    }

    private markUnread(meta: ThreadMeta) {
        if (meta.is_text) {
            this.markReadText(meta, false);
        } else if (meta.is_email) {
            this.markReadEmail(meta, false);
        } else {
            this.markReadFacebookMessage(meta, false);
        }
    }

    private markReadText(text: ThreadMeta, flag = true) {
        if (text.is_read === flag || text.incoming_id === 0) {
            return;
        }
        if (!text.is_dismissed && flag) {
            textsStore.decrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                textsStore.decrementTeamInboxCount();
            }
        }
        if (!text.is_dismissed && !flag) {
            textsStore.incrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                textsStore.incrementTeamInboxCount();
            }
        }

        loadingState.loadingIncrement(this.loadingKey);
        const moreIds = [];
        for (let iter = 0; iter < this.texts.length; iter++) {
            if (this.texts[iter].incoming_id === text.incoming_id) {
                this.texts[iter].is_read = flag;

                // Update textsStored
                const index = this.textsStored.findIndex(e => e.incoming_id === text.incoming_id);
                if (index >= 0) {
                    this.textsStored[index].is_read = flag;
                }

                // make sure vue sees the change
                this.$set(this.texts, iter, this.texts[iter]);
                const tmpThread = this.texts[iter].thread;
                // if (tmpThread.length >= messageThreadLimit) {
                //     tmpThread = (await textsRepo.getMessagesForFamily(this.texts[iter].family_id))[0];
                // }
                for (const message of tmpThread) {
                    if (message.type === MessageDirection.INCOMING && message.id !== text.incoming_id) {
                        moreIds.push(message.id);
                    }
                }
            }
        }

        textsRepo.markRead(text.incoming_id, flag);

        for (const id of moreIds) {
            textsRepo.markRead(id, flag);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private markReadEmail(email: ThreadMeta, flag = true) {
        if (email.is_read === flag) {
            return;
        }
        if (!email.is_dismissed && flag) {
            emailsStore.decrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                emailsStore.decrementTeamInboxCount();
            }
        }
        if (!email.is_dismissed && !flag) {
            emailsStore.incrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                emailsStore.incrementTeamInboxCount();
            }
        }
        loadingState.loadingIncrement(this.loadingKey);

        const moreIds = [];

        for (let iter = 0; iter < this.emails.length; iter++) {
            if (this.emails[iter].incoming_id === email.incoming_id) {
                this.emails[iter].is_read = flag;

                // Update emailsStored
                const index = this.emailsStored.findIndex(e => e.incoming_id === email.incoming_id);
                if (index >= 0) {
                    this.emailsStored[index].is_read = flag;
                }

                // make sure vue sees the change
                this.$set(this.emails, iter, this.emails[iter]);
                const tmpThread = this.emails[iter].thread;
                // if (tmpThread.length >= messageThreadLimit) {
                //     tmpThread = await emailsRepo.getSingleThread(this.emails[iter].incoming_id);
                // }
                for (const message of tmpThread) {
                    if (message.type === MessageDirection.INCOMING && message.id !== email.incoming_id) {
                        moreIds.push(message.id);
                    }
                }
            }
        }

        emailsRepo.markRead(email.incoming_id, flag);

        for (const id of moreIds) {
            emailsRepo.markRead(id, flag);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private markReadFacebookMessage(facebookMessage: ThreadMeta, flag = true) {
        if (facebookMessage.is_read === flag) {
            return;
        }
        if (!facebookMessage.is_dismissed && flag) {
            facebookMessagesStore.decrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                facebookMessagesStore.decrementTeamInboxCount();
            }
        }
        if (!facebookMessage.is_dismissed && !flag) {
            facebookMessagesStore.incrementInboxCount();
            if (this.isEnrollmentTeamMode) {
                facebookMessagesStore.incrementTeamInboxCount();
            }
        }

        loadingState.loadingIncrement(this.loadingKey);

        const moreIds = [];

        for (let iter = 0; iter < this.facebookMessages.length; iter++) {
            if (this.facebookMessages[iter].incoming_id === facebookMessage.incoming_id) {
                this.facebookMessages[iter].is_read = flag;

                // Update facebookMessagesStored
                const index = this.facebookMessagesStored.findIndex(e => e.incoming_id === facebookMessage.incoming_id);
                if (index >= 0) {
                    this.facebookMessagesStored[index].is_read = flag;
                }

                // make sure vue sees the change
                this.$set(this.facebookMessages, iter, this.facebookMessages[iter]);
                const tmpThread = this.facebookMessages[iter].thread;
                // if (tmpThread.length >= messageThreadLimit) {
                //     tmpThread = (await facebookMessagesRepo.getMessagesForFamily(this.facebookMessages[iter].family_id, null, true)).entities;
                // }
                for (const message of tmpThread) {
                    if (message.type === MessageDirection.INCOMING && message.id !== facebookMessage.incoming_id) {
                        moreIds.push(message.id);
                    }
                }
            }
        }

        facebookMessagesRepo.markRead(facebookMessage.incoming_id, flag);

        for (const id of moreIds) {
            facebookMessagesRepo.markRead(id, flag);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private markRecording(recording: Recording, flag: boolean, index: number) {
        if (!this.isEnrollmentTeamMode && recording.is_read === flag) {
            return;
        } else if (this.isEnrollmentTeamMode && recording.is_read_by_enrollment_team === flag) {
            return;
        }

        if (this.isEnrollmentTeamMode) {
            if (!recording.is_dismissed_by_enrollment_team && flag) {
                recordingsStore.decrementEnrollmentInboxCount();
            }
            if (!recording.is_dismissed_by_enrollment_team && !flag) {
                recordingsStore.incrementEnrollmentInboxCount();
            }
        } else {
            if (!recording.is_dismissed && flag) {
                recordingsStore.decrementInboxCount();
            }

            if (!recording.is_dismissed && !flag) {
                recordingsStore.incrementInboxCount();
            }
        }

        loadingState.loadingIncrement(this.loadingKey);

        if (this.recordingsMemoized) {
            const indexOfMemoizedRecordings = this.recordingsMemoized.findIndex(r => r.id === recording.id);
            if (indexOfMemoizedRecordings >= 0) {
                if (this.isEnrollmentTeamMode) {
                    this.recordingsMemoized[indexOfMemoizedRecordings].is_read_by_enrollment_team = flag;
                } else {
                    this.recordingsMemoized[indexOfMemoizedRecordings].is_read = flag;
                }
            }
        }

        if (this.isEnrollmentTeamMode) {
            this.recordings[index].is_read_by_enrollment_team = flag;
        } else {
            this.recordings[index].is_read = flag;
        }
        // make sure vue sees the change
        this.$set(this.recordings, index, this.recordings[index]);

        if (this.isEnrollmentTeamMode) {
            recordingsRepo.markAsRead(recording, flag, true);
        } else {
            recordingsRepo.markAsRead(recording, flag);
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private closeView(needRefresh = false) {
        this.viewMode = false;
        if (needRefresh) {
            this.retrieveMessages();
        }
    }

    private quickText(newText: OutgoingText, familyId: number) {
        for (const textMeta of this.texts) {
            if (textMeta.family_id === familyId) {
                (textMeta.thread as TextThread).unshift(newText);
            }
        }
    }

    private newFacebookMessage(newFacebookMessage: FacebookOutgoingMessage, familyId: number) {
        for (const facebookMessageMeta of this.facebookMessages) {
            if (facebookMessageMeta.family_id === familyId) {
                (facebookMessageMeta.thread as FacebookThread).unshift(newFacebookMessage);
            }
        }
    }

    private emailReply(newReply: OutgoingEmail, incomingId: number) {
        for (const emailMeta of this.emails) {
            if (emailMeta.incoming_id === incomingId) {
                (emailMeta.thread as EmailThread).unshift(newReply);
            }
        }
    }

    private async pendingEmailCancelled() {
        await this.retrieveEmails();
    }

    private async pendingEmailUpdated() {
        await this.retrieveEmails();
    }

    private async pendingTextCancelled() {
        await this.retrieveTexts();
    }

    private async pendingTextUpdated() {
        await this.retrieveTexts();
    }
}
