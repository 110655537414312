import { AbstractRepository } from '@/repositories/abstract-repository';
import { ApplyMarketingCampaignDto, MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';

export class MarketingCampaignsRepository extends AbstractRepository<MarketingCampaign> {
    protected endpoint = 'marketing/campaigns';

    public async retrieve(orgId: number): Promise<Array<MarketingCampaign>> {
        const response = await this.get(null, { org_id: orgId });
        return response.entities;
    }

    public async applyToFamilies(campaignId: number, payload: ApplyMarketingCampaignDto): Promise<void> {
        await this.client.post(`${this.endpoint}/${campaignId}/apply`, payload);
    }
}
