




























import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { StatsStore } from '@/store/stats-store';
import { LoadingStore } from '@/store/loading-store';
import Highcharts, { XAxisOptions } from 'highcharts';
import {
    countPeriodsToXAxis,
    countPeriodsToAreaGraphSeries
} from '@/charts/chart-utils';
import { addMonths, formatDateForApi } from '@/date-time/date-time-utils';

const loadingState = getModule(LoadingStore);
const statsState = getModule(StatsStore);

@Component({
    components: { }
})
export default class AreaChartExample extends Mixins(LocaleMixin) {
    private monthsBack = 2;
    private months = [...Array(10).keys()].map((i) => {
        return {
            value: i + 2,
            text: String(i + 2) + ' month(s) back'
        };
    });

    private chartOptions: Highcharts.Options = {
        chart: {
            type: 'area',
            height: '600'
        },
        title: {
            text: 'Statuses Over Time'
        },
        subtitle: {
            text: 'Timeline of Statuses'
        },
        xAxis: {
            categories: [],
            tickmarkPlacement: 'on'
        },
        yAxis: {
            labels: {
                format: '{value}%'
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.percentage:.0f}%</b> ({point.y:,.0f})<br/>',
            split: true
        },
        plotOptions: {
            area: {
                stacking: 'percent',
                lineColor: '#ffffff',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#ffffff'
                }
            }
        },
        series: [
        ]
    };

    async created() {
        await this.prepStatusCountPeriods();
    }

    async prepStatusCountPeriods() {
        loadingState.loadingIncrement('areaChart');
        const startDate = formatDateForApi(addMonths(new Date(), -1 * this.monthsBack));
        const endDate = (new Date()).toISOString().substr(0, 10);
        await statsState.initStatusCountPeriods({
            start_date: startDate,
            end_date: endDate,
            period_type: '3'
        });
        this.updateChart();
        loadingState.loadingDecrement('areaChart');
    }

    async updateStatusCountPeriods() {
        loadingState.loadingIncrement('areaChart');
        const startDate = formatDateForApi(addMonths(new Date(), -1 * this.monthsBack));
        const endDate = formatDateForApi(new Date());
        await statsState.retrieveStatusCountPeriods({
            start_date: startDate,
            end_date: endDate,
            period_type: '3'
        });
        this.updateChart();
        loadingState.loadingDecrement('areaChart');
    }

    private updateChart() {
        this.chartOptions.series = countPeriodsToAreaGraphSeries(statsState.statusCountPeriods, '#0060AF', false, true);
        (this.chartOptions.xAxis as XAxisOptions).categories = countPeriodsToXAxis(statsState.statusCountPeriods, 3);
        this.chartOptions.title!.text = 'Statuses Over Last ' + this.monthsBack + ' Months';
    }

}
