var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3"},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-data-table',{key:_vm.key,staticClass:"truncated-columns",attrs:{"headers":_vm.workflowHeaders,"items":_vm.workFlowsArray,"item-key":"id","must-sort":"","sort-by":"name","items-per-page":_vm.isCrmPlus ? 25 : 100,"hide-default-footer":!_vm.isCrmPlus},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.isCrmPlus)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(_vm.orgs.length > 1)?_c('emphasized-select',{attrs:{"label":"* View Automations for","items":_vm.orgs,"item-text":"name","return-object":"","hide-details":true},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}}):_vm._e()],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":"Include inactive automations","data-cy":"include-inactive-workflows"},model:{value:(_vm.includeInactiveWorkflows),callback:function ($$v) {_vm.includeInactiveWorkflows=$$v},expression:"includeInactiveWorkflows"}})],1),(_vm.canEditWorkflows)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto"}},[_c('base-button-secondary',{staticClass:"mr-1 mt-2 mp-btn-primary",attrs:{"data-cy":"add-automation-btn"},on:{"click":_vm.addNewWorkflow}},[_vm._v(" Add Automation ")]),(_vm.isAddNew)?_c('manage-workflow',{on:_vm._d({},[_vm.updatedEvent,_vm.workflowsUpdated,_vm.closeEvent,function($event){_vm.isAddNew = false}]),model:{value:(_vm.isAddNew),callback:function ($$v) {_vm.isAddNew=$$v},expression:"isAddNew"}}):_vm._e()],1):_vm._e()],1):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.description}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('base-button-secondary',{staticClass:"view-workflow",on:{"click":function($event){return _vm.viewWorkflow(item)}}},[_c('span',[_vm._v(_vm._s((_vm.isCrmPlus && _vm.canEditWorkflows) ? 'View/Edit' : 'View'))])])]}},{key:"item.has_mixed_coverage",fn:function(ref){
var item = ref.item;
return [(_vm.isWorkflowPartiallyActive(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v("Enabled at some but not all locations")])]):_vm._e()]}},{key:"item.is_enabled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"my-0",attrs:{"disabled":_vm.isCrmPlus && !_vm.canEditWorkflows,"hide-details":""},on:{"change":function($event){return _vm.toggleWorkflow(item)}},model:{value:(item.is_enabled),callback:function ($$v) {_vm.$set(item, "is_enabled", $$v)},expression:"item.is_enabled"}})]}}])}),_c('view-workflow',{attrs:{"workflow":_vm.workflow,"granted":_vm.canEditWorkflows || _vm.isSuperUser},on:_vm._d({},[_vm.closeEvent,_vm.reset,_vm.updatedEvent,_vm.workflowsUpdated]),model:{value:(_vm.isWorkflowSelected),callback:function ($$v) {_vm.isWorkflowSelected=$$v},expression:"isWorkflowSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }