import { IntegrationRole, IntegrationRoleMapping, IntegrationRoleMappingDto } from '@/integrations/models/position-roles';
import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    CcfIntegration,
    Integration,
    IntegrationExportChildPostDto,
    IntegrationPartner,
    IntegrationPartnerToggle,
    IntegrationLocationMappingId,
    ServiceAccount,
    ServiceAccountPostDto,
    FacebookPage,
    CcfInterview, LocationIntegrationToggle
} from '@/integrations/models/integration';
import { AxiosRequestConfig } from 'axios';

export class IntegrationRepository extends AbstractRepository<Integration> {
    protected endpoint = 'integrations';

    /**
     * Export the child and it's family to the management system.
     *
     * @param data
     */
    public async exportChild(data: IntegrationExportChildPostDto) {
        await this.client.post(this.endpoint + '/export', data);
    }

    public async manageSyncAll() {
        await this.client.post(this.endpoint + '/llm/export');
    }

    public async getAllActiveIntegrations(): Promise<Array<Integration>> {
        const response = await this.client.get(this.endpoint + '/active');
        return response.data;
    }

    public async getActiveIntegrationsByPartnerId(partnerId: number): Promise<Array<Integration>> {
        const response = await this.client.get(`${this.endpoint}/active/${partnerId}`);
        return response.data;
    }

    public async getCCF(): Promise<Array<CcfIntegration>> {
        const response = await this.client.get(this.endpoint + '/ccf-mappings');
        return response.data;
    }

    public async getCcfInterviews(ccfDomain: string): Promise<Array<CcfInterview>> {
        const response = await this.client.get(this.endpoint + '/ccf/interviews', { params: { ccf_domain: ccfDomain } });
        return response.data;
    }

    public async getAllIntegrationPartners(): Promise<Array<IntegrationPartner>> {
        const response = await this.client.get(this.endpoint + '/all');
        return response.data;
    }

    public async togglePartner(payload: IntegrationPartnerToggle): Promise<IntegrationPartner> {
        const response = await this.client.put(this.endpoint, payload);
        return response.data;
    }

    public async getIntegrationLocationMappingIds(managementSystemId: number | null | undefined = null): Promise<Array<IntegrationLocationMappingId>> {
        let payload: AxiosRequestConfig = {};
        if (managementSystemId) {
            payload = {
                params: { management_system: managementSystemId }
            };
        }

        try {
            const response = await this.silentClient.get(this.endpoint + '/centers', payload);
            return response.data;
        } catch (e) {
            return [];
        }
    }

    public async getLocationIntegrationsByPartnerId(partnerId: number): Promise<Array<LocationIntegrationToggle>> {
        try {
            const response = await this.silentClient.get(`${this.endpoint}/${partnerId}/centers`);
            return response.data;
        } catch (e) {
            return [];
        }
    }

    public async setLocationIntegrationByPartnerId(partnerId: number, toggles: Array<LocationIntegrationToggle>): Promise<Array<LocationIntegrationToggle>> {
        try {
            const response = await this.silentClient.put(`${this.endpoint}/${partnerId}/centers`, toggles);
            return response.data;
        } catch (e) {
            return [];
        }
    }

    /**
     * Get the roles from the integration partner.
     *
     * @param partnerId
     */
    public async getIntegrationRoles(partnerId: number): Promise<Array<IntegrationRole>> {
        const response = await this.client.get(`${this.endpoint}/${partnerId}/roles`);
        return response.data;
    }

    /**
     * Get the role mappings from the integration partner.
     *
     * @param partnerId
     */
    public async getIntegrationRoleMappings(partnerId: number): Promise<Array<IntegrationRoleMapping>> {
        const response = await this.client.get(`${this.endpoint}/${partnerId}/roles/mappings`);
        return response.data;
    }

    /**
     * Set the role mappings for the integration partner.
     *
     * @param partnerId
     * @param dto
     */
    public async setIntegrationRoleMappings(partnerId: number, dto: Array<IntegrationRoleMappingDto>): Promise<Array<IntegrationRoleMapping>> {
        const response = await this.client.put(`${this.endpoint}/${partnerId}/roles/mappings`, dto);
        return response.data;
    }

    public async getServiceAccounts(): Promise<Array<ServiceAccount>> {
        const response = await this.client.get('service-accounts');
        return response.data;
    }

    public async createServiceAccount(payload: ServiceAccountPostDto): Promise<ServiceAccount> {
        const response = await this.client.post('service-accounts', payload);
        return response.data;
    }

    public async updateServiceAccount(id: number): Promise<ServiceAccount> {
        const response = await this.client.put('service-accounts/' + `${id}` + '/reset-password', {});
        return response.data;
    }

    public async deleteServiceAccount(id: number) {
        await this.client.delete('service-accounts/' + `${id}`);
    }

    public async sendFacebookPageData(payload: object): Promise<FacebookPage> {
        const response = await this.client.post(this.endpoint + '/facebook/page_access_token', payload);
        return response.data;
    }
}
