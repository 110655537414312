import { CrmEntity, NamedLink, ValuedLink } from '@/models/base';
import { OrganizationLink } from '@/organizations/models/organization';
import { FieldLink } from '@/automation/landing-pages/models/landing-page-fields';
import { StaffLink } from '@/staff/models/user';
import { DripCampaignStatus } from '@/automation/drip-campaigns/drip-campaign-constants';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';

export interface DripCampaignEventAction {
    email_event: ValuedLink | null;
    text_event: ValuedLink | null;
    event_action: ValuedLink;
    drip_campaign: NamedLink | null;
    workflow: NamedLink | null;
}

export interface DripCampaignDrip {
    order: number;
    name: string;
    due_percentage: number | null;
    interval_amount: number | null;
    interval_unit: ValuedLink | null;
    comm_type: ValuedLink;
    email_template: NamedLink | null;
    text_template: NamedLink | null;
    event_actions: Array<DripCampaignEventAction>;
}

export interface DripCampaignStopTrigger {
    type: ValuedLink;
    status_value: NamedLink | null;
    event_type_value: ValuedLink | null;
}

export interface DripCampaign extends CrmEntity {
    name: string;
    organization: OrganizationLink;
    timing_type: ValuedLink;
    interval_amount: number | null;
    interval_unit: ValuedLink | null;
    relative_field: FieldLink | null;
    description: string;
    status: ValuedLink;
    number_of_drips: number;
    last_edited_datetime: string;
    last_edited_by_staff: StaffLink | null;
    drips: Array<DripCampaignDrip> | null;
    stop_triggers: Array<DripCampaignStopTrigger> | null;
    enabled_center_ids: Array<number>;
}

export interface DripCampaignStatusUpdate {
    status: DripCampaignStatus;
}

export interface DripCampaignEventActionDto {
    email_event: number | null;
    text_event: number | null;
    event_action: number;
    drip_campaign: number | null;
    workflow: number | null;
}

export interface DripCampaignDripDto {
    name: string;
    due_percentage: number | null;
    interval_amount: number | null;
    interval_unit: number | null;
    comm_type: number;
    email_template: number | null;
    text_template: number | null;
    event_actions: Array<DripCampaignEventActionDto>;
}

export interface DripCampaignStopTriggerDto {
    type: number;
    status_value: number | null;
    event_type_value: number | null;
}

export interface DripCampaignDto extends AbstractCreateDto {
    name: string;
    organization: number;
    timing_type: number;
    interval_amount: number | null;
    interval_unit: number | null;
    relative_field: number | null;
    description: string;
    drips: Array<DripCampaignDripDto>;
    stop_triggers: Array<DripCampaignStopTriggerDto>;
    enabled_center_ids: Array<number> | null;
}

export interface DripCampaignUpdateDto extends DripCampaignDto, AbstractUpdateDto {

}

export interface GroupDripCampaignDto extends AbstractCreateDto {
    campaign: number;
    excluded_families: Array<number> | null;
    included_families: Array<number> | null;
    org_id: number;
}

export type DripStatus = 'Sent' | 'Pending' | 'Failed' | 'Cancelled' | 'Suspended';

export const activeDripStatuses: Array<DripStatus> = ['Pending', 'Suspended'];

export interface FamilyDrip {
    name: string;
    order: number;
    send_datetime: string;
    status: DripStatus;
    template_name: string;
    type: 'Email' | 'Text';
}

export interface DripCampaignHistory extends CrmEntity {
    drip_campaign: NamedLink;
    drips: Array<FamilyDrip>;
}

export interface DripCampaignHistoryDisplay {
    campaignName: string;
    dripName: string;
    templateName: string;
    sendDatetime: string;
    dripStatus: string;
}

export interface DripCampaignHistoryCreate {
    drip_campaign: number;
}
