
import { BASE_DARK_BLUE } from '@/core/style-utils';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VTabs from 'vuetify/lib/components/VTabs/VTabs';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VTabs] });
export default base.extend({
    name: 'base-tabs-icons-and-text',
    props: {
        // set the tab text/icon to be centered
        centered: {
            type: Boolean,
            default: false
        },
        // the color of the tab text
        color: {
            type: String,
            default: BASE_DARK_BLUE
        },
        // set the tabs to take up the full size of the container
        grow: {
            type: Boolean,
            default: false
        },
        // set the height of the tab bar
        height: {
            type: String,
            default: '4.5rem'
        },
        // set whether or no the the slider should be shown
        hideSlider: {
            type: Boolean,
            default: false
        },
        // show stacked icons and text
        iconsAndText: {
            type: Boolean,
            default: true
        },
        // the color of the slider
        sliderColor: {
            type: String,
            default: BASE_DARK_BLUE
        },
        // set the size of the slider (the indicator for which tab is selected)
        // (this doesn't seem to be working correctly with rem)
        sliderSize: {
            type: Number,
            default: 5
        }
    },
    computed: {
        // add base-tabs to the css class of the rendered element
        classes(): object {
            return {
                ...VTabs.options.computed.classes.call(this),
                'base-tabs': true,
                'base-tabs-icons-and-text': true
            };
        }
    }
});
