var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{class:_vm.isLineLeaderEnroll ? 'ml-1': 'mx-1'},[_c('v-col',{staticClass:"text-h4 font-weight-bold",attrs:{"cols":"6"}},[_vm._v(" Location Information ")]),_c('v-col',[_c('v-row',{staticClass:"justify-end pr-2",attrs:{"no-gutters":""}},[(_vm.showInactiveToggle)?_c('v-switch',{attrs:{"label":"Include inactive locations","data-cy":"inactive-toggle"},model:{value:(_vm.showInactiveLocations),callback:function ($$v) {_vm.showInactiveLocations=$$v},expression:"showInactiveLocations"}}):_vm._e()],1)],1),(_vm.allLocationsCount >= 10)?_c('v-col',[_c('search-text-field',{attrs:{"label":"Search Locations","data-cy":"search-locations-bar"},on:{"input":_vm.doSearch}})],1):_vm._e()],1),_c('base-card',{class:_vm.isLineLeaderEnroll ? 'mx-0': ''},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-text',{staticClass:"pb-0"},[_c('base-data-table',{staticClass:"truncated-columns location-information-table",attrs:{"items":_vm.loadedCenters,"item-key":"id","headers":_vm.locationHeaders,"server-items-length":_vm.currentItemCount,"options":_vm.options,"loading":_vm.areCentersLoading,"data-cy":"locations-table"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.action",fn:function(ref){return [(!_vm.isCenterUser)?_c('base-button-secondary',{staticClass:"mp-btn-primary",attrs:{"data-cy":"add-location-button"},on:{"click":_vm.addLocation}},[_vm._v(" Add ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.formatPhone(item.phone)}},[_c('span',[_vm._v(" "+_vm._s(_vm.formatPhone(item.phone))+" ")])]):_vm._e()]}},{key:"item.avg_weekly_tuition",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.$n(item.avg_weekly_tuition, 'currencyWhole')}},[(item.phone)?_c('span',[_vm._v(" "+_vm._s(_vm.$n(item.avg_weekly_tuition, 'currencyWhole'))+" ")]):_vm._e()])]}},{key:"item.timezone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.timezoneToName(item.timezone)}},[_vm._v(" "+_vm._s(_vm.timezoneToName(item.timezone))+" ")])]}},{key:"item.active_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.is_active ? 'Active' : 'Inactive'}},[_vm._v(" "+_vm._s(item.is_active ? 'Active' : 'Inactive')+" ")])]}},{key:"item.address1",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.contact.address.address1}},[_vm._v(" "+_vm._s(item.contact.address.address1)+" ")])]}},{key:"item.locality",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.contact.address.locality}},[_vm._v(" "+_vm._s(item.contact.address.locality)+" ")])]}},{key:"item.region",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.contact.address.region}},[_vm._v(" "+_vm._s(item.contact.address.region)+" ")])]}},{key:"item.postcode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.contact.address.postcode}},[_vm._v(" "+_vm._s(item.contact.address.postcode)+" ")])]}},{key:"item.parent_organization_name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.parent_organization_name}},[_vm._v(" "+_vm._s(item.parent_organization_name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('base-button-secondary',{on:{"click":function($event){return _vm.editLocation(item)}}},[_vm._v(" Edit ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }