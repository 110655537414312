import store from '@/store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import LayoutTabContent from '@/views/LayoutTabContent.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';
import { getModule } from 'vuex-module-decorators';

/**
 * Utilities for layout tabs.
 */
export class LayoutTabUtils {
    /**
     * Create a layout tab component.
     *
     * @param id
     * @param routes
     */
    public static async createTabComponent(id: number, routes: Array<RouteConfig> | null = null) {
        if (!routes) {
            const router = await import('@/router');
            routes = router.getRoutes(true);
        }
        Vue.component(
            `LayoutTab${id}`,
            {
                name: `LayoutTab${id}`,
                extends: LayoutTabContent,
                router: new VueRouter({
                    mode: 'abstract',
                    routes: routes
                })
            }
        );
    }

    /**
     * Create multiple layout tab components.
     */
    public static async createTabComponents() {
        const layoutTabsStore = getModule(LayoutTabsStore, store);
        const tabs = layoutTabsStore.tabs;
        if (tabs.length) {
            const router = await import('@/router');
            const routes = router.getRoutes(true);
            for (const tab of tabs) {
                await LayoutTabUtils.createTabComponent(tab.id, routes);
            }
        }
    }

    /**
     * Pre register some tab components since they can't be added after the vue instance has been created.
     * This is only needed after refreshing the page when there are already some tabs for whatever reason.
     */
    public static async preRegisterTabComponents() {
        const layoutTabsStore = getModule(LayoutTabsStore, store);
        const tabs = layoutTabsStore.tabs;
        if (tabs.length) {
            const router = await import('@/router');
            const routes = router.getRoutes(true);
            const lastTab = tabs[tabs.length - 1];
            const startId = lastTab.id + 1;
            const lastId = startId + 100;
            const promises = [];
            for (let i = startId; i < lastId; i++) {
                promises.push(LayoutTabUtils.createTabComponent(i, routes));
            }
            await Promise.all(promises);
        }
    }
}
