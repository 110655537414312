import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { Reminder } from '@/communications/reminders/models/reminder-models';
import { EmailReminderCreateDto, EmailReminderUpdateDto } from '@/communications/templates/models/message-template';

export class EmailReminderMapper implements AbstractMapper<Reminder> {
    toCreateDto(): EmailReminderCreateDto {
        throw new Error('New reminders cannot be created');
    }

    toUpdateDto(entity: Reminder): EmailReminderUpdateDto {
        return {
            id: entity.id,
            subject: entity.subject ?? '',
            content: entity.content,
            template_data: JSON.stringify(entity.template_data) ?? ''
        };
    }

}
