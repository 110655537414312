



























































































import { EventTypes } from '@/constants/event-type-constants';
import { Customer } from '@/customers/models/customer-models';
import { CustomersRepository } from '@/customers/repositories/customer-repository';
import { LocaleMixin } from '@/locales/locale-mixin';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const customersRepository = new CustomersRepository();
const loadingState = getModule(LoadingStore);

@Component({
    components: { BaseClose }
})
export default class SuperuserSwitchCustomerModal extends Mixins(LocaleMixin) {
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;

    $refs!: {
        customersTable: any;
    };

    private customerName = '';
    private customersList: Array<Customer> = [];
    private headers = [
        { text: 'DB#', value: 'id' },
        { text: 'Customer Name', value: 'name' },
        { text: 'UI', value: 'product' },
        { text: 'Country', value: 'country' },
        { text: 'Is Active', value: 'is_active' },
        { text: 'Action', value: 'action' }
    ];

    private loadingKey = 'superuserSwitch';
    private search = '';

    /**
     * The header text for the modal
     */
    private get currentlyManaging() {
        return `Currently Managing: (${authState.currentCustomerDb}) ${this.customerName}`;
    }

    /**
     * The list of customers to show in the table
     */
    private get customers(): Array<Customer> {
        return this.customersList;
    }

    get isFullSuperuser() {
        return authState.isFullSuperuser;
    }

    /**
     * Handles showing the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Emit an input event when showing the modal.
     *
     * @param showIt
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Make sure to set the customer name when showing the modal.
     */
    @Watch('modelValue')
    private async modelWatch() {
        if (this.modelValue && this.customerName === '') {
            loadingState.loadingIncrement(this.loadingKey);
            this.setCustomerName();
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        const customersResponse = await customersRepository.getAll();
        this.customersList = customersResponse.entities;
        this.setCustomerName();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Determine the text to show in the active column.
     *
     * @param customer
     */
    private activeStatus(customer: Customer): string {
        if (customer.is_under_maintenance) {
            return 'Maintenance';
        } else if (customer.is_active) {
            return 'Yes';
        }
        return 'No';
    }

    /**
     * Choose the customer from enter key input in the search field.
     */
    private chooseSearch() {
        const filteredItems = this.$refs.customersTable.$children[0].filteredItems;
        // If there is only 1 choice from the filtered results, go to that customer.
        if (filteredItems.length === 1) {
            this.goToCustomer(filteredItems[0].id);
        } else {
            for (const customer of filteredItems) {
                // If the customer id exactly matches the search, go to that customer.
                if (customer.id.toString() === this.search) {
                    this.goToCustomer(customer.id);
                }
            }
        }
    }

    /**
     * Go the specified customer's database.
     *
     * @param customerId
     */
    private goToCustomer(customerId: number) {
        if (customerId !== authState.currentCustomerDb) {
            authState.storeCurrentCustomerDb(customerId);
            const matching = this.customersList.filter((customer) => {
                return customer.id === customerId;
            });
            if (matching.length) {
                authState.setCountry(matching[0].country);
            }
            if (this.$route.name !== 'home') {
                this.$router.push({ name: 'home' });
            }
            location.reload();
        }
    }

    /**
     * Open a new tab to IMS (Internal Management System).
     */
    private goToIMS() {
        window.open('https://ims.childcarecrm.com/', '_blank');
    }

    /**
     * Handles closing the modal.
     */
    private handleClose() {
        this.modelValue = false;
    }

    /**
     * Set the customer's name for the modal header.
     */
    private setCustomerName() {
        for (const customer of this.customersList) {
            if (customer.id === authState.currentCustomerDb) {
                this.customerName = customer.name;
                break;
            }
        }
    }
}
