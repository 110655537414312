/**
 * mostly modeled after helper.js from facebook-login-vuejs
 */
export class FbSdkUtils {
    initSdk(appId: string, version: string) {
        return new Promise(resolve => {
            window.fbAsyncInit = function() {
                window.FB.init({
                    appId,
                    xfbml: false,
                    version,
                    cookie: true
                });
                resolve();
            };
            const id = 'facebook-jssdk';
            const head = document.getElementsByTagName('head')[0];
            if (!document.getElementById(id)) {
                const scriptNode = document.createElement('script');
                scriptNode.id = id;
                scriptNode.src = '//connect.facebook.net/en_US/sdk.js';
                head.appendChild(scriptNode);
            }
        });
    }

    login(options: facebook.LoginOptions): Promise<{ status: string }> {
        return new Promise(resolve => {
            window.FB.login(response => resolve(response), options);
        });
    }

    logout() {
        return new Promise(resolve => {
            window.FB.logout(response => resolve(response));
        });
    }
}
