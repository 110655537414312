


























import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class IconStackedButton extends Mixins(LocaleMixin) {
    @Prop({ required: true, type: String }) classString!: string;
    @Prop({ required: true, type: String }) iconClassString!: string;
    @Prop({ required: true, type: String }) buttonText!: string;
    @Prop({ required: false, type: String }) href!: string;
}
