










































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

@Component
export default class EmailSubject extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: string;
    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ required: true }) readonly label!: string;
    @Prop({ default: false }) readonly outlined!: boolean;
    private subjectTags = [
        {
            text: 'Organization Name',
            value: 'OrganizationName'
        },
        {
            text: 'Location Name',
            value: 'LocationName'
        },
        {
            text: 'Guardian First Name',
            value: 'LeadFirstName'
        }
    ];

    $refs!: {
        subjectInput: HTMLFormElement;
    };

    get subject(): string {
        return this.value;
    }

    set subject(value: string) {
        this.$emit('input', value);
    }

    private insertTag(tag: string) {
        const input = this.$refs.subjectInput;
        const insertString = '{{ ' + tag + ' }}';

        this.subject += insertString;

        // move cursor:
        this.$nextTick(() => {
            input.focus();
            input.selectionStart = this.subject.length;
        });
    }
}
