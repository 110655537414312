












import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';

const featureStore = getModule(FeaturesStore);

@Component
export default class BaseClose extends Mixins(LocaleMixin) {
    private lineLeaderEnroll = true;

    async created() {
        await featureStore.init();
        this.lineLeaderEnroll = featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }
}
