
// don't love that we have to do these next two lines here, but jest bombs out on this component otherwise
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VDatePicker from 'vuetify/lib/components/VDatePicker/VDatePicker';
import Vue from 'vue';
import { LocaleMixin } from '@/locales/locale-mixin';

// grab the base component we're modifying
const base = Vue.extend({ mixins: [VDatePicker, LocaleMixin] });
export default base.extend({
    name: 'base-date-picker',
    props: {
        landscape: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: '#0060AF'
        }
    },
    computed: {
        classes(): object {
            return {
                ...VDatePicker.options.computed.classes.call(this),
                'base-date-picker': true
            };
        }
    }
});
