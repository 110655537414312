import { MenuItem } from '@/models/menu-item';
import { RouteConstants } from '@/router/route-constants';
import {
    actionDashboardType,
    CustomDashboard,
    customDashboardType, enrollmentDashboardType,
    insightDashboardType
} from '@/dashboards/models/custom-dashboards';

export class LayoutItemsUtil {
    public getDashboardItems(
        canAccessBothDashboards: boolean,
        hasEnrollmentTeams: boolean,
        isEnrollmentTeamMember: boolean,
        isCenterUser: boolean,
        enrollmentLabel: string,
        isLineLeaderEnroll: boolean,
        hasCustomDashboard = false,
        customDashboards: Array<CustomDashboard> = []
    ): MenuItem {
        const dashItem: MenuItem = {
            text: 'Dashboard',
            route: 'home',
            icon: isLineLeaderEnroll ? 'columns' : 'view-dashboard',
            classes: []
        };
        const locationDashRoute = RouteConstants.DASHBOARD_LOCATION_MAIN;
        if (hasCustomDashboard) {
            const children = [];
            for (const dash of customDashboards) {
                if (!dash.is_enabled) {
                    continue;
                }
                let route = '';
                let routeParams;
                switch (dash.type) {
                    case actionDashboardType:
                        route = locationDashRoute;
                        break;
                    case insightDashboardType:
                        route = 'dashboard-corp';
                        break;
                    case enrollmentDashboardType:
                        route = 'dashboard-et';
                        break;
                    case customDashboardType:
                        route = 'dashboard-custom';
                        routeParams = { dashId: String(dash.id) };
                        break;
                }
                children.push({
                    text: dash.name,
                    route,
                    routeParams
                });
            }
            dashItem.children = children;
            return dashItem;
        }
        let children = null;
        if (canAccessBothDashboards || !isCenterUser) {
            children = [
                {
                    text: 'Action',
                    route: locationDashRoute,
                    classes: []
                },
                {
                    text: 'Insight',
                    route: 'dashboard-corp',
                    classes: []
                }
            ];
        }
        if (hasEnrollmentTeams) {
            if (!children) {
                if (isCenterUser) {
                    children = [{
                        text: 'Action',
                        route: locationDashRoute,
                        classes: []
                    }];
                } else {
                    children = [{
                        text: 'Insight',
                        route: 'dashboard-corp',
                        classes: []
                    }];
                }
            }
            children.push({
                text: enrollmentLabel,
                route: 'dashboard-et',
                classes: []

            });
        }

        if (children) {
            dashItem.children = children;
        }
        return dashItem;
    }
}
