var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{class:_vm.isLineLeaderEnroll ? 'mx-0' : ''},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-title',[_vm._v(" User Permissions "),_c('v-spacer'),_c('base-button-plain',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('base-close')],1)],1),_c('v-card-text',[_c('base-data-table',{class:_vm.tableClass,attrs:{"dense":"","headers":_vm.headers,"items":_vm.getStaff,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"header.fullName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"filter-container"},[_c('base-card',{staticClass:"filter-card"},[_c('v-card-subtitle',{staticClass:"mb-n6",staticStyle:{"text-align":"center"}},[_vm._v(" Filter Users ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"mb-1",attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('emphasized-select',{staticClass:"mb-1",attrs:{"multiple":"","label":"Filter by Position","items":_vm.positionTitles,"item-value":"id","item-text":"value","clearable":"","hide-details":"auto"},model:{value:(_vm.positionFilter),callback:function ($$v) {_vm.positionFilter=$$v},expression:"positionFilter"}}),_c('emphasized-select',{attrs:{"multiple":"","label":"Filter by Organization Level","items":_vm.orgs,"item-value":"id","item-text":"name","clearable":"","hide-details":"auto"},model:{value:(_vm.orgLevelFilter),callback:function ($$v) {_vm.orgLevelFilter=$$v},expression:"orgLevelFilter"}})],1)],1)],1),_c('div',{staticClass:"permission-header",on:{"click":function($event){_vm.sortNameParam=!_vm.sortNameParam}}},[_c('span',[_vm._v(_vm._s(header.text))]),(_vm.sortNameParam)?_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-up theme--light",staticStyle:{"font-size":"18px"}}):_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-down theme--light",staticStyle:{"font-size":"18px"},attrs:{"aria-hidden":"true"}})])]}},{key:"header.position",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"permission-header",on:{"click":function($event){_vm.sortPositionParam=!_vm.sortPositionParam}}},[_c('span',[_vm._v(_vm._s(header.text))]),(_vm.sortPositionParam)?_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-up theme--light",staticStyle:{"font-size":"18px"}}):_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-down theme--light",staticStyle:{"font-size":"18px"},attrs:{"aria-hidden":"true"}})])]}},{key:"header.orgLevel",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"permission-header",on:{"click":function($event){_vm.sortOrgLevelParam=!_vm.sortOrgLevelParam}}},[_c('span',[_vm._v(_vm._s(header.text))]),(_vm.sortOrgLevelParam)?_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-up theme--light",staticStyle:{"font-size":"18px"}}):_c('i',{staticClass:"v-icon notranslate mdi mdi-arrow-down theme--light",staticStyle:{"font-size":"18px"},attrs:{"aria-hidden":"true"}})])]}},_vm._l((_vm.permissionsList),function(perm,i){return {key:_vm.getHeaderSlot(perm),fn:function(ref){
var header = ref.header;
return [_c('div',{key:'header' + i,staticClass:"checkbox-area"},[_c('v-checkbox',{attrs:{"input-value":_vm.getPermissionHeader(perm.label)},on:{"change":function($event){return _vm.togglePermissionColumn($event, perm.label)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{class:'test-label' + (_vm.isGrouped(perm.label) ? ' grouped-label' : '')},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-1",attrs:{"color":"primary","dark":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(header.text))]),_c('br'),_c('span',{domProps:{"innerHTML":_vm._s(header.description)}})])])],1)]},proxy:true}],null,true)})],1)]}}}),{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.position)+" ")]}},{key:"item.orgLevel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orgLevel ? item.orgLevel.values.name : '')+" ")]}},_vm._l((_vm.permissionsList),function(perm,i){return {key:_vm.getItemSlot(perm),fn:function(ref){
var item = ref.item;
return [(_vm.showPermissionCheckBox(item, perm.label))?_c('v-checkbox',{key:'checkbox' + i,staticClass:"mt-auto mb-auto",attrs:{"input-value":_vm.getGrant(item, perm.label),"disabled":_vm.isReadOnly(item) || _vm.isGroupLocked(item, perm.label),"hide-details":""},on:{"change":function($event){return _vm.permissionChange($event, item, perm.label)}}}):_vm._e()]}}}),{key:"item.copy",fn:function(ref){
var item = ref.item;
return [(_vm.copyStaffId && _vm.copyStaffId !== item.staff.id && !_vm.isReadOnly(item))?_c('v-icon',{attrs:{"title":"Paste permissions"},on:{"click":function($event){return _vm.doPaste(item)}}},[_vm._v(" mdi-content-paste ")]):(!_vm.copyStaffId)?_c('v-icon',{attrs:{"title":"Copy this user's permissions"},on:{"click":function($event){return _vm.startCopy(item)}}},[_vm._v(" mdi-content-copy ")]):_c('v-icon',[_vm._v(" mdi-blank ")])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('base-button-primary',{on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('base-button-secondary',{on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }