import { AbstractRepository } from '@/repositories/abstract-repository';
import type { LocationAdvisoryDto } from '@/enrollment-center/models/enrollment-center-location-models';
import { LocationAdvisory } from '@/enrollment-center/models/enrollment-center-location-models';

/**
 * A separate repository just for advisories because it's sort of a mix between
 * settings and location info
 */
export class EnrollmentCenterLocationAdvisoriesRepository extends AbstractRepository<LocationAdvisory> {
    readonly endpoint = '/client/settings/call-centers/advisories';

    /**
     * @param advisory
     */
    public async createAdvisory(advisory: LocationAdvisoryDto): Promise<LocationAdvisory> {
        const response = await this.client.post<LocationAdvisory>(this.endpoint, advisory);
        return response.data;
    }

    /**
     * @param id
     * @param update
     */
    public async updateAdvisory(id: number, update: LocationAdvisoryDto): Promise<LocationAdvisory> {
        const response = await this.client.put<LocationAdvisory>(this.endpoint + '/' + id, update);
        return response.data;
    }
}
