













































































































































































































































































































































































































































































































import SuperuserSwitchCustomerModal from '@/customers/components/SuperuserSwitchCustomerModal.vue';
import ManageStaffModal from '@/staff/components/ManageStaffModal.vue';
import FamilySearch from '@/families/components/FamilySearch.vue';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import Loading from '@/components/Loading.vue';
import AddFamily from '@/families/components/AddFamily.vue';
import CenterSelect from '@/components/CenterSelect.vue';
import NylasEmailAuth from '@/integrations/components/NylasEmailAuth.vue';
import LayoutMixin from '@/views/LayoutMixin.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import HelpPopup from '@/staff/components/HelpPopup.vue';

@Component({
    components: {
        HelpPopup,
        BaseClose,
        NylasEmailAuth,
        AddFamily,
        CenterSelect,
        FamilySearch,
        Loading,
        SuperuserSwitchCustomerModal,
        ManageStaffModal
    }
})
export default class Layout extends Mixins(LocaleMixin, LayoutMixin) {
    created() {
        document.getElementsByTagName('body')[0].classList.remove('moment-path');
        document.getElementsByTagName('html')[0].classList.remove('moment-path');
    }
};
