











































































































































































































import AffectedLocationsModal from '@/automation/workflows/components/AffectedLocationsModal.vue';
import { WorkflowLocationsStore } from '@/automation/workflows/store/workflow-locations-store';
import { Center, CentersEnabled } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { VForm } from '@/types/types';
import { DripCampaign, DripCampaignDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { EventTypes } from '@/constants/event-type-constants';
import { LoadingStore } from '@/store/loading-store';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { getModule } from 'vuex-module-decorators';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { DripCampaignsMapper } from '@/automation/drip-campaigns/mappers/drip-campaigns-mapper';
import {
    DripCampaignStatus,
    DripCampaignTiming,
    DripFieldType
} from '@/automation/drip-campaigns/drip-campaign-constants';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';
import { FieldsStore } from '@/crm-types/store/fields-store';
import DripCampaignDrip from '@/automation/drip-campaigns/components/DripCampaignDrip.vue';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { WorkflowsStore } from '@/automation/workflows/store/workflows-store';
import { StatusesStore } from '@/families/store/statuses-store';
import DripCampaignStopTrigger from '@/automation/drip-campaigns/components/DripCampaignStopTrigger.vue';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';

const textTemplatesState = getModule(TextTemplatesStore);
const emailTemplatesState = getModule(EmailTemplatesStore);
const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const campaignsRepo = new DripCampaignsRepository();
const campaignsState = getModule(DripCampaignsStore);
const centersStore = getModule(CentersStore);
const mapper = new DripCampaignsMapper();
const dripCampaignUtils = new DripCampaignUtils();
const fieldsState = getModule(FieldsStore);
const crmTypesState = getModule(CrmTypesStore);
const workflowLocationsStore = getModule(WorkflowLocationsStore);
const workflowsState = getModule(WorkflowsStore);
const statusesState = getModule(StatusesStore);
const featuresStore = getModule(FeaturesStore);

@Component({
    components: { AffectedLocationsModal, DripCampaignStopTrigger, DripCampaignDrip }
})
export default class ManageDripCampaign extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ default: null }) readonly campaign!: DripCampaign | null;
    @Prop({ default: false }) readonly isOpen!: boolean;
    @Ref('form') readonly form!: VForm;

    private loadingKey = 'manageDripCampaign';
    private validForm = false;
    private showComms = true;
    private showStopTriggers = false;
    private centers: Array<Center> = [];
    private dto: DripCampaignDto | null = null;
    private newStatus = 0;
    private fixedTiming = DripCampaignTiming.FIXED;
    private openLocationsModal = false;
    private relativeTiming = DripCampaignTiming.RELATIVE;
    private fieldType: DripFieldType = DripFieldType.CHILD;
    private fieldTypes = [
        {
            id: DripFieldType.CHILD,
            value: 'Child'
        },
        {
            id: DripFieldType.TASK,
            value: 'Task'
        }
    ];

    private deleteEvent = EventTypes.DELETED;
    private updatedEvent = EventTypes.UPDATED;

    get currentStatus() {
        return this.campaign ? this.campaign.status.id : DripCampaignStatus.DRAFT;
    }

    get currentOrgId(): number {
        if (!this.isFranchiseModeFeatureEnabled) {
            return 1;
        } else {
            return authState.userInfoObject && authState.userInfoObject.org_id > 0 ? authState.userInfoObject.org_id : 1;
        }
    }

    get dripFields() {
        return fieldsState.stored.filter((field) => {
            return field.use_in_drip_campaigns;
        });
    }

    get fieldString() {
        if (!this.isRelative || !this.dto || !this.dto.relative_field) {
            return '';
        }
        const fields = this.filteredDripFields.filter((field) => {
            return field.id === this.dto?.relative_field;
        });
        if (fields.length) {
            return (this.fieldType === DripFieldType.CHILD ? 'Child' : 'Task') + ' ' + fields[0].value;
        }
        return '';
    }

    get filteredDripFields() {
        return this.dripFields.filter((field) => {
            return field.is_child_field === (this.fieldType === DripFieldType.CHILD);
        });
    }

    get filteredDripFieldIds() {
        return this.filteredDripFields.map((field) => {
            return field.id;
        });
    }

    get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get isNew() {
        return this.campaign === null;
    }

    get isRelative(): boolean {
        return !!this.dto && this.dto.timing_type === DripCampaignTiming.RELATIVE;
    }

    get statusOptions() {
        return campaignsState.statusOptions(this.currentStatus);
    }

    get statusWarning() {
        return this.newStatus !== this.currentStatus ? dripCampaignUtils.getStatusWarning(this.newStatus) : '';
    }

    @Watch('isRelative')
    relativeChosen() {
        if (this.isRelative) {
            this.fieldTypeChanged();
        }
    }

    @Watch('fieldType')
    fieldTypeChanged() {
        if (this.dto && this.dto.relative_field && !this.filteredDripFieldIds.includes(this.dto.relative_field)) {
            this.dto.relative_field = null;
        }
        if (this.dto && !this.dto.relative_field) {
            this.dto.relative_field = this.filteredDripFieldIds[0];
        }
    }

    @Watch('isOpen')
    async opening() {
        loadingState.loadingIncrement(this.loadingKey);
        const promises = [];
        // preload things here so subcomponents don't have to do it themselves
        promises.push(crmTypesState.initList(CrmTypeList.DRIP_EMAIL_EVENTS));
        promises.push(crmTypesState.initList(CrmTypeList.DRIP_TEXT_EVENTS));
        promises.push(crmTypesState.initList(CrmTypeList.TASK_RESULTS));
        promises.push(workflowsState.init());
        promises.push(statusesState.init());
        promises.push(textTemplatesState.initForOrg(this.currentOrgId));
        promises.push(emailTemplatesState.initForOrg(this.currentOrgId));
        promises.push(fieldsState.init());
        promises.push(campaignsState.initStatuses(this.currentStatus));
        promises.push(await centersStore.initAccessibleCenters());
        await Promise.all(promises);
        this.newStatus = this.currentStatus;
        this.showComms = true;
        this.showStopTriggers = false;
        this.dto = this.campaign ? mapper.toCreateDto(this.campaign) : mapper.createBlank();
        if (this.isNew) {
            this.addDrip();
        }
        if (this.campaign && this.campaign.relative_field && !this.filteredDripFieldIds.includes(this.campaign.relative_field.id)) {
            // flip the default field type selection if needed
            this.fieldType = (this.fieldType === DripFieldType.TASK) ? DripFieldType.CHILD : DripFieldType.TASK;
        } else {
            this.fieldType = DripFieldType.CHILD;
        }
        workflowLocationsStore.updateLocationMappings(new Array<CentersEnabled>());
        this.centers = centersStore.storedAccessibleCenters;
        loadingState.loadingDecrement(this.loadingKey);
    }

    addDrip() {
        if (this.dto) {
            this.dto.drips.push(mapper.createBlankDrip());
        }
    }

    addStopTrigger() {
        if (this.dto) {
            this.dto.stop_triggers.push(mapper.createBlankStopTrigger());
        }
    }

    closeModal() {
        this.form.reset();
        this.$emit(EventTypes.CLOSE);
    }

    deleteDrip(index: number) {
        if (this.dto) {
            this.dto.drips.splice(index, 1);
        }
    }

    deleteStopTrigger(index: number) {
        if (this.dto) {
            this.dto.stop_triggers.splice(index, 1);
        }
    }

    private locationsUpdated(campaign: DripCampaign) {
        this.$emit(EventTypes.UPDATED, campaign);
    }

    minPct(index: number) {
        if (!this.dto) {
            return 0;
        }
        return dripCampaignUtils.getMinDripPct(this.dto, index);
    }

    async save() {
        if (!this.dto) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        let campaign: DripCampaign;
        if (this.campaign === null) {
            this.dto.organization = this.currentOrgId;
            if (workflowLocationsStore.storedLocationMappings.length > 0) {
                this.dto.enabled_center_ids = workflowLocationsStore.storedLocationMappings.filter((centerData) => {
                    return centerData.enabled;
                }).map((centerData) => {
                    return centerData.center.id;
                });
            }
            campaign = await campaignsRepo.post(this.dto);
        } else {
            campaign = await campaignsRepo.putOne(this.campaign.id, this.dto);
        }
        if (this.newStatus !== campaign.status.id) {
            campaign = await campaignsRepo.updateStatus(campaign.id, this.newStatus);
        }
        campaignsState.addOrUpdateEntity(campaign);
        campaignsState.clearOrgDripCampaigns();
        this.$emit(EventTypes.DRIP_CAMPAIGNS_UPDATED, campaign);
        this.closeModal();
        loadingState.loadingDecrement(this.loadingKey);
    }
}
