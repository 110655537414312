




































































import { DripCampaignStatus } from '@/automation/drip-campaigns/drip-campaign-constants';
import { DripCampaign, GroupDripCampaignDto } from '@/automation/drip-campaigns/models/drip-campaign';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import SaveButton from '@/components/base/SaveButton.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { LoadingStore } from '@/store/loading-store';
import { VForm } from '@/types/types';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { FeatureConstants } from '@/features/feature-constants';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { FeaturesStore } from '@/features/features-store';
import BaseClose from '@/components/base/BaseClose.vue';

const authState = getModule(AuthStore, store);
const dripCampaignsRepository = new DripCampaignsRepository();
const dripCampaignStore = getModule(DripCampaignsStore);
const loadingStore = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class GroupDripCampaignModal extends Mixins(BasicValidationMixin, LocaleMixin) {
    /** The org id to build the group at. */
    @Prop() readonly extraOrgId!: number;
    /** The family ids to exclude in the group. */
    @Prop() readonly familyExcludedIds!: Array<number>;
    /** The id of the filter (group). */
    @Prop() readonly familyFilterId!: number;
    /** The family ids to exclude in the group. */
    @Prop() readonly familyIncludedIds!: Array<number>;
    /** v-model whether we should show it. */
    @Prop({ default: false }) readonly value!: boolean;

    @Ref('form') readonly form!: VForm;

    private campaignId: number | null = null;
    private campaigns: Array<DripCampaign> = [];
    private isAdded = false;
    private isValid = false;
    private loadingKey = 'GroupDripCampaignModal';

    /**
     * Handles when to show the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get currentOrgId(): number {
        if (!this.isFranchiseModeFeatureEnabled) {
            return 1;
        } else {
            return authState.userInfoObject && authState.userInfoObject.org_id > 0 ? authState.userInfoObject.org_id : 1;
        }
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.modelValue) {
            loadingStore.loadingIncrement(this.loadingKey);
            await dripCampaignStore.retrieveOrgDripCampaigns(this.currentOrgId);
            this.campaigns = (dripCampaignStore.orgDripCampaigns.get(this.currentOrgId) as Array<DripCampaign> as Array<DripCampaign>).filter((campaign: DripCampaign) => {
                return [DripCampaignStatus.PAUSED, DripCampaignStatus.PUBLISHED].includes(campaign.status.id);
            });
            loadingStore.loadingDecrement(this.loadingKey);
        } else {
            this.form.reset();
        }
    }

    /**
     * Apply the marketing campaign to the group.
     */
    private async apply() {
        if (!this.campaignId) {
            return;
        }

        loadingStore.loadingIncrement(this.loadingKey);

        const dto = {
            campaign: this.campaignId,
            excluded_families: this.familyExcludedIds,
            included_families: this.familyIncludedIds,
            org_id: this.extraOrgId
        } as GroupDripCampaignDto;
        await dripCampaignsRepository.startGroupDripCampaign(this.familyFilterId, dto);

        loadingStore.loadingDecrement(this.loadingKey);
        this.isAdded = true;
        this.$emit(EventTypes.ADDED);
        this.close();
    }

    /**
     * Get the display for each campaign in the list.
     *
     * @param campaign
     */
    getCampaignDisplay(campaign: DripCampaign): string {
        return `${campaign.name} (${campaign.status.values.value})`;
    }

    /**
     * Close the modal.
     */
    private close() {
        this.modelValue = false;
    }
}
