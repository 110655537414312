



















































































import { EnrollmentCenterLocationOfferingsMapper } from '@/enrollment-center/mappers/enrollment-center-location-offerings-mapper';
import { LocationOffering } from '@/enrollment-center/models/enrollment-center-location-models';
import { EnrollmentCenterLocationSettingsRepository } from '@/enrollment-center/repositories/enrollment-center-location-settings-repository';
import { EnrollmentCenterLocationOfferingsStore } from '@/enrollment-center/store/enrollment-center-location-offerings-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import cloneDeep from 'lodash/cloneDeep';
import { getModule } from 'vuex-module-decorators';

const loadingState = getModule(LoadingStore);
const locationSettingsRepository = new EnrollmentCenterLocationSettingsRepository();
const locationOfferingsState = getModule(EnrollmentCenterLocationOfferingsStore);
const updatesMapper = new EnrollmentCenterLocationOfferingsMapper();

@Component
export default class LocationOfferingsTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;

    // A cloned copy of the offerings. This is the copy that will be edited in the UI.
    private clonedOfferings: Array<LocationOffering> = [];
    private loadingKey = 'locationInfoTab';
    // The offerings
    private offerings: Array<LocationOffering> = [];
    // The offerings arranged for use in the template
    private offeringsForDisplay: Array<Array<LocationOffering>> = [];

    /**
     * The number of columns to display
     */
    private get columnCount(): number {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
            case 'sm':
                return 1;
            case 'md':
                return 2;
            case 'lg':
                return 3;
            case 'xl':
            default:
                return 4;
        }
    }

    /**
     * Whether or not the view is editable.
     */
    private get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    /**
     * Keep track of when offering settings change.
     */
    get offeringsChanged() {
        return locationOfferingsState.clearedCount;
    }

    /**
     * Change the way the offerings are arranged when the column count changes.
     */
    @Watch('columnCount')
    private changeDisplay() {
        if (!this.clonedOfferings.length) {
            return;
        }

        this.buildOfferingsForDisplay();
    }

    /**
     * Load the offerings
     */
    @Watch('location', { immediate: true, deep: true })
    private async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);
        await locationOfferingsState.init(this.location.id);
        this.offerings = locationOfferingsState.storedLocationOfferings(this.location.id);
        this.clonedOfferings = cloneDeep(this.offerings);
        this.buildOfferingsForDisplay();
        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * What to do when the edit, save, and cancel buttons are clicked.
     */
    @Watch('localMode')
    private async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        } else if (this.localMode === EditMode.SAVING) {
            const updates = updatesMapper.getUpdatedOfferingDtos(this.offerings, this.clonedOfferings);
            const promises = [];
            for (const update of updates) {
                promises.push(locationSettingsRepository.updateOffering(this.location.id, update));
            }
            await Promise.all(promises);
            await locationOfferingsState.retrieve(this.location.id);
            this.localMode = EditMode.SAVED;
        }
    }

    /**
     * Refresh when the offering settings have changed.
     */
    @Watch('offeringsChanged')
    private refresh() {
        this.locationChanged();
    }

    /**
     * Get the offerings for the location, grouped into the columns for display
     */
    private buildOfferingsForDisplay() {
        const offeringsInColumns: Array<Array<LocationOffering>> = [];
        const counts: Array<number> = new Array(this.columnCount);
        counts.fill(0);
        for (let i = 0; i < this.columnCount; i++) {
            offeringsInColumns.push([]);
        }

        for (let i = 0; i < this.clonedOfferings.length; i++) {
            ++counts[i % this.columnCount];
        }
        const startingIndices: Array<number> = [];
        for (let i = 0; i < counts.length; i++) {
            if (i === 0) {
                startingIndices.push(0);
            } else if (i === 1) {
                startingIndices.push(counts[0]);
            } else {
                startingIndices.push(startingIndices[i - 1] + counts[i - 1]);
            }
        }

        let column = 0;
        let columnIndex = 0;
        for (let i = 0; i < this.clonedOfferings.length; i++) {
            columnIndex = startingIndices.indexOf(i);
            if (i !== 0 && columnIndex !== -1) {
                column = columnIndex;
            }
            offeringsInColumns[column].push(this.clonedOfferings[i]);
        }

        this.offeringsForDisplay = offeringsInColumns;
    }
}
