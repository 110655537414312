









import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class DataCell extends Vue {
    @Prop({ required: true }) readonly text!: string;
    @Prop({ default: '' }) readonly extraClasses!: string;

    get classes() {
        return 'text-truncate' + (this.extraClasses ? ' ' + this.extraClasses : '');
    }
}
