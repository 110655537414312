

















































































































































































































































































import RecordingAudioModal from '@/communications/recordings/components/RecordingAudioModal.vue';
import { PendingFamilyRecording } from '@/communications/recordings/models/recording-models';
import { EventTypes } from '@/constants/event-type-constants';
import AddFamily from '@/families/components/AddFamily.vue';
import CenterlessFamilySelection from '@/families/components/CenterlessFamilySelection.vue';
import { getPendingFamilyActions } from '@/families/families-utils';
import { PendingFamilyActions } from '@/families/models/family';
import { PendingFamilyService } from '@/families/pending-family-service';
import PendingFamilyHubModal from '@/families/views/PendingFamilyHubModal.vue';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { Center } from '@/organizations/locations/models/center';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { daysAgoFromToday } from '@/core/filters';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';
import { AppStateStore } from '@/store/app-state-store';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import { LoadingStore } from '@/store/loading-store';
import type {
    AcceptFamilyEventPayload,
    FamilyLink,
    PendingFamily,
    PendingLeadTableItem
} from '@/families/models/family';
import Loading from '@/components/Loading.vue';
import RejectFamily from '@/families/components/RejectFamily.vue';
import PotentialDuplicate from '@/families/components/PotentialDuplicate.vue';
import type { Org } from '@/models/organization/org';
import { CrmTypeList, CrmTypeOption, RejectedReasonIdentifiers } from '@/crm-types/models/crm-type';
import { DataTableOptions } from '@/models/datatables';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { getPagination } from '@/core/datatables-utils';
import { ApiParameters } from '@/repositories/abstract-repository';
import { getSortingParameters, SortingMap } from '@/core/sort-utils';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
import ViewMessageModal from '@/dashboards/components/LocationDashboardTabs/ViewMessageModal.vue';
import {
    BlockedPhoneNumbersRepository
} from '@/communications/blocked-phone-numbers/repositories/blocked-phone-numbers-repository';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import SearchTextField from '@/components/base/SearchTextField.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const appState = getModule(AppStateStore);
const crmTypesStore = getModule(CrmTypesStore);
const blockNumbersRepository = new BlockedPhoneNumbersRepository();
const loadingKey = 'pendingLeads';
const loadingState = getModule(LoadingStore);
const featuresState = getModule(FeaturesStore);
const pendingFamilyService = new PendingFamilyService();
const pendingLeadsRepository = new PendingLeadsRepository();
const typesState = getModule(CrmTypesStore);
const recordingsStore = getModule(RecordingsStore);
const textsStore = getModule(TextsStore);

enum PendingLeadsSortKeys {
    NAME = 'leadLastName',
    CENTER = 'locationName',
    ADDED = 'leadDateAddedFormatted'
}

const pendingSortMap: SortingMap = new Map();
pendingSortMap.set('name', PendingLeadsSortKeys.NAME);
pendingSortMap.set('center', PendingLeadsSortKeys.CENTER);

@Component({
    components: {
        BaseClose,
        AddFamily,
        CenterlessFamilySelection,
        Loading,
        PendingFamilyHubModal,
        RecordingAudioModal,
        RejectFamily,
        PotentialDuplicate,
        ViewMessageModal,
        SearchTextField
    }
})
export default class PendingLeads extends Mixins(LocaleMixin) {
    /** Props **/
    @Prop({ type: String, default: 'pending-leads-table' }) readonly tableId!: string;
    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;

    /** Local Data */
    private acceptedEvent = EventTypes.ACCEPTED;
    private actionAccept = 'Accept';
    private actionCompare = 'Compare';
    private actionReject = 'Reject';
    private actionView = 'View';
    private boldAction = 'font-weight-bold';
    private bulkAcceptSnackbarMessage = '';
    // Count of all pending leads that are eligible for bulk accept
    private bulkAcceptTotalCount = 0;
    private bulkFamiliesAccepted = false;
    private columnSize = 6;
    private dialogSize = 'dialog-medium';
    private duplicateFamilies: Array<FamilyLink> | null = null;
    private duplicateReasonId: number | null = null;
    private eventClose = EventTypes.CLOSE;
    private eventFamilyAccepted = EventTypes.FAMILY_ACCEPTED;
    private eventFamilyRejected = EventTypes.FAMILY_REJECTED;
    private eventNumberBlocked = EventTypes.PHONE_NUMBER_BLOCKED;
    private familyAccepted = false;
    private familySources: Array<CrmTypeOption> = [];
    private inquiryTypes: Array<CrmTypeOption> = [];
    private invalidReasonId: number | null = null;
    private isLoaded = false;
    private options: DataTableOptions = {
        itemsPerPage: 25,
        page: 1
    };

    private pendingLeads: Array<PendingFamily> = [];
    private pendingCount = 0;
    private pendingLeadsSearch = '';
    private rejectedReasonId: number | null = null;
    private readTextFromPotentiallyAcceptedLead = true;

    private selectedFamily: PendingFamily | null = null;
    private selectedFamilyId: number | null = null;
    private selectedRecordings: Array<PendingFamilyRecording> = [];
    private showAddFamily = false;
    private showDuplicateDialog = false;
    private showCenterlessFamilySelection = false;
    private showMessageModal = false;

    // This is to handle open 2 modals at the same time
    private showRecordingModal = false;
    private showRejectDialog = false;
    private selected: Array<PendingLeadTableItem> = [];
    private selectAllItems = false;
    private totalPendingCount = 0;
    private updatedEvent = EventTypes.UPDATED;
    private viewPendingFamily = false;

    /** Computed values */
    private get computedPendingLeads(): Array<PendingLeadTableItem> {
        this.bulkAcceptTotalCount = 0;
        return this.pendingLeads.map((family: PendingFamily) => {
            if (!family.is_duplicate && family.center) {
                this.bulkAcceptTotalCount++;
            }
            // Check if the "duplicate" families are already linked as referrals
            // Filter out any referrals
            let isDuplicate = family.is_duplicate;
            if (family.duplicates && family.duplicates.length && family.linked_families) {
                const linkedFamilyIds = family.linked_families.map(familyLink => familyLink.family.id);
                family.duplicates = family.duplicates.filter(familyLink => !linkedFamilyIds.includes(familyLink.id));
                isDuplicate = family.duplicates.length > 0;
            }
            return {
                id: family.id,
                icon: this.isPendingFamXferred(family) ? 'mdi-swap-horizontal' : 'mdi-human-male-female-child',
                name: `${family.primary_guardian.last_name} ${family.primary_guardian.first_name}`, // name for sorting
                nameDisplay: `${family.primary_guardian.name}`, // name for display
                center: family.center ? family.center.values.name : '',
                email: family.primary_guardian.email,
                phone: family.primary_guardian.primary_phone ? family.primary_guardian.primary_phone.number : '',
                contact: family.created_date, // contact for sorting
                comments: family.comments,
                contactDisplay: daysAgoFromToday(family.created_date), // contact for display
                isDuplicate: isDuplicate,
                is_transferred: family.is_transfer,
                is_referred: family.is_refer,
                from_center: this.isPendingFamXferred(family) ? family.refer_transfer_center?.values.name : '',
                duplicates: family.duplicates,
                action: this.getFamilyActions(family),
                call_recordings: family.call_recordings,
                texts: family.texts
            } as PendingLeadTableItem;
        });
    }

    get center(): Center | null {
        return appState.storedCurrentCenter;
    }

    get isCrmPlusMode() {
        return featuresState.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get org() {
        return appState.storedCurrentOrg;
    }

    get isTextFromPendingLead() {
        return this.selectedFamily && this.selectedFamily.texts.length > 0;
    }

    private get pendingLeadsHeaders(): Array<DataTableHeader> {
        const headers = [] as Array<DataTableHeader>;
        headers.push({
            text: '',
            value: 'icon',
            class: 'icon',
            sortable: false
        });
        headers.push({
            text: 'Name',
            value: 'name',
            class: this.center ? 'name-header-col-sml' : 'name-header-col'
        });
        if (!this.center) {
            headers.push({
                text: 'Location',
                value: 'center',
                class: 'pendingleads-location-header-col'
            });
        }
        headers.push({
            text: 'Email Address',
            value: 'email',
            class: 'email-header-column',
            sortable: false
        });
        headers.push({
            text: 'Phone',
            value: 'phone',
            class: this.center ? 'phone-header-col-sml' : 'phone-header-col',
            sortable: false
        });
        headers.push({
            text: 'Comments',
            value: 'comments',
            class: this.center ? 'comments-header-col-sml' : 'comments-header-col',
            sortable: false
        });
        headers.push({
            text: '',
            value: 'isDuplicate',
            width: '2%',
            sortable: false
        });
        headers.push({
            text: 'Action',
            value: 'action',
            sortable: false,
            class: 'action-button-header-col',
            align: 'center'
        });
        return headers;
    }

    private isPendingFamXferred(family: PendingFamily): boolean {
        return (family.is_transfer || family.is_refer);
    }

    @Watch('org')
    private async updateOrg(org: Org) {
        if (org && org.id > 0 && !this.isEnrollmentTeamMode) {
            await this.fetchPendingLeads();
        }
    }

    @Watch('options', { deep: true })
    private async tableChanged() {
        if (this.isLoaded) {
            await this.fetchPendingLeads();
        }
    }

    // Lifecycle hooks.
    private async created() {
        this.isLoaded = false;
        const fetchPending = this.init();
        const featuresPromise = featuresState.init();
        const typesPromise = crmTypesStore.initList(CrmTypeList.REJECTED_REASONS);
        await fetchPending;
        await featuresPromise;
        await typesPromise;
        this.isLoaded = true;

        const reasons = crmTypesStore.listOptions(CrmTypeList.REJECTED_REASONS);
        this.duplicateReasonId = reasons.find(reason => reason.identifier === RejectedReasonIdentifiers.DUPLICATE)?.id ?? null;
        this.invalidReasonId = reasons.find(reason => reason.identifier === RejectedReasonIdentifiers.INVALID)?.id ?? null;
    }

    /** methods */

    // Accept a pending family
    private async acceptFamily(acceptFamilyEventPayload?: AcceptFamilyEventPayload): Promise<void> {
        loadingState.loadingIncrement(loadingKey);
        let centerId = acceptFamilyEventPayload?.centerId;

        try {
            if (!this.selectedFamily?.center && !centerId) {
                if (!this.isCrmPlusMode) {
                    centerId = this.center!.id;
                    if (acceptFamilyEventPayload) {
                        acceptFamilyEventPayload.centerId = centerId;
                    }
                } else {
                    this.showCenterlessFamilySelection = true;
                    return;
                }
            }

            await pendingFamilyService.acceptFamily(this.selectedFamilyId as number, acceptFamilyEventPayload);
            await this.acceptedSideEffects();
        } catch (e) {
            await this.$swal({
                text: 'Something went wrong, and the family could not be accepted. Please try again later.',
                icon: 'error'
            });
        }

        loadingState.loadingDecrement(loadingKey);
    }

    /**
     * Perform the side effects after accepting the family.
     */
    private async acceptedSideEffects() {
        loadingState.loadingIncrement(loadingKey);
        this.familyAccepted = true;
        if (this.isTextFromPendingLead) {
            if (!this.readTextFromPotentiallyAcceptedLead) {
                // There isn't an edge right now to decrement inbox count from accepted families because
                // before accepting, texts from pending lead does not account in the inbox count, so if accepted
                // family and message is already do nothing, but if message is mark unread, we have to increment to inbox count
                textsStore.incrementInboxCount();
            }
            if (this.showMessageModal) {
                this.showMessageModal = false;
            }
        } else {
            recordingsStore.incrementInboxCount();
        }
        this.readTextFromPotentiallyAcceptedLead = true;
        this.pendingLeadsSearch = '';
        await this.fetchPendingLeads();
        this.$emit(EventTypes.FAMILY_ACCEPTED);
        loadingState.loadingDecrement(loadingKey);
    }

    private async blockNumber() {
        loadingState.loadingIncrement(loadingKey);
        try {
            if (this.selectedFamily && this.selectedFamily.primary_guardian.primary_phone) {
                await blockNumbersRepository.blockPhoneNumber({ number: this.selectedFamily.primary_guardian.primary_phone.number_e164 });
                this.pendingLeadsSearch = '';
                await this.fetchPendingLeads();
                this.$emit(EventTypes.PHONE_NUMBER_BLOCKED);
            }
        } catch (e) {
            await this.$swal({ text: 'Something went wrong when blocking this number', icon: 'error' });
        }
        loadingState.loadingDecrement(loadingKey);
    }

    // bulk accept pending families
    private async bulkAcceptPending() {
        loadingState.loadingIncrement(loadingKey);
        const promises: Array<Promise<any>> = [];
        let acceptCount = 0;
        try {
            for (const item of this.selected) {
                let centerId: number | null = null;
                const familyId = item.id;
                const familyEntity = this.pendingLeads.find(family => family.id === item.id);
                if (!familyEntity?.center) {
                    if (!this.isCrmPlusMode) {
                        centerId = this.center!.id;
                    } else {
                        continue;
                    }
                }

                promises.push(pendingLeadsRepository.acceptFamily(familyId as number, centerId));
                acceptCount++;
            }
            await Promise.all(promises);
        } catch (e) {
            // Do nothing.
            // Catch any errors and just keep processing
        }

        // Refresh table after bulk accept
        this.bulkAcceptSnackbarMessage = acceptCount + ' pending families accepted.';
        this.bulkFamiliesAccepted = true;
        this.pendingLeadsSearch = '';
        await this.fetchPendingLeads();
        this.selected = [];
        this.selectAllItems = false;
        this.$emit(EventTypes.FAMILY_ACCEPTED);
        loadingState.loadingDecrement(loadingKey);
    }

    // Close the potential duplicate dialog
    private closeDuplicateDialog(): void {
        this.showDuplicateDialog = false;
    }

    // Close the reject family dialog
    private closeRejectDialog(): void {
        this.showRejectDialog = false;
    }

    private async doSearch(searchString: string) {
        this.pendingLeadsSearch = searchString;
        if ((this.pendingLeadsSearch.length > 2 || this.pendingLeadsSearch === '') && this.isLoaded) {
            await this.fetchPendingLeads();
        }
    }

    // Retrieve the pending families
    private async init() {
        loadingState.loadingIncrement(loadingKey);
        const leadsPromise = this.fetchPendingLeads();
        const sourcesPromise = typesState.initList(CrmTypeList.FAMILY_SOURCE);
        const inquiryTypesPromise = typesState.initList(CrmTypeList.FAMILY_INQUIRY);
        await leadsPromise;
        await sourcesPromise;
        await inquiryTypesPromise;
        this.familySources = typesState.listOptions(CrmTypeList.FAMILY_SOURCE);
        this.inquiryTypes = typesState.listOptions(CrmTypeList.FAMILY_INQUIRY);
        loadingState.loadingDecrement(loadingKey);
    }

    async fetchPendingLeads() {
        loadingState.loadingIncrement(loadingKey);
        if ((this.org && this.org.id > 0) || this.isEnrollmentTeamMode) {
            const pagination = getPagination(this.options);
            const extraParams: ApiParameters = this.getSorting(this.options);
            if (!this.isEnrollmentTeamMode) {
                extraParams.org_id = this.org!.id;
            } else {
                extraParams.enrollment_team = true;
            }
            if (this.pendingLeadsSearch.length > 2) {
                extraParams.search = this.pendingLeadsSearch;
            }
            const pendingLeadsResponse = await pendingLeadsRepository.get(pagination, extraParams);
            this.pendingCount = Number(pendingLeadsResponse.count);
            this.pendingLeads = pendingLeadsResponse.entities;
            const maxPage = Math.ceil(this.pendingCount / this.options.itemsPerPage);
            if (this.options.page > maxPage) {
                this.options.page = Math.max(1, maxPage);
            }
        }
        loadingState.loadingDecrement(loadingKey);
    }

    getFamilyActions(family: PendingFamily): Array<PendingFamilyActions> {
        return getPendingFamilyActions(family);
    }

    getSorting(options: DataTableOptions): ApiParameters {
        // right now the default sorting is by added date, which they don't see
        // and can never sort by again, but...
        return getSortingParameters(options, PendingLeadsSortKeys.ADDED, pendingSortMap);
    }

    // Handle the action button click, right now it's only handling pending leads from incoming text
    private handleActionButtonClick(item: PendingLeadTableItem) {
        if (item.texts.length > 0) {
            this.selectedFamilyId = item.id;
            this.selectedFamily = this.pendingLeads.filter((family: PendingFamily) => {
                return family.id === item.id;
            })[0];
            this.showMessageModal = true;
            this.rejectedReasonId = (this.selectedFamily.is_duplicate && (this.selectedFamily.duplicates?.length ?? 0) > 0) ? this.duplicateReasonId : this.invalidReasonId;
        }
    }

    // Handle the action on the pending lead
    private async handleAction(familyId: number, actionType: string): Promise<void> {
        this.selectedFamilyId = familyId;
        this.selectedFamily = this.pendingLeads.filter((family: PendingFamily) => {
            return family.id === familyId;
        })[0];
        this.rejectedReasonId = (this.selectedFamily.is_duplicate && (this.selectedFamily.duplicates?.length ?? 0) > 0) ? this.duplicateReasonId : this.invalidReasonId;
        switch (actionType) {
            case PendingFamilyActions.VIEW:
                this.viewPendingFamily = true;
                break;
            case PendingFamilyActions.COMPARE:
                if (!this.selectedFamily) {
                    break;
                }
                this.duplicateFamilies = this.selectedFamily.duplicates;
                if (!this.duplicateFamilies) {
                    break;
                }
                this.dialogSize = this.duplicateFamilies!.length > 1 ? 'dialog-x-large' : 'dialog-medium';
                this.columnSize = this.duplicateFamilies!.length > 1 ? 4 : 6;
                this.showDuplicateDialog = true;
                break;
            case PendingFamilyActions.ACCEPT:
                await this.acceptFamily();
                break;
            case PendingFamilyActions.REJECT:
                this.showRejectDialog = true;
                break;
            case PendingFamilyActions.PLAY_RECORDING:
                this.selectedRecordings = this.selectedFamily.call_recordings;
                this.showRecordingModal = true;
                break;
            case PendingFamilyActions.NEW_FAMILY:
                this.showAddFamily = true;
                break;
        }
    }

     retrieveUpdateFromMessageModal(isRead: boolean) {
        if (this.isTextFromPendingLead && !isRead) {
            this.readTextFromPotentiallyAcceptedLead = false;
        }
    }

    // reject the family
    private async rejectFamily(preferredFamilyId: number | null = null, reasonId: number | null = null): Promise<void> {
        this.showRejectDialog = false;
        reasonId = reasonId ?? this.rejectedReasonId;

        loadingState.loadingIncrement(loadingKey);
        try {
            await pendingLeadsRepository.rejectFamily(this.selectedFamilyId as number, preferredFamilyId, reasonId);
            this.pendingLeadsSearch = '';
            await this.fetchPendingLeads();
        } catch (e) {
            this.showRejectDialog = true;
            await this.$swal({ text: 'Something went wrong and the family could not be rejected. Please try again later.', icon: 'error' });
        }
        this.$emit(EventTypes.FAMILY_REJECTED);
        loadingState.loadingDecrement(loadingKey);
    }

    private selectAll() {
        this.selected = [];
        if (this.selectAllItems) {
            this.selectAllItems = false;
            return;
        }
        this.pendingLeads.forEach((family: PendingFamily) => {
            if (!family.is_duplicate && !family.call_recordings.length && !family.texts.length) {
                this.selected.push({
                    id: family.id,
                    name: `${family.primary_guardian.last_name} ${family.primary_guardian.first_name}`, // name for sorting
                    nameDisplay: `${family.primary_guardian.name}`, // name for display
                    center: family.center ? family.center.values.name : '',
                    email: family.primary_guardian.email,
                    phone: family.primary_guardian.primary_phone ? family.primary_guardian.primary_phone.number : '',
                    contact: family.created_date, // contact for sorting
                    comments: family.comments,
                    contactDisplay: daysAgoFromToday(family.created_date), // contact for display
                    isDuplicate: family.is_duplicate,
                    duplicates: family.duplicates,
                    action: this.getFamilyActions(family),
                    call_recordings: family.call_recordings,
                    texts: family.texts
                });
            }
        });
        this.selectAllItems = true;
    }

    /**
     * Handle the row click to view a pending family.
     *
     * @param item
     */
    private viewPendingFamilyAction(item: PendingLeadTableItem) {
        this.handleAction(item.id, this.actionView);
    }
};
