var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 full-width"},[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-data-table',{staticClass:"truncated-columns",attrs:{"headers":_vm.campaignHeaders,"items":_vm.campaigns,"item-key":"id","sort-by":"name","data-cy":"marketing-campaigns-table"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('base-button-primary',{staticClass:"mr-2",attrs:{"data-cy":"add-campaign-button"},on:{"click":_vm.addCampaign}},[_vm._v(" Add Campaign ")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.name}})]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.code}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.type ? item.type.values.value : ''}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":_vm.getCampaignStatus(item)}})]}},{key:"item.start_datetime",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.start_datetime ? _vm.formatDate(item.start_datetime, _vm.userTimeZone) : ''}})]}},{key:"item.end_datetime",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.end_datetime ? _vm.formatDate(item.end_datetime, _vm.userTimeZone) : ''}})]}},{key:"item.apply_to",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.organization ? item.organization.values.name : ''}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit(item))?_c('base-button-secondary',{attrs:{"data-cy":"edit-button"},on:{"click":function($event){return _vm.editCampaign(item)}}},[_c('span',[_vm._v("Edit")])]):_vm._e()]}}])}),_c('manage-marketing-campaign',{key:_vm.modalKey,attrs:{"campaign":_vm.selectedCampaign},on:_vm._d({},[_vm.addedEvent,_vm.refreshTable,_vm.deletedEvent,_vm.refreshTable,_vm.updatedEvent,_vm.refreshTable]),model:{value:(_vm.showCampaignModal),callback:function ($$v) {_vm.showCampaignModal=$$v},expression:"showCampaignModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }