import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store/index';
import Vue from 'vue';

interface LoadingHash {
    [key: string]: number;
}

export interface LoadingState {
    loadingHash: LoadingHash;
}

/**
 * Helper store for loading overlays
 */
@Module({
    namespaced: true,
    name: StoreModuleTypes.LOADING,
    dynamic: true,
    store: store
})
export class LoadingStore extends VuexModule implements LoadingState {
    loadingHash: LoadingHash = {};

    @Mutation
    loadingIncrement(loadingKey: string): void {
        if (this.loadingHash[loadingKey] !== undefined) {
            // fun with Vue reactivity
            Vue.set(this.loadingHash, loadingKey, this.loadingHash[loadingKey] + 1);
        } else {
            Vue.set(this.loadingHash, loadingKey, 1);
        }
    }

    @Mutation
    loadingDecrement(loadingKey: string): void {
        if (this.loadingHash[loadingKey] !== undefined) {
            Vue.set(this.loadingHash, loadingKey, this.loadingHash[loadingKey] - 1);
        } else {
            Vue.set(this.loadingHash, loadingKey, 0);
        }
    }

    @Mutation
    loadingStop(loadingKey: string): void {
        Vue.set(this.loadingHash, loadingKey, 0);
    }

    public get isLoading() {
        return (loadingKey: string) => {
            return this.loadingHash[loadingKey] !== undefined && this.loadingHash[loadingKey] > 0;
        };
    }

    /**
     * Get the current loading key (naively).
     */
    public get naiveCurrentLoadingKey(): string {
        for (const [key, value] of Object.entries(this.loadingHash)) {
            if (value > 0) {
                return key;
            }
        }
        return '';
    }
}
