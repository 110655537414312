








import { LayoutTabsStore } from '@/store/layout-tabs-store';
import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '../store';
import { doLogout } from '@/auth/auth';

const authState = getModule(AuthStore, store);

@Component
export default class Logout extends Mixins(LocaleMixin) {
    async mounted() {
        const layoutTabsStore = getModule(LayoutTabsStore, store);
        layoutTabsStore.clear();
        await authState.storeLoggedOut();
        await doLogout();
        // reload this protected page so we'll be redirected to login
        // also makes sure we clear vuex cache
        await this.$router.go(0);
    }

}
