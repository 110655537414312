






































































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { messageThreadLimit, ThreadMeta } from '../models/message';
import { EventTypes } from '@/constants/event-type-constants';
import { getAvatarBackgroundFromUser, getAvatarInitialsFromUser } from '@/core/avatar-utils';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { Email, OutgoingEmail } from '@/communications/messages/models/email';
import { OutgoingText, TextMessage } from '@/communications/messages/models/text';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import ViewEmailThread from '@/communications/messages/components/ViewEmailThread.vue';
import { Family } from '@/families/models/family';
import { FamiliesStore } from '@/families/store/families-store';
import FamilyTextsPane from '@/families/components/FamilyTextsPane.vue';
import { DataTableOptions } from '@/models/datatables';
import { FacebookMessage, FacebookOutgoingMessage } from '@/communications/messages/models/facebookMessage';
import FamilyFBMessagesPane from '@/families/components/FamilyFBMessagesPane.vue';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import PhoneNumberDisplay from '@/communications/PhoneNumberDisplay.vue';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const familyStore = getModule(FamiliesStore);
const textsRepo = new TextsRepository();
const emailsRepo = new EmailsRepository();
const facebookMessagesRepo = new FacebookMessagesRepository();

@Component({
    components: { PhoneNumberDisplay, FamilyTextsPane, ViewEmailThread, FamilyFBMessagesPane }
})
export default class InboxViewMessage extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly archiveMode!: boolean;
    @Prop({ required: true }) readonly threads!: Array<ThreadMeta>;
    @PropSync('textsTableOptions') textsLocalOptions!: DataTableOptions;
    @PropSync('emailsTableOptions') emailsLocalOptions!: DataTableOptions;
    @PropSync('facebookMessagesTableOptions') facebookMessagesLocalOptions!: DataTableOptions;
    @Prop() readonly textsCount!: number;
    @Prop() readonly emailsCount!: number
    @Prop() readonly facebookMessagesCount!: number;

    @PropSync('index') localIndex!: number;

    private family: Family | null = null;
    private loadingKey = 'viewInboxMessage';
    private thread: Array<Email | TextMessage | FacebookMessage> = [];
    private quickTextEvent = EventTypes.NEW_QUICK_TEXT;
    private newFacebookMessageEvent = EventTypes.NEW_FACEBOOK_MESSAGE
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;
    private pendingTextCancelledEvent= EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent= EventTypes.PENDING_TEXT_UPDATED;
    private needRefresh = false;

    private get avatarInitials() {
        return getAvatarInitialsFromUser(authState.userInfoObject);
    }

    private get avatarBackgroundColor() {
        return getAvatarBackgroundFromUser(authState.userInfoObject?.id);
    }

    private get passedMeta() {
        return this.threads[this.localIndex] ?? null;
    }

    private get passedThread() {
        return this.passedMeta ? this.passedMeta.thread : null;
    }

    private get isText() {
        return this.passedMeta ? this.passedMeta.is_text : false;
    }

    private get isEmail() {
        return this.passedMeta ? this.passedMeta.is_email : false;
    }

    private get totalCount() {
        if (this.isText) {
            return this.textsCount;
        } else if (this.isEmail) {
            return this.emailsCount;
        } else {
            return this.facebookMessagesCount;
        }
    }

    private get tableOptions() {
        if (this.isText) {
            return this.textsLocalOptions;
        } else if (this.isEmail) {
            return this.emailsLocalOptions;
        } else {
            return this.facebookMessagesLocalOptions;
        }
    }

    private get messageIndex() {
        return this.tableOptions.itemsPerPage * (this.tableOptions.page - 1) + this.localIndex;
    }

    get title(): string {
        if (this.family && !this.isEmail) {
            return this.family.primary_guardian.first_name + ' ' + this.family.primary_guardian.last_name;
        }
        return '';
    }

    @Watch('passedThread', { deep: true, immediate: true })
    async threadUpdated(thread: Array<Email | TextMessage | FacebookMessage>, pendingMessagesEvents = false) {
        if (!thread) {
            return;
        }
        // if our passed thread is at or longer than thread limit, we need to grab the whole thread
        // from the API
        loadingState.loadingIncrement(this.loadingKey);
        const familyId = thread[0].send_to_guardian!.values.family_id;
        if (!this.family || this.family.id !== familyId) {
            this.family = await familyStore.getById(thread[0].send_to_guardian!.values.family_id);
        }
        if (thread.length >= messageThreadLimit || pendingMessagesEvents) {
            if (this.isText) {
                const tmpThreads = await textsRepo.getMessagesForFamily(familyId);
                this.thread = tmpThreads.entities;
            } else if (this.isEmail) {
                this.thread = await emailsRepo.getSingleThread(this.passedMeta.incoming_id);
            } else {
                const tmpThreads = await facebookMessagesRepo.getMessagesForFamily(familyId);
                this.thread = tmpThreads.entities;
            }
        } else {
            this.thread = thread;
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private closeView() {
        this.$emit(EventTypes.CLOSE, this.needRefresh);
    }

    private async goToFamilyHub() {
        await this.$router.push({ name: 'family-hub', params: { id: this.family!.id.toString() } });
    }

    private toggleArchived() {
        this.$emit(EventTypes.TOGGLE_ARCHIVED, [this.passedMeta], this.archiveMode);
        this.closeView();
    }

    private markUnread() {
        this.$emit(EventTypes.MARK_UNREAD, this.passedMeta);
        this.closeView();
    }

    private async prev() {
        this.$emit(EventTypes.PREV);
    }

    private async next() {
        this.$emit(EventTypes.NEXT);
    }

    private quickText(newText: OutgoingText) {
        this.$emit(EventTypes.NEW_QUICK_TEXT, newText, this.family!.id);
        this.needRefresh = true;
    }

    private newFacebookMessage(newFacebookMessage: FacebookOutgoingMessage) {
        this.$emit(EventTypes.NEW_FACEBOOK_MESSAGE, newFacebookMessage, this.family!.id);
        this.needRefresh = true;
    }

    private replySent(newOutgoing: OutgoingEmail, incomingId: number) {
        this.$emit(EventTypes.REPLIED, newOutgoing, incomingId);
        this.needRefresh = true;
    }

    private async pendingEmailCancelled() {
        this.$emit(EventTypes.PENDING_EMAIL_CANCELLED);
        await this.reloadMessages();
    }

    private async pendingEmailUpdated() {
        this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
        await this.reloadMessages();
    }

    private async pendingTextCancelled() {
        this.$emit(EventTypes.PENDING_TEXT_CANCELLED);
        await this.reloadMessages();
    }

    private async pendingTextUpdated() {
        this.$emit(EventTypes.PENDING_TEXT_UPDATED);
        await this.reloadMessages();
    }

    private async reloadMessages() {
        await this.threadUpdated(this.passedThread!, true);
        this.needRefresh = true;
    }
}
