import { StoreModuleTypes } from '@/constants/store-constants';
import type { Org } from '@/models/organization/org';
import { Center } from '@/organizations/locations/models/center';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ToDoDateRangeKey } from '@/dashboards/models/action-items-models';
import { ChildStartsRangeKey } from '@/dashboards/models/child-starts-tab-models';
import * as chamaileon from '@/types/chamaileon-types';

export interface AppState {
    currentCenter: Center | null;
    currentCenterEt: Center | null;
    currentOrg: Org | null;
    familyMessageMode: boolean;
    isManageStaffOpen: boolean;
    isInAuditTrailView: boolean;
    onlyShowMyEmails: boolean;
    onlyShowMyFacebookMessages: boolean;
    onlyShowMyTexts: boolean;
    openFamilyInNewTab: boolean;
    searchNeedsFocus: boolean;
    currentToDoDateRange: ToDoDateRangeKey;
    currentChildStartsRange: ChildStartsRangeKey;
    isMini: boolean;
    refreshMenu: number;
    refreshViewed: number;
    includeInactiveLocations: boolean;
    defaultSearchStringPendingLeadEt: string;
    chamaileonInstance: chamaileon.Plugin | null;
}

@Module({
    namespaced: true,
    dynamic: true,
    store: store,
    name: StoreModuleTypes.APP
})
export class AppStateStore extends VuexModule implements AppState {
    currentCenter: Center | null = null;
    currentCenterEt: Center | null = null;
    currentOrg: Org | null = null;
    currentPageTitle = '';
    familyMessageMode = false;
    isManageStaffOpen = false;
    isInAuditTrailView = false;
    isDashboard = false;
    isInboxPage = false;
    inboxCount = 0;

    refreshMenu = 0;
    refreshViewed = 0;
    onlyShowMyEmails= false;
    onlyShowMyFacebookMessages= false;
    onlyShowMyTexts= false;
    openFamilyInNewTab = false;
    searchNeedsFocus = false;
    isMini = false;
    currentToDoDateRange: ToDoDateRangeKey = ToDoDateRangeKey.TODAY;
    currentChildStartsRange: ChildStartsRangeKey = ChildStartsRangeKey.NEXT_3;
    includeInactiveLocations = false;
    includeInactiveUsers = false;
    defaultSearchStringPendingLeadEt = '';
    chamaileonInstance: chamaileon.Plugin | null = null;

    @Mutation
    setIsMini(flag: boolean) {
        this.isMini = flag;
    }

    @Mutation
    setFamilyMessageMode(flag: boolean) {
        this.familyMessageMode = flag;
    }

    @Mutation
    updateCurrentCenter(center: Center | null) {
        this.currentCenter = center;
    }

    @Mutation
    updateCurrentCenterEt(center: Center | null) {
        this.currentCenterEt = center;
    }

    @Mutation
    updateCurrentOrg(org: Org) {
        this.currentOrg = org;
    }

    @Mutation
    incrementMenu() {
        this.refreshMenu++;
    }

    @Mutation
    incrementViewed() {
        this.refreshViewed++;
    }

    @Mutation
    updateCurrentPageTitle(title: string) {
        this.currentPageTitle = title;
    }

    @Mutation
    updateManageStaffModalStatus(flag: boolean) {
        this.isManageStaffOpen = flag;
    }

    @Mutation
    updateFamilyHubTargetMode(flag: boolean) {
        this.openFamilyInNewTab = flag;
    }

    @Mutation
    updateAuditTrailViewStatus(flag: boolean) {
        this.isInAuditTrailView = flag;
    }

    @Mutation
    setOnlyShowMyEmails(flag: boolean) {
        this.onlyShowMyEmails = flag;
    }

    @Mutation
    setOnlyShowMyFacebookMessages(flag: boolean) {
        this.onlyShowMyFacebookMessages = flag;
    }

    @Mutation
    setOnlyShowMyTexts(flag: boolean) {
        this.onlyShowMyTexts = flag;
    }

    @Mutation
    setSearchNeedsFocus(flag: boolean) {
        this.searchNeedsFocus = flag;
    }

    @Mutation
    setToDoDateRangeKey(key: ToDoDateRangeKey) {
        this.currentToDoDateRange = key;
    }

    @Mutation
    setChildStartsRange(key: ChildStartsRangeKey) {
        this.currentChildStartsRange = key;
    }

    @Mutation
    setShowInactiveLocations(flag: boolean) {
        this.includeInactiveLocations = flag;
    }

    @Mutation
    setShowInactiveUsers(flag: boolean) {
        this.includeInactiveUsers = flag;
    }

    @Mutation
    setDefaultSearchStringPendingLeadEt(searchString: string) {
        this.defaultSearchStringPendingLeadEt = searchString;
    }

    @Mutation
    setIsInboxPage(flag: boolean) {
        this.isInboxPage = flag;
    }

    @Mutation
    setIsDashboard(flag: boolean) {
        this.isDashboard = flag;
    }

    @Mutation
    setUnreadCount(count: number) {
        this.inboxCount = count;
    }

    @Mutation
    setChamaileonInstance(instance: chamaileon.Plugin) {
        this.chamaileonInstance = instance;
    }

    // whether families table is in message mode
    get messageMode() {
        return this.familyMessageMode;
    }

    get unreadCount(): number {
        return this.inboxCount;
    }

    get inboxPage(): boolean {
        return this.isInboxPage;
    }

    get storedCurrentCenter(): Center | null {
        return this.currentCenter;
    }

    get storedCurrentCenterEt(): Center | null {
        return this.currentCenterEt;
    }

    get storedCurrentOrg(): Org | null {
        return this.currentOrg;
    }

    get storedCurrentPageTitle(): string {
        return this.currentPageTitle;
    }

    get manageStaffModalOpen(): boolean {
        return this.isManageStaffOpen;
    }

    get familyHubTargetMode(): boolean {
        return this.openFamilyInNewTab;
    }

    get auditTrailViewOpen(): boolean {
        return this.isInAuditTrailView;
    }

    get onlyMyEmails(): boolean {
        return this.onlyShowMyEmails;
    }

    get onlyMyFacebookMessages(): boolean {
        return this.onlyShowMyFacebookMessages;
    }

    get onlyMyTexts(): boolean {
        return this.onlyShowMyTexts;
    }

    get showInactiveLocations(): boolean {
        return this.includeInactiveLocations;
    }

    get showInactiveUsers(): boolean {
        return this.includeInactiveUsers;
    }

    get defaultSearchPendingLeadEt(): string {
        return this.defaultSearchStringPendingLeadEt;
    }

}
