import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { AxiosInstance } from 'axios';
import { createClient } from '@/transport/create-client';
import { baseUrl } from '@/core/base-url';
import { CrmApiResponse, CrmApiElement } from '@/models/base';
import qs from 'qs';
import { getModule } from 'vuex-module-decorators';

const authState = getModule(AuthStore, store);

export interface ApiPagination {
    limit: number;
    offset: number;
}

export interface ApiParameters {
    [key: string]: string | Array<any> | number | boolean | undefined;
}

/**
 * base class for grabbing entities from the CRM API.
 * Some assumptions are made about the API url structure.
 * Obviously inheriting classes can override and add their own functions
 * Post/Put/Deletes can be stubbed out here as well.
 *
 * Repositories can be used in stores as well as directly by components
 */
export abstract class AbstractRepository<T extends CrmApiElement> {
    protected client: AxiosInstance;
    protected silentClient: AxiosInstance;
    protected abstract endpoint: string;
    protected defaultParams: ApiParameters|undefined = undefined;

    public constructor() {
        let url = baseUrl + '/api/v3/';
        if (authState.isAssumingOtherCustomer) {
            url = `${baseUrl}/api/admin/v1/customers/${authState.currentCustomerDb}/`;
        }
        this.client = createClient(url);
        this.silentClient = createClient(url, false);
    };

    public async get(
        pagination: ApiPagination | null = null,
        params: ApiParameters = {},
        endpoint: string | null = null,
        abortController: AbortController | null = null
    ): Promise<CrmApiResponse<T>> {
        if (pagination) {
            params.limit = String(pagination.limit);
            params.offset = String(pagination.offset);
        }

        if (this.defaultParams) {
            params = {
                ...this.defaultParams,
                ...params
            };
        }

        const response = await this.client.get<Array<T>>(endpoint ?? this.endpoint, {
            params: params,
            paramsSerializer: params1 => { return qs.stringify(params1); },
            signal: abortController?.signal
        });

        if (response === undefined) {
            // no data was pulled that matched the request
            return { entities: [], count: 0 };
        }

        const count = parseInt(response.headers['x-total-count']);

        return { entities: response.data, count: !isNaN(count) ? count : 0 };
    };

    public async getOne<CrmEntity>(id: number | string): Promise<T> {
        const response = await this.client.get<T>(this.endpoint + '/' + id);
        return response.data;
    }

    /**
     * Fetch all results, no pagination needed.
     *
     * @param params
     * @param endpoint
     */
    public async getAll(params: ApiParameters = {}, endpoint: string | null = null): Promise<CrmApiResponse<T>> {
        endpoint = endpoint ?? this.endpoint;

        // Merge params.
        if (this.defaultParams) {
            params = {
                ...this.defaultParams,
                ...params
            };
        }

        // Get first response.
        const response = await this.client.get<Array<T>>(endpoint, {
            params: params,
            paramsSerializer: params1 => { return qs.stringify(params1); }
        });

        if (response === undefined) {
            // No data was pulled that matched the request.
            return { entities: [], count: 0 };
        }

        // Get the total count of results.
        const count = parseInt(response.headers['x-total-count']);

        // Split out the entities.
        let entities = response.data;

        // Ride this loop and get more data till we are done.
        if (count > entities.length && entities.length > 0) {
            const limit = entities.length;
            const pagePromises = [];
            const subLimit = 4;
            for (let i = limit; i < count; i += limit) {
                pagePromises.push(this.client.get<Array<T>>(endpoint, {
                    params: {
                        ...params,
                        limit: limit,
                        offset: i
                    },
                    paramsSerializer: params1 => { return qs.stringify(params1); }
                }));
                if (pagePromises.length >= subLimit) {
                    const subResponses = await Promise.all(pagePromises);
                    for (const subResponse of subResponses) {
                        entities = entities.concat(subResponse.data);
                    }
                    pagePromises.splice(0);
                }
            }
            const pageResponses = await Promise.all(pagePromises);
            for (const pageResponse of pageResponses) {
                entities = entities.concat(pageResponse.data);
            }
        }

        // Return the object.
        return { entities, count: !isNaN(count) ? count : 0 };
    }

    public async post<CrmEntity>(data: object): Promise<T> {
        const response = await this.client.post<T>(this.endpoint, data);
        return response.data;
    }

    public async deleteBasic(id: number): Promise<void> {
        await this.client.delete(this.endpoint + '/' + id);
    }

    public async putOne(id: number, data: object): Promise<T> {
        // Convert hateoas links into bare ids
        for (const [key, value] of Object.entries(data)) {
            if (value !== null && typeof value === 'object') {
                if (Object.prototype.hasOwnProperty.call(value, 'id')) {
                    // There's surely some way to fix the error below, but I don't know right now
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    data[key] = value.id;
                }
            }
        }
        const response = await this.client.put<T>(`${this.endpoint}/${id}`, data);
        return response.data;
    }

    public async patchOne(id: number, data: object): Promise<T> {
        const response = await this.client.patch<T>(`${this.endpoint}/${id}`, data);
        return response.data;
    }
}
