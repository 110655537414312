














































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { ModalMixin } from '@/core/modal-mixin';
import { getModule } from 'vuex-module-decorators';
import { LocationDashboardStore } from '@/dashboards/store/location-dashboard-store';
import { GroupedType } from '@/tasks/services/task-groups-service';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const dashboardState = getModule(LocationDashboardStore);
@Component({
    components: { BaseClose }
})
export default class BulkCompleteTasksTypeSelect extends Mixins(LocaleMixin, ModalMixin) {
    @Prop() readonly taskTypes!: Array<GroupedType>;

    private taskTypeFilterLocal: number | null = null;

    get taskTypeFilterStore() {
        return dashboardState.taskTypeFilter;
    }

    @Watch('modelValue')
    setCorrectDefaultOption() {
        if (this.modelValue) {
            this.taskTypeFilterLocal = this.taskTypeFilterStore;
        }
    }

    nextButtonClick() {
        if (this.taskTypeFilterLocal) {
            this.$emit(EventTypes.UPDATED, this.taskTypeFilterLocal);
            this.close();
        }
    }

}
