import { ReportColumnIdentifiers, ReportIdentifier } from '@/reports/report-constants';

// Format names to apply to columns
export enum ReportFormatNames {
    CURRENCY = 'currency'
}

export type ColumnFormat = {
    uniqueName: ReportColumnIdentifiers;
    formatName: ReportFormatNames;
};

// The formats to apply for the marketing campaigns source
const marketingCampaignFormats: Array<ColumnFormat> = [
    {
        uniqueName: ReportColumnIdentifiers.COST,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_0,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_1,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_2,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_3,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_4,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_5,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_6,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_7,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_8,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_ENROLLED,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.COST_LOST_OPP,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.REV_WEEKLY,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.REV_MONTHLY,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.REV_ANNUAL,
        formatName: ReportFormatNames.CURRENCY
    },
    {
        uniqueName: ReportColumnIdentifiers.REV_LIFETIME,
        formatName: ReportFormatNames.CURRENCY
    }
];

// The formats to apply to all reports
export const REPORT_FORMATS: Partial<Record<ReportIdentifier, Array<ColumnFormat>>> = {
    [ReportIdentifier.MARKETING_CAMPAIGNS]: marketingCampaignFormats
};
Object.freeze(REPORT_FORMATS);
