import {
    ManageTaskTableData,
    Task,
    TaskCreateDto, TaskGroup,
    TaskGroups,
    TaskType,
    TaskTypes,
    TaskUpdateDto
} from '@/tasks/models/task-models';
import { TaskGroupsStore } from '@/tasks/stores/task-groups-store';
import { getModule } from 'vuex-module-decorators';
import { addMinutes, isDateInPast } from '@/date-time/date-time-utils';
import { downloadIcsFile } from '@/calendar/calendar-utils';
import { TasksStore } from '@/tasks/stores/tasks-store';
import { TasksRepository } from '@/tasks/repositories/tasks-repository';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';

/**
 * Is a task past due?
 * Must be outside the TaskUtilities class because calendar-utils needs it, which we use in the class,
 * causing a circular dependency.
 * @param task
 */
export function isTaskPastDue(task: Task | TaskUpdateDto | TaskCreateDto): boolean {
    const duration = task.duration ? task.duration : 0;
    const dueTimeWithDuration = addMinutes(task.due_date_time, duration);
    return isDateInPast(dueTimeWithDuration) && !task.is_completed;
}

export class TaskUtilities {

    private enrollmentCenterState = getModule(EnrollmentCenterSettingsStore);
    private featuresStore = getModule(FeaturesStore);
    private taskGroupStore = getModule(TaskGroupsStore);
    private tasksStore = getModule(TasksStore);
    private crmTypesStore = getModule(CrmTypesStore);
    private tasksRepository = new TasksRepository();

    /**
     * Common code to handle completing a task
     *
     * @param task
     * @param isMeeting
     * @param isDownloadIcs
     */
    async completeTask(task: TaskUpdateDto, isMeeting = false, isDownloadIcs = false) {
        if (isTaskPastDue(task) && isMeeting) {
            this.tasksStore.decrementPastDueToursWithin30DaysCount();
        }
        const completedTask = await this.tasksRepository.complete(task);
        if (isDownloadIcs) {
            downloadIcsFile(completedTask);
        }
    }

    async getTasksInGroup(groupId: number): Promise<Array<TaskType>> {
        const groupStoreInit = this.taskGroupStore.init();
        const taskStoreInit = this.crmTypesStore.initList(CrmTypeList.TASKS);
        const getListPromise = await this.crmTypesStore.retrieveList(CrmTypeList.TASKS);
        await Promise.all([groupStoreInit, taskStoreInit, getListPromise]);

        const taskTypes = this.crmTypesStore.listOptions(CrmTypeList.TASKS);
        const tasks: Array<TaskType> = [];
        for (const group of this.taskGroupStore.entities) {
            if (group.id === groupId) {
                for (const taskTypeLinks of group.task_types) {
                    for (const task of taskTypes) {
                        if (task.id === taskTypeLinks.id) {
                            tasks.push(task);
                        }
                    }
                }
            }
        }
        return tasks;
    }

    /**
     * Given a list of task groups, and a list of group ids, give an array of all the task names
     * @param groups
     * @param groupIds
     */
    static getTaskNames(groups: Array<TaskGroup>, groupIds: Array<number>): Array<string> {
        return groups
            // filter down to only groups we care about
            .filter(group => groupIds.includes(group.id))
            // grab the task names within each group
            .map((group) => {
                return group.task_types.map(type => type.values.value);
            })
            // concat them all into a single array
            .reduce((prev, current) => prev.concat(current), []);
    }

    /**
     * Determine whether a task has been modified.
     *
     * @param task    The original task data
     * @param taskDto The task data to compare against
     */
    hasTaskBeenModified(task: Task, taskDto: TaskUpdateDto): boolean {
        return (task.assigned_by_staff?.id ?? null) !== taskDto.assigned_by_user_id ||
            (task.assigned_to_staff?.id ?? 0) !== taskDto.assigned_to_staff ||
            (task.completed_by_staff?.id ?? null) !== taskDto.completed_by_user_id ||
            task.completed_date_time !== taskDto.completed_date_time ||
            task.description !== taskDto.description ||
            task.due_date_time !== taskDto.due_date_time ||
            task.duration !== taskDto.duration ||
            task.result_description !== taskDto.result_description ||
            (task.result?.id ?? null) !== taskDto.result_type ||
            task.type.id !== taskDto.type;

    }

    /**
     * Get the icon for the task
     * @param task
     */
    async getIcon(task: Task): Promise<string> {
        return (await this.getIconByGroupAndType(task.group.id, task.type?.id));
    }

    async getIconByGroupAndType(taskGroupId: number, taskTypeId: number): Promise<string> {
        let taskTypes = [];
        let taskType;
        await this.crmTypesStore.initList(CrmTypeList.TASKS);

        switch (taskGroupId) {
            case TaskGroups.PHONE_CALLS:
                return 'mdi-phone';
            case TaskGroups.EMAILS:
                return 'mdi-email';
            case TaskGroups.TEXTS:
                return 'mdi-chat';
            case TaskGroups.TOURS:
                return 'mdi-calendar';
            case TaskGroups.MEETINGS:
                return 'mdi-account-multiple';
            case TaskGroups.OTHER:
            default:
                if (!taskTypeId) {
                    return 'mdi-checkbox-marked-outline';
                }
                // Must check if the icon is for a CCF interview task
                taskTypes = this.crmTypesStore.listOptions(CrmTypeList.TASKS);
                taskType = taskTypes.find(value => value.id === taskTypeId);
                if (taskType &&
                    (
                        taskType.identifier === TaskTypes.CCF_INTERVIEW_DUE ||
                        taskType.identifier === TaskTypes.CCF_INTERVIEW_DUE_FOLLOWUP
                    )
                ) {
                    return 'mdi-file';
                }
                return 'mdi-checkbox-marked-outline';
        }
    }

    /**
     * Convert a Task entity into a row for data tables
     *
     * @param task
     * @param timezone
     */
    async convertTaskEntityToDatatableRow(task: Task, timezone: string): Promise<ManageTaskTableData> {
        let assignee = task.assigned_to_staff ? `${task.assigned_to_staff.values.first_name} ${task.assigned_to_staff.values.last_name}` : '';

        if (this.featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
            await this.enrollmentCenterState.init();
            if (task.assigned_to_enrollment_team && !assignee) {
                assignee = this.enrollmentCenterState.settings?.name ?? 'Enrollment Team';
            }
        }

        return {
            id: task.id,
            guardian_name: task.family.values.name,
            type: task.type.values.value,
            assignee_name: assignee,
            assigneeDisplay: task.assigned_to_staff,
            et: task.assigned_to_enrollment_team,
            location: task.center.values.name,
            timezone: timezone,
            due_date: task.due_date_time,
            description: task.description,
            icon: await this.getIcon(task)
        } as ManageTaskTableData;
    }
}
