























































































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Status, StatusDto } from '@/families/models/status';
import { StatusMapper } from '@/families/mappers/status-mapper';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { StatusesRepository } from '@/families/repositories/statuses-repository';
import { LoadingStore } from '@/store/loading-store';
import { EventTypes } from '@/constants/event-type-constants';
import DeleteStatusModal from '@/settings/components/DeleteStatusModal.vue';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';

const statusMapper = new StatusMapper();
const statusStore = getModule(StatusesStore);
const statusRepo = new StatusesRepository();
const loadingState = getModule(LoadingStore);

@Component({
    components: {
        DeleteStatusModal,
        SaveButton
    }
})
export default class ManageStatusModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model whether we should show the modal
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() readonly inputStatus!: Status | null;
    // only relevant when there's no inputStatus, i.e. we're adding
    @Prop() readonly isArchive!: boolean | null;
    @Ref('form') readonly form!: VForm;

    private statusDto: StatusDto = statusMapper.createNewDto();
    private validForm = false;
    private loadingKey = 'manageStatus';
    private deleteOpen = false;
    private deletedEvent = EventTypes.STATUS_DELETED;

    // Handles showing the modal
    private get modelValue(): boolean {
        return this.value;
    }

    private set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    private get isAdd() {
        return !this.inputStatus;
    }

    private get validSave() {
        return this.validForm && this.statusDto.from_statuses && this.statusDto.from_statuses.length > 0;
    }

    private get statuses() {
        return statusStore.statuses.filter((status) => {
            return !this.inputStatus || this.inputStatus.id !== status.id;
        });
    }

    private get isChildOnly() {
        return this.statusDto.child_only;
    }

    private get newStatusCode() {
        return this.statusDto.code ? this.statusDto.code : '[New Status]';
    }

    private get fromText() {
        return 'Allow ' + (this.isChildOnly ? 'children' : 'guardians/children') + ' to change FROM ' +
        ' the following statuses TO ' + this.newStatusCode + ': *';
    }

    private get toText() {
        return 'Allow ' + (this.isChildOnly ? 'children' : 'guardians/children') + ' to change FROM ' +
            this.newStatusCode + ' TO the following statuses:';
    }

    @Watch('modelValue', { immediate: true })
    private modalActivated() {
        if (this.modelValue) {
            if (this.inputStatus) {
                this.statusDto = statusMapper.toUpdateDto(this.inputStatus);
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
            } else {
                this.statusDto = statusMapper.createNewDto();
                this.statusDto.is_archive = !!this.isArchive;
                this.statusDto.order = this.statuses.filter((status) => {
                    return status.is_archive === this.statusDto.is_archive;
                }).map((status) => {
                    return status.order;
                }).reduce((prev, curr) => {
                    return Math.max(prev, curr);
                }) + 1;
            }
        }
    }

    async mounted() {
        await statusStore.init();
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.isAdd) {
            await statusRepo.newStatus(this.statusDto);
        } else {
            await statusRepo.editStatus(this.inputStatus!.id, this.statusDto);
        }
        await statusStore.retrieveStatuses();
        this.$emit(EventTypes.STATUSES_UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
        this.handleClose();
    }

    private statusDeleted() {
        this.$emit(EventTypes.STATUSES_UPDATED);
        this.handleClose();
    }

    private handleClose() {
        this.form.reset();
        this.modelValue = false;
    }

    private openDelete() {
        this.deleteOpen = true;
    }
}
