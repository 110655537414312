export enum LogResultTypes {
    CALL_RECEIVED_LEFT_MESSAGE = 'taskResultTypeCallReceivedLeftMessage',
    CALL_RECEIVED_SPOKE_WITH = 'taskResultTypeCallReceivedSpokeWith',
    TALKED_WITH_IN_PERSON = 'taskResultTypeSpokeInPerson',
    MAIL_RECEIVED = 'taskResultTypeMailReceived'
}
export enum TaskTypeCalendar {
    TOUR = 89,
    MEETING = 88
}
