var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',{staticClass:"truncated-columns family-info-datatable family-info-guardians clickable-rows mb-2 has-header-button",attrs:{"items":_vm.guardians,"headers":_vm.headers,"item-key":"id","hide-default-footer":true,"disable-sort":""},on:{"click:row":_vm.editGuardian},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){return [(_vm.guardians.length < 2 && _vm.canEditFamily)?_c('base-button-secondary',{staticClass:"table-header-button",on:{"click":function($event){$event.stopPropagation();return _vm.editGuardian(null)}}},[_vm._v(" Add ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.relationship",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.relation_to_child}},[_vm._v(" "+_vm._s(item.relation_to_child)+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(_vm.isDoNotCall && item.primary_phone)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-phone-off ")]):_vm._e(),_vm._v(" "+_vm._s(item.primary_phone ? item.primary_phone.number : '')+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.email}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.canEditFamily)?_c('family-action-buttons',{attrs:{"compact":true,"family":_vm.family,"is-primary-guardian":_vm.isPrimaryGuardian(item),"guardian":item,"show-call":_vm.canMakeCenterCalls},on:_vm._d({},[_vm.updatedEvent,_vm.activityAdded])}):_vm._e()],1)]}}])}),_c('family-data-info-add-edit-guardian',{attrs:{"family":_vm.family,"guardian":_vm.editedGuardian},on:_vm._d({},[_vm.updatedEvent,_vm.familyUpdated,_vm.deletedEvent,_vm.familyDeleted]),model:{value:(_vm.isEditingGuardian),callback:function ($$v) {_vm.isEditingGuardian=$$v},expression:"isEditingGuardian"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }