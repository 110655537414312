import { CrmEntity, HateoasLink } from '@/models/base';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { TreeNode } from '@/core/tree';

export interface Org extends CrmEntity {
    code: string;
    name: string;
    level: number;
    level_order: number;
    parent_organization: HateoasLink|null;
    center: HateoasLink|null;
}

export interface OrgTreeNode extends Org, TreeNode<Org> {
    children?: Array<OrgTreeNode>;
}

export interface OrgCreateDtoInterface extends AbstractCreateDto {
    code: string;
    name: string;
    level: number;
    parent_organization: number;
}

export class OrgCreatePostDto implements OrgCreateDtoInterface {
    code = '';
    name = '';
    level = 0;
    parent_organization = 1;
}

export interface OrgUpdateDtoInterface extends OrgCreateDtoInterface, AbstractUpdateDto {
    id: number;
}

export interface OrgHierarchy {
    levelName: string;
    orgName: string;
}
