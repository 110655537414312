export default class StringUtils {
    /**
     * Remove special UTF8 characters, such as BOMs, Zero-Width Spaces and controls.
     *
     * @param content
     */
    public static removeSpecialUTF8(content: string): string {
        content = content.replace(/[\u200B-\u200D\uFEFF]/g, '');
        content = content.replace(/\xEF\xBB\xBF/, '');

        return content;
    }

    /**
     * Clean content for raw text.
     *
     * @param content
     */
    public static cleanContentForTextView(content: string): string {
        content = content.replace(/\n/gi, '<br />');
        content = StringUtils.removeSpecialUTF8(content);

        return content;
    }

    /**
     * Clean the content down to text, for posting to the API.
     *
     * @param content
     */
    public static cleanContentForTextApi(content: string): string {
        // Replace other things that can come from TinyMCE into the text.
        content = content.replace(/<p[^>]*>/gi, '');
        content = content.replace(/<\/p>/gi, '\n');
        content = content.replace(/<br[^>]*>/gi, '\n');
        content = content.replace(/&nbsp;/gi, ' ');
        content = StringUtils.removeSpecialUTF8(content);

        return content;
    }

    public static convertHtmlVariableTagsToText(content: string): string {
        // Convert the variable tag HTML tags to strings.
        return content.replace(
            /<span class="variable_tag" contenteditable="false" data-original-variable="{{ (.*?) }}">(.*?)<\/span>/ig,
            '{{ $1 }}'
        );
    }

    // Truncate a string if longer than limit and add ellipsis at end, don't factor in spaces and punctuation.
    public static truncateWithEllipsis(text: string, limit: number): string {
        if (text.length <= limit) {
            return text;
        }

        return text.slice(0, limit) + '…';
    }

    // Truncate a string if longer than limit and add ellipsis at end, factor in spaces and punctuation.
    public static truncateByWordWithEllipsis(text: string, limit: number): string {
        if (text.length > limit) {
            for (let i = limit; i > 0; i--) {
                // Is this character a white space character? Is the previous character not a punctuation
                if (text.charAt(i) === ' ' &&
                    (text.charAt(i - 1) !== ',' || text.charAt(i - 1) !== '.' || text.charAt(i - 1) !== ';')
                ) {
                    // Yup, truncate and add ellipses.
                    return text.substring(0, i) + '…';
                }
            }

            return text.substring(0, limit) + '…';
        }

        return text;
    }

    // format and concatenate time remaining for facebook message replies
    public static concatFBDateString(timeInSeconds: number): string {
        const day = Math.round(timeInSeconds / (3600 * 24));
        const hourFloor = Math.floor(timeInSeconds % (3600 * 24) / 3600);
        const hourRound = Math.round(timeInSeconds % (3600 * 24) / 3600);
        const min = Math.floor((timeInSeconds % 3600) / 60);
        const sec = Math.floor(timeInSeconds % 60);

        if (day <= 0 && hourFloor <= 0 && min <= 0 && sec === 1) {
            return `${sec} second.`;
        } else if (day <= 0 && hourFloor <= 0 && min <= 0) {
            return `${sec} seconds.`;
        } else if (day <= 0 && hourFloor <= 0 && min === 1) {
            return `${min} minute.`;
        } else if (day <= 0 && hourFloor <= 0) {
            return `${min} minutes.`;
        } else if (day <= 0 && hourFloor === 1) {
            return `${hourFloor} hour and ${min} minutes.`;
        } else if (day <= 0 && hourFloor < 2) {
            return `${hourFloor} hours and ${min} minutes.`;
        } else if (day <= 0 && hourFloor >= 2) {
            return `${hourRound} hours`;
        } else if (day === 1) {
            return `${day} day.`;
        } else {
            return `${day} days.`;
        }

    }

    /**
     * Capitalizes and formats an array of strings into a readable string.
     *
     * @param array - An array of strings.
     * @returns A formatted string with capitalized.
     */
    public static capitalizeAndFormat(array: Array<string>): string {
        if (!array.length) return '';

        const arrayCapitalized = array.map(
            word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

        if (arrayCapitalized.length === 1) {
            return arrayCapitalized[0];
        }

        const last = arrayCapitalized.pop();
        return `${arrayCapitalized.join(', ')} and ${last}`;
    }
}
