import { render, staticRenderFns } from "./HistoryExample.vue?vue&type=template&id=bc95c128&scoped=true&"
import script from "./HistoryExample.vue?vue&type=script&lang=ts&"
export * from "./HistoryExample.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc95c128",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardSubtitle,VCardText,VCardTitle})
