import { AbstractRepository } from '@/repositories/abstract-repository';
import { UserPermission, UserPermissionDto } from '@/staff/models/user-permission-models';

export class UserPermissionsRepository extends AbstractRepository<UserPermission> {
    readonly endpoint = 'staff';

    public async getPermissions(userId: number): Promise<Array<UserPermission>> {
        const response = await this.client.get(this.endpoint + `/${userId}/permissions`);
        if (response === undefined) {
            // no data was pulled that matched the request
            return [];
        }
        return response.data;
    }

    public async setPermissions(userId: number, payload: Array<UserPermissionDto>): Promise<void> {
        await this.client.patch(this.endpoint + `/${userId}/permissions`, payload);
    }
}
