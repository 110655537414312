







































































































































































import { Component, Mixins, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { DripCampaign, DripCampaignHistory } from '@/automation/drip-campaigns/models/drip-campaign';
import { VForm } from '@/types/types';
import { DataTableHeader } from 'vuetify';
import { FeatureConstants } from '@/features/feature-constants';
import { Center } from '@/organizations/locations/models/center';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { DripCampaignsRepository } from '@/automation/drip-campaigns/repositories/drip-campaigns-repository';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { FeaturesStore } from '@/features/features-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const campaignsRepo = new DripCampaignsRepository();
const dripCampaignUtils = new DripCampaignUtils();
const campaignsState = getModule(DripCampaignsStore);
const centersState = getModule(CentersStore);
const featuresStore = getModule(FeaturesStore);

@Component
export default class FamilyDataDripCampaigns extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() family!: Family;

    @PropSync('familyCampaigns') familyCampaignsLocal!: Array<DripCampaignHistory>;
    private isEditing = false;
    private isAddOpen = false;
    private addCampaignId = 0;
    private validAdd = false;
    private loadingKey = 'family-drip-campaigns';
    private addableCampaigns: Array<DripCampaign> = [];
    @Ref('addForm') readonly addForm!: VForm;

    private headers: DataTableHeader[] = [
        {
            text: 'Campaign Name',
            value: 'campaignName',
            class: 'campaign-name',
            width: '30%'
        },
        {
            text: 'Drip Name',
            value: 'dripName',
            class: 'drip-name'
        },
        {
            text: 'Template',
            value: 'templateName',
            class: 'template-name',
            width: '20%'
        },
        {
            text: 'Send',
            value: 'sendDatetime',
            class: 'send-datetime'
        },
        {
            text: 'Status',
            value: 'dripStatus',
            class: 'drip-status',
            width: '7rem'
        }
    ];

    private editHeaders: DataTableHeader[] = [
        {
            text: 'Campaign Name',
            value: 'campaignName'
        },
        {
            text: 'Status',
            value: 'status',
            width: '7rem'
        },
        {
            text: ' ',
            value: 'action',
            width: '6rem'
        }
    ];

    get isFranchiseModeFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FRANCHISE_MODE);
    }

    get timezone() {
        return authState.userTimeZone;
    }

    get viewRows() {
        return dripCampaignUtils.unfoldFamilyDrips(this.familyCampaignsLocal);
    }

    @Watch('family', { deep: true })
    async init() {
        this.isEditing = false;
    }

    @Watch('isEditing', { immediate: true })
    async loadCampaigns() {
        if (this.isEditing) {
            loadingState.loadingIncrement(this.loadingKey);

            let center: Center | undefined;
            if (this.family.center) {
                center = await centersState.getAccessibleCenterById(this.family.center.id);
            }
            await campaignsState.initForCenter((this.isFranchiseModeFeatureEnabled ? center : undefined) ?? undefined);
            this.addableCampaigns = campaignsState.storedForCenter((this.isFranchiseModeFeatureEnabled ? center : undefined) ?? undefined).filter((campaign) => {
                return dripCampaignUtils.isAddable(campaign, center);
            });

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    async cancelDrip(familyDrip: DripCampaignHistory) {
        const confirm = await this.$swal({
            icon: 'warning',
            text: 'Are you sure you want to cancel this drip campaign?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });
        if (confirm.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            await campaignsRepo.cancelFamilyCampaign(this.family.id, familyDrip.id);
            await this.loadFamilyCampaigns();
            this.$emit(EventTypes.UPDATED);
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    async doAdd() {
        if (!this.addCampaignId) {
            return;
        }
        loadingState.loadingIncrement(this.loadingKey);
        this.isAddOpen = false;
        await campaignsRepo.startFamilyCampaign(this.family.id, this.addCampaignId);
        await this.loadFamilyCampaigns();
        this.$emit(EventTypes.UPDATED);
        loadingState.loadingDecrement(this.loadingKey);
    }

    isActive(familyDrip: DripCampaignHistory): boolean {
        return dripCampaignUtils.isActive(familyDrip);
    }

    async loadFamilyCampaigns() {
        this.familyCampaignsLocal = await campaignsRepo.getFamilyCampaigns(this.family.id);
    }

    openAdd() {
        this.isAddOpen = true;
        this.addForm.reset();
        this.addCampaignId = 0;
    }
}
