import { clientTranslationsType } from '@/clients/translations/client-translations-type';

export class ClientTranslator {
    translations: clientTranslationsType | null;

    constructor(translations: clientTranslationsType | null = null) {
        this.translations = translations;
    }

    public setTranslations(translations: clientTranslationsType | null = null) {
        this.translations = translations;
    }

    /**
     * Translate a single word.
     *
     * @param word
     */
    public translate(word: string): string {
        return this.translations && this.translations[word] ? this.translations[word] : word;
    }

    /**
     * Attempt to translate any words in a chunk.
     *
     * @param chunk
     */
    public translateChunk(chunk: string): string {
        if (!this.translations) {
            return chunk;
        }

        for (const key of Object.keys(this.translations)) {
            chunk = chunk.replace(new RegExp('\\b' + key + '\\b', 'g'), this.translate(key));
        }

        return chunk;
    }
}
