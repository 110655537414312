





































































import { EventTypes } from '@/constants/event-type-constants';
import EnrollmentCenterSettingsModal from '@/enrollment-center/components/EnrollmentCenterSettingsModal.vue';
import { EnrollmentCenterSettingsChangesStore } from '@/enrollment-center/store/enrollment-center-settings-changes-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import LocationInformationMain from '@/enrollment-center/components/LocationInformationMain.vue';
import LocationMapMain from '@/enrollment-center/components/LocationMapMain.vue';
import LocationDashboard from '@/dashboards/views/LocationDashboard.vue';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { NavigationGuardNext, Route } from 'vue-router';
import { AppStateStore } from '@/store/app-state-store';
import store from '@/store';
import { EditMode } from '@/core/edit-modes';
import { AuthStore } from '@/store/auth-store';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import { TabTitles } from '@/router/route-constants';
import AddFamily from '@/families/components/AddFamily.vue';
import BasePageTitle from '@/components/base/BasePageTitle.vue';
import { PageTitleMixin } from '@/core/page-title-mixin';
import DashboardCheckMixin from '@/dashboards/components/custom/DashboardCheckMixin.vue';
import { enrollmentDashboardType } from '@/dashboards/models/custom-dashboards';

const appState = getModule(AppStateStore, store);
const featuresStore = getModule(FeaturesStore);
const staffUtils = new StaffUtils();
const authState = getModule(AuthStore, store);
const layoutTabsStore = getModule(LayoutTabsStore, store);
const settingsChangesStore = getModule(EnrollmentCenterSettingsChangesStore);

Component.registerHooks([
    'beforeRouteLeave'
]);

@Component({
    components: {
        BasePageTitle,
        LocationDashboard,
        LocationInformationMain,
        LocationMapMain,
        AddFamily
    }
})
export default class EnrollmentCommandCenterEnhancedDashboard extends Mixins(LocaleMixin, PageTitleMixin, DashboardCheckMixin) {
    @Prop({ default: false }) inContactMode!: boolean;
    @Prop({ default: false }) openAddFamily!: boolean;
    @Prop({ default: '' }) schoolNum!: string;
    private showAddFamily = false;

    // for dashboard check mixin
    dashboardType = enrollmentDashboardType;

    async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        appState.updateCurrentCenterEt(null);
        appState.setIsDashboard(false);
        next();
    }

    private canSeeSettings = false;
    private settingsComponent = EnrollmentCenterSettingsModal;
    private tabEditMode = EditMode.SAVED;
    private updatedEvent = EventTypes.UPDATED;
    private dash = null;

    private get isLineLeaderEnroll() {
        return featuresStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    private get isCenterUser(): boolean {
        return authState.isCenterStaff;
    }

    async created() {
        appState.setIsDashboard(true);
        this.canSeeSettings = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
    }

    async mounted() {
        // Update the tab title, since 'Add Tab' can't set it correctly.
        const outerTab = layoutTabsStore.tabs[layoutTabsStore.currentTabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? TabTitles.DASHBOARD;
        outerTab.props.routeParams = this.$route.params;
        outerTab.props.tabTitle = this.$t('enrollment') + ' ' + this.$t('center');
        layoutTabsStore.updateTab(outerTab);
        if (this.openAddFamily) {
            this.showAddFamily = true;
        }
        const enrollmentString = this.$t('enrollment').toString();
        const centerString = this.$t('center').toString();
        this.setPageTitle(`${enrollmentString[0].toUpperCase()}${enrollmentString.substring(1)} ${centerString[0].toUpperCase()}${centerString.substring(1)}`);
    }

    /**
     * Refresh the tasks card and the inbox card when changes are made to the supported locations.
     */
    private refreshLocationsSupportedCards(): void {
        if (settingsChangesStore.isRefreshLocationsSupported) {
            settingsChangesStore.setRefreshLocationsSupported(false);
            location.reload();
        }
    }
}
