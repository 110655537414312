





















































































































































import { EventTypes } from '@/constants/event-type-constants';
import ChildrenInfo from '@/families/components/ChildrenInfo.vue';
import FamilyMarketingCampaigns from '@/families/components/FamilyMarketingCampaigns.vue';
import FamilyOtherFields from '@/families/components/FamilyOtherFields.vue';
import GuardianInfo from '@/families/components/GuardianInfo.vue';
import type { PendingFamily } from '@/families/models/family';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { SixAgentUtils } from '@/integrations/six-agent-utils';
import { AppStateStore } from '@/store/app-state-store';
import BaseClose from '@/components/base/BaseClose.vue';

const featureStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const sixAgentState = getModule(SixAgentStore);
const sixAgentUtils = new SixAgentUtils();
const appState = getModule(AppStateStore);

@Component({
    components: {
        BaseClose,
        FamilyOtherFields,
        FamilyMarketingCampaigns,
        ChildrenInfo,
        GuardianInfo
    }
})
export default class PendingFamilyHubModal extends Mixins(LocaleMixin) {
    /**
     * The pending family.
     */
    @Prop({ required: true }) readonly family!: PendingFamily;
    /**
     * v-model whether we should show it.
      */
    @Prop({ default: false }) readonly value!: boolean;

    private loadingKey = 'pendingFamilyHub';

    get areMarketingCampaignsEnabled() {
        return featureStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    get hasCrmPlusMode() {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isMini() {
        return appState.isMini;
    }

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, showIt);
    }

    get sixAgentContactCount() {
        return sixAgentState.contactCount;
    }

    @Watch('modelValue')
    flagChanged() {
        if (this.modelValue && sixAgentState.loggedIn) {
            sixAgentUtils.addPossibleContact(this.family);
        }
    }

    @Watch('sixAgentContactCount')
    sixAgentCountChanged(newCount: number, oldCount: number) {
        if (newCount > oldCount && sixAgentState.loggedIn) {
            sixAgentUtils.addPossibleContact(this.family);
        }
    }

    private async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await featureStore.init();
        loadingState.loadingStop(this.loadingKey);
    }

    private close() {
        this.modelValue = false;
    }
}
