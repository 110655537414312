var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('base-card',{attrs:{"elevation":0}},[(!_vm.isPst)?_c('v-card-actions',[_c('v-spacer'),_c('base-button-secondary',{on:{"click":_vm.addSection}},[_vm._v(" Add Section ")])],1):_vm._e(),_c('v-card-text',[_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],attrs:{"items":_vm.fields,"item-class":_vm.itemClass,"headers":_vm.headers,"item-key":"id","hide-default-footer":"","dense":"","items-per-page":-1,"fixed-header":"","height":"100vh","disable-sort":""},on:{"sorted":_vm.updateFields},scopedSlots:_vm._u([{key:"item.handle",fn:function(){return [_c('v-icon',{staticClass:"drag-handle mx-0 px-0"},[_vm._v("mdi-arrow-up-down-bold")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCrmName(item))+" ")]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('base-text-field',{attrs:{"hide-details":""},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})]}},{key:"item.use",fn:function(ref){
var item = ref.item;
return [(item.type.id !== _vm.sectionType)?_c('v-simple-checkbox',{attrs:{"disabled":_vm.isLocked(item) || _vm.isLockedActive(item)},on:{"input":function($event){return _vm.checkUse(item)}},model:{value:(item.is_used),callback:function ($$v) {_vm.$set(item, "is_used", $$v)},expression:"item.is_used"}}):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.type.id === _vm.sectionType)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteSection(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [(item.type.id !== _vm.sectionType)?_c('v-simple-checkbox',{attrs:{"disabled":_vm.isLocked(item)},on:{"input":function($event){return _vm.checkRequired(item)}},model:{value:(item.is_required),callback:function ($$v) {_vm.$set(item, "is_required", $$v)},expression:"item.is_required"}}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }