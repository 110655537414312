import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    CrmTypeOption,
    CrmTypeList,
    CrmTypeOptionUpdateDto,
    CrmTypeOptionCreateDto, CustomTypeLabel, CustomTypeLabelDto
} from '@/crm-types/models/crm-type';
import { CustomFieldValue, CustomFieldWithOption } from '@/crm-types/custom-fields/custom-fields-types';

export class CrmTypesRepository extends AbstractRepository<CrmTypeOption> {
    readonly endpoint = 'types';

    public async getList(list: CrmTypeList): Promise<Array<CrmTypeOption>> {
        const response = await this.client.get<Array<CrmTypeOption>>(this.endpoint + '/' + list);
        return response.data;
    }

    public async getAllList(list: CrmTypeList): Promise<Array<CrmTypeOption>> {
        const response = await this.client.get<Array<CrmTypeOption>>(`${this.endpoint}/${list}?show_inactive=true`);
        return response.data;
    }

    public async getOneListItem(id: number, list: CrmTypeList): Promise<CrmTypeOption> {
        const response = await this.client.get<CrmTypeOption>(`${this.endpoint}/${list}/${id}`);
        return response.data;
    }

    public async getCustomFields(): Promise<Array<CustomFieldWithOption>> {
        const response = await this.client.get<Array<CustomFieldWithOption>>(this.endpoint + '/fields/custom');
        return response.data;
    }

    public async getCustomFieldOptions(id: number): Promise<Array<CustomFieldValue>> {
        const response = await this.client.get<Array<CustomFieldValue>>(this.endpoint + `/fields/custom/${id}/values`);
        return response.data;
    }

    public async getCustomLabels(): Promise<Array<CustomTypeLabel>> {
        const response = await this.client.get<Array<CustomTypeLabel>>(this.endpoint + '/custom-labels');
        return response.data;
    }

    public async updateCustomLabel(update: CustomTypeLabelDto): Promise<void> {
        await this.client.patch(this.endpoint + '/custom-labels', update);
    }

    public async updateListItem(id: number, payload: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto, list: CrmTypeList): Promise<CrmTypeOption> {
        const response = await this.client.put(this.endpoint + '/' + list + '/' + id, payload);
        return response.data;
    }

    public async addListItem(payload: CrmTypeOptionUpdateDto | CrmTypeOptionCreateDto, list: CrmTypeList): Promise<CrmTypeOption> {
        const response = await this.client.post(this.endpoint + '/' + list, payload);
        return response.data;
    }

    public async deleteListItem(id: number, list: CrmTypeList): Promise<void> {
        await this.client.delete(this.endpoint + '/' + list + '/' + id);
    }
}
