






































/**
 * This component is used to edit enrollment-related data
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ChildFields, FieldTypes } from '@/crm-types/models/field-models';
import { EventTypes } from '@/constants/event-type-constants';
import { getCrmListForField, getEnrollmentProperty } from '@/crm-types/field-utils';
import {
    formatDateWithTimezone,
    formatIsoDateTime,
    isoFormat
} from '@/date-time/date-time-utils';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { WaitlistDetails, WaitlistDetailsUpdate } from '@/families/models/enrollment';
import { RequiredFieldsStore } from '@/crm-types/store/required-fields-store';
import { AuthStore } from '@/store/auth-store';
import type { ChildUpdateDtoInterface } from '@/families/models/child';
import type { Family } from '@/families/models/family';
import type { Field } from '@/crm-types/models/field-models';
import store from '@/store';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const authState = getModule(AuthStore, store);
const enrollmentStore = getModule(EnrollmentsStore);
const requiredFieldsStore = getModule(RequiredFieldsStore);

@Component({
    components: { CrmTypeSelectList }
})
export default class EnrollmentEditor extends Mixins(LocaleMixin) {
    @Prop({ required: true }) child!: ChildUpdateDtoInterface;
    @Prop({ required: true }) family!: Family;
    @Prop({ required: true }) field!: Field;
    @Prop({ required: true }) label!: string;

    private date: string | null = null;

    get isCheckbox(): boolean {
        return this.field.type.id === FieldTypes.CHECKBOX;
    }

    get isDate(): boolean {
        return this.field.type.id === FieldTypes.DATE;
    }

    get isRequired(): boolean {
        return this.field.is_system_required || this.field.is_client_required;
    }

    get isSelect(): boolean {
        return this.field.type.id === FieldTypes.SELECT_LIST;
    }

    get isText(): boolean {
        return this.field.type.id === FieldTypes.TEXT_FIELD;
    }

    get list(): CrmTypeList | null{
        return getCrmListForField(this.field);
    }

    get model() {
        // The EditChild component stores the child's enrollment object as a pending change
        const enrollment = enrollmentStore.pendingForChildId(this.child.id);
        if (!enrollment) {
            return null;
        }
        const enrollmentProperty = getEnrollmentProperty(this.field, enrollment);

        if (!enrollmentProperty) {
            return null;
        }

        if (this.isCheckbox) {
            // Wait list is the only one with a checkbox for now
            return (enrollmentProperty as WaitlistDetailsUpdate).is_fee_paid;
        }

        if (this.isDate) {
            switch (this.field.value) {
                case ChildFields.WAIT_LIST_DATE_FEE_PAID:
                    if ((enrollmentProperty as WaitlistDetailsUpdate).fee_paid_datetime) {
                        this.date = formatDateWithTimezone((enrollmentProperty as WaitlistDetailsUpdate).fee_paid_datetime as string, 'UTC', isoFormat);
                    }
                    break;
                case ChildFields.EXPECTED_START_DATE:
                    if (enrollment.expected_start_date) {
                        this.date = formatDateWithTimezone(enrollment.expected_start_date, 'UTC', isoFormat);
                    }
                    break;
                default:
                    if (enrollmentProperty.date) {
                        this.date = formatDateWithTimezone(enrollmentProperty.date as string, 'UTC', isoFormat);
                    }
            }

            return this.date;
        }

        if (this.isText) {
            return enrollmentProperty.comments;
        }

        if (this.isSelect) {
            switch (this.field.value) {
                // Check for the 3 different possible select lists
                case ChildFields.WAIT_LIST_TYPE:
                    if (typeof (enrollmentProperty as WaitlistDetails | WaitlistDetailsUpdate).type === 'number') {
                        return (enrollmentProperty as WaitlistDetailsUpdate).type;
                    }
                    return (enrollmentProperty as WaitlistDetails).type?.id ?? null;
                case ChildFields.PRIORITY_STATUS:
                    if (typeof (enrollmentProperty as WaitlistDetails | WaitlistDetailsUpdate).priority === 'number') {
                        return (enrollmentProperty as WaitlistDetailsUpdate).priority;
                    }
                    return (enrollmentProperty as WaitlistDetails).priority?.id ?? null;
                default:
                    break;
            }
            if (typeof enrollmentProperty.reason === 'number') {
                return enrollmentProperty.reason;
            }
            if (enrollmentProperty.reason && Object.prototype.hasOwnProperty.call(enrollmentProperty.reason, 'id')) {
                return enrollmentProperty.reason!.id;
            }
        }

        return null;
    }

    set model(value: string | number | boolean | null) {
        const enrollment = enrollmentStore.pendingForChildId(this.child.id);
        if (!enrollment) {
            return;
        }
        const prop = getEnrollmentProperty(this.field, enrollment);

        // Handle the "expected_enrollment" property case when in registered
        if (!prop && this.field.value === ChildFields.EXPECTED_START_DATE) {
            enrollment.expected_start_date = value as string;
            if (this.isRequired) {
                requiredFieldsStore.removeFromList({ familyId: this.family.id, fieldName: this.field.value });
            }
            this.$emit(EventTypes.UPDATED);
        }

        if (prop) {
            if (this.isDate && value !== null) {
                this.date = value as string;
                switch (this.field.value) {
                    case ChildFields.WAIT_LIST_DATE_FEE_PAID:
                        // There are two date fields in Wait List enrollment
                        (prop as WaitlistDetailsUpdate).fee_paid_datetime = formatIsoDateTime(this.date as string, authState.userTimeZone);
                        break;
                    case ChildFields.EXPECTED_START_DATE:
                        enrollment.expected_start_date = formatIsoDateTime(this.date as string, authState.userTimeZone);
                        break;
                    case ChildFields.WAIT_LIST_DATE:
                        enrollment.wait_list_date = formatIsoDateTime(this.date as string, authState.userTimeZone);
                        prop.date = enrollment.wait_list_date;
                        break;
                    default:
                        prop.date = formatIsoDateTime(this.date as string, authState.userTimeZone);
                }
            }

            if (this.isSelect) {
                switch (this.field.value) {
                    case ChildFields.PRIORITY_STATUS:
                        (prop as WaitlistDetailsUpdate).priority = value as number;
                        break;
                    case ChildFields.WAIT_LIST_TYPE:
                        (prop as WaitlistDetailsUpdate).type = value as number;
                        break;
                    default:
                        prop.reason = value as number;
                }
            }

            if (this.isText) {
                switch (this.field.value) {
                    case ChildFields.WAIT_LIST_FEE:
                        (prop as WaitlistDetailsUpdate).fee = value === null ? '' : (value as string).toString();
                        break;
                    default:
                        prop.comments = value as string;
                }
            }

            if (this.isCheckbox) {
                switch (this.field.value) {
                    case ChildFields.WAIT_LIST_FEE_PAID_IN_FULL:
                        (prop as WaitlistDetailsUpdate).is_fee_paid = value as boolean;
                        break;
                    default:
                        // No other checkboxes
                        break;
                }
            }

            enrollmentStore.replaceForChildId({ childId: this.child.id, enrollment: enrollment, familyId: this.family.id });
            if (this.isRequired) {
                requiredFieldsStore.removeFromList({ familyId: this.family.id, fieldName: this.field.value });
            }
            this.$emit(EventTypes.UPDATED);
        }
    }

    async mounted(): Promise<void> {
        if (this.isSelect) {
            if (!this.list) {
                return;
            }
        }
        if (this.isRequired && !this.model) {
            requiredFieldsStore.addToList({ familyId: this.family.id, fieldName: this.field.value });
        }
    }
}
