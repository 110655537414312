import { AbstractRepository } from '@/repositories/abstract-repository';
import { Feature } from '@/features/features-models';

export class FeaturesRepository extends AbstractRepository<Feature> {
    protected endpoint = 'features';

    public async toggle(feature: Feature, flag: boolean): Promise<Feature> {
        const response = await this.client.patch(
            `${this.endpoint}/${feature.id}`,
            {
                is_active: flag
            }
        );

        return response.data;
    }
}
