





















































































import type { Guardian } from '@/families/models/guardian';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Center } from '@/organizations/locations/models/center';
import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getAvatarBackgroundFromUser } from '@/core/avatar-utils';
import { getModule } from 'vuex-module-decorators';
import type { Family } from '@/families/models/family';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import PhoneNumberDisplay from '@/communications/PhoneNumberDisplay.vue';

const appState = getModule(AppStateStore);
const featuresStore = getModule(FeaturesStore);
const sixAgentState = getModule(SixAgentStore);
@Component({
    components: { PhoneNumberDisplay }
})
export default class GuardianInfo extends Mixins(LocaleMixin) {
    /**
     * Whether or not the family score should be allowed to be shown.
     */
    @Prop({ type: Boolean, default: true }) readonly canShowScore!: boolean;
    @Prop() family!: Family;
    @Prop() guardian!: Guardian;
    @Prop() isDoNotCall!: boolean;

    get center(): Center | null {
        return appState.storedCurrentCenter;
    }

    get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get sixAgentReady() {
        return sixAgentState.ready && sixAgentState.loggedIn;
    }

    private get isFamilyScoreFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FAMILY_SCORE);
    }

    private avatarInitials(guardian: Guardian) {
        return guardian.first_name.substr(0, 1).toUpperCase() + guardian.last_name.substr(0, 1).toUpperCase();
    }

    // Select avatar background from the set of constant colors by using the modulus of guardian id with length of the color array
    private avatarBackground(guardian: Guardian) {
        return getAvatarBackgroundFromUser(guardian.id);
    }

    dialSixAgent() {
        if (!this.guardian.primary_phone) {
            return;
        }
        sixAgentState.open();
        window.sixAgentInterface.outboundCall(this.guardian.primary_phone.number_e164);
    }
}
