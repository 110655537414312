import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import {
    DripCampaign, DripCampaignDrip, DripCampaignDripDto,
    DripCampaignDto, DripCampaignEventActionDto,
    DripCampaignStopTriggerDto,
    DripCampaignUpdateDto
} from '@/automation/drip-campaigns/models/drip-campaign';
import {
    DripCampaignIntervalUnit,
    DripCampaignTiming,
    DripCommType
} from '@/automation/drip-campaigns/drip-campaign-constants';
import { WorkflowEntityTypes } from '@/automation/workflows/constants/workflow-constants';

export class DripCampaignsMapper extends AbstractMapper<DripCampaign> {
    createBlank(): DripCampaignDto {
        return {
            name: '',
            description: '',
            timing_type: DripCampaignTiming.FIXED,
            interval_amount: 0,
            interval_unit: DripCampaignIntervalUnit.DAY,
            relative_field: null,
            organization: 1,
            drips: [],
            stop_triggers: [],
            enabled_center_ids: null
        };
    }

    toCreateDto(campaign: DripCampaign): DripCampaignDto {
        return {
            name: campaign.name,
            description: campaign.description,
            timing_type: campaign.timing_type.id,
            interval_amount: campaign.interval_amount ?? 0,
            interval_unit: campaign.interval_unit ? campaign.interval_unit.id : DripCampaignIntervalUnit.DAY,
            relative_field: campaign.relative_field ? campaign.relative_field.id : null,
            organization: campaign.organization.id,
            drips: this.mapDrips(campaign),
            stop_triggers: this.mapStopTriggers(campaign),
            enabled_center_ids: null
        };
    }

    toUpdateDto(campaign: DripCampaign): DripCampaignUpdateDto {
        return this.toCreateDto(campaign);
    }

    createBlankDrip(): DripCampaignDripDto {
        return {
            name: '',
            comm_type: DripCommType.EMAIL,
            interval_amount: null,
            interval_unit: DripCampaignIntervalUnit.DAY,
            due_percentage: null,
            email_template: null,
            text_template: null,
            event_actions: []
        };
    }

    mapDrips(campaign: DripCampaign): Array<DripCampaignDripDto> {
        if (!campaign.drips) {
            return [];
        }
        return campaign.drips.map((drip) => {
            return {
                name: drip.name,
                comm_type: drip.comm_type.id,
                interval_amount: drip.interval_amount,
                interval_unit: drip.interval_unit ? drip.interval_unit.id : DripCampaignIntervalUnit.DAY,
                due_percentage: drip.due_percentage,
                email_template: drip.email_template ? drip.email_template.id : null,
                text_template: drip.text_template ? drip.text_template.id : null,
                event_actions: this.mapEventActions(drip)
            };
        });
    }

    createBlankEventAction(): DripCampaignEventActionDto {
        return {
            email_event: null,
            text_event: null,
            event_action: 0,
            drip_campaign: null,
            workflow: null
        };
    }

    mapEventActions(drip: DripCampaignDrip): Array<DripCampaignEventActionDto> {
        return drip.event_actions.map((eventAction) => {
            return {
                email_event: eventAction.email_event ? eventAction.email_event.id : null,
                text_event: eventAction.text_event ? eventAction.text_event.id : null,
                event_action: eventAction.event_action.id,
                drip_campaign: eventAction.drip_campaign ? eventAction.drip_campaign.id : null,
                workflow: eventAction.workflow ? eventAction.workflow.id : null
            };
        });
    }

    createBlankStopTrigger(): DripCampaignStopTriggerDto {
        return {
            type: WorkflowEntityTypes.FAMILY,
            status_value: null,
            event_type_value: null
        };
    }

    mapStopTriggers(campaign: DripCampaign): Array<DripCampaignStopTriggerDto> {
        if (!campaign.stop_triggers) {
            return [];
        }
        return campaign.stop_triggers.map((stopTrigger) => {
            return {
                type: stopTrigger.type.id,
                event_type_value: stopTrigger.event_type_value ? stopTrigger.event_type_value.id : null,
                status_value: stopTrigger.status_value ? stopTrigger.status_value.id : null
            };
        });
    }
}
