import { CrmApiElement, CrmEntity, ValuedLink } from '@/models/base';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';

export interface EnrollmentCenterSettings extends CrmApiElement {
    name: string;
    flow: ValuedLink;
    task_types: Array<ValuedLink>;
    email_address: string;
    url: string;
    phone_number: string;
    phone_extension: string;
    enable_offerings: boolean;
    enable_details: boolean;
    enable_pricing: boolean;
    enable_availability: boolean;
    enable_ratings: boolean;
}

export interface EnrollmentCenterSettingsUpdateDto extends AbstractUpdateDto {
    name: string;
    flow: number;
    task_types: Array<number>;
    email_address: string;
    url: string;
    phone_number: string;
    phone_extension: string;
    enable_offerings: boolean;
    enable_details: boolean;
    enable_pricing: boolean;
    enable_availability: boolean;
    enable_ratings: boolean;
}

export interface EnrollmentCenterOfferingsRatingsOption extends CrmEntity {
    label: string;
    order: number;
}

export interface EnrollmentCenterOfferingsRatings extends CrmEntity {
    label: string;
    order: number;
    options: Array<EnrollmentCenterOfferingsRatingsOption>;
}

export interface EnrollmentCenterOfferingsRatingsCreateDto extends AbstractCreateDto {
    label: string;
    order: number;
    options: Array<EnrollmentCenterOfferingsRatingsOption>;
}

export interface EnrollmentCenterOfferingsRatingsUpdateDto extends AbstractUpdateDto, EnrollmentCenterOfferingsRatingsCreateDto {
    id: number;
}

export const enrollmentTeamPlaceholder = -1;

export interface EnrollmentCenterDetails extends CrmEntity {
    description: string;
    header: string;
    order: number;
}

export interface EnrollmentCenterDetailsCreateDto extends AbstractCreateDto {
    description: string;
    header: string;
    order: number;
}

export interface EnrollmentCenterDetailsUpdateDto extends AbstractUpdateDto, EnrollmentCenterDetailsCreateDto {
    id: number;
}

export interface EnrollmentCenterRateOption extends CrmEntity {
    label: string;
    order: number;
}

export interface EnrollmentCenterRateOptionCreateDto extends AbstractCreateDto {
    label: string;
    order: number;
}

export interface EnrollmentCenterRateOptionUpdateDto extends AbstractUpdateDto, EnrollmentCenterRateOptionCreateDto {
    id: number;
}

export interface EnrollmentCenterRatings extends CrmEntity {
    label: string;
    order: number;
    options: Array<RatingsOption>;
}

export interface RatingsOption extends CrmEntity {
    label: string;
    order: number;
}

export enum EnrollmentRepFlowConstants {
    ASSIGNMENT_FLOW_REP = 9100,
    ASSIGNMENT_FLOW_LOCATION = 9101,
    ASSIGNMENT_FLOW_TEAM = 9102

}
