


















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

@Component({
    components: { BaseClose }
})
export default class BaseColorInput extends Mixins(LocaleMixin) {
    @Prop({ required: true }) readonly value!: string;

    private showPicker = false;
    private pickerColor = '';

    get modelValue(): string {
        return this.value;
    }

    set modelValue(color: string) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit(EventTypes.INPUT, color);
    }

    get color(): string {
        return '#' + this.modelValue;
    }

    @Watch('value', { immediate: true })
    colorChanged() {
        this.pickerColor = '#' + this.value;
    }

    private confirmColor() {
        this.modelValue = this.pickerColor.replace(/^#/, '');
        this.close();
    }

    private close() {
        this.showPicker = false;
    }
}

