






















































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { IntegrationPartners, IntegrationTypes } from '@/integrations/integration-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Integration, IntegrationPartner, ServiceAccount } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import { AuthStore } from '@/store/auth-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { Regions } from '@/locales/locales';
import store from '@/store';
import IntegrationMappedClassrooms from '@/integrations/components/IntegrationMappedClassrooms.vue';
import MapLocations from '@/integrations/components/MapLocations.vue';
import ServiceAccountModal from '@/integrations/components/ServiceAccountModal.vue';
import FacebookLink from '@/integrations/components/FacebookLink.vue';
import ManageFacebook from '@/integrations/components/ManageFacebook.vue';
import ManageCcfTemplateVariables from '@/integrations/components/ManageCcfTemplateVariables.vue';
import MapCcfTemplateVariables from '@/integrations/components/MapCcfTemplateVariables.vue';
import BaseClose from '@/components/base/BaseClose.vue';
import IntegrationService from '@/integrations/IntegrationService';
import CmsIntegrationModal from '@/integrations/components/CmsIntegrationModal.vue';

const authState = getModule(AuthStore, store);
const integrationRepository = new IntegrationRepository();
const loadingState = getModule(LoadingStore);
const featuresStore = getModule(FeaturesStore);
const facebookState = getModule(FacebookStore);

@Component({
    components: {
        CmsIntegrationModal,
        BaseClose,
        MapCcfTemplateVariables,
        ManageCcfTemplateVariables,
        ManageFacebook,
        FacebookLink,
        ServiceAccountModal,
        MapLocations,
        IntegrationMappedClassrooms
    }
})
export default class CurrentIntegrations extends Mixins(LocaleMixin, BasicValidationMixin) {
    private activeIntegrations: Array<Integration> = [];
    private activeReferralServicesArray: Array<ServiceAccount> = [];
    private activeReferralServicesMap: Map<number, ServiceAccount> = new Map();
    private allowMultiCms = false;
    private apiCustomerID = '';
    private apiCustomerIDSecondary = '';
    private apiKey = '';
    private apiKeySecondary = '';
    private apiPassword = '';
    private apiPasswordSecondary = '';
    private apiUsername = '';
    private apiUsernameSecondary = '';
    private ccfIntegrationToken = false;
    private ccfIntegrations: Array<string> = [];
    private closeEvent = EventTypes.CLOSE;
    private closingSAModal = EventTypes.CLOSE;
    private CmsId = IntegrationTypes.CMS;
    private cmsIntegrationChanged = EventTypes.SETTINGS_CHANGED;
    private cmsIntegrationToken = false;
    private cmsIntegrationTokenSecondary = false;
    private cmsPartner: IntegrationPartner | null = null;
    private cmsPartnerId = 0;
    private cmsPartnerIdSecondary = 0;
    private currentApiUsername = '';
    private currentApiPassword = '';
    private currentApiKey = '';
    private currentApiCustomerID = '';
    private currentApiUsernameSecondary = '';
    private currentApiPasswordSecondary = '';
    private currentApiKeySecondary = '';
    private currentApiCustomerIDSecondary = '';
    private currentCmsIntegrations: Array<Integration> = [];
    private currentCmsPartners: Array<IntegrationPartner> = [];
    private deleteServiceAccountEvent = EventTypes.DELETED;
    private hasMultiCms = false;
    private integrationService: IntegrationService | null = null;
    private isDisabled = false; // Disable/enable save button when valid input is present.
    private isFacebookLinkOpen = false;
    private isFacebookManageOpen = false;
    private isSecondaryDisabled = false;
    private loadingKey = 'currentIntegrations';
    private manageCurSA: ServiceAccount | null = null;
    private manageSAPartner: IntegrationPartner | null = null;
    private partnersList: Array<IntegrationPartner> = [];
    private primaryCmsPartner: IntegrationPartner | null = null;
    private primaryCmsPartnerId = 0;
    private region: Regions = Regions.us;
    private referralServiceOptionsResponse: Array<IntegrationPartner> = [];
    private referralServicePartner: IntegrationPartner | null = null;
    private referralToken = false;
    private secondaryCmsPartner: IntegrationPartner | null = null;
    private secondaryCmsPartnerId = 0;
    private serviceAccountsUpdate = EventTypes.UPDATED;
    private showCcfIntegrations = false;
    private showCmsIntegrationModal = false;
    private showManageCcfLinks = false;
    private showMapCcfLinks = false;
    private showMapClassesModal = false;
    private showMapClassesModalSecondary = false;
    private showServiceAccountModal = false;
    private integrationToEdit: IntegrationPartner | null = null;
    private cmsModalOpened = EventTypes.MODAL_OPENED;

    private get callCenterPartners() {
        return this.partnersList.filter(
            (partner) => {
                return partner.type.id === IntegrationTypes.CALL_CENTER && (!this.hasInContact || partner.name !== IntegrationPartners.INCONTACT);
            }
        );
    }

    private get hasCurrent() {
        return this.primaryCmsPartner;
    }

    private get hasEnrollmentCenter(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    private get hasFacebookPages(): boolean {
        return facebookState.entities.length > 0;
    }

    private get hasInContact() {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT);
    }

    private get inContactPartner() {
        return this.partnersList.filter((partner) => {
            return partner.name === IntegrationPartners.INCONTACT;
        })[0];
    }

    private get isSuperUser() {
        return authState.isSuperuser;
    }

    /**
     * Get the user setting state of classroom add-ons
     */
    private get isClassroomsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    /**
     * Whether the user is allowed to enable a CMS partner.
     */
    private get isCmsEnabled(): boolean {
        // Check if they have the CMS integrations add-on feature enabled.
        // If not, don't allow them to integrate with a partner.
        const features = featuresStore.entities;
        for (const feature of features) {
            if (feature.identifier === FeatureConstants.CMS_ADD_ON_ID) {
                if (feature.is_active) {
                    return true;
                }

                break;
            }
        }

        return false;
    }

    private get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    private get isFacebookIntegrationEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION);
    }

    private get isLineLeaderEnroll(): boolean {
        return featuresStore.isLineLeaderEnroll;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        if (authState.overrideCountry && Regions[authState.overrideCountry as keyof typeof Regions]) {
            this.region = Regions[authState.overrideCountry as keyof typeof Regions];
        } else {
            this.region = authState.userInfoObject
                ? Regions[authState.userInfoObject.country as keyof typeof Regions]
                : Regions.us;
        }

        this.integrationService = new IntegrationService(this.region);
        if (this.isFacebookIntegrationEnabled) {
            await facebookState.init();
        }

        this.partnersList = await this.integrationService.getPartnerList();
        this.activeIntegrations = await this.integrationService.getActiveIntegrations();
        this.currentCmsIntegrations = await this.integrationService.getActiveCmsIntegrations();
        this.currentCmsPartners = await this.integrationService.getActiveCmsPartners();
        this.activeReferralServicesMap = await this.integrationService.getActiveReferralServicesMap();
        this.activeReferralServicesArray = await this.integrationService.getActiveReferralServicesArray();
        this.referralServiceOptionsResponse = await this.integrationService.getReferralServiceOptions();
        this.ccfIntegrations = await this.integrationService.getCcfIntegrations();
        const multiCmsInfo = await this.integrationService.getMultiCmsInfo();
        this.allowMultiCms = multiCmsInfo.allowMultiCms;
        this.hasMultiCms = multiCmsInfo.hasMultiCms;

        if (this.currentCmsPartners.length > 0) {
            if (!this.allowMultiCms || !this.hasMultiCms) {
                this.setInitialCMSInfo(this.currentCmsPartners[0]);
            } else if (this.allowMultiCms && this.hasMultiCms) {
                // Determine if we can have a secondary CMS.
                // p = Primary CMS Integration.
                const p = this.currentCmsPartners
                    .find(
                        (partner) =>
                            IntegrationPartners.PROCARE === partner.name || IntegrationPartners.QIKKIDS === partner.name
                    );
                // Ss = Secondary CMS integration.
                const s = this.currentCmsPartners
                    .find(
                        (partner) =>
                            IntegrationPartners.MANAGE === partner.name || IntegrationPartners.KANGAROO_TIME === partner.name
                    );

                // If we have multiple CMS integrations, we need to set the initial values for both.
                // The cmsPartners are pre-sorted by if they are primary or secondary.
                if (p) {
                    this.setInitialCMSInfo(p);
                }

                if (s) {
                    this.setInitialCMSInfoSecondary(s);
                }
            }
        }

        if (this.ccfIntegrations.length > 0) {
            this.ccfIntegrationToken = true;
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private closeCmsModal() {
        this.showCmsIntegrationModal = false;
        this.integrationToEdit = null;
        this.cmsIntegrationTokenSecondary = false;
    }

    private async deactivateServiceAccount(serviceAcc: ServiceAccount) {
        if (this.integrationService && serviceAcc) {
            loadingState.loadingIncrement(this.loadingKey);
            await integrationRepository.deleteServiceAccount(serviceAcc.id);
            loadingState.loadingDecrement(this.loadingKey);
            this.$emit(this.cmsIntegrationChanged);
        }
    }

    private editServiceAccount(account: ServiceAccount) {
        this.manageCurSA = account;
        this.showServiceAccountModal = true;
    }

    private async getCmsPartners(): Promise<Array<IntegrationPartner>> {
        if (!this.integrationService) {
            return [];
        }

        return await this.integrationService.getCmsPartners();
    }

    /**
     * Handle clicking on the button to integrate with a CMS partner.
     *
     * @param {Integration}integration
     * @param {boolean}isSecondary
     */
    private handleEnableCms(integration: IntegrationPartner, isSecondary = false) {
        if (!this.isCmsEnabled) {
            this.$swal({
                text: 'Please contact ChildcareCRM using the chat feature to discuss upgrading your subscription to include Childcare Management System integration.',
                icon: 'info'
            });
        } else {
            this.$emit(this.cmsModalOpened, { integrationToEdit: integration, isSecondary: isSecondary });
        }
    }

    private saveCmsIntegration(type: EventTypes, data: any) {
        this.$emit(type, data);
    }

    private async updateServicesSection(updatedPartner: ServiceAccount) {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.integrationService) {
            this.referralToken = false;
            if (updatedPartner && updatedPartner.partner && updatedPartner.partner.id) {
                this.activeReferralServicesMap.set(updatedPartner.partner.id, updatedPartner);
            }
            this.activeReferralServicesArray = Array.from(this.activeReferralServicesMap.values());
            this.referralServiceOptionsResponse = await this.integrationService.getReferralServiceOptions();
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    private setInitialCMSInfo(integration: IntegrationPartner): void {
        this.cmsPartnerId = integration.id;
        this.primaryCmsPartnerId = integration.id;
        this.primaryCmsPartner = integration;
        if (integration.credentials) {
            this.currentApiUsername = integration.credentials.username ? integration.credentials.username : '';
            this.currentApiPassword = integration.credentials.password ? integration.credentials.password : '';
            if (integration.credentials.customer_id) {
                this.currentApiCustomerID = integration.credentials.customer_id;
            }
            if (integration.credentials.api_key) {
                this.currentApiKey = integration.credentials.api_key;
            }
        }
    }

    /**
     * Set the initial variable for secondary CMS.
     * CRM-13906
     *
     * @param integration
     */
    private setInitialCMSInfoSecondary(integration: IntegrationPartner | null): void {
        this.cmsPartnerIdSecondary = integration?.id ? integration.id : 0;
        this.secondaryCmsPartnerId = integration?.id ? integration.id : 0;
        this.secondaryCmsPartner = integration;
        if (integration?.credentials) {
            this.currentApiUsernameSecondary = integration.credentials.username ? integration.credentials.username : '';
            this.currentApiPasswordSecondary = integration.credentials.password ? integration.credentials.password : '';
            if (integration.credentials.customer_id) {
                this.currentApiCustomerIDSecondary = integration.credentials.customer_id;
            }
            if (integration.credentials.api_key) {
                this.currentApiKeySecondary = integration.credentials.api_key;
            }
        }
    }
}
