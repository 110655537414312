







































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { CenterUpdates } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { Class, ClassUpdateDtoInterface } from '@/organizations/locations/models/class';
import { applyAvailToDto, classSort, createAvailObject } from '@/enrollment-center/location-utils';
import { ClassesRepository } from '@/organizations/locations/repositories/classes-repository';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AvailabilityClassroomObject } from '@/enrollment-center/models/enrollment-center-location-models';
import { ClassMapper } from '@/organizations/locations/mappers/class-mapper';
import { DataTableHeader } from 'vuetify';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import ClassroomAvailRow from '@/enrollment-center/components/ClassroomAvailRow.vue';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { formatDateWithTimezone } from '@/date-time/date-time-utils';

const classesRepo = new ClassesRepository();
const centersRepo = new CentersRepository();
const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const classMapper = new ClassMapper();
const centersState = getModule(CentersStore);

@Component({
    components: { ClassroomAvailRow }
})
export default class LocationAvailabilityTab extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('tabMode') localMode!: EditMode;
    private localLocation: Center | null = null;
    private loadingKey = 'locationAvailability';
    private classes: Array<Class> = [];
    private classDtos: Array<ClassUpdateDtoInterface> = [];
    private displayClasses: Array<AvailabilityClassroomObject> = [];
    private lastUpdated: CenterUpdates | null = null;

    get headers(): Array<DataTableHeader> {
        const first: Array<DataTableHeader> = [
            {
                text: 'Classroom',
                value: 'name',
                width: '10%'
            }
        ];
        if (this.isEdit) {
            first.push({
                text: '',
                value: 'nextFree',
                width: '10rem'
            });
        }
        const headers: Array<DataTableHeader> = [
            {
                text: 'Monday',
                value: '0'
            },
            {
                text: 'Tuesday',
                value: '1'
            },
            {
                text: 'Wednesday',
                value: '2'
            },
            {
                text: 'Thursday',
                value: '3'
            },
            {
                text: 'Friday',
                value: '4'
            }
        ];
        if (this.hasSaturday) {
            headers.push({
                text: 'Saturday',
                value: '5'
            });
        }
        if (this.hasSunday) {
            headers.push({
                text: 'Sunday',
                value: '6'
            });
        }
        if (this.isEdit) {
            for (const header of headers) {
                header.width = '13rem';
            }
        }
        return first.concat(headers);
    }

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    get hasSaturday() {
        return this.localLocation && this.localLocation.open_saturday;
    }

    get hasSunday() {
        return this.localLocation && this.localLocation.open_sunday;
    }

    @Watch('location', { deep: true, immediate: true })
    async locationChanged() {
        loadingState.loadingIncrement(this.loadingKey);
        // this is the only tab that needs updated info for center
        // in case days open is changed
        this.localLocation = await centersState.getAccessibleCenterById(this.location.id);
        this.lastUpdated = await centersRepo.getCenterLastUpdated(this.location.id);
        this.classes = await classesRepo.retrieveAll(this.location.id);
        this.classes.sort(classSort);
        this.classDtos.splice(0);
        this.displayClasses.splice(0);
        for (const classroom of this.classes) {
            const dto = classMapper.toUpdateDto(classroom);
            this.classDtos.push(dto);
            this.displayClasses.push(createAvailObject(dto));
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode')
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            await this.locationChanged();
        }
        if (this.localMode === EditMode.SAVING) {
            const promises = [];
            for (let iter = 0; iter < this.classDtos.length; iter++) {
                applyAvailToDto(this.displayClasses[iter], this.classDtos[iter], this.location.timezone);
                promises.push(classesRepo.update(this.location.id, this.classDtos[iter]));
            }
            await Promise.all(promises);
            this.localMode = EditMode.SAVED;
        }
    }

    getClassroomName(classroom: Class): string {
        return classroom.name + ' (' + classroom.begin_age + '-' + classroom.end_age + ')';
    }

    get lastAvailabilityUpdate() {
        if (this.lastUpdated?.availability) {
            return 'Last updated ' + formatDateWithTimezone(new Date(this.lastUpdated.availability.date_time), authState.userTimeZone, 'EEEE, MMMM d, Y');
        }
        return '';
    }
}
