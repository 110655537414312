import { StoreModuleTypes } from '@/constants/store-constants';
import {
    Enrollment,
    EnrollmentCreateDtoInterface,
    EnrollmentUpdateDtoInterface
} from '@/families/models/enrollment';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import store from '@/store';
import { AbstractApiStore, AbstractEntityState } from '@/store/abstract-api-store';
import { Module, Mutation } from 'vuex-module-decorators';

export interface EnrollmentsState extends AbstractEntityState<Enrollment> {
    entities: Array<Enrollment>;
}

export interface ChildEnrollmentPayload {
    familyId: number;
    childId: number | undefined;
    enrollment: EnrollmentCreateDtoInterface | EnrollmentUpdateDtoInterface;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.ENROLLMENTS
})
export class EnrollmentsStore extends AbstractApiStore<Enrollment> implements EnrollmentsState {
    readonly repository = new EnrollmentsRepository();
    initialState: Set<ChildEnrollmentPayload> = new Set();
    pendingChanges: Set<ChildEnrollmentPayload> = new Set();

    public get changes() {
        return (familyId: number): Array<EnrollmentCreateDtoInterface | EnrollmentUpdateDtoInterface> => {
            return Array.from(this.pendingChanges.values())
                .filter((change) => {
                    return change.familyId === familyId;
                })
                .map((change) => {
                    return change.enrollment;
                });
        };
    }

    public get pendingForChildId() {
        return (childId: number | undefined): EnrollmentCreateDtoInterface | EnrollmentUpdateDtoInterface | null => {
            if (!childId) {
                // Because the EnrollmentCreate sets childId to 0.
                childId = 0;
            }

            for (const enrollmentPayload of this.pendingChanges) {
                if (enrollmentPayload.childId === childId) {
                    return enrollmentPayload.enrollment;
                }
            }

            for (const enrollmentPayload of this.initialState) {
                if (enrollmentPayload.childId === childId) {
                    return enrollmentPayload.enrollment;
                }
            }

            return null;
        };
    }

    @Mutation
    public replaceForChildId(payload: ChildEnrollmentPayload) {
        for (const pending of this.pendingChanges) {
            if (pending.childId === payload.childId) {
                this.pendingChanges.delete(pending);
            }
        }
        this.pendingChanges.add(payload);
    }

    @Mutation
    public deleteForChildId(childId: number | undefined) {
        for (const pending of this.pendingChanges) {
            if (pending.childId === childId) {
                this.pendingChanges.delete(pending);
            }
        }
    }

    @Mutation
    public storeInitialState(payload: ChildEnrollmentPayload) {
        this.initialState.add(payload);
    }

    @Mutation
    public clearPendingChanges(familyId: number) {
        const changes = Array.from(this.pendingChanges.values());
        changes.filter((change) => {
            return change.familyId !== familyId;
        });
        this.pendingChanges.clear();
        for (const change of changes) {
            this.pendingChanges.add(change);
        }
    }
}
