import { UserCreateDto, UserUpdateDto } from '@/staff/models/user';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { StoreModuleTypes } from '@/constants/store-constants';
import { CustomValuesUpdateDto, CustomField } from '@/crm-types/custom-fields/custom-fields-types';

export interface CustomStaffValueChanges {
    updates: CustomValuesUpdateDto;
    staffDto: UserCreateDto | UserUpdateDto;
    field: CustomField;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.CUSTOM_STAFF_VALUES
})
export class CustomStaffValuesChangesStore extends VuexModule {
    private changes: Set<CustomStaffValueChanges> = new Set();

    @Mutation
    public clearChanges() {
        this.changes.clear();
    }

    @Mutation
    public storeChanges(changes: CustomStaffValueChanges) {
        this.changes.add(changes);
    }

    @Mutation
    public replaceChanges(changes: CustomStaffValueChanges) {
        const items = Array.from(this.changes).filter(change => change.staffDto === changes.staffDto && change.field === changes.field);
        for (const item of items) {
            this.changes.delete(item);
        }
        this.changes.add(changes);
    }

    get changesForStaff() {
        return (staff: UserCreateDto | UserUpdateDto): Array<CustomValuesUpdateDto> => {
            return Array.from(this.changes).filter(change => change.staffDto === staff).map(change => change.updates);
        };
    }
}
