



































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Org } from '@/models/organization/org';
import { GroupCommHistoryRepository } from '@/communications/messages/repositories/group-comm-history-repository';
import { DataTableOptions } from '@/models/datatables';
import { ApiPagination } from '@/repositories/abstract-repository';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { getPagination } from '@/core/datatables-utils';
import { CommunicationType, GroupCommHistory } from '@/communications/messages/models/message';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';

const authState = getModule(AuthStore, store);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);

const groupCommHistRepo = new GroupCommHistoryRepository();

@Component
export default class GroupCommHistoryReport extends Mixins(LocaleMixin) {
    private currentItemCount = 1;
    private options: DataTableOptions = { itemsPerPage: 25, page: 1 };
    private isLoading = true;
    private loadingKey = 'groupCommHistory';
    private grpCommHistItems: Array<GroupCommHistory> = [];

    /**
     * The headers for the table.
     */
    get grpCommHistHeaders(): Array<DataTableHeader> {
        return [
            { text: 'Filter Name', value: 'name', width: '35%' },
            { text: 'Message Type', value: 'type', width: '15ch' },
            { text: 'Subject', value: 'subject' },
            { text: 'Date Sent', value: 'date_sent', width: '15ch' },
            { text: 'Status', value: 'status.values.value', width: '13ch' },
            { text: '', value: 'action', width: '15ch' }
        ];
    }

    get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    get timezone(): string {
        return authState.userInfoObject?.timezone ?? '';
    }

    // Watch for changes to the Datatables options -- pagination.
    @Watch('options')
    async onOptionsChange(options: DataTableOptions) {
        await this.getGrpCommHistoryItems(getPagination(options));
    }

    @Watch('org', { deep: true, immediate: true })
    async changeOrg() {
        if (this.org) {
            await this.getGrpCommHistoryItems(getPagination(this.options));
        }
    }

    private async created() {
        await this.getGrpCommHistoryItems(getPagination(this.options));
    }

    /**
     * Cancel the group history by calling the delete endpoint.
     *
     * @param history
     */
    private async cancelHistory(history: GroupCommHistory): Promise<void> {
        const result = await this.$swal({
            icon: 'warning',
            text: 'Are you sure you want to cancel this group communication before it is sent?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            focusConfirm: false,
            cancelButtonText: 'No',
            focusCancel: false
        });

        if (result.isConfirmed) {
            loadingState.loadingIncrement(this.loadingKey);
            try {
                await groupCommHistRepo.cancelGroupCommHistory(history.id, history.type);
            } catch (e) {
                // No op. Let the page reload.
            }
            await this.getGrpCommHistoryItems(getPagination(this.options));
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    /**
     * Get the items to display in the table.
     *
     * @param pagination
     */
    private async getGrpCommHistoryItems(pagination: ApiPagination = { limit: 25, offset: 0 }) {
        this.isLoading = true;
        if (this.org && this.org.id) {
            const data = await groupCommHistRepo.getGroupCommHistory(this.org.id, pagination);
            this.grpCommHistItems = data.entities;
            this.currentItemCount = Number(data.count);
        }

        this.isLoading = false;
    }

    /**
     * There be nulls in this data.
     * @param date
     * @private
     */
    private formatDateSent(date: string): string {
        // return date;
        let ret = 'Send Pending';
        try {
            ret = this.formatDate(date);
        } catch (e) {
            // Do nothing.
        }

        return ret;
    }

    /**
     * View the stats for the given group history.
     *
     * @param history
     */
    private viewStats(history: GroupCommHistory) {
        if (history.type === CommunicationType.EMAIL) {
            this.$router.push({ name: 'group-email', params: { id: history.id.toString() } });
        } else {
            this.$router.push({ name: 'group-text', params: { id: history.id.toString() } });
        }
    }
}
