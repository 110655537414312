import { CrmEntity, HateoasLink, NamedLink, ValuedLink } from '@/models/base';
import { Center, CenterLink } from '@/organizations/locations/models/center';
import { AbstractCreateDto } from '@/models/abstract-dto';
import { Child, ChildCreateDtoInterface } from '@/families/models/child';

export interface CcfIntegration extends CrmEntity {
    dns_name: string;
    ccf_client_id: number;
    organization: NamedLink | null;
    location_group: ValuedLink | null;
}

export interface CcfInterview extends CrmEntity {
    name: string;
}

export interface Integration extends CrmEntity {
    center: CenterLink | null;
    is_active: boolean;
    name: string;
    type: string;
    partner_id: number;
    credentials?: {
        username?: string;
        password?: string;
        customer_id?: string;
        api_key?: string;
    };
}

export interface IntegrationDisplayInfo {
    href: string;
    img: IntegrationImageInfo;
    show: boolean;
}

export interface IntegrationImageInfo {
    src: string;
    style: string;
    textStyle: string;
}

export interface IntegrationPartner extends CrmEntity {
    has_centers_list: boolean;
    name: string;
    needs_centers_mapped: boolean;
    needs_classrooms_mapped: boolean;
    needs_credentials: boolean;
    type: HateoasLink;
    credentials?: {
        username?: string;
        password?: string;
        customer_id?: string;
        api_key?: string;
    };
    needs_service_account: boolean;
    region: string | null;
}

export interface IntegrationPartnerToggle {
    is_active: boolean;
    partner_id: number;
    credentials?: {
        username?: string;
        password?: string;
        customer_id?: string;
        api_key?: string;
    };
}

export interface IntegrationLocationMappingId {
    id: string;
    name: string;
}

export interface LocationIntegrationToggle {
    center_id: number;
    is_enabled: boolean;
}

export interface LocationIntegrationDto {
    id: number;
    organization_id?: number;
    external_system_id?: string;
    secondary_external_system_id?: string;
}

export interface LocationIntegrationMapping {
    center: Center;
    external_system_id?: string | null;
    secondary_external_system_id?: string | null;
    original_is_enabled?: boolean | null;
    is_enabled?: boolean | null;
}

export type LocationsMappingDto = Array<LocationIntegrationDto>;

/**
 * Management system integration DTO stuff.
 */
export interface IntegrationExportChildDtoInterface extends AbstractCreateDto {
    child_id: number;
}

export class IntegrationExportChildPostDto implements IntegrationExportChildDtoInterface {
    child_id = 0;
}

export interface IntegrationExportChildInterface {
    child: Child | ChildCreateDtoInterface;
    export: boolean;
}

export interface ServiceAccount extends CrmEntity {
    partner: HateoasLink;
    username: string;
    password?: string;
}

export interface ServiceAccountDtoInterface extends AbstractCreateDto {
    partner?: number;
    username: string;
}

export class ServiceAccountPostDto implements ServiceAccountDtoInterface {
    partner = 1;
    username = '';
}

export interface FacebookPage {
    id: number;
    clientId: string;
    facebookPageId: string;
    pageAccessToken: string;
}

export interface MultiCmsInfo {
    allowMultiCms: boolean;
    hasMultiCms: boolean;
}
