







































/**
 * Note: I am unhappy about having to shove the inbox-specific code in here
 * But template slots don't work when they're being set in a component that's rendered later than this one
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { AppStateStore } from '@/store/app-state-store';

@Component
export default class BasePageTitle extends Mixins(LocaleMixin) {
    @Prop({ default: false, type: Boolean }) readonly isHeader!: boolean;

    private appStore = getModule(AppStateStore);
    private featureStore = getModule(FeaturesStore);

    get classString(): string {
        if (!this.isLineLeaderEnroll) {
            return 'text-h4 font-weight-bold ma-0 mb-1';
        }
        return 'title-button ml-4';
    }

    get isLineLeaderEnroll(): boolean {
        return this.featureStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
    }

    get isShown(): boolean {
        return (this.isLineLeaderEnroll && this.isHeader) || !this.isLineLeaderEnroll;
    }
}
