import { AbstractRepository } from '@/repositories/abstract-repository';
import { ServiceProvider, ServiceProviderToken } from '@/service-providers/models/service-providers';

export class ServiceProvidersRepository extends AbstractRepository<ServiceProviderToken> {
    readonly endpoint = '/service-providers';

    public async getToken(providerId: ServiceProvider): Promise<ServiceProviderToken> {
        const response = await this.client.get(`${this.endpoint}/${providerId}/token`);
        return response.data;
    }
}
