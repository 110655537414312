















































import { Component, Mixins } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { VmodelShowMixin } from '@/core/vmodel-show-mixin';
import { HelpRepository } from '@/staff/repositories/help-repository';

const helpRepo = new HelpRepository();
@Component
export default class HelpPopup extends Mixins(LocaleMixin, VmodelShowMixin) {
    close() {
        this.modelValue = false;
    }

    openExternalHelp() {
        window.open(process.env.VUE_APP_HELP_URL, '_blank');
        this.close();
    }

    async openFreshdesk() {
        const url = await helpRepo.getFreshdeskUrl();
        window.open(url.url, '_blank');
        this.close();
    }
}
