import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import type { User } from '@/staff/models/user';
import type { LoginReturn, RefreshReturn } from '@/auth/auth';

// This is a Vuex module defined with Typescript decorators.
// We store an indication of if they're logged in or not, and also any user info we get.
// Mutations are the basic way of changing state in Vuex, which are basically setters.
// Getters take internal state and will optionally do some operation on them and then will return the value.
// You can also directly access the state (readonly) in the store, but getters give better encapsulation.
export interface AuthState {
    // authenticated means you were able to get a token, but your user isn't necessarily ready to access the system (eula, password reset, etc)
    authenticated: boolean;
    authRefreshToken?: string;
    authToken?: string;
    currentCustomer?: number;
    loggedIn: boolean;
    needsLegacyRedirect: boolean;
    userInfo?: User;
    overrideCountry: string | null;
    closedTime: number | null;
    instanceCount: number;
}

/**
 * Basic store for user auth info. Might need to be a lot more sophisticated to handle superusers, etc
 */
@Module({
    namespaced: true,
    name: StoreModuleTypes.AUTH,
    preserveState: true
})
export class AuthStore extends VuexModule implements AuthState {
    loggedIn = false;
    authenticated = false;
    authToken?: string = undefined;
    userInfo?: User = undefined;
    authRefreshToken?: string = undefined;
    needsLegacyRedirect = false;
    currentCustomer?: number = undefined;
    overrideCountry: string | null = null;
    closedTime: number | null = null;
    oauthState: string | null = null;
    oauthCodeVerifier: string | null = null;
    redirectUrl: string | null = null;
    needIdpLogout = false;
    instanceCount = 0;

    @Mutation
    incrementInstances(): void {
        this.instanceCount++;
    }

    @Mutation
    decrementInstances(): void {
        this.instanceCount--;
    }

    @Mutation
    storeClose(): void {
        // we only want to store close time if it's their last window/tab open
        if (this.instanceCount <= 0) {
            this.closedTime = (new Date()).getTime();
        }
    }

    @Mutation
    clearClose(): void {
        this.closedTime = null;
    }

    @Mutation
    storeAuthenticated(loginData: LoginReturn): void {
        this.authToken = loginData.token;
        this.authenticated = true;
        this.userInfo = loginData.userInfo;
        this.authRefreshToken = loginData.refreshToken;
        this.currentCustomer = this.userInfo?.client_id;
    }

    @Mutation
    storeRefresh(refreshData: RefreshReturn): void {
        this.authToken = refreshData.token;
        this.authRefreshToken = refreshData.refreshToken;
    }

    @Mutation
    storeUserInfo(userInfo: User): void {
        this.userInfo = userInfo;
    }

    @Mutation
    storeLoggedIn(): void {
        this.loggedIn = true;
        this.closedTime = null;
        this.instanceCount = 0;
    }

    @Mutation
    storeLoggedOut(): void {
        this.loggedIn = false;
        this.needIdpLogout = true;
        this.authenticated = false;
        this.userInfo = undefined;
        this.authToken = undefined;
        this.authRefreshToken = undefined;
        this.currentCustomer = undefined;
        this.overrideCountry = null;
        this.closedTime = null;
        this.instanceCount = 0;
    }

    @Mutation
    storeNeedsLegacyRedirect(): void {
        this.needsLegacyRedirect = true;
        this.loggedIn = false;
        this.authenticated = false;
        this.userInfo = undefined;
        this.authToken = undefined;
        this.authRefreshToken = undefined;
        this.currentCustomer = undefined;
        this.overrideCountry = null;
    }

    @Mutation
    clearNeedsLegacyRedirect(): void {
        this.needsLegacyRedirect = false;
    }

    @Mutation
    storeCurrentCustomerDb(currentCustomer: number): void {
        const isSuperUser = this.userInfo ? this.userInfo.is_superuser : false;
        if (this.loggedIn && isSuperUser) {
            this.currentCustomer = currentCustomer;
        }
    }

    @Mutation
    setCountry(country: string): void {
        this.overrideCountry = country;
    }

    @Mutation
    storeOidcSetup(setup: { state: string; verifier: string }): void {
        this.oauthState = setup.state;
        this.oauthCodeVerifier = setup.verifier;
    }

    @Mutation
    clearOidcSetup(): void {
        this.oauthState = null;
        this.oauthCodeVerifier = null;
    }

    @Mutation
    storeRedirectUrl(url: string | null): void {
        this.redirectUrl = url;
    }

    @Mutation
    clearIdpLogout() {
        this.needIdpLogout = false;
    }

    /**
     * Which database the user is currently in.
     * It won't let me use currentCustomerId as the name.
     */
    get currentCustomerDb(): number | undefined {
        return this.currentCustomer;
    }

    get id(): number | null | undefined {
        if (this.userInfo && this.userInfo.id !== undefined) {
            return this.userInfo.id;
        }
        return null;
    }

    /**
     * Whether the current user is a superuser and is in a database other than their original.
     */
    get isAssumingOtherCustomer(): boolean {
        const isSuperUser = this.userInfo ? this.userInfo.is_superuser : false;
        return this.loggedIn && isSuperUser && this.currentCustomer !== undefined && this.userInfo !== undefined &&
            this.userInfo?.client_id !== this.currentCustomer;
    }

    get isAuthenticated(): boolean {
        return this.authenticated;
    }

    get isCenterStaff(): boolean {
        return this.userInfo ? this.userInfo.center_id !== null : true;
    }

    get isCorporateUser(): boolean {
        return this.userInfo ? this.userInfo.org_id === 1 : false;
    }

    get isEnrollmentTeamMember(): boolean {
        return this.userInfo ? this.userInfo.is_on_enrollment_team : false;
    }

    get isLoggedIn(): boolean {
        return this.loggedIn;
    }

    get isNeedsLegacyRedirect(): boolean {
        return this.needsLegacyRedirect;
    }

    get isSuperuser(): boolean {
        return this.userInfo ? this.userInfo.is_superuser : false;
    }

    get isFullSuperuser(): boolean {
        return this.userInfo?.is_full_superuser ?? false;
    }

    get refreshToken(): string | undefined {
        return this.authRefreshToken;
    }

    get token(): string | undefined {
        return this.authToken;
    }

    get userInfoObject(): User | undefined {
        return this.userInfo;
    }

    get username(): string {
        return this.userInfo ? this.userInfo.username : '';
    }

    /**
     * Get the user's timezone.
     */
    get userTimeZone(): string {
        return this.userInfo?.timezone ? this.userInfo?.timezone : 'UTC';
    }

    get userCountry(): string {
        const country = this.userInfo?.country ?? 'us';
        return country.toLowerCase();
    }

    get userEmailAddress(): string {
        return this.userInfo ? this.userInfo.email_address : '';
    }

    get userFullName(): string {
        return this.userInfo ? `${this.userInfo.first_name} ${this.userInfo.last_name}` : '';
    }
}
