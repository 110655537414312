

































































































































import { BaseStatuses } from '@/constants/status-constants';
import FamilyPageHeader from '@/families/components/FamilyPageHeader.vue';
import { StatusStatisticsParameters } from '@/models/report/period-statistics';
import { AppStateStore } from '@/store/app-state-store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { PeriodStatisticsRepository } from '@/repositories/report/period-statistics-repository';
import FamilyFilterModalDrawer from '@/families/components/FamilyFilterRightDrawer.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { Org } from '@/models/organization/org';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';
import { getStatusName } from '@/families/status-utils';
import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import UploadImport from '@/families/components/UploadImport.vue';

const loadingState = getModule(LoadingStore);
const periodStatsRepo = new PeriodStatisticsRepository();
const appState = getModule(AppStateStore);
const authState = getModule(AuthStore, store);
const familiesFiltersStore = getModule(FamiliesFilterStore);
const featuresStore = getModule(FeaturesStore);
const statusStore = getModule(StatusesStore);
const layoutTabsStore = getModule(LayoutTabsStore, store);

@Component({
    components: {
        UploadImport,
        FamilyFilterModalDrawer,
        FamilyPageHeader
    }
})
export default class FamiliesHeader extends Mixins(LocaleMixin) {
    private activeCount = 0;
    private enrolledCount = 0;
    private includeLostOpp = false;
    private withdrawnCount = 0;
    private lostCount = 0;
    private familyFiltersDrawer = false;
    private loadingKey = 0;
    private statuses: Array<Status> = [];
    private updatedEvent = EventTypes.UPDATED; // For changes in children components, update stuff.
    private tabIndex = 0;
    private showImport = false;
    private isImportAdded = false;
    private uploadedEvent = EventTypes.UPLOADED;

    $refs!: {
        tabs: any;
    };

    created() {
        this.tabIndex = layoutTabsStore.currentTabIndex;
    }

    async mounted() {
        await statusStore.init();
        this.includeLostOpp = statusStore.storedStatuses.find(status => status.id === BaseStatuses.LOST_OPP) !== undefined;
        if (this.$router.currentRoute.name === 'families') {
            // No tab chosen, default to active families
            await this.$router.push({
                name: 'families-filtered',
                params: { statusFilter: 'active' }
            });
        }

        this.statuses = statusStore.statuses;

        const outerTab = layoutTabsStore.tabs[this.tabIndex] as LayoutTab;
        outerTab.props.tabTitle = 'Active Families';
        layoutTabsStore.updateTab(outerTab);
    }

    get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    get isCustom() {
        return familiesFiltersStore.isCustom;
    }

    get messageMode(): boolean {
        return appState.messageMode;
    }

    set messageMode(flag: boolean) {
        appState.setFamilyMessageMode(flag);
    }

    get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get withdrawnName() {
        return getStatusName(this.statuses, BaseStatuses.WITHDRAWN);
    }

    get enrolledName() {
        return getStatusName(this.statuses, BaseStatuses.ENROLLED);
    }

    get lostOppName() {
        return getStatusName(this.statuses, BaseStatuses.LOST_OPP);
    }

    get isSuperuser() {
        return authState.isSuperuser;
    }

    @Watch('org', {
        deep: true,
        immediate: true
    })
    private async fetchData() {
        this.messageMode = false;
        if (!this.org) {
            loadingState.loadingDecrement('statusCount');
            // Only fetch counts once there's a center
            return;
        }
        const params = { transitions_only: false } as StatusStatisticsParameters;
        params.org_ids = [this.org.id];
        params.current_counts_only = true;
        await periodStatsRepo.queryStatuses(params);

        this.activeCount = 0;
        this.enrolledCount = 0;
        this.withdrawnCount = 0;
        this.lostCount = 0;
        const statusCounts = periodStatsRepo.getGroupedByCount();

        for (const statusCount of statusCounts) {
            switch (statusCount.id) {
                case BaseStatuses.ENROLLED:
                    this.enrolledCount = statusCount.total;
                    break;
                case BaseStatuses.WITHDRAWN:
                    this.withdrawnCount = statusCount.total;
                    break;
                case BaseStatuses.LOST_OPP:
                    this.lostCount = statusCount.total;
                    break;
                default:
                    if (statusStore.activeStatusIds.includes(statusCount.id)) {
                        this.activeCount += statusCount.total;
                    }
                    break;
            }
        }

        this.loadingKey++;

        loadingState.loadingDecrement('statusCount');
    }

    @Watch('isCustom')
    private updateFilters() {
        if (this.isCustom) {
            if (this.$route.params && this.$route.params.statusFilter !== 'custom') {
                this.$router.push({
                    name: 'families-filtered',
                    params: {
                        statusFilter: 'custom'
                    }
                });
            }
        }
    }

    private onChange() {
        const outerTab = layoutTabsStore.tabs[this.tabIndex] as LayoutTab;
        outerTab.props.routeName = this.$route.name ?? 'Families';
        outerTab.props.routeParams = this.$route.params;

        let tabTitle = 'Active Families';
        switch (this.$route.params.statusFilter) {
            case 'enrolled':
                tabTitle = this.enrolledName;
                break;
            case 'withdrawn':
                tabTitle = this.withdrawnName;
                break;
            case 'lost':
                tabTitle = this.lostOppName;
                break;
        }

        outerTab.props.tabTitle = tabTitle;
        layoutTabsStore.updateTab(outerTab);
    }

    uploadImport() {
        this.showImport = true;
    }
}
