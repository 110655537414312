
































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { EmailTemplateMapper } from '@/communications/mappers/email-template-mapper';
import { Email } from '@/communications/messages/models/email';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { openFile, encodeFile } from '@/core/file-utils';
import { DataTableHeader } from 'vuetify';
import { ApiParameters } from '@/repositories/abstract-repository';
import store from '@/store';
import BaseClose from '@/components/base/BaseClose.vue';

const attachmentsRepo = new EmailAttachmentsRepository();
const templatesRepo = new EmailTemplatesRepository();
const templateMapper = new EmailTemplateMapper();
const loadingState = getModule(LoadingStore, store);
@Component({
    components: { BaseClose }
})
export default class EmailTemplateAttachments extends Mixins(LocaleMixin) {
    @Prop() template!: MessageTemplate;
    @Prop() email!: Email;
    @Prop() viewOnly!: boolean;
    @Prop() orgId!: number | null;
    @Prop() brandId!: number | null;
    @Prop() locationGroupId!: number | null;

    private localTemplate: MessageTemplate | null = null;
    private attachments: Array<EmailAttachment> = [];
    private showAddDialog = false;
    private file: File | null = null;
    private totalSize = 0;
    private maxSize = 10 * 1024 * 1024; // 10 MB max
    private loadingKey = EmailTemplateAttachments.name;
    private listLoadingKey = 'listAttachments';
    private tableHeaders: Array<DataTableHeader> = [
        { text: 'File Name', value: 'filename' },
        { text: 'File Type', value: 'mime_type' },
        { text: 'File Size', value: 'file_size' }
    ];

    private items: Array<EmailAttachment> = [];

    get templateOrgHash() {
        return (this.orgId ?? '0') + '-' + (this.brandId ?? '0') + '-' + (this.locationGroupId ?? '0');
    }

    @Watch('templateOrgHash')
    async templateOrgChanged() {
        await this.loadAllAttachments();
        const attachmentsCount = this.attachments.length;
        this.attachments = this.attachments.filter((attachment) => {
            return this.items.map((avail) => {
                return avail.id;
            }).includes(attachment.id);
        });
        if (attachmentsCount > this.attachments.length) {
            await this.$swal({
                icon: 'warning',
                text: 'Some attachments were removed because of the template location change.'
            });
        }
        this.$emit('attachments-updated', this.attachments);
    }

    async mounted() {
        this.localTemplate = this.template;
        await this.updateAttachments();
        await this.loadAllAttachments();
    }

    private async loadAllAttachments() {
        loadingState.loadingIncrement(this.loadingKey);

        const params: ApiParameters = {
            'no-pagination': 1
        };
        if (this.brandId) {
            params.brand_id = this.brandId;
        } else if (this.locationGroupId) {
            params.location_group_id = this.locationGroupId;
        } else {
            params.orgId = this.orgId ?? 1;
            params.include_child_orgs = 1;
        }
        const response = await attachmentsRepo.get(null, params);
        this.items = response.entities;

        loadingState.loadingDecrement(this.loadingKey);
    }

    // Check that total attachment size is not too large
    private checkSize() {
        if (!this.file) {
            return true;
        }

        return this.file.size + this.totalSize <= this.maxSize;
    }

    // Open the attachment for the user
    private async openAttachment(attachment: EmailAttachment) {
        const file = await attachmentsRepo.getFile(attachment);
        openFile(file, attachment.filename, attachment.mime_type);
    }

    // Remove the attachment from the template.
    private async removeAttachment(attachment: EmailAttachment) {
        const response = await this.$swal({
            text: `Are you sure you want to remove the attachment ${attachment.filename} from this template?`,
            showConfirmButton: true,
            showCancelButton: true
        });

        if (response.isConfirmed && this.localTemplate) {
            const templateUpdate = templateMapper.toUpdateDto(this.localTemplate);

            if (this.attachments.length === 0) {
                templateUpdate.attachments = null;
            } else if (templateUpdate.attachments) {
                const newAttachmentIds: Array<number> = [];
                // Remove the attachment from the template
                for (const attachmentId of templateUpdate.attachments) {
                    if (attachmentId !== attachment.id) {
                        newAttachmentIds.push(attachmentId);
                    }
                }

                templateUpdate.attachments = newAttachmentIds;
            }

            this.localTemplate = await templatesRepo.update(templateUpdate);
            await this.updateAttachments();
        }
    }

    // Update the list of attachments for this template.
    private async updateAttachments() {
        this.attachments = [];
        this.totalSize = 0;

        if (!this.localTemplate) {
            return;
        }

        loadingState.loadingIncrement(this.listLoadingKey);

        for (const attachmentLink of this.localTemplate.attachments ?? []) {
            const attachment = await attachmentsRepo.getOne(attachmentLink.id);
            if (!this.attachments.includes(attachment)) {
                this.totalSize += attachment.file_size;
                this.attachments.push(attachment);
            }
        }

        loadingState.loadingDecrement(this.listLoadingKey);
        await this.loadAllAttachments();
        this.$emit('attachments-updated', this.attachments);
    }

    private async addExistingAttachment(attachment: EmailAttachment) {
        if (!this.localTemplate) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        const templateUpdate = templateMapper.toUpdateDto(this.localTemplate);
        if (!templateUpdate.attachments) {
            templateUpdate.attachments = [];
        }
        if (!templateUpdate.attachments.includes(attachment.id)) {
            templateUpdate.attachments.push(attachment.id);
        }

        this.localTemplate = await templatesRepo.update(templateUpdate);
        this.showAddDialog = false;

        loadingState.loadingDecrement(this.loadingKey);

        await this.updateAttachments();
    }

    // Upload a new attachment.
    private async upload() {
        if (!this.file || !this.file.name || !this.localTemplate) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);

        if (!this.checkSize()) {
            await this.$swal({
                text: `Total size of all attachments for a template may not exceed ${this.maxSize / 1024 / 1024}MB.`,
                icon: 'error'
            });
        } else {
            const fileContents = await encodeFile(this.file);
            const attachment = await attachmentsRepo.create({
                filename: this.file.name,
                file: fileContents,
                org: this.template.org.id,
                is_hidden: true
            });

            const templateUpdate = templateMapper.toUpdateDto(this.localTemplate);
            if (!templateUpdate.attachments) {
                templateUpdate.attachments = [];
            }
            if (!templateUpdate.attachments.includes(attachment.id)) {
                templateUpdate.attachments.push(attachment.id);
            }

            this.localTemplate = await templatesRepo.update(templateUpdate);
            this.showAddDialog = false;

            await this.updateAttachments();
        }

        this.file = null;
        loadingState.loadingDecrement(this.loadingKey);
    }
}
