













































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { getModule } from 'vuex-module-decorators';
import { Brand, BrandDto } from '@/organizations/brands/models/brand-models';
import { LoadingStore } from '@/store/loading-store';
import { BrandMapper } from '@/organizations/brands/models/brand-mapper';
import { BrandsRepository } from '@/organizations/brands/repositories/brands-repository';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import UploadLogo from '@/automation/landing-pages/components/UploadLogo.vue';
import { Logo } from '@/logo/models/logo';
import { LogoMapper } from '@/logo/mappers/logo-mapper';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const brandMapper = new BrandMapper();
const brandsRepo = new BrandsRepository();
const featuresStore = getModule(FeaturesStore);
const brandsState = getModule(BrandsStore);
const logoMapper = new LogoMapper();

@Component({
    components: {
        BaseClose,
        UploadLogo,
        SaveButton
    }
})
export default class ManageBrand extends Mixins(LocaleMixin, BasicValidationMixin) {
// v-model whether we should show it
@Prop({ default: false }) readonly value!: boolean;
@Prop({ required: true }) readonly brand!: Brand | null;
@Ref('form') readonly form!: VForm;

    private loadingKey = 'manageBrand';
    private validForm = false;
    private showSnack = false;
    private snackText = '';
    private logo: Logo | null = null;
    private logoExists = false;
    private showUploadDialog = false;
    private brandDto = new BrandDto();

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get isAdd() {
        return this.brand === null;
    }

    get isBrandEmailsFeatureEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.BRAND_EMAILS);
    }

    // Actions to perform after uploading the logo
    private logoUploaded(logo: Logo) {
        this.logo = logo;
        this.showUploadDialog = false;
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);
        await featuresStore.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('modelValue')
    async activationChanged(flag: boolean) {
        if (flag) {
            await brandsState.init();
            if (this.brand) {
                loadingState.loadingIncrement(this.loadingKey);
                this.brandDto = brandMapper.toUpdateDto(this.brand);
                this.logo = this.brand.logo ? await brandsRepo.getLogo(this.brand.id) as Logo : null;
                this.logoExists = !!this.logo;
                this.$nextTick(() => {
                    // Validate form when we popup the modal.
                    this.form.validate();
                });
                loadingState.loadingDecrement(this.loadingKey);
            } else {
                this.brandDto = new BrandDto();
                this.logo = null;
        }
    }
}

private handleClose() {
    this.logoExists = false;
    this.form.reset();
    this.modelValue = false;
    this.logo = null;
}

private async submit() {
    loadingState.loadingIncrement(this.loadingKey);
    if (this.brand === null) {
        let newBrand: Brand = await brandsRepo.post(this.brandDto);
        if (this.logo) {
            // Send PUT to marketing/brands/id/logo
            await brandsRepo.uploadLogo(newBrand.id, logoMapper.toCreateDto(this.logo));
            newBrand = await brandsRepo.getOne(newBrand.id);
        }
        // Get brands store
        const brandsArray: Array<Brand> = brandsState.stored;
        brandsState.storeBrands(new Array<Brand>());
        // Add new brand to that store
        brandsArray.push(newBrand);
        // Update store
        brandsState.storeBrands(brandsArray);

        this.snackText = 'Brand Added';
        this.showSnack = true;
    } else {
        // Send PUT, or DELETE to marketing/brands/id/logo
        if (this.logoExists && (this.logo === null)) {
            await brandsRepo.deleteLogo(this.brand.id);
        } else if (this.logo) {
            await brandsRepo.uploadLogo(this.brand.id, logoMapper.toCreateDto(this.logo));
        }
        const updatedBrand: Brand = await brandsRepo.putOne(this.brand.id, this.brandDto);

        // Get brands store
        const brandsArray: Array<Brand> = brandsState.stored;
        brandsState.storeBrands(new Array<Brand>());
        // Update brand
        for (let i = 0; i < brandsArray.length; i++) {
            if (brandsArray[i].id === updatedBrand.id) {
                brandsArray[i] = updatedBrand;
            }
        }

        // Update store
        brandsState.storeBrands(brandsArray);

        this.snackText = 'Brand Updated';
        this.showSnack = true;
    }
    loadingState.loadingDecrement(this.loadingKey);
    this.handleClose();
}

// Delete the brand's logo
private async deleteLogo() {
    const result = await this.$swal({
        text: 'Are you sure you want to remove the logo for this brand?',
        showConfirmButton: true,
        showCancelButton: true
    });

    if (result.isConfirmed) {
        this.logo = null;
    }
}
}
