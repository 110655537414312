import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { FamiliesFilter, FamiliesFilterDtoInterface } from '@/filters/models/families-filter';
import { HateoasLink, ValuedLink } from '@/models/base';
import { CustomFieldsTypes, CustomFieldWithOption } from '@/crm-types/custom-fields/custom-fields-types';

export class FamiliesFilterMapper implements AbstractMapper<FamiliesFilter> {
    toUpdateDto(filter: FamiliesFilter): any {
        return {
            name: filter.name,
            is_anonymous: filter.is_anonymous,
            organization: filter.organization.id,
            include_type: filter.include_type.id,
            statuses: FamiliesFilterMapper.linksToIds(filter.statuses),
            age_groups: FamiliesFilterMapper.linksToIds(filter.age_groups),
            location_group: filter.location_group ? filter.location_group.id : null,
            include_aged_out: filter.include_aged_out,
            lead_date_start: filter.lead_date_start,
            lead_date_end: filter.lead_date_end,
            status_date_start: filter.status_date_start,
            status_date_end: filter.status_date_end,
            age_in_months_start: filter.age_in_months_start,
            age_in_months_end: filter.age_in_months_end,
            birth_date_start: filter.birth_date_start,
            birth_date_end: filter.birth_date_end,
            last_contacted_date_start: filter.last_contacted_date_start,
            last_contacted_date_end: filter.last_contacted_date_end,
            expected_start_date_start: filter.expected_start_date_start,
            expected_start_date_end: filter.expected_start_date_end,
            actual_start_date_start: filter.actual_start_date_start,
            actual_start_date_end: filter.actual_start_date_end,
            inquiry_types: FamiliesFilterMapper.linksToIds(filter.inquiry_types),
            lead_sources: FamiliesFilterMapper.linksToIds(filter.lead_sources),
            marketing_campaigns: FamiliesFilterMapper.linksToIds(filter.marketing_campaigns),
            special_employers: FamiliesFilterMapper.linksToIds(filter.special_employers),
            child_gender: filter.child_gender ? filter.child_gender.id : null,
            child_birth_months: FamiliesFilterMapper.linksToIds(filter.child_birth_months),
            expected_class_groups: FamiliesFilterMapper.linksToIds(filter.expected_class_groups),
            classrooms: FamiliesFilterMapper.linksToIds(filter.classrooms),
            city: filter.city,
            state: filter.state,
            postal_code: filter.postal_code,
            lead_type_ones: FamiliesFilterMapper.linksToIds(filter.lead_type_ones),
            lead_type_twos: FamiliesFilterMapper.linksToIds(filter.lead_type_twos),
            lead_type_threes: FamiliesFilterMapper.linksToIds(filter.lead_type_threes),
            lead_type_fours: FamiliesFilterMapper.linksToIds(filter.lead_type_fours),
            child_type_ones: FamiliesFilterMapper.linksToIds(filter.child_type_ones),
            child_type_twos: FamiliesFilterMapper.linksToIds(filter.child_type_twos),
            child_type_threes: FamiliesFilterMapper.linksToIds(filter.child_type_threes),
            child_type_fours: FamiliesFilterMapper.linksToIds(filter.child_type_fours),
            lost_to_competitors: FamiliesFilterMapper.linksToIds(filter.lost_to_competitors),
            lost_opp_reasons: FamiliesFilterMapper.linksToIds(filter.lost_opp_reasons),
            withdrawn_reasons: FamiliesFilterMapper.linksToIds(filter.withdrawn_reasons),
            temp_leave_reasons: FamiliesFilterMapper.linksToIds(filter.temp_leave_reasons),
            enrolled_reasons: FamiliesFilterMapper.linksToIds(filter.enrolled_reasons),
            wait_list_reasons: FamiliesFilterMapper.linksToIds(filter.wait_list_reasons),
            wait_list_types: FamiliesFilterMapper.linksToIds(filter.wait_list_types),
            wait_list_priorities: FamiliesFilterMapper.linksToIds(filter.wait_list_priorities),
            event_results: FamiliesFilterMapper.linksToIds(filter.event_results),
            task_types: FamiliesFilterMapper.linksToIds(filter.task_types),
            event_date_start: filter.event_date_start,
            event_date_end: filter.event_date_end,
            task_date_start: filter.task_date_start,
            task_date_end: filter.task_date_end,
            guardian_custom_values: [],
            child_custom_values: []
        };
    }

    toCreateDto(entity: FamiliesFilter): FamiliesFilterDtoInterface {
        return this.toUpdateDto(entity);
    }

    private static linksToIds(links: Array<HateoasLink>): Array<number> {
        return links.map((link) => {
            return link.id;
        });
    }

    mapCustomValues(fields: Array<CustomFieldWithOption>, storedFilter: Array<ValuedLink>, newFilter: Array<number | Array<number>>): void {
        fields.forEach(item => {
            if (item.type.id === CustomFieldsTypes.MULTI_SELECT) {
                // this is a multi-select item
                const values: Array<number> = storedFilter.filter(value => item.options.map(option => option.id).includes(value.id)).map(value => value.id);
                if (values) {
                    newFilter[item.order] = values;
                }
            } else {
                // this is a single-select item
                storedFilter.map(value => {
                    if (item.options.map(option => option.id).includes(value.id)) {
                        newFilter[item.order] = value.id;
                    }
                });
            }
        });

    }
}
