





















import { DashboardGaugeMixin } from '@/dashboards/dashboard-gauge-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { DashboardStore } from '@/store/dashboard-store';
import { AppStateStore } from '@/store/app-state-store';
import Highcharts from 'highcharts';
import {
    getGaugeChartOptions,
    getGaugeStopColors,
    getSolidGaugeSeriesOptions
} from '@/charts/chart-utils';
import { LoadingStore } from '@/store/loading-store';
import { get1DayTourCompletesPct } from '@/dashboards/corp-dashboard-stats';
import { getPreviousDateFilter } from '@/date-time/date-filter-utils';
import GaugeComparison from '@/dashboards/components/GaugeComparison.vue';
import cloneDeep from 'lodash/cloneDeep';

const dashboardState = getModule(DashboardStore);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
@Component({
    components: { GaugeComparison }
})
export default class ToursCompletedGaugeGraph extends Mixins(DashboardGaugeMixin, LocaleMixin) {
    @Prop({ type: String }) readonly graphId!: undefined;

    private isMounted = false;
    private loadingKey = ToursCompletedGaugeGraph.name;

    private stops = [0.0, 0.43, 0.55, 0.63];
   chartOptions: Highcharts.Options = Highcharts.merge(cloneDeep(getGaugeChartOptions()), {
        spacingRight: 0,
        spacingLeft: 0,
        yAxis: {
            stops: getGaugeStopColors(this.stops)
        },
        exporting: {
            chartOptions: {
                title: {
                    text: 'Families Completing Tours Within 24 Hours'
                }
            }
        }
    });

    private diffPct = 0;

    get org() {
        return appState.storedCurrentOrg;
    }

    get dateRange() {
        return dashboardState.storedDateFilter;
    }

    async created() {
        await this.updateChart();
    }

    mounted() {
        this.isMounted = true;
    }

    @Watch('org', { deep: true })
    async orgChanged() {
        await this.updateChart();
    }

    @Watch('dateRange', { deep: true })
    async dateRangeChanged() {
        await this.updateChart();
    }

    private async updateChart() {
        if (!this.org) {
            return;
        }
        if (!this.dateRange.startDate || !this.dateRange.endDate) {
            return;
        }
        const org = this.org.id;
        loadingState.loadingIncrement(this.loadingKey);
        loadingState.loadingIncrement(this.loadingKey);
        let mainPct = 0;
        let prevPct = 0;
        get1DayTourCompletesPct(this.dateRange, org).then((value) => {
            mainPct = value;
            this.chartOptions.series = [getSolidGaugeSeriesOptions('Rate of Tour Completions within 1 day', mainPct)];
            this.diffPct = mainPct - prevPct;
            loadingState.loadingDecrement(this.loadingKey);
            this.$emit('updated', mainPct);
            (this.$refs.chart as any).chart.reflow();
        });
        get1DayTourCompletesPct(getPreviousDateFilter(this.dateRange), org).then((value) => {
            prevPct = value;
            this.diffPct = mainPct - prevPct;
            loadingState.loadingDecrement(this.loadingKey);
            (this.$refs.chart as any).chart.reflow();
        });
    }
}
