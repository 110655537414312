import { render, staticRenderFns } from "./NylasEmailRevoke.vue?vue&type=template&id=05171fca&scoped=true&"
import script from "./NylasEmailRevoke.vue?vue&type=script&lang=ts&"
export * from "./NylasEmailRevoke.vue?vue&type=script&lang=ts&"
import style0 from "./NylasEmailRevoke.vue?vue&type=style&index=0&id=05171fca&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05171fca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardActions,VCardText,VCardTitle,VSpacer})
