import { GroupCommDisplayStats, GroupEmailStats, GroupTextStats } from '@/communications/messages/models/message';
import {
    BASE_COLOR,
    BASE_DARK_BLUE,
    MOMENT_PATH_BLUE,
    FONT_FAMILY
} from '@/core/style-utils';
import { FeaturesStore } from '@/features/features-store';
import Highcharts, {
    ColorType
} from 'highcharts';
import { Count } from '@/models/report/count';
import {
    CountByPeriods,
    OrganizationCounts,
    OrgPeriodCounts,
    OrgPeriodValues,
    Period,
    PeriodCounts,
    PeriodValue
} from '@/models/report/period-statistics';
import { PeriodType } from '@/constants/stat-constants';
import { formatDateForLocale } from '@/locales/locale-helpers';
import { formatMonth, formatWeek, formatYear } from '@/date-time/date-time-utils';
import { getModule } from 'vuex-module-decorators';
import { FeatureConstants } from '@/features/feature-constants';

const featureState = getModule(FeaturesStore);

/**
 * Create a 10 shade monochromatic array of.
 *
 * @param base        Base color in hex.
 * @param singleColor 1 color return...
 * @param reverse     Reverse the order of colors.
 * @param count        how many steps needed
 */
export function getMonochromeColors(base: string, singleColor = false, reverse = false, count = 10): Array<Highcharts.ColorType> {
    const colors: Array<Highcharts.ColorType> = [];

    for (let i = 0; i < count; i += 1) {
        if (singleColor) {
            // It's so monochromatic, it's one color 10 times over!
            colors.push(Highcharts.color(base).get());
        } else {
            // Start out with a darkened base color (negative brighten), and end
            // up with a much brighter color
            colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
        }
    }

    if (reverse) {
        colors.reverse();
    }

    return colors;
}

/**
 * Create an array representing a gradient between the lightest and darkest colors given.
 *
 * @param lightestColor The lightest color desired; returned first
 * @param darkestColor  The darkest color desired; returned last
 * @param count
 */
export function getMonochromeColorsWithLimits(lightestColor: string, darkestColor: string, count: number): Array<Highcharts.ColorType> {
    const colors: Array<Highcharts.ColorType> = [];

    // Start out with the lower limit color, and end with the upper limit
    for (let i = 0; i < count; i += 1) {
        colors.push(Highcharts.color(lightestColor).tweenTo(Highcharts.color(darkestColor), i / (count - 1)));
    }

    return colors;
}

/**
 * @param counts
 */
export function countsToGraphData(counts: Array<Count>): Array<Highcharts.PointOptionsObject> {
    return counts.map((count: Count) => {
        return {
            name: count.name,
            y: count.total
        };
    });
}

/**
 * @param periods
 * @param periodType
 * @param locale
 */
export function periodsToXAxis(periods: Period[], periodType: PeriodType, locale = 'en-US'): string[] {
    return periods.map((period: Period) => {
        switch (periodType) {
            case 4:
                return formatYear(period.start_date);
            case 3:
                return formatMonth(period.start_date);
            case 2:
                return 'Week of ' + formatWeek(period.start_date);
            default:
                return formatDateForLocale(locale, period.start_date);
        }
    });
}

/**
 * @param counts
 * @param periodType
 * @param locale
 */
export function countPeriodsToXAxis(counts: CountByPeriods[], periodType: PeriodType, locale = 'en-US'): string[] {
    if (!counts.length) {
        return [];
    }
    return periodsToXAxis(counts[0].counts, periodType, locale);
}

interface SeriesLineAreaBarOptions {
    data: number[];
    name: string;
    color: Highcharts.ColorType;
}

/**
 * @param counts
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 */
function countPeriodsToGraphSeries(
    counts: CountByPeriods[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false
): SeriesLineAreaBarOptions[] {
    const colors = getMonochromeColors(baseColor, singleColor, reverseColors);
    let colorIter = 0;

    return counts.map((countByPeriods) => {
        return {
            name: countByPeriods.name,
            data: countByPeriods.counts.map((countByPeriod) => {
                return countByPeriod.total;
            }),
            color: colors[colorIter++]
        };
    });
}

/**
 * @param valuesCollection
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 */
function orgPeriodsValuesToGraphSeries(
    valuesCollection: OrgPeriodValues[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false
): SeriesLineAreaBarOptions[] {
    const colors = getMonochromeColors(baseColor, singleColor, reverseColors);
    let colorIter = 0;

    return valuesCollection.map((values: OrgPeriodValues) => {
        return {
            name: values.name,
            data: values.periods.map((periodValues: PeriodValue) => {
                return periodValues.value;
            }),
            color: colors[colorIter++]
        };
    });
}

/**
 * @param valuesCollection
 * @param baseColor
 * @param singleColor
 * @param reverseColor
 */
export function orgPeriodsValuesToAreaGraphSeries(
    valuesCollection: OrgPeriodValues[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColor = false
): Highcharts.SeriesAreaOptions[] {
    return orgPeriodsValuesToGraphSeries(valuesCollection, baseColor, singleColor, reverseColor).map((options) => {
        return {
            ...options,
            type: 'area'
        };
    });
}

/**
 * @param valuesCollection
 * @param baseColor
 */
export function orgPeriodsValuesToLineGraphSeries(
    valuesCollection: OrgPeriodValues[],
    baseColor = '#0060AF'
): Highcharts.SeriesLineOptions[] {
    return orgPeriodsValuesToGraphSeries(valuesCollection, baseColor).map((options) => {
        return {
            ...options,
            type: 'line'
        };
    });
}

/**
 * @param counts
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 */
export function countPeriodsToAreaGraphSeries(
    counts: CountByPeriods[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false
): Highcharts.SeriesAreaOptions[] {
    return countPeriodsToGraphSeries(counts, baseColor, singleColor, reverseColors).map((options) => {
        return {
            ...options,
            type: 'area'
        };
    });
}

/**
 * @param counts
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 */
export function countPeriodsToLineGraphSeries(
    counts: CountByPeriods[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false
): Highcharts.SeriesLineOptions[] {
    return countPeriodsToGraphSeries(counts, baseColor, singleColor, reverseColors).map((options) => {
        return {
            ...options,
            type: 'line'
        };
    });
}

/**
 * @param orgPeriodCountsCollection
 * @param callback
 */
export function orgPeriodCountsToValues(
    orgPeriodCountsCollection: OrgPeriodCounts[],
    callback: (counts: Count[]) => number
): OrgPeriodValues[] {
    return orgPeriodCountsCollection.map((orgPeriodCounts: OrgPeriodCounts) => {
        return {
            id: orgPeriodCounts.id,
            name: orgPeriodCounts.name,
            periods: orgPeriodCounts.periods.map((periodCounts: PeriodCounts) => {
                return {
                    start_date: periodCounts.start_date!,
                    end_date: periodCounts.end_date!,
                    value: callback(periodCounts.counts)
                };
            })
        };
    });
}

/**
 * @param orgPeriodValuesCollection
 * @param periodType
 * @param locale
 */
export function orgPeriodValuesToXAxis(
    orgPeriodValuesCollection: OrgPeriodValues[],
    periodType: PeriodType,
    locale = 'en-US'
): string[] {
    if (!orgPeriodValuesCollection.length) {
        return [];
    }
    return periodsToXAxis(orgPeriodValuesCollection[0].periods, periodType, locale);
}

/**
 * @param counts
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 */
export function countPeriodsToBarGraphSeries(
    counts: CountByPeriods[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false
): Highcharts.SeriesBarOptions[] {
    return countPeriodsToGraphSeries(counts, baseColor, singleColor, reverseColors).map((options) => {
        return {
            ...options,
            type: 'bar'
        };
    });
}

/**
 * @param name
 * @param data
 */
export function getSolidGaugeSeriesOptions(name: string, data: number): Highcharts.SeriesSolidgaugeOptions {
    return {
        name: name,
        data: [data],
        type: 'solidgauge'
    };
}

/**
 * Take an array of Organization Counts and extract the name for the x-axis.
 *
 * @param orgCountsCollection
 */
export function orgCountNamesToXAxis(orgCountsCollection: OrganizationCounts[]): string[] {
    if (!orgCountsCollection.length) {
        return [];
    }

    const xAxisNames: string[] = [];

    orgCountsCollection.forEach((orgCounts: OrganizationCounts) => {
        xAxisNames.push(orgCounts.name);
    });

    return xAxisNames;
}

/**
 * @param orgCountsCollection
 * @param baseColor
 * @param singleColor
 * @param reverseColors
 * @param isColumn
 */
export function countsFromOrgCountsGroupedByNameToNamedSeries(
    orgCountsCollection: OrganizationCounts[],
    baseColor = '#0060AF',
    singleColor = false,
    reverseColors = false,
    isColumn = false
): Array<any> {
    const namedSeriesCollection: Array<any> = [];
    const colors = getMonochromeColors(baseColor, singleColor, reverseColors);
    const colorCount = colors.length;

    // OK - this is a silly hack to make PHPStorm and the linter agree about let vs const.
    let colorIter: number;
    colorIter = 0;

    orgCountsCollection.forEach((orgCounts: OrganizationCounts) => {
        orgCounts.counts.forEach((orgCount: Count) => {
            let namedSeries = namedSeriesCollection.find(({ name }) => name === orgCount.name);

            if (namedSeries === undefined) {
                namedSeries = {
                    type: isColumn ? 'column' : 'bar',
                    name: orgCount.name,
                    data: [],
                    color: colors[colorIter],
                    dataLabels: {
                        enabled: isColumn,
                        style: {
                            fontFamily: FONT_FAMILY,
                            fontWeight: 'bold'
                        }
                    }
                };

                namedSeriesCollection.push(namedSeries);

                if (colorIter + 1 >= colorCount) {
                    colorIter = 0;
                } else {
                    colorIter++;
                }
            }

            namedSeries.data.push(orgCount.total);
        });
    });

    return namedSeriesCollection;
}

export const baseCorpGaugeGraphOptions: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
        height: '50%',
        spacingBottom: 0
    },
    credits: {
        enabled: false
    },
    exporting: {
        allowHTML: true,
        buttons: {
            contextButton: {
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
            }
        }
    },
    title: {
        text: undefined
    },
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: [{
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }]
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}%</b><br/>',
        valueDecimals: 2,
        outside: true,
        distance: 40
    },
    // the value axis
    yAxis: {
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        min: 0,
        max: 100,
        tickAmount: 0,
        title: {
            text: undefined
        },
        labels: {
            enabled: false
        }
    },
    plotOptions: {
        solidgauge: {
            allowPointSelect: true,
            dataLabels: {
                format: '{y:.0f}%',
                borderWidth: 0,
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'normal'
                },
                verticalAlign: 'bottom',
                useHTML: true,
                enabled: true,
                y: 10
            }
        },
        series: {
            allowPointSelect: true
        }
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 100
            },
            chartOptions: {
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            style: {
                                fontSize: '0.8rem'
                            }
                        }
                    }
                }
            }
        }, {
            condition: {
                maxWidth: 200
            },
            chartOptions: {
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            style: {
                                fontSize: '1.0rem'
                            }
                        }
                    }
                }
            }
        }]
    },
    series: []
};

export const conversionSuccessGraphOptions: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
        height: '36%',
        spacingBottom: 0
    },
    credits: {
        enabled: false
    },
    exporting: {
        allowHTML: true,
        buttons: {
            contextButton: {
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
            }
        }
    },
    title: {
        text: undefined
    },
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: [{
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }]
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}%</b><br/>',
        valueDecimals: 2,
        outside: true,
        distance: 40
    },
    // the value axis
    yAxis: {
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        min: 0,
        max: 100,
        tickAmount: 0,
        title: {
            text: undefined
        },
        labels: {
            enabled: false
        }
    },
    plotOptions: {
        solidgauge: {
            allowPointSelect: true,
            dataLabels: {
                format: '{y:.0f}%',
                borderWidth: 0,
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'normal'
                },
                verticalAlign: 'bottom',
                useHTML: true,
                enabled: true,
                y: 10
            }
        },
        series: {
            allowPointSelect: true
        }
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 100
            },
            chartOptions: {
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            style: {
                                fontSize: '0.8rem'
                            }
                        }
                    }
                }
            }
        }, {
            condition: {
                maxWidth: 200
            },
            chartOptions: {
                plotOptions: {
                    solidgauge: {
                        dataLabels: {
                            style: {
                                fontSize: '1.0rem'
                            }
                        }
                    }
                }
            }
        }]
    },
    series: []
};

export const MpBaseCorpGaugeGraphOptions: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
        height: '50%',
        spacingBottom: 0
    },
    credits: {
        enabled: false
    },
    title: {
        text: undefined
    },
    exporting: {
        allowHTML: true,
        buttons: {
            contextButton: {
                enabled: false
            },
            exportButton: {
                text: '<i class="fa-duotone fa-ellipsis-vertical"></i>',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                theme: {
                    fill: 'rgba(255,255,255,0)'
                }
            }
        },
        chartOptions: {
            title: {
                text: undefined
            },
            plotOptions: {
                solidgauge: {
                    rounded: true,
                    innerRadius: '90%',
                    dataLabels: [
                        {
                            enabled: true,
                            format: '{y:.0f}%',
                            borderWidth: 0,
                            style: {
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                color: '#46485F'
                            },
                            y: -30,
                            align: 'center'
                        }
                    ]
                }
            }
        }
    },
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: [{
            backgroundColor: '#EEE',
            innerRadius: '90%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
        }]
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}%</b><br/>',
        valueDecimals: 2,
        outside: true,
        distance: 40
    },
    // the value axis
    yAxis: {
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        min: 0,
        max: 100,
        tickAmount: 0,
        title: {
            text: undefined
        },
        labels: {
            enabled: false
        }
    },
    plotOptions: {
        solidgauge: {
            allowPointSelect: true,
            rounded: true,
            innerRadius: '90%',
            dataLabels: {
                format: '{y:.0f}%',
                borderWidth: 0,
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#46485F'
                },
                verticalAlign: 'bottom',
                useHTML: true,
                enabled: true,
                y: -40
            }
        },
        series: {
            allowPointSelect: true
        }
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 228
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '1rem'
                                },
                                y: -25
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 225
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '0.8rem'
                                },
                                y: -40
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 209
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '0.8rem'
                                },
                                y: -35
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 192
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '0.8rem'
                                },
                                y: -30
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 185
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '0.8rem'
                                },
                                y: -32
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 183
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '0.8rem'
                                },
                                y: -30
                            }
                        }
                    }
                }
            }
        ]
    },
    series: []
};

export const MpConversionSuccessGraphOptions: Highcharts.Options = {
    chart: {
        type: 'solidgauge',
        height: '40%',
        spacingBottom: 0
    },
    credits: {
        enabled: false
    },
    title: {
        text: undefined
    },
    exporting: {
        allowHTML: true,
        buttons: {
            contextButton: {
                enabled: false
            },
            exportButton: {
                text: '<i class="fa-2x fa-duotone fa-ellipsis-vertical"></i>',
                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
                theme: {
                    fill: 'rgba(255,255,255,0)'
                }
            }
        },
        chartOptions: {
            title: {
                text: undefined
            },
            plotOptions: {
                solidgauge: {
                    rounded: true,
                    innerRadius: '90%',
                    dataLabels: [
                        {
                            enabled: true,
                            format: '{y:.0f}%',
                            borderWidth: 0,
                            style: {
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                color: '#46485F'
                            },
                            y: -30,
                            align: 'center'
                        }
                    ]
                }
            }
        }
    },
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: [{
            backgroundColor: '#EEE',
            innerRadius: '90%',
            outerRadius: '100%',
            shape: 'arc',
            borderColor: 'transparent'
        }]
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}%</b><br/>',
        valueDecimals: 2,
        outside: true,
        distance: 40
    },
    // the value axis
    yAxis: {
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        min: 0,
        max: 100,
        tickAmount: 0,
        title: {
            text: undefined
        },
        labels: {
            enabled: false
        }
    },
    plotOptions: {
        solidgauge: {
            allowPointSelect: true,
            rounded: true,
            innerRadius: '90%',
            dataLabels: {
                format: '{y:.0f}%',
                borderWidth: 0,
                style: {
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#46485F'
                },
                verticalAlign: 'bottom',
                useHTML: true,
                enabled: true,
                y: -40
            }
        },
        series: {
            allowPointSelect: true
        }
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 305
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: -30
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 282
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: -35
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 242
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: -25
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 220
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '1.0rem'
                                },
                                y: -40
                            }
                        }
                    }
                }
            },
            {
                condition: {
                    maxWidth: 204
                },
                chartOptions: {
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                style: {
                                    fontSize: '1.0rem'
                                },
                                y: -10
                            }
                        }
                    },
                    exporting: {
                        allowHTML: true,
                        buttons: {
                            exportButton: {
                                text: '<i class="fa-duotone fa-ellipsis-vertical"></i>'
                            }
                        }
                    }
                }
            }
        ]
    },
    series: []
};

export enum ChartColors {
    GREEN = '#00B050',
    LIGHT_GREEN = '#92D050',
    YELLOW = '#FFC000',
    RED = '#FF0000'
}

export enum MpChartColors {
    GREEN = '#00B050',
    LIGHT_GREEN = '#43C759',
    YELLOW = '#FCCD00',
    RED = '#F53B30'
}

/**
 * Get the base options for bar/column charts.
 */
export async function getBaseBarChartOptions(): Promise<Highcharts.Options> {
    const featuresStore = getModule(FeaturesStore);
    await featuresStore.init();
    const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;
    let exporting;

    if (!isLineLeaderEnroll) {
        exporting = {
            allowHTML: true,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
                }
            }
        };
    } else {
        exporting = {
            buttons: {
                contextButton: {
                    enabled: false
                },
                exportButton: {
                    text: '<i class="fa-2x fa-duotone fa-ellipsis-vertical"></i>',
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
                }
            }
        };
    }
    return {
        chart: {
            type: isLineLeaderEnroll ? 'column' : 'bar',
            style: {
                fontFamily: FONT_FAMILY,
                color: MOMENT_PATH_BLUE
            }
        },
        exporting: exporting,
        title: {
            text: undefined,
            style: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        subtitle: {
            text: undefined,
            style: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        xAxis: {
            type: 'category',
            categories: [],
            title: {
                text: undefined,
                style: {
                    fontFamily: FONT_FAMILY,
                    color: BASE_COLOR
                }
            },
            maxPadding: 0,
            margin: 0,
            labels: {
                style: {
                    fontFamily: FONT_FAMILY,
                    color: BASE_COLOR
                }
            }
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: null,
                style: {
                    fontFamily: FONT_FAMILY,
                    color: BASE_COLOR
                }
            },
            maxPadding: 0,
            labels: {
                style: {
                    fontFamily: FONT_FAMILY,
                    color: BASE_COLOR
                }
            },
            gridLineDashStyle: isLineLeaderEnroll ? 'Dash' : 'Solid',
            gridLineColor: '#EDEDF2'
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}"></span><span style="color:#000">{series.name}</span>: <b>{point.y:.0f}</b><br/>',
            split: false
        },
        plotOptions: {
            bar: {
                cursor: 'default',
                dataLabels: {
                    enabled: true
                },
                grouping: true,
                pointPadding: 0,
                groupPadding: 0.05,
                pointWidth: undefined
            },
            column: {
                cursor: 'default',
                dataLabels: {
                    enabled: true
                },
                grouping: true,
                pointPadding: 0,
                groupPadding: 0.05,
                pointWidth: undefined
            }
        },
        legend: {
            enabled: true,
            itemStyle: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        credits: {
            enabled: false
        },
        series: []
    };
}

/**
 * The default options for pie charts.
 * This is a function so that the contents don't get overridden when used in multiple graphs.
 */
export async function getBasePieChartOptions(): Promise<Highcharts.Options> {
    const featuresStore = getModule(FeaturesStore);
    await featuresStore.init();
    const isLineLeaderEnroll = featuresStore.isLineLeaderEnroll;

    let exporting;

    if (!isLineLeaderEnroll) {
        exporting = {
            allowHTML: true,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
                }
            }
        };
    } else {
        exporting = {
            allowHTML: true,
            buttons: {
                contextButton: {
                    enabled: false
                },
                exportButton: {
                    text: '<i class="fa-2x fa-duotone fa-ellipsis-vertical"></i>',
                    menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF']
                }
            }
        };
    }

    // As per the docs above, don't change this into a constant.
    return {
        chart: {
            plotShadow: false,
            type: 'pie',
            style: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        exporting: exporting,
        title: {
            text: undefined,
            style: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        subtitle: {
            text: undefined,
            style: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        tooltip: {
            pointFormat: '{point.percentage:.0f} %<br>Count: {point.y}'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                colors: getMonochromeColors(BASE_DARK_BLUE),
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.0f} %<br>Count: {point.y}'
                }
            }
        },
        legend: {
            enabled: true,
            itemStyle: {
                fontFamily: FONT_FAMILY,
                color: BASE_COLOR
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Pie',
                type: 'pie',
                data: []
            } as Highcharts.SeriesPieOptions
        ]
    };
}

/**
 * Get the display statistics names in the shown order for the given statistics.
 *
 * @param groupCommStats
 */
function getDisplayStatsNames(groupCommStats: GroupEmailStats | GroupTextStats): Array<string> {
    let includeSent = true;
    let displayStats = Object.keys(groupCommStats);
    if (displayStats.includes('clicked')) {
        includeSent = false;
    }

    const possibleDisplayStats = Object.keys(GroupCommDisplayStats) as Array<string>;
    displayStats = displayStats.filter((stat: string) => {
        if (!includeSent && stat === 'sent') {
            return false;
        }
        return possibleDisplayStats.includes(stat);
    });

    const map: Map<number, string> = new Map();
    for (const stat of displayStats) {
        const index = possibleDisplayStats.indexOf(stat);
        map.set(index, stat);
    }

    return Array.from((new Map([...map].sort((a, b) => a[0] - b[0]))).values());
}

/**
 * Get the x-axis values for group comm stats.
 *
 * @param groupCommStats
 */
export function groupCommStatsToXAxis(groupCommStats: GroupEmailStats | GroupTextStats): Array<string> {
    return getDisplayStatsNames(groupCommStats)
        .map((stat: string) => {
            return stat.split('_')
                .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
                .join(' ');
        });
}

/**
 * Get the series data for group comm stats.
 *
 * @param groupCommStats
 * @param baseColor
 * @param singleColor
 */
export function groupCommStatsToGraphSeries(
    groupCommStats: GroupEmailStats | GroupTextStats,
    baseColor = BASE_DARK_BLUE,
    singleColor = false
): Array<any> {
    const stats = [];
    const displayStats = getDisplayStatsNames(groupCommStats);
    for (const stat of displayStats) {
        stats.push((groupCommStats as any)[stat] as number);
    }
    return [{
        showInLegend: false,
        data: stats,
        color: getMonochromeColors(baseColor, singleColor)[0]
    }];
}

export function getGaugeChartOptions(): Highcharts.Options {
    return featureState.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)
        ? MpBaseCorpGaugeGraphOptions
        : baseCorpGaugeGraphOptions;
}

export function getConversionSuccessChartOptions(): Highcharts.Options {
    return featureState.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)
        ? MpConversionSuccessGraphOptions
        : conversionSuccessGraphOptions;
}

export function getGaugeStopColors(stops: Array<number>, isReversed = false): Array<[number, ColorType]> {
    return featureState.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)
        ? [
            // We want to control the colors precisely, no gradients, please.
            [stops[0], isReversed ? MpChartColors.GREEN : MpChartColors.RED],
            [stops[1], isReversed ? MpChartColors.LIGHT_GREEN : MpChartColors.YELLOW],
            [stops[2], isReversed ? MpChartColors.YELLOW : MpChartColors.LIGHT_GREEN],
            [stops[3], isReversed ? MpChartColors.RED : MpChartColors.GREEN]
        ]
        : [
            [stops[0], isReversed ? ChartColors.GREEN : ChartColors.RED],
            [stops[1], isReversed ? ChartColors.LIGHT_GREEN : ChartColors.YELLOW],
            [stops[2], isReversed ? ChartColors.YELLOW : ChartColors.LIGHT_GREEN],
            [stops[3], isReversed ? ChartColors.RED : ChartColors.GREEN]
        ];
}

/**
 * Note: this function isn't actually used because we want a gradient of colors on our gauges
 * But that doesn't work if we want the background color to match. Who knows how Highcharts is
 * calculating that gradient?
 * But because I figured out how to make them match, leaving it here for now.
 * Usage example:
 *
 * this.chartOptions.pane!.background![0].backgroundColor = getGaugeBackgroundColor(this.stops, mainPct / 100, true);
 *
 * @param stops
 * @param value
 * @param isReversed
 */
export function getGaugeBackgroundColor(stops: Array<number>, value: number, isReversed = false): string {
    const stopColors = getGaugeStopColors(stops, isReversed);
    let hexColor: ColorType | null = null;
    for (const stopColor of stopColors) {
        const threshold = stopColor[0];
        const color = stopColor[1];
        if (!hexColor) {
            hexColor = color;
        }
        // convert to string; strip leading '#' so we can convert to rgba
        if (value < threshold) {
            break;
        }
        hexColor = color;
    }
    if (!hexColor) {
        return 'rgba(255, 255, 255, .1)';
    }
    const aRgbHex = hexColor.toString().substring(1).match(/.{1,2}/g);
    if (!aRgbHex) {
        return 'rgba(255, 255, 255, .1)';
    }
    const aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
    return `rgba(${aRgb[0]}, ${aRgb[1]}, ${aRgb[2]}, .1)`;
}
