




























































import { EventTypes } from '@/constants/event-type-constants';
import { FormUtils } from '@/core/form-utils';
import { scrollToSelector } from '@/core/scroll-to';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import type { Family } from '@/families/models/family';
import { FamilyUpdateDtoInterface } from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';

const familiesRepo = new FamiliesRepository();
const formUtils = new FormUtils();
const loadingStore = getModule(LoadingStore);
const mapper = new FamilyMapper();

@Component({
    components: {}
})
export default class FamilyDataInfoNotes extends Mixins(FamilyHubMixin, LocaleMixin) {
    @Prop({ required: true }) family!: Family;

    private dto: FamilyUpdateDtoInterface | null = null;
    private isEditing = false;

    /**
     * Whether the form can be saved or not.
     */
    private get canSave(): boolean {
        return formUtils.canSaveEntityForm(this.family, this.dto, mapper, true);
    }

    /**
     * Reload the dto when the family changes.
     */
    @Watch('family', { deep: true })
    private watchFamily(): void {
        this.createDto();
    }

    private mounted() {
        this.createDto();
    }

    /**
     * Stop editing the family notes.
     */
    private cancel(): void {
        this.isEditing = false;
        this.createDto();
    }

    /**
     * Create the dto for the family for use for updating the notes.
     */
    private createDto(): void {
        this.dto = mapper.toUpdateDto(this.family);
    }

    /**
     * Save the changes for the family notes.
     */
    private async save() {
        if (!this.dto) {
            return;
        }
        loadingStore.loadingIncrement('familyData');
        await familiesRepo.update(this.dto);
        loadingStore.loadingDecrement('familyData');
        this.isEditing = false;
        this.$emit(EventTypes.UPDATED);
    }

    private setAsEditing(): void {
        if (!this.canEditFamily) {
            return;
        }
        this.isEditing = true;
        this.$nextTick(() => {
            scrollToSelector(this.$vuetify, '#family-notes-save', '.family-data-active-tab');
        });
    }
}
