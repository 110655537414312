import { addDays, addYears, diffDays, formatDateForApi } from '@/date-time/date-time-utils';
import { DateFilter, DateRange, DateRangeKey } from '@/models/date-filter';
import { PeriodType } from '@/constants/stat-constants';

/**
 * Make the needed adjustments to a date filter based on the period type
 *
 * @param filter
 * @param relativeTo
 */
/*
export function adjustFilterRange(filter: DateFilter, relativeTo: string): void {
    switch (filter.periodType) {
        case PeriodType.YEARLY:
            filter.startDate = moment(filter.startDate).startOf('year').format(isoFormat);
            filter.endDate = moment(filter.endDate).endOf('year').format(isoFormat);
            break;
        case PeriodType.MONTHLY:
            filter.startDate = moment(filter.startDate).startOf('month').format(isoFormat);
            filter.endDate = moment(filter.endDate).endOf('month').format(isoFormat);
            break;
        case PeriodType.WEEKLY: {
            const startAdjust = (moment(relativeTo).diff(moment(filter.startDate), 'days') + 1) % 7;
            if (startAdjust) {
                filter.startDate = moment(filter.startDate).subtract(7 - startAdjust, 'days').format(isoFormat);
            }
            const endAdjust = (moment(relativeTo).diff(moment(filter.endDate), 'days')) % 7;
            if (endAdjust) {
                filter.endDate = moment(filter.endDate).subtract(7 - endAdjust, 'days').format(isoFormat);
            }
        }
    }
}
 */

export interface RangeItem {
    text: string;
    value: DateRange|null;
}

/**
 * predefined filters for our datefilter component
 *
 * @param periodType
 * @param relativeTo
 */
/*
export function getFilterPredefinedRanges(periodType: PeriodType|null, relativeTo: string): RangeItem[] {
    const ranges = [];
    switch (periodType) {
        case null:
            ranges.push({
                text: 'Last Week',
                value: {
                    startDate: moment(relativeTo).subtract(6, 'day').format(isoFormat),
                    endDate: moment(relativeTo).format(isoFormat)
                }
            });
            ranges.push({
                text: 'Last 6 Weeks',
                value: {
                    startDate: moment(relativeTo).subtract(41, 'day').format(isoFormat),
                    endDate: moment(relativeTo).format(isoFormat)
                }
            });
            ranges.push({
                text: 'Last 3 months',
                value: {
                    startDate: moment(relativeTo).subtract(3, 'month').format(isoFormat),
                    endDate: moment(relativeTo).format(isoFormat)
                }
            });
            ranges.push({
                text: 'Current Year',
                value: {
                    startDate: moment(relativeTo).startOf('year').format(isoFormat),
                    endDate: moment(relativeTo).endOf('year').format(isoFormat)
                }
            });
            break;
        case PeriodType.YEARLY:
            for (const i of [1, 4]) {
                ranges.push({
                    text: 'Last ' + (i + 1) + ' Years',
                    value: {
                        startDate: moment(relativeTo).subtract(i, 'year').startOf('year').format(isoFormat),
                        endDate: moment(relativeTo).endOf('year').format(isoFormat)
                    }
                });
            }
            break;
        case PeriodType.MONTHLY:
            for (const i of [2, 5, 11]) {
                ranges.push({
                    text: i !== 11 ? 'Last ' + (i + 1) + ' Months' : 'Last year',
                    value: {
                        startDate: moment(relativeTo).subtract(i, 'month').startOf('month').format(isoFormat),
                        endDate: moment(relativeTo).endOf('month').format(isoFormat)
                    }
                });
            }
            break;
        case PeriodType.WEEKLY:
            for (const i of [3, 6, 12]) {
                ranges.push({
                    text: 'Last ' + (i) + ' Weeks',
                    value: {
                        startDate: moment(relativeTo).subtract((i * 7) - 1, 'day').format(isoFormat),
                        endDate: moment(relativeTo).format(isoFormat)
                    }
                });
            }
            break;
        case PeriodType.DAILY:
            for (const i of [1, 2, 3]) {
                ranges.push({
                    text: i > 1 ? 'Last ' + (i) + ' Weeks' : 'Last Week',
                    value: {
                        startDate: moment(relativeTo).subtract((i * 7) - 1, 'day').format(isoFormat),
                        endDate: moment(relativeTo).format(isoFormat)
                    }
                });
            }
            break;
    }
    ranges.push({ text: 'Custom Dates', value: null });
    return ranges;
}
 */

// Get the actual date range from predetermined range representations
export function getDashboardDateRange(dateRangeKey: DateRangeKey): DateFilter {
    switch (dateRangeKey) {
        case DateRangeKey.LAST_7_DAYS:
            return {
                periodType: PeriodType.DAILY,
                startDate: formatDateForApi(addDays(new Date(), -6)),
                endDate: formatDateForApi(new Date())
            };
        case DateRangeKey.LAST_4_WEEKS:
            return {
                periodType: PeriodType.WEEKLY,
                startDate: formatDateForApi(addDays(new Date(), -27)),
                endDate: formatDateForApi(new Date())
            };
        case DateRangeKey.LAST_90_DAYS:
            return {
                periodType: PeriodType.MONTHLY,
                startDate: formatDateForApi(addDays(new Date(), -89)),
                endDate: formatDateForApi(new Date())
            };
        case DateRangeKey.LAST_YEAR:
            return {
                periodType: PeriodType.YEARLY,
                startDate: formatDateForApi(addDays(addYears(new Date(), -1), 1)),
                endDate: formatDateForApi(new Date())
            };
        case DateRangeKey.CUSTOM:
            // This is just dummy data for now since this isn't used.
            return {
                periodType: null,
                startDate: formatDateForApi(new Date()),
                endDate: formatDateForApi(new Date())
            };
    }
}

/**
 * given a particular date range filter, return a filter with the same amount of time but immediately before
 * the given filter
 *
 * @param filter
 */
export function getPreviousDateFilter(filter: DateFilter): DateFilter {
    const diff = diffDays(filter.endDate, filter.startDate);
    return {
        periodType: filter.periodType,
        startDate: formatDateForApi(addDays(filter.startDate, -1 * (diff + 1))),
        endDate: formatDateForApi(addDays(filter.startDate, -1))
    };
}
