import { HateoasLink } from './../../models/base';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { AbstractFile } from '@/models/files';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import {
    CopyTemplatesDto,
    MessageTableData,
    MessageTemplate
} from '@/communications/templates/models/message-template';
import { PreviewTemplate } from '@/communications/templates/models/preview-template';
import { TextTemplatesRepository } from '@/communications/templates/repositories/text-templates-repository';
import { LocationOption } from '@/organizations/orgs-util';
import type { Family } from '@/families/models/family';
import store from '@/store';
import { CommunicationTypes as CommunicationTypesConstants } from '../communication-constants';

export interface TemplateDetails {
    attachments: Array<AbstractFile>;
    emailSubject: string;
    messageContent: string;
    communicationType: HateoasLink;
}

export default class TemplateService {
    private authState = getModule(AuthStore, store);
    private emailTemplatesRepository = new EmailTemplatesRepository();
    private textTemplatesRepository = new TextTemplatesRepository();

    public async getEmailTemplateDetails(template: MessageTemplate, familyToMessage: Family | null): Promise<TemplateDetails> {
        const templateDetails: TemplateDetails = {
            attachments: [],
            emailSubject: '',
            messageContent: '',
            communicationType: {
                id: CommunicationTypesConstants.GENERAL as number,
                values: {
                    value: 'General'
                },
                links: []
            }
        };

        // Get content and attachments for templates.
        const templateRequest = this.emailTemplatesRepository.getOne(template.id);
        let request: Promise<PreviewTemplate> | null = null;

        if (familyToMessage) {
            request = this.emailTemplatesRepository.generatePreviewForTemplate(
                template,
                {
                    family_id: familyToMessage.id,
                    user_id: this.authState.userInfoObject!.id
                }
            );
        }
        const templateResponse = await templateRequest;
        const response = request ? await request : null;
        templateDetails.messageContent = response
            ? (response as PreviewTemplate).body as string
            : (templateResponse as MessageTemplate).content as string;
        templateDetails.emailSubject = response ? response.subject as string : templateResponse.subject as string;
        templateDetails.communicationType = templateResponse.communication_type ?? { communicationType: { id: CommunicationTypesConstants.GENERAL as number } };
        // Get attachment details from the template, if any
        // Needed for the file chips to have info about them
        if (templateResponse.attachments) {
            for (const attachmentLink of templateResponse.attachments) {
                templateDetails.attachments.push({
                    id: attachmentLink.id,
                    filename: attachmentLink.values.name,
                    file_size: attachmentLink.values.size
                });
            }
        }

        return templateDetails;
    }

    public async getTextTemplateDetails(template: MessageTemplate, familyToMessage: Family | null): Promise<TemplateDetails> {
        const templateDetails: TemplateDetails = {
            attachments: [],
            emailSubject: '',
            messageContent: '',
            communicationType: {
                id: CommunicationTypesConstants.GENERAL as number,
                values: {
                    value: 'General'
                },
                links: []
            }
        };

        // Get content and attachments for templates.
        let request: Promise<PreviewTemplate> | null = null;
        const templateRequest = this.textTemplatesRepository.getOne(template.id);

        if (familyToMessage) {
            request = this.textTemplatesRepository.generatePreviewForTemplate(
                template,
                {
                    family_id: familyToMessage.id,
                    user_id: this.authState.userInfoObject!.id
                }
            );
        }

        const templateResponse = await templateRequest;
        const response = request ? await request : null;

        templateDetails.messageContent = response
            ? (response as PreviewTemplate).body as string
            : (templateResponse as MessageTemplate).content as string;
        templateDetails.communicationType = templateResponse.communication_type ?? { communicationType: { id: CommunicationTypesConstants.GENERAL as number } };

        return templateDetails;
    }

    getCopyTemplatesDto(templates: Array<MessageTableData>, location: LocationOption): CopyTemplatesDto {
        const dto: CopyTemplatesDto = {
            email_template_ids: [],
            text_template_ids: [],
            organization_id: null,
            brand_id: null,
            location_group_id: null
        };
        for (const template of templates) {
            if (template.type === 'Email') {
                dto.email_template_ids.push(template.id);
            } else {
                dto.text_template_ids.push(template.id);
            }
        }
        switch (location.type) {
            case 'org':
                dto.organization_id = location.id;
                break;
            case 'brand':
                dto.brand_id = location.id;
                break;
            case 'locationGroup':
                dto.location_group_id = location.id;
                break;
        }
        return dto;
    }
}
