
import { Component, Mixins, Prop } from 'vue-property-decorator';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VAutocomplete from 'vuetify/lib/components/VAutocomplete/VAutocomplete';

@Component
export default class BaseAutoComplete extends Mixins(VAutocomplete) {
    @Prop({ type: Boolean, default: true }) clearable!: boolean;
    @Prop({ type: Boolean, default: true }) dense!: boolean;
    @Prop({ type: Boolean, default: true }) filled!: boolean;
    @Prop({ type: Boolean, default: true }) isEmphasized!: boolean;

    get classes(): object {
        return {
            ...VAutocomplete.options.computed.classes.call(this),
            'base-select': true,
            'base-auto-complete': true,
            'emphasized-select': this.isEmphasized
        };
    }
}
