import { StoreModuleTypes } from '@/constants/store-constants';
import { LayoutTabUtils } from '@/utils/layout-tab-utils';
import { TabTitles } from '@/router/route-constants';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface LayoutTab {
    id: number;
    component: any;
    contactId?: string;
    props: {
        routeName: string;
        routeParams: Record<string, any> | undefined;
        tabTitle: string | undefined;
    };
}

export interface LayoutTabsState {
    currentTabId: number;
    currentTabIndex: number;
    nextId: number;
    tabsArray: Array<LayoutTab>;
    tabsMap: Map<number, LayoutTab>;
}

@Module({
    namespaced: true,
    name: StoreModuleTypes.LAYOUT_TABS,
    preserveState: true
})
export class LayoutTabsStore extends VuexModule implements LayoutTabsState {
    currentTabId = 0;
    currentTabIndex = 0;
    nextId = 1;
    layoutTabsKey = 0;
    tabsArray: Array<LayoutTab> = [];
    tabsMap: Map<number, LayoutTab> = new Map();

    public get tabs(): Array<any> {
        return this.tabsArray;
    }

    @Mutation
    public clear(): void {
        this.tabsMap.clear();
        this.tabsArray = [];
        this.currentTabId = 0;
        this.currentTabIndex = 0;
        this.nextId = 1;
    }

    @Mutation
    public async addTab(addTabObject?: { routeName?: string; routeParams?: Record<string, any>; goTo?: boolean; tabTitle?: string; contactId?: string }) {
        const id = this.nextId++;
        await LayoutTabUtils.createTabComponent(id);
        const tab: LayoutTab = {
            id: id,
            component: `LayoutTab${id}`,
            props: {
                routeName: addTabObject ? (addTabObject.routeName ?? 'home') : 'home',
                routeParams: addTabObject ? addTabObject.routeParams : undefined,
                tabTitle: addTabObject ? (addTabObject.tabTitle ? addTabObject.tabTitle : (addTabObject.routeName === 'home' ? TabTitles.HOME : undefined)) : undefined
            },
            contactId: addTabObject?.contactId ?? ''
        };
        this.tabsMap.set(tab.id, tab);
        this.tabsArray.push(tab);

        // Change to the new tab.
        if (addTabObject && addTabObject.goTo) {
            this.currentTabIndex = this.tabsArray.length - 1;
        }
    }

    @Action
    public async switchToOrAddTabByContactId(tabObject: { routeName?: string; routeParams?: Record<string, any>; goTo?: boolean; contactId: string; tabTitle?: string }) {
        for (const [index, tab] of this.tabsArray.entries()) {
            if (tab.contactId && tabObject.contactId === tab.contactId) {
                this.context.commit('setCurrentTabIndex', index);
                return;
            }
        }
        await this.addTab({ routeName: tabObject.routeName, routeParams: tabObject.routeParams, goTo: tabObject.goTo, contactId: tabObject.contactId, tabTitle: tabObject.tabTitle });
    }

    @Action
    public async switchToTabByContactId(contactId: string) {
        for (const [index, tab] of this.tabsArray.entries()) {
            if (tab.contactId && contactId === tab.contactId) {
                this.context.commit('setCurrentTabIndex', index);
                return;
            }
        }
    }

    @Mutation
    public removeTab(tab: LayoutTab): void {
        this.tabsMap.delete(tab.id);
        this.tabsArray = Array.from(this.tabsMap.values());
    }

    @Mutation
    setCurrentTabIndex(index: number): void {
        this.currentTabIndex = index;
    }

    @Mutation
    public setCurrentTabId(id: number): void {
        this.currentTabId = id;
    }

    @Mutation
    public syncMapWithArray(): void {
        this.tabsMap.clear();
        for (const tab of this.tabsArray) {
            this.tabsMap.set(tab.id, tab);
        }
    }

    @Mutation
    public updateTab(tab: LayoutTab): void {
        this.tabsMap.set(tab.id, tab);
        this.tabsArray = Array.from(this.tabsMap.values());
        ++this.layoutTabsKey;
    }

    public get currentTabIdNumber(): number {
        return this.currentTabId;
    }
}
