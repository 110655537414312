














































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import type { Guardian } from '@/families/models/guardian';
import { SixAgentUtils } from '@/integrations/six-agent-utils';

const sixAgentState = getModule(SixAgentStore);
const sixAgentUtil = new SixAgentUtils();

@Component
export default class PhoneNumberDisplay extends Mixins(LocaleMixin) {
    @Prop() readonly family!: Family;
    @Prop() readonly guardian!: Guardian;
    @Prop() readonly prefix!: string | undefined;
    @Prop() readonly suffix!: string | undefined;
    @Prop({ default: false }) readonly isButton!: boolean;

    private addContactMode = false;

    get isDoNotCall() {
        return !this.guardian.primary_phone || this.family.do_not_call || !this.sixAgentReady;
    }

    get phoneString() {
        if (!this.guardian.primary_phone) {
            return '';
        }
        return (this.prefix ?? '') + this.guardian.primary_phone.number + (this.suffix ?? '');
    }

    get sixAgentReady() {
        return sixAgentState.ready && sixAgentState.loggedIn;
    }

    get sixAgentContactCount() {
        return sixAgentState.contactCount;
    }

    @Watch('sixAgentContactCount')
    sixContactChange(newCount: number, oldCount: number) {
        if (newCount > oldCount && sixAgentState.loggedIn && this.family && this.addContactMode) {
            sixAgentUtil.addPossibleContact(this.family);
            this.addContactMode = false;
        }
    }

    dialSixAgent() {
        if (!this.guardian.primary_phone) {
            return;
        }
        sixAgentState.open();
        this.addContactMode = true;
        window.sixAgentInterface.outboundCall(this.guardian.primary_phone.number_e164);
    }
}

