













import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    WorkflowActionSettingsDripCampaignDto, WorkflowCreateDto
} from '@/automation/workflows/models/workflow-models';
import { DripCampaignsStore } from '@/automation/drip-campaigns/store/drip-campaigns-store';
import { DripCampaignUtils } from '@/automation/drip-campaigns/drip-campaign-utils';

const loadingState = getModule(LoadingStore);
const campaignsState = getModule(DripCampaignsStore);
const dripCampaignsUtil = new DripCampaignUtils();

@Component
export default class WorkflowActionStartDripCampaign extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop() readonly value!: WorkflowActionSettingsDripCampaignDto;
    @Prop() readonly dto!: WorkflowCreateDto;

    private loadingKey = 'manageWorkflow';

    get settings(): WorkflowActionSettingsDripCampaignDto {
        return this.value;
    }

    set settings(settings: WorkflowActionSettingsDripCampaignDto) {
        this.$emit('input', settings);
    }

    get campaigns() {
        return campaignsState.stored.filter((campaign) => {
            return dripCampaignsUtil.isWorkflowAddable(campaign, this.dto);
        });
    }

    /**
     * make sure we clear the dto value when it's set to a no longer valid campaign
     */
    @Watch('campaigns', { deep: true, immediate: false })
    optionsUpdated() {
        const campaignIds = this.campaigns.map((campaign) => {
            return campaign.id;
        });
        if (!campaignIds.includes(this.settings.drip_campaign)) {
            this.settings.drip_campaign = 0;
        }
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await campaignsState.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

}
