import { CrmEntity, FileHateoasLink } from '@/models/base';
import { AbstractCreateDto } from '@/models/abstract-dto';

export interface Brand extends CrmEntity {
    domain: string;
    logo: FileHateoasLink | null;
    name: string;
    pst_url: string | null;
}

export interface BrandDtoInterface extends AbstractCreateDto {
    domain: string;
    name: string;
    pst_url: string | null;
}

export class BrandDto implements BrandDtoInterface {
    domain = '';
    name = '';
    pst_url: string | null = null;
}
