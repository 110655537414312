






















































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Family } from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { Relationship } from '@/families/models/relationship';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { GuardianMapper } from '@/families/mappers/guardian-mapper';
import { GuardianCreateDto } from '@/families/models/guardian';
import { ContactsRepository } from '@/families/repositories/contacts-repository';
import { ContactMapper } from '@/families/mappers/contact-mapper';
import { PhoneDtoInterface } from '@/families/models/phone';
import { AddressDto } from '@/families/models/address';
import { EventTypes } from '@/constants/event-type-constants';
import { ContactCreateDto } from '@/families/models/contact';
import { getDefaultPhoneType, getDefaultRelationship } from '@/families/families-utils';
import type { ContactCreateDtoInterface, ContactUpdateDtoInterface } from '@/families/models/contact';
import type { GuardianCreateDtoInterface, GuardianUpdateDtoInterface } from '@/families/models/guardian';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { VForm } from '@/types/types';
import SaveButton from '@/components/base/SaveButton.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const crmTypesStore = getModule(CrmTypesStore);
const loadingState = getModule(LoadingStore);
const guardianMapper = new GuardianMapper();
const contactMapper = new ContactMapper();
const contactsRepository = new ContactsRepository();

interface FormattedPhoneNumber {
    number: string;
}

interface ContactComparison {
    original: ContactCreateDtoInterface | ContactUpdateDtoInterface;
    current: ContactCreateDtoInterface | ContactUpdateDtoInterface;
}

@Component({
    components: {
        BaseClose,
        SaveButton
    }
})
export default class AddEditContactModal extends Mixins(LocaleMixin) {
    private loadingKey = 'addEditContactsModalLoading';
    private overlay = false;
    private relationships: Array<Relationship> = [];
    private phoneTypes: Array<CrmTypeOption> = [];
    private primaryGuardian: GuardianUpdateDtoInterface | null = null;
    private originalSecondaryGuardian: GuardianCreateDtoInterface | GuardianUpdateDtoInterface = new GuardianCreateDto();
    private secondaryGuardian: GuardianCreateDtoInterface | GuardianUpdateDtoInterface = new GuardianCreateDto();
    private isSecondaryGuardianFieldsRequired = false;
    private contacts: Array<ContactComparison> = [];
    private validForm = false;
    private isUpdated = false;
    private isSaved = false;
    private doNotCall = false;
    private doNotText = false;
    private doNotEmail = false;

    private requiredEmail = [
        (v: string) => /.+@.+\..*/.test(v) || 'Please enter valid email address'
    ]

    private nonRequiredEmail = [
        (v: string) => {
            if (v.length > 0) {
                return /.+@.+\..*/.test(v) || 'Please enter valid email address';
            }

            return true;
        }
    ];

    private requiredField = [
        (v: string | number) => !!v || 'Please enter a value'
    ];

    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;
    @Ref('form') readonly form!: VForm;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    @Watch('value')
    public async populate() {
        // Only do this when we open the modal and we have a family.
        if (this.value && this.family) {
            await this.updateData();
            this.$nextTick(() => {
                // Validate form when we popup the modal.
                this.form.validate();
            });
        }
    }

    // Da family.
    @Prop() readonly family!: Family | null;

    @Watch('family')
    async updateData() {
        if (!this.family) {
            return;
        }

        if (this.family) {
            loadingState.loadingIncrement(this.loadingKey);
            this.doNotCall = clone(this.family.do_not_call);
            this.doNotEmail = clone(this.family.do_not_email);
            this.doNotText = clone(this.family.do_not_text);

            // (Re)Create the primary guardian objects.
            this.primaryGuardian = guardianMapper.toUpdateDto(cloneDeep(this.family.primary_guardian));

            // Clear out contacts.
            this.contacts = [];

            // Get the contacts.
            const contactsResponse = await contactsRepository.retrieveAll(this.family.id as number);
            contactsResponse.forEach((contact) => {
                this.contacts.push(
                    {
                        original: contactMapper.toUpdateDto(cloneDeep(contact)),
                        current: contactMapper.toUpdateDto(cloneDeep(contact))
                    });
            });

            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    async mounted() {
        loadingState.loadingIncrement(this.loadingKey);

        // Load in the lists.
        await crmTypesStore.initList(CrmTypeList.PHONE_TYPE);
        await crmTypesStore.initList(CrmTypeList.RELATIONSHIP);
        this.phoneTypes = crmTypesStore.listOptions(CrmTypeList.PHONE_TYPE);
        this.relationships = crmTypesStore.listOptions(CrmTypeList.RELATIONSHIP);

        await this.populate();
        loadingState.loadingDecrement(this.loadingKey);
    }

    get hasChanges(): boolean {
        let hasChanged = false;

        for (const contact of this.contacts) {
            if (!isEqual(contact.original, contact.current)) {
                hasChanged = true;
                break;
            }
        }

        return hasChanged;
    }

    private addContact() {
        // Add a blank contact row.
        this.contacts.push({
            original: {
                name: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: {
                    type: '',
                    number_e164: ''
                } as PhoneDtoInterface,
                address: new AddressDto(),
                child_relation: null,
                is_guardian: false,
                is_family: false,
                is_emergency_contact: false,
                is_authorized_pickup: false
            } as ContactCreateDtoInterface,
            current: {
                name: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: {
                    type: getDefaultPhoneType(this.phoneTypes)?.value,
                    number_e164: ''
                } as PhoneDtoInterface,
                address: new AddressDto(),
                child_relation: getDefaultRelationship(this.relationships),
                is_guardian: false,
                is_family: false,
                is_emergency_contact: false,
                is_authorized_pickup: false
            } as ContactCreateDtoInterface
        });
    }

    private async removeContact(contactToRemove: ContactCreateDtoInterface | ContactUpdateDtoInterface) {
        if (this.family && this.family.id) {
            if (!contactToRemove.id) {
                // Just removing a row that was never saved
                this.contacts = this.contacts.filter(contact => contact.current !== contactToRemove);
                return;
            }

            const result = await this.$swal({
                text: 'Are you sure you want to remove this contact?',
                showConfirmButton: true,
                showCancelButton: true
            });

            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);
                await contactsRepository.deleteOne(this.family.id, contactToRemove.id);
                this.contacts = this.contacts.filter(contact => contact.current !== contactToRemove);

                // Force update on close.
                this.isUpdated = true;

                loadingState.loadingDecrement(this.loadingKey);
            }
        }
    }

    // Close the modal and clean up some stuff.
    private close() {
        if (this.isUpdated || this.isSaved) {
            this.primaryGuardian = null;
            this.contacts = [];
            this.validForm = false;
            this.isSaved = false;
        }

        if (this.isUpdated) {
            this.isUpdated = false;
            this.$emit(EventTypes.UPDATED);
        }

        this.overlay = false;
        this.form.reset();
        this.modelValue = false;
        this.$emit('closed');
    }

    private async save() {
        if (this.family) {
            loadingState.loadingIncrement(this.loadingKey);
            // Don't double reload.
            this.isUpdated = false;
            this.isSaved = true;

            // Update / insert additional contacts.
            // Must use for instead of forEach, since we are doing async calls.
            for (const contact of this.contacts) {
                if (!isEqual(contact.original, contact.current)) {
                    // Clone it so we don't get error about E164.
                    const contactClone = cloneDeep(contact.current);

                    // Make sure we have a null object for empty phone numbers.
                    if (contactClone.phone &&
                        contactClone.phone.number_e164 === ''
                    ) {
                        contactClone.phone = null;
                    }

                    if (contactClone.id) {
                        // Deal with the relations.
                        if (!contactClone.child_relation && contact.original.child_relation) {
                            contactClone.child_relation = null;
                        }

                        await contactsRepository.updateOne(
                            this.family.id,
                            contactClone as ContactUpdateDtoInterface
                        );
                    } else {
                        await contactsRepository.createOne(
                            this.family.id,
                            contactClone as ContactCreateDto
                        );
                    }
                }
            }

            // Emit to reload parent page.
            this.$emit(EventTypes.UPDATED);
        }

        loadingState.loadingDecrement(this.loadingKey);
        this.close();
    }
}
