import { locale as usLocale } from './en-US';
import { en as vuetifyLocale } from 'vuetify/src/locale';
import { LocaleDefinition } from '@/locales/locale-definition';

export const locale: LocaleDefinition = {
    crmCountry: 'aus',
    fcLocale: 'en-au',
    label: 'Australia',
    firstDayOfWeek: 1,
    distanceUnit: 'km',
    phoneRegion: 'AU',
    dateFormat: 'dd/MM/yyyy',
    yearFirstDateFormat: 'yyyy-MM-dd',
    shortDateYearFormat: 'dd/MM/yy',
    shortDateFormat: 'dd/MM',
    timeFormat: 'hh:mm a',
    shortTimeFormat: 'hh:mm',
    militaryTimeFormat: 'HH:mm',
    numberFormats: {
        currency: {
            style: 'currency', currency: 'AUD'
        },
        currencyWhole: {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }
    },
    messages: {
        $vuetify: {
            ...vuetifyLocale
        },
        ...usLocale.messages,
        center: 'centre',
        enrollment: 'enrolment',
        hello: 'g\'day, {user}',
        welcome: 'Welcome to ChildcareCRM - {level}',
        dashboardRevenueBold: 'Total Estimated Lifetime Value',
        dashboardRevenue: ' of Families not yet Enrolled/Waitlisted',
        regionLabel: 'State/Territory',
        regionLabelAbv: 'St/Ty',
        postcodeLabel: 'Postcode',
        corporate: 'Corporate',
        dashboard: 'Dashboard',
        supportEmail: 'support@golineleader.com.au',
        helpPhone: 'Call <a href="tel:+61-02-8039-8276,2">02 8039 8276 ext 2</a></div>',
        insight: 'Insight'
    }
};
