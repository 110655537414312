import { AbstractRepository } from '@/repositories/abstract-repository';
import { Org } from '@/models/organization/org';

export class OrgsRepository extends AbstractRepository<Org> {
    readonly endpoint = 'organizations';
    readonly defaultParams = {
        include_centers: '1'
    }

    public async delete(orgId: number): Promise<void> {
        await this.client.delete<Org>(this.endpoint + `/${orgId}`);
    }
}
