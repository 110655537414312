var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"text-h6"},[_c('v-icon',[_vm._v(" mdi-email-fast ")]),_c('v-col',[_vm._v(" Pending Emails ")])],1),(_vm.pendingEmails.length)?_c('base-data-table',{staticClass:"truncated-columns",attrs:{"id":"pending-emails-table","headers":_vm.headers,"items":_vm.pendingEmails,"no-data-text":"No Pending Emails","disable-pagination":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.sent_date_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeWithTimezone(value, _vm.userTimeZone))+" ")]}},{key:"item.send_to_guardian",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSendToNames(item))+" ")]}},{key:"item.subject",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":value}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('base-button-secondary',{staticClass:"mr-2",on:{"click":function($event){return _vm.editPending(item)}}},[_vm._v(" Edit ")]),_c('base-button-secondary',{on:{"click":function($event){return _vm.cancelPending(item)}}},[_vm._v(" Cancel ")])],1)]}}],null,false,2625857829)}):_c('div',{staticClass:"no-emails"},[_vm._v(" No Pending Emails ")]),_c('add-edit-message-modal',{attrs:{"show-activator":false,"email":_vm.selectedEmail,"family":_vm.family},on:_vm._d({},[_vm.updatedEvent,_vm.pendingEmailUpdated]),model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }