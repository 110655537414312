import { AbstractRepository } from '@/repositories/abstract-repository';
import { StatusHistories } from '@/families/models/status-histories';

export class StatusHistoriesRepository extends AbstractRepository<StatusHistories> {
    readonly endpoint = 'families';

    public async getStatusHistories(familyId: number): Promise<Array<StatusHistories>> {
        const response = await this.client.get(this.endpoint + `/${familyId}/status-histories`);
        return response.data;
    }
}
