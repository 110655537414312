import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    DripCampaign,
    DripCampaignHistory, DripCampaignHistoryCreate,
    DripCampaignStatusUpdate,
    GroupDripCampaignDto
} from '@/automation/drip-campaigns/models/drip-campaign';
import { DripCampaignStatus } from '@/automation/drip-campaigns/drip-campaign-constants';
import { CrmTypeOption } from '@/crm-types/models/crm-type';

export class DripCampaignsRepository extends AbstractRepository<DripCampaign> {
    readonly endpoint = 'settings/automation/drip-campaigns';
    readonly typesEndpoint = 'types/drip-campaigns';

    /**
     * we use normal CrmTypes repo/store for most drip campaign types, but statuses
     * are a little special because of transition rules
     * @param from
     */
    public async getStatuses(from: DripCampaignStatus): Promise<Array<CrmTypeOption>> {
        const url = this.typesEndpoint + '/statuses?from_status=' + from;
        return (await this.client.get(url)).data;
    }

    /**
     * Start a group drip campaign.
     *
     * @param filterId The group id
     * @param dto
     */
    public async startGroupDripCampaign(filterId: number, dto: GroupDripCampaignDto): Promise<void> {
        await this.client.post(`groups/${filterId}/histories/drip-campaigns`, dto);
    }

    public async updateStatus(campaignId: number, newStatus: DripCampaignStatus): Promise<DripCampaign> {
        const url = this.endpoint + '/' + campaignId + '/status';
        const payload: DripCampaignStatusUpdate = {
            status: newStatus
        };
        return (await this.client.put(url, payload)).data;
    }

    public async cancelFamilyCampaign(leadId: number, historyId: number): Promise<void> {
        const url = 'families/' + leadId + '/drip-campaign-histories/' + historyId;
        await this.client.delete(url);
    }

    public async getFamilyCampaigns(leadId: number): Promise<Array<DripCampaignHistory>> {
        const url = 'families/' + leadId + '/drip-campaign-histories';
        return (await this.client.get(url)).data;
    }

    public async startFamilyCampaign(leadId: number, campaign: number): Promise<void> {
        const url = 'families/' + leadId + '/drip-campaign-histories';
        const payload: DripCampaignHistoryCreate = {
            drip_campaign: campaign
        };
        await this.client.post(url, payload);
    }

}
