
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import VMenu from 'vuetify/lib/components/VMenu/VMenu';
import Vue from 'vue';

const base = Vue.extend({ mixins: [VMenu] });
export default base.extend({
    name: 'base-menu',
    props: {
        // set default minimum width
        minWidth: {
            type: String,
            default: '7rem'
        }
    }
});
