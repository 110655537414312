import { AbstractRepository } from '@/repositories/abstract-repository';
import { BasicFamily } from '@/families/models/family';
import { SixAgentEventPhoneLog } from '@/integrations/models/six-agent';
import { InContactEvent } from '@/integrations/models/in-contact-event';

export class IncontactRepository extends AbstractRepository<BasicFamily> {
    protected endpoint = 'integrations/incontact/';

    public async getEvents(familyId: number): Promise<Array<InContactEvent>> {
        const response = await this.client.get(this.endpoint + `events?family_id=${familyId}`);
        return response.data;
    }

    public async searchContacts(contactId: string, ani: string, email: string): Promise<Array<BasicFamily>> {
        const results = await this.client.get(this.endpoint + 'contact-search', { params: { contactId, ani, email } });
        return results.data;
    }

    public async recordPhoneLog(phoneLog: SixAgentEventPhoneLog): Promise<void> {
        await this.client.post(this.endpoint + 'phone-log', phoneLog.data);
    }
}
