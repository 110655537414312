import { PeriodType } from '@/constants/stat-constants';

export interface DateRange {
    startDate: string;
    endDate: string;
}

export interface DateFilter extends DateRange {
    periodType: PeriodType | null;
    periodLength?: number;
}

export enum DateRangeKey {
    LAST_7_DAYS = '7_days',
    LAST_4_WEEKS = '4_weeks',
    LAST_90_DAYS = '90_days',
    LAST_YEAR = 'last_year',
    CUSTOM = 'custom'
}

export const dashboardDateRanges: Array<DateRangeOption> = [
    { key: DateRangeKey.LAST_7_DAYS, value: 'Last 7 Days' },
    { key: DateRangeKey.LAST_4_WEEKS, value: 'Last 4 Weeks' },
    { key: DateRangeKey.LAST_90_DAYS, value: 'Last 90 Days' },
    { key: DateRangeKey.LAST_YEAR, value: 'Last Year' },
    { key: DateRangeKey.CUSTOM, value: 'Custom Range' }
];

export interface DateRangeOption {
    key: string;
    value: string;
}
