




































































































































import { EventTypes } from '@/constants/event-type-constants';
import CallFamilyModal from '@/families/components/new/CallFamilyModal.vue';
import FamilyDataContactsModal from '@/families/components/new/FamilyDataContactsModal.vue';
import { isDoNotCallForContact } from '@/families/families-utils';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Contact } from '@/families/models/contact';
import { Center } from '@/organizations/locations/models/center';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import { DataTableHeader } from 'vuetify';

@Component({
    components: { CallFamilyModal, FamilyDataContactsModal }
})
export default class FamilyDataContacts extends Mixins(FamilyHubMixin, LocaleMixin) {
    @Prop() readonly center!: Center | null;
    /**
     * The contacts to display in the table.
     */
    @Prop({ required: true }) contacts!: Array<Contact>;
    @Prop() family!: Family;

    private loadingKey = 'contactsInfo';
    private selectedContact: Contact | null = null;
    private showModal = false;
    private showCallModal = false;
    private updatedEvent = EventTypes.UPDATED;

    /**
     * Whether calls can be made to families in this family's center.
     */
    get canMakeCenterCalls(): boolean {
        if (!this.center) {
            return false;
        }
        return this.center.contact.crm_phone.number_e164 !== null;
    }

    /**
     * The headers for the table.
     */
    private get headers(): Array<DataTableHeader> {
        const commentsWidth = this.canMakeCenterCalls ? '30%' : '35%';
        const headers = [
            { text: 'Name', value: 'name', sortable: false, width: '18%' },
            { text: 'Relationship', value: 'relation_to_child', sortable: false, width: '13%' },
            { text: 'Phone', value: 'phone.number', sortable: false, width: '120', class: 'phone' },
            { text: 'Email', value: 'email', sortable: false, width: '23%' },
            { text: 'Notes', value: 'comments', sortable: false, width: commentsWidth }
        ];
        if (this.canMakeCenterCalls) {
            headers.push({ text: '', value: 'actions', sortable: false, width: '10ch' });
        }
        return headers;
    }

    /**
     * Open the modal to call the contact.
     *
     * @param contact
     */
    private callContact(contact: Contact) {
        this.selectedContact = contact;
        this.showCallModal = true;
    }

    /**
     * Whether the contact can be called.
     *
     * @param contact
     */
    isDoNotCall(contact: Contact) {
        return isDoNotCallForContact(this.family, contact);
    }

    /**
     * Open the modal to manage or add a contact.
     *
     * @param contact
     */
    private manageContact(contact: Contact | null) {
        if (!this.canEditFamily) {
            return;
        }
        this.selectedContact = contact;
        this.showModal = true;
    }

    /**
     * Reload the contacts.
     */
    private reloadData() {
        this.$emit(this.updatedEvent);
    }
}
