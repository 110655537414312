









































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventTypes } from '@/constants/event-type-constants';
import { IntegrationPartner, ServiceAccount, ServiceAccountPostDto } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import cloneDeep from 'lodash/cloneDeep';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const integrationRepository = new IntegrationRepository();
@Component({
    components: { BaseClose }
})
export default class ServiceAccountModal extends Mixins(LocaleMixin) {
    @Prop() curServiceAccountDetails!: ServiceAccount | null;
    @Prop() partner!: IntegrationPartner | null;
    // If modal is called from the service accounts table in 'settings/users',
    // then the user needs to be shown the list of partners
    // If modal is called from marketplace/integrations then this value is
    // false/undefined since partnerId would be passed in as a prop
    @Prop({ default: false }) includePartnersList!: boolean;
    @Prop() partnersList: Array<IntegrationPartner> | undefined;

    private isUpdated = false; // controls the snackbar
    private isError = false;
    private loadingKey = 'servicesModal';
    private isCreate = true;
    private username = '';
    private password = '';
    private response: ServiceAccount | null = null;
    // If modal is called from the service accounts table in 'settings/users',
    // then the user needs to select the partnerId from the drop-down in this modal
    private createSAPartnerId: number | undefined = -1;
    private blankPasswordText = '*****';

    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get partnersForSelectList(): Array<IntegrationPartner> {
        const list: Array<IntegrationPartner> | undefined = cloneDeep(this.partnersList);
        if (list !== undefined) {
            list.unshift({
                id: -1,
                has_centers_list: false,
                name: 'None',
                needs_centers_mapped: false,
                needs_classrooms_mapped: false,
                needs_credentials: false,
                type: {
                    id: -1,
                    values: {},
                    links: []
                },
                needs_service_account: false,
                region: null
            } as IntegrationPartner);
            return list as Array<IntegrationPartner>;
        }
        return [];
    }

    @Watch('modelValue')
    async updateModalStatus() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.value) {
            this.isCreate = !this.curServiceAccountDetails;

            if (this.isCreate) {
                this.username = '';
            } else {
                this.username = this.curServiceAccountDetails!.username;
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.isCreate && !this.includePartnersList && this.partner !== null) {
            const serviceAccountDto = new ServiceAccountPostDto();
            serviceAccountDto.partner = this.partner.id;
            serviceAccountDto.username = this.username;

            integrationRepository.createServiceAccount(serviceAccountDto).then(res => {
                this.response = res;
                if (this.response && this.response.password) {
                    this.password = this.response.password;
                }
                this.isUpdated = true;
            });
        } else if (this.isCreate && this.includePartnersList && this.createSAPartnerId !== undefined) {
            const serviceAccountDto = new ServiceAccountPostDto();
            serviceAccountDto.partner = this.createSAPartnerId;
            serviceAccountDto.username = this.username;

            if (serviceAccountDto.partner === -1) {
                delete serviceAccountDto.partner;
            }

            integrationRepository.createServiceAccount(serviceAccountDto).then(res => {
                this.response = res;
                if (this.response && this.response.password) {
                    this.password = this.response.password;
                }
                this.isUpdated = true;
            });
        } else {
            this.response = await integrationRepository.updateServiceAccount(this.curServiceAccountDetails!.id);
            if (this.response && this.response.password) {
                this.password = this.response.password;
            }
            this.isUpdated = true;
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async deleteServiceAccount() {
        const result = await this.$swal({
            text: 'Are you sure?  Service accounts cannot be restored after being deleted.',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Delete Account',
            customClass: {
                cancelButton: 'swal2-primary-button-styling',
                confirmButton: 'swal2-secondary-button-styling'
            }
        });

        if (result.isConfirmed && this.curServiceAccountDetails) {
            this.$emit(EventTypes.DELETED, this.curServiceAccountDetails);
            this.close();
        }
    }

    private close() {
        if (this.response) {
            this.$emit(EventTypes.UPDATED);
        } else {
            this.$emit(EventTypes.CLOSE);
        }
        this.modelValue = false;
        this.response = null;
        this.username = '';
        this.password = '';
        this.createSAPartnerId = -1;
    }
}
