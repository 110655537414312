






























































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import type { LandingPageCommunicationSettingsDto } from '@/automation/landing-pages/models/landing-pages-models';
import {
    landingPageTimeUnits,
    TemplateType,
    templateTypes
} from '@/automation/landing-pages/models/landing-pages-models';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const typesState = getModule(CrmTypesStore);
const templatesState = getModule(EmailTemplatesStore);

@Component({
    components: { CrmTypeSelectList }
})
export default class CommSettingsSection extends Mixins(BasicValidationMixin, LocaleMixin) {
    @Prop({ required: true }) readonly value!: LandingPageCommunicationSettingsDto;
    @Prop({ default: false }) readonly hasTemplateGroups!: boolean;

    private pendingReplyTos: Array<CrmTypeOption> = [];
    private pendingTimeUnits = landingPageTimeUnits;
    private pendingTemplateTypes = templateTypes;
    private templates: Array<MessageTemplate> = [];
    private templateGroups: Array<CrmTypeOption> = [];
    private templateGroupsEmailList = CrmTypeList.TEMPLATE_GROUPS_EMAIL;

    get commSettings(): LandingPageCommunicationSettingsDto {
        return this.value;
    }

    set commSettings(settings: LandingPageCommunicationSettingsDto) {
        this.$emit('input', settings);
    }

    get hasThankYou() {
        return this.commSettings.is_send_thank_you_email;
    }

    get useTemplateGroups() {
        return this.commSettings.family_email_template_method === TemplateType.AUTOMATIC;
    }

    async mounted() {
        const optionsPromises = [];
        optionsPromises.push(typesState.initList(CrmTypeList.PENDING_REPLY_TOS));
        optionsPromises.push(templatesState.initForOrg(1));
        await Promise.all(optionsPromises);
        this.templates = templatesState.orgTemplates;
        this.pendingReplyTos = typesState.listOptions(CrmTypeList.PENDING_REPLY_TOS);
        if (!this.hasTemplateGroups) {
            this.commSettings.family_email_template_method = TemplateType.SINGLE;
        }
    }
}
