

































































































































































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { SelectListOption } from '@/core/models/select-list';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { Center, LocationGroupAndCentersEntity } from '@/organizations/locations/models/center';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    CrmTypeList,
    CrmTypeOption,
    CrmTypeOptionCreateDto,
    CrmTypeOptionDto,
    CrmTypeOptionUpdateDto
} from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypesRepository } from '@/crm-types/repositories/crm-types-repository';
import CrmTypeMapper from '@/crm-types/mappers/crm-type-mapper';
import { DataTableHeader } from 'vuetify';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';
import { FeaturesStore } from '@/features/features-store';

interface LocationGroupWithCentersInterface {
    originalLocationGroup: CrmTypeOptionUpdateDto;
    originalCenters: Array<SelectListOption>;
    currentLocationGroup: CrmTypeOptionUpdateDto;
    currentCenters: Array<SelectListOption>;
    duplicates: number;
}

interface CenterWithLocationGroupsInterface {
    center: { text: string; value: number | string };
    originalGroups: Array<SelectListOption>;
    currentGroups: Array<SelectListOption>;
}

const centersStore = getModule(CentersStore);
const loadingStore = getModule(LoadingStore);
const centersRepo = new CentersRepository();
const crmTypesStore = getModule(CrmTypesStore);
const crmTypesRepo = new CrmTypesRepository();
const crmTypeMapper = new CrmTypeMapper();
const featureStore = getModule(FeaturesStore);

@Component({
    components: {
        BaseClose,
        InlineEditable
    }
})
export default class LocationGroups extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Ref('form') readonly form!: VForm;

    private groupsAvailable: Array<SelectListOption> = [];
    private loadingKey = 'locationGroupsLoadingKey';
    private byGroupHeaders: Array<DataTableHeader> = [
        {
            text: 'Group Name',
            value: 'currentLocationGroup.value',
            width: '38%',
            sortable: true
        },
        {
            text: 'Locations',
            value: 'locations',
            width: '60%',
            sortable: false
        },
        {
            text: '',
            value: 'copy',
            align: 'center',
            width: '1%',
            sortable: false
        },
        {
            text: '',
            value: 'delete',
            align: 'center',
            width: '1%',
            sortable: false
        }
    ];

    private byLocationHeaders: Array<DataTableHeader> = [
        {
            text: 'Location Name',
            value: 'center.text',
            width: '38%',
            sortable: true
        },
        {
            text: 'Groups',
            value: 'groups',
            width: '62%'
        }
    ];

    private showSnack = false;
    private snackText = '';
    private sortBy = 'group';
    // Keeps track of deleted items before submit
    private groupsDeleted: Array<LocationGroupWithCentersInterface> = [];

    // Initial state
    private initialItemsByGroup: Array<LocationGroupWithCentersInterface> = [];
    private initialItemsByLocation: Array<CenterWithLocationGroupsInterface> = [];

    // Items when view by group
    private itemsByGroup: Array<LocationGroupWithCentersInterface> = [];

    // Items when view by location
    private itemsByLocation: Array<CenterWithLocationGroupsInterface> = [];

    // Keeps track of new items added through copy button and add button
    private newElementsCount = 0;
    private originalLocationGroups: Array<CrmTypeOption> = [];
    private originalLocationGroupsMap: Map<number, CrmTypeOption> = new Map<number, CrmTypeOption>();
    private switchToViewByLocation = false;
    private validForm = true;

    /**
     * Centers that can be assigned to location groups (only active).
     */
    get centersAvailable(): Array<SelectListOption> {
        return centersStore.storedAccessibleCentersWithInactive.map(center => {
            return {
                value: center.id,
                text: center.name
            };
        });
    }

    private get hasChanges(): boolean {
        return !isEqual(this.itemsByGroup, this.initialItemsByGroup) || !isEqual(this.itemsByLocation, this.initialItemsByLocation);
    }

    private get isLineLeaderEnroll(): boolean {
        return featureStore.isLineLeaderEnroll;
    }

    // This is for applying pending changes from switching between groups view and locations view
    @Watch('switchToViewByLocation', {
        immediate: true,
        deep: true
    })
    private transferPendingChangesFromGroupToLocation() {
        if (this.switchToViewByLocation) {
            for (const center of this.itemsByLocation) {
                const tempArray: Array<SelectListOption> = [];
                this.itemsByGroup.forEach(group => {
                    if (group.currentCenters.filter(c => c.value === center.center.value).length > 0) {
                        tempArray.push({
                            text: group.currentLocationGroup.value,
                            value: group.currentLocationGroup.id
                        });
                    }
                });
                center.currentGroups = tempArray;
            }
        }
    }

    @Watch('itemsByLocation', {
        immediate: true,
        deep: true
    })
    private transferPendingChangesFromLocationToGroup() {
        if (this.itemsByLocation) {
            for (const group of this.itemsByGroup) {
                const tempArray: Array<SelectListOption> = [];
                this.itemsByLocation.forEach(center => {
                    if (center.currentGroups.filter(g => g.value === group.currentLocationGroup.id).length > 0) {
                        tempArray.push({
                            text: center.center.text,
                            value: center.center.value
                        });
                    }
                });
                group.currentCenters = tempArray;
            }
        }
    }

    private async created() {
        await this.initializeItems();
    }

    // Add an empty row, a new item must have temporary negative id, so pending updates will be transfer when switch view without any errors
    private addEmptyRow() {
        this.newElementsCount++;
        const newGroup = ({
            originalLocationGroup: new CrmTypeOptionDto(),
            originalCenters: [],
            currentLocationGroup: new CrmTypeOptionDto(),
            currentCenters: [],
            duplicates: 0
        });
        newGroup.currentLocationGroup.id = -Math.abs(this.newElementsCount);
        newGroup.originalLocationGroup.id = -Math.abs(this.newElementsCount);

        this.itemsByGroup.push(newGroup);
        this.groupsAvailable.push({
            text: newGroup.currentLocationGroup.value,
            value: newGroup.currentLocationGroup.id
        });
        this.sortingSelectList(this.groupsAvailable);
    }

    // Changes to the name field of the group will be transfer when switching view
    private applyChangesToSelectList(item: CrmTypeOptionUpdateDto) {
        this.groupsAvailable.find(group => group.value === item.id)!.text = item.value;
        this.sortingSelectList(this.groupsAvailable);
    }

    // Cancel will reset the table
    private async cancel() {
        await this.initializeItems();
        this.validForm = true;
        this.newElementsCount = 0;
    }

    // Copy function will create a new item, with the selected group info.
    private copyGroup(group: LocationGroupWithCentersInterface) {
        this.addEmptyRow();
        const newGroup = this.itemsByGroup[this.groupsAvailable.length - 1];
        newGroup.currentLocationGroup.value = group.currentLocationGroup.value + '(1)';
        newGroup.currentCenters = group.currentCenters;
    }

    // Delete an item, when delete it will update across the available data like the location group select list, and the copied array if there are duplicates
    private async deleteGroup(group: LocationGroupWithCentersInterface) {
        // Select list of available location groups will be updated
        const deletedGroup = this.groupsAvailable.find(g => g.value === group.currentLocationGroup.id);
        if (group.currentLocationGroup.id < 0) {
            this.itemsByGroup.splice(this.itemsByGroup.indexOf(group), 1);
            if (deletedGroup) {
                this.groupsAvailable.splice(this.groupsAvailable.indexOf(deletedGroup), 1);
            }
        } else {
            const result = await this.$swal({
                text: 'Warning--deleting a location group cannot be undone (although you could rebuild the group).  Are you sure? ',
                confirmButtonText: 'DELETE',
                cancelButtonText: 'CANCEL',
                showConfirmButton: true,
                showCancelButton: true,
                reverseButtons: true,
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });
            if (result.isConfirmed) {
                this.groupsDeleted.push(group);
                this.itemsByGroup.splice(this.itemsByGroup.indexOf(group), 1);
                if (deletedGroup) {
                    this.groupsAvailable.splice(this.groupsAvailable.indexOf(deletedGroup), 1);
                }
            }
        }
    }

    // Gets sorted location groups with centers
    private buildItemLists(groupsAndCenters: Array<LocationGroupAndCentersEntity>): void {
        const locationGroups: Array<LocationGroupWithCentersInterface> = [];
        const centersWithGroupsMap: Map<number, Array<SelectListOption>> = new Map<number, Array<SelectListOption>>();
        const accessibleCenterIds = centersStore.accessibleCentersWithInactive.map((center: Center) => center.id);

        let mappedGroup: CrmTypeOptionUpdateDto | null = null;
        let centerList: Array<SelectListOption> = [];
        let rawCenterList: Array<SelectListOption> = [];
        let groupOptions: Array<SelectListOption> = [];

        // remove any groups that have any centers that are not visible to user
        groupsAndCenters = groupsAndCenters.filter(groupAndCenters => {
            for (const center of groupAndCenters.centers) {
                if (!accessibleCenterIds.includes(center.id)) {
                    return false;
                }
            }
            return true;
        });

        for (const groupAndCenters of groupsAndCenters) {
            mappedGroup = crmTypeMapper.toUpdateDto(this.originalLocationGroupsMap.get(groupAndCenters.group.id) as CrmTypeOption);
            rawCenterList = [];
            for (const centerLink of groupAndCenters.centers) {
                rawCenterList.push({
                    text: centerLink.values.name as string,
                    value: centerLink.id
                });
                if (centersWithGroupsMap.has(centerLink.id)) {
                    groupOptions = centersWithGroupsMap.get(centerLink.id)!;
                    groupOptions.push({
                        text: groupAndCenters.group.values.value ?? '',
                        value: groupAndCenters.group.id
                    });
                    centersWithGroupsMap.set(
                        centerLink.id,
                        groupOptions
                    );
                } else {
                    centersWithGroupsMap.set(
                        centerLink.id,
                        [{
                            text: groupAndCenters.group.values.value ?? '',
                            value: groupAndCenters.group.id
                        }]
                    );
                }
            }
            centerList = this.sortingSelectList(rawCenterList);
            locationGroups.push({
                originalLocationGroup: mappedGroup,
                originalCenters: centerList,
                currentLocationGroup: mappedGroup,
                currentCenters: centerList,
                duplicates: 0
            });
        }

        // Don't return the location groups by the CrmTypeOption.order but instead by the order of the value
        this.itemsByGroup = locationGroups;
        this.itemsByGroup = locationGroups.sort(
            (a, b) => a.originalLocationGroup.value.localeCompare(b.originalLocationGroup.value)
        );

        this.itemsByLocation = centersStore.storedAccessibleCentersWithInactive.map((center: Center) => {
            return {
                center: {
                    text: center.name,
                    value: center.id
                },
                originalGroups: this.sortingSelectList(centersWithGroupsMap.get(center.id) || []),
                currentGroups: this.sortingSelectList(centersWithGroupsMap.get(center.id) || [])
            };
        });
    }

    // Initialization data for two views
    public async initializeItems() {
        loadingStore.loadingIncrement(this.loadingKey);
        await crmTypesStore.retrieveList(CrmTypeList.LOCATION_GROUPS);
        this.originalLocationGroups = crmTypesStore.listOptions(CrmTypeList.LOCATION_GROUPS);
        for (const group of this.originalLocationGroups) {
            this.originalLocationGroupsMap.set(group.id, group);
        }
        const centersPromise = centersStore.initAccessibleCentersWithInactive();
        const groupsAndCentersPromise = centersRepo.getLocationGroupsAndCenters();
        await centersPromise;
        await groupsAndCentersPromise;
        let groupsAndCenters: Array<LocationGroupAndCentersEntity> = [];
        await Promise.resolve(groupsAndCentersPromise).then((result) => {
            groupsAndCenters = result;
        });
        this.buildItemLists(groupsAndCenters);
        this.initialItemsByGroup = cloneDeep(this.itemsByGroup);
        this.initialItemsByLocation = cloneDeep(this.itemsByLocation);
        this.groupsAvailable = crmTypesStore.listOptions(CrmTypeList.LOCATION_GROUPS).map(group => {
            return {
                value: group.id,
                text: group.value
            };
        });
        this.groupsDeleted = [];
        loadingStore.loadingDecrement(this.loadingKey);
    }

    // Sorting
    public sortingSelectList(list: Array<SelectListOption>): Array<SelectListOption> {
        return list.sort((a, b) => a.text.localeCompare(b.text));
    }

    private async submit() {
        loadingStore.loadingIncrement(this.loadingKey);

        // Handle updates and creates.
        for (const group of this.itemsByGroup) {
            if (!isEqual(group.originalLocationGroup, group.currentLocationGroup)) {
                const groupClone = cloneDeep(group.currentLocationGroup);
                if (groupClone.id > 0) {
                    const groupId = groupClone.id;
                    delete groupClone.id;
                    await crmTypesRepo.updateListItem(groupId, groupClone as CrmTypeOptionUpdateDto, CrmTypeList.LOCATION_GROUPS);
                } else {
                    delete groupClone.id;
                    const newGroup: CrmTypeOption = await crmTypesRepo.addListItem(groupClone as CrmTypeOptionCreateDto, CrmTypeList.LOCATION_GROUPS);
                    const centerIds: Array<number> = [];
                    group.currentCenters.forEach(center => {
                        centerIds.push(center.value as number);
                    });
                    await centersRepo.updateCentersForLocationGroup(newGroup.id, centerIds);
                }
            }

            if (!isEqual(group.originalCenters, group.currentCenters)) {
                const centerIds: Array<number> = [];
                group.currentCenters.forEach(center => {
                    centerIds.push(center.value as number);
                });

                if (group.currentLocationGroup.id > 0) {
                    await centersRepo.updateCentersForLocationGroup(group.currentLocationGroup.id, centerIds);
                }
            }
        }

        // Handle deletes.
        while (this.groupsDeleted.length > 0) {
            await crmTypesRepo.deleteListItem(this.groupsDeleted.pop()?.currentLocationGroup.id!, CrmTypeList.LOCATION_GROUPS);
        }

        await this.initializeItems();
        this.snackText = 'Location Groups Saved';
        this.showSnack = true;
        this.newElementsCount = 0;

        loadingStore.loadingDecrement(this.loadingKey);
    }
}
