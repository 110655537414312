import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';

export type CrmBreakpoint = 'sm' | 'md' | 'lg' | 'xl';
const smallBreakpoint = 1264;
const mediumBreakpoint = 1535;
const largeBreakpoint = 1904;
const appState = getModule(AppStateStore);

// We're defining our own breakpoint service to implement our responsiveness
// guidelines. We can't override Vuetify's breakpoints altogether because there's
// already usages of it throughout the app with the default breakpoint values
// and there's a possibility of breaking things if we change that

@Component
export class CrmBreakpointsMixin extends Vue {
    get crmBreakpoint(): CrmBreakpoint {
        const width = this.$vuetify.breakpoint.width;
        if (width < smallBreakpoint) {
            return 'sm';
        } else if (width < mediumBreakpoint) {
            return 'md';
        } else if (width < largeBreakpoint) {
            return 'lg';
        }
        return 'xl';
    }

    get crmIsVerySmall(): boolean {
        if (this.$vuetify.breakpoint.width < 1080) {
            return true;
        }
        return this.crmBreakpoint === 'sm' && !appState.isMini;
    }

    get crmIsMobileSmall(): boolean {
        return this.$vuetify.breakpoint.width < 992;
    }
}
