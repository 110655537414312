


























import { FeatureConstants } from '@/features/feature-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { FeaturesRepository } from '@/features/features-repository';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { Feature } from '@/features/features-models';
import { FeaturesStore } from '@/features/features-store';

const loadingStore = getModule(LoadingStore);
const featuresRepo = new FeaturesRepository();
const featuresStore = getModule(FeaturesStore);

@Component
export default class FeatureSettings extends Mixins(LocaleMixin) {
    private loadingKey = 'featureSettings';
    private features: Array<Feature> = [];

    public async mounted() {
        await this.loadFeatures();
    }

    private async loadFeatures() {
        loadingStore.loadingIncrement(this.loadingKey);
        const featuresResponse = await featuresStore.retrieve();
        let features: Array<Feature> = [];
        let isCrmPlus = false;
        for (const feature of featuresResponse as Array<Feature>) {
            if (feature.identifier === FeatureConstants.CRM_DUAL_MODE) {
                // dual mode is handled elsewhere than this page
                continue;
            }
            // We only show user-editable features
            if (feature.is_user_editable) {
                features.push(feature);
            }
            if (feature.identifier === FeatureConstants.CRM_PLUS_MODE) {
                isCrmPlus = feature.is_active;
            }
        }

        // Filter out features that are for CRM+ only when just in CRM
        if (!isCrmPlus) {
            features = features.filter((feature: Feature) => {
                return !feature.is_crm_plus;
            });
        }
        features.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        this.features = features;
        loadingStore.loadingDecrement(this.loadingKey);
    }

    private async toggle(feature: Feature) {
        if (!feature.is_modifiable) {
            return;
        }
        loadingStore.loadingIncrement(this.loadingKey);
        await featuresRepo.toggle(feature, feature.is_active);
        await this.loadFeatures();
        loadingStore.loadingDecrement(this.loadingKey);
    }
}
