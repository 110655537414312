import { render, staticRenderFns } from "./Workflows.vue?vue&type=template&id=7fea809b&scoped=true&"
import script from "./Workflows.vue?vue&type=script&lang=ts&"
export * from "./Workflows.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fea809b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VIcon,VRow,VSpacer,VSwitch,VTooltip})
