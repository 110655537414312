export const enum StoreModuleTypes {
    APP = 'app',
    LOADING = 'loading',
    AUTH = 'auth',
    PENDING_LEADS = 'pendingLeads',
    ENROLLMENTS = 'enrollments',
    FAMILIES = 'families',
    STATS = 'stats',
    ORGS = 'orgs',
    TEMPLATE_VARIABLES = 'templateVariables',
    EMAIL_TEMPLATES = 'emailTemplates',
    TEXT_TEMPLATES = 'textTemplates',
    TEMPLATES = 'templates',
    STAFF = 'staff',
    CRM_TYPES = 'crmTypes',
    CUSTOM_FIELDS_SETTINGS = 'customFieldSettings',
    CUSTOM_FIELDS = 'customFields',
    CUSTOM_FIELD_VALUES = 'customFieldValues',
    CUSTOM_FAMILY_VALUES = 'customFamilyValues',
    CUSTOM_STAFF_VALUES = 'customStaffValues',
    STATUSES = 'statuses',
    CENTERS = 'centers',
    CLIENT_INFO = 'clientInfo',
    TASKS = 'tasks',
    TASK_TYPES = 'taskTypes',
    TASK_GROUPS = 'taskGroups',
    DASHBOARD = 'dashboard',
    EMAILS = 'emails',
    TEXTS = 'texts',
    FBMESSAGES = 'fbMessages',
    EVENTS = 'events',
    UPDATES = 'updates',
    FORMS = 'forms',
    TIMEZONES = 'timezones',
    USER_PERMISSIONS = 'userPermissions',
    INTEGRATIONS = 'integrations',
    INTEGRATION_PARTNERS = 'integrationPartners',
    FEATURES = 'features',
    USER_SETTINGS = 'userSettings',
    ORG_LEVELS = 'orgLevels',
    DRIP_CAMPAIGNS = 'dripCampaigns',
    WORKFLOWS = 'workflows',
    WORKFLOWS_TYPES = 'workflowsTypes',
    WORKFLOWS_LOCATIONS = 'workflowsLocations',
    BRANDS = 'brands',
    EMAIL_REMINDERS = 'emailReminders',
    TEXT_REMINDERS = 'textReminders',
    SAVED_REPORTS = 'savedReports',
    MARKETING_CAMPAIGNS = 'marketingCampaigns',
    FILTERS = 'filters',
    FIELDS = 'fields',
    REQUIRED_FIELDS = 'requiredFields',
    FAMILY_CHANGES = 'familyChanges',
    CHILD_CHANGES = 'childChanges',
    STATUS_CHANGES = 'statusChanges',
    ENROLLMENT_CENTER_SETTINGS = 'enrollmentCenterSettings',
    ENROLLMENT_CENTER_SETTINGS_CHANGES = 'enrollmentCenterSettingsChanges',
    ADVISORIES = 'advisories',
    OFFERINGS = 'offerings',
    SERVICE_PROVIDERS = 'serviceProviders',
    PERMISSIONS = 'permissions',
    EMAIL_TEMPLATE_BLOCKS = 'emailTemplateBlocks',
    PROMISES = 'promises',
    SIX_AGENT = 'sixAgent',
    REPORT_JOB_LOGS = 'reportJobLogs',
    STANDARD_REPORTS = 'standardReports',
    ANY = 'any',
    LAYOUT_TABS = 'layoutTabs',
    MAIN_INTERFACE_SETTINGS = 'mainInterfaceSettings',
    ORG_INTERFACE_SETTINGS = 'orgInterfaceSettings',
    INTERFACE_SETTINGS_CHANGES = 'interfaceSettingsChanges',
    FACEBOOK = 'facebook',
    RECORDINGS = 'recordings',
    LOCATION_DASHBOARD = 'locationDashboard',

    CUSTOM_DASHBOARDS = 'customDashboards',
    REPOSITORY_FACTORY = 'repositoryFactory'
}
