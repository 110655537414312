import { AbstractCreateDto } from '@/models/abstract-dto.ts';
import { CrmApiElement, HateoasLink } from '@/models/base';

export interface BasePhone extends CrmApiElement {
    country_code: string | null;
    number: string;
    number_e164: string;
    extension: string | null;
}

export interface Phone extends BasePhone {
    type: HateoasLink | null;
}

export interface BasePhoneDtoInterface extends AbstractCreateDto {
    number_e164: string;
}

export interface PhoneDtoInterface extends BasePhoneDtoInterface {
    type: string | number | null;
}

export class BasePhoneDto implements BasePhoneDtoInterface {
    number_e164 = '';
}

export class PhoneDto extends BasePhoneDto implements PhoneDtoInterface {
    type: string | number | null = null;
}
