




















































import { Component, Mixins, Ref } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { forgotUsername } from '@/auth/auth';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { VForm } from '@/types/types';

const loadingState = getModule(LoadingStore);

@Component
export default class ForgotUsername extends Mixins(LocaleMixin) {
    private email = '';
    private validForm = false;
    private notEmpty = [
        (v: string) => !!v || 'Please enter a valid email address'
    ];

    @Ref('usernameForm') readonly usernameForm!: VForm;

    public clearForm() {
        this.email = '';
        this.usernameForm.reset();
    }

    public async getUsername() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        const usernameResponse = await forgotUsername(this.email);
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
        await this.$swal({ icon: 'info', text: usernameResponse });
        this.$router.push('/');
    }
}
