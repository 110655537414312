import { AbstractRepository } from '@/repositories/abstract-repository';
import {
    TextTemplateCreateDto,
    TextTemplateUpdateDto,
    MessageTemplate, TemplateGroupTemplates, TemplateGroupUpdate
} from '@/communications/templates/models/message-template';
import { PreviewTemplate, PreviewTemplateRequest } from '@/communications/templates/models/preview-template';

interface PreviewPostData {
    body: string;
    org_id?: number;
    center_id?: number;
    user_id?: number;
}

export class TextTemplatesRepository extends AbstractRepository<MessageTemplate> {
    readonly endpoint = 'communications/templates/texts';

    public async create(createDto: TextTemplateCreateDto): Promise<MessageTemplate> {
        const response = await this.client.post(this.endpoint, createDto);
        return response.data;
    }

    public async update(updateDto: TextTemplateUpdateDto): Promise<MessageTemplate> {
        const response = await this.client.put(`${this.endpoint}/${updateDto.id}`, updateDto);
        return response.data;
    }

    public async delete(templateId: number): Promise<any> {
        const response = await this.client.delete(`${this.endpoint}/${templateId}`);
        return response.data;
    }

    public async getGroups(): Promise<Array<TemplateGroupTemplates>> {
        const response = await this.client.get(this.endpoint + '/groups');
        return response.data;
    }

    public async updateGroup(groupId: number, update: TemplateGroupUpdate): Promise<void> {
        await this.client.put(this.endpoint + '/groups/' + groupId, update);
    }

    /**
     * Create a preview from a template that doesn't exist, or was edited.
     *
     * @param body
     * @param centerId
     * @param organizationId
     * @param userId
     */
    public async generatePreview(
        body: string,
        centerId?: number | null,
        organizationId?: number | null,
        userId?: number | null
    ): Promise<PreviewTemplate> {
        const postData: PreviewPostData = {
            body: body
        };

        if (centerId) {
            postData.center_id = centerId;
        }

        if (organizationId) {
            postData.org_id = organizationId;
        }

        if (userId) {
            postData.user_id = userId;
        }

        return (await this.client.post<PreviewTemplate>(`${this.endpoint}/preview`, postData)).data;
    }

    public async generatePreviewForTemplate(template: MessageTemplate, request: PreviewTemplateRequest): Promise<PreviewTemplate> {
        const response = await this.client.get(`${this.endpoint}/${template.id}/preview`, { params: request });
        return response.data;
    }
}
