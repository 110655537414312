import { AbstractCreateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';

export interface Permission extends CrmEntity {
    description: string;
    help_description: string | null;
    is_active: boolean;
    is_for_enhanced: boolean;
    is_group_toggle: boolean;
    group: PermissionGroup;
    label: string;
}

export interface PermissionGrants {
    create: boolean;
    delete: boolean;
    read: boolean;
    update: boolean;
}

export interface PermissionGroup extends CrmEntity {
    description: string;
    label: string;
    parent: PermissionGroupParentLink | null;
}

export interface PermissionGroupParentLink extends HateoasLink {
    values: {
        name: string;
        parent: number | null;
    };
}

export enum PermissionName {
    Family = 'family',
    FamilySendGroup = 'family_send_groups',
    FamilyDeleteDocs = 'family_delete_docs',
    Tasks = 'tasks',
    TasksEditOthers = 'tasks_edit_others',
    DashboardBothViews = 'dashboards_both_views',
    AutomationAccess = 'automation_access',
    AutomationViewMessageTemplates = 'automation_view_message_templates',
    AutomationMessageTemplates = 'automation_message_templates',
    AutomationReminders = 'automation_reminders',
    AutomationLandingPages = 'automation_landing_pages',
    AutomationViewMarketing = 'automation_view_marketing',
    AutomationViewWorkflows = 'automation_view_workflows',
    AutomationPST = 'automation_pst_wizard',
    AutomationWorkflows = 'automation_workflows',
    AutomationMarketplace = 'automation_marketplace',
    AutomationDripCampaign = 'automation_drip_campaigns',
    ReportsAccess = 'reports_access',
    SettingsNav = 'settings_nav',
    SettingsUsers = 'settings_users',
    SettingsSettings = 'settings_settings',
    EnrollmentCenterEditLocation = 'enrollment_center_edit_location',
    SettingsDashboardTodoFamily = 'settings_dashboard_todo_family',
    SettingsAllGears = 'settings_all_gears',
    CustomDashboards = 'settings_custom_dashboards',
    FamilyDelete = 'family_delete',

    FamilyBulkDelete = 'family_bulk_delete'
}

export const corpOnlyPermissions: Array<string> = [PermissionName.AutomationReminders, PermissionName.AutomationWorkflows, PermissionName.AutomationDripCampaign];
export const franchiseCorpOnlyPermissions: Array<string> = [PermissionName.AutomationReminders, PermissionName.AutomationWorkflows];

export interface BaseUserPermission {
    description: string;
    grants: PermissionGrants;
    help_description: string | null;
    label: string;
}

export interface UserPermission extends CrmEntity, BaseUserPermission {
    is_active: boolean;
    is_for_enhanced: boolean;
    is_group_toggle: boolean;
    group: PermissionGroup;
}

export interface UserPermissionDto extends AbstractCreateDto {
    grants: PermissionGrants;
}

export interface UserPermissionViewDto extends AbstractCreateDto, BaseUserPermission {
}
