


















































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Center } from '@/organizations/locations/models/center';
import { EditMode } from '@/core/edit-modes';
import { getModule } from 'vuex-module-decorators';
import { EnrollmentCenterLocationAdvisoriesStore } from '@/enrollment-center/store/enrollment-center-location-advisories-store';
import { LoadingStore } from '@/store/loading-store';
import { LocationAdvisory } from '@/enrollment-center/models/enrollment-center-location-models';

const advisoriesState = getModule(EnrollmentCenterLocationAdvisoriesStore);
const loadingState = getModule(LoadingStore);

@Component
export default class LocationAdvisoryComponent extends Mixins(LocaleMixin) {
    @Prop({ required: true }) location!: Center;
    @PropSync('mode') localMode!: EditMode;

    private loadingKey = 'locationAdvisory';
    private editTitle = '';
    private editDescription = '';

    get currentAdvisory(): LocationAdvisory | null {
        const advisory = advisoriesState.stored.filter((advisory) => {
            return advisory.center.id === this.location.id;
        });
        if (advisory.length) {
            return advisory[0];
        }
        return null;
    }

    get isEdit() {
        return this.localMode !== EditMode.SAVED;
    }

    get title(): string {
        return this.currentAdvisory ? this.currentAdvisory.title : '';
    }

    get description(): string {
        return this.currentAdvisory ? this.currentAdvisory.description : '';
    }

    async created() {
        loadingState.loadingIncrement(this.loadingKey);
        await advisoriesState.init();
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('localMode', { immediate: true })
    async modeChanged() {
        if (this.localMode === EditMode.SAVED) {
            // init component state from store, if needed
        }
        if (this.localMode === EditMode.SAVING) {
            await this.saveAdvisory();
            this.localMode = EditMode.SAVED;
        }
        if (this.localMode === EditMode.EDITING) {
            this.createEdit();
        }
    }

    /**
     * initialize edit state values
     */
    createEdit() {
        this.editDescription = this.description;
        this.editTitle = this.title;
    }

    async saveAdvisory() {
        await advisoriesState.updateCenterAdvisory({
            center: this.location.id,
            title: this.editTitle,
            description: this.editDescription
        });
    }
}
